import React from 'react';
import {
    ScrollView,
    Text,
    View,
    TouchableOpacity,
    ActivityIndicator,
    Image,
} from 'react-native';
import {
    DimensionAware,
    getWindowHeight,
    getWindowWidth,
} from 'react-native-dimension-aware';
import { Box, Flex, Button } from '../../components/common';
import { Table, TableWrapper, Row, Rows, Cell } from '../../components/table';
import { fetchWorkFlow } from '../../redux/mockdata';
import { Link } from '../../navigation/router';
import withLoader from '../../components/withLoader';
import { formatRequestorName } from '../../appRedux/sagas/config';
import { connect } from 'react-redux';
import {
    getWorkflows,
    getTeamUsers,
    assignUser,
    unAssignUser,
    getStaticRequest,
} from '../../appRedux/actions';
import {
    WorkflowTaskStateType,
    WorkflowType,
    SystemType,
    RoleType,
    WorkflowProcessType,
    WorkflowTeamTypeRouteAddress,
    TaskType,
    WorkflowTeamType,
    WorkflowStateType,
    WorkflowTaskType,
    getWorkflowRouteAddress,
} from '../../constants/WorkflowEnums';
import { FormInput } from '../../components/form';

// const workFlowStatus = ['New', 'In Progress', 'Approved', 'Rejected'];
// const workFlowType = ['Create', 'Extend', 'Update', 'Block'];
import FlashMessage, { FlashMessages } from '../../components/FlashMessage';
import { removeMessage } from '../../appRedux/actions/Toast';
import { Colors, StyledText } from '../../theme/Index';
import RequestFilterFields from '../../components/RequestFilterFields';
import { AntDesign, FontAwesome5 } from '@expo/vector-icons';
import OverflowRight from '../../components/OverflowRight';
import { OverflowRightContext } from '../SearchResult/Apollo/MdmDetailScreen';
import { getSyntheticTrailingComments } from 'typescript';
import UserAssignmentSelector from '../../components/UserAssignmentSelector';
import Modal from 'modal-enhanced-react-native-web';
import { mapKeys } from 'lodash';
import idx from 'idx';
const DataTable = ({ tableHead, workflowTasks, fetchTeamUsers, staticData }) => {
    let tableData = [];
    var tdata;
    let td = workflowTasks.map((wfTask, index) => {
        var linkClassname =
            WorkflowTeamTypeRouteAddress[wfTask.WorkflowTeamType] === '#'
                ? 'disable-link'
                : 'enable-link';
        var routeTo = getWorkflowRouteAddress(wfTask);
        var navigateTo = routeTo['address'];
        // if Workflowstatetpe inprogress & WorkflowTaskStateType ReadyForProcessing then not readonly
        var readOnlyStatus = !(
            wfTask.WorkflowStateType == 2 && wfTask.WorkflowTaskStateType == 2
        );

        let wfProcess = WorkflowProcessType[wfTask.ProcessId];
        if (
            wfTask.WorkflowTaskType === 21 ||
            wfTask.WorkflowTaskType === 50 ||
            wfTask.WorkflowTaskType === 51 ||
            wfTask.WorkflowTaskType === 52
        )
            wfProcess = 'Other';
        // console.log(wfTask)
        tdata = [
            <Link
                style={{
                    paddingTop: 26,
                    paddingBottom: 27,
                    paddingLeft: 20,
                    paddingRight: 15,
                    overflowWrap: 'break-word',
                }}
                className={linkClassname}
                to={{
                    pathname: navigateTo,
                    state: {
                        ...wfTask,
                        TaskId: wfTask.TaskId,
                        isReadOnly: readOnlyStatus,
                        WorkflowId: wfTask.WorkflowId,
                        TeamId: wfTask.WorkflowTeamType,
                        Header: routeTo['header'],
                        ModifiedOn: wfTask.ModifiedOn,
                    },
                }}>
                {wfTask.WorkflowId}
            </Link>,
            SystemType[wfTask.SystemTypeId],
            wfTask.RoleTypeId === null || wfTask.RoleTypeId === 0
                ? 'N/A'
                : wfTask.SystemTypeId === 3
                ? idx(
                      staticData,
                      (_) => _.PointmanRoleType[wfTask.RoleTypeId].Description
                  )
                : wfTask.SystemTypeId === 2
                ? idx(staticData, (_) => _.OlympusRoleType[wfTask.RoleTypeId].Description)
                : wfTask.SystemTypeId === 5
                ? idx(staticData, (_) => _.JDERoleType[wfTask.RoleTypeId].Description)
                : idx(staticData, (_) => _.ApolloRoleType[wfTask.RoleTypeId].Description),
            staticData.WorkflowTaskType &&
            staticData.WorkflowTaskType[wfTask.WorkflowTaskType]
                ? staticData.WorkflowTaskType[wfTask.WorkflowTaskType].Description
                    ? staticData.WorkflowTaskType[wfTask.WorkflowTaskType].Description
                    : staticData.WorkflowTaskType[wfTask.WorkflowTaskType].Value
                    ? staticData.WorkflowTaskType[wfTask.WorkflowTaskType].Value
                    : ''
                : '',
            wfProcess,
            wfTask.CustomerName || 'N/A',
            <>
                <Text> {WorkflowTeamType[wfTask.WorkflowTeamType]}</Text>
                <Box flexDirection="row" justifyContent="flex-end" pr={3}>
                    <TouchableOpacity
                        hitSlop={{
                            top: 10,
                            bottom: 10,
                            left: 0,
                            right: 0,
                        }}
                        onPress={() => fetchTeamUsers(wfTask)}
                        style={{
                            flexDirection: 'row',
                            justifyContent: 'center',
                        }}>
                        <label>
                            <AntDesign
                                name="downcircle"
                                size={15}
                                color="#11307D"
                                label="as"
                            />
                        </label>
                    </TouchableOpacity>
                </Box>
            </>,
            formatRequestorName(wfTask.AssignedUser),
            wfTask.ModifiedOn.split('T')[0],
            WorkflowStateType[wfTask.WorkflowStateType],
            WorkflowTaskStateType[wfTask.WorkflowTaskStateType],
        ];
        tableData.push(tdata);
    });

    return (
        <Box
            my={2}
            style={{
                zIndex: -1,
            }}>
            <Table
                border="1.5px solid #234382"
                borderStyle={{
                    borderWidth: 1,
                    borderRightWidth: 1,
                    borderColor: '#98D7DA',
                    borderRightStyle: 'solid',
                }}>
                <Row
                    flexArr={[1, 0.6, 0.6, 0.85, 0.7, 1.0, 0.85, 0.8, 0.65, 0.8, 1]}
                    data={tableHead}
                    style={{
                        backgroundColor: '#E6F5FA',
                        maxHeight: 75,
                    }}
                    borderStyle={{
                        borderWidth: 0,
                        borderTopWidth: 0,
                        borderRightWidth: 1,
                        borderColor: '#98D7DA',
                        borderRightStyle: 'solid',
                    }}
                    textStyle={{
                        textAlign: 'left',
                        color: '#234385',
                        fontWeight: '600',
                        fontFamily: 'Poppins',
                        fontSize: 16,
                        paddingTop: 24,
                        paddingBottom: 24,
                        paddingHorizontal: 15,
                    }}
                />
                <Rows
                    flexArr={[1, 0.6, 0.6, 0.85, 0.7, 1.0, 0.85, 0.8, 0.65, 0.8, 1]}
                    data={tableData}
                    style={{ minHeight: 65, maxHeight: 120 }}
                    borderStyle={{
                        borderWidth: 0,
                        borderTopWidth: 0,
                        borderRightWidth: 1,
                        borderColor: '#98D7DA',
                        borderRightStyle: 'solid',
                    }}
                    textStyle={{
                        color: '#353535',
                        fontSize: 14,
                        fontWeight: '500',
                        fontFamily: 'Poppins',
                        borderColor: '#98D7DA',
                        paddingTop: 26,
                        paddingBottom: 27,
                        paddingLeft: 20,
                        paddingRight: 10,
                        textAlign: 'left',
                        backgroundColor: '#F8F8F8',
                    }}
                />
            </Table>
        </Box>
    );
};

const WorkFlowsTable = withLoader(DataTable);

class Page extends React.Component {
    toggle = (value = null) => {
        const isBoolean = value && typeof value === 'boolean';
        this.setState((state) => ({
            isToggled: isBoolean ? value : !state.isToggled,
        }));
    };
    constructor(props) {
        super(props);
        this.toggleOverflow = () => {
            this.setState((state) => ({
                isToggled: !state.isToggled,
                modalVisible: false,
                visibleRequestModal: false,
            }));
        };
        this.state = {
            tableHead: [
                'Workflow Number',
                'System',
                'Role',
                'Workflow Type',
                'WF Process',
                'Customer Name',
                'Team',
                'Assigned User',
                'Date Of Creation',
                'Workflow \n Status',
                'Task \n Status',
            ],
            loading: true,
            current_page: 1,
            workflowTotal: 500,
            isToggled: false,
            toggleOverflow: this.toggle,
            formData: { WorkflowStateTypeId: '2', WorkflowTaskStateTypeId: '2' },
            loadedFilterRequestData: true,
            assignedUserName: '',
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location && this.state.isToggled === true) {
            this.toggle(false);
        }
    }

    componentDidMount() {
        const userId = localStorage.getItem('userId');
        this.props.getStaticRequest([6, 50, 40, 46, 116]);

        var jsonBody = {
            WorkFlowEngineRequestType: 3,
            WorkFlowTaskFilterRequest: {
                UserId: userId,
                WorkflowTaskOperationType: 3,
                Pager: { From: 0, Size: 10 },
            },
            WorkflowRequestFilter: {
                WorkflowStateTypeId: '2',
                WorkflowTaskStateTypeId: '2',
            },
        };

        this.props.getWorkflows(jsonBody);
    }

    onFieldChange = (value, e) => {
        const name = e.target.name;
        const { formData: prevFormData } = this.state;

        this.setState((prevState, props) => {
            return {
                formData: {
                    ...prevState.formData,
                    [name]: value,
                },
            };
        });
    };

    fetchTeamUsers = (wfTask) => {
        this.setState({
            modalVisible: true,
            selectedWF: wfTask,
            assignedUserName: wfTask.AssignedUser,
        });
        this.props.getTeamUsers({ teamId: wfTask.WorkflowTeamType });
    };

    onFilterSubmit = () => {
        const { formData, isToggled } = this.state;

        var WorkflowRequestFilter = {
            WorkflowId: formData.WorkflowId,
            CustomerName: formData.CustomerName,
            SystemTypeId: formData.SystemTypeId === '0' ? '' : formData.SystemTypeId,
            RoleTypeId: formData.RoleTypeId === '0' ? '' : formData.RoleTypeId,
            ProcessTypeId: formData.ProcessTypeId === '0' ? '' : formData.ProcessTypeId,
            WorkflowStateTypeId:
                formData.WorkflowStateTypeId === '0' ? '' : formData.WorkflowStateTypeId,
            WorkflowTaskStateTypeId:
                formData.WorkflowTaskStateTypeId === '0'
                    ? ''
                    : formData.WorkflowTaskStateTypeId,
            CreatedOn_StartRange: formData.CreatedOn_StartRange,
            CreatedOn_EndRange: formData.CreatedOn_EndRange,
            AssignedUser: formData.AssignedUser,
        };
        this.setState({ loadedFilterRequestData: true, current_page: 1 });
        this.toggle(!isToggled);
        var postdata = {
            WorkflowEngineRequestType: 3,
            WorkflowTaskFilterRequest: {
                UserId: localStorage.getItem('userId'),
                WorkflowTaskOperationType: 3,
                Pager: { From: 0, Size: 10 },
            },
            WorkflowRequestFilter,
        };

        this.props.getWorkflows(postdata);
    };

    onSubmitAssignUser = () => {
        var postdata = {
            userId: localStorage.getItem('userId'),
            assignuserId: this.state.assignedUserName,
            workflowtaskoperationtype: 5,
            taskid: this.state.selectedWF.TaskId,
            workflowid: this.state.selectedWF.WorkflowId,
        };
        this.setState({ modalVisible: null, assignedUserName: null });
        let onSuccessWFTask = {
            ...this.state.selectedWF,
            AssignedUser: this.state.assignedUserName,
        };
        var jsondata = { postdata: postdata, wfdata: onSuccessWFTask };
        this.props.assignUser(jsondata);
    };

    onUnAssign = () => {
        var postdata = {
            workflowtaskoperationtype: 6,
            workflowid: this.state.selectedWF.WorkflowId,
            taskid: this.state.selectedWF.TaskId,
            userid: localStorage.getItem('userId'),
        };
        this.setState({ modalVisible: null });
        let onUnassignedWFTask = {
            ...this.state.selectedWF,
            AssignedUser: '',
        };
        var jsondata = { postdata: postdata, wfdata: onUnassignedWFTask };
        this.props.unAssignUser(jsondata);
    };

    onReset = () => {
        this.setState((prevState, props) => {
            return {
                formData: {},
                isToggled: !prevState.isToggled,
                loadedFilterRequestData: !prevState.loadedFilterRequestData,
                current_page: 1,
            };
        });
        var postdata = {
            WorkflowEngineRequestType: 3,
            WorkflowTaskFilterRequest: {
                UserId: localStorage.getItem('userId'),
                WorkflowTaskOperationType: 3,
                Pager: { From: 0, Size: 10 },
            },
        };
        this.props.getWorkflows(postdata);
    };

    makeHttpRequestWithPage = (pagenumber) => {
        //set current page number & start from pointer
        const { formData } = this.state;
        const userId = localStorage.getItem('userId');
        let from_size = 0,
            to_size = 10;
        this.setState({
            current_page: pagenumber,
        });

        if (pagenumber !== 1) {
            from_size = pagenumber * 10 - 10;
        }

        var postdata = {
            WorkflowEngineRequestType: 3,
            WorkflowTaskFilterRequest: {
                UserId: userId,
                WorkflowTaskOperationType: 3,
                Pager: { From: from_size, Size: to_size },
            },
        };
        if (this.state.loadedFilterRequestData) {
            var WorkflowRequestFilter = {
                WorkflowId: formData.WorkflowId,
                CustomerName: formData.CustomerName,
                SystemTypeId: formData.SystemTypeId === '0' ? '' : formData.SystemTypeId,
                RoleTypeId: formData.RoleTypeId === '0' ? '' : formData.RoleTypeId,
                ProcessTypeId:
                    formData.ProcessTypeId === '0' ? '' : formData.ProcessTypeId,
                WorkflowStateTypeId:
                    formData.WorkflowStateTypeId === '0'
                        ? ''
                        : formData.WorkflowStateTypeId,
                WorkflowTaskStateTypeId:
                    formData.WorkflowTaskStateTypeId === '0'
                        ? ''
                        : formData.WorkflowTaskStateTypeId,
                CreatedOn_StartRange: formData.CreatedOn_StartRange,
                CreatedOn_EndRange: formData.CreatedOn_EndRange,
            };
            postdata['WorkflowRequestFilter'] = WorkflowRequestFilter;
        }
        this.props.getWorkflows(postdata);
    };

    moveNext = () => {
        let lastPageNumber = Math.ceil(this.props.myTasksTotal / 10);
        this.setState(
            {
                current_page: this.state.current_page + 1,
            },
            () => {
                if (this.state.current_page <= lastPageNumber) {
                    this.makeHttpRequestWithPage(this.state.current_page);
                }
            }
        );
    };
    movePrev = () => {
        this.setState(
            {
                current_page: this.state.current_page - 1,
            },
            () => {
                if (this.state.current_page >= 1) {
                    this.makeHttpRequestWithPage(this.state.current_page);
                }
            }
        );
    };

    isEmpty = (data) => {
        let result = false;
        if (typeof data === 'string') {
            return !data || 0 === data.length;
        }
        if (typeof data === 'object') {
            for (var key in data) {
                if (data.hasOwnProperty(key)) return false;
            }
            return true;
        }
        return result;
    };

    setSelected = (value, e) => {
        const { name } = e.target;
        this.setState({ assignedUserName: value });
    };

    _renderNewRequest = () => (
        // <Box
        //     my={4}
        //     mx="30%"
        //     backgroundColor="white"
        //     borderRadius="4px"
        //     borderColor="rgba(0, 0, 0, 0.1)">
        //     <Text
        //         style={{
        //             fontWeight: '500',
        //             fontSize: 20,
        //             color: Colors.lightBlue,
        //             marginTop: 25,
        //             marginBottom: 20,
        //             marginLeft: 20,
        //         }}>
        //         Assign User
        //     </Text>
        //     </Box>
        <iframe
            width="100%"
            height="100%"
            scrolling="yes"
            src="https://forms.monday.com/forms/embed/1fbbf11a25a5ce5745faa8c97a1be6b0?igu=1&gws_rd=ssl"
            sandbox="allow-modals allow-forms allow-popups allow-scripts allow-same-origin"></iframe>
    );

    _renderModalContent = () => (
        <Box
            my={4}
            mx="30%"
            backgroundColor="white"
            borderRadius="4px"
            borderColor="rgba(0, 0, 0, 0.1)">
            <Text
                style={{
                    fontWeight: '500',
                    fontSize: 20,
                    color: Colors.lightBlue,
                    marginTop: 25,
                    marginBottom: 20,
                    marginLeft: 20,
                }}>
                Assign User
            </Text>
            <Box px="0px" pb="22px" justifyContent="center">
                {this.props.fetchingTeamUsers ? (
                    <ActivityIndicator />
                ) : (
                    <Box alignItems="center" justifyContent="center">
                        <UserAssignmentSelector
                            arrayOfData={this.props.teamUsers}
                            name="assignedUser"
                            onSelect={this.setSelected}
                            value={this.state.assignedUserName}
                        />
                    </Box>
                )}
                <Flex
                    justifyEnd
                    alignCenter
                    alignSelf="flex-end"
                    style={{
                        paddingTop: 15,
                        flexDirection: 'row',
                        alignItems: 'center',
                        paddingLeft: 10,
                        paddingRight: 15,
                        marginTop: 20,
                        marginBottom: 10,
                        marginHorizontal: 25,
                    }}>
                    <Button onPress={() => this.onUnAssign()} title="UnAssign" />
                    <Button onPress={() => this.onSubmitAssignUser()} title="Assign" />
                    <Button
                        title="Cancel"
                        onPress={() => this.setState({ modalVisible: null })}
                    />
                </Flex>
            </Box>
        </Box>
    );

    render() {
        const {
            width,
            height,
            marginBottom,
            location,
            myTaskFilters: filters = {},
        } = this.props;
        const { state } = location;
        var bgcolor = this.props.alert.color || '#FFF';
        const { isToggled } = this.state;
        let renderPageNumbers;
        const { mdmSearchResults } = this.state;
        let details = '';
        if (!this.isEmpty(filters)) {
            let {
                WorkflowId,
                SystemTypeId,
                RoleTypeId,
                ProcessTypeId,
                WorkflowStateTypeId,
                CustomerName,
                CreatedOn_StartRange,
                CreatedOn_EndRange,
                WorkflowTaskStateTypeId,
            } = filters;
            if (WorkflowId) details = details + '   WF ID: ' + WorkflowId;
            if (SystemTypeId)
                details = details + '   System: ' + SystemType[SystemTypeId];
            if (RoleType[RoleTypeId])
                details = details + '   Role: ' + RoleType[RoleTypeId];
            if (ProcessTypeId)
                details =
                    details + '   WF Process: ' + WorkflowProcessType[ProcessTypeId];
            if (CustomerName) details = details + '   CustomerName: ' + CustomerName;
            if (WorkflowStateTypeId)
                details =
                    details +
                    '   Workflow Status: ' +
                    WorkflowStateType[WorkflowStateTypeId];
            if (WorkflowTaskStateTypeId)
                details =
                    details +
                    '    Task Status: ' +
                    WorkflowTaskStateType[WorkflowTaskStateTypeId];
            if (CreatedOn_StartRange)
                details = details + '   StartDate: ' + CreatedOn_StartRange.split('T')[0];
            if (CreatedOn_EndRange)
                details = details + '   EndDate: ' + CreatedOn_EndRange.split('T')[0];

            // for (let [key, value] of Object.entries(filters)) {
            //     if (value && !this.isEmpty(value)) {
            //         details = details + key + ' : ' + value + '  ';
            //     }
            // }
        }

        if (this.props.myTasksTotal) {
            let totalpageCnt = Math.ceil(this.props.myTasksTotal / 10);
            const pageNumbers = [];
            for (let i = 1; i <= totalpageCnt; i++) {
                pageNumbers.push(i);
            }
            renderPageNumbers = pageNumbers.map((number) => {
                let classes = this.state.current_page === number ? 'active' : '';
                if (
                    number === 1 ||
                    number === this.props.myTasksTotal ||
                    (number >= this.state.current_page - 2 &&
                        number <= this.state.current_page + 2)
                ) {
                    return (
                        <span
                            key={number}
                            className={classes}
                            onClick={() => this.makeHttpRequestWithPage(number)}>
                            {number}
                        </span>
                    );
                }
            });
        }
        return (
            <View
                style={{
                    backgroundColor: '#EFF3F6',
                    paddingBottom: 75,
                    minHeight: '100vh',
                }}>
                <ScrollView
                    keyboardShouldPersistTaps="always"
                    style={{
                        flex: 1,
                        paddingHorizontal: width < 1440 ? 50 : width * 0.05,
                        paddingBottom: 5,
                    }}>
                    <Modal isVisible={this.state.modalVisible}>
                        {this._renderModalContent()}
                    </Modal>
                    <Modal
                        isVisible={this.state.visibleRequestModal}
                        onBackdropPress={() =>
                            this.setState({ visibleRequestModal: false })
                        }>
                        {this._renderNewRequest()}
                    </Modal>
                    <FlashMessages
                        toasts={this.props.toasts}
                        onDismiss={this.props.removeMessage}
                    />
                    {this.props.alert.display && (
                        <FlashMessage
                            bg={{ backgroundColor: bgcolor }}
                            message={this.props.alert.message}
                        />
                    )}
                    <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="space-between">
                        <Text
                            style={{
                                fontWeight: '500',
                                fontSize: 24,
                                color: Colors.lightBlue,
                                marginTop: 25,
                                marginBottom: 20,
                            }}>
                            My Tasks
                        </Text>
                        <Box mt={3} pr={3}>
                            <TouchableOpacity
                                hitSlop={{
                                    top: 10,
                                    bottom: 10,
                                    left: 0,
                                    right: 0,
                                }}
                                onPress={() => this.toggle(true)}
                                style={{
                                    flexDirection: 'row-reverse',
                                    alignItems: 'flex-end',
                                }}>
                                <label>
                                    <AntDesign
                                        name="filter"
                                        size={30}
                                        color="#11307D"
                                        label="as"
                                    />
                                    <StyledText style={{ color: '#11307D' }}>
                                        More Filters
                                    </StyledText>
                                </label>
                            </TouchableOpacity>
                        </Box>
                    </Box>
                    <Box>
                        <OverflowRightContext.Provider value={this.state}>
                            <View style={{ zIndex: 1, marginTop: '-58px' }}>
                                <OverflowRight
                                    title="FILTER OPTIONS"
                                    content={
                                        <RequestFilterFields
                                            onFieldChange={this.onFieldChange}
                                            formData={this.state.formData}
                                            onSubmit={this.onFilterSubmit}
                                            onReset={this.onReset}>
                                            <FormInput
                                                label="Assigned Username"
                                                name="AssignedUser"
                                                placeholder="Firstname.Lastname"
                                                small
                                                value={this.state.formData.AssignedUser}
                                                inline={false}
                                                readOnly={false}
                                                onChange={this.onFieldChange}
                                            />
                                        </RequestFilterFields>
                                    }
                                    onMenuDismiss={() => this.toggle(false)}
                                    style={{
                                        position: 'absolute',
                                        zIndex: 1,
                                    }}
                                    isToggled={isToggled}
                                    containerWidth="50%"
                                />
                            </View>
                        </OverflowRightContext.Provider>
                    </Box>
                    {!this.isEmpty(filters) && details.length > 0 && (
                        <StyledText style={{ color: '#11307D', textAlign: 'inherit' }}>
                            Filters Applied: {details}
                        </StyledText>
                    )}

                    {this.props.assigningUser && (
                        <View
                            style={{
                                position: 'absolute',
                                left: 0,
                                right: 0,
                                bottom: 0,
                                top: 0,
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                            <ActivityIndicator size="large" />
                        </View>
                    )}

                    <WorkFlowsTable
                        loading={this.props.fetching}
                        tableHead={this.state.tableHead}
                        workflowTasks={this.props.workflows || []}
                        fetchTeamUsers={this.fetchTeamUsers}
                        staticData={this.props.staticData}
                    />

                    <View
                        style={{
                            flex: 1,
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                        }}>
                        <TouchableOpacity
                            onPress={() => this.setState({ visibleRequestModal: true })}>
                            <View
                                style={{
                                    backgroundColor: '#10206c',
                                    padding: 12,
                                    margin: 16,
                                    top: 10,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: 4,
                                }}>
                                <Text style={{ color: 'white' }}>
                                    Enhancement Requests
                                </Text>
                            </View>
                        </TouchableOpacity>
                        <View
                            style={{
                                zIndex: -1,
                            }}
                            className="pagination">
                            <span onClick={() => this.movePrev()}>&laquo;</span>
                            {renderPageNumbers}
                            <span onClick={() => this.moveNext()}>&raquo;</span>
                        </View>
                    </View>
                </ScrollView>
            </View>
        );
    }
}

class Default extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {}

    render() {
        const props = this.props;

        return (
            <DimensionAware
                render={(dimensions) => (
                    <Page
                        {...{
                            ...props,
                            width: getWindowWidth(dimensions),
                            height: getWindowHeight(dimensions),
                            marginBottom: 25,
                        }}
                    />
                )}
            />
        );
    }
}

const mapStateToProps = ({ workflows, toasts, staticData }) => {
    const {
        myTaskData,
        myTaskFilters,
        teamUsers,
        fetchingTeamUsers,
        fetching,
        assigningUser,
        alert,
        myTasksTotal,
    } = workflows;

    const { data = {}, fetching: staticFetching } = staticData;

    return {
        workflows: myTaskData,
        myTaskFilters,
        teamUsers,
        fetchingTeamUsers,
        fetching: fetching || staticFetching,
        assigningUser,
        alert,
        toasts,
        myTasksTotal: myTasksTotal || 0,
        staticData: data
            ? Object.fromEntries(
                  Object.entries(data).map(([key, value]) => [key, mapKeys(value, 'Id')])
              )
            : {},
    };
};

export default connect(mapStateToProps, {
    getWorkflows,
    getTeamUsers,
    assignUser,
    unAssignUser,
    removeMessage,
    getStaticRequest,
})(Default);
