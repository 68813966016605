import React, { Component, useState } from 'react';
import { Animated, Easing, Image, View, StyleSheet } from 'react-native';
import { ScrollView } from 'react-native-web';
import debounce from 'debounce';
import { connect } from 'react-redux';
import shallowCompare from 'react-addons-shallow-compare'; // ES6
import { Container, Flex, Text } from '../components/common';
import { Link } from '../navigation/router';
import { SearchBar, SearchResults } from '../components/search';
const { spring } = Animated;
import {
    searchCustomer,
    searchCustomerSuccess,
    advanceSearchCustomer,
} from '../appRedux/actions/Customer';
import { MenuContext } from '../Root';

const userId = localStorage.getItem('userId');

export class Page extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        const opacity = new Animated.Value(0);
        const translateY = opacity.interpolate({
            inputRange: [0, 1],
            outputRange: [200, 0],
        });

        this.state = {
            customerdata: [],
            searchResult: [],
            isFocused: false,
            animation: { posY: translateY, opacity },
            TypeaheadKeyword: '',
        };

        this.config = {
            delay: 75,
            duration: 400,
            toValue: 1,
            easing: Easing.bounce(),
        };

        this.anim = spring(this.state.animation.opacity, this.config);
    }

    shouldComponentUpdate(nextProps, nextState) {
        return shallowCompare(this, nextProps, nextState);
    }

    componentDidMount() {
        // if (!this.props.isToggled) this.props.toggleMenu(true);

        this._isMounted = true;
        this.anim.start();
    }
    componentWillReceiveProps(newProps) {
        if (
            newProps.customerdata != this.props.customerdata &&
            this.state.TypeaheadKeyword.length != 0
        ) {
            this.setState({ customerdata: newProps.customerdata });
        }
        if (
            newProps.searchResult != this.props.searchResult &&
            this.state.TypeaheadKeyword.length != 0
        ) {
            this.setState({ searchResult: newProps.searchResult });
        }
    }

    onSubmit = (event) => {
        if (event.nativeEvent.key === 'Enter' && event.target.value.length >= 3) {
            //when enter key pressed , redirect to search results page when 2 or more results found
            // if (this.state.customerdata.length === 1) {
            //     let customer = this.props.customerdata[0];
            //     console.log('singlecust', customer);
            //     this.props.history.push({
            //         pathname: `/search-results/${customer.MdmNumber}`,
            //         state: customer,
            //     });
            //     this.setState({ customerdata: [], searchResult: [] });
            //     // this.props.searchCustomerSuccess([]);
            // } else {
            var postdata = {
                CustomerSearchType: 5,
                WorkflowSearchHits: {
                    from: 0,
                    size: 10,
                },
                CustomerMasterSearchHits: {
                    from: 0,
                    size: 10,
                },
                userId: userId,
                TypeaheadKeyword: event.target.value,
            };
            this.setState(
                { customerdata: [], searchResult: [] },
                () => this.props.advanceSearchCustomer(postdata, this.props.history) //this redirects to search result page
            );
            // }
        }
    };

    handleOnChange = debounce((e, text) => {
        this.setState({ TypeaheadKeyword: e.trim() });
        if (e.trim().length >= 3) {
            var postdata = {
                CustomerSearchType: 5,
                CustomerMasterSearchHits: {
                    from: 0,
                    size: 10,
                },
                userId: userId,
                TypeaheadKeyword: e.trim().length <= 0 ? null : e.trim(),
            };
            this.props.searchCustomer(postdata);
        }
        if (e.trim().length >= 0 && e.trim().length <= 2)
            this.setState({ customerdata: [], searchResult: [] });
    }, 400);

    handleOnBlur = debounce(() => {
        if (this._isMounted) this.setState({ isFocused: false });
        // this.props.searchCustomerSuccess([]);
        // this.setState({ customerdata: [], searchResult: []  });
    }, 200);

    render() {
        const { searchResult, customerdata, isFocused } = this.state;
        return (
            <Container full fullVertical>
                <Flex
                    alignCenter
                    style={{
                        flex: 1,
                        flexDirection: 'column',
                        marginTop: '20vh',
                        bottom: 0,
                    }}>
                    <Animated.View
                        style={{
                            transform: [
                                {
                                    translateY: this.state.animation.posY,
                                },
                            ],
                            opacity: this.state.animation.opacity.value,
                        }}>
                        <View style={styles.linkContainer}>
                            <Link to="/advance-search">
                                <Text
                                    style={styles.linkText}
                                    fontWeight="medium"
                                    fontSize="12">
                                    Advanced Search
                                </Text>
                            </Link>
                        </View>
                        <View>
                            <SearchBar
                                onFocus={() => this.setState({ isFocused: true })}
                                onBlur={this.handleOnBlur}
                                onSubmit={this.onSubmit}
                                onQuery={this.handleOnChange}
                                isQuerying={this.props.fetching}
                            />
                        </View>
                    </Animated.View>
                    <View>
                        {customerdata && customerdata.length > 0 && isFocused && (
                            <View style={{ maxHeight: '320px' }}>
                                <ScrollView
                                    keyboardShouldPersistTaps
                                    style={{
                                        minWidth: '600px',
                                        maxWidth: '650px',
                                        backgroundColor: '#FFFFFF',
                                    }}>
                                    <SearchResults
                                        searchResult={searchResult}
                                        customers={customerdata}
                                    />
                                </ScrollView>
                                <View
                                    style={{
                                        flex: 1,
                                        flexBasis: 'auto',
                                        minWidth: '650px',
                                        width: '100%',
                                        height: '1%',
                                        flexDirection: 'column',
                                        borderTopWidth: 0,
                                        borderLeftWidth: 2,
                                        borderRightWidth: 2,
                                        borderBottomWidth: 2,
                                        borderColor: '#8ea1c4',
                                        backgroundColor: '#F3F9FA',
                                    }}
                                />
                            </View>
                        )}
                    </View>
                </Flex>
            </Container>
        );
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
}

class SearchPage extends Component {
    render() {
        return (
            <MenuContext.Consumer>
                {({ isToggled, toggleMenu }) => {
                    return (
                        <Page
                            {...this.props}
                            toggleMenu={toggleMenu}
                            isToggled={isToggled}
                        />
                    );
                }}
            </MenuContext.Consumer>
        );
    }
}

const styles = StyleSheet.create({
    linkContainer: {
        flex: 1,
        position: 'relative',
        right: '-580px',
        marginBottom: 16,
        width: '20%',
    },
    linkText: {
        textAlign: 'right',
        color: '#4195C7',
        paddingHorizontal: 1,
        borderBottomWidth: 1,
        borderBottomColor: '#4195C7',
        borderBottomStyle: 'solid',
    },
});

const mapStateToProps = ({ customer }) => {
    const {
        fetching,
        typeaheadcustomerdata: customerdata,
        typeaheadsearchResult: searchResult,
    } = customer;
    return { searchResult, customerdata, fetching };
};

export default connect(mapStateToProps, {
    searchCustomer,
    searchCustomerSuccess,
    advanceSearchCustomer,
})(SearchPage);
