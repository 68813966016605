import { Auth } from 'aws-amplify';
import axios from 'axios';
import * as delve from 'delve';
import {
    MdmCreateCustomer,
    addDocument,
    advanceSearchCustomers,
    blockRequest,
    deltaUpdate,
    downloadDocument,
    extendSalesOrg,
    fetchDunsFields,
    fetchMdmParentDataDuns,
    fetchMyRequests,
    getFunctionalGroupDetails,
    getMyTasks,
    getSAPCustomerDetails,
    getStatusBarDetails,
    getTaxJurisdiction,
    getUserTeam,
    mdmMappingMatrix,
    releaseCheckList,
    extendReleaseCheckList,
    saveApolloContracts,
    saveApolloCredit,
    saveApolloCustMaster,
    saveApolloCustomerGlobal,
    saveApolloGlobalTrade,
    saveApolloPricing,
    saveApolloUpdateContracts,
    saveApolloUpdateContractsExtendSalesOrg,
    saveApolloUpdateCredit,
    saveApolloUpdateCreditExtendSalesOrg,
    saveApolloUpdateCustMaster,
    saveApolloUpdateCustMasterExtendSalesOrg,
    saveApolloUpdateGlobalTrade,
    saveApolloUpdateGlobalTradeExtendSalesOrg,
    saveApolloUpdatePricing,
    saveApolloUpdatePricingExtendSalesOrg,
    saveBlockCustomerMaser,
    searchCustomers,
    withdrawRequest,
    CreateOtherCustomerApi,
    SaveTaskOtherCustomerMasterApi,
    getMyTeamUsers,
    assignUserToTask,
    unAssignUserFromTask,
    getStaticData,
    fetchPartners,
    fetchApolloPartners,
    createApolloPartners,
    fetchwfApolloPartners,
    searchApolloPartner,
    approveRejectApolloPartner,
    fetchOlympusPartners,
    fetchwfOlymmpusPartners,
    searchOlympusPartner,
    searchcustomernumberfilter,
    getJdePartners,
    searchJDEPartners,
    GetJdePartnerWorkflowData,
} from '@env';

// export const axiosRequestInterceptor = async (config) => {
//     console.log(config);
//     const session = await Auth.currentSession();
//
//     const token = delve(session, 'idToken.jwtToken');
//     if (token) {
//         config.headers.Authorization = token;
//     }
//     return config;
// };
// axios.interceptors.request.use(axiosRequestInterceptor, (e) => Promise.reject(e));

export const customerMasterUrldomain =
    'https://cors-anywhere.herokuapp.com/https://oaa4qq34j6.execute-api.us-east-2.amazonaws.com/dev';

export const endpoints = {
    MdmCreateCustomer,
    addDocument,
    advanceSearchCustomers,
    blockRequest,
    deltaUpdate,
    downloadDocument,
    extendSalesOrg,
    fetchDunsFields,
    fetchMdmParentDataDuns,
    fetchMyRequests,
    getFunctionalGroupDetails,
    getMyTasks,
    getSAPCustomerDetails,
    getStatusBarDetails,
    getTaxJurisdiction,
    getUserTeam,
    mdmMappingMatrix,
    releaseCheckList,
    extendReleaseCheckList,
    saveApolloContracts,
    saveApolloCredit,
    saveApolloCustMaster,
    saveApolloCustomerGlobal,
    saveApolloGlobalTrade,
    saveApolloPricing,
    saveApolloUpdateContracts,
    saveApolloUpdateContractsExtendSalesOrg,
    saveApolloUpdateCredit,
    saveApolloUpdateCreditExtendSalesOrg,
    saveApolloUpdateCustMaster,
    saveApolloUpdateCustMasterExtendSalesOrg,
    saveApolloUpdateGlobalTrade,
    saveApolloUpdateGlobalTradeExtendSalesOrg,
    saveApolloUpdatePricing,
    saveApolloUpdatePricingExtendSalesOrg,
    saveBlockCustomerMaser,
    searchCustomers,
    withdrawRequest,
    CreateOtherCustomerApi,
    SaveTaskOtherCustomerMasterApi,
    getMyTeamUsers,
    assignUserToTask,
    unAssignUserFromTask,
    getStaticData,
    fetchPartners,
    fetchApolloPartners,
    createApolloPartners,
    fetchwfApolloPartners,
    searchApolloPartner,
    approveRejectApolloPartner,
    fetchOlympusPartners,
    fetchwfOlymmpusPartners,
    searchOlympusPartner,
    searchcustomernumberfilter,
    getJdePartners,
    searchJDEPartners,
    GetJdePartnerWorkflowData,
};

export const headerParams = {
    Authorization: localStorage.getItem('accessToken'),
};

export const filePartParams = {
    'Content-Type': 'multipart/form-data',
};

export const ajaxGetRequest = async (url) => {
    const userSession = await Auth.currentSession();
    // const userInfo = await Auth.currentUserInfo()
    return await axios
        .get(url, { headers: { Authorization: userSession.idToken.jwtToken } })
        .then((data) => data.data)
        .catch((error) => error);
};

export const ajaxPostRequest = async (url, data, passUserId = false) => {
    const userSession = await Auth.currentSession();
    // const userInfo = await Auth.currentUserInfo()
    let body = data;
    // console.log('jwt', userSession.idToken.jwtToken);
    return await axios
        .post(url, body, {
            headers: { Authorization: userSession.idToken.jwtToken },
        })
        .then((data) => data.data)
        .catch((error) => error);
};

export const ajaxPutFileRequest = async (url, data) => {
    // const userInfo = await Auth.currentUserInfo()
    return await axios
        .put(url, data, { headers: filePartParams })
        .then((data) => data.data)
        .catch((error) => error);
};

export const capitalize = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
};

export const formatRequestorName = (name) => {
    if (name === undefined || name === null || name.length <= 0) return;

    const fullName = name.split('.');
    var username =
        capitalize(fullName[0]) + (fullName[1] ? ' ' + capitalize(fullName[1]) : '');
    return username;
};

export const getMockSearchResult = async () =>
    new Promise((resolve, reject) => {
        resolve({
            WorkflowCustomerSearchResults: [
                {
                    WorkflowId: 'wf0098379',
                    WorkflowType: 1,
                    WorkflowTitle: 'My Workflow',
                    CustomerName: 'Cardinal Health',
                    WorkflowDateCreated: '2020-01-13T21:00:26.724359-06:00',
                    WorkflowStatusType: 1,
                },
                {
                    WorkflowId: 'wf234243',
                    WorkflowType: 2,
                    WorkflowTitle: 'My Workflow 2',
                    CustomerName: 'Cardinal Health 2',
                    WorkflowDateCreated: '2020-01-13T21:00:26.7415697-06:00',
                    WorkflowStatusType: 2,
                },
            ],
            MdmSearchResults: [
                {
                    MdmCustomerNumber: 'mdm233247',
                    CustomerName: 'Cardinal Health',
                    Street: 'Belmont',
                    City: 'Chicago',
                    State: 'IL',
                    ZipCode: '60657',
                    Country: 'USA',
                    DunsNumber: '878234h',
                    Role: 'SAP Apollo: Sold To (0001)',
                    SystemName: 'SAP Apollo',
                },
                {
                    MdmCustomerNumber: 'mdm23234',
                    CustomerName: 'Cardinal Health2',
                    Street: 'Monroe',
                    City: 'Chicago',
                    State: 'IL',
                    ZipCode: '60072',
                    Country: 'USA',
                    DunsNumber: '878234h',
                    Role: 'SAP Apollo: Sold To (0001)',
                    SystemName: 'SAP Apollo',
                },
            ],
        });
    });

export const normalize = (arr) => {
    const reducer = (accumulator, currentValue) => {
        accumulator[currentValue.Name] = currentValue;
        return accumulator;
    };
    return arr.reduce(reducer, {});
};

export const getMockUpdateTaskDetail = async () => {
    const data = {
        OperationName: 'GetCustomerWithDeltas',
        IsSuccess: true,
        OperationResultMessages: [],
        ResultData: {
            CustomerData: {
                SystemType: 1,
                Title: '',
                Name1: 'Dell Test3301',
                Name2: '',
                Name3: '',
                Name4: '',
                Street: '1 Dell Way',
                Street2: '',
                City: 'Round Rock',
                Region: 'TX',
                Postalcode: '78682',
                Country: 'US',
                Telephone: '',
                Fax: '',
                Email: '',
                Taxnumber: '74-2487834',
                VatRegNo: '',
                SicCode4: '3341',
                SicCode6: '334118',
                SicCode8: '',
                NaicsCode: '',
                DunsNumber: '114315195',
                License: '123',
                LicenseExpDate: '2020-03-28',
                SearchTerm1: '',
                SearchTerm2: '',
                DistributionChannel: '',
                Division: '',
                TransporationZone: '',
                TaxNumber2: '',
                SortKey: '009',
                PaymentHistoryRecord: 'F',
                PaymentMethods: 'c',
                AcctgClerk: '01',
                AccountStatement: '2',
                OrderCombination: '',
                IncoTerms2: '',
                TaxClassification: '1',
                TaxJurisdiction: '4449134400',
                CreditLimit: '0',
                CredInfoNumber: '',
                LastExtReview: '0000-00-00',
                Rating: '',
                PaymentIndex: '',
                ContactFirstName: 'Dell Test3301',
                ContactLastName: '',
                ContactPhone: '',
                ContactFax: '',
                ContactEmail: '',
                RoleTypeId: 0,
                SalesOrgTypeId: 0,
                CustomerClassTypeId: 0,
                IndustryCodeTypeId: 0,
                CompanyCodeTypeId: 2,
                IndustryTypeId: 0,
                ReconAccountTypeId: 2,
                SalesOfficeTypeId: 0,
                CustomerGroupTypeId: 0,
                PPCustProcTypeId: 0,
                CustomerPriceProcTypeId: 0,
                PriceListTypeId: 1,
                DeliveryPriorityTypeId: 0,
                IncoTerms1TypeId: 0,
                AcctAssignmentGroupTypeId: 0,
                AccountTypeId: 3,
                ShippingCustomerTypeId: 0,
                PaymentTermsTypeId: 0,
                CreditRepGroupTypeId: 0,
                RiskCategoryTypeId: 0,
                ShippingConditionsTypeId: 0,
            },
            Deltas: [
                {
                    Name: 'SicCode4',
                    OriginalValue: '3341',
                    UpdatedValue: '3341324234',
                },
            ],
            CreateWorkflowId: null,
        },
    };

    const payload = {
        ...data.ResultData,
        Deltas: normalize(data.ResultData.Deltas),
    };

    return payload;
};

export const GetMockWorkflowTaskStatus = async () => {
    const data = {
        OperationName: 'ProcessGetWorkflowTaskStatusData',
        IsSuccess: false,
        OperationResultMessages: [
            { Message: 'No workflow tasks found', OperationalResultType: 3 },
        ],
        ResultData: {
            WorkflowId: 'TEST',
            TaskId: 115,
            UserId: 'abdullahi.mahamed',
            WorkflowTaskStatus: null,
        },
    };

    return data.ResultData;
};
