/**
 * @prettier
 */

import { omit, pick } from '@styled-system/props';
import React from 'react';
import { Text, TextInput, Label, DatePicker } from '../common';
import Wrapper from './Wrapper';
import { Box } from '../common/Box';
import { FieldValue as TextValue } from '../common/TextInput';
import Indicator from '../Indicator';

function FormInput({
    rightComponent: RightComponent,
    name,
    maxLength,
    type,
    small,
    hide,
    inputWidth,
    placeholder,
    labelColor,
    delta,
    disabledPadding = false,
    onChange,
    value,
    error,
    colon = true,
    children,
    variant,
    multiline,
    numberOfLines,
    readOnly,
    getValue = null,
    whiteSpace,
    inline,
    onBlur,
    disabled,
    label,
    upperCase,
    onPress,
    flexDirection,
    alignItems,
    containerWidth,
    sfdcdisabled,
    ...rest
}) {
    const isMultiLine = multiline && variant === 'outline';
    let wrapperProps = {
        display: hide ? `none` : inline && 'flex',
        ...pick(rest),
        width: '100%',
        flexDirection: !isMultiLine && inline ? 'row' : 'column',
        alignItems: !isMultiLine && inline && 'center',
    };
    if (containerWidth !== undefined && containerWidth !== null) {
        if (flexDirection !== null && flexDirection !== undefined)
            wrapperProps.flexDirection = flexDirection;
        if (alignItems !== null && alignItems !== undefined)
            wrapperProps.alignItems = alignItems;
    }
    const inputProps = omit(rest);

    if (getValue !== null) {
        const get = getValue(name);
        if (get) inputProps.value = get;
    }

    let rows = numberOfLines;

    inputProps.style = {
        ...(delta && {
            borderBottomColor: '#EA0029',
            borderBottomWidth: '2px',
            resize: null,
        }),
    };

    if (isMultiLine) {
        inputProps.as = 'p';
        if (getValue !== null) {
            const get = getValue(name);
            if (get) inputProps.children = get;
        } else {
            inputProps.children = value || '';
        }
        inputProps.style = {
            ...inputProps.style,
            padding: 0,
            marginTop: 8,
            borderWidth: 0,
            borderBottomWidth: 'thin',
            borderColor: '#6e6e6e',
            borderStyle: 'solid',
            wordBreak: 'break-word',
        };
    }

    let labelText = label && inline && colon ? `${label}:` : label;

    return (
        <Wrapper {...wrapperProps} upperCase={upperCase} py={inline ? '4px' : '8px'}>
            {delta && (
                <Box ml={delta && '-14px'}>
                    <Indicator />
                </Box>
            )}
            <Label
                small={small}
                width="60%"
                mt={colon === false && '5px'}
                htmlFor={name}
                disabled={disabled}
                inline={`${inline}`}
                variant={variant}>
                {labelText}
                {!inline && (rest.required || rest.showRequired) ? (
                    <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        pl={error && '2px'}>
                        <Text
                            color="red"
                            fontWeight="500"
                            fontFamily="Poppins"
                            fontSize="19px">
                            {'*'}
                        </Text>
                        {error && (
                            <Text color="red" fontWeight="400" fontSize="14px">
                                {error}
                            </Text>
                        )}
                    </Box>
                ) : inline && rest.showInlineRequired ? (
                    <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        pl={error && '2px'}>
                        <Text
                            color="red"
                            fontWeight="500"
                            fontFamily="Poppins"
                            fontSize="19px">
                            {'*'}
                        </Text>
                        {error && (
                            <Text color="red" fontWeight="400" fontSize="14px">
                                {error}
                            </Text>
                        )}
                    </Box>
                ) : (
                    <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        pl={error && '2px'}>
                        {error && (
                            <Text color="red" fontWeight="400" fontSize="14px">
                                {error}
                            </Text>
                        )}
                    </Box>
                )}
            </Label>
            <Box
                width={
                    containerWidth !== undefined && containerWidth !== null
                        ? containerWidth
                        : inputWidth
                        ? inputWidth
                        : (!isMultiLine && readOnly) || (!isMultiLine && inline)
                        ? '60%'
                        : 'inherit'
                }
                display={RightComponent ? 'flex' : null}
                flexDirection={RightComponent && 'row'}
                alignItems={RightComponent && 'center'}>
                {type === 'date' ? (
                    <DatePicker
                        variant={variant}
                        type={type}
                        small={small}
                        disabled={variant === 'outline'}
                        name={name}
                        placeholder={placeholder}
                        onChange={onChange && ((e) => onChange(e.target.value, e))}
                        defaultValue={value}
                        {...inputProps}
                    />
                ) : (
                    <TextInput
                        {...(inputWidth && { width: inputWidth })}
                        whiteSpace={whiteSpace}
                        disabledPadding={disabledPadding}
                        small={small}
                        spellCheck={false}
                        autoComplete={name}
                        autoCorrect={false}
                        editable={!readOnly}
                        variant={variant}
                        type={type}
                        disabled={variant === 'outline' || disabled}
                        sfdcdisabled={sfdcdisabled}
                        multiline={multiline}
                        error={error}
                        numberOfLines={numberOfLines}
                        name={name}
                        stringLength={value ? value.length : 0}
                        maxLength={maxLength}
                        placeholder={placeholder}
                        onChange={onChange && ((e) => onChange(e.target.value, e))}
                        onBlur={onBlur && ((e) => onBlur(e.target.value, e))}
                        value={value || ''}
                        onClick={onPress}
                        {...inputProps}
                    />
                )}
                {RightComponent && <RightComponent />}
            </Box>
        </Wrapper>
    );
}

export function FieldLabel({
    rightComponent: RightComponent,
    inline = true,
    label,
    colon = true,
    disabled,
    error,
    ...rest
}) {
    const wrapperProps = {
        display: inline ? 'flex' : null,
        ...pick(rest),
        width: '100%',
        flexDirection: inline ? 'row' : 'column',
        alignItems: inline && 'center',
    };

    const labelText = label && inline && colon ? `${label}:` : label;

    return (
        <Label
            {...wrapperProps}
            width="60%"
            mt={colon === false && '5px'}
            htmlFor={name}
            disabled={disabled}
            inline={`${inline}`}
            variant="outline">
            {labelText}
            {!inline && (rest.required || rest.showRequired) ? (
                <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    pl={error && '2px'}>
                    <Text
                        color="red"
                        fontWeight="500"
                        fontFamily="Poppins"
                        fontSize="19px">
                        {'*'}
                    </Text>
                    {error && (
                        <Text color="red" fontWeight="400" fontSize="14px">
                            {error}
                        </Text>
                    )}
                </Box>
            ) : (
                <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    pl={error && '2px'}>
                    {error && (
                        <Text color="red" fontWeight="400" fontSize="14px">
                            {error}
                        </Text>
                    )}
                </Box>
            )}
        </Label>
    );
}

export function FieldValue({
    rightComponent: RightComponent,
    name,
    error,
    children,
    color,
    padding,
    style,
    multiline,
    inputWidth,
    containerWidth,
    ...rest
}) {
    const wrapperProps = {
        ...pick(rest),
    };

    const inputProps = omit(rest);

    return (
        <Box
            display={RightComponent ? 'flex' : null}
            flexDirection={(RightComponent || multiline) && 'row'}
            alignItems={(RightComponent || multiline) && 'center'}
            width={containerWidth}>
            <TextValue
                spellCheck={false}
                error={error}
                {...inputProps}
                padding={padding}
                style={style}
                color={color}
                width={inputWidth}>
                {children}
            </TextValue>
            {RightComponent && <RightComponent />}
        </Box>
    );
}

export function Field({
    rightComponent: RightComponent,
    upperCase,
    inline,
    name,
    error,
    colon = true,
    children,
    alignItems,
    ...rest
}) {
    const wrapperProps = {
        display: inline ? 'flex' : null,
        ...pick(rest),
        width: '100%',
        flexDirection: inline ? 'row' : 'column',
        alignItems: alignItems || (inline && 'center'),
    };

    return (
        <Wrapper {...wrapperProps} upperCase={upperCase} py={inline ? '4px' : '8px'}>
            {children}
        </Wrapper>
    );
}

export default FormInput;
