import { AntDesign } from '@expo/vector-icons';
import React from 'react';
import {
    Image,
    Platform,
    StyleSheet,
    Text,
    TouchableOpacity,
    View,
    Dimensions,
    PixelRatio,
    Animated,
    Easing,
} from 'react-native';
import {
    DimensionAware,
    getWindowWidth,
    getWindowHeight,
} from 'react-native-dimension-aware';
import { TouchableWithoutFeedback } from 'react-native-web';
import { Link } from 'react-router-dom';

import { OverflowRightContext } from '../containers/SearchResult/Apollo/MdmDetailScreen';
import { Box } from './common';
import useAnimation from './useAnimation';

const CloseSlider = ({ children, onPress, title }) => (
    <>
        <View style={styles.menuHeaderContainer}>
            <TouchableOpacity
                onPress={onPress}
                hitSlop={{
                    top: 50,
                    bottom: 50,
                    left: 0,
                    right: 0,
                }}>
                <AntDesign name="arrowright" size={38} color="#10206C">
                    <Text style={styles.containerTitle}>{title}</Text>
                </AntDesign>
            </TouchableOpacity>
        </View>
        {children}
    </>
);

export function MenuContent({
    width: windowwidth,
    isToggled,
    toggleOverflow,
    onMenuDismiss,
    content,
    title,
    containerWidth,
}) {
    return (
        <TouchableWithoutFeedback
            style={{ cursor: 'none' }}
            onPress={() => toggleOverflow(false)}
            pointerEvents={!isToggled ? 'none' : 'box-none'}>
            <View
                style={[styles.overlay, { width: windowwidth }]}
                pointerEvents={!isToggled ? 'none' : 'box-none'}>
                <View>
                    <View style={{ flexDirection: 'row-reverse' }}>
                        <View
                            style={[
                                styles.menuContainer,
                                { width: containerWidth ? containerWidth : '60%' },
                                windowwidth <= 892 && {
                                    zIndex: -1,
                                    position: 'absolute',
                                    width: 245,
                                },
                            ]}>
                            <View style={{ flex: 1 }} keyboardShouldPersistTaps="always">
                                <CloseSlider title={title} onPress={onMenuDismiss} />
                                {isToggled && (
                                    <TouchableWithoutFeedback
                                        style={{ cursor: 'none' }}
                                        pointerEvents={!isToggled ? 'none' : 'box-none'}>
                                        <Box
                                            style={{
                                                width: '100%',
                                            }}>
                                            {content}
                                        </Box>
                                    </TouchableWithoutFeedback>
                                )}
                            </View>
                        </View>
                    </View>
                </View>
            </View>
        </TouchableWithoutFeedback>
    );
}

const AnimatedComponent = ({ doAnimation, children }) => {
    const animation = useAnimation({
        doAnimation,
        duration: 150,
        easing: Easing.ease,
        type: 'timing',
    });
    return (
        <Animated.View
            pointerEvents={doAnimation ? 'auto' : 'box-none'}
            style={{
                zIndex: 3,
                position: 'absolute',
                transform: [
                    {
                        translateX: animation.interpolate({
                            inputRange: [0, 1],
                            outputRange: [200, 0],
                        }),
                    },
                ],
                opacity: animation,
            }}>
            {children}
        </Animated.View>
    );
};

class OverflowRight extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <OverflowRightContext.Consumer>
                {({ isToggled, toggleOverflow }) => {
                    return (
                        <AnimatedComponent doAnimation={this.props.isToggled}>
                            <DimensionAware
                                render={(dimensions) => (
                                    <MenuContent
                                        {...{
                                            ...this.props,
                                            toggleOverflow,
                                            isToggled: this.props.isToggled,
                                            width: getWindowWidth(dimensions),
                                            height: getWindowHeight(dimensions),
                                        }}
                                    />
                                )}
                            />
                        </AnimatedComponent>
                    );
                }}
            </OverflowRightContext.Consumer>
        );
    }
}

export default OverflowRight;

const { width: SCREEN_WIDTH, height: SCREEN_HEIGHT } = Dimensions.get('window');

// based on iphone 5s's scale
const scale = SCREEN_WIDTH / 320;

export function normalize(size) {
    const newSize = size * scale;
    if (Platform.OS === 'ios') {
        return Math.round(PixelRatio.roundToNearestPixel(newSize));
    }

    return Math.round(PixelRatio.roundToNearestPixel(newSize)) - 2;
}

const styles = StyleSheet.create({
    bold: { color: '#10254D', fontFamily: 'Poppins', fontWeight: '700' },
    logoContainer: {
        padding: 10,
        flexDirection: 'row',
        alignItems: 'center',
        borderBottomColor: '#cfcfcf',
        borderBottomWidth: 1,
    },
    overlay: {
        zIndex: 1,
    },
    menuContainer: {
        height: '100%',
        backgroundColor: '#FFFFFF',
        paddingBottom: 20,
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.23,
        shadowRadius: 2.62,
        elevation: 4,
    },
    menuItem: {
        flexDirection: 'row',
        alignItems: 'center',
        borderBottomColor: '#CCE7F2',
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        paddingBottom: 16,
        paddingRight: 15,
        marginTop: 16,
        marginHorizontal: 32,
    },
    containerTitle: {
        fontSize: 16,
        color: '#10206C',
        fontFamily: 'Poppins',
        fontWeight: '700',
    },
    menuItemText: {
        fontSize: 16,
        color: '#10254D',
        fontFamily: 'Poppins',
        fontWeight: '400',
    },
    menuHeaderContainer: {
        paddingLeft: 32,
        backgroundColor: '#D0E8F2',
        paddingVertical: 12,
    },
    TableHeaderContainer: {
        paddingLeft: 32,
        backgroundColor: '#10206C',
        paddingVertical: 12,
    },
    menuItemsHeader: {
        color: 'white',
        fontFamily: 'Poppins',
        fontSize: 17,
    },
    menuItemIcon: {
        width: 35,
        height: 35,
        paddingRight: 5,
    },
    selectedStreamListItem: {
        borderLeftColor: '#6ED2F6',
        borderLeftWidth: 3,
        borderRadius: 2,
    },
    selectedStreamListItemText: {
        color: '#E57230',
    },
});
