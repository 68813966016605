import styled from 'styled-components/native';

const StyledText = styled.Text`
    font-size: 14px;
    color: #ffffff;
    text-align: center;
    padding-top: 1px;
    font-family: Poppins;
    font-weight: 400;
`;

export default StyledText;
