import React from 'react';
import { ScrollView, View, StyleSheet, TouchableOpacity } from 'react-native';
import {
    DimensionAware,
    getWindowHeight,
    getWindowWidth,
} from 'react-native-dimension-aware';
import { connect } from 'react-redux';
import { Colors, StyledText } from '../../../../theme/Index';
import { Flex, Button, Box, Text } from '../../../../components/common';
import { FormInput } from '../../../../components/form';
import { saveApolloUpdateMyTaskPricing } from '../../../../appRedux/actions/UpdateFlowAction';
import { yupAllFieldsValidation } from '../../../../constants/utils';
import { mapKeys } from 'lodash';
import GlobalMdmFields from '../../../../components/GlobalMdmFields';
import { mytaskPricingRules, rejectRules } from '../../../../constants/FieldRules';

import {
    RoleType,
    SalesOrgType,
    SystemType,
    DistributionChannelType,
    DivisionType,
    CompanyCodeType,
} from '../../../../constants/WorkflowEnums';

import DynamicSelect from '../../../../components/DynamicSelect';
import {
    fetchPricingDropDownData,
    fetchTeamRoleFieldMapping,
} from '../../../../redux/DropDownDatas';
import Loading from '../../../../components/Loading';
import FlashMessage, { FlashMessages } from '../../../../components/FlashMessage';
import MultiColorProgressBar from '../../../../components/MultiColorProgressBar';
import {
    getStatusBarData,
    getFunctionalGroupData,
} from '../../../../appRedux/actions/Workflow';
import { getCustomerFromSAP, getStaticRequest } from '../../../../appRedux/actions';
import idx from 'idx';
import DeltaField from '../../../../components/DeltaField';
import Modal from 'modal-enhanced-react-native-web';
import styles from './styles';
import BreadCrumbs from '../../../../components/BreadCrumbs';
import { removeMessage } from '../../../../appRedux/actions/Toast';
import FilesList from '../../../../components/FilesList';
import { MaterialIcons } from '@expo/vector-icons';
import * as moment from 'moment';

class Page extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            WorkflowId: this.props.location.state.WorkflowId,
            TaskId: this.props.location.state.TaskId,
            reject: false,
            dropDownDatas: {},
            teamRoleFieldMapping: {},
            formData: { RejectionButton: false },
            formErrors: {},
            isReject: false,
            modalVisible: false,
            selectedFiles: {},
            selectedFilesIds: [],
            files: [],
            fileinputkey: Date.now(),
        };
        // console.log(this.props,"====",this.state)
    }

    componentDidMount() {
        let { state: wf } = this.props.location;
        this.props.getStaticRequest([
            42,
            43,
            40,
            52,
            53,
            54,
            55,
            56,
            57,
            58,
            59,
            60,
            61,
            62,
            63,
            64,
            65,
            67,
            69,
            76,
            77,
            81,
            84,
            81,
            43,
            44,
            5,
            84,
        ]);
        let postJson = {
            workflowId: wf.WorkflowId,
            fuctionalGroup: 'pricing',
            taskId: wf.TaskId,
        };
        this.props.getStatusBarData(postJson);
        this.props.getCustomerFromSAP({
            WorkflowId: wf.WorkflowId,
            TaskId: wf.TaskId,
            CustomerNumber: '',
            DivisionTypeId: 0,
            SystemTypeId: 0,
            DistributionChannelTypeId: 0,
            CompanyCodeTypeId: '',
            SalesOrgTypeId: 0,
        });
        fetchPricingDropDownData(true).then((res) =>
            this.setState({ dropDownDatas: res })
        );
        fetchTeamRoleFieldMapping().then((resp) => {
            const data = resp;
            this.setState({ teamRoleFieldMapping: data });
        });
    }

    setFormErrors = (errors) => {
        const { formErrors } = this.state;
        this.setState({ formErrors: errors }, () => window.scrollTo(0, 0));
    };

    onFieldChange = (value, e) => {
        const { name } = e.target;
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: value,
            },
        });
    };

    handleFormSubmission = (schema) => {
        let {
                TaskId,
                WorkflowId,
                formData,
                selectedFiles,
                selectedFilesIds,
            } = this.state,
            postData = {};
        let { action } = this.props.match.params;
        try {
            const WorkflowTaskModel = {
                RejectReason: formData['RejectionButton']
                    ? formData['RejectionReason']
                    : '',
                AdditionalNotes: formData['AdditionalNotes']
                    ? formData['AdditionalNotes']
                    : '',
                TaskId: TaskId,
                UserId: localStorage.getItem('userId'),
                WorkflowId: WorkflowId,
            };
            if (action === 'update') {
                WorkflowTaskModel['WorkflowTaskOperationType'] = !formData[
                    'RejectionButton'
                ]
                    ? 1
                    : 4;
            } else if (action === 'extend-salesorg') {
                WorkflowTaskModel['WorkflowTaskOperationType'] = !formData[
                    'RejectionButton'
                ]
                    ? 1
                    : 2;
            }

            postData['formData'] = {
                WorkflowTaskModel,
                action,
                Deltas: this.props.denormalizedDeltas || null,
            };

            postData['files'] = selectedFilesIds.map((id) => selectedFiles[id]);
            this.props.saveApolloUpdateMyTaskPricing(postData, this.props.history);
            this.resetForm();
            this.scrollToTop();
        } catch (error) {
            console.log('form validtion error');
        }
    };

    onSubmit = (event, reject, schema) => {
        let { formData, selectedFilesIds, selectedFiles } = this.state;
        let fileErrors = {};
        let errors = false;
        selectedFilesIds.map((id) => {
            if (selectedFiles[id] && selectedFiles[id].DocumentType <= 0) {
                fileErrors[id] = 'Document Type Required for file';
                errors = true;
            }
        });
        this.setState({ fileErrors, isFileErrors: errors });

        this.setState(
            {
                modalVisible: false,
                formData: {
                    ...this.state.formData,
                    RejectionButton: reject,
                },
            },
            () => {
                if (this.state.isFileErrors === false) this.handleFormSubmission(schema);
            }
        );
    };

    selectFiles = (events) => {
        event.preventDefault();

        const { selectedFilesIds, selectedFiles } = this.state;
        const id = events.target.files[0].name;

        this.setState({
            selectedFiles: {
                ...selectedFiles,
                [id]: {
                    data: events.target.files[0],
                    DocumentName: events.target.files[0].name,
                    DocumentType: 0,
                },
            },
            selectedFilesIds: [...selectedFilesIds, id],
            filename: events.target.files[0].name,
            fileinputkey: Date.now(),
        });
    };

    removeFile = (id) => {
        event.preventDefault();

        var selectedFilesDict = this.state.selectedFiles;
        var selectedFilesIdsList = this.state.selectedFilesIds;
        // remove the existing state
        delete selectedFilesDict[id];
        selectedFilesIdsList = selectedFilesIdsList.filter((fileid) => fileid !== id);

        this.setState({
            selectedFiles: selectedFilesDict,
            selectedFilesIds: selectedFilesIdsList,
        });
    };

    scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    resetForm = () => {
        Object.keys(this.state.formData).map((key) => {
            this.setState({
                formData: {
                    [key]: '',
                },
            });
        });
        Object.keys(this.state.formErrors).map((key) => {
            this.setState({
                formErrors: {
                    [key]: '',
                },
            });
        });
        //restore initial values
        this.setState({
            formData: { RejectionButton: false },
        });
    };

    getValue = (name) => {
        const { bapi70CustData = {}, deltas = {} } = this.props;
        const { dropDownDatas } = this.state;
        if (deltas[name]) {
            return idx(
                dropDownDatas,
                (_) => _[name][deltas[name].UpdatedValue].description
            );
        } else if (bapi70CustData) {
            return idx(dropDownDatas, (_) => _[name][bapi70CustData.name].description);
        }
    };

    _renderButton = (text, onPress) => (
        <TouchableOpacity onPress={onPress}>
            <View style={styles.button}>
                <Text>{text}</Text>
            </View>
        </TouchableOpacity>
    );

    _renderModalContent = () => (
        <Box
            my={4}
            mx="30%"
            backgroundColor="white"
            borderRadius="4px"
            borderColor="rgba(0, 0, 0, 0.1)">
            <Text my={3} fontWeight="regular" color="lightBlue" fontSize={24} pl={4}>
                Submit Workflow
            </Text>
            <Box px="0px" pb="22px" justifyContent="center">
                <Box alignItems="center" justifyContent="center">
                    {!this.state.isReject ? (
                        <FormInput
                            label="Additional Notes"
                            multiline
                            maxLength={250}
                            numberOfLines={2}
                            name="AdditionalNotes"
                            type="text"
                            onChange={this.onFieldChange}
                            value={
                                this.state.formData &&
                                this.state.formData['AdditionalNotes']
                            }
                            variant={'solid'}
                        />
                    ) : (
                        <FormInput
                            label="Rejection Reason"
                            name="RejectionReason"
                            maxLength={250}
                            onChange={this.onFieldChange}
                            error={'*this field is required'}
                            multiline
                            numberOfLines={2}
                            type="text"
                            value={
                                this.state.formData &&
                                this.state.formData['RejectionReason']
                            }
                            variant="solid"
                        />
                    )}
                </Box>
                <Flex
                    justifyEnd
                    alignCenter
                    alignSelf="flex-end"
                    style={{
                        paddingTop: 15,
                        flexDirection: 'row',
                        alignItems: 'center',
                        paddingLeft: 10,
                        paddingRight: 15,
                        marginTop: 20,
                        marginBottom: 10,
                        marginHorizontal: 25,
                    }}>
                    <Button
                        onPress={(event) => {
                            if (this.state.isReject)
                                this.setState(
                                    { isReject: false, modalVisible: null },
                                    () => this.onSubmit(event, true, rejectRules)
                                );
                            else this.onSubmit(event, false, mytaskPricingRules);
                        }}
                        title="Submit"
                    />
                    <Button
                        title="Cancel"
                        onPress={() => this.setState({ modalVisible: null })}
                    />
                </Flex>
            </Box>
        </Box>
    );
    getDropdownValues = (Id, dropdownList) => {
        let description = '';
        if (Id && dropdownList)
            dropdownList.some((data) => {
                if (data.Id === Id) {
                    description = data.Value + ' : ' + data.Description;
                    return true;
                }
            });
        // if (description.includes('/n') || description.includes(';')) {
        //     console.log('html string');
        // }
        return description;
    };

    renderOlympusFields = (isPointman, globalMdmDetail, staticData, allowedFields) => {
        const { formData } = this.state;
        return (
            <>
                <FormInput
                    hide={isPointman}
                    variant="outline"
                    label="Distribution Channel"
                    name="DistributionChannelTypeId"
                    hide={allowedFields && allowedFields.includes('DistributionChannel')}
                    value={
                        staticData &&
                        globalMdmDetail &&
                        this.getDropdownValues(
                            globalMdmDetail.DistributionChannelTypeId,
                            staticData && staticData.OlympusDistributionChannelType
                        )
                    }
                    isRequired
                    formErrors={
                        this.state.formErrors
                            ? this.state.formErrors['DistributionChannelTypeId']
                            : null
                    }
                    inline={true}
                    team="system"
                />

                <FormInput
                    label="Division"
                    name="DivisionTypeId"
                    hide={allowedFields && allowedFields.includes('Division')}
                    variant="outline"
                    type="text"
                    value={
                        staticData &&
                        globalMdmDetail &&
                        this.getDropdownValues(
                            globalMdmDetail.DivisionTypeId,
                            staticData.OlympusDivisionType
                        )
                    }
                    inline={true}
                    team="system"
                />

                <FormInput
                    variant="outline"
                    hide={
                        isPointman &&
                        allowedFields &&
                        allowedFields.includes('CompanyCode')
                    }
                    label="Company Code"
                    name="CompanyCodeTypeId"
                    value={
                        staticData &&
                        globalMdmDetail &&
                        this.getDropdownValues(
                            globalMdmDetail.CompanyCodeTypeId,
                            staticData && staticData.OlympusCompanyCodeType
                        )
                    }
                    isRequired
                    formErrors={
                        this.state.formErrors
                            ? this.state.formErrors['CompanyCodeTypeId']
                            : null
                    }
                    onFieldChange={this.onFieldChange}
                    inline={true}
                    team="system"
                />
                <FormInput
                    variant="outline"
                    label="Sold To"
                    name="SoldTo"
                    error={this.state.formErrors ? this.state.formErrors['SoldTo'] : null}
                    hide={
                        globalMdmDetail &&
                        globalMdmDetail.RoleTypeId != 2 &&
                        globalMdmDetail.RoleTypeId != 3 &&
                        globalMdmDetail.RoleTypeId != 4 &&
                        allowedFields &&
                        allowedFields.includes('SoldTo')
                    }
                    value={globalMdmDetail && globalMdmDetail['SoldTo']}
                    onChange={(value, element) => {
                        this.onFieldChange(value, element);
                    }}
                    inline={true}
                    team="system"
                />
                <FormInput
                    variant="outline"
                    value={
                        staticData &&
                        globalMdmDetail &&
                        this.getDropdownValues(
                            globalMdmDetail.LanguageTypeId,
                            staticData && staticData.OlympusLanguageType
                        )
                    }
                    hide={allowedFields && allowedFields.includes('Language')}
                    label="Language"
                    name="Language"
                    isRequired
                    formErrors={
                        this.state.formErrors ? this.state.formErrors['Language'] : null
                    }
                    onFieldChange={this.onFieldChange}
                    inline={true}
                    team="system"
                />

                <FormInput
                    variant="outline"
                    label="Customer Class"
                    name="CustomerClass"
                    value={
                        staticData &&
                        globalMdmDetail &&
                        this.getDropdownValues(
                            globalMdmDetail.CustomerClassTypeId,
                            staticData && staticData.OlympusCustomerClassType
                        )
                    }
                    formErrors={
                        this.state.formErrors
                            ? this.state.formErrors['CustomerClass']
                            : null
                    }
                    onFieldChange={this.onFieldChange}
                    inline={true}
                    team="system"
                />

                <FormInput
                    variant="outline"
                    label="Recon Account"
                    name="ReconAccount"
                    hide={allowedFields && allowedFields.includes('ReconAccount')}
                    value={
                        staticData &&
                        globalMdmDetail &&
                        this.getDropdownValues(
                            globalMdmDetail.ReconAccountTypeId,
                            staticData && staticData.OlympusReconAccountType
                        )
                    }
                    isRequired
                    formErrors={
                        this.state.formErrors
                            ? this.state.formErrors['ReconAccount']
                            : null
                    }
                    onFieldChange={this.onFieldChange}
                    inline={true}
                    team="system"
                />
                <FormInput
                    variant="outline"
                    label="Terms of Payment"
                    name="TermsOfPayment"
                    hide={allowedFields && allowedFields.includes('TermsOfPayment')}
                    style={{ whiteSpace: 'pre-wrap' }}
                    multiline={true}
                    value={
                        staticData &&
                        globalMdmDetail &&
                        this.getDropdownValues(
                            globalMdmDetail.TermsOfPaymentTypeId,
                            staticData && staticData.OlympusTermsOfPaymentType
                        )
                    }
                    isRequired
                    formErrors={
                        this.state.formErrors
                            ? this.state.formErrors['TermsOfPayment']
                            : null
                    }
                    onFieldChange={this.onFieldChange}
                    inline={true}
                    team="system"
                />
                <FormInput
                    variant="outline"
                    label="Currency"
                    name="Currency"
                    hide={allowedFields && allowedFields.includes('Currency')}
                    value={
                        staticData &&
                        globalMdmDetail &&
                        this.getDropdownValues(
                            globalMdmDetail.CurrencyTypeId,
                            staticData && staticData.OlympusCurrencyType
                        )
                    }
                    formErrors={
                        this.state.formErrors ? this.state.formErrors['Currency'] : null
                    }
                    onFieldChange={this.onFieldChange}
                    inline={true}
                    team="system"
                />
                <FormInput
                    variant="outline"
                    label="Primary GPO"
                    name="PrimaryGpo"
                    hide={allowedFields && allowedFields.includes('PrimaryGpo')}
                    error={
                        this.state.formErrors ? this.state.formErrors['PrimaryGpo'] : null
                    }
                    value={globalMdmDetail && globalMdmDetail['PrimaryGpo']}
                    style={{ height: 'inherit' }}
                    onChange={(value, element) => {
                        this.onFieldChange(value, element);
                    }}
                    inline={true}
                    team="system"
                />
                <FormInput
                    variant="outline"
                    label="Requested Credit Limit"
                    name="RequestedCreditLimit"
                    hide={allowedFields && allowedFields.includes('RequestedCreditLimit')}
                    error={
                        this.state.formErrors
                            ? this.state.formErrors['RequestedCreditLimit']
                            : null
                    }
                    value={globalMdmDetail && globalMdmDetail['CreditLimit']}
                    style={{ height: 'inherit' }}
                    onChange={(value, element) => {
                        this.onFieldChange(value, element);
                    }}
                    inline={true}
                    team="system"
                />

                <FormInput
                    variant="outline"
                    label="Priority"
                    name="Priority"
                    hide={allowedFields && allowedFields.includes('Priority')}
                    value={
                        staticData &&
                        globalMdmDetail &&
                        this.getDropdownValues(
                            globalMdmDetail.PriorityTypeId,
                            staticData && staticData.OlympusPriorityType
                        )
                    }
                    formErrors={
                        this.state.formErrors ? this.state.formErrors['Priority'] : null
                    }
                    onFieldChange={this.onFieldChange}
                    inline={true}
                    team="system"
                />
                <FormInput
                    variant="outline"
                    label="Effective Date"
                    hide={allowedFields && allowedFields.includes('EffectiveDate')}
                    min={moment().format('YYYY-MM-DD')}
                    name="EffectiveDate"
                    error={
                        this.state.formErrors
                            ? this.state.formErrors['EffectiveDate']
                            : null
                    }
                    value={
                        globalMdmDetail &&
                        moment(globalMdmDetail['EffectiveDate']).format('YYYY-MM-DD')
                    }
                    type="date"
                    style={{ height: 'inherit' }}
                    onChange={(value, element) => {
                        this.onFieldChange(
                            new Date(value).toJSON().slice(0, 19),
                            element
                        );
                    }}
                    inline={true}
                    team="system"
                />
                <FormInput
                    label="Purpose of Request"
                    name="Purpose"
                    hide={allowedFields && allowedFields.includes('Purpose')}
                    multiline
                    value={globalMdmDetail && globalMdmDetail.Purpose}
                    numberOfLines={
                        globalMdmDetail.Purpose && globalMdmDetail.Purpose.length > 36
                            ? 3
                            : 1
                    }
                    style={{ lineHeight: '2.5' }}
                    inline
                    maxLength={1000}
                    variant="outline"
                    type="text"
                    team="system"
                />
            </>
        );
    };

    render() {
        const {
            width,
            location,
            bapi70CustData = {},
            deltas = {},
            statusBarData,
            alert = {},
            TasksStatusByTeamId = null,
            staticData,
        } = this.props;
        const Deltas = deltas;

        const {
            dropDownDatas,
            teamRoleFieldMapping,
            selectedFilesIds,
            selectedFiles,
        } = this.state;
        const { state } = location;

        const PricingTeamFields =
            idx(teamRoleFieldMapping, (_) => _.PricingTeamFields) || [];
        const RoleTypeId = bapi70CustData.RoleTypeId;
        const SystemTypeId = bapi70CustData.SystemType;
        const allowedFieldList = PricingTeamFields.filter(
            (item) => item.RoleTypeId === RoleTypeId && item.SystemTypeId === SystemTypeId
        );
        let allowedFields = undefined;
        if (allowedFieldList && allowedFieldList.length > 0) {
            allowedFields = allowedFieldList[0].Fields;
        }

        const workflow = {
            ...state,
            isReadOnly:
                TasksStatusByTeamId === null ||
                !(
                    state.WorkflowStateType === 2 &&
                    TasksStatusByTeamId[8].WorkflowTaskStateTypeId === 2
                ),
        };

        const requestDateUtc = moment(idx(bapi70CustData, (_) => _.DateOfRequest));
        const localDate = moment(requestDateUtc).local();
        const requestDate = localDate.format('YYYY-MM-DD hh:mm:ss A');

        const inputReadonlyProps = workflow.isReadOnly ? { disabled: true } : null;
        const {
            WorkflowDocuments: files = [],
            RequestorName,
        } = this.props.bapi70CustData;

        const showFunctionalDetail =
            state.isReadOnly && bapi70CustData === null ? { display: 'none' } : null;

        const showButtons = workflow.isReadOnly ? { display: 'none' } : null;

        const inputProps = {
            variant: 'outline',
            inline: true,
            type: 'text',
        };
        let { action } = this.props.match.params;
        var bgcolor = alert.color || '#FFF';
        const isPointman = bapi70CustData && bapi70CustData.SystemTypeId === 3;
        const isWorkFlowReadOnly = workflow.isReadOnly;

        if (this.props.fetching) {
            return <Loading />;
        }
        if (this.props.fetchingfnGroupData) {
            return <Loading />;
        }

        console.log(this.state, this.props);

        return (
            <ScrollView
                keyboardShouldPersistTaps="always"
                style={{
                    backgroundColor: '#EFF3F6',
                    paddingBottom: 75,
                }}>
                <Modal isVisible={this.state.modalVisible}>
                    {this._renderModalContent()}
                </Modal>
                <FlashMessages
                    toasts={this.props.toasts}
                    onDismiss={this.props.removeMessage}
                />
                {alert.display && (
                    <FlashMessage
                        bg={{ backgroundColor: bgcolor }}
                        message={alert.message}
                    />
                )}

                <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between">
                    <Text
                        style={{
                            fontWeight: '500',
                            fontSize: 24,
                            color: Colors.lightBlue,
                            marginTop: 25,
                            marginBottom: 20,
                            paddingLeft: 45,
                        }}>
                        MY TASKS - {state.Header}
                    </Text>
                    <Box
                        marginTop="25px"
                        flex={3 / 4}
                        flexDirection="row-reverse"
                        alignItems="flex-end">
                        <MultiColorProgressBar readings={statusBarData} />
                    </Box>
                </Box>
                <Box marginTop="25px" flexDirection="row-reverse" alignItems="flex-end">
                    <StyledText
                        style={{
                            fontWeight: '600',
                            paddingRight: '20px',
                            fontSize: '16px',
                            color: '#10206C',
                        }}>
                        {' '}
                        Requestor : {RequestorName}{' '}
                    </StyledText>
                </Box>
                <Box flexDirection="row-reverse" alignItems="flex-end">
                    <StyledText
                        style={{
                            fontWeight: '600',
                            paddingRight: '20px',
                            fontSize: '16px',
                            color: '#10206C',
                        }}>
                        {' '}
                        Date of Request: {requestDate}{' '}
                    </StyledText>
                </Box>
                <View
                    style={{
                        flex: 1,
                        paddingHorizontal: width < 1440 ? 60 : width * 0.1,
                        paddingBottom: 10,
                    }}>
                    <Box fullHeight my={2}>
                        <Box flexDirection="row" justifyContent="space-around" my={4}>
                            <FormInput
                                px="25px"
                                flex={1 / 4}
                                label="Workflow Title"
                                name="title"
                                variant="outline"
                                type="text"
                                value={bapi70CustData && bapi70CustData.WorkflowTitle}
                            />
                            <FormInput
                                px="25px"
                                flex={1 / 4}
                                label="Workflow Number"
                                name="workflow-number"
                                variant="outline"
                                type="text"
                                value={state && state.WorkflowId}
                            />
                            {bapi70CustData && bapi70CustData.SystemRecordId && (
                                <FormInput
                                    px="25px"
                                    flex={1 / 4}
                                    label="Customer Number"
                                    name="CustomerNumber"
                                    variant="outline"
                                    type="text"
                                    value={
                                        bapi70CustData && bapi70CustData.SystemRecordId
                                    }
                                />
                            )}
                            {bapi70CustData && bapi70CustData.MdmNumber && (
                                <TouchableOpacity
                                    onPress={() =>
                                        this.props.history.push(
                                            `/search-results/${
                                                bapi70CustData && bapi70CustData.MdmNumber
                                            }`
                                        )
                                    }>
                                    <FormInput
                                        px="25px"
                                        flex={1 / 4}
                                        label="MDM Number"
                                        name="mdm-number"
                                        variant="outline"
                                        type="text"
                                        value={bapi70CustData && bapi70CustData.MdmNumber}
                                    />
                                </TouchableOpacity>
                            )}
                        </Box>
                        <GlobalMdmFields
                            staticDropDownData={this.props.staticData}
                            formData={bapi70CustData}
                            readOnly
                        />

                        <Text
                            mt="42px"
                            mb="20px"
                            fontWeight="regular"
                            color="lightBlue"
                            fontSize={24}
                            pl={4}>
                            SYSTEM FIELDS
                        </Text>
                        <Box flexDirection="row" justifyContent="center">
                            <Box width={1 / 2} mx="auto" alignItems="center">
                                <FormInput
                                    label="System"
                                    name="System"
                                    inline={true}
                                    variant="outline"
                                    type="text"
                                    team="system"
                                    hide={
                                        allowedFields && allowedFields.includes('System')
                                    }
                                    value={
                                        staticData &&
                                        bapi70CustData &&
                                        this.getDropdownValues(
                                            bapi70CustData.SystemType,
                                            staticData && this.props.staticData.SystemType
                                        )
                                    }
                                />
                                <FormInput
                                    label="Role"
                                    name="Role"
                                    hide={allowedFields && allowedFields.includes('Role')}
                                    inline={true}
                                    variant="outline"
                                    type="text"
                                    team="system"
                                    value={
                                        staticData &&
                                        staticData &&
                                        this.getDropdownValues(
                                            bapi70CustData.RoleTypeId,
                                            staticData &&
                                                this.props.staticData.OlympusRoleType
                                        )
                                    }
                                />
                                <FormInput
                                    label="Sales Org"
                                    name="SalesOrg"
                                    hide={
                                        allowedFields &&
                                        allowedFields.includes('SalesOrg')
                                    }
                                    inline={true}
                                    variant="outline"
                                    type="text"
                                    team="system"
                                    hide={isPointman}
                                    value={
                                        staticData &&
                                        bapi70CustData &&
                                        this.getDropdownValues(
                                            bapi70CustData.SalesOrgTypeId,
                                            staticData && staticData.OlympusSalesOrgType
                                        )
                                    }
                                />
                                <FormInput
                                    label="Service Code"
                                    name="ServiceCode"
                                    variant="outline"
                                    hide={
                                        allowedFields &&
                                        allowedFields.includes('ServiceCode')
                                    }
                                    error={
                                        this.state.formErrors
                                            ? this.state.formErrors['ServiceCode']
                                            : null
                                    }
                                    value={
                                        bapi70CustData && bapi70CustData['UriServiceCode']
                                    }
                                    onChange={(value, element) => {
                                        this.onFieldChange(value, element);
                                    }}
                                    inline={true}
                                />
                                <DynamicSelect
                                    arrayOfData={
                                        this.props.staticData &&
                                        this.props.staticData.OlympusURIType
                                        // &&
                                        // this.props.staticData.OlympusCustomerClassType.filter(
                                        //     (role) =>
                                        //         role.Id ===
                                        //         parseInt(formData['CustomerClass'])
                                        // )
                                    }
                                    label="URI Type"
                                    name="UriTypeId"
                                    // inputProps={isSalesForce}
                                    value={bapi70CustData.UriTypeId}
                                    formErrors={
                                        this.state.formErrors
                                            ? this.state.formErrors['UriTypeId']
                                            : null
                                    }
                                    onFieldChange={this.onFieldChange}
                                    hide={
                                        allowedFields && allowedFields.includes('URIType')
                                    }
                                    variant="outline"
                                    readOnly={true}
                                />
                                <FormInput
                                    variant="outline"
                                    label="Delivery Information"
                                    name="DeliveryInformation"
                                    inline={true}
                                    hide={
                                        allowedFields &&
                                        allowedFields.includes('DeliveryInformation')
                                    }
                                    error={
                                        this.state.formErrors
                                            ? this.state.formErrors['DeliveryInformation']
                                            : null
                                    }
                                    value={
                                        bapi70CustData &&
                                        bapi70CustData['DeliveryInformation']
                                    }
                                    style={{ height: 'inherit' }}
                                    onChange={(value, element) => {
                                        this.onFieldChange(value, element);
                                    }}
                                    team="system"
                                />
                                <FormInput
                                    variant="outline"
                                    label="Sales District"
                                    name="SalesDistrict"
                                    hide={
                                        allowedFields &&
                                        allowedFields.includes('SalesDistrict')
                                    }
                                    value={
                                        staticData &&
                                        bapi70CustData &&
                                        this.getDropdownValues(
                                            bapi70CustData.SalesDistrictTypeId,
                                            staticData &&
                                                staticData.OlympusSalesDistrictType
                                        )
                                    }
                                    isRequired={this.state.formData['Country'] === 'DE'}
                                    formErrors={
                                        this.state.formErrors
                                            ? this.state.formErrors['SalesDistrict']
                                            : null
                                    }
                                    onFieldChange={this.onFieldChange}
                                    inline={true}
                                    team="system"
                                />
                                <FormInput
                                    variant="outline"
                                    label="Sales Office"
                                    name="SalesOffice"
                                    hide={
                                        allowedFields &&
                                        allowedFields.includes('SalesOffice')
                                    }
                                    value={
                                        staticData &&
                                        bapi70CustData &&
                                        this.getDropdownValues(
                                            bapi70CustData.SalesOfficeTypeId,
                                            staticData &&
                                                staticData.OlympusSalesOfficeType
                                        )
                                    }
                                    isRequired={this.state.formData['Country'] === 'DE'}
                                    formErrors={
                                        this.state.formErrors
                                            ? this.state.formErrors['SalesOffice']
                                            : null
                                    }
                                    onFieldChange={this.onFieldChange}
                                    inline={true}
                                    team="system"
                                />
                                <FormInput
                                    variant="outline"
                                    label="Sales Group"
                                    name="SalesGroup"
                                    hide={
                                        allowedFields &&
                                        allowedFields.includes('SalesGroup')
                                    }
                                    value={
                                        staticData &&
                                        bapi70CustData &&
                                        this.getDropdownValues(
                                            bapi70CustData.SalesGroupTypeId,
                                            staticData && staticData.OlympusSalesGroupType
                                        )
                                    }
                                    isRequired={this.state.formData['Country'] === 'DE'}
                                    formErrors={
                                        this.state.formErrors
                                            ? this.state.formErrors['SalesGroup']
                                            : null
                                    }
                                    onFieldChange={this.onFieldChange}
                                    inline={true}
                                    team="system"
                                />
                                <FormInput
                                    variant="outline"
                                    label="Tax Number 4"
                                    name="TaxNumber4"
                                    hide={
                                        allowedFields &&
                                        allowedFields.includes('TaxNumber4')
                                    }
                                    error={
                                        this.state.formErrors
                                            ? this.state.formErrors['TaxNumber4']
                                            : null
                                    }
                                    value={bapi70CustData && bapi70CustData['TaxNumber4']}
                                    style={{ height: 'inherit' }}
                                    onChange={(value, element) => {
                                        this.onFieldChange(value, element);
                                    }}
                                    inline={true}
                                    team="system"
                                />
                                <FormInput
                                    variant="outline"
                                    label="Incoterms1"
                                    name="Incoterms1"
                                    hide={
                                        allowedFields &&
                                        allowedFields.includes('Incoterms1')
                                    }
                                    value={
                                        staticData &&
                                        bapi70CustData &&
                                        this.getDropdownValues(
                                            bapi70CustData.Incoterms1TypeId,
                                            staticData && staticData.OlympusIncoTermsType
                                        )
                                    }
                                    formErrors={
                                        this.state.formErrors
                                            ? this.state.formErrors['Incoterms1']
                                            : null
                                    }
                                    onFieldChange={this.onFieldChange}
                                    inline={true}
                                    team="system"
                                />
                                <FormInput
                                    variant="outline"
                                    label="Place Of Destination"
                                    name="PlaceOfDestination"
                                    hide={
                                        allowedFields &&
                                        allowedFields.includes('PlaceOfDestination')
                                    }
                                    error={
                                        this.state.formErrors
                                            ? this.state.formErrors['PlaceOfDestination']
                                            : null
                                    }
                                    value={
                                        bapi70CustData &&
                                        bapi70CustData['PlaceOfDestination']
                                    }
                                    style={{ height: 'inherit' }}
                                    onChange={(value, element) => {
                                        this.onFieldChange(value, element);
                                    }}
                                    inline={true}
                                    team="system"
                                />
                                <FormInput
                                    variant="outline"
                                    label="Acct Assignment Group"
                                    name="AcctAssignmentGroup"
                                    hide={
                                        allowedFields &&
                                        allowedFields.includes('AcctAssignmentGroup')
                                    }
                                    value={
                                        staticData &&
                                        bapi70CustData &&
                                        this.getDropdownValues(
                                            bapi70CustData.AccountAssignmentGroupTypeId,
                                            staticData &&
                                                staticData.OlympusAcctAssignmentGroupType
                                        )
                                    }
                                    formErrors={
                                        this.state.formErrors
                                            ? this.state.formErrors['AcctAssignmentGroup']
                                            : null
                                    }
                                    onFieldChange={this.onFieldChange}
                                    inline={true}
                                    team="system"
                                />
                                <FormInput
                                    variant="outline"
                                    label="Tax Classification"
                                    name="TaxClassificationTypeId"
                                    hide={
                                        allowedFields &&
                                        allowedFields.includes('TaxClassification')
                                    }
                                    error={
                                        this.state.formErrors
                                            ? this.state.formErrors[
                                                  'TaxClassificationTypeId'
                                              ]
                                            : null
                                    }
                                    value={
                                        staticData &&
                                        bapi70CustData &&
                                        this.getDropdownValues(
                                            bapi70CustData.TaxClassificationTypeId,
                                            staticData &&
                                                staticData.OlympusTaxClassificationType
                                        )
                                    }
                                    style={{ height: 'inherit' }}
                                    onChange={(value, element) => {
                                        this.onFieldChange(value, element);
                                    }}
                                    inline={true}
                                />
                            </Box>
                            <Box width={1 / 2} mx="auto" alignItems="center">
                                {bapi70CustData.RoleTypeId !== 1 && (
                                    <FormInput
                                        label="Ship To Customer Number"
                                        name="ShipToCustomerNumber"
                                        type="number"
                                        value={
                                            isWorkFlowReadOnly
                                                ? bapi70CustData &&
                                                  bapi70CustData.ShipToCustomerNumber
                                                : this.state.formData
                                                ? this.state.formData[
                                                      'ShipToCustomerNumber'
                                                  ]
                                                : null
                                        }
                                        error={
                                            this.state.formErrors
                                                ? this.state.formErrors[
                                                      'ShipToCustomerNumber'
                                                  ]
                                                : null
                                        }
                                        onChange={(val, e) =>
                                            this.onFieldChange(
                                                val.toLowerCase().trim(),
                                                e
                                            )
                                        }
                                        maxLength={8}
                                        variant={isWorkFlowReadOnly ? 'outline' : 'solid'}
                                        inline={isWorkFlowReadOnly ? true : false}
                                        required
                                        team="system"
                                    />
                                )}
                                {this.renderOlympusFields(
                                    isPointman,
                                    bapi70CustData,
                                    staticData,
                                    allowedFields
                                )}
                            </Box>
                        </Box>

                        <Box {...showFunctionalDetail}>
                            {/* <>
                            <Text
                                mt="42px"
                                mb="20px"
                                fontWeight="regular"
                                color="lightBlue"
                                fontSize={24}
                                pl={4}>
                                PRICING FIELDS
                            </Text>
                            <Box flexDirection="row" justifyContent="center">
                                <Box width={1 / 2} mx="auto" alignItems="center">
                                    {Deltas['PriceGroupTypeId'] ? (
                                        <DeltaField
                                            delta={Deltas['PriceGroupTypeId']}
                                            arrayOfData={staticData.OlympusPriceGroupType}
                                        />
                                    ) : (
                                        <FormInput
                                            label="Price Group"
                                            name="PriceGroupTypeId"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes(
                                                    'PriceGroupTypeId'
                                                )
                                            }
                                            value={
                                                bapi70CustData &&
                                                idx(
                                                    dropDownDatas,
                                                    (_) =>
                                                        _.SpecialPricingTypeId[
                                                            bapi70CustData
                                                                .SpecialPricingTypeId
                                                        ]
                                                )
                                            }
                                            {...inputProps}
                                        />
                                    )}
                                </Box>
                                <Box width={1 / 2} mx="auto" alignItems="center"></Box>
                            </Box>
                            </> */}
                            {files && <FilesList files={files} readOnly />}
                            {workflow.isReadOnly ? (
                                <Box my={4}>
                                    <Box flexDirection="row" justifyContent="center">
                                        {bapi70CustData &&
                                            bapi70CustData.RejectionReason && (
                                                <Box
                                                    width={1 / 2}
                                                    mx="auto"
                                                    alignItems="center">
                                                    <FormInput
                                                        label="Notes"
                                                        name="RejectionReason"
                                                        inputWidth="100%"
                                                        onChange={this.onFieldChange}
                                                        error={
                                                            this.state.formErrors
                                                                ? this.state.formErrors[
                                                                      'RejectionReason'
                                                                  ]
                                                                : null
                                                        }
                                                        multiline
                                                        numberOfLines={2}
                                                        type="text"
                                                        value={
                                                            workflow.isReadOnly
                                                                ? bapi70CustData &&
                                                                  bapi70CustData.RejectionReason
                                                                : this.state.formData
                                                                ? this.state.formData[
                                                                      'RejectionReason'
                                                                  ]
                                                                : null
                                                        }
                                                        variant={
                                                            workflow.isReadOnly
                                                                ? 'outline'
                                                                : 'solid'
                                                        }
                                                        inline={
                                                            workflow.isReadOnly
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                </Box>
                                            )}
                                        <Box
                                            width={1 / 2}
                                            mx="auto"
                                            alignItems="center"
                                        />
                                    </Box>
                                </Box>
                            ) : (
                                <FilesList
                                    title="NEW ATTACHMENTS"
                                    formErrors={this.state.fileErrors}
                                    files={selectedFilesIds.map(
                                        (id) => selectedFiles[id]
                                    )}
                                    onChange={(value, id) => {
                                        this.setState({
                                            selectedFiles: {
                                                ...selectedFiles,
                                                [id]: {
                                                    ...selectedFiles[id],
                                                    DocumentType: parseInt(value),
                                                },
                                            },
                                        });
                                    }}
                                    onRemove={(value, id) => this.removeFile(id)}
                                />
                            )}
                        </Box>
                    </Box>

                    <Box {...showButtons}>
                        <Flex
                            justifyEnd
                            alignCenter
                            style={{
                                paddingTop: 15,
                                flexDirection: 'row',
                                alignItems: 'center',
                                paddingLeft: 10,
                                paddingRight: 15,
                                marginTop: 20,
                                marginBottom: 10,
                                marginHorizontal: 25,
                            }}>
                            <label htmlFor="file-upload" className="custom-file-upload">
                                <MaterialIcons
                                    name="attach-file"
                                    size={18}
                                    color="#fff"
                                />
                            </label>
                            <input
                                id="file-upload"
                                type="file"
                                key={this.state.fileinputkey}
                                onChange={this.selectFiles}
                                multiple
                            />
                            <Button
                                onPress={(event) =>
                                    this.setState({ isReject: false, modalVisible: true })
                                }
                                title="Approve"
                            />
                            <Button
                                title="Reject"
                                onPress={(event) =>
                                    this.setState({ isReject: true, modalVisible: true })
                                }
                            />
                        </Flex>
                    </Box>
                </View>
            </ScrollView>
        );
    }
}

class Default extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const props = this.props;

        return (
            <DimensionAware
                render={(dimensions) => (
                    <Page
                        {...{
                            ...props,
                            width: getWindowWidth(dimensions),
                            height: getWindowHeight(dimensions),
                            marginBottom: 25,
                        }}
                    />
                )}
            />
        );
    }
}

const mapStateToProps = ({
    workflows,
    myTasks,
    customer,
    updateFlow,
    toasts,
    staticData,
}) => {
    const {
        bapi70CustData,
        deltas,
        denormalizedDeltas,
        fetching: fetchingCustomer,
    } = customer;

    const { fetching, alert } = myTasks;
    const { fetching: fetchingUpdateflow } = updateFlow;

    const {
        fetchingfnGroupData,
        statusBarData,
        functionalGroupDetails,
        TasksStatusByTeamId,
        fetchingStatusBar,
    } = workflows;
    const { data, fetching: staticFetching } = staticData;
    return {
        fetching:
            fetching ||
            staticFetching ||
            fetchingUpdateflow ||
            fetchingStatusBar ||
            fetchingfnGroupData ||
            fetchingCustomer,
        alert,
        statusBarData,
        functionalGroupDetails,
        TasksStatusByTeamId,
        bapi70CustData,
        deltas,
        denormalizedDeltas,
        toasts,
        staticData: data,
    };
};

export default connect(mapStateToProps, {
    saveApolloUpdateMyTaskPricing,
    getFunctionalGroupData,
    getStatusBarData,
    getCustomerFromSAP,
    removeMessage,
    getStaticRequest,
})(Default);
