import React from 'react';
import { ScrollView, View, StyleSheet, TouchableOpacity } from 'react-native';
import {
    DimensionAware,
    getWindowHeight,
    getWindowWidth,
} from 'react-native-dimension-aware';
import { Flex, Button, Box, Text } from '../../../components/common';
import { FormInput } from '../../../components/form';
import { saveApolloMyTaskPricing } from '../../../appRedux/actions/MyTasks';
import { yupAllFieldsValidation } from '../../../constants/utils';
import { Colors, StyledText } from '../../../theme/Index';
import Modal from 'modal-enhanced-react-native-web';
import { MaterialIcons } from '@expo/vector-icons';

import GlobalMdmFields from '../../../components/GlobalMdmFields';
import { mytaskPricingRules } from '../../../constants/FieldRules';
import {
    RoleType,
    SalesOrgType,
    SystemType,
    DistributionChannelType,
    DivisionType,
    CompanyCodeType,
} from '../../../constants/WorkflowEnums';

import DynamicSelect from '../../../components/DynamicSelect';
import {
    fetchPricingDropDownData,
    fetchCreateTeamRoleFieldMapping,
} from '../../../redux/DropDownDatas';
import Loading from '../../../components/Loading';
import FlashMessage from '../../../components/FlashMessage';
import { connect } from 'react-redux';
import MultiColorProgressBar from '../../../components/MultiColorProgressBar';
import {
    getStatusBarData,
    getFunctionalGroupData,
    getStaticRequest,
} from '../../../appRedux/actions';
import FilesList from '../../../components/FilesList';
import idx from 'idx';
import * as moment from 'moment';
import TaskNotes from '../../../components/TaskNotes';

class Page extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            WorkflowId: this.props.location.state.WorkflowId,
            TaskId: this.props.location.state.TaskId,
            reject: false,
            dropDownDatas: {},
            teamRoleFieldMapping: {},
            formData: { RejectionButton: false },
            formErrors: {},
            selectedFiles: {},
            selectedFilesIds: [],
        };
    }

    componentDidMount() {
        let { state: wf } = this.props.location;
        this.props.getStaticRequest([42, 43, 82]);
        let postJson = {
            workflowId: wf.WorkflowId,
            fuctionalGroup: 'pricing',
        };
        this.props.getStatusBarData(postJson);
        this.props.getFunctionalGroupData(postJson);
        fetchPricingDropDownData().then((res) => this.setState({ dropDownDatas: res }));
        fetchCreateTeamRoleFieldMapping().then((resp) => {
            const data = resp;
            this.setState({ teamRoleFieldMapping: data });
        });
    }

    setFormErrors = (errors) => {
        const { formErrors } = this.state;
        this.setState({ formErrors: errors }, () => window.scrollTo(0, 0));
    };

    onFieldChange = (value, e) => {
        const { name } = e.target;
        this.setState(
            {
                formData: {
                    ...this.state.formData,
                    [name]: value,
                },
            },
            () => {
                //remove from formData if TypeId is set to 0 OR ''
                if (name.includes('TypeId') && parseInt(value) === 0) {
                    delete this.state.formData[name];
                }
                console.log('val---', name, this.state.formData[name]);
            }
        );
    };

    handleFormSubmission = (schema) => {
        let {
                TaskId,
                WorkflowId,
                formData,
                selectedFilesIds,
                selectedFiles,
            } = this.state,
            castedFormData = {},
            postData = {};
        try {
            const WorkflowTaskModel = {
                TaskId: TaskId,
                UserId: localStorage.getItem('userId'),
                WorkflowId: WorkflowId,
                WorkflowTaskOperationType: !formData['RejectionButton'] ? 1 : 2,
            };

            postData['files'] = selectedFilesIds.map((id) => selectedFiles[id]);

            if (!formData['RejectionButton']) {
                castedFormData = schema.cast(formData);
            } else {
                castedFormData = formData;
            }

            delete castedFormData.RejectionButton;
            postData['formdata'] = {
                WorkflowTaskModel,
                ...castedFormData,
                AdditionalNotes: this.state.formData.additionalNotes,
            };
            console.log('postData', postData);

            this.props.saveApolloMyTaskPricing(postData, this.props.history);
            this.setState({ modalVisible: false });
            this.resetForm();
            this.scrollToTop();
        } catch (error) {
            console.log('form validtion error');
        }
    };

    onSubmit = (event, reject, schema) => {
        let { formData, selectedFilesIds, selectedFiles } = this.state;
        let fileErrors = {};
        let errors = false;
        selectedFilesIds.map((id) => {
            if (selectedFiles[id] && selectedFiles[id].DocumentType <= 0) {
                fileErrors[id] = 'Document Type Required for file';
                errors = true;
            }
        });

        this.setState({ fileErrors, isFileErrors: errors }, () =>
            this.setState(
                {
                    formData: {
                        ...this.state.formData,
                        RejectionButton: reject,
                    },
                },
                () => {
                    yupAllFieldsValidation(
                        this.state.formData,
                        schema,
                        (...rest) => {
                            if (this.state.isFileErrors === false)
                                this.handleFormSubmission(...rest);
                        },
                        this.setFormErrors
                    );
                }
            )
        );
    };

    _renderModalContent = () => (
        <Box
            my={4}
            mx="30%"
            backgroundColor="white"
            borderRadius="4px"
            borderColor="rgba(0, 0, 0, 0.1)">
            <Text my={3} fontWeight="regular" color="lightBlue" fontSize={24} pl={4}>
                Release
            </Text>
            <Box px="0px" pb="22px" justifyContent="center">
                <Box alignItems="center" justifyContent="center">
                    <FormInput
                        label="Notes"
                        multiline
                        numberOfLines={2}
                        maxLength={250}
                        name="additionalNotes"
                        type="text"
                        onChange={this.onFieldChange}
                        error={
                            this.state.formErrors
                                ? this.state.formErrors['additionalNotes']
                                : null
                        }
                        value={
                            this.state.formData && this.state.formData['additionalNotes']
                        }
                        variant={'solid'}
                    />
                </Box>
                <Flex
                    justifyEnd
                    alignCenter
                    alignSelf="flex-end"
                    style={{
                        paddingTop: 15,
                        flexDirection: 'row',
                        alignItems: 'center',
                        paddingLeft: 10,
                        paddingRight: 15,
                        marginTop: 20,
                        marginBottom: 10,
                        marginHorizontal: 25,
                    }}>
                    <Button
                        onPress={(event) => {
                            this.onSubmit(event, false, mytaskPricingRules);
                        }}
                        title="Confirm"
                    />
                    <Button
                        title="Cancel"
                        onPress={() => this.setState({ modalVisible: null })}
                    />
                </Flex>
            </Box>
        </Box>
    );

    scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    resetForm = () => {
        Object.keys(this.state.formData).map((key) => {
            this.setState({
                formData: {
                    [key]: '',
                },
            });
        });
        Object.keys(this.state.formErrors).map((key) => {
            this.setState({
                formErrors: {
                    [key]: '',
                },
            });
        });
        //restore initial values
        this.setState({
            formData: { RejectionButton: false },
        });
    };

    selectFiles = (events) => {
        event.preventDefault();

        const { selectedFilesIds, selectedFiles } = this.state;
        const id = events.target.files[0].name;

        this.setState({
            selectedFiles: {
                ...selectedFiles,
                [id]: {
                    data: events.target.files[0],
                    DocumentName: events.target.files[0].name,
                    DocumentType: 0,
                },
            },
            selectedFilesIds: [...selectedFilesIds, id],
            filename: events.target.files[0].name,
            fileinputkey: Date.now(),
        });
    };

    removeFile = (id) => {
        event.preventDefault();

        var selectedFilesDict = this.state.selectedFiles;
        var selectedFilesIdsList = this.state.selectedFilesIds;
        // remove the existing state
        delete selectedFilesDict[id];
        selectedFilesIdsList = selectedFilesIdsList.filter((fileid) => fileid !== id);

        this.setState({
            selectedFiles: selectedFilesDict,
            selectedFilesIds: selectedFilesIdsList,
        });
    };

    render() {
        const {
            width,
            location,
            history: { action },
            functionalGroupDetails: {
                DocumentLocation: files,
                Customer: globalMdmDetail = {},
                Pricing: pricingDetail = null,
                RequestorName,
                TaskNote: Notes = '',
                TaskNoteData,
            },
            statusBarData,
            alert = {},
            TasksStatusByTeamId = null,
        } = this.props;
        const { dropDownDatas, teamRoleFieldMapping } = this.state;

        const { state } = location;
        let tasknotes = TaskNoteData || [];

        const PricingTeamFields =
            idx(teamRoleFieldMapping, (_) => _.PricingTeamFields) || [];
        const RoleTypeId = globalMdmDetail.RoleTypeId;
        const SystemTypeId = globalMdmDetail.SystemTypeId;
        const allowedFieldList = PricingTeamFields.filter(
            (item) => item.RoleTypeId === RoleTypeId && item.SystemTypeId === SystemTypeId
        );
        let allowedFields = undefined;
        if (allowedFieldList && allowedFieldList.length > 0) {
            allowedFields = allowedFieldList[0].Fields;
        }

        const workflow = {
            ...state,
            isReadOnly:
                TasksStatusByTeamId === null ||
                !(
                    globalMdmDetail.WorkflowStateTypeId === 2 &&
                    TasksStatusByTeamId[8].WorkflowTaskStateTypeId === 2
                ),
        };

        const requestDateUtc = moment(globalMdmDetail.DateOfCreation);
        const localDate = moment(requestDateUtc).local();
        const requestDate = localDate.format('YYYY-MM-DD hh:mm:ss A');

        const inputReadonlyProps = workflow.isReadOnly ? { disabled: true } : null;

        const showFunctionalDetail =
            state.isReadOnly && pricingDetail === null ? { display: 'none' } : null;

        const showButtons = workflow.isReadOnly ? { display: 'none' } : null;
        var isReleased = true;
        for (let i = 0; i < statusBarData.length; i++) {
            let teamstatus = statusBarData[i];
            if (teamstatus.WorkflowTaskStateTypeId === 2) {
                isReleased = false;
                break;
            } else {
                isReleased = true;
            }
        }
        var bgcolor = alert.color || '#FFF';
        if (this.props.fetching) {
            return <Loading />;
        }
        if (this.props.fetchingfnGroupData) {
            return <Loading />;
        }

        const { selectedFilesIds, selectedFiles } = this.state;

        return (
            <ScrollView
                keyboardShouldPersistTaps="always"
                style={{
                    backgroundColor: '#EFF3F6',
                    paddingBottom: 75,
                }}>
                <Modal isVisible={this.state.modalVisible}>
                    {this._renderModalContent()}
                </Modal>
                {alert.display && (
                    <FlashMessage
                        bg={{ backgroundColor: bgcolor }}
                        message={alert.message}
                    />
                )}
                <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between">
                    <Text
                        style={{
                            fontWeight: '500',
                            fontSize: 24,
                            color: Colors.lightBlue,
                            marginTop: 25,
                            marginBottom: 20,
                            paddingLeft: 45,
                        }}>
                        MY TASKS - {state.Header}
                    </Text>
                    <Box
                        marginTop="25px"
                        flex={3 / 4}
                        flexDirection="row-reverse"
                        alignItems="flex-end">
                        <MultiColorProgressBar readings={statusBarData} />
                    </Box>
                </Box>
                <Box marginTop="25px" flexDirection="row-reverse" alignItems="flex-end">
                    <StyledText
                        style={{
                            fontWeight: '600',
                            paddingRight: '20px',
                            fontSize: '16px',
                            color: '#10206C',
                        }}>
                        {' '}
                        Requestor : {RequestorName}{' '}
                    </StyledText>
                </Box>
                <Box flexDirection="row-reverse" alignItems="flex-end">
                    <StyledText
                        style={{
                            fontWeight: '600',
                            paddingRight: '20px',
                            fontSize: '16px',
                            color: '#10206C',
                        }}>
                        {' '}
                        Date of Request: {requestDate}{' '}
                    </StyledText>
                </Box>
                <View
                    style={{
                        flex: 1,
                        paddingHorizontal: width < 1440 ? 60 : width * 0.1,
                        paddingBottom: 10,
                    }}>
                    <Box fullHeight my={2}>
                        <Box flexDirection="row" justifyContent="space-around" my={4}>
                            <FormInput
                                px="25px"
                                flex={1 / 4}
                                label="WorkflowTitle"
                                maxLength={40}
                                name="WorkflowTitle"
                                variant="outline"
                                type="text"
                                value={globalMdmDetail && globalMdmDetail.WorkflowTitle}
                            />
                            <FormInput
                                px="25px"
                                flex={1 / 4}
                                label="Workflow Number"
                                name="workflow-number"
                                variant="outline"
                                type="text"
                                value={globalMdmDetail && globalMdmDetail.WorkflowId}
                            />
                            <FormInput
                                px="25px"
                                flex={1 / 4}
                                label="Customer Number"
                                name="CustomerNumber"
                                variant="outline"
                                type="text"
                                value={globalMdmDetail && globalMdmDetail.SystemRecordId}
                            />

                            {globalMdmDetail &&
                            globalMdmDetail.MdmCustomerId &&
                            isReleased ? (
                                <TouchableOpacity
                                    onPress={() =>
                                        this.props.history.push(
                                            `/search-results/${globalMdmDetail.MdmCustomerId}`
                                        )
                                    }>
                                    <FormInput
                                        px="25px"
                                        flex={1 / 4}
                                        label="MDM Number"
                                        name="mdm-number"
                                        variant="outline"
                                        type="text"
                                        value={
                                            globalMdmDetail &&
                                            globalMdmDetail.MdmCustomerId
                                        }
                                    />
                                </TouchableOpacity>
                            ) : (
                                <FormInput
                                    px="25px"
                                    flex={1 / 4}
                                    label="MDM Number"
                                    name="mdm-number"
                                    variant="outline"
                                    type="text"
                                    value={
                                        globalMdmDetail && globalMdmDetail.MdmCustomerId
                                    }
                                />
                            )}
                        </Box>
                        <GlobalMdmFields
                            formData={globalMdmDetail}
                            staticDropDownData={this.props.staticData}
                            readOnly
                        />

                        <Text
                            mt="42px"
                            mb="20px"
                            fontWeight="regular"
                            color="lightBlue"
                            fontSize={24}
                            pl={4}>
                            SYSTEM FIELDS
                        </Text>
                        <Box flexDirection="row" justifyContent="center">
                            <Box width={1 / 2} mx="auto" alignItems="center">
                                <FormInput
                                    label="System"
                                    name="System"
                                    inline
                                    variant="outline"
                                    type="text"
                                    value={
                                        SystemType[
                                            globalMdmDetail &&
                                                globalMdmDetail.SystemTypeId
                                        ]
                                    }
                                />
                                <FormInput
                                    label="Role"
                                    name="Role"
                                    inline
                                    variant="outline"
                                    type="text"
                                    value={
                                        RoleType[
                                            globalMdmDetail && globalMdmDetail.RoleTypeId
                                        ]
                                    }
                                />
                                <FormInput
                                    label="Sales Org"
                                    name="SalesOrg"
                                    inline
                                    variant="outline"
                                    type="text"
                                    value={
                                        SalesOrgType[
                                            globalMdmDetail &&
                                                globalMdmDetail.SalesOrgTypeId
                                        ]
                                    }
                                />
                                <FormInput
                                    label="Purpose of Request"
                                    name="Purpose"
                                    value={globalMdmDetail && globalMdmDetail.Purpose}
                                    multiline
                                    numberOfLines={
                                        globalMdmDetail.Purpose &&
                                        globalMdmDetail.Purpose.length > 36
                                            ? 3
                                            : 1
                                    }
                                    style={{ lineHeight: '2.5' }}
                                    inline
                                    variant="outline"
                                    maxLength={1000}
                                    type="text"
                                />
                            </Box>
                            <Box width={1 / 2} mx="auto" alignItems="center">
                                <FormInput
                                    label="Distribution Channel"
                                    name="DistributionChannel"
                                    inline
                                    variant="outline"
                                    type="text"
                                    value={
                                        DistributionChannelType[
                                            globalMdmDetail &&
                                                globalMdmDetail.DistributionChannelTypeId
                                        ]
                                    }
                                />
                                <FormInput
                                    label="Division"
                                    name="DivisionTypeId"
                                    inline
                                    variant="outline"
                                    type="text"
                                    value={
                                        DivisionType[
                                            globalMdmDetail &&
                                                globalMdmDetail.DivisionTypeId
                                        ]
                                    }
                                />
                                <FormInput
                                    label="CompanyCode"
                                    name="CompanyCodeTypeId"
                                    inline
                                    variant="outline"
                                    type="text"
                                    value={
                                        CompanyCodeType[
                                            globalMdmDetail &&
                                                globalMdmDetail.CompanyCodeTypeId
                                        ]
                                    }
                                />
                            </Box>
                        </Box>

                        <Box {...showFunctionalDetail}>
                            <Text
                                mt="42px"
                                mb="20px"
                                fontWeight="regular"
                                color="lightBlue"
                                fontSize={24}
                                pl={4}>
                                PRICING FIELDS
                            </Text>
                            <Box flexDirection="row" justifyContent="center">
                                <Box width={1 / 2} mx="auto" alignItems="center">
                                    <DynamicSelect
                                        arrayOfData={dropDownDatas.SpecialPricingTypeId}
                                        label="Special Pricing"
                                        name="SpecialPricingTypeId"
                                        hide={
                                            allowedFields &&
                                            !allowedFields.includes(
                                                'SpecialPricingTypeId'
                                            )
                                        }
                                        formErrors={
                                            this.state.formErrors
                                                ? this.state.formErrors[
                                                      'SpecialPricingTypeId'
                                                  ]
                                                : null
                                        }
                                        onFieldChange={this.onFieldChange}
                                        value={
                                            workflow.isReadOnly
                                                ? pricingDetail &&
                                                  parseInt(
                                                      pricingDetail.SpecialPricingTypeId
                                                  )
                                                : this.state.formData
                                                ? this.state.formData[
                                                      'SpecialPricingTypeId'
                                                  ]
                                                : null
                                        }
                                        inputProps={inputReadonlyProps}
                                    />
                                    <DynamicSelect
                                        arrayOfData={dropDownDatas.DAPAPricingTypeId}
                                        label="DAPA Pricing"
                                        name="DAPAPricingTypeId"
                                        hide={
                                            allowedFields &&
                                            !allowedFields.includes('DAPAPricingTypeId')
                                        }
                                        formErrors={
                                            this.state.formErrors
                                                ? this.state.formErrors[
                                                      'DAPAPricingTypeId'
                                                  ]
                                                : null
                                        }
                                        onFieldChange={this.onFieldChange}
                                        value={
                                            workflow.isReadOnly
                                                ? pricingDetail &&
                                                  parseInt(
                                                      pricingDetail.DAPAPricingTypeId
                                                  )
                                                : this.state.formData
                                                ? this.state.formData['DAPAPricingTypeId']
                                                : null
                                        }
                                        inputProps={inputReadonlyProps}
                                    />
                                    <DynamicSelect
                                        arrayOfData={dropDownDatas.DAPA2PricingTypeId}
                                        label="DAPA2 Pricing"
                                        name="DAPA2PricingTypeId"
                                        hide={
                                            allowedFields &&
                                            !allowedFields.includes('DAPA2PricingTypeId')
                                        }
                                        formErrors={
                                            this.state.formErrors
                                                ? this.state.formErrors[
                                                      'DAPA2PricingTypeId'
                                                  ]
                                                : null
                                        }
                                        onFieldChange={this.onFieldChange}
                                        value={
                                            workflow.isReadOnly
                                                ? pricingDetail &&
                                                  parseInt(
                                                      pricingDetail.DAPA2PricingTypeId
                                                  )
                                                : this.state.formData
                                                ? this.state.formData[
                                                      'DAPA2PricingTypeId'
                                                  ]
                                                : null
                                        }
                                        inputProps={inputReadonlyProps}
                                    />
                                </Box>
                                <Box width={1 / 2} mx="auto" alignItems="center">
                                    <DynamicSelect
                                        arrayOfData={dropDownDatas.DistLevelTypeId}
                                        label="Dist Level Pricing"
                                        name="DistLevelTypeId"
                                        hide={
                                            allowedFields &&
                                            !allowedFields.includes('DistLevelTypeId')
                                        }
                                        formErrors={
                                            this.state.formErrors
                                                ? this.state.formErrors['DistLevelTypeId']
                                                : null
                                        }
                                        onFieldChange={this.onFieldChange}
                                        value={
                                            workflow.isReadOnly
                                                ? pricingDetail &&
                                                  parseInt(pricingDetail.DistLevelTypeId)
                                                : this.state.formData
                                                ? this.state.formData['DistLevelTypeId']
                                                : null
                                        }
                                        inputProps={inputReadonlyProps}
                                    />
                                    <DynamicSelect
                                        arrayOfData={dropDownDatas.DAPA2PricingTypeId}
                                        label="FSS Pricing"
                                        name="FSSPricingTypeId"
                                        hide={
                                            allowedFields &&
                                            !allowedFields.includes('FSSPricingTypeId')
                                        }
                                        formErrors={
                                            this.state.formErrors
                                                ? this.state.formErrors[
                                                      'FSSPricingTypeId'
                                                  ]
                                                : null
                                        }
                                        onFieldChange={this.onFieldChange}
                                        value={
                                            workflow.isReadOnly
                                                ? pricingDetail &&
                                                  parseInt(pricingDetail.FSSPricingTypeId)
                                                : this.state.formData
                                                ? this.state.formData['FSSPricingTypeId']
                                                : null
                                        }
                                        inputProps={inputReadonlyProps}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    {files && <FilesList files={files} readOnly />}
                    {workflow.isReadOnly ? (
                        <>
                            <Box my={4}>
                                <Box flexDirection="row" justifyContent="center">
                                    <Box width={1 / 2} mx="auto" alignItems="center">
                                        <FormInput
                                            label="Notes"
                                            multiline
                                            numberOfLines={2}
                                            inputWidth="100%"
                                            name="additionalNotes"
                                            variant="solid"
                                            type="text"
                                            error={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                          'additionalNotes'
                                                      ]
                                                    : null
                                            }
                                            value={
                                                workflow.isReadOnly
                                                    ? Notes
                                                    : this.state.formData
                                                    ? this.state.formData[
                                                          'AdditionalNotes'
                                                      ]
                                                    : null
                                            }
                                            variant={
                                                workflow.isReadOnly ? 'outline' : 'solid'
                                            }
                                            inline={workflow.isReadOnly ? true : false}
                                        />
                                    </Box>

                                    <Box
                                        width={1 / 2}
                                        mx="auto"
                                        alignItems="center"></Box>
                                </Box>
                            </Box>
                        </>
                    ) : (
                        <FilesList
                            title="NEW ATTACHMENTS"
                            formErrors={this.state.fileErrors}
                            files={selectedFilesIds.map((id) => selectedFiles[id])}
                            onChange={(value, id) => {
                                this.setState({
                                    selectedFiles: {
                                        ...selectedFiles,
                                        [id]: {
                                            ...selectedFiles[id],
                                            DocumentType: parseInt(value),
                                        },
                                    },
                                });
                            }}
                            onRemove={(value, id) => this.removeFile(id)}
                        />
                    )}

                    <Box flexDirection="row" justifyContent="center">
                        <Box ml="5%" width={1 / 2}>
                            {tasknotes && tasknotes.length > 0 && (
                                <TaskNotes taskNotes={tasknotes} />
                            )}
                        </Box>
                        <Box width={1 / 2} mx="auto" alignItems="center" />
                    </Box>
                    <Box {...showButtons}>
                        <Flex
                            justifyEnd
                            alignCenter
                            style={{
                                paddingTop: 15,
                                flexDirection: 'row',
                                alignItems: 'center',
                                paddingLeft: 10,
                                paddingRight: 15,
                                marginTop: 20,
                                marginBottom: 10,
                                marginHorizontal: 25,
                            }}>
                            <label htmlFor="file-upload" className="custom-file-upload">
                                <MaterialIcons
                                    name="attach-file"
                                    size={18}
                                    color="#fff"
                                />
                            </label>
                            <input
                                id="file-upload"
                                key={this.state.fileinputkey}
                                type="file"
                                onChange={this.selectFiles}
                                multiple
                            />
                            <Button
                                onPress={() =>
                                    this.setState({
                                        isReject: false,
                                        modalVisible: true,
                                    })
                                }
                                title="Release"
                            />
                        </Flex>
                    </Box>
                </View>
            </ScrollView>
        );
    }
}

class Default extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const props = this.props;

        return (
            <DimensionAware
                render={(dimensions) => (
                    <Page
                        {...{
                            ...props,
                            width: getWindowWidth(dimensions),
                            height: getWindowHeight(dimensions),
                            marginBottom: 25,
                        }}
                    />
                )}
            />
        );
    }
}

const mapStateToProps = ({ workflows, myTasks, staticData }) => {
    const { fetching, alert } = myTasks;
    const {
        fetchingfnGroupData,
        statusBarData,
        functionalGroupDetails,
        TasksStatusByTeamId,
        fetchingStatusBar,
    } = workflows;
    const { data, fetching: staticFetching } = staticData;
    return {
        fetching: fetching || staticFetching || fetchingStatusBar || fetchingfnGroupData,
        alert,
        statusBarData,
        functionalGroupDetails,
        TasksStatusByTeamId,
        staticData: data,
    };
};

export default connect(mapStateToProps, {
    saveApolloMyTaskPricing,
    getFunctionalGroupData,
    getStatusBarData,
    getStaticRequest,
})(Default);

const styles = StyleSheet.create({
    progressIndicator: {
        flex: 1,
        paddingBottom: 5,
        flexDirection: 'row-reverse',
        alignItems: 'flex-end',
    },
    statusText: {
        fontSize: 15,
        color: '#1D4289',
        fontFamily: 'Poppins',
        textAlign: 'center',
        marginTop: 20,
    },
});
