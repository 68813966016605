//General
export const SUCCESS_BGCOLOR = '#27ae60';
export const FAILED_BGCOLOR = '#c0392b';

export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';

// auth
export const AUTH_USER = 'AUTH_USER';
export const AUTH_USER_SUCCESS = 'AUTH_USER_SUCCESS';
export const AUTH_USER_FAILED = 'AUTH_USER_FAILED';
export const LOGGED_IN_STATUS_CHANGED = 'LOGGED_IN_STATUS_CHANGED';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const LOGOUT = 'LOGOUT';

export const GET_STATIC_DATA_REQUEST = 'GET_STATIC_DATA_REQUEST';
export const GET_STATIC_DATA_SUCCESS = 'GET_STATIC_DATA_SUCCESS';
export const GET_STATIC_DATA_FAIL = 'GET_STATIC_DATA_FAIL';

//create customer
export const CREATE_CUSTOMER_REQUEST = 'CREATE_CUSTOMER_REQUEST';
export const CREATE_CUSTOMER_SUCCESS = 'CREATE_CUSTOMER_SUCCESS';
export const CREATE_CUSTOMER_FAILURE = 'CREATE_CUSTOMER_FAILURE';

//my requests
export const GET_MYREQUESTS = 'GET_MYREQUESTS';
export const GET_MYREQUESTS_SUCCESS = 'GET_MYREQUESTS_SUCCESS';
export const GET_MYREQUESTS_FAILURE = 'GET_MYREQUESTS_FAILURE';

export const WITHDRAW_REQUEST = 'WITHDRAW_REQUEST';
export const WITHDRAW_REQUESTS_SUCCESS = 'WITHDRAW_REQUESTS_SUCCESS';
export const WITHDRAW_REQUESTS_FAILURE = 'WITHDRAW_REQUESTS_FAILURE';

// file attachments
export const UPLOAD_FILE = 'UPLOAD_FILE';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_FAILURE = 'UPLOAD_FILE_FAILURE ';

//notification
export const ADD_TOAST = 'ADD_TOAST';
export const REMOVE_TOAST = 'REMOVE_TOAST';
export const QUEUE_REMOVE = 'QUEUE_REMOVE';

// Customer
export const SEARCH_CUSTOMER = 'SEARCH_CUSTOMER';
export const SEARCH_CUSTOMER_SUCCESS = 'SEARCH_CUSTOMER_SUCCESS';
export const SEARCH_CUSTOMER_FAIL = 'SEARCH_CUSTOMER_FAIL';
export const EXPORT_CSV_DATA = 'EXPORT_CSV_DATA';
export const UPDATE_CSV_DATA = 'UPDATE_CSV_DATA';
export const ADVANCE_SEARCH_CUSTOMER = 'ADVANCE_SEARCH_CUSTOMER';
export const ADVANCE_SEARCH_CUSTOMER_SUCCESS = 'ADVANCE_SEARCH_CUSTOMER_SUCCESS';
export const ADVANCE_SEARCH_CUSTOMER_FAIL = 'ADVANCE_SEARCH_CUSTOMER_FAIL';
export const SELECT_RECORD = 'SELECT_RECORD';
export const SELECT_WORKFLOW = 'SELECT_WORKFLOW';

export const GET_CUSTOMER_DETAIL = 'GET_CUSTOMER_DETAIL';
export const GET_CUSTOMER_DETAIL_SUCCESS = 'GET_CUSTOMER_DETAIL_SUCCESS';
export const GET_CUSTOMER_FROM_SAP = 'GET_CUSTOMER_FROM_SAP';
export const RETRIEVE_CUSTOMER_FROM_SAP_SUCCESS = 'RETRIEVE_CUSTOMER_FROM_SAP_SUCCESS';
export const CUSTOMER_ACTION_MESSAGE = 'CUSTOMER_ACTION_MESSAGE';

export const FETCH_DUNS_FIELDS_REQUEST = 'FETCH_DUNS_FIELDS_REQUEST';
export const FETCH_DUNS_FIELDS_SUCCESS = 'FETCH_DUNS_FIELDS_SUCCESS';
export const FETCH_DUNS_FIELDS_FAILED = 'FETCH_DUNS_FIELDS_FAILED';

export const FETCH_MDM_PARENT_DATA_DUNS = 'FETCH_MDM_PARENT_DATA_DUNS';
export const FETCH_MDM_PARENT_DATA_DUNS_RESULT = 'FETCH_MDM_PARENT_DATA_DUNS_RESULT';

// workflow Mytask S1 screen (grid)
export const GET_WORKFLOW = 'GET_WORKFLOW';
export const GET_WORKFLOW_SUCCESS = 'GET_WORKFLOW_SUCCESS';
export const GET_WORKFLOW_FAILURE = 'GET_WORKFLOW_FAILURE';
export const GET_STATUS_BAR_DATA = 'GET_STATUS_BAR_DATA';
export const SET_STATUS_BAR_DATA = 'SET_STATUS_BAR_DATA';
export const UPDATE_TASK_STATUS = 'UPDATE_TASK_STATUS';
export const GET_TEAM_USERLIST = 'GET_TEAM_USERLIST';
export const GET_TEAM_USERLIST_SUCCESS = 'GET_TEAM_USERLIST_SUCCESS';
export const GET_FUCTIONAL_GROUP_DATA = 'GET_FUCTIONAL_GROUP_DATA';
export const SET_FUCTIONAL_GROUP_DATA = 'SET_FUCTIONAL_GROUP_DATA';
export const ASSIGN_USER = 'ASSIGN_USER';
export const ASSIGN_USER_SUCCESS = 'ASSIGN_USER_SUCCESS';
export const UN_ASSIGN_USER = 'UN_ASSIGN_USER';
export const UN_ASSIGN_USER_SUCCESS = 'UN_ASSIGN_USER_SUCCESS';

//customer ~ my tasks customer master
export const SAVE_APOLLO_CUSTOMER_MASTER = 'SAVE_APOLLO_CUSTOMER_MASTER';
export const SAVE_APOLLO_CONTRACTS = 'SAVE_APOLLO_CONTRACTS';
export const SAVE_APOLLO_CREDIT = 'SAVE_APOLLO_CREDIT';
export const SAVE_APOLLO_PRICING = 'SAVE_APOLLO_PRICING';
export const SAVE_APOLLO_GLOBALTRADE = 'SAVE_APOLLO_GLOBALTRADE';
export const GET_TAX_JURISDICTION = 'GET_TAX_JURISDICTION';
export const SET_TAX_JURISDICTION = 'SET_TAX_JURISDICTION';
export const RELEASE_CHECKLIST = 'RELEASE_CHECKLIST';
export const EXTEND_RELEASE_CHECKLIST = 'EXTEND_RELEASE_CHECKLIST';
// update process flow
export const GET_MDM_MAPPING_MATRIX = 'GET_MDM_MAPPING_MATRIX';
export const SET_MDM_MAPPING_MATRIX = 'SET_MDM_MAPPING_MATRIX';
export const UPDATE_DELTAS = 'UPDATE_DELTAS';
export const UPDATE_DELTAS_STATUS = 'UPDATE_DELTAS_STATUS';
export const UPDATE_EXTEND_SALES_ORG = 'UPDATE_EXTEND_SALES_ORG';

export const APPROVE_UPDATE_REQUEST = 'APPROVE_UPDATE_REQUEST';
export const APPROVE_UPDATE_SUCCESS = 'APPROVE_UPDATE_SUCCESS';
export const APPROVE_UPDATE_FAILED = 'APPROVE_UPDATE_FAILED';

export const SAVE_APOLLO_UPDATE_CUSTOMER_MASTER = 'SAVE_APOLLO_UPDATE_CUSTOMER_MASTER';
export const SAVE_APOLLO_UPDATE_CONTRACTS = 'SAVE_APOLLO_UPDATE_CONTRACTS';
export const SAVE_APOLLO_UPDATE_CREDIT = 'SAVE_APOLLO_UPDATE_CREDIT';
export const SAVE_APOLLO_UPDATE_PRICING = 'SAVE_APOLLO_UPDATE_PRICING';
export const SAVE_APOLLO_UPDATE_GLOBALTRADE = 'SAVE_APOLLO_UPDATE_GLOBALTRADE';
export const SAVE_APOLLO_UPDATE_GLOBAL = 'SAVE_APOLLO_UPDATE_GLOBAL';

export const READ_REDSHIFT_REQUEST = 'READ_REDSHIFT_REQUEST';
export const READ_REDSHIFT_SUCCESS = 'READ_REDSHIFT_SUCCESS';

//Block - Unblock
export const REQUEST_BLOCK = 'REQUEST_BLOCK';
export const REQUEST_BLOCK_SUCCESS = 'REQUEST_BLOCK_SUCCESS';

export const OTHER_REQUEST = 'OTHER_REQUEST';
export const OTHER_REQUEST_SUCCESS = 'OTHER_REQUEST_SUCCESS';
export const OTHER_REQUEST_FAILED = 'OTHER_REQUEST_FAILED';

export const SAVE_OTHER_REQUEST = 'SAVE_OTHER_REQUEST';

//Partners
export const GET_POINTMAN_PARTNERS = 'GET_POINTMAN_PARTNERS';
export const GET_POINTMAN_PARTNERS_SUCCESS = 'GET_POINTMAN_PARTNERS_SUCCESS';
export const GET_POINTMAN_PARTNERS_FAILURE = 'GET_POINTMAN_PARTNERS_FAILURE';
export const ADD_NEW_PARTNER = 'ADD_NEW_PARTNER';
export const FETCH_PARTNER_DETAILS = 'FETCH_PARTNER_DETAILS';
export const FETCH_PARTNER_DETAILS_SUCCESS = 'FETCH_PARTNER_DETAILS_SUCCESS';
export const GET_APOLLO_PARTNERS = 'GET_APOLLO_PARTNERS';
export const GET_APOLLO_PARTNERS_SUCCESS = 'GET_APOLLO_PARTNERS_SUCCESS';
export const GET_APOLLO_PARTNERS_FAILURE = 'GET_APOLLO_PARTNERS_FAILURE';
export const GET_WF_APOLLO_PARTNERS = 'GET_WF_APOLLO_PARTNERS';
export const GET_WF_APOLLO_PARTNERS_SUCCESS = 'GET_WF_APOLLO_PARTNERS_SUCCESS';
export const GET_WF_APOLLO_PARTNERS_FAILURE = 'GET_WF_APOLLO_PARTNERS_FAILURE';
export const CREATE_APOLLO_PARTNER_REQUEST = 'CREATE_APOLLO_PARTNER_REQUEST';
export const CREATE_APOLLO_PARTNER_REQUEST_SUCCESS =
    'CREATE_APOLLO_PARTNER_REQUEST_SUCCESS';
export const APPROVE_APOLLO_PARTNERS = 'APPROVE_APOLLO_PARTNERS';
export const CLEAR_PARTNERS = 'CLEAR_APPROVE_PARTNERS';
export const CREATE_APOLLO_PARTNER_FAIL = 'CREATE_APOLLO_PARTNER_FAIL';
export const APPROVE_APOLLO_PARTNER_REQUEST_SUCCESS = 'APPROVE_APOLLO_PARTNERS_SUCCESS';
export const APPROVE_APOLLO_PARTNER_REQUEST_FAIL = 'APPROVE_APOLLO_PARTNERS_FAIL';
export const CLEAR_PARTNEREDITSAFTERSAVE = 'CLEAR_PARTNEREDITSAFTERSAVE';
export const CLEAR_TOAST = 'CLEAR_TOAST';
export const GET_JDE_PARTNERS = 'GET_JDE_PARTNERS';
export const GET_JDE_PARTNERS_SUCCESS = 'GET_JDE_PARTNERS_SUCCESS';
export const GET_JDE_PARTNERS_FAIL = 'GET_JDE_PARTNERS_FAIL';
export const SEARCH_JDE_PARTNERS = 'SEARCH_JDE_PARTNERS';
export const SEARCH_JDE_PARTNERS_SUCCESS = 'SEARCH_JDE_PARTNERS_SUCCESS';
export const SEARCH_JDE_PARTNERS_FAIL = 'SEARCH_JDE_PARTNERS_FAIL';
export const GET_WF_JDE_PARTNERS = 'GET_WF_JDE_PARTNERS';
export const GET_WF_JDE_PARTNERS_SUCCESS = 'GET_WF_JDE_PARTNERS_SUCCESS';
export const GET_WF_JDE_PARTNERS_FAILURE = 'GET_WF_JDE_PARTNERS_FAILURE';
