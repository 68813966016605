import React from 'react';
import { ScrollView, View, StyleSheet, TouchableOpacity } from 'react-native';
import {
    DimensionAware,
    getWindowHeight,
    getWindowWidth,
} from 'react-native-dimension-aware';
import { connect } from 'react-redux';
import * as _ from 'lodash';
import { Colors, StyledText } from '../../../../theme/Index';
import { Flex, Button, Box, Text } from '../../../../components/common';
import { FormInput } from '../../../../components/form';
import { saveApolloMyTaskCredit, getStaticRequest } from '../../../../appRedux/actions';
import {
    getStatusBarData,
    getFunctionalGroupData,
} from '../../../../appRedux/actions/Workflow';
import { getMockUpdateTaskDetail } from '../../../../appRedux/sagas/config';
import { saveApolloUpdateMyTaskCredit } from '../../../../appRedux/actions/UpdateFlowAction';
import { removeMessage } from '../../../../appRedux/actions/Toast';
import { yupAllFieldsValidation } from '../../../../constants/utils';
import GlobalMdmFields from '../../../../components/GlobalMdmFields';
import {
    mdmFieldsRules,
    myTaskCreditExtend,
    mytaskCreditRules,
    mytaskPricingRules,
    rejectRules,
} from '../../../../constants/FieldRules';
import {
    RoleType,
    SalesOrgType,
    SystemType,
    DistributionChannelType,
    DivisionType,
    CompanyCodeType,
    CategoryTypesById,
} from '../../../../constants/WorkflowEnums';
import MultiColorProgressBar from '../../../../components/MultiColorProgressBar';
import {
    fetchCreditDropDownData,
    fetchCustomerMasterDropDownData,
    fetchTeamRoleFieldMapping,
} from '../../../../redux/DropDownDatas';
import Loading from '../../../../components/Loading';
import FlashMessage, { FlashMessages } from '../../../../components/FlashMessage';
import DeltaField from '../../../../components/DeltaField';
import { getCustomerFromSAP } from '../../../../appRedux/actions';
import idx from 'idx';
import BreadCrumbs from '../../../../components/BreadCrumbs';
import Modal from 'modal-enhanced-react-native-web';
import FilesList from '../../../../components/FilesList';
import { MaterialIcons } from '@expo/vector-icons';
import DynamicSelect from '../../../../components/DynamicSelect';
import { backgroundPosition } from 'styled-system';
import * as moment from 'moment';

class Page extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            WorkflowId: this.props.location.state.WorkflowId,
            TaskId: this.props.location.state.TaskId,
            data: {},
            reject: false,
            dropDownDatas: {},
            dropdowns: {},
            teamRoleFieldMapping: {},
            updatedFormData: {},
            formData: { CreditLimit: '1', RejectionButton: false, RejectionReason: '' },
            formErrors: {},
            isReject: false,
            modalVisible: false,
            selectedFiles: {},
            selectedFilesIds: [],
            files: [],
            fileinputkey: Date.now(),
            extendSalesOrgEnabled: false,
        };
    }

    componentDidMount() {
        let { state: wf } = this.props.location;
        let postJson = {
            workflowId: wf.WorkflowId,
            fuctionalGroup: 'credit',
            taskId: wf.TaskId,
        };
        const { action, id } = this.props.match.params;
        this.props.getStaticRequest([15, 30, 42, 43, 82]);
        this.props.getStatusBarData(postJson, true);
        // this.props.getFunctionalGroupData(postJson);
        this.props.getCustomerFromSAP({
            WorkflowId: wf.WorkflowId,
            TaskId: wf.TaskId,
            CustomerNumber: '',
            DivisionTypeId: 0,
            SystemTypeId: 0,
            DistributionChannelTypeId: 0,
            CompanyCodeTypeId: '',
            SalesOrgTypeId: 0,
        });
        fetchCreditDropDownData(true, true).then(({ normalizedDropDowns, dropdowns }) => {
            const data = normalizedDropDowns;
            let isReqPage = action === 'update-request';
            let pageReadOnlyFields = action !== 'update'; //set readonly for my-requests & extend sales org & BlockUnblock .
            this.setState({
                dropDownDatas: data,
                dropdowns: dropdowns,
                readOnly: pageReadOnlyFields,
                isRequestPage: isReqPage,
                currentPage: action,
                extendSalesOrgEnabled: action === 'extend-salesorg',
            });
        });

        fetchTeamRoleFieldMapping().then((resp) => {
            const data = resp;
            this.setState({ teamRoleFieldMapping: data });
        });
    }

    componentWillReceiveProps(newProps) {
        let { action } = this.props.match.params;

        if (action === 'extend-salesorg') {
            if (
                newProps.bapi70CustData !== this.props.bapi70CustData &&
                !this.state.isWorkFlowReadOnly &&
                newProps.bapi70CustData !== {}
            ) {
                this.setState({
                    formData: {
                        ...newProps.bapi70CustData,
                        ...this.state.formData,
                    },
                });
            }
        }
    }

    setFormErrors = (errors) => {
        console.log(errors);
        const { formErrors } = this.state;
        this.setState({ formErrors: errors }, () => window.scrollTo(0, 0));
    };

    onFieldChange = (value, e) => {
        const { name } = e.target;
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: value,
            },
            updatedFormData: {
                ...this.state.updatedFormData,
                [name]: value,
            },
        });
    };

    handleDefaultValues = () => {
        let { formData } = this.state;
        let defaultValues = {};
        if (
            formData.CreditLimit === undefined ||
            formData.CreditLimit.trim().length === 0
        ) {
            defaultValues['CreditLimit'] = '1';
        }
        // if (
        //     formData.PaymentTermsTypeId === undefined ||
        //     formData.PaymentTermsTypeId === 0
        // ) {
        //     defaultValues['PaymentTermsTypeId'] = 3;
        // }
        return defaultValues;
    };

    createDeltas = (origData, newData) => {
        let addedKeys = [];
        var denormDeltas = this.props.denormalizedDeltas || [];
        for (const key in newData) {
            //check if orig data & new data doesnt duplicate empty string or null
            let orgiDataValue =
                origData[key] != null
                    ? origData[key].length != 0
                        ? origData[key]
                        : ''
                    : '';

            let newDataValue =
                newData[key] != null
                    ? newData[key].length != 0
                        ? newData[key].toString().trim()
                        : ''
                    : '';

            if (orgiDataValue != newDataValue && !addedKeys.includes(key)) {
                //check if the key is already present in the DenormDeltas
                for (let i = 0; i < denormDeltas.length; i++) {
                    if (denormDeltas[i]['Name'] == key) denormDeltas.splice(i, 1);
                }

                let delta = {};
                delta['Name'] = key;
                delta['OriginalValue'] = origData[key];
                delta['UpdatedValue'] = newData[key];
                addedKeys.push(key);
                denormDeltas.push(delta);
            }
        }
        return denormDeltas;
    };

    handleFormSubmission = (schema) => {
        let {
            TaskId,
            WorkflowId,
            formData,
            updatedFormData,
            selectedFiles,
            selectedFilesIds,
        } = this.state;

        const {
            RejectionReason: RejectReason = '',
            AdditionalNotes = '',
            ...updatedData
        } = updatedFormData;

        const { bapi70CustData = {} } = this.props;
        let { action } = this.props.match.params;
        let postData = {};
        try {
            const WorkflowTaskModel = {
                RejectReason,
                AdditionalNotes,
                TaskId,
                UserId: localStorage.getItem('userId'),
                WorkflowId,
            };
            if (action === 'update') {
                WorkflowTaskModel['WorkflowTaskOperationType'] = !formData[
                    'RejectionButton'
                ]
                    ? 1
                    : 4;
            } else if (action === 'extend-salesorg') {
                WorkflowTaskModel['WorkflowTaskOperationType'] = !formData[
                    'RejectionButton'
                ]
                    ? 1
                    : 2;
            }

            var delta = this.props.denormalizedDeltas;
            if (Object.keys(this.state.updatedFormData).length !== 0)
                delta = this.createDeltas(bapi70CustData, updatedData);

            console.log(delta);

            postData['formData'] = {
                WorkflowTaskModel,
                action,
                Deltas: delta || null,
            };
            postData['files'] = selectedFilesIds.map((id) => selectedFiles[id]);

            // console.log('state', this.state, 'orig', bapi70CustData, 'pos', postData);
            this.props.saveApolloUpdateMyTaskCredit(postData);
            this.resetForm();
            this.scrollToTop();
        } catch (error) {
            console.log('form validtion error');
        }
    };

    onSubmit = (event, reject, schema) => {
        const { action } = this.props.match.params;

        let { formData, selectedFilesIds, selectedFiles } = this.state;
        let defaults = this.handleDefaultValues();
        let fileErrors = {};
        let errors = false;
        selectedFilesIds.map((id) => {
            if (selectedFiles[id] && selectedFiles[id].DocumentType <= 0) {
                fileErrors[id] = 'Document Type Required for file';
                errors = true;
            }
        });
        this.setState({ fileErrors, isFileErrors: errors });
        let CategoryTypeId = this.props.bapi70CustData.CategoryTypeId;
        const RoleTypeId = this.props.bapi70CustData.RoleTypeId;
        const SystemTypeId = this.props.bapi70CustData.SystemType;
        const CreditTeamFields =
            idx(this.state.teamRoleFieldMapping, (_) => _.CreditTeamFields) || [];
        const allowedFieldList = CreditTeamFields.filter(
            (item) => item.RoleTypeId === RoleTypeId && item.SystemTypeId === SystemTypeId
        );
        let allowedFields = undefined;
        if (allowedFieldList && allowedFieldList.length > 0) {
            allowedFields = allowedFieldList[0].Fields;
        }
        let CustomerData = { ...this.state.formData };
        allowedFields.forEach((field) => {
            CustomerData[field] =
                this.state.formData[field] || this.props.bapi70CustData[field];
        });
        this.setState(
            {
                modalVisible: false,
                formData: {
                    ...this.state.formData,
                    ...defaults,
                    CategoryTypeId,
                    RejectionButton: reject,
                },
            },
            () => {
                if (reject)
                    yupAllFieldsValidation(
                        this.state.formData,
                        schema,
                        (...rest) => {
                            if (this.state.isFileErrors === false)
                                this.handleFormSubmission(...rest);
                        },
                        this.setFormErrors
                    );
                else if (this.state.isFileErrors === false) {
                    if (action === 'extend-salesorg') {
                        yupAllFieldsValidation(
                            CustomerData,
                            myTaskCreditExtend,
                            (...rest) => {
                                this.handleFormSubmission(...rest);
                            },
                            this.setFormErrors
                        );
                    } else {
                        this.handleFormSubmission(schema);
                    }
                }
            }
        );
    };

    selectFiles = (events) => {
        event.preventDefault();

        const { selectedFilesIds, selectedFiles } = this.state;
        const id = events.target.files[0].name;

        this.setState({
            selectedFiles: {
                ...selectedFiles,
                [id]: {
                    data: events.target.files[0],
                    DocumentName: events.target.files[0].name,
                    DocumentType: 0,
                },
            },
            selectedFilesIds: [...selectedFilesIds, id],
            filename: events.target.files[0].name,
            fileinputkey: Date.now(),
        });
    };

    removeFile = (id) => {
        event.preventDefault();

        var selectedFilesDict = this.state.selectedFiles;
        var selectedFilesIdsList = this.state.selectedFilesIds;
        // remove the existing state
        delete selectedFilesDict[id];
        selectedFilesIdsList = selectedFilesIdsList.filter((fileid) => fileid !== id);

        this.setState({
            selectedFiles: selectedFilesDict,
            selectedFilesIds: selectedFilesIdsList,
        });
    };

    scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    resetForm = () => {
        Object.keys(this.state.formData).map((key) => {
            this.setState({
                formData: {
                    [key]: '',
                },
            });
        });
        Object.keys(this.state.formErrors).map((key) => {
            this.setState({
                formErrors: {
                    [key]: '',
                },
            });
        });
        //restore initial values
        this.setState({
            formData: { CreditLimit: '1', RejectionButton: false },
        });
    };

    getValue = (name) => {
        const { bapi70CustData = {}, deltas = {} } = this.props;
        if (deltas[name]) {
            return deltas[name].UpdatedValue;
        } else if (bapi70CustData) {
            return bapi70CustData[name];
        }
    };

    getEditableValue = (name) => {
        const { bapi70CustData = {}, deltas = {} } = this.props;
        const { formData } = this.state;
        if (formData[name]) {
            return formData[name];
        } else if (deltas[name]) {
            return deltas[name].UpdatedValue;
        } else if (bapi70CustData) {
            return formData[name] || bapi70CustData[name];
        }
    };

    getDropDownValue = (name) => {
        const { bapi70CustData = {}, deltas = {} } = this.props;
        const { dropDownDatas } = this.state;
        if (deltas[name]) {
            return idx(dropDownDatas, (_) => _[name][deltas[name].UpdatedValue]);
        } else if (bapi70CustData) {
            return idx(dropDownDatas, (_) => _[name][bapi70CustData.name]);
        }
    };

    _renderModalContent = () => (
        <Box
            my={4}
            mx="30%"
            backgroundColor="white"
            borderRadius="4px"
            borderColor="rgba(0, 0, 0, 0.1)">
            <Text my={3} fontWeight="regular" color="lightBlue" fontSize={24} pl={4}>
                Submit Workflow
            </Text>
            <Box px="0px" pb="22px" justifyContent="center">
                <Box alignItems="center" justifyContent="center">
                    {!this.state.isReject ? (
                        <FormInput
                            label="Additional Notes"
                            multiline
                            numberOfLines={2}
                            maxLength={250}
                            name="AdditionalNotes"
                            type="text"
                            onChange={this.onFieldChange}
                            error={
                                this.state.formErrors
                                    ? this.state.formErrors['AdditionalNotes']
                                    : null
                            }
                            value={
                                this.state.formData &&
                                this.state.formData['AdditionalNotes']
                            }
                            variant={'solid'}
                        />
                    ) : (
                        <FormInput
                            label="Rejection Reason"
                            name="RejectionReason"
                            maxLength={250}
                            onChange={this.onFieldChange}
                            error={' *this field is required'}
                            multiline
                            numberOfLines={2}
                            type="text"
                            value={
                                this.state.formData &&
                                this.state.formData['RejectionReason']
                            }
                            variant="solid"
                        />
                    )}
                </Box>
                <Flex
                    justifyEnd
                    alignCenter
                    alignSelf="flex-end"
                    style={{
                        paddingTop: 15,
                        flexDirection: 'row',
                        alignItems: 'center',
                        paddingLeft: 10,
                        paddingRight: 15,
                        marginTop: 20,
                        marginBottom: 10,
                        marginHorizontal: 25,
                    }}>
                    <Button
                        onPress={(event) => {
                            if (this.state.isReject)
                                this.setState({ isReject: false }, () =>
                                    this.onSubmit(event, true, rejectRules)
                                );
                            else this.onSubmit(event, false, mytaskCreditRules);
                        }}
                        title="Submit"
                    />
                    <Button
                        title="Cancel"
                        onPress={() => this.setState({ modalVisible: null })}
                    />
                </Flex>
            </Box>
        </Box>
    );

    render() {
        const {
            width,
            location,
            bapi70CustData = {},
            deltas = {},
            statusBarData,
            alert = {},
            TasksStatusByTeamId = null,
        } = this.props;
        const Deltas = deltas;
        const {
            dropDownDatas,
            dropdowns = {},
            teamRoleFieldMapping,
            selectedFilesIds,
            selectedFiles,
        } = this.state;
        const { state } = location;
        const {
            WorkflowDocuments: files = [],
            RequestorName,
        } = this.props.bapi70CustData;
        const workflow = {
            ...state,
            isReadOnly:
                TasksStatusByTeamId === null ||
                !(
                    state.WorkflowStateType === 2 &&
                    TasksStatusByTeamId[4].WorkflowTaskStateTypeId === 2
                ),
        };

        const requestDateUtc = moment(idx(bapi70CustData, (_) => _.DateOfRequest));
        const localDate = moment(requestDateUtc).local();
        const requestDate = localDate.format('YYYY-MM-DD hh:mm:ss A');

        const CreditTeamFields =
            idx(teamRoleFieldMapping, (_) => _.CreditTeamFields) || [];
        const RoleTypeId = bapi70CustData.RoleTypeId;
        const SystemTypeId = bapi70CustData.SystemType;
        const allowedFieldList = CreditTeamFields.filter(
            (item) => item.RoleTypeId === RoleTypeId && item.SystemTypeId === SystemTypeId
        );
        let allowedFields = undefined;
        if (allowedFieldList && allowedFieldList.length > 0) {
            allowedFields = allowedFieldList[0].Fields;
            console.log('allowedFields', allowedFields);
        }

        const inputReadonlyProps = workflow.isReadOnly ? { disabled: true } : null;
        const showCreditDetail = null;

        const showButtons = workflow.isReadOnly ? { display: 'none' } : null;
        let disp_payterms = !!workflow.isReadOnly;
        if (bapi70CustData && bapi70CustData.CategoryTypeId != undefined) {
            var source_category = parseInt(bapi70CustData.CategoryTypeId);
            //direct , dropship , other
            if (
                source_category === CategoryTypesById.Direct ||
                source_category === CategoryTypesById['Drop Ship'] ||
                source_category === CategoryTypesById['Other']
            ) {
                disp_payterms = true;
            }
        }

        const inputProps = {
            variant: 'outline',
            inline: true,
            type: 'text',
        };
        let { action } = this.props.match.params;

        const editableCredsProps =
            action === 'extend-salesorg' || workflow.isReadOnly
                ? {
                      variant: 'outline',
                      inline: true,
                      type: 'text',
                  }
                : {
                      inline: true,
                      readOnly: false,
                      onChange: this.onFieldChange,
                      onChange: this.onFieldChange,
                  };

        var bgcolor = alert.color || '#FFF';
        if (this.props.fetching) {
            return <Loading />;
        }

        let extendSalesOrgEnabled =
            this.state.extendSalesOrgEnabled && !workflow.isReadOnly;

        const category = bapi70CustData ? bapi70CustData.CategoryTypeId : null;
        const paymentTermsEditable =
            category !== 1 && category !== 2 && category !== 3 && category !== 6;

        const ApolloCreditRepGroupType = idx(
            this.props.staticData,
            (_) => _.ApolloCreditRepGroupType[bapi70CustData.CreditRepGroupTypeId]
        ) || { Id: 0 };

        return (
            <ScrollView
                keyboardShouldPersistTaps="always"
                style={{
                    backgroundColor: '#EFF3F6',
                    paddingBottom: 75,
                }}>
                <Modal isVisible={this.state.modalVisible}>
                    {this._renderModalContent()}
                </Modal>
                <FlashMessages
                    toasts={this.props.toasts}
                    onDismiss={this.props.removeMessage}
                />
                {alert.display && (
                    <FlashMessage
                        bg={{ backgroundColor: bgcolor }}
                        message={alert.message}
                    />
                )}

                <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between">
                    <Text
                        style={{
                            fontWeight: '500',
                            fontSize: 24,
                            color: Colors.lightBlue,
                            marginTop: 25,
                            marginBottom: 20,
                            paddingLeft: 45,
                        }}>
                        MY TASKS - {state.Header}
                    </Text>
                    <Box
                        marginTop="25px"
                        flex={3 / 4}
                        flexDirection="row-reverse"
                        alignItems="flex-end">
                        <MultiColorProgressBar readings={statusBarData} />
                    </Box>
                </Box>
                <Box marginTop="25px" flexDirection="row-reverse" alignItems="flex-end">
                    <StyledText
                        style={{
                            fontWeight: '600',
                            paddingRight: '20px',
                            fontSize: '16px',
                            color: '#10206C',
                        }}>
                        {' '}
                        Requestor : {RequestorName}{' '}
                    </StyledText>
                </Box>
                <Box flexDirection="row-reverse" alignItems="flex-end">
                    <StyledText
                        style={{
                            fontWeight: '600',
                            paddingRight: '20px',
                            fontSize: '16px',
                            color: '#10206C',
                        }}>
                        {' '}
                        Date of Request: {requestDate}{' '}
                    </StyledText>
                </Box>
                <View
                    style={{
                        flex: 1,
                        paddingHorizontal: width < 1440 ? 60 : width * 0.1,
                        paddingBottom: 10,
                    }}>
                    <Box fullHeight my={2}>
                        <Box flexDirection="row" justifyContent="space-around" my={4}>
                            <FormInput
                                px="25px"
                                flex={1 / 4}
                                label="Workflow Title"
                                name="WorkflowTitle"
                                maxLength={40}
                                variant="outline"
                                type="text"
                                value={bapi70CustData && bapi70CustData.WorkflowTitle}
                            />
                            <FormInput
                                px="25px"
                                flex={1 / 4}
                                label="Workflow Number"
                                name="workflow-number"
                                variant="outline"
                                type="text"
                                value={state && state.WorkflowId}
                            />
                            {bapi70CustData && bapi70CustData.SystemRecordId && (
                                <FormInput
                                    px="25px"
                                    flex={1 / 4}
                                    label="Customer Number"
                                    name="CustomerNumber"
                                    variant="outline"
                                    type="text"
                                    value={
                                        bapi70CustData && bapi70CustData.SystemRecordId
                                    }
                                />
                            )}
                            {bapi70CustData && bapi70CustData.MdmNumber && (
                                <TouchableOpacity
                                    onPress={() =>
                                        this.props.history.push(
                                            `/search-results/${
                                                bapi70CustData && bapi70CustData.MdmNumber
                                            }`
                                        )
                                    }>
                                    <FormInput
                                        px="25px"
                                        flex={1 / 4}
                                        label="MDM Number"
                                        name="mdm-number"
                                        variant="outline"
                                        type="text"
                                        value={bapi70CustData && bapi70CustData.MdmNumber}
                                    />
                                </TouchableOpacity>
                            )}
                        </Box>

                        <GlobalMdmFields
                            formData={bapi70CustData}
                            staticDropDownData={this.props.staticData}
                            readOnly
                        />

                        <Text
                            mt="42px"
                            mb="20px"
                            fontWeight="regular"
                            color="lightBlue"
                            fontSize={24}
                            pl={4}>
                            SYSTEM FIELDS
                        </Text>
                        <Box flexDirection="row" justifyContent="center">
                            <Box width={1 / 2} mx="auto" alignItems="center">
                                <FormInput
                                    label="System"
                                    name="System"
                                    delta={deltas['SystemType']}
                                    value={
                                        SystemType[
                                            bapi70CustData && bapi70CustData.SystemType
                                        ]
                                    }
                                    {...inputProps}
                                />
                                <FormInput
                                    label="Role"
                                    name="Role"
                                    delta={deltas['RoleTypeId']}
                                    value={
                                        RoleType[
                                            bapi70CustData && bapi70CustData.RoleTypeId
                                        ]
                                    }
                                    {...inputProps}
                                />
                                <FormInput
                                    label="Sales Org"
                                    name="SalesOrg"
                                    delta={deltas['SalesOrgTypeId']}
                                    value={
                                        SalesOrgType[
                                            bapi70CustData &&
                                                bapi70CustData.SalesOrgTypeId
                                        ]
                                    }
                                    {...inputProps}
                                />
                                {action === 'extend-salesorg' ? (
                                    <FormInput
                                        label="Target Sales Org"
                                        name="TargetSalesOrg"
                                        team="system"
                                        inline
                                        variant="outline"
                                        type="text"
                                        value={
                                            SalesOrgType[
                                                bapi70CustData &&
                                                    bapi70CustData.TargetSalesOrgTypeId
                                            ]
                                        }
                                    />
                                ) : null}
                            </Box>
                            <Box width={1 / 2} mx="auto" alignItems="center">
                                {deltas['DistributionChannelTypeId'] ? (
                                    <DeltaField
                                        delta={deltas['DistributionChannelTypeId']}
                                    />
                                ) : (
                                    <FormInput
                                        label="Distribution Channel"
                                        name="DistributionChannel"
                                        inline
                                        variant="outline"
                                        type="text"
                                        value={
                                            DistributionChannelType[
                                                bapi70CustData &&
                                                    bapi70CustData.DistributionChannelTypeId
                                            ]
                                        }
                                    />
                                )}
                                {deltas['DivisionTypeId'] ? (
                                    <DeltaField delta={deltas['DivisionTypeId']} />
                                ) : (
                                    <FormInput
                                        label="Division"
                                        name="DivisionTypeId"
                                        inline
                                        variant="outline"
                                        type="text"
                                        value={
                                            DivisionType[
                                                bapi70CustData &&
                                                    bapi70CustData.DivisionTypeId
                                            ]
                                        }
                                    />
                                )}
                                <FormInput
                                    label="CompanyCode"
                                    name="CompanyCodeTypeId"
                                    delta={deltas['CompanyCodeTypeId']}
                                    value={
                                        CompanyCodeType[
                                            bapi70CustData &&
                                                bapi70CustData.CompanyCodeTypeId
                                        ]
                                    }
                                    {...inputProps}
                                />
                                <FormInput
                                    label="Purpose of Request"
                                    name="Purpose"
                                    value={
                                        (bapi70CustData && bapi70CustData.Purpose) ||
                                        (Deltas['Purpose'] &&
                                            Deltas['Purpose'].UpdatedValue)
                                    }
                                    multiline
                                    numberOfLines={
                                        bapi70CustData.Purpose &&
                                        bapi70CustData.Purpose.length > 36
                                            ? 2
                                            : 1
                                    }
                                    style={{ lineHeight: '2.5' }}
                                    inline
                                    variant="outline"
                                    maxLength={1000}
                                    type="text"
                                />
                            </Box>
                        </Box>

                        <Box {...showCreditDetail}>
                            <Text
                                mt="42px"
                                mb="20px"
                                fontWeight="regular"
                                color="lightBlue"
                                fontSize={24}
                                pl={4}>
                                CREDIT FIELDS
                            </Text>
                            <Box flexDirection="row" justifyContent="center">
                                <Box width={1 / 2} mx="auto" alignItems="center">
                                    {Deltas['PaymentTermsTypeId'] ? (
                                        <DeltaField
                                            delta={Deltas['PaymentTermsTypeId']}
                                        />
                                    ) : (
                                        <DynamicSelect
                                            readOnly={
                                                !extendSalesOrgEnabled ||
                                                !paymentTermsEditable
                                            }
                                            team="credit"
                                            arrayOfData={
                                                (this.props.staticData &&
                                                    this.props.staticData
                                                        .ApolloPaymentTermsType) ||
                                                []
                                            }
                                            label="Payment Terms "
                                            name="PaymentTermsTypeId"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes(
                                                    'PaymentTermsTypeId'
                                                )
                                            }
                                            value={
                                                workflow.isReadOnly
                                                    ? this.state.formData
                                                          .PaymentTermsTypeId ||
                                                      bapi70CustData.PaymentTermsTypeId
                                                    : this.state.formData
                                                          .PaymentTermsTypeId ||
                                                      bapi70CustData.PaymentTermsTypeId
                                            }
                                            isRequired={paymentTermsEditable}
                                            formErrors={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                          'PaymentTermsTypeId'
                                                      ]
                                                    : null
                                            }
                                            onFieldChange={this.onFieldChange}
                                        />
                                    )}

                                    {Deltas['RiskCategoryTypeId'] ? (
                                        <DeltaField
                                            delta={Deltas['RiskCategoryTypeId']}
                                        />
                                    ) : (
                                        <FormInput
                                            label="Risk Category "
                                            name="RiskCategoryTypeId"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes(
                                                    'RiskCategoryTypeId'
                                                )
                                            }
                                            value={
                                                bapi70CustData &&
                                                idx(
                                                    dropDownDatas,
                                                    (_) =>
                                                        _.RiskCategoryTypeId[
                                                            bapi70CustData
                                                                .RiskCategoryTypeId
                                                        ]
                                                )
                                            }
                                            {...inputProps}
                                        />
                                    )}

                                    {Deltas['CreditRepGroupTypeId'] ? (
                                        <DeltaField
                                            delta={Deltas['CreditRepGroupTypeId']}
                                        />
                                    ) : (
                                        <FormInput
                                            label="Credit Rep Group "
                                            name="CreditRepGroupTypeId"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes(
                                                    'CreditRepGroupTypeId'
                                                )
                                            }
                                            value={
                                                bapi70CustData &&
                                                ApolloCreditRepGroupType.Id !== 0 &&
                                                `${idx(
                                                    ApolloCreditRepGroupType,
                                                    (_) => _.Value
                                                )} : ${idx(
                                                    ApolloCreditRepGroupType,
                                                    (_) => _.Description
                                                )}`
                                            }
                                            {...inputProps}
                                        />
                                    )}

                                    {Deltas['Rating'] ? (
                                        <DeltaField delta={Deltas['Rating']} />
                                    ) : (
                                        <FormInput
                                            label="Rating "
                                            name="Rating"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes('Rating')
                                            }
                                            maxLength={5}
                                            error={
                                                this.state.formErrors
                                                    ? this.state.formErrors['Rating']
                                                    : null
                                            }
                                            value={
                                                (this.state.formData &&
                                                    this.state.formData['Rating']) ||
                                                (bapi70CustData && bapi70CustData.Rating)
                                            }
                                            {...editableCredsProps}
                                        />
                                    )}
                                </Box>
                                <Box width={1 / 2} mx="auto" alignItems="center">
                                    {Deltas['CreditLimit'] ? (
                                        <DeltaField delta={Deltas['CreditLimit']} />
                                    ) : (
                                        <FormInput
                                            label="Credit Limit "
                                            name="CreditLimit"
                                            maxLength={15}
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes('CreditLimit')
                                            }
                                            value={
                                                bapi70CustData &&
                                                bapi70CustData.CreditLimit
                                            }
                                            {...inputProps}
                                        />
                                    )}

                                    {Deltas['CredInfoNumber'] ? (
                                        <DeltaField delta={Deltas['CredInfoNumber']} />
                                    ) : (
                                        <FormInput
                                            label="Cred Info Number"
                                            name="CredInfoNumber"
                                            maxLength={11}
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes('CredInfoNumber')
                                            }
                                            maxLength={11}
                                            delta={deltas['CredInfoNumber']}
                                            value={
                                                this.state.formData['CredInfoNumber'] ||
                                                bapi70CustData['CredInfoNumber']
                                            }
                                            error={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                          'CredInfoNumber'
                                                      ]
                                                    : null
                                            }
                                            {...editableCredsProps}
                                            type="text"
                                        />
                                    )}
                                    {Deltas['PaymentIndex'] ? (
                                        <DeltaField delta={Deltas['PaymentIndex']} />
                                    ) : (
                                        <FormInput
                                            label="Payment Index "
                                            name="PaymentIndex"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes('PaymentIndex')
                                            }
                                            maxLength={3}
                                            error={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                          'PaymentIndex'
                                                      ]
                                                    : null
                                            }
                                            value={
                                                (this.state.formData &&
                                                    this.state.formData[
                                                        'PaymentIndex'
                                                    ]) ||
                                                (bapi70CustData &&
                                                    bapi70CustData.PaymentIndex)
                                            }
                                            {...editableCredsProps}
                                        />
                                    )}

                                    {action === 'extend-salesorg' ||
                                    workflow.isReadOnly ? (
                                        <FormInput
                                            label="Last Ext Review"
                                            name="LastExtReview"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes('LastExtReview')
                                            }
                                            inline
                                            delta={deltas['LastExtReview']}
                                            getValue={(name) => {
                                                const val = this.getValue(name);
                                                if (val === '0000-00-00') return '';
                                                else return val && val.split('T')[0];
                                            }}
                                            {...inputProps}
                                        />
                                    ) : (
                                        <FormInput
                                            label="Last Ext Review"
                                            name="LastExtReview"
                                            delta={deltas['LastExtReview']}
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes('LastExtReview')
                                            }
                                            team="credit"
                                            inline
                                            value={
                                                (Deltas &&
                                                    Deltas['LastExtReview'] &&
                                                    Deltas['LastExtReview']
                                                        .UpdatedValue) ||
                                                this.state.formData['LastExtReview'] ||
                                                bapi70CustData['LastExtReview']
                                            }
                                            error={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                          'LastExtReview'
                                                      ]
                                                    : null
                                            }
                                            onChange={(value, element) => {
                                                this.onFieldChange(
                                                    new Date(value).toJSON().slice(0, 10),
                                                    element
                                                );
                                            }}
                                            type="date"
                                        />
                                    )}
                                </Box>
                            </Box>
                        </Box>
                        {files && <FilesList files={files} readOnly />}
                        {workflow.isReadOnly ? (
                            <Box my={4}>
                                <Box flexDirection="row" justifyContent="center">
                                    {bapi70CustData && bapi70CustData.RejectionReason && (
                                        <Box width={1 / 2} mx="auto" alignItems="center">
                                            <FormInput
                                                label="Notes"
                                                name="RejectionReason"
                                                inputWidth="100%"
                                                required
                                                onChange={this.onFieldChange}
                                                error={
                                                    this.state.formErrors
                                                        ? this.state.formErrors[
                                                              'RejectionReason'
                                                          ]
                                                        : null
                                                }
                                                multiline
                                                numberOfLines={2}
                                                type="text"
                                                value={bapi70CustData.RejectionReason}
                                                variant={'outline'}
                                                inline={
                                                    workflow.isReadOnly ? true : false
                                                }
                                            />
                                        </Box>
                                    )}
                                    <Box width={1 / 2} mx="auto" alignItems="center" />
                                </Box>
                            </Box>
                        ) : (
                            <FilesList
                                title="NEW ATTACHMENTS"
                                formErrors={this.state.fileErrors}
                                files={selectedFilesIds.map((id) => selectedFiles[id])}
                                onChange={(value, id) => {
                                    this.setState({
                                        selectedFiles: {
                                            ...selectedFiles,
                                            [id]: {
                                                ...selectedFiles[id],
                                                DocumentType: parseInt(value),
                                            },
                                        },
                                    });
                                }}
                                onRemove={(value, id) => this.removeFile(id)}
                            />
                        )}
                    </Box>

                    <Box {...showButtons}>
                        <Flex
                            justifyEnd
                            alignCenter
                            style={{
                                paddingTop: 15,
                                flexDirection: 'row',
                                alignItems: 'center',
                                paddingLeft: 10,
                                paddingRight: 15,
                                marginTop: 20,
                                marginBottom: 10,
                                marginHorizontal: 25,
                            }}>
                            <label htmlFor="file-upload" className="custom-file-upload">
                                <MaterialIcons
                                    name="attach-file"
                                    size={18}
                                    color="#fff"
                                />
                            </label>
                            <input
                                id="file-upload"
                                type="file"
                                key={this.state.fileinputkey}
                                onChange={this.selectFiles}
                                multiple
                            />
                            <Button
                                onPress={(event) =>
                                    this.setState({ isReject: false, modalVisible: true })
                                }
                                title="Approve"
                            />
                            <Button
                                title="Reject"
                                onPress={(event) =>
                                    this.setState({ isReject: true, modalVisible: true })
                                }
                            />
                        </Flex>
                    </Box>
                </View>
            </ScrollView>
        );
    }
}

class Default extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const props = this.props;

        return (
            <DimensionAware
                render={(dimensions) => (
                    <Page
                        {...{
                            ...props,
                            width: getWindowWidth(dimensions),
                            height: getWindowHeight(dimensions),
                            marginBottom: 25,
                        }}
                    />
                )}
            />
        );
    }
}

const mapStateToProps = ({
    workflows,
    myTasks,
    customer,
    updateFlow,
    toasts,
    staticData,
}) => {
    const {
        bapi70CustData,
        deltas,
        denormalizedDeltas,
        fetching: fetchingCustomer,
    } = customer;
    const { fetching, alert, readOnly } = myTasks;
    const { fetching: fetchingUpdateflow } = updateFlow;
    const {
        fetchingfnGroupData,
        statusBarData,
        functionalGroupDetails,
        TasksStatusByTeamId,
        fetchingStatusBar,
    } = workflows;
    const { data, fetching: staticFetching } = staticData;

    return {
        fetching:
            fetching ||
            fetchingStatusBar ||
            fetchingfnGroupData ||
            fetchingCustomer ||
            fetchingUpdateflow ||
            staticFetching,
        alert,
        statusBarData,
        functionalGroupDetails,
        readOnly,
        TasksStatusByTeamId,
        bapi70CustData,
        denormalizedDeltas,
        deltas,
        toasts,
        staticData: data,
    };
};

export default connect(mapStateToProps, {
    saveApolloMyTaskCredit,
    getFunctionalGroupData,
    getStatusBarData,
    getCustomerFromSAP,
    saveApolloUpdateMyTaskCredit,
    removeMessage,
    getStaticRequest,
})(Default);

const styles = StyleSheet.create({
    progressIndicator: {
        flex: 1,
        paddingBottom: 5,
        flexDirection: 'row-reverse',
        alignItems: 'flex-end',
    },
    statusText: {
        fontSize: 15,
        color: '#1D4289',
        fontFamily: 'Poppins',
        textAlign: 'center',
        marginTop: 20,
    },
});
