import React from 'react';
import {
    ScrollView,
    View,
    TouchableOpacity,
    ActivityIndicator,
    StyleSheet,
} from 'react-native';
import {
    DimensionAware,
    getWindowHeight,
    getWindowWidth,
} from 'react-native-dimension-aware';
import { Colors, StyledText } from '../../../theme/Index';
import { Flex, Button, Box, Text } from '../../../components/common';
import { FormInput } from '../../../components/form';
import GlobalMdmFields from '../../../components/GlobalMdmFields';
import DynamicSelect from '../../../components/DynamicSelect';
import { CheckBoxItem } from '../../../components/CheckBoxItem';
import { debounce } from 'lodash';
import { parseAndSanitise, yupAllFieldsValidation } from '../../../constants/utils';
import { myTaskJdeRules, rejectRules } from '../../../constants/FieldRules';
import {
    saveApolloMyTaskCustomerMaster,
    fetchDuns,
    getStatusBarData,
    getFunctionalGroupData,
    getStaticRequest,
    removeMessage,
} from '../../../appRedux/actions/';
import { connect } from 'react-redux';
import Loading from '../../../components/Loading';
import FlashMessage, { FlashMessages } from '../../../components/FlashMessage';
import {
    RoleType,
    PtmnRoleType,
    SalesOrgType,
    SystemType,
    DistributionChannelType,
    DivisionType,
    CompanyCodeType,
} from '../../../constants/WorkflowEnums';
import MultiColorProgressBar from '../../../components/MultiColorProgressBar';
import Modal from 'modal-enhanced-react-native-web';
import * as moment from 'moment';
import FilesList from '../../../components/FilesList';
import { MaterialIcons } from '@expo/vector-icons';
import idx from 'idx';
import { Table, Row, Rows } from '../../../components/table';
import JdeGlobalMdmFields from '../../../components/JdeGlobalMdmFields';
import JDESystemFields from '../../JDESystemFields';
import { fetchJDECreateTeamRoleFieldMapping } from '../../../redux/DropDownDatas';
import CollapsibleTable from '../../../components/table/CollapsibleTable';

class Page extends React.Component {
    constructor(props) {
        super(props);
        const editableProp = {
            inline: false,
            variant: 'solid',
            onChange: this.onFieldChange,
        };
        // let isWorkFlowReadOnly=false
        this.state = {
            isWorkFlowReadOnly: this.props.location.state.isReadOnly,
            WorkflowId: this.props.location.state.WorkflowId,
            TaskId: this.props.location.state.TaskId,
            loading: this.props.fetching,
            functionalGroupDetails: this.props.functionalGroupDetails,
            formData: {
                RejectionButton: false,
                CategoryCode03TypeId: 1,
                PaymentInstructionTypeId: 1,
                PolicyNameTypeId: 1,
                CompanyTypeId: 1,
                TaxExplCodeTypeId: 1,
                RelatedAddressNumTypeId: 1,
                AdjustmentScheduleTypeId: 1,
                ItemRestrictionsTypeId: 1,
                Contracts: 'CONTRACT',
                WorkOrder: 'SERVICE',
                Payables: 'N',
                ArApNetting: 'N',
                SendStatementTo: 'C',
                StatementCycle: 'P',
                BatchProcessingMode: 'I',
                CreditCheckLevel: 'C',
                PriorityProcessingCode: '0',
                AdjustmentOnInvoice: 'Y',
            },
            formErrors: {},
            inputPropsForDefaultRules: { CustomerGroupTypeId: editableProp },
            selectedFiles: {},
            selectedFilesIds: [],
            files: [],
            fileinputkey: Date.now(),
            isInternationalVersionVisible: false,
            jdeTeamRoleFieldMapping: {},
            isTaxRateAreaTypeRequired: true,
            isPaymentInstructionTypeRequired: true,
            isAdjustmentScheduleTypeRequired: true,
        };

        this.fetchDunsFields = debounce(this.props.fetchDuns, 200);
    }

    componentDidMount() {
        let { state: wf } = this.props.location;
        let postJson = {
            workflowId: wf.WorkflowId,
            fuctionalGroup: 'customermaster',
            taskId: wf.TaskId,
            systemTypeId: wf.SystemTypeId,
        };

        this.props.getStaticRequest([
            87,
            88,
            89,
            90,
            91,
            92,
            93,
            94,
            95,
            96,
            97,
            98,
            99,
            100,
            101,
            102,
            103,
            104,
            105,
            106,
            107,
            108,
            109,
            110,
            111,
            112,
            113,
            114,
            115,
            117,
            118,
            5,
            116,
            121,
            122,
            123,
        ]);

        fetchJDECreateTeamRoleFieldMapping().then((resp) => {
            const data = resp;
            this.setState({ jdeTeamRoleFieldMapping: data });
        });

        this.props.getStatusBarData(postJson);
        this.props.getFunctionalGroupData(postJson);
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps &&
            prevProps.staticData &&
            this.props.staticData !== prevProps.staticData &&
            Object.keys(this.props.staticData).length > 0 &&
            this.props.staticData !== null &&
            prevProps.functionalGroupDetails !== this.props.functionalGroupDetails &&
            this.props.functionalGroupDetails &&
            this.props.functionalGroupDetails.Customer
        ) {
            var customerdata = this.props.functionalGroupDetails.Customer;
            var result = this.validateFromSourceData(customerdata);
            let formData = this.state.formData;
            if (
                customerdata &&
                customerdata.Country == 'IT' &&
                customerdata.CustomerClassTypeId == 2
            ) {
                formData['CustPriceProc'] = '2';
            } else {
                formData['CustPriceProc'] = '1';
            }

            this.setState({
                formData: {
                    ...this.state.formData,
                    ...result.newStateValue,
                    Role:
                        this.props.staticData &&
                        this.props.functionalGroupDetails &&
                        this.props.functionalGroupDetails.Customer
                            ? this.getDropdownValues(
                                  this.props.functionalGroupDetails.Customer.RoleTypeId,
                                  this.props.staticData.OlympusRoleType
                              )
                            : '',
                    RoleTypeId:
                        this.props.functionalGroupDetails &&
                        this.props.functionalGroupDetails.Customer
                            ? this.props.functionalGroupDetails.Customer.RoleTypeId
                            : '',
                    DunsNumber: this.props.functionalGroupDetails.Customer.DunsNumber,
                    MarketingSegmentationTypeId: this.props.functionalGroupDetails
                        .Customer.MarketingSegmentationTypeId,
                },
                inputPropsForDefaultRules: {
                    ...this.state.inputPropsForDefaultRules,
                    ...result.newStyleProps,
                },
            });
        }
    }

    componentWillReceiveProps(newProps) {
        if (
            (newProps &&
                newProps.functionalGroupDetails != this.props.functionalGroupDetails &&
                newProps.functionalGroupDetails.Customer &&
                !this.state.isWorkFlowReadOnly) ||
            (this.props.staticData != newProps.staticData &&
                newProps.staticData &&
                Object.keys(newProps.staticData).length > 0)
        ) {
            var customerdata = newProps.functionalGroupDetails.Customer;

            var result = this.validateFromSourceData(customerdata);
            let formData = this.state.formData;
            if (
                customerdata &&
                customerdata.Country == 'IT' &&
                customerdata.CustomerClassTypeId == 2
            ) {
                formData['CustPriceProc'] = '2';
            } else {
                formData['CustPriceProc'] = '1';
            }

            this.setState({
                formData: {
                    ...this.state.formData,
                    ...result.newStateValue,
                    Role:
                        newProps.staticData &&
                        newProps.functionalGroupDetails &&
                        newProps.functionalGroupDetails.Customer
                            ? this.getDropdownValues(
                                  newProps.functionalGroupDetails.Customer.RoleTypeId,
                                  newProps.staticData.OlympusRoleType
                              )
                            : '',
                    RoleTypeId:
                        newProps.functionalGroupDetails &&
                        newProps.functionalGroupDetails.Customer
                            ? newProps.functionalGroupDetails.Customer.RoleTypeId
                            : null,
                    DunsNumber:
                        newProps.functionalGroupDetails &&
                        newProps.functionalGroupDetails.Customer
                            ? newProps.functionalGroupDetails.Customer.DunsNumber
                            : null,
                    MarketingSegmentationTypeId:
                        newProps.functionalGroupDetails &&
                        newProps.functionalGroupDetails.CustomerMaster &&
                        newProps.functionalGroupDetails.CustomerMaster
                            .MarketingSegmentationTypeId
                            ? newProps.functionalGroupDetails.CustomerMaster
                                  .MarketingSegmentationTypeId
                            : newProps.functionalGroupDetails.Customer
                            ? newProps.functionalGroupDetails.Customer
                                  .MarketingSegmentationTypeId
                            : null,
                    IndustryClassificationTypeId:
                        newProps.functionalGroupDetails &&
                        newProps.functionalGroupDetails.CustomerMaster &&
                        newProps.functionalGroupDetails.CustomerMaster
                            .IndustryClassificationTypeId
                            ? newProps.functionalGroupDetails.CustomerMaster
                                  .IndustryClassificationTypeId
                            : newProps.functionalGroupDetails.Customer
                            ? newProps.functionalGroupDetails.Customer
                                  .IndustryClassificationTypeId
                            : null,
                },
                inputPropsForDefaultRules: {
                    ...this.state.inputPropsForDefaultRules,
                    ...result.newStyleProps,
                },
            });
        }
    }

    setFormErrors = (errors) => {
        const { formErrors } = this.state;
        this.setState({ formErrors: errors }, () => window.scrollTo(0, 0));
    };

    onDunsNewInput = (value, e) => {
        const { name } = e.target;
        this.fetchDunsFields({
            UserId: localStorage.getItem('userId'),
            DunsNumber: value,
        });
    };

    onFieldChange = (value, e) => {
        const { name } = e.target;
        // console.log(e.target.name,value)
        const { formData: prevFormData } = this.state;
        if (name === 'DunsNumber' && value.length > 0) {
            const { isDunsValid } = this.state;
            const {
                validity: { valid },
            } = e.target;
            if (valid !== isDunsValid)
                this.setState({
                    isDunsValid: valid,
                    formErrors: {
                        ...this.state.formErrors,
                        isDunsValid: !valid ? ' only numbers (0-9) are allowed.' : null,
                    },
                });
        } else if (value.length <= 0 && this.state.formErrors['isDunsValid']) {
            this.setState({
                formErrors: {
                    ...this.state.formErrors,
                    isDunsValid: null,
                },
            });
        }

        if (name === 'Reporting1099TypeId' && value.length > 0) {
            this.onReporting1099TypeIdChanged(value, e);
        }
        if (name === 'PaymentInstructionTypeId' && value.length > 0) {
            if (name.includes('TypeId') && parseInt(value) === 0) {
                delete this.state.formData[name];
            }
        }
        if (name === 'AdjustmentScheduleTypeId' && value.length > 0) {
            if (name.includes('TypeId') && parseInt(value) === 0) {
                delete this.state.formData[name];
            }
        }

        var customerdata = this.props.functionalGroupDetails.Customer;
        let formData = this.state.formData;
        this.setState({
            test: { ...this.state.test, [name]: value },
            formData: {
                ...this.state.formData,
                [name]: value,
            },
        });
    };

    onReporting1099TypeIdChanged = (value, e) => {
        this.setState({
            formErrors: {},
        });
        if (this.isSystemJDE() && value == 6) {
            this.setState({
                isTaxRateAreaTypeRequired: false,
                isPaymentInstructionTypeRequired: false,
                isAdjustmentScheduleTypeRequired: false,
            });
        } else {
            this.setState(
                {
                    isTaxRateAreaTypeRequired: true,
                    isPaymentInstructionTypeRequired: true,
                    isAdjustmentScheduleTypeRequired: true,
                },
                () => {
                    delete this.state.formData['PaymentInstructionTypeId'];
                    delete this.state.formData['AdjustmentScheduleTypeId'];
                }
            );
        }
    };

    isSystemJDE = () => {
        const {
            functionalGroupDetails: { Customer: globalMdmDetail = {} },
        } = this.props;
        const isJDE = globalMdmDetail && globalMdmDetail.SystemTypeId === 5;
        return isJDE;
    };

    parseAndHandleFieldChange = (value, e) => {
        const { name } = e.target;
        const val = parseAndSanitise(value, 10);
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: val,
            },
        });
    };

    setBulkFormDataUpdate = (keyValdict) => {
        this.setState({
            formData: {
                ...this.state.formData,
                ...keyValdict,
            },
        });
    };
    setFormDataValues = (name, value) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: value,
            },
        });
    };

    setInputPropsForDefaultRules = (field_name, property) => {
        this.setState({
            inputPropsForDefaultRules: {
                ...this.state.inputPropsForDefaultRules,
                [field_name]: property,
            },
        });
    };
    // display or set input/dropdowns values based on rules

    validateFromSourceData = (source_data) => {
        const readOnlyDropDown = { disabled: true };
        const newStateValue = {},
            newStyleProps = {};

        //static data api doesnt get loaded when this function being called. Hence added const dropdownvalues
        const jdeSalesOrgTypeStaticData = [
            {
                Id: 1,
                Value: '10781',
                Description: 'Main Warehouse',
                SortOrder: 1,
                IsDefault: false,
                ValidationData: [],
            },
            {
                Id: 2,
                Value: '21781',
                Description: 'Sales / CoGs',
                SortOrder: 2,
                IsDefault: false,
                ValidationData: [],
            },
            {
                Id: 3,
                Value: '31781',
                Description: 'Sales / CoGs',
                SortOrder: 3,
                IsDefault: false,
                ValidationData: [],
            },
            {
                Id: 4,
                Value: '60781',
                Description: 'Sales / CoGs',
                SortOrder: 4,
                IsDefault: false,
                ValidationData: [],
            },
            {
                Id: 5,
                Value: '70781',
                Description: 'Sales / CoGs',
                SortOrder: 5,
                IsDefault: false,
                ValidationData: [],
            },
        ];
        if (source_data) {
            //check collection manager ---
            if (source_data.SalesOrgTypeId === 1 && source_data.Country === 'DE')
                newStateValue['CollectionManagerTypeId'] = 1;
            else if (source_data.SalesOrgTypeId === 1 && source_data.Country != 'DE')
                newStateValue['CollectionManagerTypeId'] = 2;
            else if (source_data.SalesOrgTypeId != 1)
                newStateValue['CollectionManagerTypeId'] = 0;

            //check GL Offset ---
            // EU Countries - Austria, Belgium, Bulgaria, Croatia, Republic of Cyprus, Czech Republic, Denmark,
            //  Estonia, Finland, France, Germany, Greece, Hungary, Ireland, Italy, Latvia, Lithuania, Luxembourg,
            // Malta, Netherlands, Poland, Portugal, Romania, Slovakia, Slovenia, Spain and Sweden

            // DOM -- If Sales Org and Country are same
            // EC -- If BU and Country are not same & Country belongs to EU
            // FGN -- If BU and Country are not same & Country belongs to non EU

            // Sales Org(First two char) & Country Validation
            // 10 = Germany
            // 21 = Netherlands
            // 31 = UK
            // 60 = Italy
            // 70 - Australia
            let EU_CountriesList = [
                'AT',
                'BE',
                'BG',
                'HR',
                'CY',
                'CZ',
                'DK',
                'EE',
                'FI',
                'FR',
                'DE',
                'GR',
                'HU',
                'IE',
                'IT',
                'LV',
                'LT',
                '06',
                'MT',
                'NL',
                'PL',
                'PT',
                'RO',
                'SK',
                'SI',
                'ES',
                'SE',
            ];

            let salesorgValueDesc = this.getDropdownValues(
                source_data.SalesOrgTypeId,
                jdeSalesOrgTypeStaticData
            );
            let Country = source_data.Country;
            let salesorgfirst2char = salesorgValueDesc.substring(0, 2);
            console.log(salesorgfirst2char, Country);
            if (
                (salesorgfirst2char === '10' && Country === 'DE') ||
                (salesorgfirst2char === '21' && Country === 'NL') ||
                (salesorgfirst2char === '31' && Country === 'UK') ||
                (salesorgfirst2char === '60' && Country === 'IT') ||
                (salesorgfirst2char === '70' && Country === 'AU')
            ) {
                newStateValue['GlOffsetTypeId'] = 1;
            } else if (EU_CountriesList.includes(Country)) {
                newStateValue['GlOffsetTypeId'] = 2;
            } else {
                newStateValue['GlOffsetTypeId'] = 3;
            }

            ///check for 1099reporting
            // "10 -- GERMANY
            // 21 -- NETHERLANDS
            // 31 -- UK
            // 60 -- ITALY
            // 70 -- AUSTRALIA"
            if (salesorgfirst2char === '10') newStateValue['Reporting1099TypeId'] = 1;
            else if (salesorgfirst2char === '21')
                newStateValue['Reporting1099TypeId'] = 2;
            else if (salesorgfirst2char === '31')
                newStateValue['Reporting1099TypeId'] = 3;
            else if (salesorgfirst2char === '60')
                newStateValue['Reporting1099TypeId'] = 4;
            else if (salesorgfirst2char === '70')
                newStateValue['Reporting1099TypeId'] = 5;

            //check for Taxrate/area ---
            // "If Sales Org = 10781 then M100
            // If Sales Org = 21781 then M200
            // If Sales Org = 31781 then GBS
            // If Sales Org = 60781 then ITRDHG / ITRDHI
            // If Sales Org = 70781 then GST
            // If Sales Org and country are not same
            // and EU = M02
            // If Sales Org and country are not same
            // and Non EU = M00
            // "M100
            // M200
            // GBS
            // ITRDHG / ITRDHI
            // GST
            // M02
            // M00"
            let salesorgValue = this.getDropdownValuesPart(
                source_data.SalesOrgTypeId,
                jdeSalesOrgTypeStaticData
            );
            let taxratevalforitaly = source_data.AdditionalTaxNumber === 'ITRDHG' ? 4 : 5;
            if (
                (salesorgfirst2char === '10' && Country === 'DE') ||
                (salesorgfirst2char === '21' && Country === 'NL') ||
                (salesorgfirst2char === '31' && Country === 'UK') ||
                (salesorgfirst2char === '60' && Country === 'IT') ||
                (salesorgfirst2char === '70' && Country === 'AU')
            ) {
                if (salesorgValue === '10781') newStateValue['TaxRateAreaTypeId'] = 1;
                else if (salesorgValue === '21781')
                    newStateValue['TaxRateAreaTypeId'] = 2;
                else if (salesorgValue === '31781')
                    newStateValue['TaxRateAreaTypeId'] = 3;
                else if (salesorgValue === '60781')
                    newStateValue['TaxRateAreaTypeId'] = taxratevalforitaly;
                else if (salesorgValue === '70781')
                    newStateValue['TaxRateAreaTypeId'] = 5;
            } else if (
                EU_CountriesList.includes(Country) &&
                ((salesorgfirst2char === '10' && Country != 'DE') ||
                    (salesorgfirst2char === '21' && Country != 'NL') ||
                    (salesorgfirst2char === '31' && Country != 'UK') ||
                    (salesorgfirst2char === '60' && Country != 'IT') ||
                    (salesorgfirst2char === '70' && Country != 'AU'))
            ) {
                newStateValue['TaxRateAreaTypeId'] = 7;
            } else if (
                !EU_CountriesList.includes(Country) &&
                ((salesorgfirst2char === '10' && Country != 'DE') ||
                    (salesorgfirst2char === '21' && Country != 'NL') ||
                    (salesorgfirst2char === '31' && Country != 'UK') ||
                    (salesorgfirst2char === '60' && Country != 'IT') ||
                    (salesorgfirst2char === '70' && Country != 'AU'))
            ) {
                newStateValue['TaxRateAreaTypeId'] = 8;
            }

            //set default value ---
            //   SendMethod "If county Italy, default 5 else no default"
            if (Country === 'IT') {
                newStateValue['SendMethodTypeId'] = 5;
            }
            //Billingaddresstype "If Role - Invoice & Shippiong - X If Role - Invoice - B If Role - Shipping - S"
            if (source_data.RoleTypeId === 1) newStateValue['BillingAddressTypeId'] = 3;
            else if (source_data.RoleTypeId === 2)
                newStateValue['BillingAddressTypeId'] = 1;
            else if (source_data.RoleTypeId === 3)
                newStateValue['BillingAddressTypeId'] = 2;
        }

        return { newStateValue, newStyleProps };
    };

    handleFormSubmission = (schema) => {
        let {
                TaskId,
                WorkflowId,
                formData,
                selectedFiles,
                selectedFilesIds,
            } = this.state,
            castedFormData = {};

        formData = {
            ...formData,
            ...this.props.dunsFields,
        };

        try {
            const WorkflowTaskModel = {
                RejectReason: formData['RejectionButton']
                    ? formData['RejectionReason']
                    : '',
                TaskId: TaskId,
                UserId: localStorage.getItem('userId'),
                WorkflowId: WorkflowId,
                WorkflowTaskOperationType: !formData['RejectionButton'] ? 1 : 2,
            };
            if (!formData['RejectionButton']) {
                castedFormData = schema.cast(formData);
            } else {
                castedFormData = formData;
            }
            delete castedFormData.RejectionButton;
            delete castedFormData.displayINCOT2;
            delete castedFormData.display_LN;
            delete castedFormData.Role;
            let systemFields = this.props.functionalGroupDetails
                ? this.props.functionalGroupDetails.Customer
                : {};
            let internationalVersionData = systemFields.InternationalVersionDomain
                ? systemFields.InternationalVersionDomain
                : {};
            let postData = {};
            const { teamRoleFieldMapping } = this.state;
            const CustomerMasterTeamFields =
                idx(teamRoleFieldMapping, (_) => _.OlympusCustomerMasterFields) || [];
            const RoleTypeId = this.props.functionalGroupDetails.Customer.RoleTypeId;
            const SystemTypeId = this.props.functionalGroupDetails.Customer.SystemTypeId;
            const allowedFieldList = CustomerMasterTeamFields.filter((item) => {
                return (
                    item.RoleTypeId === RoleTypeId && item.SystemTypeId === SystemTypeId
                );
            });
            let allowedFields = undefined;
            if (allowedFieldList && allowedFieldList.length > 0) {
                allowedFields = allowedFieldList[0].Fields;
            }

            // if (this.isSystemPointman())
            postData['formData'] = {
                WorkflowTaskModel,
                AlphaName: formData['AlphaName'],
                CategoryCode03TypeId: formData['CategoryCode03TypeId'],
                Reporting1099TypeId: formData['Reporting1099TypeId'],
                CategoryCode22TypeId: formData['CategoryCode22TypeId'],
                PaymentInstructionTypeId: formData['PaymentInstructionTypeId'],
                CreditLimit: formData['CreditLimit'],
                CollectionManagerTypeId: formData['CollectionManagerTypeId'],
                PolicyNameTypeId: formData['PolicyNameTypeId'],
                CompanyTypeId: formData['CompanyTypeId'],
                GlOffsetTypeId: formData['GlOffsetTypeId'],
                TaxExplCodeTypeId: formData['TaxExplCodeTypeId'],
                TaxRateAreaTypeId: formData['TaxRateAreaTypeId'],
                SendMethodTypeId: formData['SendMethodTypeId'],
                BillingAddressTypeId: formData['BillingAddressTypeId'],
                RelatedAddressNumTypeId: formData['RelatedAddressNumTypeId'],
                AdjustmentScheduleTypeId: formData['AdjustmentScheduleTypeId'],
                ItemRestrictionsTypeId: formData['ItemRestrictionsTypeId'],
                Contracts: formData['Contracts'],
                WorkOrder: formData['WorkOrder'],
                GeographicRegionTypeId: formData['GeographicRegionTypeId'],
                TimeZoneTypeId: formData['TimeZoneTypeId'],
                CatCode28TypeId: formData['CatCode28TypeId'],
                CatCode29TypeId: formData['CatCode29TypeId'],
                MdmNumberToGroup: formData.MdmNumberToGroup || null,
                IndustryClassificationTypeId: formData['IndustryClassificationTypeId'],
                MarketingSegmentationTypeId: formData['MarketingSegmentationTypeId'],
            };
            console.log(postData);
            // else
            //     postData['formData'] = {
            //         WorkflowTaskModel,
            //         ...castedFormData,
            //     };
            // postData['files'] = selectedFilesIds.map((id) => selectedFiles[id]);

            // console.log(this.props, this.state)

            // console.log(postData['formData'], "1st obj")
            // Object.keys(postData['formData']).forEach(dataToSend => {

            //     allowedFields.some(fieldList => {
            //         console.log(fieldList)
            //         if (dataToSend.toLowerCase().includes(fieldList.toLowerCase())) {
            //             delete postData['formData'][dataToSend]
            //             return true
            //         }
            //     })
            // })
            // console.log(postData['formData'], 'filteredData==============', allowedFields)
            this.props.saveApolloMyTaskCustomerMaster(postData);
            this.resetForm();
            this.scrollToTop();
        } catch (error) {
            console.log(error);
        }
    };

    setMDM = (mdmObj) => {
        this.setState({
            formData: {
                ...this.state.formData,
                MdmNumberToGroup: mdmObj && mdmObj[0] && mdmObj[0]['MdmNumber'],
            },
        });
    };

    isSystemPointman = () => {
        const {
            functionalGroupDetails: { Customer: globalMdmDetail = {} },
        } = this.props;
        const isPointman = globalMdmDetail && globalMdmDetail.SystemTypeId === 3;

        return isPointman;
    };
    onSubmit = (event, reject, _schema) => {
        const {
            functionalGroupDetails: { Customer: globalMdmDetail = {} },
        } = this.props;

        const schema = this.isSystemPointman() ? ptmnCustomerMasterRules : _schema;
        // let isContractEnabled = false;
        //check iff contract fields to be displayed or not
        // if (globalMdmDetail && globalMdmDetail.CategoryTypeId != undefined) {
        //     let categoryTypeid = parseAndSanitise(globalMdmDetail.CategoryTypeId);
        //     if (
        //         categoryTypeid === 1 ||
        //         categoryTypeid === 2 ||
        //         categoryTypeid === 3 ||
        //         categoryTypeid === 6
        //     ) {
        //         isContractEnabled = true;
        //     }
        // }

        let { formData, selectedFilesIds, selectedFiles } = this.state;
        let fileErrors = {};
        let errors = false;
        selectedFilesIds.map((id) => {
            if (selectedFiles[id] && selectedFiles[id].DocumentType <= 0) {
                fileErrors[id] = 'Document Type Required for file';
                errors = true;
            }
        });
        if (this.state.formData['RelatedAddressNumTypeId'])
            delete globalMdmDetail['RelatedAddressNumTypeId'];

        this.setState({ fileErrors, isFileErrors: errors });
        this.setState(
            {
                modalVisible: false,
                formData: {
                    ...this.state.formData,
                    RejectionButton: reject,
                },
            },
            () => {
                // console.log(this.state.formData,globalMdmDetail,'========on submit data');
                yupAllFieldsValidation(
                    {
                        ...this.state.formData,
                        ...globalMdmDetail,
                    },
                    schema,
                    (...rest) => {
                        if (this.state.isFileErrors === false)
                            this.handleFormSubmission(...rest);
                    },
                    this.setFormErrors
                );
            }
        );
    };

    scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    resetFormData = () => {
        Object.keys(this.state.formData).map((key) => {
            var myitem = key;
            if (
                ![
                    'OrderCombination',
                    'PaymentHistoryRecord',
                    'RejectionButton',
                    'displayINCOT2',
                    'display_LN',
                ].includes(myitem)
            ) {
                this.setState({
                    formData: {
                        ...this.state.formData,
                        [key]: '',
                    },
                });
            } else {
                if (!['displayINCOT2', 'display_LN'].includes(myitem)) {
                    this.setState({
                        formData: {
                            ...this.state.formData,
                            [key]: false,
                        },
                    });
                }
            }
        });
    };

    resetForm = () => {
        let { formErrors } = this.state;

        Object.keys(this.state.formErrors).forEach((key) => (formErrors[key] = ''));

        this.setState(
            {
                formErrors,
            },
            this.resetFormData
        );
    };

    selectFiles = (events) => {
        event.preventDefault();

        const { selectedFilesIds, selectedFiles } = this.state;
        const id = events.target.files[0].name;

        this.setState({
            selectedFiles: {
                ...selectedFiles,
                [id]: {
                    data: events.target.files[0],
                    DocumentName: events.target.files[0].name,
                    DocumentType: 0,
                },
            },
            selectedFilesIds: [...selectedFilesIds, id],
            filename: events.target.files[0].name,
            fileinputkey: Date.now(),
        });
    };

    removeFile = (id) => {
        event.preventDefault();

        var selectedFilesDict = this.state.selectedFiles;
        var selectedFilesIdsList = this.state.selectedFilesIds;
        // remove the existing state
        delete selectedFilesDict[id];
        selectedFilesIdsList = selectedFilesIdsList.filter((fileid) => fileid !== id);

        this.setState({
            selectedFiles: selectedFilesDict,
            selectedFilesIds: selectedFilesIdsList,
        });
    };

    _renderModalContent = () => {
        const { formData } = this.state;
        return (
            <Box
                my={4}
                mx="30%"
                backgroundColor="white"
                borderRadius="4px"
                style={{ padding: '3%' }}
                borderColor="rgba(0, 0, 0, 0.1)">
                <Text my={3} fontWeight="regular" color="lightBlue" fontSize={24} pl={4}>
                    Submit Workflow
                </Text>
                <Box px="0px" pb="22px" justifyContent="center">
                    <Box alignItems="center" justifyContent="center">
                        {!this.state.isReject ? (
                            <FormInput
                                label="Additional Notes"
                                multiline
                                numberOfLines={2}
                                maxLength={250}
                                name="AdditionalNotes"
                                type="text"
                                onChange={this.onFieldChange}
                                value={
                                    this.state.formData &&
                                    this.state.formData['AdditionalNotes']
                                }
                                variant={'solid'}
                            />
                        ) : (
                            <FormInput
                                label="Rejection Reason"
                                name="RejectionReason"
                                maxLength={250}
                                onChange={this.onFieldChange}
                                error={'*this field is required'}
                                multiline
                                numberOfLines={2}
                                type="text"
                                value={
                                    this.state.formData &&
                                    this.state.formData['RejectionReason']
                                }
                                variant="solid"
                            />
                        )}
                    </Box>
                    <Flex
                        justifyEnd
                        alignCenter
                        alignSelf="flex-end"
                        style={{
                            paddingTop: 15,
                            flexDirection: 'row',
                            alignItems: 'center',
                            paddingLeft: 10,
                            paddingRight: 15,
                            marginTop: 20,
                            marginBottom: 10,
                            marginHorizontal: 25,
                        }}>
                        <Button
                            onPress={(event) => {
                                if (this.state.isReject)
                                    this.setState({ isReject: false }, () =>
                                        this.onSubmit(event, true, rejectRules)
                                    );
                                else this.onSubmit(event, false, myTaskJdeRules);
                            }}
                            title="Submit"
                        />
                        <Button
                            title="Cancel"
                            onPress={() => this.setState({ modalVisible: null })}
                        />
                    </Flex>
                </Box>
            </Box>
        );
    };

    isNumber = (n) => {
        return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
    };

    validateNumericKeypad = (value, e) => {
        const { name } = e.target;
        if (value) {
            if (!this.isNumber(value) || parseInt(value) < 0 || parseInt(value) > 100) {
                this.setState({
                    formErrors: {
                        [name]:
                            value >= 0 || value <= 100
                                ? 'Value should be greater 0 and less than 100'
                                : 'Must be digits',
                    },
                });
            } else {
                this.onFieldChange(value, e);
            }
        } else {
            this.onFieldChange(value, e);
        }
    };

    deliveryPlantMapping = (data) => {
        let systemFields = this.props.functionalGroupDetails.Customer;
        let dropdownData = [];
        if (data) {
            data.forEach((dropdownList) => {
                if (dropdownList.ValidationData.length > 0) {
                    dropdownList.ValidationData.forEach((datatofilter) => {
                        if (
                            datatofilter.SalesOrgTypeId === systemFields.SalesOrgTypeId &&
                            systemFields.DistributionChannelTypeId ===
                                datatofilter.DistributionChannelTypeId
                        ) {
                            dropdownData.push(dropdownList);
                        }
                    });
                }
            });
        }
        return dropdownData;
    };

    getDropdownValues = (Id, dropdownList) => {
        let description = '';
        if (Id && dropdownList)
            dropdownList.some((data) => {
                if (data.Id == Id) {
                    description = data.Value + ' : ' + data.Description;
                    return true;
                }
            });
        // if (description.includes('/n') || description.includes(';')) {
        //     console.log('html string');
        // }
        return description;
    };

    getDropdownValuesPart = (Id, dropdownList) => {
        let description = '';
        if (Id && dropdownList)
            dropdownList.some((data) => {
                if (data.Id == Id) {
                    description = data.Value;
                    return true;
                }
            });
        // if (description.includes('/n') || description.includes(';')) {
        //     console.log('html string');
        // }
        return description;
    };

    getDropdownList = (key, data, stateKey) => {
        let dropdownData = [];
        const self = this;
        const {
            functionalGroupDetails: {
                DocumentLocation: files,
                Customer: globalMdmDetail = {},
            },
        } = this.props;

        if (data) {
            dropdownData = [];
            data.forEach((dropdownList) => {
                if (dropdownList.ValidationData.length > 0) {
                    dropdownList.ValidationData.forEach((datatofilter) => {
                        if (datatofilter[key] === globalMdmDetail[stateKey]) {
                            dropdownData.push(dropdownList);
                        }
                    });
                }
            });
        }
        var check = new Set();
        let filteredArr = dropdownData.filter(
            (obj) => !check.has(obj['Id']) && check.add(obj['Id'])
        );
        return filteredArr;
    };

    render() {
        const {
            width,
            location,
            functionalGroupDetails: {
                DocumentLocation: files,
                Customer: globalMdmDetail = {},
                CustomerMaster: customerMaster = null,
                TaskNote: Notes = '',
                RequestorName,
            },
            alert = {},
            statusBarData,
            TasksStatusByTeamId = null,
            fetching,
            staticData,
        } = this.props;

        const { formData } = this.state;
        const {
            inputPropsForDefaultRules,
            selectedFilesIds,
            selectedFiles,
            jdeTeamRoleFieldMapping,
        } = this.state;

        const { state } = location;

        var isReleased = true;
        for (let i = 0; i < statusBarData.length; i++) {
            let teamstatus = statusBarData[i];
            if (teamstatus.WorkflowTaskStateTypeId === 2) {
                isReleased = false;
                break;
            } else {
                isReleased = true;
            }
        }
        // console.log(TasksStatusByTeamId, '+++++++++++++++ Task status by team id');
        const workflow = {
            ...state,
            isReadOnly:
                TasksStatusByTeamId === null ||
                !(
                    globalMdmDetail &&
                    globalMdmDetail.WorkflowStateTypeId === 2 &&
                    TasksStatusByTeamId &&
                    TasksStatusByTeamId[3] &&
                    TasksStatusByTeamId[3].WorkflowTaskStateTypeId === 2
                ),
        };
        let isContractEnabled = false;
        //check iff contract fields to be displayed or not
        if (globalMdmDetail && globalMdmDetail.CategoryTypeId != undefined) {
            let categoryTypeid = parseAndSanitise(globalMdmDetail.CategoryTypeId);
            if (
                categoryTypeid === 1 ||
                categoryTypeid === 2 ||
                categoryTypeid === 3 ||
                categoryTypeid === 6
            ) {
                isContractEnabled = true;
            }
        }
        const isWorkFlowReadOnly = workflow.isReadOnly;

        const inputReadonlyProps = workflow.isReadOnly ? { disabled: true } : null;
        const requestDateUtc = moment(globalMdmDetail.DateOfCreation);
        const localDate = moment(requestDateUtc).local();
        const requestDate = localDate.format('YYYY-MM-DD hh:mm:ss A');

        const showFunctionalDetail =
            state.isReadOnly && customerMaster === null ? { display: 'none' } : null;

        const enableDisplay = isWorkFlowReadOnly ? { display: 'none' } : null;

        let CreateJDETeamFields = [];
        CreateJDETeamFields =
            idx(jdeTeamRoleFieldMapping, (_) => _.CustomerMasterTeamFields) || [];
        const RoleTypeId = globalMdmDetail && globalMdmDetail.RoleTypeId;
        const SystemTypeId = globalMdmDetail && globalMdmDetail.SystemTypeId;
        const allowedFieldList = CreateJDETeamFields.filter((item) => {
            return item.RoleTypeId == RoleTypeId && item.SystemTypeId == SystemTypeId;
        });
        let allowedFields = undefined;
        if (allowedFieldList && allowedFieldList.length > 0) {
            allowedFields = allowedFieldList[0].Fields;
        }

        var bgcolor = alert.color || '#FFF';
        if (fetching) {
            return <Loading />;
        }

        const isPointman = globalMdmDetail && globalMdmDetail.SystemTypeId === 3;
        // console.log(this.props, '========', this.state);

        let toNegate =
            globalMdmDetail.AdditionalTaxNumber == 'ITRDHG' ? 'ITRDHI' : 'ITRDHG';
        let JDETaxRateAreaType = (staticData && staticData.JDETaxRateAreaType) || [];
        let TaxrateAreaDropdownVals = JDETaxRateAreaType.filter(
            (item) => !(item.Value === toNegate)
        );

        return (
            <ScrollView
                keyboardShouldPersistTaps="always"
                style={{
                    backgroundColor: '#EFF3F6',
                    paddingBottom: 75,
                }}>
                <Modal isVisible={this.state.modalVisible} style={{ overflow: 'auto' }}>
                    {this._renderModalContent()}
                </Modal>
                <FlashMessages
                    toasts={this.props.toasts}
                    onDismiss={this.props.removeMessage}
                />
                {alert.display && (
                    <FlashMessage
                        bg={{ backgroundColor: bgcolor }}
                        message={alert.message}
                    />
                )}

                <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between">
                    <Text
                        style={{
                            fontWeight: '500',
                            fontSize: 24,
                            color: Colors.lightBlue,
                            marginTop: 25,
                            marginBottom: 20,
                            paddingLeft: 45,
                        }}>
                        My Tasks - {state.Header}
                    </Text>
                    <Box
                        marginTop="25px"
                        flex={3 / 4}
                        flexDirection="row-reverse"
                        alignItems="flex-end">
                        <MultiColorProgressBar readings={statusBarData} />
                    </Box>
                </Box>
                <Box marginTop="25px" flexDirection="row-reverse" alignItems="flex-end">
                    <StyledText
                        style={{
                            fontWeight: '600',
                            paddingRight: '20px',
                            fontSize: '16px',
                            color: '#11307D',
                        }}>
                        {' '}
                        Requestor : {RequestorName}{' '}
                    </StyledText>
                </Box>
                <Box flexDirection="row-reverse" alignItems="flex-end">
                    <StyledText
                        style={{
                            fontWeight: '600',
                            paddingRight: '20px',
                            fontSize: '16px',
                            color: '#11307D',
                        }}>
                        {' '}
                        Date of Request: {requestDate}{' '}
                    </StyledText>
                </Box>
                <View
                    style={{
                        flex: 1,
                        paddingHorizontal: width < 1440 ? 60 : width * 0.1,
                        paddingBottom: 10,
                    }}>
                    <Box fullHeight my={2}>
                        <Box flexDirection="row" justifyContent="space-around" my={4}>
                            <FormInput
                                px="25px"
                                flex={1 / 4}
                                label="WorkflowTitle"
                                maxLength={40}
                                name="WorkflowTitle"
                                variant="outline"
                                type="text"
                                value={globalMdmDetail && globalMdmDetail.WorkflowTitle}
                            />
                            <FormInput
                                px="25px"
                                flex={1 / 4}
                                label="Workflow Number"
                                name="workflow-number"
                                variant="outline"
                                type="text"
                                value={globalMdmDetail && globalMdmDetail.WorkflowId}
                            />
                            {globalMdmDetail.SystemRecordId && (
                                <FormInput
                                    px="25px"
                                    flex={1 / 4}
                                    label="Customer Number"
                                    name="CustomerNumber"
                                    variant="outline"
                                    type="text"
                                    value={globalMdmDetail.SystemRecordId}
                                />
                            )}
                            {globalMdmDetail &&
                                globalMdmDetail.MdmCustomerId &&
                                (isReleased ? (
                                    <TouchableOpacity
                                        onPress={() =>
                                            this.props.history.push(
                                                `/search-results/${globalMdmDetail.MdmCustomerId}`
                                            )
                                        }>
                                        <FormInput
                                            px="25px"
                                            flex={1 / 4}
                                            label="MDM Number"
                                            name="mdm-number"
                                            variant="outline"
                                            type="text"
                                            value={
                                                globalMdmDetail &&
                                                globalMdmDetail.MdmCustomerId
                                            }
                                        />
                                    </TouchableOpacity>
                                ) : (
                                    <FormInput
                                        px="25px"
                                        flex={1 / 4}
                                        label="MDM Number"
                                        name="mdm-number"
                                        variant="outline"
                                        type="text"
                                        value={
                                            globalMdmDetail &&
                                            globalMdmDetail.MdmCustomerId
                                        }
                                    />
                                ))}
                        </Box>

                        {!this.state.isInternationalVersionVisible && (
                            <JdeGlobalMdmFields
                                showMarketingSegmentation={true}
                                formData={{
                                    ...globalMdmDetail,
                                    DunsNumber:
                                        this.state.formData.DunsNumber ||
                                        globalMdmDetail.DunsNumber,
                                    MarketingSegmentationTypeId:
                                        idx(
                                            this.state,
                                            (_) => _.formData.MarketingSegmentationTypeId
                                        ) ||
                                        idx(
                                            customerMaster,
                                            (_) => _.MarketingSegmentationTypeId
                                        ) ||
                                        idx(
                                            globalMdmDetail,
                                            (_) => _.MarketingSegmentationTypeId
                                        ),
                                }}
                                // dunsEditable={!workflow.isReadOnly}
                                editableInputs={{ TaxNumber: false }}
                                onDunsNewInput={this.onDunsNewInput}
                                formErrors={this.state.formErrors}
                                onFieldChange={this.onFieldChange}
                                staticDropDownData={this.props.staticData}
                                viewInternationalModal={() => {
                                    this.setState({
                                        isInternationalVersionVisible: true,
                                    });
                                }}
                                // isOlympus={true}
                                readOnly
                            />
                        )}

                        <Text
                            mt="42px"
                            mb="20px"
                            fontWeight="regular"
                            color="lightBlue"
                            fontSize={24}
                            pl={4}>
                            SYSTEM FIELDS
                        </Text>
                        <JDESystemFields
                            formData={{ ...globalMdmDetail }}
                            allowedFields={allowedFields}
                            {...this.props}
                            isReadOnly={true}
                        />
                        {/* <Box flexDirection="row" justifyContent="center">
                            <Box width={1 / 2} mx="auto" alignItems="center">
                                <FormInput
                                    label="System"
                                    name="System"
                                    inline={true}
                                    variant="outline"
                                    type="text"
                                    value={
                                        SystemType[
                                            globalMdmDetail &&
                                                globalMdmDetail.SystemTypeId
                                        ]
                                    }
                                />
                                <FormInput
                                    label="Role"
                                    name="Role"
                                    inline={true}
                                    variant="outline"
                                    type="text"
                                    value={
                                        staticData &&
                                        staticData &&
                                        this.getDropdownValues(
                                            globalMdmDetail.RoleTypeId,
                                            staticData &&
                                                this.props.staticData.OlympusRoleType
                                        )
                                    }
                                />
                                <FormInput
                                    label="Sales Org"
                                    name="SalesOrg"
                                    inline={true}
                                    variant="outline"
                                    type="text"
                                    value={
                                        staticData &&
                                        globalMdmDetail &&
                                        this.getDropdownValues(
                                            globalMdmDetail.SalesOrgTypeId,
                                            staticData && staticData.OlympusSalesOrgType
                                        )
                                    }
                                />
                                <FormInput
                                    label="Search Type"
                                    name="SearchType"
                                    variant="outline"
                                    inline={true}
                                    type="text"
                                    value={
                                        globalMdmDetail && globalMdmDetail['SearchType']
                                    }
                                />

                                <FormInput
                                    label="Language"
                                    name="Language"
                                    variant="outline"
                                    inline={true}
                                    type="text"
                                    value={globalMdmDetail && globalMdmDetail['Language']}
                                />

                                <FormInput
                                    label="IPA/Destination code"
                                    name="IPA/DestinationCode"
                                    variant="outline"
                                    inline={true}
                                    type="text"
                                    value={
                                        globalMdmDetail &&
                                        globalMdmDetail['DestinationCode']
                                    }
                                />

                                <FormInput
                                    label="Additional Tax Number"
                                    name="AdditionalTaxNumber"
                                    variant="outline"
                                    inline={true}
                                    type="text"
                                    value={
                                        globalMdmDetail &&
                                        globalMdmDetail['AdditionalTaxNumber']
                                    }
                                />

                                <FormInput
                                    label="Related Address Number"
                                    name="RelatedAddressNumber"
                                    variant="outline"
                                    inline={true}
                                    type="text"
                                    value={
                                        globalMdmDetail &&
                                        globalMdmDetail['RelatedAddressNumber']
                                    }
                                />

                                <FormInput
                                    label="Account/Sales Reps"
                                    name="Account/SalesReps"
                                    variant="outline"
                                    inline={true}
                                    type="text"
                                    value={
                                        globalMdmDetail &&
                                        globalMdmDetail['Account/SalesReps']
                                    }
                                />

                                <FormInput
                                    label="Payment Terms"
                                    name="PaymentTerms"
                                    variant="outline"
                                    inline={true}
                                    type="text"
                                    value={
                                        globalMdmDetail && globalMdmDetail['PaymentTerms']
                                    }
                                />
                            </Box>
                            <Box width={1 / 2} mx="auto" alignItems="center">
                                <FormInput
                                    label="Cat. Code 08 (Sales area)"
                                    name="CatCode8"
                                    variant="outline"
                                    inline={true}
                                    type="text"
                                    value={globalMdmDetail && globalMdmDetail['CatCode8']}
                                />

                                <FormInput
                                    label="Cat. Code 9 (region)"
                                    name="CatCode9"
                                    variant="outline"
                                    inline={true}
                                    type="text"
                                    value={globalMdmDetail && globalMdmDetail['CatCode9']}
                                />

                                <FormInput
                                    label="Cat. Code 10 (Language for documents)"
                                    name="CatCode10"
                                    variant="outline"
                                    inline={true}
                                    type="text"
                                    value={
                                        globalMdmDetail && globalMdmDetail['CatCode10']
                                    }
                                />

                                <FormInput
                                    label="Cat. Code 23 (GPO)"
                                    name="CatCode23"
                                    variant="outline"
                                    inline={true}
                                    type="text"
                                    value={
                                        globalMdmDetail && globalMdmDetail['CatCode23']
                                    }
                                />

                                <FormInput
                                    label="Currency"
                                    name="Currency"
                                    variant="outline"
                                    inline={true}
                                    type="text"
                                    value={globalMdmDetail && globalMdmDetail['Currency']}
                                />

                                <FormInput
                                    label="Price Group"
                                    name="PriceGroup"
                                    variant="outline"
                                    inline={true}
                                    type="text"
                                    value={
                                        globalMdmDetail && globalMdmDetail['PriceGroup']
                                    }
                                />

                                <FormInput
                                    label="Incoterms"
                                    name="Incoterms"
                                    variant="outline"
                                    inline={true}
                                    type="text"
                                    value={
                                        globalMdmDetail && globalMdmDetail['Incoterms']
                                    }
                                />

                                <FormInput
                                    label="Carrier"
                                    name="Carrier"
                                    variant="outline"
                                    inline={true}
                                    type="text"
                                    value={globalMdmDetail && globalMdmDetail['Carrier']}
                                />

                                <FormInput
                                    variant="outline"
                                    label="Effective Date"
                                    name="EffectiveDate"
                                    value={
                                        globalMdmDetail &&
                                        moment(globalMdmDetail['EffectiveDate']).format(
                                            'YYYY-MM-DD'
                                        )
                                    }
                                    type="date"
                                    style={{ height: 'inherit' }}
                                    inline={true}
                                />

                                <FormInput
                                    label="Purpose of Request"
                                    name="Purpose"
                                    multiline
                                    value={globalMdmDetail && globalMdmDetail.Purpose}
                                    numberOfLines={
                                        globalMdmDetail.Purpose &&
                                        globalMdmDetail.Purpose.length > 36
                                            ? 3
                                            : 1
                                    }
                                    style={{ lineHeight: '2.5' }}
                                    inline
                                    maxLength={1000}
                                    variant="outline"
                                    type="text"
                                />
                            </Box>
                        </Box> */}

                        <Box {...showFunctionalDetail}>
                            <Text
                                mt="42px"
                                mb="20px"
                                fontWeight="regular"
                                color="lightBlue"
                                fontSize={24}
                                pl={4}>
                                CUSTOMER MASTER FIELDS
                            </Text>
                            <Box flexDirection="row" justifyContent="center">
                                <Box width={1 / 2} mx="auto" alignItems="center">
                                    <FormInput
                                        label="Alpha Name"
                                        name="AlphaName"
                                        maxLength={40}
                                        error={
                                            this.state.formErrors
                                                ? this.state.formErrors['AlphaName']
                                                : null
                                        }
                                        onChange={this.onFieldChange}
                                        type="text"
                                        value={
                                            isWorkFlowReadOnly
                                                ? customerMaster &&
                                                  customerMaster.AlphaName
                                                : this.state.formData
                                                ? this.state.formData['AlphaName']
                                                : null
                                        }
                                        required
                                        variant={isWorkFlowReadOnly ? 'outline' : 'solid'}
                                        inline={isWorkFlowReadOnly ? true : false}
                                    />

                                    {/* <FormInput
                                        label="Credit Limit"
                                        name="CreditLimit"
                                        maxLength={20}
                                        error={
                                            this.state.formErrors
                                                ? this.state.formErrors['CreditLimit']
                                                : null
                                        }
                                        onChange={this.onFieldChange}
                                        type="text"
                                        required={
                                            globalMdmDetail &&
                                            (globalMdmDetail.RoleTypeId == 1 ||
                                                globalMdmDetail.RoleTypeId == 2)
                                        }
                                        value={
                                            isWorkFlowReadOnly
                                                ? customerMaster &&
                                                  customerMaster.CreditLimit
                                                : this.state.formData
                                                ? this.state.formData['CreditLimit']
                                                : null
                                        }
                                        variant={isWorkFlowReadOnly ? 'outline' : 'solid'}
                                        inline={isWorkFlowReadOnly ? true : false}
                                        hide={
                                            allowedFields &&
                                            allowedFields.includes('CreditLimit')
                                        }
                                    /> */}

                                    <DynamicSelect
                                        arrayOfData={
                                            this.props.staticData &&
                                            this.props.staticData.JDEIndustryClassType
                                                ? this.props.staticData
                                                      .JDEIndustryClassType
                                                : []
                                        }
                                        value={formData['IndustryClassificationTypeId']}
                                        label="Industry Class"
                                        name="IndustryClassificationTypeId"
                                        isRequired
                                        formErrors={
                                            this.props.formErrors
                                                ? this.props.formErrors[
                                                      'IndustryClassificationTypeId'
                                                  ]
                                                : null
                                        }
                                        onFieldChange={this.onFieldChange}
                                        inputProps={inputReadonlyProps}
                                    />

                                    <DynamicSelect
                                        arrayOfData={
                                            staticData && staticData.JDECategoryCode03Type
                                        }
                                        label="Category Code 03 "
                                        name="CategoryCode03TypeId"
                                        isRequired={true}
                                        formErrors={
                                            this.state.formErrors
                                                ? this.state.formErrors[
                                                      'CategoryCode03TypeId'
                                                  ]
                                                : null
                                        }
                                        onFieldChange={this.onFieldChange}
                                        value={
                                            isWorkFlowReadOnly
                                                ? customerMaster &&
                                                  parseAndSanitise(
                                                      customerMaster.CategoryCode03TypeId
                                                  )
                                                : this.state.formData
                                                ? this.state.formData[
                                                      'CategoryCode03TypeId'
                                                  ]
                                                : null
                                        }
                                        inputProps={inputReadonlyProps}
                                    />
                                    <DynamicSelect
                                        arrayOfData={
                                            staticData &&
                                            staticData['JDE1099ReportingType']
                                        }
                                        label="1099 Reporting"
                                        name="Reporting1099TypeId"
                                        isRequired={true}
                                        formErrors={
                                            this.state.formErrors
                                                ? this.state.formErrors[
                                                      'Reporting1099TypeId'
                                                  ]
                                                : null
                                        }
                                        onFieldChange={this.onFieldChange}
                                        value={
                                            isWorkFlowReadOnly
                                                ? customerMaster &&
                                                  parseAndSanitise(
                                                      customerMaster[
                                                          'Reporting1099TypeId'
                                                      ]
                                                  )
                                                : this.state.formData
                                                ? this.state.formData[
                                                      'Reporting1099TypeId'
                                                  ]
                                                : null
                                        }
                                        inputProps={inputReadonlyProps}
                                    />

                                    <DynamicSelect
                                        arrayOfData={
                                            staticData && staticData.JDECategoryCode22Type
                                        }
                                        label="Cat. Code 22"
                                        name="CategoryCode22TypeId"
                                        isRequired={
                                            globalMdmDetail &&
                                            globalMdmDetail.MarketingSegmentationTypeId ==
                                                '6' &&
                                            globalMdmDetail.Country == 'DE'
                                        }
                                        formErrors={
                                            this.state.formErrors
                                                ? this.state.formErrors[
                                                      'CategoryCode22TypeId'
                                                  ]
                                                : null
                                        }
                                        onFieldChange={this.onFieldChange}
                                        value={
                                            isWorkFlowReadOnly
                                                ? customerMaster &&
                                                  parseAndSanitise(
                                                      customerMaster.CategoryCode22TypeId
                                                  )
                                                : this.state.formData
                                                ? this.state.formData[
                                                      'CategoryCode22TypeId'
                                                  ]
                                                : null
                                        }
                                        inputProps={inputReadonlyProps}
                                    />

                                    <DynamicSelect
                                        arrayOfData={
                                            staticData &&
                                            staticData.JDEPaymentInstructionType
                                        }
                                        label="Payment Instrument"
                                        name="PaymentInstructionTypeId"
                                        isRequired={
                                            this.state.isPaymentInstructionTypeRequired
                                        }
                                        formErrors={
                                            this.state.formErrors
                                                ? this.state.formErrors[
                                                      'PaymentInstructionTypeId'
                                                  ]
                                                : null
                                        }
                                        onFieldChange={this.onFieldChange}
                                        value={
                                            isWorkFlowReadOnly
                                                ? customerMaster &&
                                                  parseAndSanitise(
                                                      customerMaster.PaymentInstructionTypeId
                                                  )
                                                : this.state.formData
                                                ? this.state.formData[
                                                      'PaymentInstructionTypeId'
                                                  ]
                                                : null
                                        }
                                        inputProps={inputReadonlyProps}
                                    />

                                    <DynamicSelect
                                        arrayOfData={
                                            staticData &&
                                            staticData.JDECollectionManagerType
                                        }
                                        label="Collection Manager"
                                        name="CollectionManagerTypeId"
                                        isRequired={true}
                                        formErrors={
                                            this.state.formErrors
                                                ? this.state.formErrors[
                                                      'CollectionManagerTypeId'
                                                  ]
                                                : null
                                        }
                                        onFieldChange={this.onFieldChange}
                                        value={
                                            isWorkFlowReadOnly
                                                ? customerMaster &&
                                                  parseAndSanitise(
                                                      customerMaster.CollectionManagerTypeId
                                                  )
                                                : this.state.formData
                                                ? this.state.formData[
                                                      'CollectionManagerTypeId'
                                                  ]
                                                : null
                                        }
                                        inputProps={inputReadonlyProps}
                                    />

                                    <FormInput
                                        label="Contracts"
                                        name="Contracts"
                                        maxLength={8}
                                        error={
                                            this.state.formErrors
                                                ? this.state.formErrors['Contracts']
                                                : null
                                        }
                                        required
                                        onChange={this.onFieldChange}
                                        type="text"
                                        value={
                                            isWorkFlowReadOnly
                                                ? customerMaster &&
                                                  customerMaster.Contracts
                                                : this.state.formData
                                                ? this.state.formData['Contracts']
                                                : null
                                        }
                                        variant={isWorkFlowReadOnly ? 'outline' : 'solid'}
                                        inline={isWorkFlowReadOnly ? true : false}
                                    />
                                    <FormInput
                                        label="Work Order"
                                        name="WorkOrder"
                                        maxLength={8}
                                        error={
                                            this.state.formErrors
                                                ? this.state.formErrors['WorkOrder']
                                                : null
                                        }
                                        onChange={this.onFieldChange}
                                        type="text"
                                        required
                                        value={
                                            isWorkFlowReadOnly
                                                ? customerMaster &&
                                                  customerMaster.WorkOrder
                                                : this.state.formData
                                                ? this.state.formData['WorkOrder']
                                                : null
                                        }
                                        variant={isWorkFlowReadOnly ? 'outline' : 'solid'}
                                        inline={isWorkFlowReadOnly ? true : false}
                                    />

                                    {/* <DynamicSelect
                                        arrayOfData={
                                            staticData && staticData.JDECategoryCode28Type
                                        }
                                        label="Cat Code  28"
                                        name="CatCode28TypeId"
                                        // isRequired={true}
                                        formErrors={
                                            this.state.formErrors
                                                ? this.state.formErrors['CatCode28TypeId']
                                                : null
                                        }
                                        onFieldChange={this.onFieldChange}
                                        value={
                                            isWorkFlowReadOnly
                                                ? customerMaster &&
                                                  parseAndSanitise(
                                                      customerMaster.CatCode28TypeId
                                                  )
                                                : this.state.formData
                                                ? this.state.formData['CatCode28TypeId']
                                                : null
                                        }
                                        inputProps={inputReadonlyProps}
                                    />

                                    <DynamicSelect
                                        arrayOfData={
                                            staticData && staticData.JDECategoryCode29Type
                                        }
                                        label="Cat Code 29"
                                        name="CatCode29TypeId"
                                        // isRequired={true}
                                        formErrors={
                                            this.state.formErrors
                                                ? this.state.formErrors['CatCode29TypeId']
                                                : null
                                        }
                                        onFieldChange={this.onFieldChange}
                                        value={
                                            isWorkFlowReadOnly
                                                ? customerMaster &&
                                                  parseAndSanitise(
                                                      customerMaster.CatCode29TypeId
                                                  )
                                                : this.state.formData
                                                ? this.state.formData['CatCode29TypeId']
                                                : null
                                        }
                                        inputProps={inputReadonlyProps}
                                    /> */}

                                    {/* <FormInput
                                        label="Payables"
                                        name="Payables"
                                        inline={true}
                                        variant="outline"
                                        type="text"
                                        value={this.state.formData['Payables']}
                                    />
                                    <FormInput
                                        label="AR/AP Netting"
                                        name="ArApNetting"
                                        inline={true}
                                        variant="outline"
                                        type="text"
                                        value={this.state.formData['ArApNetting']}
                                    />

                                    <FormInput
                                        label="Send Statement To "
                                        name="SendStatementTo"
                                        inline={true}
                                        variant="outline"
                                        type="text"
                                        value={this.state.formData['SendStatementTo']}
                                    />
                                    <FormInput
                                        label="Statement Cycle"
                                        name="StatementCycle"
                                        inline={true}
                                        variant="outline"
                                        type="text"
                                        value={this.state.formData['StatementCycle']}
                                    />
                                    <FormInput
                                        label="Batch Processing Mode"
                                        name="BatchProcessingMode"
                                        inline={true}
                                        variant="outline"
                                        type="text"
                                        value={this.state.formData['BatchProcessingMode']}
                                    />
                                    <FormInput
                                        label="Credit Check Level"
                                        name="CreditCheckLevel"
                                        inline={true}
                                        variant="outline"
                                        type="text"
                                        value={this.state.formData['CreditCheckLevel']}
                                    />
                                    <FormInput
                                        label="Priority Processing Code"
                                        name="PriorityProcessingCode"
                                        inline={true}
                                        variant="outline"
                                        type="text"
                                        value={
                                            this.state.formData['PriorityProcessingCode']
                                        }
                                    />
                                    <FormInput
                                        label="Adjustment On Invoice"
                                        name="AdjustmentOnInvoice"
                                        inline={true}
                                        variant="outline"
                                        type="text"
                                        value={this.state.formData['AdjustmentOnInvoice']}
                                    />
                                    <FormInput
                                        label="Long Address Number"
                                        name="LongAddressNumber"
                                        inline={true}
                                        variant="outline"
                                        type="text"
                                        value={this.state.formData['LongAddressNumber']}
                                    /> */}
                                </Box>
                                <Box width={1 / 2} mx="auto" alignItems="center">
                                    <DynamicSelect
                                        arrayOfData={
                                            staticData && staticData.JDEPolicyNameType
                                        }
                                        label="PolicyName"
                                        name="PolicyNameTypeId"
                                        isRequired={true}
                                        formErrors={
                                            this.state.formErrors
                                                ? this.state.formErrors[
                                                      'PolicyNameTypeId'
                                                  ]
                                                : null
                                        }
                                        onFieldChange={this.onFieldChange}
                                        value={
                                            isWorkFlowReadOnly
                                                ? customerMaster &&
                                                  parseAndSanitise(
                                                      customerMaster.PolicyNameTypeId
                                                  )
                                                : this.state.formData
                                                ? this.state.formData['PolicyNameTypeId']
                                                : null
                                        }
                                        inputProps={inputReadonlyProps}
                                    />

                                    <DynamicSelect
                                        arrayOfData={
                                            staticData && staticData.JDECompanyType
                                        }
                                        label="Company"
                                        name="CompanyTypeId"
                                        isRequired={true}
                                        formErrors={
                                            this.state.formErrors
                                                ? this.state.formErrors['CompanyTypeId']
                                                : null
                                        }
                                        onFieldChange={this.onFieldChange}
                                        value={
                                            isWorkFlowReadOnly
                                                ? customerMaster &&
                                                  parseAndSanitise(
                                                      customerMaster.CompanyTypeId
                                                  )
                                                : this.state.formData
                                                ? this.state.formData['CompanyTypeId']
                                                : null
                                        }
                                        inputProps={inputReadonlyProps}
                                    />
                                    <DynamicSelect
                                        arrayOfData={
                                            staticData && staticData.JDEGLOffsetType
                                        }
                                        label="G/L Offset"
                                        name="GlOffsetTypeId"
                                        isRequired={true}
                                        formErrors={
                                            this.state.formErrors
                                                ? this.state.formErrors['GlOffsetTypeId']
                                                : null
                                        }
                                        onFieldChange={this.onFieldChange}
                                        value={
                                            isWorkFlowReadOnly
                                                ? customerMaster &&
                                                  parseAndSanitise(
                                                      customerMaster.GlOffsetTypeId
                                                  )
                                                : this.state.formData
                                                ? this.state.formData['GlOffsetTypeId']
                                                : null
                                        }
                                        inputProps={inputReadonlyProps}
                                    />
                                    <DynamicSelect
                                        arrayOfData={
                                            staticData && staticData.JDETaxExplCodeType
                                        }
                                        label="Tax Expl Code"
                                        name="TaxExplCodeTypeId"
                                        isRequired={true}
                                        formErrors={
                                            this.state.formErrors
                                                ? this.state.formErrors[
                                                      'TaxExplCodeTypeId'
                                                  ]
                                                : null
                                        }
                                        onFieldChange={this.onFieldChange}
                                        value={
                                            isWorkFlowReadOnly
                                                ? customerMaster &&
                                                  parseAndSanitise(
                                                      customerMaster.TaxExplCodeTypeId
                                                  )
                                                : this.state.formData
                                                ? this.state.formData['TaxExplCodeTypeId']
                                                : null
                                        }
                                        inputProps={inputReadonlyProps}
                                    />
                                    <DynamicSelect
                                        arrayOfData={TaxrateAreaDropdownVals}
                                        label="Tax Rate/Area"
                                        name="TaxRateAreaTypeId"
                                        isRequired={this.state.isTaxRateAreaTypeRequired}
                                        formErrors={
                                            this.state.formErrors
                                                ? this.state.formErrors[
                                                      'TaxRateAreaTypeId'
                                                  ]
                                                : null
                                        }
                                        onFieldChange={this.onFieldChange}
                                        value={
                                            isWorkFlowReadOnly
                                                ? customerMaster &&
                                                  parseAndSanitise(
                                                      customerMaster.TaxRateAreaTypeId
                                                  )
                                                : this.state.formData
                                                ? this.state.formData['TaxRateAreaTypeId']
                                                : null
                                        }
                                        inputProps={inputReadonlyProps}
                                    />
                                    <DynamicSelect
                                        arrayOfData={
                                            staticData && staticData.JDESendMethodType
                                        }
                                        label="Send Method"
                                        name="SendMethodTypeId"
                                        isRequired={true}
                                        formErrors={
                                            this.state.formErrors
                                                ? this.state.formErrors[
                                                      'SendMethodTypeId'
                                                  ]
                                                : null
                                        }
                                        onFieldChange={this.onFieldChange}
                                        value={
                                            isWorkFlowReadOnly
                                                ? customerMaster &&
                                                  parseAndSanitise(
                                                      customerMaster.SendMethodTypeId
                                                  )
                                                : this.state.formData
                                                ? this.state.formData['SendMethodTypeId']
                                                : null
                                        }
                                        inputProps={inputReadonlyProps}
                                    />
                                    <DynamicSelect
                                        arrayOfData={
                                            staticData &&
                                            staticData.JDEBillingAddressTypeType
                                        }
                                        label="Billing Address Type"
                                        name="BillingAddressTypeId"
                                        isRequired={true}
                                        formErrors={
                                            this.state.formErrors
                                                ? this.state.formErrors[
                                                      'BillingAddressTypeId'
                                                  ]
                                                : null
                                        }
                                        onFieldChange={this.onFieldChange}
                                        value={
                                            isWorkFlowReadOnly
                                                ? customerMaster &&
                                                  parseAndSanitise(
                                                      customerMaster.BillingAddressTypeId
                                                  )
                                                : this.state.formData
                                                ? this.state.formData[
                                                      'BillingAddressTypeId'
                                                  ]
                                                : null
                                        }
                                        inputProps={inputReadonlyProps}
                                    />
                                    <DynamicSelect
                                        arrayOfData={
                                            staticData &&
                                            staticData.JDERelatedAddressNumType
                                        }
                                        label="Related Address Num"
                                        name="RelatedAddressNumTypeId"
                                        isRequired={true}
                                        formErrors={
                                            this.state.formErrors
                                                ? this.state.formErrors[
                                                      'RelatedAddressNumTypeId'
                                                  ]
                                                : null
                                        }
                                        onFieldChange={this.onFieldChange}
                                        value={
                                            isWorkFlowReadOnly
                                                ? customerMaster &&
                                                  parseAndSanitise(
                                                      customerMaster.RelatedAddressNumTypeId
                                                  )
                                                : this.state.formData
                                                ? this.state.formData[
                                                      'RelatedAddressNumTypeId'
                                                  ]
                                                : null
                                        }
                                        inputProps={inputReadonlyProps}
                                    />
                                    <DynamicSelect
                                        arrayOfData={
                                            staticData &&
                                            staticData.JDEAdjustmentScheduleType
                                        }
                                        label="Adjustment Schedule"
                                        name="AdjustmentScheduleTypeId"
                                        isRequired={
                                            this.state.isAdjustmentScheduleTypeRequired
                                        }
                                        formErrors={
                                            this.state.formErrors
                                                ? this.state.formErrors[
                                                      'AdjustmentScheduleTypeId'
                                                  ]
                                                : null
                                        }
                                        onFieldChange={this.onFieldChange}
                                        value={
                                            isWorkFlowReadOnly
                                                ? customerMaster &&
                                                  parseAndSanitise(
                                                      customerMaster.AdjustmentScheduleTypeId
                                                  )
                                                : this.state.formData
                                                ? this.state.formData[
                                                      'AdjustmentScheduleTypeId'
                                                  ]
                                                : null
                                        }
                                        inputProps={inputReadonlyProps}
                                    />
                                    <DynamicSelect
                                        arrayOfData={
                                            staticData &&
                                            staticData.JDEItemRestrictionsType
                                        }
                                        label="Item Restrictions"
                                        name="ItemRestrictionsTypeId"
                                        isRequired={true}
                                        formErrors={
                                            this.state.formErrors
                                                ? this.state.formErrors[
                                                      'ItemRestrictionsTypeId'
                                                  ]
                                                : null
                                        }
                                        onFieldChange={this.onFieldChange}
                                        value={
                                            isWorkFlowReadOnly
                                                ? customerMaster &&
                                                  parseAndSanitise(
                                                      customerMaster.ItemRestrictionsTypeId
                                                  )
                                                : this.state.formData
                                                ? this.state.formData[
                                                      'ItemRestrictionsTypeId'
                                                  ]
                                                : null
                                        }
                                        inputProps={inputReadonlyProps}
                                    />
                                    <DynamicSelect
                                        arrayOfData={
                                            staticData && staticData.JDEGeographicalType
                                        }
                                        label="Geographic Region"
                                        name="GeographicRegionTypeId"
                                        isRequired={true}
                                        formErrors={
                                            this.state.formErrors
                                                ? this.state.formErrors[
                                                      'GeographicRegionTypeId'
                                                  ]
                                                : null
                                        }
                                        onFieldChange={this.onFieldChange}
                                        value={
                                            isWorkFlowReadOnly
                                                ? customerMaster &&
                                                  parseAndSanitise(
                                                      customerMaster.GeographicRegionTypeId
                                                  )
                                                : this.state.formData
                                                ? this.state.formData[
                                                      'GeographicRegionTypeId'
                                                  ]
                                                : null
                                        }
                                        inputProps={inputReadonlyProps}
                                    />
                                    <DynamicSelect
                                        arrayOfData={
                                            staticData && staticData.JDETimeZoneType
                                        }
                                        label="Timezone"
                                        name="TimeZoneTypeId"
                                        isRequired={true}
                                        formErrors={
                                            this.state.formErrors
                                                ? this.state.formErrors['TimeZoneTypeId']
                                                : null
                                        }
                                        onFieldChange={this.onFieldChange}
                                        value={
                                            isWorkFlowReadOnly
                                                ? customerMaster &&
                                                  parseAndSanitise(
                                                      customerMaster.TimeZoneTypeId
                                                  )
                                                : this.state.formData
                                                ? this.state.formData['TimeZoneTypeId']
                                                : null
                                        }
                                        inputProps={inputReadonlyProps}
                                    />
                                </Box>
                            </Box>
                        </Box>
                        {globalMdmDetail.LikelyMatches && (
                            <>
                                <Text
                                    mt="42px"
                                    mb="20px"
                                    fontWeight="regular"
                                    color="lightBlue"
                                    fontSize={24}
                                    pl={4}>
                                    LIKELY MATCHES
                                </Text>
                                {globalMdmDetail.AllowMdmGrouping == false && (
                                    <Text
                                        fontWeight="regular"
                                        color="red"
                                        fontSize={13}
                                        ml={'10%'}>
                                        MDM Assignment is not enabled because DUNS#
                                        matches {globalMdmDetail.MdmDunsMatch}
                                    </Text>
                                )}
                                <Box ml={'10%'} width={3 / 4} justifyContent="center">
                                    <Box
                                        flexDirection="row"
                                        justifyContent="center"
                                        alignItems="center">
                                        <CollapsibleTable
                                            AllowMdmGrouping={
                                                globalMdmDetail.AllowMdmGrouping !=
                                                undefined
                                                    ? globalMdmDetail.AllowMdmGrouping
                                                    : true
                                            }
                                            readOnly={workflow.isReadOnly}
                                            MdmNumberToGroup={
                                                (customerMaster &&
                                                    customerMaster.MdmNumberToGroup) ||
                                                null
                                            }
                                            MDMDunsMatch={123142452}
                                            checkboxState={this.state.checkboxState}
                                            setSelectedMDM={this.setMDM}
                                            data={globalMdmDetail.LikelyMatches}
                                        />
                                    </Box>
                                </Box>
                            </>
                        )}

                        {files && <FilesList files={files} readOnly />}
                        {workflow && workflow.isReadOnly ? (
                            <>
                                <Box my={4}>
                                    <Box flexDirection="row" justifyContent="center">
                                        <Box width={1 / 2} mx="auto" alignItems="center">
                                            <FormInput
                                                label="Notes"
                                                name="AdditionalNotes"
                                                multiline
                                                numberOfLines={2}
                                                type="text"
                                                inputWidth="100%"
                                                value={
                                                    isWorkFlowReadOnly
                                                        ? Notes
                                                        : this.state.formData
                                                        ? this.state.formData[
                                                              'AdditionalNotes'
                                                          ]
                                                        : null
                                                }
                                                variant={
                                                    isWorkFlowReadOnly
                                                        ? 'outline'
                                                        : 'solid'
                                                }
                                                inline={isWorkFlowReadOnly ? true : false}
                                            />
                                        </Box>
                                        <Box
                                            width={1 / 2}
                                            mx="auto"
                                            alignItems="center"></Box>
                                    </Box>
                                </Box>
                            </>
                        ) : (
                            <FilesList
                                title="NEW ATTACHMENTS"
                                formErrors={this.state.fileErrors}
                                files={selectedFilesIds.map((id) => selectedFiles[id])}
                                onChange={(value, id) => {
                                    this.setState({
                                        selectedFiles: {
                                            ...selectedFiles,
                                            [id]: {
                                                ...selectedFiles[id],
                                                DocumentType: parseInt(value),
                                            },
                                        },
                                    });
                                }}
                                onRemove={(value, id) => this.removeFile(id)}
                            />
                        )}
                    </Box>
                    <Box {...enableDisplay}>
                        <Flex
                            justifyEnd
                            alignCenter
                            style={{
                                paddingTop: 15,
                                flexDirection: 'row',
                                alignItems: 'center',
                                paddingLeft: 10,
                                paddingRight: 15,
                                marginTop: 20,
                                marginBottom: 10,
                                marginHorizontal: 25,
                            }}>
                            <>
                                <label
                                    htmlFor="file-upload"
                                    className="custom-file-upload">
                                    <MaterialIcons
                                        name="attach-file"
                                        size={18}
                                        color="#fff"
                                    />
                                </label>
                                <input
                                    id="file-upload"
                                    type="file"
                                    key={this.state.fileinputkey}
                                    onChange={this.selectFiles}
                                    multiple
                                />
                                <span id="testError"></span>
                            </>
                            <Button
                                onPress={(event) => {
                                    this.setState({
                                        formErrors: {},
                                        isReject: false,
                                        modalVisible: true,
                                    });
                                }}
                                title="Approve"
                            />
                            <Button
                                title="Reject"
                                onPress={(event) =>
                                    this.setState({ isReject: true, modalVisible: true })
                                }
                            />
                        </Flex>
                    </Box>
                </View>
            </ScrollView>
        );
    }
}

class Default extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const props = this.props;

        return (
            <DimensionAware
                render={(dimensions) => (
                    <Page
                        {...{
                            ...props,
                            width: getWindowWidth(dimensions),
                            height: getWindowHeight(dimensions),
                            marginBottom: 25,
                        }}
                    />
                )}
            />
        );
    }
}

const mapStateToProps = ({ workflows, myTasks, toasts, staticData }) => {
    const { fetching, alert } = myTasks;
    const {
        fetchingfnGroupData,
        statusBarData,
        functionalGroupDetails,
        TasksStatusByTeamId,
        fetchingStatusBar,
        dunsFields = {},
    } = workflows;
    const { data, fetching: staticFetching } = staticData;
    return {
        fetching: fetching || fetchingStatusBar || fetchingfnGroupData || staticFetching,
        dunsFields,
        alert,
        statusBarData,
        functionalGroupDetails,
        TasksStatusByTeamId,
        toasts,
        staticData: data,
    };
};

export default connect(mapStateToProps, {
    saveApolloMyTaskCustomerMaster,
    getFunctionalGroupData,
    getStatusBarData,
    removeMessage,
    fetchDuns,
    getStaticRequest,
})(Default);

const styles = StyleSheet.create({
    progressIndicator: {
        flex: 1,
        paddingBottom: 5,
        flexDirection: 'row-reverse',
        alignItems: 'flex-end',
    },
    statusText: {
        fontSize: 15,
        color: '#1D4289',
        fontFamily: 'Poppins',
        textAlign: 'center',
        marginTop: 20,
    },
});
