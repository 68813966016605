import React from 'react';
import {
    ScrollView,
    View,
    TouchableOpacity,
    ActivityIndicator,
    Image,
    StyleSheet,
} from 'react-native';
import {
    DimensionAware,
    getWindowHeight,
    getWindowWidth,
} from 'react-native-dimension-aware';
import { MaterialIcons } from '@expo/vector-icons';
import { Colors, StyledText } from '../../../theme/Index';
import { Flex, Column, Card, Button, Box, Text } from '../../../components/common';
import { FormInput, FormSelect } from '../../../components/form';
import {
    resolveDependencies,
    passFields,
    yupFieldValidation,
    parseAndSanitise,
    yupAllFieldsValidation,
} from '../../../constants/utils';
import {
    FieldValidationRoleType,
    disabled_mdmFieldsRules,
    mdmFieldsRules,
    ExtendFieldRules,
    OlympusExtendFieldRules,
} from '../../../constants/FieldRules';
import {
    getCustomerFromSAP,
    withDrawRequest,
    getStatusBarData,
} from '../../../appRedux/actions';
import { removeMessage } from '../../../appRedux/actions/Toast';
import GlobalMdmFields from '../../../components/GlobalMdmFields';
import SystemFields from '../../../components/SystemFields';
import { CheckBoxItem } from '../../../components/CheckBoxItem';
import DynamicSelect from '../../../components/DynamicSelect';
import { connect } from 'react-redux';
import {
    fetchCustomerMasterDropDownData,
    fetchRoleFieldMapping,
    fetchOlympusFieldMapping,
} from '../../../redux/DropDownDatas';
import Loading from '../../../components/Loading';
import FlashMessage, { FlashMessages } from '../../../components/FlashMessage';
import { ajaxGetRequest } from '../../../appRedux/sagas/config';
import { updateDeltas } from '../../../appRedux/actions/UpdateFlowAction';
import { getStaticRequest } from '../../../appRedux/actions';
import FilesList from '../../../components/FilesList';
import TaskNotes from '../../../components/TaskNotes';
import FileInput from '../../../components/form/FileInput.jsx';
import DeltaField from '../../../components/DeltaField';
import {
    RoleType,
    SystemType,
    DistributionChannelType,
    DivisionType,
    CompanyCodeType,
    SalesOrgType,
} from '../../../constants/WorkflowEnums';

import { normalize, formatRequestorName } from '../../../appRedux/sagas/config';
import MultiColorProgressBar from '../../../components/MultiColorProgressBar';
import BreadCrumbs from '../../../components/BreadCrumbs';
import { GenerateWorkflowId } from '@env';
import idx from 'idx';
import { cos } from 'react-native-reanimated';
import Modal from 'modal-enhanced-react-native-web';
import * as moment from 'moment';

const _ = require('lodash');

const initFormdData = {
    SystemTypeId: 1,
};
class Page extends React.Component {
    constructor(props) {
        super(props);
        const editableProp = {
            inline: false,
            variant: 'solid',
            onChange: this.onFieldChange,
        };
        this.state = {
            system: '',
            role: '',
            dropDownDatas: {},
            roleFieldMapping: {},
            pricing: [],
            customermaster: [],
            credit: [],
            contracts: [],
            globaltrade: [],
            isContractEnabled: false,
            formData: {
                displayINCOT2: false,
                display_LN: false,
                TaxClassificationTypeId: 2,
                AccountStatementTypeId: 2,
                SortKey: '009',
                PaymentMethods: 'C',
                AcctgClerk: '01',
                CreditLimit: 1,
            },
            customer70Fields: {},
            updatedFormData: { display: false },
            formErrors: {},
            inputPropsForDefaultRules: { CustomerGroupTypeId: editableProp },
            fileErrors: {},
            selectedFiles: {},
            selectedFilesIds: [],
            files: [],
            dunsData: {},
            readOnly: false,
            isRequestPage: false,
            currentPage: 'update',
            extendSalesOrgEnabled: 'false',
            statusBarData: this.props.statusBarData,
            fileinputkey: Date.now(),
        };
    }

    generateWorkflowId(action) {
        if (action != 'update-request') {
            const url = GenerateWorkflowId;

            ajaxGetRequest(url).then((res) => {
                if (res.IsSuccess)
                    this.setState({
                        fetchingWorkflowId: false,
                        formData: {
                            ...initFormdData,
                            ...this.state.formData,
                            WorkflowId: res.ResultData,
                            UserId: this.state.userId,
                            WorkflowTitle: '',
                        },
                    });
            });
        }
    }

    componentDidMount() {
        const { action, id } = this.props.match.params;
        var jsonBody = {};
        fetchOlympusFieldMapping().then((resp) => {
            const data = resp;
            this.setState({ roleFieldMapping: data });
        });

        this.props.getStaticRequest([
            5,
            15,
            30,
            26,
            17,
            42,
            43,
            49,
            42,
            43,
            5,
            52,
            53,
            54,
            55,
            56,
            57,
            58,
            59,
            60,
            61,
            62,
            63,
            64,
            65,
            40,
            81,
            66,
            67,
            68,
            69,
            70,
            71,
            72,
            73,
            74,
            75,
            76,
            77,
            78,
            79,
            80,
            81,
            82,
            83,
            41,
            84,
        ]);

        fetchCustomerMasterDropDownData().then((res) => {
            const data = res;
            var isReqPage = action === 'update-request';
            var pageReadOnlyFields = action !== 'update'; //set readonly for my-requests & extend sales org & BlockUnblock .
            this.setState(
                {
                    dropDownDatas: data,
                    readOnly: pageReadOnlyFields,
                    isRequestPage: isReqPage,
                    currentPage: action,
                    extendSalesOrgEnabled: action === 'extend-salesorg' ? true : false,
                },
                this.generateWorkflowId(action)
            );
        });
        if (action === 'update-request') {
            //get sap details for my-request
            jsonBody = {
                WorkflowId: id,
                CustomerNumber: '',
                DivisionTypeId: 0,
                SystemTypeId: 0,
                DistributionChannelTypeId: 0,
                CompanyCodeTypeId: 0,
                SalesOrgTypeId: 0,
                RoleTypeId: 0,
            };
            this.props.getCustomerFromSAP(jsonBody);
            //show status bar for my requests functional team page
            let postJson = {
                workflowId: id,
                fuctionalGroup: '',
                taskId: '',
                userId: localStorage.getItem('userId'),
            };
            this.props.getStatusBarData(postJson);
        } else {
            //for update functional and extend to sales org
            const { state } = this.props.location;
            jsonBody = state.sysFieldsData;
            // jsonBody =
            // {
            //     "MdmNumber": "MDMCM001175382",
            //     "CustomerNumber": "0010000005",
            //     "SystemTypeId": 2,
            //     "RoleTypeId": 1,
            //     "SalesOrgTypeId": 3,
            //     "DistributionChannelTypeId": 4,
            //     "DivisionTypeId": 2,
            //     "CompanyCodeTypeId": 3
            // }

            this.props.getCustomerFromSAP(jsonBody);
            this.setContractsVisibility(state.CategoryTypeId);
        }
    }

    getDropdownList = (key, data, stateKey) => {
        let dropdownData = [];
        const self = this;
        const {
            functionalGroupDetails: {
                DocumentLocation: files,
                Customer: globalMdmDetail = {},
            },
            bapiFullSet,
        } = this.props;
        let systemFields = bapiFullSet.CustomerData;
        // console.log(systemFields);
        if (data && systemFields) {
            dropdownData = [];
            data.forEach((dropdownList) => {
                if (dropdownList.ValidationData.length > 0) {
                    dropdownList.ValidationData.forEach((datatofilter) => {
                        if (datatofilter[key] === systemFields[stateKey]) {
                            dropdownData.push(dropdownList);
                        }
                    });
                }
            });
        }
        var check = new Set();
        let filteredArr = dropdownData.filter(
            (obj) => !check.has(obj['Id']) && check.add(obj['Id'])
        );
        return filteredArr;
    };

    componentWillReceiveProps(newProps) {
        if (newProps.bapiFullSet != this.props.bapiFullSet) {
            this.setState(
                {
                    formData: {
                        ...this.state.formData,
                        ...newProps.bapiFullSet.CustomerData,
                        retrievedBlockUnBlockData: newProps.bapiFullSet.BlockUnblockData,
                        MdmNumber: this.props.match.params.id,
                    },
                    customer70Fields: {
                        ...newProps.bapiFullSet.CustomerData,
                        PaymentHistoryRecord:
                            newProps.bapiFullSet.CustomerData.PaymentHistoryRecord ===
                            'T',
                        OrderCombination:
                            newProps.bapiFullSet.CustomerData.OrderCombination === 'T',
                        Role:
                            FieldValidationRoleType[
                                newProps.bapiFullSet.CustomerData.RoleTypeId
                            ],
                    },
                },
                () => {
                    if (!this.state.isReqPage) {
                        // console.log(
                        //     'newProps.bapiFullSet.CustomerData',
                        //     newProps.bapiFullSet
                        // );
                        // this.props.fetchDuns({
                        //     UserId: localStorage.getItem('userId'),
                        //     DunsNumber: newProps.bapiFullSet.DunsNumber,
                        // });
                        this.createDunsObj(newProps.bapiFullSet.CustomerData);
                    }
                }
            );
        }
        if (newProps.statusBarData != this.props.statusBarData) {
            this.setState({
                statusBarData: newProps.statusBarData,
            });
        }
    }
    setContractsVisibility = (category) => {
        let categoryTypeid = parseInt(category);
        let showContract = false;
        //check if Category = Distributor, OEM, Kitter, Self-Distributor then Contract else Customermaster
        if (
            categoryTypeid === 1 ||
            categoryTypeid === 2 ||
            categoryTypeid === 3 ||
            categoryTypeid === 6
        ) {
            // showContractsForReqDetails = true;
            showContract = true;
        }
        this.setState({ isContractEnabled: showContract });
    };

    isEmpty = (str) => {
        // checking if a string is empty, null or undefined
        return !str || 0 === str.length;
    };

    formatDeltaObj = () => {
        const { action } = this.props.match.params;
        let customerDataModel = {},
            functionElements = [],
            teams = ['pricing', 'customermaster', 'contracts', 'credit', 'globaltrade'];
        for (var i = 0; i < teams.length; i++) {
            let team = teams[i];
            if (this.state[team] != undefined && this.state[team].length > 0) {
                let functionalDelta = {};
                functionalDelta['functionName'] = team;
                functionalDelta['customerElements'] = this.state[team];
                functionElements.push(functionalDelta);
            }
        }

        if (action === 'extend-salesorg') {
            if (functionElements.length <= 0) {
                customerDataModel['functionElements'] = null;
            } else {
                customerDataModel['functionElements'] = functionElements;
            }
        }

        if (action === 'update') {
            if (functionElements.length <= 0) {
                customerDataModel = null;
                this.setState({ noDeltaUpdates: true }, () => window.scrollTo(0, 0));
                setTimeout(
                    function () {
                        this.setState({ noDeltaUpdates: false });
                    }.bind(this),
                    1000
                );
            } else {
                customerDataModel['functionElements'] = functionElements;
            }
        }

        return customerDataModel;
    };

    proceedAction = () => {
        const { history, location } = this.props;
        const { action, id } = this.props.match.params;
        const { state } = location;
        const { formData, selectedFilesIds, selectedFiles } = this.state;
        let customerDataModel = this.formatDeltaObj();
        // let jsonObj = {
        //     userId: localStorage.getItem('userId'),
        //     workflowId: formData.WorkflowId,
        //     mdmCustomerId: formData.MdmNumber,
        //     WorkflowTitle: formData.WorkflowTitle,
        //     CustomerName: this.props.bapiFullSet.CustomerData.Name1,
        //     SystemRecordId: state.sysFieldsData.CustomerNumber,
        //     SystemTypeId: state.sysFieldsData.SystemTypeId,
        //     RoleTypeId: state.sysFieldsData.RoleTypeId,
        //     SalesOrgTypeId: state.sysFieldsData.SalesOrgTypeId,
        //     DivisionTypeId: state.sysFieldsData.DivisionTypeId,
        //     DistributionChannelTypeId: state.sysFieldsData.DistributionChannelTypeId,
        //     CompanyCodeTypeId: state.sysFieldsData.CompanyCodeTypeId,
        //     IsSaveToWorkflow: true,
        //     customerDataModel:
        //         formData.Purpose &&
        //             formData.Purpose.length > 0 &&
        //             formData.Purpose.trim().length > 0
        //             ? {
        //                 ...customerDataModel,
        //                 customerElements: [
        //                     {
        //                         name: 'Purpose',
        //                         OriginalValue: null,
        //                         UpdatedValue: formData.Purpose,
        //                     },
        //                 ],
        //             }
        //             : customerDataModel,
        // };

        let jsonObj = {
            userId: localStorage.getItem('userId'),
            workflowId: formData.WorkflowId,
            mdmCustomerId: formData.MdmNumber,
            WorkflowTitle: formData.WorkflowTitle,
            CustomerName: this.props.bapiFullSet.CustomerData.Name1,
            SystemRecordId: state.sysFieldsData.CustomerNumber,
            SystemTypeId: state.sysFieldsData.SystemTypeId,
            RoleTypeId: state.sysFieldsData.RoleTypeId,
            SalesOrgTypeId: state.sysFieldsData.SalesOrgTypeId,
            DivisionTypeId: state.sysFieldsData.DivisionTypeId,
            DistributionChannelTypeId: state.sysFieldsData.DistributionChannelTypeId,
            CompanyCodeTypeId: state.sysFieldsData.CompanyCodeTypeId,
            IsSaveToWorkflow: true,
            customerDataModel:
                formData.Purpose &&
                formData.Purpose.length > 0 &&
                formData.Purpose.trim().length > 0
                    ? {
                          ...customerDataModel,
                          customerElements: [
                              {
                                  name: 'Purpose',
                                  OriginalValue: null,
                                  UpdatedValue: formData.Purpose,
                              },
                          ],
                      }
                    : customerDataModel,
            WorkflowType: 45,
            DUNSData: {
                Country: formData.Country,
                Name1: formData.Name1,
                City: formData.City,
                Region: formData.Region,
                Street: formData.Street,
                DunsNumber: formData.DunsNumber,
                SicCode4: formData.SicCode4,
                SicCode6: formData.SicCode6,
                SicCode8: formData.SicCode8,
                TaxNumber: formData.TaxNumber4,
                VatRegNo: formData.VatRegNo,
                NaicsCode: formData.NaicsCode,
            },
        };

        let data = {};

        if (action === 'update') {
            //update functional
            let updObj = {
                SalesOrgTypeId: state.sysFieldsData.SalesOrgTypeId,
                WorkflowType: 21,
                DUNSData: this.state.dunsData,
            };
            data = { ...jsonObj, ...updObj };
        } else if (action === 'extend-salesorg') {
            var role = state.sysFieldsData.RoleTypeId;
            let wfType = 31;
            if (role === 1 || role === 2) {
                wfType = 35; //soldto,shipto
            } else if (role === 1) {
                wfType = 36; //dropship
            } else wfType = 31;
            let updObj = {
                SourceSalesOrgTypeId: state.sysFieldsData.SalesOrgTypeId,
                TargetSalesOrgTypeId: state.sysFieldsData.TargetSalesOrgTypeId,
                WorkflowType: wfType,
            };
            data = { ...jsonObj, ...updObj };
        }
        let postData = {
            data,
            action,
            files: selectedFilesIds.map((id) => selectedFiles[id]),
            history,
        };
        // console.log('postData....', postData);
        if (customerDataModel != null) this.props.updateDeltas(postData);
    };

    onSubmit = () => {
        let {
            formData,
            customer70Fields,
            updatedFormData,
            selectedFilesIds,
            selectedFiles,
        } = this.state;
        const { Category, ...data } = formData;
        let fileErrors = {};
        let errors = false;

        selectedFilesIds.map((id) => {
            if (selectedFiles[id] && selectedFiles[id].DocumentType <= 0) {
                fileErrors[id] = 'Document Type Required for file';
                errors = true;
            }
        });
        let castedFormData = mdmFieldsRules.cast(customer70Fields);

        this.setState({ fileErrors, isFileErrors: errors });

        this.setState(
            {
                formData: {
                    ...data,
                },
            },
            () => {
                yupAllFieldsValidation(
                    { ...castedFormData },
                    OlympusExtendFieldRules,
                    (...rest) => {
                        if (this.state.isFileErrors === false) {
                            this.proceedAction(...rest);
                        }
                    },
                    this.setFormErrors
                );
            }
        );
    };
    selectFile = (events) => {
        event.preventDefault();

        const { selectedFilesIds, selectedFiles } = this.state;
        const id = events.target.files[0].name;

        this.setState({
            selectedFiles: {
                ...selectedFiles,
                [id]: {
                    data: events.target.files[0],
                    DocumentName: events.target.files[0].name,
                    DocumentType: 0,
                },
            },
            selectedFilesIds: [...selectedFilesIds, id],
            filename: events.target.files[0].name,
            fileinputkey: Date.now(),
        });
    };

    removeFile = (id) => {
        event.preventDefault();

        var selectedFilesDict = this.state.selectedFiles;
        var selectedFilesIdsList = this.state.selectedFilesIds;
        // remove the existing state
        delete selectedFilesDict[id];
        selectedFilesIdsList = selectedFilesIdsList.filter((fileid) => fileid !== id);

        this.setState({
            selectedFiles: selectedFilesDict,
            selectedFilesIds: selectedFilesIdsList,
        });
    };

    isNumber = (n) => {
        return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
    };

    validateNumericKeypad = (value, e) => {
        const { name } = e.target;
        if (value.length !== 0 && !this.isNumber(value)) {
            this.setState({
                formErrors: {
                    [name]: 'Must be digits',
                },
            });
        } else {
            this.onFieldChange(value, e);
        }
    };

    createDunsObj = (data, name = null, val = null) => {
        if (name === null || val === null) {
            this.setState({
                dunsData: {
                    Country: data.Country,
                    Name1: data.Name1,
                    City: data.City,
                    Region: data.Region,
                    Street: data.Street,
                    DunsNumber: data.DunsNumber,
                    SicCode4: data.SicCode4,
                    SicCode6: data.SicCode6,
                    SicCode8: data.SicCode8,
                    TaxNumber: data.Taxnumber,
                    VatRegNo: data.VatRegNo,
                    NaicsCode: data.NaicsCode,
                    NaicsCodeDescription: data.NaicsCodeDescription,
                },
            });
        } else {
            this.setState({
                dunsData: {
                    ...this.state.dunsData,
                    [name]: val,
                },
            });
        }
    };

    onFieldChange = (value, e) => {
        const { name } = e.target;
        var target_team =
            e.target.getAttribute('team') ||
            (e.target &&
                e.target.selectedOptions &&
                e.target.selectedOptions[0].getAttribute('team')) ||
            null;
        var tgt_team = name === 'LastExtReview' ? 'credit' : target_team;
        value = name === 'LastExtReview' ? value.split('T')[0] : value;
        var formDataValue =
            this.isNumber(value) && name != 'CreditLimit' ? parseInt(value, 10) : value;
        let origdata = this.props.bapiFullSet.CustomerData;

        if (name === 'WorkflowTitle') {
            this.setState({
                formData: {
                    ...this.state.formData,
                    [name]: value,
                },
                customer70Fields: {
                    ...this.state.customer70Fields,
                    [name]: value,
                },
            });
        }
        if (name === 'Purpose') {
            this.setState({
                formData: {
                    ...this.state.formData,
                    [name]: value,
                },
                customer70Fields: {
                    ...this.state.customer70Fields,
                    [name]: value,
                },
            });
        }
        if (
            name === 'DunsNumber' ||
            name === 'SicCode4' ||
            name === 'SicCode6' ||
            name === 'SicCode8' ||
            name === 'VatRegNo' ||
            name === 'TaxNumber' ||
            name === 'NaicsCode' ||
            name === 'NaicsCodeDescription'
        ) {
            let origdata = this.props.bapiFullSet.CustomerData;

            let origdatavalue = origdata[name]
                ? origdata[name].length != 0
                    ? origdata[name]
                    : ''
                : '';
            let newValue = value != null ? (value.length != 0 ? value : '') : '';

            let newOne =
                origdatavalue.length === 0 && newValue.length === 0
                    ? origdata[name]
                    : value;
            this.createDunsObj(origdata, name, newOne);
        } else if (tgt_team != null) {
            // console.log(origdata);
            let origdatavalue =
                origdata && origdata[name]
                    ? origdata[name].length != 0
                        ? origdata[name]
                        : ''
                    : '';
            let newValue = value != null ? (value.length != 0 ? value : '') : '';

            let newOne =
                origdatavalue.length === 0 && newValue.length === 0
                    ? origdata[name]
                    : value;
            let teamsDelta = this.state[tgt_team] || [];
            let filterTeamDelta = [],
                newDeltaValue = {};
            if (origdatavalue === newOne) {
                //remove the delta obj from team if both orig , new data has null/empty data
                filterTeamDelta = teamsDelta.filter((delta) => delta.name != name);
            } else if (
                name.toLowerCase().includes('typeid') &&
                parseInt(origdatavalue) === parseInt(value)
            ) {
                //remove the delta obj from team if same dropdown selected again.
                filterTeamDelta = teamsDelta.filter((delta) => delta.name != name);
            } else {
                //else create the new delta obj and filter the existing delta obj with same key
                var finalvalue = value;
                if (name.toLowerCase().includes('typeid')) {
                    finalvalue = parseInt(value);
                }
                // console.log(origdata);
                newDeltaValue = {
                    name: name,
                    originalValue: origdata[name],
                    updatedValue: finalvalue,
                };
                filterTeamDelta = teamsDelta.filter((delta) => delta.name != name);
            }

            this.setState(
                (state) => {
                    let list = filterTeamDelta;
                    if (Object.keys(newDeltaValue).length != 0) {
                        list = [...filterTeamDelta, newDeltaValue];
                    }
                    return {
                        ...this.state,
                        [tgt_team]: list,
                        updatedFormData: {
                            ...this.state.updatedFormData,
                            [name]: value,
                        },
                        customer70Fields: {
                            ...this.state.customer70Fields,
                            [name]: value,
                        },
                        formData: {
                            ...this.state.formData,
                            [name]: formDataValue,
                        },
                        formErrors: {},
                    };
                },
                () => {
                    if (
                        name === 'CustomerClassTypeId' ||
                        name === 'IncoTerms1TypeId' ||
                        name === 'CustomerGroupTypeId'
                    ) {
                        this.validateRules(name, value);
                    }
                    // console.log(
                    //     'team',
                    //     this.state[tgt_team],
                    //     'up',
                    //     this.state.updatedFormData,
                    //     'fm',
                    //     this.state.formData
                    // );
                }
            );
        }
    };

    setFormErrors = (errors) => {
        const { formErrors } = this.state;
        console.log(errors);
        this.setState({ formErrors: errors, notifyBadData: true }, () =>
            window.scrollTo(0, 0)
        );
        setTimeout(
            function () {
                this.setState({ notifyBadData: false });
            }.bind(this),
            1000
        );
    };

    setFormDataValues = (name, value) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: value,
            },
            customer70Fields: {
                ...this.state.customer70Fields,
                [name]: value,
            },
        });
    };

    //this is called during validate rules and to set checkbox items
    setFunctionalTeamDeltaObject = (key = null, fieldValue = null, team = null) => {
        let value = fieldValue;
        //checkbox item fieldChange
        if (fieldValue === null) {
            //check if string is empty
            var currentBooleanValue =
                this.state.formData[key] != undefined && this.state.formData[key] === 'T';
            value = !currentBooleanValue;
        }
        let origdata = this.props.bapiFullSet;
        var formDataValue = this.isNumber(value) ? parseInt(value, 10) : value; //if the value is numeric type , convert string '2' to 2
        let newDeltaValue = {
            name: key,
            originalValue: origdata[key],
            updatedValue: value,
        };

        if (key === 'OrderCombination' || key === 'PaymentHistoryRecord') {
            newDeltaValue.originalValue = origdata.CustomerData[key];

            if (newDeltaValue.originalValue === newDeltaValue.updatedValue) {
                newDeltaValue = null;
            }
        }

        let keyValDict = {};

        if (key === 'IncoTerms2') {
            let inco1Val = this.state.formData['IncoTerms1TypeId'];
            if (inco1Val === 1)
                keyValDict = { isRequiredINCOT2: true, isReadonlyINCOT2: false };
            else if (
                inco1Val === 3 ||
                inco1Val === 4 ||
                inco1Val === 5 ||
                inco1Val === 7 ||
                inco1Val === 8
            )
                keyValDict = { isRequiredINCOT2: false, isReadonlyINCOT2: true };
            else keyValDict = { isRequiredINCOT2: false, isReadonlyINCOT2: false };
        }

        let teamsDelta = this.state[team] || [];
        let filterTeamDelta = teamsDelta.filter((delta) => delta.name != key);
        this.setState(
            (state) => {
                const list = newDeltaValue
                    ? [...filterTeamDelta, newDeltaValue]
                    : [...filterTeamDelta];
                return {
                    ...this.state,
                    updatedFormData: {
                        ...this.state.updatedFormData,
                        ...keyValDict,
                        [key]: value,
                    },
                    [team]: list,
                    formData: {
                        ...this.state.formData,
                        ...keyValDict,
                        [key]: formDataValue,
                    },
                    customer70Fields: {
                        ...this.state.customer70Fields,
                        ...keyValDict,
                        [key]: value,
                    },
                };
            }
            // () => console.log('statess', this.state)
        );
    };
    setInputPropsForDefaultRules = (field_name, property) => {
        this.setState({
            inputPropsForDefaultRules: {
                ...this.state.inputPropsForDefaultRules,
                [field_name]: property,
            },
        });
    };

    // display or set input/dropdowns values based on rules
    validateRules = (stateKey, stateVal) => {
        const readOnlyInputprop = { inline: true, variant: 'outline' };
        const editInputProp = {
            inline: false,
            variant: 'solid',
            onChange: this.onFieldChange,
        };
        const readOnlyDropDown = { disabled: true };
        let showContracts = this.state.isContractEnabled;

        // check for CustomerPriceProcTypeId
        if (stateKey === 'CustomerClassTypeId') {
            var CC_val = stateVal;
            if (['1', '2', '3', '4', '5'].includes(CC_val)) {
                this.setFunctionalTeamDeltaObject(
                    'CustomerPriceProcTypeId',
                    '2',
                    'customermaster'
                );
                this.setInputPropsForDefaultRules(
                    'CustomerPriceProcTypeId',
                    readOnlyDropDown
                );
            } else {
                this.setFunctionalTeamDeltaObject(
                    'CustomerPriceProcTypeId',
                    '',
                    'customermaster'
                );
                this.setInputPropsForDefaultRules('CustomerPriceProcTypeId', {
                    disabled: false,
                });
            }
        }
        // check for incoterms2

        if (stateKey === 'IncoTerms1TypeId') {
            var team = showContracts ? 'contracts' : 'customermaster';
            var INCOT1_val = stateVal;
            if (INCOT1_val === '3') {
                this.setFunctionalTeamDeltaObject('IncoTerms2', 'Carriage Paid To', team);
            } else if (INCOT1_val === '4') {
                this.setFunctionalTeamDeltaObject(
                    'IncoTerms2',
                    'Delivered at Location',
                    team
                );
            } else if (INCOT1_val === '5') {
                this.setFunctionalTeamDeltaObject(
                    'IncoTerms2',
                    'Delivered Duty Paid',
                    team
                );
            } else if (INCOT1_val === '7') {
                this.setFunctionalTeamDeltaObject('IncoTerms2', 'Ex Works', team);
            } else if (INCOT1_val === '8') {
                this.setFunctionalTeamDeltaObject('IncoTerms2', 'Free Carrier', team);
            } else {
                this.setFunctionalTeamDeltaObject('IncoTerms2', '', team);
            }
        }

        // check for AccountTypeId
        if (stateKey === 'CustomerGroupTypeId') {
            var team = showContracts ? 'contracts' : 'customermaster';
            var cg_val = stateVal;
            const readOnlyDropDown = { disabled: true };
            if (cg_val === '1' || cg_val === '10') {
                this.setFunctionalTeamDeltaObject('AccountTypeId', '1', team);
                this.setInputPropsForDefaultRules('AccountTypeId', readOnlyDropDown);
            } else if (cg_val === '2' || cg_val === '7') {
                this.setFunctionalTeamDeltaObject('AccountTypeId', '2', team);
                this.setInputPropsForDefaultRules('AccountTypeId', readOnlyDropDown);
            } else if (
                cg_val === '3' ||
                cg_val === '4' ||
                cg_val === '6' ||
                cg_val === '11'
            ) {
                this.setFunctionalTeamDeltaObject('AccountTypeId', '3', team);
                this.setInputPropsForDefaultRules('AccountTypeId', readOnlyDropDown);
            } else if (cg_val === '8') {
                this.setFunctionalTeamDeltaObject('AccountTypeId', '6', team);
                this.setInputPropsForDefaultRules('AccountTypeId', readOnlyDropDown);
            } else {
                this.setFunctionalTeamDeltaObject('AccountTypeId', '0', team);
                this.setInputPropsForDefaultRules('AccountTypeId', {
                    disabled: false,
                });
            }
        }
    };

    _renderModalContent = () => {
        const { formData } = this.state;
        return (
            <Box
                my={4}
                mx="30%"
                backgroundColor="white"
                borderRadius="4px"
                style={{ padding: '3%' }}
                borderColor="rgba(0, 0, 0, 0.1)">
                <Text my={3} fontWeight="regular" color="lightBlue" fontSize={24} pl={4}>
                    Submit Workflow
                </Text>
                <Box px="0px" pb="22px" justifyContent="center">
                    <Box alignItems="center" justifyContent="center">
                        {!this.state.isReject ? (
                            <FormInput
                                label="Additional Notes"
                                multiline
                                numberOfLines={2}
                                maxLength={250}
                                name="AdditionalNotes"
                                type="text"
                                onChange={this.onFieldChange}
                                value={
                                    this.state.formData &&
                                    this.state.formData['AdditionalNotes']
                                }
                                variant={'solid'}
                            />
                        ) : (
                            <FormInput
                                label="Rejection Reason"
                                name="RejectionReason"
                                maxLength={250}
                                onChange={this.onFieldChange}
                                error={'*this field is required'}
                                multiline
                                numberOfLines={2}
                                type="text"
                                value={
                                    this.state.formData &&
                                    this.state.formData['RejectionReason']
                                }
                                variant="solid"
                            />
                        )}
                    </Box>
                    <Flex
                        justifyEnd
                        alignCenter
                        alignSelf="flex-end"
                        style={{
                            paddingTop: 15,
                            flexDirection: 'row',
                            alignItems: 'center',
                            paddingLeft: 10,
                            paddingRight: 15,
                            marginTop: 20,
                            marginBottom: 10,
                            marginHorizontal: 25,
                        }}>
                        <Button
                            onPress={(event) => {
                                if (this.state.isReject)
                                    this.setState({ isReject: false }, () =>
                                        this.onSubmit(event, true, rejectRules)
                                    );
                                else this.onSubmit();
                            }}
                            title="Submit"
                        />
                        <Button
                            title="Cancel"
                            onPress={() => this.setState({ modalVisible: null })}
                        />
                    </Flex>
                </Box>
            </Box>
        );
    };

    renderOlympusFields = (isPointman, globalMdmDetail, staticData, allowedFields) => {
        const { formData } = this.state;
        const {
            bapiFullSet: {
                CustomerData: globalDetail = {},
                Deltas: erpDeltas = [],
                TaskNoteData: tasknotes = [],
                RequestorName,
            },
        } = this.props;
        let Deltas = [];
        Deltas = normalize(erpDeltas || []);
        return (
            <>
                <FormInput
                    hide={isPointman}
                    variant="outline"
                    label="Distribution Channel"
                    name="DistributionChannelTypeId"
                    hide={allowedFields && allowedFields.includes('DistributionChannel')}
                    value={
                        staticData &&
                        globalMdmDetail &&
                        this.getDropdownValues(
                            globalMdmDetail.DistributionChannelTypeId,
                            staticData && staticData.OlympusDistributionChannelType
                        )
                    }
                    isRequired
                    formErrors={
                        this.state.formErrors
                            ? this.state.formErrors['DistributionChannelTypeId']
                            : null
                    }
                    inline={true}
                    team="system"
                />

                <FormInput
                    label="Division"
                    name="DivisionTypeId"
                    hide={allowedFields && allowedFields.includes('Division')}
                    variant="outline"
                    type="text"
                    value={
                        staticData &&
                        globalMdmDetail &&
                        this.getDropdownValues(
                            globalMdmDetail.DivisionTypeId,
                            staticData.OlympusDivisionType
                        )
                    }
                    inline={true}
                    team="system"
                />

                <FormInput
                    variant="outline"
                    hide={
                        isPointman &&
                        allowedFields &&
                        allowedFields.includes('CompanyCode')
                    }
                    label="Company Code"
                    name="CompanyCodeTypeId"
                    inputProps={this.state.inputPropsForDefaultRules['CompanyCodeTypeId']}
                    value={
                        staticData &&
                        globalMdmDetail &&
                        this.getDropdownValues(
                            globalMdmDetail.CompanyCodeTypeId,
                            staticData && staticData.OlympusCompanyCodeType
                        )
                    }
                    isRequired
                    formErrors={
                        this.state.formErrors
                            ? this.state.formErrors['CompanyCodeTypeId']
                            : null
                    }
                    onFieldChange={this.onFieldChange}
                    inline={true}
                    team="system"
                />
                <FormInput
                    variant="outline"
                    label="Sold To"
                    name="SoldTo"
                    error={this.state.formErrors ? this.state.formErrors['SoldTo'] : null}
                    hide={
                        globalMdmDetail &&
                        globalMdmDetail.RoleTypeId != 2 &&
                        globalMdmDetail.RoleTypeId != 3 &&
                        globalMdmDetail.RoleTypeId != 4 &&
                        allowedFields &&
                        allowedFields.includes('SoldTo')
                    }
                    value={globalMdmDetail && globalMdmDetail['SoldTo']}
                    onChange={(value, element) => {
                        this.onFieldChange(value, element);
                    }}
                    inline={true}
                    team="system"
                />
                <FormInput
                    variant="outline"
                    value={
                        staticData &&
                        globalMdmDetail &&
                        this.getDropdownValues(
                            globalMdmDetail.LanguageTypeId,
                            staticData && staticData.OlympusLanguageType
                        )
                    }
                    hide={allowedFields && allowedFields.includes('Language')}
                    label="Language"
                    name="Language"
                    isRequired
                    formErrors={
                        this.state.formErrors ? this.state.formErrors['Language'] : null
                    }
                    onFieldChange={this.onFieldChange}
                    inline={true}
                    team="system"
                />

                <FormInput
                    variant="outline"
                    label="Customer Class"
                    name="CustomerClass"
                    value={
                        staticData &&
                        globalMdmDetail &&
                        this.getDropdownValues(
                            globalMdmDetail.CustomerClassTypeId,
                            staticData && staticData.OlympusCustomerClassType
                        )
                    }
                    formErrors={
                        this.state.formErrors
                            ? this.state.formErrors['CustomerClass']
                            : null
                    }
                    onFieldChange={this.onFieldChange}
                    inline={true}
                    team="system"
                />

                <FormInput
                    variant="outline"
                    label="Recon Account"
                    name="ReconAccount"
                    hide={allowedFields && allowedFields.includes('ReconAccount')}
                    value={
                        staticData &&
                        globalMdmDetail &&
                        this.getDropdownValues(
                            globalMdmDetail.ReconAccountTypeId,
                            staticData && staticData.OlympusReconAccountType
                        )
                    }
                    isRequired
                    formErrors={
                        this.state.formErrors
                            ? this.state.formErrors['ReconAccount']
                            : null
                    }
                    onFieldChange={this.onFieldChange}
                    inline={true}
                    team="system"
                />
                {Deltas && Deltas['TermsOfPaymentTypeId'] ? (
                    <DeltaField
                        delta={Deltas['TermsOfPaymentTypeId']}
                        arrayOfData={staticData && staticData.OlympusTermsOfPaymentType}
                    />
                ) : (
                    <FormInput
                        variant="outline"
                        label="Terms of Payment"
                        name="TermsOfPayment"
                        hide={allowedFields && allowedFields.includes('TermsOfPayment')}
                        style={{ whiteSpace: 'pre-wrap' }}
                        multiline={true}
                        value={
                            staticData &&
                            globalMdmDetail &&
                            this.getDropdownValues(
                                globalMdmDetail.TermsOfPaymentTypeId,
                                staticData && staticData.OlympusTermsOfPaymentType
                            )
                        }
                        isRequired
                        formErrors={
                            this.state.formErrors
                                ? this.state.formErrors['TermsOfPayment']
                                : null
                        }
                        onFieldChange={this.onFieldChange}
                        inline={true}
                        team="system"
                    />
                )}
                <FormInput
                    variant="outline"
                    label="Currency"
                    name="Currency"
                    hide={allowedFields && allowedFields.includes('Currency')}
                    value={
                        staticData &&
                        globalMdmDetail &&
                        this.getDropdownValues(
                            globalMdmDetail.CurrencyTypeId,
                            staticData && staticData.OlympusCurrencyType
                        )
                    }
                    formErrors={
                        this.state.formErrors ? this.state.formErrors['Currency'] : null
                    }
                    onFieldChange={this.onFieldChange}
                    inline={true}
                    team="system"
                />
                <FormInput
                    variant="outline"
                    label="Primary GPO"
                    name="PrimaryGpo"
                    hide={allowedFields && allowedFields.includes('PrimaryGpo')}
                    error={
                        this.state.formErrors ? this.state.formErrors['PrimaryGpo'] : null
                    }
                    value={globalMdmDetail && globalMdmDetail['PrimaryGpo']}
                    style={{ height: 'inherit' }}
                    onChange={(value, element) => {
                        this.onFieldChange(value, element);
                    }}
                    inline={true}
                    team="system"
                />
                <FormInput
                    variant="outline"
                    label="Requested Credit Limit"
                    name="RequestedCreditLimit"
                    hide={allowedFields && allowedFields.includes('RequestedCreditLimit')}
                    error={
                        this.state.formErrors
                            ? this.state.formErrors['RequestedCreditLimit']
                            : null
                    }
                    value={globalMdmDetail && globalMdmDetail['CreditLimit']}
                    style={{ height: 'inherit' }}
                    onChange={(value, element) => {
                        this.onFieldChange(value, element);
                    }}
                    inline={true}
                    team="system"
                />

                <FormInput
                    variant="outline"
                    label="Priority"
                    name="Priority"
                    hide={allowedFields && allowedFields.includes('Priority')}
                    value={
                        staticData &&
                        globalMdmDetail &&
                        this.getDropdownValues(
                            globalMdmDetail.PriorityTypeId,
                            staticData && staticData.OlympusPriorityType
                        )
                    }
                    formErrors={
                        this.state.formErrors ? this.state.formErrors['Priority'] : null
                    }
                    onFieldChange={this.onFieldChange}
                    inline={true}
                    team="system"
                />
                <FormInput
                    variant="outline"
                    label="Effective Date"
                    hide={allowedFields && allowedFields.includes('EffectiveDate')}
                    min={moment().format('YYYY-MM-DD')}
                    name="EffectiveDate"
                    error={
                        this.state.formErrors
                            ? this.state.formErrors['EffectiveDate']
                            : null
                    }
                    value={
                        globalMdmDetail &&
                        moment(globalMdmDetail['EffectiveDate']).format('YYYY-MM-DD')
                    }
                    type="date"
                    style={{ height: 'inherit' }}
                    onChange={(value, element) => {
                        this.onFieldChange(
                            new Date(value).toJSON().slice(0, 19),
                            element
                        );
                    }}
                    inline={true}
                    team="system"
                />
                <FormInput
                    label="Purpose of Request"
                    name="Purpose"
                    hide={allowedFields && allowedFields.includes('Purpose')}
                    multiline
                    value={globalMdmDetail && globalMdmDetail.Purpose}
                    numberOfLines={
                        globalMdmDetail.Purpose && globalMdmDetail.Purpose.length > 36
                            ? 3
                            : 1
                    }
                    style={{ lineHeight: '2.5' }}
                    inline
                    maxLength={1000}
                    variant="outline"
                    type="text"
                    team="system"
                />
            </>
        );
    };

    isNumber = (n) => {
        return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
    };

    // validateNumericKeypad = (value, e) => {
    //     const { name } = e.target;
    //     if (value) {
    //         if (!this.isNumber(value) || parseInt(value) < 0 || parseInt(value) > 100) {
    //             this.setState({
    //                 formErrors: {
    //                     [name]:
    //                         value >= 0 || value <= 100
    //                             ? 'Value should be greater 0 and less than 100'
    //                             : 'Must be digits',
    //                 },
    //             });
    //         } else {
    //             this.onFieldChange(value, e);
    //         }
    //     } else {
    //         this.onFieldChange(value, e);
    //     }
    // };

    deliveryPlantMapping = (data) => {
        let systemFields = this.props.bapiFullSet.CustomerData;
        let dropdownData = [];
        if (data) {
            data.forEach((dropdownList) => {
                if (dropdownList.ValidationData.length > 0) {
                    dropdownList.ValidationData.forEach((datatofilter) => {
                        if (
                            datatofilter &&
                            systemFields &&
                            datatofilter.SalesOrgTypeId === systemFields.SalesOrgTypeId &&
                            systemFields.DistributionChannelTypeId ===
                                datatofilter.DistributionChannelTypeId
                        ) {
                            dropdownData.push(dropdownList);
                        }
                    });
                }
            });
        }
        return dropdownData;
    };

    getDropdownValues = (Id, dropdownList) => {
        let description = '';
        if (Id && dropdownList)
            dropdownList.some((data) => {
                if (data.Id === Id) {
                    description = data.Value + ' : ' + data.Description;
                    return true;
                }
            });
        // if (description.includes('/n') || description.includes(';')) {
        //     console.log('html string');
        // }
        return description;
    };

    getDropdownCreditControlValues = (key, data, stateKey) => {
        let dropdownData = [];
        const self = this;
        const {
            bapiFullSet: { DocumentLocation: files, CustomerData: globalMdmDetail = {} },
        } = this.props;
        // console.log(globalMdmDetail,"Global MDM Detail")
        if (data) {
            dropdownData = [];
            data.forEach((dropdownList) => {
                if (dropdownList.ValidationData.length > 0) {
                    dropdownList.ValidationData.forEach((datatofilter) => {
                        if (globalMdmDetail && globalMdmDetail.SalesOrgTypeId) {
                            if (
                                datatofilter[key] ===
                                parseInt(globalMdmDetail.SalesOrgTypeId)
                            ) {
                                dropdownData.push(dropdownList);
                            }
                        }
                    });
                }
            });
        }
        var check = new Set();
        let filteredArr = dropdownData.filter(
            (obj) => !check.has(obj['Id']) && check.add(obj['Id'])
        );
        // console.log(filteredArr,"==============")
        return filteredArr;
    };

    render() {
        const { width, location, alert } = this.props;

        const {
            dropDownDatas,
            roleFieldMapping,
            inputPropsForDefaultRules,
            selectedFilesIds,
            selectedFiles,
            isRequestPage,
            currentPage,
            extendSalesOrgEnabled,
        } = this.state;
        let { state: workflow } = this.props.location;
        const {
            bapiFullSet: {
                CustomerData: globalDetail = {},
                Deltas: erpDeltas = [],
                TaskNoteData: tasknotes = [],
                RequestorName,
            },
            statusBarData,
            staticData,
            functionalGroupDetails: {
                // DocumentLocation: files,
                // Customer: globalMdmDetail = {},
                CustomerMaster: customerMaster = null,
                Credit: creditDetail = null,
                Contracts: functionalDetail = null,
                Pricing: pricingDetail = null,
                TaskNote: Notes = '',
            },
        } = this.props;
        const requestDateUtc = moment('22-01-2020');
        const localDate = moment(requestDateUtc).local();
        const requestDate = localDate.format('YYYY-MM-DD hh:mm:ss A');
        const { state } = location;
        const { action } = this.props.match.params;
        var requestor = RequestorName;
        var signedUser = formatRequestorName(localStorage.getItem('userId'));
        var IsWithdrawAllowed =
            signedUser === requestor ? this.props.bapiFullSet.IsWithdrawAllowed : false;
        let teams = JSON.parse(localStorage.getItem('teamIds'));
        // Hide options for Search team users of CM system / AD groups
        let isSearchTeamUser =
            (teams && teams.includes(20) && teams.length == 1) ||
            (teams && teams.length == 0) ||
            (teams && teams.includes(99));
        let withdrawToggle = IsWithdrawAllowed && !isSearchTeamUser;

        let workflowtype = 'Update';
        let Deltas = [],
            MdmNumber = '',
            SystemRecordId = '',
            globalMdmDetail,
            workFlowTaskStatus = [],
            showContracts = false,
            showInlineAstreik = true;

        if (currentPage === 'update-request') {
            //if on my-requests page
            showInlineAstreik = false;
            let CategoryTypeId = this.props.bapiFullSet.CategoryTypeId;
            globalMdmDetail = {
                ...globalDetail,
                // ...this.props.dunsFields,
                CategoryTypeId,
            };
            Deltas = normalize(erpDeltas || []);
            MdmNumber = this.props.bapiFullSet.MdmNumber;
            workFlowTaskStatus = this.state.statusBarData || [];
            workFlowTaskStatus = workFlowTaskStatus.filter(function (item) {
                return item.WorkflowTaskStateTypeId != 4;
            });
            var stateWFType = state.Type || state.WorkflowType;
            workflowtype = stateWFType.toLowerCase().includes('update')
                ? 'Update'
                : stateWFType.toLowerCase().includes('extend')
                ? 'Extend to New Sales Org'
                : 'Block/UnBlock';
            let categoryTypeid = parseInt(this.props.bapiFullSet.CategoryTypeId);
            //check if Category = Distributor, OEM, Kitter, Self-Distributor then Contract else Customermaster
            if (
                categoryTypeid === 1 ||
                categoryTypeid === 2 ||
                categoryTypeid === 3 ||
                categoryTypeid === 6
            )
                // showContractsForReqDetails = true;
                showContracts = true;
        } else {
            if (action === 'extend-salesorg') {
                showInlineAstreik = false;
            }
            //for update and extend sales org
            let CategoryTypeId =
                this.props.bapiFullSet.CategoryTypeId || state.CategoryTypeId;
            globalMdmDetail = {
                ...globalDetail,
                ...state.sysFieldsData,
                // ...this.props.dunsFields,
                CategoryTypeId,
            };
            MdmNumber = state.MdmNumber;
            let categoryTypeid = parseInt(state.CategoryTypeId);
            //check if Category = Distributor, OEM, Kitter, Self-Distributor then Contract else Customermaster
            if (
                categoryTypeid === 1 ||
                categoryTypeid === 2 ||
                categoryTypeid === 3 ||
                categoryTypeid === 6
            )
                // showContractsForReqDetails = true;
                showContracts = true;
        }

        const RoleFields =
            idx(roleFieldMapping, (_) => _.OlympusUpdateExtendFields) || [];
        const RoleTypeId = globalMdmDetail.RoleTypeId;
        const SystemTypeId = globalMdmDetail.SystemTypeId || globalMdmDetail.SystemType;
        const allowedFieldList = RoleFields.filter(
            (item) => item.RoleTypeId == RoleTypeId && item.SystemTypeId == SystemTypeId
        );
        let allowedFields = undefined;

        if (allowedFieldList && allowedFieldList.length > 0) {
            allowedFields = allowedFieldList[0].Fields;
        }

        SystemRecordId = this.props.bapiFullSet.SystemRecordId;
        const { WorkflowDocuments: files = [] } = this.props.bapiFullSet;
        // console.log('glob', globalMdmDetail, 'stateobj', this.state, 'props', this.props);
        console.log(allowedFields);
        const pageProps = this.state.readOnly
            ? {
                  inline: true,
                  variant: 'outline',
                  readOnly: true,
              }
            : {
                  inline: false,
                  readOnly: false,
                  onBlur: this.props.onFieldChange,
              };
        let nonReadOnlyForExtendSalesOrg = extendSalesOrgEnabled ? false : pageProps; // read only false when extend sales org for tax clasification
        let disp_payterms = false;
        if (this.state && this.state.formData.Category != undefined) {
            var source_category = this.state.formData.Category.toLowerCase();
            if (
                source_category === 'direct' ||
                source_category === 'dropship' ||
                source_category === 'other'
            ) {
                disp_payterms = true;
            }
        }
        var bgcolor = alert.color || '#FFF';
        const isPointman = globalMdmDetail && globalMdmDetail.SystemTypeId === 3;
        const showFunctionalDetail =
            state && //remove after dev
            state.isReadOnly &&
            customerMaster === null
                ? { display: 'none' }
                : null;
        const inputReadonlyProps = workflow.isReadOnly ? { disabled: true } : null;
        // console.log(workflow, Deltas);
        let showCreditDetail =
            state && state.isReadOnly && creditDetail === null
                ? {
                      display:
                          // '',
                          'none',
                  }
                : null;
        const isWorkFlowReadOnly = workflow.isReadOnly;
        const enableDisplay = isWorkFlowReadOnly ? { display: 'none' } : null;
        if (this.props.fetching)
            return (
                <Box
                    display="flex"
                    flex={1}
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                    minHeight="650px"
                    bg="#eff3f6">
                    <FlashMessages
                        toasts={this.props.toasts}
                        onDismiss={this.props.removeMessage}
                    />
                    <View>
                        <ActivityIndicator size="large" />
                    </View>
                </Box>
            );

        const _role = RoleType[globalMdmDetail && globalMdmDetail.RoleTypeId] || '';
        // console.log(this.state, this.props, staticData, '===========', globalMdmDetail);
        return (
            <ScrollView
                keyboardShouldPersistTaps="always"
                style={{
                    backgroundColor: '#EFF3F6',
                    paddingBottom: 75,
                }}>
                <Modal isVisible={this.state.modalVisible} style={{ overflow: 'auto' }}>
                    {this._renderModalContent()}
                </Modal>
                <FlashMessages
                    toasts={this.props.toasts}
                    onDismiss={this.props.removeMessage}
                />
                {alert.display && (
                    <FlashMessage
                        bg={{ backgroundColor: bgcolor }}
                        message={alert.message}
                    />
                )}

                <View
                    style={{
                        flex: 1,
                        paddingHorizontal: width < 1440 ? 60 : width * 0.1,
                        paddingBottom: 10,
                    }}>
                    <Box fullHeight my={2}>
                        <Box flexDirection="row" justifyContent="space-around" my={4}>
                            <FormInput
                                padding="8px 25px 0px 25px"
                                style={{ lineHeight: '2', paddingBottom: 0 }}
                                flex={1 / 4}
                                required
                                onChange={this.onFieldChange}
                                error={
                                    this.state.formErrors
                                        ? this.state.formErrors['WorkflowTitle']
                                        : null
                                }
                                value={
                                    isRequestPage
                                        ? this.props.bapiFullSet.WorkflowTitle
                                        : this.state.formData.WorkflowTitle || ''
                                }
                                label="Workflow Title"
                                name="WorkflowTitle"
                                maxLength={40}
                                inline={false}
                                variant={nonReadOnlyForExtendSalesOrg.variant}
                                readOnly={nonReadOnlyForExtendSalesOrg.readOnly}
                            />
                            {/* <FormInput
                                px="25px"
                                flex={1 / 4}
                                label="WorkflowTitle"
                                maxLength={40}
                                name="WorkflowTitle"
                                type="text"
                                value={globalMdmDetail && globalMdmDetail.WorkflowTitle}
                                on
                            /> */}
                            <FormInput
                                px="25px"
                                flex={1 / 4}
                                label="Workflow Number"
                                name="workflow-number"
                                variant="outline"
                                type="text"
                                value={
                                    !isRequestPage
                                        ? this.state.formData.WorkflowId
                                        : this.props.match.params.id
                                }
                            />

                            {!this.isEmpty(SystemRecordId) && (
                                <FormInput
                                    px="25px"
                                    flex={1 / 4}
                                    label="Customer Number"
                                    name="Customer Number"
                                    style={{ lineHeight: '2' }}
                                    variant="outline"
                                    type="text"
                                    value={SystemRecordId || ''}
                                />
                            )}

                            {!this.isEmpty(MdmNumber) && (
                                <TouchableOpacity
                                    onPress={() =>
                                        this.props.history.push(
                                            `/search-results/${MdmNumber}`
                                        )
                                    }>
                                    <FormInput
                                        px="25px"
                                        flex={1 / 4}
                                        label="MDM Number"
                                        name="MdmNumber"
                                        style={{ lineHeight: '2' }}
                                        variant="outline"
                                        type="text"
                                        value={MdmNumber || ''}
                                    />
                                </TouchableOpacity>
                            )}

                            {globalMdmDetail && globalMdmDetail.SystemRecordId && (
                                <FormInput
                                    px="25px"
                                    flex={1 / 4}
                                    label="Customer Number"
                                    name="CustomerNumber"
                                    variant="outline"
                                    type="text"
                                    value={globalMdmDetail.SystemRecordId}
                                />
                            )}
                            {globalMdmDetail &&
                                globalMdmDetail.MdmCustomerId &&
                                (isReleased ? (
                                    <TouchableOpacity
                                        onPress={() =>
                                            this.props.history.push(
                                                `/search-results/${globalMdmDetail.MdmCustomerId}`
                                            )
                                        }>
                                        <FormInput
                                            px="25px"
                                            flex={1 / 4}
                                            label="MDM Number"
                                            name="mdm-number"
                                            variant="outline"
                                            type="text"
                                            value={
                                                globalMdmDetail &&
                                                globalMdmDetail.MdmCustomerId
                                            }
                                        />
                                    </TouchableOpacity>
                                ) : (
                                    <FormInput
                                        px="25px"
                                        flex={1 / 4}
                                        label="MDM Number"
                                        name="mdm-number"
                                        variant="outline"
                                        type="text"
                                        value={
                                            globalMdmDetail &&
                                            globalMdmDetail.MdmCustomerId
                                        }
                                    />
                                ))}
                        </Box>

                        {!this.state.isInternationalVersionVisible && (
                            <GlobalMdmFields
                                showMarketingSegmentation={!workflow.isReadOnly}
                                formData={{
                                    ...this.state.formData,
                                    DunsNumber:
                                        // '11',
                                        this.state.formData['DunsNumber'] ||
                                        globalMdmDetail.DunsNumber,
                                    MarketingSegmentationTypeId:
                                        idx(
                                            this.state,
                                            (_) => _.formData.MarketingSegmentationTypeId
                                        ) ||
                                        idx(
                                            customerMaster,
                                            (_) => _.MarketingSegmentationTypeId
                                        ) ||
                                        idx(
                                            globalMdmDetail,
                                            (_) => _.MarketingSegmentationTypeId
                                        ),
                                }}
                                // dunsEditable={!workflow.isReadOnly}
                                editableInputs={{ TaxNumber: false }}
                                onDunsNewInput={this.onDunsNewInput}
                                formErrors={this.state.formErrors}
                                onFieldChange={this.onFieldChange}
                                staticDropDownData={this.props.staticData}
                                viewInternationalModal={() => {
                                    this.setState({
                                        isInternationalVersionVisible: true,
                                    });
                                }}
                                isOlympus={true}
                                readOnly
                            />
                        )}

                        <Text
                            mt="42px"
                            mb="20px"
                            fontWeight="regular"
                            color="lightBlue"
                            fontSize={24}
                            pl={4}>
                            SYSTEM FIELDS
                        </Text>
                        <Box flexDirection="row" justifyContent="center">
                            <Box width={1 / 2} mx="auto" alignItems="center">
                                <FormInput
                                    label="System"
                                    name="System"
                                    inline={true}
                                    variant="outline"
                                    type="text"
                                    team="system"
                                    hide={
                                        allowedFields && allowedFields.includes('System')
                                    }
                                    value={
                                        staticData &&
                                        this.getDropdownValues(
                                            globalMdmDetail.SystemType,
                                            this.props.staticData.SystemType
                                        )
                                    }
                                />
                                <FormInput
                                    label="Role"
                                    name="Role"
                                    hide={allowedFields && allowedFields.includes('Role')}
                                    inline={true}
                                    variant="outline"
                                    type="text"
                                    team="system"
                                    value={
                                        staticData &&
                                        staticData &&
                                        this.getDropdownValues(
                                            globalMdmDetail.RoleTypeId,
                                            staticData &&
                                                this.props.staticData.OlympusRoleType
                                        )
                                    }
                                />
                                <FormInput
                                    label="Sales Org"
                                    name="SalesOrg"
                                    hide={
                                        allowedFields &&
                                        allowedFields.includes('SalesOrg')
                                    }
                                    inline={true}
                                    variant="outline"
                                    type="text"
                                    team="system"
                                    hide={isPointman}
                                    value={
                                        staticData &&
                                        globalMdmDetail &&
                                        this.getDropdownValues(
                                            globalMdmDetail.SalesOrgTypeId,
                                            staticData && staticData.OlympusSalesOrgType
                                        )
                                    }
                                />
                                {Deltas && Deltas['URIServiceCode'] ? (
                                    <DeltaField delta={Deltas['URIServiceCode']} />
                                ) : (
                                    <FormInput
                                        label="URI / Service Code"
                                        name="ServiceCode"
                                        // variant="outline"
                                        hide={
                                            allowedFields &&
                                            allowedFields.includes('ServiceCode')
                                        }
                                        error={
                                            this.state.formErrors
                                                ? this.state.formErrors['ServiceCode']
                                                : null
                                        }
                                        value={
                                            this.state.formData['ServiceCode'] ||
                                            (globalMdmDetail &&
                                                globalMdmDetail['UriServiceCode'])
                                        }
                                        onChange={(value, element) => {
                                            this.onFieldChange(value, element);
                                        }}
                                        team="system"
                                        inline={true}
                                        {...pageProps}
                                    />
                                )}
                                {Deltas && Deltas['UriTypeId'] ? (
                                    <DeltaField delta={Deltas['UriTypeId']} />
                                ) : (
                                    <DynamicSelect
                                        arrayOfData={
                                            this.props.staticData &&
                                            this.props.staticData.OlympusURIType
                                        }
                                        label="URI Type"
                                        name="UriTypeId"
                                        // inputProps={isSalesForce}
                                        value={this.state.formData.UriTypeId}
                                        isRequired={this.state.formData['ServiceCode']}
                                        formErrors={
                                            this.state.formErrors
                                                ? this.state.formErrors['UriTypeId']
                                                : null
                                        }
                                        onFieldChange={this.onFieldChange}
                                        hide={
                                            allowedFields &&
                                            allowedFields.includes('URIType')
                                        }
                                        outline="variant"
                                        {...pageProps}
                                    />
                                )}
                                <FormInput
                                    variant="outline"
                                    label="Delivery Information"
                                    name="DeliveryInformation"
                                    inline={true}
                                    hide={
                                        allowedFields &&
                                        allowedFields.includes('DeliveryInformation')
                                    }
                                    error={
                                        this.state.formErrors
                                            ? this.state.formErrors['DeliveryInformation']
                                            : null
                                    }
                                    value={
                                        globalMdmDetail &&
                                        globalMdmDetail['UnloadingPoints']
                                    }
                                    style={{ height: 'inherit' }}
                                    onChange={(value, element) => {
                                        this.onFieldChange(value, element);
                                    }}
                                    team="system"
                                />
                                <FormInput
                                    variant="outline"
                                    label="Sales District"
                                    name="SalesDistrict"
                                    hide={
                                        allowedFields &&
                                        allowedFields.includes('SalesDistrict')
                                    }
                                    value={
                                        staticData &&
                                        globalMdmDetail &&
                                        this.getDropdownValues(
                                            globalMdmDetail.SalesDistrictTypeId,
                                            staticData &&
                                                staticData.OlympusSalesDistrictType
                                        )
                                    }
                                    isRequired={this.state.formData['Country'] === 'DE'}
                                    formErrors={
                                        this.state.formErrors
                                            ? this.state.formErrors['SalesDistrict']
                                            : null
                                    }
                                    onFieldChange={this.onFieldChange}
                                    inline={true}
                                    team="system"
                                />
                                <FormInput
                                    variant="outline"
                                    label="Sales Office"
                                    name="SalesOffice"
                                    hide={
                                        allowedFields &&
                                        allowedFields.includes('SalesOffice')
                                    }
                                    value={
                                        staticData &&
                                        globalMdmDetail &&
                                        this.getDropdownValues(
                                            globalMdmDetail.SalesOfficeTypeId,
                                            staticData &&
                                                staticData.OlympusSalesOfficeType
                                        )
                                    }
                                    isRequired={this.state.formData['Country'] === 'DE'}
                                    formErrors={
                                        this.state.formErrors
                                            ? this.state.formErrors['SalesOffice']
                                            : null
                                    }
                                    onFieldChange={this.onFieldChange}
                                    inline={true}
                                    team="system"
                                />
                                <FormInput
                                    variant="outline"
                                    label="Sales Group"
                                    name="SalesGroup"
                                    hide={
                                        allowedFields &&
                                        allowedFields.includes('SalesGroup')
                                    }
                                    value={
                                        staticData &&
                                        globalMdmDetail &&
                                        this.getDropdownValues(
                                            globalMdmDetail.SalesGroupTypeId,
                                            staticData && staticData.OlympusSalesGroupType
                                        )
                                    }
                                    isRequired={this.state.formData['Country'] === 'DE'}
                                    formErrors={
                                        this.state.formErrors
                                            ? this.state.formErrors['SalesGroup']
                                            : null
                                    }
                                    onFieldChange={this.onFieldChange}
                                    inline={true}
                                    team="system"
                                />
                                {Deltas && Deltas['TaxNumber4'] ? (
                                    <DeltaField delta={Deltas['TaxNumber4']} />
                                ) : (
                                    <FormInput
                                        // variant="outline"
                                        label="Tax Number 4"
                                        name="TaxNumber4"
                                        hide={
                                            allowedFields &&
                                            allowedFields.includes('TaxNumber4')
                                        }
                                        error={
                                            this.state.formErrors
                                                ? this.state.formErrors['TaxNumber4']
                                                : null
                                        }
                                        value={
                                            (this.state.formData &&
                                                this.state.formData['TaxNumber4']) ||
                                            (globalMdmDetail &&
                                                globalMdmDetail['TaxNumber4'])
                                        }
                                        style={{ height: 'inherit' }}
                                        onChange={(value, element) => {
                                            this.onFieldChange(value, element);
                                        }}
                                        inline={true}
                                        team="system"
                                        {...pageProps}
                                    />
                                )}
                                <FormInput
                                    variant="outline"
                                    label="Incoterms1"
                                    name="Incoterms1"
                                    hide={
                                        allowedFields &&
                                        allowedFields.includes('Incoterms1')
                                    }
                                    value={
                                        staticData &&
                                        globalMdmDetail &&
                                        this.getDropdownValues(
                                            globalMdmDetail.IncoTermsTypeId ||
                                                globalMdmDetail.Incoterms1TypeId,
                                            staticData && staticData.OlympusIncoTermsType
                                        )
                                    }
                                    formErrors={
                                        this.state.formErrors
                                            ? this.state.formErrors['Incoterms1']
                                            : null
                                    }
                                    onFieldChange={this.onFieldChange}
                                    inline={true}
                                    team="system"
                                />
                                <DynamicSelect
                                    arrayOfData={
                                        staticData && staticData.OlympusPriceGroupType
                                    }
                                    label="Price Group"
                                    name="PriceGroupTypeId"
                                    isRequired={true}
                                    formErrors={
                                        this.state.formErrors
                                            ? this.state.formErrors['PriceGroupTypeId']
                                            : null
                                    }
                                    onFieldChange={this.onFieldChange}
                                    value={
                                        isWorkFlowReadOnly
                                            ? customerMaster &&
                                              parseAndSanitise(
                                                  customerMaster.PriceGroupTypeId
                                              )
                                            : this.state.formData
                                            ? this.state.formData['PriceGroupTypeId']
                                            : null
                                    }
                                    inputProps={inputReadonlyProps}
                                    team="customermaster"
                                    variant="outline"
                                    readOnly={true}
                                />
                                <DynamicSelect
                                    arrayOfData={
                                        staticData &&
                                        this.deliveryPlantMapping(
                                            staticData.OlympusDeliveringPlantType
                                        )
                                    }
                                    label="Delivering Plant"
                                    name="DeliveringPlantTypeId"
                                    hide={
                                        allowedFields &&
                                        allowedFields.includes('DeliveringPlant')
                                    }
                                    isRequired={true}
                                    formErrors={
                                        this.state.formErrors
                                            ? this.state.formErrors[
                                                  'DeliveringPlantTypeId'
                                              ]
                                            : null
                                    }
                                    onFieldChange={this.onFieldChange}
                                    value={
                                        isWorkFlowReadOnly
                                            ? customerMaster &&
                                              parseAndSanitise(
                                                  customerMaster.DeliveringPlantTypeId
                                              )
                                            : this.state.formData
                                            ? this.state.formData['DeliveringPlantTypeId']
                                            : null
                                    }
                                    inputProps={inputReadonlyProps}
                                    team="customermaster"
                                    variant="outline"
                                    readOnly={true}
                                />

                                <FormInput
                                    variant="outline"
                                    label="Place Of Destination"
                                    name="PlaceOfDestination"
                                    hide={
                                        allowedFields &&
                                        allowedFields.includes('PlaceOfDestination')
                                    }
                                    error={
                                        this.state.formErrors
                                            ? this.state.formErrors['PlaceOfDestination']
                                            : null
                                    }
                                    value={
                                        globalMdmDetail &&
                                        globalMdmDetail['PlaceOfDestination']
                                    }
                                    style={{ height: 'inherit' }}
                                    onChange={(value, element) => {
                                        this.onFieldChange(value, element);
                                    }}
                                    inline={true}
                                    team="system"
                                />
                                <FormInput
                                    variant="outline"
                                    label="Acct Assignment Group"
                                    name="AcctAssignmentGroup"
                                    hide={
                                        allowedFields &&
                                        allowedFields.includes('AcctAssignmentGroup')
                                    }
                                    value={
                                        staticData &&
                                        globalMdmDetail &&
                                        this.getDropdownValues(
                                            globalMdmDetail.AccountAssignmentGroupTypeId,
                                            staticData &&
                                                staticData.OlympusAcctAssignmentGroupType
                                        )
                                    }
                                    formErrors={
                                        this.state.formErrors
                                            ? this.state.formErrors['AcctAssignmentGroup']
                                            : null
                                    }
                                    onFieldChange={this.onFieldChange}
                                    inline={true}
                                    team="system"
                                />
                                {Deltas && Deltas['TaxClassificationTypeId'] ? (
                                    <DeltaField
                                        delta={Deltas['TaxClassificationTypeId']}
                                    />
                                ) : (
                                    <DynamicSelect
                                        arrayOfData={
                                            staticData &&
                                            staticData.OlympusTaxClassificationType
                                            // &&
                                            // staticData.OlympusTaxClassificationType.filter(
                                            //     (role) =>
                                            //         role.systemId ===
                                            //         parseInt(formData['TaxClassification'])
                                            // )
                                        }
                                        label="Tax Classification"
                                        name="TaxClassificationTypeId"
                                        // inputProps={isSalesForce}
                                        value={
                                            this.state.formData['TaxClassificationTypeId']
                                        }
                                        isRequired
                                        formErrors={
                                            this.state.formErrors
                                                ? this.state.formErrors[
                                                      'TaxClassificationTypeId'
                                                  ]
                                                : null
                                        }
                                        onFieldChange={this.onFieldChange}
                                        hide={
                                            allowedFields &&
                                            allowedFields.includes('TaxClassification')
                                        }
                                        team="system"
                                        {...pageProps}
                                    />
                                )}
                            </Box>
                            {!isPointman ? (
                                <Box width={1 / 2} mx="auto" alignItems="center">
                                    {this.renderOlympusFields(
                                        isPointman,
                                        globalMdmDetail,
                                        staticData,
                                        allowedFields
                                    )}
                                </Box>
                            ) : (
                                <Box width={1 / 2} mx="auto" alignItems="center">
                                    {globalMdmDetail.RoleTypeId !== 1 && (
                                        <FormInput
                                            label="Ship To Customer Number"
                                            name="ShipToCustomerNumber"
                                            type="number"
                                            value={
                                                isWorkFlowReadOnly
                                                    ? customerMaster &&
                                                      customerMaster.ShipToCustomerNumber
                                                    : this.state.formData
                                                    ? this.state.formData[
                                                          'ShipToCustomerNumber'
                                                      ]
                                                    : null
                                            }
                                            error={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                          'ShipToCustomerNumber'
                                                      ]
                                                    : null
                                            }
                                            onChange={(val, e) =>
                                                this.onFieldChange(
                                                    val.toLowerCase().trim(),
                                                    e
                                                )
                                            }
                                            maxLength={8}
                                            variant={
                                                isWorkFlowReadOnly ? 'outline' : 'solid'
                                            }
                                            inline={isWorkFlowReadOnly ? true : false}
                                            required
                                            team="system"
                                        />
                                    )}
                                    {this.renderOlympusFields(
                                        isPointman,
                                        globalMdmDetail,
                                        staticData,
                                        allowedFields
                                    )}
                                </Box>
                            )}
                        </Box>
                        <Box {...showFunctionalDetail}>
                            <Text
                                mt="42px"
                                mb="20px"
                                fontWeight="regular"
                                color="lightBlue"
                                fontSize={24}
                                pl={4}>
                                CUSTOMER MASTER FIELDS
                            </Text>
                            <Box flexDirection="row" justifyContent="center">
                                <Box width={1 / 2} mx="auto" alignItems="center">
                                    <FormInput
                                        label="Search Term 1"
                                        name="SearchTerm1"
                                        variant="outline"
                                        hide={
                                            allowedFields &&
                                            allowedFields.includes('SearchTerm1')
                                        }
                                        maxLength={20}
                                        error={
                                            this.state.formErrors
                                                ? this.state.formErrors['SearchTerm1']
                                                : null
                                        }
                                        onChange={this.onFieldChange}
                                        type="text"
                                        value={
                                            isWorkFlowReadOnly
                                                ? customerMaster &&
                                                  customerMaster.SearchTerm1
                                                : this.state.formData
                                                ? this.state.formData['SearchTerm1']
                                                : null
                                        }
                                        // variant={
                                        //     isWorkFlowReadOnly ? 'outline' : 'solid'
                                        // }
                                        inline={isWorkFlowReadOnly ? true : false}
                                        team="customermaster"
                                    />
                                    <FormInput
                                        label="Search Term 2"
                                        name="SearchTerm2"
                                        hide={
                                            allowedFields &&
                                            allowedFields.includes('SearchTerm2')
                                        }
                                        maxLength={20}
                                        error={
                                            this.state.formErrors
                                                ? this.state.formErrors['SearchTerm2']
                                                : null
                                        }
                                        onChange={this.onFieldChange}
                                        type="text"
                                        required={true}
                                        value={
                                            isWorkFlowReadOnly
                                                ? customerMaster &&
                                                  customerMaster.SearchTerm2
                                                : this.state.formData
                                                ? this.state.formData['SearchTerm2']
                                                : null
                                        }
                                        variant={isWorkFlowReadOnly ? 'outline' : 'solid'}
                                        inline={isWorkFlowReadOnly ? true : false}
                                        team="customermaster"
                                        {...pageProps}
                                    />
                                    {/* <DynamicSelect
                                            arrayOfData={
                                                staticData &&
                                                staticData.OlympusIndustryType
                                            }
                                            label="Industry"
                                            name="Industry"
                                            hide={
                                                allowedFields &&
                                                allowedFields.includes('Industry')
                                            }
                                            isRequired={true}
                                            formErrors={
                                                this.state.formErrors
                                                    ? this.state.formErrors['Industry']
                                                    : null
                                            }
                                            onFieldChange={this.onFieldChange}
                                            value={
                                                isWorkFlowReadOnly
                                                    ? customerMaster &&
                                                      parseAndSanitise(
                                                          customerMaster.Industry
                                                      )
                                                    : this.state.formData
                                                    ? this.state.formData['Industry']
                                                    : null
                                            }
                                            inputProps={inputReadonlyProps}
                                        /> */}
                                    <DynamicSelect
                                        arrayOfData={
                                            staticData &&
                                            staticData.OlympusIndustryCodeType
                                        }
                                        label="Industry Code 1"
                                        name="IndustryCode"
                                        hide={
                                            allowedFields &&
                                            allowedFields.includes('IndustryCode')
                                        }
                                        isRequired={false}
                                        formErrors={
                                            this.state.formErrors
                                                ? this.state.formErrors['IndustryCode']
                                                : null
                                        }
                                        onFieldChange={this.onFieldChange}
                                        value={
                                            isWorkFlowReadOnly
                                                ? customerMaster &&
                                                  parseAndSanitise(
                                                      customerMaster.IndustryCodeTypeId
                                                  )
                                                : this.state.formData
                                                ? this.state.formData['IndustryCode']
                                                : null
                                        }
                                        inputProps={inputReadonlyProps}
                                        team="customermaster"
                                        variant="outline"
                                        readOnly={true}
                                    />
                                    <DynamicSelect
                                        arrayOfData={
                                            staticData && staticData.OlympusSortKeyType
                                        }
                                        label="Sort Key"
                                        name="SortKeyTypeId"
                                        hide={
                                            allowedFields &&
                                            allowedFields.includes('SortKey')
                                        }
                                        isRequired={true}
                                        formErrors={
                                            this.state.formErrors
                                                ? this.state.formErrors['SortKeyTypeId']
                                                : null
                                        }
                                        onFieldChange={this.onFieldChange}
                                        value={
                                            isWorkFlowReadOnly
                                                ? customerMaster &&
                                                  parseAndSanitise(
                                                      this.state.formData.SortKeyTypeId
                                                  )
                                                : this.state.formData
                                                ? this.state.formData['SortKeyTypeId']
                                                : null
                                        }
                                        inputProps={inputReadonlyProps}
                                        team="customermaster"
                                        readOnly={true}
                                        variant="outline"
                                    />
                                    {Deltas && Deltas['CustPriceProcTypeId'] ? (
                                        <DeltaField
                                            delta={Deltas['CustPriceProcTypeId']}
                                            arrayOfData={
                                                staticData &&
                                                staticData.OlympusCustPriceProcType
                                            }
                                        />
                                    ) : (
                                        <DynamicSelect
                                            arrayOfData={
                                                staticData &&
                                                staticData.OlympusCustPriceProcType
                                            }
                                            label="Cust Price Proc"
                                            name="CustPriceProcTypeId"
                                            hide={
                                                allowedFields &&
                                                allowedFields.includes('CustPriceProc')
                                            }
                                            isRequired={true}
                                            formErrors={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                          'CustPriceProcTypeId'
                                                      ]
                                                    : null
                                            }
                                            onFieldChange={this.onFieldChange}
                                            value={
                                                isWorkFlowReadOnly
                                                    ? customerMaster &&
                                                      parseAndSanitise(
                                                          customerMaster.CustomerPriceProcTypeId
                                                      )
                                                    : this.state.formData
                                                    ? this.state.formData[
                                                          'CustPriceProcTypeId'
                                                      ]
                                                    : null
                                            }
                                            inputProps={inputReadonlyProps}
                                            team="customermaster"
                                        />
                                    )}
                                    {Deltas && Deltas['CustomerGroupTypeId'] ? (
                                        <DeltaField
                                            delta={Deltas['CustomerGroupTypeId']}
                                            arrayOfData={
                                                staticData &&
                                                staticData.OlympusCustomerGroupType
                                            }
                                        />
                                    ) : (
                                        <DynamicSelect
                                            arrayOfData={
                                                staticData &&
                                                staticData.OlympusCustomerGroupType
                                            }
                                            label="Customer Group"
                                            name="CustomerGroupTypeId"
                                            hide={
                                                allowedFields &&
                                                allowedFields.includes('CustomerGroup')
                                            }
                                            isRequired={false}
                                            formErrors={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                          'CustomerGroupTypeId'
                                                      ]
                                                    : null
                                            }
                                            onFieldChange={this.onFieldChange}
                                            value={
                                                isWorkFlowReadOnly
                                                    ? customerMaster &&
                                                      parseAndSanitise(
                                                          customerMaster.CustomerGroupTypeId
                                                      )
                                                    : this.state.formData
                                                    ? this.state.formData[
                                                          'CustomerGroupTypeId'
                                                      ]
                                                    : null
                                            }
                                            inputProps={inputReadonlyProps}
                                            team="customermaster"
                                            {...pageProps}
                                        />
                                    )}
                                    <DynamicSelect
                                        arrayOfData={
                                            staticData &&
                                            staticData.OlympusDeliveryPriorityType
                                        }
                                        label="Delivery Priority"
                                        name="DeliveryPriorityTypeId"
                                        hide={
                                            allowedFields &&
                                            allowedFields.includes('DeliveryPriority')
                                        }
                                        isRequired={true}
                                        formErrors={
                                            this.state.formErrors
                                                ? this.state.formErrors[
                                                      'DeliveryPriorityTypeId'
                                                  ]
                                                : null
                                        }
                                        onFieldChange={this.onFieldChange}
                                        value={
                                            isWorkFlowReadOnly
                                                ? customerMaster &&
                                                  parseAndSanitise(
                                                      customerMaster.DeliveryPriorityTypeId
                                                  )
                                                : this.state.formData
                                                ? this.state.formData[
                                                      'DeliveryPriorityTypeId'
                                                  ]
                                                : null
                                        }
                                        inputProps={inputReadonlyProps}
                                        team="customermaster"
                                        variant="outline"
                                        readOnly={true}
                                    />

                                    {/* <FormInput
                                            label="Additional Notes"
                                            name="AdditionalNotes"
                                            hide={
                                                allowedFields &&
                                                allowedFields.includes('AdditionalNotes')
                                            }
                                            maxLength={20}
                                            error={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                          'AddtionalNotes'
                                                      ]
                                                    : null
                                            }
                                            onChange={this.onFieldChange}
                                            type="text"
                                            value={
                                                isWorkFlowReadOnly
                                                    ? customerMaster &&
                                                      customerMaster.SearchTerm1
                                                    : this.state.formData
                                                    ? this.state.formData[
                                                          'AdditionalNotes'
                                                      ]
                                                    : null
                                            }
                                            variant={
                                                isWorkFlowReadOnly ? 'outline' : 'solid'
                                            }
                                            inline={isWorkFlowReadOnly ? true : false}
                                        /> */}
                                </Box>
                                <Box width={1 / 2} mx="auto" alignItems="center">
                                    {Deltas && Deltas['ShippingConditionTypeId'] ? (
                                        <DeltaField
                                            delta={Deltas['ShippingConditionTypeId']}
                                            arrayOfData={
                                                staticData &&
                                                staticData.OlympusShippingConditionsType
                                            }
                                        />
                                    ) : (
                                        <DynamicSelect
                                            arrayOfData={
                                                staticData &&
                                                staticData.OlympusShippingConditionsType
                                            }
                                            label="Shipping Conditions"
                                            name="ShippingConditionTypeId"
                                            hide={
                                                allowedFields &&
                                                allowedFields.includes(
                                                    'ShippingConditons'
                                                )
                                            }
                                            isRequired={true}
                                            formErrors={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                          'ShippingConditionTypeId'
                                                      ]
                                                    : null
                                            }
                                            onFieldChange={this.onFieldChange}
                                            value={
                                                isWorkFlowReadOnly
                                                    ? customerMaster &&
                                                      parseAndSanitise(
                                                          customerMaster.ShippingConditionsTypeId
                                                      )
                                                    : this.state.formData
                                                    ? this.state.formData[
                                                          'ShippingConditionTypeId'
                                                      ]
                                                    : null
                                            }
                                            inputProps={inputReadonlyProps}
                                            team="customermaster"
                                            {...pageProps}
                                        />
                                    )}
                                    <DynamicSelect
                                        arrayOfData={
                                            staticData &&
                                            staticData.OlympusMaxPartialDeliveriesType
                                        }
                                        label="Max Partial Deliveries"
                                        name="MaxPartialDeliveryTypeId"
                                        hide={
                                            allowedFields &&
                                            allowedFields.includes('MaxPartialDeliveries')
                                        }
                                        isRequired={true}
                                        formErrors={
                                            this.state.formErrors
                                                ? this.state.formErrors[
                                                      'MaxPartialDeliveryTypeId'
                                                  ]
                                                : null
                                        }
                                        onFieldChange={this.onFieldChange}
                                        value={
                                            isWorkFlowReadOnly
                                                ? customerMaster &&
                                                  parseAndSanitise(
                                                      customerMaster.MaxPartialDeliveriesTypeId
                                                  )
                                                : this.state.formData
                                                ? this.state.formData[
                                                      'MaxPartialDeliveryTypeId'
                                                  ]
                                                : null
                                        }
                                        inputProps={inputReadonlyProps}
                                        team="customermaster"
                                        variant="outline"
                                        readOnly={true}
                                    />
                                    <FormInput
                                        label="Order Probab"
                                        name="OrdProbability"
                                        hide={
                                            allowedFields &&
                                            allowedFields.includes('OrderProbab')
                                        }
                                        maxLength={3}
                                        required={true}
                                        error={
                                            this.state.formErrors
                                                ? this.state.formErrors['OrdProbability']
                                                : null
                                        }
                                        onChange={this.validateNumericKeypad}
                                        type="number"
                                        value={
                                            isWorkFlowReadOnly
                                                ? customerMaster &&
                                                  customerMaster.OrderProbab
                                                : this.state.formData
                                                ? this.state.formData['OrdProbability']
                                                : null
                                        }
                                        variant="outline"
                                        readOnly={true}
                                        inline={isWorkFlowReadOnly ? true : false}
                                        team="customermaster"
                                    />

                                    <CheckBoxItem
                                        title="Complete Delivery Required"
                                        name="CompleteDelivery"
                                        hide={
                                            allowedFields &&
                                            allowedFields.includes(
                                                'CompleteDeliveryRequired'
                                            )
                                        }
                                        stateValue={
                                            isWorkFlowReadOnly
                                                ? customerMaster &&
                                                  customerMaster.CompleteDelivery
                                                : this.state.formData &&
                                                  this.state.formData['CompleteDelivery']
                                        }
                                        inputProps={enableDisplay}
                                        onValueChange={() => {
                                            this.setState({
                                                formData: {
                                                    ...this.state.formData,
                                                    CompleteDeliveryRequired: !this.state
                                                        .formData
                                                        .CompleteDeliveryRequired,
                                                },
                                                PartialDeliveryPerItem: !this.state
                                                    .formData.CompleteDeliveryRequired
                                                    ? '4'
                                                    : '',
                                            });
                                        }}
                                        team="customermaster"
                                    />

                                    <DynamicSelect
                                        arrayOfData={
                                            staticData && staticData.OlympusPriceGroupType
                                        }
                                        label="Price Group"
                                        name="PriceGroupTypeId"
                                        isRequired={true}
                                        formErrors={
                                            this.state.formErrors
                                                ? this.state.formErrors[
                                                      'PriceGroupTypeId'
                                                  ]
                                                : null
                                        }
                                        onFieldChange={this.onFieldChange}
                                        value={
                                            isWorkFlowReadOnly
                                                ? customerMaster &&
                                                  parseAndSanitise(
                                                      customerMaster.PriceGroupTypeId
                                                  )
                                                : this.state.formData
                                                ? this.state.formData['PriceGroupTypeId']
                                                : null
                                        }
                                        inputProps={inputReadonlyProps}
                                        team="customermaster"
                                        variant="outline"
                                        readOnly={true}
                                    />

                                    <DynamicSelect
                                        arrayOfData={
                                            staticData &&
                                            staticData.OlympusPartialDeliveryPerItemType
                                        }
                                        label="Partial Delivery Per Item"
                                        name="PartialDeliveryTypeId"
                                        hide={
                                            allowedFields &&
                                            allowedFields.includes(
                                                'PartialDeliveryPerItem'
                                            )
                                        }
                                        isRequired={true}
                                        formErrors={
                                            this.state.formErrors
                                                ? this.state.formErrors[
                                                      'PartialDeliveryTypeId'
                                                  ]
                                                : null
                                        }
                                        onFieldChange={this.onFieldChange}
                                        value={
                                            isWorkFlowReadOnly
                                                ? customerMaster &&
                                                  parseAndSanitise(
                                                      customerMaster.PartialDeliveryPerItemTypeId
                                                  )
                                                : this.state.formData
                                                ? this.state.formData[
                                                      'PartialDeliveryTypeId'
                                                  ]
                                                : null
                                        }
                                        inputProps={inputReadonlyProps}
                                        team="customermaster"
                                        variant="outline"
                                        readOnly={true}
                                    />

                                    <FormInput
                                        label="Customer Stat Group"
                                        name="CustomerStatGroup"
                                        variant="outline"
                                        hide={
                                            allowedFields &&
                                            allowedFields.includes('CustomerStatGroup')
                                        }
                                        maxLength={20}
                                        error={
                                            this.state.formErrors
                                                ? this.state.formErrors[
                                                      'CustomerStatGroup'
                                                  ]
                                                : null
                                        }
                                        onChange={this.onFieldChange}
                                        type="text"
                                        value={
                                            isWorkFlowReadOnly
                                                ? customerMaster &&
                                                  customerMaster.CustomerStatGroup
                                                : this.state.formData
                                                ? this.state.formData['CustomerStatGroup']
                                                : null
                                        }
                                        inline={isWorkFlowReadOnly ? true : false}
                                        team="customermaster"
                                        variant="outline"
                                        readOnly={true}
                                    />

                                    {/* <DynamicSelect
                                        arrayOfData={
                                            staticData &&
                                            this.deliveryPlantMapping(
                                                staticData.OlympusDeliveringPlantType
                                            )
                                        }
                                        label="Delivering Plant"
                                        name="DeliveringPlantTypeId"
                                        hide={
                                            allowedFields &&
                                            allowedFields.includes('DeliveringPlant')
                                        }
                                        isRequired={true}
                                        formErrors={
                                            this.state.formErrors
                                                ? this.state.formErrors[
                                                'DeliveringPlantTypeId'
                                                ]
                                                : null
                                        }
                                        onFieldChange={this.onFieldChange}
                                        value={
                                            isWorkFlowReadOnly
                                                ? customerMaster &&
                                                parseAndSanitise(
                                                    customerMaster.DeliveringPlantTypeId
                                                )
                                                : this.state.formData
                                                    ? this.state.formData[
                                                    'DeliveringPlantTypeId'
                                                    ]
                                                    : null
                                        }
                                        inputProps={inputReadonlyProps}
                                        team="customermaster"
                                        variant="outline"
                                        readOnly={true}
                                    /> */}
                                    {/* <DynamicSelect
                                        arrayOfData={
                                            staticData &&
                                            staticData.OlympusTransporationZoneType &&
                                            this.getDropdownList(
                                                'CountryTypeValue',
                                                staticData.OlympusTransporationZoneType,
                                                'Country'
                                            )
                                        }
                                        label="Transportation Zone"
                                        name="TransportationZone"
                                        hide={
                                            allowedFields &&
                                            allowedFields.includes('TransportationZone')
                                        }
                                        isRequired={true}
                                        formErrors={
                                            this.state.formErrors
                                                ? this.state.formErrors[
                                                      'TransportationZone'
                                                  ]
                                                : null
                                        }
                                        onFieldChange={this.onFieldChange}
                                        value={
                                            isWorkFlowReadOnly
                                                ? customerMaster &&
                                                  parseAndSanitise(
                                                      customerMaster.TransportationZone
                                                  )
                                                : this.state.formData
                                                ? this.state.formData[
                                                      'TransportationZone'
                                                  ]
                                                : null
                                        }
                                        inputProps={inputReadonlyProps}
                                        team="customermaster"
                                        variant="outline"
                                        readOnly={true}
                                    /> */}
                                    <FormInput
                                        label="Transportation Zone"
                                        name="TransZone"
                                        hide={
                                            allowedFields &&
                                            allowedFields.includes('TransportationZone')
                                        }
                                        maxLength={20}
                                        error={
                                            this.state.formErrors
                                                ? this.state.formErrors[
                                                      'TransportionZone'
                                                  ]
                                                : null
                                        }
                                        onChange={this.onFieldChange}
                                        type="text"
                                        value={
                                            isWorkFlowReadOnly
                                                ? customerMaster &&
                                                  customerMaster.TransZone
                                                : this.state.formData
                                                ? this.state.formData['TransZone']
                                                : null
                                        }
                                        required={true}
                                        variant={'outline'}
                                        inline={true}
                                    />
                                    <CheckBoxItem
                                        title="Payment History Record"
                                        name="PaymHistory"
                                        hide={
                                            allowedFields &&
                                            allowedFields.includes('PaymentHistoryRecord')
                                        }
                                        stateValue={
                                            isWorkFlowReadOnly
                                                ? customerMaster &&
                                                  customerMaster.PaymHistory
                                                : this.state.formData &&
                                                  this.state.formData['PaymHistory']
                                        }
                                        required={true}
                                        inputProps={enableDisplay}
                                        onValueChange={() =>
                                            this.setState({
                                                formData: {
                                                    ...this.state.formData,
                                                    PaymentHistoryRecord: !this.state
                                                        .formData.PaymentHistoryRecord,
                                                },
                                            })
                                        }
                                        team="customermaster"
                                    />
                                    <FormInput
                                        label="Incoterms 2"
                                        name="IncoTerms2"
                                        hide={
                                            allowedFields &&
                                            allowedFields.includes('IncoTerms2')
                                        }
                                        maxLength={28}
                                        onChange={this.onFieldChange}
                                        error={
                                            this.state.formErrors
                                                ? this.state.formErrors['Incoterms2']
                                                : null
                                        }
                                        type="text"
                                        required={true}
                                        value={
                                            isWorkFlowReadOnly
                                                ? customerMaster &&
                                                  customerMaster.IncoTerms2
                                                : this.state.formData
                                                ? this.state.formData['Incoterms2']
                                                : null
                                        }
                                        variant={
                                            this.state.formData.isReadonlyINCOT2 ||
                                            isWorkFlowReadOnly
                                                ? 'outline'
                                                : 'solid'
                                        }
                                        inline={isWorkFlowReadOnly ? true : false}
                                        inputProps={
                                            isWorkFlowReadOnly
                                                ? inputReadonlyProps
                                                : inputPropsForDefaultRules['Incoterms2']
                                        }
                                        team="customermaster"
                                        variant="outline"
                                        readOnly={true}
                                    />

                                    <CheckBoxItem
                                        title="Order Combination"
                                        name="OrderCombn"
                                        hide={
                                            allowedFields &&
                                            allowedFields.includes('OrderCombination')
                                        }
                                        stateValue={
                                            isWorkFlowReadOnly
                                                ? customerMaster &&
                                                  customerMaster.OrderCombn
                                                : this.state.formData &&
                                                  this.state.formData['OrderCombn']
                                        }
                                        inputProps={enableDisplay}
                                        onValueChange={() =>
                                            this.setState({
                                                formData: {
                                                    ...this.state.formData,
                                                    OrderCombination: !this.state.formData
                                                        .OrderCombination,
                                                },
                                            })
                                        }
                                        team="customermaster"
                                    />
                                </Box>
                            </Box>
                        </Box>

                        <Box {...showFunctionalDetail}>
                            <Text
                                mt="42px"
                                mb="20px"
                                fontWeight="regular"
                                color="lightBlue"
                                fontSize={24}
                                pl={4}>
                                CONTRACT FIELDS
                            </Text>
                            <Box flexDirection="row" justifyContent="center">
                                <Box width={1 / 2} mx="auto" alignItems="center">
                                    {Deltas && Deltas['TermsOfPaymentTypeId'] ? (
                                        <DeltaField
                                            delta={Deltas['TermsOfPaymentTypeId']}
                                            arrayOfData={
                                                (this.props.staticData &&
                                                    this.props.staticData
                                                        .OlympusTermsOfPaymentType) ||
                                                []
                                            }
                                        />
                                    ) : (
                                        <DynamicSelect
                                            arrayOfData={
                                                (this.props.staticData &&
                                                    this.props.staticData
                                                        .OlympusTermsOfPaymentType) ||
                                                []
                                            }
                                            label="Payment Terms"
                                            name="TermsOfPaymentTypeId"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes(
                                                    'PaymentTermsContracts'
                                                )
                                            }
                                            isRequired={true}
                                            formErrors={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                          'TermsOfPaymentTypeId'
                                                      ]
                                                    : null
                                            }
                                            onFieldChange={this.onFieldChange}
                                            value={
                                                workflow.isReadOnly
                                                    ? functionalDetail &&
                                                      parseInt(
                                                          functionalDetail.PaymentTermsTypeId
                                                      )
                                                    : this.state.formData
                                                    ? this.state.formData[
                                                          'TermsOfPaymentTypeId'
                                                      ]
                                                    : null
                                            }
                                            inputProps={
                                                workflow.isReadOnly
                                                    ? inputReadonlyProps
                                                    : inputPropsForDefaultRules[
                                                          'TermsOfPaymentTypeId'
                                                      ]
                                            }
                                            team="contracts"
                                            {...pageProps}
                                        />
                                    )}
                                </Box>
                                <Box width={1 / 2} mx="auto" alignItems="center">
                                    {Deltas && Deltas['IncoTerms1TypeId'] ? (
                                        <DeltaField
                                            delta={Deltas['IncoTerms1TypeId']}
                                            arrayOfData={
                                                (this.props.staticData &&
                                                    this.props.staticData
                                                        .OlympusIncoTermsType) ||
                                                []
                                            }
                                        />
                                    ) : (
                                        <DynamicSelect
                                            arrayOfData={
                                                (this.props.staticData &&
                                                    this.props.staticData
                                                        .OlympusIncoTermsType) ||
                                                []
                                            }
                                            label="Incoterms 1"
                                            name="Incoterms1TypeId"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes(
                                                    'IncoTerms1Contracts'
                                                )
                                            }
                                            isRequired={true}
                                            formErrors={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                          'IncoTerms1TypeId'
                                                      ]
                                                    : null
                                            }
                                            onFieldChange={this.onFieldChange}
                                            value={
                                                workflow.isReadOnly
                                                    ? functionalDetail &&
                                                      functionalDetail.Incoterms1TypeId
                                                    : this.state.formData
                                                    ? this.state.formData[
                                                          'Incoterms1TypeId'
                                                      ]
                                                    : null
                                            }
                                            inputProps={inputReadonlyProps}
                                            team="contracts"
                                            {...pageProps}
                                        />
                                    )}
                                    {Deltas && Deltas['Incoterms2'] ? (
                                        <DeltaField delta={Deltas['Incoterms2']} />
                                    ) : (
                                        <FormInput
                                            label="Incoterms 2"
                                            name="Incoterms2"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes(
                                                    'IncoTerms2Contracts'
                                                )
                                            }
                                            maxLength={28}
                                            onChange={this.onFieldChange}
                                            error={
                                                this.state.formErrors
                                                    ? this.state.formErrors['IncoTerms2']
                                                    : null
                                            }
                                            type="text"
                                            required={
                                                this.state.formData.isRequiredINCOT2
                                            }
                                            value={
                                                workflow.isReadOnly
                                                    ? functionalDetail &&
                                                      functionalDetail.Incoterms2
                                                    : this.state.formData
                                                    ? this.state.formData['Incoterms2']
                                                    : null
                                            }
                                            variant={
                                                this.state.formData.isReadonlyINCOT2 ||
                                                workflow.isReadOnly
                                                    ? 'outline'
                                                    : 'solid'
                                            }
                                            inline={workflow.isReadOnly ? true : false}
                                            inputProps={
                                                workflow.isReadOnly
                                                    ? inputReadonlyProps
                                                    : inputPropsForDefaultRules[
                                                          'IncoTerms2'
                                                      ]
                                            }
                                            team="contracts"
                                            {...pageProps}
                                        />
                                    )}
                                </Box>
                            </Box>
                        </Box>
                        <Box {...showCreditDetail}>
                            <Text
                                mt="42px"
                                mb="20px"
                                fontWeight="regular"
                                color="lightBlue"
                                fontSize={24}
                                pl={4}>
                                CREDIT FIELDS
                            </Text>
                            <Box flexDirection="row" justifyContent="center">
                                <Box width={1 / 2} mx="auto" alignItems="center">
                                    <DynamicSelect
                                        arrayOfData={
                                            this.props.staticData &&
                                            this.props.staticData
                                                .OlympusAccountStatementType
                                        }
                                        value={
                                            workflow.isReadOnly
                                                ? creditDetail &&
                                                  parseAndSanitise(
                                                      creditDetail.AccountStatementTypeId
                                                  )
                                                : this.state.formData
                                                ? this.state.formData[
                                                      'AccountStatementTypeId'
                                                  ]
                                                : null
                                        }
                                        label="Account Statement"
                                        name="AccountStatementTypeId"
                                        hide={
                                            allowedFields &&
                                            !allowedFields.includes(
                                                'AccountStatementTypeIdCredit'
                                            )
                                        }
                                        isRequired
                                        formErrors={
                                            this.state.formErrors
                                                ? this.state.formErrors[
                                                      'AccountStatementTypeId'
                                                  ]
                                                : null
                                        }
                                        onFieldChange={this.onFieldChange}
                                        inputProps={inputReadonlyProps}
                                        team="credit"
                                        variant="outline"
                                        readOnly={true}
                                    />
                                    <DynamicSelect
                                        arrayOfData={
                                            this.props.staticData &&
                                            this.props.staticData
                                                .OlympusCreditControlAreaType &&
                                            workflow.isReadOnly
                                                ? this.props.staticData
                                                      .OlympusCreditControlAreaType
                                                : this.getDropdownCreditControlValues(
                                                      'SalesOrgTypeId',
                                                      this.props.staticData &&
                                                          this.props.staticData
                                                              .OlympusCreditControlAreaType,
                                                      'SalesOrgTypeId'
                                                  )
                                        }
                                        value={
                                            workflow.isReadOnly
                                                ? creditDetail &&
                                                  creditDetail.CreditControlAreaTypeId
                                                : this.state.formData
                                                ? this.state.formData[
                                                      'CreditControlAreaTypeId'
                                                  ]
                                                : null
                                        }
                                        label="Credit Control Area"
                                        name="CreditControlAreaTypeId"
                                        hide={
                                            allowedFields &&
                                            !allowedFields.includes(
                                                'CreditControlAreaTypeIdCredit'
                                            )
                                        }
                                        isRequired
                                        formErrors={
                                            this.state.formErrors
                                                ? this.state.formErrors[
                                                      'CreditControlAreaTypeId'
                                                  ]
                                                : null
                                        }
                                        inline={workflow.isReadOnly ? true : false}
                                        // variant={
                                        //     workflow.isReadOnly ? 'outline' : 'solid'
                                        // }
                                        onFieldChange={this.onFieldChange}
                                        inputProps={inputReadonlyProps}
                                        team="credit"
                                        variant="outline"
                                        readOnly={true}
                                    />
                                    {Deltas && Deltas['RiskCategoryTypeId'] ? (
                                        <DeltaField
                                            delta={Deltas['RiskCategoryTypeId']}
                                            arrayOfData={
                                                this.props.staticData &&
                                                this.props.staticData
                                                    .OlympusRiskCategoryType
                                            }
                                        />
                                    ) : (
                                        <DynamicSelect
                                            arrayOfData={
                                                this.props.staticData &&
                                                this.props.staticData
                                                    .OlympusRiskCategoryType
                                            }
                                            label="Risk Category"
                                            name="RiskCategoryTypeId"
                                            isRequired
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes(
                                                    'RiskCategoryTypeIdCredit'
                                                )
                                            }
                                            value={
                                                workflow.isReadOnly
                                                    ? creditDetail &&
                                                      parseAndSanitise(
                                                          creditDetail.RiskCategoryTypeId
                                                      )
                                                    : this.state.formData
                                                    ? this.state.formData[
                                                          'RiskCategoryTypeId'
                                                      ]
                                                    : null
                                            }
                                            formErrors={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                          'RiskCategoryTypeId'
                                                      ]
                                                    : creditDetail.RiskCategoryTypeId
                                            }
                                            inline={workflow.isReadOnly ? true : false}
                                            variant={
                                                workflow.isReadOnly ? 'outline' : 'solid'
                                            }
                                            onFieldChange={this.onFieldChange}
                                            inputProps={inputReadonlyProps}
                                            team="credit"
                                            {...pageProps}
                                        />
                                    )}
                                    <DynamicSelect
                                        arrayOfData={
                                            this.props.staticData &&
                                            this.props.staticData
                                                .OlympusCustomerCreditGroupType
                                        }
                                        label="Customer Credit Group"
                                        name="CustomerCreditGroupTypeId"
                                        hide={
                                            allowedFields &&
                                            !allowedFields.includes(
                                                'CustomerCreditGroupTypeIdCredit'
                                            )
                                        }
                                        value={
                                            workflow.isReadOnly
                                                ? creditDetail &&
                                                  parseAndSanitise(
                                                      creditDetail.CustomerCreditGroupTypeId
                                                  )
                                                : this.state.formData
                                                ? this.state.formData[
                                                      'CustomerCreditGroupTypeId'
                                                  ]
                                                : null
                                        }
                                        inline={workflow.isReadOnly ? true : false}
                                        // variant={
                                        //     workflow.isReadOnly ? 'outline' : 'solid'
                                        // }
                                        formErrors={
                                            this.state.formErrors
                                                ? this.state.formErrors[
                                                      'CustomerCreditGroupTypeId'
                                                  ]
                                                : null
                                        }
                                        onFieldChange={this.onFieldChange}
                                        inputProps={inputReadonlyProps}
                                        team="credit"
                                        variant="outline"
                                        readOnly={true}
                                    />
                                </Box>
                                <Box width={1 / 2} mx="auto" alignItems="center">
                                    {Deltas && Deltas['CreditLimit'] ? (
                                        <DeltaField delta={Deltas['CreditLimit']} />
                                    ) : (
                                        <FormInput
                                            label="Credit Limit"
                                            name="CreditLimit"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes('CreditLimit')
                                            }
                                            maxLength={15}
                                            value={
                                                workflow.isReadOnly
                                                    ? creditDetail &&
                                                      parseAndSanitise(
                                                          creditDetail.CreditLimit
                                                      )
                                                    : this.state.formData
                                                    ? parseAndSanitise(
                                                          parseInt(
                                                              this.state.formData[
                                                                  'CreditLimit'
                                                              ]
                                                          )
                                                      )
                                                    : null
                                            }
                                            error={
                                                this.state.formErrors
                                                    ? this.state.formErrors['CreditLimit']
                                                    : null
                                            }
                                            onChange={this.validateNumericKeypad}
                                            variant={
                                                workflow.isReadOnly ? 'outline' : 'solid'
                                            }
                                            inline={workflow.isReadOnly ? true : false}
                                            type="number"
                                            placeholder={'Only numeric values'}
                                            team="credit"
                                            {...pageProps}
                                        />
                                    )}
                                    <FormInput
                                        label="Cred Info Number"
                                        name="CreditInfoNo"
                                        hide={
                                            allowedFields &&
                                            !allowedFields.includes('CredInfoNumber')
                                        }
                                        maxLength={11}
                                        value={
                                            workflow.isReadOnly
                                                ? creditDetail &&
                                                  creditDetail.CredInfoNumber
                                                : this.state.formData
                                                ? this.state.formData['CreditInfoNo']
                                                : 0
                                        }
                                        error={
                                            this.state.formErrors
                                                ? this.state.formErrors['CreditInfoNo']
                                                : null
                                        }
                                        onChange={this.validateNumericKeypad}
                                        variant={
                                            workflow.isReadOnly ? 'outline' : 'solid'
                                        }
                                        inline={workflow.isReadOnly ? true : false}
                                        type="test"
                                        team="credit"
                                        variant="outline"
                                        readOnly={true}
                                    />
                                    <FormInput
                                        label="Payment Index"
                                        name="PaymentIndex"
                                        maxLength={3}
                                        hide={
                                            allowedFields &&
                                            !allowedFields.includes('PaymentIndex')
                                        }
                                        value={
                                            workflow.isReadOnly
                                                ? creditDetail &&
                                                  creditDetail.PaymentIndex
                                                : this.state.formData
                                                ? this.state.formData['PaymentIndex']
                                                : null
                                        }
                                        error={
                                            this.state.formErrors
                                                ? this.state.formErrors['PaymentIndex']
                                                : null
                                        }
                                        onChange={this.validateNumericKeypad}
                                        inline={workflow.isReadOnly ? true : false}
                                        variant={
                                            workflow.isReadOnly ? 'outline' : 'solid'
                                        }
                                        type="text"
                                        team="credit"
                                        variant="outline"
                                        readOnly={true}
                                    />
                                    <FormInput
                                        label="Last Ext Review"
                                        name="LastExtReview"
                                        hide={
                                            allowedFields &&
                                            !allowedFields.includes('LastExtReview')
                                        }
                                        variant={
                                            workflow.isReadOnly ? 'outline' : 'solid'
                                        }
                                        error={
                                            this.state.formErrors
                                                ? this.state.formErrors['LastExtReview']
                                                : null
                                        }
                                        value={
                                            workflow.isReadOnly
                                                ? creditDetail &&
                                                  creditDetail.LastExtReview &&
                                                  creditDetail.LastExtReview.split('T')[0]
                                                : this.state.formData
                                                ? this.state.formData['LastExtReview']
                                                : null
                                        }
                                        type="date"
                                        inline={workflow.isReadOnly ? true : false}
                                        onChange={(value, element) => {
                                            this.onFieldChange(
                                                new Date(value).toJSON().slice(0, 19),
                                                element
                                            );
                                        }}
                                        team="credit"
                                        variant="outline"
                                        readOnly={true}
                                    />
                                    <FormInput
                                        label="Rating"
                                        name="Rating"
                                        hide={
                                            allowedFields &&
                                            !allowedFields.includes('Rating')
                                        }
                                        type="text"
                                        maxLength={5}
                                        value={
                                            workflow.isReadOnly
                                                ? creditDetail && creditDetail.Rating
                                                : this.state.formData
                                                ? this.state.formData['Rating']
                                                : null
                                        }
                                        error={
                                            this.state.formErrors
                                                ? this.state.formErrors['Rating']
                                                : null
                                        }
                                        onChange={this.onFieldChange}
                                        // variant={
                                        //     workflow.isReadOnly ? 'outline' : 'solid'
                                        // }
                                        inline={workflow.isReadOnly ? true : false}
                                        team="credit"
                                        variant="outline"
                                        readOnly={true}
                                    />
                                </Box>
                            </Box>
                        </Box>
                        {/* <Box {...showFunctionalDetail}>
                            <Text
                                mt="42px"
                                mb="20px"
                                fontWeight="regular"
                                color="lightBlue"
                                fontSize={24}
                                pl={4}>
                                PRICING FIELDS
                            </Text>
                            <Box flexDirection="row" justifyContent="center">
                                <Box width={1 / 2} mx="auto" alignItems="center">
                                    {Deltas && Deltas['PriceGroupTypeId'] ? (
                                        <DeltaField
                                            delta={Deltas['PriceGroupTypeId']}
                                            arrayOfData={
                                                this.props.staticData &&
                                                this.props.staticData
                                                    .OlympusPriceGroupType
                                            }
                                        />
                                    ) : (
                                        <DynamicSelect
                                            arrayOfData={
                                                this.props.staticData &&
                                                this.props.staticData
                                                    .OlympusPriceGroupType
                                            }
                                            label="Price Group"
                                            name="PriceGroupTypeId"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes('PriceGroup')
                                            }
                                            formErrors={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                          'PriceGroupTypeId'
                                                      ]
                                                    : null
                                            }
                                            onFieldChange={this.onFieldChange}
                                            team="pricing"
                                            value={
                                                workflow.isReadOnly
                                                    ? pricingDetail &&
                                                      parseInt(
                                                          pricingDetail.PriceGroupTypeId
                                                      )
                                                    : this.state.formData
                                                    ? this.state.formData[
                                                          'PriceGroupTypeId'
                                                      ]
                                                    : null
                                            }
                                            inputProps={inputReadonlyProps}
                                            {...pageProps}
                                        />
                                    )}
                                </Box>
                                <Box width={1 / 2} mx="auto" alignItems="center"></Box>
                            </Box>
                        </Box> */}
                        {globalMdmDetail.LikelyMatches && (
                            <>
                                <Text
                                    mt="42px"
                                    mb="20px"
                                    fontWeight="regular"
                                    color="lightBlue"
                                    fontSize={24}
                                    pl={4}>
                                    Likely Matches
                                </Text>
                                <Box my={4}>
                                    <Box
                                        flexDirection="row"
                                        justifyContent="center"
                                        alignItems="center">
                                        {this.renderMatches(
                                            globalMdmDetail.LikelyMatches
                                        )}
                                    </Box>
                                </Box>
                            </>
                        )}

                        {files && <FilesList files={files} readOnly />}
                        {workflow && workflow.isReadOnly ? (
                            <>
                                <Box my={4}>
                                    <Box flexDirection="row" justifyContent="center">
                                        <Box width={1 / 2} mx="auto" alignItems="center">
                                            <FormInput
                                                label="Notes"
                                                name="AdditionalNotes"
                                                multiline
                                                numberOfLines={2}
                                                type="text"
                                                inputWidth="100%"
                                                value={
                                                    isWorkFlowReadOnly
                                                        ? Notes
                                                        : this.state.formData
                                                        ? this.state.formData[
                                                              'AdditionalNotes'
                                                          ]
                                                        : null
                                                }
                                                variant={
                                                    isWorkFlowReadOnly
                                                        ? 'outline'
                                                        : 'solid'
                                                }
                                                inline={isWorkFlowReadOnly ? true : false}
                                            />
                                        </Box>
                                        <Box
                                            width={1 / 2}
                                            mx="auto"
                                            alignItems="center"></Box>
                                    </Box>
                                </Box>
                            </>
                        ) : (
                            <FilesList
                                title="NEW ATTACHMENTS"
                                formErrors={this.state.fileErrors}
                                files={selectedFilesIds.map((id) => selectedFiles[id])}
                                onChange={(value, id) => {
                                    this.setState({
                                        selectedFiles: {
                                            ...selectedFiles,
                                            [id]: {
                                                ...selectedFiles[id],
                                                DocumentType: parseInt(value),
                                            },
                                        },
                                    });
                                }}
                                onRemove={(value, id) => this.removeFile(id)}
                            />
                        )}
                    </Box>
                    <Box {...enableDisplay}>
                        <Flex
                            justifyEnd
                            alignCenter
                            style={{
                                paddingTop: 15,
                                flexDirection: 'row',
                                alignItems: 'center',
                                paddingLeft: 10,
                                paddingRight: 15,
                                marginTop: 20,
                                marginBottom: 10,
                                marginHorizontal: 25,
                            }}>
                            {!isRequestPage && (
                                <>
                                    <label
                                        htmlFor="file-upload"
                                        className="custom-file-upload">
                                        <MaterialIcons
                                            name="attach-file"
                                            size={18}
                                            color="#fff"
                                        />
                                    </label>
                                    <input
                                        id="file-upload"
                                        key={this.state.fileinputkey}
                                        type="file"
                                        onChange={this.selectFile}
                                    />
                                    <Button
                                        onPress={() => this.props.history.goBack()}
                                        title="Cancel"
                                    />
                                    <Button
                                        onPress={(event) => this.onSubmit()}
                                        title="Submit"
                                        type="submit"
                                    />
                                </>
                            )}
                            {isRequestPage && withdrawToggle && (
                                //dont show withdraw for completed workflow
                                <Button
                                    onPress={() => {
                                        window.scrollTo(0, 0);
                                        this.props.withDrawRequest(
                                            {
                                                WorkflowId: this.props.match.params.id,
                                                WorkflowOperationType: 4,
                                            },
                                            this.props.history
                                        );
                                    }}
                                    title="Withdraw"
                                />
                            )}
                        </Flex>
                    </Box>
                    {this.state.isInternationalVersionVisible && (
                        <Modal
                            isVisible={this.state.isInternationalVersionVisible}
                            style={{ background: '#FFFFFF' }}>
                            <GlobalMdmFields
                                staticDropDownData={this.props.staticData}
                                formData={this.state.formData}
                                isInternationalVersion={true}
                                isOlympus={true}
                                isOlympusModal={true}
                                viewInternationalModal={() => {
                                    this.setState({
                                        isInternationalVersionVisible: false,
                                    });
                                }}
                                readOnly
                            />
                        </Modal>
                    )}
                </View>
            </ScrollView>
        );
    }
}

class Default extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const props = this.props;

        return (
            <DimensionAware
                render={(dimensions) => (
                    <Page
                        {...{
                            ...props,
                            width: getWindowWidth(dimensions),
                            height: getWindowHeight(dimensions),
                            marginBottom: 25,
                        }}
                    />
                )}
            />
        );
    }
}

const mapStateToProps = ({
    customer,
    toasts,
    workflows,
    myRequests,
    updateFlow,
    staticData,
}) => {
    const { fetching: fetchingReq, alert: requestAlert } = myRequests;
    const { bapiFullSet, alert: customerAlert, fetching: fetchingCustomer } = customer;
    const { alert: workFlowAlert, statusBarData } = workflows;
    const alert = workFlowAlert.display
        ? workFlowAlert
        : customerAlert.display
        ? customerAlert
        : requestAlert;
    const { fetching } = updateFlow;
    const {
        fetchingfnGroupData,
        functionalGroupDetails,
        TasksStatusByTeamId,
        fetchingStatusBar,
        dunsFields = {},
    } = workflows;

    const { data, fetching: staticFetching } = staticData;
    return {
        bapiFullSet,
        fetching: fetchingCustomer || fetching || fetchingReq || staticFetching,
        alert,
        toasts,
        statusBarData,
        functionalGroupDetails,
        staticData: data || [],
    };
};

export default connect(mapStateToProps, {
    updateDeltas,
    getCustomerFromSAP,
    removeMessage,
    withDrawRequest,
    getStatusBarData,
    getStaticRequest,
})(Default);

const styles = StyleSheet.create({
    progressIndicator: {
        flex: 1,
        paddingBottom: 5,
        flexDirection: 'row-reverse',
        alignItems: 'flex-end',
    },
    statusText: {
        fontSize: 15,
        color: '#1D4289',
        fontFamily: 'Poppins',
        textAlign: 'center',
        marginTop: 20,
    },
});
