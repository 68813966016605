import React, { Component } from 'react';
import { FormInput, FormSelect } from './form';
import { fetchCustomerMasterDropDownData } from '../redux/DropDownDatas';
import { countryregiondata } from '../constants/CountryRegionDropDownData';

class CountryRegionSelector extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropDownDatas: {},
            fvalue: '',
        };
    }
    render() {
        const { selectorType, inputProps, readOnly } = this.props;
        let arrayOfData = countryregiondata;
        let options = [];
        let placeholder_text = 'Select ..';
        if (selectorType === 'Country') {
            placeholder_text = 'Select Country';
            options =
                arrayOfData !== undefined &&
                arrayOfData.map((data) => (
                    <option
                        team={this.props.team || data.id}
                        key={data.countryShortCode}
                        value={data.countryShortCode}
                        disabled={inputProps ? inputProps.disabled : false}>
                        {' '}
                        {data.countryName ? data.countryName : ''}{' '}
                    </option>
                ));
        } else if (selectorType === 'region') {
            placeholder_text = 'Select Region';
            let states = [];
            if (this.props.Country != undefined && this.props.Country.length > 0)
                states = arrayOfData.find(
                    (cntry) => cntry.countryShortCode === this.props.Country
                ).Regions;
            options = states.map((data) => (
                <option
                    team={this.props.team || data.id}
                    key={data.id}
                    value={data.rkey}
                    disabled={inputProps ? inputProps.disabled : false}>
                    {' '}
                    {data.rname ? data.rname : ''}{' '}
                </option>
            ));
        }

        //if isRequired is false then show choose from option on dropdown list
        let isRequiredValue = this.props.isRequired ? this.props.isRequired : false;
        let displayChoose = isRequiredValue ? 'none' : 'flex';
        return (
            <>
                {!readOnly ? (
                    <FormSelect
                        label={this.props.label}
                        name={this.props.name}
                        value={this.props.value}
                        hide={this.props.hide}
                        small={this.props.small}
                        error={this.props.formErrors ? this.props.formErrors : null}
                        required={this.props.isRequired}
                        onChange={this.props.onFieldChange}
                        variant="solid"
                        {...inputProps}>
                        <option
                            style={{ display: displayChoose }}
                            value={0}
                            team={this.props.team || 'general'}>
                            Choose from...
                        </option>
                        {options}
                    </FormSelect>
                ) : (
                    <FormInput
                        px="25px"
                        flex={1 / 4}
                        width={this.props.width}
                        label={this.props.label}
                        hide={this.props.hide}
                        name={this.props.name}
                        small={this.props.small}
                        variant="outline"
                        inline
                        type="text"
                        value={this.props.value}
                        readOnly
                        showInlineRequired={this.props.showInlineRequired}
                    />
                )}
            </>
        );
    }
}

export default CountryRegionSelector;
