/* eslint-env browser */
if ('serviceWorker' in navigator) {
    window.addEventListener('load', function () {
        navigator.serviceWorker
            .register('/expo-service-worker.js', {
                scope: '/',
            })
            .then(function (registration) {
                console.info('Registered service-worker', registration);
                registration.unregister().then(function (boolean) {
                    console.info('unregister is successful');
                });
            })
            .catch(function (error) {
                console.info('Failed to register service-worker', error);
            });
    });
}
