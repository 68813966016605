import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import CreatePartnersEnhancedTable from './CreateJdeEnhancedTable';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { searchJdePartner } from '../appRedux/actions/Partners';
import { useSelector, useDispatch } from 'react-redux';
import { State } from 'react-native-gesture-handler';
import { ActivityIndicator } from 'react-native-web';
const CreateJdePartnersGrid = ({
    dataFull: parentGridData = [],
    data: results = [],
    columns: columnConfig = [],
    openModal,
    onChange: changeHandler = (data) => {},
    onPasteUpdate,
    ApolloPartnersRoleType,
    setTableData,
    addData,
    fetching,
    setPayloadp,
    originalTableData,
    showAddPartner,
    disableSaveButton,
    filterData,
}) => {
    const dispatch = useDispatch();
    const columns = React.useMemo(() => columnConfig, [columnConfig]);
    const [data, setData] = React.useState([...results] || []);
    const [selectedRows, setSelectedRows] = React.useState([]);
    const [selectedRowIds, setSelectedRowIds] = React.useState({});
    const [skipPageReset, setSkipPageReset] = React.useState(false);
    const [isdel, setdel] = React.useState(false);
    const [partnerEdits, setPartnerEdits] = React.useState([]);
    const [warning, setWarningDisplay] = React.useState(false);
    const [existingRolesInTable, setRoles] = React.useState([]);
    const [dropdownRoles, setDropdownRoles] = React.useState(
        ApolloPartnersRoleType || []
    );
    const jdePartnersData = useSelector((state) => state.myPartners.jdePartnersData);

    React.useEffect(() => {
        // console.log('partnerEditDict', partnerEdits);
        setData(jdePartnersData);
        //roles in data table
        let rolesArr = [];
        for (let i = 0; i < jdePartnersData.length; i++) {
            rolesArr.push(parseInt(jdePartnersData[i]['PartnerFunctionTypeId']));
        }
        //valid Roles to be shown as drop down for Add Partner
        if (jdePartnersData.length > 0) {
            let jdeRoles = ApolloPartnersRoleType;
            // const dropdownRoles=jdeRoles.filter(role=> !rolesArr.includes(role.Id) );
            //allow only bill to role.
            const dropdownRoles = jdeRoles.filter((role) => role.Id == 3);
            setDropdownRoles(dropdownRoles);
        }
    }, [jdePartnersData]);

    React.useEffect(() => {
        //roles in data table
        // let rolesArr=[]
        // for (let i = 0; i < data.length; i++) {
        //     rolesArr.push(parseInt(data[i]['PartnerFunctionTypeId']))
        // }
        // setRoles(rolesArr)
        //validRoles to be shown as drop for Add Partner
    }, [data]);

    React.useEffect(() => {
        let selected = [];
        for (let i = 0; i < selectedRows.length; i++) {
            selected.push(selectedRows[i]['original']);
        }
        let difference = data.filter((x) => !selected.includes(x));
        if (difference.length > 0) {
            setData((arr) => [...difference]);
        }
        setdel(false);
    }, [isdel]);

    React.useEffect(() => {
        // console.log('partnerEditDict *** ---', partnerEdits)
        setPayloadp(partnerEdits);
        console.log('pat edit--', partnerEdits);

        let hasRemovePayload = false,
            disable = false,
            hasTwoLinked = results.length > 1;
        if (hasTwoLinked) {
            if (partnerEdits.length > 0) {
                disable = partnerEdits.some((el) => el.PartnersOperation == 'Add');
                if (partnerEdits[0]['PartnersOperation'] == 'Remove') disable = false;
            }
            if (partnerEdits.length == 0 || partnerEdits.length == 2) {
                disable = true;
            }
        }

        showAddPartner(disable);
        disableSaveButton(!partnerEdits.some((el) => el.PartnersOperation == 'Add'));
    }, [partnerEdits]);

    React.useEffect(() => {
        // console.log('partnerEditDict', partnerEdits);
        if (addData) addNewPartnerData(addData);
    }, [addData]);

    // We need to keep the table from resetting the pageIndex when we
    // Update data. So we can keep track of that flag with a ref.

    // When our cell renderer calls updateMyData, we'll use
    // the rowIndex, columnId and new value to update the
    // original data

    const updateMyData = (rowIndex, cellrow, columnId, value) => {
        // We also turn on the flag to not reset the page
        let twoRolesRemoved = false;
        setSkipPageReset(true);
        var partnerOperation = '';
        const newData = data.map((row, index) => {
            if (index === rowIndex) {
                let editedData = {
                    ...data[rowIndex],
                    [columnId]: value,
                };
                let sData = originalTableData[rowIndex];
                if (
                    originalTableData[rowIndex] &&
                    columnId === 'UnPartner' &&
                    value === false
                ) {
                    editedData = {
                        ...sData,
                    };
                }

                if (columnId === 'UnPartner' && value === true) {
                    editedData['PartnersOperation'] = 'Remove';
                    editedData['UnPartner'] = true;
                    editedData['PartnerRoleTypeId'] = row['CustomerRoleTypeId'];
                    partnerOperation = 'Remove';
                } else if (results[rowIndex] === undefined || cellrow.original.newRow) {
                    partnerOperation = 'Add';
                    editedData['PartnersOperation'] = 'Add';
                }

                let wfnum = row['CustomerNumberOrWorkflowNumber'] || row['PartnerNumber'];
                wfnum = wfnum.replace(/\b0+/g, ''); //trim leading zeros
                let partnerEditDict = {
                    rowIndex,
                    PartnersOperation: partnerOperation,
                    PartnerFunctionTypeId: row.PartnerFunctionTypeId,
                    CustomerNumberOrWorkflowNumber: wfnum,
                    PartnerRoleTypeId:
                        row['PartnerCustomerRoleTypeId'] || row['CustomerRoleTypeId'],
                };
                if (partnerOperation == 'Add') {
                    if (columnId == 'PartnerNumber') {
                        partnerEditDict['CustomerNumberOrWorkflowNumber'] = value.replace(
                            /\b0+/g,
                            ''
                        );
                    } else {
                        partnerEditDict[
                            'CustomerNumberOrWorkflowNumber'
                        ] = cellrow.original.WorkflowOrCustomerNumber.replace(
                            /\b0+/g,
                            ''
                        );
                    }
                }
                // console.log(partnerEditDict, cellrow);

                if (columnId == 'PartnerNumber') {
                    partnerEditDict['CustomerNumberOrWorkflowNumber'] = value.replace(
                        /\b0+/g,
                        ''
                    );
                    editedData['PartnerNumber'] = value;
                }

                if (partnerOperation == 'Remove' && columnId == 'UnPartner') {
                    partnerEditDict['UnPartner'] = value;
                }

                // console.log(partnerEditDict, editedData);
                if (
                    (results[rowIndex] === undefined ||
                        partnerOperation === 'Add' ||
                        cellrow.original.newRow) &&
                    columnId == 'PartnerNumber' &&
                    value.length != 0
                ) {
                    //on adding new row , trigger api to get partner info detail based partner no
                    // console.log('--99999--',results,rowIndex,results[rowIndex] )
                    console.log('calls ----');
                    dispatch(
                        searchJdePartner(
                            rowIndex,
                            columnId,
                            value,
                            cellrow,
                            data,
                            partnerOperation
                        )
                    );
                }

                if (
                    partnerOperation === 'Add' &&
                    (columnId == 'PartnerFunctionTypeId' || columnId == 'UnPartner')
                ) {
                    partnerEditDict[columnId] = value;
                }

                //remove newly added row, if the data already exist in the grid.
                let isDuplicate = false,
                    duplicateRowIndex = null;
                if (cellrow.original.newRow) {
                    let newAddedRow = cellrow.original;
                    if (columnId === 'PartnerFunctionTypeId') {
                        //check if partnerno has input formadata data
                        if (newAddedRow['WorkflowOrCustomerNumber']) {
                            isDuplicate = results.some(
                                (sourcerow) =>
                                    sourcerow.PartnerNumber ===
                                        partnerEditDict[
                                            'CustomerNumberOrWorkflowNumber'
                                        ].trim() &&
                                    sourcerow.PartnerFunctionTypeId ===
                                        partnerEditDict[
                                            'PartnerFunctionTypeId'
                                        ].toString()
                            );
                            duplicateRowIndex = index;
                            editedData = isDuplicate ? {} : editedData;
                            // console.log('^^^^^^^^^^^^^', isDuplicate);
                        }
                    }
                }

                setPartnerEdits((partnerarray) => {
                    let copy = [...partnerarray, partnerEditDict];
                    partnerarray.map((partnerrow, index) => {
                        if (partnerrow.rowIndex === partnerEditDict.rowIndex) {
                            // console.log('partnerrow', partnerrow);
                            //if there is any edit operation done on a new row added, the partneroperation shd retain as 'ADD'
                            if (partnerrow.PartnersOperation == 'Add')
                                partnerEditDict['PartnersOperation'] = 'Add';

                            copy.splice(index, 1);
                        }

                        // //when ever you undo remove partner , remove the obj from the array Copy.
                        if (columnId === 'UnPartner' && value == false) {
                            copy = copy.filter(
                                (editedDict) => editedDict.rowIndex != rowIndex
                            );
                        }

                        //remove if new added row is redundant
                        if (
                            partnerrow.rowIndex === partnerEditDict.rowIndex &&
                            isDuplicate
                        ) {
                            copy.splice(index, 1);
                            setWarningDisplay(true);
                        }
                    });

                    console.log('..copy..', copy);

                    return [...copy];

                    // add only delta changes
                    // if(results[partnerEditDict.rowIndex][columnId]===value)
                    //     return[...partnerarray]
                    // else  return [...partnerarray, partnerEditDict];
                });

                return editedData;
            }

            return row;
        });

        var mnewData = newData.filter((value) => Object.keys(value).length !== 0);
        var setRedundantWarning = newData.some((value) => Object.keys(value).length == 0);
        // console.log('setRedundantWarning', setRedundantWarning);
        // remove if 'newly added partners' are redundant
        let finalPartnerEdits = mnewData.filter(
            (ele, ind) =>
                ind ===
                mnewData.findIndex(
                    (elem) =>
                        elem.PartnersOperation === ele.PartnersOperation &&
                        elem.PartnerNumber === ele.PartnerNumber &&
                        elem.PartnerFunctionTypeId === ele.PartnerFunctionTypeId
                )
        );
        setRedundantWarning = mnewData.length != finalPartnerEdits.length;
        console.log('mnewData', finalPartnerEdits);
        // console.log('source data', results);
        changeHandler(finalPartnerEdits);
        setData((ary) => [...finalPartnerEdits]);
        setTableData([...finalPartnerEdits]);
        // setPartnerEdits([...finalPartnerEdits]);
        if (setRedundantWarning) {
            setWarningDisplay(setRedundantWarning);
            console.log('-----', setRedundantWarning);
        } else {
            console.log('----- else cond', setRedundantWarning);
        }
    };

    const selectRowHandler = (rows, rowIds = {}) => {
        setSelectedRows(rows);
        setSelectedRowIds(rowIds);
    };

    const addNewPartnerData = (partner) => {
        setData((arr) => [...arr, partner]);
        setdel(false);
        setSelectedRows([]);
        setSelectedRowIds([]);
        // console.log('ddd',data)
    };

    const removePartner = (row) => {
        console.log('row on remove', row);
        let index = partnerEdits.findIndex(
            (x) =>
                (x.CustomerNumberOrWorkflowNumber ==
                    row.original.WorkflowOrCustomerNumber &&
                    (row.original.newRow ||
                        x.PartnerFunctionTypeId == row.original.PartnerFunctionTypeId)) ||
                (x.rowIndex && x.rowIndex == row.id)
        );
        if (index >= 0) partnerEdits.splice(index, 1);
        setPartnerEdits([...partnerEdits]);
        data.splice(row.id, 1);
        setData([...data]);
        setTableData([...data]);
        if (index < 0) showAddPartner(false);
        // setdel(true);
    };
    console.log(data, '----helo test data');
    return (
        <div>
            <CssBaseline />
            <CreatePartnersEnhancedTable
                columns={columns}
                data={data}
                setData={setData}
                updateMyData={updateMyData}
                skipPageReset={skipPageReset}
                selectRowHandler={selectRowHandler}
                addNewPartnerData={addNewPartnerData}
                removePartner={removePartner}
                ApolloPartnersRoleType={ApolloPartnersRoleType}
                dropdownRoles={dropdownRoles}
                showWarning={warning}
                existingRolesInTable={existingRolesInTable}
                formData={filterData}
            />
        </div>
    );
};

export default CreateJdePartnersGrid;
