import {
    GET_STATIC_DATA_FAIL,
    GET_STATIC_DATA_REQUEST,
    GET_STATIC_DATA_SUCCESS,
} from '../../constants/ActionTypes';

export const getStaticRequest = (ids) => {
    return {
        type: GET_STATIC_DATA_REQUEST,
        payload: ids,
    };
};

export const getStaticSuccess = (data) => {
    return {
        type: GET_STATIC_DATA_SUCCESS,
        payload: data,
    };
};

export const getStaticFail = () => {
    return {
        type: GET_STATIC_DATA_FAIL,
    };
};
