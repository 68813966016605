import {
    GET_WORKFLOW,
    GET_WORKFLOW_SUCCESS,
    GET_WORKFLOW_FAILURE,
    GET_STATUS_BAR_DATA,
    SET_STATUS_BAR_DATA,
    GET_FUCTIONAL_GROUP_DATA,
    SET_FUCTIONAL_GROUP_DATA,
    UPDATE_TASK_STATUS,
    OTHER_REQUEST,
    OTHER_REQUEST_SUCCESS,
    OTHER_REQUEST_FAILED,
    GET_TEAM_USERLIST,
    GET_TEAM_USERLIST_SUCCESS,
    ASSIGN_USER,
    ASSIGN_USER_SUCCESS,
    UN_ASSIGN_USER,
    UN_ASSIGN_USER_SUCCESS,
    SELECT_WORKFLOW,
} from '../../constants/ActionTypes';

export const getWorkflows = (data) => {
    return {
        type: GET_WORKFLOW,
        payload: data,
    };
};

export const getWorkflowsSuccess = (res) => {
    return {
        type: GET_WORKFLOW_SUCCESS,
        payload: res,
    };
};

export const getWorkflowsFailed = (resp) => {
    return {
        type: GET_WORKFLOW_FAILURE,
        payload: resp,
    };
};

export const getTeamUsers = (team) => {
    return {
        type: GET_TEAM_USERLIST,
        payload: team,
    };
};

export const setTeamUsers = (resp) => {
    return {
        type: GET_TEAM_USERLIST_SUCCESS,
        payload: resp,
    };
};

export const assignUser = (user) => {
    return {
        type: ASSIGN_USER,
        payload: user,
    };
};

export const assignUserSuccess = (user) => {
    return {
        type: ASSIGN_USER_SUCCESS,
        payload: user,
    };
};

export const unAssignUser = (user) => {
    return {
        type: UN_ASSIGN_USER,
        payload: user,
    };
};

export const unAssignUserSuccess = (user) => {
    return {
        type: UN_ASSIGN_USER_SUCCESS,
        payload: user,
    };
};

export const getFunctionalGroupData = (data) => {
    return {
        type: GET_FUCTIONAL_GROUP_DATA,
        payload: data,
    };
};
export const setFunctionalGroupData = (data) => {
    return {
        type: SET_FUCTIONAL_GROUP_DATA,
        payload: data,
    };
};

export const getStatusBarData = (data, parseCredit) => {
    return {
        type: GET_STATUS_BAR_DATA,
        payload: data,
        parseCredit,
    };
};
export const setStatusBarData = (data) => {
    return {
        type: SET_STATUS_BAR_DATA,
        payload: data,
    };
};

export const updateTaskStatus = (data) => {
    return {
        type: UPDATE_TASK_STATUS,
        payload: data,
    };
};

export const otherRequest = (data) => {
    console.log(data);
    return {
        type: OTHER_REQUEST,
        payload: data,
    };
};

export const otherRequestSuccess = (data) => {
    return {
        type: OTHER_REQUEST_SUCCESS,
        payload: data,
    };
};

export const otherRequestFailed = (data) => {
    return {
        type: OTHER_REQUEST_FAILED,
        payload: data,
    };
};

export const selectWorkflow = (data) => {
    return {
        type: SELECT_WORKFLOW,
        payload: data,
    };
};
