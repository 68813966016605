import React, { Component } from 'react';
import { FormInput, FormSelect } from './form';
import { fetchCustomerMasterDropDownData } from '../redux/DropDownDatas';

class UserAssignmentSelector extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropDownDatas: {},
            fvalue: '',
        };
    }
    render() {
        const { inputProps, readOnly } = this.props;

        let arrayOfData = this.props.arrayOfData;
        let options =
            arrayOfData !== undefined &&
            arrayOfData.map((data) => (
                <option
                    key={data.UserId}
                    value={data.UserName}
                    disabled={inputProps ? inputProps.disabled : false}>
                    {' '}
                    {data.FormattedUserName}{' '}
                </option>
            ));
        //if isRequired is false then show choose from option on dropdown list
        let isRequiredValue = this.props.isRequired ? this.props.isRequired : false;
        let displayChoose = isRequiredValue ? 'none' : 'flex';
        return (
            <>
                {!readOnly ? (
                    <FormSelect
                        label={this.props.label}
                        name={this.props.name}
                        value={this.props.value}
                        hide={this.props.hide}
                        small={this.props.small}
                        error={this.props.formErrors ? this.props.formErrors : null}
                        required={this.props.isRequired}
                        onChange={this.props.onSelect}
                        variant="solid"
                        {...inputProps}>
                        <option style={{ display: displayChoose }} value={0}>
                            Select...
                        </option>
                        {options}
                    </FormSelect>
                ) : (
                    <FormInput
                        px="25px"
                        flex={1 / 4}
                        width={this.props.width}
                        label={this.props.label}
                        hide={this.props.hide}
                        name={this.props.name}
                        small={this.props.small}
                        variant="outline"
                        inline
                        type="text"
                        value={this.state.fvalue}
                        readOnly
                        showInlineRequired={this.props.showInlineRequired}
                    />
                )}
            </>
        );
    }
}

export default UserAssignmentSelector;
