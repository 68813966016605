import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import MyTaskJdePartnerEnhancedTable from './MyTaskJdePartnerEnhancedTable';
import { searchJdePartner } from '../appRedux/actions/Partners';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Flex, Button } from './common';
import { result } from 'lodash';
import { MaterialIcons } from '@expo/vector-icons';

const MyTaskJdePartnersGrid = ({
    dataFull: parentGridData = [],
    data: results = [],
    columns: columnConfig = [],
    openModal,
    onChange: changeHandler = (data) => {},
    onPasteUpdate,
    setPayloadp,
    onClickSave,
    readOnly,
    allowEdit,
    systemFields,
    ApolloPartnersRoleType,
    defaultPartnerEdits: defaultEdits = [],
    isSoldToPresent,
    setTableData,
    screen,
    location,
    allowApprove,
    originalTableData,
    originalEditDict,
    isDataEdited,
    selectFiles,
}) => {
    let editDataFromApi = [...defaultEdits];
    console.log('editDataFromApi', editDataFromApi);
    const dispatch = useDispatch();
    const columns = React.useMemo(() => columnConfig, [columnConfig]);
    const [data, setData] = React.useState(results || []);
    const [selectedRows, setSelectedRows] = React.useState([]);
    const [selectedRowIds, setSelectedRowIds] = React.useState({});
    const [skipPageReset, setSkipPageReset] = React.useState(false);
    const [isdel, setdel] = React.useState(false);
    const [partnerEdits, setPartnerEdits] = React.useState([...editDataFromApi] || []);
    const [save, setSave] = React.useState([false]);
    const [reject, setReject] = React.useState([false]);
    const [warning, setWarningDisplay] = React.useState(false);
    const [showButton, setShowButton] = React.useState(false);

    const partnerDetails = useSelector((state) => state.myPartners.partnerDetails);

    const [hasIsDefaultSetInGrid, sethasIsDefaultSetInGrid] = React.useState(
        results.some((sourcedataRow) => sourcedataRow.IsDefaultPartner === true)
    );

    const wfApolloPartnersData = useSelector(
        (state) => state.myPartners.wfApolloPartnersData
    );

    React.useEffect(() => {
        let selected = [];
        for (let i = 0; i < selectedRows.length; i++) {
            selected.push(selectedRows[i]['original']);
        }
        let difference = data.filter((x) => !selected.includes(x));
        if (difference.length > 0) {
            setData((arr) => [...difference]);
        }
        setdel(false);
    }, [isdel]);

    React.useEffect(() => {
        if (editDataFromApi.length > 0 && partnerEdits.length == 0 && !isDataEdited) {
            setPartnerEdits(editDataFromApi);
        }
    }, [editDataFromApi]);

    React.useEffect(() => {
        if (save === true) {
            // console.log('selectedRows', selectedRows);
            setSave(false);
            onClickSave(selectedRows, true);
        }
    }, [save]);
    React.useEffect(() => {
        // console.log('fetched results ===============', results);
        if (results.length > 0) {
            setData(results);
            if (results && results[1] && results[1]['PartnerCustomerRoleTypeId']) {
                let pedit = partnerEdits[0];

                setPartnerEdits([
                    {
                        ...pedit,
                        PartnerRoleTypeId: results[1]['PartnerCustomerRoleTypeId'],
                    },
                ]);
            }
        }

        // onClickSave(selectedRows, true);
    }, [results]);
    React.useEffect(() => {
        if (reject === true) {
            // console.log('selectedRows', selectedRows);
            setReject(false);
            onClickSave(selectedRows, false);
        }
    }, [reject]);

    React.useEffect(() => {
        console.log('part edit', partnerEdits);
        if (partnerEdits.length > 0) setPayloadp(partnerEdits);
        let hasRemoveEdit = false;
        let hasAdd = partnerEdits.some((edit) => edit.PartnersOperation == 'Add');
        if (editDataFromApi.length == 2) {
            hasRemoveEdit = partnerEdits.some(
                (edit) => edit.PartnersOperation == 'Remove'
            );
            if (hasRemoveEdit == false && hasAdd == false) {
                setShowButton(false);
            } else {
                setShowButton(!hasAdd);
            }
        } else {
            setShowButton(!hasAdd);
        }
    }, [partnerEdits]);

    React.useEffect(() => {
        //if any one IsDefaultPartner is updated to true on the grid, disable other selection
        sethasIsDefaultSetInGrid(data.some((row) => row.IsDefaultPartner === true));
    }, [data]);

    // React.useEffect(() => {
    //     console.log('partnerEditDict', partnerEdits);
    //     setData(wfApolloPartnersData.partnerEdits);
    // }, [wfApolloPartnersData]);

    // We need to keep the table from resetting the pageIndex when we
    // Update data. So we can keep track of that flag with a ref.

    // When our cell renderer calls updateMyData, we'll use
    // the rowIndex, columnId and new value to update the
    // original data
    const updateMyData = (rowIndex, cellrow, columnId, value) => {
        // We also turn on the flag to not reset the page
        // console.log('celrow---', cellrow, rowIndex, columnId, value);
        let twoRolesRemoved = false;
        let partnerOperation = 'Edit';
        let partnerSearchFields = {};
        let editedData = {};
        setSkipPageReset(true);
        // console.log(rowIndex, cellrow, columnId, value);
        let indexToFind = '';
        // Check whether edit is already present or not
        indexToFind = partnerEdits.findIndex(
            (x) =>
                x.CustomerNumberOrWorkflowNumber ==
                    cellrow.original.CustomerNumberOrWorkflowNumber &&
                x.PartnerFunctionTypeId == cellrow.original.PartnerFunctionTypeId
        );
        // console.log(
        //     columnId,
        //     '=====',
        //     indexToFind,
        //     '==========',
        //     indexToFind && indexToFind >= 0,
        //     '======= if cond'
        // );
        let newData = [];
        //if partner edit data exists then edit only the partner edits data instead of creating a new edit obj
        if (indexToFind >= 0) {
            let newPartnerEdits = [...partnerEdits];
            let partnerEditObj = { ...newPartnerEdits[indexToFind] };
            newData = [...data];
            partnerEditObj[columnId] = value;
            if (columnId == 'UnPartner') {
                partnerEditObj['PartnersOperation'] =
                    partnerEditObj['PartnersOperation'] == 'Add'
                        ? 'Add'
                        : partnerEditObj['PartnersOperation'] == 'Remove'
                        ? 'Edit'
                        : 'Remove';
            }
            if (columnId == 'IsDefaultPartner') {
                let checkOperation = results.findIndex((x) => {
                    return (
                        x.CustomerNumberOrWorkflowNumber ==
                            cellrow.original.CustomerNumberOrWorkflowNumber &&
                        x.PartnerFunctionTypeId ==
                            cellrow.original.PartnerFunctionTypeId &&
                        columnId == 'IsDefaultPartner' &&
                        (x.IsDefault == value || x.IsDefaultPartner == value)
                    );
                });
                partnerEditObj['PartnersOperation'] =
                    partnerEditObj['PartnersOperation'] == 'Add' ? 'Add' : 'Edit';
                partnerEditObj['IsDefault'] = value;
                partnerEditObj['IsDefaultPartner'] = value;
            }
            console.log('partner edit obj', partnerEditObj['PartnersOperation']);
            // console.log('Checking for pre existing data');
            if (
                originalEditDict.length > 0 &&
                (columnId == 'UnPartner' || columnId == 'IsDefaultPartner')
            ) {
                // console.log(results,'-------')
                let checkOperation = results.findIndex((x) => {
                    return (
                        x.CustomerNumberOrWorkflowNumber ==
                            cellrow.original.CustomerNumberOrWorkflowNumber &&
                        x.PartnerFunctionTypeId ==
                            cellrow.original.PartnerFunctionTypeId &&
                        ((columnId == 'IsDefaultPartner' &&
                            (x.IsDefault == value || x.IsDefaultPartner == value)) ||
                            (columnId == 'UnPartner' && x.UnPartner == value))
                    );
                });
                let checkIndex = originalEditDict.findIndex((x) => {
                    return (
                        (x.CustomerNumberOrWorkflowNumber ==
                            cellrow.original.CustomerNumberOrWorkflowNumber &&
                            x.PartnerFunctionTypeId ==
                                cellrow.original.PartnerFunctionTypeId &&
                            ((columnId == 'IsDefaultPartner' &&
                                (x.IsDefault == value || x.IsDefaultPartner == value)) ||
                                (columnId == 'UnPartner' && x.UnPartner == value))) ||
                        (columnId == 'IsDefaultPartner' &&
                            cellrow.original.PartnersOperation == 'Add')
                    );
                });
                console.log('check index=======', checkIndex, originalEditDict);
                if (checkOperation >= 0 || checkIndex >= 0) {
                    if (columnId == 'IsDefaultPartner')
                        partnerEditObj['PartnersOperation'] =
                            partnerEditObj['PartnersOperation'] == 'Add' || checkIndex < 0
                                ? 'Add'
                                : value
                                ? 'Edit'
                                : '';
                    if (columnId == 'UnPartner')
                        partnerEditObj['PartnersOperation'] = value ? 'Remove' : '';
                } else {
                    if (columnId == 'IsDefaultPartner') {
                        partnerEditObj['PartnersOperation'] = value ? 'Edit' : '';
                    }
                    if (columnId == 'UnPartner')
                        partnerEditObj['PartnersOperation'] = value ? 'Remove' : '';
                }
            }
            console.log('partner edit obj', partnerEditObj);
            newPartnerEdits[indexToFind] = { ...partnerEditObj };
            newData[rowIndex] = { ...newData[rowIndex], ...partnerEditObj };
            // console.log(newData, newPartnerEdits,"=============");
            setData([...newData]);
            setPartnerEdits([...newPartnerEdits]);
        } else {
            newData = data.map((row, index) => {
                if (index === rowIndex) {
                    editedData = {
                        ...data[rowIndex],
                        [columnId]: value,
                    };
                    partnerOperation = 'Edit';

                    if (columnId === 'UnPartner') {
                        // console.log(value);
                        if (value == 'True' || value == true) {
                            partnerOperation = 'Remove';
                            editedData['PartnersOperation'] = 'Remove';
                        } else {
                            partnerOperation = '';
                            editedData['PartnersOperation'] = '';
                        }
                    } else if (
                        results[rowIndex] === undefined ||
                        cellrow.original.newRow
                    ) {
                        partnerOperation = 'Add';
                        editedData['PartnersOperation'] = 'Add';
                    }
                    console.log('editedData', editedData);
                    //build create request datastructure
                    // console.log(row, '=============', cellrow.original);
                    let wfnum =
                        row['CustomerNumberOrWorkflowNumber'] ||
                        row['WorkflowOrCustomerNumber'] ||
                        row['PartnerNumber'] ||
                        '';
                    wfnum = wfnum.replace(/\b0+/g, ''); //trim leading zeros
                    console.log('row data--', row);
                    let partnerEditDict = {
                        rowIndex,
                        PartnersOperation: partnerOperation,
                        CustomerNumberOrWorkflowNumber: wfnum,
                        PartnerFunctionTypeId: row.PartnerFunctionTypeId,
                        PartnerRoleTypeId: row['PartnerCustomerRoleTypeId'],
                    };

                    if (columnId == 'PartnerNumber') {
                        partnerEditDict['CustomerNumberOrWorkflowNumber'] = value;
                        editedData['PartnerNumber'] = value;
                    }

                    // include the column name that is edited on partnereditdict
                    // console.log(partnerEditDict, '============ 1');
                    if (partnerOperation === 'Edit') {
                        editedData['PartnersOperation'] = 'Edit';
                    }

                    if (
                        partnerOperation == 'Edit' ||
                        partnerOperation == 'Add' ||
                        partnerOperation == 'Remove'
                    ) {
                        partnerEditDict[columnId] = value;
                    }

                    partnerSearchFields = {
                        DistributionChannelTypeId: systemFields.DistributionChannelTypeId,
                        DivisionTypeId: systemFields.DivisionTypeId,
                        RoleTypeId: systemFields.RoleTypeId,
                        SalesOrgTypeId: systemFields.SalesOrgTypeId,
                        UserId: localStorage.getItem('userId'),
                        WorkflowOrCustomerNumber: '',
                    };

                    //undo edited data if it matches to source data
                    let sData = originalTableData[rowIndex];
                    console.log(editedData, '===== editeddData');
                    if (
                        sData &&
                        editedData['PartnersOperation'] === 'Edit' &&
                        sData[columnId] === editedData[columnId]
                    ) {
                        editedData['PartnersOperation'] = '';
                    }

                    //remove newly added row, if the data already exist in the grid.
                    let isDuplicate = false,
                        duplicateRowIndex = null;
                    // console.log(cellrow.original);
                    if (cellrow.original.newRow) {
                        let newAddedRow = cellrow.original;
                        if (columnId === 'PartnerFunctionTypeId') {
                            //check if partnerno has input formadata data
                            if (newAddedRow['CustomerNumberOrWorkflowNumber']) {
                                isDuplicate = results.some(
                                    (sourcerow) =>
                                        sourcerow.PartnerNumber ===
                                            (partnerEditDict[
                                                'CustomerNumberOrWorkflowNumber'
                                            ] &&
                                                partnerEditDict[
                                                    'CustomerNumberOrWorkflowNumber'
                                                ].trim()) &&
                                        sourcerow.PartnerFunctionTypeId ===
                                            partnerEditDict[
                                                'PartnerFunctionTypeId'
                                            ].toString() &&
                                        sourcerow.PartnersOperation != 'Remove'
                                );
                                duplicateRowIndex = index;
                                editedData = isDuplicate ? {} : editedData;
                                console.log('^^^^^^^^^^^^^', isDuplicate);
                            }
                        }
                    }

                    setPartnerEdits((partnerarray) => {
                        let copy = [...partnerarray, partnerEditDict];
                        partnerarray.map((partnerrow, index) => {
                            if (partnerrow.rowIndex === partnerEditDict.rowIndex) {
                                //if there is any edit operation done on a new row added, the partneroperation shd retain as 'ADD'
                                if (partnerrow.PartnersOperation == 'Add')
                                    partnerEditDict['PartnersOperation'] = 'Add';

                                copy.splice(index, 1);
                            }
                            //if there are no isDefault to true on source data, and when user undo's the edit, it Should get removed from partner edits
                            if (columnId === 'IsDefaultPartner') {
                                if (
                                    partnerrow.PartnersOperation == 'Edit' &&
                                    partnerrow.rowIndex === partnerEditDict.rowIndex
                                ) {
                                    copy = copy.filter(
                                        (editedDict) =>
                                            editedDict.rowIndex != partnerrow.rowIndex
                                    );
                                }
                            }
                            //remove if new added row is redundant
                            if (
                                partnerrow.rowIndex === partnerEditDict.rowIndex &&
                                isDuplicate
                            ) {
                                copy.splice(index, 1);
                            }
                        });
                        console.log('..copy..', copy);

                        return [...copy];
                    });

                    return editedData;
                }
                return row;
            });
        }

        //NEED TO WORK ON --
        if (
            (results[rowIndex] === undefined ||
                cellrow.original.newRow ||
                cellrow.original.PartnersOperation == 'Add') &&
            columnId == 'PartnerNumber' &&
            value.length != 0
        ) {
            //on adding new row , trigger api to get partner info detail based partner no
            // console.log('--99999--',results,rowIndex,results[rowIndex] )
            // console.log('calls ---------------------');
            dispatch(
                searchJdePartner(
                    rowIndex,
                    columnId,
                    value,
                    cellrow,
                    data,
                    partnerOperation
                )
            );
        }

        var mnewData = newData.filter((value) => Object.keys(value).length !== 0);
        var setRedundantWarning = newData.some((value) => Object.keys(value).length == 0);

        // console.log('source data', newData);
        changeHandler(partnerEdits);
        setData(mnewData);
        setTableData(mnewData);
        if (setRedundantWarning) setWarningDisplay(setRedundantWarning);
    };

    const selectRowHandler = (rows, rowIds = {}) => {
        // setSelectedRows(rows);
        // setSelectedRowIds(rowIds);
    };

    const addNewPartnerData = (partner) => {
        setData((arr) => [...arr, partner]);
        setdel(false);
        setSelectedRows([]);
        setSelectedRowIds([]);
        setShowButton(false);
        // console.log('ddd',data)
    };

    const removePartner = (row) => {
        if (row && (row.original.newRow || row.original.PartnersOperation == 'Add')) {
            data.splice(row.id, 1);
            let index = partnerEdits.findIndex(
                (x) =>
                    (x.CustomerNumberOrWorkflowNumber ==
                        row.original.CustomerNumberOrWorkflowNumber &&
                        (row.original.newRow ||
                            x.PartnerFunctionTypeId ==
                                row.original.PartnerFunctionTypeId)) ||
                    (x.rowIndex && x.rowIndex == row.id)
            );

            // let index = partnerEdits.findIndex(
            //     (x) =>  (x.rowIndex && x.rowIndex == row.id)
            // );
            if (index >= 0) partnerEdits.splice(index, 1);
            console.log('--pe--', index, row, partnerEdits);
            setShowButton(true);
        } else {
            data[row.id]['DeleteClicked'] = data[row.id]['DeleteClicked']
                ? !data[row.id]['DeleteClicked']
                : true;
            if (
                (data[row.id]['DeleteClicked'] == undefined ||
                    data[row.id]['DeleteClicked']) &&
                data[row.id]['PartnersOperation']
            ) {
                let index = partnerEdits.findIndex(
                    (x) =>
                        x.CustomerNumberOrWorkflowNumber ==
                            row.original.CustomerNumberOrWorkflowNumber &&
                        x.PartnerFunctionTypeId == row.original.PartnerFunctionTypeId
                );
                if (index >= 0) partnerEdits.splice(index, 1);
            } else {
                let index = defaultEdits.findIndex(
                    (x) =>
                        x.CustomerNumberOrWorkflowNumber ==
                            row.original.CustomerNumberOrWorkflowNumber &&
                        x.PartnerFunctionTypeId == row.original.PartnerFunctionTypeId
                );
                if (index >= 0) partnerEdits.splice(index, 0, row);
            }
        }
        setPartnerEdits([...partnerEdits]);
        setData([...data]);
        // setdel(true);
    };

    const checkChangeData = (editData) => {
        let flag = false;
        if (editData.length > 0) {
            editData.forEach((edits) => {
                if (edits.PartnersOperation.length != 0) {
                    flag = true;
                }
            });
        }
        return flag;
    };

    return (
        <div style={{ marginTop: '5%' }}>
            <div>
                <CssBaseline />
                <MyTaskJdePartnerEnhancedTable
                    columns={columns}
                    data={data}
                    setData={setData}
                    updateMyData={updateMyData}
                    skipPageReset={skipPageReset}
                    selectRowHandler={selectRowHandler}
                    addNewPartnerData={addNewPartnerData}
                    hasIsDefaultSetInGrid={hasIsDefaultSetInGrid}
                    removePartner={removePartner}
                    readOnly={readOnly}
                    systemFields={systemFields}
                    ApolloPartnersRoleType={ApolloPartnersRoleType}
                    isSoldToPresent={isSoldToPresent}
                    showWarning={warning}
                    isEditAllowed={
                        allowApprove &&
                        systemFields &&
                        systemFields.WorkflowTaskStateTypeId == 2 &&
                        screen == 'mytask' &&
                        location &&
                        location.state.ProcessId == 8
                    }
                />
            </div>
            {!readOnly &&
                systemFields &&
                systemFields.WorkflowTaskStateTypeId == 2 &&
                screen == 'mytask' && (
                    <>
                        <div
                            style={{
                                flexDirection: 'row',
                                flexBasis: 'auto',
                                display: 'flex',
                            }}>
                            <Button
                                disabled={
                                    !(
                                        allowApprove &&
                                        systemFields &&
                                        systemFields.WorkflowTaskStateTypeId == 2 &&
                                        screen == 'mytask' &&
                                        location &&
                                        location.state.ProcessId == 8 &&
                                        showButton
                                    ) ||
                                    (systemFields &&
                                        systemFields.WorkflowStateTypeId != 2)
                                }
                                onPress={() =>
                                    addNewPartnerData({
                                        CustomerNumberOrWorkflowNumber: '',
                                        PartnerCustomerNumber: '',
                                        MdmNumber: '',
                                        PartnerCustomerName: '',
                                        PartnerCustomerAddress: '',
                                        PartnersOperation: 'Add',
                                        PartnerFunctionRole: '',
                                        PartnerFunctionTypeId: '',
                                        IsDefault: false,
                                        newRow: true,
                                        UnPartner: false,
                                        IsDefaultPartner: false,
                                    })
                                }
                                style={{ maxWidth: '120px' }}
                                title="Add Partner"
                            />
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    width: '90%',
                                    justifyContent: 'flex-end',
                                }}>
                                {systemFields &&
                                    systemFields.WorkflowTaskStateTypeId == 2 && (
                                        <>
                                            <label
                                                htmlFor="file-upload"
                                                className="custom-file-upload">
                                                <MaterialIcons
                                                    name="attach-file"
                                                    size={18}
                                                    color="#fff"
                                                />
                                            </label>
                                            <input
                                                id="file-upload"
                                                key={Date.now()}
                                                type="file"
                                                onChange={selectFiles}
                                                multiple
                                                disabled={
                                                    (systemFields &&
                                                        systemFields.WorkflowTaskStateTypeId !=
                                                            2 &&
                                                        results &&
                                                        results.length === 0) ||
                                                    partnerEdits.length === 0
                                                }
                                            />
                                        </>
                                    )}
                                <Button
                                    disabled={
                                        // isDataEdited &&
                                        allowApprove
                                            ? checkChangeData(partnerEdits) &&
                                              systemFields &&
                                              systemFields.WorkflowTaskStateTypeId == 2 &&
                                              systemFields.WorkflowStateTypeId == 2
                                                ? false
                                                : true
                                            : true
                                    }
                                    onPress={() => setSave(true)}
                                    title="Approve"
                                />
                                <Button
                                    disabled={
                                        // isDataEdited &&
                                        allowApprove
                                            ? systemFields &&
                                              systemFields.WorkflowTaskStateTypeId == 2 &&
                                              systemFields.WorkflowStateTypeId != 2
                                                ? true
                                                : false
                                            : true
                                    }
                                    onPress={() => setReject(true)}
                                    title="Reject"
                                />
                            </div>
                        </div>
                    </>
                )}
        </div>
    );
};

export default MyTaskJdePartnersGrid;
