import * as yup from 'yup';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as Moment from 'moment';
import { otherwise } from 'ramda';

export const FieldValidationRoleType = {
    1: 'soldto',
    2: 'shipto',
    3: 'payer',
    4: 'billto',
    5: 'salesrep',
    6: 'dropship',
    99: 'global',
};

export const rejectRules = yup.object().shape({
    RejectionReason: yup.string().required('*this field is required'),
});

export const rejectPartnerRules = yup.object().shape({
    RejectionReason: yup.string().required('*this field is required'),
});

export const yupglobalMDMFieldRules = yup.object().shape({
    Name1: yup.string().required('this field is required'),
    Name2: yup.string().notRequired().nullable(),
    Name3: yup.string().notRequired().nullable(),
    Name4: yup.string().notRequired().nullable(),
    Street: yup.string().required('this field is required'),
    Street2: yup.string().notRequired().nullable(),
    City: yup.string().required('this field is required').max(35),
    Region: yup.string().when('Country', {
        is: (val) => val === 'US',
        then: yup.string().required('this field is required').max(3),
        otherwise: yup.string().notRequired(),
    }),
    Country: yup.string().required('this field is required'),
    PostalCode: yup.string().when(['Country', 'SystemTypeId'], {
        is: (val, systemTypeId) => (val === 'US' || val === 'CA') && systemTypeId != 5,
        then: yup.string().required('this field is required'),
        otherwise: yup.string().notRequired(),
    }),
    Telephone: yup.string().nullable(),
    Fax: yup.string().nullable(),
    Email: yup.string().nullable().notRequired().email(),
    DunsNumber: yup.string().max(9),
    SicCode4: yup.string(),
    SicCode6: yup.string(),
    SicCode8: yup.string(),
    NaicsCode: yup.string().max(10),
    CategoryTypeId: yup.number().required('this field is required'),
    TaxJurisdiction: yup.string().required('this field is required'),
    MarketingSegmentationTypeId: yup.string().required('this field is required'),
    FamilyId: yup.string().min(10),
    FamilyName: yup.string().max(250),
});

export const otherRequestRules = yup.object().shape({
    WorkflowTitle: yup.string().required('this field is required').max(40),
    SystemTypeId: yup.number().required('this field is required').label('System'),
    EffectiveDate: yup.date().required('this field is required'),
});

export const updateGlobalMDMFieldRules = yup.object().shape({
    WorkflowTitle: yup.string().required(),
    Name1: yup.string().max(35),
    Street: yup.string().min(3).max(35),
    City: yup.string().min(2).max(35),
    Region: yup.string().min(1).max(3),
    PostalCode: yup.string().min(1),
    Country: yup.string().min(1).max(3),
    Telephone: yup.string().nullable(),
    Fax: yup.string().nullable(),
    Email: yup.string().max(241).nullable().notRequired().email(),
    CategoryTypeId: yup.number().min(1),
    TaxJurisdiction: yup.string().min(1),
    DunsNumber: yup.string().max(9),
    SicCode4: yup.string(),
    SicCode6: yup.string(),
    SicCode8: yup.string(),
    NaicsCode: yup.string().max(10),
    FamilyId: yup.string().min(10),
    FamilyName: yup.string().max(250),
});

export const disabled_mdmFieldsRules = yup.object().shape({
    WorkflowTitle: yup.string().required(),
    SearchTerm1: yup.string().max(20),
    SearchTerm2: yup.string().max(20),
    TransporationZone: yup.string().max(10),
    TaxNumber2: yup.number().typeError('TaxNumber2 must be a `number` type'),
    SortKey: yup.string().max(3),
    PaymentMethods: yup.string().max(10),
    AcctgClerk: yup.string().max(2),
    OrderCombination: yup.string().nullable(),
    PaymentHistoryRecord: yup.bool().oneOf([true, false]),
    AdditionalNotes: yup.string(),
    displayINCOT2: yup.bool().notRequired(),
    IncoTerms2: yup.string().max(28),
    TaxClassificationTypeId: yup.number(),
    AccountStatement: yup.number(),
    AccountTypeId: yup.number(),
    CustomerGroupTypeId: yup.number(),
    CustomerPriceProcTypeId: yup.number(),
    PriceListTypeId: yup.number(),
    CustomerClassTypeId: yup.number(),
    IndustryCodeTypeId: yup.number(),
    MarketingSegmentationTypeId: yup.number(),
    ReconAccountTypeId: yup.number(),
    SalesOfficeTypeId: yup.number(),
    PpcustProcTypeId: yup.number(),
    DeliveryPriorityTypeId: yup.number(),
    ShippingConditionsTypeId: yup.number(),
    IncoTerms1TypeId: yup.number(),
    AcctAssignmentGroupTypeId: yup.number(),
    ShippingCustomerTypeId: yup.number(),
    CreditLimit: yup.number().typeError('CreditLimit must be a `number` type'),
    IncoTermsTypeId: yup.number(),
    PaymentTermsTypeId: yup.number(),
});

export const mdmFieldsRules = yup.object().shape({
    WorkflowTitle: yup.string().required('this field is required'),
    Role: yup.string(),
    SearchTerm1: yup.string().max(20),
    SearchTerm2: yup.string().max(20),
    TransporationZone: yup.string().when('Role', {
        is: (roleval) => roleval === 'payer' || roleval === 'billto',
        then: yup.string().notRequired(),
        otherwise: yup.string().max(10).required('this field is required'),
    }),
    TaxNumber2: yup
        .number()
        .transform((value) => (isNaN(value) ? 0 : value))
        .typeError('TaxNumber2 must be a `number` type'),
    SortKey: yup.string().max(3),
    PaymentMethods: yup.string().max(10),
    AcctgClerk: yup.string().max(2),
    OrderCombination: yup.string().nullable(),
    PaymentHistoryRecord: yup.string().nullable(),
    AdditionalNotes: yup.string(),
    isRequiredINCOT2: yup.bool().notRequired(),
    IncoTerms2: yup.string().when('isRequiredINCOT2', {
        is: true,
        then: yup.string().required('this field is required').max(28),
        otherwise: yup.string().notRequired(),
    }),
    TaxClassificationTypeId: yup.number().when('Role', {
        is: (roleval) => roleval === 'billto',
        then: yup.number().notRequired(),
        otherwise: yup
            .number()
            .min(1, 'this field is required')
            .required('this field is required'),
    }),
    AccountStatementTypeId: yup
        .number()
        .min(1, 'this field is required')
        .required('this field is required'),
    AccountTypeId: yup.number().required('this field is required'),
    CustomerGroupTypeId: yup.number().when('Role', {
        is: (roleval) => roleval === 'payer' || roleval === 'billto',
        then: yup.number().notRequired(),
        otherwise: yup.number().required('this field is required'),
    }),
    CustomerPriceProcTypeId: yup.string().when('Role', {
        is: (roleval) => roleval === 'payer' || roleval === 'billto',
        then: yup.string().notRequired(),
        otherwise: yup
            .string()
            .min(1, 'this field is required')
            .required('this field is required'),
    }),
    PriceListTypeId: yup.number().when('Role', {
        is: (roleval) => roleval === 'payer' || roleval === 'billto',
        then: yup.number().notRequired(),
        otherwise: yup
            .number()
            .min(1, 'this field is required')
            .required('this field is required'),
    }),
    CustomerClassTypeId: yup
        .number()
        .min(1, 'this field is required')
        .required('this field is required'),
    IndustryCodeTypeId: yup.number().notRequired(),
    MarketingSegmentationTypeId: yup.number().notRequired(),
    ReconAccountTypeId: yup
        .number()
        .min(1, 'this field is required')
        .required('this field is required'),
    SalesOfficeTypeId: yup
        .number()
        .min(1, 'this field is required')
        .required('this field is required'),
    PPCustProcTypeId: yup.number().when('Role', {
        is: (roleval) => roleval === 'payer' || roleval === 'billto',
        then: yup.number().notRequired(),
        otherwise: yup
            .number()
            .min(1, 'this field is required')
            .required('this field is required'),
    }),
    DeliveryPriorityTypeId: yup.number().when('Role', {
        is: (roleval) => roleval === 'payer' || roleval === 'billto',
        then: yup.number().notRequired(),
        otherwise: yup
            .number()
            .min(1, 'this field is required')
            .required('this field is required'),
    }),
    ShippingConditionsTypeId: yup.number().when('Role', {
        is: (roleval) => roleval === 'payer' || roleval === 'billto',
        then: yup.number().notRequired(),
        otherwise: yup
            .number()
            .min(1, 'this field is required')
            .required('this field is required'),
    }),
    IncoTerms1TypeId: yup.number().when('Role', {
        is: (roleval) => roleval === 'payer' || roleval === 'billto',
        then: yup.number().notRequired(),
        otherwise: yup.number().required('this field is required'),
    }),
    AcctAssignmentGroupTypeId: yup.number().when('Role', {
        is: (roleval) => roleval === 'billto',
        then: yup.number().notRequired(),
        otherwise: yup
            .number()
            .min(1, 'this field is required')
            .required('this field is required'),
    }),
    ShippingCustomerTypeId: yup
        .number()
        .min(1, 'this field is required')
        .required('this field is required'),
    CreditLimit: yup.number().typeError('CreditLimit must be a `number` type'),
});

export const extendMdmFieldsRules = yup.object().shape({
    WorkflowTitle: yup.string().required('this field is required'),
    Role: yup.string(),
    SearchTerm1: yup.string().max(20),
    SearchTerm2: yup.string().max(20),
    TransporationZone: yup.string().when('Role', {
        is: (roleval) => roleval === 'payer' || roleval === 'billto',
        then: yup.string().notRequired(),
        otherwise: yup.string().max(10).required('this field is required'),
    }),
    TaxNumber2: yup
        .number()
        .transform((value) => (isNaN(value) ? 0 : value))
        .typeError('TaxNumber2 must be a `number` type'),
    SortKey: yup.string().max(3),
    PaymentMethods: yup.string().max(10),
    AcctgClerk: yup.string().max(2),
    OrderCombination: yup.string().nullable(),
    PaymentHistoryRecord: yup.string().nullable(),
    AdditionalNotes: yup.string(),
    isRequiredINCOT2: yup.bool().notRequired(),
    IncoTerms2: yup.string().when('isRequiredINCOT2', {
        is: true,
        then: yup.string().required('this field is required').max(28),
        otherwise: yup.string().notRequired(),
    }),
    TaxClassificationTypeId: yup.number().when('Role', {
        is: (roleval) => roleval === 'billto',
        then: yup.number().notRequired(),
        otherwise: yup
            .number()
            .min(1, 'this field is required')
            .required('this field is required'),
    }),
    AccountStatementTypeId: yup
        .number()
        .min(1, 'this field is required')
        .required('this field is required'),
    AccountTypeId: yup.number().required('this field is required'),
    CustomerGroupTypeId: yup.number().when('Role', {
        is: (roleval) => roleval === 'payer' || roleval === 'billto',
        then: yup.number().notRequired(),
        otherwise: yup.number().required('this field is required'),
    }),
    CustomerPriceProcTypeId: yup.number().when('Role', {
        is: (roleval) => roleval === 'payer' || roleval === 'billto',
        then: yup.number().notRequired(),
        otherwise: yup
            .number()
            .min(1, 'this field is required')
            .required('this field is required'),
    }),
    PriceListTypeId: yup.number().when('Role', {
        is: (roleval) => roleval === 'payer' || roleval === 'billto',
        then: yup.number().notRequired(),
        otherwise: yup
            .number()
            .min(1, 'this field is required')
            .required('this field is required'),
    }),
    CustomerClassTypeId: yup
        .number()
        .min(1, 'this field is required')
        .required('this field is required'),
    IndustryCodeTypeId: yup.number().notRequired(),
    MarketingSegmentationTypeId: yup.number().when('Role', {
        is: (roleval) => roleval === 'payer' || roleval === 'billto',
        then: yup.number().notRequired(),
        otherwise: yup.number(),
    }),
    ReconAccountTypeId: yup
        .number()
        .min(0, 'this field is required')
        .required('this field is required'),
    SalesOfficeTypeId: yup
        .number()
        .min(1, 'this field is required')
        .required('this field is required'),
    PPCustProcTypeId: yup.number().when('Role', {
        is: (roleval) => roleval === 'payer' || roleval === 'billto',
        then: yup.number().notRequired(),
        otherwise: yup
            .number()
            .min(1, 'this field is required')
            .required('this field is required'),
    }),
    DeliveryPriorityTypeId: yup.number().when('Role', {
        is: (roleval) => roleval === 'payer' || roleval === 'billto',
        then: yup.number().notRequired(),
        otherwise: yup
            .number()
            .min(1, 'this field is required')
            .required('this field is required'),
    }),
    ShippingConditionsTypeId: yup.number().when('Role', {
        is: (roleval) => roleval === 'payer' || roleval === 'billto',
        then: yup.number().notRequired(),
        otherwise: yup
            .number()
            .min(1, 'this field is required')
            .required('this field is required'),
    }),
    IncoTerms1TypeId: yup.number().when('Role', {
        is: (roleval) => roleval === 'payer' || roleval === 'billto',
        then: yup.number().notRequired(),
        otherwise: yup.number().required('this field is required'),
    }),
    AcctAssignmentGroupTypeId: yup.number().when('Role', {
        is: (roleval) => roleval === 'billto',
        then: yup.number().notRequired(),
        otherwise: yup
            .number()
            .min(1, 'this field is required')
            .required('this field is required'),
    }),
    ShippingCustomerTypeId: yup
        .number()
        .min(0, 'this field is required')
        .required('this field is required'),
    CreditLimit: yup.number().typeError('CreditLimit must be a `number` type'),
});

export const ptmnCustomerMasterRules = yup.object().shape({
    BillToCustomerNumber: yup.string().when('RoleTypeId', {
        is: (roleval) => parseInt(roleval) === 4 || parseInt(roleval) === 2,
        then: yup
            .string()
            .required('this field is required')
            .matches(/^(.?$|[^w].+|w[^f].*)/, 'invalid format'),
        otherwise: yup.string().notRequired(),
    }),
    ShipToCustomerNumber: yup.string().when('RoleTypeId', {
        is: (roleval) => parseInt(roleval) === 2,
        then: yup
            .string()
            .required('this field is required')
            .matches(/^(.?$|[^w].+|w[^f].*)/, 'invalid format'),
        otherwise: yup.string().notRequired(),
    }),
    MarketingSegmentationTypeId: yup.string().required('this field is required'),
});

export const mytaskCustomerMasterRules = yup.object().shape({
    Role: yup.string(),
    display_LN: yup.bool().notRequired(),
    License: yup.string().when('display_LN', {
        is: true,
        then: yup.string().required('this field is required').max(30),
        otherwise: yup.string().notRequired(),
    }),
    LicenseExpDate: yup.string().when('display_LN', {
        is: true,
        then: yup.string().required('this field is required'),
        otherwise: yup.string().notRequired(),
    }),
    SearchTerm1: yup.string().max(20),
    SearchTerm2: yup.string().max(20),
    TransporationZone: yup.string().when('Role', {
        is: (roleval) => roleval === 'payer' || roleval === 'billto',
        then: yup.string().notRequired(),
        otherwise: yup.string().max(10).required('this field is required'),
    }),
    TaxNumber2: yup.string().notRequired(),
    SortKey: yup.string().required('this field is required').max(3),
    PaymentMethods: yup.string().required('this field is required').max(10),
    AcctgClerk: yup.string().required('this field is required').max(2),
    OrderCombination: yup.bool().when('Role', {
        is: (roleval) => roleval === 'payer' || roleval === 'billto',
        then: yup.bool().notRequired(),
        otherwise: yup.bool().required('this field is required').oneOf([true, false]),
    }),
    PaymentHistoryRecord: yup
        .bool()
        .required('this field is required')
        .oneOf([true, false]),
    LikelyMatches: yup.array().notRequired(),
    AdditionalNotes: yup.string(),
    isRequiredINCOT2: yup.bool().notRequired(),
    IncoTerms2: yup.string().when('isRequiredINCOT2', {
        is: true,
        then: yup.string().required('this field is required').max(28),
        otherwise: yup.string().notRequired(),
    }),
    RejectionButton: yup.bool(),
    RejectionReason: yup.string().when('RejectionButton', {
        is: true,
        then: yup.string().required('this field is required'),
        otherwise: yup.string().notRequired(),
    }),
    isContractEnabled: yup.bool(),
    TaxClassificationTypeId: yup.number().when('Role', {
        is: (roleval) => roleval === 'billto',
        then: yup.number().notRequired(),
        otherwise: yup.number().required('this field is required'),
    }),
    AccountStatementTypeId: yup.number().required('this field is required'),
    AccountTypeId: yup.string().when('isContractEnabled', {
        is: false,
        then: yup.string().required('this field is required'),
        otherwise: yup.string().notRequired(),
    }),
    CustomerGroupTypeId: yup.number().when('Role', {
        is: (roleval) => roleval === 'payer' || roleval === 'billto',
        then: yup.number().notRequired(),
        otherwise: yup.number().when('isContractEnabled', {
            is: false,
            then: yup.number().required('this field is required'),
            otherwise: yup.number().notRequired(),
        }),
    }),
    CustomerPriceProcTypeId: yup.number().when('Role', {
        is: (roleval) => roleval === 'payer' || roleval === 'billto',
        then: yup.number().notRequired(),
        otherwise: yup
            .number()
            .typeError('this field is required')
            .required('this field is required'),
    }),
    PriceListTypeId: yup.number().when('Role', {
        is: (roleval) => roleval === 'payer' || roleval === 'billto',
        then: yup.number().notRequired(),
        otherwise: yup.number().required('this field is required'),
    }),
    CustomerClassTypeId: yup.number().required('this field is required'),
    IndustryCodeTypeId: yup.number().notRequired(),
    MarketingSegmentationTypeId: yup.number().notRequired(),
    ReconAccountTypeId: yup.number().required('this field is required'),
    SalesOfficeTypeId: yup.number().required('this field is required'),
    PpcustProcTypeId: yup.number().when('Role', {
        is: (roleval) => roleval === 'payer' || roleval === 'billto',
        then: yup.number().notRequired(),
        otherwise: yup.number().required('this field is required'),
    }),
    DeliveryPriorityTypeId: yup.number().when('Role', {
        is: (roleval) => roleval === 'payer' || roleval === 'billto',
        then: yup.number().notRequired(),
        otherwise: yup.number().required('this field is required'),
    }),
    ShippingConditionsTypeId: yup.number().when('Role', {
        is: (roleval) => roleval === 'payer' || roleval === 'billto',
        then: yup.number().notRequired(),
        otherwise: yup.number().required('this field is required'),
    }),
    IncoTerms1TypeId: yup.number().when('Role', {
        is: (roleval) => roleval === 'payer' || roleval === 'billto',
        then: yup.number().notRequired(),
        otherwise: yup.number().when('isContractEnabled', {
            is: false,
            then: yup.number().required('this field is required'),
            otherwise: yup.number().notRequired(),
        }),
    }),
    AcctAssignmentGroupTypeId: yup.number().when('Role', {
        is: (roleval) => roleval === 'billto',
        then: yup.number().notRequired(),
        otherwise: yup.number().required('this field is required'),
    }),
    ShippingCustomerTypeId: yup.number().required('this field is required'),
});

export const mytaskOlympusCustomerMasterRules = yup.object().shape({
    // Industry: yup.string().required('this field is required'),
    Country: yup.string(),
    SearchTerm1: yup.string().max(20),
    SearchTerm2: yup
        .string()
        .when('RoleTypeId', {
            is: (roleval) => roleval != 23,
            then: yup.string().required('this field is required'),
            otherwise: yup.string().notRequired(),
        })
        .max(20),
    // Industry: yup.string().when('RoleTypeId', {
    //     is: (roleval) => roleval != 2 && roleval != 29,
    //     then: yup.string().required('this field is required'),
    //     otherwise: yup.string().notRequired(),
    // }),
    IndustryCode1: yup.string(),
    SortKey: yup.string().when('RoleTypeId', {
        is: (roleval) => roleval != 2 && roleval != 4 && roleval != 29,
        then: yup.string().required('this field is required'),
        otherwise: yup.string().notRequired(),
    }),
    CustPriceProc: yup.string().when('RoleTypeId', {
        is: (roleval) => roleval == 1 || roleval == 23,
        then: yup.string().required('this field is required'),
        otherwise: yup.string().notRequired(),
    }),
    CustomerGroup: yup.string(),
    DeliveryPriority: yup.string().when('RoleTypeId', {
        is: (roleval) => roleval == 1 || roleval == 2 || roleval == 29,
        then: yup.string().required('this field is required'),
        otherwise: yup.string().notRequired(),
    }),
    IncoTerms2: yup
        .string()
        .when('RoleTypeId', {
            is: (roleval) => roleval == 1 || roleval == 3,
            then: yup.string().required('this field is required'),
            otherwise: yup.string().notRequired(),
        })
        .max(28),
    OrderCombination: yup.string(),
    ShippingConditions: yup.string().when('RoleTypeId', {
        is: (roleval) => roleval == 1 || roleval == 2 || roleval == 29,
        then: yup.string().required('this field is required'),
        otherwise: yup.string().notRequired(),
    }),
    MaxPartialDeliveries: yup.string().when('RoleTypeId', {
        is: (roleval) => roleval == 1 || roleval == 2 || roleval == 29,
        then: yup.string().required('this field is required'),
        otherwise: yup.string().notRequired(),
    }),
    OrderProbab: yup.string().when('RoleTypeId', {
        is: (roleval) => roleval == 1,
        then: yup.string().required('this field is required'),
        otherwise: yup.string().notRequired(),
    }),
    CompleteDeliveryRequired: yup.bool(),
    PartialDeliveryperItem: yup.string(),
    // DeliveringPlant: yup.string(),
    TransportationZone: yup.string().when('RoleTypeId', {
        is: (roleval) => roleval == 1 || roleval == 2 || roleval == 29,
        then: yup.string().required('this field is required'),
        otherwise: yup.string().notRequired(),
    }),
    PaymentHistoryRecord: yup.string().when('RoleTypeId', {
        is: (roleval) => roleval == 1 || roleval == 3 || roleval == 4,
        then: yup.string().required('this field is required'),
        otherwise: yup.string().notRequired(),
    }),
    AdditionalNotes: yup.string(),
});

export const myTaskJDECreditRules = yup.object().shape({
    CreditLimit: yup.string().when(['RoleTypeId'], {
        is: (role) => parseInt(role) == 1 || parseInt(role) == 2,
        then: yup.string().required('this field is required'),
        otherwise: yup.string().notRequired(),
    }),
    PaymentTermsTypeId: yup.string().required(),
});

export const myTaskJdeRules = yup.object().shape({
    AlphaName: yup.string().required(),
    // CreditLimit: yup.string().when(['RoleTypeId'], {
    //     is: (role) => parseInt(role) == 1 || parseInt(role) == 2,
    //     then: yup.string().required('this field is required'),
    //     otherwise: yup.string().notRequired(),
    // }),
    CategoryCode03TypeId: yup.number().required(),
    CategoryCode22TypeId: yup.string().when(['Country', 'MarketingSegmentationTypeId'], {
        is: (country, marketingsegmentationid) =>
            country == 'DE' && parseInt(marketingsegmentationid) == 6,
        then: yup.string().required('this field is required'),
        otherwise: yup.string().notRequired(),
    }),
    Reporting1099TypeId: yup.number().required(),
    PaymentInstructionTypeId: yup.number().when('Reporting1099TypeId', {
        is: (Reporting1099Type) => Reporting1099Type == 6,
        then: yup.number().notRequired(),
        otherwise: yup.number().required('this field is required'),
    }),
    PolicyNameTypeId: yup.number().required(),
    CompanyTypeId: yup.number().required(),
    GlOffsetTypeId: yup.number().required(),
    TaxExplCodeTypeId: yup.number().required(),
    TaxRateAreaTypeId: yup.number().when('Reporting1099TypeId', {
        is: (Reporting1099Type) => Reporting1099Type == 6,
        then: yup.number().notRequired(),
        otherwise: yup.number().required('this field is required'),
    }),
    SendMethodTypeId: yup.number().required(),
    BillingAddressTypeId: yup.number().required(),
    RelatedAddressNumTypeId: yup.string().when('RoleTypeId', {
        is: (roleval) => roleval == 2 || roleval == 3,
        then: yup.string().required('this field is required'),
        otherwise: yup.string().notRequired(),
    }),
    AdjustmentScheduleTypeId: yup.number().when('Reporting1099TypeId', {
        is: (Reporting1099Type) => Reporting1099Type == 6,
        then: yup.number().notRequired(),
        otherwise: yup.number().required('this field is required'),
    }),
    ItemRestrictionsTypeId: yup.number().required(),
    CollectionManagerTypeId: yup.string().required('this field is required'),
});

export const mytaskContractsRules = yup.object().shape({
    RejectionButton: yup.bool(),
    RejectionReason: yup.string().when('RejectionButton', {
        is: true,
        then: yup.string().required('this field is required'),
        otherwise: yup.string().notRequired().nullable(),
    }),
    Role: yup.string(),
    IncoTerms1TypeId: yup.number().when('Role', {
        is: (roleval) =>
            roleval === 'shipto' || roleval === 'payer' || roleval === 'billto',
        then: yup.number().notRequired(),
        otherwise: yup.number().required('this field is required'),
    }),
    isRequiredINCOT2: yup.bool().notRequired(),
    IncoTerms2: yup.string().when('isRequiredINCOT2', {
        is: true,
        then: yup.string().required('this field is required').max(28),
        otherwise: yup.string().notRequired(),
    }),
    AccountTypeId: yup.number().required('this field is required'),
    CustomerGroupTypeId: yup.number().when('Role', {
        is: (roleval) => roleval === 'payer' || roleval === 'billto',
        then: yup.number().notRequired(),
        otherwise: yup.number().required('this field is required'),
    }),
    PaymentTermsTypeId: yup.number().required('this field is required'),
});

export const mytaskCreditRules = yup.object().shape({
    RejectionButton: yup.bool(),
    RejectionReason: yup.string().when('RejectionButton', {
        is: true,
        then: yup.string().required('this field is required'),
        otherwise: yup.string().notRequired(),
    }),
    CreditLimit: yup.number().typeError('CreditLimit must be a `number` type'),
});

export const myTaskCreditExtend = yup.object().shape({
    RejectionButton: yup.bool(),
    RejectionReason: yup.string().when('RejectionButton', {
        is: true,
        then: yup.string().required('this field is required'),
        otherwise: yup.string().notRequired(),
    }),
    CategoryTypeId: yup.number(),
    PaymentTermsTypeId: yup.number().when('CategoryTypeId', {
        is: (category) => category === 4 || category === 5 || category === 7,
        then: yup.number().required('this field is required'),
        otherwise: yup.number().notRequired(),
    }),
});

export const mytaskPricingRules = yup.object().shape({
    RejectionButton: yup.bool(),
    RejectionReason: yup.string().when('RejectionButton', {
        is: true,
        then: yup.string().required('this field is required'),
        otherwise: yup.string().notRequired(),
    }),
});

export const createCustomerRules = yup.object().shape({
    WorkflowTitle: yup.string().required('this field is required').max(40),
    SystemTypeId: yup.number().required('this field is required').label('System'),
    RoleTypeId: yup.number().when('SystemTypeId', {
        is: (systemTypeId) => parseInt(systemTypeId) === 6,
        then: yup.number().notRequired().label('Role'),
        otherwise: yup.number().required('this field is required').label('Role'),
    }),
    SalesOrgTypeId: yup.string().when('SystemTypeId', {
        is: (systemTypeId) =>
            parseInt(systemTypeId) === 6 || parseInt(systemTypeId) === 3,
        then: yup.string().nullable().notRequired().label('Sales Org'),
        otherwise: yup
            .string()
            .typeError('this field is required')
            .required('this field is required')
            .label('Sales Org'),
    }),
    CompanyCodeTypeId: yup.number().when('SystemTypeId', {
        is: (systemTypeId) =>
            parseInt(systemTypeId) === 6 || parseInt(systemTypeId) === 3,
        then: yup.number().notRequired().label('Company Code'),
        otherwise: yup.number().required('this field is required').label('Company Code'),
    }),
    DistributionChannelTypeId: yup.number().when('SystemTypeId', {
        is: (systemTypeId) =>
            parseInt(systemTypeId) === 6 || parseInt(systemTypeId) === 3,
        then: yup.number().notRequired(),
        otherwise: yup.number().required('this field is required'),
    }),
    DivisionTypeId: yup.number().when('SystemTypeId', {
        is: (systemTypeId) =>
            parseInt(systemTypeId) === 6 || parseInt(systemTypeId) === 3,
        then: yup.number().notRequired(),
        otherwise: yup.number().required('this field is required'),
    }),
    BillToPartner: yup.string().when(['RoleTypeId', 'SystemTypeId'], {
        is: (roleval, systemTypeId) =>
            parseInt(systemTypeId) === 3 && parseInt(roleval) === 2,
        then: yup.string().required('this field is required'),
        otherwise: yup.string().notRequired(),
    }),
    EffectiveDate: yup.date().required('this field is required'),
});

export const createOlympusCustomerRules = yup.object().shape({
    WorkflowTitle: yup.string().required('this field is required'),
    SystemTypeId: yup.string().required('this field is required').label('System'),
    RoleTypeId: yup.string().when('SystemTypeId', {
        is: (systemTypeId) => parseInt(systemTypeId) === 6,
        then: yup.string().notRequired().label('Role'),
        otherwise: yup.string().required('this field is required').label('Role'),
    }),
    // SoldTo: yup.string().when(['RoleTypeId'], {
    //     is: (roleTypeId, systemTypeId) =>
    //         roleTypeId == 2 || roleTypeId == 3 || roleTypeId == 4,
    //     then: yup.string().required(),
    //     otherwise: yup.string().notRequired(),
    // }),
    SalesOrgTypeId: yup.string().when(['SystemTypeId', 'RoleTypeId'], {
        is: (systemTypeId, roleTypeId) =>
            parseInt(systemTypeId) === 2 && roleTypeId == 28,
        then: yup.string().nullable().notRequired().label('Sales Org'),
        otherwise: yup
            .string()
            .typeError('this field is required')
            .required('this field is required')
            .label('Sales Org'),
    }),
    CompanyCodeTypeId: yup.string().required('this field is required'),
    DistributionChannelTypeId: yup.string().required('this field is required'),
    // .when(['RoleTypeId'], {
    //     is: (roleVal) => roleVal != 28,
    //     then: yup.string().required('this field is required'),
    //     otherwise: yup.string().notRequired(),
    // }),
    DivisionTypeId: yup.string().required('this field is required'),
    // when(['RoleTypeId'], {
    //     is: (roleTypeId, systemTypeId) => roleTypeId == 28,
    //     then: yup.string().notRequired(),
    //     otherwise: yup.string().required('this field is required'),
    // }),
    BillToPartner: yup.string().when(['RoleTypeId', 'SystemTypeId'], {
        is: (roleval, systemTypeId) =>
            parseInt(systemTypeId) === 3 && parseInt(roleval) === 2,
        then: yup.string().required('this field is required'),
        otherwise: yup.string().notRequired(),
    }),
    EffectiveDate: yup.string().required('this field is required'),
    Priority: yup.string().required('this field is required'),
    ReconAccount: yup.string().when(['RoleTypeId', 'SystemTypeId'], {
        is: (roleVal, systemTypeId) =>
            parseInt(systemTypeId) === 2 &&
            (roleVal == 1 || roleVal == 3 || roleVal == 23 || roleVal == 28),
        then: yup.string().required(),
        otherwise: yup.string().notRequired(),
    }),
    TermsOfPayment: yup.string().when(['RoleTypeId'], {
        is: (roleVal) =>
            roleVal == 1 ||
            roleVal == 3 ||
            roleVal == 4 ||
            roleVal == 23 ||
            roleVal == 28,
        then: yup.string().required(),
        otherwise: yup.string().notRequired(),
    }),
    Currency: yup.string().when(['RoleTypeId', 'SystemTypeId'], {
        is: (roleVal, systemTypeId) =>
            parseInt(systemTypeId) === 2 && (roleVal == 1 || roleVal == 23),
        then: yup.string().required(),
        otherwise: yup.string().notRequired(),
    }),
    CustomerClass: yup.string().when(['RoleTypeId', 'SystemTypeId'], {
        is: (roleVal, systemTypeId) =>
            parseInt(systemTypeId) === 2 &&
            (roleVal == 1 || roleVal == 3 || roleVal == 4 || roleVal == 23),
        then: yup.string().required(),
        otherwise: yup.string().notRequired(),
    }),
    Incoterms1: yup.string().when(['RoleTypeId', 'SystemTypeId'], {
        is: (roleVal, systemTypeId) =>
            parseInt(systemTypeId) === 2 &&
            (roleVal === 1 || roleVal === 3 || roleVal === 23),
        then: yup.string().required(),
        otherwise: yup.string().notRequired(),
    }),
    AcctAssignmentGroup: yup.string().when(['RoleTypeId', 'SystemTypeId'], {
        is: (roleVal, systemTypeId) =>
            parseInt(systemTypeId) === 2 &&
            (roleVal == 1 || roleVal == 3 || roleVal == 23 || roleVal == 28),
        then: yup.string().required(),
        otherwise: yup.string().notRequired(),
    }),
    TaxClassification: yup.string().when(['RoleTypeId', 'SystemTypeId'], {
        is: (roleVal, systemTypeId) =>
            parseInt(systemTypeId) === 2 && (roleVal == 4 || roleVal == 28),
        then: yup.string().required('this field is required'),
        otherwise: yup.string().notRequired(),
    }),
    SalesDistrict: yup.string().when(['Country', 'SystemTypeId', 'RoleTypeId'], {
        is: (country, systemTypeId, roleVal) =>
            parseInt(systemTypeId) === 2 && country === 'DE' && roleVal == 1,
        then: yup.string().required('this field is required'),
        otherwise: yup.string().notRequired(),
    }),
    SalesOffice: yup.string().when(['Country', 'SystemTypeId', 'RoleTypeId'], {
        is: (country, systemTypeId, roleVal) =>
            parseInt(systemTypeId) === 2 && country === 'DE' && roleVal == 1,
        then: yup.string().required('this field is required'),
        otherwise: yup.string().notRequired(),
    }),
    SalesGroup: yup.string().when(['Country', 'SystemTypeId', 'RoleTypeId'], {
        is: (country, systemTypeId, roleVal) =>
            parseInt(systemTypeId) === 2 && country === 'DE' && roleVal == 1,
        then: yup.string().required('this field is required'),
        otherwise: yup.string().notRequired(),
    }),
    // TaxNumber4: yup.string().when(['Country', 'SystemTypeId', 'RoleTypeId'], {
    //     is: (country, systemTypeId) => parseInt(systemTypeId) === 2 && country === 'IT',
    //     then: yup.string().required('this field is required'),
    //     otherwise: yup.string().notRequired(),
    // }),
    Language: yup
        .string()
        .when(['SalesOrgTypeId', 'DistributionChannelTypeId', 'RoleTypeId'], {
            is: (salesOrg, distributionChannel) =>
                salesOrg == '14' && distributionChannel == '3',
            then: yup.string().default('15').required(),
            otherwise: yup.string().required('this field is required'),
        }),
    URIType: yup.string().when(['RoleTypeId', 'ServiceCode'], {
        is: (roleTypeId, serviceCode) =>
            serviceCode === '' ||
            parseInt(roleTypeId) === 2 ||
            parseInt(roleTypeId) === 28 ||
            parseInt(roleTypeId) === 29,
        then: yup.string().notRequired(),
        otherwise: yup.string().required('this field is required'),
    }),
    // PriceGroup: yup.string().when('RoleTypeId', {
    //     is: (role) => role === '1',
    //     then: yup.string().required('this field is required'),
    //     otherwise: yup.string().notRequired(),
    // }),
    CustomerNumber: yup.string().when('RoleTypeId', {
        is: (role) => role == '23' || role == '28',
        then: yup.string().required('this field is required'),
        otherwise: yup.string().notRequired(),
    }),
});

export const ExtendFieldRules = yup.object().shape({
    WorkflowTitle: yup.string().required(),
    Purpose: yup.string().notRequired(),
});
export const OlympusExtendFieldRules = yup.object().shape({
    WorkflowTitle: yup.string().required(),
    Purpose: yup.string().notRequired(),
});

export const BlockFieldRules = yup.object().shape({
    WorkflowTitle: yup.string().required(),
    Purpose: yup.string().notRequired(),
});

export const saveInternationalRules = yup.object().shape({
    IName1: yup.string().required(),
    IStreet: yup.string().required(),
    IStreet2: yup.string(),
    ICity: yup.string().required(),
});
export const mytaskOlympusContractsRules = yup.object().shape({
    RejectionButton: yup.bool(),
    RejectionReason: yup.string().when('RejectionButton', {
        is: true,
        then: yup.string().required('this field is required'),
        otherwise: yup.string().notRequired().nullable(),
    }),
    RoleTypeId: yup.string().when('SystemTypeId', {
        is: (systemTypeId) => parseInt(systemTypeId) === 6,
        then: yup.string().notRequired().label('Role'),
        otherwise: yup.string().required('this field is required').label('Role'),
    }),
    PaymentTermsTypeId: yup.number().when(['RoleTypeId'], {
        is: (roleVal) => parseInt(roleVal) != 2 && parseInt(roleVal) != 29,
        then: yup.number().required('this field is required'),
        otherwise: yup.number().notRequired(),
    }),
    IncoTerms1TypeId: yup.number().when(['RoleTypeId'], {
        is: (roleVal) => parseInt(roleVal) == 1 || parseInt(roleVal) == 3,
        then: yup.number().required('this field is required'),
        otherwise: yup.number().notRequired(),
    }),
    IncoTerms2: yup.string().when(['RoleTypeId'], {
        is: (roleVal) => parseInt(roleVal) == 1 || parseInt(roleVal) == 3,
        then: yup.string().required('this field is required'),
        otherwise: yup.string().notRequired(),
    }),
});

export const mytaskOlympusCreditRules = yup.object().shape({
    RejectionButton: yup.bool(),
    RejectionReason: yup.string().when('RejectionButton', {
        is: true,
        then: yup.string().required('this field is required'),
        otherwise: yup.string().notRequired(),
    }),
    CreditLimit: yup.number().typeError('CreditLimit must be a `number` type'),
    AccountStatementTypeId: yup.number().required('this field is required'),
    RiskCategoryTypeId: yup.number().required('this field is required'),
    CreditControlAreaTypeId: yup.number().required('this field is required'),
});

export const apolloPartnerRules = yup.object().shape({
    WorkflowId: yup.string().required('this field is required'),
    WorkflowTitle: yup.string().required('this field is required'),
});

export const approveApolloPartners = yup.object().shape({
    WorkflowId: yup.string().required('this field is required'),
});

export const createJDECustomerRules = yup.object().shape({
    WorkflowTitle: yup.string().required('this field is required'),
    SystemTypeId: yup.string().required('this field is required').label('System'),
    RoleTypeId: yup.string().required('this field is required').label('Role'),
    SearchTypeId: yup.string().required('this field is required'),
    SalesOrgTypeId: yup.string().required('this field is required'),
    LanguageTypeId: yup.string().required('this field is required'),
    IPADestinationCode: yup.string().when('Country', {
        is: (country) => country == 'IT',
        then: yup.string().required('this field is required'),
        otherwise: yup.string().notRequired(),
    }),
    AdditionalTaxNumber: yup.string().when('Country', {
        is: (country) => country == 'IT',
        then: yup.string().required('this field is required'),
        otherwise: yup.string().notRequired(),
    }),
    // SoldTo: yup.string().when('RoleTypeId', {
    //     is: (role) => role == '3',
    //     then: yup.string().required('this field is required'),
    //     otherwise: yup.string().notRequired(),
    // }),
    TaxId: yup.string().when('Country', {
        is: (country) => country == 'IT',
        then: yup.string().required('this field is required'),
        otherwise: yup.string().notRequired(),
    }),
    PaymentTermsTypeId: yup.string().required('this field is required'),
    CurrencyTypeId: yup.string().required('this field is required'),
    PriceGroupTypeId: yup.string().required('this field is required'),
    IncoTermsTypeId: yup.string().required('this field is required'),
    CarrierTypeId: yup.string().required('this field is required'),
    SalesRepTypeId: yup.string().required('this field is required'),
    SalesAreaTypeId: yup.string().required('this field is required'),
    RegionTypeId: yup.string().required('this field is required'),
    DocumentsLanguageTypeId: yup.string().required('this field is required'),
    // CatCode23TypeId: yup.string().required('this field is required'),
    EffectiveDate: yup.string().required('this field is required'),
    IndustryClassificationTypeId: yup.string().required('this field is required'),
    // RequestedCreditLimit: yup.string().required('this field is required'),
});
