import { Colors } from '../theme/Index';

export const WorkflowProcessType = {
    1: 'Create',
    2: 'Update',
    3: 'Extend',
    4: 'BlockUnblock',
    5: 'Other',
    6: 'Approve Apollo Partners',
    7: 'Approve Olympus Partners',
    8: 'Approve JDE Partners',
};

export const TaskType = {
    1: 'SapApollo | Create Customer | Role: Sold To ',
    2: 'SapApollo | Create Customer | Role: Ship To ',
    3: 'SapApollo | Create Customer | Role: Bill To ',
    4: 'SapApollo | Create Customer | Role: Payer',
    5: 'SapApollo | Create | Role: Sales Rep',
    6: 'SapApollo | Create | Role:DropShip',
    7: 'SapOlympus | Create | Role: Sold To',
    8: 'SapOlympus | Create | Role: Ship To',
    9: 'SapOlympus | Create | Role: Bill To',
    10: 'SapOlympus | Create | Role: Payer',
    11: 'SapOlympus | Create | Role: Sales Rep',
    12: 'Create SoldTo Pointman',
    13: 'Create ShipTo Pointman',
    14: 'Create SalesRepPointman',
    15: 'Create SoldTo Made2Manage',
    16: 'Create ShipTo Made2Manage',
    17: 'Create SalesRepMade2Manage',
    18: 'Create SoldTo JDEdwards',
    19: 'Create ShipTo JDEdwards',
    20: 'Create SalesRepJDEdwards',
    21: 'SapApollo | Update Customer',
    22: 'SapApollo | Extend To SalesOrg',
    23: 'SapApollo | Block',
    24: 'SapApollo | Extend To System',
    25: 'SapApollo | Partner',
    27: 'SapApollo | Withdraw',
    31: 'Extend to New Sales Org - SAP Apollo',
};

export const WorkflowType = {
    1: 'CreateSoldToSapApollo',
    2: 'CreateShipToSapApollo',
    3: 'CreateBillToSapApollo',
    4: 'CreatePayerSapApollo',
    5: 'CreateSalesRepSapApollo',
    6: 'CreateDropShipSapApollo',
    7: 'CreateSoldToSapOlympus',
    8: 'CreateShipToSapOlympus',
    9: 'CreateBillToSapOlympus',
    10: 'CreatePayerSapOlympus',
    11: 'CreateSalesRepSapOlympus',
    12: 'CreateSoldToPointman',
    13: 'CreateShipToPointman',
    14: 'CreateSalesRepPointman',
    15: 'CreateSoldToMade2Manage',
    16: 'CreateShipToMade2Manage',
    17: 'CreateSalesRepMade2Manage',
    18: 'CreateSoldToJDEdwards',
    19: 'CreateShipToJDEdwards',
    20: 'CreateSalesRepJDEdwards',
    21: 'UpdateSapApollo',
    22: 'ExtendToSalesOrgSapApollo',
    23: 'BlockSapApollo',
    24: 'ExtendToSystemSapApollo',
    25: 'PartnerSapApollo',
    27: 'WithdrawSapApollo',
    31: 'ExtendToNewSalesOrg',
    48: 'CreateJDECustomer',
    47: 'ApproveApolloPartners',
};

export const TypeToSystem = {
    1: 'SAP Apollo',
    2: 'SAP Apollo',
    3: 'SAP Apollo',
    4: 'SAP Apollo',
    5: 'SAP Apollo',
    6: 'SAP Apollo',
};

export const WorkflowStateType = {
    1: 'New',
    2: 'InProgress',
    3: 'Completed',
    4: 'Withdrawn',
    5: 'Rejected',
};

export const WorkflowTaskType = {
    1: 'ApproveSoldTo',
    2: 'UnblockSoldTo',
    3: 'ApproveShipTo',
    4: 'ApproveBillTo',
    5: 'ApprovePayer',
    6: 'UnblockShipTo',
    7: 'UnblockBillTo',
    8: 'UnblockPayer',
    12: 'ApproveUpdate',
    13: 'ApproveUpdateGlobal',
    14: 'ApproveExtendToNewSalesOrg',
    15: 'BlockUnblock',
    16: 'ApproveSalesRep',
    17: 'UnblockSalesRep',
    18: 'ApproveDropShip',
    19: 'UnblockDropShip',
    20: 'ApproveSalesForce',
    21: 'ApproveOther',
    22: 'ApproveExtendToNewSalesOrgWithBlock',
    23: 'UnblockExtendToNewSalesOrg',
    28: 'ApproveBillTo',
    27: 'ApproveShipTo',
    50: 'ApproveOtherOlympus',
    51: 'ApproveOtherJDE',
    52: 'ApproveOtherMajesty',
};

export const WorkflowTaskStateType = {
    1: 'New',
    2: 'ReadyForProcessing',
    3: 'InProcess',
    4: 'Approved',
    5: 'Rejected',
};

export const TASK_APPROVED = 4;
export const TASK_REJECTED = 5;

export const ColorCodeWorkFlowTaskStateType = {
    0: Colors.lightGrey,
    1: Colors.lightGrey,
    2: Colors.lightYellow,
    3: Colors.lightYellow,
    4: Colors.lightGreen,
    5: Colors.lightRed,
};

export const AliasWorkflowTeamType = {
    1: 'Requestor',
    2: 'Global Trade',
    3: 'Customer Master',
    4: 'Credit',
    5: 'Contracts',
    6: 'Sales Operations',
    7: 'Customer Service Operations',
    8: 'Pricing',
    9: 'Tax',
    10: 'Credit Team West',
    11: 'Credit Team NorthEast',
    12: 'Credit Team SouthEast',
    13: 'Credit Team MidSouth',
    14: 'Credit Team MidWest',
    15: 'Credit Team Africa',
    16: 'Credit Team Mexico',
    17: 'Credit Team Caribbean',
    18: 'Credit Team SouthAmerica',
    19: 'Credit Team Other International',
    21: 'Global Trade',
    22: 'Customer Master',
    23: 'Credit',
    24: 'Contracts',
    25: 'Pricing',
    27: 'Customer Master',
    28: 'Credit',
    29: 'Global Trade',
    30: 'Contracts',
    31: 'Pricing',
    32: 'Customer Master',
};

export const AlternateWorkflowTeamTypeId = {
    // 1: 'Requestor'
    // 2: 'Global Trade'
    // 3: 'Customer Master'
    // 4: 'Credit'
    // 5: 'Contracts'
    // 6: 'Sales Operations'
    // 7: 'Customer Service Operations'
    // 8: 'Pricing'
    // 9: 'Tax'
    10: 4, //'Credit Team West'
    11: 4, //'Credit Team NorthEast'
    12: 4, //'Credit Team SouthEast'
    13: 4, //'Credit Team MidSouth'
    14: 4, //'Credit Team MidWest'
    15: 4, //'Credit Team Africa'
    16: 4, //'Credit Team Mexico'
    17: 4, //'Credit Team Caribbean'
    18: 4, //'Credit Team SouthAmerica'
    19: 4, //'Credit Team Other International'
    21: 2, //GlobalTradeOlympus
    22: 3, //CustomerMasterOlympus
    23: 4, //CreditOlympus
    24: 5, //ContractsOlympus
    25: 8, //PricingOlympus
    27: 3, // Customer Master JDE
    28: 4, // Credit JDE
    29: 2, //Global Trade JDE
    30: 5, // Contracts JDE
    31: 8, // Pricing JDE
    32: 3, //CustomerMasterMajesty
};

export const WorkflowTeamType = {
    1: 'CustomerServiceSales',
    2: 'GlobalTrade',
    3: 'CustomerMaster',
    4: 'Credit',
    5: 'Contracts',
    6: 'SalesOperations',
    7: 'CustomerServiceOperations',
    8: 'Pricing',
    9: 'Tax',
    10: 'Credit Team West',
    11: 'Credit Team NorthEast',
    12: 'Credit Team SouthEast',
    13: 'Credit Team MidSouth',
    14: 'Credit Team MidWest',
    15: 'Credit Team Africa',
    16: 'Credit Team Mexico',
    17: 'Credit Team Caribbean',
    18: 'Credit Team SouthAmerica',
    19: 'Credit Team OtherInternational',
    21: 'Global Trade',
    22: 'Customer Master',
    23: 'Credit',
    24: 'Contracts',
    25: 'Pricing',
    27: 'Customer Master',
    28: 'Credit',
    29: 'Global Trade',
    30: 'Contracts',
    31: 'Pricing',
    32: 'CustomerMaster',
};

export const WorkflowTeamTypeRouteAddress = {
    1: '/my-tasks/release',
    2: '/my-tasks/global-trade',
    3: '/my-tasks/customer-master',
    4: '/my-tasks/credit-form',
    5: '/my-tasks/contracts',
    6: '/my-tasks/release',
    7: '/my-tasks/release',
    8: '/my-tasks/pricing',
    9: '/my-tasks/release',
    10: '/my-tasks/credit-form',
    11: '/my-tasks/credit-form',
    12: '/my-tasks/credit-form',
    13: '/my-tasks/credit-form',
    14: '/my-tasks/credit-form',
    15: '/my-tasks/credit-form',
    16: '/my-tasks/credit-form',
    17: '/my-tasks/credit-form',
    18: '/my-tasks/credit-form',
    19: '/my-tasks/credit-form',
};

export const WorkflowTeamTypeRouteAddressRoute = {
    1: 'release',
    2: 'global-trade',
    3: 'customer-master',
    4: 'credit-form',
    5: 'contracts',
    6: 'release',
    7: 'release',
    8: 'pricing',
    9: 'release',
    10: 'credit-form',
    11: 'credit-form',
    12: 'credit-form',
    13: 'credit-form',
    14: 'credit-form',
    15: 'credit-form',
    16: 'credit-form',
    17: 'credit-form',
    18: 'credit-form',
    19: 'credit-form',
    21: 'global-trade',
    22: 'customer-master',
    23: 'credit-form',
    24: 'contracts',
    25: 'pricing',
    27: 'customer-master',
    28: 'credit-form',
    29: 'global-trade',
    30: 'contracts',
    31: 'pricing',
    32: 'customer-master',
};

export const getWorkflowRouteAddress = (workflow) => {
    const validRoutes = [2, 3, 4, 5, 8, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19];
    const index = validRoutes.indexOf(workflow.WorkflowTeamType);
    let route = {};
    if (workflow && workflow.WorkflowType == 47) {
        route['address'] =
            '/my-tasks/Apollo' +
            // SystemTypeShort[workflow.SystemTypeId] +
            '/partners/' +
            workflow.WorkflowId +
            '/mytask';
    } else if (workflow && workflow.WorkflowType == 49) {
        route['address'] =
            '/my-tasks/Olympus' +
            // SystemTypeShort[workflow.SystemTypeId] +
            '/partners/' +
            workflow.WorkflowId +
            '/mytask';
    } else if (workflow && workflow.WorkflowType == 51) {
        route['address'] =
            '/my-tasks/jded' +
            // SystemTypeShort[workflow.SystemTypeId] +
            '/partners/' +
            workflow.WorkflowId +
            '/mytask';
    } else {
        route['address'] =
            '/my-tasks/' +
            SystemTypeShort[workflow.SystemTypeId] +
            '/' +
            WorkflowTeamTypeRouteAddressRoute[workflow.WorkflowTeamType] +
            '/' +
            workflow.WorkflowId;
    }
    route['header'] = 'CREATE';

    if (index !== -1 && workflow.WorkflowTaskType === 12) {
        route['address'] = `/my-tasks/${SystemTypeShort[workflow.SystemTypeId]}/${
            WorkflowTeamTypeRouteAddressRoute[workflow.WorkflowTeamType]
        }/${workflow.WorkflowId}/update`;
        route['header'] = 'Update';
    } else if (workflow.WorkflowTaskType === 13 && workflow.WorkflowTeamType === 3) {
        route['address'] =
            `/my-tasks/` +
            SystemTypeShort[workflow.SystemTypeId] +
            `customer-global/${workflow.WorkflowId}/update`;
        route['header'] = 'Update Customer Global';
    } else if (workflow.WorkflowTaskType === 13 && workflow.WorkflowTeamType === 2) {
        route['address'] =
            `/my-tasks/` +
            SystemTypeShort[workflow.SystemTypeId] +
            `global-trade/${workflow.WorkflowId}/update`;
        route['header'] = 'Update Customer Global';
    } else if (
        index !== -1 &&
        (workflow.WorkflowTaskType === 14 || workflow.WorkflowTaskType === 22)
    ) {
        route['address'] = `/my-tasks/${SystemTypeShort[workflow.SystemTypeId]}/${
            WorkflowTeamTypeRouteAddressRoute[workflow.WorkflowTeamType]
        }/${workflow.WorkflowId}/extend-salesorg`;
        route['header'] = 'Extend to New Sales Org';
    } else if (index !== -1 && workflow.WorkflowTaskType === 15) {
        route['address'] = `/my-tasks/${SystemTypeShort[workflow.SystemTypeId]}/${
            WorkflowTeamTypeRouteAddressRoute[workflow.WorkflowTeamType]
        }/${workflow.WorkflowId}/block-unblock`;
        route['header'] = 'Block - UnBlock';
    } else if (workflow.WorkflowTaskType === 20 && workflow.WorkflowTeamType === 3) {
        route['address'] =
            `/my-tasks/` +
            SystemTypeShort[workflow.SystemTypeId] +
            `/customer-master/salesforce/${workflow.WorkflowId}`;
        route['header'] = 'Create ~ SalesForce';
    } else if (
        workflow.WorkflowTaskType === 21 ||
        workflow.WorkflowTaskType === 50 ||
        workflow.WorkflowTaskType === 51 ||
        workflow.WorkflowTaskType === 52
    ) {
        route['address'] =
            `/my-tasks/` +
            SystemTypeShort[workflow.SystemTypeId] +
            `/customer-master/${workflow.WorkflowId}/other-request`;
        route['header'] = 'Other';
    } else if (workflow.WorkflowTaskType === 23) {
        route['address'] =
            `/my-tasks/` +
            SystemTypeShort[workflow.SystemTypeId] +
            `/extend/release/${workflow.WorkflowId}`;
    }
    return route;
};

export const GeneralRoleType = {
    1: 'Sold To',
    2: 'Ship To',
    3: 'Payer',
    4: 'Bill To',
    5: 'Sales Rep',
    6: 'Drop Ship',
    99: 'Global',
};

//*other roles
// 21: '0007: Sales partners',
// 22: '0110: Branch w/o intercomp.billing',
// 23: '0120: Branch with intercomp.billing',
// 24: '0130: Branch with external billing',
// 25: '0140: Assortment',
// 26: '0150: Franchisee',
// 27: 'CPD: One-time cust.(int.no.assgnmt)',
// 28: 'CPDA: One-time cust.(ext.no.assgnmt)',
// 29: 'KUNA: Customer (ext.number assgnmt)',
// 30: 'Z001: Buying Group',
// 31: 'Z003: Sales Territory/Area/Region',
// 32: 'Z004: National Accounts/GPO',
// 33: 'Z005: Integrated Health Network',
// 34: 'Z006: Trace Customer',
// 35: 'Z009: Dispensing Side ID',
// 36: 'Z011: Intercompany Payer',
// 37: 'Z012: GPO Hierarchy Node',
// 38: 'Z998: Ultimate Destination Country',
// 39: 'Z999: Test Acct with All Fields',
// 40: 'ZSEL: Seller',

export const MarketSegmentationReverseMap = {
    Acute: 1,
    'Non-Acute': 2,
    'Acute & Non-Acute': 3,
    'N/A': 4,
};

export const PtmnRoleType = {
    4: 'Bill To',
    2: 'Ship To',
};
export const PointmanRoleType = {
    4: 'Bill To',
    2: 'Ship To',
};
export const OlympusRoleType = {
    1: 'Bill To',
    2: 'Ship To',
};
export const RoleType = {
    0: 'N/A',
    1: 'Sold To',
    2: 'Ship To',
    3: 'Payer',
    4: 'Bill To',
    5: 'Sales Rep',
    6: 'Drop Ship',
    7: 'SAP Olympus: Sold To',
    8: 'SAP Olympus: Ship To',
    9: 'SAP Olympus: Payer',
    10: 'SAP Olympus: Bill To',
    11: 'SAP Olympus: Sales Rep',
    12: 'Pointman: Sold To',
    13: 'Pointman: Ship To',
    14: 'Pointman: Sales Rep',
    15: 'Made2Manage: Sold To',
    16: 'Made2Manage: Ship To',
    17: 'Made2Manage: Sales Rep',
    18: 'JD Edwards: Sold To',
    19: 'JD Edwards: Ship To',
    20: 'JD Edwards: Sales Rep',
    21: 'Sales partners',
    22: 'Branch w/o intercomp.billing',
    23: 'Branch with intercomp.billing',
    24: 'Branch with external billing',
    25: 'Assortment',
    26: 'Franchisee',
    27: 'One-time cust.(int.no.assgnmt)',
    28: 'One-time cust.(ext.no.assgnmt)',
    29: 'Customer (ext.number assgnmt)',
    30: 'Buying Group',
    31: 'Sales Territory/Area/Region',
    32: 'National Accounts/GPO',
    33: 'Integrated Health Network',
    34: 'Trace Customer',
    35: 'Dispensing Side ID',
    36: 'Intercompany Payer',
    37: 'GPO Hierarchy Node',
    38: 'Ultimate Destination Country',
    39: 'Test Acct with All Fields',
    40: 'Seller',
    99: 'Global',
};

export const SalesOrgType = {
    1: '0120',
    2: '0130',
    3: '0150',
};

export const SystemType = {
    1: 'SAP Apollo',
    2: 'SAP Olympus',
    3: 'Pointman',
    4: 'Made2Manage',
    5: 'JD Edwards',
    6: 'Salesforce',
    7: 'Majesty',
    99: 'Global',
};

export const SystemTypeShort = {
    1: 'apollo',
    2: 'olympus',
    3: 'ptmn',
    4: 'm2m',
    5: 'jded',
    6: 'salesforce',
    99: 'global',
};

export const CreditControlAreaType = {
    0: 'N/A',
    1: '1000',
    2: '1150',
};

export const DistributionChannelType = {
    1: '10',
};

export const DivisionType = { 1: '99' };

export const CompanyCodeType = { 1: '0120', 2: '0150' };

export const CategoryTypes = [
    'Distributor',
    'Self-Distributor',
    'OEM',
    'Direct',
    'Other',
    'Kitter',
    'Drop Ship',
];

export const jdeCategoryTypes = [
    'Distributor',
    'Self-Distributor',
    'OEM',
    'Direct',
    'Internal',
    'Kitter',
    'Drop Ship',
];

export const CategoryTypesById = {
    Distributor: 1,
    'Self-Distributor': 2,
    OEM: 3,
    Direct: 4,
    Other: 5,
    Kitter: 6,
    'Drop Ship': 7,
};
export const jdeCategoryTypesById = {
    Distributor: 1,
    'Self-Distributor': 2,
    OEM: 3,
    Direct: 4,
    Internal: 5,
    Kitter: 6,
    'Drop Ship': 7,
};

export const AbbreviatedStreet = {
    AVENUE: 'AVE',
    BOULEVARD: 'BLVD',
    CENTER: 'CTR',
    CIRCLE: 'CIR',
    DRIVE: 'DR',
    EXPRESSWAY: 'EXPY',
    HIGHWAY: 'HWY',
    ROAD: 'RD',
    ROUTE: 'RTE',
    STREET: 'ST',
    EAST: 'E',
    NORTH: 'N',
    NORTHEAST: 'NE',
    NORTHWEST: 'NW',
    SOUTH: 'S',
    SOUTHEAST: 'SE',
    SOUTHWEST: 'SW',
    WEST: 'W',
};

export const OlympusRoleTypes = {
    1: 'SoldTo',
    2: 'ShipTo',
    3: 'Payer',
    4: 'BillTo',
    23: 'IntercompanyCustomers',
    28: 'IntercompanyCustomersPayer',
    29: 'SalesRep',
};

export const CreateApolloPartnersColumns = [
    {
        Header: 'Partner Operation',
        accessor: 'PartnersOperation',
        filter: 'fuzzyText',
        editable: false,
    },
    {
        Header: 'Default Partner',
        accessor: 'IsDefaultPartner',
        filter: 'fuzzyText',
        editable: true,
    },
    {
        Header: 'Remove Partner',
        accessor: 'UnPartner',
        filter: 'fuzzyText',
        editable: true,
    },
    {
        Header: 'Customer Or Workflow Number',
        accessor: 'systemFields',
        filter: 'fuzzyText',
        editable: false,
    },
    {
        Header: 'Partner Number',
        accessor: 'PartnerNumber',
        filter: 'fuzzyText',
        editable: true,
    },
    {
        Header: 'Partner Role',
        accessor: 'PartnerFunctionTypeId',
        filter: 'fuzzyText',
        editable: true,
    },
    {
        Header: 'MDM Number',
        accessor: 'MdmNumber',
        editable: false,
        filter: 'fuzzyText',
    },
    {
        Header: 'Customer Name',
        accessor: 'PartnerCustomerName',
        editable: false,
        filter: 'fuzzyText',
    },
    {
        Header: 'Customer Address',
        accessor: 'PartnerCustomerAddress',
        editable: false,
        filter: 'fuzzyText',
    },
];

export const MyTaskApolloPartnersColumns = [
    // {
    //     Header: 'ID',
    //     accessor: 'ID',
    //     show:false,
    //     toggleHidden:true
    // },
    {
        Header: 'Partner Operation',
        accessor: 'PartnersOperation',
    },
    {
        Header: 'Default Partner',
        accessor: 'IsDefaultPartner',
        filter: 'fuzzyText',
        editable: true,
    },
    {
        Header: 'Remove Partner',
        accessor: 'UnPartner',
        filter: 'fuzzyText',
        editable: true,
    },
    {
        Header: 'Customer Or Workflow Number',
        accessor: 'workflownumber',
        filter: 'fuzzyText',
    },
    {
        Header: 'Partner Number',
        accessor: 'PartnerNumber',
        filter: 'fuzzyText',
        editable: true,
    },
    {
        Header: 'Partner Role',
        accessor: 'PartnerFunctionTypeId',
        filter: 'fuzzyText',
        editable: true,
    },
    {
        Header: 'MDM Number',
        accessor: 'MdmNumber',
        editable: false,
        filter: 'fuzzyText',
    },
    {
        Header: 'Customer Name',
        accessor: 'PartnerCustomerName',
        editable: false,
        filter: 'fuzzyText',
    },
    {
        Header: 'Customer Address',
        accessor: 'PartnerCustomerAddress',
        editable: false,
        filter: 'fuzzyText',
    },
];

export const ApolloPartners = [
    {
        WorkflowOrCustomerNumber: '010128576',
        PartnerCustomerNumber: '010128576',
        IsRemovable: 'False',
        PartnerFunctionRole: 'Sold To',
        PartnerFunctionTypeId: '1',
        MdmNumber: 'MDMCM000123',
        PartnerCustomerName: 'Cardinal West',
        PartnerCustomerAddress: '123 Main Street',
        IsDefaultPartner: 'False',
        UnPartner: 'True',
    },
    {
        WorkflowOrCustomerNumber: '010128576',
        PartnerCustomerNumber: '010128576',
        IsRemovable: 'False',
        PartnerFunctionRole: 'Ship To',
        PartnerFunctionTypeId: '2',
        MdmNumber: 'MDMCM000123',
        PartnerCustomerName: 'Cardinal West',
        PartnerCustomerAddress: '123 Main Street',
        IsDefaultPartner: 'True',
        UnPartner: 'False',
    },
    {
        WorkflowOrCustomerNumber: '010128576',
        PartnerCustomerNumber: '01007321',
        IsRemovable: 'True',
        PartnerFunctionRole: 'Bill To',
        PartnerFunctionTypeId: '3',
        MdmNumber: 'MDMCM000111',
        PartnerCustomerName: 'Cardinal AP',
        PartnerCustomerAddress: '123 Main Street',
        IsDefaultPartner: 'False',
        UnPartner: 'False',
    },
    {
        WorkflowOrCustomerNumber: '010128576',
        PartnerCustomerNumber: '01007321',
        IsRemovable: 'True',
        PartnerFunctionRole: 'Payer',
        PartnerFunctionTypeId: '4',
        MdmNumber: 'MDMCM000543',
        PartnerCustomerName: 'Adventist',
        PartnerCustomerAddress: '527 W 8th St',
        IsDefaultPartner: 'False',
        UnPartner: 'False',
    },
    {
        WorkflowOrCustomerNumber: '010128576',
        PartnerCustomerNumber: '7000070',
        IsRemovable: 'False',
        PartnerFunctionRole: 'Parcel Carrier',
        PartnerFunctionTypeId: '5',
        MdmNumber: '',
        PartnerCustomerName: 'Small Parcel Freight Rates',
        PartnerCustomerAddress: '',
        IsDefaultPartner: 'False',
        UnPartner: 'False',
    },
    {
        WorkflowOrCustomerNumber: '010128576',
        PartnerCustomerNumber: '7000060',
        IsRemovable: 'False',
        PartnerFunctionRole: 'LTL Carrier',
        PartnerFunctionTypeId: '6',
        MdmNumber: '',
        PartnerCustomerName: 'CZARLITE LTL FREIGHT RATES',
        PartnerCustomerAddress: '',
        IsDefaultPartner: 'False',
        UnPartner: 'False',
    },
];

export const CreateJdePartnersColumns = [
    {
        Header: 'Partner Operation',
        accessor: 'PartnersOperation',
        filter: 'fuzzyText',
        editable: false,
    },
    {
        Header: 'Remove Partner',
        accessor: 'UnPartner',
        filter: 'fuzzyText',
        editable: true,
    },
    {
        Header: 'Customer Or Workflow Number',
        accessor: 'WorkflowOrCustomerNumber',
        filter: 'fuzzyText',
        editable: false,
    },
    {
        Header: 'Partner Number',
        accessor: 'PartnerNumber',
        filter: 'fuzzyText',
        editable: true,
    },
    {
        Header: 'Partner Role',
        accessor: 'PartnerFunctionTypeId',
        filter: 'fuzzyText',
        editable: true,
    },
    // {
    //     Header: 'Partner Address',
    //     accessor: 'PartnerAddressTypeId',
    //     filter: 'fuzzyText',
    //     editable: true,
    // },
    {
        Header: 'MDM Number',
        accessor: 'MdmNumber',
        editable: false,
        filter: 'fuzzyText',
    },
    {
        Header: 'Customer Name',
        accessor: 'PartnerCustomerName',
        editable: false,
        filter: 'fuzzyText',
    },
    {
        Header: 'Customer Address',
        accessor: 'PartnerCustomerAddress',
        editable: false,
        filter: 'fuzzyText',
    },
];
