import React from 'react';
import { ScrollView, View, StyleSheet, TouchableOpacity } from 'react-native';
import {
    DimensionAware,
    getWindowHeight,
    getWindowWidth,
} from 'react-native-dimension-aware';
import { connect } from 'react-redux';
import { Colors, StyledText } from '../../../../theme/Index';
import { Flex, Button, Box, Text, TextInput } from '../../../../components/common';
import { FormInput } from '../../../../components/form';
import GlobalMdmFields from '../../../../components/GlobalMdmFields';
import DynamicSelect from '../../../../components/DynamicSelect';
import { CheckBoxItem } from '../../../../components/CheckBoxItem';
import debounce from 'debounce';
import { yupAllFieldsValidation, parseAndSanitise } from '../../../../constants/utils';
import {
    extendMdmFieldsRules,
    mytaskCustomerMasterRules,
    mytaskPricingRules,
    rejectRules,
} from '../../../../constants/FieldRules';
import { fetchDuns } from '../../../../appRedux/actions/Customer';
import { saveApolloUpdateCustomerMaster } from '../../../../appRedux/actions/UpdateFlowAction';
import {
    fetchCustomerMasterDropDownData,
    fetchTeamRoleFieldMapping,
} from '../../../../redux/DropDownDatas';
import Loading from '../../../../components/Loading';
import FlashMessage, { FlashMessages } from '../../../../components/FlashMessage';
import {
    RoleType,
    SalesOrgType,
    SystemType,
    DistributionChannelType,
    DivisionType,
    CompanyCodeType,
    CategoryTypesById,
} from '../../../../constants/WorkflowEnums';
import MultiColorProgressBar from '../../../../components/MultiColorProgressBar';
import {
    getStatusBarData,
    getFunctionalGroupData,
} from '../../../../appRedux/actions/Workflow';
import { getCustomerFromSAP } from '../../../../appRedux/actions/Customer';
import { removeMessage } from '../../../../appRedux/actions/Toast';
import { getMockUpdateTaskDetail } from '../../../../appRedux/sagas/config';
import DeltaField from '../../../../components/DeltaField';
import Modal from 'modal-enhanced-react-native-web';
import idx from 'idx';
import BreadCrumbs from '../../../../components/BreadCrumbs';
import FilesList from '../../../../components/FilesList';
import { MaterialIcons } from '@expo/vector-icons';
import { action } from '@storybook/addon-actions';
import * as moment from 'moment';
import { getStaticRequest } from '../../../../appRedux/actions';

class Page extends React.Component {
    constructor(props) {
        super(props);
        const editableProp = {
            inline: false,
            variant: 'solid',
            onChange: this.onFieldChange,
        };
        let { state } = this.props.location;
        let isWorkFlowReadOnly = this.props.location.state.isReadOnly;
        // let isWorkFlowReadOnly=false
        this.state = {
            modalVisible: false,
            isReject: false,
            isWorkFlowReadOnly: this.props.location.state.isReadOnly,
            WorkflowId: this.props.location.state.WorkflowId,
            TaskId: this.props.location.state.TaskId,
            loading: this.props.fetching,
            data: {},
            dropDownDatas: {},
            dropdowns: {},
            teamRoleFieldMapping: {},
            updatedFormData: {},
            formData: {
                AccountStatementTypeId: 2,
                SortKey: '009',
                PaymentMethods: 'C',
                AcctgClerk: '01',
                CreditLimit: 1,
                RejectionButton: false,
                displayINCOT2: isWorkFlowReadOnly ? true : false,
                display_LN: isWorkFlowReadOnly ? true : false,
            },
            formErrors: {},
            inputPropsForDefaultRules: { CustomerGroupTypeId: editableProp },
            selectedFiles: {},
            selectedFilesIds: [],
            files: [],
            fileinputkey: Date.now(),
            extendSalesOrgEnabled: false,
        };

        this.fetchDunsFields = debounce(this.props.fetchDuns, 200);
    }

    componentDidMount() {
        let { state: wf } = this.props.location;
        let postJson = {
            workflowId: wf.WorkflowId,
            fuctionalGroup: 'customermaster',
            taskId: wf.TaskId,
        };
        const { action, id } = this.props.match.params;
        this.props.getStatusBarData(postJson);
        this.props.getStaticRequest([15, 30, 17, 26, 42, 43, 82]);
        // this.props.getFunctionalGroupData(postJson);
        this.props.getCustomerFromSAP({
            WorkflowId: wf.WorkflowId,
            TaskId: wf.TaskId,
            CustomerNumber: '',
            DivisionTypeId: 0,
            SystemTypeId: 0,
            DistributionChannelTypeId: 0,
            CompanyCodeTypeId: '',
            SalesOrgTypeId: 0,
        });

        fetchCustomerMasterDropDownData(true, true).then(
            ({ normalizedDropDowns, dropdowns }) => {
                const data = normalizedDropDowns;
                let isReqPage = action === 'update-request';
                let pageReadOnlyFields = action !== 'update'; //set readonly for my-requests & extend sales org & BlockUnblock .
                this.setState({
                    dropDownDatas: data,
                    dropdowns: dropdowns,
                    readOnly: pageReadOnlyFields,
                    isRequestPage: isReqPage,
                    currentPage: action,
                    extendSalesOrgEnabled: action === 'extend-salesorg',
                });
            }
        );
        fetchTeamRoleFieldMapping().then((resp) => {
            const data = resp;
            this.setState({ teamRoleFieldMapping: data });
        });
    }

    componentWillReceiveProps(newProps) {
        if (
            newProps.bapi70CustData !== this.props.bapi70CustData &&
            !this.state.isWorkFlowReadOnly &&
            newProps.bapi70CustData !== {}
        ) {
            let customerdata = { ...newProps.bapi70CustData, ...this.state.formData };
            // validateFromSourceData -- we dont have to validate as this is done on Create Screen already.
            // Doing this  will alter the data coming from SAP

            var result = this.validateFromSourceData(customerdata);
            // formData: {
            //     ...this.state.formData,
            //     ...result.newStateValue,
            // },
            this.setState(
                {
                    inputPropsForDefaultRules: {
                        ...this.state.inputPropsForDefaultRules,
                        ...result.newStyleProps,
                    },
                },
                () => console.log('thisform', this.state.formData)
            );

            const { bapi70CustData } = newProps;
            if (bapi70CustData.IncoTerms1TypeId) {
                let allowedCategories = [1, 2, 3, 6];
                console.log(
                    bapi70CustData.IncoTerms1TypeId &&
                        !allowedCategories.includes(bapi70CustData.CategoryTypeId)
                );
                if (
                    bapi70CustData.IncoTerms1TypeId &&
                    !allowedCategories.includes(bapi70CustData.CategoryTypeId)
                ) {
                    this.validateIncoTerms(
                        bapi70CustData,
                        bapi70CustData.IncoTerms1TypeId.toString()
                    );
                }
            }
        }

        if (newProps.dunsFields != this.props.dunsFields) {
            this.setState({
                updatedFormData: {
                    ...this.state.updatedFormData,
                    NaicsCode: newProps.dunsFields.NaicsCode,
                    NaicsCodeDescription: newProps.dunsFields.NaicsCodeDescription,
                    SicCode: newProps.dunsFields.SicCode,
                    SicCode4: newProps.dunsFields.SicCode4,
                    SicCode6: newProps.dunsFields.SicCode6,
                    SicCode8: newProps.dunsFields.SicCode8,
                    SicCodeDescription: newProps.dunsFields.SicCodeDescription,
                    TaxNumber: newProps.dunsFields.TaxNumber,
                    VatRegNo: newProps.dunsFields.VatRegNo,
                },
            });
        }
    }

    setFormErrors = (errors) => {
        const { formErrors } = this.state;
        this.setState({ formErrors: errors }, () => window.scrollTo(0, 0));
    };

    onFieldChange = (value, e) => {
        const { name } = e.target;
        const { formData: prevFormData } = this.state;
        console.log(value, name, prevFormData);

        if (name === 'DunsNumber' && value.length > 0) {
            const { isDunsValid } = this.state;
            const {
                validity: { valid },
            } = e.target;

            console.log(valid, isDunsValid);
            if (valid !== isDunsValid)
                this.setState({
                    isDunsValid: valid,
                    formErrors: {
                        ...this.state.formErrors,
                        isDunsValid: !valid ? ' only numbers (0-9) are allowed.' : null,
                    },
                });
        }

        this.setState(
            {
                formData: {
                    ...this.state.formData,
                    [name]: value,
                },
                updatedFormData: {
                    ...this.state.updatedFormData,
                    [name]: value,
                },
            },
            () => {
                if (
                    name === 'CustomerClassTypeId' ||
                    name === 'IncoTerms1TypeId' ||
                    name === 'CustomerGroupTypeId'
                ) {
                    this.validateRules(name, value);
                } else if (
                    name === 'DunsNumber' &&
                    (prevFormData &&
                        prevFormData.DunsNumber &&
                        prevFormData.DunsNumber.trim()) !== (value && value.trim())
                ) {
                    this.fetchDunsFields({
                        UserId: localStorage.getItem('userId'),
                        DunsNumber: value,
                    });
                }
            }
        );
    };

    parseAndHandleFieldChange = (value, e) => {
        const { name } = e.target;
        const val = parseInt(value, 10);
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: val,
            },
        });
    };

    setFormDataValues = (name, value) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: value,
            },
        });
    };

    setInputPropsForDefaultRules = (field_name, property) => {
        this.setState({
            inputPropsForDefaultRules: {
                ...this.state.inputPropsForDefaultRules,
                [field_name]: property,
            },
        });
    };

    setBulkFormDataUpdate = (keyValdict) => {
        this.setState({
            formData: {
                ...this.state.formData,
                ...keyValdict,
            },
        });
    };

    // display or set input/dropdowns values based on rules
    validateRules = (stateKey, stateVal) => {
        const readOnlyInputprop = { inline: true, variant: 'outline' };
        const editInputProp = {
            inline: false,
            variant: 'solid',
            onChange: this.onFieldChange,
        };
        const readOnlyDropDown = { disabled: true };
        // check for CustomerPriceProcTypeId
        if (stateKey === 'CustomerClassTypeId') {
            var CC_val = stateVal;
            if (['1', '2', '3', '4', '5'].includes(CC_val)) {
                this.setFormDataValues('CustomerPriceProcTypeId', 2);
                this.setInputPropsForDefaultRules(
                    'CustomerPriceProcTypeId',
                    readOnlyDropDown
                );
            } else {
                this.setFormDataValues('CustomerPriceProcTypeId', '');
                this.setInputPropsForDefaultRules('CustomerPriceProcTypeId', {
                    disabled: false,
                });
            }
        }

        // console.log(stateKey, stateVal);
        // check for incoterms2
        if (stateKey === 'IncoTerms1TypeId') {
            this.validateIncoTerms(this.props.bapi70CustData, stateVal);
        }

        // check for AccountTypeId
        if (stateKey === 'CustomerGroupTypeId') {
            var cg_val = stateVal;
            const readOnlyDropDown = { disabled: true };
            if (cg_val === '1' || cg_val === '10') {
                this.setFormDataValues('AccountTypeId', '1');
                this.setInputPropsForDefaultRules('AccountTypeId', readOnlyDropDown);
            } else if (cg_val === '2' || cg_val === '7') {
                this.setFormDataValues('AccountTypeId', '2');
                this.setInputPropsForDefaultRules('AccountTypeId', readOnlyDropDown);
            } else if (
                cg_val === '3' ||
                cg_val === '4' ||
                cg_val === '6' ||
                cg_val === '11'
            ) {
                this.setFormDataValues('AccountTypeId', '3');
                this.setInputPropsForDefaultRules('AccountTypeId', readOnlyDropDown);
            } else if (cg_val === '8') {
                this.setFormDataValues('AccountTypeId', '6');
                this.setInputPropsForDefaultRules('AccountTypeId', readOnlyDropDown);
            } else {
                this.setFormDataValues('AccountTypeId', '');
                this.setInputPropsForDefaultRules('AccountTypeId', {
                    disabled: false,
                });
            }
        }
    };

    validateIncoTerms = (bapi70CustData, incoValue) => {
        const readOnlyDropDown = { disabled: !extendSalesOrgEnabled };
        const extendSalesOrgEnabled = this.state.extendSalesOrgEnabled;
        //for extend inco2 is always editable
        var INCOT1_val = incoValue;
        if (INCOT1_val === '1') {
            this.setFormDataValues('displayINCOT2', true);
        } else {
            this.setFormDataValues('displayINCOT2', false);
        }

        if (INCOT1_val === '1') {
            this.setBulkFormDataUpdate({
                isRequiredINCOT2: true,
                isReadonlyINCOT2: false,
                IncoTerms2: bapi70CustData.IncoTerms2 || '',
            });
        } else if (INCOT1_val === '3') {
            this.setBulkFormDataUpdate({
                isRequiredINCOT2: false,
                isReadonlyINCOT2: !extendSalesOrgEnabled,
                IncoTerms2: 'Carriage Paid To',
            });
            this.setInputPropsForDefaultRules('IncoTerms2', readOnlyDropDown);
        } else if (INCOT1_val === '4') {
            this.setBulkFormDataUpdate({
                isRequiredINCOT2: false,
                isReadonlyINCOT2: !extendSalesOrgEnabled,
                IncoTerms2: 'Delivered at Location',
            });
            this.setInputPropsForDefaultRules('IncoTerms2', readOnlyDropDown);
        } else if (INCOT1_val === '5') {
            this.setBulkFormDataUpdate({
                isRequiredINCOT2: false,
                isReadonlyINCOT2: !extendSalesOrgEnabled,
                IncoTerms2: 'Delivered Duty Paid',
            });
            this.setInputPropsForDefaultRules('IncoTerms2', readOnlyDropDown);
        } else if (INCOT1_val === '7') {
            this.setBulkFormDataUpdate({
                isRequiredINCOT2: false,
                isReadonlyINCOT2: !extendSalesOrgEnabled,
                IncoTerms2: 'Ex Works',
            });
            this.setInputPropsForDefaultRules('IncoTerms2', readOnlyDropDown);
        } else if (INCOT1_val === '8') {
            this.setBulkFormDataUpdate({
                isRequiredINCOT2: false,
                isReadonlyINCOT2: !extendSalesOrgEnabled,
                IncoTerms2: 'Free Carrier',
            });
            this.setInputPropsForDefaultRules('IncoTerms2', readOnlyDropDown);
        } else {
            this.setBulkFormDataUpdate({
                isRequiredINCOT2: false,
                isReadonlyINCOT2: false,
                IncoTerms2: bapi70CustData.IncoTerms2 || '',
            });
            this.setInputPropsForDefaultRules('IncoTerms2', { disabled: false });
        }
    };

    validateFromSourceData = (source_data) => {
        const readOnlyDropDown = { disabled: true };
        const newStateValue = {},
            newStyleProps = {};
        //check License Number
        let d_LN_RegionsList = [
            'DE',
            'FL',
            'GA',
            'HI',
            'IL',
            'IN',
            'KS',
            'MA',
            'ME',
            'MN',
            'MO',
            'NC',
            'ND',
            'NE',
            'NM',
            'OH',
            'OK',
            'RI',
            'SD',
            'VT',
            'WA',
            'WV',
        ];
        if (
            source_data.RoleTypeId === 1 ||
            source_data.RoleTypeId === 2 ||
            source_data.RoleTypeId === 5 ||
            source_data.RoleTypeId === 6
        ) {
            newStateValue['display_LN'] = true;
            if (source_data.RoleTypeId === 5) {
                newStateValue['License'] = 'R-SALES REP EXEMPT';
                newStateValue['LicenseExpDate'] = '9999-12-31';
            } else if (source_data.Country != 'US') {
                newStateValue['License'] = 'I-INTERNATIONAL EXEMPT';
                newStateValue['LicenseExpDate'] = '9999-12-31';
            } else if (d_LN_RegionsList.includes(source_data.Region)) {
                newStateValue['License'] = 'S-IN STATE EXEMPT APPROVAL SM';
                newStateValue['LicenseExpDate'] = '9999-12-31';
            }
        }
        //check transportation zone
        let d_TransporationZone_RegionList = ['NS', 'NT', 'NU', 'PE', 'SK', 'YT'];
        if (source_data.Country === 'US' || source_data.Country === 'PR') {
            newStateValue[
                'TransporationZone'
            ] = source_data.PostalCode.toString().substring(0, 3);
        } else if (
            source_data.Country === 'CA' &&
            d_TransporationZone_RegionList.includes(source_data.Region)
        ) {
            newStateValue['TransporationZone'] = 'INTL';
        } else if (source_data.Country === 'CA') {
            newStateValue['TransporationZone'] = source_data.Region;
        } else {
            newStateValue['TransporationZone'] = 'INTL';
        }

        //check price list
        if (source_data.Country != 'US') {
            newStateValue['PriceListTypeId'] = 5;
            newStyleProps['PriceListTypeId'] = { disabled: false }; //ITMDMK1707
        } else {
            newStateValue['PriceListTypeId'] = 0;
            newStyleProps['PriceListTypeId'] = { disabled: false };
        }

        //check Customer group --as per #ITMDMK1756 customergroup is editable in all cases.
        if (source_data.CategoryTypeId != undefined) {
            let categoryTypeid = parseInt(source_data.CategoryTypeId);
            if (categoryTypeid === 2) {
                //if self-distributor
                newStateValue['CustomerGroupTypeId'] = 5;
                newStyleProps['CustomerGroupTypeId'] = { disabled: false }; // readOnlyDropDown;;;
            } else if (categoryTypeid === 3 || categoryTypeid === 6) {
                //if oem or kitter
                newStateValue['CustomerGroupTypeId'] = 9;
                newStyleProps['CustomerGroupTypeId'] = { disabled: false }; // readOnlyDropDown;;;
            } else if (categoryTypeid === 7) {
                // if dropship
                newStateValue['AccountTypeId'] = 3;
                newStyleProps['AccountTypeId'] = { disabled: false }; // readOnlyDropDown;;;
                newStateValue['CustomerGroupTypeId'] = 11;
                newStyleProps['CustomerGroupTypeId'] = { disabled: false }; // readOnlyDropDown;;;
            }
        }
        //check shipping conditions
        if (source_data.Country != 'US') {
            newStateValue['ShippingConditionsTypeId'] = 2;
            newStyleProps['ShippingConditionsTypeId'] = readOnlyDropDown;
        } else {
            newStateValue['ShippingConditionsTypeId'] = 1;
            newStyleProps['ShippingConditionsTypeId'] = readOnlyDropDown;
        }

        return {
            newStateValue,
            newStyleProps,
        };
    };

    handleDefaultValues = () => {
        let { formData } = this.state;
        let defaultValues = {};
        if (formData.SortKey === undefined || formData.SortKey.trim().length === 0) {
            defaultValues['SortKey'] = '009';
        }
        if (
            formData.PaymentMethods === undefined ||
            formData.PaymentMethods.trim().length === 0
        ) {
            defaultValues['PaymentMethods'] = 'c';
        }
        if (
            formData.AcctgClerk === undefined ||
            formData.AcctgClerk.trim().length === 0
        ) {
            defaultValues['AcctgClerk'] = '01';
        }
        return defaultValues;
    };

    // isEmpty = (str) => {
    //     // checking if a string is empty, null or undefined
    //     return (!str || 0 === str.length);
    // }

    createDeltas = (origData, newData) => {
        let addedKeys = [];
        var denormDeltas = this.props.denormalizedDeltas || [];
        for (const key in newData) {
            //check if orig data & new data doesnt duplicate empty string or null
            let orgiDataValue =
                origData[key] != null
                    ? origData[key].length != 0
                        ? origData[key]
                        : ''
                    : '';

            let newDataValue =
                newData[key] != null
                    ? newData[key].length != 0
                        ? newData[key].toString().trim()
                        : ''
                    : '';

            if (orgiDataValue != newDataValue && !addedKeys.includes(key)) {
                let delta = {};
                delta['name'] = key;
                delta['originalValue'] = origData[key];
                delta['updatedValue'] = newData[key];
                addedKeys.push(key);
                denormDeltas.push(delta);
            }
        }
        return denormDeltas;
    };

    handleFormSubmission = (schema) => {
        let {
                TaskId,
                WorkflowId,
                formData,
                updatedFormData,
                selectedFiles,
                selectedFilesIds,
            } = this.state,
            castedFormData = {},
            postData = {};
        let { action } = this.props.match.params;
        const { bapi70CustData = {} } = this.props;

        const {
            RejectionReason: RejectReason = '',
            AdditionalNotes = '',
            ...updatedData
        } = updatedFormData;

        try {
            let WorkflowTaskModel = {
                RejectReason,
                AdditionalNotes,
                TaskId,
                UserId: localStorage.getItem('userId'),
                WorkflowId,
            };
            if (action === 'update') {
                WorkflowTaskModel['WorkflowTaskOperationType'] = !formData[
                    'RejectionButton'
                ]
                    ? 1
                    : 4;
            } else if (action === 'extend-salesorg') {
                WorkflowTaskModel['WorkflowTaskOperationType'] = !formData[
                    'RejectionButton'
                ]
                    ? 1
                    : 2;
            } else if (action === 'block-unblock') {
                WorkflowTaskModel['WorkflowTaskOperationType'] = !formData[
                    'RejectionButton'
                ]
                    ? 1
                    : 2;
            }

            var delta = this.props.denormalizedDeltas || [];
            if (Object.keys(this.state.updatedFormData).length !== 0)
                delta = this.createDeltas(bapi70CustData, updatedData);
            //check for payment methods,sortkey,acntg clerk
            if (bapi70CustData['SortKey'].length <= 0) {
                let newdelta = {},
                    key = 'SortKey';
                newdelta['name'] = key;
                newdelta['originalValue'] = bapi70CustData[key];
                newdelta['updatedValue'] = '009';
                delta.push(newdelta);
            }
            if (bapi70CustData['PaymentMethods'].length <= 0) {
                let newdelta = {},
                    key = 'PaymentMethods';
                newdelta['name'] = key;
                newdelta['originalValue'] = bapi70CustData[key];
                newdelta['updatedValue'] = 'C';
                delta.push(newdelta);
            }
            if (bapi70CustData['AcctgClerk'].length <= 0) {
                let newdelta = {},
                    key = 'AcctgClerk';
                newdelta['name'] = key;
                newdelta['originalValue'] = bapi70CustData[key];
                newdelta['updatedValue'] = '01';
                delta.push(newdelta);
            }

            if (action === 'extend-salesorg') {
                let newdelta = {},
                    key = 'IncoTerms2';
                newdelta['name'] = key;
                newdelta['originalValue'] = bapi70CustData[key];
                newdelta['updatedValue'] = this.state.formData[key];
                if (
                    this.state.formData[key] &&
                    newdelta['originalValue'] !== newdelta['updatedValue']
                )
                    delta.push(newdelta);
            }

            postData['formData'] = {
                WorkflowTaskModel,
                action,
                Deltas: delta || null,
            };
            postData['files'] = selectedFilesIds.map((id) => selectedFiles[id]);
            // console.log('state', this.state, 'orig', bapi70CustData, 'pos', postData);
            this.props.saveApolloUpdateCustomerMaster(postData);

            this.resetForm();
            this.scrollToTop();
        } catch (error) {
            console.log('customer master form approval error');
        }
    };

    onSubmit = (event, reject, schema) => {
        const { action } = this.props.match.params;
        const { bapi70CustData = {} } = this.props;

        let isContractEnabled = false;
        //check iff contract fields to be displayed or not
        if (bapi70CustData && bapi70CustData.CategoryTypeId != undefined) {
            let categoryTypeid = parseAndSanitise(bapi70CustData.CategoryTypeId);
            if (
                categoryTypeid === 1 ||
                categoryTypeid === 2 ||
                categoryTypeid === 3 ||
                categoryTypeid === 6
            ) {
                isContractEnabled = true;
            }
        }

        let {
            formData,
            teamRoleFieldMapping,
            selectedFilesIds,
            selectedFiles,
        } = this.state;
        let defaults = this.handleDefaultValues();
        let fileErrors = {};
        let errors = false;
        selectedFilesIds.map((id) => {
            if (selectedFiles[id] && selectedFiles[id].DocumentType <= 0) {
                fileErrors[id] = 'Document Type Required for file';
                errors = true;
            }
        });
        this.setState({ fileErrors, isFileErrors: errors });

        const CustomerMasterTeamFields =
            idx(teamRoleFieldMapping, (_) => _.CustomerMasterTeamFields) || [];
        const RoleTypeId = bapi70CustData.RoleTypeId;
        const SystemTypeId = bapi70CustData.SystemType;
        const allowedFieldList = CustomerMasterTeamFields.filter(
            (item) => item.RoleTypeId === RoleTypeId && item.SystemTypeId === SystemTypeId
        );

        // console.log(allowedFieldList);

        const {
            AccountTypeId,
            CustomerClassTypeId,
            ReconAccountTypeId,
            ShippingCustomerTypeId,
            WorkflowTitle,
            CustomerPriceProcTypeId,
            SalesOfficeTypeId,
            CustomerGroupTypeId,
            PPCustProcTypeId,
            TaxClassificationTypeId,
            PriceListTypeId,
            DeliveryPriorityTypeId,
            ShippingConditionsTypeId,
            IncoTerms1TypeId,
            IncoTerms2,
            AcctAssignmentGroupTypeId,
            TransporationZone,
        } = bapi70CustData;

        const data = {};
        let validationSchema = schema;
        const CustomerData = {
            AccountTypeId,
            CustomerClassTypeId,
            ReconAccountTypeId,
            ShippingCustomerTypeId,
            WorkflowTitle,
            CustomerPriceProcTypeId,
            SalesOfficeTypeId,
            CustomerGroupTypeId,
            PPCustProcTypeId,
            TaxClassificationTypeId,
            PriceListTypeId,
            DeliveryPriorityTypeId,
            ShippingConditionsTypeId,
            IncoTerms1TypeId,
            IncoTerms2,
            AcctAssignmentGroupTypeId,
            TransporationZone,
            isContractEnabled,
            ...this.state.formData,
            Role: allowedFieldList[0].Role,
            RoleTypeId: allowedFieldList[0].RoleTypeId,
        };

        console.log(CustomerData);
        this.setState(
            {
                modalVisible: false,
                formData: {
                    ...this.state.formData,
                    ...defaults,
                    RejectionButton: reject,
                },
            },
            () => {
                if (reject)
                    yupAllFieldsValidation(
                        this.state.updatedFormData,
                        validationSchema,
                        (...rest) => {
                            if (this.state.isFileErrors === false)
                                this.handleFormSubmission(...rest);
                        },
                        this.setFormErrors
                    );
                else if (this.state.isFileErrors === false) {
                    if (action === 'extend-salesorg') {
                        yupAllFieldsValidation(
                            CustomerData,
                            extendMdmFieldsRules,
                            (...rest) => {
                                this.handleFormSubmission(...rest);
                            },
                            this.setFormErrors
                        );
                    } else {
                        this.handleFormSubmission(schema);
                    }
                }
            }
        );
    };

    selectFiles = (events) => {
        event.preventDefault();

        const { selectedFilesIds, selectedFiles } = this.state;
        const id = events.target.files[0].name;

        this.setState({
            selectedFiles: {
                ...selectedFiles,
                [id]: {
                    data: events.target.files[0],
                    DocumentName: events.target.files[0].name,
                    DocumentType: 0,
                },
            },
            selectedFilesIds: [...selectedFilesIds, id],
            filename: events.target.files[0].name,
            fileinputkey: Date.now(),
        });
    };

    removeFile = (id) => {
        event.preventDefault();

        var selectedFilesDict = this.state.selectedFiles;
        var selectedFilesIdsList = this.state.selectedFilesIds;
        // remove the existing state
        delete selectedFilesDict[id];
        selectedFilesIdsList = selectedFilesIdsList.filter((fileid) => fileid !== id);

        this.setState({
            selectedFiles: selectedFilesDict,
            selectedFilesIds: selectedFilesIdsList,
        });
    };

    _renderModalContent = () => (
        <Box
            my={4}
            mx="30%"
            backgroundColor="white"
            borderRadius="4px"
            borderColor="rgba(0, 0, 0, 0.1)">
            <Text my={3} fontWeight="regular" color="lightBlue" fontSize={24} pl={4}>
                Submit Workflow
            </Text>
            <Box px="0px" pb="22px" justifyContent="center">
                <Box alignItems="center" justifyContent="center">
                    {!this.state.isReject ? (
                        <FormInput
                            label="Additional Notes"
                            multiline
                            numberOfLines={2}
                            maxLength={250}
                            name="AdditionalNotes"
                            type="text"
                            onChange={this.onFieldChange}
                            error={
                                this.state.formErrors
                                    ? this.state.formErrors['AdditionalNotes']
                                    : null
                            }
                            value={
                                this.state.formData &&
                                this.state.formData['AdditionalNotes']
                            }
                            variant={'solid'}
                        />
                    ) : (
                        <FormInput
                            label="Rejection Reason"
                            name="RejectionReason"
                            maxLength={250}
                            onChange={this.onFieldChange}
                            error={' *this field is required'}
                            multiline
                            numberOfLines={2}
                            type="text"
                            value={
                                this.state.formData &&
                                this.state.formData['RejectionReason']
                            }
                            variant="solid"
                        />
                    )}
                </Box>
                <Flex
                    justifyEnd
                    alignCenter
                    alignSelf="flex-end"
                    style={{
                        paddingTop: 15,
                        flexDirection: 'row',
                        alignItems: 'center',
                        paddingLeft: 10,
                        paddingRight: 15,
                        marginTop: 20,
                        marginBottom: 10,
                        marginHorizontal: 25,
                    }}>
                    <Button
                        onPress={(event) => {
                            if (this.state.isReject)
                                this.setState(
                                    {
                                        isReject: false,
                                    },
                                    () => this.onSubmit(event, true, rejectRules)
                                );
                            else this.onSubmit(event, false, mytaskCustomerMasterRules);
                        }}
                        title="Submit"
                    />
                    <Button
                        title="Cancel"
                        onPress={() => this.setState({ modalVisible: null })}
                    />
                </Flex>
            </Box>
        </Box>
    );

    onSubmitReject = (event, reject, schema) => {
        let { formData } = this.state;
        let defaults = this.handleDefaultValues();
        this.setState(
            {
                formData: {
                    ...this.state.formData,
                    ...defaults,
                    RejectionButton: reject,
                },
            },
            () => {}
        );
    };

    scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    resetForm = () => {
        Object.keys(this.state.formData).map((key) => {
            var myitem = key;
            if (
                ![
                    'OrderCombination',
                    'PaymentHistoryRecord',
                    'RejectionButton',
                    'displayINCOT2',
                    'display_LN',
                ].includes(myitem)
            ) {
                this.setState({
                    formData: {
                        ...this.state.formData,
                        [key]: '',
                    },
                });
            } else {
                if (!['displayINCOT2', 'display_LN'].includes(myitem)) {
                    this.setState({
                        formData: {
                            ...this.state.formData,
                            [key]: false,
                        },
                    });
                }
            }
        });
        Object.keys(this.state.formErrors).map((key) => {
            this.setState({
                formErrors: {
                    [key]: '',
                },
            });
        });
    };

    getDropDownValue = (name) => {
        const { bapi70CustData = {}, deltas = {} } = this.props;
        const { dropDownDatas } = this.state;

        if (deltas[name]) {
            return idx(
                dropDownDatas,
                (_) => _[name][deltas[name].UpdatedValue].description
            );
        } else if (bapi70CustData) {
            return idx(dropDownDatas, (_) => _[name][bapi70CustData[name]]);
        }
    };

    getValue = (name) => {
        const { CustomerData: bapi70CustData = {}, deltas = {} } = this.props;

        if (deltas[name]) {
            return deltas[name].UpdatedValue;
        } else if (bapi70CustData) {
            return bapi70CustData[name];
        }
    };

    render() {
        const {
            width,
            location,
            bapi70CustData = {},
            deltas = {},
            alert = {},
            statusBarData,
            TasksStatusByTeamId = null,
            fetching,
        } = this.props;

        const CustomerData = { ...this.state.formData, ...bapi70CustData };
        const Deltas = deltas;
        const {
            dropDownDatas,
            dropdowns = {},
            teamRoleFieldMapping,
            inputPropsForDefaultRules,
            selectedFilesIds,
            selectedFiles,
            isRequestPage,
            currentPage,
        } = this.state;

        let showInlineAstreik = currentPage !== 'update';
        const inputsProps = {
            variant: 'outline',
            inline: true,
            type: 'text',
        };
        const {
            WorkflowDocuments: files = [],
            RequestorName,
            A,
        } = this.props.bapi70CustData;
        const { state } = location;

        const workflow = {
            ...state,
            isReadOnly:
                TasksStatusByTeamId === null ||
                !(
                    state.WorkflowStateType === 2 &&
                    TasksStatusByTeamId[3].WorkflowTaskStateTypeId === 2
                ),
        };

        const requestDateUtc = moment(idx(bapi70CustData, (_) => _.DateOfRequest));
        const localDate = moment(requestDateUtc).local();
        const requestDate = localDate.format('YYYY-MM-DD hh:mm:ss A');

        const CustomerMasterTeamFields =
            idx(teamRoleFieldMapping, (_) => _.CustomerMasterTeamFields) || [];
        const RoleTypeId = bapi70CustData.RoleTypeId;
        const SystemTypeId = bapi70CustData.SystemType;
        const allowedFieldList = CustomerMasterTeamFields.filter(
            (item) => item.RoleTypeId === RoleTypeId && item.SystemTypeId === SystemTypeId
        );
        let allowedFields = undefined;
        if (allowedFieldList && allowedFieldList.length > 0) {
            allowedFields = allowedFieldList[0].Fields;
        }
        const isWorkFlowReadOnly = workflow.isReadOnly;

        const inputReadonlyProps = workflow.isReadOnly ? { disabled: true } : null;

        const showFunctionalDetail =
            state.isReadOnly && CustomerData === null ? { display: 'none' } : null;

        const enableDisplay = isWorkFlowReadOnly ? { display: 'none' } : null;
        let { action } = this.props.match.params;

        var bgcolor = alert.color || '#FFF';
        if (fetching) {
            return <Loading />;
        }

        const inputProps = {
            variant: 'outline',
            inline: true,
            type: 'text',
        };

        let disp_input = true;
        if (bapi70CustData && bapi70CustData.CategoryTypeId !== undefined) {
            var source_category = parseInt(bapi70CustData.CategoryTypeId);
            //direct , dropship , other
            if (
                source_category === CategoryTypesById['Distributor'] ||
                source_category === CategoryTypesById['OEM'] ||
                source_category === CategoryTypesById['Kitter'] ||
                source_category === CategoryTypesById['Self-Distributor']
            ) {
                disp_input = false;
            }
        }

        const pageProps = isWorkFlowReadOnly
            ? inputProps
            : {
                  inline: false,
                  readOnly: false,
                  onBlur: this.props.onFieldChange,
              };

        let extendSalesOrgEnabled =
            this.state.extendSalesOrgEnabled && !isWorkFlowReadOnly;
        console.log('CustomerData', CustomerData);
        return (
            <ScrollView
                keyboardShouldPersistTaps="always"
                style={{
                    backgroundColor: '#EFF3F6',
                    paddingBottom: 75,
                }}>
                <Modal isVisible={this.state.modalVisible}>
                    {this._renderModalContent()}
                </Modal>
                <FlashMessages
                    toasts={this.props.toasts}
                    onDismiss={this.props.removeMessage}
                />
                {alert.display && (
                    <FlashMessage
                        bg={{ backgroundColor: bgcolor }}
                        message={alert.message}
                    />
                )}

                <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between">
                    <Text
                        style={{
                            fontWeight: '500',
                            fontSize: 24,
                            color: Colors.lightBlue,
                            marginTop: 25,
                            marginBottom: 20,
                            paddingLeft: 45,
                        }}>
                        MY TASKS - {state.Header}
                    </Text>
                    <Box
                        marginTop="25px"
                        flex={3 / 4}
                        flexDirection="row-reverse"
                        alignItems="flex-end">
                        <MultiColorProgressBar readings={statusBarData} />
                    </Box>
                </Box>
                <Box marginTop="25px" flexDirection="row-reverse" alignItems="flex-end">
                    <StyledText
                        style={{
                            fontWeight: '600',
                            paddingRight: '20px',
                            fontSize: '16px',
                            color: '#10206C',
                        }}>
                        Requestor : {RequestorName}{' '}
                    </StyledText>
                </Box>
                <Box flexDirection="row-reverse" alignItems="flex-end">
                    <StyledText
                        style={{
                            fontWeight: '600',
                            paddingRight: '20px',
                            fontSize: '16px',
                            color: '#10206C',
                        }}>
                        {' '}
                        Date of Request: {requestDate}{' '}
                    </StyledText>
                </Box>
                <View
                    style={{
                        flex: 1,
                        paddingHorizontal: width < 1440 ? 60 : width * 0.1,
                        paddingBottom: 10,
                    }}>
                    <Box fullHeight my={2}>
                        <Box flexDirection="row" justifyContent="space-around" my={4}>
                            <FormInput
                                px="25px"
                                flex={1 / 4}
                                label="Workflow Title"
                                name="WorkflowTitle"
                                maxLength={40}
                                variant="outline"
                                type="text"
                                value={CustomerData && CustomerData.WorkflowTitle}
                            />
                            <FormInput
                                px="25px"
                                flex={1 / 4}
                                label="Workflow Number"
                                name="workflow-number"
                                variant="outline"
                                type="text"
                                value={state.WorkflowId}
                            />
                            {CustomerData && CustomerData.SystemRecordId && (
                                <FormInput
                                    px="25px"
                                    flex={1 / 4}
                                    label="Customer Number"
                                    name="CustomerNumber"
                                    variant="outline"
                                    type="text"
                                    value={CustomerData && CustomerData.SystemRecordId}
                                />
                            )}
                            {CustomerData && CustomerData.MdmNumber && (
                                <TouchableOpacity
                                    onPress={() =>
                                        this.props.history.push(
                                            `/search-results/${CustomerData.MdmNumber}`
                                        )
                                    }>
                                    <FormInput
                                        px="25px"
                                        flex={1 / 4}
                                        label="MDM Number"
                                        name="mdm-number"
                                        variant="outline"
                                        type="text"
                                        value={CustomerData.MdmNumber}
                                        deltas={Deltas}
                                    />
                                </TouchableOpacity>
                            )}
                        </Box>

                        <GlobalMdmFields
                            formData={{
                                ...CustomerData,
                                DunsNumber:
                                    this.state.formData.DunsNumber ||
                                    CustomerData.DunsNumber,
                            }}
                            readOnly
                            dunsEditable={!workflow.isReadOnly}
                            formErrors={this.state.formErrors}
                            onFieldChange={this.onFieldChange}
                            deltas={deltas}
                            staticDropDownData={this.props.staticData}
                        />

                        <Text
                            mt="42px"
                            mb="20px"
                            fontWeight="regular"
                            color="lightBlue"
                            fontSize={24}
                            pl={4}>
                            SYSTEM FIELDS
                        </Text>
                        <Box flexDirection="row" justifyContent="center">
                            <Box width={1 / 2} mx="auto" alignItems="center">
                                {Deltas['SystemType'] ? (
                                    <DeltaField delta={Deltas['SystemType']} />
                                ) : (
                                    <FormInput
                                        label="System"
                                        name="System"
                                        inline
                                        variant="outline"
                                        type="text"
                                        value={
                                            SystemType[
                                                CustomerData && CustomerData.SystemType
                                            ]
                                        }
                                    />
                                )}
                                {Deltas['RoleTypeId'] ? (
                                    <DeltaField delta={Deltas['RoleTypeId']} />
                                ) : (
                                    <FormInput
                                        label="Role"
                                        name="Role"
                                        inline
                                        variant="outline"
                                        type="text"
                                        value={
                                            RoleType[
                                                CustomerData && CustomerData.RoleTypeId
                                            ]
                                        }
                                    />
                                )}

                                {Deltas['SalesOrgTypeId'] ? (
                                    <DeltaField delta={Deltas['SalesOrgTypeId']} />
                                ) : (
                                    <FormInput
                                        label="Sales Org"
                                        name="SalesOrg"
                                        inline
                                        variant="outline"
                                        type="text"
                                        value={
                                            SalesOrgType[
                                                CustomerData &&
                                                    CustomerData.SalesOrgTypeId
                                            ]
                                        }
                                    />
                                )}
                                {action === 'extend-salesorg' ? (
                                    <FormInput
                                        label="Target Sales Org"
                                        name="TargetSalesOrg"
                                        team="system"
                                        inline
                                        variant="outline"
                                        type="text"
                                        value={
                                            SalesOrgType[
                                                CustomerData &&
                                                    CustomerData.TargetSalesOrgTypeId
                                            ]
                                        }
                                    />
                                ) : null}
                            </Box>
                            <Box width={1 / 2} mx="auto" alignItems="center">
                                {Deltas['DistributionChannelTypeId'] ? (
                                    <DeltaField
                                        delta={Deltas['DistributionChannelTypeId']}
                                    />
                                ) : (
                                    <FormInput
                                        label="Distribution Channel"
                                        name="DistributionChannel"
                                        inline
                                        variant="outline"
                                        type="text"
                                        value={
                                            DistributionChannelType[
                                                CustomerData &&
                                                    CustomerData.DistributionChannelTypeId
                                            ]
                                        }
                                    />
                                )}
                                {Deltas['DivisionTypeId'] ? (
                                    <DeltaField delta={Deltas['DivisionTypeId']} />
                                ) : (
                                    <FormInput
                                        label="Division"
                                        name="DivisionTypeId"
                                        inline
                                        variant="outline"
                                        type="text"
                                        value={
                                            DivisionType[
                                                CustomerData &&
                                                    CustomerData.DivisionTypeId
                                            ]
                                        }
                                    />
                                )}
                                {Deltas['CompanyCodeTypeId'] ? (
                                    <DeltaField delta={Deltas['CompanyCodeTypeId']} />
                                ) : (
                                    <FormInput
                                        label="CompanyCode"
                                        name="CompanyCodeTypeId"
                                        inline
                                        variant="outline"
                                        type="text"
                                        value={
                                            CompanyCodeType[
                                                CustomerData &&
                                                    CustomerData.CompanyCodeTypeId
                                            ]
                                        }
                                    />
                                )}
                                <FormInput
                                    label="Purpose of Request"
                                    name="Purpose"
                                    value={
                                        (CustomerData && CustomerData.Purpose) ||
                                        (Deltas['Purpose'] &&
                                            Deltas['Purpose'].UpdatedValue) ||
                                        (CustomerData.RetrievedBlockUnblockData &&
                                            CustomerData.RetrievedBlockUnblockData[
                                                'Purpose'
                                            ])
                                    }
                                    multiline
                                    numberOfLines={
                                        CustomerData.Purpose &&
                                        CustomerData.Purpose.length > 36
                                            ? 3
                                            : 1
                                    }
                                    style={{ lineHeight: '2.5' }}
                                    inline
                                    variant="outline"
                                    maxLength={1000}
                                    type="text"
                                />
                            </Box>
                        </Box>
                        <Box {...showFunctionalDetail}>
                            <Text
                                mt="42px"
                                mb="20px"
                                fontWeight="regular"
                                color="lightBlue"
                                fontSize={24}
                                pl={4}>
                                CUSTOMER MASTER FIELDS
                            </Text>
                            <Box flexDirection="row" justifyContent="center">
                                <Box width={1 / 2} mx="auto" alignItems="center">
                                    {Deltas['TransporationZone'] ? (
                                        <DeltaField delta={Deltas['TransporationZone']} />
                                    ) : (
                                        <FormInput
                                            label="Transportation Zone"
                                            name="TransporationZone"
                                            maxLength={10}
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes(
                                                    'TransporationZone'
                                                )
                                            }
                                            value={
                                                CustomerData &&
                                                CustomerData.TransporationZone
                                            }
                                            {...inputsProps}
                                        />
                                    )}
                                    {Deltas['License'] ? (
                                        <DeltaField delta={Deltas['License']} />
                                    ) : (
                                        <FormInput
                                            label="License Number"
                                            name="License"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes('License')
                                            }
                                            value={CustomerData && CustomerData.License}
                                            {...inputsProps}
                                        />
                                    )}
                                    {Deltas['LicenseExpDate'] ? (
                                        <DeltaField delta={Deltas['LicenseExpDate']} />
                                    ) : (
                                        <FormInput
                                            label="License Expiration Date"
                                            name="LicenseExpDate"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes('LicenseExpDate')
                                            }
                                            value={
                                                CustomerData &&
                                                CustomerData.LicenseExpDate
                                            }
                                            {...inputsProps}
                                        />
                                    )}
                                    {Deltas['SearchTerm1'] ? (
                                        <DeltaField
                                            label="Search Term 1"
                                            delta={Deltas['SearchTerm1']}
                                        />
                                    ) : (
                                        <FormInput
                                            label="Search Term 1"
                                            name="SearchTerm1"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes('SearchTerm1')
                                            }
                                            value={
                                                CustomerData && CustomerData.SearchTerm1
                                            }
                                            {...inputsProps}
                                        />
                                    )}
                                    {Deltas['SearchTerm2'] ? (
                                        <DeltaField delta={Deltas['SearchTerm2']} />
                                    ) : (
                                        <FormInput
                                            label="Search Term 2"
                                            name="SearchTerm2"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes('SearchTerm2')
                                            }
                                            value={
                                                CustomerData && CustomerData.SearchTerm2
                                            }
                                            {...inputsProps}
                                        />
                                    )}
                                    {Deltas['CustomerClassTypeId'] ? (
                                        <DeltaField
                                            delta={Deltas['CustomerClassTypeId']}
                                            label="Customer Class "
                                        />
                                    ) : (
                                        <FormInput
                                            label="Customer Class "
                                            name="CustomerClassTypeId"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes(
                                                    'CustomerClassTypeId'
                                                )
                                            }
                                            value={
                                                CustomerData &&
                                                idx(
                                                    dropDownDatas,
                                                    (_) =>
                                                        _.CustomerClassTypeId[
                                                            CustomerData
                                                                .CustomerClassTypeId
                                                        ]
                                                )
                                            }
                                            {...inputsProps}
                                        />
                                    )}
                                    {Deltas['CustomerPriceProcTypeId'] ? (
                                        <DeltaField
                                            delta={Deltas['CustomerPriceProcTypeId']}
                                            label="Customer Price Proc"
                                        />
                                    ) : (
                                        <DynamicSelect
                                            readOnly={!extendSalesOrgEnabled}
                                            team="customermaster"
                                            arrayOfData={
                                                dropdowns.CustomerPriceProcTypeId
                                            }
                                            label="Customer Price Proc"
                                            name="CustomerPriceProcTypeId"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes(
                                                    'CustomerPriceProcTypeId'
                                                )
                                            }
                                            value={
                                                this.state.formData[
                                                    'CustomerPriceProcTypeId'
                                                ] ||
                                                (CustomerData &&
                                                    CustomerData.CustomerPriceProcTypeId)
                                            }
                                            isRequired
                                            formErrors={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                          'CustomerPriceProcTypeId'
                                                      ]
                                                    : null
                                            }
                                            onFieldChange={this.onFieldChange}
                                            showInlineRequired={showInlineAstreik}
                                        />
                                    )}
                                    {Deltas['IndustryCodeTypeId'] ? (
                                        <DeltaField
                                            delta={Deltas['IndustryCodeTypeId']}
                                        />
                                    ) : (
                                        <FormInput
                                            label="Industry Code 1"
                                            name="IndustryCodeTypeId"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes(
                                                    'IndustryCodeTypeId'
                                                )
                                            }
                                            value={
                                                CustomerData &&
                                                idx(
                                                    dropDownDatas,
                                                    (_) =>
                                                        _.IndustryCodeTypeId[
                                                            CustomerData
                                                                .IndustryCodeTypeId
                                                        ]
                                                )
                                            }
                                            {...inputsProps}
                                        />
                                    )}

                                    {Deltas['ReconAccountTypeId'] ? (
                                        <DeltaField
                                            delta={Deltas['ReconAccountTypeId']}
                                        />
                                    ) : (
                                        <FormInput
                                            label="Recon Account"
                                            name="ReconAccountTypeId"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes(
                                                    'ReconAccountTypeId'
                                                )
                                            }
                                            value={
                                                CustomerData &&
                                                idx(
                                                    dropDownDatas,
                                                    (_) =>
                                                        _.ReconAccountTypeId[
                                                            CustomerData
                                                                .ReconAccountTypeId
                                                        ]
                                                )
                                            }
                                            {...inputsProps}
                                        />
                                    )}
                                    {Deltas['SalesOfficeTypeId'] ? (
                                        <DeltaField delta={Deltas['SalesOfficeTypeId']} />
                                    ) : (
                                        <DynamicSelect
                                            readOnly={!extendSalesOrgEnabled}
                                            team="customermaster"
                                            arrayOfData={dropdowns.SalesOfficeTypeId}
                                            label="Sales Office"
                                            name="SalesOfficeTypeId"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes(
                                                    'SalesOfficeTypeId'
                                                )
                                            }
                                            value={
                                                this.state.formData.SalesOfficeTypeId ||
                                                (CustomerData &&
                                                    CustomerData.SalesOfficeTypeId)
                                            }
                                            isRequired
                                            formErrors={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                          'SalesOfficeTypeId'
                                                      ]
                                                    : null
                                            }
                                            onFieldChange={this.onFieldChange}
                                            showInlineRequired={showInlineAstreik}
                                        />
                                    )}

                                    {disp_input &&
                                        (Deltas['CustomerGroupTypeId'] ? (
                                            <DeltaField
                                                delta={Deltas['CustomerGroupTypeId']}
                                            />
                                        ) : (
                                            <DynamicSelect
                                                readOnly={!extendSalesOrgEnabled}
                                                arrayOfData={
                                                    (this.props.staticData &&
                                                        this.props.staticData
                                                            .ApolloCustomerGroupType) ||
                                                    []
                                                }
                                                label="Customer Group"
                                                name="CustomerGroupTypeId"
                                                hide={
                                                    allowedFields &&
                                                    !allowedFields.includes(
                                                        'CustomerGroupTypeId'
                                                    )
                                                }
                                                team="contracts"
                                                isRequired={true}
                                                formErrors={
                                                    this.state.formErrors
                                                        ? this.state.formErrors[
                                                              'CustomerGroupTypeId'
                                                          ]
                                                        : null
                                                }
                                                onFieldChange={this.onFieldChange}
                                                value={
                                                    this.state.formData[
                                                        'CustomerGroupTypeId'
                                                    ] ||
                                                    (CustomerData &&
                                                        CustomerData.CustomerGroupTypeId)
                                                }
                                                inputProps={
                                                    action === 'extend-salesorg'
                                                        ? inputReadonlyProps
                                                        : inputPropsForDefaultRules[
                                                              'CustomerGroupTypeId'
                                                          ]
                                                }
                                                showInlineRequired={showInlineAstreik}
                                            />
                                        ))}

                                    {Deltas['PPCustProcTypeId'] ? (
                                        <DeltaField delta={Deltas['PPCustProcTypeId']} />
                                    ) : (
                                        <DynamicSelect
                                            readOnly={!extendSalesOrgEnabled}
                                            team="customermaster"
                                            arrayOfData={dropdowns.PPCustProcTypeId}
                                            label="PP Cust Proc "
                                            name="PPCustProcTypeId"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes(
                                                    'PpcustProcTypeId'
                                                )
                                            }
                                            value={
                                                this.state.formData['PPCustProcTypeId'] ||
                                                (CustomerData &&
                                                    CustomerData.PPCustProcTypeId)
                                            }
                                            isRequired={
                                                !(
                                                    allowedFields &&
                                                    !allowedFields.includes(
                                                        'PpcustProcTypeId'
                                                    )
                                                )
                                            }
                                            formErrors={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                          'PPCustProcTypeId'
                                                      ]
                                                    : null
                                            }
                                            onFieldChange={this.onFieldChange}
                                            showInlineRequired={showInlineAstreik}
                                        />
                                    )}

                                    <CheckBoxItem
                                        style={{
                                            marginBottom: '0px',
                                        }}
                                        title="Order Combination"
                                        name="OrderCombination"
                                        hide={
                                            allowedFields &&
                                            !allowedFields.includes('OrderCombination')
                                        }
                                        delta={Deltas['OrderCombination']}
                                        stateValue={
                                            CustomerData && CustomerData.OrderCombination
                                        }
                                    />
                                </Box>
                                <Box width={1 / 2} mx="auto" alignItems="center">
                                    {Deltas['TaxNumber2'] ? (
                                        <DeltaField
                                            label="Tax Number 2"
                                            delta={Deltas['TaxNumber2']}
                                        />
                                    ) : (
                                        <FormInput
                                            label="Tax Number 2"
                                            name="TaxNumber2"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes('TaxNumber2')
                                            }
                                            maxLength={11}
                                            value={
                                                CustomerData && CustomerData.TaxNumber2
                                            }
                                            {...inputsProps}
                                            placeholder={'Only numeric values'}
                                        />
                                    )}

                                    {Deltas['SortKey'] ? (
                                        <DeltaField delta={Deltas['SortKey']} />
                                    ) : (
                                        <FormInput
                                            label="Sort Key"
                                            name="SortKey"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes('SortKey')
                                            }
                                            value={CustomerData && CustomerData.SortKey}
                                            {...inputsProps}
                                        />
                                    )}

                                    {Deltas['PaymentMethods'] ? (
                                        <DeltaField delta={Deltas['PaymentMethods']} />
                                    ) : (
                                        <FormInput
                                            label="Payment Methods"
                                            name="PaymentMethods"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes('PaymentMethods')
                                            }
                                            value={
                                                CustomerData &&
                                                CustomerData.PaymentMethods
                                            }
                                            {...inputsProps}
                                        />
                                    )}

                                    {Deltas['AcctgClerk'] ? (
                                        <DeltaField delta={Deltas['AcctgClerk']} />
                                    ) : (
                                        <FormInput
                                            label="Acctg Clerk"
                                            name="AcctgClerk"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes('AcctgClerk')
                                            }
                                            value={
                                                CustomerData && CustomerData.AcctgClerk
                                            }
                                            {...inputsProps}
                                        />
                                    )}

                                    {Deltas['AccountStatementTypeId'] ? (
                                        <DeltaField
                                            delta={Deltas['AccountStatementTypeId']}
                                        />
                                    ) : (
                                        <FormInput
                                            label="Account Statement"
                                            name="AccountStatementTypeId"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes(
                                                    'AccountStatementTypeId'
                                                )
                                            }
                                            value={
                                                CustomerData &&
                                                idx(
                                                    dropDownDatas,
                                                    (_) =>
                                                        _.AccountStatementTypeId[
                                                            CustomerData
                                                                .AccountStatementTypeId
                                                        ]
                                                )
                                            }
                                            {...inputsProps}
                                        />
                                    )}

                                    {Deltas['TaxClassificationTypeId'] ? (
                                        <DeltaField
                                            delta={Deltas['TaxClassificationTypeId']}
                                        />
                                    ) : (
                                        <DynamicSelect
                                            readOnly={!extendSalesOrgEnabled}
                                            team="customermaster"
                                            arrayOfData={
                                                dropdowns.TaxClassificationTypeId
                                            }
                                            label="Tax Classification "
                                            name="TaxClassificationTypeId"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes(
                                                    'TaxClassificationTypeId'
                                                )
                                            }
                                            value={
                                                this.state.formData[
                                                    'TaxClassificationTypeId'
                                                ] ||
                                                (CustomerData &&
                                                    CustomerData.TaxClassificationTypeId)
                                            }
                                            isRequired={true}
                                            formErrors={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                          'TaxClassificationTypeId'
                                                      ]
                                                    : null
                                            }
                                            onFieldChange={this.onFieldChange}
                                            showInlineRequired={showInlineAstreik}
                                        />
                                    )}

                                    {Deltas['PriceListTypeId'] ? (
                                        <DeltaField delta={Deltas['PriceListTypeId']} />
                                    ) : (
                                        <DynamicSelect
                                            readOnly={!extendSalesOrgEnabled}
                                            team="customermaster"
                                            arrayOfData={dropdowns.PriceListTypeId}
                                            label="Price List"
                                            name="PriceListTypeId"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes('PriceListTypeId')
                                            }
                                            value={
                                                this.state.formData['PriceListTypeId'] ||
                                                (CustomerData &&
                                                    CustomerData.PriceListTypeId)
                                            }
                                            isRequired={true}
                                            formErrors={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                          'PriceListTypeId'
                                                      ]
                                                    : null
                                            }
                                            onFieldChange={this.onFieldChange}
                                            inputProps={
                                                inputPropsForDefaultRules[
                                                    'PriceListTypeId'
                                                ]
                                            }
                                            showInlineRequired={showInlineAstreik}
                                        />
                                    )}

                                    {Deltas['DeliveryPriorityTypeId'] ? (
                                        <DeltaField
                                            delta={Deltas['DeliveryPriorityTypeId']}
                                        />
                                    ) : (
                                        <DynamicSelect
                                            readOnly={!extendSalesOrgEnabled}
                                            team="customermaster"
                                            arrayOfData={dropdowns.DeliveryPriorityTypeId}
                                            label="Delivery Priority"
                                            name="DeliveryPriorityTypeId"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes(
                                                    'DeliveryPriorityTypeId'
                                                )
                                            }
                                            value={
                                                this.state.formData[
                                                    'DeliveryPriorityTypeId'
                                                ] ||
                                                (CustomerData &&
                                                    CustomerData.DeliveryPriorityTypeId)
                                            }
                                            isRequired={true}
                                            formErrors={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                          'DeliveryPriorityTypeId'
                                                      ]
                                                    : null
                                            }
                                            onFieldChange={this.onFieldChange}
                                            showInlineRequired={showInlineAstreik}
                                        />
                                    )}

                                    {Deltas['ShippingConditionsTypeId'] ? (
                                        <DeltaField
                                            delta={Deltas['ShippingConditionsTypeId']}
                                        />
                                    ) : (
                                        <DynamicSelect
                                            readOnly={!extendSalesOrgEnabled}
                                            team="customermaster"
                                            arrayOfData={
                                                dropdowns.ShippingConditionsTypeId
                                            }
                                            label="Shipping Conditions"
                                            name="ShippingConditionsTypeId"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes(
                                                    'ShippingConditionsTypeId'
                                                )
                                            }
                                            value={
                                                this.state.formData
                                                    .ShippingConditionsTypeId ||
                                                CustomerData.ShippingConditionsTypeId
                                            }
                                            isRequired
                                            formErrors={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                          'ShippingConditionsTypeId'
                                                      ]
                                                    : null
                                            }
                                            onFieldChange={this.onFieldChange}
                                            inputProps={inputReadonlyProps}
                                            showInlineRequired={showInlineAstreik}
                                        />
                                    )}

                                    {disp_input &&
                                        (Deltas['IncoTerms1TypeId'] ? (
                                            <DeltaField
                                                delta={Deltas['IncoTerms1TypeId']}
                                            />
                                        ) : (
                                            <DynamicSelect
                                                readOnly={!extendSalesOrgEnabled}
                                                team="customermaster"
                                                arrayOfData={
                                                    (this.props.staticData &&
                                                        this.props.staticData
                                                            .ApolloIncoTermsType) ||
                                                    []
                                                }
                                                label="Incoterms 1"
                                                name="IncoTerms1TypeId"
                                                hide={
                                                    allowedFields &&
                                                    !allowedFields.includes(
                                                        'IncoTerms1TypeId'
                                                    )
                                                }
                                                value={
                                                    this.state.formData
                                                        .IncoTerms1TypeId ||
                                                    (CustomerData &&
                                                        CustomerData.IncoTerms1TypeId)
                                                }
                                                isRequired
                                                formErrors={
                                                    this.state.formErrors
                                                        ? this.state.formErrors[
                                                              'IncoTerms1TypeId'
                                                          ]
                                                        : null
                                                }
                                                onFieldChange={this.onFieldChange}
                                            />
                                        ))}
                                    {disp_input &&
                                        (Deltas['IncoTerms2'] ? (
                                            <DeltaField delta={Deltas['IncoTerms2']} />
                                        ) : (
                                            <FormInput
                                                readOnly={!extendSalesOrgEnabled}
                                                label="Incoterms 2"
                                                name="IncoTerms2"
                                                hide={
                                                    allowedFields &&
                                                    !allowedFields.includes('IncoTerms2')
                                                }
                                                maxLength={28}
                                                onChange={this.onFieldChange}
                                                error={
                                                    this.state.formErrors
                                                        ? this.state.formErrors[
                                                              'IncoTerms2'
                                                          ]
                                                        : null
                                                }
                                                type="text"
                                                required={
                                                    this.state.formData.isRequiredINCOT2
                                                }
                                                showInlineRequired={
                                                    showInlineAstreik &&
                                                    this.state.formData.isRequiredINCOT2
                                                }
                                                value={
                                                    isWorkFlowReadOnly
                                                        ? CustomerData &&
                                                          CustomerData.IncoTerms2
                                                        : this.state.formData
                                                        ? this.state.formData[
                                                              'IncoTerms2'
                                                          ]
                                                        : null
                                                }
                                                variant={
                                                    this.state.formData
                                                        .isReadonlyINCOT2 ||
                                                    isWorkFlowReadOnly
                                                        ? 'outline'
                                                        : 'solid'
                                                }
                                                {...(extendSalesOrgEnabled
                                                    ? pageProps
                                                    : inputProps)}
                                            />
                                        ))}

                                    {Deltas['AcctAssignmentGroupTypeId'] ? (
                                        <DeltaField
                                            label="Acct Assgmt Group"
                                            delta={Deltas['AcctAssignmentGroupTypeId']}
                                        />
                                    ) : (
                                        <DynamicSelect
                                            readOnly={!extendSalesOrgEnabled}
                                            team="customermaster"
                                            arrayOfData={
                                                dropdowns.AcctAssignmentGroupTypeId
                                            }
                                            label="Acct Assgmt Group"
                                            name="AcctAssignmentGroupTypeId"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes(
                                                    'AcctAssignmentGroupTypeId'
                                                )
                                            }
                                            isRequired={true}
                                            value={
                                                this.state.formData[
                                                    'AcctAssignmentGroupTypeId'
                                                ] ||
                                                (CustomerData &&
                                                    CustomerData.AcctAssignmentGroupTypeId)
                                            }
                                            formErrors={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                          'AcctAssignmentGroupTypeId'
                                                      ]
                                                    : null
                                            }
                                            onFieldChange={this.onFieldChange}
                                            showInlineRequired={showInlineAstreik}
                                        />
                                    )}
                                    {disp_input &&
                                        (Deltas['AccountTypeId'] ? (
                                            <DeltaField
                                                label="Account Type"
                                                delta={Deltas['AccountTypeId']}
                                            />
                                        ) : (
                                            <FormInput
                                                label="Account Type"
                                                name="AccountTypeId"
                                                hide={
                                                    allowedFields &&
                                                    !allowedFields.includes(
                                                        'AccountTypeId'
                                                    )
                                                }
                                                value={
                                                    CustomerData &&
                                                    idx(
                                                        dropDownDatas,
                                                        (_) =>
                                                            _.AccountTypeId[
                                                                CustomerData.AccountTypeId
                                                            ]
                                                    )
                                                }
                                                {...inputsProps}
                                            />
                                        ))}
                                    {Deltas['ShippingCustomerTypeId'] ? (
                                        <DeltaField
                                            delta={Deltas['ShippingCustomerTypeId']}
                                        />
                                    ) : (
                                        <FormInput
                                            label="Shipping Customer Type"
                                            name="ShippingCustomerTypeId"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes(
                                                    'ShippingCustomerTypeId'
                                                )
                                            }
                                            value={
                                                CustomerData &&
                                                idx(
                                                    dropDownDatas,
                                                    (_) =>
                                                        _.ShippingCustomerTypeId[
                                                            CustomerData
                                                                .ShippingCustomerTypeId
                                                        ]
                                                )
                                            }
                                            {...inputsProps}
                                        />
                                    )}
                                    <CheckBoxItem
                                        title="Payment History Record"
                                        name="PaymentHistoryRecord"
                                        hide={
                                            allowedFields &&
                                            !allowedFields.includes(
                                                'PaymentHistoryRecord'
                                            )
                                        }
                                        delta={Deltas['PaymentHistoryRecord']}
                                        stateValue={
                                            CustomerData &&
                                            CustomerData.PaymentHistoryRecord === 'T'
                                        }
                                    />
                                </Box>
                            </Box>
                        </Box>
                        {action === 'block-unblock' && (
                            <React.Fragment key="BlockUnblock">
                                <Text
                                    m="10px 0 24px 5%"
                                    ml="2%"
                                    fontWeight="light"
                                    color="#4195C7"
                                    fontSize="24px">
                                    Block - UnBlock Status
                                </Text>
                                <Box flexDirection="row" justifyContent="center">
                                    <Box width={1 / 2} ml="25%">
                                        <Text
                                            m="10px 0 24px 5%"
                                            ml="2%"
                                            fontWeight="light"
                                            color="#4195C7"
                                            fontSize="20px">
                                            Order Block
                                        </Text>
                                        <FormInput
                                            label="All Sales Area"
                                            name="OrderBlockTypeId"
                                            value={
                                                CustomerData &&
                                                CustomerData.RetrievedBlockUnblockData &&
                                                idx(
                                                    dropDownDatas,
                                                    (_) =>
                                                        _.OrderBlockTypeId[
                                                            CustomerData
                                                                .RetrievedBlockUnblockData
                                                                .OrderBlock.AllSalesAreaId
                                                        ]
                                                )
                                            }
                                            {...inputsProps}
                                        />
                                        <FormInput
                                            label="Selected Sales Area"
                                            name="OrderBlockTypeId"
                                            value={
                                                CustomerData &&
                                                CustomerData.RetrievedBlockUnblockData &&
                                                idx(
                                                    dropDownDatas,
                                                    (_) =>
                                                        _.OrderBlockTypeId[
                                                            CustomerData
                                                                .RetrievedBlockUnblockData
                                                                .OrderBlock
                                                                .SelectedSalesAreaId
                                                        ]
                                                )
                                            }
                                            {...inputsProps}
                                        />
                                        <Text
                                            m="10px 0 24px 5%"
                                            ml="2%"
                                            fontWeight="light"
                                            color="#4195C7"
                                            fontSize="20px">
                                            Delivery Block
                                        </Text>
                                        <FormInput
                                            label="All Sales Area"
                                            name="DeliveryBlockTypeId"
                                            value={
                                                CustomerData &&
                                                CustomerData.RetrievedBlockUnblockData &&
                                                idx(
                                                    dropDownDatas,
                                                    (_) =>
                                                        _.DeliveryBlockTypeId[
                                                            CustomerData
                                                                .RetrievedBlockUnblockData
                                                                .DeliveryBlock
                                                                .AllSalesAreaId
                                                        ]
                                                )
                                            }
                                            {...inputsProps}
                                        />
                                        <FormInput
                                            label="Selected Sales Area"
                                            name="DeliveryBlockTypeId"
                                            value={
                                                CustomerData &&
                                                CustomerData.RetrievedBlockUnblockData &&
                                                idx(
                                                    dropDownDatas,
                                                    (_) =>
                                                        _.DeliveryBlockTypeId[
                                                            CustomerData
                                                                .RetrievedBlockUnblockData
                                                                .DeliveryBlock
                                                                .SelectedSalesAreaId
                                                        ]
                                                )
                                            }
                                            {...inputsProps}
                                        />
                                        <Text
                                            m="10px 0 24px 5%"
                                            ml="2%"
                                            fontWeight="light"
                                            color="#4195C7"
                                            fontSize="20px">
                                            Billing Block
                                        </Text>
                                        <FormInput
                                            label="All Sales Area"
                                            name="BillingBlockTypeId"
                                            value={
                                                CustomerData &&
                                                CustomerData.RetrievedBlockUnblockData &&
                                                idx(
                                                    dropDownDatas,
                                                    (_) =>
                                                        _.BillingBlockTypeId[
                                                            CustomerData
                                                                .RetrievedBlockUnblockData
                                                                .BillingBlock
                                                                .AllSalesAreaId
                                                        ]
                                                )
                                            }
                                            {...inputsProps}
                                        />
                                        <FormInput
                                            label="Selected Sales Area"
                                            name="BillingBlockTypeId"
                                            value={
                                                CustomerData &&
                                                CustomerData.RetrievedBlockUnblockData &&
                                                idx(
                                                    dropDownDatas,
                                                    (_) =>
                                                        _.BillingBlockTypeId[
                                                            CustomerData
                                                                .RetrievedBlockUnblockData
                                                                .BillingBlock
                                                                .SelectedSalesAreaId
                                                        ]
                                                )
                                            }
                                            {...inputsProps}
                                        />
                                    </Box>
                                    <Box width={1 / 2} mx="auto" ml="12%">
                                        <Text
                                            m="10px 0 24px 5%"
                                            ml="2%"
                                            fontWeight="light"
                                            color="#4195C7"
                                            fontSize="20px">
                                            Posting Block
                                        </Text>
                                        <CheckBoxItem
                                            team="customermaster"
                                            title="All Company Codes"
                                            name="AllCompanyCodes"
                                            stateValue={
                                                CustomerData &&
                                                CustomerData.RetrievedBlockUnblockData &&
                                                CustomerData.RetrievedBlockUnblockData
                                                    .PostingBlock.AllCompanyCodes === true
                                            }
                                        />
                                        <CheckBoxItem
                                            team="customermaster"
                                            title="Selected Company Code"
                                            name="SelectedCompanyCode"
                                            stateValue={
                                                CustomerData &&
                                                CustomerData.RetrievedBlockUnblockData &&
                                                CustomerData.RetrievedBlockUnblockData
                                                    .PostingBlock.SelectedCompanyCode ===
                                                    true
                                            }
                                        />
                                    </Box>
                                </Box>
                            </React.Fragment>
                        )}
                        {files && <FilesList files={files} readOnly />}
                        {isWorkFlowReadOnly ? (
                            <Box my={4}>
                                <Box flexDirection="row" justifyContent="center">
                                    {CustomerData && CustomerData.RejectionReason && (
                                        <Box width={1 / 2} mx="auto" alignItems="center">
                                            <FormInput
                                                label="Notes"
                                                name="RejectionReason"
                                                inputWidth="100%"
                                                multiline
                                                onChange={this.onFieldChange}
                                                error={
                                                    this.state.formErrors
                                                        ? this.state.formErrors[
                                                              'RejectionReason'
                                                          ]
                                                        : null
                                                }
                                                type="text"
                                                value={
                                                    CustomerData &&
                                                    CustomerData.RejectionReason
                                                }
                                                variant={'outline'}
                                                inline={isWorkFlowReadOnly}
                                            />
                                        </Box>
                                    )}
                                    <Box width={1 / 2} mx="auto" alignItems="center" />
                                </Box>
                            </Box>
                        ) : (
                            <FilesList
                                title="NEW ATTACHMENTS"
                                formErrors={this.state.fileErrors}
                                files={selectedFilesIds.map((id) => selectedFiles[id])}
                                onChange={(value, id) => {
                                    this.setState({
                                        selectedFiles: {
                                            ...selectedFiles,
                                            [id]: {
                                                ...selectedFiles[id],
                                                DocumentType: parseInt(value),
                                            },
                                        },
                                    });
                                }}
                                onRemove={(value, id) => this.removeFile(id)}
                            />
                        )}
                    </Box>
                    <Box {...enableDisplay}>
                        <Flex
                            justifyEnd
                            alignCenter
                            style={{
                                paddingTop: 15,
                                flexDirection: 'row',
                                alignItems: 'center',
                                paddingLeft: 10,
                                paddingRight: 15,
                                marginTop: 20,
                                marginBottom: 10,
                                marginHorizontal: 25,
                            }}>
                            <label htmlFor="file-upload" className="custom-file-upload">
                                <MaterialIcons
                                    name="attach-file"
                                    size={18}
                                    color="#fff"
                                />
                            </label>
                            <input
                                id="file-upload"
                                type="file"
                                key={this.state.fileinputkey}
                                onChange={this.selectFiles}
                                multiple
                            />
                            <Button
                                onPress={(event) =>
                                    this.setState({ isReject: false, modalVisible: true })
                                }
                                title="Approve"
                            />
                            <Button
                                title="Reject"
                                onPress={(event) =>
                                    this.setState({ isReject: true, modalVisible: true })
                                }
                            />
                        </Flex>
                    </Box>
                </View>
            </ScrollView>
        );
    }
}

class Default extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const props = this.props;

        return (
            <DimensionAware
                render={(dimensions) => (
                    <Page
                        {...{
                            ...props,
                            width: getWindowWidth(dimensions),
                            height: getWindowHeight(dimensions),
                            marginBottom: 25,
                        }}
                    />
                )}
            />
        );
    }
}

const mapStateToProps = ({
    workflows,
    myTasks,
    customer,
    updateFlow,
    toasts,
    staticData,
}) => {
    const {
        bapi70CustData,
        deltas,
        fetching: fetchingCustomer,
        denormalizedDeltas,
    } = customer;

    const { fetching: fetchingUpdateFlow } = updateFlow;
    const { fetching, alert } = myTasks;
    const {
        fetchingfnGroupData,
        statusBarData,
        functionalGroupDetails,
        TasksStatusByTeamId,
        fetchingStatusBar,
        dunsFields = {},
    } = workflows;
    const { data, fetching: staticFetching } = staticData;
    return {
        fetching:
            fetching ||
            fetchingStatusBar ||
            fetchingfnGroupData ||
            fetchingCustomer ||
            fetchingUpdateFlow ||
            staticFetching,
        alert,
        dunsFields,
        statusBarData,
        functionalGroupDetails,
        TasksStatusByTeamId,
        denormalizedDeltas,
        bapi70CustData,
        deltas,
        toasts,
        staticData: data,
    };
};

export default connect(mapStateToProps, {
    saveApolloUpdateCustomerMaster,
    getFunctionalGroupData,
    getStatusBarData,
    getCustomerFromSAP,
    removeMessage,
    fetchDuns,
    getStaticRequest,
})(Default);

const styles = StyleSheet.create({
    progressIndicator: {
        flex: 1,
        paddingBottom: 5,
        flexDirection: 'row-reverse',
        alignItems: 'flex-end',
    },
    statusText: {
        fontSize: 15,
        color: '#1D4289',
        fontFamily: 'Poppins',
        textAlign: 'center',
        marginTop: 20,
    },
});
