import React, { useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { CheckBoxItem } from '../CheckBoxItem';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#e6f5fa',
        color: '#10206C',
        fontSize: 16,
        fontWeight: 600,
    },
    body: {
        fontSize: 16,
        fontWeight: 600,
        color: '#10206C',
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        fontSize: 14,
    },
}))(TableRow);

const StyledInnerTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#e6f5fa',
        color: '#10206C',
        fontSize: 12,
        fontWeight: 600,
    },
    body: {
        fontSize: 11,
        color: '#10206C',
    },
}))(TableCell);

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
});

function createData(name, calories, fat, carbs, protein, price) {
    return {
        name,
        calories,
        fat,
        carbs,
        protein,
        price,
        history: [
            { date: '2020-01-05', customerId: '11091700', amount: 3 },
            { date: '2020-01-02', customerId: 'Anonymous', amount: 1 },
        ],
    };
}

function Row(props) {
    const {
        readOnly,
        MdmNumberToGroup,
        AllowMdmGrouping,
        row,
        checkboxState,
        onCheck,
    } = props;
    const [open, setOpen] = React.useState(false);
    const [checkState, setmdm] = React.useState({ [row.MdmNumber]: false });
    const classes = useRowStyles();
    // onValueChange={()=>setmdm(prevState => ({ ...prevState, [row.MdmNumber]: !checkState[row.MdmNumber] }))}

    return (
        <React.Fragment>
            <StyledTableRow className={classes.root}>
                <StyledTableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                    {row.MdmNumber}
                </StyledTableCell>
                <StyledTableCell>{row.HighestPercentage}%</StyledTableCell>
                {AllowMdmGrouping && (
                    <StyledTableCell>
                        <CheckBoxItem
                            name={row.MdmNumber}
                            stateValue={
                                !readOnly
                                    ? checkboxState[row.MdmNumber]
                                    : row.MdmNumber === MdmNumberToGroup
                                    ? true
                                    : false
                            }
                            onValueChange={() => onCheck(row.MdmNumber)}
                        />
                    </StyledTableCell>
                )}
            </StyledTableRow>
            <StyledTableRow>
                <StyledTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <StyledTableRow>
                                        <StyledInnerTableCell>
                                            Source
                                        </StyledInnerTableCell>
                                        <StyledInnerTableCell>
                                            Customer Number
                                        </StyledInnerTableCell>
                                        <StyledInnerTableCell>
                                            Customer Name
                                        </StyledInnerTableCell>
                                        <StyledInnerTableCell>
                                            Customer Address
                                        </StyledInnerTableCell>
                                        <StyledInnerTableCell>Role</StyledInnerTableCell>
                                        <StyledInnerTableCell>
                                            Percentage Match
                                        </StyledInnerTableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {row &&
                                        row.Customers.map((historyRow) => (
                                            <StyledTableRow
                                                key={historyRow.CustomerNumber}>
                                                <StyledInnerTableCell
                                                    component="th"
                                                    scope="row">
                                                    {historyRow.Source}
                                                </StyledInnerTableCell>
                                                <StyledInnerTableCell>
                                                    {historyRow.CustomerNumber}
                                                </StyledInnerTableCell>
                                                <StyledInnerTableCell>
                                                    {historyRow.CustomerName}
                                                </StyledInnerTableCell>
                                                <StyledInnerTableCell>
                                                    {historyRow.CustomerAddress}
                                                </StyledInnerTableCell>
                                                <StyledInnerTableCell>
                                                    {historyRow.Role}
                                                </StyledInnerTableCell>
                                                <StyledInnerTableCell>
                                                    {historyRow.PercentageMatch}%
                                                </StyledInnerTableCell>
                                            </StyledTableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </StyledTableCell>
            </StyledTableRow>
        </React.Fragment>
    );
}

const rows = [
    {
        MdmNumber: 'MDM123',
        HighestPercentage: '98',
        Customers: [
            {
                CustomerName: 'test cust1',
                CustomerNumber: '1000023834',
                CustomerAddress: '10 test street',
                Role: 'SOLD TO',
                Source: 'APOLLO',
                PercentageMatch: '98',
            },
            {
                CustomerName: 'test cust4',
                CustomerNumber: 'PTMN-10017838-SHIPTO',
                CustomerAddress: '13 test street',
                Role: 'SHIP TO',
                Source: 'PTMN',
                PercentageMatch: '88',
            },
            {
                CustomerName: 'test cust8',
                CustomerNumber: 'PTMN-10017838-SHIPTO',
                CustomerAddress: '10 test street',
                Role: 'SOLD TO',
                Source: 'PTMN',
                PercentageMatch: '97',
            },
        ],
    },
    {
        MdmNumber: 'MDM456',
        HighestPercentage: '93',
        Customers: [
            {
                CustomerName: 'test cust2',
                CustomerNumber: 'PTMN-2000000351-BILLTO',
                CustomerAddress:
                    '11 test street Cityy1 region24 countryname 11 test street Cityy1 region24 countryname',
                Role: 'BILL TO',
                Source: 'PTMN',
                PercentageMatch: '93',
            },
        ],
    },
    {
        MdmNumber: 'MDM789',
        HighestPercentage: '93',
        Customers: [
            {
                CustomerName: 'test cust2',
                CustomerNumber: 'PTMN-2000000351-BILLTO',
                CustomerAddress: 'region24 countryname',
                Role: 'BILL TO',
                Source: 'PTMN',
                PercentageMatch: '93',
            },
        ],
    },
];

export default function CollapsibleTable(props) {
    const {
        readOnly,
        MdmNumberToGroup,
        AllowMdmGrouping,
        MDMDunsMatch,
        setSelectedMDM,
        data,
    } = props;
    const [checkState, setCheckboxState] = React.useState({});
    const [indexChosen, setIndex] = React.useState(0);
    const [likelyMatches, setlikelyMatches] = React.useState(data || []);
    function handleUncheckOthers(mdmSelected) {
        var matchlength = likelyMatches.length;
        let toUncheck = {},
            newLikelyMatches = [],
            chosenMatch = {};
        for (let i = 0; i < matchlength; i++) {
            let rowState = likelyMatches[i]['MdmNumber'] !== mdmSelected;
            if (rowState) {
                toUncheck[likelyMatches[i]['MdmNumber']] = false;
            }
            chosenMatch = likelyMatches[i];
            chosenMatch['IsChosen'] = rowState
                ? false
                : !checkState[likelyMatches[i]['MdmNumber']];
            newLikelyMatches.push(chosenMatch);
        }
        setlikelyMatches(newLikelyMatches);
        setCheckboxState((prevState) => ({
            ...prevState,
            [mdmSelected]: checkState[mdmSelected] ? !checkState[mdmSelected] : true,
            ...toUncheck,
        }));
        let chosenObj = newLikelyMatches.filter((item) => item.IsChosen == true);
        setSelectedMDM(chosenObj);
    }

    return (
        <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
                <TableHead>
                    <StyledTableRow>
                        <StyledTableCell />
                        <StyledTableCell>MDM Number</StyledTableCell>
                        <StyledTableCell>Highest percentage</StyledTableCell>
                        {AllowMdmGrouping && (
                            <StyledTableCell>Assign to MDM</StyledTableCell>
                        )}
                    </StyledTableRow>
                </TableHead>
                <TableBody>
                    {likelyMatches.map((row) => (
                        <Row
                            AllowMdmGrouping={AllowMdmGrouping}
                            key={row.MdmNumber}
                            row={row}
                            checkboxState={checkState}
                            onCheck={handleUncheckOthers}
                            readOnly={readOnly}
                            MdmNumberToGroup={MdmNumberToGroup}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
