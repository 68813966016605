import {
    all,
    call,
    fork,
    put,
    cancelled,
    takeEvery,
    takeLatest,
} from 'redux-saga/effects';
import {
    SEARCH_CUSTOMER,
    GET_CUSTOMER_DETAIL,
    GET_CUSTOMER_FROM_SAP,
    ADVANCE_SEARCH_CUSTOMER,
    CREATE_CUSTOMER_REQUEST,
    SUCCESS_BGCOLOR,
    FAILED_BGCOLOR,
    FETCH_DUNS_FIELDS_REQUEST,
    FETCH_MDM_PARENT_DATA_DUNS,
    EXPORT_CSV_DATA,
} from '../../constants/ActionTypes';
import {
    fetchMdmParentDataDunsResult,
    showCustMessage,
    searchCustomerSuccess,
    getCustomerDetailSuccess,
    retrieveCustomerFromSAPSuccess,
    advanceSearchCustomerSuccess,
    updateCsvData,
    createCustomerSuccess,
    createCustomerFailure,
    fetchDuns,
    fetchDunsSuccess,
    fetchDunsFailed,
    setTaxJurisdictionData,
} from '../../appRedux/actions';
import { showMessage as showToast } from '../../appRedux/actions/Toast';
import {
    customerMasterUrldomain,
    ajaxPostRequest,
    endpoints,
    ajaxPutFileRequest,
    formatRequestorName,
} from './config';

export function* getMdmParentDataDuns(data = {}) {
    try {
        var resp = { msg: '', color: '#FFF', delay: '' };
        const result = undefined; // modified when removing d&bs
        if (result?.IsSuccess) {
            yield put(fetchMdmParentDataDunsResult(result.ResultData));
        }
    } catch (error) {
        resp = { msg: 'No Valid Duns Values Found', color: FAILED_BGCOLOR };
        yield put(fetchMdmParentDataDunsResult(resp));
    }
}

export function* fetchCustomerDunsFields(data = {}) {
    const { DunsNumber } = data.payload;
    var resp = { msg: '', color: '#FFF', delay: '' };
    if (DunsNumber) { // why ?? && DunsNumber.toString().charAt(0).toLowerCase() !== 'v') {
        yield put(
            showToast({
                msg: 'Fetching Duns Fields',
                color: '#2980b9',
                delay: 100,
            })
        );

        try {
            const result = yield call(
                ajaxPostRequest,
                endpoints.fetchDunsFields,
                data.payload
            );
            // yield delay(500);
            let msg, color, delay;
            if (
                result.IsSuccess &&
                result.ResultData &&
                !result.ResultData.DunsRedShiftResponse.IsEmptyResult
            ) {
                const {
                    TaxNumber,
                    VatRegNo,
                    DunsNumber,
                    SicCode,
                    SicCode4,
                    SicCode6,
                    SicCode8,
                    NaicsCode,
                    NaicsCodeDescription,
                    SicCodeDescription,
                    IsEmptyResult,
                } = result.ResultData.DunsRedShiftResponse;

                if (data.length === 0) {
                    msg = 'No Valid Duns Values Found';
                    color = FAILED_BGCOLOR;
                    delay: 10000;
                } else {
                    msg = 'Duns Values Found';
                    color = SUCCESS_BGCOLOR;
                }
                resp = { msg: msg, color: color, taxData: data };
                yield put(showToast(resp));
                yield put(
                    fetchDunsSuccess({
                        TaxNumber,
                        VatRegNo,
                        DunsNumber,
                        SicCode,
                        SicCode4,
                        SicCode6,
                        SicCode8,
                        NaicsCode,
                        NaicsCodeDescription,
                        SicCodeDescription,
                        IsEmptyResult,
                    })
                );
            } else {
                resp = {
                    msg: 'No Valid Tax Jurisdiction Found',
                    color: FAILED_BGCOLOR,
                    delay: 10000,
                };
                yield put(fetchDunsFailed(resp));
            }
        } catch (error) {
            resp = { msg: error, color: FAILED_BGCOLOR };
            yield put(fetchDunsFailed(resp));
        }
    } else {
        yield put(fetchDunsFailed(resp));
        yield cancelled();
    }
}

export function* UploadFiles(files, workflowId, otherRequest = false) {
    let filesBody = {};
    let filesData = files.map((file) => {
        const { DocumentType, DocumentName, data, AttachmentName } = file;
        filesBody[DocumentName] = data;

        let _data = {
            userId: localStorage.getItem('userId'),
            workflowId,
            documentType: DocumentType,
            documentName: DocumentName,
        };

        if (otherRequest) {
            _data.AttachmentName = AttachmentName;
            delete _data.documentType;
        }

        return _data;
    });

    const url = endpoints.addDocument;
    const result = yield call(ajaxPostRequest, url, filesData);
    if (!result.IsSuccess) {
        yield put(
            showToast({
                msg: workflowId + ': File Upload Failed',
                color: FAILED_BGCOLOR,
            })
        );

        throw new Error(result.OperationResultMessages[0]);
    }

    const { ResultData: resp } = result;

    let requests = resp.map(({ PreSignedURL, DocumentName }) => {
        event.preventDefault();
        var options = {
            headers: {
                'Content-Type': filesBody[DocumentName],
            },
        };
        return fork(ajaxPutFileRequest, `${PreSignedURL}`, filesBody[DocumentName], {
            'Content-Type': 'multipart/form-data',
            'x-amz-acl': 'public-read',
        });
    });
    var uploadedFiles;
    if (requests != null && requests != undefined && requests.length != 0) {
        uploadedFiles = yield all(requests);
    }

    if (uploadedFiles) {
        yield put(
            showToast({
                msg: workflowId + ': File Upload Successful',
                color: SUCCESS_BGCOLOR,
            })
        );
    } else {
        yield put(
            showToast({
                msg: workflowId + ': File Upload Unsuccessful',
                color: FAILED_BBGCOLOR,
            })
        );
    }
    // return uploadedFiles;
}

export function* UploadFilesOtherRequest(files, workflowId) {
    let filesBody = {};
    let filesData = files.map((file) => {
        const { DocumentType, DocumentName, data } = file;
        filesBody[DocumentName] = data;

        return {
            userId: localStorage.getItem('userId'),
            workflowId,
            documentType: DocumentType,
            documentName: DocumentName,
        };
    });

    const url = endpoints.addDocument;
    const result = yield call(ajaxPostRequest, url, filesData);
    if (!result.IsSuccess) {
        yield put(
            showToast({
                msg: workflowId + ': File Upload Failed',
                color: FAILED_BGCOLOR,
            })
        );

        throw new Error(result.OperationResultMessages[0]);
    }

    const { ResultData: resp } = result;

    let requests = resp.map(({ PreSignedURL, DocumentName }) => {
        event.preventDefault();
        var options = {
            headers: {
                'Content-Type': filesBody[DocumentName],
            },
        };
        return fork(ajaxPutFileRequest, `${PreSignedURL}`, filesBody[DocumentName], {
            'Content-Type': 'multipart/form-data',
            'x-amz-acl': 'public-read',
        });
    });
    var uploadedFiles;
    if (requests != null && requests != undefined && requests.length != 0) {
        uploadedFiles = yield all(requests);
    }

    if (uploadedFiles) {
        yield put(
            showToast({
                msg: workflowId + ': File Upload Successful',
                color: SUCCESS_BGCOLOR,
            })
        );
    } else {
        yield put(
            showToast({
                msg: workflowId + ': File Upload Unsuccessful',
                color: FAILED_BBGCOLOR,
            })
        );
    }
    // return uploadedFiles;
}

export function* MdmCreateCustomer({ payload }) {
    const { history, data: jsonBody, files } = payload;
    const url = endpoints.MdmCreateCustomer;
    var resp = { msg: '', color: '#FFF' };
    try {
        yield put(
            showToast({
                msg: 'Saving new customer',
                color: '#2980b9',
            })
        );
        const createResult = yield call(ajaxPostRequest, url, jsonBody);
        if (createResult.IsSuccess) {
            window.scrollTo(0, 0);

            let result = { IsSuccess: true };
            if (files && files.length > 0) {
                yield put(
                    showToast({
                        msg: 'Uploading files',
                        color: '#2980b9',
                    })
                );

                yield* UploadFiles(files, jsonBody.WorkflowId);
            }

            if (result.IsSuccess) {
                yield put(
                    createCustomerSuccess({
                        msg: 'Successfully created new customer',
                        color: '#27ae60',
                    })
                );
                localStorage.removeItem('SFDCData');
                history.push({
                    pathname: '/my-requests',
                });
            } else {
                resp = {
                    msg: 'Error uploading files',
                    color: FAILED_BGCOLOR,
                };
                yield put(createCustomerFailure(resp));
                window.scrollTo(0, 0);
            }
        } else {
            let errorMessage = 'Error saving data: ';
            if (
                createResult.OperationResultMessages != null &&
                createResult.OperationResultMessages.length > 0
            ) {
                createResult.OperationResultMessages.forEach((operationResultMessage) => {
                    if (operationResultMessage.OperationalResultType == 3) {
                        errorMessage =
                            errorMessage + operationResultMessage.Message + ' ';
                    }
                });
            }

            resp = { msg: errorMessage, color: FAILED_BGCOLOR };
            yield put(showToast(resp));
            yield put(createCustomerFailure(resp));
        }

        // const jsonBody = data;
        // const result = yield call(ajaxPostRequest, url, jsonBody);
        // if (result.IsSuccess) {
        //     yield put(createCustomerSuccess(result.ResultData));
        //     history.push({
        //         pathname: '/my-requests',
        //     });
        // }
    } catch (error) {
        // resp = { msg: error, color: FAILED_BGCOLOR };
        // yield put(getWorkflowsFailed(resp));

        resp = {
            msg: error.message,
            color: FAILED_BGCOLOR,
        };
        yield put(createCustomerFailure(resp));
    }
}

export function* getCustomerDetail(customer_id) {
    const cust_id = customer_id.payload;
    const text = 'nam';
    const url = customerMasterUrldomain + '/customer/' + cust_id + '/searchv2';
    try {
        const res = yield call(fetch, url);
        let data = yield call([res, 'json']);
        if (data.message) {
            //  no search results found
            let customerdata = [];
            yield put(getCustomerDetailSuccess(customerdata));
        } else {
            // yield put(getCustomerDetailSuccess(data.customers[0]));

            yield put(
                getCustomerDetailSuccess({
                    MdmNumber: '002491624',
                    ContactFirstName: '91CntctFirstName',
                    ContactLastName: '91CntctLastName ',
                    ContactFunction: '91Sales Contact ',
                    ContactTelephone: '91444444444',
                    ContactFax: '91883334444',
                    ContactEmailAddress: '91test@test.com',
                    Name: 'Name Test002491624',
                    Name2: '',
                    Name3: '',
                    Name4: '',
                    Street: '91123 test',
                    Street2: '91ste 123 ',
                    City: '91Dallas',
                    Region: '91TX',
                    PostalCode: '910210',
                    Country: 'USA',
                    SalesOrgId: 1,
                    ErpSystemId: 1,
                    SystemFields: Array(1),
                    0: {
                        System: 'SAP Apollo',
                        SoldTo: '',
                        PurposeOfRequest: '',
                        Role: 'Sold To (0001)',
                        SalesOrg: '0150',
                    },
                })
            );
        }
    } catch (error) {
        yield put(showCustMessage(error));
    }
}

export function* getSAPCustomerDetails({ payload }) {
    const postData = payload;
    var resp = { msg: '', color: '#FFF' };
    const url = endpoints.getSAPCustomerDetails;

    try {
        yield put(
            showToast({
                msg: 'Fetching Data From The ERP',
                color: '#2980b9',
            })
        );
        const result = yield call(ajaxPostRequest, url, postData);
        if (result.IsSuccess) {
            const { RequestorName } = result.ResultData;
            if (RequestorName) {
                result.ResultData.RequestorName = formatRequestorName(RequestorName);
            }
            yield put(retrieveCustomerFromSAPSuccess(result.ResultData));
            var dunsdata = {};
            dunsdata['payload'] = {
                UserId: localStorage.getItem('userId'),
                DunsNumber: result.ResultData.Deltas[0].DunsNumber.UpdatedValue || null,
            };
            console.log('dunsdata update', dunsdata);
            yield* fetchCustomerDunsFields(dunsdata);
        } else {
            resp = { msg: 'No data found', color: FAILED_BGCOLOR };
            yield put(showCustMessage(resp));
        }
    } catch (error) {
        resp = { msg: error, color: FAILED_BGCOLOR };
        yield put(showCustMessage(resp));
    }
}

export function* searchCustomers(action) {
    const jsonBody = action.payload;
    // const url = customerMasterUrldomain + '/customer/' + searchtext + '/searchv2';
    const url = endpoints.advanceSearchCustomers;
    try {
        // const res = yield call(fetch, url);
        // console.log('res',res);
        // let json = yield call([res, 'json']);
        // const data = {
        //     customers: [json],
        // };
        // if (data.customers[0].message) {
        //     //  no search results found
        //     let customerdata = [];
        //     yield put(searchCustomerSuccess(customerdata));
        // } else {
        //     console.log('searchresult',data);
        //     yield put(searchCustomerSuccess(data.customers[0].customers));
        // }
        const result = yield call(ajaxPostRequest, url, jsonBody);
        if (result.IsSuccess) {
            yield put(searchCustomerSuccess(result.ResultData));
        } else {
            let customerdata = [];
            yield put(searchCustomerSuccess(customerdata));
        }
    } catch (error) {
        yield put(showCustMessage(error));
    }
}

export function* export_csvdata(action) {
    const { jsonBody, history } = action.payload;
    const userId = localStorage.getItem('userId');
    // const url = customerMasterUrldomain + '/customer/' + searchtext + '/searchv2';
    const url = endpoints.advanceSearchCustomers;
    try {
        const result = yield call(ajaxPostRequest, url, {
            ...jsonBody,
            userId,
        });
        if (result.IsSuccess) {
            yield put(updateCsvData(result.ResultData));
        } else {
            yield put(
                updateCsvData({
                    ...result.ResultData,
                    Customers: [],
                    Workflows: [],
                })
            );
        }
    } catch (error) {
        yield put(showCustMessage(error));
    }
}

export function* advanceSearchCustomers(action) {
    const { jsonBody, history, redirect } = action.payload;
    const userId = localStorage.getItem('userId');
    // const url = customerMasterUrldomain + '/customer/' + searchtext + '/searchv2';
    const url = endpoints.advanceSearchCustomers;
    try {
        const result = yield call(ajaxPostRequest, url, {
            ...jsonBody,
            userId,
        });
        if (result.IsSuccess) {
            yield put(advanceSearchCustomerSuccess(result.ResultData));
        } else {
            yield put(
                advanceSearchCustomerSuccess({
                    ...result.ResultData,
                    Customers: [],
                    Workflows: [],
                })
            );
            // yield put(
            //     showCustMessage({
            //         msg: 'Connection Issue, please try after sometime',
            //         color: '#EA0029',
            //         display: true,
            //     })
            // );
        }

        if (redirect) {
            history.push({
                pathname: `/search-results`,
            });
        }

        if (
            result.ResultData.Customers.length <= 0 &&
            result.ResultData.Workflows.length <= 0
        ) {
            yield put(
                showCustMessage({
                    msg: 'No Results',
                    color: '#EA0029',
                    display: true,
                })
            );
        }
    } catch (error) {
        yield put(showCustMessage(error));
    }
}

export function* retrieveCustomers() {
    yield takeLatest(SEARCH_CUSTOMER, searchCustomers);
}

export function* advSearch() {
    yield takeEvery(ADVANCE_SEARCH_CUSTOMER, advanceSearchCustomers);
}
export function* fetchCustomerDetail() {
    yield takeLatest(GET_CUSTOMER_DETAIL, getCustomerDetail);
}

export function* retrieveCustomersFromSAP() {
    yield takeLatest(GET_CUSTOMER_FROM_SAP, getSAPCustomerDetails);
}

export function* createCustomer() {
    yield takeLatest(CREATE_CUSTOMER_REQUEST, MdmCreateCustomer);
}

export function* fetchDunsFields() {
    yield takeLatest(FETCH_DUNS_FIELDS_REQUEST, fetchCustomerDunsFields);
}
export function* fetchParentDataDuns() {
    yield takeLatest(FETCH_MDM_PARENT_DATA_DUNS, getMdmParentDataDuns);
}
export function* exportcsv() {
    yield takeLatest(EXPORT_CSV_DATA, export_csvdata);
}

const customerSagas = function* rootSaga() {
    yield all([
        fork(retrieveCustomers),
        fork(fetchCustomerDetail),
        fork(retrieveCustomersFromSAP),
        fork(advSearch),
        fork(createCustomer),
        fork(fetchDunsFields),
        fork(fetchParentDataDuns),
        fork(exportcsv),
    ]);
};
export default customerSagas;
