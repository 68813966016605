/**
 * @prettier
 */

import React, { Component, Fragment } from 'react';
import { Box, Text, Button, Flex } from './common';
import { FormInput, FormSelect } from './form';
import { FontAwesome } from '@expo/vector-icons';
import { times } from 'lodash';
import { jdeCategoryTypes } from '../constants/WorkflowEnums.js';
import { View, TouchableOpacity } from 'react-native';
import { TextInput } from 'react-native-web';
import CountryRegionData from '../constants/data.normalized';
import { connect } from 'react-redux';
import idx from 'idx';
import DeltaField from './DeltaField';
import debounce from 'debounce';
import CountryRegionSelector from './CountryRegionSelector';
import { SicCodeInputs } from '../components/DunsEditableGlobalMdmFields';
import { fetchDuns } from '../appRedux/actions';
import DynamicSelect from '../components/DynamicSelect';
import { AntDesign } from '@expo/vector-icons';
import { Colors, StyledText } from '../theme/Index';
import { jdeRegionData, jdeCountryData } from '../constants/CountryRegionDropDownData';

const _CategoryTypes = [
    { name: 'Distributor', id: 1 },
    { name: 'Self-Distributor', id: 2 },
    { name: 'OEM', id: 3 },
    { name: 'Direct', id: 4 },
    { name: 'Kitter', id: 6 },
    { name: 'Drop Ship', id: 7 },
    { name: 'Other', id: 5 },
];

const emailPlaceHolderList = [
    'Invoice',
    'Order Confirmation',
    'Delivery Note',
    'General Contact',
];

const AddIcon = ({ onPress }) => (
    <Box ml={3}>
        <FontAwesome.Button
            name="plus"
            size={15}
            color="#FFFFFF"
            backgroundColor="#264384"
            borderRadius={13}
            iconStyle={{ marginRight: 0, paddingHorizontal: 1 }}
            onPress={onPress}
        />
    </Box>
);

const RemoveIcon = ({ onPress }) => (
    <Box ml={3}>
        <FontAwesome.Button
            name="minus"
            size={15}
            color="#FFFFFF"
            backgroundColor="#264384"
            borderRadius={13}
            iconStyle={{ marginRight: 0, paddingHorizontal: 1 }}
            onPress={onPress}
        />
    </Box>
);
const gdmInternationalData = (data, formName1, formName2) => {
    let value = '';
    if (data[formName1]) {
        value = data[formName1];
    } else {
        if (
            data.InternationalVersionDomain &&
            data.InternationalVersionDomain[formName2]
        ) {
            value = data.InternationalVersionDomain[formName2];
        }
    }
    return value;
};

class JdeGlobalMdmFields extends Component {
    constructor(props) {
        super(props);
        this.state = {
            namesInput: 0,
            emailsInput: 0,
            Country: '',
            region: '',
            buttonTitle: this.props.isOlympusModal
                ? "Close Int'l Version"
                : 'Show INTERNATIONAL VERSION',
            IName1: this.props.isOlympusModal
                ? gdmInternationalData(this.props.formData, 'IName1', 'Name1')
                : '',
            IDoorNo: this.props.isOlympusModal
                ? gdmInternationalData(this.props.formData, 'IDoorNo', 'DoorNo')
                : '',
            ISearchTerm1: this.props.isOlympusModal
                ? gdmInternationalData(this.props.formData, 'ISearchTerm1', 'SearchTerm1')
                : '',
            IStreet: this.props.isOlympusModal
                ? gdmInternationalData(this.props.formData, 'IStreet', 'Street')
                : '',
            IStreet2: this.props.isOlympusModal
                ? gdmInternationalData(this.props.formData, 'IStreet2', 'Street2')
                : '',
            ICity: this.props.isOlympusModal
                ? gdmInternationalData(this.props.formData, 'ICity', 'City')
                : '',
        };
    }

    selectCountry(val) {
        this.setState({ Country: val });
    }

    selectRegion(val) {
        this.setState({ region: val });
    }

    addNameInput = () => {
        const { namesInput } = this.state;
        // const isOlympus = this.props.formData && this.props.formData.SystemTypeId == 2;
        let numberOfAllowedFields = 3;
        if (namesInput < numberOfAllowedFields) {
            this.setState({
                namesInput: namesInput + 1,
            });
        }
    };

    removeNameInput = () => {
        const { namesInput } = this.state;

        if (namesInput >= 1) {
            this.setState({
                namesInput: namesInput - 1,
            });
        }
    };

    addEmailInput = () => {
        const { emailsInput } = this.state;
        // const isOlympus = this.props.formData && this.props.formData.SystemTypeId == 2;
        let numberOfAllowedFields = 3;
        if (emailsInput < numberOfAllowedFields) {
            this.setState({
                emailsInput: emailsInput + 1,
            });
        }
    };

    removeEmailInput = () => {
        const { emailsInput } = this.state;

        if (emailsInput >= 1) {
            this.setState({
                emailsInput: emailsInput - 1,
            });
        }
    };

    componentDidMount() {
        if (this.props.readOnly) {
            this.setState({ namesInput: 3, emailsInput: 3 });
        }
        const { formData = {}, deltas = {} } = this.props;

        const DunsNumber = deltas['DunsNumber']
            ? deltas['DunsNumber'].UpdatedValue
            : formData['DunsNumber'];

        // if (formData.DunsNumber)
        //     this.props.fetchDuns({
        //         UserId: localStorage.getItem('userId'),
        //         DunsNumber,
        //     });
    }

    componentWillReceiveProps(newProps) {
        if (newProps.formData !== this.props.formData) {
            // console.log({...newProps.formData})
            this.setState({
                ...newProps.formData,
                PostalCode: newProps.formData.PostalCode,
            });
            // if ((this.props.formData.SystemTypeId !== newProps.formData.SystemTypeId) && (newProps.formData.SystemTypeId === '2')) {
            //     alert("test")
            //     const { formData = {}, deltas = {} } = this.props;
            //     const DunsNumber = deltas['DunsNumber']
            //         ? deltas['DunsNumber'].UpdatedValue
            //         : formData['DunsNumber'];

            //     this.props.fetchDuns({
            //         UserId: localStorage.getItem('userId'),
            //         DunsNumber,
            //     });
            // }
        }
    }

    handleChange = (val, e) => {
        e.persist();
        const {
            name,
            value,
            validity: { valid },
        } = e.target;
        if (valid) this.setState({ [name]: value });
    };

    handleTextChange = (val, e) => {
        e.persist();
        const { name, value } = e.target;
        this.setState({ [name]: value });
    };


    isInternationalVersionData = () => {
        const InternationalVersionDomain = this.props.formData.InternationalVersionDomain;
        let flag = false;
        Object.keys(InternationalVersionDomain).some((data) => {
            if (InternationalVersionDomain[data]) {
                flag = true;
                return true;
            }
        });
        return flag;
    };

    changeLanguage = (e) => {
        const InternationalVersionDomain = this.props.formData.InternationalVersionDomain;
        if (this.props.isOlympusModal)
            this.setState({ buttonTitle: "Show Int'l Version" });
        if (this.state.buttonTitle === "Show Int'l Version") {
            this.setState({ buttonTitle: 'VIEW INTERNATIONAL VERSION' });
            this.setState(
                {
                    Street: this.props.formData.Street,
                    Street2: this.props.formData.Street2,
                    City: this.props.formData.City,
                },
                () => {
                    if (this.props.viewInternationalModal) {
                        this.props.viewInternationalModal();
                    }
                }
            );

            times(this.state.namesInput, (index) => {
                this.setState({
                    [`Name${index + 1}`]: this.props.formData[`Name${index + 1}`],
                });
            });
        } else {
            this.setState(
                {
                    IName1: InternationalVersionDomain.Name1,
                    IDoorNo: InternationalVersionDomain.DoorNo,
                    IStreet: InternationalVersionDomain.Street,
                    IStreet2: InternationalVersionDomain.Street2,
                    ICity: InternationalVersionDomain.City,
                    ISearchTerm1: InternationalVersionDomain.SearchTerm1,
                },
                () => {
                    if (this.props.viewInternationalModal) {
                        this.props.viewInternationalModal();
                    }
                }
            );
            times(this.state.namesInput, (index) => {
                this.setState({
                    [`Name${index + 1}`]: this.props.formData.InternationalVersionDomain[
                        `Name${index + 1}`
                    ],
                });
            });
        }
    };

    handleDropdownChange = (val, e) => {
        e.persist();
        const { name, value } = e.target;
        this.setState({ [name]: val });
    };

    render() {
        let {
            readOnly,
            formData = {},
            staticDropDownData = [],
            deltas = {},
            hide,
            taxEditable = false,
            editableInputs = {},
            dunsEditable = false,
            countryNotEditable = false,
            hideTitle = false,
            isInternationalVersion,
            isSFDC,
            functionalGroupDetails,
        } = this.props;
        if (!formData) formData = {};

        // const disabledFields = this.props.disabledFields;
        // console.log(this.props.dunsFields);
        const dunsFields = {
            DunsNumber: this.props.dunsFields.DunsNumber || formData.DunsNumber,
            IsEmptyResult: this.props.dunsFields.IsEmptyResult || formData.IsEmptyResult,
            NaicsCode: this.props.dunsFields.NaicsCode || formData.NaicsCode,
            NaicsCodeDescription:
                this.props.dunsFields.NaicsCodeDescription ||
                formData.NaicsCodeDescription,
            SicCode: this.state.SicCode||this.props.dunsFields.SicCode || formData.SicCode,
            SicCode4: this.state.SicCode4||this.props.dunsFields.SicCode4 || formData.SicCode4,
            SicCode6: this.state.SicCode6|| this.props.dunsFields.SicCode6 || formData.SicCode6,
            SicCode8: this.state.SicCode8|| this.props.dunsFields.SicCode8 || formData.SicCode8,
            SicCodeDescription:
                this.props.dunsFields.SicCodeDescription || formData.SicCodeDescription,
            TaxNumber: this.props.dunsFields.TaxNumber || formData.TaxNumber,
            VatRegNo: this.props.dunsFields.VatRegNo || formData.VatRegNo,
        };
        formData = { ...formData, ...dunsFields };
        const selectorProps = readOnly ? { disabled: true } : null;
        const inputProps = readOnly
            ? {
                inline: true,
                variant: 'outline',
                readOnly: true,
            }
            : {
                inline: false,
                readOnly: false,
                onBlur: this.props.onFieldChange,
            };

        const FaxAndTaxProps = readOnly
            ? {
                inline: true,
                variant: 'outline',
                readOnly: true,
            }
            : {
                inline: false,
                readOnly: false,
                onBlur: this.props.onFieldChange,
            };

        const taxInputProps = !taxEditable
            ? {
                inline: true,
                variant: 'outline',
                readOnly: true,
            }
            : {
                inline: true,
                readOnly: false,
                onChange: this.props.onFieldChange,
            };

        const dunsInputProps =
            dunsEditable || taxEditable
                ? {
                    inline: false,
                    readOnly: false,
                    onChange: this.props.onFieldChange,
                    onBlur: this.props.onDunsNewInput,
                }
                : {
                    inline: true,
                    variant: 'outline',
                    readOnly: true,
                };

        const { namesInput, emailsInput } = this.state;
        const isCountry_US = formData.Country && formData.Country.toLowerCase() === 'us';
        const isCountry_US_OR_CA =
            formData.Country &&
            (formData.Country.toLowerCase() === 'us' ||
                formData.Country.toLowerCase() === 'ca');

        const Country = idx(CountryRegionData, (_) => _[formData.Country]) || {};
        const region = idx(Country, (_) => _.regions[formData.Region]) || {};

        const hasDescription =
            (formData.NaicsCodeDescription !== undefined &&
                formData.NaicsCodeDescription !== null) ||
            (formData.SicCodeDescription !== undefined &&
                formData.SicCodeDescription !== null);
        const isPointman = formData.SystemTypeId == 3;
        const isOlympus =
            formData.SystemTypeId == 2 ||
            (functionalGroupDetails.Customer &&
                functionalGroupDetails.Customer.SystemTypeId == 2);
        const isJDE =
            formData.SystemTypeId == 5 ||
            (functionalGroupDetails.Customer &&
                functionalGroupDetails.Customer.SystemTypeId == 5);
        const InternationalVersionDomain = formData.InternationalVersionDomain;
        const sfdcInputProps = {
            ...inputProps,
            disabled: true,
            disabledPadding: true,
            sfdcdisabled: true,
        };

        return (
            <Fragment>
                {!hideTitle && (
                    <Box
                        flexDirection="row"
                        justifyContent="left"
                        alignItems="flex-start">
                        <Text
                            m="10px 0 24px 5%"
                            ml={readOnly ? '2%' : '5%'}
                            fontWeight="light"
                            color="lightBlue"
                            fontSize="24px">
                            {this.props.title ? this.props.title : 'MDM GLOBAL FIELDS'}
                        </Text>
                    </Box>
                )}

                <Box
                    flexDirection="row"
                    justifyContent="center"
                    alignItems={hasDescription && 'flex-start'}>
                    <Box width={1 / 2} mx="auto" alignItems="center">
                        <>
                            {deltas[`Name1`] ? (
                                <DeltaField delta={deltas[`Name1`]} label="Name" />
                            ) : (
                                <FormInput
                                    display="flex"
                                    flex={1}
                                    flexDirection="row"
                                    label="Name"
                                    name="Name1"
                                    maxLength={40}
                                    team="global"
                                    pattern="[^~*:;\^\\><|]+"
                                    onChange={this.handleChange}
                                    showRequired
                                    error={
                                        this.props.formErrors
                                            ? this.props.formErrors['Name1']
                                            : null
                                    }
                                    value={
                                        this.state.Name1 !== undefined
                                            ? this.state.Name1
                                            : this.props.formData &&
                                            this.props.formData.Name1
                                    }
                                    rightComponent={() =>
                                        !readOnly && (
                                            <AddIcon onPress={this.addNameInput} />
                                        )
                                    }
                                    {...(isSFDC ? sfdcInputProps : inputProps)}
                                />
                            )}
                            {times(namesInput, (index) => {
                                index += 1;
                                if (readOnly) {
                                    return deltas[`Name${index + 1}`] ? (
                                        <DeltaField delta={deltas[`Name${index + 1}`]} />
                                    ) : formData[`Name${index + 1}`] ? (
                                        <FormInput
                                            key={`name${index}`}
                                            label={`Name ${index + 1}`}
                                            name={`Name${index + 1}`}
                                            team="global"
                                            maxLength={40}
                                            error={
                                                this.props.formErrors
                                                    ? this.props.formErrors[
                                                    `Name${index + 1}`
                                                    ]
                                                    : null
                                            }
                                            value={formData[`Name${index + 1}`]}
                                            rightComponent={() =>
                                                !readOnly && (
                                                    <RemoveIcon
                                                        onPress={this.removeNameInput}
                                                    />
                                                )
                                            }
                                            {...inputProps}
                                        />
                                    ) : null;
                                } else {
                                    return (
                                        <FormInput
                                            key={`name${index}`}
                                            label={`Name ${index + 1}`}
                                            name={`Name${index + 1}`}
                                            maxLength={40}
                                            team="global"
                                            error={
                                                this.props.formErrors
                                                    ? this.props.formErrors[
                                                    `Name${index + 1}`
                                                    ]
                                                    : null
                                            }
                                            pattern="[^~*:;\^\\><|]+"
                                            onChange={this.props.onFieldChange}
                                            value={
                                                this.state[`Name${index + 1}`] !==
                                                    undefined
                                                    ? this.state[`Name${index + 1}`]
                                                    : this.props.formData &&
                                                    this.props.formData[
                                                    `Name${index + 1}`
                                                    ]
                                            }
                                            rightComponent={() =>
                                                !readOnly && (
                                                    <RemoveIcon
                                                        onPress={this.removeNameInput}
                                                    />
                                                )
                                            }
                                            {...inputProps}
                                        />
                                    );
                                }
                            })}

                            {isInternationalVersion && (
                                <>
                                    {deltas[`DoorNo`] ? (
                                        <DeltaField delta={deltas[`DoorNo`]} />
                                    ) : (
                                        <FormInput
                                            label="Door No"
                                            name="DoorNo"
                                            maxLength={241}
                                            team="global"
                                            value={
                                                this.props.formData &&
                                                (this.props.formData.Email ||
                                                    this.props.formData
                                                        .ContactEmailAddress)
                                            }
                                            {...inputProps}
                                            autoComplete="off"
                                        />
                                    )}

                                    {deltas[`SearchTerm1`] ? (
                                        <DeltaField delta={deltas[`SearchTerm1`]} />
                                    ) : (
                                        <FormInput
                                            label="Search Term 1"
                                            name="SearchTerm1"
                                            maxLength={241}
                                            team="global"
                                            value={
                                                this.props.formData &&
                                                (this.props.formData.Email ||
                                                    this.props.formData
                                                        .ContactEmailAddress)
                                            }
                                            {...inputProps}
                                            autoComplete="off"
                                        />
                                    )}
                                </>
                            )}
                            {deltas[`Street`] ? (
                                <DeltaField delta={deltas[`Street`]} />
                            ) : (
                                <FormInput
                                    label="Street"
                                    name="Street"
                                    maxLength={40}
                                    team="global"
                                    error={
                                        this.props.formErrors
                                            ? this.props.formErrors['Street']
                                            : null
                                    }
                                    showRequired
                                    pattern="[^~*:;\^\\><|]+"
                                    onChange={this.handleChange}
                                    value={
                                        this.state.Street !== undefined
                                            ? this.state.Street
                                            : this.props.formData &&
                                            this.props.formData.Street
                                    }
                                    {...(isSFDC ? sfdcInputProps : inputProps)}
                                />
                            )}

                            {deltas[`Street2`] ? (
                                <DeltaField delta={deltas[`Street2`]} label="Street 2" />
                            ) : (
                                <FormInput
                                    label="Street 2"
                                    name="Street2"
                                    maxLength={40}
                                    team="global"
                                    pattern="[^~*:;\^\\><|]+"
                                    onChange={this.handleChange}
                                    value={
                                        this.state.Street2 !== undefined
                                            ? this.state.Street2
                                            : this.props.formData &&
                                            this.props.formData.Street2
                                    }
                                    {...inputProps}
                                />
                            )}

                            {deltas[`City`] ? (
                                <DeltaField delta={deltas[`City`]} />
                            ) : (
                                <FormInput
                                    label="City"
                                    name="City"
                                    maxLength={25}
                                    team="global"
                                    error={
                                        this.props.formErrors
                                            ? this.props.formErrors['City']
                                            : null
                                    }
                                    required
                                    onChange={this.handleTextChange}
                                    value={
                                        this.state.City !== undefined
                                            ? this.state.City
                                            : this.props.formData &&
                                            this.props.formData.City
                                    }
                                    {...(isSFDC ? sfdcInputProps : inputProps)}
                                    autoComplete="off"
                                />
                            )}

                            {readOnly ? (
                                deltas[`Country`] ? (
                                    <DeltaField delta={deltas[`Country`]} />
                                ) : (
                                    <FormInput
                                        label="Country"
                                        name="Country"
                                        team="global"
                                        error={
                                            this.props.formErrors
                                                ? this.props.formErrors['Country']
                                                : null
                                        }
                                        required
                                        value={Country.countryName || ''}
                                        {...inputProps}
                                        autoComplete="off"
                                    />
                                )
                            ) : (
                                <CountryRegionSelector
                                    label="Country"
                                    name="Country"
                                    team="global"
                                    inline={countryNotEditable}
                                    readOnly={countryNotEditable}
                                    formErrors={
                                        this.props.formErrors
                                            ? this.props.formErrors['Country']
                                            : null
                                    }
                                    isRequired={true}
                                    autoComplete="off"
                                    value={formData.Country}
                                    selectorType="Country"
                                    upperCase
                                    onFieldChange={this.props.onFieldChange}
                                    {...(isSFDC && {
                                        inputProps: {
                                            disabled: true,
                                            disabledPadding: true,
                                        },
                                    })}
                                />
                            )}

                            {readOnly ? (
                                deltas[`Region`] ? (
                                    <DeltaField
                                        delta={deltas[`Region`]}
                                        arrayOfData={jdeRegionData}
                                    />
                                ) : (
                                    <FormInput
                                        label="Region"
                                        name="Region"
                                        team="global"
                                        error={
                                            this.props.formErrors
                                                ? this.props.formErrors['Region']
                                                : null
                                        }
                                        required
                                        value={region.name || ''}
                                        {...inputProps}
                                        upperCase
                                        autoComplete="off"
                                    />
                                )
                            ) : (
                                <>
                                    <CountryRegionSelector
                                        label="Region"
                                        name="Region"
                                        team="global"
                                        inline={false}
                                        readOnly={false}
                                        selectorType="region"
                                        formErrors={
                                            this.props.formErrors
                                                ? this.props.formErrors['Region']
                                                : null
                                        }
                                        isRequired={isCountry_US ? true : false}
                                        Country={formData.Country || ''}
                                        value={formData.Region || ''}
                                        onFieldChange={this.props.onFieldChange}
                                        {...inputProps}
                                        upperCase
                                        autoComplete="off"
                                        {...(isSFDC && {
                                            inputProps: {
                                                disabled: true,
                                                disabledPadding: true,
                                            },
                                        })}
                                    />
                                </>
                            )}

                            {deltas[`PostalCode`] ? (
                                <DeltaField delta={deltas[`PostalCode`]} />
                            ) : (
                                <FormInput
                                    label="Postal Code"
                                    name="PostalCode"
                                    maxLength={12}
                                    team="global"
                                    error={
                                        this.props.formErrors
                                            ? this.props.formErrors['isPostalCodeValid']
                                            : null
                                    }
                                    pattern={isCountry_US ? '[0-9-]+' : '[a-zA-Z0-9]+'}
                                    onChange={this.handleChange}
                                    // showRequired={isCountry_US_OR_CA}
                                    value={
                                        this.state.PostalCode !== undefined
                                            ? this.state.PostalCode
                                            : this.props.formData &&
                                            this.props.formData.PostalCode
                                    }
                                    {...(isSFDC ? sfdcInputProps : inputProps)}
                                    autoComplete="off"
                                    placeholder={
                                        isCountry_US
                                            ? 'Only Numeric Values'
                                            : 'Alpha-Numeric Values'
                                    }
                                />
                            )}
                            <>
                                {/* <FormInput
                                    label="Email"
                                    name="Email"
                                    team="global"
                                    onChange={this.handleTextChange}
                                    value={
                                        this.state.Email !== undefined
                                            ? this.state.Email
                                            : this.props.formData &&
                                            (this.props.formData.Email ||
                                                this.props.formData.ContactEmailAddress)
                                    }
                                    error={
                                        this.props.formErrors
                                            ? this.props.formErrors['Email']
                                            : null
                                    }
                                    disabled={readOnly}
                                    maxLength={241}
                                    {...inputProps}
                                    autoComplete="off"
                                    {...(isSFDC ? sfdcInputProps : inputProps)}
                                />

                                <FormInput
                                    label="Email 2"
                                    name="Email2"
                                    team="global"
                                    onChange={this.handleTextChange}
                                    value={
                                        this.state.Email2 !== undefined
                                            ? this.state.Email2
                                            : this.props.formData &&
                                            (this.props.formData.Email2 ||
                                                this.props.formData.ContactEmailAddress)
                                    }
                                    error={
                                        this.props.formErrors
                                            ? this.props.formErrors['Email2']
                                            : null
                                    }
                                    disabled={readOnly}
                                    maxLength={241}
                                    {...inputProps}
                                    autoComplete="off"
                                    {...(isSFDC ? sfdcInputProps : inputProps)}
                                />
                                <FormInput
                                    label="Email 3"
                                    name="Email3"
                                    team="global"
                                    onChange={this.handleTextChange}
                                    value={
                                        this.state.Email3 !== undefined
                                            ? this.state.Email3
                                            : this.props.formData &&
                                            (this.props.formData.Email3 ||
                                                this.props.formData.ContactEmailAddress)
                                    }
                                    error={
                                        this.props.formErrors
                                            ? this.props.formErrors['Email3']
                                            : null
                                    }
                                    disabled={readOnly}
                                    maxLength={241}
                                    {...inputProps}
                                    autoComplete="off"
                                    {...(isSFDC ? sfdcInputProps : inputProps)}
                                />
                                <FormInput
                                    label="Email 4"
                                    name="Email4"
                                    team="global"
                                    onChange={this.handleTextChange}
                                    value={
                                        this.state.Email4 !== undefined
                                            ? this.state.Email4
                                            : this.props.formData &&
                                            (this.props.formData.Email4 ||
                                                this.props.formData.ContactEmailAddress)
                                    }
                                    error={
                                        this.props.formErrors
                                            ? this.props.formErrors['Email4']
                                            : null
                                    }
                                    disabled={readOnly}
                                    maxLength={241}
                                    {...inputProps}
                                    autoComplete="off"
                                    {...(isSFDC ? sfdcInputProps : inputProps)}
                                /> */}
                                {deltas[`Email`] ? (
                                    <DeltaField delta={deltas[`Email`]} label="Email" />
                                ) : (
                                    <FormInput
                                        display="flex"
                                        flex={1}
                                        flexDirection="row"
                                        label="Email"
                                        name="Email"
                                        placeholder={!readOnly && emailPlaceHolderList[0]}
                                        maxLength={256}
                                        team="global"
                                        pattern="[^~*:;\^\\><|]+"
                                        onChange={this.handleChange}
                                        // showRequired
                                        error={
                                            this.props.formErrors
                                                ? this.props.formErrors['Email']
                                                : null
                                        }
                                        value={
                                            this.state.Email !== undefined
                                                ? this.state.Email
                                                : this.props.formData &&
                                                this.props.formData.Email
                                        }
                                        rightComponent={() =>
                                            !readOnly && (
                                                <AddIcon onPress={this.addEmailInput} />
                                            )
                                        }
                                        {...(isSFDC ? sfdcInputProps : inputProps)}
                                    />
                                )}
                                {times(emailsInput, (index) => {
                                    index += 1;
                                    if (readOnly) {
                                        return deltas[`Email${index + 1}`] ? (
                                            <DeltaField
                                                delta={deltas[`Email${index + 1}`]}
                                            />
                                        ) : formData[`Email${index + 1}`] ? (
                                            <FormInput
                                                key={`email${index}`}
                                                label={`Email ${index + 1}`}
                                                name={`Email${index + 1}`}
                                                team="global"
                                                maxLength={35}
                                                placeholder={
                                                    !readOnly &&
                                                    emailPlaceHolderList[index]
                                                }
                                                error={
                                                    this.props.formErrors
                                                        ? this.props.formErrors[
                                                        `Email${index + 1}`
                                                        ]
                                                        : null
                                                }
                                                value={formData[`Email${index + 1}`]}
                                                rightComponent={() =>
                                                    !readOnly && (
                                                        <RemoveIcon
                                                            onPress={
                                                                this.removeEmailInput
                                                            }
                                                        />
                                                    )
                                                }
                                                {...inputProps}
                                            />
                                        ) : null;
                                    } else {
                                        return (
                                            <FormInput
                                                key={`email${index}`}
                                                label={`Email ${index + 1}`}
                                                name={`Email${index + 1}`}
                                                maxLength={35}
                                                placeholder={
                                                    !readOnly &&
                                                    emailPlaceHolderList[index]
                                                }
                                                team="global"
                                                error={
                                                    this.props.formErrors
                                                        ? this.props.formErrors[
                                                        `Email${index + 1}`
                                                        ]
                                                        : null
                                                }
                                                pattern="[^~*:;\^\\><|]+"
                                                onChange={this.handleChange}
                                                value={
                                                    this.state[`Email${index + 1}`] !==
                                                        undefined
                                                        ? this.state[`Email${index + 1}`]
                                                        : this.props.formData &&
                                                        this.props.formData[
                                                        `Email${index + 1}`
                                                        ]
                                                }
                                                rightComponent={() =>
                                                    !readOnly && (
                                                        <RemoveIcon
                                                            onPress={
                                                                this.removeEmailInput
                                                            }
                                                        />
                                                    )
                                                }
                                                {...inputProps}
                                            />
                                        );
                                    }
                                })}
                            </>

                            {readOnly && (
                                <Fragment>
                                    {deltas[`Telephone`] ? (
                                        <DeltaField delta={deltas[`Telephone`]} />
                                    ) : (
                                        <FormInput
                                            label="Telephone"
                                            name="telephone"
                                            team="global"
                                            maxLength={20}
                                            value={
                                                this.props.formData &&
                                                (this.props.formData.Telephone ||
                                                    this.props.formData.ContactTelephone)
                                            }
                                            {...inputProps}
                                        />
                                    )}
                                    {deltas[`Fax`] ? (
                                        <DeltaField delta={deltas[`Fax`]} />
                                    ) : (
                                        <FormInput
                                            label="Fax"
                                            name="Fax"
                                            maxLength={20}
                                            team="global"
                                            value={
                                                this.props.formData &&
                                                (this.props.formData.Fax ||
                                                    this.props.formData.ContactFax)
                                            }
                                            {...inputProps}
                                        />
                                    )}
                                    {/* {deltas[`Email`] ? (
                                        <DeltaField delta={deltas[`Email`]} />
                                    ) : (
                                        <FormInput
                                            label="Email"
                                            name="Email"
                                            maxLength={241}
                                            team="global"
                                            value={
                                                this.props.formData &&
                                                (this.props.formData.Email ||
                                                    this.props.formData
                                                        .ContactEmailAddress)
                                            }
                                            {...inputProps}
                                            autoComplete="off"
                                        />
                                    )} */}
                                    <>
                                        {deltas[`MarketingSegmentationTypeId`] ? (
                                            <DeltaField
                                                label="Market Segmentation"
                                                arrayOfData={
                                                    (staticDropDownData &&
                                                        staticDropDownData.JDEMarketSegmentationType) ||
                                                    []
                                                }
                                                delta={
                                                    deltas[`MarketingSegmentationTypeId`]
                                                }
                                            />
                                        ) : (
                                            <DynamicSelect
                                                hide={isOlympus}
                                                arrayOfData={
                                                    (staticDropDownData &&
                                                        staticDropDownData.JDEMarketSegmentationType) ||
                                                    []
                                                }
                                                label="Market Segmentation"
                                                name="MarketingSegmentationTypeId"
                                                isRequired={true}
                                                formErrors={
                                                    this.props.formErrors
                                                        ? this.props.formErrors[
                                                        'MarketingSegmentationTypeId'
                                                        ]
                                                        : null
                                                }
                                                value={
                                                    this.state.formData
                                                        ? this.state.formData[
                                                        'MarketingSegmentationTypeId'
                                                        ]
                                                        : this.props.formData[
                                                        'MarketingSegmentationTypeId'
                                                        ]
                                                }
                                                onFieldChange={this.props.onFieldChange}
                                                readOnly={
                                                    !this.props.showMarketingSegmentation
                                                }
                                                {...(isSFDC && {
                                                    inputProps: {
                                                        disabled: true,
                                                    },
                                                })}
                                            />
                                        )}
                                    </>
                                </Fragment>
                            )}
                        </>
                    </Box>
                    <Box width={1 / 2} mx="auto" alignItems="center">
                        <>
                            {!readOnly && (
                                <Fragment>
                                    <FormInput
                                        label="Telephone"
                                        name="Telephone"
                                        team="global"
                                        error={
                                            this.props.formErrors
                                                ? this.props.formErrors['Telephone']
                                                : null
                                        }
                                        {...FaxAndTaxProps}
                                        disabled={readOnly}
                                        type="text"
                                        pattern="[0-9*#+\-\(\)]+"
                                        maxLength={20}
                                        onChange={this.handleChange}
                                        value={
                                            this.state.Telephone !== undefined
                                                ? this.state.Telephone
                                                : this.props.formData &&
                                                this.props.formData.Telephone
                                        }
                                        {...(isSFDC
                                            ? {
                                                ...FaxAndTaxProps,
                                                disabled: true,
                                                disabledPadding: true,
                                            }
                                            : FaxAndTaxProps)}
                                    />
                                    <FormInput
                                        label="Fax"
                                        name="Fax"
                                        maxLength={20}
                                        team="global"
                                        error={
                                            this.props.formErrors
                                                ? this.props.formErrors['Fax']
                                                : null
                                        }
                                        {...FaxAndTaxProps}
                                        disabled={readOnly}
                                        type="text"
                                        pattern="[0-9*#+\-\(\)]+"
                                        onChange={this.handleChange}
                                        value={
                                            this.state.Fax !== undefined
                                                ? this.state.Fax
                                                : this.props.formData &&
                                                this.props.formData.Fax
                                        }
                                        {...(isSFDC
                                            ? {
                                                ...FaxAndTaxProps,
                                                disabled: true,
                                                disabledPadding: true,
                                            }
                                            : FaxAndTaxProps)}
                                    />

                                    {isSFDC ? (
                                        <FormInput
                                            label="Category"
                                            name="CategoryTypeId"
                                            team="global"
                                            type="text"
                                            value={
                                                jdeCategoryTypes[
                                                formData.CategoryTypeId - 1
                                                ]
                                            }
                                            {...inputProps}
                                            disabled
                                            disabledPadding
                                        />
                                    ) : (
                                        <FormSelect
                                            label="Category"
                                            name="CategoryTypeId"
                                            team="global"
                                            onChange={this.props.onFieldChange}
                                            required
                                            error={
                                                this.props.formErrors
                                                    ? this.props.formErrors[
                                                    'CategoryTypeId'
                                                    ]
                                                    : null
                                            }
                                            variant="solid"
                                            value={this.props.formData['CategoryTypeId']}>
                                            <option hidden={true}>Choose from...</option>
                                            {_CategoryTypes.map((category, index) => (
                                                <option
                                                    key={`category-${index}`}
                                                    value={category.id}>
                                                    {category.name}
                                                </option>
                                            ))}
                                        </FormSelect>
                                    )}

                                    <DynamicSelect
                                        arrayOfData={
                                            (staticDropDownData &&
                                                staticDropDownData.JDEMarketSegmentationType) ||
                                            []
                                        }
                                        label="Market Segmentation "
                                        name="MarketingSegmentationTypeId"
                                        isRequired={true}
                                        formErrors={
                                            this.props.formErrors
                                                ? this.props.formErrors[
                                                'MarketingSegmentationTypeId'
                                                ]
                                                : null
                                        }
                                        onFieldChange={this.props.onFieldChange}
                                        value={
                                            this.state.formData
                                                ? this.state.formData[
                                                'MarketingSegmentationTypeId'
                                                ]
                                                : this.props.formData[
                                                'MarketingSegmentationTypeId'
                                                ]
                                        }
                                        inputProps={selectorProps}
                                        {...(isSFDC && {
                                            inputProps: sfdcInputProps,
                                        })}
                                    />
                                </Fragment>
                            )}

                            <>
                                <>
                                    <>{this.props.children}</>
                                    {!this.props.isCreate && (
                                        <>
                                            {deltas[`DunsNumber`] ? (
                                                <DeltaField
                                                    delta={deltas[`DunsNumber`]}
                                                />
                                            ) : (
                                                <FormInput
                                                    label="DUNS Number"
                                                    name="DunsNumber"
                                                    team="global"
                                                    variant={
                                                        'solid'
                                                    }
                                                    labelColor={
                                                        deltas['DunsNumber'] && '#239d56'
                                                    }
                                                    value={
                                                        (formData &&
                                                            formData.DunsNumber) ||
                                                        ''
                                                    }
                                                    error={
                                                        this.props.formErrors
                                                            ? this.props.formErrors[
                                                            'isDunsValid'
                                                            ]
                                                            : null
                                                    }
                                                    {...dunsInputProps}
                                                    type="text"
                                                />
                                            )}

                                            <>
                                                {deltas[`TaxJurisdiction`] ? (
                                                    <DeltaField
                                                        delta={deltas[`TaxJurisdiction`]}
                                                    />
                                                ) : (
                                                    <FormInput
                                                        mt="10px"
                                                        label="Tax Jurisdiction"
                                                        name="TaxJurisdiction"
                                                        team="global"
                                                        labelColor={
                                                            deltas['TaxJurisdiction'] &&
                                                            '#239d56'
                                                        }
                                                        error={
                                                            this.props.formErrors
                                                                ? this.props.formErrors[
                                                                'TaxJurisdiction'
                                                                ]
                                                                : null
                                                        }
                                                        type="text"
                                                        required
                                                        value={
                                                            this.props.formData
                                                                ? this.props.formData[
                                                                'TaxJurisdiction'
                                                                ]
                                                                : null
                                                        }
                                                        variant="outline"
                                                        inline
                                                    />
                                                )}
                                            </>
                                            {deltas[`TaxNumber`] ? (
                                                <DeltaField delta={deltas[`TaxNumber`]} />
                                            ) : (
                                                <FormInput
                                                    label="Tax Number 1"
                                                    name="TaxNumber"
                                                    maxLength={16}
                                                    team="global"
                                                    delta={deltas['TaxNumber']}
                                                    getValue={() =>
                                                        this.state.TaxNumber
                                                            ? this.state.TaxNumber
                                                            : formData.TaxNumber ||
                                                            formData.Taxnumber
                                                    }
                                                    {...(editableInputs.TaxNumber === true
                                                        ? {
                                                            inline: true,
                                                            readOnly: false,
                                                            onChange: this
                                                                .handleTextChange,
                                                            onBlur: this.props
                                                                .onFieldChange,
                                                        }
                                                        : taxInputProps)}
                                                    type="text"
                                                />
                                            )}
                                            <SicCodeInputs
                                                deltas={deltas}
                                                formData={formData}
                                                inputProps={{
                                                    ...taxInputProps,
                                                    onChange: this
                                                        .handleTextChange,
                                                    onBlur: this.props
                                                        .onFieldChange,
                                                }}
                                            />

                                            {deltas[`NaicsCode`] ? (
                                                <DeltaField
                                                    delta={deltas[`NaicsCode`]}
                                                    getValue={() => {
                                                        const CodeVal = deltas.NaicsCode
                                                            ? deltas.NaicsCode
                                                                .UpdatedValue
                                                            : formData.NaicsCode;
                                                        const CodeDescription =
                                                            formData.NaicsCodeDescription;

                                                        if (CodeDescription) {
                                                            return `${CodeVal}: \n ${CodeDescription}`;
                                                        } else {
                                                            return CodeVal;
                                                        }
                                                    }}
                                                    inputWidth="100%"
                                                    containerWidth="60%"
                                                />
                                            ) : (
                                                <FormInput
                                                    label="NAICS Code"
                                                    name="NaicsCode"
                                                    team="global"
                                                    maxLength={10}
                                                    labelColor={
                                                        deltas['NaicsCode'] && '#239d56'
                                                    }
                                                    whiteSpace="pre-line"
                                                    getValue={() => {
                                                        const CodeVal = deltas.NaicsCode
                                                            ? deltas.NaicsCode
                                                                .UpdatedValue
                                                            : formData.NaicsCode;
                                                        const CodeDescription =
                                                            formData.NaicsCodeDescription;

                                                        if (CodeDescription) {
                                                            return `${CodeVal}: \n ${CodeDescription}`;
                                                        } else {
                                                            return CodeVal;
                                                        }
                                                    }}
                                                    {...taxInputProps}
                                                    {...(formData.NaicsCodeDescription !==
                                                        undefined &&
                                                        formData.NaicsCodeDescription !==
                                                        null && {
                                                        flexDirection: 'row',
                                                        inputWidth: '100%',
                                                        containerWidth: '60%',
                                                        alignItems: 'baseline',
                                                        multiline: true,
                                                        inline: true,
                                                    })}
                                                    type="text"
                                                />
                                            )}
                                            {deltas[`VatRegNo`] ? (
                                                <DeltaField delta={deltas[`VatRegNo`]} />
                                            ) : (
                                                <FormInput
                                                    label="Vat Reg No"
                                                    name="VatRegNo"
                                                    maxLength={25}
                                                    team="global"
                                                    labelColor={
                                                        deltas['VatRegNo'] && '#239d56'
                                                    }
                                                    getValue={() =>
                                                        deltas['VatRegNo']
                                                            ? deltas['VatRegNo']
                                                                .UpdatedValue
                                                            : this.state.VatRegNo
                                                                ? this.state.VatRegNo
                                                                : formData.VatRegNo
                                                    }
                                                    {...(editableInputs.VatRegNo
                                                        ? {
                                                            inline: true,
                                                            readOnly: false,
                                                            onChange: this
                                                                .handleTextChange,
                                                            onBlur: this.props
                                                                .onFieldChange,
                                                        }
                                                        : taxInputProps)}
                                                    type="text"
                                                />
                                            )}
                                            <FormInput
                                                label="Category"
                                                name="CategoryTypeId"
                                                team="global"
                                                type="text"
                                                labelColor={'#239d56'}
                                                value={
                                                    jdeCategoryTypes[
                                                    formData.CategoryTypeId - 1
                                                    ]
                                                }
                                                {...inputProps}
                                            />
                                        </>
                                    )}
                                </>
                                <>
                                    {deltas[`MarketingSegmentationTypeId`] ? (
                                        <DeltaField
                                            label="Market Segmentation"
                                            delta={deltas[`MarketingSegmentationTypeId`]}
                                        />
                                    ) : (
                                        <DynamicSelect
                                            hide={!isOlympus}
                                            arrayOfData={
                                                (staticDropDownData &&
                                                    staticDropDownData.JDEMarketSegmentationType) ||
                                                []
                                            }
                                            label="Market Segmentation"
                                            name="MarketingSegmentationTypeId"
                                            isRequired={true}
                                            formErrors={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                    'MarketingSegmentationTypeId'
                                                    ]
                                                    : null
                                            }
                                            value={
                                                this.state.formData
                                                    ? this.state.formData[
                                                    'MarketingSegmentationTypeId'
                                                    ]
                                                    : this.props.formData[
                                                    'MarketingSegmentationTypeId'
                                                    ]
                                            }
                                            onFieldChange={this.props.onFieldChange}
                                            readOnly={
                                                !this.props.showMarketingSegmentation
                                            }
                                            {...(isSFDC && {
                                                inputProps: {
                                                    disabled: true,
                                                },
                                            })}
                                        />
                                    )}
                                </>
                            </>
                        </>
                    </Box>
                </Box>
            </Fragment>
        );
    }
}

const mapStateToProps = ({ workflows }) => {
    const { fetching, dunsFields = {}, functionalGroupDetails } = workflows;
    return {
        fetching,
        dunsFields,
        functionalGroupDetails,
    };
};

export default connect(mapStateToProps, {
    fetchDuns,
})(JdeGlobalMdmFields);
