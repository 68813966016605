import React, { Component } from 'react';
import { Box } from './common';
import { FieldValue, Field, FieldLabel } from './form';
import { fetchCustomerMasterDropDownData } from '../redux/DropDownDatas';
import Indicator from './Indicator';
import Tooltip from './Tooltip/index';

const DeltaToolTip = ({ children, value, ...rest }) => {
    if (value)
        return (
            <Tooltip content={value} direction="left" useDefaultStyles {...rest}>
                {children}
            </Tooltip>
        );
    else return <Field {...rest}>{children}</Field>;
};

class DeltaField extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropDownDatas: {},
        };
    }
    componentDidMount() {
        fetchCustomerMasterDropDownData().then((res) => {
            const data = res;
            this.setState({ dropDownDatas: data });
        });
    }

    getOriginalValue() {
        const { delta, label, inputWidth, containerWidth } = this.props;
        const { dropDownDatas } = this.state;
        let fname = delta['Name'] + '-updated',
            flabel = delta['Name'],
            fvalue = delta.OriginalValue || ' ';

        if (delta && delta['Name'].toLowerCase().includes('typeid')) {
            if (
                dropDownDatas[delta['Name']] != undefined &&
                parseInt(delta.OriginalValue) != 0 &&
                !this.props.arrayOfData
            ) {
                var matched_desc = dropDownDatas[delta['Name']].filter(
                    (dict) => dict.id == parseInt(delta.OriginalValue)
                );
                var valuepart = matched_desc.length > 0 ? matched_desc[0].value : '';
                var descpart = matched_desc.length > 0 ? matched_desc[0].description : '';
                fvalue =
                    matched_desc.length > 0
                        ? valuepart
                            ? valuepart + ' : ' + descpart
                            : descpart
                        : '';

                var pascalName = delta['Name'].replace('TypeId', '');
                flabel = pascalName.replace(/([a-z])([A-Z])/g, '$1 $2'); // adds space in between every camel case
                // fvalue = matched_desc.length > 0 ? matched_desc[0].description : '';
            } else {
                if (this.props.arrayOfData) {
                    var matched_desc = this.props.arrayOfData.filter(
                        (dict) => dict.Id === parseInt(delta.OriginalValue)
                    );
                    var valuepart = matched_desc.length > 0 ? matched_desc[0].Value : '';
                    var descpart =
                        matched_desc.length > 0 ? matched_desc[0].Description : '';
                    fvalue =
                        matched_desc.length > 0
                            ? valuepart
                                ? valuepart + ' : ' + descpart
                                : descpart
                            : '';

                    var pascalName = delta['Name'].replace('TypeId', '');
                    flabel = pascalName.replace(/([a-z])([A-Z])/g, '$1 $2');
                } else {
                    fvalue = 'none';
                    var pascalName = delta['Name'].replace('TypeId', '');
                    flabel = pascalName.replace(/([a-z])([A-Z])/g, '$1 $2');
                }
            }

            return fvalue;
        } else {
            return delta.OriginalValue;
        }
    }
    render() {
        const { delta, label, inputWidth, containerWidth } = this.props;
        const { dropDownDatas } = this.state;

        let fname = delta['Name'] + '-updated',
            flabel = delta['Name'],
            fvalue = delta.UpdatedValue || ' ';

        if (this.props.getValue) {
            fvalue = this.props.getValue();
        }

        if (delta && delta['Name'].toLowerCase().includes('typeid')) {
            if (
                dropDownDatas[delta['Name']] != undefined &&
                parseInt(delta.UpdatedValue) != 0 &&
                !this.props.arrayOfData
            ) {
                var matched_desc = dropDownDatas[delta['Name']].filter(
                    (dict) => dict.id == parseInt(delta.UpdatedValue)
                );
                if (matched_desc.length > 0) {
                    var valuepart = matched_desc[0].value;
                    var descpart = matched_desc[0].description;
                }
                fvalue =
                    matched_desc.length > 0
                        ? valuepart
                            ? valuepart + ' : ' + descpart
                            : descpart
                        : '';

                var pascalName = delta['Name'].replace('TypeId', '');
                flabel = pascalName.replace(/([a-z])([A-Z])/g, '$1 $2'); // adds space in between every camel case
                // fvalue = matched_desc.length > 0 ? matched_desc[0].description : '';
            } else {
                if (this.props.arrayOfData) {
                    // console.log(delta, this.props.label);
                    var matched_desc = this.props.arrayOfData.filter(
                        (dict) => dict.Id === parseInt(delta.UpdatedValue)
                    );
                    var valuepart = matched_desc.length > 0 ? matched_desc[0].Value : '';
                    var descpart =
                        matched_desc.length > 0 ? matched_desc[0].Description : '';
                    fvalue =
                        matched_desc.length > 0
                            ? valuepart
                                ? valuepart + ' : ' + descpart
                                : descpart
                            : '';

                    var pascalName = delta['Name'].replace('TypeId', '');
                    flabel = pascalName.replace(/([a-z])([A-Z])/g, '$1 $2');
                } else {
                    fvalue = 'none';
                    var pascalName = delta['Name'].replace('TypeId', '');
                    flabel = pascalName.replace(/([a-z])([A-Z])/g, '$1 $2');
                }
            }
        }

        let noChange = false;
        if (delta.UpdatedValue === null) {
            noChange = true;
        }

        let fieldStyle = {};

        if (!noChange) {
            fieldStyle = {
                borderBottomColor: '#EA0029',
                borderBottomWidth: '2px',
                width: '25%',
            };
        }

        return (
            <DeltaToolTip
                value={
                    delta && delta.OriginalValue && delta.OriginalValue !== '0'
                        ? this.getOriginalValue()
                        : null
                }
                inline={!this.props.multiline}
                alignItems={containerWidth && inputWidth && 'baseline'}
                name={fname}>
                {!this.props.multiline ? (
                    <>
                        {delta && !noChange && (
                            <Box ml={delta && '-14px'}>
                                <Indicator />
                            </Box>
                        )}
                        <FieldLabel label={`${label || flabel}`} inline />
                    </>
                ) : (
                    <Box display="flex" flexDirection="row">
                        {delta && !noChange && (
                            <Box ml={delta && '-14px'}>
                                <Indicator />
                            </Box>
                        )}
                        <FieldLabel label={`${label || flabel}`} inline />
                    </Box>
                )}

                <FieldValue
                    name={`${delta.Name}-updated`}
                    padding="0px"
                    style={fieldStyle}
                    inputWidth={inputWidth}
                    containerWidth={containerWidth}>
                    {fvalue}
                </FieldValue>
            </DeltaToolTip>
        );
    }
}

export default DeltaField;
