/**
 * @prettier
 */
import { TextInput } from 'react-native-web';

import React, { Fragment } from 'react';
import {
    ScrollView,
    View,
    TouchableOpacity,
    ActivityIndicator,
    Image,
    StyleSheet,
} from 'react-native';
import {
    DimensionAware,
    getWindowHeight,
    getWindowWidth,
} from 'react-native-dimension-aware';
import * as _ from 'lodash';
import { connect } from 'react-redux';
import { Colors, StyledText } from '../../../theme/Index';
import { MaterialIcons } from '@expo/vector-icons';
import { Flex, Button, Box, Text } from '../../../components/common';
import {
    FormInput,
    FormSelect,
    FormRadioGroup,
    FormRadio,
} from '../../../components/form';
import FilesList from '../../../components/FilesList.js';
import idx from 'idx';
import ReactTooltip from 'react-tooltip';

import { yupFieldValidation, yupAllFieldsValidation } from '../../../constants/utils';
import GlobalMdmFields from '../../../components/GlobalMdmFields';
import DynamicSelect from '../../../components/DynamicSelect';
import { fetchBlockDropDownData } from '../../../redux/DropDownDatas';
import { getStaticRequest } from '../../../appRedux/actions';
import { getCustomerFromSAP } from '../../../appRedux/actions/Customer.js';
import FileInput from '../../../components/form/FileInput.jsx';
import { ajaxGetRequest } from '../../../appRedux/sagas/config';
import { getTaxJurisdictionData } from '../../../appRedux/actions/MyTasks';
import { requestBlock } from '../../../appRedux/actions/UpdateFlowAction';
import { removeMessage } from '../../../appRedux/actions/Toast';
import FlashMessage, { FlashMessages } from '../../../components/FlashMessage';
import { CheckBoxItem } from '../../../components/CheckBoxItem';
import DeltaField from '../../../components/DeltaField';
import {
    RoleType,
    SystemType,
    DistributionChannelType,
    DivisionType,
    CompanyCodeType,
    SalesOrgType,
} from '../../../constants/WorkflowEnums';
import { GenerateWorkflowId } from '@env';
import { BlockFieldRules } from '../../../constants/FieldRules';

const SystemValidValues = Object.keys(SystemType).map((index) => {
    const system = SystemType[index];
    return { id: index, description: system };
});

const SalesOrgValidValues = Object.keys(SalesOrgType).map((index) => {
    const system = SalesOrgType[index];
    return { id: index, description: system, value: system };
});

function merge(...schemas) {
    const [first, ...rest] = schemas;

    const merged = rest.reduce(
        (mergedSchemas, schema) => mergedSchemas.concat(schema),
        first
    );

    return merged;
}

const initFormdData = {
    SystemTypeId: 1,
};

const mapKeys = (obj, callback) => {
    const keys = Object.keys(obj);
    return keys.map((key, index) => callback(obj[key], key));
};

class Page extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            system: '',
            role: '',
            formData: {
                ...initFormdData,
                WorkflowTitle: '',
                OverallBlock: false,
                AllCompanyCodes: false,
                SelectedCompanyCode: false,
                OrderAllSalesAreaId: 0,
                OrderSelectedSalesAreaId: 0,
                DeliveryAllSalesAreaId: 0,
                DeliverySelectedSalesAreaId: 0,
                BillingAllSalesAreaId: 0,
                BillingSelectedSalesAreaId: 0,
            },
            updatedFormData: {},
            dropDownDatas: {},
            taxUpdated: false,
            fetchingWorkflowId: false,
            fileErrors: {},
            selectedFiles: {},
            selectedFilesIds: [],
            files: [],
            dunsData: {},
            userId: localStorage.getItem('userId'),
            inputPropsForDefaultRules: {},
            fileinputkey: Date.now(),
            isDeltaChange: false,
            existingBlockData: {},
            newBlockData: {},
        };
    }

    generateWorkflowId() {
        const url = GenerateWorkflowId;

        ajaxGetRequest(url).then((res) => {
            if (res.IsSuccess)
                this.setState({
                    fetchingWorkflowId: false,
                    formData: {
                        ...initFormdData,
                        ...this.state.formData,
                        WorkflowId: res.ResultData,
                        UserId: this.state.userId,
                    },
                });
        });
    }

    componentDidMount() {
        const { state } = this.props.location;
        this.setState({
            formData: {
                ...this.state.formData,
                ...state,
            },
        });
        var jsonBody = state.sysFieldsData;
        this.props.getCustomerFromSAP(jsonBody);

        this.props.getStaticRequest([10, 11, 12]);
        fetchBlockDropDownData().then((res) => {
            const data = res;
            this.setState({ dropDownDatas: data }, () => {
                this.generateWorkflowId();
            });
        });
    }

    componentWillReceiveProps(newProps) {
        if (newProps.bapiFullSet != this.props.bapiFullSet) {
            var blockUnblockData = newProps.bapiFullSet.CustomerData.BlockUnblockData;
            this.setState({
                formData: {
                    ...this.state.formData,
                    AllCompanyCodes: blockUnblockData.PostingBlock.AllCompanyCodes,
                    SelectedCompanyCode:
                        blockUnblockData.PostingBlock.SelectedCompanyCode,
                    OrderAllSalesAreaId: blockUnblockData.OrderBlock.AllSalesAreaId,
                    OrderSelectedSalesAreaId:
                        blockUnblockData.OrderBlock.SelectedSalesAreaId,
                    DeliveryAllSalesAreaId: blockUnblockData.DeliveryBlock.AllSalesAreaId,
                    DeliverySelectedSalesAreaId:
                        blockUnblockData.DeliveryBlock.SelectedSalesAreaId,
                    BillingAllSalesAreaId: blockUnblockData.BillingBlock.AllSalesAreaId,
                    BillingSelectedSalesAreaId:
                        blockUnblockData.BillingBlock.SelectedSalesAreaId,
                },
                existingBlockData: {
                    AllCompanyCodes: blockUnblockData.PostingBlock.AllCompanyCodes,
                    SelectedCompanyCode:
                        blockUnblockData.PostingBlock.SelectedCompanyCode,
                    OrderAllSalesAreaId: blockUnblockData.OrderBlock.AllSalesAreaId,
                    OrderSelectedSalesAreaId:
                        blockUnblockData.OrderBlock.SelectedSalesAreaId,
                    DeliveryAllSalesAreaId: blockUnblockData.DeliveryBlock.AllSalesAreaId,
                    DeliverySelectedSalesAreaId:
                        blockUnblockData.DeliveryBlock.SelectedSalesAreaId,
                    BillingAllSalesAreaId: blockUnblockData.BillingBlock.AllSalesAreaId,
                    BillingSelectedSalesAreaId:
                        blockUnblockData.BillingBlock.SelectedSalesAreaId,
                },
                newBlockData: {
                    AllCompanyCodes: blockUnblockData.PostingBlock.AllCompanyCodes,
                    SelectedCompanyCode:
                        blockUnblockData.PostingBlock.SelectedCompanyCode,
                    OrderAllSalesAreaId: blockUnblockData.OrderBlock.AllSalesAreaId,
                    OrderSelectedSalesAreaId:
                        blockUnblockData.OrderBlock.SelectedSalesAreaId,
                    DeliveryAllSalesAreaId: blockUnblockData.DeliveryBlock.AllSalesAreaId,
                    DeliverySelectedSalesAreaId:
                        blockUnblockData.DeliveryBlock.SelectedSalesAreaId,
                    BillingAllSalesAreaId: blockUnblockData.BillingBlock.AllSalesAreaId,
                    BillingSelectedSalesAreaId:
                        blockUnblockData.BillingBlock.SelectedSalesAreaId,
                },
            });
        }
    }

    isAnyDelta = () => {
        console.log(this.state.existingBlockData, this.state.newBlockData);
        delete this.state.newBlockData.WorkflowTitle;
        delete this.state.newBlockData.Purpose;
        var eq =
            JSON.stringify(this.state.existingBlockData) !=
            JSON.stringify(this.state.newBlockData);
        console.log(eq);
        this.setState({
            isDeltaChange: eq,
        });
    };

    onFieldChange = (val, e) => {
        const name = e.target.name;
        const value = name === 'Country' ? val.toUpperCase() : val;
        const { formData: prevFormData } = this.state;

        this.setState(
            (prevState, props) => {
                return {
                    formData: {
                        ...prevState.formData,
                        [name]: typeof value == 'number' ? parseInt(value) : value,
                    },
                    newBlockData: {
                        ...this.state.newBlockData,
                        [name]: typeof value == 'number' ? parseInt(value) : value,
                    },
                };
            },
            () => {
                this.isAnyDelta();
            }
        );
    };

    setFormDataValues = (name, value) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: value,
            },
        });
    };
    setInputPropsForDefaultRules = (field_name, property) => {
        this.setState({
            inputPropsForDefaultRules: {
                ...this.state.inputPropsForDefaultRules,
                [field_name]: property,
            },
        });
    };

    setFormErrors = (errors) => {
        const { formErrors } = this.state;
        this.setState({ formErrors: errors }, () => window.scrollTo(0, 0));
    };

    isEmpty = (str) => {
        // checking if a string is empty, null or undefined
        return !str || 0 === str.length;
    };
    proceedAction = () => {
        const { history, location } = this.props;
        const { state } = location;
        const { CustomerData } = this.props.bapiFullSet;

        const { selectedFilesIds, selectedFiles, formData, userId } = this.state;
        let jsonData = {
            IsSaveToWorkflow: true,
            WorkflowType: 23,
            UserId: userId,
            WorkflowId: formData.WorkflowId,
            WorkflowTitle: formData.WorkflowTitle,
            MdmCustomerId: this.props.match.params.id,
            SystemRecordId: this.props.bapiFullSet.SystemRecordId,
            SystemTypeId: 1,
            CustomerName: CustomerData.Name1,
            RoleTypeId: CustomerData.RoleTypeId,
            SalesOrgTypeId: CustomerData.SalesOrgTypeId,
            CompanyCodeTypeId: CustomerData.CompanyCodeTypeId,
            DistributionChannelTypeId: CustomerData.DistributionChannelTypeId,
            DivisionTypeId: CustomerData.DivisionTypeId,
            Data: {
                OrderBlock: {
                    AllSalesAreaId: parseInt(this.state.formData.OrderAllSalesAreaId),
                    SelectedSalesAreaId: parseInt(
                        this.state.formData.OrderSelectedSalesAreaId
                    ),
                },
                DeliveryBlock: {
                    AllSalesAreaId: parseInt(this.state.formData.DeliveryAllSalesAreaId),
                    SelectedSalesAreaId: parseInt(
                        this.state.formData.DeliverySelectedSalesAreaId
                    ),
                },
                BillingBlock: {
                    AllSalesAreaId: parseInt(this.state.formData.BillingAllSalesAreaId),
                    SelectedSalesAreaId: parseInt(
                        this.state.formData.BillingSelectedSalesAreaId
                    ),
                },
                PostingBlock: {
                    AllCompanyCodes: this.state.formData.AllCompanyCodes,
                    SelectedCompanyCode: this.state.formData.SelectedCompanyCode,
                },
                Purpose: this.state.formData.Purpose,
            },
        };
        let postData = {
            jsonData,
            action: 'block-unblock',
            files: selectedFilesIds.map((id) => selectedFiles[id]),
            history,
        };
        console.log('state', state, 'props', this.props, 'pos', postData);
        this.props.requestBlock(postData);
    };

    Submit = (e) => {
        let { formData, selectedFilesIds, selectedFiles } = this.state;
        const { Category, ...data } = formData;
        let fileErrors = {};
        let errors = false;

        selectedFilesIds.map((id) => {
            if (selectedFiles[id] && selectedFiles[id].DocumentType <= 0) {
                fileErrors[id] = 'Document Type Required for file';
                errors = true;
            }
        });

        this.setState({ fileErrors, isFileErrors: errors });
        yupAllFieldsValidation(
            this.state.formData,
            BlockFieldRules,
            (...rest) => {
                if (this.state.isFileErrors === false) this.proceedAction(...rest);
            },
            this.setFormErrors
        );
    };

    selectFile = (events) => {
        event.preventDefault();

        const { selectedFilesIds, selectedFiles } = this.state;
        const id = events.target.files[0].name;

        this.setState({
            selectedFiles: {
                ...selectedFiles,
                [id]: {
                    data: events.target.files[0],
                    DocumentName: events.target.files[0].name,
                    DocumentType: 0,
                },
            },
            selectedFilesIds: [...selectedFilesIds, id],
            filename: events.target.files[0].name,
            fileinputkey: Date.now(),
        });
    };

    removeFile = (id) => {
        event.preventDefault();

        var selectedFilesDict = this.state.selectedFiles;
        var selectedFilesIdsList = this.state.selectedFilesIds;
        // remove the existing state
        delete selectedFilesDict[id];
        selectedFilesIdsList = selectedFilesIdsList.filter((fileid) => fileid !== id);

        this.setState({
            selectedFiles: selectedFilesDict,
            selectedFilesIds: selectedFilesIdsList,
        });
    };

    render() {
        const { width, height, marginBottom, location } = this.props;
        const { dropDownDatas, formData, selectedFilesIds, selectedFiles } = this.state;
        let Deltas = [];
        const { state } = location;
        const {
            bapiFullSet: { CustomerData: globalMdmDetail = {}, Deltas: erpDeltas = [] },
        } = this.props;
        let workflowtype = 'Block - UnBlock';
        const MdmNumber = this.props.match.params.id;
        const SystemRecordId = this.props.bapiFullSet.SystemRecordId;

        if (
            this.state.fetchingWorkflowId === true ||
            this.props.fetching ||
            !this.state.formData
        )
            return (
                <Box
                    display="flex"
                    flex={1}
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                    minHeight="650px"
                    bg="#eff3f6">
                    <FlashMessages
                        toasts={this.props.toasts}
                        onDismiss={this.props.removeMessage}
                    />
                    <View>
                        <ActivityIndicator size="large" />
                    </View>
                </Box>
            );

        return (
            <ScrollView
                keyboardShouldPersistTaps="always"
                style={{
                    backgroundColor: '#eff3f6',
                    paddingTop: 50,
                    paddingBottom: 75,
                }}>
                <FlashMessages
                    toasts={this.props.toasts}
                    onDismiss={this.props.removeMessage}
                />
                <View
                    style={{
                        flex: 1,
                        paddingHorizontal: width < 1440 ? 75 : width * 0.1,
                        paddingBottom: 10,
                    }}>
                    <Box fullHeight my={2}>
                        <Box
                            flexDirection="row"
                            justifyContent="space-around"
                            my={4}
                            alignItems="center">
                            <FormInput
                                padding="8px 25px 0px 25px"
                                style={{ lineHeight: '2', paddingBottom: 0 }}
                                flex={1 / 4}
                                mb={2}
                                required
                                maxLength={40}
                                onChange={this.onFieldChange}
                                error={
                                    this.state.formErrors
                                        ? this.state.formErrors['WorkflowTitle']
                                        : null
                                }
                                value={formData.WorkflowTitle || ''}
                                label="Workflow Title"
                                name="WorkflowTitle"
                            />
                            <FormInput
                                px="25px"
                                flex={1 / 4}
                                label="Workflow Number"
                                name="WorkflowId"
                                style={{ lineHeight: '2' }}
                                variant="outline"
                                type="text"
                                value={formData.WorkflowId || ''}
                                disabled
                            />

                            <FormInput
                                px="25px"
                                flex={1 / 4}
                                label="Customer Number"
                                name="Customer Number"
                                style={{ lineHeight: '2' }}
                                variant="outline"
                                type="text"
                                value={SystemRecordId || ''}
                            />

                            {!this.isEmpty(MdmNumber) && (
                                <TouchableOpacity
                                    onPress={() =>
                                        this.props.history.push(
                                            `/search-results/${MdmNumber}`
                                        )
                                    }>
                                    <FormInput
                                        px="25px"
                                        flex={1 / 4}
                                        label="MDM Number"
                                        name="mdm-number"
                                        value={MdmNumber || ''}
                                        style={{ lineHeight: '2' }}
                                        variant="outline"
                                        type="text"
                                        disabled
                                    />
                                </TouchableOpacity>
                            )}
                        </Box>

                        <Text
                            m="10px 0 24px 5%"
                            ml="2%"
                            fontWeight="light"
                            color="#4195C7"
                            fontSize="24px">
                            SYSTEM FIELDS
                        </Text>
                        <Box flexDirection="row" justifyContent="center">
                            <Box width={1 / 2} mx="auto" alignItems="center">
                                {Deltas && Deltas['SystemType'] ? (
                                    <DeltaField delta={Deltas['SystemType']} />
                                ) : (
                                    <FormInput
                                        label="System"
                                        name="System"
                                        team="system"
                                        inline
                                        variant="outline"
                                        type="text"
                                        value={
                                            SystemType[
                                                globalMdmDetail &&
                                                    (globalMdmDetail.SystemTypeId ||
                                                        globalMdmDetail.SystemType)
                                            ]
                                        }
                                    />
                                )}
                                {Deltas && Deltas['RoleTypeId'] ? (
                                    <DeltaField delta={Deltas['RoleTypeId']} />
                                ) : (
                                    <FormInput
                                        label="Role"
                                        name="Role"
                                        team="system"
                                        inline
                                        variant="outline"
                                        type="text"
                                        value={
                                            RoleType[
                                                globalMdmDetail &&
                                                    globalMdmDetail.RoleTypeId
                                            ]
                                        }
                                    />
                                )}

                                {Deltas && Deltas['SalesOrgTypeId'] ? (
                                    <DeltaField delta={Deltas['SalesOrgTypeId']} />
                                ) : (
                                    <FormInput
                                        label="Sales Org"
                                        name="SalesOrg"
                                        team="system"
                                        inline
                                        variant="outline"
                                        type="text"
                                        value={
                                            SalesOrgType[
                                                globalMdmDetail &&
                                                    globalMdmDetail.SalesOrgTypeId
                                            ]
                                        }
                                    />
                                )}
                                {this.state.extendSalesOrgEnabled ||
                                workflowtype === 'extend-salesorg-process' ? (
                                    <FormInput
                                        label="Target Sales Org"
                                        name="TargetSalesOrg"
                                        team="system"
                                        inline
                                        variant="outline"
                                        type="text"
                                        value={
                                            SalesOrgType[
                                                this.props.bapiFullSet[
                                                    'TargetSalesOrgTypeId'
                                                ] ||
                                                    (globalMdmDetail &&
                                                        globalMdmDetail.TargetSalesOrgTypeId)
                                            ]
                                        }
                                    />
                                ) : null}
                            </Box>
                            <Box width={1 / 2} mx="auto" alignItems="center">
                                {Deltas && Deltas['DistributionChannelTypeId'] ? (
                                    <DeltaField
                                        delta={Deltas['DistributionChannelTypeId']}
                                    />
                                ) : (
                                    <FormInput
                                        label="Distribution Channel"
                                        name="DistributionChannel"
                                        team="system"
                                        inline
                                        variant="outline"
                                        type="text"
                                        value={
                                            DistributionChannelType[
                                                globalMdmDetail &&
                                                    globalMdmDetail.DistributionChannelTypeId
                                            ]
                                        }
                                    />
                                )}

                                {Deltas && Deltas['DivisionTypeId'] ? (
                                    <DeltaField delta={Deltas['DivisionTypeId']} />
                                ) : (
                                    <FormInput
                                        label="Division"
                                        name="DivisionTypeId"
                                        team="system"
                                        inline
                                        variant="outline"
                                        type="text"
                                        value={
                                            DivisionType[
                                                globalMdmDetail &&
                                                    globalMdmDetail.DivisionTypeId
                                            ]
                                        }
                                    />
                                )}

                                {Deltas && Deltas['CompanyCodeTypeId'] ? (
                                    <DeltaField delta={Deltas['CompanyCodeTypeId']} />
                                ) : (
                                    <FormInput
                                        label="CompanyCode"
                                        name="CompanyCodeTypeId"
                                        team="system"
                                        inline
                                        variant="outline"
                                        type="text"
                                        value={
                                            CompanyCodeType[
                                                globalMdmDetail &&
                                                    globalMdmDetail.CompanyCodeTypeId
                                            ]
                                        }
                                    />
                                )}
                            </Box>
                        </Box>
                        <Text
                            data-tip
                            data-for="postingblock"
                            m="10px 0 0 5%"
                            ml="2%"
                            style={{ width: '15%' }}
                            fontWeight="light"
                            color="#4195C7"
                            fontSize="24px">
                            Posting Block
                        </Text>
                        <ReactTooltip
                            id="postingblock"
                            place="right"
                            backgroundColor="#233665"
                            effect="solid">
                            If selected, the system prevents users from posting items to
                            the account.
                        </ReactTooltip>
                        <Box flexDirection="row" justifyContent="center">
                            <Box width={1 / 2} mx="auto" alignItems="center">
                                <CheckBoxItem
                                    team="customermaster"
                                    title="All Company Codes"
                                    name="AllCompanyCodes"
                                    style={{ height: '40px' }}
                                    stateValue={
                                        this.state.formData &&
                                        this.state.formData.AllCompanyCodes
                                    }
                                    onValueChange={() =>
                                        this.setState(
                                            {
                                                formData: {
                                                    ...this.state.formData,
                                                    AllCompanyCodes: !this.state.formData
                                                        .AllCompanyCodes,
                                                },
                                                newBlockData: {
                                                    ...this.state.newBlockData,
                                                    AllCompanyCodes: !this.state.formData
                                                        .AllCompanyCodes,
                                                },
                                            },
                                            () => this.isAnyDelta()
                                        )
                                    }
                                />
                                <CheckBoxItem
                                    team="customermaster"
                                    title="Selected Company Code"
                                    name="SelectedCompanyCode"
                                    stateValue={
                                        this.state.formData &&
                                        this.state.formData.SelectedCompanyCode
                                    }
                                    onValueChange={() =>
                                        this.setState(
                                            {
                                                formData: {
                                                    ...this.state.formData,
                                                    SelectedCompanyCode: !this.state
                                                        .formData.SelectedCompanyCode,
                                                },
                                                newBlockData: {
                                                    ...this.state.newBlockData,
                                                    SelectedCompanyCode: !this.state
                                                        .formData.SelectedCompanyCode,
                                                },
                                            },
                                            () => this.isAnyDelta()
                                        )
                                    }
                                />
                            </Box>
                            <Box width={1 / 2} mx="auto" alignItems="center"></Box>
                        </Box>
                        <Text
                            ml="2%"
                            marginTop="25px"
                            fontWeight="light"
                            color="#4195C7"
                            fontSize="24px">
                            Sales and Distribution Blocks
                        </Text>

                        <Box flexDirection="row" justifyContent="center">
                            <Box width={1 / 2} mx="auto" alignItems="center">
                                <Text
                                    data-tip
                                    data-for="orderblock"
                                    fontWeight="500"
                                    color="#"
                                    fontSize="20px"
                                    marginTop="20px"
                                    marginLeft="-240px"
                                    height="15px">
                                    Order Block
                                </Text>
                                <ReactTooltip
                                    id="orderblock"
                                    place="right"
                                    backgroundColor="#233665"
                                    effect="solid">
                                    If selected, sales order processing is blocked for the
                                    account.
                                </ReactTooltip>
                                <DynamicSelect
                                    arrayOfData={
                                        (this.props.staticData &&
                                            this.props.staticData.ApolloBlockOrderType) ||
                                        []
                                    }
                                    spliceIdZero
                                    label="All Sales Area "
                                    name="OrderAllSalesAreaId"
                                    showInlineRequired={false}
                                    onFieldChange={this.onFieldChange}
                                    value={
                                        this.state.formData
                                            ? this.state.formData['OrderAllSalesAreaId']
                                            : null
                                    }
                                />

                                <DynamicSelect
                                    arrayOfData={
                                        (this.props.staticData &&
                                            this.props.staticData.ApolloBlockOrderType) ||
                                        []
                                    }
                                    spliceIdZero
                                    label="Selected Sales Area"
                                    name="OrderSelectedSalesAreaId"
                                    showInlineRequired={false}
                                    onFieldChange={this.onFieldChange}
                                    value={
                                        this.state.formData
                                            ? this.state.formData[
                                                  'OrderSelectedSalesAreaId'
                                              ]
                                            : null
                                    }
                                />
                            </Box>
                            <Box width={1 / 2} mx="auto" alignItems="center"></Box>
                        </Box>

                        <Box flexDirection="row" justifyContent="center">
                            <Box width={1 / 2} mx="auto" alignItems="center">
                                <Text
                                    data-tip
                                    data-for="deliveryblock"
                                    fontWeight="500"
                                    color="#"
                                    fontSize="20px"
                                    marginTop="20px"
                                    marginLeft="-220px"
                                    height="15px">
                                    Delivery Block
                                </Text>
                                <ReactTooltip
                                    id="deliveryblock"
                                    place="right"
                                    backgroundColor="#233665"
                                    effect="solid">
                                    If selected, delivery processing is blocked for the
                                    account.
                                </ReactTooltip>
                                <DynamicSelect
                                    arrayOfData={
                                        (this.props.staticData &&
                                            this.props.staticData
                                                .ApolloBlockDeliveryType) ||
                                        []
                                    }
                                    spliceIdZero
                                    label="All Sales Area "
                                    name="DeliveryAllSalesAreaId"
                                    showInlineRequired={false}
                                    onFieldChange={this.onFieldChange}
                                    value={
                                        this.state.formData
                                            ? this.state.formData[
                                                  'DeliveryAllSalesAreaId'
                                              ]
                                            : null
                                    }
                                />

                                <DynamicSelect
                                    arrayOfData={
                                        (this.props.staticData &&
                                            this.props.staticData
                                                .ApolloBlockDeliveryType) ||
                                        []
                                    }
                                    spliceIdZero
                                    label="Selected Sales Area"
                                    name="DeliverySelectedSalesAreaId"
                                    showInlineRequired={false}
                                    onFieldChange={this.onFieldChange}
                                    value={
                                        this.state.formData
                                            ? this.state.formData[
                                                  'DeliverySelectedSalesAreaId'
                                              ]
                                            : null
                                    }
                                />
                            </Box>
                            <Box width={1 / 2} mx="auto" alignItems="center"></Box>
                        </Box>

                        <Box flexDirection="row" justifyContent="center">
                            <Box width={1 / 2} mx="auto" alignItems="center">
                                <Text
                                    data-tip
                                    data-for="billingblock"
                                    fontWeight="500"
                                    color="#"
                                    fontSize="20px"
                                    marginTop="20px"
                                    marginLeft="-240px"
                                    height="15px">
                                    Billing Block
                                </Text>
                                <ReactTooltip
                                    id="billingblock"
                                    place="right"
                                    backgroundColor="#233665"
                                    effect="solid">
                                    If selected, the processing of billing documents is
                                    blocked for the account.
                                </ReactTooltip>
                                <DynamicSelect
                                    arrayOfData={
                                        (this.props.staticData &&
                                            this.props.staticData
                                                .ApolloBlockBillingType) ||
                                        []
                                    }
                                    spliceIdZero
                                    label="All Sales Area "
                                    name="BillingAllSalesAreaId"
                                    showInlineRequired={false}
                                    onFieldChange={this.onFieldChange}
                                    value={
                                        this.state.formData
                                            ? this.state.formData['BillingAllSalesAreaId']
                                            : null
                                    }
                                />

                                <DynamicSelect
                                    arrayOfData={
                                        (this.props.staticData &&
                                            this.props.staticData
                                                .ApolloBlockBillingType) ||
                                        []
                                    }
                                    spliceIdZero
                                    label="Selected Sales Area"
                                    name="BillingSelectedSalesAreaId"
                                    showInlineRequired={false}
                                    onFieldChange={this.onFieldChange}
                                    value={
                                        this.state.formData
                                            ? this.state.formData[
                                                  'BillingSelectedSalesAreaId'
                                              ]
                                            : null
                                    }
                                />
                            </Box>
                            <Box width={1 / 2} mx="auto" alignItems="center"></Box>
                        </Box>

                        {/*
                        <Box flexDirection="row" justifyContent="center">
                            <Box width={1 / 2} mx="auto" alignItems="center">
                                <CheckBoxItem
                                    team="customermaster"
                                    title="Block All Sales Org"
                                    name="OverallBlock"
                                    stateValue={
                                        this.state.formData &&
                                        this.state.formData.OverallBlock
                                    }
                                    onValueChange={() =>
                                        this.setState({
                                            formData: {
                                                ...this.state.formData,
                                                OverallBlock: !this.state.formData
                                                    .OverallBlock,
                                            },
                                        })
                                    }
                                />
                            </Box>
                            <Box width={1 / 2} mx="auto" alignItems="center" />
                        </Box>
                                */}
                        <Box mt={2} flexDirection="row" justifyContent="center">
                            <Box width={0.79} mx="auto" alignItems="center">
                                <FormInput
                                    maxWidth="98%"
                                    label="Purpose of Request"
                                    style={{ height: '100px' }}
                                    name="Purpose"
                                    value={this.state.formData.Purpose || ''}
                                    maxLength={1000}
                                    multiline
                                    numberOfLines={4}
                                    onChange={this.onFieldChange}
                                />
                            </Box>
                        </Box>
                        <FilesList
                            formErrors={this.state.fileErrors}
                            files={selectedFilesIds.map((id) => selectedFiles[id])}
                            onChange={(value, id) => {
                                this.setState({
                                    selectedFiles: {
                                        ...selectedFiles,
                                        [id]: {
                                            ...selectedFiles[id],
                                            DocumentType: parseInt(value),
                                        },
                                    },
                                });
                            }}
                            onRemove={(value, id) => this.removeFile(id)}
                        />
                    </Box>

                    <Flex
                        justifyEnd
                        alignCenter
                        style={{
                            paddingTop: 65,
                            flexDirection: 'row',
                            alignItems: 'center',
                            paddingLeft: 10,
                            paddingRight: 15,
                            marginTop: 20,
                            marginBottom: 10,
                            marginHorizontal: 25,
                        }}>
                        <label htmlFor="file-upload" className="custom-file-upload">
                            <MaterialIcons name="attach-file" size={18} color="#fff" />
                        </label>
                        <input
                            id="file-upload"
                            key={this.state.fileinputkey}
                            type="file"
                            onChange={this.selectFile}
                        />

                        <Button
                            onPress={() => this.props.history.goBack()}
                            title="Cancel"
                        />

                        <Button
                            disabled={!this.state.isDeltaChange}
                            onPress={(e) => this.Submit(e)}
                            title="Submit"
                            type="submit"
                        />
                    </Flex>
                </View>
            </ScrollView>
        );
    }
}

class Default extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const props = this.props;

        return (
            <DimensionAware
                render={(dimensions) => (
                    <Page
                        {...{
                            ...props,
                            width: getWindowWidth(dimensions),
                            height: getWindowHeight(dimensions),
                            marginBottom: 25,
                        }}
                    />
                )}
            />
        );
    }
}

const styles = StyleSheet.create({
    progressIndicator: {
        flex: 1,
        paddingBottom: 5,
        flexDirection: 'row-reverse',
        alignItems: 'flex-end',
    },
    statusText: {
        fontSize: 15,
        color: '#1D4289',
        fontFamily: 'Poppins',
        textAlign: 'center',
        marginTop: 20,
    },
});

const mapStateToProps = ({ customer, toasts, updateFlow, staticData }) => {
    const { fetching: fetchinUpdateFlow } = updateFlow;
    const { bapiFullSet, alert: customerAlert, fetching: fetchingCustomer } = customer;
    const { data, fetching: staticFetching } = staticData;
    return {
        bapiFullSet,
        fetching: fetchingCustomer || fetchinUpdateFlow || staticFetching,
        alert: customerAlert,
        toasts,
        staticData: data,
    };
};

export default connect(mapStateToProps, {
    removeMessage,
    getCustomerFromSAP,
    requestBlock,
    getStaticRequest,
})(Default);
