import React, { Component, Fragment } from 'react';
import { Flex, Button, Box, Text } from './common';
import { connect } from 'react-redux';
import { FormInput, FormSelect } from './form';
import * as moment from 'moment';
import { ScrollView, TouchableOpacity, View } from 'react-native';
import DynamicSelect from './DynamicSelect';
import idx from 'idx';

class OlympusPartnersFilterFields extends Component {
    constructor(props) {
        super(props);
    }

    getDropdownValues = (key, data, stateKey, checkValue) => {
        let dropdownData = [];
        const self = this;
        const { formData } = this.props;
        if (data) {
            dropdownData = [];
            data.forEach((dropdownList) => {
                if (dropdownList.ValidationData.length > 0) {
                    dropdownList.ValidationData.forEach((datatofilter) => {
                        if (datatofilter[key] == parseInt(formData[stateKey])) {
                            dropdownData.push(dropdownList);
                        }
                    });
                }
            });
        }
        var check = new Set();
        let filteredArr = dropdownData.filter(
            (obj) => !check.has(obj['Id']) && check.add(obj['Id'])
        );

        return this.getDistinctDropDownData(
            filteredArr,
            checkValue == 'DistributionChannelTypeId' ? 'DistChannels' : 'Divisions'
        );
    };
    getDistinctDropDownData = (data, key) => {
        const { formData } = this.props;
        let filterData = [];
        if (data && formData && formData.filterList && formData.filterList[key]) {
            filterData = data.filter((fileid) => {
                return (
                    formData.filterList[key].includes(fileid.Value) ||
                    formData.filterList[key].includes(fileid.Id.toString())
                );
            });
        } else {
            filterData = [];
        }
        return filterData;
    };

    render() {
        const hiddenFields = {
            ...this.props.hiddenFields,
        };
        let { formData = {}, staticDropDownData = [], dropDownDatas = [] } = this.props;
        let SalesOrgType = idx(dropDownDatas, (_) => _.OlympusSalesOrgType) || [];
        let salesOrgData = SalesOrgType;
        let isPointman = parseInt(this.props.SystemTypeId) === 3;
        const inputProps = {
            inline: false,
            readOnly: false,
            onChange: this.props.onFieldChange,
        };

        console.log(
            !this.props.formData.CustomerOrWorkflowId,
            this.getDistinctDropDownData(salesOrgData, 'SalesOrgs')
        );
        console.log(salesOrgData);

        return (
            <Fragment>
                <Box marginLeft="0%" flexDirection="row" justifyContent="left">
                    <Box mt="4px" alignItems="left">
                        <FormInput
                            label="Customer/Workflow Number"
                            name="CustomerOrWorkflowId"
                            error={
                                this.props.formErrors
                                    ? this.props.formErrors['CustomerOrWorkflowId']
                                    : null
                            }
                            value={this.props.formData.CustomerOrWorkflowId || ''}
                            {...inputProps}
                            onBlur={this.props.onBlurChange}
                        />

                        {this.props.children}
                    </Box>
                    {!isPointman && (
                        <Box mx="2" alignItems="left">
                            <DynamicSelect
                                arrayOfData={this.getDistinctDropDownData(
                                    salesOrgData,
                                    'SalesOrgs'
                                )}
                                label="Sales Org"
                                name="SalesOrgTypeId"
                                isRequired
                                value={this.props.formData.SalesOrgTypeId}
                                formErrors={
                                    this.props.formErrors
                                        ? this.props.formErrors['SalesOrgTypeId']
                                        : null
                                }
                                onFieldChange={this.props.onFieldChange}
                                disabled={
                                    !this.props.formData.CustomerOrWorkflowId ||
                                    this.getDistinctDropDownData(
                                        salesOrgData,
                                        'SalesOrgs'
                                    ).length == 0
                                }
                                // readOnly={formData.filterList && formData.filterList['SalesOrgs']?false:true}
                            />
                        </Box>
                    )}
                    {/* {!isPointman && (
                        <Box mx="2" alignItems="left">
                            <DynamicSelect
                                arrayOfData={
                                    dropDownDatas.RoleTypeId &&
                                    dropDownDatas.RoleTypeId.filter(
                                        (role) =>
                                            role.systemId ===
                                                parseInt(this.props.SystemTypeId) &&
                                            role.id <= 6
                                    )
                                }
                                label="Role"
                                name="RoleTypeId"
                                isRequired
                                value={this.props.formData.RoleTypeId}
                                formErrors={
                                    this.props.formErrors
                                        ? this.props.formErrors['RoleTypeId']
                                        : null
                                }
                                onFieldChange={this.props.onFieldChange}
                            />
                        </Box>
                    )} */}
                    {!isPointman && (
                        <Box mx="2" alignItems="left">
                            <DynamicSelect
                                arrayOfData={this.getDropdownValues(
                                    'SalesOrgTypeId',
                                    dropDownDatas.OlympusDistributionChannelType,
                                    'SalesOrgTypeId',
                                    'DistributionChannelTypeId'
                                )}
                                label="Distribution Channel"
                                name="DistributionChannelTypeId"
                                value={this.props.formData.DistributionChannelTypeId}
                                isRequired
                                formErrors={
                                    this.props.formErrors
                                        ? this.props.formErrors[
                                              'DistributionChannelTypeId'
                                          ]
                                        : null
                                }
                                onFieldChange={this.props.onFieldChange}
                                disabled={
                                    !this.props.formData.SalesOrgTypeId ||
                                    this.getDropdownValues(
                                        'SalesOrgTypeId',
                                        dropDownDatas.OlympusDistributionChannelType,
                                        'SalesOrgTypeId',
                                        'DistributionChannelTypeId'
                                    ).length == 0
                                }
                                // readOnly={formData.filterList && formData.filterList['DistChannels']?false:true}
                            />
                        </Box>
                    )}
                    {!isPointman && (
                        <Box mx="2" alignItems="left">
                            <DynamicSelect
                                arrayOfData={this.getDropdownValues(
                                    'DistributionChannelTypeId',
                                    dropDownDatas.OlympusDivisionType,
                                    'DistributionChannelTypeId',
                                    'DivisionTypeId'
                                )}
                                label="Division"
                                name="DivisionTypeId"
                                value={this.props.formData.DivisionTypeId}
                                isRequired
                                formErrors={
                                    this.props.formErrors
                                        ? this.props.formErrors['DivisionTypeId']
                                        : null
                                }
                                onFieldChange={this.props.onFieldChange}
                                disabled={
                                    !this.props.formData.DistributionChannelTypeId ||
                                    this.getDropdownValues(
                                        'DistributionChannelTypeId',
                                        dropDownDatas.OlympusDivisionType,
                                        'DistributionChannelTypeId',
                                        'DivisionTypeId'
                                    ).length == 0
                                }
                                // readOnly={formData.filterList && formData.filterList['Divisions']?false:true}
                            />
                        </Box>
                    )}
                    <View
                        style={{
                            flex: 1,
                        }}>
                        <Flex
                            alignCenter
                            style={{
                                paddingBottom: 15,
                                flexDirection: 'row',
                                alignItems: 'center',
                                paddingLeft: 10,
                                paddingRight: 15,
                                marginBottom: 10,
                                marginHorizontal: 25,
                                marginTop: '35px',
                            }}>
                            <Button
                                // style={{ height: '98%', paddingTop: '5%' }}
                                onPress={this.props.onSubmit}
                                title="Search"
                            />
                        </Flex>
                    </View>
                </Box>
            </Fragment>
        );
    }
}

export default OlympusPartnersFilterFields;
