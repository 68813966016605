import { Box } from './common';
import React from 'react';

const Indicator = () => (
    <Box
        mr="8px"
        width="6px"
        height="6px"
        backgroundColor="#EA0029"
        borderRadius="25px"
    />
);

export default Indicator;
