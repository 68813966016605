import React from 'react';
import { ScrollView, View, StyleSheet, TouchableOpacity } from 'react-native';
import {
    DimensionAware,
    getWindowHeight,
    getWindowWidth,
} from 'react-native-dimension-aware';
import { Colors, StyledText } from '../../../theme/Index';
import { Flex, Button, Box, Text } from '../../../components/common';
import { FormInput } from '../../../components/form';
import { saveApolloMyTaskCredit, getStaticRequest } from '../../../appRedux/actions/';
import {
    getStatusBarData,
    getFunctionalGroupData,
} from '../../../appRedux/actions/Workflow';
import { yupAllFieldsValidation, parseAndSanitise } from '../../../constants/utils';

import GlobalMdmFields from '../../../components/GlobalMdmFields';
import { mytaskCreditRules, rejectRules } from '../../../constants/FieldRules';
import {
    RoleType,
    SalesOrgType,
    SystemType,
    DistributionChannelType,
    DivisionType,
    CompanyCodeType,
    CategoryTypesById,
} from '../../../constants/WorkflowEnums';
import MultiColorProgressBar from '../../../components/MultiColorProgressBar';
import DynamicSelect from '../../../components/DynamicSelect';
import {
    fetchCreditDropDownData,
    fetchCreateTeamRoleFieldMapping,
} from '../../../redux/DropDownDatas';
import Loading from '../../../components/Loading';
import FlashMessage, { FlashMessages } from '../../../components/FlashMessage';
import { connect } from 'react-redux';
import * as _ from 'lodash';
import BreadCrumbs from '../../../components/BreadCrumbs';
import Modal from 'modal-enhanced-react-native-web';
import FilesList from '../../../components/FilesList';
import { removeMessage } from '../../../appRedux/actions/Toast';
import { MaterialIcons } from '@expo/vector-icons';
import idx from 'idx';
import moment from 'moment-timezone';

const camelCaseToPascalCase = (str = '') => _.upperFirst(_.camelCase(str));

class Page extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            WorkflowId: this.props.location.state.WorkflowId,
            TaskId: this.props.location.state.TaskId,
            reject: false,
            dropDownDatas: {},
            teamRoleFieldMapping: {},
            formData: { CreditLimit: 1, RejectionButton: false },
            formErrors: {},
            selectedFiles: {},
            selectedFilesIds: [],
            files: [],
            fileinputkey: Date.now(),
        };
    }

    componentDidMount() {
        let { state: wf } = this.props.location;
        let postJson = {
            workflowId: wf.WorkflowId,
            fuctionalGroup: 'credit',
            taskId: wf.TaskId,
        };
        this.props.getStaticRequest([15, 30, 26, 17, 42, 43, 82]);
        this.props.getStatusBarData(postJson);
        this.props.getFunctionalGroupData(postJson);
        fetchCreditDropDownData().then((res) => {
            const data = res;
            this.setState({ dropDownDatas: data });
        });
        fetchCreateTeamRoleFieldMapping().then((resp) => {
            const data = resp;
            this.setState({ teamRoleFieldMapping: data });
        });
    }

    componentWillReceiveProps(newProps) {
        if (newProps.functionalGroupDetails != this.props.functionalGroupDetails) {
            let {
                functionalGroupDetails: { Customer: globalMdmDetail = {} },
            } = newProps;

            console.log(globalMdmDetail);
            // this.props.fetchDuns({
            //     UserId: localStorage.getItem('userId'),
            //     DunsNumber: customerdata.DunsNumber,
            // });


            let defaultValues = {};
            if (globalMdmDetail && globalMdmDetail.CategoryTypeId != undefined) {
                var source_category = parseInt(globalMdmDetail.CategoryTypeId);
                //direct , dropship , other
                if (
                    source_category === CategoryTypesById.Direct ||
                    source_category === CategoryTypesById['Drop Ship'] ||
                    source_category === CategoryTypesById['Other']
                ) {
                    defaultValues['PaymentTermsTypeId'] = 3;
                }
            }

            if (globalMdmDetail && globalMdmDetail.RoleTypeId != undefined) {
                var role = RoleType[globalMdmDetail && globalMdmDetail.RoleTypeId];
                if (role === 'Sales Rep') {
                    defaultValues['PaymentTermsTypeId'] = 3;
                    defaultValues['RiskCategoryTypeId'] = 4;
                    defaultValues['CreditRepGroupTypeId'] = 21;
                }
                if (role === 'Drop Ship') {
                    defaultValues['PaymentTermsTypeId'] = 3;
                }
            }

            this.setState({
                formData: {
                    ...this.state.formData,
                    ...defaultValues,
                },
            });
        }
    }

    setFormErrors = (errors) => {
        const { formErrors } = this.state;
        this.setState({ formErrors: errors }, () => window.scrollTo(0, 0));
    };

    isNumber = (n) => {
        return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
    };

    validateNumericKeypad = (value, e) => {
        const { name } = e.target;
        if (value.length !== 0 && !this.isNumber(value)) {
            this.setState({
                formErrors: {
                    [name]: 'Must be digits',
                },
            });
        } else {
            this.onFieldChange(value, e);
        }
    };

    onFieldChange = (value, e) => {
        const { name } = e.target;
        this.setState(
            {
                formData: {
                    ...this.state.formData,
                    [name]: value,
                },
                formErrors: {},
            },
            () => {
                //remove from formData if TypeId is set to 0 OR ''
                if (name.includes('TypeId') && parseInt(value) === 0) {
                    delete this.state.formData[name];
                }
            }
        );
    };

    handleDefaultValues = () => {
        let { formData } = this.state;
        let {
            functionalGroupDetails: { Customer: globalMdmDetail = {} },
        } = this.props;
        let defaultValues = {};
        if (
            formData.CreditLimit === undefined ||
            formData.CreditLimit.toString().trim().length === 0
        ) {
            defaultValues['CreditLimit'] = 1;
        }

        if (globalMdmDetail && globalMdmDetail.CategoryTypeId != undefined) {
            var source_category = parseInt(globalMdmDetail.CategoryTypeId);
            //direct , dropship , other
            if (
                (source_category === CategoryTypesById.Direct ||
                    source_category === CategoryTypesById['Drop Ship'] ||
                    source_category === CategoryTypesById['Other']) &&
                (formData.PaymentTermsTypeId === undefined ||
                    formData.PaymentTermsTypeId === '' ||
                    formData.PaymentTermsTypeId === 0)
            ) {
                defaultValues['PaymentTermsTypeId'] = 3;
            }
        }
        return defaultValues;
    };

    handleFormSubmission = (schema) => {
        let {
            TaskId,
            WorkflowId,
            formData,
            selectedFiles,
            selectedFilesIds,
            teamRoleFieldMapping,
        } = this.state,
            castedFormData = {},
            postData = {};
        const { TasksStatusByTeamId } = this.props;
        const {
            functionalGroupDetails: { Customer: globalMdmDetail = {} },
        } = this.props;
        try {
            const WorkflowTaskModel = {
                RejectReason: formData['RejectionButton']
                    ? formData['RejectionReason']
                    : '',
                TaskId: TaskId,
                UserId: localStorage.getItem('userId'),
                WorkflowId: WorkflowId,
                WorkflowTaskOperationType: !formData['RejectionButton'] ? 1 : 2,
            };
            if (!formData['RejectionButton']) {
                castedFormData = schema.cast(formData);
            } else {
                castedFormData = formData;
            }
            const CreditTeamFields =
                idx(teamRoleFieldMapping, (_) => _.CreditTeamFields) || [];
            const RoleTypeId = globalMdmDetail.RoleTypeId;
            const SystemTypeId = globalMdmDetail.SystemTypeId;
            const allowedFieldList = CreditTeamFields.filter(
                (item) =>
                    item.RoleTypeId === RoleTypeId && item.SystemTypeId === SystemTypeId
            );
            let allowedFields = undefined;
            if (allowedFieldList && allowedFieldList.length > 0) {
                allowedFields = allowedFieldList[0].Fields;
            }
            delete castedFormData.RejectionButton;
            castedFormData['PaymentTermsTypeId'] =
                allowedFields && allowedFields.includes('PaymentTermsTypeId')
                    ? castedFormData['PaymentTermsTypeId']
                    : undefined;
            castedFormData['RiskCategoryTypeId'] =
                allowedFields && allowedFields.includes('RiskCategoryTypeId')
                    ? castedFormData['RiskCategoryTypeId']
                    : undefined;
            castedFormData['CreditRepGroupTypeId'] =
                allowedFields && allowedFields.includes('CreditRepGroupTypeId')
                    ? castedFormData['CreditRepGroupTypeId']
                    : undefined;
            castedFormData['CreditLimit'] =
                allowedFields && allowedFields.includes('CreditLimit')
                    ? castedFormData['CreditLimit']
                    : undefined;
            castedFormData['CredInfoNumber'] =
                allowedFields && allowedFields.includes('CredInfoNumber')
                    ? castedFormData['CredInfoNumber']
                    : undefined;
            castedFormData['PaymentIndex'] =
                allowedFields && allowedFields.includes('PaymentIndex')
                    ? castedFormData['PaymentIndex']
                    : undefined;
            castedFormData['LastExtReview'] =
                allowedFields && allowedFields.includes('LastExtReview')
                    ? castedFormData['LastExtReview']
                    : undefined;
            castedFormData['Rating'] =
                allowedFields && allowedFields.includes('Rating')
                    ? castedFormData['Rating']
                    : undefined;
            console.log(castedFormData);

            postData['formdata'] = {
                WorkflowTaskModel,
                ...castedFormData,
            };
            postData['files'] = selectedFilesIds.map((id) => selectedFiles[id]);
            // console.log('postdat',postData)
            this.props.saveApolloMyTaskCredit(postData);
            this.resetForm();
            this.scrollToTop();
        } catch (error) {
            console.log('form validtion error', error);
        }
    };

    onSubmit = (event, reject, schema) => {
        let { formData, selectedFilesIds, selectedFiles } = this.state;
        let defaults = this.handleDefaultValues();
        let fileErrors = {};
        let errors = false;
        selectedFilesIds.map((id) => {
            if (selectedFiles[id] && selectedFiles[id].DocumentType <= 0) {
                fileErrors[id] = 'Document Type Required for file';
                errors = true;
            }
        });
        this.setState({ fileErrors, isFileErrors: errors });

        this.setState(
            {
                modalVisible: false,
                formData: {
                    ...this.state.formData,
                    ...defaults,
                    RejectionButton: reject,
                },
            },
            () => {
                yupAllFieldsValidation(
                    this.state.formData,
                    schema,
                    (...rest) => {
                        if (this.state.isFileErrors === false)
                            this.handleFormSubmission(...rest);
                    },
                    this.setFormErrors
                );
            }
        );
    };

    selectFiles = (events) => {
        event.preventDefault();

        const { selectedFilesIds, selectedFiles } = this.state;
        const id = events.target.files[0].name;

        this.setState({
            selectedFiles: {
                ...selectedFiles,
                [id]: {
                    data: events.target.files[0],
                    DocumentName: events.target.files[0].name,
                    DocumentType: 0,
                },
            },
            selectedFilesIds: [...selectedFilesIds, id],
            filename: events.target.files[0].name,
            fileinputkey: Date.now(),
        });
    };

    removeFile = (id) => {
        event.preventDefault();

        var selectedFilesDict = this.state.selectedFiles;
        var selectedFilesIdsList = this.state.selectedFilesIds;
        // remove the existing state
        delete selectedFilesDict[id];
        selectedFilesIdsList = selectedFilesIdsList.filter((fileid) => fileid !== id);

        this.setState({
            selectedFiles: selectedFilesDict,
            selectedFilesIds: selectedFilesIdsList,
        });
    };

    scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    resetFormData = () => {
        //restore initial values
        this.setState(
            {
                formData: { CreditLimit: 1, RejectionButton: false },
            },
            () => {
                Object.keys(this.state.formData).map((key) => {
                    this.setState({
                        formData: {
                            [key]: '',
                        },
                    });
                });
            }
        );
    };

    resetForm = () => {
        let { formErrors } = this.state;

        Object.keys(this.state.formErrors).forEach((key) => (formErrors[key] = ''));

        this.setState(
            {
                formErrors,
            },
            this.resetFormData
        );
    };

    _renderModalContent = () => (
        <Box
            my={4}
            mx="30%"
            backgroundColor="white"
            borderRadius="4px"
            borderColor="rgba(0, 0, 0, 0.1)">
            <Text my={3} fontWeight="regular" color="lightBlue" fontSize={24} pl={4}>
                Submit Workflow
            </Text>
            <Box px="0px" pb="22px" justifyContent="center">
                <Box alignItems="center" justifyContent="center">
                    {!this.state.isReject ? (
                        <FormInput
                            label="Additional Notes"
                            multiline
                            numberOfLines={2}
                            maxLength={250}
                            name="AdditionalNotes"
                            type="text"
                            onChange={this.onFieldChange}
                            error={
                                this.state.formErrors
                                    ? this.state.formErrors['AdditionalNotes']
                                    : null
                            }
                            value={
                                this.state.formData &&
                                this.state.formData['AdditionalNotes']
                            }
                            variant={'solid'}
                        />
                    ) : (
                        <FormInput
                            label="Rejection Reason"
                            name="RejectionReason"
                            maxLength={250}
                            onChange={this.onFieldChange}
                            error={'*this field is required'}
                            multiline
                            numberOfLines={2}
                            type="text"
                            value={
                                this.state.formData &&
                                this.state.formData['RejectionReason']
                            }
                            variant="solid"
                        />
                    )}
                </Box>

                <Flex
                    justifyEnd
                    alignCenter
                    alignSelf="flex-end"
                    style={{
                        paddingTop: 15,
                        flexDirection: 'row',
                        alignItems: 'center',
                        paddingLeft: 10,
                        paddingRight: 15,
                        marginTop: 20,
                        marginBottom: 10,
                        marginHorizontal: 25,
                    }}>
                    <Button
                        onPress={(event) => {
                            if (this.state.isReject)
                                this.setState(
                                    { isReject: false, modalVisible: null },
                                    () => this.onSubmit(event, true, rejectRules)
                                );
                            else this.onSubmit(event, false, mytaskCreditRules);
                        }}
                        title="Submit"
                    />
                    <Button
                        title="Cancel"
                        onPress={() => this.setState({ modalVisible: null })}
                    />
                </Flex>
            </Box>
        </Box>
    );

    render() {
        const {
            width,
            location,
            functionalGroupDetails: {
                DocumentLocation: files,
                Customer: globalMdmDetail = {},
                Credit: creditDetail = null,
                TaskNote: Notes = '',
                RequestorName,
            },
            statusBarData,
            alert = {},
            TasksStatusByTeamId = null,
        } = this.props;

        const {
            dropDownDatas,
            teamRoleFieldMapping,
            selectedFilesIds,
            selectedFiles,
        } = this.state;

        const { state } = location;

        const workflow = {
            ...state,
            isReadOnly:
                TasksStatusByTeamId === null ||
                !(
                    globalMdmDetail.WorkflowStateTypeId === 2 &&
                    TasksStatusByTeamId[4].WorkflowTaskStateTypeId === 2
                ),
        };

        const requestDateUtc = moment(globalMdmDetail.DateOfCreation);
        const localDate = moment(requestDateUtc).local();
        const requestDate = localDate.format('YYYY-MM-DD hh:mm:ss A');

        const CreditTeamFields =
            idx(teamRoleFieldMapping, (_) => _.CreditTeamFields) || [];
        const RoleTypeId = globalMdmDetail.RoleTypeId;
        const SystemTypeId = globalMdmDetail.SystemTypeId;
        const allowedFieldList = CreditTeamFields.filter(
            (item) => item.RoleTypeId === RoleTypeId && item.SystemTypeId === SystemTypeId
        );
        let allowedFields = undefined;
        if (allowedFieldList && allowedFieldList.length > 0) {
            allowedFields = allowedFieldList[0].Fields;
        }
        const inputReadonlyProps = workflow.isReadOnly ? { disabled: true } : null;
        let showCreditDetail =
            state.isReadOnly && creditDetail === null ? { display: 'none' } : null;

        const showButtons = workflow.isReadOnly ? { display: 'none' } : null;
        let disp_payterms = false;
        if (globalMdmDetail && globalMdmDetail.CategoryTypeId != undefined) {
            var source_category = parseInt(globalMdmDetail.CategoryTypeId);
            //direct , dropship , other
            if (
                source_category === CategoryTypesById.Direct ||
                source_category === CategoryTypesById['Drop Ship'] ||
                source_category === CategoryTypesById['Other']
            ) {
                disp_payterms = true;
            } else {
                // hide cred details except for soldto , payer , sales rep , dropship when disp_payterms is false
                if (
                    parseInt(globalMdmDetail.RoleTypeId, 10) != 1 &&
                    parseInt(globalMdmDetail.RoleTypeId, 10) != 2 &&
                    parseInt(globalMdmDetail.RoleTypeId, 10) != 3 &&
                    parseInt(globalMdmDetail.RoleTypeId, 10) != 5 &&
                    parseInt(globalMdmDetail.RoleTypeId, 10) != 6
                )
                    showCreditDetail = { display: 'none' };
            }
        }
        var isReleased = true;
        for (let i = 0; i < statusBarData.length; i++) {
            let teamstatus = statusBarData[i];
            if (teamstatus.WorkflowTaskStateTypeId === 2) {
                isReleased = false;
                break;
            } else {
                isReleased = true;
            }
        }
        var bgcolor = alert.color || '#FFF';
        if (this.props.fetching) {
            return <Loading />;
        }

        console.log(this.props, this.state);

        return (
            <ScrollView
                keyboardShouldPersistTaps="always"
                style={{
                    backgroundColor: '#EFF3F6',
                    paddingBottom: 75,
                }}>
                <Modal isVisible={this.state.modalVisible}>
                    {this._renderModalContent()}
                </Modal>
                <FlashMessages
                    toasts={this.props.toasts}
                    onDismiss={this.props.removeMessage}
                />
                {alert.display && (
                    <FlashMessage
                        bg={{ backgroundColor: bgcolor }}
                        message={alert.message}
                    />
                )}

                <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between">
                    <Text
                        style={{
                            fontWeight: '500',
                            fontSize: 32,
                            color: Colors.lightBlue,
                            marginTop: 24,
                            marginBottom: 20,
                            paddingLeft: 45,
                        }}>
                        MY TASKS - {state.Header}
                    </Text>
                    <Box
                        marginTop="25px"
                        flex={3 / 4}
                        flexDirection="row-reverse"
                        alignItems="flex-end">
                        <MultiColorProgressBar readings={statusBarData} />
                    </Box>
                </Box>
                <Box marginTop="25px" flexDirection="row-reverse" alignItems="flex-end">
                    <StyledText
                        style={{
                            fontWeight: '600',
                            paddingRight: '20px',
                            fontSize: '16px',
                            color: '#10206C',
                        }}>
                        {' '}
                        Requestor : {RequestorName}{' '}
                    </StyledText>
                </Box>
                <Box flexDirection="row-reverse" alignItems="flex-end">
                    <StyledText
                        style={{
                            fontWeight: '600',
                            paddingRight: '20px',
                            fontSize: '16px',
                            color: '#10206C',
                        }}>
                        {' '}
                        Date of Request: {requestDate}{' '}
                    </StyledText>
                </Box>
                <View
                    style={{
                        flex: 1,
                        paddingHorizontal: width < 1440 ? 60 : width * 0.1,
                        paddingBottom: 10,
                    }}>
                    <Box fullHeight my={2}>
                        <Box flexDirection="row" justifyContent="space-around" my={4}>
                            <FormInput
                                px="25px"
                                flex={1 / 4}
                                label="WorkflowTitle"
                                maxLength={40}
                                name="WorkflowTitle"
                                variant="outline"
                                type="text"
                                value={globalMdmDetail && globalMdmDetail.WorkflowTitle}
                            />
                            <FormInput
                                px="25px"
                                flex={1 / 4}
                                label="Workflow Number"
                                name="workflow-number"
                                variant="outline"
                                type="text"
                                value={globalMdmDetail && globalMdmDetail.WorkflowId}
                            />
                            {globalMdmDetail.SystemRecordId && (
                                <FormInput
                                    px="25px"
                                    flex={1 / 4}
                                    label="Customer Number"
                                    name="CustomerNumber"
                                    variant="outline"
                                    type="text"
                                    value={globalMdmDetail.SystemRecordId}
                                />
                            )}
                            {globalMdmDetail &&
                                globalMdmDetail.MdmCustomerId &&
                                (isReleased ? (
                                    <TouchableOpacity
                                        onPress={() =>
                                            this.props.history.push(
                                                `/search-results/${globalMdmDetail.MdmCustomerId}`
                                            )
                                        }>
                                        <FormInput
                                            px="25px"
                                            flex={1 / 4}
                                            label="MDM Number"
                                            name="mdm-number"
                                            variant="outline"
                                            type="text"
                                            value={
                                                globalMdmDetail &&
                                                globalMdmDetail.MdmCustomerId
                                            }
                                        />
                                    </TouchableOpacity>
                                ) : (
                                    <FormInput
                                        px="25px"
                                        flex={1 / 4}
                                        label="MDM Number"
                                        name="mdm-number"
                                        variant="outline"
                                        type="text"
                                        value={
                                            globalMdmDetail &&
                                            globalMdmDetail.MdmCustomerId
                                        }
                                    />
                                ))}
                        </Box>
                        <GlobalMdmFields
                            staticDropDownData={this.props.staticData}
                            editableInputs={{ TaxNumber: true, VatRegNo: true }}

                            formData={{
                                ...globalMdmDetail,
                                DunsNumber:
                                    this.state.formData?.DunsNumber ||
                                    creditDetail?.DunsNumber,
                                NaicsCode: this.state.formData?.NaicsCode ||
                                    creditDetail?.NaicsCode,
                                NaicsCodeDescription: this.state.formData?.NaicsCodeDescription ||
                                    creditDetail?.NaicsCodeDescription,
                                SicCode: this.state.formData?.SicCode ||
                                    creditDetail?.SicCode,
                                SicCode4: this.state.formData?.SicCode4 ||
                                    creditDetail?.SicCode4,
                                SicCode6: this.state.formData?.SicCode6 ||
                                    creditDetail?.SicCode6,
                                SicCode8: this.state.formData?.SicCode8 ||
                                    creditDetail?.SicCode8,
                                SicCodeDescription: this.state.formData?.SicCodeDescription ||
                                    creditDetail?.SicCodeDescription,
                                TaxNumber: this.state.formData?.TaxNumber ||
                                    creditDetail?.TaxNumber,
                                VatRegNo: this.state.formData?.VatRegNo ||
                                    creditDetail?.VatRegNo,

                            }}
                            dunsEditable={true}
                            taxEditable={true}
                            onFieldChange={this.onFieldChange}

                            readOnly
                        />

                        <Text
                            mt="42px"
                            mb="20px"
                            fontWeight="regular"
                            color="lightBlue"
                            fontSize={24}
                            pl={4}>
                            SYSTEM FIELDS
                        </Text>
                        <Box flexDirection="row" justifyContent="center">
                            <Box width={1 / 2} mx="auto" alignItems="center">
                                <FormInput
                                    label="System"
                                    name="System"
                                    inline
                                    variant="outline"
                                    type="text"
                                    value={
                                        SystemType[
                                        globalMdmDetail &&
                                        globalMdmDetail.SystemTypeId
                                        ]
                                    }
                                />
                                <FormInput
                                    label="Role"
                                    name="Role"
                                    inline
                                    variant="outline"
                                    type="text"
                                    value={
                                        RoleType[
                                        globalMdmDetail && globalMdmDetail.RoleTypeId
                                        ]
                                    }
                                />
                                <FormInput
                                    label="Sales Org"
                                    name="SalesOrg"
                                    inline
                                    variant="outline"
                                    type="text"
                                    value={
                                        SalesOrgType[
                                        globalMdmDetail &&
                                        globalMdmDetail.SalesOrgTypeId
                                        ]
                                    }
                                />
                                <FormInput
                                    label="Purpose of Request"
                                    name="Purpose"
                                    maxLength={1000}
                                    value={globalMdmDetail && globalMdmDetail.Purpose}
                                    multiline
                                    numberOfLines={
                                        globalMdmDetail.Purpose &&
                                            globalMdmDetail.Purpose.length > 36
                                            ? 3
                                            : 1
                                    }
                                    style={{ lineHeight: '2.5' }}
                                    inline
                                    variant="outline"
                                    type="text"
                                />
                            </Box>
                            <Box width={1 / 2} mx="auto" alignItems="center">
                                <FormInput
                                    label="Distribution Channel"
                                    name="DistributionChannel"
                                    inline
                                    variant="outline"
                                    type="text"
                                    value={
                                        DistributionChannelType[
                                        globalMdmDetail &&
                                        globalMdmDetail.DistributionChannelTypeId
                                        ]
                                    }
                                />
                                <FormInput
                                    label="Division"
                                    name="DivisionTypeId"
                                    inline
                                    variant="outline"
                                    type="text"
                                    value={
                                        DivisionType[
                                        globalMdmDetail &&
                                        globalMdmDetail.DivisionTypeId
                                        ]
                                    }
                                />
                                <FormInput
                                    label="CompanyCode"
                                    name="CompanyCodeTypeId"
                                    inline
                                    variant="outline"
                                    type="text"
                                    value={
                                        CompanyCodeType[
                                        globalMdmDetail &&
                                        globalMdmDetail.CompanyCodeTypeId
                                        ]
                                    }
                                />
                            </Box>
                        </Box>

                        <Box {...showCreditDetail}>
                            <Text
                                mt="42px"
                                mb="20px"
                                fontWeight="regular"
                                color="lightBlue"
                                fontSize={24}
                                pl={4}>
                                CREDIT FIELDS
                            </Text>
                            <Box flexDirection="row" justifyContent="center">
                                <Box width={1 / 2} mx="auto" alignItems="center">
                                    {disp_payterms && (
                                        <DynamicSelect
                                            arrayOfData={
                                                (this.props.staticData &&
                                                    this.props.staticData
                                                        .ApolloPaymentTermsType) ||
                                                []
                                            }
                                            label="Payment Terms"
                                            name="PaymentTermsTypeId"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes(
                                                    'PaymentTermsTypeId'
                                                )
                                            }
                                            value={
                                                workflow.isReadOnly
                                                    ? creditDetail &&
                                                    parseAndSanitise(
                                                        creditDetail.PaymentTermsTypeId
                                                    )
                                                    : this.state.formData
                                                        ? this.state.formData[
                                                        'PaymentTermsTypeId'
                                                        ]
                                                        : ''
                                            }
                                            formErrors={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                    'PaymentTermsTypeId'
                                                    ]
                                                    : null
                                            }
                                            inline={workflow.isReadOnly ? true : false}
                                            variant={
                                                workflow.isReadOnly ? 'outline' : 'solid'
                                            }
                                            onFieldChange={this.onFieldChange}
                                            inputProps={inputReadonlyProps}
                                        />
                                    )}
                                    <DynamicSelect
                                        arrayOfData={dropDownDatas.RiskCategoryTypeId}
                                        label="Risk Category"
                                        name="RiskCategoryTypeId"
                                        hide={
                                            allowedFields &&
                                            !allowedFields.includes('RiskCategoryTypeId')
                                        }
                                        value={
                                            workflow.isReadOnly
                                                ? creditDetail &&
                                                parseAndSanitise(
                                                    creditDetail.RiskCategoryTypeId
                                                )
                                                : this.state.formData
                                                    ? this.state.formData[
                                                    'RiskCategoryTypeId'
                                                    ]
                                                    : null
                                        }
                                        formErrors={
                                            this.state.formErrors
                                                ? this.state.formErrors[
                                                'RiskCategoryTypeId'
                                                ]
                                                : creditDetail.RiskCategoryTypeId
                                        }
                                        inline={workflow.isReadOnly ? true : false}
                                        variant={
                                            workflow.isReadOnly ? 'outline' : 'solid'
                                        }
                                        onFieldChange={this.onFieldChange}
                                        inputProps={inputReadonlyProps}
                                    />

                                    <DynamicSelect
                                        arrayOfData={
                                            this.props.staticData &&
                                            this.props.staticData.ApolloCreditRepGroupType
                                        }
                                        label="Credit Rep Group"
                                        name="CreditRepGroupTypeId"
                                        hide={
                                            allowedFields &&
                                            !allowedFields.includes(
                                                'CreditRepGroupTypeId'
                                            )
                                        }
                                        value={
                                            workflow.isReadOnly
                                                ? creditDetail &&
                                                parseAndSanitise(
                                                    creditDetail.CreditRepGroupTypeId
                                                )
                                                : this.state.formData
                                                    ? this.state.formData[
                                                    'CreditRepGroupTypeId'
                                                    ]
                                                    : null
                                        }
                                        inline={workflow.isReadOnly ? true : false}
                                        variant={
                                            workflow.isReadOnly ? 'outline' : 'solid'
                                        }
                                        formErrors={
                                            this.state.formErrors
                                                ? this.state.formErrors[
                                                'CreditRepGroupTypeId'
                                                ]
                                                : null
                                        }
                                        onFieldChange={this.onFieldChange}
                                        inputProps={inputReadonlyProps}
                                    />
                                </Box>
                                <Box width={1 / 2} mx="auto" alignItems="center">
                                    <FormInput
                                        label="Credit Limit"
                                        name="CreditLimit"
                                        hide={
                                            allowedFields &&
                                            !allowedFields.includes('CreditLimit')
                                        }
                                        maxLength={15}
                                        value={
                                            workflow.isReadOnly
                                                ? creditDetail &&
                                                parseAndSanitise(
                                                    creditDetail.CreditLimit
                                                )
                                                : this.state.formData
                                                    ? parseAndSanitise(
                                                        this.state.formData['CreditLimit']
                                                    )
                                                    : null
                                        }
                                        error={
                                            this.state.formErrors
                                                ? this.state.formErrors['CreditLimit']
                                                : null
                                        }
                                        onChange={this.validateNumericKeypad}
                                        variant={
                                            workflow.isReadOnly ? 'outline' : 'solid'
                                        }
                                        inline={workflow.isReadOnly ? true : false}
                                        type="number"
                                        placeholder={'Only numeric values'}
                                    />
                                    <FormInput
                                        label="Cred Info Number"
                                        name="CredInfoNumber"
                                        hide={
                                            allowedFields &&
                                            !allowedFields.includes('CredInfoNumber')
                                        }
                                        maxLength={11}
                                        value={
                                            workflow.isReadOnly
                                                ? creditDetail &&
                                                creditDetail.CredInfoNumber
                                                : this.state.formData
                                                    ? this.state.formData['CredInfoNumber']
                                                    : 0
                                        }
                                        error={
                                            this.state.formErrors
                                                ? this.state.formErrors['CredInfoNumber']
                                                : null
                                        }
                                        onChange={this.validateNumericKeypad}
                                        variant={
                                            workflow.isReadOnly ? 'outline' : 'solid'
                                        }
                                        inline={workflow.isReadOnly ? true : false}
                                        type="test"
                                    />
                                    <FormInput
                                        label="Payment Index"
                                        name="PaymentIndex"
                                        maxLength={3}
                                        hide={
                                            allowedFields &&
                                            !allowedFields.includes('PaymentIndex')
                                        }
                                        value={
                                            workflow.isReadOnly
                                                ? creditDetail &&
                                                creditDetail.PaymentIndex
                                                : this.state.formData
                                                    ? this.state.formData['PaymentIndex']
                                                    : null
                                        }
                                        error={
                                            this.state.formErrors
                                                ? this.state.formErrors['PaymentIndex']
                                                : null
                                        }
                                        onChange={this.validateNumericKeypad}
                                        inline={workflow.isReadOnly ? true : false}
                                        variant={
                                            workflow.isReadOnly ? 'outline' : 'solid'
                                        }
                                        type="text"
                                    />
                                    <FormInput
                                        label="Last Ext Review"
                                        name="LastExtReview"
                                        hide={
                                            allowedFields &&
                                            !allowedFields.includes('LastExtReview')
                                        }
                                        variant={
                                            workflow.isReadOnly ? 'outline' : 'solid'
                                        }
                                        error={
                                            this.state.formErrors
                                                ? this.state.formErrors['EffectiveDate']
                                                : null
                                        }
                                        value={
                                            workflow.isReadOnly
                                                ? creditDetail &&
                                                creditDetail.LastExtReview &&
                                                creditDetail.LastExtReview.split('T')[0]
                                                : this.state.formData
                                                    ? this.state.formData['LastExtReview']
                                                    : null
                                        }
                                        type="date"
                                        inline={workflow.isReadOnly ? true : false}
                                        onChange={(value, element) => {
                                            this.onFieldChange(
                                                new Date(value).toJSON().slice(0, 19),
                                                element
                                            );
                                        }}
                                    />
                                    <FormInput
                                        label="Rating"
                                        name="Rating"
                                        hide={
                                            allowedFields &&
                                            !allowedFields.includes('Rating')
                                        }
                                        type="text"
                                        maxLength={5}
                                        value={
                                            workflow.isReadOnly
                                                ? creditDetail && creditDetail.Rating
                                                : this.state.formData
                                                    ? this.state.formData['Rating']
                                                    : null
                                        }
                                        error={
                                            this.state.formErrors
                                                ? this.state.formErrors['Rating']
                                                : null
                                        }
                                        onChange={this.onFieldChange}
                                        variant={
                                            workflow.isReadOnly ? 'outline' : 'solid'
                                        }
                                        inline={workflow.isReadOnly ? true : false}
                                    />
                                </Box>
                            </Box>
                        </Box>
                        {files && <FilesList files={files} readOnly />}
                        {workflow.isReadOnly ? (
                            <Box mt={4} flexDirection="row" justifyContent="center">
                                <Box width={1 / 2} mx="auto" alignItems="center">
                                    <FormInput
                                        label="Notes"
                                        multiline
                                        inputWidth="100%"
                                        numberOfLines={2}
                                        name="AdditionalNotes"
                                        variant={
                                            workflow.isReadOnly ? 'outline' : 'solid'
                                        }
                                        inline={workflow.isReadOnly ? true : false}
                                        type="text"
                                        value={
                                            workflow.isReadOnly
                                                ? Notes
                                                : this.state.formData
                                                    ? this.state.formData['AdditionalNotes']
                                                    : null
                                        }
                                    />
                                </Box>
                                <Box width={1 / 2} mx="auto" alignItems="center"></Box>
                            </Box>
                        ) : (
                            <FilesList
                                title="NEW ATTACHMENTS"
                                formErrors={this.state.fileErrors}
                                files={selectedFilesIds.map((id) => selectedFiles[id])}
                                onChange={(value, id) => {
                                    this.setState({
                                        selectedFiles: {
                                            ...selectedFiles,
                                            [id]: {
                                                ...selectedFiles[id],
                                                DocumentType: parseInt(value),
                                            },
                                        },
                                    });
                                }}
                                onRemove={(value, id) => this.removeFile(id)}
                            />
                        )}
                    </Box>
                    <Box {...showButtons}>
                        <Flex
                            justifyEnd
                            alignCenter
                            style={{
                                paddingTop: 15,
                                flexDirection: 'row',
                                alignItems: 'center',
                                paddingLeft: 10,
                                paddingRight: 15,
                                marginTop: 20,
                                marginBottom: 10,
                                marginHorizontal: 25,
                            }}>
                            <label htmlFor="file-upload" className="custom-file-upload">
                                <MaterialIcons
                                    name="attach-file"
                                    size={18}
                                    color="#fff"
                                />
                            </label>
                            <input
                                id="file-upload"
                                type="file"
                                key={this.state.fileinputkey}
                                onChange={this.selectFiles}
                                multiple
                            />

                            <Button
                                onPress={(event) =>
                                    this.setState({ isReject: false, modalVisible: true })
                                }
                                title="Approve"
                            />
                            <Button
                                title="Reject"
                                onPress={(event) =>
                                    this.setState({ isReject: true, modalVisible: true })
                                }
                            />
                        </Flex>
                    </Box>
                </View>
            </ScrollView>
        );
    }
}

class Default extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const props = this.props;

        return (
            <DimensionAware
                render={(dimensions) => (
                    <Page
                        {...{
                            ...props,
                            width: getWindowWidth(dimensions),
                            height: getWindowHeight(dimensions),
                            marginBottom: 25,
                        }}
                    />
                )}
            />
        );
    }
}

const mapStateToProps = ({ workflows, myTasks, toasts, staticData }) => {
    const { fetching, alert, readOnly } = myTasks;
    const {
        fetchingfnGroupData,
        statusBarData,
        functionalGroupDetails,
        TasksStatusByTeamId,
        fetchingStatusBar,
    } = workflows;

    const { data, fetching: staticFetching } = staticData;
    return {
        fetching: fetching || fetchingStatusBar || fetchingfnGroupData || staticFetching,
        alert,
        statusBarData,
        functionalGroupDetails,
        readOnly,
        TasksStatusByTeamId,
        toasts,
        staticData: data || {},
    };
};

export default connect(mapStateToProps, {
    saveApolloMyTaskCredit,
    getFunctionalGroupData,
    getStatusBarData,
    removeMessage,
    getStaticRequest,
})(Default);

const styles = StyleSheet.create({
    progressIndicator: {
        flex: 1,
        paddingBottom: 5,
        flexDirection: 'row-reverse',
        alignItems: 'flex-end',
    },
    statusText: {
        fontSize: 15,
        color: '#1D4289',
        fontFamily: 'Poppins',
        textAlign: 'center',
        marginTop: 20,
    },
});
