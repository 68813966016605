import {
    GET_MDM_MAPPING_MATRIX,
    SAVE_APOLLO_UPDATE_CONTRACTS,
    SAVE_APOLLO_UPDATE_CREDIT,
    SAVE_APOLLO_UPDATE_PRICING,
    SAVE_APOLLO_UPDATE_GLOBALTRADE,
    SAVE_APOLLO_UPDATE_GLOBAL,
    SAVE_APOLLO_UPDATE_CUSTOMER_MASTER,
    SET_MDM_MAPPING_MATRIX,
    UPDATE_DELTAS,
    UPDATE_DELTAS_STATUS,
    UPDATE_EXTEND_SALES_ORG,
    READ_REDSHIFT_REQUEST,
    READ_REDSHIFT_SUCCESS,
    REQUEST_BLOCK,
    FETCH_MDM_PARENT_DATA_DUNS,
    FETCH_MDM_PARENT_DATA_DUNS_RESULT,
} from '../../constants/ActionTypes';

export const getMdmMappingMatrix = (id) => {
    return {
        type: GET_MDM_MAPPING_MATRIX,
        payload: id,
    };
};

export const setMdmMappingMatrix = (data) => {
    return {
        type: SET_MDM_MAPPING_MATRIX,
        payload: data,
    };
};

export const getCustomerGlobalData = (data) => {
    return {
        type: READ_REDSHIFT_REQUEST,
        payload: data,
    };
};

export const setCustomerGlobalData = ({ data, deltas, WorkflowDocuments }) => {
    return {
        type: READ_REDSHIFT_SUCCESS,
        payload: { data, deltas, WorkflowDocuments },
    };
};

export const updateDeltas = (data) => {
    return {
        type: UPDATE_DELTAS,
        payload: data,
    };
};

export const updateDeltasStatus = (data) => {
    return {
        type: UPDATE_DELTAS_STATUS,
        payload: data,
    };
};

export const extendSalesOrgUpdate = (data) => {
    return {
        type: UPDATE_EXTEND_SALES_ORG,
        payload: data,
    };
};
export const saveApolloUpdateUpdateMyTaskContracts = (data) => {
    return {
        type: SAVE_APOLLO_UPDATE_CONTRACTS,
        payload: data,
    };
};
export const saveApolloUpdateMyTaskCredit = (data) => {
    return {
        type: SAVE_APOLLO_UPDATE_CREDIT,
        payload: data,
    };
};
export const saveApolloUpdateMyTaskPricing = (data) => {
    return {
        type: SAVE_APOLLO_UPDATE_PRICING,
        payload: data,
    };
};
export const saveApolloUpdateMyTaskGlobalTrade = (data) => {
    return {
        type: SAVE_APOLLO_UPDATE_GLOBALTRADE,
        payload: data,
    };
};
export const saveApolloUpdateCustomerGlobal = (data) => {
    return {
        type: SAVE_APOLLO_UPDATE_GLOBAL,
        payload: data,
    };
};
export const saveApolloUpdateCustomerMaster = (data) => {
    return {
        type: SAVE_APOLLO_UPDATE_CUSTOMER_MASTER,
        payload: data,
    };
};

export const requestBlock = (data) => {
    return {
        type: REQUEST_BLOCK,
        payload: data,
    };
};

export const fetchMdmParentDataDuns = (data) => {
    return {
        type: FETCH_MDM_PARENT_DATA_DUNS,
        payload: data,
    };
};

export const fetchMdmParentDataDunsResult = (data) => {
    return {
        type: FETCH_MDM_PARENT_DATA_DUNS_RESULT,
        payload: data,
    };
};
