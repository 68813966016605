import React from 'react';
import {
    ScrollView,
    View,
    StyleSheet,
    TouchableOpacity,
    ActivityIndicator,
    Image,
} from 'react-native';
import {
    DimensionAware,
    getWindowHeight,
    getWindowWidth,
} from 'react-native-dimension-aware';
import { Colors, StyledText } from '../../../theme/Index';
import { Flex, Column, Card, Button, Box, Text } from '../../../components/common';
import { FormInput } from '../../../components/form';
import { saveApolloMyTaskGlobalTrade } from '../../../appRedux/actions/MyTasks';
import { connect } from 'react-redux';
import Loading from '../../../components/Loading';
import FlashMessage from '../../../components/FlashMessage';
import MultiColorProgressBar from '../../../components/MultiColorProgressBar';
import {
    getStatusBarData,
    getFunctionalGroupData,
    getStaticRequest,
} from '../../../appRedux/actions';
import {
    RoleType,
    SalesOrgType,
    SystemType,
    DistributionChannelType,
    DivisionType,
    CompanyCodeType,
} from '../../../constants/WorkflowEnums';
import GlobalMdmFields from '../../../components/GlobalMdmFields';
import Modal from 'modal-enhanced-react-native-web';
import FilesList from '../../../components/FilesList';
import { MaterialIcons } from '@expo/vector-icons';
import * as moment from 'moment';
import { fetchOlympusCreateTeamRoleFieldMapping } from '../../../redux/DropDownDatas';
import idx from 'idx';

class Page extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            WorkflowId: this.props.location.state.WorkflowId,
            TaskId: this.props.location.state.TaskId,
            formData: {},
            rejectionRequired: false,
            selectedFiles: {},
            selectedFilesIds: [],
            files: [],
            fileinputkey: Date.now(),
            teamRoleFieldMapping: {},
            isInternationalVersionVisible: false,
        };
    }
    componentDidMount() {
        let { state: wf } = this.props.location;
        this.props.getStaticRequest([
            42,
            43,
            40,
            52,
            53,
            54,
            55,
            56,
            57,
            58,
            59,
            60,
            61,
            62,
            63,
            64,
            65,
            81,
            84,
            71,
            76,
        ]);
        let postJson = {
            workflowId: wf.WorkflowId,
            fuctionalGroup: 'globaltrade',
            taskId: wf.TaskId,
        };
        this.props.getStatusBarData(postJson);
        this.props.getFunctionalGroupData(postJson);
        fetchOlympusCreateTeamRoleFieldMapping().then((resp) => {
            const data = resp;
            this.setState({ teamRoleFieldMapping: data });
        });
    }

    onFieldChange = (value, e) => {
        this.setState(
            {
                formData: {
                    ...this.state.formData,
                    [e.target.name]: e.target.value,
                },
            },
            this.updateSchema
        );
    };

    _renderModalContent = () => (
        <Box
            my={4}
            mx="30%"
            backgroundColor="white"
            borderRadius="4px"
            borderColor="rgba(0, 0, 0, 0.1)">
            <Text my={3} fontWeight="regular" color="lightBlue" fontSize={24} pl={4}>
                Submit Workflow
            </Text>
            <Box px="0px" pb="22px" justifyContent="center">
                <Box alignItems="center" justifyContent="center">
                    {!this.state.isReject ? (
                        <FormInput
                            label="Additional Notes"
                            multiline
                            numberOfLines={2}
                            name="AdditionalNotes"
                            maxLength={250}
                            type="text"
                            onChange={this.onFieldChange}
                            error={
                                this.state.formErrors
                                    ? this.state.formErrors['AdditionalNotes']
                                    : null
                            }
                            value={
                                this.state.formData &&
                                this.state.formData['AdditionalNotes']
                            }
                            variant={'solid'}
                        />
                    ) : (
                        <FormInput
                            label="Rejection Reason"
                            name="RejectionReason"
                            maxLength={250}
                            onChange={this.onFieldChange}
                            error={' *this field is required'}
                            multiline
                            numberOfLines={2}
                            type="text"
                            value={
                                this.state.formData &&
                                this.state.formData['RejectionReason']
                            }
                            variant="solid"
                        />
                    )}
                </Box>
                <Flex
                    justifyEnd
                    alignCenter
                    alignSelf="flex-end"
                    style={{
                        paddingTop: 15,
                        flexDirection: 'row',
                        alignItems: 'center',
                        paddingLeft: 10,
                        paddingRight: 15,
                        marginTop: 20,
                        marginBottom: 10,
                        marginHorizontal: 25,
                    }}>
                    <Button
                        onPress={(event) => {
                            if (this.state.isReject)
                                this.setState(
                                    {
                                        isReject: false,
                                    },
                                    () => this.onSubmit(true)
                                );
                            else this.onSubmit(false);
                        }}
                        title="Submit"
                    />
                    <Button
                        title="Cancel"
                        onPress={() => this.setState({ modalVisible: false })}
                    />
                </Flex>
            </Box>
        </Box>
    );

    submitForm = (reject = false) => {
        const userId = localStorage.getItem('userId');

        let {
            functionalGroupDetails: { Customer: globalMdmDetail = {} },
        } = this.props;

        try {
            const { location } = this.props;
            const { TaskId, WorkflowId, selectedFiles, selectedFilesIds } = this.state;
            const WorkflowTaskModel = {
                RejectReason: reject ? this.state.formData.RejectionReason : '',
                TaskId: TaskId,
                UserId: userId,
                WorkflowId: WorkflowId,
                WorkflowTaskOperationType: !reject ? 1 : 2,
                SystemTypeId:
                    globalMdmDetail.SystemTypeId != '' ? globalMdmDetail.SystemTypeId : 2,
            };
            let postData = {};
            postData['formdata'] = {
                WorkflowTaskModel,
                AdditionalNotes: this.state.formData.AdditionalNotes,
            };
            postData['files'] = selectedFilesIds.map((id) => selectedFiles[id]);
            //console.log('postdata', postData);
            this.props.saveApolloMyTaskGlobalTrade(postData);
            // this.resetForm();
            this.scrollToTop();
        } catch (error) {
            console.log('form error', error);
        }
    };

    resetForm = () => {
        Object.keys(this.state.formData).map((key) => {
            this.setState({
                formData: {
                    [key]: '',
                },
            });
        });
        Object.keys(this.state.formErrors).map((key) => {
            this.setState({
                formErrors: {
                    [key]: '',
                },
            });
        });
        //restore initial values
        this.setState({
            formData: { RejectionButton: false },
        });
    };

    onSubmit = (reject = false) => {
        let { formData, selectedFilesIds, selectedFiles } = this.state;
        let fileErrors = {};
        let errors = false;
        selectedFilesIds.map((id) => {
            if (selectedFiles[id] && selectedFiles[id].DocumentType <= 0) {
                fileErrors[id] = 'Document Type Required for file';
                errors = true;
            }
        });

        this.setState({ fileErrors, isFileErrors: errors });

        if (!this.state.formData.RejectReason && reject) {
            if (!this.props.rejectionRequired) {
                this.setState({ rejectionRequired: true, modalVisible: false });
                this.submitForm(reject);
            }
        } else {
            this.setState(
                { rejectionRequired: false, loading: true, modalVisible: false },
                () => {
                    if (this.state.isFileErrors === false) this.submitForm(reject);
                }
            );
            //;
        }
    };

    selectFiles = (events) => {
        event.preventDefault();

        const { selectedFilesIds, selectedFiles } = this.state;
        const id = events.target.files[0].name;

        this.setState({
            selectedFiles: {
                ...selectedFiles,
                [id]: {
                    data: events.target.files[0],
                    DocumentName: events.target.files[0].name,
                    DocumentType: 0,
                },
            },
            selectedFilesIds: [...selectedFilesIds, id],
            filename: events.target.files[0].name,
            fileinputkey: Date.now(),
        });
    };

    removeFile = (id) => {
        event.preventDefault();

        var selectedFilesDict = this.state.selectedFiles;
        var selectedFilesIdsList = this.state.selectedFilesIds;
        // remove the existing state
        delete selectedFilesDict[id];
        selectedFilesIdsList = selectedFilesIdsList.filter((fileid) => fileid !== id);

        this.setState({
            selectedFiles: selectedFilesDict,
            selectedFilesIds: selectedFilesIdsList,
        });
    };

    getStaticDropdownValues = (Id, dropdownList) => {
        let description = '';
        if (Id && dropdownList)
            dropdownList.some((data) => {
                if (data.Id === Id) {
                    description = data.Value + ' : ' + data.Description;
                    return true;
                }
            });
        // if (description.includes('/n') || description.includes(';')) {
        //     console.log('html string');
        // }
        return description;
    };

    scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    getDropdownValues = (Id, dropdownList) => {
        let description = '';
        if (Id && dropdownList)
            dropdownList.some((data) => {
                if (data.Id == Id) {
                    description = data.Value + ' : ' + data.Description;
                    return true;
                }
            });
        // if (description.includes('/n') || description.includes(';')) {
        //     console.log('html string');
        // }
        return description;
    };

    render() {
        const {
            width,
            location,
            functionalGroupDetails: {
                DocumentLocation: files,
                Customer: globalMdmDetail = {},
                GlobalTrade: functionalDetail = null,
                TaskNote: Notes = '',
                RequestorName,
            },
            statusBarData,
            alert = {},
            staticData,
        } = this.props;

        const { state } = location;

        const { selectedFilesIds, selectedFiles, teamRoleFieldMapping } = this.state;

        const workflow = {
            ...state,
            isReadOnly: functionalDetail !== null ? true : state.isReadOnly,
        };
        const GlobalTradeTeamFields =
            idx(teamRoleFieldMapping, (_) => _.GlobalTradeTeamFields) || [];
        const RoleTypeId = globalMdmDetail.RoleTypeId;
        const SystemTypeId = globalMdmDetail.SystemTypeId;
        const allowedFieldList = GlobalTradeTeamFields.filter(
            (item) => item.RoleTypeId === RoleTypeId && item.SystemTypeId === SystemTypeId
        );
        let allowedFields = undefined;
        if (allowedFieldList && allowedFieldList.length > 0) {
            allowedFields = allowedFieldList[0].Fields;
        }

        const requestDateUtc = moment(globalMdmDetail.DateOfCreation);
        const localDate = moment(requestDateUtc).local();
        const requestDate = localDate.format('YYYY-MM-DD hh:mm:ss A');

        const inputReadonlyProps = workflow.isReadOnly ? { disabled: true } : null;

        const showFunctionalDetail =
            state.isReadOnly && functionalDetail === null ? { display: 'none' } : null;

        const showButtons = workflow.isReadOnly ? { display: 'none' } : null;
        var isReleased = true;
        for (let i = 0; i < statusBarData.length; i++) {
            let teamstatus = statusBarData[i];
            if (teamstatus.WorkflowTaskStateTypeId === 2) {
                isReleased = false;
                break;
            } else {
                isReleased = true;
            }
        }
        var bgcolor = alert.color || '#FFF';
        if (this.props.fetching) {
            return <Loading />;
        }
        if (this.props.fetchingfnGroupData) {
            return <Loading />;
        }

        console.log(this.props, this.state, statusBarData);

        return (
            <ScrollView
                keyboardShouldPersistTaps="always"
                style={{
                    backgroundColor: '#EFF3F6',
                    paddingBottom: 75,
                }}>
                <Modal isVisible={this.state.modalVisible}>
                    {this._renderModalContent()}
                </Modal>
                {alert.display && (
                    <FlashMessage
                        bg={{ backgroundColor: bgcolor }}
                        message={alert.message}
                    />
                )}

                <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between">
                    <Text
                        style={{
                            fontWeight: '500',
                            fontSize: 24,
                            color: Colors.lightBlue,
                            marginTop: 25,
                            marginBottom: 20,
                            paddingLeft: 45,
                        }}>
                        My Tasks - {state.Header}
                    </Text>
                    <Box
                        marginTop="25px"
                        flex={3 / 4}
                        flexDirection="row-reverse"
                        alignItems="flex-end">
                        <MultiColorProgressBar readings={statusBarData} />
                    </Box>
                </Box>
                <Box marginTop="25px" flexDirection="row-reverse" alignItems="flex-end">
                    <StyledText
                        style={{
                            fontWeight: '600',
                            paddingRight: '20px',
                            fontSize: '16px',
                            color: '#11307D',
                        }}>
                        {' '}
                        Requestor : {RequestorName}{' '}
                    </StyledText>
                </Box>
                <Box flexDirection="row-reverse" alignItems="flex-end">
                    <StyledText
                        style={{
                            fontWeight: '600',
                            paddingRight: '20px',
                            fontSize: '16px',
                            color: '#11307D',
                        }}>
                        {' '}
                        Date of Request: {requestDate}{' '}
                    </StyledText>
                </Box>
                <View
                    style={{
                        flex: 1,
                        paddingHorizontal: width < 1440 ? 60 : width * 0.1,
                        paddingBottom: 10,
                    }}>
                    <Box fullHeight my={2}>
                        <Box flexDirection="row" justifyContent="space-around" my={4}>
                            <FormInput
                                px="25px"
                                flex={1 / 4}
                                label="WorkflowTitle"
                                maxLength={40}
                                name="WorkflowTitle"
                                variant="outline"
                                type="text"
                                value={globalMdmDetail && globalMdmDetail.WorkflowTitle}
                            />
                            <FormInput
                                px="25px"
                                flex={1 / 4}
                                label="Workflow Number"
                                name="workflow-number"
                                variant="outline"
                                type="text"
                                value={globalMdmDetail && globalMdmDetail.WorkflowId}
                            />
                            {globalMdmDetail.SystemRecordId && (
                                <FormInput
                                    px="25px"
                                    flex={1 / 4}
                                    label="Customer Number"
                                    name="CustomerNumber"
                                    variant="outline"
                                    type="text"
                                    value={globalMdmDetail.SystemRecordId}
                                />
                            )}
                            {globalMdmDetail &&
                                globalMdmDetail.MdmCustomerId &&
                                (isReleased ? (
                                    <TouchableOpacity
                                        onPress={() =>
                                            this.props.history.push(
                                                `/search-results/${globalMdmDetail.MdmCustomerId}`
                                            )
                                        }>
                                        <FormInput
                                            px="25px"
                                            flex={1 / 4}
                                            label="MDM Number"
                                            name="mdm-number"
                                            variant="outline"
                                            type="text"
                                            value={
                                                globalMdmDetail &&
                                                globalMdmDetail.MdmCustomerId
                                            }
                                        />
                                    </TouchableOpacity>
                                ) : (
                                    <FormInput
                                        px="25px"
                                        flex={1 / 4}
                                        label="MDM Number"
                                        name="mdm-number"
                                        variant="outline"
                                        type="text"
                                        value={
                                            globalMdmDetail &&
                                            globalMdmDetail.MdmCustomerId
                                        }
                                    />
                                ))}
                        </Box>
                        {!this.state.isInternationalVersionVisible && (
                            <GlobalMdmFields
                                staticDropDownData={this.props.staticData}
                                formData={globalMdmDetail}
                                viewInternationalModal={() => {
                                    this.setState({
                                        isInternationalVersionVisible: true,
                                    });
                                }}
                                isOlympus={true}
                                readOnly
                            />
                        )}
                        <Text
                            mt="42px"
                            mb="20px"
                            fontWeight="regular"
                            color="lightBlue"
                            fontSize={24}
                            pl={4}>
                            SYSTEM FIELDS
                        </Text>
                        <Box flexDirection="row" justifyContent="center">
                            <Box width={1 / 2} mx="auto" alignItems="center">
                                <FormInput
                                    label="System"
                                    name="System"
                                    inline
                                    variant="outline"
                                    type="text"
                                    value={
                                        SystemType[
                                            globalMdmDetail &&
                                                globalMdmDetail.SystemTypeId
                                        ]
                                    }
                                    hide={
                                        allowedFields && !allowedFields.includes('System')
                                    }
                                />
                                <FormInput
                                    label="Role"
                                    name="Role"
                                    inline
                                    variant="outline"
                                    type="text"
                                    value={
                                        staticData &&
                                        globalMdmDetail &&
                                        this.getStaticDropdownValues(
                                            globalMdmDetail.RoleTypeId,
                                            staticData && staticData.OlympusRoleType
                                        )
                                    }
                                    hide={
                                        allowedFields && !allowedFields.includes('Role')
                                    }
                                />
                                <FormInput
                                    label="Sales Org"
                                    name="SalesOrg"
                                    inline
                                    variant="outline"
                                    type="text"
                                    value={
                                        staticData &&
                                        globalMdmDetail &&
                                        this.getStaticDropdownValues(
                                            globalMdmDetail.SalesOrgTypeId,
                                            staticData && staticData.OlympusSalesOrgType
                                        )
                                    }
                                    hide={
                                        allowedFields &&
                                        !allowedFields.includes('SalesOrg')
                                    }
                                />
                                <FormInput
                                    inline
                                    variant="outline"
                                    label="URI/Service Code"
                                    name="URIServiceCode"
                                    type="text"
                                    hide={
                                        allowedFields &&
                                        !allowedFields.includes('ServiceCode')
                                    }
                                    value={
                                        globalMdmDetail && globalMdmDetail.URIServiceCode
                                    }
                                />
                                <FormInput
                                    inline
                                    variant="outline"
                                    label="Delivery Information"
                                    name="UnloadingPoints"
                                    type="text"
                                    value={
                                        globalMdmDetail && globalMdmDetail.UnloadingPoints
                                    }
                                    hide={
                                        allowedFields &&
                                        !allowedFields.includes('DeliveryInformation')
                                    }
                                />
                                <FormInput
                                    inline
                                    variant="outline"
                                    label="Tax Number 4"
                                    name="TaxNumber4"
                                    type="text"
                                    value={globalMdmDetail && globalMdmDetail.TaxNumber4}
                                    hide={
                                        allowedFields &&
                                        !allowedFields.includes('TaxNumber4')
                                    }
                                />
                                <FormInput
                                    inline
                                    variant="outline"
                                    label="Sales District"
                                    name="SalesDistrictTypeId"
                                    type="text"
                                    hide={
                                        allowedFields &&
                                        !allowedFields.includes('SalesDistrict')
                                    }
                                    value={
                                        staticData &&
                                        globalMdmDetail &&
                                        this.getStaticDropdownValues(
                                            globalMdmDetail.SalesDistrictTypeId,
                                            staticData &&
                                                staticData.OlympusSalesDistrictType
                                        )
                                    }
                                />
                                <FormInput
                                    label="URI Type"
                                    name="UriTypeId"
                                    // inputProps={isSalesForce}
                                    value={
                                        staticData &&
                                        globalMdmDetail &&
                                        this.getStaticDropdownValues(
                                            globalMdmDetail.URITypeId,
                                            this.props.staticData &&
                                                this.props.staticData.OlympusURIType
                                        )
                                    }
                                    formErrors={
                                        this.state.formErrors
                                            ? this.state.formErrors['UriTypeId']
                                            : null
                                    }
                                    onFieldChange={this.onFieldChange}
                                    hide={
                                        allowedFields &&
                                        !allowedFields.includes('URIType')
                                    }
                                    variant="outline"
                                    readOnly={true}
                                    inline
                                />
                                <FormInput
                                    inline
                                    variant="outline"
                                    label="Sales Office"
                                    name="SalesOffice"
                                    type="text"
                                    hide={
                                        allowedFields &&
                                        !allowedFields.includes('SalesOffice')
                                    }
                                    value={
                                        staticData &&
                                        globalMdmDetail &&
                                        this.getStaticDropdownValues(
                                            globalMdmDetail.SalesOfficeTypeId,
                                            staticData &&
                                                staticData.OlympusSalesOfficeType
                                        )
                                    }
                                />
                                <FormInput
                                    inline
                                    variant="outline"
                                    label="Sales Group"
                                    name="SalesGroup"
                                    type="text"
                                    hide={
                                        allowedFields &&
                                        !allowedFields.includes('SalesGroup')
                                    }
                                    value={
                                        staticData &&
                                        globalMdmDetail &&
                                        this.getStaticDropdownValues(
                                            globalMdmDetail.SalesGroupTypeId,
                                            staticData && staticData.OlympusSalesGroupType
                                        )
                                    }
                                />
                                <FormInput
                                    inline
                                    variant="outline"
                                    label="Incoterms 1"
                                    name="Incoterms1"
                                    type="text"
                                    hide={
                                        allowedFields &&
                                        !allowedFields.includes('Incoterms1')
                                    }
                                    value={
                                        staticData &&
                                        globalMdmDetail &&
                                        this.getStaticDropdownValues(
                                            globalMdmDetail.IncoTermsTypeId,
                                            staticData && staticData.OlympusIncoTermsType
                                        )
                                    }
                                />
                                <FormInput
                                    inline
                                    variant="outline"
                                    label="Place of Destination"
                                    name="PlaceOfDestination"
                                    type="text"
                                    hide={
                                        allowedFields &&
                                        !allowedFields.includes('PlaceOfDestination')
                                    }
                                    value={
                                        globalMdmDetail &&
                                        globalMdmDetail.PlaceOfDestination
                                    }
                                />
                                <FormInput
                                    inline
                                    variant="outline"
                                    label="Acct Assignment Group"
                                    name="AcctAssignmentGroupTypeId"
                                    type="text"
                                    hide={
                                        allowedFields &&
                                        !allowedFields.includes('AcctAssignmentGroup')
                                    }
                                    value={
                                        staticData &&
                                        globalMdmDetail &&
                                        this.getStaticDropdownValues(
                                            globalMdmDetail.AcctAssignmentGroupTypeId,
                                            staticData &&
                                                staticData.OlympusAcctAssignmentGroupType
                                        )
                                    }
                                />
                                <FormInput
                                    inline
                                    variant="outline"
                                    label="Tax Classification"
                                    name="TaxClassification"
                                    type="text"
                                    hide={
                                        allowedFields &&
                                        !allowedFields.includes('TaxClassification')
                                    }
                                    value={
                                        staticData &&
                                        globalMdmDetail &&
                                        this.getStaticDropdownValues(
                                            globalMdmDetail.TaxClassificationTypeId,
                                            staticData &&
                                                staticData.OlympusTaxClassificationType
                                        )
                                    }
                                />
                                <FormInput
                                    inline
                                    variant="outline"
                                    label="Customer Number"
                                    name="CustomerNumber"
                                    type="text"
                                    hide={
                                        allowedFields &&
                                        !allowedFields.includes('CustomerNumber')
                                    }
                                    value={
                                        staticData &&
                                        globalMdmDetail &&
                                        globalMdmDetail.CustomerNumber
                                    }
                                />
                            </Box>

                            <Box width={1 / 2} mx="auto" alignItems="center">
                                <FormInput
                                    label="Distribution Channel"
                                    name="DistributionChannel"
                                    inline
                                    variant="outline"
                                    type="text"
                                    value={
                                        staticData &&
                                        globalMdmDetail &&
                                        this.getStaticDropdownValues(
                                            globalMdmDetail.DistributionChannelTypeId,
                                            staticData &&
                                                staticData.OlympusDistributionChannelType
                                        )
                                    }
                                    hide={
                                        allowedFields &&
                                        !allowedFields.includes('DistributionChannel')
                                    }
                                />
                                <FormInput
                                    label="Division"
                                    name="DivisionTypeId"
                                    inline
                                    variant="outline"
                                    type="text"
                                    value={
                                        staticData &&
                                        globalMdmDetail &&
                                        this.getStaticDropdownValues(
                                            globalMdmDetail.DivisionTypeId,
                                            staticData && staticData.OlympusDivisionType
                                        )
                                    }
                                    hide={
                                        allowedFields &&
                                        !allowedFields.includes('Division')
                                    }
                                />
                                <FormInput
                                    label="CompanyCode"
                                    name="CompanyCodeTypeId"
                                    inline
                                    variant="outline"
                                    type="text"
                                    value={
                                        staticData &&
                                        globalMdmDetail &&
                                        this.getStaticDropdownValues(
                                            globalMdmDetail.CompanyCodeTypeId,
                                            staticData &&
                                                staticData.OlympusCompanyCodeType
                                        )
                                    }
                                    hide={
                                        allowedFields &&
                                        !allowedFields.includes('CompanyCode')
                                    }
                                />
                                <FormInput
                                    variant="outline"
                                    label="Price Group"
                                    name="PriceGroupTypeId"
                                    value={
                                        staticData &&
                                        globalMdmDetail &&
                                        this.getStaticDropdownValues(
                                            globalMdmDetail.PriceGroupTypeId,
                                            staticData && staticData.OlympusPriceGroupType
                                        )
                                    }
                                    formErrors={
                                        this.state.formErrors
                                            ? this.state.formErrors['PriceGroup']
                                            : null
                                    }
                                    onFieldChange={this.onFieldChange}
                                    hide={
                                        allowedFields &&
                                        !allowedFields.includes('PriceGroup')
                                    }
                                    inline={true}
                                />
                                <FormInput
                                    variant="outline"
                                    label="Delivering Plant"
                                    name="DeliveringPlant"
                                    value={
                                        staticData &&
                                        globalMdmDetail &&
                                        this.getStaticDropdownValues(
                                            globalMdmDetail.DeliveringPlantTypeId,
                                            staticData &&
                                                staticData.OlympusDeliveringPlantType
                                        )
                                    }
                                    formErrors={
                                        this.state.formErrors
                                            ? this.state.formErrors['DeliveringPlant']
                                            : null
                                    }
                                    onFieldChange={this.onFieldChange}
                                    hide={
                                        allowedFields &&
                                        !allowedFields.includes('DeliveringPlant')
                                    }
                                    inline={true}
                                />
                                <FormInput
                                    label="Sold To"
                                    name="SoldTo"
                                    team="system"
                                    inline
                                    variant="outline"
                                    type="text"
                                    hide={
                                        allowedFields &&
                                        !allowedFields.includes('SoldTo') &&
                                        globalMdmDetail &&
                                        globalMdmDetail.RoleTypeId != 2 &&
                                        globalMdmDetail.RoleTypeId != 3 &&
                                        globalMdmDetail.RoleTypeId != 4
                                    }
                                    value={globalMdmDetail && globalMdmDetail.SoldTo}
                                    hide={
                                        allowedFields && !allowedFields.includes('SoldTo')
                                    }
                                />
                                <FormInput
                                    label="Language"
                                    name="Language"
                                    team="system"
                                    inline
                                    variant="outline"
                                    type="text"
                                    value={
                                        staticData &&
                                        globalMdmDetail &&
                                        this.getStaticDropdownValues(
                                            globalMdmDetail.LanguageTypeId,
                                            staticData && staticData.OlympusLanguageType
                                        )
                                    }
                                    hide={
                                        allowedFields &&
                                        !allowedFields.includes('Language')
                                    }
                                />
                                <FormInput
                                    label="Customer Class"
                                    name="CustomerClass"
                                    team="system"
                                    inline
                                    variant="outline"
                                    type="text"
                                    hide={
                                        allowedFields &&
                                        !allowedFields.includes('CustomerClass')
                                    }
                                    value={
                                        staticData &&
                                        globalMdmDetail &&
                                        this.getStaticDropdownValues(
                                            globalMdmDetail.CustomerClassTypeId,
                                            staticData &&
                                                staticData.OlympusCustomerClassType
                                        )
                                    }
                                />
                                <FormInput
                                    label="Recon Account"
                                    name="ReconAccount"
                                    team="system"
                                    inline
                                    variant="outline"
                                    type="text"
                                    hide={
                                        allowedFields &&
                                        !allowedFields.includes('ReconAccount')
                                    }
                                    value={
                                        staticData &&
                                        globalMdmDetail &&
                                        this.getStaticDropdownValues(
                                            globalMdmDetail.ReconAccountTypeId,
                                            staticData &&
                                                staticData.OlympusReconAccountType
                                        )
                                    }
                                />
                                <FormInput
                                    label="Terms of Payment"
                                    name="TermsofPayment"
                                    team="system"
                                    inline={false}
                                    variant="outline"
                                    type="text"
                                    hide={
                                        allowedFields &&
                                        !allowedFields.includes('TermsOfPayment')
                                    }
                                    value={
                                        staticData &&
                                        globalMdmDetail &&
                                        this.getStaticDropdownValues(
                                            globalMdmDetail.TermsOfPaymentTypeId,
                                            staticData &&
                                                staticData.OlympusTermsOfPaymentType
                                        )
                                    }
                                />
                                <FormInput
                                    label="Currency"
                                    name="Currency"
                                    team="system"
                                    inline
                                    variant="outline"
                                    type="text"
                                    hide={
                                        allowedFields &&
                                        !allowedFields.includes('Currency')
                                    }
                                    value={
                                        staticData &&
                                        globalMdmDetail &&
                                        this.getStaticDropdownValues(
                                            globalMdmDetail.CurrencyTypeId,
                                            staticData && staticData.OlympusCurrencyType
                                        )
                                    }
                                />
                                <FormInput
                                    label="Primary GPO"
                                    name="PrimaryGPO"
                                    team="system"
                                    inline
                                    variant="outline"
                                    type="text"
                                    hide={
                                        allowedFields &&
                                        !allowedFields.includes('PrimaryGPO')
                                    }
                                    value={globalMdmDetail && globalMdmDetail.PrimaryGPO}
                                />
                                <FormInput
                                    label="Requested Credit Limit"
                                    name="RequestedCreditLimit"
                                    team="system"
                                    inline
                                    variant="outline"
                                    type="text"
                                    hide={
                                        allowedFields &&
                                        !allowedFields.includes('RequestedCreditLimit')
                                    }
                                    value={
                                        globalMdmDetail &&
                                        globalMdmDetail.RequestedCreditLimit
                                    }
                                />
                                <FormInput
                                    label="Priority"
                                    name="Priority"
                                    team="system"
                                    inline
                                    variant="outline"
                                    type="text"
                                    value={
                                        staticData &&
                                        globalMdmDetail &&
                                        this.getStaticDropdownValues(
                                            globalMdmDetail.PriorityTypeId,
                                            staticData && staticData.OlympusPriorityType
                                        )
                                    }
                                    hide={
                                        allowedFields &&
                                        !allowedFields.includes('Priority')
                                    }
                                />
                                <FormInput
                                    label="Effective Date"
                                    name="EffectiveDate"
                                    team="system"
                                    inline
                                    variant="outline"
                                    type="text"
                                    value={
                                        globalMdmDetail && globalMdmDetail.EffectiveDate
                                    }
                                    hide={
                                        allowedFields &&
                                        !allowedFields.includes('EffectiveDate')
                                    }
                                />
                                <FormInput
                                    label="Purpose of Request"
                                    name="Purpose"
                                    multiline
                                    value={globalMdmDetail && globalMdmDetail.Purpose}
                                    numberOfLines={
                                        globalMdmDetail.Purpose &&
                                        globalMdmDetail.Purpose.length > 36
                                            ? 3
                                            : 1
                                    }
                                    style={{ lineHeight: '2.5' }}
                                    inline
                                    maxLength={1000}
                                    variant="outline"
                                    type="text"
                                    hide={
                                        allowedFields &&
                                        !allowedFields.includes('PurposeOfRequest')
                                    }
                                />
                            </Box>
                        </Box>
                    </Box>

                    {files && <FilesList files={files} readOnly />}
                    {workflow.isReadOnly ? (
                        <>
                            <Box my={4}>
                                <Box flexDirection="row" justifyContent="center">
                                    <Box width={1 / 2} mx="auto" alignItems="center">
                                        <FormInput
                                            label="Notes"
                                            name="AdditionalNotes"
                                            inputWidth="100%"
                                            multiline
                                            numberOfLines={2}
                                            type="text"
                                            value={
                                                workflow.isReadOnly
                                                    ? Notes
                                                    : this.state.formData
                                                    ? this.state.formData[
                                                          'AdditionalNotes'
                                                      ]
                                                    : null
                                            }
                                            variant={
                                                workflow.isReadOnly ? 'outline' : 'solid'
                                            }
                                            inline={workflow.isReadOnly ? true : false}
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes('AdditionalNotes')
                                            }
                                        />
                                    </Box>
                                    <Box
                                        width={1 / 2}
                                        mx="auto"
                                        alignItems="center"></Box>
                                </Box>
                            </Box>
                        </>
                    ) : (
                        <FilesList
                            title="NEW ATTACHMENTS"
                            formErrors={this.state.fileErrors}
                            files={selectedFilesIds.map((id) => selectedFiles[id])}
                            onChange={(value, id) => {
                                this.setState({
                                    selectedFiles: {
                                        ...selectedFiles,
                                        [id]: {
                                            ...selectedFiles[id],
                                            DocumentType: parseInt(value),
                                        },
                                    },
                                });
                            }}
                            onRemove={(value, id) => this.removeFile(id)}
                        />
                    )}

                    <Box {...showButtons}>
                        <Flex
                            justifyEnd
                            alignCenter
                            style={{
                                paddingTop: 15,
                                flexDirection: 'row',
                                alignItems: 'center',
                                paddingLeft: 10,
                                paddingRight: 15,
                                marginTop: 20,
                                marginBottom: 10,
                                marginHorizontal: 25,
                            }}>
                            <label htmlFor="file-upload" className="custom-file-upload">
                                <MaterialIcons
                                    name="attach-file"
                                    size={18}
                                    color="#fff"
                                />
                            </label>
                            <input
                                id="file-upload"
                                type="file"
                                key={this.state.fileinputkey}
                                onChange={this.selectFiles}
                                multiple
                            />

                            <Button
                                onPress={(event) =>
                                    this.setState({ isReject: false, modalVisible: true })
                                }
                                title="Approve"
                            />
                            <Button
                                title="Reject"
                                onPress={(event) =>
                                    this.setState({ isReject: true, modalVisible: true })
                                }
                            />
                        </Flex>
                    </Box>
                    {this.state.isInternationalVersionVisible && (
                        <Modal
                            isVisible={this.state.isInternationalVersionVisible}
                            style={{ background: '#FFFFFF' }}>
                            <GlobalMdmFields
                                staticDropDownData={this.props.staticData}
                                formData={globalMdmDetail}
                                isInternationalVersion={true}
                                isOlympus={true}
                                isOlympusModal={true}
                                viewInternationalModal={() => {
                                    this.setState({
                                        isInternationalVersionVisible: false,
                                    });
                                }}
                                readOnly
                            />
                        </Modal>
                    )}
                </View>
            </ScrollView>
        );
    }
}

class Default extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const props = this.props;

        return (
            <DimensionAware
                render={(dimensions) => (
                    <Page
                        {...{
                            ...props,
                            width: getWindowWidth(dimensions),
                            height: getWindowHeight(dimensions),
                            marginBottom: 25,
                        }}
                    />
                )}
            />
        );
    }
}

const mapStateToProps = ({ workflows, myTasks, staticData }) => {
    const { fetching, alert } = myTasks;
    const { fetchingfnGroupData, statusBarData, functionalGroupDetails } = workflows;
    const { data, fetching: staticFetching } = staticData;
    return {
        fetchingfnGroupData,
        fetching: fetching || staticFetching,
        alert,
        statusBarData,
        functionalGroupDetails,
        staticData: data,
    };
};

export default connect(mapStateToProps, {
    saveApolloMyTaskGlobalTrade,
    getFunctionalGroupData,
    getStatusBarData,
    getStaticRequest,
})(Default);

const styles = StyleSheet.create({
    progressIndicator: {
        flex: 1,
        paddingBottom: 5,
        flexDirection: 'row-reverse',
        alignItems: 'flex-end',
    },
    statusText: {
        fontSize: 15,
        color: '#1D4289',
        fontFamily: 'Poppins',
        textAlign: 'center',
        marginTop: 20,
    },
});
