import React from 'react';
import { ScrollView, View, StyleSheet, TouchableOpacity } from 'react-native';
import {
    DimensionAware,
    getWindowHeight,
    getWindowWidth,
} from 'react-native-dimension-aware';
import { connect } from 'react-redux';
import { Colors, StyledText } from '../../../../theme/Index';
import { Flex, Button, Box, Text } from '../../../../components/common';
import { FormInput } from '../../../../components/form';
import { saveApolloUpdateMyTaskPricing } from '../../../../appRedux/actions/UpdateFlowAction';
import { yupAllFieldsValidation } from '../../../../constants/utils';
import { mapKeys } from 'lodash';
import GlobalMdmFields from '../../../../components/GlobalMdmFields';
import { mytaskPricingRules, rejectRules } from '../../../../constants/FieldRules';

import {
    RoleType,
    SalesOrgType,
    SystemType,
    DistributionChannelType,
    DivisionType,
    CompanyCodeType,
} from '../../../../constants/WorkflowEnums';

import DynamicSelect from '../../../../components/DynamicSelect';
import {
    fetchPricingDropDownData,
    fetchTeamRoleFieldMapping,
} from '../../../../redux/DropDownDatas';
import Loading from '../../../../components/Loading';
import FlashMessage, { FlashMessages } from '../../../../components/FlashMessage';
import MultiColorProgressBar from '../../../../components/MultiColorProgressBar';
import {
    getStatusBarData,
    getFunctionalGroupData,
} from '../../../../appRedux/actions/Workflow';
import { getCustomerFromSAP, getStaticRequest } from '../../../../appRedux/actions';
import idx from 'idx';
import DeltaField from '../../../../components/DeltaField';
import Modal from 'modal-enhanced-react-native-web';
import styles from './styles';
import BreadCrumbs from '../../../../components/BreadCrumbs';
import { removeMessage } from '../../../../appRedux/actions/Toast';
import FilesList from '../../../../components/FilesList';
import { MaterialIcons } from '@expo/vector-icons';
import * as moment from 'moment';

class Page extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            WorkflowId: this.props.location.state.WorkflowId,
            TaskId: this.props.location.state.TaskId,
            reject: false,
            dropDownDatas: {},
            teamRoleFieldMapping: {},
            formData: { RejectionButton: false },
            formErrors: {},
            isReject: false,
            modalVisible: false,
            selectedFiles: {},
            selectedFilesIds: [],
            files: [],
            fileinputkey: Date.now(),
        };
    }

    componentDidMount() {
        let { state: wf } = this.props.location;
        this.props.getStaticRequest([42, 43, 82]);
        let postJson = {
            workflowId: wf.WorkflowId,
            fuctionalGroup: 'pricing',
            taskId: wf.TaskId,
        };
        this.props.getStatusBarData(postJson);
        this.props.getCustomerFromSAP({
            WorkflowId: wf.WorkflowId,
            TaskId: wf.TaskId,
            CustomerNumber: '',
            DivisionTypeId: 0,
            SystemTypeId: 0,
            DistributionChannelTypeId: 0,
            CompanyCodeTypeId: '',
            SalesOrgTypeId: 0,
        });
        fetchPricingDropDownData(true).then((res) =>
            this.setState({ dropDownDatas: res })
        );
        fetchTeamRoleFieldMapping().then((resp) => {
            const data = resp;
            this.setState({ teamRoleFieldMapping: data });
        });
    }

    setFormErrors = (errors) => {
        const { formErrors } = this.state;
        this.setState({ formErrors: errors }, () => window.scrollTo(0, 0));
    };

    onFieldChange = (value, e) => {
        const { name } = e.target;
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: value,
            },
        });
    };

    handleFormSubmission = (schema) => {
        let {
                TaskId,
                WorkflowId,
                formData,
                selectedFiles,
                selectedFilesIds,
            } = this.state,
            postData = {};
        let { action } = this.props.match.params;
        try {
            const WorkflowTaskModel = {
                RejectReason: formData['RejectionButton']
                    ? formData['RejectionReason']
                    : '',
                AdditionalNotes: formData['AdditionalNotes']
                    ? formData['AdditionalNotes']
                    : '',
                TaskId: TaskId,
                UserId: localStorage.getItem('userId'),
                WorkflowId: WorkflowId,
            };
            if (action === 'update') {
                WorkflowTaskModel['WorkflowTaskOperationType'] = !formData[
                    'RejectionButton'
                ]
                    ? 1
                    : 4;
            } else if (action === 'extend-salesorg') {
                WorkflowTaskModel['WorkflowTaskOperationType'] = !formData[
                    'RejectionButton'
                ]
                    ? 1
                    : 2;
            }

            postData['formData'] = {
                WorkflowTaskModel,
                action,
                Deltas: this.props.denormalizedDeltas || null,
            };

            postData['files'] = selectedFilesIds.map((id) => selectedFiles[id]);
            this.props.saveApolloUpdateMyTaskPricing(postData, this.props.history);
            this.resetForm();
            this.scrollToTop();
        } catch (error) {
            console.log('form validtion error');
        }
    };

    onSubmit = (event, reject, schema) => {
        let { formData, selectedFilesIds, selectedFiles } = this.state;
        let fileErrors = {};
        let errors = false;
        selectedFilesIds.map((id) => {
            if (selectedFiles[id] && selectedFiles[id].DocumentType <= 0) {
                fileErrors[id] = 'Document Type Required for file';
                errors = true;
            }
        });
        this.setState({ fileErrors, isFileErrors: errors });

        this.setState(
            {
                modalVisible: false,
                formData: {
                    ...this.state.formData,
                    RejectionButton: reject,
                },
            },
            () => {
                if (this.state.isFileErrors === false) this.handleFormSubmission(schema);
            }
        );
    };

    selectFiles = (events) => {
        event.preventDefault();

        const { selectedFilesIds, selectedFiles } = this.state;
        const id = events.target.files[0].name;

        this.setState({
            selectedFiles: {
                ...selectedFiles,
                [id]: {
                    data: events.target.files[0],
                    DocumentName: events.target.files[0].name,
                    DocumentType: 0,
                },
            },
            selectedFilesIds: [...selectedFilesIds, id],
            filename: events.target.files[0].name,
            fileinputkey: Date.now(),
        });
    };

    removeFile = (id) => {
        event.preventDefault();

        var selectedFilesDict = this.state.selectedFiles;
        var selectedFilesIdsList = this.state.selectedFilesIds;
        // remove the existing state
        delete selectedFilesDict[id];
        selectedFilesIdsList = selectedFilesIdsList.filter((fileid) => fileid !== id);

        this.setState({
            selectedFiles: selectedFilesDict,
            selectedFilesIds: selectedFilesIdsList,
        });
    };

    scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    resetForm = () => {
        Object.keys(this.state.formData).map((key) => {
            this.setState({
                formData: {
                    [key]: '',
                },
            });
        });
        Object.keys(this.state.formErrors).map((key) => {
            this.setState({
                formErrors: {
                    [key]: '',
                },
            });
        });
        //restore initial values
        this.setState({
            formData: { RejectionButton: false },
        });
    };

    getValue = (name) => {
        const { bapi70CustData = {}, deltas = {} } = this.props;
        const { dropDownDatas } = this.state;
        if (deltas[name]) {
            return idx(
                dropDownDatas,
                (_) => _[name][deltas[name].UpdatedValue].description
            );
        } else if (bapi70CustData) {
            return idx(dropDownDatas, (_) => _[name][bapi70CustData.name].description);
        }
    };

    _renderButton = (text, onPress) => (
        <TouchableOpacity onPress={onPress}>
            <View style={styles.button}>
                <Text>{text}</Text>
            </View>
        </TouchableOpacity>
    );

    _renderModalContent = () => (
        <Box
            my={4}
            mx="30%"
            backgroundColor="white"
            borderRadius="4px"
            borderColor="rgba(0, 0, 0, 0.1)">
            <Text my={3} fontWeight="regular" color="lightBlue" fontSize={24} pl={4}>
                Submit Workflow
            </Text>
            <Box px="0px" pb="22px" justifyContent="center">
                <Box alignItems="center" justifyContent="center">
                    {!this.state.isReject ? (
                        <FormInput
                            label="Additional Notes"
                            multiline
                            maxLength={250}
                            numberOfLines={2}
                            name="AdditionalNotes"
                            type="text"
                            onChange={this.onFieldChange}
                            value={
                                this.state.formData &&
                                this.state.formData['AdditionalNotes']
                            }
                            variant={'solid'}
                        />
                    ) : (
                        <FormInput
                            label="Rejection Reason"
                            name="RejectionReason"
                            maxLength={250}
                            onChange={this.onFieldChange}
                            error={'*this field is required'}
                            multiline
                            numberOfLines={2}
                            type="text"
                            value={
                                this.state.formData &&
                                this.state.formData['RejectionReason']
                            }
                            variant="solid"
                        />
                    )}
                </Box>
                <Flex
                    justifyEnd
                    alignCenter
                    alignSelf="flex-end"
                    style={{
                        paddingTop: 15,
                        flexDirection: 'row',
                        alignItems: 'center',
                        paddingLeft: 10,
                        paddingRight: 15,
                        marginTop: 20,
                        marginBottom: 10,
                        marginHorizontal: 25,
                    }}>
                    <Button
                        onPress={(event) => {
                            if (this.state.isReject)
                                this.setState(
                                    { isReject: false, modalVisible: null },
                                    () => this.onSubmit(event, true, rejectRules)
                                );
                            else this.onSubmit(event, false, mytaskPricingRules);
                        }}
                        title="Submit"
                    />
                    <Button
                        title="Cancel"
                        onPress={() => this.setState({ modalVisible: null })}
                    />
                </Flex>
            </Box>
        </Box>
    );

    render() {
        const {
            width,
            location,
            bapi70CustData = {},
            deltas = {},
            statusBarData,
            alert = {},
            TasksStatusByTeamId = null,
        } = this.props;
        const Deltas = deltas;

        const {
            dropDownDatas,
            teamRoleFieldMapping,
            selectedFilesIds,
            selectedFiles,
        } = this.state;
        const { state } = location;

        const PricingTeamFields =
            idx(teamRoleFieldMapping, (_) => _.PricingTeamFields) || [];
        const RoleTypeId = bapi70CustData.RoleTypeId;
        const SystemTypeId = bapi70CustData.SystemType;
        const allowedFieldList = PricingTeamFields.filter(
            (item) => item.RoleTypeId === RoleTypeId && item.SystemTypeId === SystemTypeId
        );
        let allowedFields = undefined;
        if (allowedFieldList && allowedFieldList.length > 0) {
            allowedFields = allowedFieldList[0].Fields;
        }

        const workflow = {
            ...state,
            isReadOnly:
                TasksStatusByTeamId === null ||
                !(
                    state.WorkflowStateType === 2 &&
                    TasksStatusByTeamId[8].WorkflowTaskStateTypeId === 2
                ),
        };

        const requestDateUtc = moment(idx(bapi70CustData, (_) => _.DateOfRequest));
        const localDate = moment(requestDateUtc).local();
        const requestDate = localDate.format('YYYY-MM-DD hh:mm:ss A');

        const inputReadonlyProps = workflow.isReadOnly ? { disabled: true } : null;
        const {
            WorkflowDocuments: files = [],
            RequestorName,
        } = this.props.bapi70CustData;

        const showFunctionalDetail =
            state.isReadOnly && bapi70CustData === null ? { display: 'none' } : null;

        const showButtons = workflow.isReadOnly ? { display: 'none' } : null;

        const inputProps = {
            variant: 'outline',
            inline: true,
            type: 'text',
        };
        let { action } = this.props.match.params;
        var bgcolor = alert.color || '#FFF';
        if (this.props.fetching) {
            return <Loading />;
        }
        if (this.props.fetchingfnGroupData) {
            return <Loading />;
        }
        return (
            <ScrollView
                keyboardShouldPersistTaps="always"
                style={{
                    backgroundColor: '#EFF3F6',
                    paddingBottom: 75,
                }}>
                <Modal isVisible={this.state.modalVisible}>
                    {this._renderModalContent()}
                </Modal>
                <FlashMessages
                    toasts={this.props.toasts}
                    onDismiss={this.props.removeMessage}
                />
                {alert.display && (
                    <FlashMessage
                        bg={{ backgroundColor: bgcolor }}
                        message={alert.message}
                    />
                )}

                <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between">
                    <Text
                        style={{
                            fontWeight: '500',
                            fontSize: 24,
                            color: Colors.lightBlue,
                            marginTop: 25,
                            marginBottom: 20,
                            paddingLeft: 45,
                        }}>
                        MY TASKS - {state.Header}
                    </Text>
                    <Box
                        marginTop="25px"
                        flex={3 / 4}
                        flexDirection="row-reverse"
                        alignItems="flex-end">
                        <MultiColorProgressBar readings={statusBarData} />
                    </Box>
                </Box>
                <Box marginTop="25px" flexDirection="row-reverse" alignItems="flex-end">
                    <StyledText
                        style={{
                            fontWeight: '600',
                            paddingRight: '20px',
                            fontSize: '16px',
                            color: '#10206C',
                        }}>
                        {' '}
                        Requestor : {RequestorName}{' '}
                    </StyledText>
                </Box>
                <Box flexDirection="row-reverse" alignItems="flex-end">
                    <StyledText
                        style={{
                            fontWeight: '600',
                            paddingRight: '20px',
                            fontSize: '16px',
                            color: '#10206C',
                        }}>
                        {' '}
                        Date of Request: {requestDate}{' '}
                    </StyledText>
                </Box>
                <View
                    style={{
                        flex: 1,
                        paddingHorizontal: width < 1440 ? 60 : width * 0.1,
                        paddingBottom: 10,
                    }}>
                    <Box fullHeight my={2}>
                        <Box flexDirection="row" justifyContent="space-around" my={4}>
                            <FormInput
                                px="25px"
                                flex={1 / 4}
                                label="Workflow Title"
                                name="title"
                                variant="outline"
                                type="text"
                                value={bapi70CustData && bapi70CustData.WorkflowTitle}
                            />
                            <FormInput
                                px="25px"
                                flex={1 / 4}
                                label="Workflow Number"
                                name="workflow-number"
                                variant="outline"
                                type="text"
                                value={state && state.WorkflowId}
                            />
                            {bapi70CustData && bapi70CustData.SystemRecordId && (
                                <FormInput
                                    px="25px"
                                    flex={1 / 4}
                                    label="Customer Number"
                                    name="CustomerNumber"
                                    variant="outline"
                                    type="text"
                                    value={
                                        bapi70CustData && bapi70CustData.SystemRecordId
                                    }
                                />
                            )}
                            {bapi70CustData && bapi70CustData.MdmNumber && (
                                <TouchableOpacity
                                    onPress={() =>
                                        this.props.history.push(
                                            `/search-results/${
                                                bapi70CustData && bapi70CustData.MdmNumber
                                            }`
                                        )
                                    }>
                                    <FormInput
                                        px="25px"
                                        flex={1 / 4}
                                        label="MDM Number"
                                        name="mdm-number"
                                        variant="outline"
                                        type="text"
                                        value={bapi70CustData && bapi70CustData.MdmNumber}
                                    />
                                </TouchableOpacity>
                            )}
                        </Box>
                        <GlobalMdmFields
                            staticDropDownData={this.props.staticData}
                            formData={bapi70CustData}
                            readOnly
                        />

                        <Text
                            mt="42px"
                            mb="20px"
                            fontWeight="regular"
                            color="lightBlue"
                            fontSize={24}
                            pl={4}>
                            SYSTEM FIELDS
                        </Text>
                        <Box flexDirection="row" justifyContent="center">
                            <Box width={1 / 2} mx="auto" alignItems="center">
                                <FormInput
                                    label="System"
                                    name="System"
                                    delta={deltas['SystemType']}
                                    value={
                                        SystemType[
                                            bapi70CustData && bapi70CustData.SystemType
                                        ]
                                    }
                                    {...inputProps}
                                />
                                <FormInput
                                    label="Role"
                                    name="Role"
                                    delta={deltas['RoleTypeId']}
                                    value={
                                        RoleType[
                                            bapi70CustData && bapi70CustData.RoleTypeId
                                        ]
                                    }
                                    {...inputProps}
                                />
                                <FormInput
                                    label="Sales Org"
                                    name="SalesOrg"
                                    delta={deltas['SalesOrgTypeId']}
                                    value={
                                        SalesOrgType[
                                            bapi70CustData &&
                                                bapi70CustData.SalesOrgTypeId
                                        ]
                                    }
                                    {...inputProps}
                                />
                                {action === 'extend-salesorg' ? (
                                    <FormInput
                                        label="Target Sales Org"
                                        name="TargetSalesOrg"
                                        team="system"
                                        inline
                                        variant="outline"
                                        type="text"
                                        value={
                                            SalesOrgType[
                                                bapi70CustData &&
                                                    bapi70CustData.TargetSalesOrgTypeId
                                            ]
                                        }
                                    />
                                ) : null}
                            </Box>
                            <Box width={1 / 2} mx="auto" alignItems="center">
                                {deltas['DistributionChannelTypeId'] ? (
                                    <DeltaField
                                        delta={deltas['DistributionChannelTypeId']}
                                    />
                                ) : (
                                    <FormInput
                                        label="Distribution Channel"
                                        name="DistributionChannel"
                                        inline
                                        variant="outline"
                                        type="text"
                                        value={
                                            DistributionChannelType[
                                                bapi70CustData &&
                                                    bapi70CustData.DistributionChannelTypeId
                                            ]
                                        }
                                    />
                                )}
                                {deltas['DivisionTypeId'] ? (
                                    <DeltaField delta={deltas['DivisionTypeId']} />
                                ) : (
                                    <FormInput
                                        label="Division"
                                        name="DivisionTypeId"
                                        inline
                                        variant="outline"
                                        type="text"
                                        value={
                                            DivisionType[
                                                bapi70CustData &&
                                                    bapi70CustData.DivisionTypeId
                                            ]
                                        }
                                    />
                                )}
                                <FormInput
                                    label="CompanyCode"
                                    name="CompanyCodeTypeId"
                                    delta={deltas['CompanyCodeTypeId']}
                                    value={
                                        CompanyCodeType[
                                            bapi70CustData &&
                                                bapi70CustData.CompanyCodeTypeId
                                        ]
                                    }
                                    {...inputProps}
                                />
                                <FormInput
                                    label="Purpose of Request"
                                    name="Purpose"
                                    value={
                                        (bapi70CustData && bapi70CustData.Purpose) ||
                                        (Deltas['Purpose'] &&
                                            Deltas['Purpose'].UpdatedValue)
                                    }
                                    multiline
                                    numberOfLines={
                                        bapi70CustData.Purpose &&
                                        bapi70CustData.Purpose.length > 36
                                            ? 3
                                            : 1
                                    }
                                    style={{ lineHeight: '2.5' }}
                                    inline
                                    variant="outline"
                                    maxLength={1000}
                                    type="text"
                                />
                            </Box>
                        </Box>

                        <Box {...showFunctionalDetail}>
                            <Text
                                mt="42px"
                                mb="20px"
                                fontWeight="regular"
                                color="lightBlue"
                                fontSize={24}
                                pl={4}>
                                PRICING FIELDS
                            </Text>
                            <Box flexDirection="row" justifyContent="center">
                                <Box width={1 / 2} mx="auto" alignItems="center">
                                    {Deltas['SpecialPricingTypeId'] ? (
                                        <DeltaField
                                            delta={Deltas['SpecialPricingTypeId']}
                                        />
                                    ) : (
                                        <FormInput
                                            label="Special Pricing "
                                            name="SpecialPricingTypeId"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes(
                                                    'SpecialPricingTypeId'
                                                )
                                            }
                                            value={
                                                bapi70CustData &&
                                                idx(
                                                    dropDownDatas,
                                                    (_) =>
                                                        _.SpecialPricingTypeId[
                                                            bapi70CustData
                                                                .SpecialPricingTypeId
                                                        ]
                                                )
                                            }
                                            {...inputProps}
                                        />
                                    )}
                                    {Deltas['DAPAPricingTypeId'] ? (
                                        <DeltaField delta={Deltas['DAPAPricingTypeId']} />
                                    ) : (
                                        <FormInput
                                            label="DAPA Pricing"
                                            name="DAPAPricingTypeId"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes(
                                                    'DAPAPricingTypeId'
                                                )
                                            }
                                            value={
                                                bapi70CustData &&
                                                idx(
                                                    dropDownDatas,
                                                    (_) =>
                                                        _.DAPAPricingTypeId[
                                                            bapi70CustData
                                                                .DAPAPricingTypeId
                                                        ]
                                                )
                                            }
                                            {...inputProps}
                                        />
                                    )}
                                    {Deltas['DAPA2PricingTypeId'] ? (
                                        <DeltaField
                                            delta={Deltas['DAPA2PricingTypeId']}
                                        />
                                    ) : (
                                        <FormInput
                                            label="DAPA2 Pricing"
                                            name="DAPA2PricingTypeId"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes(
                                                    'DAPA2PricingTypeId'
                                                )
                                            }
                                            value={
                                                bapi70CustData &&
                                                idx(
                                                    dropDownDatas,
                                                    (_) =>
                                                        _.DAPA2PricingTypeId[
                                                            bapi70CustData
                                                                .DAPA2PricingTypeId
                                                        ]
                                                )
                                            }
                                            {...inputProps}
                                        />
                                    )}
                                </Box>
                                <Box width={1 / 2} mx="auto" alignItems="center">
                                    {Deltas['DistLevelTypeId'] ? (
                                        <DeltaField
                                            label="Dist Level Pricing"
                                            delta={Deltas['DistLevelTypeId']}
                                        />
                                    ) : (
                                        <FormInput
                                            label="Dist Level Pricing"
                                            name="DistLevelTypeId"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes('DistLevelTypeId')
                                            }
                                            value={
                                                bapi70CustData &&
                                                idx(
                                                    dropDownDatas,
                                                    (_) =>
                                                        _.DistLevelTypeId[
                                                            bapi70CustData.DistLevelTypeId
                                                        ]
                                                )
                                            }
                                            {...inputProps}
                                        />
                                    )}
                                    {Deltas['FSSPricingTypeId'] ? (
                                        <DeltaField delta={Deltas['FSSPricingTypeId']} />
                                    ) : (
                                        <FormInput
                                            label="FSS Pricing"
                                            name="FSSPricingTypeId"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes(
                                                    'FSSPricingTypeId'
                                                )
                                            }
                                            value={
                                                bapi70CustData &&
                                                idx(
                                                    dropDownDatas,
                                                    (_) =>
                                                        _.FSSPricingTypeId[
                                                            bapi70CustData
                                                                .FSSPricingTypeId
                                                        ]
                                                )
                                            }
                                            {...inputProps}
                                        />
                                    )}
                                </Box>
                            </Box>
                            {files && <FilesList files={files} readOnly />}
                            {workflow.isReadOnly ? (
                                <Box my={4}>
                                    <Box flexDirection="row" justifyContent="center">
                                        {bapi70CustData &&
                                            bapi70CustData.RejectionReason && (
                                                <Box
                                                    width={1 / 2}
                                                    mx="auto"
                                                    alignItems="center">
                                                    <FormInput
                                                        label="Notes"
                                                        name="RejectionReason"
                                                        inputWidth="100%"
                                                        onChange={this.onFieldChange}
                                                        error={
                                                            this.state.formErrors
                                                                ? this.state.formErrors[
                                                                      'RejectionReason'
                                                                  ]
                                                                : null
                                                        }
                                                        multiline
                                                        numberOfLines={2}
                                                        type="text"
                                                        value={
                                                            workflow.isReadOnly
                                                                ? bapi70CustData &&
                                                                  bapi70CustData.RejectionReason
                                                                : this.state.formData
                                                                ? this.state.formData[
                                                                      'RejectionReason'
                                                                  ]
                                                                : null
                                                        }
                                                        variant={
                                                            workflow.isReadOnly
                                                                ? 'outline'
                                                                : 'solid'
                                                        }
                                                        inline={
                                                            workflow.isReadOnly
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                </Box>
                                            )}
                                        <Box
                                            width={1 / 2}
                                            mx="auto"
                                            alignItems="center"
                                        />
                                    </Box>
                                </Box>
                            ) : (
                                <FilesList
                                    title="NEW ATTACHMENTS"
                                    formErrors={this.state.fileErrors}
                                    files={selectedFilesIds.map(
                                        (id) => selectedFiles[id]
                                    )}
                                    onChange={(value, id) => {
                                        this.setState({
                                            selectedFiles: {
                                                ...selectedFiles,
                                                [id]: {
                                                    ...selectedFiles[id],
                                                    DocumentType: parseInt(value),
                                                },
                                            },
                                        });
                                    }}
                                    onRemove={(value, id) => this.removeFile(id)}
                                />
                            )}
                        </Box>
                    </Box>

                    <Box {...showButtons}>
                        <Flex
                            justifyEnd
                            alignCenter
                            style={{
                                paddingTop: 15,
                                flexDirection: 'row',
                                alignItems: 'center',
                                paddingLeft: 10,
                                paddingRight: 15,
                                marginTop: 20,
                                marginBottom: 10,
                                marginHorizontal: 25,
                            }}>
                            <label htmlFor="file-upload" className="custom-file-upload">
                                <MaterialIcons
                                    name="attach-file"
                                    size={18}
                                    color="#fff"
                                />
                            </label>
                            <input
                                id="file-upload"
                                type="file"
                                key={this.state.fileinputkey}
                                onChange={this.selectFiles}
                                multiple
                            />
                            <Button
                                onPress={(event) =>
                                    this.setState({ isReject: false, modalVisible: true })
                                }
                                title="Approve"
                            />
                            <Button
                                title="Reject"
                                onPress={(event) =>
                                    this.setState({ isReject: true, modalVisible: true })
                                }
                            />
                        </Flex>
                    </Box>
                </View>
            </ScrollView>
        );
    }
}

class Default extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const props = this.props;

        return (
            <DimensionAware
                render={(dimensions) => (
                    <Page
                        {...{
                            ...props,
                            width: getWindowWidth(dimensions),
                            height: getWindowHeight(dimensions),
                            marginBottom: 25,
                        }}
                    />
                )}
            />
        );
    }
}

const mapStateToProps = ({
    workflows,
    myTasks,
    customer,
    updateFlow,
    toasts,
    staticData,
}) => {
    const {
        bapi70CustData,
        deltas,
        denormalizedDeltas,
        fetching: fetchingCustomer,
    } = customer;

    const { fetching, alert } = myTasks;
    const { fetching: fetchingUpdateflow } = updateFlow;

    const {
        fetchingfnGroupData,
        statusBarData,
        functionalGroupDetails,
        TasksStatusByTeamId,
        fetchingStatusBar,
    } = workflows;
    const { data, fetching: staticFetching } = staticData;
    return {
        fetching:
            fetching ||
            staticFetching ||
            fetchingUpdateflow ||
            fetchingStatusBar ||
            fetchingfnGroupData ||
            fetchingCustomer,
        alert,
        statusBarData,
        functionalGroupDetails,
        TasksStatusByTeamId,
        bapi70CustData,
        deltas,
        denormalizedDeltas,
        toasts,
        staticData: data,
    };
};

export default connect(mapStateToProps, {
    saveApolloUpdateMyTaskPricing,
    getFunctionalGroupData,
    getStatusBarData,
    getCustomerFromSAP,
    removeMessage,
    getStaticRequest,
})(Default);
