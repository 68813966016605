import React from 'react';
import { Image } from 'react-native';
import Checkbox from '@material-ui/core/Checkbox';
import MaUTable from '@material-ui/core/Table';
import PropTypes from 'prop-types';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from './TablePaginationActions';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import TableToolbar from './TableToolbar';
import matchSorter from 'match-sorter';
import {
    useGlobalFilter,
    useSortBy,
    useTable,
    usePagination,
    useFilters,
    useGroupBy,
    useExpanded,
    useRowSelect,
} from 'react-table';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Text, TouchableOpacity } from 'react-native';
import { Feather } from '@expo/vector-icons';
import ReactTooltip from 'react-tooltip';
import FilesList from './FilesList';
import { FontAwesome } from '@expo/vector-icons';
import { CheckBoxItem } from './CheckBoxItem';
import { Box, Flex, Button } from './common';
import { MaterialCommunityIcons } from '@expo/vector-icons';

const CssTextField = withStyles({
    root: {
        '& .MuiInput-underline:before': {
            borderBottomColor: 'transparent',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'rgb(224, 224, 224)',
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            borderBottomColor: 'transparent',
        },
        '& .MuiInput-underline.Mui-error:after': {
            transform: 'scaleX(1)',
            borderBottomColor: '#f44336',
        },

        '& input:valid + fieldset': {
            borderColor: 'green',
            borderWidth: 2,
        },
        '& input:invalid + fieldset': {
            borderColor: 'red',
            borderWidth: 2,
        },
        '& input:focus': {
            backgroundColor: '#fff',
        },
    },
})(TextField);

const CSSTableRow = withStyles({
    root: {
        '&:hover td': {
            backgroundColor: '#f1f2f6',
        },
    },
})(TableRow);

// @ts-ignore
const StyledTableCell = withStyles({
    head: {
        backgroundColor: '#DDF4FA',
        color: '#0b3074',
        fontFamily: 'Poppins',
    },
    body: {
        fontSize: 14,
    },
})(TableCell);

const IndeterminateCheckbox = React.forwardRef(({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
        <>
            <Checkbox color="primary" ref={resolvedRef} {...rest} />
        </>
    );
});

const inputStyle = {
    padding: 0,
    margin: 0,
    border: 0,
    background: 'transparent',
};

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    margin: {},
}));

// Define a default UI for filtering
function DefaultColumnFilter({
    ApolloPartnersRoleType,
    column: { filterValue, preFilteredRows, setFilter, ...rest },
}) {
    const count = preFilteredRows.length;
    if (rest.Header == 'Partner Role') {
        return (
            <select
                style={{ width: '100%' }}
                value={filterValue != null ? filterValue : ''}
                onChange={(e) => {
                    setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
                }}>
                <option key="" value="">
                    Choose Role...
                </option>
                {ApolloPartnersRoleType &&
                    ApolloPartnersRoleType.map((role, index) => (
                        <option key={role.Id} value={role.Id}>
                            {role.Description}
                        </option>
                    ))}
            </select>
        );
    } else {
        return (
            <input
                value={filterValue || ''}
                onChange={(e) => {
                    setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
                }}
                placeholder={`Search ${count} records...`}
            />
        );
    }
}

function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

// Create an editable cell renderer
const EditableCell = ({
    cell,
    row: { index },
    column: { id },
    updateMyData, // This is a custom function that we supplied to our table instance
    editable,
    data,
    readOnly,
    systemFields,
    ApolloPartnersRoleType,
    removePartner,
    hasIsDefaultSetInGrid,
    isSoldToPresent,
    isEditAllowed,
    showWarning,
}) => {
    const classes = useStyles();
    const SelectHeader = cell.column.Header;

    const defaultValidate = (val) => ({
        error: val && val.length <= 0,
        helperText: `${column.Header} is required`,
    });

    let { value: initialValue, column, row } = cell;
    let { multiSelect, inputType = 'text', validate = defaultValidate } = column;
    let rolesmap = [
        { id: '1', value: '1', desc: 'soldto' },
        { id: '2', value: '2', desc: 'shipto' },
        { id: '3', value: '3', desc: 'billto' },
        { id: '4', value: '4', desc: 'payer' },
    ];
    // We need to keep and update the state of the cell normally
    const [value, setValue] = React.useState(initialValue);
    const [error, setError] = React.useState(false);
    const [focus, setFocus] = React.useState(false);
    const [role, setRole] = React.useState(1);
    const [defaultPartnerRole, setDefaultPartner] = React.useState([]);
    // We'll only update the external data when the input is blurred
    const onBlur = () => {
        // console.log('comseshere========',value,index,row,id);
        setError(validate(value));
        setFocus(false);
        updateMyData(index, row, id, value);
    };

    const onChange = (e) => {
        // console.log('testhereonchange');
        // setError(validate(e.target.value));
        if (e.target.type === 'checkbox') {
            setValue(e.target.checked);
            // if(defaultPartnerRole.length>0){
            //     defaultPartnerRole.forEach((data,index)=>{
            //         if(index==e.target.id){
            //             data=true;
            //         }else{
            //             data=false;
            //         }
            //     })
            // }else{
            //     defaultPartnerRole[e.target.id]=e.target.checked;
            // }
            // setDefaultPartner(defaultPartnerRole)
            updateMyData(index, row, id, e.target.checked);
        } else {
            setValue(e.target.value);
        }
        // updateMyData(index, id, value);
    };
    const onRemovePartnerChange = (value) => {
        console.log('testhereonchange');
        console.log(!value, value);
        updateMyData(index, row, id, !value);
        setValue(!value);
    };
    const onRoleSelect = (e) => {
        console.log(e.target.value);
        setRole(e.target.value);
    };

    const rowFieldData = (cellData, fieldName) => {
        return cellData && cellData.original && cellData.original.SearchedSapPartner
            ? cellData.original.SearchedSapPartner[fieldName]
            : cellData.original[fieldName]
            ? cellData.original[fieldName]
            : value;
    };

    const getFontColor = () => {
        return row.original.DeleteClicked
            ? 'grey'
            : row.original.PartnersOperation == 'Remove'
            ? 'red'
            : row.original.PartnersOperation == 'Add' ||
              row.original.PartnersOperation == 'Edit'
            ? 'green'
            : '#000000de';
    };

    if (SelectHeader === 'Customer Or Workflow Number') {
        let partneraddressobj = row.original.SearchedApolloPartner || {};
        let custwfval = '';
        Object.keys(partneraddressobj).forEach(function (key) {
            custwfval = custwfval + ' \n' + key + ':' + partneraddressobj[key] + ' \n ';
        });
        // let custwfval= row ? JSON.stringify(row.original.SearchedApolloPartner) : ''
        return (
            <>
                <div
                    className="bar"
                    data-tip
                    data-for={index + 'ApolloPartnerData'}
                    style={{
                        width: '11%',
                        color: getFontColor(),
                    }}>
                    <center style={{ marginTop: '-10px' }}>
                        <p
                            style={{
                                marginLeft: '20%',
                                fontSize: 15,
                            }}>
                            {(systemFields &&
                                systemFields.SapPartners &&
                                systemFields.SapPartners.length > 0 &&
                                systemFields.SapPartners[0].WorkflowOrCustomerNumber) ||
                                systemFields.WorkflowOrCustomerNumber}
                        </p>
                    </center>
                </div>
                {/* <ReactTooltip
                    id={index + 'ApolloPartnerData'}
                    place="right"
                    effect="solid"
                    style={{ maxWidth: '30%' }}>
                    {(systemFields &&
                        systemFields.SapPartners &&
                        systemFields.SapPartners.length > 0 &&
                        systemFields.SapPartners[0].WorkflowOrCustomerNumber) ||
                        systemFields.WorkflowOrCustomerNumber}
                </ReactTooltip> */}
            </>
        );
    }

    // console.log('readOnly', readOnly);
    if (SelectHeader === 'Default Partner' && !readOnly) {
        //validation to enable disable if customer has a default partner set to true
        let disableAllNonDefaultPartner = hasIsDefaultSetInGrid
            ? row.original.IsDefaultPartner === true
                ? false
                : true
            : false;
        let exceptionRole = [1, 5, 6];
        let index = data.findIndex((x) => {
            return (
                // x.WorkflowOrCustomerNumber == row.original.WorkflowOrCustomerNumber &&
                x.PartnerFunctionTypeId == row.original.PartnerFunctionTypeId &&
                (x.IsDefault || x.IsDefaultPartner)
            );
        });

        return (
            <>
                {row &&
                row.original.IsEditable != false &&
                !exceptionRole.includes(parseInt(row.original.PartnerFunctionTypeId)) &&
                row.original.PartnersOperation != 'Remove' &&
                isEditAllowed ? (
                    // <Select
                    //     value={value != null ? value : ''}
                    //     defaultValue={value != null ? value : ''}
                    //     onChange={onChange}
                    //     onBlur={onBlur}
                    //     onFocus={() => setFocus(true)}>
                    //     <option key="d1" value={true}>
                    //         {' '}
                    //         True{' '}
                    //     </option>
                    //     <option key="d2" value={false}>
                    //         {' '}
                    //         False{' '}
                    //     </option>
                    // </Select>
                    <>
                        <input
                            type="checkbox"
                            id={row.id}
                            checked={row && row.original.IsDefaultPartner}
                            onChange={onChange}
                            disabled={
                                // disableAllNonDefaultPartner ||
                                (row &&
                                    row.original &&
                                    !row.original.PartnerFunctionTypeId) ||
                                (index >= 0 && index != row.id) ||
                                cell.row.original.DeleteClicked ||
                                row.original.UnPartner
                            }
                        />
                        {
                            <Text>
                                {row && row.original.IsDefaultPartner && '( Default )'}
                            </Text>
                        }
                    </>
                ) : (
                    <Text style={{ color: getFontColor(), textTransform: 'capitalize' }}>
                        {value ? value.toString() : ''}
                    </Text>
                )}
            </>
        );
    }

    const deletePartnerHandler = (row) => {
        removePartner(row);
    };

    const getPartnerRole = (partnerValue) => {
        let roleValue = null;
        if (ApolloPartnersRoleType && ApolloPartnersRoleType.length > 0)
            ApolloPartnersRoleType.some((roleValues) => {
                if (roleValues.Id == partnerValue) {
                    roleValue = roleValues.Description;
                    return true;
                }
            });
        return roleValue;
    };

    if (SelectHeader === 'MDM Number') {
        return (
            <Text style={{ color: getFontColor(), textTransform: 'capitalize' }}>
                {value
                    ? value
                    : row && row.original.MdmNumber
                    ? row.original.MdmNumber
                    : row &&
                      row.original.SearchedSapPartner &&
                      row.original.SearchedSapPartner.MdmNumber
                    ? row.original.SearchedSapPartner.MdmNumber
                    : ''}
            </Text>
        );
    }

    if (SelectHeader === 'Partner Role') {
        return (
            <div style={{ minWidth: 100 }}>
                {row && row.original.newRow === true ? (
                    <Select
                        style={{ width: '100%' }}
                        value={value != null ? value : ''}
                        onChange={onChange}
                        onBlur={onBlur}
                        disabled={
                            row.original.DeleteClicked ||
                            !row.original.CustomerNumberOrWorkflowNumber
                        }
                        onFocus={() => setFocus(true)}>
                        {ApolloPartnersRoleType &&
                            ApolloPartnersRoleType.map((role, index) => {
                                if (
                                    (isSoldToPresent ? role.Id != 1 : true) &&
                                    role.Id != 5 &&
                                    role.Id != 6 &&
                                    role.ValidationData &&
                                    role.ValidationData.length > 0 &&
                                    role.ValidationData[0].RoleTypeIds &&
                                    role.ValidationData[0].RoleTypeIds.includes(
                                        parseInt(row.original.PartnerCustomerRoleTypeId)
                                    )
                                )
                                    return (
                                        <option key={role.Id} value={role.Id}>
                                            {role.Description}
                                        </option>
                                    );
                            })}
                    </Select>
                ) : (
                    <Text style={{ color: getFontColor(), width: '100%' }}>
                        {getPartnerRole(row.original.PartnerFunctionTypeId)}
                    </Text>
                )}
            </div>
        );
    }

    if (SelectHeader === 'Customer Name') {
        // console.log(row, '+++++++++++++++++++++++++++++', data);
        return (
            <>
                <Text style={{ color: getFontColor() }}>
                    {rowFieldData(row, 'PartnerCustomerName')}
                </Text>
            </>
        );
    }
    if (SelectHeader === 'Partner Number') {
        if (row && row.original.newRow === true) {
            return (
                <div
                    className={classes.root}
                    onPaste={(e) => {
                        if (focus) e.stopPropagation();
                    }}>
                    <CssTextField
                        error={error.error}
                        helperText={error.error ? error.helperText : ''}
                        margin="dense"
                        type={inputType}
                        fullWidth
                        value={value}
                        onChange={onChange}
                        placeholder={error.error ? null : `Enter ${column.Header}`}
                        onBlur={onBlur}
                        inputProps={{
                            style: {
                                color:
                                    row.original.UnPartner === 'True'
                                        ? 'red'
                                        : '#000000de',
                            },
                        }}
                    />
                </div>
            );
        } else {
            return (
                <>
                    <Text style={{ color: getFontColor() }}>
                        {row.original.CustomerNumberOrWorkflowNumber
                            ? row.original.CustomerNumberOrWorkflowNumber
                            : row.original.PartnerNumber
                            ? row.original.PartnerNumber
                            : ''}
                    </Text>
                </>
            );
        }
    }
    if (SelectHeader === 'Customer Address') {
        return (
            <>
                <Text style={{ color: getFontColor() }}>
                    {rowFieldData(row, 'PartnerCustomerAddress')}
                </Text>
            </>
        );
    }

    if (SelectHeader == 'Partner Operation') {
        let partnerOperation = row.original.PartnersOperation;
        if (
            systemFields &&
            systemFields.WorkflowTaskStateTypeId != 2 &&
            partnerOperation
        ) {
            partnerOperation =
                partnerOperation == 'Remove'
                    ? partnerOperation + 'd'
                    : partnerOperation + 'ed';
        }
        return (
            <div style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <Text style={{ color: getFontColor() }}>
                    {partnerOperation ? partnerOperation + '  ' : ''}
                </Text>{' '}
                {row &&
                    (row.original.newRow || row.original.PartnersOperation == 'Add') &&
                    isEditAllowed && (
                        <Tooltip title="Delete" aria-label="delete">
                            {/* <IconButton onClick={() => deletePartnerHandler(row)}> */}
                            {/* <Image
                                resizeMode="contain"
                                style={{ height: 18, width: 18 }}
                                source={require('../../assets/icons/trashIcon.png')}
                            /> */}
                            <Text onClick={() => deletePartnerHandler(row)}>Cancel</Text>
                            {/* </IconButton> */}
                        </Tooltip>
                    )}
            </div>
        );
    }

    if (SelectHeader === 'Remove Partner') {
        return (
            <>
                {row &&
                row.original.PartnersOperation != 'Add' &&
                row.original.IsEditable != false &&
                !row.original.IsDefaultPartner &&
                isEditAllowed &&
                !row.original.newRow &&
                row.original.PartnerFunctionTypeId != 5 &&
                row.original.PartnerFunctionTypeId != 6 &&
                // isSoldToPresent ||
                row.original.PartnerFunctionTypeId != 1 ? (
                    <div
                        style={{
                            alignContent: 'center',
                            alignSelf: 'center',
                            width: '100%',
                        }}>
                        {/* <Select
                            value={value != null ? value : false}
                            onChange={onChange}
                            onBlur={onBlur}
                            disabled={row.original.DeleteClicked}
                            onFocus={() => setFocus(true)}
                            style={{ color: getFontColor() }}>
                            <option key="d1" value={true}>
                                {' '}
                                True{' '}
                            </option>
                            <option key="d2" value={false}>
                                {' '}
                                False{' '}
                            </option>
                        </Select> */}
                        {!row.original.UnPartner ? (
                            <>
                                <Image
                                    resizeMode="contain"
                                    style={{
                                        height: 18,
                                        width: 18,
                                        cursor: 'pointer',
                                    }}
                                    id={'RemovePartner'}
                                    source={require('../../assets/icons/trashIcon.png')}
                                    onClick={() => {
                                        onRemovePartnerChange(value);
                                    }}
                                />
                                {/* <Text>Remove</Text> */}
                            </>
                        ) : (
                            <>
                                <MaterialCommunityIcons
                                    name="undo-variant"
                                    size={24}
                                    color="#10206c"
                                    style={{ cursor: 'pointer' }}
                                    id={'RemovePartner'}
                                    onClick={() => {
                                        onRemovePartnerChange(value);
                                    }}
                                />
                                {/* <Text>Undo</Text> */}
                            </>
                        )}
                        {/* {value ?
                            <Text id="RemovePartner" onClick={(e) => { console.log(e.target.Id) }}>Undo</Text> :
                            <IconButton
                                id="RemovePartner"
                                onClick={(e) =>
                                    console.log(e.target.Id)
                                }>
                                <Image
                                id="RemovePartner"
                                    resizeMode="contain"
                                    style={{ height: 18, width: 18 }}
                                    source={require('../../assets/icons/trashIcon.png')}
                                />
                            </IconButton>} */}
                    </div>
                ) : (
                    <Text style={{ color: getFontColor(), textTransform: 'capitalize' }}>
                        {value ? value.toString() : ''}
                    </Text>
                )}
            </>
        );
    }

    // If the initialValue is changed externall, sync it up with our state
    React.useEffect(() => {
        setError(validate(initialValue));
        setValue(initialValue);
        // setDefaultPartner(initialValue)
    }, [initialValue]);

    React.useEffect(() => {
        // setDefaultPartner(defaultPartnerRole)
    }, [defaultPartnerRole]);

    if (!editable) {
        return `${initialValue ? initialValue : ''}`;
    }

    return (
        <div
            className={classes.root}
            onPaste={(e) => {
                if (focus) e.stopPropagation();
            }}>
            <CssTextField
                error={error.error}
                helperText={error.error ? error.helperText : ''}
                margin="dense"
                type={inputType}
                fullWidth
                value={value}
                onChange={onChange}
                placeholder={error.error ? null : `Enter ${column.Header}`}
                onBlur={onBlur}
                inputProps={{
                    style: {
                        color: row.original.UnPartner === 'True' ? 'red' : '#000000de',
                    },
                }}
            />
        </div>
    );
};

EditableCell.propTypes = {
    cell: PropTypes.shape({
        value: PropTypes.any.isRequired,
    }),
    row: PropTypes.shape({
        index: PropTypes.number.isRequired,
    }),
    column: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }),
    updateMyData: PropTypes.func.isRequired,
    systemFields: PropTypes.shape({
        value: PropTypes.any.isRequired,
    }),
};

function GetIEVersion() {
    var sAgent = window.navigator.userAgent;
    var Idx = sAgent.indexOf('MSIE');

    // If IE, return version number.
    if (Idx > 0) return parseInt(sAgent.substring(Idx + 5, sAgent.indexOf('.', Idx)));
    // If IE 11 then look for Updated user agent string.
    else if (!!navigator.userAgent.match(/Trident\/7\./)) return 11;
    else return 0; //It is not IE
}

// Set our editable cell renderer as the default Cell renderer

const MyTaskPartnerEnhancedTable = ({
    columns,
    data,
    setData,
    updateMyData,
    openModal,
    selectRowHandler,
    skipPageReset,
    addNewPartnerData,
    removePartner,
    readOnly,
    systemFields,
    ApolloPartnersRoleType,
    hasIsDefaultSetInGrid,
    showWarning,
    isSoldToPresent,
    isEditAllowed,
}) => {
    var isIE = GetIEVersion() > 0 ? true : false;
    const filterTypes = React.useMemo(
        () => ({
            // Add a new fuzzyTextFilterFn filter type.
            fuzzyText: fuzzyTextFilterFn,
            // Or, override the default text filter to use
            // "startWith"
            text: (rows, id, filterValue) => {
                return rows.filter((row) => {
                    const rowValue = row.values[id];
                    return rowValue !== undefined
                        ? String(rowValue)
                              .toLowerCase()
                              .startsWith(String(filterValue).toLowerCase())
                        : true;
                });
            },
        }),
        []
    );

    // console.log(data, '========== new data check');

    const defaultColumn = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter,
            // And also our default editable cell
            Cell: EditableCell,
        }),
        []
    );

    const deletePartnerHandler = (row) => {
        removePartner(row);
    };

    React.useEffect(() => setPageSize(1000), []);
    const [addButton, setAddButton] = React.useState(false);

    const {
        getTableProps,
        headerGroups,
        prepareRow,
        visibleColumns = [{ Header: 'PartnerFunctionTypeId', isVisible: false }],
        page,
        gotoPage,
        setPageSize,
        selectedFlatRows,
        preGlobalFilteredRows,
        setGlobalFilter,
        state: {
            pageIndex,
            pageSize,
            selectedRowIds,
            globalFilter,
            sortBy,
            groupBy,
            expanded,
            filters,
        },
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            filterTypes,
            autoResetPage: !skipPageReset,
            autoResetSelectedRows: !skipPageReset,
            disableMultiSort: true,
            // updateMyData isn't part of the API, but
            // anything we put into these options will
            // automatically be available on the instance.
            // That way we can call this function from our
            // cell renderer!
            openModal,
            updateMyData,
            readOnly,
            selectRowHandler,
            systemFields,
            ApolloPartnersRoleType,
            removePartner,
            hasIsDefaultSetInGrid,
            showWarning,
            isSoldToPresent,
            isEditAllowed,
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect
        // (hooks) => {
        //     !isIE &&
        //         hooks.allColumns.push((columns) => [
        //             // Let's make a column for selection

        //             {
        //                 id: 'selection',
        //                 // The header can use the table's getToggleAllRowsSelectedProps method
        //                 // to render a checkbox.  Pagination is a problem since this will select all
        //                 // rows even though not all rows are on the current page.  The solution should
        //                 // be server side pagination.  For one, the clients should not download all
        //                 // rows in most cases.  The client should only download data for the current page.
        //                 // In that case, getToggleAllRowsSelectedProps works fine.
        //                 Header: ({ getToggleAllRowsSelectedProps }) => (
        //                     <div
        //                         style={{
        //                             display: 'flex',
        //                             justifyContent: 'center',
        //                             flex: 1,
        //                             width: '100%',
        //                             padding: 0,
        //                         }}>
        //                         {/* <IndeterminateCheckbox
        //                             {...getToggleAllRowsSelectedProps()}
        //                         /> */}
        //                         {!readOnly && isEditAllowed && (
        //                             <Tooltip title="Add Partners" aria-label="add">
        //                                 <IconButton
        //                                     onClick={() =>
        //                                         addPartnersHandler({
        //                                             CustomerNumberOrWorkflowNumber: '',
        //                                             PartnerCustomerNumber: '',
        //                                             MdmNumber: '',
        //                                             PartnerCustomerName: '',
        //                                             PartnerCustomerAddress: '',
        //                                             PartnersOperation: 'Add',
        //                                             PartnerFunctionRole: '',
        //                                             PartnerFunctionTypeId: '',
        //                                             IsDefault: false,
        //                                             newRow: true,
        //                                             UnPartner: false,
        //                                             IsDefaultPartner: false,
        //                                         })
        //                                     }
        //                                     disabled={addButton}>
        //                                     <Image
        //                                         resizeMode="contain"
        //                                         style={{ height: 18, width: 18 }}
        //                                         source={require('../../assets/icons/addIcon.png')}
        //                                     />
        //                                 </IconButton>
        //                             </Tooltip>
        //                         )}

        //                         {/* <Tooltip title="Delete" aria-label="delete">
        //                         <IconButton
        //                             onClick={() =>
        //                                 deletePartnerHandler()
        //                             }>
        //                             <Image
        //                                 resizeMode="contain"
        //                                 style={{ height: 18, width: 18 }}
        //                                 source={require('../../assets/icons/trashIcon.png')}
        //                             />
        //                         </IconButton>
        //                     </Tooltip> */}
        //                     </div>
        //                 ),
        //                 // The cell can use the individual row's getToggleRowSelectedProps method
        //                 // to the render a checkbox
        //                 Cell: ({ row }) => (
        //                     <div>
        //                         {!readOnly && (
        //                             <div style={{ display: 'none' }}>
        //                                 <IndeterminateCheckbox
        //                                     {...row.getToggleRowSelectedProps()}
        //                                     row={row}
        //                                     style={{ display: 'none' }}
        //                                     disabled={row.original.IsEditable === false}
        //                                     onPress={() =>
        //                                         selectRowHandler(
        //                                             selectedFlatRows,
        //                                             selectedRowIds
        //                                         )
        //                                     }
        //                                 />
        //                                 <Tooltip title="Delete" aria-label="delete">
        //                                     <IconButton
        //                                         onClick={() => {
        //                                             deletePartnerHandler(row);
        //                                         }}>
        //                                         {row.original.PartnersOperation && (
        //                                             <>
        //                                                 {!row.original.DeleteClicked ? (
        //                                                     <Image
        //                                                         resizeMode="contain"
        //                                                         style={{
        //                                                             height: 18,
        //                                                             width: 18,
        //                                                         }}
        //                                                         source={require('../../assets/icons/trashIcon.png')}
        //                                                     />
        //                                                 ) : (
        //                                                     <MaterialCommunityIcons
        //                                                         name="undo-variant"
        //                                                         size={24}
        //                                                         color="#10206c"
        //                                                     />
        //                                                 )}
        //                                             </>
        //                                         )}
        //                                     </IconButton>
        //                                 </Tooltip>
        //                             </div>
        //                         )}
        //                     </div>
        //                 ),
        //             },
        //             ...columns,
        //         ]);
        // }
    );

    // React.useEffect(() => {
    //     selectRowHandler(selectedFlatRows, selectedRowIds);
    // }, [selectedFlatRows]);

    const addPartnersHandler = (partner) => {
        // event.preventDefault();
        addNewPartnerData(partner);
        setAddButton(true);
    };

    const handleChangePage = (event, newPage) => {
        gotoPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPageSize(Number(event.target.value));
    };

    const removeByIndexs = (array, indexs) => array.filter((_, i) => !indexs.includes(i));

    const deleteUserHandler = (event) => {
        event.preventDefault();
        const newData = removeByIndexs(
            data,
            Object.keys(selectedRowIds).map((x) => parseInt(x, 10))
        );
        setData(newData);
    };

    const addUserHandler = (user) => {
        const newData = [user].concat(data);
        setData(newData);
    };

    const getCellFontColor = (row) => {
        return row.original.DeleteClicked
            ? 'grey'
            : row.original.PartnersOperation == 'Remove'
            ? 'red'
            : row.original.PartnersOperation == 'Add' ||
              row.original.PartnersOperation == 'Edit'
            ? 'green'
            : '#000000de';
    };

    // Render the UI for your table
    return (
        <>
            <TableContainer
                style={{
                    border: '1.5px solid #234385',
                    overflow: 'auto',
                    maxHeight: '900px',
                }}>
                {/*<TableToolbar*/}
                {/*  numSelected={Object.keys(selectedRowIds).length}*/}
                {/*  deleteUserHandler={deleteUserHandler}*/}
                {/*  addUserHandler={addUserHandler}*/}
                {/*  preGlobalFilteredRows={preGlobalFilteredRows}*/}
                {/*  setGlobalFilter={setGlobalFilter}*/}
                {/*  globalFilter={globalFilter}*/}
                {/*/>*/}
                <MaUTable {...getTableProps()}>
                    <TableHead>
                        {headerGroups.map((headerGroup) => (
                            <TableRow {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column, index) => (
                                    <StyledTableCell
                                        key={column.id}
                                        style={{
                                            borderBottom:
                                                '1px solid rgba(130, 214, 216, 1)',
                                            borderLeft:
                                                '1px solid rgba(130, 214, 216, 1)',
                                            padding: '7px 5px 7px 5px',
                                        }}>
                                        <div
                                            {...(column.id === 'selection'
                                                ? column.getHeaderProps()
                                                : column.getHeaderProps(
                                                      column.getSortByToggleProps()
                                                  ))}>
                                            {column.render('Header')}
                                            {column.id !== 'selection' ? (
                                                <TableSortLabel
                                                    active={column.isSorted}
                                                    // react-table has a unsorted state which is not treated here
                                                    direction={
                                                        column.isSortedDesc
                                                            ? 'desc'
                                                            : 'asc'
                                                    }
                                                />
                                            ) : null}
                                        </div>
                                        <div>
                                            {column.canFilter
                                                ? column.render('Filter')
                                                : null}
                                        </div>
                                    </StyledTableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableHead>
                    <TableBody>
                        {page.map((row, i) => {
                            prepareRow(row);
                            return (
                                <CSSTableRow
                                    style={{
                                        backgroundColor:
                                            i % 2 === 0 ? '#ffffff' : '#f6f6f6',
                                        textAlign: 'center',
                                        // borderRight: '2px solid rgba(224, 224, 224, 1)',
                                        // borderBottom: '2px solid rgba(224, 224, 224, 1)'
                                    }}
                                    {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                        return (
                                            <TableCell
                                                {...cell.getCellProps()}
                                                style={{
                                                    borderLeft:
                                                        '1px solid rgba(130, 214, 216, 1)',
                                                    borderRight:
                                                        '1px solid rgba(130, 214, 216, 1)',
                                                    backgroundColor: cell.row.isSelected
                                                        ? '#f1f2f6'
                                                        : i % 2 === 0
                                                        ? '#ffffff'
                                                        : '#f5f5f5',
                                                    padding: '10px',
                                                    fontFamily: 'Poppins',
                                                    color:
                                                        cell.row.original
                                                            .PartnersOperation == 'Add' ||
                                                        cell.row.original
                                                            .PartnersOperation == 'Edit'
                                                            ? 'green'
                                                            : cell.row.original
                                                                  .UnPartner === 'True' ||
                                                              cell.row.original
                                                                  .PartnersOperation ==
                                                                  'Remove'
                                                            ? 'red'
                                                            : '#000000de',
                                                }}>
                                                {cell.render('Cell', {
                                                    editable: cell.row.original.newRow
                                                        ? cell.column.editable
                                                        : false,
                                                })}
                                            </TableCell>
                                        );
                                    })}
                                </CSSTableRow>
                            );
                        })}
                    </TableBody>
                </MaUTable>
            </TableContainer>

            {/*<TableFooter>*/}
            {/*<View style={{}}>*/}
            <MaUTable>
                <TableFooter style={{ display: '' }}>
                    <TableRow>
                        <TableCell colSpan={1}>
                            {showWarning && (
                                <Text style={{ color: 'red' }}>
                                    Duplicate/Redundant data are not allowed to be
                                    submitted and are removed automatically from the grid.
                                </Text>
                            )}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        {/* <TablePagination
                            rowsPerPageOptions={[
                                5,
                                10,
                                25,
                                { label: 'All', value: data.length },
                            ]}
                            colSpan={4}
                            align={'left'}
                            count={data.length}
                            rowsPerPage={pageSize}
                            page={pageIndex}
                            SelectProps={{
                                inputProps: {
                                    'aria-label': 'rows per page',
                                },
                                native: true,
                            }}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        /> */}
                        {!readOnly && isEditAllowed && (
                            <>
                                {/* <Tooltip title="Add Partners" aria-label="add"> */}
                                {/* <IconButton
                                    onClick={() =>
                                        addPartnersHandler({
                                            CustomerNumberOrWorkflowNumber: '',
                                            PartnerCustomerNumber: '',
                                            MdmNumber: '',
                                            PartnerCustomerName: '',
                                            PartnerCustomerAddress: '',
                                            PartnersOperation: 'Add',
                                            PartnerFunctionRole: '',
                                            PartnerFunctionTypeId: '',
                                            IsDefault: false,
                                            newRow: true,
                                            UnPartner: false,
                                            IsDefaultPartner: false,
                                        })
                                    }
                                    disabled={addButton}>
                                    <Image
                                        resizeMode="contain"
                                        style={{ height: 18, width: 18 }}
                                        source={require('../../assets/icons/addIcon.png')}
                                    />
                                    
                                </IconButton> */}
                                <TableCell colSpan={1} style={{ display: 'none' }}>
                                    <Button
                                        disabled={!isEditAllowed}
                                        onPress={() =>
                                            addPartnersHandler({
                                                CustomerNumberOrWorkflowNumber: '',
                                                PartnerCustomerNumber: '',
                                                MdmNumber: '',
                                                PartnerCustomerName: '',
                                                PartnerCustomerAddress: '',
                                                PartnersOperation: 'Add',
                                                PartnerFunctionRole: '',
                                                PartnerFunctionTypeId: '',
                                                IsDefault: false,
                                                newRow: true,
                                                UnPartner: false,
                                                IsDefaultPartner: false,
                                            })
                                        }
                                        style={{ maxWidth: '120px' }}
                                        title="Add Partner"
                                    />
                                </TableCell>

                                {/* </Tooltip> */}
                            </>
                        )}
                    </TableRow>
                </TableFooter>
            </MaUTable>

            {/*</View>*/}
            {/*</TableRow>*/}
            {/*</TableFooter>*/}
        </>
    );
};

MyTaskPartnerEnhancedTable.propTypes = {
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    updateMyData: PropTypes.func.isRequired,
    setData: PropTypes.func.isRequired,
    skipPageReset: PropTypes.bool.isRequired,
    openModal: PropTypes.func.isRequired,
    readOnly: PropTypes.bool.isRequired,
};

export default MyTaskPartnerEnhancedTable;
