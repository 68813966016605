import {
    GET_STATIC_DATA_REQUEST,
    GET_STATIC_DATA_SUCCESS,
    GET_STATIC_DATA_FAIL,
} from '../../constants/ActionTypes';

export const initialState = {
    error: null,
    fetching: false,
    data: null,
};

/* ------------- Selectors ------------- */

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_STATIC_DATA_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case GET_STATIC_DATA_SUCCESS:
            return {
                ...state,
                fetching: false,
                data: action.payload,
            };
        case GET_STATIC_DATA_FAIL:
            return {
                ...state,
                fetching: false,
            };
        default:
            return state;
    }
};
