/**
 * @prettier
 */

import React, { Component, Fragment, useState } from 'react';
import { ActivityIndicator } from 'react-native';
import { FontAwesome, MaterialIcons } from '@expo/vector-icons';
import { Box } from './common/Box.jsx';
import Text from './common/Text.jsx';
import { FormSelect, FormInput } from './form';
import { Link } from '../navigation/router';
import { ajaxPostRequest, endpoints } from '../appRedux/sagas/config';

const FileTypes = [
    'Choose from...',
    'License',
    'CreditApp',
    'PurchaseOrder',
    'Supporting',
];

function UploadFile({ fileData, error, onChange, onRemove, fileTypes }) {
    return (
        <Box display="flex" flexDirection="row" alignItems="center">
            <Box display="flex" flex="1" flexDirection="row" mb="-20px">
                <MaterialIcons name="description" size={25} color="#1D4289" />
                <Text
                    color="primary"
                    fontWeight={500}
                    fontFamily="Poppins"
                    fontSize="17px"
                    ml="10px">
                    {fileData.DocumentName}
                </Text>
            </Box>
            <Box display="flex" flex="1" flexDirection="row" alignItems="top">
                <FormSelect
                    error={error}
                    name="type"
                    required
                    onChange={onChange}
                    variant="solid">
                    {fileTypes.map((val, index) => (
                        <option value={index}>{val}</option>
                    ))}
                </FormSelect>
            </Box>
            <Box display="flex" flex="1" flexDirection="row" alignItems="flex-end">
                <RemoveIcon onPress={onRemove} />
            </Box>
        </Box>
    );
}

function DownloadFile({ fileData, onClick, fileTypes }) {
    const [downloading, setState] = useState(false);

    return (
        <Box
            display="flex"
            flexDirection="row"
            maxWidth="350px"
            alignItems="center"
            justifyContent="space-between">
            <Box display="flex" flexDirection="row" alignItems="center" mr="2%">
                {downloading && <ActivityIndicator />}
                <MaterialIcons
                    name="description"
                    size={25}
                    color="#1D4289"
                    style={{ paddingBottom: 5 }}
                />
                <Link
                    to="#"
                    style={{
                        fontFamily: 'Poppins',
                        fontSize: '17px',
                        ml: '10px',
                    }}
                    onClick={(e) => {
                        e.preventDefault();
                        setState(true);
                        ajaxPostRequest(endpoints.downloadDocument, {
                            UserId: localStorage.getItem('userId'),
                            S3ObjectKey: fileData.S3objectKey,
                        }).then(({ ResultData: resp }) => {
                            setState(false);
                            window.open(resp.PreSignedURL, '_blank');
                        });
                    }}>
                    {fileData.DocumentName}
                </Link>
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center ">
                <Text color="#58595B" fontFamily="Poppins" fontSize="17px" ml="10px">
                    {`: ${fileTypes[fileData.DocumentTypeId]}`}
                </Text>
            </Box>
        </Box>
    );
}

const RemoveIcon = ({ onPress }) => (
    <Box style={{ marginLeft: '40px', marginTop: '20px' }}>
        <FontAwesome.Button
            name="minus"
            size={15}
            color="#FFFFFF"
            backgroundColor="#264384"
            borderRadius={13}
            iconStyle={{ marginRight: 0, paddingHorizontal: 1 }}
            onPress={onPress}
        />
    </Box>
);

function FilesList({
    title = 'ATTACHMENTS',
    files = [],
    readOnly = false,
    formErrors,
    onChange,
    onClick,
    onRemove,
    fileTypes = FileTypes,
}) {
    if (files.length <= 0) return <Box />;
    return (
        <Fragment>
            <Text
                mt="42px"
                mb="20px"
                ml="2%"
                fontWeight="light"
                color="lightBlue"
                fontSize="24px">
                {title}
            </Text>
            <Box marginLeft="8%" flexDirection="row" justifyContent="center">
                <Box width={1 / 2} mx="auto" alignItems="center">
                    <Box display="flex" style={{ minWidth: '600px' }}>
                        {files.map((file) => (
                            <Box key={file.DocumentName}>
                                {readOnly ? (
                                    <DownloadFile
                                        fileData={file}
                                        onClick={onClick}
                                        fileTypes={fileTypes}
                                    />
                                ) : (
                                    <UploadFile
                                        fileTypes={fileTypes}
                                        error={
                                            formErrors
                                                ? formErrors[file.DocumentName]
                                                : null
                                        }
                                        fileData={file}
                                        onChange={(value, e) =>
                                            onChange(value, file.DocumentName)
                                        }
                                        onRemove={(value, e) =>
                                            onRemove(value, file.DocumentName)
                                        }
                                    />
                                )}
                            </Box>
                        ))}
                    </Box>
                </Box>
                <Box width={1 / 2} mx="auto" alignItems="center"></Box>
            </Box>
        </Fragment>
    );
}

export default FilesList;
