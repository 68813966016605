import * as moment from 'moment';
import React, { Component, Fragment } from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';

import { formatRequestorName } from '../appRedux/sagas/config';
import { Table, TableWrapper, Row, Rows, Cell } from '../components/table';
import { WorkflowTaskStateType } from '../constants/WorkflowEnums';
import { Flex, Button, Box, Text } from './common';
import { FormInput, FormSelect } from './form';

const Card = styled(Box)`
    cursor: pointer;
    background-color: #fafbfc;
    overflow: hidden;
    border-radius: 0.25rem;
    border-color: #ddd;
    padding: 0rem 0rem 0.4rem 0rem;
`;

function TaskNoteItem({
    TeamName,
    TaskNote,
    ModifiedDate,
    WorkflowTaskState,
    ApprovedRejectedUser,
}) {
    return (
        <Box>
            <Box
                py="10px"
                display="inline-flex"
                flexDirection="row"
                alignItems="center"
                bg="#f5f5f5"
                style={{
                    borderColor: '#ddd',
                    borderBottomWidth: '1px',
                }}>
                <Text ml="4%" fontWeight="500" color="lightBlue" fontSize="1rem">
                    {TeamName}:
                </Text>
                <Text
                    ml="2%"
                    color={WorkflowTaskState === 5 ? '#e74c3c' : '#239d56'}
                    fontWeight="500"
                    fontSize="0.9rem">
                    {WorkflowTaskState === 5
                        ? 'Rejected'
                        : WorkflowTaskState === 4
                        ? 'Approved'
                        : ''}{' '}
                    ~
                </Text>
                <Text
                    ml="2%"
                    color={WorkflowTaskState === 5 ? '#e74c3c' : '#239d56'}
                    fontWeight="500"
                    fontSize="0.9rem">
                    {formatRequestorName(ApprovedRejectedUser[0])}
                </Text>
            </Box>

            {TaskNote !== null && (
                <Text ml="5%" color="black" fontSize="17px" mt={2}>
                    {TaskNote}
                </Text>
            )}

            <Text ml="5%" color="#333333" mt={2} mb={1} fontSize="13px">
                <i>{moment(ModifiedDate).format('MM/DD/YYYY h:mm:ss a')}</i>
            </Text>
        </Box>
    );
}

function TaskNotes({ title = 'Notes', taskNotes = [] }) {
    if (taskNotes.length > 0)
        return (
            <>
                <Text
                    mt="42px"
                    mb="20px"
                    fontWeight="light"
                    color="lightBlue"
                    fontSize="24px">
                    {title}
                </Text>

                <Box flexDirection="row" justifyContent="center">
                    <Box ml="5%" width="100%">
                        {taskNotes.map((taskNote, index) => {
                            if (
                                taskNote.WorkflowTaskState === 5 ||
                                taskNote.WorkflowTaskState === 4
                            )
                                return (
                                    <Card
                                        my={3}
                                        style={{
                                            boxShadow:
                                                'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px',
                                        }}>
                                        <TaskNoteItem {...taskNote} />
                                    </Card>
                                );
                        })}
                    </Box>
                </Box>
            </>
        );
}

export default TaskNotes;
