/**
 * @prettier
 */

import React, { Component, Fragment } from 'react';
import { ActivityIndicator, View, StyleSheet, TouchableOpacity } from 'react-native';
import { connect } from 'react-redux';
import { Colors, StyledText } from '../../theme/Index';
import { FormInput } from '../../components/form';
import { Box, Flex, Text } from '../../components/common';
import Button from '../../components/common/Button';
import {
    getFunctionalGroupData,
    withDrawRequest,
    getStatusBarData,
    getMdmMappingMatrix,
} from '../../appRedux/actions';
import GlobalMdmFields from '../../components/GlobalMdmFields';
import {
    CategoryTypes,
    CompanyCodeType,
    DistributionChannelType,
    DivisionType,
    RoleType,
    PtmnRoleType,
    SalesOrgType,
    SystemType,
    OlympusRoleType,
} from '../../constants/WorkflowEnums.js';
import FilesList from '../../components/FilesList';
import { default as FileListText } from '../../components/FilesListTextInput';
import TaskNotes from '../../components/TaskNotes';
import { ajaxPostRequest } from '../../appRedux/sagas/config';
import FlashMessage from '../../components/FlashMessage';
import MultiColorProgressBar from '../../components/MultiColorProgressBar';
import { normalize, formatRequestorName } from '../../appRedux/sagas/config';
import BreadCrumbs from '../../components/BreadCrumbs';
import idx from 'idx';
import { fetchDuns } from '../../appRedux/actions/Customer.js';
import debounce from 'debounce';
import { getStaticRequest } from '../../appRedux/actions';
import Modal from 'modal-enhanced-react-native-web';
import {
    fetchCreateCustomerDropDownData,
    fetchOlympusFieldMapping,
} from '../../redux/DropDownDatas';
import JdeGlobalMdmFields from '../../components/JdeGlobalMdmFields';
import JDESystemFields from '../../containers/JDESystemFields';

class MyRequestsForm extends Component {
    state = {
        downloading: {},
        statusBarData: this.props.statusBarData,
        isUpdateScreenDetail: false,
        pageHeader: 'Create',
        isInternationalVersionVisible: false,
        teamRoleFieldMapping: {},
    };

    componentDidMount() {
        let { state: wf } = this.props.location;

        this.props.getStaticRequest([
            42,
            43,
            5,
            49,
            52,
            53,
            54,
            55,
            56,
            57,
            58,
            59,
            60,
            61,
            62,
            63,
            64,
            65,
            40,
            81,
            82,
            84,
            71,
            76,
            87,
            88,
            89,
            90,
            91,
            92,
            93,
            94,
            95,
            96,
            97,
            98,
            99,
            100,
            101,
            102,
            103,
            104,
            105,
            106,
            107,
            108,
            109,
            110,
            111,
            112,
            113,
            114,
            115,
            116,
            117,
            118,
            123,
        ]);
        let postJson = {
            workflowId: (wf, (_) => _.WorkflowIdDeltas.Purpose.UpdatedValue),
            fuctionalGroup: '',
            wfId: wf.WorkflowId,
            taskId: wf.TaskId,
            userId: localStorage.getItem('userId'),
        };

        if (
            (wf.WorkflowTypeName === 'Create' && wf.SystemTypeId === 6) ||
            (wf.SystemType === 'Salesforce' && wf.WorkflowType === 'CreateSalesforce')
        ) {
            postJson.fuctionalGroup = 'customermaster';
        }

        fetchOlympusFieldMapping().then((resp) => {
            const data = resp;
            this.setState({ teamRoleFieldMapping: data });
        });

        var wftype = wf.Type || wf.WorkflowType;

        if (wftype && wftype.toLowerCase().includes('create')) {
            this.props.getFunctionalGroupData(postJson);
        } else if (wftype === 'Other') {
            this.setState(
                {
                    pageHeader: 'Other',
                },
                () => this.props.getFunctionalGroupData(postJson)
            );
        } else if (
            wftype &&
            wftype.toLowerCase().includes('update') &&
            wf.IsGlobalUpdate
        ) {
            let jsonBody = {
                requestObj: {
                    MdmNumber: wf.MdmNumber,
                    WorkflowId: wf.WorkflowId,
                    taskId: wf.TaskId,
                    userId: localStorage.getItem('userId'),
                },
            };
            this.props.getMdmMappingMatrix(jsonBody);
            this.setState({
                isUpdateScreenDetail: true,
                pageHeader: 'Update Customer Global',
            });
        }

        this.props.getStatusBarData(postJson);
    }

    componentWillReceiveProps(newProps) {
        if (newProps.mdmcustomerdata !== this.props.mdmcustomerdata) {
            const globalMdmDetail =
                idx(newProps, (_) => _.mdmcustomerdata.MDMGlobalData) || {};
            const deltas = globalMdmDetail ? normalize(globalMdmDetail.Deltas || []) : [];

            this.props.fetchDuns({
                UserId: localStorage.getItem('userId'),
                DunsNumber: deltas['DunsNumber']
                    ? deltas['DunsNumber'].UpdatedValue
                    : globalMdmDetail.DunsNumber,
            });
        }

        if (newProps.statusBarData != this.props.statusBarData) {
            this.setState({
                statusBarData: newProps.statusBarData,
            });
        }
    }

    getStaticDropdownValues = (Id, dropdownList) => {
        let description = '';
        if (Id && dropdownList)
            dropdownList.some((data) => {
                if (data.Id === Id) {
                    description = data.Value + ' : ' + data.Description;
                    return true;
                }
            });
        // if (description.includes('/n') || description.includes(';')) {
        //     console.log('html string');
        // }
        return description;
    };

    getDropdownValues = (Id, dropdownList) => {
        let description = '';
        if (Id && dropdownList)
            dropdownList.some((data) => {
                if (data.Id == Id) {
                    description = data.Value + ' : ' + data.Description;
                    return true;
                }
            });
        // if (description.includes('/n') || description.includes(';')) {
        //     console.log('html string');
        // }
        return description;
    };
    formatUserName = (data) => {
        if (data) {
            let username = data.toLowerCase().split(' ');
            let refactoredUserName =
                this.capitalize(username[0]) + ' ' + this.capitalize(username[1]);
            return refactoredUserName;
        } else {
            return '';
        }
    };
    capitalize = (s) => {
        if (typeof s !== 'string') return '';
        return s.charAt(0).toUpperCase() + s.slice(1);
    };

    render() {
        const { downloading, isUpdateScreenDetail, teamRoleFieldMapping } = this.state;
        let { state: wf = {} } = this.props.location;

        const {
            functionalGroupDetails,
            fetching,
            mdmcustomerdata: {
                IsWithdrawAllowed: withDrawAction = false,
                MDMGlobalData: globalDetail = {},
                MDMMappingMatrix: mdmMappingMatrix = [],
                CreditData: mdmCreditData = [],
                WorkflowDocuments,
                TaskNoteData: taskNoteData,
                RequestorName: requestorName = '',
            },
            staticData,
        } = this.props;

        const {
            TaskNoteData,
            DocumentLocation,
            Customer,
            RequestorName,
            CustomerMaster: customerMaster = null,
        } = functionalGroupDetails;
        let Deltas = [],
            globalMdmDetail = {},
            files = [],
            tasknotes = [],
            withDrawEnabled = false;
        var requestor = this.formatUserName(RequestorName || requestorName);
        var signedUser = formatRequestorName(localStorage.getItem('userId'));

        var wftype = wf.Type || wf.WorkflowType;
        if (wftype && wftype.toLowerCase().includes('update')) {
            //for update global form
            globalMdmDetail = globalDetail;
            withDrawEnabled = signedUser === requestor ? withDrawAction : false;
            files = WorkflowDocuments || [];
            Deltas = globalMdmDetail ? normalize(globalMdmDetail.Deltas || []) : [];
            tasknotes = taskNoteData || [];
        } else {
            let canWithdraw = Customer && Customer.IsWithdrawAllowed;
            globalMdmDetail = Customer || {};
            withDrawEnabled = signedUser === requestor ? canWithdraw : false;
            files = DocumentLocation || [];
            tasknotes = TaskNoteData || [];
        }
        let workFlowTaskStatus = this.state.statusBarData;

        workFlowTaskStatus = workFlowTaskStatus.filter(function (item) {
            return item.WorkflowTaskStateTypeId != 4;
        });

        let teams = JSON.parse(localStorage.getItem('teamIds'));
        let isSearchTeamUser =
            (teams && teams.includes(20) && teams.length == 1) ||
            (teams && teams.includes(99) && teams.length == 1);
        // console.log(withDrawEnabled, !isSearchTeamUser);
        let withdrawToggle = withDrawEnabled && !isSearchTeamUser;

        const isPointman = globalMdmDetail && globalMdmDetail.SystemTypeId === 3;
        const isOlympus = globalMdmDetail && globalMdmDetail.SystemTypeId === 2;

        let CustomerMasterTeamFields = [];
        if (isOlympus) {
            CustomerMasterTeamFields =
                idx(teamRoleFieldMapping, (_) => _.OlympusCreateCustomerFields) || [];
        }
        const RoleTypeId = globalMdmDetail && globalMdmDetail.RoleTypeId;
        const SystemTypeId = globalMdmDetail && globalMdmDetail.SystemTypeId;
        const allowedFieldList = CustomerMasterTeamFields.filter((item) => {
            return item.RoleTypeId == RoleTypeId && item.SystemTypeId == SystemTypeId;
        });
        let allowedFields = undefined;
        if (allowedFieldList && allowedFieldList.length > 0) {
            allowedFields = allowedFieldList[0].Fields;
        }

        console.log(isUpdateScreenDetail, globalMdmDetail);

        if (this.props.fetching)
            return (
                <Box
                    display="flex"
                    flex={1}
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                    minHeight="650px">
                    {this.props.alert.display && (
                        <FlashMessage
                            bg={{
                                backgroundColor: this.props.alert.color || '#FFF',
                            }}
                            message={this.props.alert.message}
                            marginTop={this.props.error ? '100px' : '125px'}
                        />
                    )}
                    <ActivityIndicator size="large" />
                </Box>
            );
        return (
            <Box bg="#EFF3F6" display="block">
                {this.props.alert.display && (
                    <FlashMessage
                        bg={{
                            backgroundColor: this.props.alert.color || '#FFF',
                        }}
                        message={this.props.alert.message}
                        marginTop={this.props.error ? '100px' : '125px'}
                    />
                )}

                <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between">
                    {/* remove bread crumbs */}
                    {/*this.props.location.pathname !== '/' && (
                        <BreadCrumbs
                            title={'Search results screen2'}
                            currentPath={this.props.location.pathname}
                            links={this.props.location.pathname.split('/')}
                            blacklist={[]}
                        />
                    )*/}
                    <Text
                        style={{
                            fontWeight: '500',
                            fontSize: 24,
                            color: Colors.lightBlue,
                            marginTop: 25,
                            marginBottom: 20,
                            paddingLeft: 45,
                        }}>
                        MY REQUESTS - {this.state.pageHeader}{' '}
                        {globalMdmDetail.SystemTypeId === 6 ? '~ SalesForce' : ''}
                    </Text>
                    <Box
                        marginTop="25px"
                        flex={3 / 4}
                        flexDirection="row-reverse"
                        alignItems="flex-end">
                        <MultiColorProgressBar readings={this.state.statusBarData} />
                    </Box>
                </Box>

                <Box display="flex" flex={1} fullHeight mx="12%" mt={3} mb={5}>
                    <Box flexDirection="row" justifyContent="space-around" my={4}>
                        <FormInput
                            px="25px"
                            flex={1 / 4}
                            label="Workflow Title"
                            name="WorkflowTitle"
                            maxLength={40}
                            variant="outline"
                            type="text"
                            value={globalMdmDetail.WorkflowTitle}
                        />
                        <FormInput
                            px="25px"
                            flex={1 / 4}
                            label="Workflow Number"
                            name="workflow-number"
                            variant="outline"
                            type="text"
                            value={wf.WorkflowId}
                        />
                        {(wf.SystemRecordId || globalMdmDetail.SystemRecordId) && (
                            <FormInput
                                px="25px"
                                flex={1 / 4}
                                label="Customer Number"
                                name="CustomerNumber"
                                variant="outline"
                                type="text"
                                value={
                                    wf.SystemRecordId || globalMdmDetail.SystemRecordId
                                }
                            />
                        )}
                        {wf.MdmNumber && (
                            <TouchableOpacity
                                onPress={() =>
                                    this.props.history.push(
                                        `/search-results/${wf.MdmNumber}`
                                    )
                                }>
                                <FormInput
                                    px="25px"
                                    flex={1 / 4}
                                    label="MDM Number"
                                    name="mdm-number"
                                    variant="outline"
                                    type="text"
                                    value={wf.MdmNumber}
                                />
                            </TouchableOpacity>
                        )}
                    </Box>
                    {wf.WorkflowType != 'CreateOther' &&
                        !this.state.isInternationalVersionVisible && (
                            <>
                                {Customer && Customer.SystemTypeId == '5' ? (
                                    <JdeGlobalMdmFields
                                        formData={{
                                            ...globalMdmDetail,
                                            Category:
                                                CategoryTypes[
                                                    globalMdmDetail.CategoryTypeId - 1
                                                ],
                                            ...this.props.dunsFields,
                                        }}
                                        staticDropDownData={this.props.staticData}
                                        deltas={Deltas ? Deltas : {}}
                                        viewInternationalModal={() => {
                                            this.setState({
                                                isInternationalVersionVisible: true,
                                            });
                                        }}
                                        isOlympus={
                                            Customer && Customer.SystemTypeId === 2
                                        }
                                        readOnly
                                    />
                                ) : (
                                    <GlobalMdmFields
                                        formData={{
                                            ...globalMdmDetail,
                                            Category:
                                                CategoryTypes[
                                                    globalMdmDetail.CategoryTypeId - 1
                                                ],
                                            ...this.props.dunsFields,
                                        }}
                                        staticDropDownData={this.props.staticData}
                                        deltas={Deltas ? Deltas : {}}
                                        viewInternationalModal={() => {
                                            this.setState({
                                                isInternationalVersionVisible: true,
                                            });
                                        }}
                                        isOlympus={
                                            Customer && Customer.SystemTypeId === 2
                                        }
                                        readOnly
                                    />
                                )}
                            </>
                        )}
                    {((!isUpdateScreenDetail &&
                        parseInt(globalMdmDetail.SystemTypeId) != 5 &&
                        parseInt(globalMdmDetail.SystemTypeId) != 6) ||
                        (parseInt(wf.SystemTypeId) == 5 && wf.Type == 'Other')) && (
                        <>
                            <Text
                                mt={5}
                                mb={2}
                                fontWeight="regular"
                                color="lightBlue"
                                fontSize={24}
                                pl={4}>
                                SYSTEM FIELDS
                            </Text>
                            <Box mb={4} flexDirection="row" justifyContent="center">
                                <Box width={1 / 2} mx="auto" alignItems="center">
                                    {Deltas && Deltas['SystemTypeId'] ? (
                                        <DeltaField delta={Deltas['SystemTypeId']} />
                                    ) : (
                                        <FormInput
                                            label="System"
                                            name="System"
                                            team="system"
                                            inline
                                            variant="outline"
                                            type="text"
                                            value={
                                                SystemType[
                                                    globalMdmDetail &&
                                                        globalMdmDetail.SystemTypeId
                                                ]
                                            }
                                        />
                                    )}

                                    {wf.WorkflowType != 'CreateOther' &&
                                        (Deltas && Deltas['RoleTypeId'] ? (
                                            <DeltaField delta={Deltas['RoleTypeId']} />
                                        ) : (
                                            <FormInput
                                                label="Role"
                                                name="Role"
                                                team="system"
                                                inline
                                                variant="outline"
                                                type="text"
                                                value={
                                                    isPointman
                                                        ? PtmnRoleType[
                                                              globalMdmDetail &&
                                                                  globalMdmDetail.RoleTypeId
                                                          ]
                                                        : isOlympus
                                                        ? this.props.staticData
                                                              .OlympusRoleType[
                                                              globalMdmDetail &&
                                                                  globalMdmDetail.RoleTypeId -
                                                                      1
                                                          ].Value +
                                                          ' : ' +
                                                          this.props.staticData
                                                              .OlympusRoleType[
                                                              globalMdmDetail &&
                                                                  globalMdmDetail.RoleTypeId -
                                                                      1
                                                          ].Description
                                                        : RoleType[
                                                              globalMdmDetail &&
                                                                  globalMdmDetail.RoleTypeId
                                                          ]
                                                }
                                            />
                                        ))}

                                    {!isPointman &&
                                        wf.WorkflowType != 'CreateOther' &&
                                        (Deltas && Deltas['SalesOrgTypeId'] ? (
                                            <DeltaField
                                                delta={Deltas['SalesOrgTypeId']}
                                            />
                                        ) : (
                                            <FormInput
                                                label="Sales Org"
                                                name="SalesOrg"
                                                team="system"
                                                inline
                                                variant="outline"
                                                type="text"
                                                hide={
                                                    isOlympus
                                                        ? allowedFields &&
                                                          allowedFields.includes(
                                                              'SalesOrg'
                                                          )
                                                        : false
                                                }
                                                value={
                                                    isOlympus
                                                        ? globalMdmDetail &&
                                                          this.props.staticData &&
                                                          this.getDropdownValues(
                                                              globalMdmDetail.SalesOrgTypeId,
                                                              this.props.staticData
                                                                  .OlympusSalesOrgType
                                                          )
                                                        : SalesOrgType[
                                                              globalMdmDetail &&
                                                                  globalMdmDetail.SalesOrgTypeId
                                                          ]
                                                }
                                            />
                                        ))}

                                    {isOlympus && wf.WorkflowType != 'CreateOther' ? (
                                        <Box mx="auto" alignItems="center">
                                            <FormInput
                                                inline
                                                variant="outline"
                                                label="URI/Service Code"
                                                name="URIServiceCode"
                                                type="text"
                                                hide={
                                                    allowedFields &&
                                                    allowedFields.includes('ServiceCode')
                                                }
                                                value={
                                                    globalMdmDetail &&
                                                    globalMdmDetail.URIServiceCode
                                                }
                                            />
                                            <FormInput
                                                inline
                                                variant="outline"
                                                label="Unloading Points"
                                                name="UnloadingPoints"
                                                hide={
                                                    allowedFields &&
                                                    allowedFields.includes(
                                                        'DeliveryInformation'
                                                    )
                                                }
                                                type="text"
                                                value={
                                                    globalMdmDetail &&
                                                    globalMdmDetail.UnloadingPoints
                                                }
                                            />
                                            <FormInput
                                                inline
                                                variant="outline"
                                                label="Tax Number 4"
                                                name="TaxNumber4"
                                                type="text"
                                                value={
                                                    globalMdmDetail &&
                                                    globalMdmDetail.TaxNumber4
                                                }
                                                hide={
                                                    allowedFields &&
                                                    allowedFields.includes('TaxNumber4')
                                                }
                                            />
                                            <FormInput
                                                inline
                                                variant="outline"
                                                label="Sales District"
                                                name="SalesDistrictTypeId"
                                                type="text"
                                                value={
                                                    staticData &&
                                                    globalMdmDetail &&
                                                    this.getStaticDropdownValues(
                                                        globalMdmDetail.SalesDistrictTypeId,
                                                        staticData &&
                                                            staticData.OlympusSalesDistrictType
                                                    )
                                                }
                                                hide={
                                                    allowedFields &&
                                                    allowedFields.includes(
                                                        'SalesDistrict'
                                                    )
                                                }
                                            />
                                            <FormInput
                                                inline
                                                variant="outline"
                                                label="Sales Office"
                                                name="SalesOfficeTypeId"
                                                type="text"
                                                value={
                                                    staticData &&
                                                    globalMdmDetail &&
                                                    this.getStaticDropdownValues(
                                                        globalMdmDetail.SalesOfficeTypeId,
                                                        staticData &&
                                                            staticData.OlympusSalesOfficeType
                                                    )
                                                }
                                                hide={
                                                    allowedFields &&
                                                    allowedFields.includes('SalesOffice')
                                                }
                                            />
                                            <FormInput
                                                inline
                                                variant="outline"
                                                label="Sales Group"
                                                name="SalesGroupTypeId"
                                                type="text"
                                                value={
                                                    staticData &&
                                                    globalMdmDetail &&
                                                    this.getStaticDropdownValues(
                                                        globalMdmDetail.SalesGroupTypeId,
                                                        staticData &&
                                                            staticData.OlympusSalesGroupType
                                                    )
                                                }
                                                hide={
                                                    allowedFields &&
                                                    allowedFields.includes('SalesGroup')
                                                }
                                            />
                                            <FormInput
                                                inline
                                                variant="outline"
                                                label="Incoterms 1"
                                                name="IncoTermsTypeId"
                                                type="text"
                                                value={
                                                    staticData &&
                                                    globalMdmDetail &&
                                                    this.getStaticDropdownValues(
                                                        globalMdmDetail.IncoTermsTypeId,
                                                        staticData &&
                                                            staticData.OlympusIncoTermsType
                                                    )
                                                }
                                                hide={
                                                    allowedFields &&
                                                    allowedFields.includes('Incoterms1')
                                                }
                                            />
                                            <FormInput
                                                inline
                                                variant="outline"
                                                label="Place of Destination"
                                                name="PlaceOfDestination"
                                                type="text"
                                                value={
                                                    globalMdmDetail &&
                                                    globalMdmDetail.PlaceOfDestination
                                                }
                                                hide={
                                                    allowedFields &&
                                                    allowedFields.includes(
                                                        'PlaceOfDestination'
                                                    )
                                                }
                                            />
                                            <FormInput
                                                inline
                                                variant="outline"
                                                label="Acct Assignment Group"
                                                name="AcctAssignmentGroupTypeId"
                                                type="text"
                                                value={
                                                    staticData &&
                                                    globalMdmDetail &&
                                                    this.getStaticDropdownValues(
                                                        globalMdmDetail.AcctAssignmentGroupTypeId,
                                                        staticData &&
                                                            staticData.OlympusAcctAssignmentGroupType
                                                    )
                                                }
                                                hide={
                                                    allowedFields &&
                                                    allowedFields.includes(
                                                        'AcctAssignmentGroup'
                                                    )
                                                }
                                            />
                                            <FormInput
                                                inline
                                                variant="outline"
                                                label="Tax Classification"
                                                name="TaxClassificationTypeId"
                                                type="text"
                                                // value={
                                                //     this.props.staticData &&
                                                //     this.props.staticData
                                                //         .OlympusTaxClassificationType[
                                                //         globalMdmDetail &&
                                                //             globalMdmDetail.TaxClassificationTypeId
                                                //     ].Description
                                                // }
                                                value={
                                                    staticData &&
                                                    globalMdmDetail &&
                                                    this.getStaticDropdownValues(
                                                        globalMdmDetail.TaxClassificationTypeId,
                                                        staticData &&
                                                            staticData.OlympusTaxClassificationType
                                                    )
                                                }
                                                hide={
                                                    allowedFields &&
                                                    allowedFields.includes(
                                                        'TaxClassification'
                                                    )
                                                }
                                            />
                                            <FormInput
                                                inline
                                                variant="outline"
                                                label="Customer Number"
                                                name="CustomerNumber"
                                                type="text"
                                                value={
                                                    globalMdmDetail &&
                                                    globalMdmDetail.CustomerNumber
                                                }
                                                hide={
                                                    allowedFields &&
                                                    allowedFields.includes(
                                                        'CustomerNumber'
                                                    )
                                                }
                                            />
                                        </Box>
                                    ) : (
                                        <></>
                                    )}
                                </Box>

                                {isPointman ? (
                                    <Box width={1 / 2} mx="auto" alignItems="center">
                                        {globalMdmDetail.RoleTypeId !== 4 && (
                                            <FormInput
                                                inline
                                                variant="outline"
                                                label="Ship To Customer Number"
                                                name="ShipToNumber"
                                                type="text"
                                                value={
                                                    globalMdmDetail &&
                                                    globalMdmDetail.ShipToCustomerNumber
                                                }
                                            />
                                        )}
                                        <FormInput
                                            inline
                                            variant="outline"
                                            label="Bill To Customer Number"
                                            name="BillToPartner"
                                            type="text"
                                            value={
                                                globalMdmDetail &&
                                                globalMdmDetail.BillToCustomerNumber
                                            }
                                        />
                                        <FormInput
                                            label="Purpose of Request"
                                            name="PurposeOfRequest"
                                            value={globalMdmDetail.Purpose}
                                            multiline
                                            numberOfLines={
                                                globalMdmDetail.Purpose &&
                                                globalMdmDetail.Purpose.length > 36
                                                    ? 3
                                                    : 1
                                            }
                                            style={{ lineHeight: '2.5' }}
                                            hide={wf.WorkflowType == 'CreateOther'}
                                            team="system"
                                            inline
                                            variant="outline"
                                            type="text"
                                        />
                                    </Box>
                                ) : isOlympus && wf.WorkflowType != 'CreateOther' ? (
                                    <Box width={1 / 2} mx="auto" alignItems="center">
                                        <FormInput
                                            label="Distribution Channel"
                                            name="DistributionChannelTypeId"
                                            team="system"
                                            inline
                                            variant="outline"
                                            type="text"
                                            value={
                                                staticData &&
                                                globalMdmDetail &&
                                                this.getStaticDropdownValues(
                                                    globalMdmDetail.DistributionChannelTypeId,
                                                    staticData &&
                                                        staticData.OlympusDistributionChannelType
                                                )
                                            }
                                            hide={
                                                allowedFields &&
                                                allowedFields.includes(
                                                    'DistributionChannel'
                                                )
                                            }
                                        />
                                        <FormInput
                                            label="Division"
                                            name="DivisionTypeId"
                                            team="system"
                                            inline
                                            variant="outline"
                                            type="text"
                                            value={
                                                staticData &&
                                                globalMdmDetail &&
                                                this.getStaticDropdownValues(
                                                    globalMdmDetail.DivisionTypeId,
                                                    staticData &&
                                                        staticData.OlympusDivisionType
                                                )
                                            }
                                            hide={
                                                allowedFields &&
                                                allowedFields.includes('Division')
                                            }
                                        />
                                        <FormInput
                                            label="CompanyCode"
                                            name="CompanyCodeTypeId"
                                            team="system"
                                            inline
                                            variant="outline"
                                            type="text"
                                            value={
                                                staticData &&
                                                globalMdmDetail &&
                                                this.getStaticDropdownValues(
                                                    globalMdmDetail.CompanyCodeTypeId,
                                                    staticData &&
                                                        staticData.OlympusCompanyCodeType
                                                )
                                            }
                                            hide={
                                                allowedFields &&
                                                allowedFields.includes('CompanyCode')
                                            }
                                        />
                                        <FormInput
                                            label="Sold To"
                                            name="SoldTo"
                                            team="system"
                                            inline
                                            variant="outline"
                                            type="text"
                                            value={globalMdmDetail.SoldTo}
                                            hide={
                                                allowedFields &&
                                                allowedFields.includes('SoldTo')
                                            }
                                        />
                                        <FormInput
                                            label="Language"
                                            name="LanguageTypeId"
                                            team="system"
                                            inline
                                            variant="outline"
                                            type="text"
                                            value={
                                                staticData &&
                                                globalMdmDetail &&
                                                this.getStaticDropdownValues(
                                                    globalMdmDetail.LanguageTypeId,
                                                    staticData &&
                                                        staticData.OlympusLanguageType
                                                )
                                            }
                                            hide={
                                                allowedFields &&
                                                allowedFields.includes('Language')
                                            }
                                        />
                                        <FormInput
                                            label="Customer Class"
                                            name="CustomerClassTypeId"
                                            team="system"
                                            inline
                                            variant="outline"
                                            type="text"
                                            value={
                                                staticData &&
                                                globalMdmDetail &&
                                                this.getStaticDropdownValues(
                                                    globalMdmDetail.CustomerClassTypeId,
                                                    staticData &&
                                                        staticData.OlympusCustomerClassType
                                                )
                                            }
                                            hide={
                                                allowedFields &&
                                                allowedFields.includes('CustomerClass')
                                            }
                                        />
                                        <FormInput
                                            label="URI Type"
                                            name="URITypeId"
                                            team="system"
                                            inline
                                            variant="outline"
                                            type="text"
                                            value={
                                                staticData &&
                                                globalMdmDetail &&
                                                this.getStaticDropdownValues(
                                                    globalMdmDetail.URITypeId,
                                                    staticData &&
                                                        staticData.OlympusURIType
                                                )
                                            }
                                            hide={
                                                allowedFields &&
                                                allowedFields.includes('URIType')
                                            }
                                        />
                                        <FormInput
                                            label="Price Group"
                                            name="PriceGroupTypeId"
                                            team="system"
                                            inline
                                            variant="outline"
                                            type="text"
                                            value={
                                                staticData &&
                                                globalMdmDetail &&
                                                this.getStaticDropdownValues(
                                                    globalMdmDetail.PriceGroupTypeId,
                                                    staticData &&
                                                        staticData.OlympusPriceGroupType
                                                )
                                            }
                                            hide={
                                                allowedFields &&
                                                allowedFields.includes('PriceGroup')
                                            }
                                        />
                                        <FormInput
                                            label="Delivering Plant"
                                            name="DeliveringPlantTypeId"
                                            team="system"
                                            inline
                                            variant="outline"
                                            type="text"
                                            value={
                                                staticData &&
                                                globalMdmDetail &&
                                                this.getStaticDropdownValues(
                                                    globalMdmDetail.DeliveringPlantTypeId,
                                                    staticData &&
                                                        staticData.OlympusDeliveringPlantType
                                                )
                                            }
                                            hide={
                                                allowedFields &&
                                                allowedFields.includes('DeliveringPlant')
                                            }
                                        />
                                        <FormInput
                                            label="Recon Account"
                                            name="ReconAccountTypeId"
                                            team="system"
                                            inline
                                            variant="outline"
                                            type="text"
                                            // value={
                                            //     this.props.staticData &&
                                            //     this.props.staticData
                                            //         .OlympusReconAccountType[
                                            //         globalMdmDetail &&
                                            //             globalMdmDetail.ReconAccountTypeId
                                            //     ].Description staticData &&
                                            // }
                                            value={
                                                staticData &&
                                                globalMdmDetail &&
                                                this.getStaticDropdownValues(
                                                    globalMdmDetail.ReconAccountTypeId,
                                                    staticData &&
                                                        staticData.OlympusReconAccountType
                                                )
                                            }
                                            hide={
                                                allowedFields &&
                                                allowedFields.includes('ReconAccount')
                                            }
                                        />
                                        <FormInput
                                            label="Terms of Payment"
                                            name="TermsOfPaymentTypeId"
                                            team="system"
                                            inline
                                            variant="outline"
                                            type="text"
                                            value={
                                                staticData &&
                                                globalMdmDetail &&
                                                this.getStaticDropdownValues(
                                                    globalMdmDetail.TermsOfPaymentTypeId,
                                                    staticData &&
                                                        staticData.OlympusTermsOfPaymentType
                                                )
                                            }
                                            hide={
                                                allowedFields &&
                                                allowedFields.includes('TermsOfPayment')
                                            }
                                        />
                                        <FormInput
                                            label="Currency"
                                            name="CurrencyTypeId"
                                            team="system"
                                            inline
                                            variant="outline"
                                            type="text"
                                            value={
                                                staticData &&
                                                globalMdmDetail &&
                                                this.getStaticDropdownValues(
                                                    globalMdmDetail.CurrencyTypeId,
                                                    staticData &&
                                                        staticData.OlympusCurrencyType
                                                )
                                            }
                                            hide={
                                                allowedFields &&
                                                allowedFields.includes('Currency')
                                            }
                                        />
                                        <FormInput
                                            label="Primary GPO"
                                            name="PrimaryGPO"
                                            team="system"
                                            inline
                                            variant="outline"
                                            type="text"
                                            value={
                                                globalMdmDetail &&
                                                globalMdmDetail.PrimaryGPO
                                            }
                                            hide={
                                                allowedFields &&
                                                allowedFields.includes('PrimaryGPO')
                                            }
                                        />

                                        <FormInput
                                            label="Requested Credit Limit"
                                            name="RequestedCreditLimit"
                                            team="system"
                                            inline
                                            variant="outline"
                                            type="text"
                                            value={
                                                globalMdmDetail &&
                                                globalMdmDetail.RequestedCreditLimit
                                            }
                                            hide={
                                                allowedFields &&
                                                allowedFields.includes(
                                                    'RequestedCreditLimit'
                                                )
                                            }
                                        />
                                        <FormInput
                                            label="Priority"
                                            name="PriorityTypeId"
                                            team="system"
                                            inline
                                            variant="outline"
                                            type="text"
                                            value={
                                                staticData &&
                                                globalMdmDetail &&
                                                this.getStaticDropdownValues(
                                                    globalMdmDetail.PriorityTypeId,
                                                    staticData &&
                                                        staticData.OlympusPriorityType
                                                )
                                            }
                                            hide={
                                                allowedFields &&
                                                allowedFields.includes('Priority')
                                            }
                                        />
                                        <FormInput
                                            label="Effective Date"
                                            name="EffectiveDate"
                                            team="system"
                                            inline
                                            variant="outline"
                                            type="text"
                                            value={
                                                globalMdmDetail &&
                                                globalMdmDetail.EffectiveDate
                                            }
                                            hide={
                                                allowedFields &&
                                                allowedFields.includes('EffectiveDate')
                                            }
                                        />
                                        <FormInput
                                            label="Purpose of Request"
                                            name="PurposeOfRequest"
                                            value={globalMdmDetail.Purpose}
                                            multiline
                                            numberOfLines={
                                                globalMdmDetail.Purpose &&
                                                globalMdmDetail.Purpose.length > 36
                                                    ? 3
                                                    : 1
                                            }
                                            style={{ lineHeight: '2.5' }}
                                            team="system"
                                            inline
                                            variant="outline"
                                            type="text"
                                        />
                                    </Box>
                                ) : (
                                    <Box width={1 / 2} mx="auto" alignItems="center">
                                        {wf.WorkflowType === 'CreateOther' && (
                                            <FormInput
                                                label="Effective Date"
                                                name="EffectiveDate"
                                                inline
                                                variant="outline"
                                                type="text"
                                                value={
                                                    globalMdmDetail &&
                                                    globalMdmDetail.EffectiveDate
                                                }
                                            />
                                        )}
                                        {wf.WorkflowType != 'CreateOther' &&
                                            (Deltas &&
                                            Deltas['DistributionChannelTypeId'] ? (
                                                <DeltaField
                                                    delta={
                                                        Deltas[
                                                            'DistributionChannelTypeId'
                                                        ]
                                                    }
                                                />
                                            ) : (
                                                <FormInput
                                                    label="Distribution Channel"
                                                    name="DistributionChannel"
                                                    team="system"
                                                    inline
                                                    variant="outline"
                                                    type="text"
                                                    value={
                                                        DistributionChannelType[
                                                            globalMdmDetail &&
                                                                globalMdmDetail.DistributionChannelTypeId
                                                        ]
                                                    }
                                                />
                                            ))}

                                        {wf.WorkflowType != 'CreateOther' &&
                                            (Deltas && Deltas['DivisionTypeId'] ? (
                                                <DeltaField
                                                    delta={Deltas['DivisionTypeId']}
                                                />
                                            ) : (
                                                <FormInput
                                                    label="Division"
                                                    name="DivisionTypeId"
                                                    team="system"
                                                    inline
                                                    variant="outline"
                                                    type="text"
                                                    value={
                                                        DivisionType[
                                                            globalMdmDetail &&
                                                                globalMdmDetail.DivisionTypeId
                                                        ]
                                                    }
                                                />
                                            ))}

                                        {wf.WorkflowType != 'CreateOther' &&
                                            (Deltas && Deltas['CompanyCodeTypeId'] ? (
                                                <DeltaField
                                                    delta={Deltas['CompanyCodeTypeId']}
                                                />
                                            ) : (
                                                <>
                                                    <FormInput
                                                        label="CompanyCode"
                                                        name="CompanyCodeTypeId"
                                                        team="system"
                                                        inline
                                                        variant="outline"
                                                        type="text"
                                                        value={
                                                            CompanyCodeType[
                                                                globalMdmDetail &&
                                                                    globalMdmDetail.CompanyCodeTypeId
                                                            ]
                                                        }
                                                    />
                                                    <FormInput
                                                        label="Purpose of Request"
                                                        name="PurposeOfRequest"
                                                        value={globalMdmDetail.Purpose}
                                                        multiline
                                                        numberOfLines={
                                                            globalMdmDetail.Purpose &&
                                                            globalMdmDetail.Purpose
                                                                .length > 36
                                                                ? 3
                                                                : 1
                                                        }
                                                        style={{ lineHeight: '2.5' }}
                                                        team="system"
                                                        inline
                                                        variant="outline"
                                                        type="text"
                                                    />
                                                </>
                                            ))}
                                    </Box>
                                )}
                            </Box>
                        </>
                    )}

                    {wf.SystemTypeId == 5 &&
                        wf.Type != 'Other' &&
                        parseInt(globalMdmDetail.SystemTypeId) == 5 && (
                            <>
                                {' '}
                                <Text
                                    mt={5}
                                    mb={2}
                                    fontWeight="regular"
                                    color="lightBlue"
                                    fontSize={24}
                                    pl={4}>
                                    SYSTEM FIELDS
                                </Text>
                                <JDESystemFields
                                    formData={globalMdmDetail}
                                    isReadOnly={true}
                                />
                            </>
                        )}

                    {((wf.SystemType === 'Salesforce' &&
                        wf.WorkflowType === 'CreateSalesforce') ||
                        (parseInt(globalMdmDetail.SystemTypeId) === 6 &&
                            wf.WorkflowTypeName === 'Create')) && (
                        <>
                            <Text
                                mt={5}
                                mb="24px"
                                fontWeight="regular"
                                color="lightBlue"
                                fontSize={24}
                                pl={4}>
                                SYSTEM FIELDS
                            </Text>
                            <Box mb={4} flexDirection="row" justifyContent="center">
                                <Box width={1 / 2} mx="auto" alignItems="center">
                                    <FormInput
                                        label="SFDC Number"
                                        name="SfdcNumber"
                                        multiline
                                        numberOfLines={3}
                                        onChange={this.onFieldChange}
                                        style={{ lineHeight: '2.5' }}
                                        value={
                                            customerMaster && customerMaster.SfdcNumber
                                        }
                                        variant={'outline'}
                                        inline
                                        type="text"
                                    />
                                </Box>
                                <Box width={1 / 2} mx="auto" alignItems="center">
                                    <FormInput
                                        label="Purpose of Request"
                                        name="PurposeOfRequest"
                                        value={globalMdmDetail.Purpose}
                                        multiline
                                        numberOfLines={
                                            globalMdmDetail.Purpose &&
                                            globalMdmDetail.Purpose.length > 36
                                                ? 3
                                                : 1
                                        }
                                        style={{ lineHeight: '2.5' }}
                                        team="system"
                                        inline
                                        variant="outline"
                                        type="text"
                                        hide={globalMdmDetail.SystemTypeId == 6}
                                    />
                                </Box>
                            </Box>
                        </>
                    )}

                    {(isUpdateScreenDetail || wf.WorkflowType == 'CreateOther') && (
                        <Box mt={2} flexDirection="row" justifyContent="center">
                            <Box width={1 / 2} mx="auto" alignItems="center">
                                <FormInput
                                    label="Purpose of Request"
                                    name="PurposeOfRequest"
                                    value={
                                        globalMdmDetail.Purpose ||
                                        idx(Deltas, (_) => _.Purpose.UpdatedValue)
                                    }
                                    multiline
                                    numberOfLines={
                                        (globalMdmDetail.Purpose &&
                                            globalMdmDetail.Purpose.length > 36) ||
                                        (Deltas.Purpose && Deltas.Purpose.length > 36)
                                            ? 3
                                            : 1
                                    }
                                    style={{ lineHeight: '2.5' }}
                                    team="system"
                                    inline
                                    variant="outline"
                                    type="text"
                                />
                            </Box>
                            <Box width={1 / 2} mx="auto" alignItems="center" />
                        </Box>
                    )}
                    {files && wf.WorkflowType === 'CreateOther' ? (
                        <FileListText files={files} readOnly />
                    ) : (
                        <FilesList files={files} readOnly />
                    )}
                    <Box flexDirection="row" justifyContent="center">
                        <Box ml="5%" width={1 / 2}>
                            {tasknotes && tasknotes.length > 0 && (
                                <TaskNotes taskNotes={tasknotes} />
                            )}
                        </Box>
                        <Box width={1 / 2} mx="auto" alignItems="center" />
                    </Box>
                    <Box flexDirection="row" justifyContent="center">
                        <Box ml="5%" width={1 / 2} />
                        <Box width={1 / 2} mx="auto" alignItems="center">
                            {withdrawToggle && (
                                <Button
                                    onPress={() => {
                                        window.scrollTo(0, 0);
                                        this.props.withDrawRequest(
                                            {
                                                WorkflowId: wf.WorkflowId,
                                                WorkflowOperationType: 4,
                                            },
                                            this.props.history
                                        );
                                    }}
                                    title="Withdraw"
                                />
                            )}
                        </Box>
                    </Box>
                </Box>
                <Box>
                    {this.state.isInternationalVersionVisible && (
                        <Modal
                            isVisible={this.state.isInternationalVersionVisible}
                            style={{ background: '#FFFFFF' }}>
                            <GlobalMdmFields
                                staticDropDownData={this.props.staticData}
                                formData={globalMdmDetail}
                                isInternationalVersion={true}
                                isOlympus={true}
                                isOlympusModal={true}
                                viewInternationalModal={() => {
                                    this.setState({
                                        isInternationalVersionVisible: false,
                                    });
                                }}
                                readOnly
                            />
                        </Modal>
                    )}
                </Box>
            </Box>
        );
    }
}

const mapStateToProps = ({ workflows, myRequests, updateFlow, staticData }) => {
    const {
        fetchingfnGroupData: fetchingGlobaldata,
        functionalGroupDetails,
        alert: workFlowAlert,
        dunsFields = {},
        error,
        statusBarData,
    } = workflows;
    const { fetching, alert: requestAlert } = myRequests;
    const { mdmcustomerdata, fetching: fetchingUpdates } = updateFlow;
    const alert = workFlowAlert.display ? workFlowAlert : requestAlert;
    const { data, fetching: staticFetching } = staticData;
    return {
        fetching: fetchingGlobaldata || fetching || fetchingUpdates || staticFetching,
        functionalGroupDetails,
        alert,
        error,
        statusBarData,
        mdmcustomerdata,
        dunsFields,
        staticData: data,
    };
};

export default connect(mapStateToProps, {
    getFunctionalGroupData,
    withDrawRequest,
    getStatusBarData,
    getMdmMappingMatrix,
    fetchDuns,
    getStaticRequest,
})(MyRequestsForm);

const styles = StyleSheet.create({
    progressIndicator: {
        flex: 1,
        paddingBottom: 5,
        flexDirection: 'row-reverse',
        alignItems: 'flex-end',
    },
    statusText: {
        fontSize: 15,
        color: '#1D4289',
        fontFamily: 'Poppins',
        textAlign: 'center',
        marginTop: 20,
    },
});
