import React, { Fragment, Component } from 'react';
import {
    ScrollView,
    View,
    TouchableOpacity,
    ActivityIndicator,
    Image,
    StyleSheet,
} from 'react-native';
import {
    DimensionAware,
    getWindowHeight,
    getWindowWidth,
} from 'react-native-dimension-aware';
import { TextInput } from 'react-native-web';
import { connect } from 'react-redux';
import * as moment from 'moment';
import DynamicSelect from '../components/DynamicSelect';
import { createCustomer, hideMessage } from '../appRedux/actions/Customer.js';
import { Flex, Button, Box, Text } from '../components/common';
import {
    FormInput,
    FormSelect,
    FormRadioGroup,
    FormRadio,
    CountryDropdown,
} from '../components/form';
import { getStaticRequest } from '../appRedux/actions';
import Modal from 'modal-enhanced-react-native-web';
const additionalTaxNumberOption = [
    { Id: 'ITRDHG', Value: 'ITRDHG', Description: 'Private Customers' },
    { Id: 'ITRDHI', Value: 'ITRDHI', Description: 'Public Customers' },
];
class Page extends Component {
    onFieldChange = (val, e) => {
        this.props.onFieldChange(val, e);
    };

    isNumber = (n) => {
        return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
    };

    validateNumericKeypad = (value, e) => {
        const { name } = e.target;
        if (value) {
            if (!this.isNumber(value)) {
                this.setState({
                    formErrors: {
                        [name]:
                            value >= 0 || value <= 100
                                ? 'Value should be greater 0 and less than 100'
                                : 'Must be digits',
                    },
                });
            } else {
                this.onFieldChange(value, e);
            }
        } else {
            this.onFieldChange(value, e);
        }
    };

    render() {
        const {
            formData,
            dropDownDatas = [],
            isReadOnly = false,
            allowedFields,
        } = this.props;
        // console.log(formData,"=========", this.props);
        let filteredSystemFields = [];
        let excludedId = [4, 7, 99];
        if (
            this.props.staticData &&
            this.props.staticData.SystemType &&
            this.props.staticData.SystemType.length > 0
        ) {
            this.props.staticData.SystemType.forEach((systemData) => {
                if (!excludedId.includes(systemData.Id)) {
                    filteredSystemFields.push(systemData);
                }
            });
        }

        return (
            <>
                <Box flexDirection="row" justifyContent="center">
                    <Box width={1 / 2} mx="auto" alignItems="center">
                        <>
                            <DynamicSelect
                                arrayOfData={
                                    this.props.staticData &&
                                    this.props.staticData.SystemType
                                        ? filteredSystemFields
                                        : []
                                }
                                value={formData.SystemTypeId}
                                label="System"
                                name="SystemTypeId"
                                isRequired
                                formErrors={
                                    this.props.formErrors
                                        ? this.props.formErrors['SystemTypeId']
                                        : null
                                }
                                onFieldChange={this.onFieldChange}
                                readOnly={isReadOnly}
                            />
                            <DynamicSelect
                                arrayOfData={
                                    this.props.staticData &&
                                    this.props.staticData.JDESearchTypeType
                                        ? this.props.staticData.JDESearchTypeType
                                        : []
                                }
                                value={formData.SearchTypeId}
                                label="SearchType"
                                name="SearchTypeId"
                                isRequired
                                formErrors={
                                    this.props.formErrors
                                        ? this.props.formErrors['SearchTypeId']
                                        : null
                                }
                                onFieldChange={this.onFieldChange}
                                readOnly={isReadOnly}
                            />
                            <DynamicSelect
                                arrayOfData={
                                    this.props.staticData &&
                                    this.props.staticData.JDESalesOrgType
                                        ? this.props.staticData.JDESalesOrgType
                                        : []
                                }
                                value={formData['SalesOrgTypeId']}
                                label="SalesOrg"
                                name="SalesOrgTypeId"
                                isRequired
                                formErrors={
                                    this.props.formErrors
                                        ? this.props.formErrors['SalesOrgTypeId']
                                        : null
                                }
                                onFieldChange={this.onFieldChange}
                                readOnly={isReadOnly}
                            />
                            <DynamicSelect
                                arrayOfData={
                                    this.props.staticData &&
                                    this.props.staticData.JDEIndustryClassType
                                        ? this.props.staticData.JDEIndustryClassType
                                        : []
                                }
                                value={formData['IndustryClassificationTypeId']}
                                label="Industry Class"
                                name="IndustryClassificationTypeId"
                                isRequired
                                formErrors={
                                    this.props.formErrors
                                        ? this.props.formErrors[
                                              'IndustryClassificationTypeId'
                                          ]
                                        : null
                                }
                                onFieldChange={this.onFieldChange}
                                readOnly={isReadOnly}
                            />
                            <DynamicSelect
                                arrayOfData={
                                    this.props.staticData &&
                                    this.props.staticData.JDELanguageType
                                        ? this.props.staticData.JDELanguageType
                                        : []
                                }
                                value={formData.LanguageTypeId}
                                label="Language"
                                name="LanguageTypeId"
                                isRequired
                                formErrors={
                                    this.props.formErrors
                                        ? this.props.formErrors['LanguageTypeId']
                                        : null
                                }
                                onFieldChange={this.onFieldChange}
                                readOnly={isReadOnly}
                            />
                            <FormInput
                                label="IPA / Destination Code"
                                name="IPADestinationCode"
                                error={
                                    this.props.formErrors
                                        ? this.props.formErrors['IPADestinationCode']
                                        : null
                                }
                                value={
                                    this.props.formData['IPADestinationCode'] ||
                                    this.props.formData['IPADestinationCode']
                                }
                                style={{ height: 'inherit' }}
                                onChange={(value, element) => {
                                    this.onFieldChange(value, element);
                                }}
                                required={formData.Country == 'IT'}
                                variant={isReadOnly ? 'outline' : 'solid'}
                                inline={isReadOnly ? true : false}
                            />
                            <FormInput
                                label="Requested Credit Limit"
                                name="RequestedCreditLimit"
                                error={
                                    this.props.formErrors
                                        ? this.props.formErrors['RequestedCreditLimit']
                                        : null
                                }
                                value={
                                    this.props.formData['RequestedCreditLimit'] ||
                                    this.props.formData['RequestedCreditLimit']
                                }
                                style={{ height: 'inherit' }}
                                onChange={(value, element) => {
                                    this.validateNumericKeypad(value, element);
                                }}
                                // required={true}
                                type="number"
                                variant={isReadOnly ? 'outline' : 'solid'}
                                inline={isReadOnly ? true : false}
                            />
                            {this.props.formData.Country == 'IT' && !isReadOnly ? (
                                <DynamicSelect
                                    arrayOfData={additionalTaxNumberOption}
                                    value={
                                        this.props.formData.AdditionalTaxNumber ||
                                        this.props.formData['AdditionalTaxNumber']
                                    }
                                    stringValID={true}
                                    label="Additional Tax Number"
                                    name="AdditionalTaxNumber"
                                    isRequired
                                    formErrors={
                                        this.props.formErrors
                                            ? this.props.formErrors['AdditionalTaxNumber']
                                            : null
                                    }
                                    onFieldChange={this.onFieldChange}
                                    readOnly={isReadOnly}
                                />
                            ) : (
                                this.props.formData.Country == 'FR' && (
                                    <FormInput
                                        label="Additional Tax Number"
                                        name="AdditionalTaxNumber"
                                        error={
                                            this.props.formErrors
                                                ? this.props.formErrors[
                                                      'AdditionalTaxNumber'
                                                  ]
                                                : null
                                        }
                                        value={this.props.formData['AdditionalTaxNumber']}
                                        style={{ height: 'inherit' }}
                                        onChange={(value, element) => {
                                            this.onFieldChange(value, element);
                                        }}
                                        variant={isReadOnly ? 'outline' : 'solid'}
                                        inline={isReadOnly ? true : false}
                                    />
                                )
                            )}
                            <FormInput
                                label="Sold To"
                                name="SoldTo"
                                hide={allowedFields && allowedFields.includes('SoldTo')}
                                error={
                                    this.props.formErrors
                                        ? this.props.formErrors['SoldTo']
                                        : null
                                }
                                value={this.props.formData['SoldTo']}
                                style={{ height: 'inherit' }}
                                onChange={(value, element) => {
                                    this.onFieldChange(value, element);
                                }}
                                maxLength={8}
                                // required={formData.RoleTypeId == '3'}
                                variant={isReadOnly ? 'outline' : 'solid'}
                                inline={isReadOnly ? true : false}
                            />
                            <FormInput
                                label="Tax ID"
                                name="TaxId"
                                error={
                                    this.props.formErrors
                                        ? this.props.formErrors['TaxId']
                                        : null
                                }
                                value={this.props.formData['TaxId']}
                                style={{ height: 'inherit' }}
                                onChange={(value, element) => {
                                    this.onFieldChange(value, element);
                                }}
                                maxLength={20}
                                required={formData.Country == 'IT'}
                                variant={isReadOnly ? 'outline' : 'solid'}
                                inline={isReadOnly ? true : false}
                            />
                            <DynamicSelect
                                arrayOfData={
                                    this.props.staticData &&
                                    this.props.staticData.JDEPaymentTermsType
                                        ? this.props.staticData.JDEPaymentTermsType
                                        : []
                                }
                                value={formData.PaymentTermsTypeId}
                                label="Payment Terms"
                                name="PaymentTermsTypeId"
                                isRequired
                                formErrors={
                                    this.props.formErrors
                                        ? this.props.formErrors['PaymentTermsTypeId']
                                        : null
                                }
                                onFieldChange={this.onFieldChange}
                                readOnly={isReadOnly}
                            />
                            <DynamicSelect
                                arrayOfData={
                                    this.props.staticData &&
                                    this.props.staticData.JDECurrencyType
                                        ? this.props.staticData.JDECurrencyType
                                        : []
                                }
                                value={formData.CurrencyTypeId}
                                label="Currency"
                                name="CurrencyTypeId"
                                isRequired
                                formErrors={
                                    this.props.formErrors
                                        ? this.props.formErrors['CurrencyTypeId']
                                        : null
                                }
                                onFieldChange={this.onFieldChange}
                                readOnly={isReadOnly}
                            />
                            <FormInput
                                label="Purpose of Request"
                                name="Purpose"
                                hide={!isReadOnly}
                                multiline
                                value={formData && formData.Purpose}
                                numberOfLines={
                                    formData.Purpose && formData.Purpose.length > 36
                                        ? 3
                                        : 1
                                }
                                style={{ lineHeight: '2.5' }}
                                inline
                                maxLength={1000}
                                variant="outline"
                                type="text"
                            />
                        </>
                    </Box>
                    <Box width={1 / 2} mx="auto" alignItems="center">
                        <>
                            <DynamicSelect
                                arrayOfData={
                                    this.props.staticData &&
                                    this.props.staticData.JDERoleType
                                        ? this.props.staticData.JDERoleType
                                        : []
                                }
                                value={formData.RoleTypeId}
                                label="Role"
                                name="RoleTypeId"
                                isRequired
                                formErrors={
                                    this.props.formErrors
                                        ? this.props.formErrors['RoleTypeId']
                                        : null
                                }
                                onFieldChange={this.onFieldChange}
                                readOnly={isReadOnly}
                            />
                            <DynamicSelect
                                arrayOfData={
                                    this.props.staticData &&
                                    this.props.staticData.JDEPriceGroupType
                                        ? this.props.staticData.JDEPriceGroupType
                                        : []
                                }
                                value={formData.PriceGroupTypeId}
                                label="Price Group"
                                name="PriceGroupTypeId"
                                isRequired
                                formErrors={
                                    this.props.formErrors
                                        ? this.props.formErrors['PriceGroupTypeId']
                                        : null
                                }
                                onFieldChange={this.onFieldChange}
                                readOnly={isReadOnly}
                            />
                            <DynamicSelect
                                arrayOfData={
                                    this.props.staticData &&
                                    this.props.staticData.JDEIncoTermsType
                                        ? this.props.staticData.JDEIncoTermsType
                                        : []
                                }
                                value={formData.IncoTermsTypeId}
                                label="IncoTerms"
                                name="IncoTermsTypeId"
                                isRequired
                                formErrors={
                                    this.props.formErrors
                                        ? this.props.formErrors['IncoTermsTypeId']
                                        : null
                                }
                                onFieldChange={this.onFieldChange}
                                readOnly={isReadOnly}
                            />
                            <DynamicSelect
                                arrayOfData={
                                    this.props.staticData &&
                                    this.props.staticData.JDECarrierType
                                        ? this.props.staticData.JDECarrierType
                                        : []
                                }
                                value={formData.CarrierTypeId}
                                label="Carrier"
                                name="CarrierTypeId"
                                isRequired
                                formErrors={
                                    this.props.formErrors
                                        ? this.props.formErrors['CarrierTypeId']
                                        : null
                                }
                                onFieldChange={this.onFieldChange}
                                readOnly={isReadOnly}
                            />
                            <DynamicSelect
                                arrayOfData={
                                    this.props.staticData &&
                                    this.props.staticData.JDESalesRepsType
                                        ? this.props.staticData.JDESalesRepsType
                                        : []
                                }
                                value={formData.SalesRepTypeId}
                                label="Account / Sales Reps"
                                name="SalesRepTypeId"
                                isRequired
                                formErrors={
                                    this.props.formErrors
                                        ? this.props.formErrors['SalesRepTypeId']
                                        : null
                                }
                                onFieldChange={this.onFieldChange}
                                readOnly={isReadOnly}
                            />
                            <DynamicSelect
                                arrayOfData={
                                    this.props.staticData &&
                                    this.props.staticData.JDESalesAreaType
                                        ? this.props.staticData.JDESalesAreaType
                                        : []
                                }
                                value={formData.SalesAreaTypeId}
                                label="Cat Code 08 (Sales Area)"
                                name="SalesAreaTypeId"
                                isRequired
                                formErrors={
                                    this.props.formErrors
                                        ? this.props.formErrors['SalesAreaTypeId']
                                        : null
                                }
                                onFieldChange={this.onFieldChange}
                                readOnly={isReadOnly}
                            />
                            <DynamicSelect
                                arrayOfData={
                                    this.props.staticData &&
                                    this.props.staticData.JDERegionType
                                        ? this.props.staticData.JDERegionType
                                        : []
                                }
                                value={formData.RegionTypeId}
                                label="Cat Code 09 (Region)"
                                name="RegionTypeId"
                                isRequired
                                formErrors={
                                    this.props.formErrors
                                        ? this.props.formErrors['RegionTypeId']
                                        : null
                                }
                                onFieldChange={this.onFieldChange}
                                readOnly={isReadOnly}
                            />
                            <DynamicSelect
                                arrayOfData={
                                    this.props.staticData &&
                                    this.props.staticData.JDEDocumentsLanguageType
                                        ? this.props.staticData.JDEDocumentsLanguageType
                                        : []
                                }
                                value={formData.DocumentsLanguageTypeId}
                                label="Cat Code 10 (Language for documents)"
                                name="DocumentsLanguageTypeId"
                                isRequired
                                formErrors={
                                    this.props.formErrors
                                        ? this.props.formErrors['DocumentsLanguageTypeId']
                                        : null
                                }
                                onFieldChange={this.onFieldChange}
                                inline={isReadOnly ? false : true}
                                readOnly={isReadOnly}
                            />
                            <DynamicSelect
                                arrayOfData={
                                    this.props.staticData &&
                                    this.props.staticData.JDEGPOType
                                        ? this.props.staticData.JDEGPOType
                                        : []
                                }
                                value={formData.GpoTypeId}
                                label="Cat Code 23 (GPO)"
                                name="GpoTypeId"
                                formErrors={
                                    this.props.formErrors
                                        ? this.props.formErrors['GpoTypeId']
                                        : null
                                }
                                onFieldChange={this.onFieldChange}
                                readOnly={isReadOnly}
                            />
                            <FormInput
                                label="Effective Date"
                                min={moment().format('YYYY-MM-DD')}
                                name="EffectiveDate"
                                error={
                                    this.props.formErrors
                                        ? this.props.formErrors['EffectiveDate']
                                        : null
                                }
                                value={moment(
                                    this.props.formData['EffectiveDate']
                                ).format('YYYY-MM-DD')}
                                required
                                type="date"
                                variant="outline"
                                style={{ height: 'inherit' }}
                                onChange={(value, element) => {
                                    this.onFieldChange(
                                        new Date(value).toJSON().slice(0, 19),
                                        element
                                    );
                                }}
                                variant={isReadOnly ? 'outline' : 'solid'}
                                inline={isReadOnly ? true : false}
                            />
                        </>
                    </Box>
                </Box>
            </>
        );
    }
}
class Default extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const props = this.props;

        return (
            <DimensionAware
                render={(dimensions) => (
                    <Page
                        {...{
                            ...props,
                            width: getWindowWidth(dimensions),
                            height: getWindowHeight(dimensions),
                            marginBottom: 25,
                        }}
                    />
                )}
            />
        );
    }
}
const styles = StyleSheet.create({
    progressIndicator: {
        flex: 1,
        paddingBottom: 5,
        flexDirection: 'row-reverse',
        alignItems: 'flex-end',
    },
    statusText: {
        fontSize: 15,
        color: '#1D4289',
        fontFamily: 'Poppins',
        textAlign: 'center',
        marginTop: 20,
    },
});

const mapStateToProps = ({ customer, toasts, staticData }) => {
    const {
        fetching: fetchingCustomer,
        error,
        customerdata,
        alert,
        taxJuriData,
    } = customer;
    const { data, fetching: staticFetching } = staticData;

    return {
        error,
        fetching: fetchingCustomer || staticFetching,
        customerdata,
        alert,
        taxJuriData,
        toasts,
        staticData: data,
    };
};

export default connect(mapStateToProps, {
    // createCustomer,
    // getTaxJurisdictionData,
    // removeMessage,
    // hideMessage,
    // getStaticRequest,
})(Default);
