/**
 * @prettier
 */
import { GenerateWorkflowId } from '@env';
import { MaterialIcons } from '@expo/vector-icons';
import idx from 'idx';
import { camelCase, mapKeys as lodashMapKeys } from 'lodash';
import * as moment from 'moment';
import React, { Fragment } from 'react';
import {
    ScrollView,
    View,
    TouchableOpacity,
    ActivityIndicator,
    Image,
    StyleSheet,
} from 'react-native';
import {
    DimensionAware,
    getWindowHeight,
    getWindowWidth,
} from 'react-native-dimension-aware';
import { TextInput } from 'react-native-web';
import { connect } from 'react-redux';

import {
    yupAllFieldsValidation,
    normalizeName,
    normalizeStreet,
    validatePostalCode,
} from '../constants/utils';
import {
    SystemType,
    SalesOrgType,
    MarketSegmentationReverseMap,
} from '../constants/WorkflowEnums.js';
import GlobalMdmFields from '../components/GlobalMdmFields';
import JdeGlobalMdmFields from '../components/JdeGlobalMdmFields';
import {
    createCustomerRules,
    yupglobalMDMFieldRules,
    saveInternationalRules,
    createOlympusCustomerRules,
    createJDECustomerRules,
} from '../constants/FieldRules';
import DynamicSelect from '../components/DynamicSelect';
import {
    fetchCreateCustomerDropDownData,
    fetchOlympusFieldMapping,
    fetchJDECreateTeamRoleFieldMapping,
} from '../redux/DropDownDatas';
import { createCustomer, hideMessage } from '../appRedux/actions/Customer.js';
import FileInput from '../components/form/FileInput.jsx';
import { ajaxGetRequest } from '../appRedux/sagas/config';
import { getTaxJurisdictionData } from '../appRedux/actions/MyTasks';
import { removeMessage } from '../appRedux/actions/Toast';
import FilesList from '../components/FilesList.js';
import FlashMessage, { FlashMessages } from '../components/FlashMessage';
import { Flex, Button, Box, Text } from '../components/common';
import {
    FormInput,
    FormSelect,
    FormRadioGroup,
    FormRadio,
    CountryDropdown,
} from '../components/form';
import { Colors, StyledText } from '../theme/Index';
import { getStaticRequest } from '../appRedux/actions';
import Modal from 'modal-enhanced-react-native-web';
import JDESystemFields from './JDESystemFields';

const postalCodes = require('../postal-codes-js');

const SystemValidValues = Object.keys(SystemType).map((index) => {
    const system = SystemType[index];
    return { id: index, description: system };
});

const SalesOrgValidValues = Object.keys(SalesOrgType).map((index) => {
    const system = SalesOrgType[index];
    return { id: index, description: system, value: system };
});

function merge(...schemas) {
    const [first, ...rest] = schemas;

    const merged = rest.reduce(
        (mergedSchemas, schema) => mergedSchemas.concat(schema),
        first
    );

    return merged;
}

const initFormdData = {
    SystemTypeId: 1,
};

const mapKeys = (obj, callback) => {
    const keys = Object.keys(obj);
    return keys.map((key, index) => callback(obj[key], key));
};
class Page extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            system: '',
            role: '',
            formData: {
                ...initFormdData,
                Country: 'US',
                CompanyCodeTypeId: 1,
                DistributionChannelTypeId: 1,
                DivisionTypeId: 1,
                MarketingSegmentationTypeId: 4,
                EffectiveDate: new Date().toJSON().slice(0, 10),
                RequestedCreditLimited: '',
                ServiceCode: '',
                SalesOrgTypeId: '',
                Language: '',
                TaxNumber4: '',
                CustomerClass: '',
                Incoterms1: '',
                PlaceOfDestination: '',
                AcctAssignmentGroup: '',
                TaxClassification: '2',
                RequestedCreditLimit: '',
                Priority: '',
                Purpose: '',
                SoldTo: '',
                TaxId: '',
                Language: '',
                ServiceCode: '',
                DeliveryInformation: '',
                ReconAccount: '',
                TermsOfPayment: '',
                SalesDistrict: '',
                SalesOffice: '',
                SalesGroup: '',
                Currency: '',
                PrimaryGpo: '',
                DunsNumber: '',
                URIType: 1,
                GpoTypeId: 7,
            },
            isSalesForce: { disabled: false },
            dropDownDatas: {},
            taxUpdated: false,
            fetchingWorkflowId: false,
            fileErrors: {},
            selectedFiles: {},
            selectedFilesIds: [],
            files: [],
            userId: localStorage.getItem('userId'),
            inputPropsForDefaultRules: {},
            fileinputkey: Date.now(),
            modalVisible: false,
            internationalVersionData: {
                IName1: '',
                IDoorNo: '',
                ISearchTerm1: '',
                IStreet: '',
                IStreet2: '',
                ICity: '',
            },
            disabledFields: {},
            teamRoleFieldMapping: {},
            jdeTeamRoleFieldMapping: {},
        };
    }

    generateWorkflowId() {
        const url = GenerateWorkflowId;

        ajaxGetRequest(url).then((res) => {
            if (res.IsSuccess)
                this.setState({
                    fetchingWorkflowId: false,
                    formData: {
                        ...initFormdData,
                        ...this.state.formData,
                        WorkflowId: res.ResultData,
                        UserId: this.state.userId,
                    },
                });
        });
    }

    componentDidMount() {
        this.props.getStaticRequest([
            42,
            43,
            5,
            49,
            52,
            53,
            54,
            55,
            56,
            57,
            58,
            59,
            60,
            61,
            62,
            63,
            64,
            65,
            40,
            81,
            82,
            84,
            71,
            76,
            87,
            88,
            89,
            90,
            91,
            92,
            93,
            94,
            95,
            96,
            97,
            98,
            99,
            100,
            101,
            102,
            103,
            104,
            105,
            106,
            107,
            108,
            109,
            110,
            111,
            112,
            113,
            114,
            115,
            116,
            117,
            118,
            123,
        ]);
        fetchCreateCustomerDropDownData().then((res) => {
            const data = res;
            this.setState({ dropDownDatas: data }, this.generateWorkflowId);
        });
        fetchOlympusFieldMapping().then((resp) => {
            const data = resp;
            this.setState({ teamRoleFieldMapping: data });
        });
        fetchJDECreateTeamRoleFieldMapping().then((resp) => {
            const data = resp;
            this.setState({ jdeTeamRoleFieldMapping: data });
        });
        if (localStorage.getItem('SFDCData')) {
            const { formData: prevFormData } = this.state;
            const savedData = JSON.parse(localStorage.getItem('SFDCData'));
            savedData.MarketingSegmentationTypeId = savedData.hasOwnProperty(
                'marketsegmentation'
            )
                ? MarketSegmentationReverseMap[savedData['marketsegmentation'].trim()]
                : MarketSegmentationReverseMap['N/A'];
            localStorage.removeItem('SFDCData');
            let disabledFields = {};
            delete savedData['type'];

            const capitalize = (str) => {
                return str.charAt(0).toUpperCase() + str.slice(1);
            };

            const SfdcData = lodashMapKeys(savedData, (val, key) => {
                const formattedKey = capitalize(camelCase(key));
                disabledFields[formattedKey] = true;
                return formattedKey;
            });

            // console.log(disabledFields);
            this.setState(
                {
                    ...this.state,
                    formData: {
                        ...this.state.formData,
                        ...SfdcData,
                    },
                    disabledFields,
                },
                () =>
                    (this.state.formData['Country'].toLowerCase().trim() === 'us' ||
                        this.state.formData['Country'].toLowerCase() === 'ca') &&
                    this.shouldTaxJuriUpdate(prevFormData, this.state.formData) &&
                    this.getTaxJuri(
                        validatePostalCode(
                            this.state.formData['Country'],
                            this.state.formData['PostalCode']
                        )
                    )
            );
        }
    }

    componentWillUnmount() {
        this.props.hideMessage();
        localStorage.removeItem('SFDCData');
    }

    updateFormData = (val, name) => {};

    getTaxJuri = (isPostalCodeValid) => {
        let { formData, userId } = this.state;
        try {
            var postData = {
                userId,
                Country: formData['Country'].trim(),
                Region: formData['Region'].trim(),
                PostalCode: isPostalCodeValid ? formData['PostalCode'].trim() : '',
                City: formData['City'].trim(),
            };
            this.props.getTaxJurisdictionData(postData);
        } catch (error) {
            console.log(error);
            console.log('tax juri api/formdata call error');
        }
    };

    getAddress = (formData) => ({
        Country: idx(formData, (_) => _.Country) || '',
        Region: idx(formData, (_) => _.Region) || '',
        PostalCode: idx(formData, (_) => _.PostalCode) || '',
        City: idx(formData, (_) => _.City) || '',
    });

    shouldTaxJuriUpdate(prevFormData, formData) {
        const adressesObj = this.getAddress(formData);
        const prevAddress = this.getAddress(prevFormData);

        const obj = mapKeys(adressesObj, (value, key) => ({
            value,
            key,
        }));

        const addressUpdated = obj.some(({ key, value }) => {
            const prevValue = idx(prevFormData, (_) => _[key]);
            return (prevValue ? prevValue.trim() : '') !== value.trim();
        });

        const addressFilled = obj.every(({ key, value }) => {
            return value.length > 0;
        });

        if (addressFilled && this.state.taxUpdated === false) {
            this.setState({ taxUpdated: true });
            return true;
        } else if (
            this.props.taxJuriData &&
            this.props.taxJuriData.length > 0 &&
            addressUpdated &&
            !addressFilled
        ) {
            return true;
        }

        return addressFilled && addressUpdated;
    }

    onFieldChange = (val, e) => {
        const { formData: prevFormData } = this.state;
        const name = e.target.name;
        let value = val.trim();
        // other fields to uppercase
        var otherUpperCaseFields = ['city', 'postalcode', 'email'];
        value =
            otherUpperCaseFields.includes(name.toLowerCase()) &&
            prevFormData['SystemTypeId'] != 5 &&
            prevFormData['SystemTypeId'] != 2
                ? val.toUpperCase()
                : val;

        // name field abbreviations & uppercase
        if (
            name.includes('Name') &&
            prevFormData['SystemTypeId'] != 5 &&
            prevFormData['SystemTypeId'] != 2
        ) {
            value = normalizeName(val);
        }

        if (
            name.includes('Street') &&
            prevFormData['SystemTypeId'] != 5 &&
            prevFormData['SystemTypeId'] != 2
        ) {
            value = normalizeStreet(val);
        }

        if (name == 'Country') {
            prevFormData['Region'] = undefined;
        }

        if (name === 'PostalCode') {
            if (value.length > 0) {
                const { isPostalCodeValid } = this.state;
                // const valid = validatePostalCode(this.state.formData['Country'], value);
                let valid = true;
                const result = postalCodes.validate(
                    this.state.formData['Country'],
                    value
                );
                console.log(result);
                if (result === false) {
                    //wrong postal code fmt
                    valid = false;
                } else {
                    //valid postal code. For specific countries ,would return the formatted string.
                    // Such cases set the value of form data to be result .
                    var type = typeof result;
                    if (type === 'string') {
                        value = result;
                        valid = true;
                    } else {
                        valid = true;
                    }
                }
                if (valid != isPostalCodeValid)
                    this.setState({
                        isPostalCodeValid: valid,
                        formErrors: {
                            ...this.state.formErrors,
                            isPostalCodeValid: !valid ? ' Invalid PostalCode' : null,
                        },
                    });
            } else if (
                value.length <= 0 &&
                this.state.formErrors &&
                this.state.formErrors['isPostalCodeValid']
            ) {
                this.setState({
                    isPostalCodeValid: !this.state.isPostalCodeValid,
                    formErrors: {
                        ...this.state.formErrors,
                        isPostalCodeValid: null,
                    },
                });
            }
        }
        this.setState(
            (prevState, props) => {
                return {
                    formData: {
                        ...prevState.formData,
                        [name]: value,
                    },
                };
            },
            () => {
                const { formData, taxUpdated } = this.state;
                if (
                    name === 'RoleTypeId' ||
                    name === 'CategoryTypeId' ||
                    name === 'Telephone' ||
                    name === 'SystemTypeId'
                ) {
                    this.validateRules(name, val);
                } else if (
                    name === 'Country' ||
                    name === 'Region' ||
                    name === 'PostalCode' ||
                    name === 'City'
                ) {
                    if (name === 'Country') this.validateRules(name, val);
                    if (
                        (formData['Country'].toLowerCase().trim() === 'us' ||
                            formData['Country'].toLowerCase() === 'ca') &&
                        this.shouldTaxJuriUpdate(prevFormData, formData)
                    )
                        this.getTaxJuri(this.state.isPostalCodeValid);
                }

                const isOlympus = this.state.formData['SystemTypeId'] === '2';
                if (isOlympus) {
                    let { formData } = this.state;
                    if (name === 'SystemTypeId') {
                        formData['DivisionTypeId'] = 2;
                        formData['DistributionChannelTypeId'] = '';
                        formData['CompanyCodeTypeId'] = '';
                    }
                    if (name == 'ServiceCode') {
                        if (value) {
                            formData['URIType'] = 1;
                        } else {
                            formData['URIType'] = null;
                        }
                    }
                    if (
                        this.state.formData['SalesOrgTypeId'] == '14' &&
                        this.state.formData['DistributionChannelTypeId'] == '3'
                    ) {
                        formData['Language'] = '15';
                    } else {
                        if (
                            name === 'SalesOrgTypeId' ||
                            name === 'DistributionChannelTypeId'
                        ) {
                            formData['DeliveringPlant'] = '';
                            formData['Language'] = '';
                        }
                    }
                    if (
                        name == 'SalesOffice' ||
                        name == 'SalesOrgTypeId' ||
                        name == 'DivisionTypeId' ||
                        name == 'DistributionChannelTypeId'
                    ) {
                        formData['SalesGroup'] = '';
                    }
                    if (this.state.formData['DistributionChannelTypeId'] === '5') {
                        formData['ReconAccount'] = '2';
                    }
                    this.setState({
                        formData,
                    });
                }
                const isJDE = this.state.formData['SystemTypeId'] == '5';
                if (isJDE) {
                    const formData = { ...this.state.formData };
                    const isSFDC = formData.Sfdcid && formData.Sfdcid.length > 0;
                    console.log(
                        name === 'SystemTypeId' &&
                            !isSFDC &&
                            !formData['MarketingSegmentationTypeId']
                    );
                    if (
                        name === 'SystemTypeId' &&
                        !isSFDC &&
                        !formData['MarketingSegmentationTypeId']
                    ) {
                        formData['MarketingSegmentationTypeId'] = '4';
                    }
                    if (name == 'RoleTypeId') {
                        formData['SearchTypeId'] =
                            this.state.formData['RoleTypeId'] == '3' ? '2' : '1';
                    }
                    this.setState({
                        formData,
                    });
                }
                if (!isJDE && !isOlympus) {
                    const formData = this.state.formData;
                    if (name === 'SystemTypeId') {
                        formData['MarketingSegmentationTypeId'] = '4';
                    }
                    this.setState({
                        formData,
                    });
                }
            }
        );
    };

    setStateProperty = (name, value) => {
        this.setState({
            [name]: value,
        });
    };
    setFormDataValues = (name, value) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: value,
            },
        });
    };
    setFormDataMultipleValues = (obj) => {
        this.setState({
            formData: {
                ...this.state.formData,
                ...obj,
            },
        });
    };

    setInputPropsForDefaultRules = (obj) => {
        this.setState({
            inputPropsForDefaultRules: {
                ...this.state.inputPropsForDefaultRules,
                ...obj,
            },
        });
    };

    setAttachmentMessage = (systemTypeId, roleTypeId) => {
        var isPointman = systemTypeId == 3;
        const { formData } = this.state;
        const isOlympus = formData.SystemTypeId === '2';
        var value = roleTypeId;
        if (isPointman) {
            this.setStateProperty('attachment_text', '');
            if (parseInt(value, 10) === 2)
                //ship to
                this.setStateProperty('attachment_text', 'License, Purchase Order');
            else if (parseInt(value, 10) === 4)
                //billto
                this.setStateProperty(
                    'attachment_text',
                    'Credit Application, License, Purchase Order'
                );
        } else if (isOlympus) {
            if (parseInt(value) === 2)
                //payer
                this.setStateProperty(
                    'attachment_text',
                    'License, Purchase Order, Letter from Customer'
                );
            else if (parseInt(value) === 3)
                //billto
                this.setStateProperty('attachment_text', 'Credit App');
            else if (parseInt(value) === 4)
                //sales rep
                this.setStateProperty(
                    'attachment_text',
                    'Purchase Order, Letter from Customer'
                );
            else if (parseInt(value) === 29)
                //dropship
                this.setStateProperty('attachment_text', 'Approval from RM');
            else
                this.setStateProperty(
                    'attachment_text',
                    'Credit Application, License, Purchase Order'
                );
        } else {
            this.setStateProperty(
                'attachment_text',
                'Credit Application, License, Purchase Order'
            );
            if (parseInt(value, 10) === 3)
                //payer
                this.setStateProperty('attachment_text', 'Credit Application');
            else if (parseInt(value, 10) === 4)
                //billto
                this.setStateProperty('attachment_text', 'Purchase Order');
            else if (parseInt(value, 10) === 5)
                //sales rep
                this.setStateProperty(
                    'attachment_text',
                    'Approval from Regional Manager'
                );
            else if (parseInt(value, 10) === 6)
                //dropship
                this.setStateProperty('attachment_text', 'License, Purchase Order');
            else
                this.setStateProperty(
                    'attachment_text',
                    'Credit Application, License, Purchase Order'
                );
        }
    };

    validateRules = (stateKey, stateVal) => {
        const readOnlyDropDown = { disabled: true };
        //check for system type salesforce
        if (stateKey === 'SystemTypeId') {
            this.setAttachmentMessage(
                stateVal,
                parseInt(this.state.formData['RoleTypeId'])
            );
            if (parseInt(stateVal) === 6)
                this.setStateProperty('isSalesForce', { disabled: true });
            else this.setStateProperty('isSalesForce', { disabled: false });
        }

        //check for attachment text
        //let attachment_text = 'Credit Application, License, Purchase Order';
        if (stateKey === 'RoleTypeId') {
            this.setAttachmentMessage(
                parseInt(this.state.formData['SystemTypeId']),
                stateVal
            );
        }
        // check for SalesOrgTypeId
        if (stateKey === 'CategoryTypeId' && this.state.formData['Country'] != 'CA') {
            var categoryTypeidValue = this.state.formData['CategoryTypeId'];
            var roleTypeidValue = this.state.formData['RoleTypeId'];

            // if (parseInt(roleTypeidValue, 10) === 5) {
            //     this.setFormDataMultipleValues({'SalesOrgTypeId': 4});
            //     this.setInputPropsForDefaultRules({'SalesOrgTypeId': readOnlyDropDown});
            // } else {
            if (categoryTypeidValue === '4') {
                this.setFormDataMultipleValues({ SalesOrgTypeId: 1 });
                //  this.setInputPropsForDefaultRules({ SalesOrgTypeId: readOnlyDropDown });
            } else if (
                categoryTypeidValue === '1' ||
                categoryTypeidValue === '2' ||
                categoryTypeidValue === '3' ||
                categoryTypeidValue === '6' ||
                categoryTypeidValue === '7'
            ) {
                this.setFormDataMultipleValues({ SalesOrgTypeId: 2 });
                //  this.setInputPropsForDefaultRules({ SalesOrgTypeId: readOnlyDropDown });
            } else {
                this.setFormDataMultipleValues({ SalesOrgTypeId: 0 });
                //this.setInputPropsForDefaultRules({ SalesOrgTypeId: {disabled: false,},});
            }
            // }
        } else if (stateKey === 'Country') {
            if (stateVal === 'CA') {
                this.setFormDataMultipleValues({
                    CompanyCodeTypeId: 2,
                    SalesOrgTypeId: 3,
                });
                // this.setInputPropsForDefaultRules({
                //     CompanyCodeTypeId: readOnlyDropDown,
                //     SalesOrgTypeId: readOnlyDropDown,
                // });
            } else {
                this.setFormDataMultipleValues({
                    CompanyCodeTypeId: 1,
                    SalesOrgTypeId: null,
                });
                // this.setInputPropsForDefaultRules({
                //     CompanyCodeTypeId: readOnlyDropDown,
                //     SalesOrgTypeId: { disabled: false },
                // });
            }
        } else if (stateKey === 'Telephone') {
            this.setFormDataMultipleValues({
                Telephone: stateVal.length > 0 ? stateVal.trim() : null,
            });
        }
    };

    setFormErrors = (errors) => {
        console.log(errors);
        const { formErrors } = this.state;
        this.setState({ formErrors: errors }, () => window.scrollTo(0, 0));
    };

    proceedAction = () => {
        const { history } = this.props;
        const { selectedFilesIds, selectedFiles, formData, userId } = this.state;
        var isSalesForce = parseInt(formData.SystemTypeId, 10) === 6;
        let wfType = parseInt(formData.RoleTypeId, 10);
        const isPointman = formData.SystemTypeId == 3;
        const isOlympus = this.state.formData['SystemTypeId'] === '2';
        const isJDE = this.state.formData['SystemTypeId'] === '5';
        if (isSalesForce) {
            wfType = 33;
        } else if (isPointman) {
            if (parseInt(formData.RoleTypeId, 10) === 4) wfType = 41;
            else if (parseInt(formData.RoleTypeId, 10) === 2) wfType = 13;
        }
        try {
            formData['Region'] = formData['Region'] == 0 ? null : formData.Region;
            const createCustomerRequest = {
                ...formData,
                WorkflowType: wfType,
                UserId: userId,
                CategoryTypeId: parseInt(formData.CategoryTypeId, 10),
                SystemTypeId: parseInt(formData.SystemTypeId, 10),
                RoleTypeId: isSalesForce ? 0 : parseInt(formData.RoleTypeId, 10),
                SalesOrgTypeId: isSalesForce ? 0 : parseInt(formData.SalesOrgTypeId, 10),
                DistributionChannelTypeId: isSalesForce
                    ? 0
                    : parseInt(formData.DistributionChannelTypeId, 10),
                DivisionTypeId: isSalesForce ? 0 : parseInt(formData.DivisionTypeId, 10),
                CompanyCodeTypeId: isSalesForce
                    ? 0
                    : parseInt(formData.CompanyCodeTypeId, 10),
                TaxJurisdiction:
                    this.state.formData['TaxJurisdiction'] || this.props.taxJuriData[0],
                Name1: formData.Name1.toUpperCase(),
                Name2: formData.Name2 ? formData.Name2.toUpperCase() : undefined,
                Name3: formData.Name3 ? formData.Name3.toUpperCase() : undefined,
                Name4: formData.Name4 ? formData.Name4.toUpperCase() : undefined,
                Street: formData.Street ? formData.Street.toUpperCase() : undefined,
                Street2: formData.Street2 ? formData.Street2.toUpperCase() : undefined,
                City: formData.City ? formData.City.toUpperCase() : undefined,
                Region: formData['Region'] == 0 ? null : formData.Region,
                PostalCode: formData.PostalCode,
                Country: formData.Country,
                Telephone: formData.Telephone,
                Fax: formData.Fax,
                Email: formData.Email ? formData.Email.toUpperCase() : undefined,
            };
            const createPtmnRequest = {
                IsSaveToWorkflow: true,
                WorkflowId: formData.WorkflowId,
                WorkflowType: wfType,
                UserId: userId,
                WorkflowTitle: formData.WorkflowTitle,
                CategoryTypeId: parseInt(formData.CategoryTypeId, 10),
                Name1: formData.Name1.toUpperCase(),
                Name2: formData.Name2 ? formData.Name2.toUpperCase() : undefined,
                Name3: formData.Name3 ? formData.Name3.toUpperCase() : undefined,
                Name4: formData.Name4 ? formData.Name4.toUpperCase() : undefined,
                Street: formData.Street ? formData.Street.toUpperCase() : undefined,
                Street2: formData.Street2 ? formData.Street2.toUpperCase() : undefined,
                City: formData.City ? formData.City.toUpperCase() : undefined,
                Region: formData['Region'] == 0 ? null : formData.Region,
                PostalCode: formData.PostalCode,
                Country: formData.Country,
                Telephone: formData.Telephone,
                Fax: formData.Fax,
                Email: formData.Email ? formData.Email.toUpperCase() : undefined,
                TaxJurisdiction:
                    this.state.formData['TaxJurisdiction'] || this.props.taxJuriData[0],
                Purpose: formData.Purpose,
                SystemTypeId: parseInt(formData.SystemTypeId, 10),
                RoleTypeId: isSalesForce ? 0 : parseInt(formData.RoleTypeId, 10),
                EffectiveDate: formData.EffectiveDate,
                BillToPartner: formData.BillToPartner,
                MarketingSegmentationTypeId: formData.MarketingSegmentationTypeId,
            };

            const { teamRoleFieldMapping, jdeTeamRoleFieldMapping } = this.state;
            let CustomerMasterTeamFields = [];
            if (isOlympus) {
                CustomerMasterTeamFields =
                    idx(teamRoleFieldMapping, (_) => _.OlympusCreateCustomerFields) || [];
            }
            let CreateJDETeamFields = [];
            if (isJDE) {
                CreateJDETeamFields =
                    idx(jdeTeamRoleFieldMapping, (_) => _.CreateTeamFields) || [];
            }

            const RoleTypeId = formData.RoleTypeId;
            const SystemTypeId = formData.SystemTypeId;
            let fieldsToMap = isOlympus ? CustomerMasterTeamFields : CreateJDETeamFields;
            const allowedFieldList = fieldsToMap.filter((item) => {
                return item.RoleTypeId == RoleTypeId && item.SystemTypeId == SystemTypeId;
            });
            let allowedFields = undefined;
            if (allowedFieldList && allowedFieldList.length > 0) {
                allowedFields = allowedFieldList[0].Fields;
            }
            // if(isOlympus){
            const olympusDataObj = {
                IsSaveToWorkflow: true,
                WorkflowId: formData.WorkflowId,
                WorkflowTitle: formData.WorkflowTitle,
                WorkflowType: wfType,
                Sfdcid: this.state.formData['SFDCID'] || null,
                UserId: localStorage.getItem('userId'),
                Name1: formData.Name1,
                Name2: formData.Name2,
                Name3: formData.Name3,
                Name4: formData.Name4,
                Street: formData.Street,
                Street2: formData.Street2,
                City: formData.City,
                Region: formData.Region,
                PostalCode: formData.PostalCode,
                Country: formData.Country,
                Telephone: formData.Telephone,
                Fax: formData.Fax,
                Email: formData.Email,
                MarketingSegmentationTypeId: formData.MarketingSegmentationTypeId,
                Purpose: formData.Purpose,
                BillToPartner: formData.BillToPartner,
                CategoryTypeId: formData.CategoryTypeId,
                RoleTypeId:
                    allowedFields && allowedFields.includes('Role')
                        ? undefined
                        : formData.RoleTypeId,
                SalesOrgTypeId:
                    allowedFields && allowedFields.includes('SalesOrg')
                        ? undefined
                        : formData.SalesOrgTypeId,
                CompanyCodeTypeId:
                    allowedFields && allowedFields.includes('CompanyCode')
                        ? undefined
                        : formData.CompanyCodeTypeId,
                DistributionChannelTypeId:
                    allowedFields && allowedFields.includes('DistributionChannel')
                        ? undefined
                        : formData.DistributionChannelTypeId,
                DivisionTypeId:
                    allowedFields && allowedFields.includes('Division')
                        ? undefined
                        : formData.DivisionTypeId,
                SystemTypeId: formData.SystemTypeId,
                EffectiveDate: formData.EffectiveDate,
                SoldTo:
                    allowedFields && allowedFields.includes('SoldTo')
                        ? undefined
                        : formData.SoldTo,
                URIServiceCode:
                    allowedFields && allowedFields.includes('ServiceCode')
                        ? undefined
                        : formData.ServiceCode,

                UnloadingPoints:
                    allowedFields && allowedFields.includes('DeliveryInformation')
                        ? undefined
                        : formData.DeliveryInformation,
                TaxNumber4:
                    allowedFields && allowedFields.includes('TaxNumber4')
                        ? undefined
                        : formData.TaxNumber4,
                PrimaryGPO:
                    allowedFields && allowedFields.includes('PrimaryGPO')
                        ? undefined
                        : formData.PrimaryGpo,
                PlaceOfDestination:
                    allowedFields && allowedFields.includes('PlaceOfDestination')
                        ? undefined
                        : formData.PlaceOfDestination,
                RequestedCreditLimit:
                    allowedFields && allowedFields.includes('RequestedCreditLimit')
                        ? undefined
                        : formData.RequestedCreditLimit,
                PurposeOfRequest: formData.Purpose,
                AcctAssignmentGroupTypeId:
                    allowedFields && allowedFields.includes('AcctAssignmentGroup')
                        ? undefined
                        : formData.AcctAssignmentGroup,
                CurrencyTypeId:
                    allowedFields && allowedFields.includes('Currency')
                        ? undefined
                        : formData.Currency,
                CustomerClassTypeId:
                    allowedFields && allowedFields.includes('CustomerClass')
                        ? undefined
                        : formData.CustomerClass,
                IncoTermsTypeId:
                    allowedFields && allowedFields.includes('Incoterms1')
                        ? undefined
                        : formData.Incoterms1,
                LanguageTypeId:
                    allowedFields && allowedFields.includes('Language')
                        ? undefined
                        : formData.Language,
                PriorityTypeId: formData.Priority,
                ReconAccountTypeId:
                    allowedFields && allowedFields.includes('ReconAccount')
                        ? undefined
                        : formData.ReconAccount,
                SalesDistrictTypeId:
                    allowedFields && allowedFields.includes('SalesDistrict')
                        ? undefined
                        : formData.SalesDistrict,
                SalesGroupTypeId:
                    allowedFields && allowedFields.includes('SalesGroup')
                        ? undefined
                        : formData.SalesGroup,
                SalesOfficeTypeId:
                    allowedFields && allowedFields.includes('SalesOffice')
                        ? undefined
                        : formData.SalesOffice,
                TaxClassificationTypeId:
                    allowedFields && allowedFields.includes('TaxClassification')
                        ? undefined
                        : formData.TaxClassification,
                TermsOfPaymentTypeId:
                    allowedFields && allowedFields.includes('TermsOfPayment')
                        ? undefined
                        : formData.TermsOfPayment,
                URITypeId:
                    allowedFields && allowedFields.includes('URIType')
                        ? undefined
                        : formData.URIType,
                PriceGroupTypeId:
                    allowedFields && allowedFields.includes('PriceGroup')
                        ? undefined
                        : formData.PriceGroup,
                DeliveringPlantTypeId:
                    allowedFields && allowedFields.includes('DeliveringPlant')
                        ? undefined
                        : formData.DeliveringPlant,
                CustomerNumber:
                    allowedFields && allowedFields.includes('CustomerNumber')
                        ? undefined
                        : formData.CustomerNumber,
            };
            // }
            // console.log(olympusDataObj)
            const { internationalVersionData } = this.state;
            if (this.isInternationalVersionDataPresent())
                olympusDataObj['InternationalVersion'] = {
                    Name1: internationalVersionData.IName1,
                    Name2: internationalVersionData.IName2,
                    Name3: internationalVersionData.IName3,
                    Name4: internationalVersionData.IName4,
                    Street: internationalVersionData.IStreet,
                    Street2: internationalVersionData.IStreet2,
                    City: internationalVersionData.ICity,
                    DoorNo: internationalVersionData.IDoorNo,
                    SearchTerm1: internationalVersionData.ISearchTerm1,
                    LanguageTypeId: formData.Language,
                };

            if (parseInt(formData.RoleTypeId) === 4) {
                delete createPtmnRequest.BillToPartner;
            }

            // console.log(this.state.formData);

            let jdeCreateCustomerObj = {
                SystemTypeId: formData.SystemTypeId,
                Country: formData.Country,
                MarketingSegmentationTypeId: formData.MarketingSegmentationTypeId,
                EffectiveDate: formData.EffectiveDate,
                SalesOrgTypeId: formData.SalesOrgTypeId,
                LanguageTypeId: formData.LanguageTypeId,
                Purpose: formData.Purpose,
                CurrencyTypeId: formData.CurrencyTypeId,
                DunsNumber: formData.DunsNumber,
                WorkflowId: formData.WorkflowId,
                UserId: localStorage.getItem('userId'),
                WorkflowTitle: formData.WorkflowTitle,
                Name1: formData.Name1,
                Street: formData.Street,
                Street2: formData.Street2,
                City: formData.City,
                Region: formData.Region == 0 ? null : formData.Region,
                PostalCode: formData.PostalCode,
                Country: formData.Country,
                Telephone: formData.Telephone,
                Fax: formData.Fax,
                CategoryTypeId: formData.CategoryTypeId,
                RoleTypeId: formData.RoleTypeId,
                IsSaveToWorkflow: formData.IsSaveToWorkflow,
                WorkflowType: wfType,
                Name2: formData.Name2,
                Name3: formData.Name3,
                Name4: formData.Name4,
                Email: formData.Email,
                Email2: formData.Email2,
                Email3: formData.Email3,
                Email4: formData.Email4,
                IPADestinationCode: formData.IPADestinationCode,
                AdditionalTaxNumber: formData.AdditionalTaxNumber || '',
                SoldTo:
                    allowedFields && allowedFields.includes('SoldTo')
                        ? undefined
                        : formData.SoldTo,
                TaxId: formData.TaxId,
                SalesRepTypeId: formData.SalesRepTypeId,
                SalesAreaTypeId: formData.SalesAreaTypeId,
                RegionTypeId: formData.RegionTypeId,
                DocumentsLanguageTypeId: formData.DocumentsLanguageTypeId,
                CarrierTypeId: formData.CarrierTypeId,
                GpoTypeId: formData.GpoTypeId,
                PaymentTermsTypeId: formData.PaymentTermsTypeId,
                PriceGroupTypeId: formData.PriceGroupTypeId,
                SearchTypeId: formData.SearchTypeId,
                IncoTermsTypeId: formData.IncoTermsTypeId,
                IndustryClassificationTypeId: formData.IndustryClassificationTypeId,
                RequestedCreditLimit: formData.RequestedCreditLimit,
            };

            // console.log(jdeCreateCustomerObj);

            let data = isOlympus
                ? olympusDataObj
                : isJDE
                ? jdeCreateCustomerObj
                : parseInt(formData.SystemTypeId) === 3
                ? createPtmnRequest
                : createCustomerRequest;
            // console.log(jdeCreateCustomerObj,"=======",olympusDataObj," Olympus data obj=======",createCustomerRequest,"=======",createPtmnRequest)

            if (formData['Sfdcid']) data['Sfdcid'] = formData['Sfdcid'];
            this.props.createCustomer({
                data,
                files: selectedFilesIds.map((id) => selectedFiles[id]),
                history,
            });
        } catch (err) {
            console.log(err, '==========');
        }
    };

    isInternationalVersionDataPresent = () => {
        const { internationalVersionData, formData } = this.state;
        let isPresent = false;
        Object.keys(internationalVersionData).some((data) => {
            if (internationalVersionData[data]) {
                isPresent = true;
                return true;
            }
        });
        return isPresent && (formData.Country === 'JP' || formData.Country === 'CN');
    };

    onSubmit = (event, schema, IsSaveToWorkflow) => {
        let { formData, selectedFilesIds, selectedFiles } = this.state;
        const { Category, ...data } = formData;
        let fileErrors = {};
        let errors = false;
        const isOlympus = formData.SystemTypeId === '2';
        selectedFilesIds.map((id) => {
            if (selectedFiles[id] && selectedFiles[id].DocumentType <= 0) {
                fileErrors[id] = 'Document Type Required for file';
                errors = true;
            }
        });

        this.setState({ fileErrors, isFileErrors: errors });
        this.setState(
            {
                formData: {
                    ...data,
                    IsSaveToWorkflow,
                },
            },
            () => {
                const { internationalVersionData } = this.state;
                yupAllFieldsValidation(
                    { ...formData, TaxJurisdiction: ' ' },
                    merge(schema, yupglobalMDMFieldRules),
                    (...rest) => {
                        if (
                            this.isInternationalVersionDataPresent() &&
                            this.state.isFileErrors === false
                        ) {
                            yupAllFieldsValidation(
                                { ...internationalVersionData },
                                saveInternationalRules,
                                (...rest) => {
                                    console.log(this.state.isFileErrors);
                                    if (this.state.isFileErrors === false) {
                                        this.proceedAction(...rest);
                                    }
                                },
                                (err) => {
                                    this.setFormErrors(err);
                                    this.setState({
                                        modalVisible: true,
                                    });
                                }
                            );
                        } else {
                            this.proceedAction(...rest);
                        }
                    },
                    this.setFormErrors
                );
            }
        );
    };

    selectFile = (events) => {
        event.preventDefault();

        const { selectedFilesIds, selectedFiles } = this.state;
        const id = events.target.files[0].name;

        this.setState({
            selectedFiles: {
                ...selectedFiles,
                [id]: {
                    data: events.target.files[0],
                    DocumentName: events.target.files[0].name,
                    DocumentType: 0,
                },
            },
            selectedFilesIds: [...selectedFilesIds, id],
            filename: events.target.files[0].name,
            fileinputkey: Date.now(),
        });
    };

    removeFile = (id) => {
        event.preventDefault();

        var selectedFilesDict = this.state.selectedFiles;
        var selectedFilesIdsList = this.state.selectedFilesIds;
        // remove the existing state
        delete selectedFilesDict[id];
        selectedFilesIdsList = selectedFilesIdsList.filter((fileid) => fileid !== id);

        this.setState({
            selectedFiles: selectedFilesDict,
            selectedFilesIds: selectedFilesIdsList,
        });
    };

    saveInternationalVersion = (internationalVersionData) => {
        this.setState({
            modalVisible: false,
            internationalVersionData,
        });
    };

    internationVersionDataChange = (val, e) => {
        let { internationalVersionData } = this.state;
        const name = e.target.name;
        let value = val.trim();
        this.setState((prevState, props) => {
            return {
                internationalVersionData: {
                    ...prevState.internationalVersionData,
                    [name]: value,
                },
            };
        });
    };

    getDropdownValues = (key, data, stateKey, checkValue) => {
        let dropdownData = [];
        const self = this;
        const { formData } = this.state;
        if (data) {
            dropdownData = [];
            data.forEach((dropdownList) => {
                if (dropdownList.ValidationData.length > 0) {
                    dropdownList.ValidationData.forEach((datatofilter) => {
                        if (datatofilter[key] == parseInt(formData[stateKey])) {
                            dropdownData.push(dropdownList);
                        }
                    });
                }
            });
        }
        var check = new Set();
        let filteredArr = dropdownData.filter(
            (obj) => !check.has(obj['Id']) && check.add(obj['Id'])
        );
        return filteredArr;
    };

    deliveryPlantMapping = (data) => {
        let systemFields = this.state.formData;
        let dropdownData = [];
        if (data) {
            data.forEach((dropdownList) => {
                if (dropdownList.ValidationData.length > 0) {
                    dropdownList.ValidationData.forEach((datatofilter) => {
                        if (
                            datatofilter.SalesOrgTypeId == systemFields.SalesOrgTypeId &&
                            systemFields.DistributionChannelTypeId ==
                                datatofilter.DistributionChannelTypeId
                        ) {
                            dropdownData.push(dropdownList);
                        }
                    });
                }
            });
        }
        return dropdownData;
    };

    getSalesOfficeData = (salesOfficeData) => {
        const {
            SalesOrgTypeId,
            DistributionChannelTypeId,
            DivisionTypeId,
        } = this.state.formData;
        let filteredArr = [];
        if (salesOfficeData) {
            salesOfficeData.forEach((data) => {
                if (data.ValidationData.length) {
                    data.ValidationData.forEach((dataToFilter) => {
                        if (
                            dataToFilter.DistributionChannelTypeId ==
                                DistributionChannelTypeId &&
                            dataToFilter.DivisionTypeId == DivisionTypeId &&
                            dataToFilter.SalesOrgTypeId == SalesOrgTypeId
                        ) {
                            filteredArr.push(data);
                        }
                    });
                }
            });
        }
        return filteredArr;
    };
    isNumber = (n) => {
        return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
    };

    validateNumericKeypad = (value, e) => {
        const { name } = e.target;
        if (value) {
            if (!this.isNumber(value)) {
                this.setState({
                    formErrors: {
                        [name]:
                            value >= 0 || value <= 100
                                ? 'Value should be greater 0 and less than 100'
                                : 'Must be digits',
                    },
                });
            } else {
                this.onFieldChange(value, e);
            }
        } else {
            this.onFieldChange(value, e);
        }
    };

    render() {
        const { width, height, marginBottom, location, staticData } = this.props;
        const {
            dropDownDatas,
            formData,
            selectedFilesIds,
            selectedFiles,
            isSalesForce,
            modalVisible,
            teamRoleFieldMapping,
            jdeTeamRoleFieldMapping,
        } = this.state;
        let SalesOrgType = idx(dropDownDatas, (_) => _.SalesOrgType) || [];
        let salesOrgData = SalesOrgType;
        // if (parseInt(formData['RoleTypeId'], 10) === 5) {
        //     salesOrgData = SalesOrgType.filter((saleorg) => !(saleorg.value === '0150'));
        // }
        const isOlympus = formData.SystemTypeId === '2';
        const isJDE = formData.SystemTypeId == '5';
        let disable_submit = !this.props.taxJuriData[0] && !isOlympus && !isJDE;
        if (
            formData['Country'] &&
            formData['Country'].toLowerCase().trim() != 'us' &&
            formData['Country'].toLowerCase() != 'ca'
        ) {
            disable_submit = false;
        }
        const isPointman = formData.SystemTypeId == 3;
        const isSFDC = formData.Sfdcid && formData.Sfdcid.length > 0;
        let CustomerMasterTeamFields = [];
        if (isOlympus) {
            CustomerMasterTeamFields =
                idx(teamRoleFieldMapping, (_) => _.OlympusCreateCustomerFields) || [];
        }
        let CreateJDETeamFields = [];
        if (isJDE) {
            CreateJDETeamFields =
                idx(jdeTeamRoleFieldMapping, (_) => _.CreateTeamFields) || [];
        }
        const RoleTypeId = formData.RoleTypeId;
        const SystemTypeId = formData.SystemTypeId;
        let fieldsToMap = isOlympus ? CustomerMasterTeamFields : CreateJDETeamFields;
        const allowedFieldList = fieldsToMap.filter((item) => {
            return item.RoleTypeId == RoleTypeId && item.SystemTypeId == SystemTypeId;
        });
        let allowedFields = undefined;
        if (allowedFieldList && allowedFieldList.length > 0) {
            allowedFields = allowedFieldList[0].Fields;
        }

        let filteredSystemFields = [];
        let excludedId = [4, 7, 99, 6];
        if (this.props.staticData && this.props.staticData.SystemType) {
            console.log(this.props.staticData.SystemType);
            this.props.staticData.SystemType.forEach((systemData) => {
                if (!excludedId.includes(systemData.Id)) {
                    filteredSystemFields.push(systemData);
                }
            });
        }
        // console.log(this.props, '=========', this.state);
        if (
            this.state.fetchingWorkflowId === true ||
            this.props.fetching ||
            !this.state.formData
        )
            return (
                <Box
                    display="flex"
                    flex={1}
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                    minHeight="650px"
                    bg="#eff3f6">
                    <FlashMessages
                        toasts={this.props.toasts}
                        onDismiss={this.props.removeMessage}
                        showDuration={7000}
                    />
                    <View>
                        <ActivityIndicator size="large" />
                    </View>
                </Box>
            );

        // console.log(this.props);

        return (
            <ScrollView
                keyboardShouldPersistTaps="always"
                style={{
                    backgroundColor: '#eff3f6',
                    paddingBottom: 75,
                }}>
                <Box m="10px 0 0 5%">
                    <Text
                        style={{
                            fontWeight: '500',
                            fontSize: 24,
                            color: Colors.lightBlue,
                            marginBottom: 20,
                            paddingLeft: 45,
                        }}>
                        CREATE CUSTOMER
                    </Text>
                </Box>

                <FlashMessages
                    toasts={this.props.toasts}
                    onDismiss={this.props.removeMessage}
                    showDuration={7000}
                />
                <View
                    style={{
                        flex: 1,
                        paddingHorizontal: width < 1440 ? 75 : width * 0.1,
                    }}>
                    <Box fullHeight my={2}>
                        <Box
                            flexDirection="row"
                            justifyContent="space-around"
                            my={4}
                            alignItems="center">
                            <FormInput
                                padding="8px 25px 0px 25px"
                                team="general"
                                style={{ lineHeight: '2', paddingBottom: 0 }}
                                flex={1 / 4}
                                required
                                onChange={this.onFieldChange}
                                error={
                                    this.state.formErrors
                                        ? this.state.formErrors['WorkflowTitle']
                                        : null
                                }
                                value={this.state.formData.WorkflowTitle}
                                label="Workflow Title"
                                name="WorkflowTitle"
                                maxLength={40}
                                inline={false}
                                variant="solid"
                            />

                            <FormInput
                                px="25px"
                                flex={1 / 4}
                                label="Workflow Number"
                                name="WorkflowId"
                                style={{ lineHeight: '2' }}
                                variant="outline"
                                type="text"
                                value={formData.WorkflowId || ''}
                                disabled
                            />
                        </Box>
                        <TextInput
                            autoComplete="off"
                            name="hidden"
                            type="text"
                            style={{ display: 'none' }}
                        />

                        {isJDE ? (
                            <JdeGlobalMdmFields
                                replaceCategory
                                formData={{ ...this.state.formData }}
                                staticDropDownData={this.props.staticData}
                                readOnly={false}
                                formErrors={this.state.formErrors}
                                onFieldChange={this.onFieldChange}
                                isSFDC={isSFDC}
                                isInternationalVersion={false}
                                isJDE={formData.SystemTypeId == '5'}
                                isCreate={true}>
                                {/* {!isOlympus && (
                                    <FormRadioGroup
                                        value={
                                            this.state.formData['TaxJurisdiction'] ||
                                            this.props.taxJuriData[0]
                                        }
                                        onChange={this.onFieldChange}
                                        error={
                                            this.state.formErrors
                                                ? this.state.formErrors['TaxJurisdiction']
                                                : null
                                        }
                                        disabled={this.props.taxJuriData.length <= 0}
                                        name="TaxJurisdiction"
                                        label="Tax Jurisdiction: ">
                                        {this.props.taxJuriData.map((taxJuri, index) => (
                                            <FormRadio
                                                key={`taxjuri-${index}`}
                                                value={taxJuri}
                                            />
                                        ))}
                                    </FormRadioGroup>
                                )} */}
                            </JdeGlobalMdmFields>
                        ) : (
                            <GlobalMdmFields
                                replaceCategory
                                formData={{ ...this.state.formData }}
                                staticDropDownData={this.props.staticData}
                                readOnly={false}
                                formErrors={this.state.formErrors}
                                onFieldChange={this.onFieldChange}
                                isInternationalVersion={false}
                                isOlympusModal={false}
                                isSFDC={isSFDC}
                                isOlympus={formData.SystemTypeId == '2'}
                                isJDE={formData.SystemTypeId == '5'}>
                                {!isOlympus && (
                                    <FormRadioGroup
                                        value={
                                            this.state.formData['TaxJurisdiction'] ||
                                            this.props.taxJuriData[0]
                                        }
                                        onChange={this.onFieldChange}
                                        error={
                                            this.state.formErrors
                                                ? this.state.formErrors['TaxJurisdiction']
                                                : null
                                        }
                                        disabled={this.props.taxJuriData.length <= 0}
                                        name="TaxJurisdiction"
                                        label="Tax Jurisdiction: ">
                                        {this.props.taxJuriData.map((taxJuri, index) => (
                                            <FormRadio
                                                key={`taxjuri-${index}`}
                                                value={taxJuri}
                                            />
                                        ))}
                                    </FormRadioGroup>
                                )}
                                {isOlympus &&
                                    (this.state.formData.Country === 'CN' ||
                                        this.state.formData.Country === 'JP') && (
                                        <Button
                                            onPress={(e) => {
                                                this.setState({
                                                    modalVisible: true,
                                                });
                                            }}
                                            title="Show International Versions"
                                        />
                                    )}
                            </GlobalMdmFields>
                        )}

                        <Text
                            mt="42px"
                            mb="20px"
                            ml="5%"
                            fontWeight="light"
                            color="lightBlue"
                            fontSize="28px">
                            SYSTEM FIELDS
                        </Text>
                        {isJDE ? (
                            <JDESystemFields
                                {...this.state}
                                {...this.props}
                                allowedFields={allowedFields}
                                onFieldChange={this.onFieldChange}
                            />
                        ) : (
                            <Box flexDirection="row" justifyContent="center">
                                <Box width={1 / 2} mx="auto" alignItems="center">
                                    <DynamicSelect
                                        arrayOfData={
                                            this.props.staticData &&
                                            this.props.staticData.SystemType
                                                ? filteredSystemFields
                                                : []
                                        }
                                        value={formData.SystemTypeId}
                                        label="System"
                                        name="SystemTypeId"
                                        isRequired
                                        formErrors={
                                            this.state.formErrors
                                                ? this.state.formErrors['SystemTypeId']
                                                : null
                                        }
                                        onFieldChange={this.onFieldChange}
                                        hide={
                                            allowedFields &&
                                            allowedFields.includes('System')
                                        }
                                    />
                                    {!isOlympus && (
                                        <DynamicSelect
                                            hide={isPointman || isOlympus || isJDE}
                                            arrayOfData={
                                                dropDownDatas.DistributionChannelType &&
                                                dropDownDatas.DistributionChannelType.filter(
                                                    (role) =>
                                                        role.systemId ===
                                                        parseInt(formData['SystemTypeId'])
                                                )
                                            }
                                            label="Distribution Channel"
                                            name="DistributionChannelTypeId"
                                            inputProps={isSalesForce}
                                            value={
                                                this.state.formData
                                                    .DistributionChannelTypeId
                                            }
                                            isRequired
                                            formErrors={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                          'DistributionChannelTypeId'
                                                      ]
                                                    : null
                                            }
                                            onFieldChange={this.onFieldChange}
                                        />
                                    )}
                                    {isOlympus && (
                                        <DynamicSelect
                                            hide={isPointman}
                                            arrayOfData={
                                                staticData &&
                                                staticData.OlympusSalesOrgType
                                            }
                                            label="Sales Org"
                                            name="SalesOrgTypeId"
                                            value={formData['SalesOrgTypeId']}
                                            isRequired
                                            formErrors={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                          'SalesOrgTypeId'
                                                      ]
                                                    : null
                                            }
                                            onFieldChange={this.onFieldChange}
                                            inputProps={
                                                this.state.inputPropsForDefaultRules[
                                                    'SalesOrgTypeId'
                                                ] || isSalesForce
                                            }
                                            hide={
                                                allowedFields &&
                                                allowedFields.includes('SalesOrg')
                                            }
                                        />
                                    )}
                                    {isOlympus && (
                                        <>
                                            <FormInput
                                                label="Sold To"
                                                name="SoldTo"
                                                // required={
                                                //     formData['RoleTypeId'] === '2' ||
                                                //     formData['RoleTypeId'] === '3' ||
                                                //     formData['RoleTypeId'] === '4'
                                                // }
                                                hide={
                                                    allowedFields &&
                                                    allowedFields.includes('SoldTo')
                                                }
                                                error={
                                                    this.state.formErrors
                                                        ? this.state.formErrors['SoldTo']
                                                        : null
                                                }
                                                value={this.state.formData['SoldTo']}
                                                onChange={(value, element) => {
                                                    this.onFieldChange(value, element);
                                                }}
                                            />
                                            <DynamicSelect
                                                hide={
                                                    isPointman ||
                                                    (allowedFields &&
                                                        allowedFields.includes(
                                                            'Division'
                                                        ))
                                                }
                                                arrayOfData={
                                                    staticData &&
                                                    staticData.OlympusDivisionType
                                                        ? staticData.OlympusDivisionType &&
                                                          this.getDropdownValues(
                                                              'DistributionChannelTypeId',
                                                              staticData.OlympusDivisionType,
                                                              'DistributionChannelTypeId'
                                                          )
                                                        : []
                                                    //  &&
                                                    // staticData.OlympusDivisionType.filter(
                                                    //     (role) =>
                                                    //         role.systemId ===
                                                    //         parseInt(formData['SystemTypeId'])
                                                    // )
                                                }
                                                label="Division"
                                                name="DivisionTypeId"
                                                inputProps={isSalesForce}
                                                value={this.state.formData.DivisionTypeId}
                                                isRequired
                                                formErrors={
                                                    this.state.formErrors
                                                        ? this.state.formErrors[
                                                              'DivisionTypeId'
                                                          ]
                                                        : null
                                                }
                                                onFieldChange={this.onFieldChange}
                                            />
                                            <DynamicSelect
                                                arrayOfData={
                                                    this.props.staticData &&
                                                    this.props.staticData
                                                        .OlympusLanguageType
                                                        ? this.props.staticData
                                                              .OlympusLanguageType
                                                        : []
                                                }
                                                value={formData.Language}
                                                label="Language"
                                                name="Language"
                                                disabled={
                                                    this.state.formData[
                                                        'SalesOrgTypeId'
                                                    ] == '14' &&
                                                    this.state.formData[
                                                        'DistributionChannelTypeId'
                                                    ] == '3'
                                                }
                                                isRequired
                                                formErrors={
                                                    this.state.formErrors
                                                        ? this.state.formErrors[
                                                              'Language'
                                                          ]
                                                        : null
                                                }
                                                onFieldChange={this.onFieldChange}
                                                hide={
                                                    allowedFields &&
                                                    allowedFields.includes('Language')
                                                }
                                            />
                                        </>
                                    )}
                                    <DynamicSelect
                                        hide={
                                            isPointman ||
                                            isOlympus ||
                                            isJDE ||
                                            (allowedFields &&
                                                allowedFields.includes('Division'))
                                        }
                                        arrayOfData={
                                            dropDownDatas.DivisionType &&
                                            dropDownDatas.DivisionType.filter(
                                                (role) =>
                                                    role.systemId ===
                                                    parseInt(formData['SystemTypeId'])
                                            )
                                        }
                                        label="Division"
                                        name="DivisionTypeId"
                                        inputProps={isSalesForce}
                                        value={this.state.formData.DivisionTypeId}
                                        isRequired
                                        formErrors={
                                            this.state.formErrors
                                                ? this.state.formErrors['DivisionTypeId']
                                                : null
                                        }
                                        onFieldChange={this.onFieldChange}
                                    />

                                    {isOlympus && (
                                        <>
                                            <FormInput
                                                label="URI / Service Code"
                                                name="ServiceCode"
                                                error={
                                                    this.state.formErrors
                                                        ? this.state.formErrors[
                                                              'ServiceCode'
                                                          ]
                                                        : null
                                                }
                                                value={this.state.formData['ServiceCode']}
                                                onChange={(value, element) => {
                                                    this.onFieldChange(value, element);
                                                }}
                                                maxLength={132}
                                                hide={
                                                    allowedFields &&
                                                    allowedFields.includes('ServiceCode')
                                                }
                                            />
                                            <FormInput
                                                label="Unloading Points/Delivery Information"
                                                name="DeliveryInformation"
                                                error={
                                                    this.state.formErrors
                                                        ? this.state.formErrors[
                                                              'DeliveryInformation'
                                                          ]
                                                        : null
                                                }
                                                value={
                                                    this.state.formData[
                                                        'DeliveryInformation'
                                                    ]
                                                }
                                                style={{ height: 'inherit' }}
                                                onChange={(value, element) => {
                                                    this.onFieldChange(value, element);
                                                }}
                                                hide={
                                                    allowedFields &&
                                                    allowedFields.includes(
                                                        'DeliveryInformation'
                                                    )
                                                }
                                            />
                                            <DynamicSelect
                                                arrayOfData={
                                                    staticData &&
                                                    staticData.OlympusReconAccountType
                                                    // &&
                                                    // staticData.OlympusReconAccountType.filter(
                                                    //     (role) =>
                                                    //         role.systemId ===
                                                    //         parseInt(formData['ReconAccount'])
                                                    // )
                                                }
                                                label="Recon Account"
                                                name="ReconAccount"
                                                // inputProps={isSalesForce}
                                                value={
                                                    this.state.formData['ReconAccount']
                                                }
                                                isRequired
                                                formErrors={
                                                    this.state.formErrors
                                                        ? this.state.formErrors[
                                                              'ReconAccount'
                                                          ]
                                                        : null
                                                }
                                                onFieldChange={this.onFieldChange}
                                                hide={
                                                    allowedFields &&
                                                    allowedFields.includes('ReconAccount')
                                                }
                                            />
                                            <DynamicSelect
                                                arrayOfData={
                                                    staticData &&
                                                    staticData.OlympusTermsOfPaymentType
                                                    // &&
                                                    // staticData.OlympusTermsOfPaymentType.filter(
                                                    //     (role) =>
                                                    //         role.systemId ===
                                                    //         parseInt(formData['TermsofPayment'])
                                                    // )
                                                }
                                                label="Terms of Payment"
                                                name="TermsOfPayment"
                                                // inputProps={isSalesForce}
                                                value={
                                                    this.state.formData['TermsOfPayment']
                                                }
                                                isRequired
                                                formErrors={
                                                    this.state.formErrors
                                                        ? this.state.formErrors[
                                                              'TermsOfPayment'
                                                          ]
                                                        : null
                                                }
                                                onFieldChange={this.onFieldChange}
                                                hide={
                                                    allowedFields &&
                                                    allowedFields.includes(
                                                        'TermsOfPayment'
                                                    )
                                                }
                                            />

                                            <DynamicSelect
                                                arrayOfData={
                                                    staticData &&
                                                    staticData.OlympusSalesDistrictType
                                                    // &&
                                                    // staticData.OlympusSalesDistrictType.filter(
                                                    //     (role) =>
                                                    //         role.systemId ===
                                                    //         parseInt(formData['SalesDistrict'])
                                                    // )
                                                }
                                                label="Sales District"
                                                name="SalesDistrict"
                                                // inputProps={isSalesForce}
                                                value={
                                                    this.state.formData['SalesDistrict']
                                                }
                                                isRequired={
                                                    this.state.formData['Country'] ===
                                                    'DE'
                                                }
                                                formErrors={
                                                    this.state.formErrors
                                                        ? this.state.formErrors[
                                                              'SalesDistrict'
                                                          ]
                                                        : null
                                                }
                                                onFieldChange={this.onFieldChange}
                                                hide={
                                                    allowedFields &&
                                                    allowedFields.includes(
                                                        'SalesDistrict'
                                                    )
                                                }
                                            />
                                            <DynamicSelect
                                                arrayOfData={
                                                    this.getSalesOfficeData(
                                                        staticData &&
                                                            staticData.OlympusSalesOfficeType
                                                    )
                                                    // &&
                                                    // staticData.OlympusSalesOfficeType.filter(
                                                    //     (role) =>
                                                    //         role.systemId ===
                                                    //         parseInt(formData['SalesOffice'])
                                                    // )
                                                }
                                                label="Sales Office"
                                                name="SalesOffice"
                                                // inputProps={isSalesForce}
                                                value={this.state.formData['SalesOffice']}
                                                isRequired={
                                                    this.state.formData['Country'] ===
                                                    'DE'
                                                }
                                                formErrors={
                                                    this.state.formErrors
                                                        ? this.state.formErrors[
                                                              'SalesOffice'
                                                          ]
                                                        : null
                                                }
                                                onFieldChange={this.onFieldChange}
                                                hide={
                                                    allowedFields &&
                                                    allowedFields.includes('SalesOffice')
                                                }
                                            />
                                            <DynamicSelect
                                                arrayOfData={
                                                    this.getDropdownValues(
                                                        'SalesOfficeTypeId',
                                                        staticData &&
                                                            staticData.OlympusSalesGroupType,
                                                        'SalesOffice',
                                                        'check'
                                                    )

                                                    // &&
                                                    // staticData.OlympusSalesGroupType.filter(
                                                    //     (role) =>
                                                    //         role.systemId ===
                                                    //         parseInt(formData['SalesGroup'])
                                                    // )
                                                }
                                                label="Sales Group"
                                                name="SalesGroup"
                                                // inputProps={isSalesForce}
                                                value={this.state.formData['SalesGroup']}
                                                isRequired={
                                                    this.state.formData['Country'] ===
                                                    'DE'
                                                }
                                                formErrors={
                                                    this.state.formErrors
                                                        ? this.state.formErrors[
                                                              'SalesGroup'
                                                          ]
                                                        : null
                                                }
                                                onFieldChange={this.onFieldChange}
                                                hide={
                                                    allowedFields &&
                                                    allowedFields.includes('SalesGroup')
                                                }
                                            />
                                            <DynamicSelect
                                                arrayOfData={
                                                    staticData &&
                                                    staticData.OlympusCurrencyType
                                                    // &&
                                                    // staticData.OlympusCurrencyType.filter(
                                                    //     (role) =>
                                                    //         role.systemId ===
                                                    //         parseInt(formData['Currency'])
                                                    // )
                                                }
                                                label="Currency"
                                                name="Currency"
                                                // inputProps={isSalesForce}
                                                value={this.state.formData['Currency']}
                                                isRequired
                                                formErrors={
                                                    this.state.formErrors
                                                        ? this.state.formErrors[
                                                              'Currency'
                                                          ]
                                                        : null
                                                }
                                                onFieldChange={this.onFieldChange}
                                                hide={
                                                    allowedFields &&
                                                    allowedFields.includes('Currency')
                                                }
                                            />
                                            <FormInput
                                                label="Primary GPO"
                                                name="PrimaryGpo"
                                                error={
                                                    this.state.formErrors
                                                        ? this.state.formErrors[
                                                              'PrimaryGpo'
                                                          ]
                                                        : null
                                                }
                                                value={this.state.formData['PrimaryGpo']}
                                                style={{ height: 'inherit' }}
                                                onChange={(value, element) => {
                                                    this.onFieldChange(value, element);
                                                }}
                                                hide={
                                                    allowedFields &&
                                                    allowedFields.includes('PrimaryGPO')
                                                }
                                            />
                                            <FormInput
                                                label="Customer Number"
                                                name="CustomerNumber"
                                                error={
                                                    this.state.formErrors
                                                        ? this.state.formErrors[
                                                              'CustomerNumber'
                                                          ]
                                                        : null
                                                }
                                                value={
                                                    this.state.formData['CustomerNumber']
                                                }
                                                style={{ height: 'inherit' }}
                                                onChange={(value, element) => {
                                                    this.onFieldChange(value, element);
                                                }}
                                                required={true}
                                                hide={
                                                    allowedFields &&
                                                    allowedFields.includes(
                                                        'CustomerNumber'
                                                    )
                                                }
                                            />
                                        </>
                                    )}
                                </Box>
                                <Box width={1 / 2} mx="auto" alignItems="center">
                                    <DynamicSelect
                                        hide={
                                            isOlympus ||
                                            isJDE ||
                                            (allowedFields &&
                                                allowedFields.includes('Role'))
                                        }
                                        arrayOfData={
                                            dropDownDatas.RoleTypeId &&
                                            dropDownDatas.RoleTypeId.filter(
                                                (role) =>
                                                    role.systemId ===
                                                        parseInt(
                                                            formData['SystemTypeId']
                                                        ) && role.id <= 6
                                            )
                                        }
                                        label="Role"
                                        name="RoleTypeId"
                                        inputProps={isSalesForce}
                                        isRequired
                                        formErrors={
                                            this.state.formErrors
                                                ? this.state.formErrors['RoleTypeId']
                                                : null
                                        }
                                        onFieldChange={this.onFieldChange}
                                    />
                                    {isOlympus && (
                                        <DynamicSelect
                                            arrayOfData={
                                                dropDownDatas.OlympusRoleType &&
                                                dropDownDatas.OlympusRoleType
                                            }
                                            label="Role"
                                            name="RoleTypeId"
                                            inputProps={isSalesForce}
                                            isRequired
                                            formErrors={
                                                this.state.formErrors
                                                    ? this.state.formErrors['RoleTypeId']
                                                    : null
                                            }
                                            onFieldChange={this.onFieldChange}
                                            hide={
                                                allowedFields &&
                                                allowedFields.includes('Role')
                                            }
                                        />
                                    )}
                                    {isJDE && (
                                        <>
                                            <DynamicSelect
                                                arrayOfData={
                                                    dropDownDatas.OlympusRoleType &&
                                                    dropDownDatas.OlympusRoleType
                                                }
                                                value={formData.RoleTypeId}
                                                label="Role"
                                                name="RoleTypeId"
                                                isRequired
                                                formErrors={
                                                    this.state.formErrors
                                                        ? this.state.formErrors[
                                                              'RoleTypeId'
                                                          ]
                                                        : null
                                                }
                                                onFieldChange={this.onFieldChange}
                                                // hide={
                                                //     allowedFields && !allowedFields.includes('RoleTypeId')
                                                // }
                                            />
                                            <DynamicSelect
                                                arrayOfData={
                                                    this.props.staticData &&
                                                    this.props.staticData.SystemType
                                                        ? this.props.staticData.SystemType
                                                        : []
                                                }
                                                value={formData.PriceGroupTypeId}
                                                label="Price Group"
                                                name="PriceGroupTypeId"
                                                isRequired
                                                formErrors={
                                                    this.state.formErrors
                                                        ? this.state.formErrors[
                                                              'PriceGroupTypeId'
                                                          ]
                                                        : null
                                                }
                                                onFieldChange={this.onFieldChange}
                                                // hide={
                                                //     allowedFields && !allowedFields.includes('PriceGroupTypeId')
                                                // }
                                            />
                                            <DynamicSelect
                                                arrayOfData={
                                                    this.props.staticData &&
                                                    this.props.staticData.SystemType
                                                        ? this.props.staticData.SystemType
                                                        : []
                                                }
                                                value={formData.IncoTermsTypeId}
                                                label="IncoTerms"
                                                name="IncoTermsTypeId"
                                                isRequired
                                                formErrors={
                                                    this.state.formErrors
                                                        ? this.state.formErrors[
                                                              'IncoTermsTypeId'
                                                          ]
                                                        : null
                                                }
                                                onFieldChange={this.onFieldChange}
                                                // hide={
                                                //     allowedFields && !allowedFields.includes('IncoTermsTypeId')
                                                // }
                                            />
                                            <DynamicSelect
                                                arrayOfData={
                                                    this.props.staticData &&
                                                    this.props.staticData.SystemType
                                                        ? this.props.staticData.SystemType
                                                        : []
                                                }
                                                value={formData.CarrierTypeId}
                                                label="Carrier"
                                                name="CarrierTypeId"
                                                isRequired
                                                formErrors={
                                                    this.state.formErrors
                                                        ? this.state.formErrors[
                                                              'CarrierTypeId'
                                                          ]
                                                        : null
                                                }
                                                onFieldChange={this.onFieldChange}
                                                // hide={
                                                //     allowedFields && !allowedFields.includes('CarrierTypeId')
                                                // }
                                            />
                                            <DynamicSelect
                                                arrayOfData={
                                                    this.props.staticData &&
                                                    this.props.staticData.SystemType
                                                        ? this.props.staticData.SystemType
                                                        : []
                                                }
                                                value={formData.SalesRepsTypeId}
                                                label="Account / Sales Reps"
                                                name="SalesRepsTypeId"
                                                isRequired
                                                formErrors={
                                                    this.state.formErrors
                                                        ? this.state.formErrors[
                                                              'SalesRepsTypeId'
                                                          ]
                                                        : null
                                                }
                                                onFieldChange={this.onFieldChange}
                                                // hide={
                                                //     allowedFields && !allowedFields.includes('SalesRepsTypeId')
                                                // }
                                            />
                                            <DynamicSelect
                                                arrayOfData={
                                                    this.props.staticData &&
                                                    this.props.staticData.SystemType
                                                        ? this.props.staticData.SystemType
                                                        : []
                                                }
                                                value={formData.CatCode08TypeId}
                                                label="Cat Code 08 (Sales Area)"
                                                name="CatCode08TypeId"
                                                isRequired
                                                formErrors={
                                                    this.state.formErrors
                                                        ? this.state.formErrors[
                                                              'CatCode08TypeId'
                                                          ]
                                                        : null
                                                }
                                                onFieldChange={this.onFieldChange}
                                                // hide={
                                                //     allowedFields && !allowedFields.includes('CatCode08TypeId')
                                                // }
                                            />
                                            <DynamicSelect
                                                arrayOfData={
                                                    this.props.staticData &&
                                                    this.props.staticData.SystemType
                                                        ? this.props.staticData.SystemType
                                                        : []
                                                }
                                                value={formData.CatCode09TypeId}
                                                label="Cat Code 09 (Region)"
                                                name="CatCode09TypeId"
                                                isRequired
                                                formErrors={
                                                    this.state.formErrors
                                                        ? this.state.formErrors[
                                                              'CatCode09TypeId'
                                                          ]
                                                        : null
                                                }
                                                onFieldChange={this.onFieldChange}
                                                // hide={
                                                //     allowedFields && !allowedFields.includes('CatCode09TypeId')
                                                // }
                                            />
                                            <DynamicSelect
                                                arrayOfData={
                                                    this.props.staticData &&
                                                    this.props.staticData.SystemType
                                                        ? this.props.staticData.SystemType
                                                        : []
                                                }
                                                value={formData.CatCode10TypeId}
                                                label="Cat Code 10 (Language for documents)"
                                                name="CatCode10TypeId"
                                                isRequired
                                                formErrors={
                                                    this.state.formErrors
                                                        ? this.state.formErrors[
                                                              'CatCode10TypeId'
                                                          ]
                                                        : null
                                                }
                                                onFieldChange={this.onFieldChange}
                                                // hide={
                                                //     allowedFields && !allowedFields.includes('CatCode10TypeId')
                                                // }
                                            />
                                            <DynamicSelect
                                                arrayOfData={
                                                    this.props.staticData &&
                                                    this.props.staticData.SystemType
                                                        ? this.props.staticData.SystemType
                                                        : []
                                                }
                                                value={formData.CatCode23TypeId}
                                                label="Cat Code 23 (GPO)"
                                                name="CatCode23TypeId"
                                                formErrors={
                                                    this.state.formErrors
                                                        ? this.state.formErrors[
                                                              'CatCode23TypeId'
                                                          ]
                                                        : null
                                                }
                                                onFieldChange={this.onFieldChange}
                                                // hide={
                                                //     allowedFields && !allowedFields.includes('CatCode23TypeId')
                                                // }
                                            />
                                            <FormInput
                                                label="Effective Date"
                                                min={moment().format('YYYY-MM-DD')}
                                                name="EffectiveDate"
                                                error={
                                                    this.state.formErrors
                                                        ? this.state.formErrors[
                                                              'EffectiveDate'
                                                          ]
                                                        : null
                                                }
                                                value={
                                                    this.state.formData['EffectiveDate']
                                                }
                                                required
                                                type="date"
                                                variant="outline"
                                                style={{ height: 'inherit' }}
                                                onChange={(value, element) => {
                                                    this.onFieldChange(
                                                        new Date(value)
                                                            .toJSON()
                                                            .slice(0, 19),
                                                        element
                                                    );
                                                }}
                                            />
                                        </>
                                    )}
                                    {isOlympus && (
                                        <DynamicSelect
                                            hide={
                                                isPointman ||
                                                (allowedFields &&
                                                    allowedFields.includes(
                                                        'DistributionChannel'
                                                    ))
                                            }
                                            arrayOfData={
                                                staticData &&
                                                staticData.OlympusDistributionChannelType &&
                                                this.getDropdownValues(
                                                    'SalesOrgTypeId',
                                                    staticData &&
                                                        staticData.OlympusDistributionChannelType,
                                                    'SalesOrgTypeId'
                                                )
                                                // &&
                                                // staticData.OlympusDistributionChannelType.filter(
                                                //     (role) =>
                                                //         role.systemId ===
                                                //         parseInt(formData['SystemTypeId'])
                                                // )
                                            }
                                            label="Distribution Channel"
                                            name="DistributionChannelTypeId"
                                            inputProps={isSalesForce}
                                            value={
                                                this.state.formData
                                                    .DistributionChannelTypeId
                                            }
                                            isRequired
                                            formErrors={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                          'DistributionChannelTypeId'
                                                      ]
                                                    : null
                                            }
                                            onFieldChange={this.onFieldChange}
                                        />
                                    )}
                                    {isPointman &&
                                        parseInt(formData.RoleTypeId) === 2 && (
                                            <FormInput
                                                label="Bill to Account or Workflow Number"
                                                name="BillToPartner"
                                                value={
                                                    this.state.formData.BillToPartner !==
                                                        undefined &&
                                                    this.state.formData.BillToPartner
                                                }
                                                error={
                                                    this.state.formErrors
                                                        ? this.state.formErrors[
                                                              'BillToPartner'
                                                          ]
                                                        : null
                                                }
                                                maxLength={17}
                                                onChange={this.onFieldChange}
                                                type="text"
                                                required
                                                hide={
                                                    allowedFields &&
                                                    allowedFields.includes(
                                                        'BillToPartner'
                                                    )
                                                }
                                            />
                                        )}
                                    <DynamicSelect
                                        hide={
                                            isPointman ||
                                            isOlympus ||
                                            isJDE ||
                                            (allowedFields &&
                                                allowedFields.includes('SalesOrg'))
                                        }
                                        arrayOfData={salesOrgData.filter(
                                            (sorg) =>
                                                sorg.systemId ===
                                                parseInt(formData['SystemTypeId'])
                                        )}
                                        label="Sales Org"
                                        name="SalesOrgTypeId"
                                        value={formData['SalesOrgTypeId']}
                                        isRequired={
                                            this.state.formData['Country'] === 'DE'
                                        }
                                        formErrors={
                                            this.state.formErrors
                                                ? this.state.formErrors['SalesOrgTypeId']
                                                : null
                                        }
                                        onFieldChange={this.onFieldChange}
                                        inputProps={
                                            this.state.inputPropsForDefaultRules[
                                                'SalesOrgTypeId'
                                            ] || isSalesForce
                                        }
                                    />

                                    <DynamicSelect
                                        hide={
                                            isPointman ||
                                            isOlympus ||
                                            isJDE ||
                                            (allowedFields &&
                                                allowedFields.includes('CompanyCode'))
                                        }
                                        arrayOfData={
                                            dropDownDatas.CompanyCodeTypeId &&
                                            dropDownDatas.CompanyCodeTypeId.filter(
                                                (role) =>
                                                    role.systemId ===
                                                    parseInt(formData['SystemTypeId'])
                                            )
                                        }
                                        label="Company Code"
                                        name="CompanyCodeTypeId"
                                        inputProps={
                                            this.state.inputPropsForDefaultRules[
                                                'CompanyCodeTypeId'
                                            ] || isSalesForce
                                        }
                                        value={formData['CompanyCodeTypeId']}
                                        isRequired
                                        formErrors={
                                            this.state.formErrors
                                                ? this.state.formErrors[
                                                      'CompanyCodeTypeId'
                                                  ]
                                                : null
                                        }
                                        onFieldChange={this.onFieldChange}
                                    />
                                    {isOlympus && (
                                        <>
                                            <DynamicSelect
                                                arrayOfData={
                                                    staticData &&
                                                    staticData.OlympusPriceGroupType
                                                }
                                                label="Price Group"
                                                name="PriceGroup"
                                                // isRequired={true}
                                                formErrors={
                                                    this.state.formErrors
                                                        ? this.state.formErrors[
                                                              'PriceGroup'
                                                          ]
                                                        : null
                                                }
                                                onFieldChange={this.onFieldChange}
                                                value={
                                                    this.state.formData
                                                        ? this.state.formData[
                                                              'PriceGroup'
                                                          ]
                                                        : null
                                                }
                                                hide={
                                                    allowedFields &&
                                                    allowedFields.includes('PriceGroup')
                                                }
                                                // inputProps={inputReadonlyProps}
                                            />
                                            <DynamicSelect
                                                arrayOfData={
                                                    staticData &&
                                                    this.deliveryPlantMapping(
                                                        staticData.OlympusDeliveringPlantType
                                                    )
                                                }
                                                label="Delivering Plant"
                                                name="DeliveringPlant"
                                                hide={
                                                    allowedFields &&
                                                    allowedFields.includes(
                                                        'DeliveringPlant'
                                                    )
                                                }
                                                formErrors={
                                                    this.state.formErrors
                                                        ? this.state.formErrors[
                                                              'DeliveringPlant'
                                                          ]
                                                        : null
                                                }
                                                onFieldChange={this.onFieldChange}
                                                value={
                                                    this.state.formData
                                                        ? this.state.formData[
                                                              'DeliveringPlant'
                                                          ]
                                                        : null
                                                }
                                                // inputProps={inputReadonlyProps}
                                            />
                                        </>
                                    )}

                                    {isOlympus && (
                                        <>
                                            <DynamicSelect
                                                hide={
                                                    isPointman ||
                                                    (allowedFields &&
                                                        allowedFields.includes(
                                                            'CompanyCode'
                                                        ))
                                                }
                                                arrayOfData={
                                                    this.props.staticData &&
                                                    this.props.staticData
                                                        .OlympusCompanyCodeType &&
                                                    this.getDropdownValues(
                                                        'SalesOrgTypeId',
                                                        staticData &&
                                                            staticData.OlympusCompanyCodeType,
                                                        'SalesOrgTypeId'
                                                    )
                                                    // &&
                                                    // this.props.staticData.CompanyCodeType.filter(
                                                    //     (role) =>
                                                    //         role.Id ===
                                                    //         parseInt(formData['CompanyCodeTypeId'])
                                                    // )
                                                }
                                                label="Company Code"
                                                name="CompanyCodeTypeId"
                                                inputProps={
                                                    this.state.inputPropsForDefaultRules[
                                                        'CompanyCodeTypeId'
                                                    ] || isSalesForce
                                                }
                                                value={formData['CompanyCodeTypeId']}
                                                isRequired
                                                formErrors={
                                                    this.state.formErrors
                                                        ? this.state.formErrors[
                                                              'CompanyCodeTypeId'
                                                          ]
                                                        : null
                                                }
                                                onFieldChange={this.onFieldChange}
                                            />
                                        </>
                                    )}

                                    {isOlympus && (
                                        <>
                                            <FormInput
                                                label="Tax Number 4"
                                                name="TaxNumber4"
                                                error={
                                                    this.state.formErrors
                                                        ? this.state.formErrors[
                                                              'TaxNumber4'
                                                          ]
                                                        : null
                                                }
                                                value={this.state.formData['TaxNumber4']}
                                                required={
                                                    false
                                                    // this.state.formData['Country'] === 'IT'
                                                }
                                                style={{ height: 'inherit' }}
                                                onChange={(value, element) => {
                                                    this.onFieldChange(value, element);
                                                }}
                                                maxLength={18}
                                                hide={
                                                    allowedFields &&
                                                    allowedFields.includes('TaxNumber4')
                                                }
                                            />
                                            <DynamicSelect
                                                arrayOfData={
                                                    this.props.staticData &&
                                                    this.props.staticData.OlympusURIType
                                                    // &&
                                                    // this.props.staticData.OlympusCustomerClassType.filter(
                                                    //     (role) =>
                                                    //         role.Id ===
                                                    //         parseInt(formData['CustomerClass'])
                                                    // )
                                                }
                                                label="URI Type"
                                                name="URIType"
                                                // inputProps={isSalesForce}
                                                value={this.state.formData.URIType}
                                                isRequired={
                                                    this.state.formData['ServiceCode']
                                                }
                                                formErrors={
                                                    this.state.formErrors
                                                        ? this.state.formErrors['URIType']
                                                        : null
                                                }
                                                onFieldChange={this.onFieldChange}
                                                hide={
                                                    allowedFields &&
                                                    allowedFields.includes('URIType')
                                                }
                                            />
                                            <DynamicSelect
                                                arrayOfData={
                                                    this.props.staticData &&
                                                    this.props.staticData
                                                        .OlympusCustomerClassType
                                                    // &&
                                                    // this.props.staticData.OlympusCustomerClassType.filter(
                                                    //     (role) =>
                                                    //         role.Id ===
                                                    //         parseInt(formData['CustomerClass'])
                                                    // )
                                                }
                                                label="Customer Class"
                                                name="CustomerClass"
                                                // inputProps={isSalesForce}
                                                value={this.state.formData.CustomerClass}
                                                isRequired
                                                formErrors={
                                                    this.state.formErrors
                                                        ? this.state.formErrors[
                                                              'CustomerClass'
                                                          ]
                                                        : null
                                                }
                                                onFieldChange={this.onFieldChange}
                                                hide={
                                                    allowedFields &&
                                                    allowedFields.includes(
                                                        'CustomerClass'
                                                    )
                                                }
                                            />
                                            <DynamicSelect
                                                arrayOfData={
                                                    this.props.staticData &&
                                                    this.props.staticData
                                                        .OlympusIncoTermsType
                                                    // &&
                                                    // this.props.staticData.OlympusIncoTermsType.filter(
                                                    //     (role) =>
                                                    //         role.Id ===
                                                    //         parseInt(formData['Incoterms1'])
                                                    // )
                                                }
                                                label="Incoterms1"
                                                name="Incoterms1"
                                                // inputProps={isSalesForce}
                                                value={this.state.formData['Incoterms1']}
                                                isRequired
                                                formErrors={
                                                    this.state.formErrors
                                                        ? this.state.formErrors[
                                                              'Incoterms1'
                                                          ]
                                                        : null
                                                }
                                                onFieldChange={this.onFieldChange}
                                                hide={
                                                    allowedFields &&
                                                    allowedFields.includes('Incoterms1')
                                                }
                                            />
                                            <FormInput
                                                label="Place Of Destination"
                                                name="PlaceOfDestination"
                                                error={
                                                    this.state.formErrors
                                                        ? this.state.formErrors[
                                                              'PlaceOfDestination'
                                                          ]
                                                        : null
                                                }
                                                value={
                                                    this.state.formData[
                                                        'PlaceOfDestination'
                                                    ]
                                                }
                                                style={{ height: 'inherit' }}
                                                onChange={(value, element) => {
                                                    this.onFieldChange(value, element);
                                                }}
                                                hide={
                                                    allowedFields &&
                                                    allowedFields.includes(
                                                        'PlaceOfDestination'
                                                    )
                                                }
                                            />
                                            <DynamicSelect
                                                arrayOfData={
                                                    staticData.OlympusAcctAssignmentGroupType &&
                                                    staticData.OlympusAcctAssignmentGroupType
                                                    // &&
                                                    // dropDownDatas.DivisionType.filter(
                                                    //     (role) =>
                                                    //         role.systemId ===
                                                    //         parseInt(formData['AcctAssignmentGroup'])
                                                    // )
                                                }
                                                label="Acct Assignment Group"
                                                name="AcctAssignmentGroup"
                                                // inputProps={isSalesForce}
                                                value={
                                                    this.state.formData[
                                                        'AcctAssignmentGroup'
                                                    ]
                                                }
                                                isRequired
                                                formErrors={
                                                    this.state.formErrors
                                                        ? this.state.formErrors[
                                                              'AcctAssignmentGroup'
                                                          ]
                                                        : null
                                                }
                                                onFieldChange={this.onFieldChange}
                                                hide={
                                                    allowedFields &&
                                                    allowedFields.includes(
                                                        'AcctAssignmentGroup'
                                                    )
                                                }
                                            />
                                            <DynamicSelect
                                                arrayOfData={
                                                    staticData &&
                                                    staticData.OlympusTaxClassificationType
                                                    // &&
                                                    // staticData.OlympusTaxClassificationType.filter(
                                                    //     (role) =>
                                                    //         role.systemId ===
                                                    //         parseInt(formData['TaxClassification'])
                                                    // )
                                                }
                                                label="Tax Classification"
                                                name="TaxClassification"
                                                // inputProps={isSalesForce}
                                                value={
                                                    this.state.formData[
                                                        'TaxClassification'
                                                    ]
                                                }
                                                isRequired
                                                formErrors={
                                                    this.state.formErrors
                                                        ? this.state.formErrors[
                                                              'TaxClasssification'
                                                          ]
                                                        : null
                                                }
                                                onFieldChange={this.onFieldChange}
                                                hide={
                                                    allowedFields &&
                                                    allowedFields.includes(
                                                        'TaxClassification'
                                                    )
                                                }
                                            />

                                            <FormInput
                                                label="Requested Credit Limit"
                                                name="RequestedCreditLimit"
                                                error={
                                                    this.state.formErrors
                                                        ? this.state.formErrors[
                                                              'RequestedCreditLimit'
                                                          ]
                                                        : null
                                                }
                                                value={
                                                    this.state.formData[
                                                        'RequestedCreditLimit'
                                                    ]
                                                }
                                                style={{ height: 'inherit' }}
                                                onChange={(value, element) => {
                                                    this.validateNumericKeypad(
                                                        value,
                                                        element
                                                    );
                                                }}
                                                hide={
                                                    allowedFields &&
                                                    allowedFields.includes(
                                                        'RequestedCreditLimit'
                                                    )
                                                }
                                            />

                                            <DynamicSelect
                                                arrayOfData={
                                                    staticData &&
                                                    staticData.OlympusPriorityType
                                                    // &&
                                                    // staticData.OlympusPriorityType.filter(
                                                    //     (role) =>
                                                    //         role.systemId ===
                                                    //         parseInt(formData['Priority'])
                                                    // )
                                                }
                                                label="Priority"
                                                name="Priority"
                                                // inputProps={isSalesForce}
                                                value={this.state.formData['Priority']}
                                                isRequired
                                                formErrors={
                                                    this.state.formErrors
                                                        ? this.state.formErrors[
                                                              'Priority'
                                                          ]
                                                        : null
                                                }
                                                onFieldChange={this.onFieldChange}
                                                hide={
                                                    allowedFields &&
                                                    allowedFields.includes('Priority')
                                                }
                                            />
                                        </>
                                    )}
                                    <FormInput
                                        hide={isOlympus || isJDE}
                                        label="Effective Date"
                                        min={moment().format('YYYY-MM-DD')}
                                        name="EffectiveDate"
                                        error={
                                            this.state.formErrors
                                                ? this.state.formErrors['EffectiveDate']
                                                : null
                                        }
                                        value={this.state.formData['EffectiveDate']}
                                        required
                                        type="date"
                                        variant="outline"
                                        style={{ height: 'inherit' }}
                                        onChange={(value, element) => {
                                            this.onFieldChange(
                                                new Date(value).toJSON().slice(0, 19),
                                                element
                                            );
                                        }}
                                    />
                                    {isOlympus && (
                                        <FormInput
                                            label="Effective Date"
                                            min={moment().format('YYYY-MM-DD')}
                                            name="EffectiveDate"
                                            error={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                          'EffectiveDate'
                                                      ]
                                                    : null
                                            }
                                            value={this.state.formData['EffectiveDate']}
                                            required
                                            type="date"
                                            style={{ height: 'inherit' }}
                                            onChange={(value, element) => {
                                                this.onFieldChange(
                                                    new Date(value).toJSON().slice(0, 19),
                                                    element
                                                );
                                            }}
                                            hide={
                                                allowedFields &&
                                                allowedFields.includes('EffectiveDate')
                                            }
                                        />
                                    )}
                                </Box>
                            </Box>
                        )}
                        <Box mt={2} flexDirection="row" justifyContent="center">
                            <Box width={0.79} mx="auto" alignItems="center">
                                <FormInput
                                    maxWidth="98%"
                                    label="Purpose of Request"
                                    style={{ height: '100px' }}
                                    name="Purpose"
                                    value={this.state.formData.Purpose}
                                    maxLength={1000}
                                    multiline
                                    numberOfLines={4}
                                    onChange={this.onFieldChange}
                                    value={formData.Purpose || ''}
                                />
                            </Box>
                        </Box>
                        <Box mt={2} flexDirection="row" justifyContent="flex-start">
                            <Box width={0.79} mx="auto" alignItems="flex-start">
                                {this.state.formData.RoleTypeId &&
                                    this.state.formData.SystemTypeId != 2 &&
                                    this.state.formData.SystemTypeId != 5 && (
                                        <StyledText
                                            style={{ fontWeight: '500', color: 'red' }}>
                                            Recommended Attachments:{' '}
                                            {this.state.attachment_text}{' '}
                                        </StyledText>
                                    )}
                            </Box>
                        </Box>
                        <FilesList
                            formErrors={this.state.fileErrors}
                            files={selectedFilesIds.map((id) => selectedFiles[id])}
                            onChange={(value, id) => {
                                this.setState({
                                    selectedFiles: {
                                        ...selectedFiles,
                                        [id]: {
                                            ...selectedFiles[id],
                                            DocumentType: parseInt(value),
                                        },
                                    },
                                });
                            }}
                            onRemove={(value, id) => this.removeFile(id)}
                        />
                    </Box>
                    <Flex
                        justifyEnd
                        alignCenter
                        style={{
                            paddingTop: 65,
                            flexDirection: 'row',
                            alignItems: 'center',
                            paddingLeft: 10,
                            paddingRight: 15,
                            marginTop: 20,
                            marginBottom: 10,
                            marginHorizontal: 25,
                        }}>
                        <label htmlFor="file-upload" className="custom-file-upload">
                            <MaterialIcons name="attach-file" size={18} color="#fff" />
                        </label>
                        <input
                            id="file-upload"
                            key={this.state.fileinputkey}
                            type="file"
                            onChange={this.selectFile}
                        />

                        <Button
                            onPress={() => {
                                this.props.history.goBack();
                            }}
                            title="Cancel"
                        />
                        <Button
                            disabled={disable_submit}
                            onPress={(e) => {
                                this.setState({ formErrors: {} }, () => {
                                    this.onSubmit(
                                        e,
                                        isOlympus
                                            ? createOlympusCustomerRules
                                            : isJDE
                                            ? createJDECustomerRules
                                            : createCustomerRules,
                                        true
                                    );
                                });
                            }}
                            title="Submit"
                        />
                    </Flex>
                </View>
                <View>
                    <Modal transparent={true} isVisible={this.state.modalVisible}>
                        <View
                            style={{
                                backgroundColor: 'white',
                                padding: '1%',
                                maxHeight: '95vh',
                                overflow: 'auto',
                            }}>
                            {this.state.modalVisible && (
                                <GlobalMdmFields
                                    replaceCategory
                                    staticDropDownData={this.props.staticData}
                                    formData={{ ...this.state.internationalVersionData }}
                                    readOnly={false}
                                    formErrors={this.state.formErrors}
                                    isInternationalVersion={true}
                                    isOlympus={true}
                                    isOlympusModal={true}
                                    saveInternationalVersion={
                                        this.saveInternationalVersion
                                    }
                                    onFieldChange={
                                        this.internationVersionDataChange
                                    }></GlobalMdmFields>
                            )}
                        </View>
                    </Modal>
                </View>
            </ScrollView>
        );
    }
}

class Default extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const props = this.props;

        return (
            <DimensionAware
                render={(dimensions) => (
                    <Page
                        {...{
                            ...props,
                            width: getWindowWidth(dimensions),
                            height: getWindowHeight(dimensions),
                            marginBottom: 25,
                        }}
                    />
                )}
            />
        );
    }
}

const styles = StyleSheet.create({
    progressIndicator: {
        flex: 1,
        paddingBottom: 5,
        flexDirection: 'row-reverse',
        alignItems: 'flex-end',
    },
    statusText: {
        fontSize: 15,
        color: '#1D4289',
        fontFamily: 'Poppins',
        textAlign: 'center',
        marginTop: 20,
    },
});

const mapStateToProps = ({ customer, toasts, staticData }) => {
    const {
        fetching: fetchingCustomer,
        error,
        customerdata,
        alert,
        taxJuriData,
    } = customer;
    const { data, fetching: staticFetching } = staticData;

    return {
        error,
        fetching: fetchingCustomer || staticFetching,
        customerdata,
        alert,
        taxJuriData,
        toasts,
        staticData: data,
    };
};

export default connect(mapStateToProps, {
    createCustomer,
    getTaxJurisdictionData,
    removeMessage,
    hideMessage,
    getStaticRequest,
})(Default);
