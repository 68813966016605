import React from 'react';
import {
    ScrollView,
    View,
    StyleSheet,
    TouchableOpacity,
    ActivityIndicator,
    Image,
} from 'react-native';
import {
    DimensionAware,
    getWindowHeight,
    getWindowWidth,
} from 'react-native-dimension-aware';
import { Colors, StyledText } from '../../../../theme/Index';
import { Flex, Column, Card, Button, Box, Text } from '../../../../components/common';
import { FormInput } from '../../../../components/form';
import {
    saveApolloUpdateMyTaskGlobalTrade,
    getCustomerGlobalData,
} from '../../../../appRedux/actions';
import { connect } from 'react-redux';
import Loading from '../../../../components/Loading';
import FlashMessage, { FlashMessages } from '../../../../components/FlashMessage';
import MultiColorProgressBar from '../../../../components/MultiColorProgressBar';
import {
    getStatusBarData,
    getFunctionalGroupData,
} from '../../../../appRedux/actions/Workflow';
import {
    RoleType,
    SalesOrgType,
    SystemType,
    DistributionChannelType,
    DivisionType,
    CompanyCodeType,
} from '../../../../constants/WorkflowEnums';
import GlobalMdmFields from '../../../../components/GlobalMdmFields';
import DeltaField from '../../../../components/DeltaField';
import { getMockUpdateTaskDetail } from '../../../../appRedux/sagas/config';
import BreadCrumbs from '../../../../components/BreadCrumbs';
import {
    myTaskCreditExtend,
    mytaskCreditRules,
    mytaskCustomerMasterRules,
    rejectRules,
} from '../../../../constants/FieldRules';
import Modal from 'modal-enhanced-react-native-web';
import { MaterialIcons } from '@expo/vector-icons';
import { getCustomerFromSAP, getStaticRequest } from '../../../../appRedux/actions';
import { removeMessage } from '../../../../appRedux/actions/Toast';
import FilesList from '../../../../components/FilesList';
import { yupAllFieldsValidation } from '../../../../constants/utils';
import * as moment from 'moment';
import idx from 'idx';

class Page extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            WorkflowId: this.props.location.state.WorkflowId,
            TaskId: this.props.location.state.TaskId,
            data: {},
            formData: {},
            rejectionRequired: false,
            selectedFiles: {},
            selectedFilesIds: [],
            files: [],
            fileinputkey: Date.now(),
        };
    }
    componentDidMount() {
        let { state: wf } = this.props.location;
        let { action } = this.props.match.params;
        this.props.getStaticRequest([42, 43, 82]);
        let postJson = {
            workflowId: wf.WorkflowId,
            fuctionalGroup: 'globaltrade',
            taskId: wf.TaskId,
        };
        if (action === 'update') {
            this.props.getCustomerGlobalData({
                WorkflowId: wf.WorkflowId,
                userId: localStorage.getItem('userId'),
            });
        }
        if (action === 'extend-salesorg') {
            // this.props.getFunctionalGroupData(postJson);
            this.props.getCustomerFromSAP({
                WorkflowId: wf.WorkflowId,
                TaskId: wf.TaskId,
                CustomerNumber: '',
                DivisionTypeId: 0,
                SystemTypeId: 0,
                DistributionChannelTypeId: 0,
                CompanyCodeTypeId: '',
                SalesOrgTypeId: 0,
            });
        }
        this.props.getStatusBarData(postJson);
    }

    onFieldChange = (value, e) => {
        this.setState(
            {
                formData: {
                    ...this.state.formData,
                    [e.target.name]: e.target.value,
                },
            },
            this.updateSchema
        );
    };

    submitForm = (reject = false) => {
        const userId = localStorage.getItem('userId');
        const { TaskId, WorkflowId, selectedFiles, selectedFilesIds } = this.state;

        let { action } = this.props.match.params;
        try {
            const { location } = this.props;
            const { TaskId, WorkflowId } = this.state;
            const WorkflowTaskModel = {
                RejectReason: reject ? this.state.formData.RejectionReason : '',
                AdditionalNotes: this.state.formData.AdditionalNotes,
                TaskId: TaskId,
                UserId: userId,
                WorkflowId: WorkflowId,
            };
            if (action === 'update') {
                WorkflowTaskModel['WorkflowTaskOperationType'] = !reject ? 1 : 4;
            } else if (action === 'extend-salesorg') {
                WorkflowTaskModel['WorkflowTaskOperationType'] = !reject ? 1 : 2;
            }
            let postData = {};
            postData['formData'] = {
                WorkflowTaskModel,
                action,
                Deltas: this.props.denormalizedDeltas || null,
            };
            postData['files'] = selectedFilesIds.map((id) => selectedFiles[id]);
            // console.log('postData',postData)
            this.props.saveApolloUpdateMyTaskGlobalTrade(postData);
            this.scrollToTop();
        } catch (error) {
            console.log('form error', error);
        }
    };

    setFormErrors = (errors) => {
        const { formErrors } = this.state;
        this.setState({ formErrors: errors }, () => window.scrollTo(0, 0));
    };

    onSubmit = (event, reject, schema) => {
        let { formData, selectedFilesIds, selectedFiles } = this.state;
        let fileErrors = {};
        let errors = false;
        selectedFilesIds.map((id) => {
            if (selectedFiles[id] && selectedFiles[id].DocumentType <= 0) {
                fileErrors[id] = 'Document Type Required for file';
                errors = true;
            }
        });

        this.setState({ fileErrors, isFileErrors: errors });

        this.setState(
            {
                modalVisible: false,
                formData: {
                    ...this.state.formData,
                    RejectionButton: reject,
                },
            },
            () => {
                if (reject)
                    yupAllFieldsValidation(
                        this.state.formData,
                        schema,
                        (...rest) => {
                            if (this.state.isFileErrors === false) this.submitForm(true);
                        },
                        this.setFormErrors
                    );
                else if (this.state.isFileErrors === false) {
                    this.submitForm(reject);
                }
            }
        );
    };

    selectFiles = (events) => {
        event.preventDefault();

        const { selectedFilesIds, selectedFiles } = this.state;
        const id = events.target.files[0].name;

        this.setState({
            selectedFiles: {
                ...selectedFiles,
                [id]: {
                    data: events.target.files[0],
                    DocumentName: events.target.files[0].name,
                    DocumentType: 0,
                },
            },
            selectedFilesIds: [...selectedFilesIds, id],
            filename: events.target.files[0].name,
            fileinputkey: Date.now(),
        });
    };

    removeFile = (id) => {
        event.preventDefault();

        var selectedFilesDict = this.state.selectedFiles;
        var selectedFilesIdsList = this.state.selectedFilesIds;
        // remove the existing state
        delete selectedFilesDict[id];
        selectedFilesIdsList = selectedFilesIdsList.filter((fileid) => fileid !== id);

        this.setState({
            selectedFiles: selectedFilesDict,
            selectedFilesIds: selectedFilesIdsList,
        });
    };

    scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    _renderModalContent = () => (
        <Box
            my={4}
            mx="30%"
            backgroundColor="white"
            borderRadius="4px"
            borderColor="rgba(0, 0, 0, 0.1)">
            <Text my={3} fontWeight="regular" color="lightBlue" fontSize={24} pl={4}>
                Submit Workflow
            </Text>
            <Box px="0px" pb="22px" justifyContent="center">
                <Box alignItems="center" justifyContent="center">
                    {!this.state.isReject ? (
                        <FormInput
                            label="Additional Notes"
                            multiline
                            numberOfLines={2}
                            maxLength={250}
                            name="AdditionalNotes"
                            type="text"
                            onChange={this.onFieldChange}
                            error={
                                this.state.formErrors
                                    ? this.state.formErrors['AdditionalNotes']
                                    : null
                            }
                            value={
                                this.state.formData &&
                                this.state.formData['AdditionalNotes']
                            }
                            variant={'solid'}
                        />
                    ) : (
                        <FormInput
                            label="Rejection Reason"
                            name="RejectionReason"
                            maxLength={250}
                            onChange={this.onFieldChange}
                            error={' *this field is required'}
                            multiline
                            numberOfLines={2}
                            type="text"
                            value={
                                this.state.formData &&
                                this.state.formData['RejectionReason']
                            }
                            variant="solid"
                        />
                    )}
                </Box>
                <Flex
                    justifyEnd
                    alignCenter
                    alignSelf="flex-end"
                    style={{
                        paddingTop: 15,
                        flexDirection: 'row',
                        alignItems: 'center',
                        paddingLeft: 10,
                        paddingRight: 15,
                        marginTop: 20,
                        marginBottom: 10,
                        marginHorizontal: 25,
                    }}>
                    <Button
                        onPress={(event) => {
                            if (this.state.isReject)
                                this.setState({ isReject: false }, () =>
                                    this.onSubmit(event, true, rejectRules)
                                );
                            else this.onSubmit(event, false);
                        }}
                        title="Submit"
                    />
                    <Button
                        title="Cancel"
                        onPress={() => this.setState({ modalVisible: null })}
                    />
                </Flex>
            </Box>
        </Box>
    );

    render() {
        const {
            width,
            location,
            bapi70CustData = {},
            customerData = {},
            deltasUpdateflow = {},
            deltas = {},
            statusBarData,
            alert = {},
            TasksStatusByTeamId = null,
        } = this.props;

        const { WorkflowDocuments } = this.props.bapi70CustData;
        let { action } = this.props.match.params;
        let globalMdmDetail = bapi70CustData;
        let RequestorName = bapi70CustData.RequestorName;
        let files = WorkflowDocuments || [];
        if (action === 'update') {
            globalMdmDetail = customerData;
            RequestorName = customerData.RequestorName;
            files = this.props.WorkflowDocuments || [];
        }
        const { state } = location;
        const { selectedFilesIds, selectedFiles } = this.state;

        const workflow = {
            ...state,
            isReadOnly:
                TasksStatusByTeamId === null ||
                !(
                    state.WorkflowStateType === 2 &&
                    TasksStatusByTeamId[2].WorkflowTaskStateTypeId === 2
                ),
        };

        const requestDateUtc = moment(idx(bapi70CustData, (_) => _.DateOfRequest));
        const localDate = moment(requestDateUtc).local();
        const requestDate = localDate.format('YYYY-MM-DD hh:mm:ss A');

        // console.log(
        //     'bapi70CustData',
        //     globalMdmDetail,
        //     'workflow',
        //     workflow,
        //     'TasksStatusByTeamId',
        //     TasksStatusByTeamId
        // );
        const inputProps = {
            variant: 'outline',
            inline: true,
            type: 'text',
        };
        const showButtons = workflow.isReadOnly ? { display: 'none' } : null;

        var bgcolor = alert.color || '#FFF';
        if (this.props.fetching) {
            return <Loading />;
        }

        const pageProps = workflow.isReadOnly
            ? inputProps
            : {
                  inline: false,
                  readOnly: false,
                  onBlur: this.props.onFieldChange,
              };

        return (
            <ScrollView
                keyboardShouldPersistTaps="always"
                style={{
                    backgroundColor: '#EFF3F6',
                    paddingBottom: 75,
                }}>
                <Modal isVisible={this.state.modalVisible}>
                    {this._renderModalContent()}
                </Modal>
                <FlashMessages
                    toasts={this.props.toasts}
                    onDismiss={this.props.removeMessage}
                />
                {alert.display && (
                    <FlashMessage
                        bg={{ backgroundColor: bgcolor }}
                        message={alert.message}
                    />
                )}

                <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between">
                    <Text
                        style={{
                            fontWeight: '500',
                            fontSize: 24,
                            color: Colors.lightBlue,
                            marginTop: 25,
                            marginBottom: 20,
                            paddingLeft: 45,
                        }}>
                        MY TASKS - {state.Header}
                    </Text>
                    <Box
                        marginTop="25px"
                        flex={3 / 4}
                        flexDirection="row-reverse"
                        alignItems="flex-end">
                        <MultiColorProgressBar readings={statusBarData} />
                    </Box>
                </Box>
                <Box marginTop="25px" flexDirection="row-reverse" alignItems="flex-end">
                    <StyledText
                        style={{
                            fontWeight: '600',
                            paddingRight: '20px',
                            fontSize: '16px',
                            color: '#10206C',
                        }}>
                        {' '}
                        Requestor : {RequestorName}{' '}
                    </StyledText>
                </Box>
                <Box flexDirection="row-reverse" alignItems="flex-end">
                    <StyledText
                        style={{
                            fontWeight: '600',
                            paddingRight: '20px',
                            fontSize: '16px',
                            color: '#10206C',
                        }}>
                        {' '}
                        Date of Request: {requestDate}{' '}
                    </StyledText>
                </Box>
                <View
                    style={{
                        flex: 1,
                        paddingHorizontal: width < 1440 ? 60 : width * 0.1,
                        paddingBottom: 10,
                    }}>
                    <Box fullHeight my={2}>
                        <Box flexDirection="row" justifyContent="space-around" my={4}>
                            <FormInput
                                px="25px"
                                flex={1 / 4}
                                label="Title"
                                name="title"
                                variant="outline"
                                type="text"
                                value={globalMdmDetail && globalMdmDetail.WorkflowTitle}
                            />

                            <FormInput
                                px="25px"
                                flex={1 / 4}
                                label="Workflow Number"
                                name="workflow-number"
                                variant="outline"
                                type="text"
                                value={state && state.WorkflowId}
                            />
                            {globalMdmDetail && globalMdmDetail.SystemRecordId && (
                                <FormInput
                                    px="25px"
                                    flex={1 / 4}
                                    label="Customer Number"
                                    name="CustomerNumber"
                                    variant="outline"
                                    type="text"
                                    value={
                                        globalMdmDetail && globalMdmDetail.SystemRecordId
                                    }
                                />
                            )}
                            {globalMdmDetail && globalMdmDetail.MdmNumber && (
                                <TouchableOpacity
                                    onPress={() =>
                                        this.props.history.push(
                                            `/search-results/${
                                                globalMdmDetail &&
                                                globalMdmDetail.MdmNumber
                                            }`
                                        )
                                    }>
                                    <FormInput
                                        px="25px"
                                        flex={1 / 4}
                                        label="MDM Number"
                                        name="mdm-number"
                                        variant="outline"
                                        type="text"
                                        value={
                                            globalMdmDetail && globalMdmDetail.MdmNumber
                                        }
                                    />
                                </TouchableOpacity>
                            )}
                        </Box>
                        <GlobalMdmFields
                            formData={globalMdmDetail}
                            deltas={deltasUpdateflow}
                            readOnly
                            staticDropDownData={this.props.staticData}
                        />
                        {action !== 'update' && (
                            <Box>
                                <Text
                                    mt="42px"
                                    mb="20px"
                                    fontWeight="regular"
                                    color="lightBlue"
                                    fontSize={24}
                                    pl={4}>
                                    SYSTEM FIELDS
                                </Text>
                                <Box flexDirection="row" justifyContent="center">
                                    <Box width={1 / 2} mx="auto" alignItems="center">
                                        <FormInput
                                            label="System"
                                            name="System"
                                            delta={deltas['SystemType']}
                                            value={
                                                SystemType[
                                                    globalMdmDetail &&
                                                        globalMdmDetail.SystemType
                                                ]
                                            }
                                            {...inputProps}
                                        />
                                        <FormInput
                                            label="Role"
                                            name="Role"
                                            delta={deltas['RoleTypeId']}
                                            value={
                                                RoleType[
                                                    globalMdmDetail &&
                                                        globalMdmDetail.RoleTypeId
                                                ]
                                            }
                                            {...inputProps}
                                        />
                                        <FormInput
                                            label="Sales Org"
                                            name="SalesOrg"
                                            delta={deltas['SalesOrgTypeId']}
                                            value={
                                                SalesOrgType[
                                                    globalMdmDetail &&
                                                        globalMdmDetail.SalesOrgTypeId
                                                ]
                                            }
                                            {...inputProps}
                                        />
                                        {action === 'extend-salesorg' ? (
                                            <FormInput
                                                label="Target Sales Org"
                                                name="TargetSalesOrg"
                                                team="system"
                                                inline
                                                variant="outline"
                                                type="text"
                                                value={
                                                    SalesOrgType[
                                                        globalMdmDetail &&
                                                            globalMdmDetail.TargetSalesOrgTypeId
                                                    ]
                                                }
                                            />
                                        ) : null}
                                    </Box>
                                    <Box width={1 / 2} mx="auto" alignItems="center">
                                        <FormInput
                                            label="Distribution Channel"
                                            name="DistributionChannel"
                                            delta={deltas['DistributionChannelTypeId']}
                                            value={
                                                DistributionChannelType[
                                                    globalMdmDetail &&
                                                        globalMdmDetail.DistributionChannelTypeId
                                                ]
                                            }
                                            {...inputProps}
                                        />
                                        <FormInput
                                            label="Division"
                                            name="DivisionTypeId"
                                            delta={deltas['DivisionTypeId']}
                                            value={
                                                DivisionType[
                                                    globalMdmDetail &&
                                                        globalMdmDetail.DivisionTypeId
                                                ]
                                            }
                                            {...inputProps}
                                        />
                                        <FormInput
                                            label="CompanyCode"
                                            name="CompanyCodeTypeId"
                                            delta={deltas['CompanyCodeTypeId']}
                                            value={
                                                CompanyCodeType[
                                                    globalMdmDetail &&
                                                        globalMdmDetail.CompanyCodeTypeId
                                                ]
                                            }
                                            {...inputProps}
                                        />
                                        <FormInput
                                            label="Purpose of Request"
                                            name="Purpose"
                                            value={
                                                (globalMdmDetail &&
                                                    globalMdmDetail.Purpose) ||
                                                (deltas['Purpose'] &&
                                                    deltas['Purpose'].UpdatedValue)
                                            }
                                            multiline
                                            numberOfLines={
                                                globalMdmDetail.Purpose &&
                                                globalMdmDetail.Purpose.length > 36
                                                    ? 3
                                                    : 1
                                            }
                                            style={{ lineHeight: '2.5' }}
                                            inline
                                            variant="outline"
                                            maxLength={1000}
                                            type="text"
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        )}
                    </Box>
                    {files && <FilesList files={files} readOnly />}
                    {workflow.isReadOnly ? (
                        <Box my={4}>
                            <Box flexDirection="row" justifyContent="center">
                                {globalMdmDetail && globalMdmDetail.RejectionReason && (
                                    <Box width={1 / 2} mx="auto" alignItems="center">
                                        <FormInput
                                            label="Notes"
                                            name="RejectionReason"
                                            inputWidth="100%"
                                            required
                                            onChange={this.onFieldChange}
                                            error={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                          'RejectionReason'
                                                      ]
                                                    : null
                                            }
                                            multiline
                                            numberOfLines={2}
                                            type="text"
                                            value={globalMdmDetail.RejectionReason}
                                            variant={'outline'}
                                            inline={workflow.isReadOnly ? true : false}
                                        />
                                    </Box>
                                )}
                                <Box width={1 / 2} mx="auto" alignItems="center" />
                            </Box>
                        </Box>
                    ) : (
                        <FilesList
                            title="NEW ATTACHMENTS"
                            formErrors={this.state.fileErrors}
                            files={selectedFilesIds.map((id) => selectedFiles[id])}
                            onChange={(value, id) => {
                                this.setState({
                                    selectedFiles: {
                                        ...selectedFiles,
                                        [id]: {
                                            ...selectedFiles[id],
                                            DocumentType: parseInt(value),
                                        },
                                    },
                                });
                            }}
                            onRemove={(value, id) => this.removeFile(id)}
                        />
                    )}

                    <Box {...showButtons}>
                        <Flex
                            justifyEnd
                            alignCenter
                            style={{
                                paddingTop: 15,
                                flexDirection: 'row',
                                alignItems: 'center',
                                paddingLeft: 10,
                                paddingRight: 15,
                                marginTop: 20,
                                marginBottom: 10,
                                marginHorizontal: 25,
                            }}>
                            <label htmlFor="file-upload" className="custom-file-upload">
                                <MaterialIcons
                                    name="attach-file"
                                    size={18}
                                    color="#fff"
                                />
                            </label>
                            <input
                                id="file-upload"
                                type="file"
                                key={this.state.fileinputkey}
                                onChange={this.selectFiles}
                                multiple
                            />

                            <Button
                                onPress={(event) =>
                                    this.setState({ isReject: false, modalVisible: true })
                                }
                                title="Approve"
                            />
                            <Button
                                title="Reject"
                                onPress={(event) =>
                                    this.setState({ isReject: true, modalVisible: true })
                                }
                            />
                        </Flex>
                    </Box>
                </View>
            </ScrollView>
        );
    }
}

class Default extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const props = this.props;

        return (
            <DimensionAware
                render={(dimensions) => (
                    <Page
                        {...{
                            ...props,
                            width: getWindowWidth(dimensions),
                            height: getWindowHeight(dimensions),
                            marginBottom: 25,
                        }}
                    />
                )}
            />
        );
    }
}

const mapStateToProps = ({
    workflows,
    myTasks,
    customer,
    updateFlow,
    toasts,
    staticData,
}) => {
    const {
        bapi70CustData,
        deltas,
        denormalizedDeltas,
        fetching: fetchingCustomer,
    } = customer;
    const { fetching, alert, readOnly } = myTasks;
    const { data, fetching: staticFetching } = staticData;
    const {
        denormalizedDeltas: denormalizedDeltasUpdateFlow,
        deltas: deltasUpdateflow,
        customerData,
        WorkflowDocuments,
        fetching: fetchingUpdateflow,
    } = updateFlow;
    const {
        fetchingfnGroupData,
        statusBarData,
        functionalGroupDetails,
        TasksStatusByTeamId,
        fetchingStatusBar,
    } = workflows;
    return {
        fetching:
            fetching ||
            staticFetching ||
            fetchingStatusBar ||
            fetchingfnGroupData ||
            fetchingCustomer ||
            fetchingUpdateflow,
        deltasUpdateflow,
        alert,
        statusBarData,
        functionalGroupDetails,
        readOnly,
        TasksStatusByTeamId,
        bapi70CustData,
        customerData,
        WorkflowDocuments,
        denormalizedDeltas: denormalizedDeltas || denormalizedDeltasUpdateFlow,
        deltas,
        toasts,
        staticData: data,
    };
};

export default connect(mapStateToProps, {
    getFunctionalGroupData,
    getStatusBarData,
    getCustomerFromSAP,
    saveApolloUpdateMyTaskGlobalTrade,
    removeMessage,
    getCustomerGlobalData,
    getStaticRequest,
})(Default);

const styles = StyleSheet.create({
    progressIndicator: {
        flex: 1,
        paddingBottom: 5,
        flexDirection: 'row-reverse',
        alignItems: 'flex-end',
    },
    statusText: {
        fontSize: 15,
        color: '#1D4289',
        fontFamily: 'Poppins',
        textAlign: 'center',
        marginTop: 20,
    },
});
