/**
 * @prettier
 */

import React from 'react';
import {
    ScrollView,
    Text,
    View,
    TouchableOpacity,
    ActivityIndicator,
    Image,
    Modal,
} from 'react-native';
import {
    DimensionAware,
    getWindowHeight,
    getWindowWidth,
} from 'react-native-dimension-aware';
import { connect } from 'react-redux';
import { FormInput, FormSelect } from '../../components/form';
import { Box, Flex } from '../../components/common';
import { Table, TableWrapper, Row, Rows, Cell } from '../../components/table';
import { Link } from '../../navigation/router';
import { Colors, StyledText } from '../../theme/Index';
import { AntDesign } from '@expo/vector-icons';
import OverflowRight from '../../components/OverflowRight';
import RequestFilterFields from '../../components/RequestFilterFields';
import {
    SystemType,
    RoleType,
    GeneralRoleType,
    WorkflowProcessType,
    TaskType,
    WorkflowStateType,
} from '../../constants/WorkflowEnums';
import { OverflowRightContext } from '../SearchResult/Apollo/MdmDetailScreen';
import { getStaticRequest, getMyRequests } from '../../appRedux/actions';
import { mapKeys } from 'lodash';
import idx from 'idx';

class Page extends React.Component {
    toggle = (value = null) => {
        const isBoolean = value && typeof value === 'boolean';
        this.setState((state) => ({
            isToggled: isBoolean ? value : !state.isToggled,
        }));
    };
    constructor(props) {
        super(props);
        this.toggleOverflow = () => {
            this.setState((state) => ({
                isToggled: !state.isToggled,
            }));
        };
        this.state = {
            tableHead: [
                'WorkFlow Number',
                'System',
                'Role',
                'WF Process',
                'Customer Name',
                'Workflow Title',
                `Date of Creation`,
                'Status',
            ],
            isToggled: false,
            requestsTotal: 200,
            current_page: 1,
            toggleOverflow: this.toggle,
            formData: {},
            loadedFilterRequestData: false,
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location && this.state.isToggled === true) {
            this.toggle(false);
        }
    }

    componentDidMount() {
        this.props.getStaticRequest([6, 50, 40, 116, 123]);
        this.props.getMyRequests();
    }

    onFieldChange = (value, e) => {
        const name = e.target.name;
        const { formData: prevFormData } = this.state;

        this.setState((prevState, props) => {
            return {
                formData: {
                    ...prevState.formData,
                    [name]: value,
                },
            };
        });
    };

    onFilterSubmit = () => {
        const { formData, isToggled } = this.state;

        var WorkflowRequestFilter = {
            WorkflowId: formData.WorkflowId,
            CustomerName: formData.CustomerName,
            WorkflowTitle: formData.WorkflowTitle,
            SystemTypeId: formData.SystemTypeId === '0' ? '' : formData.SystemTypeId,
            RoleTypeId: formData.RoleTypeId === '0' ? '' : formData.RoleTypeId,
            ProcessTypeId: formData.ProcessTypeId === '0' ? '' : formData.ProcessTypeId,
            WorkflowStateTypeId:
                formData.WorkflowStateTypeId === '0' ? '' : formData.WorkflowStateTypeId,
            CreatedOn_StartRange: formData.CreatedOn_StartRange,
            CreatedOn_EndRange: formData.CreatedOn_EndRange,
        };
        this.setState({ loadedFilterRequestData: true, current_page: 1 });
        this.toggle(!isToggled);
        var postdata = {
            UserId: localStorage.getItem('userId'),
            WorkflowRequestFilter,
        };

        this.props.getMyRequests(postdata);
    };

    onReset = () => {
        this.setState((prevState, props) => {
            return {
                formData: {},
                isToggled: !prevState.isToggled,
                loadedFilterRequestData: !prevState.loadedFilterRequestData,
                current_page: 1,
            };
        });
        var postdata = {
            UserId: localStorage.getItem('userId'),
        };

        this.props.getMyRequests(postdata);
    };

    renderPage = (pagenumber) => {
        //set current page number & start from pointer
        const { formData } = this.state;
        const userId = localStorage.getItem('userId');
        let from_size = 0,
            to_size = 10;
        this.setState({
            current_page: pagenumber,
        });

        if (pagenumber !== 1) {
            from_size = pagenumber * 10 - 10;
        }
    };

    moveNext = () => {
        let lastPageNumber = Math.ceil(this.props.totalRequests / 10);
        this.setState(
            {
                current_page: this.state.current_page + 1,
            },
            () => {
                if (this.state.current_page <= lastPageNumber) {
                    this.renderPage(this.state.current_page);
                }
            }
        );
    };
    movePrev = () => {
        this.setState(
            {
                current_page: this.state.current_page - 1,
            },
            () => {
                if (this.state.current_page >= 1) {
                    this.renderPage(this.state.current_page);
                }
            }
        );
    };

    isEmpty = (data) => {
        let result = false;
        if (typeof data === 'string') {
            return !data || 0 === data.length;
        }
        if (typeof data === 'object') {
            for (var key in data) {
                if (data.hasOwnProperty(key)) return false;
            }
            return true;
        }
        return result;
    };

    renderRequests(currentPage) {
        const { totalRequests, staticData } = this.props;
        // console.log('staticData: ', staticData);
        const myRequests = totalRequests[currentPage - 1] || [];
        var navigateTo, header;
        var data = [];
        var requestData = myRequests.map((request, index) => {
            if (request.Type.toLowerCase().includes('create apollo partners')) {
                navigateTo = '/my-requests/apollo/partners/' + request.WorkflowId;
                header = 'CREATE';
            } else if (request.Type.toLowerCase().includes('create olympus partners')) {
                navigateTo = '/my-requests/olympus/partners/' + request.WorkflowId;
                header = 'CREATE';
            } else if (request.Type.toLowerCase().includes('create jde partners')) {
                navigateTo = '/my-requests/jded/partners/' + request.WorkflowId;
                header = 'CREATE';
            } else if (request.Type.toLowerCase().includes('create')) {
                navigateTo = '/my-requests/' + request.WorkflowId;
                header = 'CREATE';
            } else if (request.Type.toLowerCase().includes('update')) {
                if (request.IsGlobalUpdate) {
                    navigateTo = '/my-requests/' + request.WorkflowId;
                    header = 'UPDATE CUSTOMER GLOBAL';
                } else {
                    navigateTo =
                        request.SystemTypeId == 2
                            ? '/my-requests/olympus/' +
                              request.WorkflowId +
                              '/update-request'
                            : '/my-requests/' + request.WorkflowId + '/update-request';
                    header = 'UPDATE';
                }
            } else if (request.Type.toLowerCase().includes('extend')) {
                navigateTo = '/my-requests/' + request.WorkflowId + '/update-request';
                header = 'EXTEND TO NEW SALES ORG';
            } else if (request.Type.toLowerCase().includes('block')) {
                navigateTo = '/my-requests/' + request.WorkflowId + '/update-request';
                header = 'BLOCK UNBLOCK';
            }

            let wfProcess = request.WorkflowTypeName;

            if (
                request.RoleTypeId === null &&
                request.Type.toLowerCase().includes('other')
            ) {
                wfProcess = 'Other';
                request.WorkflowType = 'CreateOther';
                navigateTo = '/my-requests/' + request.WorkflowId;
                header = 'OTHER';
            }

            let rqdata = [
                <Link
                    style={{
                        paddingTop: 26,
                        paddingBottom: 27,
                        paddingLeft: 20,
                        color: '#2C2B94',
                    }}
                    to={{
                        pathname: navigateTo,
                        state: {
                            ...request,
                            taskId: request.TaskId,
                            Header: header,
                        },
                    }}>
                    {request.WorkflowId}
                </Link>,
                SystemType[request.SystemTypeId],
                request.RoleTypeId === null || request.RoleTypeId === 0
                    ? 'N/A'
                    : request.SystemTypeId === 3
                    ? idx(
                          staticData,
                          (_) => _.PointmanRoleType[request.RoleTypeId].Description
                      )
                    : request.SystemTypeId === 2
                    ? idx(
                          staticData,
                          (_) => _.OlympusRoleType[request.RoleTypeId].Description
                      )
                    : request.SystemTypeId === 5
                    ? idx(
                          staticData,
                          (_) => _.JDERoleType[request.RoleTypeId].Description
                      )
                    : idx(
                          staticData,
                          (_) => _.ApolloRoleType[request.RoleTypeId].Description
                      ),
                wfProcess,
                request.CustomerName || 'N/A',
                request.WorkflowTitle,
                request.DateOfCreation.split('T')[0],
                request.Status,
            ];
            data.push(rqdata);
        });

        return (
            <Box
                my={2}
                style={{
                    zIndex: -1,
                }}>
                <Table
                    border="2px solid #234382"
                    borderStyle={{
                        borderWidth: 1,
                        borderRightWidth: 1,
                        borderColor: '#98D7DA',
                        borderRightStyle: 'solid',
                    }}>
                    <Row
                        data={this.state.tableHead}
                        flexArr={[1.3, 0.8, 1, 0.9, 1, 1, 0.7, 0.7]}
                        style={{
                            backgroundColor: '#E6F5FA',
                            maxHeight: 75,
                        }}
                        borderStyle={{
                            borderWidth: 0,
                            borderTopWidth: 0,
                            borderRightWidth: 1,
                            borderColor: '#98D7DA',
                            borderRightStyle: 'solid',
                        }}
                        textStyle={{
                            textAlign: 'left',
                            color: '#10206C',
                            fontWeight: '600',
                            fontFamily: 'Poppins',
                            fontSize: 16,
                            paddingTop: 24,
                            paddingBottom: 24,
                            paddingHorizontal: 15,
                        }}
                    />
                    <Rows
                        data={data}
                        flexArr={[1.3, 0.8, 1, 0.9, 1, 1, 0.7, 0.7]}
                        style={{ minHeight: 65, maxHeight: 80 }}
                        borderStyle={{
                            borderWidth: 0,
                            borderTopWidth: 0,
                            borderRightWidth: 1,
                            borderColor: '#98D7DA',
                            borderRightStyle: 'solid',
                        }}
                        textStyle={{
                            color: '#353535',
                            fontSize: 14,
                            fontWeight: '500',
                            fontFamily: 'Poppins',
                            borderColor: '#98D7DA',
                            paddingTop: 26,
                            paddingBottom: 27,
                            paddingLeft: 20,
                            paddingRight: 10,
                            textAlign: 'left',
                            backgroundColor: '#F8F8F8',
                        }}
                    />
                </Table>
            </Box>
        );
    }

    renderPageNumbers() {
        let totalpageCnt = Math.ceil(this.props.totalRequests / 10);
        const pageNumbers = [];
        for (let i = 1; i <= totalpageCnt; i++) {
            pageNumbers.push(i);
        }

        return;
    }

    render() {
        const {
            width,
            height,
            marginBottom,
            location,
            myRequestFilters: filters = {},
        } = this.props;
        const { state } = location;
        const { isToggled } = this.state;

        if (this.props.fetching)
            return (
                <Box
                    display="flex"
                    flex={1}
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                    minHeight="650px">
                    <ActivityIndicator size="large" />
                </Box>
            );

        let details = '';
        if (!this.isEmpty(filters)) {
            let {
                WorkflowId,
                SystemTypeId,
                RoleTypeId,
                ProcessTypeId,
                WorkflowStateTypeId,
                CustomerName,
                WorkflowTitle,
                CreatedOn_StartRange,
                CreatedOn_EndRange,
            } = filters;
            if (WorkflowId) details = details + '   WF ID: ' + WorkflowId;
            if (SystemTypeId)
                details = details + '   System: ' + SystemType[SystemTypeId];
            if (RoleTypeId) details = details + '   Role: ' + RoleType[RoleTypeId];
            if (ProcessTypeId)
                details =
                    details + '   WF Process: ' + WorkflowProcessType[ProcessTypeId];
            if (CustomerName) details = details + '   CustomerName: ' + CustomerName;
            if (WorkflowTitle) details = details + '   WorkflowTitle: ' + WorkflowTitle;
            if (WorkflowStateTypeId)
                details =
                    details + '   Status: ' + WorkflowStateType[WorkflowStateTypeId];
            if (CreatedOn_StartRange)
                details = details + '   StartDate: ' + CreatedOn_StartRange.split('T')[0];
            if (CreatedOn_EndRange)
                details = details + '   EndDate: ' + CreatedOn_EndRange.split('T')[0];
        }

        const pageNumbers = [];

        if (this.props.totalRequests) {
            let totalpageCnt = Math.ceil(this.props.totalRequests.length);
            for (let i = 1; i <= totalpageCnt; i++) {
                pageNumbers.push(i);
            }
        }

        return (
            <View
                style={{
                    backgroundColor: '#EFF3F6',
                    paddingBottom: 75,
                    minHeight: '100vh',
                }}>
                <ScrollView
                    keyboardShouldPersistTaps="always"
                    style={{
                        flex: 1,
                        paddingHorizontal: width < 1440 ? 75 : width * 0.1,
                        paddingBottom: 5,
                    }}>
                    <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="space-between">
                        <Text
                            style={{
                                fontWeight: '500',
                                fontSize: 24,
                                color: Colors.lightBlue,
                                marginTop: 25,
                                marginBottom: 20,
                            }}>
                            MY REQUESTS
                        </Text>

                        <Box mt={3} pr={3}>
                            <TouchableOpacity
                                hitSlop={{
                                    top: 10,
                                    bottom: 10,
                                    left: 0,
                                    right: 0,
                                }}
                                onPress={() => this.toggle(true)}
                                style={{
                                    flexDirection: 'row-reverse',
                                    alignItems: 'flex-end',
                                }}>
                                <label>
                                    <AntDesign
                                        name="filter"
                                        size={30}
                                        color="#10206C"
                                        label="as"
                                    />
                                    <StyledText style={{ color: '#10206C' }}>
                                        More Filters
                                    </StyledText>
                                </label>
                            </TouchableOpacity>
                        </Box>
                    </Box>

                    <Box>
                        <OverflowRightContext.Provider value={this.state}>
                            <View style={{ zIndex: 1, marginTop: '-58px' }}>
                                <OverflowRight
                                    title="FILTER OPTIONS"
                                    content={
                                        <RequestFilterFields
                                            onFieldChange={this.onFieldChange}
                                            formData={this.state.formData}
                                            onSubmit={this.onFilterSubmit}
                                            hiddenFields={{
                                                WorkflowTaskStateTypeId: true,
                                            }}
                                            onReset={this.onReset}>
                                            <FormInput
                                                label="Workflow Title"
                                                name="WorkflowTitle"
                                                maxLength={40}
                                                small
                                                value={this.state.formData.WorkflowTitle}
                                                inline={false}
                                                readOnly={false}
                                                onChange={this.onFieldChange}
                                            />
                                        </RequestFilterFields>
                                    }
                                    onMenuDismiss={() => this.toggle(false)}
                                    style={{
                                        position: 'absolute',
                                        zIndex: 1,
                                    }}
                                    isToggled={isToggled}
                                    containerWidth="50%"
                                />
                            </View>
                        </OverflowRightContext.Provider>
                    </Box>
                    {!this.isEmpty(filters) && details.length > 0 && (
                        <StyledText style={{ color: '#10206C', textAlign: 'inherit' }}>
                            Filters Applied: {details}
                        </StyledText>
                    )}
                    {this.renderRequests(this.state.current_page)}
                    <View
                        style={{
                            zIndex: -1,
                        }}
                        className="pagination">
                        <span onClick={() => this.movePrev()}>&laquo;</span>
                        {pageNumbers.map((number) => {
                            let classes =
                                this.state.current_page === number ? 'active' : '';
                            if (
                                number === 1 ||
                                number === this.props.totalRequests ||
                                (number >= this.state.current_page - 2 &&
                                    number <= this.state.current_page + 2)
                            ) {
                                return (
                                    <span
                                        key={number}
                                        className={classes}
                                        onClick={() => {
                                            this.renderPage(number);
                                        }}>
                                        {number}
                                    </span>
                                );
                            }
                        })}
                        <span onClick={() => this.moveNext()}>&raquo;</span>
                    </View>
                </ScrollView>
            </View>
        );
    }
}

class Default extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {}

    render() {
        const props = this.props;

        return (
            <DimensionAware
                render={(dimensions) => (
                    <Page
                        {...{
                            ...props,
                            width: getWindowWidth(dimensions),
                            height: getWindowHeight(dimensions),
                            marginBottom: 25,
                        }}
                    />
                )}
            />
        );
    }
}

const mapStateToProps = ({ myRequests, staticData }) => {
    const {
        data: requests,
        totalRequests,
        myRequestFilters,
        fetching,
        alert,
    } = myRequests;
    const { data, fetching: staticFetching } = staticData;
    return {
        myRequests: requests || [],
        totalRequests: totalRequests || [],
        myRequestFilters,
        fetching: fetching || staticFetching,
        alert,
        staticData: data
            ? Object.fromEntries(
                  Object.entries(data).map(([key, value]) => [key, mapKeys(value, 'Id')])
              )
            : {},
    };
};

export default connect(mapStateToProps, { getMyRequests, getStaticRequest })(Default);
