/**
 * @prettier
 */

import React, { Component, Fragment, useState, useEffect } from 'react';
import { ActivityIndicator } from 'react-native';
import { FontAwesome, MaterialIcons } from '@expo/vector-icons';
import { Box } from './common/Box.jsx';
import Text from './common/Text.jsx';
import { FormSelect, FormInput } from './form';
import { Link } from '../navigation/router';
import { ajaxPostRequest, endpoints } from '../appRedux/sagas/config';

const FileTypes = [
    'Choose from...',
    'License',
    'CreditApp',
    'PurchaseOrder',
    'Supporting',
];

function UploadFile({ fileData, error, onChange, onRemove, fileTypes }) {
    const [state, setState] = useState({ value: '' });

    useEffect(() => {
        onChange(state.value);
    }, [state]);

    return (
        <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            mb={4}
            justifyContent="center">
            <Box>
                <Box flexDirection="row">
                    <MaterialIcons name="description" size={25} color="#1D4289" />
                    <Text
                        color="primary"
                        fontWeight={500}
                        fontFamily="Poppins"
                        fontSize="17px"
                        ml="10px">
                        {fileData.DocumentName}
                    </Text>
                </Box>
                <FormInput
                    multiline
                    numberOfLines={3}
                    maxLength={30}
                    value={state.value}
                    onChange={(val, e) => setState({ ...state, value: val })}
                />
            </Box>
            <Box flex="1" alignSelf="baseline" flexDirection="row" paddingLeft="25">
                <RemoveIcon onPress={onRemove} alignSelf="baseline" />
            </Box>
        </Box>
    );
}

function DownloadFile({ fileData, onClick, fileTypes }) {
    const [downloading, setState] = useState(false);

    return (
        <Box display="flex" maxWidth="350px" justifyContent="space-between" mt={1}>
            <Box display="flex" flexDirection="row" alignItems="center" mr="2%">
                {downloading && <ActivityIndicator />}
                <MaterialIcons
                    name="description"
                    size={25}
                    color="#1D4289"
                    style={{ paddingBottom: 5 }}
                />
                <Link
                    to="#"
                    style={{
                        fontFamily: 'Poppins',
                        fontSize: '17px',
                        ml: '10px',
                    }}
                    onClick={(e) => {
                        e.preventDefault();
                        setState(true);
                        ajaxPostRequest(endpoints.downloadDocument, {
                            UserId: localStorage.getItem('userId'),
                            S3ObjectKey: fileData.S3objectKey,
                        }).then(({ ResultData: resp }) => {
                            setState(false);
                            window.open(resp.PreSignedURL, '_blank');
                        });
                    }}>
                    {fileData.DocumentName}
                </Link>
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center " marginTop="10">
                <Text
                    color="#000000"
                    fontFamily="Poppins"
                    fontSize="17px"
                    ml="10px"
                    style={{
                        marginTop: 4,
                        borderWidth: 0,
                        borderBottomWidth: 'thin',
                        borderColor: '#6e6e6e',
                        borderStyle: 'solid',
                        wordBreak: 'break-word',
                    }}>
                    {fileData.AttachmentName}
                </Text>
            </Box>
        </Box>
    );
}

const RemoveIcon = ({ onPress }) => (
    <Box style={{ marginLeft: '40px', marginTop: '6px', alignSelf: 'baseline' }}>
        <FontAwesome.Button
            name="minus"
            size={15}
            color="#FFFFFF"
            backgroundColor="#264384"
            borderRadius={13}
            iconStyle={{ marginRight: 0, paddingHorizontal: 1 }}
            onPress={onPress}
        />
    </Box>
);

function FilesList({
    title = 'ATTACHMENTS',
    files = [],
    readOnly = false,
    formErrors,
    onChange,
    onClick,
    onRemove,
    fileTypes = FileTypes,
}) {
    if (files.length <= 0) return <Box />;

    return (
        <Fragment>
            <Text
                mt="42px"
                mb="20px"
                ml="5%"
                fontWeight="light"
                color="lightBlue"
                fontSize="24px">
                {title}
            </Text>

            <Box mt={2} flexDirection="row" justifyContent="center">
                <Box width={0.79} mx="auto" alignItems="flex-start">
                    <Box display="flex" width="100%">
                        {files.map((file) => (
                            <Box key={file.DocumentName}>
                                {readOnly ? (
                                    <DownloadFile
                                        fileData={file}
                                        onClick={onClick}
                                        fileTypes={fileTypes}
                                    />
                                ) : (
                                    <UploadFile
                                        fileTypes={fileTypes}
                                        error={
                                            formErrors
                                                ? formErrors[file.DocumentName]
                                                : null
                                        }
                                        fileData={file}
                                        onChange={(value, e) =>
                                            onChange(value, file.DocumentName)
                                        }
                                        onRemove={(value, e) =>
                                            onRemove(value, file.DocumentName)
                                        }
                                    />
                                )}
                            </Box>
                        ))}
                    </Box>
                </Box>
            </Box>
        </Fragment>
    );
}

export default FilesList;
