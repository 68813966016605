import { AbbreviatedStreet } from './WorkflowEnums.js';

const _ = require('lodash');
const postalCodes = require('postal-codes-js');

export const resolveDependencies = (dependencies, schema, obj, type) => {
    const typeFuncs = {
        oneOf: (deps) =>
            _.find(deps, (val) =>
                _.isMatch(
                    obj,
                    _.mapValues(val, (o) => o.toString())
                )
            ),
        allOf: (deps) =>
            _.isMatch(
                obj,
                _.reduce(deps, (sum, dependency, index) => ({
                    ...sum,
                    ...dependency,
                }))
            ),
        anyOf: () => {},
    };

    return {
        ...schema,
        display: typeFuncs[type](dependencies, obj) ? 'block' : 'none',
    };
};

export const passFields = (_system, fields) => {
    return _.mapValues(_system, (schema, fieldKey, obj) => {
        const { dependencies, ...rest } = schema;

        if (!dependencies) {
            return _.isObject(schema)
                ? { ...rest, name: fieldKey, display: 'block' }
                : schema;
        } else {
            const rests = _.map(dependencies, (val, key) => {
                return resolveDependencies(val, rest, fields, key);
            });

            return _.reduce(rests, (sum, n) => ({ sum, n }));
        }
    });
};

export const yupFieldValidation = (data, schema, proceedAction, setFormError) => {
    schema
        .validate(data, { abortEarly: false })
        .then((valid) => {
            proceedAction(schema);
            return true;
        })
        .catch((error) => {
            let errormsg = error.errors;
            let errlength = errormsg.length;
            for (let i = 0; i < errlength; i++) {
                let key = errormsg[i].split(' ')[0];
                setFormError(false, key, errormsg[i]);
            }
        });
};

export const yupAllFieldsValidation = (data, schema, proceedAction, setFormError) => {
    schema
        .validate(data, { abortEarly: false })
        .then((valid) => {
            proceedAction(schema);
            return true;
        })
        .catch((error) => {
            let errormsg = error.inner;
            let errlength = errormsg ? errormsg.length : 0;
            let errorsObj = {};
            for (let i = 0; i < errlength; i++) {
                let key = errormsg[i].path;
                errorsObj[key] = errormsg[i].message;
            }
            console.log('data', data, 'er', errorsObj);
            setFormError(errorsObj);
        });
};

export function sanitise(x) {
    if (isNaN(x)) {
        return NaN;
    }
    return x;
}

export function parseAndSanitise(str) {
    const num = parseInt(str);
    if (isNaN(num)) {
        return 0;
    }
    return num;
}

export function validatePostalCode(countryCode, postalCode) {
    return postalCodes.validate(countryCode, postalCode) === true ? true : false;
}

export function normalizeName(val) {
    let value = val.trim();
    let regexp = '';
    if (val.trim().toLowerCase().includes('limited liability corporation')) {
        regexp = new RegExp('\\b(' + 'limited liability corporation' + ')\\b', 'gi');
        value = value.toLowerCase().replace(regexp, 'LLC');
    } else if (val.trim().toLowerCase().includes('limited')) {
        regexp = new RegExp('\\b(' + 'limited' + ')\\b', 'gi');
        value = value.toLowerCase().replace(regexp, 'LTD');
    }
    if (val.trim().toLowerCase().includes('company')) {
        regexp = new RegExp('\\b(' + 'company' + ')\\b', 'gi');
        value = value.toLowerCase().replace(regexp, 'CO');
    }
    if (val.trim().toLowerCase().includes('corporation')) {
        regexp = new RegExp('\\b(' + 'corporation' + ')\\b', 'gi');
        value = value.toLowerCase().replace(regexp, 'CORP');
    }
    if (val.trim().toLowerCase().includes('incorporated')) {
        regexp = new RegExp('\\b(' + 'incorporated' + ')\\b', 'gi');
        value = value.toLowerCase().replace(regexp, 'INC');
    }

    return value.toUpperCase();
}

export function normalizeStreet(val) {
    let value = val.trim();
    //street field abbreviations
    var allowedStreetAbbreviations = [
        'AVENUE',
        'BOULEVARD',
        'CENTER',
        'CIRCLE',
        'DRIVE',
        'EXPRESSWAY',
        'HIGHWAY',
        'ROAD',
        'ROUTE',
        'STREET',
        'EAST',
        'NORTH',
        'NORTHEAST',
        'NORTHWEST',
        'SOUTH',
        'SOUTHEAST',
        'SOUTHWEST',
        'WEST',
    ];
    var temp_value = val.toUpperCase();
    for (let i = 0; i < allowedStreetAbbreviations.length; i++) {
        var street = allowedStreetAbbreviations[i];
        let regexp = new RegExp('\\b(' + street + ')\\b', 'gi');
        if (temp_value.includes(street))
            temp_value = temp_value.replace(regexp, AbbreviatedStreet[street]);
    }
    value = temp_value;
    return value;
}
