export const countryregiondata = [
    {
        countryName: 'United States',
        countryShortCode: 'US',
        Regions: [
            {
                rname: 'AF in Eur,Afr,Can,ME',
                rkey: 'AE',
            },
            {
                rname: 'Alabama',
                rkey: 'AL',
            },
            {
                rname: 'Alaska',
                rkey: 'AK',
            },
            {
                rname: 'American Samoa',
                rkey: 'AS',
            },
            {
                rname: 'Americas Excl Canada',
                rkey: 'AA',
            },
            {
                rname: 'Arizona',
                rkey: 'AZ',
            },
            {
                rname: 'Arkansas',
                rkey: 'AR',
            },
            {
                rname: 'California',
                rkey: 'CA',
            },
            {
                rname: 'Colorado',
                rkey: 'CO',
            },
            {
                rname: 'Connecticut',
                rkey: 'CT',
            },
            {
                rname: 'Delaware',
                rkey: 'DE',
            },
            {
                rname: 'District of Columbia',
                rkey: 'DC',
            },
            {
                rname: 'Florida',
                rkey: 'FL',
            },
            {
                rname: 'Georgia',
                rkey: 'GA',
            },
            {
                rname: 'Guam',
                rkey: 'GU',
            },
            {
                rname: 'Hawaii',
                rkey: 'HI',
            },
            {
                rname: 'Idaho',
                rkey: 'ID',
            },
            {
                rname: 'Illinois',
                rkey: 'IL',
            },
            {
                rname: 'Indiana',
                rkey: 'IN',
            },
            {
                rname: 'Iowa',
                rkey: 'IA',
            },
            {
                rname: 'Kansas',
                rkey: 'KS',
            },
            {
                rname: 'Kentucky',
                rkey: 'KY',
            },
            {
                rname: 'Louisiana',
                rkey: 'LA',
            },
            {
                rname: 'Maine',
                rkey: 'ME',
            },
            {
                rname: 'Maryland',
                rkey: 'MD',
            },
            {
                rname: 'Massachusetts',
                rkey: 'MA',
            },
            {
                rname: 'Michigan',
                rkey: 'MI',
            },
            {
                rname: 'Minnesota',
                rkey: 'MN',
            },
            {
                rname: 'Mississippi',
                rkey: 'MS',
            },
            {
                rname: 'Missouri',
                rkey: 'MO',
            },
            {
                rname: 'Montana',
                rkey: 'MT',
            },
            {
                rname: 'Nebraska',
                rkey: 'NE',
            },
            {
                rname: 'Nevada',
                rkey: 'NV',
            },
            {
                rname: 'New Hampshire',
                rkey: 'NH',
            },
            {
                rname: 'New Jersey',
                rkey: 'NJ',
            },
            {
                rname: 'New Mexico',
                rkey: 'NM',
            },
            {
                rname: 'New York',
                rkey: 'NY',
            },
            {
                rname: 'North Carolina',
                rkey: 'NC',
            },
            {
                rname: 'North Dakota',
                rkey: 'ND',
            },
            {
                rname: 'Northern Mariana Isl',
                rkey: 'MP',
            },
            {
                rname: 'Ohio',
                rkey: 'OH',
            },
            {
                rname: 'Oklahoma',
                rkey: 'OK',
            },
            {
                rname: 'Oregon',
                rkey: 'OR',
            },
            {
                rname: 'Pacific',
                rkey: 'AP',
            },
            {
                rname: 'Pennsylvania',
                rkey: 'PA',
            },
            {
                rname: 'Puerto Rico',
                rkey: 'PR',
            },
            {
                rname: 'Rhode Island',
                rkey: 'RI',
            },
            {
                rname: 'South Carolina',
                rkey: 'SC',
            },
            {
                rname: 'South Dakota',
                rkey: 'SD',
            },
            {
                rname: 'Tennessee',
                rkey: 'TN',
            },
            {
                rname: 'Texas',
                rkey: 'TX',
            },
            {
                rname: 'Utah',
                rkey: 'UT',
            },
            {
                rname: 'Vermont',
                rkey: 'VT',
            },
            {
                rname: 'Virgin Islands',
                rkey: 'VI',
            },
            {
                rname: 'Virginia',
                rkey: 'VA',
            },
            {
                rname: 'Washington',
                rkey: 'WA',
            },
            {
                rname: 'West Virginia',
                rkey: 'WV',
            },
            {
                rname: 'Wisconsin',
                rkey: 'WI',
            },
            {
                rname: 'Wyoming',
                rkey: 'WY',
            },
        ],
    },
    {
        countryName: 'Afghanistan',
        countryShortCode: 'AF',
        Regions: [],
    },
    {
        countryName: 'Albania',
        countryShortCode: 'AL',
        Regions: [],
    },
    {
        countryName: 'Algeria',
        countryShortCode: 'DZ',
        Regions: [],
    },
    {
        countryName: 'American Samoa',
        countryShortCode: 'AS',
        Regions: [],
    },
    {
        countryName: 'Andorra',
        countryShortCode: 'AD',
        Regions: [],
    },
    {
        countryName: 'Angola',
        countryShortCode: 'AO',
        Regions: [],
    },
    {
        countryName: 'Anguilla',
        countryShortCode: 'AI',
        Regions: [],
    },
    {
        countryName: 'Antarctica',
        countryShortCode: 'AQ',
        Regions: [],
    },
    {
        countryName: 'Antigua and Barbuda',
        countryShortCode: 'AG',
        Regions: [],
    },
    {
        countryName: 'Argentina',
        countryShortCode: 'AR',
        Regions: [
            {
                rname: 'Buenos Aires',
                rkey: '01',
            },
            {
                rname: 'Capital Federal',
                rkey: '00',
            },
            {
                rname: 'Catamarca',
                rkey: '02',
            },
            {
                rname: 'Chaco',
                rkey: '16',
            },
            {
                rname: 'Chubut',
                rkey: '17',
            },
            {
                rname: 'Cordoba',
                rkey: '03',
            },
            {
                rname: 'Corrientes',
                rkey: '04',
            },
            {
                rname: 'Entre Rios',
                rkey: '05',
            },
            {
                rname: 'Formosa',
                rkey: '18',
            },
            {
                rname: 'Jujuy',
                rkey: '06',
            },
            {
                rname: 'La Pampa',
                rkey: '21',
            },
            {
                rname: 'La Rioja',
                rkey: '08',
            },
            {
                rname: 'Mendoza',
                rkey: '07',
            },
            {
                rname: 'Misiones',
                rkey: '19',
            },
            {
                rname: 'Neuquen',
                rkey: '20',
            },
            {
                rname: 'Rio Negro',
                rkey: '22',
            },
            {
                rname: 'Salta',
                rkey: '09',
            },
            {
                rname: 'San Juan',
                rkey: '10',
            },
            {
                rname: 'San Luis',
                rkey: '11',
            },
            {
                rname: 'Santa Cruz',
                rkey: '23',
            },
            {
                rname: 'Santa Fe',
                rkey: '12',
            },
            {
                rname: 'Santiago del Estero',
                rkey: '13',
            },
            {
                rname: 'Tierra de Fuego',
                rkey: '24',
            },
            {
                rname: 'Tucuman',
                rkey: '14',
            },
        ],
    },
    {
        countryName: 'Armenia',
        countryShortCode: 'AM',
        Regions: [],
    },
    {
        countryName: 'Aruba',
        countryShortCode: 'AW',
        Regions: [],
    },
    {
        countryName: 'Australia',
        countryShortCode: 'AU',
        Regions: [
            {
                rname: 'Aust Capital Terr',
                rkey: 'ACT',
            },
            {
                rname: 'New South Wales',
                rkey: 'NSW',
            },
            {
                rname: 'Northern Territory',
                rkey: 'NT',
            },
            {
                rname: 'Queensland',
                rkey: 'QLD',
            },
            {
                rname: 'South Australia',
                rkey: 'SA',
            },
            {
                rname: 'Tasmania',
                rkey: 'TAS',
            },
            {
                rname: 'Victoria',
                rkey: 'VIC',
            },
            {
                rname: 'Western Australia',
                rkey: 'WA',
            },
        ],
    },
    {
        countryName: 'Austria',
        countryShortCode: 'AT',
        Regions: [
            {
                rname: 'Burgenland',
                rkey: 'CountryKey',
            },
            {
                rname: 'Carinthia',
                rkey: 'K',
            },
            {
                rname: 'Lower Austria',
                rkey: 'NOE',
            },
            {
                rname: 'Lower Austria',
                rkey: 'N\u00d6',
            },
            {
                rname: 'Salzburg',
                rkey: 'S',
            },
            {
                rname: 'Styria',
                rkey: 'ST',
            },
            {
                rname: 'Tyrol',
                rkey: 'T',
            },
            {
                rname: 'Upper Austria',
                rkey: 'OOE',
            },
            {
                rname: 'Vienna',
                rkey: 'W',
            },
            {
                rname: 'Vorarlberg',
                rkey: 'V',
            },
        ],
    },
    {
        countryName: 'Azerbaijan',
        countryShortCode: 'AZ',
        Regions: [],
    },
    {
        countryName: 'Bahamas',
        countryShortCode: 'BS',
        Regions: [],
    },
    {
        countryName: 'Bahrain',
        countryShortCode: 'BH',
        Regions: [],
    },
    {
        countryName: 'Bangladesh',
        countryShortCode: 'BD',
        Regions: [],
    },
    {
        countryName: 'Barbados',
        countryShortCode: 'BB',
        Regions: [],
    },
    {
        countryName: 'Belarus',
        countryShortCode: 'BY',
        Regions: [],
    },
    {
        countryName: 'Belgium',
        countryShortCode: 'BE',
        Regions: [
            {
                rname: 'Antwerp',
                rkey: '01',
            },
            {
                rname: 'Brabant',
                rkey: '02',
            },
            {
                rname: 'Brabant (Walloon)',
                rkey: '10',
            },
            {
                rname: 'Brussels (Capital)',
                rkey: '11',
            },
            {
                rname: 'Hainaut',
                rkey: '03',
            },
            {
                rname: 'Liege',
                rkey: '04',
            },
            {
                rname: 'Limburg',
                rkey: '05',
            },
            {
                rname: 'Luxembourg',
                rkey: '06',
            },
            {
                rname: 'Namur',
                rkey: '07',
            },
            {
                rname: 'Oost-Vlaanderen',
                rkey: '08',
            },
            {
                rname: 'West-Vlaanderen',
                rkey: '09',
            },
        ],
    },
    {
        countryName: 'Belize',
        countryShortCode: 'BZ',
        Regions: [],
    },
    {
        countryName: 'Benin',
        countryShortCode: 'BJ',
        Regions: [],
    },
    {
        countryName: 'Bermuda',
        countryShortCode: 'BM',
        Regions: [],
    },
    {
        countryName: 'Bhutan',
        countryShortCode: 'BT',
        Regions: [],
    },
    {
        countryName: 'Blue',
        countryShortCode: 'BL',
        Regions: [],
    },
    {
        countryName: 'Bolivia',
        countryShortCode: 'BO',
        Regions: [],
    },
    {
        countryName: 'Bonaire, Sint Eustatius and Saba',
        countryShortCode: 'BQ',
        Regions: [],
    },
    {
        countryName: 'Bosnia and Herzegovina',
        countryShortCode: 'BA',
        Regions: [],
    },
    {
        countryName: 'Botswana',
        countryShortCode: 'BW',
        Regions: [],
    },
    {
        countryName: 'Bouvet Islands',
        countryShortCode: 'BV',
        Regions: [],
    },
    {
        countryName: 'Brazil',
        countryShortCode: 'BR',
        Regions: [
            {
                rname: 'Acre',
                rkey: 'AC',
            },
            {
                rname: 'Alagoas',
                rkey: 'AL',
            },
            {
                rname: 'Amapa',
                rkey: 'AP',
            },
            {
                rname: 'Amazon',
                rkey: 'AM',
            },
            {
                rname: 'Bahia',
                rkey: 'BA',
            },
            {
                rname: 'Brasilia',
                rkey: 'DF',
            },
            {
                rname: 'Ceara',
                rkey: 'CE',
            },
            {
                rname: 'Espirito Santo',
                rkey: 'ES',
            },
            {
                rname: 'Goias',
                rkey: 'GO',
            },
            {
                rname: 'Maranhao',
                rkey: 'MA',
            },
            {
                rname: 'Mato Grosso',
                rkey: 'MT',
            },
            {
                rname: 'Mato Grosso do Sul',
                rkey: 'MS',
            },
            {
                rname: 'Minas Gerais',
                rkey: 'MG',
            },
            {
                rname: 'Para',
                rkey: 'PA',
            },
            {
                rname: 'Paraiba',
                rkey: 'PB',
            },
            {
                rname: 'Parana',
                rkey: 'PR',
            },
            {
                rname: 'Pernambuco',
                rkey: 'PE',
            },
            {
                rname: 'Piaui',
                rkey: 'PI',
            },
            {
                rname: 'Rio de Janeiro',
                rkey: 'RJ',
            },
            {
                rname: 'Rio Grande do Norte',
                rkey: 'RN',
            },
            {
                rname: 'Rio Grande do Sul',
                rkey: 'RS',
            },
            {
                rname: 'Rondonia',
                rkey: 'RO',
            },
            {
                rname: 'Roraima',
                rkey: 'RR',
            },
            {
                rname: 'Santa Catarina',
                rkey: 'SC',
            },
            {
                rname: 'Sao Paulo',
                rkey: 'SP',
            },
            {
                rname: 'Sergipe',
                rkey: 'SE',
            },
            {
                rname: 'Tocantins',
                rkey: 'TO',
            },
        ],
    },
    {
        countryName: 'British Indian Ocean Territory',
        countryShortCode: 'IO',
        Regions: [],
    },
    {
        countryName: 'British Virgin Islands',
        countryShortCode: 'VG',
        Regions: [],
    },
    {
        countryName: 'Brunei Darussalam',
        countryShortCode: 'BN',
        Regions: [],
    },
    {
        countryName: 'Bulgaria',
        countryShortCode: 'BG',
        Regions: [
            {
                rname: 'Burgas',
                rkey: '01',
            },
            {
                rname: 'Grad Sofiya',
                rkey: '02',
            },
            {
                rname: 'Khaskovo',
                rkey: '03',
            },
            {
                rname: 'Lovech',
                rkey: '04',
            },
            {
                rname: 'Montana',
                rkey: '05',
            },
            {
                rname: 'Plovdiv',
                rkey: '06',
            },
            {
                rname: 'Ruse',
                rkey: '07',
            },
            {
                rname: 'Sofiya',
                rkey: '08',
            },
            {
                rname: 'Varna',
                rkey: '09',
            },
        ],
    },
    {
        countryName: 'Burkina Faso',
        countryShortCode: 'BF',
        Regions: [],
    },
    {
        countryName: 'Burma',
        countryShortCode: 'MM',
        Regions: [],
    },
    {
        countryName: 'Burundi',
        countryShortCode: 'BI',
        Regions: [],
    },
    {
        countryName: 'Cambodia',
        countryShortCode: 'KH',
        Regions: [],
    },
    {
        countryName: 'Cameroon',
        countryShortCode: 'CM',
        Regions: [],
    },
    {
        countryName: 'Canada',
        countryShortCode: 'CA',
        Regions: [
            {
                rname: 'Alberta',
                rkey: 'AB',
            },
            {
                rname: 'British Columbia',
                rkey: 'BC',
            },
            {
                rname: 'Manitoba',
                rkey: 'MB',
            },
            {
                rname: 'New Brunswick',
                rkey: 'NB',
            },
            {
                rname: 'Newfoundland/Labrad.',
                rkey: 'NL',
            },
            {
                rname: 'Northwest Territory',
                rkey: 'NT',
            },
            {
                rname: 'Nova Scotia',
                rkey: 'NS',
            },
            {
                rname: 'Nunavut',
                rkey: 'NU',
            },
            {
                rname: 'Ontario',
                rkey: 'ON',
            },
            {
                rname: 'Prince Edward Island',
                rkey: 'PE',
            },
            {
                rname: 'Quebec',
                rkey: 'QC',
            },
            {
                rname: 'Saskatchewan',
                rkey: 'SK',
            },
            {
                rname: 'Yukon Territory',
                rkey: 'YT',
            },
        ],
    },
    {
        countryName: 'Cape Verde',
        countryShortCode: 'CV',
        Regions: [],
    },
    {
        countryName: 'Cayman Islands',
        countryShortCode: 'KY',
        Regions: [],
    },
    {
        countryName: 'Central African Republic',
        countryShortCode: 'CF',
        Regions: [],
    },
    {
        countryName: 'Chad',
        countryShortCode: 'TD',
        Regions: [],
    },
    {
        countryName: 'Chile',
        countryShortCode: 'CL',
        Regions: [
            {
                rname: 'I - Iquique',
                rkey: '01',
            },
            {
                rname: 'II - Antofagasta',
                rkey: '02',
            },
            {
                rname: 'III - Copiapo',
                rkey: '03',
            },
            {
                rname: 'IV - La Serena',
                rkey: '04',
            },
            {
                rname: 'IX - Temuco',
                rkey: '09',
            },
            {
                rname: 'RM - Santiago',
                rkey: '13',
            },
            {
                rname: 'V - Valparaiso',
                rkey: '05',
            },
            {
                rname: 'VI - Rancagua',
                rkey: '06',
            },
            {
                rname: 'VII - Talca',
                rkey: '07',
            },
            {
                rname: 'VIII - Concepcion',
                rkey: '08',
            },
            {
                rname: 'X - Puerto Montt',
                rkey: '10',
            },
            {
                rname: 'XI - Coyhaique',
                rkey: '11',
            },
            {
                rname: 'XII - Punta Arenas',
                rkey: '12',
            },
            {
                rname: 'XIV - Los Rios',
                rkey: '14',
            },
            {
                rname: 'XV - Arica y Parinac',
                rkey: '15',
            },
        ],
    },
    {
        countryName: 'China',
        countryShortCode: 'CN',
        Regions: [
            {
                rname: 'Anhui',
                rkey: '110',
            },
            {
                rname: 'Beijing',
                rkey: '010',
            },
            {
                rname: 'Chong Qing',
                rkey: '320',
            },
            {
                rname: 'Fujian',
                rkey: '150',
            },
            {
                rname: 'Gansu',
                rkey: '260',
            },
            {
                rname: 'Guangdong',
                rkey: '190',
            },
            {
                rname: 'Guangxi',
                rkey: '210',
            },
            {
                rname: 'Guizhou',
                rkey: '220',
            },
            {
                rname: 'Hainan',
                rkey: '200',
            },
            {
                rname: 'Hebei',
                rkey: '060',
            },
            {
                rname: 'Heilongjiang',
                rkey: '090',
            },
            {
                rname: 'Henan',
                rkey: '180',
            },
            {
                rname: 'Hubei',
                rkey: '170',
            },
            {
                rname: 'Hunan',
                rkey: '160',
            },
            {
                rname: 'Jiangsu',
                rkey: '100',
            },
            {
                rname: 'Jiangxi',
                rkey: '140',
            },
            {
                rname: 'Jilin',
                rkey: '080',
            },
            {
                rname: 'Liaoning',
                rkey: '070',
            },
            {
                rname: 'Nei Mongol',
                rkey: '040',
            },
            {
                rname: 'Ningxia',
                rkey: '270',
            },
            {
                rname: 'Qinghai',
                rkey: '280',
            },
            {
                rname: 'Shaanxi',
                rkey: '250',
            },
            {
                rname: 'Shandong',
                rkey: '120',
            },
            {
                rname: 'Shanghai',
                rkey: '020',
            },
            {
                rname: 'Shanxi',
                rkey: '050',
            },
            {
                rname: 'Sichuan',
                rkey: '230',
            },
            {
                rname: 'Tianjin',
                rkey: '030',
            },
            {
                rname: 'Xinjiang',
                rkey: '290',
            },
            {
                rname: 'Xizang',
                rkey: '300',
            },
            {
                rname: 'Yunnan',
                rkey: '240',
            },
            {
                rname: 'Zhejiang',
                rkey: '130',
            },
        ],
    },
    {
        countryName: 'Christmas Islnd',
        countryShortCode: 'CX',
        Regions: [],
    },
    {
        countryName: 'Cocos (Keeling) Islands',
        countryShortCode: 'CC',
        Regions: [],
    },
    {
        countryName: 'Colombia',
        countryShortCode: 'CO',
        Regions: [
            {
                rname: 'AMAZONAS',
                rkey: '91',
            },
            {
                rname: 'ANTIOQUIA',
                rkey: '05',
            },
            {
                rname: 'ARAUCA',
                rkey: '81',
            },
            {
                rname: 'ATLANTICO',
                rkey: '08',
            },
            {
                rname: 'BOGOTA',
                rkey: '11',
            },
            {
                rname: 'BOLIVAR',
                rkey: '13',
            },
            {
                rname: 'BOYACA',
                rkey: '15',
            },
            {
                rname: 'CALDAS',
                rkey: '17',
            },
            {
                rname: 'CAQUETA',
                rkey: '18',
            },
            {
                rname: 'CASANARE',
                rkey: '85',
            },
            {
                rname: 'CAUCA',
                rkey: '19',
            },
            {
                rname: 'CESAR',
                rkey: '20',
            },
            {
                rname: 'CHOCO',
                rkey: '27',
            },
            {
                rname: 'CORDOBA',
                rkey: '23',
            },
            {
                rname: 'CUNDINAMARCA',
                rkey: '25',
            },
            {
                rname: 'GUAINIA',
                rkey: '94',
            },
            {
                rname: 'GUAVIARE',
                rkey: '95',
            },
            {
                rname: 'HUILA',
                rkey: '41',
            },
            {
                rname: 'LA GUAJIRA',
                rkey: '44',
            },
            {
                rname: 'MAGDALENA',
                rkey: '47',
            },
            {
                rname: 'META',
                rkey: '50',
            },
            {
                rname: 'NARINO',
                rkey: '52',
            },
            {
                rname: 'NORTE SANTANDER',
                rkey: '54',
            },
            {
                rname: 'PUTUMAYO',
                rkey: '86',
            },
            {
                rname: 'QUINDIO',
                rkey: '63',
            },
            {
                rname: 'RISARALDA',
                rkey: '66',
            },
            {
                rname: 'SAN ANDRES',
                rkey: '88',
            },
            {
                rname: 'SANTANDER',
                rkey: '68',
            },
            {
                rname: 'SUCRE',
                rkey: '70',
            },
            {
                rname: 'TOLIMA',
                rkey: '73',
            },
            {
                rname: 'VALLE',
                rkey: '76',
            },
            {
                rname: 'VAUPES',
                rkey: '97',
            },
            {
                rname: 'VICHADA',
                rkey: '99',
            },
        ],
    },
    {
        countryName: 'Comoros',
        countryShortCode: 'KM',
        Regions: [],
    },
    {
        countryName: 'Cook Islands',
        countryShortCode: 'CK',
        Regions: [],
    },
    {
        countryName: 'Costa Rica',
        countryShortCode: 'CR',
        Regions: [
            {
                rname: 'Alajuela',
                rkey: 'Country',
            },
            {
                rname: 'Cartago',
                rkey: 'Region',
            },
            {
                rname: 'Guanacaste',
                rkey: 'G',
            },
            {
                rname: 'Heredia',
                rkey: 'H',
            },
            {
                rname: 'Limon',
                rkey: 'L',
            },
            {
                rname: 'Puntarenas',
                rkey: 'P',
            },
            {
                rname: 'San Jose',
                rkey: 'SJ',
            },
        ],
    },
    {
        countryName: "Cote d'Ivoire",
        countryShortCode: 'CI',
        Regions: [],
    },
    {
        countryName: 'Croatia',
        countryShortCode: 'HR',
        Regions: [
            {
                rname: 'Bjelovarsko-bilogors',
                rkey: 'G00',
            },
            {
                rname: 'Dubrovacko-neretvans',
                rkey: 'T00',
            },
            {
                rname: 'Istarska',
                rkey: 'S00',
            },
            {
                rname: 'Karlovacka',
                rkey: 'D00',
            },
            {
                rname: 'Koprivnicko-krizevac',
                rkey: 'F00',
            },
            {
                rname: 'Krapinsko-zagorska',
                rkey: 'B00',
            },
            {
                rname: 'Licko-senjska',
                rkey: 'I00',
            },
            {
                rname: 'Medjimurska',
                rkey: 'U00',
            },
            {
                rname: 'Osjecko-baranjska',
                rkey: 'N00',
            },
            {
                rname: 'Pozesko-slavonska',
                rkey: 'K00',
            },
            {
                rname: 'Rijecko-goranska',
                rkey: 'H00',
            },
            {
                rname: 'Sibensko-kninska',
                rkey: 'O00',
            },
            {
                rname: 'Sisacko-moslavacka',
                rkey: 'C00',
            },
            {
                rname: 'Slavonskobrodska',
                rkey: 'L00',
            },
            {
                rname: 'Splitsko-dalmatinska',
                rkey: 'R00',
            },
            {
                rname: 'Varazdinska',
                rkey: 'E00',
            },
            {
                rname: 'Viroviticko-podravac',
                rkey: 'J00',
            },
            {
                rname: 'Vukovarsko-srijemska',
                rkey: 'P00',
            },
            {
                rname: 'Zadarska',
                rkey: 'M00',
            },
            {
                rname: 'Zagreb',
                rkey: 'V00',
            },
            {
                rname: 'Zagrebacka',
                rkey: 'A00',
            },
        ],
    },
    {
        countryName: 'Cuba',
        countryShortCode: 'CU',
        Regions: [],
    },
    {
        countryName: 'Curacao',
        countryShortCode: 'CW',
        Regions: [],
    },
    {
        countryName: 'Cyprus',
        countryShortCode: 'CY',
        Regions: [],
    },
    {
        countryName: 'Czech Republic',
        countryShortCode: 'CZ',
        Regions: [
            {
                rname: 'Jihocesky',
                rkey: '31',
            },
            {
                rname: 'Jihomoravsky',
                rkey: '64',
            },
            {
                rname: 'Karlovarsky',
                rkey: '41',
            },
            {
                rname: 'Kralovehradecky',
                rkey: '52',
            },
            {
                rname: 'Liberecky',
                rkey: '51',
            },
            {
                rname: 'Moravskoslezsky',
                rkey: '81',
            },
            {
                rname: 'Olomoucky',
                rkey: '71',
            },
            {
                rname: 'Pardubicky',
                rkey: '53',
            },
            {
                rname: 'Plzensky',
                rkey: '32',
            },
            {
                rname: 'Praha',
                rkey: '11',
            },
            {
                rname: 'Stredocesky',
                rkey: '21',
            },
            {
                rname: 'Ustecky',
                rkey: '42',
            },
            {
                rname: 'Vysocina',
                rkey: '63',
            },
            {
                rname: 'Zlinsky',
                rkey: '72',
            },
        ],
    },
    {
        countryName: 'Democratic Republic of the Congo',
        countryShortCode: 'CD',
        Regions: [],
    },
    {
        countryName: 'Denmark',
        countryShortCode: 'DK',
        Regions: [
            {
                rname: 'Central Jutland',
                rkey: '002',
            },
            {
                rname: 'Danish Capital Reg.',
                rkey: '001',
            },
            {
                rname: 'North Jutland',
                rkey: '003',
            },
            {
                rname: 'South Denmark',
                rkey: '005',
            },
            {
                rname: 'Zealand',
                rkey: '004',
            },
        ],
    },
    {
        countryName: 'Djibouti',
        countryShortCode: 'DJ',
        Regions: [],
    },
    {
        countryName: 'Dominica',
        countryShortCode: 'DM',
        Regions: [],
    },
    {
        countryName: 'Dominican Republic',
        countryShortCode: 'DO',
        Regions: [
            {
                rname: 'San Jose de Ocoa',
                rkey: '31',
            },
            {
                rname: 'Santo Domingo',
                rkey: '32',
            },
        ],
    },
    {
        countryName: 'East Timor',
        countryShortCode: 'TL',
        Regions: [],
    },
    {
        countryName: 'Ecuador',
        countryShortCode: 'EC',
        Regions: [],
    },
    {
        countryName: 'Egypt',
        countryShortCode: 'EG',
        Regions: [
            {
                rname: 'Al Sharqia',
                rkey: '03',
            },
            {
                rname: 'Alexandria',
                rkey: '08',
            },
            {
                rname: 'Aswan',
                rkey: '17',
            },
            {
                rname: 'Asyut',
                rkey: '11',
            },
            {
                rname: 'Beheira',
                rkey: '05',
            },
            {
                rname: 'Beni Suef',
                rkey: '16',
            },
            {
                rname: 'Cairo',
                rkey: '01',
            },
            {
                rname: 'Dakahlia',
                rkey: '04',
            },
            {
                rname: 'Damietta',
                rkey: '18',
            },
            {
                rname: 'Faiyum',
                rkey: '15',
            },
            {
                rname: 'Gharbia',
                rkey: '09',
            },
            {
                rname: 'Giza',
                rkey: '02',
            },
            {
                rname: 'Ismailia',
                rkey: '19',
            },
            {
                rname: 'Kafr El-Sheikh',
                rkey: '14',
            },
            {
                rname: 'Luxor',
                rkey: '22',
            },
            {
                rname: 'Matruh',
                rkey: '24',
            },
            {
                rname: 'Minya',
                rkey: '07',
            },
            {
                rname: 'Monufia',
                rkey: '12',
            },
            {
                rname: 'New Valley',
                rkey: '26',
            },
            {
                rname: 'North Sinai',
                rkey: '23',
            },
            {
                rname: 'Port Said',
                rkey: '20',
            },
            {
                rname: 'Qalyubia',
                rkey: '06',
            },
            {
                rname: 'Qena',
                rkey: '13',
            },
            {
                rname: 'Red Sea',
                rkey: '25',
            },
            {
                rname: 'Sohag',
                rkey: '10',
            },
            {
                rname: 'South Sinai',
                rkey: '27',
            },
            {
                rname: 'Suez',
                rkey: '21',
            },
        ],
    },
    {
        countryName: 'El Salvador',
        countryShortCode: 'SV',
        Regions: [],
    },
    {
        countryName: 'Equatorial Guinea',
        countryShortCode: 'GQ',
        Regions: [],
    },
    {
        countryName: 'Eritrea',
        countryShortCode: 'ER',
        Regions: [],
    },
    {
        countryName: 'Estonia',
        countryShortCode: 'EE',
        Regions: [],
    },
    {
        countryName: 'Ethiopia',
        countryShortCode: 'ET',
        Regions: [],
    },
    {
        countryName: 'European Union',
        countryShortCode: 'EU',
        Regions: [],
    },
    {
        countryName: 'Falkland Islands',
        countryShortCode: 'FK',
        Regions: [],
    },
    {
        countryName: 'Faroe Islands',
        countryShortCode: 'FO',
        Regions: [],
    },
    {
        countryName: 'Fiji',
        countryShortCode: 'FJ',
        Regions: [],
    },
    {
        countryName: 'Finland',
        countryShortCode: 'FI',
        Regions: [
            {
                rname: 'Ahvenanmaa',
                rkey: '001',
            },
            {
                rname: 'Eastern Finnland',
                rkey: '003',
            },
            {
                rname: 'Lappi',
                rkey: '004',
            },
            {
                rname: 'Oulu',
                rkey: '006',
            },
            {
                rname: 'Southern Finnland',
                rkey: '002',
            },
            {
                rname: 'Western Finnland',
                rkey: '005',
            },
        ],
    },
    {
        countryName: 'France',
        countryShortCode: 'FR',
        Regions: [
            {
                rname: 'Ain',
                rkey: '01',
            },
            {
                rname: 'Aisne',
                rkey: '02',
            },
            {
                rname: 'Allier',
                rkey: '03',
            },
            {
                rname: 'Alpes (Hautes)',
                rkey: '05',
            },
            {
                rname: 'Alpes (Hte-Provence)',
                rkey: '04',
            },
            {
                rname: 'Alpes-Maritimes',
                rkey: '06',
            },
            {
                rname: 'Ardeche',
                rkey: '07',
            },
            {
                rname: 'Ardennes',
                rkey: '08',
            },
            {
                rname: 'Ariege',
                rkey: '09',
            },
            {
                rname: 'Aube',
                rkey: '10',
            },
            {
                rname: 'Aude',
                rkey: '11',
            },
            {
                rname: 'Aveyron',
                rkey: '12',
            },
            {
                rname: 'Bas-Rhin',
                rkey: '67',
            },
            {
                rname: 'Bouches-du-Rhone',
                rkey: '13',
            },
            {
                rname: 'Calvados',
                rkey: '14',
            },
            {
                rname: 'Cantal',
                rkey: '15',
            },
            {
                rname: 'Charente',
                rkey: '16',
            },
            {
                rname: 'Charente-Maritime',
                rkey: '17',
            },
            {
                rname: 'Cher',
                rkey: '18',
            },
            {
                rname: 'Correze',
                rkey: '19',
            },
            {
                rname: 'Corse-du-Nord',
                rkey: '2B',
            },
            {
                rname: 'Corse-du-Sud',
                rkey: '2A',
            },
            {
                rname: "Cote-d'Or",
                rkey: '21',
            },
            {
                rname: "Cotes-d'Armor",
                rkey: '22',
            },
            {
                rname: 'Creuse',
                rkey: '23',
            },
            {
                rname: 'D.O.M.-T.O.M.',
                rkey: '97',
            },
            {
                rname: 'Dordogne',
                rkey: '24',
            },
            {
                rname: 'Doubs',
                rkey: '25',
            },
            {
                rname: 'Drome',
                rkey: '26',
            },
            {
                rname: 'Essonne',
                rkey: '91',
            },
            {
                rname: 'Eure',
                rkey: '27',
            },
            {
                rname: 'Eure-et-Loir',
                rkey: '28',
            },
            {
                rname: 'Finistere',
                rkey: '29',
            },
            {
                rname: 'Gard',
                rkey: '30',
            },
            {
                rname: 'Garonne (Haute)',
                rkey: '31',
            },
            {
                rname: 'Gers',
                rkey: '32',
            },
            {
                rname: 'Gironde',
                rkey: '33',
            },
            {
                rname: 'Guadeloupe',
                rkey: '971',
            },
            {
                rname: 'Guyane',
                rkey: '973',
            },
            {
                rname: 'Haut-Rhin',
                rkey: '68',
            },
            {
                rname: 'Hauts-de-Seine',
                rkey: '92',
            },
            {
                rname: 'Herault',
                rkey: '34',
            },
            {
                rname: 'Hors-France',
                rkey: '99',
            },
            {
                rname: 'Ille-et-Vilaine',
                rkey: '35',
            },
            {
                rname: 'Indre',
                rkey: '36',
            },
            {
                rname: 'Indre-et-Loire',
                rkey: '37',
            },
            {
                rname: 'Isere',
                rkey: '38',
            },
            {
                rname: 'Jura',
                rkey: '39',
            },
            {
                rname: 'Landes',
                rkey: '40',
            },
            {
                rname: 'Loire',
                rkey: '42',
            },
            {
                rname: 'Loire (Haute)',
                rkey: '43',
            },
            {
                rname: 'Loire-Atlantique',
                rkey: '44',
            },
            {
                rname: 'Loiret',
                rkey: '45',
            },
            {
                rname: 'Loir-et-Cher',
                rkey: '41',
            },
            {
                rname: 'Lot',
                rkey: '46',
            },
            {
                rname: 'Lot-et-Garonne',
                rkey: '47',
            },
            {
                rname: 'Lozere',
                rkey: '48',
            },
            {
                rname: 'Maine-et-Loire',
                rkey: '49',
            },
            {
                rname: 'Manche',
                rkey: '50',
            },
            {
                rname: 'Marne',
                rkey: '51',
            },
            {
                rname: 'Marne (Haute)',
                rkey: '52',
            },
            {
                rname: 'Martinique',
                rkey: '972',
            },
            {
                rname: 'Mayenne',
                rkey: '53',
            },
            {
                rname: 'Meurthe-et-Moselle',
                rkey: '54',
            },
            {
                rname: 'Meuse',
                rkey: '55',
            },
            {
                rname: 'Morbihan',
                rkey: '56',
            },
            {
                rname: 'Moselle',
                rkey: '57',
            },
            {
                rname: 'Nievre',
                rkey: '58',
            },
            {
                rname: 'Nord',
                rkey: '59',
            },
            {
                rname: 'Oise',
                rkey: '60',
            },
            {
                rname: 'Orne',
                rkey: '61',
            },
            {
                rname: 'Paris',
                rkey: '75',
            },
            {
                rname: 'Pas-de-Calais',
                rkey: '62',
            },
            {
                rname: 'Puy-de-Dome',
                rkey: '63',
            },
            {
                rname: 'Pyrenees (Hautes)',
                rkey: '65',
            },
            {
                rname: 'Pyrenees-Atlantiques',
                rkey: '64',
            },
            {
                rname: 'Pyrenees-Orientales',
                rkey: '66',
            },
            {
                rname: 'Reunion',
                rkey: '974',
            },
            {
                rname: 'Rhone',
                rkey: '69',
            },
            {
                rname: 'Saint-Pierre-et-Miq.',
                rkey: '975',
            },
            {
                rname: 'Saone (Haute)',
                rkey: '70',
            },
            {
                rname: 'Saone-et-Loire',
                rkey: '71',
            },
            {
                rname: 'Sarthe',
                rkey: '72',
            },
            {
                rname: 'Savoie',
                rkey: '73',
            },
            {
                rname: 'Savoie (Haute)',
                rkey: '74',
            },
            {
                rname: 'Seine-et-Marne',
                rkey: '77',
            },
            {
                rname: 'Seine-Maritime',
                rkey: '76',
            },
            {
                rname: 'Seine-Saint-Denis',
                rkey: '93',
            },
            {
                rname: 'Sevres (Deux)',
                rkey: '79',
            },
            {
                rname: 'Somme',
                rkey: '80',
            },
            {
                rname: 'Tarn',
                rkey: '81',
            },
            {
                rname: 'Tarn-et-Garonne',
                rkey: '82',
            },
            {
                rname: 'Territ.-de-Belfort',
                rkey: '90',
            },
            {
                rname: 'Val-de-Marne',
                rkey: '94',
            },
            {
                rname: "Val-d'Oise",
                rkey: '95',
            },
            {
                rname: 'Var',
                rkey: '83',
            },
            {
                rname: 'Vaucluse',
                rkey: '84',
            },
            {
                rname: 'Vendee',
                rkey: '85',
            },
            {
                rname: 'Vienne',
                rkey: '86',
            },
            {
                rname: 'Vienne (Haute)',
                rkey: '87',
            },
            {
                rname: 'Vosges',
                rkey: '88',
            },
            {
                rname: 'Wallis-et-Futuna',
                rkey: '976',
            },
            {
                rname: 'Yonne',
                rkey: '89',
            },
            {
                rname: 'Yvelines',
                rkey: '78',
            },
        ],
    },
    {
        countryName: 'French Guyana',
        countryShortCode: 'GF',
        Regions: [],
    },
    {
        countryName: 'French Polynesia',
        countryShortCode: 'PF',
        Regions: [],
    },
    {
        countryName: 'French Southern and Antarctic Lands',
        countryShortCode: 'TF',
        Regions: [],
    },
    {
        countryName: 'Gabon',
        countryShortCode: 'GA',
        Regions: [],
    },
    {
        countryName: 'Gambia',
        countryShortCode: 'GM',
        Regions: [],
    },
    {
        countryName: 'Georgia',
        countryShortCode: 'GE',
        Regions: [],
    },
    {
        countryName: 'Germany',
        countryShortCode: 'DE',
        Regions: [
            {
                rname: 'Baden-Wurttemberg',
                rkey: '08',
            },
            {
                rname: 'Bavaria',
                rkey: '09',
            },
            {
                rname: 'Berlin',
                rkey: '11',
            },
            {
                rname: 'Brandenburg',
                rkey: '12',
            },
            {
                rname: 'Bremen',
                rkey: '04',
            },
            {
                rname: 'Hamburg',
                rkey: '02',
            },
            {
                rname: 'Hessen',
                rkey: '06',
            },
            {
                rname: 'Lower Saxony',
                rkey: '03',
            },
            {
                rname: 'Mecklenburg-Vorpomm.',
                rkey: '13',
            },
            {
                rname: 'Nrth Rhine Westfalia',
                rkey: '05',
            },
            {
                rname: 'Rhineland Palatinate',
                rkey: '07',
            },
            {
                rname: 'Saarland',
                rkey: '10',
            },
            {
                rname: 'Saxony',
                rkey: '14',
            },
            {
                rname: 'Saxony-Anhalt',
                rkey: '15',
            },
            {
                rname: 'Schleswig-Holstein',
                rkey: '01',
            },
            {
                rname: 'Thuringia',
                rkey: '16',
            },
        ],
    },
    {
        countryName: 'Ghana',
        countryShortCode: 'GH',
        Regions: [],
    },
    {
        countryName: 'Gibraltar',
        countryShortCode: 'GI',
        Regions: [],
    },
    {
        countryName: 'Greece',
        countryShortCode: 'GR',
        Regions: [
            {
                rname: 'Aitolia kai Akarnan.',
                rkey: '01',
            },
            {
                rname: 'Akhaia',
                rkey: '02',
            },
            {
                rname: 'Argolis',
                rkey: '03',
            },
            {
                rname: 'Arkadhia',
                rkey: '04',
            },
            {
                rname: 'Arta',
                rkey: '05',
            },
            {
                rname: 'Attiki',
                rkey: '06',
            },
            {
                rname: 'Dhodhekanisos',
                rkey: '07',
            },
            {
                rname: 'Dhrama',
                rkey: '08',
            },
            {
                rname: 'Evritania',
                rkey: '09',
            },
            {
                rname: 'Evros',
                rkey: '10',
            },
            {
                rname: 'Evvoia',
                rkey: '11',
            },
            {
                rname: 'Florina',
                rkey: '12',
            },
            {
                rname: 'Fokis',
                rkey: '13',
            },
            {
                rname: 'Fthiotis',
                rkey: '14',
            },
            {
                rname: 'Grevena',
                rkey: '15',
            },
            {
                rname: 'Ilia',
                rkey: '16',
            },
            {
                rname: 'Imathia',
                rkey: '17',
            },
            {
                rname: 'Ioannina',
                rkey: '18',
            },
            {
                rname: 'Iraklion',
                rkey: '19',
            },
            {
                rname: 'Kardhitsa',
                rkey: '20',
            },
            {
                rname: 'Kastoria',
                rkey: '21',
            },
            {
                rname: 'Kavala',
                rkey: '22',
            },
            {
                rname: 'Kefallinia',
                rkey: '23',
            },
            {
                rname: 'Kerkira',
                rkey: '24',
            },
            {
                rname: 'Khalkidhiki',
                rkey: '25',
            },
            {
                rname: 'Khania',
                rkey: '26',
            },
            {
                rname: 'Khios',
                rkey: '27',
            },
            {
                rname: 'Kikladhes',
                rkey: '28',
            },
            {
                rname: 'Kilkis',
                rkey: '29',
            },
            {
                rname: 'Korinthia',
                rkey: '30',
            },
            {
                rname: 'Kozani',
                rkey: '31',
            },
            {
                rname: 'Lakonia',
                rkey: '32',
            },
            {
                rname: 'Larisa',
                rkey: '33',
            },
            {
                rname: 'Lasithi',
                rkey: '34',
            },
            {
                rname: 'Lesvos',
                rkey: '35',
            },
            {
                rname: 'Levkas',
                rkey: '36',
            },
            {
                rname: 'Magnisia',
                rkey: '37',
            },
            {
                rname: 'Messinia',
                rkey: '38',
            },
            {
                rname: 'Pella',
                rkey: '39',
            },
            {
                rname: 'Pieria',
                rkey: '40',
            },
            {
                rname: 'Piraievs',
                rkey: '41',
            },
            {
                rname: 'Preveza',
                rkey: '42',
            },
            {
                rname: 'Rethimni',
                rkey: '43',
            },
            {
                rname: 'Rodhopi',
                rkey: '44',
            },
            {
                rname: 'Samos',
                rkey: '45',
            },
            {
                rname: 'Serrai',
                rkey: '46',
            },
            {
                rname: 'Thesprotia',
                rkey: '47',
            },
            {
                rname: 'Thessaloniki',
                rkey: '48',
            },
            {
                rname: 'Trikala',
                rkey: '49',
            },
            {
                rname: 'Voiotia',
                rkey: '50',
            },
            {
                rname: 'Xanthi',
                rkey: '51',
            },
            {
                rname: 'Zakinthos',
                rkey: '52',
            },
        ],
    },
    {
        countryName: 'Greenland',
        countryShortCode: 'GL',
        Regions: [],
    },
    {
        countryName: 'Grenada',
        countryShortCode: 'GD',
        Regions: [],
    },
    {
        countryName: 'Guadeloupe',
        countryShortCode: 'GP',
        Regions: [],
    },
    {
        countryName: 'Guam',
        countryShortCode: 'GU',
        Regions: [],
    },
    {
        countryName: 'Guatemala',
        countryShortCode: 'GT',
        Regions: [],
    },
    {
        countryName: 'Guernsey (Channel Islands)',
        countryShortCode: 'GG',
        Regions: [],
    },
    {
        countryName: 'Guinea',
        countryShortCode: 'GN',
        Regions: [],
    },
    {
        countryName: 'Guinea-Bissau',
        countryShortCode: 'GW',
        Regions: [],
    },
    {
        countryName: 'Guyana',
        countryShortCode: 'GY',
        Regions: [],
    },
    {
        countryName: 'Haiti',
        countryShortCode: 'HT',
        Regions: [],
    },
    {
        countryName: 'Heard and McDonald Islands',
        countryShortCode: 'HM',
        Regions: [],
    },
    {
        countryName: 'Honduras',
        countryShortCode: 'HN',
        Regions: [],
    },
    {
        countryName: 'Hong Kong',
        countryShortCode: 'HK',
        Regions: [
            {
                rname: 'Hong Kong Island',
                rkey: 'HK',
            },
            {
                rname: 'Kowloon',
                rkey: 'KLN',
            },
            {
                rname: 'New Territories',
                rkey: 'NT',
            },
        ],
    },
    {
        countryName: 'Hungary',
        countryShortCode: 'HU',
        Regions: [
            {
                rname: 'Bacs-Kiskun',
                rkey: '01',
            },
            {
                rname: 'Baranya',
                rkey: '02',
            },
            {
                rname: 'Bekes',
                rkey: '03',
            },
            {
                rname: 'Bekescsaba',
                rkey: '04',
            },
            {
                rname: 'Borsod-Abauj-Zemplen',
                rkey: '05',
            },
            {
                rname: 'Budapest',
                rkey: '06',
            },
            {
                rname: 'Csongrad',
                rkey: '07',
            },
            {
                rname: 'Debrecen',
                rkey: '08',
            },
            {
                rname: 'Dunaujvaros',
                rkey: '09',
            },
            {
                rname: 'Eger',
                rkey: '10',
            },
            {
                rname: 'Fejer',
                rkey: '11',
            },
            {
                rname: 'Gyor',
                rkey: '12',
            },
            {
                rname: 'Gyor-Moson-Sopron',
                rkey: '13',
            },
            {
                rname: 'Hajdu-Bihar',
                rkey: '14',
            },
            {
                rname: 'Heves',
                rkey: '15',
            },
            {
                rname: 'Hodmezovasarhely',
                rkey: '16',
            },
            {
                rname: 'Jasz-Nagykun-Szolnok',
                rkey: '17',
            },
            {
                rname: 'Kaposvar',
                rkey: '18',
            },
            {
                rname: 'Kecskemet',
                rkey: '19',
            },
            {
                rname: 'Komarom-Esztergom',
                rkey: '20',
            },
            {
                rname: 'Miskolc',
                rkey: '21',
            },
            {
                rname: 'Nagykanizsa',
                rkey: '22',
            },
            {
                rname: 'Nograd',
                rkey: '23',
            },
            {
                rname: 'Nyiregyhaza',
                rkey: '24',
            },
            {
                rname: 'Pecs',
                rkey: '25',
            },
            {
                rname: 'Pest',
                rkey: '26',
            },
            {
                rname: 'Somogy',
                rkey: '27',
            },
            {
                rname: 'Sopron',
                rkey: '28',
            },
            {
                rname: 'Szabolcs-Szat.-Bereg',
                rkey: '29',
            },
            {
                rname: 'Szeged',
                rkey: '30',
            },
            {
                rname: 'Szekesfehervar',
                rkey: '31',
            },
            {
                rname: 'Szolnok',
                rkey: '32',
            },
            {
                rname: 'Szombathely',
                rkey: '33',
            },
            {
                rname: 'Tatabanya',
                rkey: '34',
            },
            {
                rname: 'Tolna',
                rkey: '35',
            },
            {
                rname: 'Vas',
                rkey: '36',
            },
            {
                rname: 'Veszprem',
                rkey: '37',
            },
            {
                rname: 'Zala',
                rkey: '38',
            },
            {
                rname: 'Zalaegerszeg',
                rkey: '39',
            },
        ],
    },
    {
        countryName: 'Iceland',
        countryShortCode: 'IS',
        Regions: [],
    },
    {
        countryName: 'India',
        countryShortCode: 'IN',
        Regions: [
            {
                rname: 'Andaman and Nico.Is.',
                rkey: '26',
            },
            {
                rname: 'Andhra Pradesh',
                rkey: '01',
            },
            {
                rname: 'Arunachal Pradesh',
                rkey: '02',
            },
            {
                rname: 'Assam',
                rkey: '03',
            },
            {
                rname: 'Bihar',
                rkey: '04',
            },
            {
                rname: 'Chandigarh',
                rkey: '27',
            },
            {
                rname: 'Chhattisgarh',
                rkey: '33',
            },
            {
                rname: 'Dadra and Nagar Hav.',
                rkey: '28',
            },
            {
                rname: 'Daman and Diu',
                rkey: '29',
            },
            {
                rname: 'Delhi',
                rkey: '30',
            },
            {
                rname: 'Goa',
                rkey: '05',
            },
            {
                rname: 'Gujarat',
                rkey: '06',
            },
            {
                rname: 'Haryana',
                rkey: '07',
            },
            {
                rname: 'Himachal Pradesh',
                rkey: '08',
            },
            {
                rname: 'Jammu and Kashmir',
                rkey: '09',
            },
            {
                rname: 'Jharkhand',
                rkey: '34',
            },
            {
                rname: 'Karnataka',
                rkey: '10',
            },
            {
                rname: 'Kerala',
                rkey: '11',
            },
            {
                rname: 'Lakshadweep',
                rkey: '31',
            },
            {
                rname: 'Madhya Pradesh',
                rkey: '12',
            },
            {
                rname: 'Maharashtra',
                rkey: '13',
            },
            {
                rname: 'Manipur',
                rkey: '14',
            },
            {
                rname: 'Meghalaya',
                rkey: '15',
            },
            {
                rname: 'Mizoram',
                rkey: '16',
            },
            {
                rname: 'Nagaland',
                rkey: '17',
            },
            {
                rname: 'Orissa',
                rkey: '18',
            },
            {
                rname: 'Puducherry',
                rkey: '32',
            },
            {
                rname: 'Punjab',
                rkey: '19',
            },
            {
                rname: 'Rajasthan',
                rkey: '20',
            },
            {
                rname: 'Sikkim',
                rkey: '21',
            },
            {
                rname: 'Tamil Nadu',
                rkey: '22',
            },
            {
                rname: 'Tripura',
                rkey: '23',
            },
            {
                rname: 'Uttar Pradesh',
                rkey: '24',
            },
            {
                rname: 'Uttarakhand',
                rkey: '35',
            },
            {
                rname: 'West Bengal',
                rkey: '25',
            },
        ],
    },
    {
        countryName: 'Indonesia',
        countryShortCode: 'ID',
        Regions: [
            {
                rname: 'Bali Bali',
                rkey: '22',
            },
            {
                rname: 'Bengkulu Bengkulu',
                rkey: '12',
            },
            {
                rname: 'DI Aceh Aceh',
                rkey: '06',
            },
            {
                rname: 'DI Yogyakarta Yogyak',
                rkey: '05',
            },
            {
                rname: 'DKI Jakarta Jakarta',
                rkey: '01',
            },
            {
                rname: 'Irian Jaya Irian Jay',
                rkey: '26',
            },
            {
                rname: 'Jambi Jambi',
                rkey: '10',
            },
            {
                rname: 'Jawa Barat West Java',
                rkey: '02',
            },
            {
                rname: 'Jawa Tengah Central',
                rkey: '03',
            },
            {
                rname: 'Jawa Timur East Java',
                rkey: '04',
            },
            {
                rname: 'Kalimantan Barat Wes',
                rkey: '15',
            },
            {
                rname: 'Kalimantan Selatan S',
                rkey: '14',
            },
            {
                rname: 'Kalimantan Tengah Ce',
                rkey: '16',
            },
            {
                rname: 'Kalimantan Timur Eas',
                rkey: '17',
            },
            {
                rname: 'Lampung Lampung',
                rkey: '13',
            },
            {
                rname: 'Maluku Maluku',
                rkey: '25',
            },
            {
                rname: 'Nusa Tenggara Barat',
                rkey: '23',
            },
            {
                rname: 'Nusa Tenggara Timur',
                rkey: '24',
            },
            {
                rname: 'Riau Riau',
                rkey: '09',
            },
            {
                rname: 'Sulawesi Selatan Sou',
                rkey: '18',
            },
            {
                rname: 'Sulawesi Tengah Cent',
                rkey: '20',
            },
            {
                rname: 'Sulawesi Tenggara So',
                rkey: '19',
            },
            {
                rname: 'Sulawesi Utara North',
                rkey: '21',
            },
            {
                rname: 'Sumatera Barat West',
                rkey: '08',
            },
            {
                rname: 'Sumatera Selatan Sou',
                rkey: '11',
            },
            {
                rname: 'Sumatera Utara North',
                rkey: '07',
            },
            {
                rname: 'Timor Timur East Tim',
                rkey: '27',
            },
        ],
    },
    {
        countryName: 'Iran',
        countryShortCode: 'IR',
        Regions: [],
    },
    {
        countryName: 'Iraq',
        countryShortCode: 'IQ',
        Regions: [],
    },
    {
        countryName: 'Ireland',
        countryShortCode: 'IE',
        Regions: [
            {
                rname: 'Carlow',
                rkey: 'CW',
            },
            {
                rname: 'Cavan',
                rkey: 'CN',
            },
            {
                rname: 'Clare',
                rkey: 'CE',
            },
            {
                rname: 'Donegal',
                rkey: 'DL',
            },
            {
                rname: 'Dublin',
                rkey: 'RegionKey',
            },
            {
                rname: 'Dublin',
                rkey: 'DB',
            },
            {
                rname: 'Kerry',
                rkey: 'KY',
            },
            {
                rname: 'Kildare',
                rkey: 'KE',
            },
            {
                rname: 'Kilkenny',
                rkey: 'KK',
            },
            {
                rname: 'Laois',
                rkey: 'LS',
            },
            {
                rname: 'Leitrim',
                rkey: 'LM',
            },
            {
                rname: 'Limerick',
                rkey: 'LK',
            },
            {
                rname: 'Longford',
                rkey: 'LD',
            },
            {
                rname: 'Louth',
                rkey: 'LH',
            },
            {
                rname: 'Mayo',
                rkey: 'MO',
            },
            {
                rname: 'Meath',
                rkey: 'MH',
            },
            {
                rname: 'Monaghan',
                rkey: 'MN',
            },
            {
                rname: 'Offaly',
                rkey: 'OY',
            },
            {
                rname: 'Roscommon',
                rkey: 'RN',
            },
            {
                rname: 'Sligo',
                rkey: 'SO',
            },
            {
                rname: 'Tipperary',
                rkey: 'TA',
            },
            {
                rname: 'Waterford',
                rkey: 'WD',
            },
            {
                rname: 'Westmeath',
                rkey: 'WH',
            },
            {
                rname: 'Wexford',
                rkey: 'WX',
            },
            {
                rname: 'Wicklow',
                rkey: 'WK',
            },
            {
                rname: 'Wicklow',
                rkey: 'WW',
            },
        ],
    },
    {
        countryName: 'Isle of Man',
        countryShortCode: 'IM',
        Regions: [],
    },
    {
        countryName: 'Israel',
        countryShortCode: 'IL',
        Regions: [
            {
                rname: 'Central',
                rkey: '01',
            },
            {
                rname: 'Haifa',
                rkey: '02',
            },
            {
                rname: 'Jerusalem',
                rkey: '03',
            },
            {
                rname: 'Northern',
                rkey: '04',
            },
            {
                rname: 'Southern',
                rkey: '05',
            },
            {
                rname: 'Tel Aviv',
                rkey: '06',
            },
        ],
    },
    {
        countryName: 'Italy',
        countryShortCode: 'IT',
        Regions: [
            {
                rname: 'Agriento',
                rkey: 'AG',
            },
            {
                rname: 'Alessandria',
                rkey: 'AL',
            },
            {
                rname: 'Ancona',
                rkey: 'AN',
            },
            {
                rname: 'Aosta',
                rkey: 'AO',
            },
            {
                rname: 'Arezzo',
                rkey: 'AR',
            },
            {
                rname: 'Ascoli Piceno',
                rkey: 'AP',
            },
            {
                rname: 'Asti',
                rkey: 'AT',
            },
            {
                rname: 'Avellino',
                rkey: 'AV',
            },
            {
                rname: 'Bari',
                rkey: 'BA',
            },
            {
                rname: 'Barletta Andria Tran',
                rkey: 'BT',
            },
            {
                rname: 'Belluno',
                rkey: 'BL',
            },
            {
                rname: 'Benevento',
                rkey: 'BN',
            },
            {
                rname: 'Bergamo',
                rkey: 'BG',
            },
            {
                rname: 'Biella',
                rkey: 'BI',
            },
            {
                rname: 'Bologna',
                rkey: 'BO',
            },
            {
                rname: 'Bolzano',
                rkey: 'BZ',
            },
            {
                rname: 'Brescia',
                rkey: 'BS',
            },
            {
                rname: 'Brindisi',
                rkey: 'BR',
            },
            {
                rname: 'Cagliari',
                rkey: 'CA',
            },
            {
                rname: 'Caltanisetta',
                rkey: 'CL',
            },
            {
                rname: 'Campobasso',
                rkey: 'CB',
            },
            {
                rname: 'Carbonia-Iglesias',
                rkey: 'CI',
            },
            {
                rname: 'Caserta',
                rkey: 'CE',
            },
            {
                rname: 'Catania',
                rkey: 'CT',
            },
            {
                rname: 'Catanzaro',
                rkey: 'CZ',
            },
            {
                rname: 'Chieti',
                rkey: 'CH',
            },
            {
                rname: 'Como',
                rkey: 'CO',
            },
            {
                rname: 'Cosenza',
                rkey: 'CS',
            },
            {
                rname: 'Cremona',
                rkey: 'CR',
            },
            {
                rname: 'Crotone',
                rkey: 'KR',
            },
            {
                rname: 'Cuneo',
                rkey: 'CN',
            },
            {
                rname: 'Enna',
                rkey: 'EN',
            },
            {
                rname: 'Fermo',
                rkey: 'FM',
            },
            {
                rname: 'Ferrara',
                rkey: 'FE',
            },
            {
                rname: 'Fiume',
                rkey: 'FU',
            },
            {
                rname: 'Florence',
                rkey: 'FI',
            },
            {
                rname: 'Foggia',
                rkey: 'FG',
            },
            {
                rname: 'Forli/Cesana',
                rkey: 'FC',
            },
            {
                rname: 'Frosinone',
                rkey: 'FR',
            },
            {
                rname: 'Genova',
                rkey: 'GE',
            },
            {
                rname: 'Gorizia',
                rkey: 'GO',
            },
            {
                rname: 'Grosseto',
                rkey: 'GR',
            },
            {
                rname: 'Imperia',
                rkey: 'IM',
            },
            {
                rname: 'Isernia',
                rkey: 'IS',
            },
            {
                rname: 'La Spezia',
                rkey: 'SP',
            },
            {
                rname: "L'Aquila",
                rkey: 'AQ',
            },
            {
                rname: 'Latina',
                rkey: 'LT',
            },
            {
                rname: 'Lecce',
                rkey: 'LE',
            },
            {
                rname: 'Lecco',
                rkey: 'LC',
            },
            {
                rname: 'Livorno',
                rkey: 'LI',
            },
            {
                rname: 'Lodi',
                rkey: 'LO',
            },
            {
                rname: 'Lucca',
                rkey: 'LU',
            },
            {
                rname: 'Macerata',
                rkey: 'MC',
            },
            {
                rname: 'Mantova',
                rkey: 'MN',
            },
            {
                rname: 'Massa Carrara',
                rkey: 'MS',
            },
            {
                rname: 'Matera',
                rkey: 'MT',
            },
            {
                rname: 'Medio Campidano',
                rkey: 'VS',
            },
            {
                rname: 'Messina',
                rkey: 'ME',
            },
            {
                rname: 'Milan',
                rkey: 'MI',
            },
            {
                rname: 'Modena',
                rkey: 'MO',
            },
            {
                rname: 'Monza e Brianza',
                rkey: 'MB',
            },
            {
                rname: 'Naples',
                rkey: 'NA',
            },
            {
                rname: 'Novara',
                rkey: 'NO',
            },
            {
                rname: 'Nuoro',
                rkey: 'NU',
            },
            {
                rname: 'Ogliastra',
                rkey: 'OG',
            },
            {
                rname: 'Olbia-Tempio',
                rkey: 'OT',
            },
            {
                rname: 'Oristano',
                rkey: 'OR',
            },
            {
                rname: 'Padova',
                rkey: 'PD',
            },
            {
                rname: 'Palermo',
                rkey: 'PA',
            },
            {
                rname: 'Parma',
                rkey: 'PR',
            },
            {
                rname: 'Pavia',
                rkey: 'PV',
            },
            {
                rname: 'Perugia',
                rkey: 'PG',
            },
            {
                rname: 'Pesaro-Urbino',
                rkey: 'PU',
            },
            {
                rname: 'Pescara',
                rkey: 'PE',
            },
            {
                rname: 'Piacenza',
                rkey: 'PC',
            },
            {
                rname: 'Pisa',
                rkey: 'PI',
            },
            {
                rname: 'Pistoia',
                rkey: 'PT',
            },
            {
                rname: 'Pola',
                rkey: 'PL',
            },
            {
                rname: 'Pordenone',
                rkey: 'PN',
            },
            {
                rname: 'Potenza',
                rkey: 'PZ',
            },
            {
                rname: 'Prato',
                rkey: 'PO',
            },
            {
                rname: 'Ragusa',
                rkey: 'RG',
            },
            {
                rname: 'Ravenna',
                rkey: 'RA',
            },
            {
                rname: 'Reggio Calabria',
                rkey: 'RC',
            },
            {
                rname: 'Reggio Emilia',
                rkey: 'RE',
            },
            {
                rname: 'Rieti',
                rkey: 'RI',
            },
            {
                rname: 'Rimini',
                rkey: 'RN',
            },
            {
                rname: 'Rome',
                rkey: 'RM',
            },
            {
                rname: 'Rovigo',
                rkey: 'RO',
            },
            {
                rname: 'Salerno',
                rkey: 'SA',
            },
            {
                rname: 'Sassari',
                rkey: 'SS',
            },
            {
                rname: 'Savona',
                rkey: 'SV',
            },
            {
                rname: 'Siena',
                rkey: 'SI',
            },
            {
                rname: 'Siracusa',
                rkey: 'SR',
            },
            {
                rname: 'Sondrio',
                rkey: 'SO',
            },
            {
                rname: 'Stati Esteri',
                rkey: 'EE',
            },
            {
                rname: 'Taranto',
                rkey: 'TA',
            },
            {
                rname: 'Teramo',
                rkey: 'TE',
            },
            {
                rname: 'Terni',
                rkey: 'TR',
            },
            {
                rname: 'Trapani',
                rkey: 'TP',
            },
            {
                rname: 'Trento',
                rkey: 'TN',
            },
            {
                rname: 'Treviso',
                rkey: 'TV',
            },
            {
                rname: 'Trieste',
                rkey: 'TS',
            },
            {
                rname: 'Turin',
                rkey: 'TO',
            },
            {
                rname: 'Udine',
                rkey: 'UD',
            },
            {
                rname: 'Varese',
                rkey: 'VA',
            },
            {
                rname: 'Venice',
                rkey: 'VE',
            },
            {
                rname: 'Verbania',
                rkey: 'VB',
            },
            {
                rname: 'Vercelli',
                rkey: 'VC',
            },
            {
                rname: 'Verona',
                rkey: 'VR',
            },
            {
                rname: 'Vibo Valentia',
                rkey: 'VV',
            },
            {
                rname: 'Vicenza',
                rkey: 'VI',
            },
            {
                rname: 'Viterbo',
                rkey: 'VT',
            },
            {
                rname: 'Zara',
                rkey: 'ZA',
            },
        ],
    },
    {
        countryName: 'Jamaica',
        countryShortCode: 'JM',
        Regions: [],
    },
    {
        countryName: 'Japan',
        countryShortCode: 'JP',
        Regions: [
            {
                rname: 'Aichi',
                rkey: '23',
            },
            {
                rname: 'Akita',
                rkey: '05',
            },
            {
                rname: 'Aomori',
                rkey: '02',
            },
            {
                rname: 'Chiba',
                rkey: '12',
            },
            {
                rname: 'Ehime',
                rkey: '38',
            },
            {
                rname: 'Fukui',
                rkey: '18',
            },
            {
                rname: 'Fukuoka',
                rkey: '40',
            },
            {
                rname: 'Fukushima',
                rkey: '07',
            },
            {
                rname: 'Gifu',
                rkey: '21',
            },
            {
                rname: 'Gunma',
                rkey: '10',
            },
            {
                rname: 'Hiroshima',
                rkey: '34',
            },
            {
                rname: 'Hokkaido',
                rkey: '01',
            },
            {
                rname: 'Hyogo',
                rkey: '28',
            },
            {
                rname: 'Ibaraki',
                rkey: '08',
            },
            {
                rname: 'Ishikawa',
                rkey: '17',
            },
            {
                rname: 'Iwate',
                rkey: '03',
            },
            {
                rname: 'Kagawa',
                rkey: '37',
            },
            {
                rname: 'Kagoshima',
                rkey: '46',
            },
            {
                rname: 'Kanagawa',
                rkey: '14',
            },
            {
                rname: 'Kochi',
                rkey: '39',
            },
            {
                rname: 'Kumamoto',
                rkey: '43',
            },
            {
                rname: 'Kyoto',
                rkey: '26',
            },
            {
                rname: 'Mie',
                rkey: '24',
            },
            {
                rname: 'Miyagi',
                rkey: '04',
            },
            {
                rname: 'Miyazaki',
                rkey: '45',
            },
            {
                rname: 'Nagano',
                rkey: '20',
            },
            {
                rname: 'Nagasaki',
                rkey: '42',
            },
            {
                rname: 'Nara',
                rkey: '29',
            },
            {
                rname: 'Niigata',
                rkey: '15',
            },
            {
                rname: 'Oita',
                rkey: '44',
            },
            {
                rname: 'Okayama',
                rkey: '33',
            },
            {
                rname: 'Okinawa',
                rkey: '47',
            },
            {
                rname: 'Osaka',
                rkey: '27',
            },
            {
                rname: 'Saga',
                rkey: '41',
            },
            {
                rname: 'Saitama',
                rkey: '11',
            },
            {
                rname: 'Shiga',
                rkey: '25',
            },
            {
                rname: 'Shimane',
                rkey: '32',
            },
            {
                rname: 'Shizuoka',
                rkey: '22',
            },
            {
                rname: 'Tochigi',
                rkey: '09',
            },
            {
                rname: 'Tokushima',
                rkey: '36',
            },
            {
                rname: 'Tokyo',
                rkey: '13',
            },
            {
                rname: 'Tottori',
                rkey: '31',
            },
            {
                rname: 'Toyama',
                rkey: '16',
            },
            {
                rname: 'Wakayama',
                rkey: '30',
            },
            {
                rname: 'Yamagata',
                rkey: '06',
            },
            {
                rname: 'Yamaguchi',
                rkey: '35',
            },
            {
                rname: 'Yamanashi',
                rkey: '19',
            },
        ],
    },
    {
        countryName: 'Jersey',
        countryShortCode: 'JE',
        Regions: [],
    },
    {
        countryName: 'Jordan',
        countryShortCode: 'JO',
        Regions: [],
    },
    {
        countryName: 'Kazakhstan',
        countryShortCode: 'KZ',
        Regions: [
            {
                rname: 'Akmolinskaia',
                rkey: '04',
            },
            {
                rname: 'Aktubinskaia',
                rkey: '05',
            },
            {
                rname: 'Almatynskaia',
                rkey: '00',
            },
            {
                rname: 'Atyrauskaia',
                rkey: '06',
            },
            {
                rname: 'Gambilskaia',
                rkey: '11',
            },
            {
                rname: 'Karagandinskaia',
                rkey: '09',
            },
            {
                rname: 'Kostanaiskaia',
                rkey: '01',
            },
            {
                rname: 'Kyzilordinskaia',
                rkey: '12',
            },
            {
                rname: 'Mangystayskaia',
                rkey: '08',
            },
            {
                rname: 'Pavlodarskaia',
                rkey: '03',
            },
            {
                rname: 'Severo-Kazakhstansk',
                rkey: '02',
            },
            {
                rname: 'Vostochno-Kazakhstan',
                rkey: '10',
            },
            {
                rname: 'Zapadno-Kazakhst',
                rkey: '07',
            },
        ],
    },
    {
        countryName: 'Kenya',
        countryShortCode: 'KE',
        Regions: [],
    },
    {
        countryName: 'Kiribati',
        countryShortCode: 'KI',
        Regions: [],
    },
    {
        countryName: 'Kuwait',
        countryShortCode: 'KW',
        Regions: [
            {
                rname: 'Kuwait City',
                rkey: 'KW',
            },
        ],
    },
    {
        countryName: 'Kyrgyzstan',
        countryShortCode: 'KG',
        Regions: [],
    },
    {
        countryName: 'Laos',
        countryShortCode: 'LA',
        Regions: [],
    },
    {
        countryName: 'Latvia',
        countryShortCode: 'LV',
        Regions: [],
    },
    {
        countryName: 'Lebanon',
        countryShortCode: 'LB',
        Regions: [],
    },
    {
        countryName: 'Lesotho',
        countryShortCode: 'LS',
        Regions: [],
    },
    {
        countryName: 'Liberia',
        countryShortCode: 'LR',
        Regions: [],
    },
    {
        countryName: 'Libya',
        countryShortCode: 'LY',
        Regions: [],
    },
    {
        countryName: 'Liechtenstein',
        countryShortCode: 'LI',
        Regions: [],
    },
    {
        countryName: 'Lithuania',
        countryShortCode: 'LT',
        Regions: [],
    },
    {
        countryName: 'Luxembourg',
        countryShortCode: 'LU',
        Regions: [],
    },
    {
        countryName: 'Macau',
        countryShortCode: 'MO',
        Regions: [],
    },
    {
        countryName: 'Macedonia',
        countryShortCode: 'MK',
        Regions: [],
    },
    {
        countryName: 'Madagascar',
        countryShortCode: 'MG',
        Regions: [],
    },
    {
        countryName: 'Malawi',
        countryShortCode: 'MW',
        Regions: [],
    },
    {
        countryName: 'Malaysia',
        countryShortCode: 'MY',
        Regions: [
            {
                rname: 'Johor',
                rkey: 'JOH',
            },
            {
                rname: 'Kedah',
                rkey: 'KED',
            },
            {
                rname: 'Kelantan',
                rkey: 'KEL',
            },
            {
                rname: 'Kuala Lumpur',
                rkey: 'KUL',
            },
            {
                rname: 'Labuan',
                rkey: 'LAB',
            },
            {
                rname: 'Melaka',
                rkey: 'MEL',
            },
            {
                rname: 'Negeri Sembilan',
                rkey: 'SER',
            },
            {
                rname: 'Pahang',
                rkey: 'PAH',
            },
            {
                rname: 'Perak',
                rkey: 'PER',
            },
            {
                rname: 'Perlis',
                rkey: 'PEL',
            },
            {
                rname: 'Pulau Pinang',
                rkey: 'PIN',
            },
            {
                rname: 'Sabah',
                rkey: 'SAB',
            },
            {
                rname: 'Sarawak',
                rkey: 'SAR',
            },
            {
                rname: 'Selangor',
                rkey: 'SEL',
            },
            {
                rname: 'Trengganu',
                rkey: 'TRE',
            },
            {
                rname: 'Wil. Persekutuan',
                rkey: 'PSK',
            },
        ],
    },
    {
        countryName: 'Maldives',
        countryShortCode: 'MV',
        Regions: [],
    },
    {
        countryName: 'Mali',
        countryShortCode: 'ML',
        Regions: [],
    },
    {
        countryName: 'Malta',
        countryShortCode: 'MT',
        Regions: [],
    },
    {
        countryName: 'Marshall Islands',
        countryShortCode: 'MH',
        Regions: [],
    },
    {
        countryName: 'Martinique',
        countryShortCode: 'MQ',
        Regions: [],
    },
    {
        countryName: 'Mauritania',
        countryShortCode: 'MR',
        Regions: [],
    },
    {
        countryName: 'Mauritius',
        countryShortCode: 'MU',
        Regions: [],
    },
    {
        countryName: 'Mayotte',
        countryShortCode: 'YT',
        Regions: [],
    },
    {
        countryName: 'Mexico',
        countryShortCode: 'MX',
        Regions: [
            {
                rname: 'Aguascalientes',
                rkey: 'AGS',
            },
            {
                rname: 'Baja California',
                rkey: 'BC',
            },
            {
                rname: 'Baja California S',
                rkey: 'BCS',
            },
            {
                rname: 'Campeche',
                rkey: 'CMP',
            },
            {
                rname: 'Chiapas',
                rkey: 'CHS',
            },
            {
                rname: 'Chihuahua',
                rkey: 'CHI',
            },
            {
                rname: 'Coahuila',
                rkey: 'COA',
            },
            {
                rname: 'Colima',
                rkey: 'COL',
            },
            {
                rname: 'Distrito Federal',
                rkey: 'DF',
            },
            {
                rname: 'Durango',
                rkey: 'DGO',
            },
            {
                rname: 'Estado de Mexico',
                rkey: 'MEX',
            },
            {
                rname: 'Guanajuato',
                rkey: 'GTO',
            },
            {
                rname: 'Guerrero',
                rkey: 'GRO',
            },
            {
                rname: 'Hidalgo',
                rkey: 'HGO',
            },
            {
                rname: 'Jalisco',
                rkey: 'JAL',
            },
            {
                rname: 'Michoacan',
                rkey: 'MCH',
            },
            {
                rname: 'Morelos',
                rkey: 'MOR',
            },
            {
                rname: 'Nayarit',
                rkey: 'NAY',
            },
            {
                rname: 'Nuevo Leon',
                rkey: 'NL',
            },
            {
                rname: 'Oaxaca',
                rkey: 'OAX',
            },
            {
                rname: 'Puebla',
                rkey: 'PUE',
            },
            {
                rname: 'Queretaro',
                rkey: 'QRO',
            },
            {
                rname: 'Quintana Roo',
                rkey: 'QR',
            },
            {
                rname: 'San Luis Potosi',
                rkey: 'SLP',
            },
            {
                rname: 'Sinaloa',
                rkey: 'SIN',
            },
            {
                rname: 'Sonora',
                rkey: 'SON',
            },
            {
                rname: 'Tabasco',
                rkey: 'TAB',
            },
            {
                rname: 'Tamaulipas',
                rkey: 'TMS',
            },
            {
                rname: 'Tlaxcala',
                rkey: 'TLX',
            },
            {
                rname: 'Veracruz',
                rkey: 'VER',
            },
            {
                rname: 'Yucatan',
                rkey: 'YUC',
            },
            {
                rname: 'Zacatecas',
                rkey: 'ZAC',
            },
        ],
    },
    {
        countryName: 'Micronesia',
        countryShortCode: 'FM',
        Regions: [],
    },
    {
        countryName: 'Moldova',
        countryShortCode: 'MD',
        Regions: [],
    },
    {
        countryName: 'Monaco',
        countryShortCode: 'MC',
        Regions: [],
    },
    {
        countryName: 'Mongolia',
        countryShortCode: 'MN',
        Regions: [],
    },
    {
        countryName: 'Montserrat',
        countryShortCode: 'MS',
        Regions: [],
    },
    {
        countryName: 'Morocco',
        countryShortCode: 'MA',
        Regions: [],
    },
    {
        countryName: 'Mozambique',
        countryShortCode: 'MZ',
        Regions: [],
    },
    {
        countryName: 'Namibia',
        countryShortCode: 'NA',
        Regions: [],
    },
    {
        countryName: 'NATO',
        countryShortCode: 'NT',
        Regions: [],
    },
    {
        countryName: 'Nauru',
        countryShortCode: 'NR',
        Regions: [],
    },
    {
        countryName: 'Nepal',
        countryShortCode: 'NP',
        Regions: [],
    },
    {
        countryName: 'Netherlands',
        countryShortCode: 'NL',
        Regions: [
            {
                rname: 'Drenthe',
                rkey: '01',
            },
            {
                rname: 'Flevoland',
                rkey: '02',
            },
            {
                rname: 'Friesland',
                rkey: '03',
            },
            {
                rname: 'Gelderland',
                rkey: '04',
            },
            {
                rname: 'Groningen',
                rkey: '05',
            },
            {
                rname: 'Limburg',
                rkey: '06',
            },
            {
                rname: 'Noord-Brabant',
                rkey: '07',
            },
            {
                rname: 'Noord-Holland',
                rkey: '08',
            },
            {
                rname: 'Overijssel',
                rkey: '09',
            },
            {
                rname: 'Utrecht',
                rkey: '10',
            },
            {
                rname: 'Zeeland',
                rkey: '11',
            },
            {
                rname: 'Zuid-Holland',
                rkey: '12',
            },
        ],
    },
    {
        countryName: 'New Caledonia',
        countryShortCode: 'NC',
        Regions: [],
    },
    {
        countryName: 'New Zealand',
        countryShortCode: 'NZ',
        Regions: [
            {
                rname: 'Auckland',
                rkey: 'AKL',
            },
            {
                rname: 'Bay of Plenty',
                rkey: 'BOP',
            },
            {
                rname: 'Canterbury',
                rkey: 'CAN',
            },
            {
                rname: 'Hawke\u00b4s Bay',
                rkey: 'HAB',
            },
            {
                rname: 'Manawatu-Wanganui',
                rkey: 'MAN',
            },
            {
                rname: 'Northland',
                rkey: 'NTL',
            },
            {
                rname: 'Otago',
                rkey: 'OTA',
            },
            {
                rname: 'Southland',
                rkey: 'STL',
            },
            {
                rname: 'Taranaki',
                rkey: 'TAR',
            },
            {
                rname: 'Waikato',
                rkey: 'WAI',
            },
            {
                rname: 'Wellington',
                rkey: 'WLG',
            },
            {
                rname: 'West Coast',
                rkey: 'WEC',
            },
        ],
    },
    {
        countryName: 'Nicaragua',
        countryShortCode: 'NI',
        Regions: [],
    },
    {
        countryName: 'Niger',
        countryShortCode: 'NE',
        Regions: [],
    },
    {
        countryName: 'Nigeria',
        countryShortCode: 'NG',
        Regions: [],
    },
    {
        countryName: 'Niue',
        countryShortCode: 'NU',
        Regions: [],
    },
    {
        countryName: 'Norfolk Islands',
        countryShortCode: 'NF',
        Regions: [],
    },
    {
        countryName: 'North Korea',
        countryShortCode: 'KP',
        Regions: [],
    },
    {
        countryName: 'North Mariana Islands',
        countryShortCode: 'MP',
        Regions: [],
    },
    {
        countryName: 'Norway',
        countryShortCode: 'NO',
        Regions: [
            {
                rname: 'Akershus County',
                rkey: '02',
            },
            {
                rname: 'Aust-Agder County',
                rkey: '09',
            },
            {
                rname: 'Buskerud County',
                rkey: '06',
            },
            {
                rname: 'Finnmark County',
                rkey: '20',
            },
            {
                rname: 'Hedmark County',
                rkey: '04',
            },
            {
                rname: 'Hordaland County',
                rkey: '12',
            },
            {
                rname: 'More og Romsdal C.',
                rkey: '15',
            },
            {
                rname: 'Nordland County',
                rkey: '18',
            },
            {
                rname: 'Nord-Trondelag Cnty',
                rkey: '17',
            },
            {
                rname: 'Oppland County',
                rkey: '05',
            },
            {
                rname: 'Oslo',
                rkey: '03',
            },
            {
                rname: 'Ostfold County',
                rkey: '01',
            },
            {
                rname: 'Rogaland County',
                rkey: '11',
            },
            {
                rname: 'Sogn og Fjordane C.',
                rkey: '14',
            },
            {
                rname: 'Sor-Trondelag County',
                rkey: '16',
            },
            {
                rname: 'Telemark County',
                rkey: '08',
            },
            {
                rname: 'Troms County',
                rkey: '19',
            },
            {
                rname: 'Vest-Agder County',
                rkey: '10',
            },
            {
                rname: 'Vestfold County',
                rkey: '07',
            },
        ],
    },
    {
        countryName: 'Oman',
        countryShortCode: 'OM',
        Regions: [],
    },
    {
        countryName: 'Orange',
        countryShortCode: 'OR',
        Regions: [],
    },
    {
        countryName: 'Pakistan',
        countryShortCode: 'PK',
        Regions: [],
    },
    {
        countryName: 'Palau',
        countryShortCode: 'PW',
        Regions: [],
    },
    {
        countryName: 'Palestine',
        countryShortCode: 'PS',
        Regions: [],
    },
    {
        countryName: 'Panama',
        countryShortCode: 'PA',
        Regions: [],
    },
    {
        countryName: 'Papua New Guinea',
        countryShortCode: 'PG',
        Regions: [],
    },
    {
        countryName: 'Paraguay',
        countryShortCode: 'PY',
        Regions: [],
    },
    {
        countryName: 'Peru',
        countryShortCode: 'PE',
        Regions: [
            {
                rname: 'Amazon',
                rkey: '11',
            },
            {
                rname: 'Ancash',
                rkey: '05',
            },
            {
                rname: 'Apurimac',
                rkey: '19',
            },
            {
                rname: 'Arequipa',
                rkey: '08',
            },
            {
                rname: 'Ayacucho',
                rkey: '18',
            },
            {
                rname: 'Cajamarca',
                rkey: '12',
            },
            {
                rname: 'Cuzco',
                rkey: '20',
            },
            {
                rname: 'Huancavelica',
                rkey: '17',
            },
            {
                rname: 'Huanuco',
                rkey: '14',
            },
            {
                rname: 'Ica',
                rkey: '07',
            },
            {
                rname: 'Junin',
                rkey: '16',
            },
            {
                rname: 'La Libertad',
                rkey: '04',
            },
            {
                rname: 'Lambayeque',
                rkey: '03',
            },
            {
                rname: 'Lima y Callao',
                rkey: '06',
            },
            {
                rname: 'Loreto',
                rkey: '22',
            },
            {
                rname: 'Madre de Dios',
                rkey: '24',
            },
            {
                rname: 'Moquegua',
                rkey: '09',
            },
            {
                rname: 'Pasco',
                rkey: '15',
            },
            {
                rname: 'Piura',
                rkey: '02',
            },
            {
                rname: 'Puno',
                rkey: '21',
            },
            {
                rname: 'San Martin',
                rkey: '13',
            },
            {
                rname: 'Tacna',
                rkey: '10',
            },
            {
                rname: 'Tumbes',
                rkey: '01',
            },
            {
                rname: 'Ucayali',
                rkey: '23',
            },
        ],
    },
    {
        countryName: 'Philippines',
        countryShortCode: 'PH',
        Regions: [
            {
                rname: 'Bicol',
                rkey: '05',
            },
            {
                rname: 'Cagayan Valley',
                rkey: '02',
            },
            {
                rname: 'Central Luzon',
                rkey: '03',
            },
            {
                rname: 'Central Mindanao',
                rkey: '11',
            },
            {
                rname: 'Central Visayas',
                rkey: '07',
            },
            {
                rname: 'Eastern Visayas',
                rkey: '08',
            },
            {
                rname: 'Ilocos',
                rkey: '01',
            },
            {
                rname: 'Northern Mindanao',
                rkey: '10',
            },
            {
                rname: 'South Luzon',
                rkey: '04',
            },
            {
                rname: 'South Mindanao',
                rkey: '12',
            },
            {
                rname: 'West Visayas',
                rkey: '06',
            },
            {
                rname: 'Western Mindanao',
                rkey: '09',
            },
        ],
    },
    {
        countryName: 'Pitcairn Islands',
        countryShortCode: 'PN',
        Regions: [],
    },
    {
        countryName: 'Poland',
        countryShortCode: 'PL',
        Regions: [
            {
                rname: 'Dolnoslaskie',
                rkey: 'DSL',
            },
            {
                rname: 'Kujawsko-Pomorskie',
                rkey: 'K-P',
            },
            {
                rname: 'Lodzkie',
                rkey: 'LDZ',
            },
            {
                rname: 'Lubelskie',
                rkey: 'LBL',
            },
            {
                rname: 'Lubuskie',
                rkey: 'LBS',
            },
            {
                rname: 'Malopolskie',
                rkey: 'MAL',
            },
            {
                rname: 'Mazowieckie',
                rkey: 'MAZ',
            },
            {
                rname: 'Opolskie',
                rkey: 'OPO',
            },
            {
                rname: 'Podkarpackie',
                rkey: 'PDK',
            },
            {
                rname: 'Podlaskie',
                rkey: 'PDL',
            },
            {
                rname: 'Pomorskie',
                rkey: 'POM',
            },
            {
                rname: 'Slaskie',
                rkey: 'SLS',
            },
            {
                rname: 'Swietokrzyskie',
                rkey: 'SWK',
            },
            {
                rname: 'Warminsko-mazurskie',
                rkey: 'W-M',
            },
            {
                rname: 'Warszawa',
                rkey: '45',
            },
            {
                rname: 'Wielkopolskie',
                rkey: 'WLK',
            },
            {
                rname: 'Zachodnio-Pomorskie',
                rkey: 'Z-P',
            },
        ],
    },
    {
        countryName: 'Portugal',
        countryShortCode: 'PT',
        Regions: [
            {
                rname: 'Alentejo Central',
                rkey: '42',
            },
            {
                rname: 'Alentejo Litoral',
                rkey: '40',
            },
            {
                rname: 'Algarve',
                rkey: '50',
            },
            {
                rname: 'Alto Alentejo',
                rkey: '41',
            },
            {
                rname: 'Alto Tras-os-Montes',
                rkey: '17',
            },
            {
                rname: 'Ave',
                rkey: '12',
            },
            {
                rname: 'Baixo Alentejo',
                rkey: '43',
            },
            {
                rname: 'Baixo Mondego',
                rkey: '21',
            },
            {
                rname: 'Baixo Vouga',
                rkey: '20',
            },
            {
                rname: 'Beira Interior Norte',
                rkey: '27',
            },
            {
                rname: 'Beira Interior Sul',
                rkey: '28',
            },
            {
                rname: 'Cavado',
                rkey: '11',
            },
            {
                rname: 'Cova da Beira',
                rkey: '29',
            },
            {
                rname: 'Dao-Lafoes',
                rkey: '25',
            },
            {
                rname: 'Douro',
                rkey: '16',
            },
            {
                rname: 'Entre Douro e Vouga',
                rkey: '15',
            },
            {
                rname: 'Grande Lisboa',
                rkey: '31',
            },
            {
                rname: 'Grande Porto',
                rkey: '13',
            },
            {
                rname: 'Leziria do Tejo',
                rkey: '34',
            },
            {
                rname: 'Medio Tejo',
                rkey: '33',
            },
            {
                rname: 'Minho-Lima',
                rkey: '10',
            },
            {
                rname: 'Oeste',
                rkey: '30',
            },
            {
                rname: 'Peninsula de Setubal',
                rkey: '32',
            },
            {
                rname: 'Pinhal Interior N.',
                rkey: '23',
            },
            {
                rname: 'Pinhal Interior Sul',
                rkey: '24',
            },
            {
                rname: 'Pinhal Litoral',
                rkey: '22',
            },
            {
                rname: 'Reg. Aut. da Madeira',
                rkey: '70',
            },
            {
                rname: 'Reg. Aut. dos A\u00e7ores',
                rkey: '60',
            },
            {
                rname: 'Serra da Estrela',
                rkey: '26',
            },
            {
                rname: 'Tamega',
                rkey: '14',
            },
        ],
    },
    {
        countryName: 'Puerto Rico',
        countryShortCode: 'PR',
        Regions: [],
    },
    {
        countryName: 'Qatar',
        countryShortCode: 'QA',
        Regions: [
            {
                rname: 'Doha',
                rkey: '001',
            },
            {
                rname: 'The north',
                rkey: '002',
            },
            {
                rname: 'The south',
                rkey: '004',
            },
            {
                rname: 'The west coast',
                rkey: '003',
            },
        ],
    },
    {
        countryName: 'Republic of Kosovo',
        countryShortCode: 'XK',
        Regions: [],
    },
    {
        countryName: 'Republic of the Congo',
        countryShortCode: 'CG',
        Regions: [],
    },
    {
        countryName: 'Reunion',
        countryShortCode: 'RE',
        Regions: [],
    },
    {
        countryName: 'Romania',
        countryShortCode: 'RO',
        Regions: [
            {
                rname: 'Alba',
                rkey: '01',
            },
            {
                rname: 'Arad',
                rkey: '02',
            },
            {
                rname: 'Arges',
                rkey: '03',
            },
            {
                rname: 'Bacau',
                rkey: '04',
            },
            {
                rname: 'Bihor',
                rkey: '05',
            },
            {
                rname: 'Bistrita-Nasaud',
                rkey: '06',
            },
            {
                rname: 'Botosani',
                rkey: '07',
            },
            {
                rname: 'Braila',
                rkey: '08',
            },
            {
                rname: 'Brasov',
                rkey: '09',
            },
            {
                rname: 'Bucuresti',
                rkey: '10',
            },
            {
                rname: 'Buzau',
                rkey: '11',
            },
            {
                rname: 'Calarasi',
                rkey: '12',
            },
            {
                rname: 'Caras-Severin',
                rkey: '13',
            },
            {
                rname: 'Cluj',
                rkey: '14',
            },
            {
                rname: 'Constanta',
                rkey: '15',
            },
            {
                rname: 'Covasna',
                rkey: '16',
            },
            {
                rname: 'Dimbovita',
                rkey: '17',
            },
            {
                rname: 'Dolj',
                rkey: '18',
            },
            {
                rname: 'Galati',
                rkey: '19',
            },
            {
                rname: 'Giurgiu',
                rkey: '21',
            },
            {
                rname: 'Gorj',
                rkey: '20',
            },
            {
                rname: 'Harghita',
                rkey: '22',
            },
            {
                rname: 'Hunedoara',
                rkey: '23',
            },
            {
                rname: 'Ialomita',
                rkey: '24',
            },
            {
                rname: 'Iasi',
                rkey: '25',
            },
            {
                rname: 'Ilfov',
                rkey: '26',
            },
            {
                rname: 'Maramures',
                rkey: '27',
            },
            {
                rname: 'Mehedinti',
                rkey: '28',
            },
            {
                rname: 'Mures',
                rkey: '29',
            },
            {
                rname: 'Neamt',
                rkey: '30',
            },
            {
                rname: 'Olt',
                rkey: '31',
            },
            {
                rname: 'Prahova',
                rkey: '32',
            },
            {
                rname: 'Salaj',
                rkey: '33',
            },
            {
                rname: 'Satu Mare',
                rkey: '34',
            },
            {
                rname: 'Sibiu',
                rkey: '35',
            },
            {
                rname: 'Suceava',
                rkey: '36',
            },
            {
                rname: 'Teleorman',
                rkey: '37',
            },
            {
                rname: 'Timis',
                rkey: '38',
            },
            {
                rname: 'Tulcea',
                rkey: '39',
            },
            {
                rname: 'Vaslui',
                rkey: '40',
            },
            {
                rname: 'Vilcea',
                rkey: '41',
            },
            {
                rname: 'Vrancea',
                rkey: '42',
            },
        ],
    },
    {
        countryName: 'Russian Federation',
        countryShortCode: 'RU',
        Regions: [
            {
                rname: 'Adigeja Republic',
                rkey: '01',
            },
            {
                rname: 'Aginsk Buryat Aut.di',
                rkey: '80',
            },
            {
                rname: 'Altay Territory',
                rkey: '22',
            },
            {
                rname: 'Buryat Republic',
                rkey: '04',
            },
            {
                rname: 'c.Moscow',
                rkey: '77',
            },
            {
                rname: 'c.St-Peterburg',
                rkey: '78',
            },
            {
                rname: 'Chakassky Republic',
                rkey: '19',
            },
            {
                rname: 'Chanti-Mansyjsky Aut',
                rkey: '86',
            },
            {
                rname: 'Chechenskaya Republ.',
                rkey: '20',
            },
            {
                rname: 'Chukotka Auton. dist',
                rkey: '87',
            },
            {
                rname: 'Chuvash Republic',
                rkey: '21',
            },
            {
                rname: 'Dagestan Republic',
                rkey: '05',
            },
            {
                rname: 'Evensky Auton.distri',
                rkey: '88',
            },
            {
                rname: 'Highlands-Altay Rep.',
                rkey: '02',
            },
            {
                rname: 'Ingushetija Republic',
                rkey: '06',
            },
            {
                rname: 'Jamalo-Nenekchky Aut',
                rkey: '89',
            },
            {
                rname: 'Kabardino-Balkar.Rep',
                rkey: '07',
            },
            {
                rname: 'Kalmyk Republic',
                rkey: '08',
            },
            {
                rname: 'Kamchatka Territory',
                rkey: '41',
            },
            {
                rname: 'Karach.-Cherkessk Re',
                rkey: '09',
            },
            {
                rname: 'Karelian Republic',
                rkey: '10',
            },
            {
                rname: 'Khabarovsk Territory',
                rkey: '27',
            },
            {
                rname: 'Komi Republic',
                rkey: '11',
            },
            {
                rname: 'Komy Permjats.Aut.di',
                rkey: '81',
            },
            {
                rname: 'Korjacs Auton.distri',
                rkey: '82',
            },
            {
                rname: 'Krasnodar Territory',
                rkey: '23',
            },
            {
                rname: 'Krasnoyarsk Territor',
                rkey: '24',
            },
            {
                rname: 'Marijskaya Republic',
                rkey: '12',
            },
            {
                rname: 'Mordovian Republic',
                rkey: '13',
            },
            {
                rname: 'Nenekchky Auton.dist',
                rkey: '83',
            },
            {
                rname: 'North-Osetiya Republ',
                rkey: '15',
            },
            {
                rname: 'Perm Territory',
                rkey: '59',
            },
            {
                rname: 'Primorye Territory',
                rkey: '25',
            },
            {
                rname: 'Republ.of Bashkortos',
                rkey: '03',
            },
            {
                rname: 'Stavropol Territory',
                rkey: '26',
            },
            {
                rname: 'Tatarstan Republic',
                rkey: '16',
            },
            {
                rname: 'The Amur Area',
                rkey: '28',
            },
            {
                rname: 'The Arkhangelsk Area',
                rkey: '29',
            },
            {
                rname: 'The Astrakhan Area',
                rkey: '30',
            },
            {
                rname: 'The Belgorod Area',
                rkey: '31',
            },
            {
                rname: 'The Bryansk Area',
                rkey: '32',
            },
            {
                rname: 'The Chelyabinsk Area',
                rkey: '74',
            },
            {
                rname: 'The Irkutsk Area',
                rkey: '38',
            },
            {
                rname: 'The Ivanovo Area',
                rkey: '37',
            },
            {
                rname: 'The Jewish Auton.are',
                rkey: '79',
            },
            {
                rname: 'The Kaliningrad Area',
                rkey: '39',
            },
            {
                rname: 'The Kaluga Area',
                rkey: '40',
            },
            {
                rname: 'The Kemerovo Area',
                rkey: '42',
            },
            {
                rname: 'The Kirov Area',
                rkey: '43',
            },
            {
                rname: 'The Kostroma Area',
                rkey: '44',
            },
            {
                rname: 'The Kurgan Area',
                rkey: '45',
            },
            {
                rname: 'The Kursk Area',
                rkey: '46',
            },
            {
                rname: 'The Leningrad Area',
                rkey: '47',
            },
            {
                rname: 'The Lipetsk Area',
                rkey: '48',
            },
            {
                rname: 'The Magadan Area',
                rkey: '49',
            },
            {
                rname: 'The Moscow Area',
                rkey: '50',
            },
            {
                rname: 'The Murmansk Area',
                rkey: '51',
            },
            {
                rname: 'The Nizhniy Novgorod',
                rkey: '52',
            },
            {
                rname: 'The Novgorod Area',
                rkey: '53',
            },
            {
                rname: 'The Novosibirsk Area',
                rkey: '54',
            },
            {
                rname: 'The Omsk Area',
                rkey: '55',
            },
            {
                rname: 'The Orenburg Area',
                rkey: '56',
            },
            {
                rname: 'The Oryol Area',
                rkey: '57',
            },
            {
                rname: 'The Penza Area',
                rkey: '58',
            },
            {
                rname: 'The Pskov Area',
                rkey: '60',
            },
            {
                rname: 'The Rostov Area',
                rkey: '61',
            },
            {
                rname: 'The Ryazan Area',
                rkey: '62',
            },
            {
                rname: 'The Sakhalin Area',
                rkey: '65',
            },
            {
                rname: 'The Samara Area',
                rkey: '63',
            },
            {
                rname: 'The Saratov Area',
                rkey: '64',
            },
            {
                rname: 'The Smolensk Area',
                rkey: '67',
            },
            {
                rname: 'The Sverdlovsk Area',
                rkey: '66',
            },
            {
                rname: 'The Tambov Area',
                rkey: '68',
            },
            {
                rname: 'The Taymir Auton.dis',
                rkey: '84',
            },
            {
                rname: 'The Tomsk Area',
                rkey: '70',
            },
            {
                rname: 'The Tula Area',
                rkey: '71',
            },
            {
                rname: 'The Tver Area',
                rkey: '69',
            },
            {
                rname: 'The Tyumen Area',
                rkey: '72',
            },
            {
                rname: 'The Udmurt Republic',
                rkey: '18',
            },
            {
                rname: 'The Ulyanovsk Area',
                rkey: '73',
            },
            {
                rname: 'The Vladimir Area',
                rkey: '33',
            },
            {
                rname: 'The Volgograd Area',
                rkey: '34',
            },
            {
                rname: 'The Vologda Area',
                rkey: '35',
            },
            {
                rname: 'The Voronezh Area',
                rkey: '36',
            },
            {
                rname: 'The Yaroslavl Area',
                rkey: '76',
            },
            {
                rname: 'Tuva Republic',
                rkey: '17',
            },
            {
                rname: 'Ust-Ordinsky Buryat',
                rkey: '85',
            },
            {
                rname: 'Yakutiya-Saha Rrepub',
                rkey: '14',
            },
            {
                rname: 'Zabaykalsk Territory',
                rkey: '75',
            },
        ],
    },
    {
        countryName: 'Rwanda',
        countryShortCode: 'RW',
        Regions: [],
    },
    {
        countryName: 'Saint Helena',
        countryShortCode: 'SH',
        Regions: [],
    },
    {
        countryName: 'Saint Kitts and Nevis',
        countryShortCode: 'KN',
        Regions: [],
    },
    {
        countryName: 'Samoa',
        countryShortCode: 'WS',
        Regions: [],
    },
    {
        countryName: 'San Marino',
        countryShortCode: 'SM',
        Regions: [],
    },
    {
        countryName: 'Sao Tome and Principe',
        countryShortCode: 'ST',
        Regions: [],
    },
    {
        countryName: 'Saudi Arabia',
        countryShortCode: 'SA',
        Regions: [],
    },
    {
        countryName: 'Senegal',
        countryShortCode: 'SN',
        Regions: [],
    },
    {
        countryName: 'Serbia and Montenegro',
        countryShortCode: 'CS',
        Regions: [],
    },
    {
        countryName: 'Seychelles',
        countryShortCode: 'SC',
        Regions: [],
    },
    {
        countryName: 'Sierra Leone',
        countryShortCode: 'SL',
        Regions: [],
    },
    {
        countryName: 'Singapore',
        countryShortCode: 'SG',
        Regions: [
            {
                rname: 'Singapore',
                rkey: 'SG',
            },
        ],
    },
    {
        countryName: 'Sint Maarten (Dutch part)',
        countryShortCode: 'SX',
        Regions: [],
    },
    {
        countryName: 'Slovakia',
        countryShortCode: 'SK',
        Regions: [],
    },
    {
        countryName: 'Slovenia',
        countryShortCode: 'SI',
        Regions: [
            {
                rname: '\u00a9alovci',
                rkey: '033',
            },
            {
                rname: '\u00a9empeter - Vrtojba',
                rkey: '183',
            },
            {
                rname: '\u00a9en\u00e8ur',
                rkey: '117',
            },
            {
                rname: '\u00a9entilj',
                rkey: '118',
            },
            {
                rname: '\u00a9entjernej',
                rkey: '119',
            },
            {
                rname: '\u00a9entjur pri Celju',
                rkey: '120',
            },
            {
                rname: '\u00a9kocjan',
                rkey: '121',
            },
            {
                rname: '\u00a9kofja Loka',
                rkey: '122',
            },
            {
                rname: '\u00a9kofljica',
                rkey: '123',
            },
            {
                rname: '\u00a9marje pri Jel\u00b9ah',
                rkey: '124',
            },
            {
                rname: '\u00a9martno ob Paki',
                rkey: '125',
            },
            {
                rname: '\u00a9o\u00b9tanj',
                rkey: '126',
            },
            {
                rname: '\u00a9tore',
                rkey: '127',
            },
            {
                rname: '\u00aealec',
                rkey: '190',
            },
            {
                rname: '\u00aeelezniki',
                rkey: '146',
            },
            {
                rname: '\u00aeetale',
                rkey: '191',
            },
            {
                rname: '\u00aeiri',
                rkey: '147',
            },
            {
                rname: '\u00aeirovnica',
                rkey: '192',
            },
            {
                rname: '\u00aeu\u00beemberk',
                rkey: '193',
            },
            {
                rname: 'Ajdov\u00b9\u00e8ina',
                rkey: '001',
            },
            {
                rname: 'Ajdovscina',
                rkey: '01',
            },
            {
                rname: 'Beltinci',
                rkey: '002',
            },
            {
                rname: 'Benedikt',
                rkey: '148',
            },
            {
                rname: 'Bistrica ob Sotli',
                rkey: '149',
            },
            {
                rname: 'Bled',
                rkey: '003',
            },
            {
                rname: 'Bloke',
                rkey: '150',
            },
            {
                rname: 'Bohinj',
                rkey: '004',
            },
            {
                rname: 'Borovnica',
                rkey: '005',
            },
            {
                rname: 'Bovec',
                rkey: '006',
            },
            {
                rname: 'Braslov\u00e8e',
                rkey: '151',
            },
            {
                rname: 'Brda',
                rkey: '007',
            },
            {
                rname: 'Bre\u00beice',
                rkey: '009',
            },
            {
                rname: 'Brezice',
                rkey: '02',
            },
            {
                rname: 'Brezovica',
                rkey: '008',
            },
            {
                rname: 'Cankova',
                rkey: '152',
            },
            {
                rname: 'Celje',
                rkey: '011',
            },
            {
                rname: 'Celje',
                rkey: '03',
            },
            {
                rname: 'Cerklje na Gorenjske',
                rkey: '012',
            },
            {
                rname: 'Cerknica',
                rkey: '013',
            },
            {
                rname: 'Cerknica',
                rkey: '04',
            },
            {
                rname: 'Cerkno',
                rkey: '014',
            },
            {
                rname: 'Cerkvenjak',
                rkey: '153',
            },
            {
                rname: 'Crnomelj',
                rkey: '05',
            },
            {
                rname: 'Destrnik',
                rkey: '018',
            },
            {
                rname: 'Diva\u00e8a',
                rkey: '019',
            },
            {
                rname: 'Dobje',
                rkey: '154',
            },
            {
                rname: 'Dobrepolje',
                rkey: '020',
            },
            {
                rname: 'Dobrna',
                rkey: '155',
            },
            {
                rname: 'Dobrova-Polhov Grade',
                rkey: '021',
            },
            {
                rname: 'Dobrovnik - Dobronak',
                rkey: '156',
            },
            {
                rname: 'Dol pri Ljubljani',
                rkey: '022',
            },
            {
                rname: 'Dolenjske Toplice',
                rkey: '157',
            },
            {
                rname: 'Dom\u00beale',
                rkey: '023',
            },
            {
                rname: 'Dornava',
                rkey: '024',
            },
            {
                rname: 'Dravograd',
                rkey: '025',
            },
            {
                rname: 'Dravograd',
                rkey: '06',
            },
            {
                rname: 'Duplek',
                rkey: '026',
            },
            {
                rname: '\u00c8ren\u00b9ovci',
                rkey: '015',
            },
            {
                rname: '\u00c8rna na Koro\u00b9kem',
                rkey: '016',
            },
            {
                rname: '\u00c8rnomelj',
                rkey: '017',
            },
            {
                rname: 'Gorenja vas-Poljane',
                rkey: '027',
            },
            {
                rname: 'Gori\u00b9nica',
                rkey: '028',
            },
            {
                rname: 'Gornja Radgona',
                rkey: '029',
            },
            {
                rname: 'Gornja Radgona',
                rkey: '07',
            },
            {
                rname: 'Gornji Grad',
                rkey: '030',
            },
            {
                rname: 'Gornji Petrovci',
                rkey: '031',
            },
            {
                rname: 'Grad',
                rkey: '158',
            },
            {
                rname: 'Grosuplje',
                rkey: '032',
            },
            {
                rname: 'Grosuplje',
                rkey: '08',
            },
            {
                rname: 'Hajdina',
                rkey: '159',
            },
            {
                rname: 'Hodo\u00b9 - Hodos',
                rkey: '161',
            },
            {
                rname: 'Ho\u00e8e-Slivnica',
                rkey: '160',
            },
            {
                rname: 'Horjul',
                rkey: '162',
            },
            {
                rname: 'Hrastnik',
                rkey: '034',
            },
            {
                rname: 'Hrastnik Lasko',
                rkey: '09',
            },
            {
                rname: 'Hrpelje-Kozina',
                rkey: '035',
            },
            {
                rname: 'Idrija',
                rkey: '036',
            },
            {
                rname: 'Idrija',
                rkey: '10',
            },
            {
                rname: 'Ig',
                rkey: '037',
            },
            {
                rname: 'Ilirska Bistrica',
                rkey: '038',
            },
            {
                rname: 'Ilirska Bistrica',
                rkey: '11',
            },
            {
                rname: 'Ivan\u00e8na Gorica',
                rkey: '039',
            },
            {
                rname: 'Izola',
                rkey: '12',
            },
            {
                rname: 'Izola - Isola',
                rkey: '040',
            },
            {
                rname: 'Jesenice',
                rkey: '041',
            },
            {
                rname: 'Jesenice',
                rkey: '13',
            },
            {
                rname: 'Jezersko',
                rkey: '163',
            },
            {
                rname: 'Jur\u00b9inci',
                rkey: '042',
            },
            {
                rname: 'Kamnik',
                rkey: '043',
            },
            {
                rname: 'Kamnik',
                rkey: '14',
            },
            {
                rname: 'Kanal',
                rkey: '044',
            },
            {
                rname: 'Kidri\u00e8evo',
                rkey: '045',
            },
            {
                rname: 'Kobarid',
                rkey: '046',
            },
            {
                rname: 'Kobilje',
                rkey: '047',
            },
            {
                rname: 'Kocevje',
                rkey: '15',
            },
            {
                rname: 'Ko\u00e8evje',
                rkey: '048',
            },
            {
                rname: 'Komen',
                rkey: '049',
            },
            {
                rname: 'Komenda',
                rkey: '164',
            },
            {
                rname: 'Koper',
                rkey: '16',
            },
            {
                rname: 'Koper - Capodistria',
                rkey: '050',
            },
            {
                rname: 'Kostel',
                rkey: '165',
            },
            {
                rname: 'Kozje',
                rkey: '051',
            },
            {
                rname: 'Kr\u00b9ko',
                rkey: '054',
            },
            {
                rname: 'Kranj',
                rkey: '052',
            },
            {
                rname: 'Kranj',
                rkey: '17',
            },
            {
                rname: 'Kranjska Gora',
                rkey: '053',
            },
            {
                rname: 'Kri\u00beevci',
                rkey: '166',
            },
            {
                rname: 'Krsko',
                rkey: '18',
            },
            {
                rname: 'Kungota',
                rkey: '055',
            },
            {
                rname: 'Kuzma',
                rkey: '056',
            },
            {
                rname: 'La\u00b9ko',
                rkey: '057',
            },
            {
                rname: 'Lenart',
                rkey: '058',
            },
            {
                rname: 'Lenart',
                rkey: '19',
            },
            {
                rname: 'Lendava',
                rkey: '20',
            },
            {
                rname: 'Lendava - Lendva',
                rkey: '059',
            },
            {
                rname: 'Litija',
                rkey: '060',
            },
            {
                rname: 'Litija',
                rkey: '21',
            },
            {
                rname: 'Ljubljana',
                rkey: '061',
            },
            {
                rname: 'Ljubljana-Bezigrad',
                rkey: '22',
            },
            {
                rname: 'Ljubljana-Center',
                rkey: '23',
            },
            {
                rname: 'Ljubljana-Moste-Polj',
                rkey: '24',
            },
            {
                rname: 'Ljubljana-Siska',
                rkey: '25',
            },
            {
                rname: 'Ljubljana-Vic-Rudnik',
                rkey: '26',
            },
            {
                rname: 'Ljubno',
                rkey: '062',
            },
            {
                rname: 'Ljutomer',
                rkey: '063',
            },
            {
                rname: 'Ljutomer',
                rkey: '27',
            },
            {
                rname: 'Lo\u00b9ka dolina',
                rkey: '065',
            },
            {
                rname: 'Lo\u00b9ki Potok',
                rkey: '066',
            },
            {
                rname: 'Logatec',
                rkey: '064',
            },
            {
                rname: 'Logatec',
                rkey: '28',
            },
            {
                rname: 'Lovrenc na Pohorju',
                rkey: '167',
            },
            {
                rname: 'Lu\u00e8e',
                rkey: '067',
            },
            {
                rname: 'Lukovica',
                rkey: '068',
            },
            {
                rname: 'Maj\u00b9perk',
                rkey: '069',
            },
            {
                rname: 'Maribor',
                rkey: '070',
            },
            {
                rname: 'Maribor',
                rkey: '29',
            },
            {
                rname: 'Markovci',
                rkey: '168',
            },
            {
                rname: 'Me\u00beica',
                rkey: '074',
            },
            {
                rname: 'Medvode',
                rkey: '071',
            },
            {
                rname: 'Menge\u00b9',
                rkey: '072',
            },
            {
                rname: 'Metlika',
                rkey: '073',
            },
            {
                rname: 'Metlika',
                rkey: '30',
            },
            {
                rname: 'Miklav\u00be na Dravskem',
                rkey: '169',
            },
            {
                rname: 'Miren-Kostanjevica',
                rkey: '075',
            },
            {
                rname: 'Mirna Pe\u00e8',
                rkey: '170',
            },
            {
                rname: 'Mislinja',
                rkey: '076',
            },
            {
                rname: 'Morav\u00e8e',
                rkey: '077',
            },
            {
                rname: 'Moravske Toplice',
                rkey: '078',
            },
            {
                rname: 'Mozirje',
                rkey: '079',
            },
            {
                rname: 'Mozirje',
                rkey: '31',
            },
            {
                rname: 'Murska Sobota',
                rkey: '080',
            },
            {
                rname: 'Murska Sobota',
                rkey: '32',
            },
            {
                rname: 'Muta',
                rkey: '081',
            },
            {
                rname: 'Naklo',
                rkey: '082',
            },
            {
                rname: 'Nazarje',
                rkey: '083',
            },
            {
                rname: 'Nova Gorica',
                rkey: '084',
            },
            {
                rname: 'Nova Gorica',
                rkey: '33',
            },
            {
                rname: 'Novo mesto',
                rkey: '085',
            },
            {
                rname: 'Novo Mesto',
                rkey: '34',
            },
            {
                rname: 'Odranci',
                rkey: '086',
            },
            {
                rname: 'Oplotnica',
                rkey: '171',
            },
            {
                rname: 'Ormo\u00be',
                rkey: '087',
            },
            {
                rname: 'Ormoz',
                rkey: '35',
            },
            {
                rname: 'Osilnica',
                rkey: '088',
            },
            {
                rname: 'Pesnica',
                rkey: '089',
            },
            {
                rname: 'Pesnica',
                rkey: '36',
            },
            {
                rname: 'Piran',
                rkey: '37',
            },
            {
                rname: 'Piran - Pirano',
                rkey: '090',
            },
            {
                rname: 'Pivka',
                rkey: '091',
            },
            {
                rname: 'Pod\u00e8etrtek',
                rkey: '092',
            },
            {
                rname: 'Podlehnik',
                rkey: '172',
            },
            {
                rname: 'Podvelka',
                rkey: '093',
            },
            {
                rname: 'Polzela',
                rkey: '173',
            },
            {
                rname: 'Postojna',
                rkey: '094',
            },
            {
                rname: 'Postojna',
                rkey: '38',
            },
            {
                rname: 'Prebold',
                rkey: '174',
            },
            {
                rname: 'Preddvor',
                rkey: '095',
            },
            {
                rname: 'Prevalje',
                rkey: '175',
            },
            {
                rname: 'Ptuj',
                rkey: '096',
            },
            {
                rname: 'Ptuj',
                rkey: '39',
            },
            {
                rname: 'Puconci',
                rkey: '097',
            },
            {
                rname: 'Rade\u00e8e',
                rkey: '099',
            },
            {
                rname: 'Radenci',
                rkey: '100',
            },
            {
                rname: 'Radlje ob Dravi',
                rkey: '101',
            },
            {
                rname: 'Radlje Ob Dravi',
                rkey: '40',
            },
            {
                rname: 'Radovljica',
                rkey: '102',
            },
            {
                rname: 'Radovljica',
                rkey: '41',
            },
            {
                rname: 'Ra\u00e8e-Fram',
                rkey: '098',
            },
            {
                rname: 'Ravne na Koro\u00b9kem',
                rkey: '103',
            },
            {
                rname: 'Ravne Na Koroskem',
                rkey: '42',
            },
            {
                rname: 'Razkri\u00beje',
                rkey: '176',
            },
            {
                rname: 'Ribnica',
                rkey: '104',
            },
            {
                rname: 'Ribnica',
                rkey: '43',
            },
            {
                rname: 'Ribnica na Pohorju',
                rkey: '177',
            },
            {
                rname: 'Roga\u00b9ka Slatina',
                rkey: '106',
            },
            {
                rname: 'Roga\u00b9ovci',
                rkey: '105',
            },
            {
                rname: 'Rogatec',
                rkey: '107',
            },
            {
                rname: 'Ru\u00b9e',
                rkey: '108',
            },
            {
                rname: 'Ruse',
                rkey: '44',
            },
            {
                rname: 'Se\u00beana',
                rkey: '111',
            },
            {
                rname: 'Selnica ob Dravi',
                rkey: '178',
            },
            {
                rname: 'Semi\u00e8',
                rkey: '109',
            },
            {
                rname: 'Sentjur Pri Celju',
                rkey: '45',
            },
            {
                rname: 'Sevnica',
                rkey: '110',
            },
            {
                rname: 'Sevnica',
                rkey: '46',
            },
            {
                rname: 'Sezana',
                rkey: '47',
            },
            {
                rname: 'Skofja Loka',
                rkey: '48',
            },
            {
                rname: 'Slovenj Gradec',
                rkey: '112',
            },
            {
                rname: 'Slovenj Gradec',
                rkey: '49',
            },
            {
                rname: 'Slovenska Bistrica',
                rkey: '113',
            },
            {
                rname: 'Slovenska Bistrica',
                rkey: '50',
            },
            {
                rname: 'Slovenske Konjice',
                rkey: '114',
            },
            {
                rname: 'Slovenske Konjice',
                rkey: '51',
            },
            {
                rname: 'Smarje Pri Jelsah',
                rkey: '52',
            },
            {
                rname: 'Sodra\u00beica',
                rkey: '179',
            },
            {
                rname: 'Sol\u00e8ava',
                rkey: '180',
            },
            {
                rname: 'Star\u00b9e',
                rkey: '115',
            },
            {
                rname: 'Sveta Ana',
                rkey: '181',
            },
            {
                rname: 'Sveti Andra\u00be v Slov.',
                rkey: '182',
            },
            {
                rname: 'Sveti Jurij',
                rkey: '116',
            },
            {
                rname: 'Tabor',
                rkey: '184',
            },
            {
                rname: 'Ti\u00b9ina',
                rkey: '010',
            },
            {
                rname: 'Tolmin',
                rkey: '128',
            },
            {
                rname: 'Tolmin',
                rkey: '53',
            },
            {
                rname: 'Tr\u00bei\u00e8',
                rkey: '131',
            },
            {
                rname: 'Trbovlje',
                rkey: '129',
            },
            {
                rname: 'Trbovlje',
                rkey: '54',
            },
            {
                rname: 'Trebnje',
                rkey: '130',
            },
            {
                rname: 'Trebnje',
                rkey: '55',
            },
            {
                rname: 'Trnovska vas',
                rkey: '185',
            },
            {
                rname: 'Trzic',
                rkey: '56',
            },
            {
                rname: 'Trzin',
                rkey: '186',
            },
            {
                rname: 'Turni\u00b9\u00e8e',
                rkey: '132',
            },
            {
                rname: 'Velenje',
                rkey: '133',
            },
            {
                rname: 'Velenje',
                rkey: '57',
            },
            {
                rname: 'Velika Polana',
                rkey: '187',
            },
            {
                rname: 'Velike La\u00b9\u00e8e',
                rkey: '134',
            },
            {
                rname: 'Ver\u00beej',
                rkey: '188',
            },
            {
                rname: 'Videm',
                rkey: '135',
            },
            {
                rname: 'Vipava',
                rkey: '136',
            },
            {
                rname: 'Vitanje',
                rkey: '137',
            },
            {
                rname: 'Vodice',
                rkey: '138',
            },
            {
                rname: 'Vojnik',
                rkey: '139',
            },
            {
                rname: 'Vransko',
                rkey: '189',
            },
            {
                rname: 'Vrhnika',
                rkey: '140',
            },
            {
                rname: 'Vrhnika',
                rkey: '58',
            },
            {
                rname: 'Vuzenica',
                rkey: '141',
            },
            {
                rname: 'Zagorje ob Savi',
                rkey: '142',
            },
            {
                rname: 'Zagorje Ob Savi',
                rkey: '59',
            },
            {
                rname: 'Zalec',
                rkey: '60',
            },
            {
                rname: 'Zavr\u00e8',
                rkey: '143',
            },
            {
                rname: 'Zre\u00e8e',
                rkey: '144',
            },
        ],
    },
    {
        countryName: 'Solomon Islands',
        countryShortCode: 'SB',
        Regions: [],
    },
    {
        countryName: 'Somalia',
        countryShortCode: 'SO',
        Regions: [],
    },
    {
        countryName: 'South Africa',
        countryShortCode: 'ZA',
        Regions: [
            {
                rname: 'Eastern Cape',
                rkey: 'EC',
            },
            {
                rname: 'Free State',
                rkey: 'FS',
            },
            {
                rname: 'Gauteng',
                rkey: 'GP',
            },
            {
                rname: 'KwaZulu-Natal',
                rkey: 'ZN',
            },
            {
                rname: 'Limpopo',
                rkey: 'LP',
            },
            {
                rname: 'Mpumalanga',
                rkey: 'MP',
            },
            {
                rname: 'Northern Cape',
                rkey: 'NC',
            },
            {
                rname: 'Northwest',
                rkey: 'NW',
            },
            {
                rname: 'Western Cape',
                rkey: 'WC',
            },
        ],
    },
    {
        countryName: 'South Georgia and the Southern Sandwich Islands',
        countryShortCode: 'GS',
        Regions: [],
    },
    {
        countryName: 'South Korea',
        countryShortCode: 'KR',
        Regions: [
            {
                rname: 'Ch\u00b4ungch\u00b4ong-bukto',
                rkey: '04',
            },
            {
                rname: 'Ch\u00b4ungch\u00b4ong-namdo',
                rkey: '05',
            },
            {
                rname: 'Cheju-do',
                rkey: '01',
            },
            {
                rname: 'Cholla-bukto',
                rkey: '02',
            },
            {
                rname: 'Cholla-namdo',
                rkey: '03',
            },
            {
                rname: 'Inch\u00b4on-jikhalsi',
                rkey: '06',
            },
            {
                rname: 'Kangwon-do',
                rkey: '07',
            },
            {
                rname: 'Kwangju-jikhalsi',
                rkey: '08',
            },
            {
                rname: 'Kyonggi-do',
                rkey: '09',
            },
            {
                rname: 'Kyongsang-bukto',
                rkey: '10',
            },
            {
                rname: 'Kyongsang-namdo',
                rkey: '11',
            },
            {
                rname: 'Pusan-jikhalsi',
                rkey: '12',
            },
            {
                rname: 'Sejong',
                rkey: '17',
            },
            {
                rname: 'Soul-t\u00b4ukpyolsi',
                rkey: '13',
            },
            {
                rname: 'Taegu-jikhalsi',
                rkey: '14',
            },
            {
                rname: 'Taejon-jikhalsi',
                rkey: '15',
            },
            {
                rname: 'Ulsan',
                rkey: '16',
            },
        ],
    },
    {
        countryName: 'South Sudan',
        countryShortCode: 'SS',
        Regions: [],
    },
    {
        countryName: 'Spain',
        countryShortCode: 'ES',
        Regions: [
            {
                rname: 'Alava',
                rkey: '01',
            },
            {
                rname: 'Albacete',
                rkey: '02',
            },
            {
                rname: 'Alicante',
                rkey: '03',
            },
            {
                rname: 'Almeria',
                rkey: '04',
            },
            {
                rname: 'Asturias',
                rkey: '33',
            },
            {
                rname: 'Avila',
                rkey: '05',
            },
            {
                rname: 'Badajoz',
                rkey: '06',
            },
            {
                rname: 'Baleares',
                rkey: '07',
            },
            {
                rname: 'Barcelona',
                rkey: '08',
            },
            {
                rname: 'Burgos',
                rkey: '09',
            },
            {
                rname: 'Caceres',
                rkey: '10',
            },
            {
                rname: 'Cadiz',
                rkey: '11',
            },
            {
                rname: 'Cantabria',
                rkey: '39',
            },
            {
                rname: 'Castellon',
                rkey: '12',
            },
            {
                rname: 'Ciudad Real',
                rkey: '13',
            },
            {
                rname: 'Cordoba',
                rkey: '14',
            },
            {
                rname: 'Cuenca',
                rkey: '16',
            },
            {
                rname: 'Gerona',
                rkey: '17',
            },
            {
                rname: 'Granada',
                rkey: '18',
            },
            {
                rname: 'Guadalajara',
                rkey: '19',
            },
            {
                rname: 'Guipuzcoa',
                rkey: '20',
            },
            {
                rname: 'Huelva',
                rkey: '21',
            },
            {
                rname: 'Huesca',
                rkey: '22',
            },
            {
                rname: 'Jaen',
                rkey: '23',
            },
            {
                rname: 'La Coruna',
                rkey: '15',
            },
            {
                rname: 'La Rioja',
                rkey: '26',
            },
            {
                rname: 'Las Palmas',
                rkey: '35',
            },
            {
                rname: 'Leon',
                rkey: '24',
            },
            {
                rname: 'Lerida',
                rkey: '25',
            },
            {
                rname: 'Lugo',
                rkey: '27',
            },
            {
                rname: 'Madrid',
                rkey: '28',
            },
            {
                rname: 'Malaga',
                rkey: '29',
            },
            {
                rname: 'Murcia',
                rkey: '30',
            },
            {
                rname: 'Navarra',
                rkey: '31',
            },
            {
                rname: 'Orense',
                rkey: '32',
            },
            {
                rname: 'Palencia',
                rkey: '34',
            },
            {
                rname: 'Pontevedra',
                rkey: '36',
            },
            {
                rname: 'Salamanca',
                rkey: '37',
            },
            {
                rname: 'Segovia',
                rkey: '40',
            },
            {
                rname: 'Sevilla',
                rkey: '41',
            },
            {
                rname: 'Soria',
                rkey: '42',
            },
            {
                rname: 'Sta. Cruz Tenerife',
                rkey: '38',
            },
            {
                rname: 'Tarragona',
                rkey: '43',
            },
            {
                rname: 'Teruel',
                rkey: '44',
            },
            {
                rname: 'Toledo',
                rkey: '45',
            },
            {
                rname: 'Valencia',
                rkey: '46',
            },
            {
                rname: 'Valladolid',
                rkey: '47',
            },
            {
                rname: 'Vizcaya',
                rkey: '48',
            },
            {
                rname: 'Zamora',
                rkey: '49',
            },
            {
                rname: 'Zaragoza',
                rkey: '50',
            },
        ],
    },
    {
        countryName: 'Sri Lanka',
        countryShortCode: 'LK',
        Regions: [],
    },
    {
        countryName: 'St. Lucia',
        countryShortCode: 'LC',
        Regions: [],
    },
    {
        countryName: 'St. Pierre and Miquelon',
        countryShortCode: 'PM',
        Regions: [],
    },
    {
        countryName: 'St. Vincent and the Grenadines',
        countryShortCode: 'VC',
        Regions: [],
    },
    {
        countryName: 'Sudan',
        countryShortCode: 'SD',
        Regions: [],
    },
    {
        countryName: 'Suriname',
        countryShortCode: 'SR',
        Regions: [],
    },
    {
        countryName: 'Svalbard',
        countryShortCode: 'SJ',
        Regions: [],
    },
    {
        countryName: 'Swaziland',
        countryShortCode: 'SZ',
        Regions: [],
    },
    {
        countryName: 'Sweden',
        countryShortCode: 'SE',
        Regions: [
            {
                rname: 'Blekinge County',
                rkey: '001',
            },
            {
                rname: 'Dalarnas County',
                rkey: '002',
            },
            {
                rname: 'Gaevleborg County',
                rkey: '004',
            },
            {
                rname: 'Gotland County',
                rkey: '003',
            },
            {
                rname: 'Halland County',
                rkey: '005',
            },
            {
                rname: 'Jaemtland County',
                rkey: '006',
            },
            {
                rname: 'Joenkoeping County',
                rkey: '007',
            },
            {
                rname: 'Kalmar County',
                rkey: '008',
            },
            {
                rname: 'Kronoberg County',
                rkey: '009',
            },
            {
                rname: 'Norrbotten County',
                rkey: '010',
            },
            {
                rname: 'Oerebro County',
                rkey: '020',
            },
            {
                rname: 'Oestergoetland Cnty',
                rkey: '021',
            },
            {
                rname: 'Skaane County',
                rkey: '011',
            },
            {
                rname: 'Soedermanland County',
                rkey: '013',
            },
            {
                rname: 'Stockholm County',
                rkey: '012',
            },
            {
                rname: 'Uppsala County',
                rkey: '014',
            },
            {
                rname: 'Vaermland County',
                rkey: '015',
            },
            {
                rname: 'Vaesterbotten County',
                rkey: '016',
            },
            {
                rname: 'Vaesternorrland Cnty',
                rkey: '017',
            },
            {
                rname: 'Vaestmanland County',
                rkey: '018',
            },
            {
                rname: 'Vaestra Goetaland C.',
                rkey: '019',
            },
        ],
    },
    {
        countryName: 'Switzerland',
        countryShortCode: 'CH',
        Regions: [
            {
                rname: 'Aargau',
                rkey: 'AG',
            },
            {
                rname: 'Ausser-Rhoden',
                rkey: 'AR',
            },
            {
                rname: 'Basel Land',
                rkey: 'BL',
            },
            {
                rname: 'Basel Stadt',
                rkey: 'BS',
            },
            {
                rname: 'Bern',
                rkey: 'BE',
            },
            {
                rname: 'Fribourg',
                rkey: 'FR',
            },
            {
                rname: 'Geneva',
                rkey: 'GE',
            },
            {
                rname: 'Glarus',
                rkey: 'GL',
            },
            {
                rname: 'Graubuenden',
                rkey: 'GR',
            },
            {
                rname: 'Inner-Rhoden',
                rkey: 'AI',
            },
            {
                rname: 'Jura',
                rkey: 'JU',
            },
            {
                rname: 'Lucerne',
                rkey: 'LU',
            },
            {
                rname: 'Neuchatel',
                rkey: 'NE',
            },
            {
                rname: 'Nidwalden',
                rkey: 'NW',
            },
            {
                rname: 'Obwalden',
                rkey: 'OW',
            },
            {
                rname: 'Schaffhausen',
                rkey: 'SH',
            },
            {
                rname: 'Schwyz',
                rkey: 'SZ',
            },
            {
                rname: 'Solothurn',
                rkey: 'SO',
            },
            {
                rname: 'St. Gallen',
                rkey: 'SG',
            },
            {
                rname: 'Thurgau',
                rkey: 'TG',
            },
            {
                rname: 'Ticino',
                rkey: 'TI',
            },
            {
                rname: 'Uri',
                rkey: 'UR',
            },
            {
                rname: 'Valais',
                rkey: 'VS',
            },
            {
                rname: 'Vaud',
                rkey: 'VD',
            },
            {
                rname: 'Zug',
                rkey: 'ZG',
            },
            {
                rname: 'Zurich',
                rkey: 'ZH',
            },
        ],
    },
    {
        countryName: 'Syria',
        countryShortCode: 'SY',
        Regions: [],
    },
    {
        countryName: 'Taiwan',
        countryShortCode: 'TW',
        Regions: [
            {
                rname: 'Fu-chien',
                rkey: 'FJN',
            },
            {
                rname: 'Kao-hsiung',
                rkey: 'KSH',
            },
            {
                rname: 'T\u00b4ai-pei',
                rkey: 'TPE',
            },
            {
                rname: 'Taiwan',
                rkey: 'TWN',
            },
        ],
    },
    {
        countryName: 'Tajikistan',
        countryShortCode: 'TJ',
        Regions: [],
    },
    {
        countryName: 'Tanzania',
        countryShortCode: 'TZ',
        Regions: [],
    },
    {
        countryName: 'Thailand',
        countryShortCode: 'TH',
        Regions: [
            {
                rname: 'Amnat Charoen',
                rkey: '01',
            },
            {
                rname: 'Ang Thong',
                rkey: '02',
            },
            {
                rname: 'Bueng Kan',
                rkey: '50',
            },
            {
                rname: 'Buriram',
                rkey: '03',
            },
            {
                rname: 'Chachoengsao',
                rkey: '04',
            },
            {
                rname: 'Chai Nat',
                rkey: '05',
            },
            {
                rname: 'Chaiyaphum',
                rkey: '06',
            },
            {
                rname: 'Chanthaburi',
                rkey: '07',
            },
            {
                rname: 'Chiang Mai',
                rkey: '08',
            },
            {
                rname: 'Chiang Rai',
                rkey: '09',
            },
            {
                rname: 'Chon Buri',
                rkey: '10',
            },
            {
                rname: 'Chumphon',
                rkey: '11',
            },
            {
                rname: 'Kalasin',
                rkey: '12',
            },
            {
                rname: 'Kamphaeng Phet',
                rkey: '13',
            },
            {
                rname: 'Kanchanaburi',
                rkey: '14',
            },
            {
                rname: 'Khon Kaen',
                rkey: '15',
            },
            {
                rname: 'Krabi',
                rkey: '16',
            },
            {
                rname: 'Krung Thep',
                rkey: '17',
            },
            {
                rname: 'Lampang',
                rkey: '19',
            },
            {
                rname: 'Lamphun',
                rkey: '20',
            },
            {
                rname: 'Loei',
                rkey: '21',
            },
            {
                rname: 'Lop Buri',
                rkey: '22',
            },
            {
                rname: 'Mae Hong Son',
                rkey: '23',
            },
            {
                rname: 'Maha Sarakham',
                rkey: '24',
            },
            {
                rname: 'Mahanakhon',
                rkey: '18',
            },
            {
                rname: 'Mukdahan',
                rkey: '25',
            },
            {
                rname: 'Nakhon Nayok',
                rkey: '26',
            },
            {
                rname: 'Nakhon Pathom',
                rkey: '27',
            },
            {
                rname: 'Nakhon Phanom',
                rkey: '28',
            },
            {
                rname: 'Nakhon Ratchasima',
                rkey: '29',
            },
            {
                rname: 'Nakhon Sawan',
                rkey: '30',
            },
            {
                rname: 'Nakhon Si Thammarat',
                rkey: '31',
            },
            {
                rname: 'Nan',
                rkey: '32',
            },
            {
                rname: 'Narathiwat',
                rkey: '33',
            },
            {
                rname: 'Nong Bua Lamphu',
                rkey: '34',
            },
            {
                rname: 'Nong Khai',
                rkey: '35',
            },
            {
                rname: 'Nonthaburi',
                rkey: '36',
            },
            {
                rname: 'Pathum Thani',
                rkey: '37',
            },
            {
                rname: 'Pattani',
                rkey: '38',
            },
            {
                rname: 'Phangnga',
                rkey: '39',
            },
            {
                rname: 'Phatthalung',
                rkey: '40',
            },
            {
                rname: 'Phayao',
                rkey: '41',
            },
            {
                rname: 'Phetchabun',
                rkey: '42',
            },
            {
                rname: 'Phetchaburi',
                rkey: '43',
            },
            {
                rname: 'Phichit',
                rkey: '44',
            },
            {
                rname: 'Phitsanulok',
                rkey: '45',
            },
            {
                rname: 'Phra Nakhon Si Ayut.',
                rkey: '46',
            },
            {
                rname: 'Phrae',
                rkey: '47',
            },
            {
                rname: 'Phuket',
                rkey: '48',
            },
            {
                rname: 'Prachin Buri',
                rkey: '49',
            },
        ],
    },
    {
        countryName: 'Togo',
        countryShortCode: 'TG',
        Regions: [],
    },
    {
        countryName: 'Tokelau Islands',
        countryShortCode: 'TK',
        Regions: [],
    },
    {
        countryName: 'Tonga',
        countryShortCode: 'TO',
        Regions: [],
    },
    {
        countryName: 'Trinidad and Tobago',
        countryShortCode: 'TT',
        Regions: [],
    },
    {
        countryName: 'Tunisia',
        countryShortCode: 'TN',
        Regions: [],
    },
    {
        countryName: 'Turkey',
        countryShortCode: 'TR',
        Regions: [
            {
                rname: 'Adana',
                rkey: '01',
            },
            {
                rname: 'Adiyaman',
                rkey: '02',
            },
            {
                rname: 'Afyon',
                rkey: '03',
            },
            {
                rname: 'Agri',
                rkey: '04',
            },
            {
                rname: 'Aksaray',
                rkey: '68',
            },
            {
                rname: 'Amasya',
                rkey: '05',
            },
            {
                rname: 'Ankara',
                rkey: '06',
            },
            {
                rname: 'Antalya',
                rkey: '07',
            },
            {
                rname: 'Ardahan',
                rkey: '75',
            },
            {
                rname: 'Artvin',
                rkey: '08',
            },
            {
                rname: 'Aydin',
                rkey: '09',
            },
            {
                rname: 'Balikesir',
                rkey: '10',
            },
            {
                rname: 'Bartin',
                rkey: '74',
            },
            {
                rname: 'Batman',
                rkey: '72',
            },
            {
                rname: 'Bayburt',
                rkey: '69',
            },
            {
                rname: 'Bilecik',
                rkey: '11',
            },
            {
                rname: 'Bing\u00f6l',
                rkey: '12',
            },
            {
                rname: 'Bitlis',
                rkey: '13',
            },
            {
                rname: 'Bolu',
                rkey: '14',
            },
            {
                rname: 'Burdur',
                rkey: '15',
            },
            {
                rname: 'Bursa',
                rkey: '16',
            },
            {
                rname: 'Canakkale',
                rkey: '17',
            },
            {
                rname: 'Cankiri',
                rkey: '18',
            },
            {
                rname: 'Corum',
                rkey: '19',
            },
            {
                rname: 'Denizli',
                rkey: '20',
            },
            {
                rname: 'Diyarbakir',
                rkey: '21',
            },
            {
                rname: 'Edirne',
                rkey: '22',
            },
            {
                rname: 'Elazig',
                rkey: '23',
            },
            {
                rname: 'Erzincan',
                rkey: '24',
            },
            {
                rname: 'Erzurum',
                rkey: '25',
            },
            {
                rname: 'Eskisehir',
                rkey: '26',
            },
            {
                rname: 'Gaziantep',
                rkey: '27',
            },
            {
                rname: 'Giresun',
                rkey: '28',
            },
            {
                rname: 'Guemueshane',
                rkey: '29',
            },
            {
                rname: 'Hakkari',
                rkey: '30',
            },
            {
                rname: 'Hatay',
                rkey: '31',
            },
            {
                rname: 'Icel',
                rkey: '33',
            },
            {
                rname: 'Igdir',
                rkey: '76',
            },
            {
                rname: 'Isparta',
                rkey: '32',
            },
            {
                rname: 'Istanbul',
                rkey: '34',
            },
            {
                rname: 'Izmir',
                rkey: '35',
            },
            {
                rname: 'K.Marash',
                rkey: '46',
            },
            {
                rname: 'Karaman',
                rkey: '70',
            },
            {
                rname: 'Kars',
                rkey: '36',
            },
            {
                rname: 'Kastamonu',
                rkey: '37',
            },
            {
                rname: 'Kayseri',
                rkey: '38',
            },
            {
                rname: 'Kirikkale',
                rkey: '71',
            },
            {
                rname: 'Kirklareli',
                rkey: '39',
            },
            {
                rname: 'Kirshehir',
                rkey: '40',
            },
            {
                rname: 'Kocaeli',
                rkey: '41',
            },
            {
                rname: 'Konya',
                rkey: '42',
            },
            {
                rname: 'Kuetahya',
                rkey: '43',
            },
            {
                rname: 'Malatya',
                rkey: '44',
            },
            {
                rname: 'Manisa',
                rkey: '45',
            },
            {
                rname: 'Mardin',
                rkey: '47',
            },
            {
                rname: 'Mugla',
                rkey: '48',
            },
            {
                rname: 'Mush',
                rkey: '49',
            },
            {
                rname: 'Nevshehir',
                rkey: '50',
            },
            {
                rname: 'Nigde',
                rkey: '51',
            },
            {
                rname: 'Ordu',
                rkey: '52',
            },
            {
                rname: 'Rize',
                rkey: '53',
            },
            {
                rname: 'Sakarya',
                rkey: '54',
            },
            {
                rname: 'Samsun',
                rkey: '55',
            },
            {
                rname: 'Shanliurfa',
                rkey: '63',
            },
            {
                rname: 'Shirnak',
                rkey: '73',
            },
            {
                rname: 'Siirt',
                rkey: '56',
            },
            {
                rname: 'Sinop',
                rkey: '57',
            },
            {
                rname: 'Sivas',
                rkey: '58',
            },
            {
                rname: 'Tekirdag',
                rkey: '59',
            },
            {
                rname: 'Tokat',
                rkey: '60',
            },
            {
                rname: 'Trabzon',
                rkey: '61',
            },
            {
                rname: 'Tunceli',
                rkey: '62',
            },
            {
                rname: 'Ushak',
                rkey: '64',
            },
            {
                rname: 'Van',
                rkey: '65',
            },
            {
                rname: 'Yalova',
                rkey: '77',
            },
            {
                rname: 'Yozgat',
                rkey: '66',
            },
            {
                rname: 'Zonguldak',
                rkey: '67',
            },
        ],
    },
    {
        countryName: 'Turkmenistan',
        countryShortCode: 'TM',
        Regions: [],
    },
    {
        countryName: 'Turks and Caicos Islands',
        countryShortCode: 'TC',
        Regions: [],
    },
    {
        countryName: 'Tuvalu',
        countryShortCode: 'TV',
        Regions: [],
    },
    {
        countryName: 'Uganda',
        countryShortCode: 'UG',
        Regions: [],
    },
    {
        countryName: 'Ukraine',
        countryShortCode: 'UA',
        Regions: [
            {
                rname: "Cherkas'ka",
                rkey: 'CHR',
            },
            {
                rname: "Chernigivs'ka",
                rkey: 'CHG',
            },
            {
                rname: "Chernovits'ka",
                rkey: 'CHV',
            },
            {
                rname: "Dnipropetrovs'ka",
                rkey: 'DNP',
            },
            {
                rname: "Donets'ka",
                rkey: 'DON',
            },
            {
                rname: "Harkivs'ka",
                rkey: 'HAR',
            },
            {
                rname: "Hersons'ka",
                rkey: 'HRS',
            },
            {
                rname: "Hmel'nits'ka",
                rkey: 'HML',
            },
            {
                rname: "Ivano-Frankivs'ka",
                rkey: 'IVF',
            },
            {
                rname: "Kievs'ka",
                rkey: 'KIE',
            },
            {
                rname: "Kirovograds'ka",
                rkey: 'KIR',
            },
            {
                rname: "Lugans'ka",
                rkey: 'LUG',
            },
            {
                rname: "L'vivsbka",
                rkey: "L'V",
            },
            {
                rname: 'm.Kiev',
                rkey: 'M_K',
            },
            {
                rname: "m.Sevastopil'",
                rkey: 'M_S',
            },
            {
                rname: "Mikolaivs'ka",
                rkey: 'MIK',
            },
            {
                rname: "Odes'ka",
                rkey: 'ODS',
            },
            {
                rname: "Poltavs'ka",
                rkey: 'POL',
            },
            {
                rname: 'Respublika Krim',
                rkey: 'KRM',
            },
            {
                rname: "Rivnens'ka",
                rkey: 'RIV',
            },
            {
                rname: "Sums'ka",
                rkey: 'SUM',
            },
            {
                rname: "Ternopil's'ka",
                rkey: 'TER',
            },
            {
                rname: "Vinnits'ka",
                rkey: 'VIN',
            },
            {
                rname: "Volins'ka",
                rkey: 'VOL',
            },
            {
                rname: "Zakarpats'ka",
                rkey: 'ZAK',
            },
            {
                rname: "Zaporiz'ka",
                rkey: 'ZAP',
            },
            {
                rname: "Zhitomirs'ka",
                rkey: 'ZHI',
            },
        ],
    },
    {
        countryName: 'United Arab Emirates',
        countryShortCode: 'AE',
        Regions: [],
    },
    {
        countryName: 'United Kingdom',
        countryShortCode: 'GB',
        Regions: [
            {
                rname: 'Aberdeenshire',
                rkey: 'AB',
            },
            {
                rname: 'Anglesey',
                rkey: 'AL',
            },
            {
                rname: 'Angus/Forfarshire',
                rkey: 'AN',
            },
            {
                rname: 'Antrim',
                rkey: 'AT',
            },
            {
                rname: 'Argyllshire',
                rkey: 'AG',
            },
            {
                rname: 'Armagh',
                rkey: 'AM',
            },
            {
                rname: 'Ayrshire',
                rkey: 'AY',
            },
            {
                rname: 'Banffshire',
                rkey: 'BF',
            },
            {
                rname: 'Bath&NthEstSomerset',
                rkey: 'BS',
            },
            {
                rname: 'Bedfordshire',
                rkey: 'BE',
            },
            {
                rname: 'Berkshire',
                rkey: 'BK',
            },
            {
                rname: 'Berwickshire',
                rkey: 'BW',
            },
            {
                rname: 'Brecknockshire',
                rkey: 'BR',
            },
            {
                rname: 'Buckinghamshire',
                rkey: 'BU',
            },
            {
                rname: 'Buteshire',
                rkey: 'BT',
            },
            {
                rname: 'Caernarfonshire',
                rkey: 'CF',
            },
            {
                rname: 'Caithness',
                rkey: 'CT',
            },
            {
                rname: 'Cambridgeshire',
                rkey: 'CA',
            },
            {
                rname: 'Cardiganshire',
                rkey: 'CD',
            },
            {
                rname: 'Carmarthenshire',
                rkey: 'CB',
            },
            {
                rname: 'Cheshire',
                rkey: 'CH',
            },
            {
                rname: 'Clackmannanshire',
                rkey: 'CN',
            },
            {
                rname: 'Cornwall',
                rkey: 'CO',
            },
            {
                rname: 'Cromartyshire',
                rkey: 'CM',
            },
            {
                rname: 'Cumberland',
                rkey: 'CU',
            },
            {
                rname: 'Denbighshire',
                rkey: 'DD',
            },
            {
                rname: 'Devon',
                rkey: 'DV',
            },
            {
                rname: 'Dorset',
                rkey: 'DO',
            },
            {
                rname: 'Down',
                rkey: 'DN',
            },
            {
                rname: 'Dumfriesshire',
                rkey: 'DF',
            },
            {
                rname: 'Dunbartonshire',
                rkey: 'DT',
            },
            {
                rname: 'Durham',
                rkey: 'DU',
            },
            {
                rname: 'East Lothian',
                rkey: 'EL',
            },
            {
                rname: 'East Sussex',
                rkey: 'SE',
            },
            {
                rname: 'Essex',
                rkey: 'ES',
            },
            {
                rname: 'Fermanagh',
                rkey: 'FM',
            },
            {
                rname: 'Fife',
                rkey: 'FI',
            },
            {
                rname: 'Flintshire',
                rkey: 'FL',
            },
            {
                rname: 'Gloucestershire',
                rkey: 'GL',
            },
            {
                rname: 'Greater London',
                rkey: 'LO',
            },
            {
                rname: 'Hampshire',
                rkey: 'HA',
            },
            {
                rname: 'Hereford and Worcs.',
                rkey: 'HW',
            },
            {
                rname: 'Hertfordshire',
                rkey: 'HT',
            },
            {
                rname: 'Huntingdonshire',
                rkey: 'HU',
            },
            {
                rname: 'Invernesshire',
                rkey: 'IN',
            },
            {
                rname: 'Isle of Wight',
                rkey: 'IW',
            },
            {
                rname: 'Kent',
                rkey: 'KE',
            },
            {
                rname: 'Kincardineshire',
                rkey: 'KI',
            },
            {
                rname: 'Kinross-shire',
                rkey: 'KN',
            },
            {
                rname: 'Kirkcudbrightshire',
                rkey: 'KK',
            },
            {
                rname: 'Lanarkshire',
                rkey: 'LN',
            },
            {
                rname: 'Lancashire',
                rkey: 'LA',
            },
            {
                rname: 'Leicestershire',
                rkey: 'LE',
            },
            {
                rname: 'Lincolnshire',
                rkey: 'LI',
            },
            {
                rname: 'Londonderry',
                rkey: 'LD',
            },
            {
                rname: 'Merioneth',
                rkey: 'ME',
            },
            {
                rname: 'Mid Glamorgan',
                rkey: 'MG',
            },
            {
                rname: 'Middlesex',
                rkey: 'MX',
            },
            {
                rname: 'Midlothian',
                rkey: 'MD',
            },
            {
                rname: 'Monmouthshire',
                rkey: 'MM',
            },
            {
                rname: 'Montgomeryshire',
                rkey: 'MT',
            },
            {
                rname: 'Morayshire',
                rkey: 'MR',
            },
            {
                rname: 'Nairnshire',
                rkey: 'NR',
            },
            {
                rname: 'Norfolk',
                rkey: 'NK',
            },
            {
                rname: 'North Yorkshire',
                rkey: 'YN',
            },
            {
                rname: 'Northamptonshire',
                rkey: 'NH',
            },
            {
                rname: 'Northumberland',
                rkey: 'NU',
            },
            {
                rname: 'Nottinghamshire',
                rkey: 'NT',
            },
            {
                rname: 'Orkney',
                rkey: 'OR',
            },
            {
                rname: 'Oxfordshire',
                rkey: 'OX',
            },
            {
                rname: 'Peeblesshire',
                rkey: 'PE',
            },
            {
                rname: 'Pembrokeshire',
                rkey: 'PM',
            },
            {
                rname: 'Perthshire',
                rkey: 'PR',
            },
            {
                rname: 'Radnorshire',
                rkey: 'RA',
            },
            {
                rname: 'Renfrewshire',
                rkey: 'RE',
            },
            {
                rname: 'Ross-shire',
                rkey: 'RO',
            },
            {
                rname: 'Roxburghshire',
                rkey: 'RX',
            },
            {
                rname: 'Rutland',
                rkey: 'RU',
            },
            {
                rname: 'Selkirkshire',
                rkey: 'SF',
            },
            {
                rname: 'Shetland',
                rkey: 'SL',
            },
            {
                rname: 'Shropshire',
                rkey: 'SH',
            },
            {
                rname: 'Somerset',
                rkey: 'SO',
            },
            {
                rname: 'South Glamorgan',
                rkey: 'SG',
            },
            {
                rname: 'South Yorkshire',
                rkey: 'YS',
            },
            {
                rname: 'Staffordshire',
                rkey: 'ST',
            },
            {
                rname: 'Stirlingshire',
                rkey: 'SV',
            },
            {
                rname: 'Suffolk',
                rkey: 'SK',
            },
            {
                rname: 'Surrey',
                rkey: 'SY',
            },
            {
                rname: 'Sutherland',
                rkey: 'SU',
            },
            {
                rname: 'Tyrone',
                rkey: 'TY',
            },
            {
                rname: 'Warwickshire',
                rkey: 'WA',
            },
            {
                rname: 'West Glamorgan',
                rkey: 'WG',
            },
            {
                rname: 'West Sussex',
                rkey: 'SW',
            },
            {
                rname: 'West Yorkshire',
                rkey: 'YW',
            },
            {
                rname: 'Westmorland',
                rkey: 'WE',
            },
            {
                rname: 'Wigtownshire',
                rkey: 'WT',
            },
            {
                rname: 'Wiltshire',
                rkey: 'WI',
            },
            {
                rname: 'Worcestershire',
                rkey: 'WC',
            },
        ],
    },
    {
        countryName: 'United Nations',
        countryShortCode: 'UN',
        Regions: [],
    },
    {
        countryName: 'United States Minor Outlying Islands',
        countryShortCode: 'UM',
        Regions: [],
    },
    {
        countryName: 'United States Virgin Islands',
        countryShortCode: 'VI',
        Regions: [],
    },
    {
        countryName: 'Uruguay',
        countryShortCode: 'UY',
        Regions: [],
    },
    {
        countryName: 'Uzbekistan',
        countryShortCode: 'UZ',
        Regions: [],
    },
    {
        countryName: 'Vanuatu',
        countryShortCode: 'VU',
        Regions: [],
    },
    {
        countryName: 'Vatican City',
        countryShortCode: 'VA',
        Regions: [],
    },
    {
        countryName: 'Venezuela',
        countryShortCode: 'VE',
        Regions: [
            {
                rname: 'Amazon',
                rkey: 'AMA',
            },
            {
                rname: 'Anzoategui',
                rkey: 'ANZ',
            },
            {
                rname: 'Apure',
                rkey: 'APU',
            },
            {
                rname: 'Aragua',
                rkey: 'ARA',
            },
            {
                rname: 'Barinas',
                rkey: 'BAR',
            },
            {
                rname: 'Bolivar',
                rkey: 'BOL',
            },
            {
                rname: 'Carabobo',
                rkey: 'CAR',
            },
            {
                rname: 'Cojedes',
                rkey: 'COJ',
            },
            {
                rname: 'Delta Amacuro',
                rkey: 'DA',
            },
            {
                rname: 'Distrito Federal',
                rkey: 'DF',
            },
            {
                rname: 'Falcon',
                rkey: 'FAL',
            },
            {
                rname: 'Guarico',
                rkey: 'GUA',
            },
            {
                rname: 'Lara',
                rkey: 'LAR',
            },
            {
                rname: 'Merida',
                rkey: 'MER',
            },
            {
                rname: 'Miranda',
                rkey: 'MIR',
            },
            {
                rname: 'Monagas',
                rkey: 'MON',
            },
            {
                rname: 'Nueva Esparta',
                rkey: 'NE',
            },
            {
                rname: 'Portuguesa',
                rkey: 'POR',
            },
            {
                rname: 'Sucre',
                rkey: 'SUC',
            },
            {
                rname: 'Tachira',
                rkey: 'TAC',
            },
            {
                rname: 'Trujillo',
                rkey: 'TRU',
            },
            {
                rname: 'Vargas',
                rkey: 'VAR',
            },
            {
                rname: 'Yaracuy',
                rkey: 'YAR',
            },
            {
                rname: 'Zulia',
                rkey: 'ZUL',
            },
        ],
    },
    {
        countryName: 'Vietnam',
        countryShortCode: 'VN',
        Regions: [],
    },
    {
        countryName: 'Wallis and Futuna Islands',
        countryShortCode: 'WF',
        Regions: [],
    },
    {
        countryName: 'Western Sahara',
        countryShortCode: 'EH',
        Regions: [],
    },
    {
        countryName: 'Yemen',
        countryShortCode: 'YE',
        Regions: [],
    },
    {
        countryName: 'Zambia',
        countryShortCode: 'ZM',
        Regions: [],
    },
    {
        countryName: 'Zimbabwe',
        countryShortCode: 'ZW',
        Regions: [],
    },
    {
        countryName: 'Republic Serbia',
        countryShortCode: 'RS',
        Regions: [],
    },
    {
        countryName: 'CEUTA',
        countryShortCode: 'XC',
        Regions: [],
    },
    {
        countryName: 'MELILLA',
        countryShortCode: 'XL',
        Regions: [],
    },
    {
        countryName: 'MONTENEGRO',
        countryShortCode: 'ME',
        Regions: [],
    },
    {
        countryName: 'NETHERLANDS ANTILLES',
        countryShortCode: 'AN',
        Regions: [],
    },
];
