import React, { Component } from 'react';
import { heightPercentageToDP as hp } from 'react-native-responsive-screen';
import {
    Text,
    View,
    KeyboardAvoidingView,
    StyleSheet,
    TouchableOpacity,
    Image,
    ActivityIndicator,
    AsyncStorage,
} from 'react-native';

import { Redirect } from 'react-router-dom';
import { Box } from '../components/common';

class SFDC extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
        };
    }

    componentDidMount() {
        const params = window.location.pathname;

        const search = window.location.search;

        const localCheck = localStorage.getItem('SFDCData');

        if (
            (params.includes('/sfdc%3F') || search.includes('/sfdc%3F')) &&
            localCheck == null
        ) {
            console.log('Made it here');
            const requestData =
                search.length > 0
                    ? search.substr(search.indexOf('sfdc%3F') + 7)
                    : params.substr(params.indexOf('sfdc%3F') + 7);

            const decoded = atob(requestData);

            const queryParams = decoded
                .split('&')
                .reduce((accumulator, singleQueryParam) => {
                    console.log(singleQueryParam);
                    const [key, value] = singleQueryParam.split('=');
                    accumulator[key] = decodeURIComponent(value);
                    return accumulator;
                }, {});

            localStorage.setItem('SFDCData', JSON.stringify(queryParams));
        }
        this.setState({
            loading: false,
        });
    }

    render() {
        const currentValues = JSON.parse(localStorage.getItem('SFDCData'));

        console.log(currentValues);

        if (this.state.loading) {
            return (
                <Box>
                    <Text>Loading...</Text>
                </Box>
            );
        }
        return currentValues == null ? (
            <Redirect to="/" />
        ) : currentValues['type'] == 'create-customer' ? (
            <Redirect to="/search-results/create-customer" />
        ) : currentValues['type'] == 'other-request' ? (
            <Redirect to="/advance-search/other-request" />
        ) : (
            <Redirect to="/" />
        );
    }
}

export default SFDC;
