import React from 'react';
import {
    ScrollView,
    View,
    TouchableOpacity,
    ActivityIndicator,
    Image,
    CheckBox,
    StyleSheet,
    Dimensions,
} from 'react-native';
import {
    DimensionAware,
    getWindowHeight,
    getWindowWidth,
} from 'react-native-dimension-aware';
import { Colors, StyledText } from '../../../../theme/Index';
import { Flex, Column, Card, Button, Box, Text } from '../../../../components/common';
import FilesList from '../../../../components/FilesList.js';
import { FormInput, FormSelect } from '../../../../components/form';
import ProgressBarAnimated from 'react-native-progress-bar-animated';
import { saveApolloUpdateUpdateMyTaskContracts } from '../../../../appRedux/actions/UpdateFlowAction';
import { yupFieldValidation, yupAllFieldsValidation } from '../../../../constants/utils';
import { MaterialIcons } from '@expo/vector-icons';
import {
    getStatusBarData,
    getFunctionalGroupData,
} from '../../../../appRedux/actions/Workflow';

import GlobalMdmFields from '../../../../components/GlobalMdmFields';
import SystemFields from '../../../../components/SystemFields';
import { getCustomerFromSAP, getStaticRequest } from '../../../../appRedux/actions';
import {
    mdmFieldsRules,
    mytaskContractsRules,
    mytaskCustomerMasterRules,
    rejectRules,
} from '../../../../constants/FieldRules';
import {
    RoleType,
    SalesOrgType,
    SystemType,
    DistributionChannelType,
    DivisionType,
    CompanyCodeType,
} from '../../../../constants/WorkflowEnums';
import {
    fetchContractsDropDownData,
    fetchTeamRoleFieldMapping,
} from '../../../../redux/DropDownDatas';
import Loading from '../../../../components/Loading';
import FlashMessage from '../../../../components/FlashMessage';
import { connect } from 'react-redux';
import MultiColorProgressBar from '../../../../components/MultiColorProgressBar';
import DeltaField from '../../../../components/DeltaField';
import { getMockUpdateTaskDetail } from '../../../../appRedux/sagas/config';
import Modal from 'modal-enhanced-react-native-web';
import BreadCrumbs from '../../../../components/BreadCrumbs';
import idx from 'idx';
import DynamicSelect from '../../../../components/DynamicSelect';
import * as moment from 'moment';

class Page extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            WorkflowId: this.props.location.state.WorkflowId,
            TaskId: this.props.location.state.TaskId,
            data: {},
            reject: false,
            dropDownDatas: {},
            teamRoleFieldMapping: {},
            formData: {
                RejectionButton: false,
                RejectionReason: '',
                AdditionalNotes: '',
            },
            formErrors: {},
            inputPropsForDefaultRules: {},
            fileErrors: {},
            selectedFiles: {},
            selectedFilesIds: [],
            files: [],
            modalVisible: false,
            isReject: false,
            fileinputkey: Date.now(),
        };
    }

    componentDidMount() {
        let { state: wf } = this.props.location;
        let postJson = {
            workflowId: wf.WorkflowId,
            fuctionalGroup: 'contracts',
            taskId: wf.TaskId,
        };

        const { action, id } = this.props.match.params;

        this.props.getStatusBarData(postJson);
        this.props.getStaticRequest([15, 30, 17, 26, 42, 43, 82]);
        // this.props.getFunctionalGroupData(postJson);
        this.props.getCustomerFromSAP({
            WorkflowId: wf.WorkflowId,
            CustomerNumber: '',
            TaskId: wf.TaskId,
            DivisionTypeId: 0,
            SystemTypeId: 0,
            DistributionChannelTypeId: 0,
            CompanyCodeTypeId: '',
            SalesOrgTypeId: 0,
        });

        fetchContractsDropDownData(true, true).then(
            ({ normalizedDropDowns, dropdowns }) => {
                const data = normalizedDropDowns;
                let isReqPage = action === 'update-request';
                let pageReadOnlyFields = action !== 'update'; //set readonly for my-requests & extend sales org & BlockUnblock .

                this.setState({
                    dropDownDatas: data,
                    dropdowns: dropdowns,
                    readOnly: pageReadOnlyFields,
                    isRequestPage: isReqPage,
                    currentPage: action,
                    extendSalesOrgEnabled: action === 'extend-salesorg',
                });
            }
        );
        fetchTeamRoleFieldMapping().then((resp) => {
            const data = resp;
            this.setState({ teamRoleFieldMapping: data });
        });
    }

    componentWillReceiveProps(newProps) {
        let { action } = this.props.match.params;

        if (
            newProps.bapi70CustData !== this.props.bapi70CustData &&
            !this.state.isWorkFlowReadOnly &&
            newProps.bapi70CustData !== {}
        ) {
            const { bapi70CustData } = newProps;
            console.log(bapi70CustData);
            if (bapi70CustData.IncoTerms1TypeId) {
                this.validateIncoTerms(
                    bapi70CustData,
                    bapi70CustData.IncoTerms1TypeId.toString()
                );
            }
        }
    }

    setFormErrors = (errors) => {
        const { formErrors } = this.state;
        this.setState({ formErrors: errors }, () => window.scrollTo(0, 0));
    };

    onFieldChange = (value, e) => {
        const { name } = e.target;
        const { formData: prevFormData } = this.state;
        console.log(value, name, prevFormData);

        this.setState(
            {
                formData: {
                    ...this.state.formData,
                    [name]: value,
                },
                updatedFormData: {
                    ...this.state.updatedFormData,
                    [name]: value,
                },
            },
            () => {
                if (name === 'CustomerGroupTypeId' || name === 'IncoTerms1TypeId') {
                    this.validateRules(name, value);
                }
            }
        );
    };

    setFormDataValues = (name, value) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: value,
            },
        });
    };

    setInputPropsForDefaultRules = (field_name, property) => {
        this.setState({
            inputPropsForDefaultRules: {
                ...this.state.inputPropsForDefaultRules,
                [field_name]: property,
            },
        });
    };

    setBulkFormDataUpdate = (keyValdict) => {
        this.setState({
            formData: {
                ...this.state.formData,
                ...keyValdict,
            },
        });
    };

    // display or set input/dropdowns values based on rules
    validateRules = (stateKey, stateVal) => {
        const readOnlyInputprop = { inline: true, variant: 'outline' };
        const editInputProp = {
            inline: false,
            variant: 'solid',
            onChange: this.onFieldChange,
        };
        const readOnlyDropDown = { disabled: true };

        if (stateKey === 'IncoTerms1TypeId') {
            this.validateIncoTerms(this.props.bapi70CustData, stateVal);
        }

        // check for AccountTypeId  //ITMDMK1707 ALL EDITABLE
        if (stateKey === 'CustomerGroupTypeId') {
            var cg_val = stateVal;
            if (cg_val === '1' || cg_val === '10') {
                this.setFormDataValues('AccountTypeId', '1');
                this.setInputPropsForDefaultRules('AccountTypeId', { disabled: false });
            } else if (cg_val === '2' || cg_val === '7') {
                this.setFormDataValues('AccountTypeId', '2');
                this.setInputPropsForDefaultRules('AccountTypeId', { disabled: false });
            } else if (
                cg_val === '3' ||
                cg_val === '4' ||
                cg_val === '6' ||
                cg_val === '11'
            ) {
                this.setFormDataValues('AccountTypeId', '3');
                this.setInputPropsForDefaultRules('AccountTypeId', { disabled: false });
            } else if (cg_val === '8') {
                this.setFormDataValues('AccountTypeId', '6');
                this.setInputPropsForDefaultRules('AccountTypeId', { disabled: false });
            } else {
                this.setFormDataValues('AccountTypeId', '');
                this.setInputPropsForDefaultRules('AccountTypeId', {
                    disabled: false,
                });
            }
        }
    };

    validateIncoTerms = (bapi70CustData, incoValue) => {
        //inco2 editable for extend
        const readOnlyDropDown = { disabled: !extendSalesOrgEnabled };
        const extendSalesOrgEnabled = this.state.extendSalesOrgEnabled;

        var INCOT1_val = incoValue;
        if (INCOT1_val === '1') {
            this.setFormDataValues('displayINCOT2', true);
        } else {
            this.setFormDataValues('displayINCOT2', false);
        }

        if (INCOT1_val === '1') {
            this.setBulkFormDataUpdate({
                isRequiredINCOT2: true,
                isReadonlyINCOT2: false,
                IncoTerms2: bapi70CustData.IncoTerms2 || '',
            });
        } else if (INCOT1_val === '3') {
            this.setBulkFormDataUpdate({
                isRequiredINCOT2: false,
                isReadonlyINCOT2: !extendSalesOrgEnabled,
                IncoTerms2: 'Carriage Paid To',
            });
            this.setInputPropsForDefaultRules('IncoTerms2', readOnlyDropDown);
        } else if (INCOT1_val === '4') {
            this.setBulkFormDataUpdate({
                isRequiredINCOT2: false,
                isReadonlyINCOT2: !extendSalesOrgEnabled,
                IncoTerms2: 'Delivered at Location',
            });
            this.setInputPropsForDefaultRules('IncoTerms2', readOnlyDropDown);
        } else if (INCOT1_val === '5') {
            this.setBulkFormDataUpdate({
                isRequiredINCOT2: false,
                isReadonlyINCOT2: !extendSalesOrgEnabled,
                IncoTerms2: 'Delivered Duty Paid',
            });
            this.setInputPropsForDefaultRules('IncoTerms2', readOnlyDropDown);
        } else if (INCOT1_val === '7') {
            this.setBulkFormDataUpdate({
                isRequiredINCOT2: false,
                isReadonlyINCOT2: !extendSalesOrgEnabled,
                IncoTerms2: 'Ex Works',
            });
            this.setInputPropsForDefaultRules('IncoTerms2', readOnlyDropDown);
        } else if (INCOT1_val === '8') {
            this.setBulkFormDataUpdate({
                isRequiredINCOT2: false,
                isReadonlyINCOT2: !extendSalesOrgEnabled,
                IncoTerms2: 'Free Carrier',
            });
            this.setInputPropsForDefaultRules('IncoTerms2', readOnlyDropDown);
        } else {
            this.setBulkFormDataUpdate({
                isRequiredINCOT2: false,
                isReadonlyINCOT2: false,
                IncoTerms2: bapi70CustData.IncoTerms2 || '',
            });
            this.setInputPropsForDefaultRules('IncoTerms2', { disabled: false });
        }
    };

    validateFromSourceData = (source_data) => {
        const readOnlyDropDown = { disabled: true };
        const newStateValue = {},
            newStyleProps = {};

        //check Customer group -ITMDMK 1756
        if (source_data.CategoryTypeId != undefined) {
            let categoryTypeid = parseInt(source_data.CategoryTypeId);
            if (categoryTypeid === 2) {
                //if self-distributor
                newStateValue['CustomerGroupTypeId'] = '5';
                newStyleProps['CustomerGroupTypeId'] = { disabled: false }; // readOnlyDropDown;;;
            } else if (categoryTypeid === 3 || categoryTypeid === 6) {
                //if oem or kitter
                newStateValue['CustomerGroupTypeId'] = '9';
                newStyleProps['CustomerGroupTypeId'] = { disabled: false }; // readOnlyDropDown;;;
            } else if (categoryTypeid === 7) {
                // if dropship
                newStateValue['AccountTypeId'] = '3';
                newStyleProps['AccountTypeId'] = { disabled: false };
                newStateValue['CustomerGroupTypeId'] = '11';
                newStyleProps['CustomerGroupTypeId'] = { disabled: false }; // readOnlyDropDown;;;
            }
        }

        this.setState({
            formData: {
                ...this.state.formData,
                ...newStateValue,
            },
            inputPropsForDefaultRules: {
                ...this.state.inputPropsForDefaultRules,
                ...newStyleProps,
            },
        });
    };

    createDeltas = (origData, newData) => {
        let addedKeys = [];
        var denormDeltas = this.props.denormalizedDeltas || [];
        for (const key in newData) {
            //check if orig data & new data doesnt duplicate empty string or null
            let orgiDataValue =
                origData[key] != null
                    ? origData[key].length != 0
                        ? origData[key]
                        : ''
                    : '';

            let newDataValue =
                newData[key] != null
                    ? newData[key].length != 0
                        ? newData[key].toString().trim()
                        : ''
                    : '';

            if (orgiDataValue != newDataValue && !addedKeys.includes(key)) {
                let delta = {};
                delta['name'] = key;
                delta['originalValue'] = origData[key];
                delta['updatedValue'] = newData[key];
                addedKeys.push(key);
                denormDeltas.push(delta);
            }
        }
        return denormDeltas;
    };

    handleFormSubmission = (schema) => {
        const userId = localStorage.getItem('userId');
        let { action } = this.props.match.params;
        let {
                TaskId,
                WorkflowId,
                formData = {},
                selectedFiles,
                selectedFilesIds,
                updatedFormData = {},
            } = this.state,
            castedFormData = {},
            postData = {};
        const { bapi70CustData = {} } = this.props;

        const {
            RejectionReason: RejectReason = '',
            AdditionalNotes = '',
            ...updatedData
        } = updatedFormData;

        try {
            const WorkflowTaskModel = {
                RejectReason: formData['RejectionButton']
                    ? formData['RejectionReason']
                    : '',
                AdditionalNotes: formData['AdditionalNotes']
                    ? formData['AdditionalNotes']
                    : '',
                TaskId: TaskId,
                UserId: userId,
                WorkflowId: WorkflowId,
            };
            if (action === 'update') {
                WorkflowTaskModel['WorkflowTaskOperationType'] = !formData[
                    'RejectionButton'
                ]
                    ? 1
                    : 4;
            } else if (action === 'extend-salesorg') {
                WorkflowTaskModel['WorkflowTaskOperationType'] = !formData[
                    'RejectionButton'
                ]
                    ? 1
                    : 2;
            }
            if (!formData['RejectionButton']) {
                castedFormData = schema.cast({
                    bapi70CustData,
                    formData,
                });
            } else {
                castedFormData = formData;
            }
            delete castedFormData.RejectionButton;

            let delta = this.props.denormalizedDeltas || [];
            if (this.state.updatedFormData && Object.keys(updatedFormData).length !== 0)
                delta = this.createDeltas(bapi70CustData, updatedData);

            if (action === 'extend-salesorg') {
                let newdelta = {},
                    key = 'IncoTerms2';
                newdelta['name'] = key;
                newdelta['originalValue'] = bapi70CustData[key];
                newdelta['updatedValue'] = this.state.formData[key];
                if (
                    newdelta['updatedValue'] &&
                    newdelta['updatedValue'].trim().length > 0 &&
                    newdelta['originalValue'] !== newdelta['updatedValue']
                )
                    delta.push(newdelta);
            }

            postData['formdata'] = {
                WorkflowTaskModel,
                action,
                Deltas: delta,
            };

            console.log(postData);
            postData['files'] = selectedFilesIds.map((id) => selectedFiles[id]);
            // console.log('pos', postData);
            this.props.saveApolloUpdateUpdateMyTaskContracts(postData);
            this.resetForm();
            this.scrollToTop();
        } catch (error) {
            console.log(error, 'form validtion error');
        }
    };

    onSubmit = (event, reject, schema) => {
        const { action } = this.props.match.params;

        let { formData, selectedFilesIds, selectedFiles } = this.state;
        let fileErrors = {};
        let errors = false;
        selectedFilesIds.map((id) => {
            if (selectedFiles[id] && selectedFiles[id].DocumentType <= 0) {
                fileErrors[id] = 'Document Type Required for file';
                errors = true;
            }
        });

        this.setState({ fileErrors, isFileErrors: errors });

        const { teamRoleFieldMapping } = this.state;
        const { bapi70CustData } = this.props;
        const ContractTeamFields =
            idx(teamRoleFieldMapping, (_) => _.ContractTeamFields) || [];
        const RoleTypeId = bapi70CustData.RoleTypeId;
        const SystemTypeId = bapi70CustData.SystemType;
        const allowedFieldList = ContractTeamFields.filter(
            (item) => item.RoleTypeId === RoleTypeId && item.SystemTypeId === SystemTypeId
        );
        let allowedFields = [];
        let allowedData = {};
        if (allowedFieldList && allowedFieldList.length > 0) {
            allowedFields = allowedFieldList[0].Fields;
            allowedData = allowedFieldList[0];
        }

        let CustomerData = {
            IncoTerms2: '',
            ...this.state.formData,
            RoleTypeId: allowedData.RoleTypeId,
            Role: allowedData.Role,
            PaymentTermsTypeId:
                this.state.formData.PaymentTermsTypeId ||
                bapi70CustData.PaymentTermsTypeId,
        };

        allowedFields.forEach((field) => {
            console.log(field);
            // if (field !== 'IncoTerms2' && field !== 'PaymentTermsTypeId')
            CustomerData[field] = this.state.formData[field] || bapi70CustData[field];
        });

        console.log(allowedFields);
        console.log(CustomerData);

        this.setState(
            {
                modalVisible: false,
                formData: {
                    ...this.state.formData,
                    ...this.state.updatedFormData,
                    RejectionButton: reject,
                },
            },
            () => {
                console.log('from', this.state.formData);
                if (reject)
                    yupAllFieldsValidation(
                        this.state.formData,
                        schema,
                        this.handleFormSubmission,
                        this.setFormErrors
                    );
                else if (this.state.isFileErrors === false) {
                    if (action === 'extend-salesorg') {
                        yupAllFieldsValidation(
                            {
                                ...CustomerData,
                                RejectionButton: reject,
                                RejectionReason: '',
                            },
                            mytaskContractsRules,
                            (...rest) => {
                                if (this.state.isFileErrors === false)
                                    this.handleFormSubmission(...rest);
                            },
                            this.setFormErrors
                        );
                    } else {
                        this.handleFormSubmission(schema);
                    }
                }
            }
        );
    };

    _renderModalContent = () => (
        <Box
            my={4}
            mx="30%"
            backgroundColor="white"
            borderRadius="4px"
            borderColor="rgba(0, 0, 0, 0.1)">
            <Text my={3} fontWeight="regular" color="lightBlue" fontSize={24} pl={4}>
                Submit Workflow
            </Text>
            <Box px="0px" pb="22px" justifyContent="center">
                <Box alignItems="center" justifyContent="center">
                    {!this.state.isReject ? (
                        <FormInput
                            label="Additional Notes"
                            multiline
                            numberOfLines={2}
                            maxLength={250}
                            name="AdditionalNotes"
                            type="text"
                            onChange={this.onFieldChange}
                            error={
                                this.state.formErrors
                                    ? this.state.formErrors['AdditionalNotes']
                                    : null
                            }
                            value={
                                this.state.formData &&
                                this.state.formData['AdditionalNotes']
                            }
                            variant={'solid'}
                        />
                    ) : (
                        <FormInput
                            label="Rejection Reason"
                            name="RejectionReason"
                            maxLength={250}
                            onChange={this.onFieldChange}
                            error={' *this field is required'}
                            multiline
                            numberOfLines={2}
                            type="text"
                            value={
                                this.state.formData &&
                                this.state.formData['RejectionReason']
                            }
                            variant="solid"
                        />
                    )}
                </Box>
                <Flex
                    justifyEnd
                    alignCenter
                    alignSelf="flex-end"
                    style={{
                        paddingTop: 15,
                        flexDirection: 'row',
                        alignItems: 'center',
                        paddingLeft: 10,
                        paddingRight: 15,
                        marginTop: 20,
                        marginBottom: 10,
                        marginHorizontal: 25,
                    }}>
                    <Button
                        onPress={(event) => {
                            if (this.state.isReject)
                                this.setState(
                                    {
                                        isReject: false,
                                    },
                                    () => this.onSubmit(event, true, rejectRules)
                                );
                            else this.onSubmit(event, false, mytaskContractsRules);
                        }}
                        title="Submit"
                    />
                    <Button
                        title="Cancel"
                        onPress={() => this.setState({ modalVisible: null })}
                    />
                </Flex>
            </Box>
        </Box>
    );

    scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    resetForm = () => {
        Object.keys(this.state.formData).map((key) => {
            this.setState({
                formData: {
                    [key]: '',
                },
            });
        });
        Object.keys(this.state.formErrors).map((key) => {
            this.setState({
                formErrors: {
                    [key]: '',
                },
            });
        });
        //restore initial values
        this.setState({
            formData: { RejectionButton: false },
        });
    };

    selectFiles = (events) => {
        event.preventDefault();

        const { selectedFilesIds, selectedFiles } = this.state;
        const id = events.target.files[0].name;

        this.setState({
            selectedFiles: {
                ...selectedFiles,
                [id]: {
                    data: events.target.files[0],
                    DocumentName: events.target.files[0].name,
                    DocumentType: 0,
                },
            },
            selectedFilesIds: [...selectedFilesIds, id],
            filename: events.target.files[0].name,
            fileinputkey: Date.now(),
        });
    };

    removeFile = (id) => {
        event.preventDefault();

        var selectedFilesDict = this.state.selectedFiles;
        var selectedFilesIdsList = this.state.selectedFilesIds;
        // remove the existing state
        delete selectedFilesDict[id];
        selectedFilesIdsList = selectedFilesIdsList.filter((fileid) => fileid !== id);

        this.setState({
            selectedFiles: selectedFilesDict,
            selectedFilesIds: selectedFilesIdsList,
        });
    };

    render() {
        const {
            width,
            location,
            bapi70CustData,
            deltas = {},
            statusBarData,
            TasksStatusByTeamId = null,
            alert = {},
        } = this.props;

        const Deltas = deltas;

        const {
            dropDownDatas,
            dropdowns = {},
            teamRoleFieldMapping,
            inputPropsForDefaultRules,
            selectedFilesIds,
            selectedFiles,
        } = this.state;

        const {
            WorkflowDocuments: files = [],
            RequestorName,
        } = this.props.bapi70CustData;

        const { state } = location;

        const ContractTeamFields =
            idx(teamRoleFieldMapping, (_) => _.ContractTeamFields) || [];
        const RoleTypeId = bapi70CustData.RoleTypeId;
        const SystemTypeId = bapi70CustData.SystemType;
        const allowedFieldList = ContractTeamFields.filter(
            (item) => item.RoleTypeId === RoleTypeId && item.SystemTypeId === SystemTypeId
        );
        let allowedFields = undefined;
        if (allowedFieldList && allowedFieldList.length > 0) {
            allowedFields = allowedFieldList[0].Fields;
        }

        const workflow = {
            ...state,
            isReadOnly:
                TasksStatusByTeamId === null ||
                !(
                    state.WorkflowStateType === 2 &&
                    TasksStatusByTeamId[5].WorkflowTaskStateTypeId === 2
                ),
        };

        const requestDateUtc = moment(idx(bapi70CustData, (_) => _.DateOfRequest));
        const localDate = moment(requestDateUtc).local();
        const requestDate = localDate.format('YYYY-MM-DD hh:mm:ss A');

        const inputReadonlyProps = workflow.isReadOnly ? { disabled: true } : null;

        const showFunctionalDetail =
            state.isReadOnly && bapi70CustData === null ? { display: 'none' } : null;

        const showButtons = workflow.isReadOnly ? { display: 'none' } : null;

        const inputProps = {
            variant: 'outline',
            inline: true,
            type: 'text',
        };
        let { action } = this.props.match.params;
        var bgcolor = alert.color || '#FFF';
        if (this.props.fetching) {
            return <Loading />;
        }

        let extendSalesOrgEnabled =
            this.state.extendSalesOrgEnabled && !workflow.isReadOnly;

        const pageProps = workflow.isReadOnly
            ? inputProps
            : {
                  inline: false,
                  readOnly: false,
                  onBlur: this.props.onFieldChange,
              };

        const category = bapi70CustData ? bapi70CustData.CategoryTypeId : null;
        const paymentTermsEditable =
            category === 1 || category === 2 || category === 3 || category === 6;

        return (
            <ScrollView
                keyboardShouldPersistTaps="always"
                style={{
                    backgroundColor: '#EFF3F6',
                    paddingBottom: 75,
                }}>
                <Modal isVisible={this.state.modalVisible}>
                    {this._renderModalContent()}
                </Modal>
                {alert.display && (
                    <FlashMessage
                        bg={{ backgroundColor: bgcolor }}
                        message={alert.message}
                    />
                )}

                <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between">
                    <Text
                        style={{
                            fontWeight: '500',
                            fontSize: 24,
                            color: Colors.lightBlue,
                            marginTop: 25,
                            marginBottom: 20,
                            paddingLeft: 45,
                        }}>
                        MY TASKS - {state.Header}
                    </Text>
                    <Box
                        marginTop="25px"
                        flex={3 / 4}
                        flexDirection="row-reverse"
                        alignItems="flex-end">
                        <MultiColorProgressBar readings={statusBarData} />
                    </Box>
                </Box>
                <Box marginTop="25px" flexDirection="row-reverse" alignItems="flex-end">
                    <StyledText
                        style={{
                            fontWeight: '600',
                            paddingRight: '20px',
                            fontSize: '16px',
                            color: '#10206C',
                        }}>
                        {' '}
                        Requestor : {RequestorName}{' '}
                    </StyledText>
                </Box>
                <Box flexDirection="row-reverse" alignItems="flex-end">
                    <StyledText
                        style={{
                            fontWeight: '600',
                            paddingRight: '20px',
                            fontSize: '16px',
                            color: '#10206C',
                        }}>
                        {' '}
                        Date of Request: {requestDate}{' '}
                    </StyledText>
                </Box>
                <View
                    style={{
                        flex: 1,
                        paddingHorizontal: width < 1440 ? 60 : width * 0.1,
                        paddingBottom: 10,
                    }}>
                    <Box fullHeight my={2}>
                        <Box flexDirection="row" justifyContent="space-around" my={4}>
                            <FormInput
                                px="25px"
                                flex={1 / 4}
                                label="Title"
                                name="WorkflowTitle"
                                maxLength={40}
                                variant="outline"
                                type="text"
                                value={bapi70CustData && bapi70CustData.WorkflowTitle}
                            />

                            <FormInput
                                px="25px"
                                flex={1 / 4}
                                label="Workflow Number"
                                name="WorkflowId"
                                variant="outline"
                                type="text"
                                value={state && state.WorkflowId}
                            />
                            {bapi70CustData && bapi70CustData.SystemRecordId && (
                                <FormInput
                                    px="25px"
                                    flex={1 / 4}
                                    label="Customer Number"
                                    name="CustomerNumber"
                                    variant="outline"
                                    type="text"
                                    value={
                                        bapi70CustData && bapi70CustData.SystemRecordId
                                    }
                                />
                            )}
                            {bapi70CustData && bapi70CustData.MdmNumber && (
                                <TouchableOpacity
                                    onPress={() =>
                                        this.props.history.push(
                                            `/search-results/${
                                                bapi70CustData && bapi70CustData.MdmNumber
                                            }`
                                        )
                                    }>
                                    <FormInput
                                        px="25px"
                                        flex={1 / 4}
                                        label="MDM Number"
                                        name="MdmNumber"
                                        variant="outline"
                                        type="text"
                                        value={bapi70CustData && bapi70CustData.MdmNumber}
                                    />
                                </TouchableOpacity>
                            )}
                        </Box>
                        <GlobalMdmFields
                            formData={bapi70CustData}
                            readOnly
                            deltas={Deltas}
                            staticDropDownData={this.props.staticData}
                        />

                        <Text
                            mt="42px"
                            mb="20px"
                            fontWeight="regular"
                            color="lightBlue"
                            fontSize={24}
                            pl={4}>
                            SYSTEM FIELDS
                        </Text>
                        <Box flexDirection="row" justifyContent="center">
                            <Box width={1 / 2} mx="auto" alignItems="center">
                                <FormInput
                                    label="System"
                                    name="System"
                                    delta={deltas['SystemType']}
                                    value={
                                        SystemType[
                                            bapi70CustData && bapi70CustData.SystemType
                                        ]
                                    }
                                    {...inputProps}
                                />
                                <FormInput
                                    label="Role"
                                    name="Role"
                                    delta={deltas['RoleTypeId']}
                                    value={
                                        RoleType[
                                            bapi70CustData && bapi70CustData.RoleTypeId
                                        ]
                                    }
                                    {...inputProps}
                                />
                                <FormInput
                                    label="Sales Org"
                                    name="SalesOrg"
                                    delta={deltas['SalesOrgTypeId']}
                                    value={
                                        SalesOrgType[
                                            bapi70CustData &&
                                                bapi70CustData.SalesOrgTypeId
                                        ]
                                    }
                                    {...inputProps}
                                />
                                {action === 'extend-salesorg' ? (
                                    <FormInput
                                        label="Target Sales Org"
                                        name="TargetSalesOrg"
                                        team="system"
                                        inline
                                        variant="outline"
                                        type="text"
                                        value={
                                            SalesOrgType[
                                                bapi70CustData &&
                                                    bapi70CustData.TargetSalesOrgTypeId
                                            ]
                                        }
                                    />
                                ) : null}
                            </Box>
                            <Box width={1 / 2} mx="auto" alignItems="center">
                                <FormInput
                                    label="Distribution Channel"
                                    name="DistributionChannel"
                                    delta={deltas['DistributionChannelTypeId']}
                                    value={
                                        DistributionChannelType[
                                            bapi70CustData &&
                                                bapi70CustData.DistributionChannelTypeId
                                        ]
                                    }
                                    {...inputProps}
                                />
                                <FormInput
                                    label="Division"
                                    name="DivisionTypeId"
                                    delta={deltas['DivisionTypeId']}
                                    value={
                                        DivisionType[
                                            bapi70CustData &&
                                                bapi70CustData.DivisionTypeId
                                        ]
                                    }
                                    {...inputProps}
                                />
                                <FormInput
                                    label="CompanyCode"
                                    name="CompanyCodeTypeId"
                                    delta={deltas['CompanyCodeTypeId']}
                                    value={
                                        CompanyCodeType[
                                            bapi70CustData &&
                                                bapi70CustData.CompanyCodeTypeId
                                        ]
                                    }
                                    {...inputProps}
                                />
                                <FormInput
                                    label="Purpose of Request"
                                    name="Purpose"
                                    value={
                                        (bapi70CustData && bapi70CustData.Purpose) ||
                                        (Deltas['Purpose'] &&
                                            Deltas['Purpose'].UpdatedValue)
                                    }
                                    multiline
                                    numberOfLines={
                                        bapi70CustData.Purpose &&
                                        bapi70CustData.Purpose.length > 36
                                            ? 3
                                            : 1
                                    }
                                    style={{ lineHeight: '2.5' }}
                                    inline
                                    variant="outline"
                                    maxLength={1000}
                                    type="text"
                                />
                            </Box>
                        </Box>

                        <Box {...showFunctionalDetail}>
                            <Text
                                mt="42px"
                                mb="20px"
                                fontWeight="regular"
                                color="lightBlue"
                                fontSize={24}
                                pl={4}>
                                CONTRACT FIELDS
                            </Text>
                            <Box flexDirection="row" justifyContent="center">
                                <Box width={1 / 2} mx="auto" alignItems="center">
                                    {Deltas['PaymentTermsTypeId'] ? (
                                        <DeltaField
                                            delta={Deltas['PaymentTermsTypeId']}
                                        />
                                    ) : (
                                        <DynamicSelect
                                            readOnly={
                                                !extendSalesOrgEnabled ||
                                                !paymentTermsEditable
                                            }
                                            team="credit"
                                            arrayOfData={
                                                (this.props.staticData &&
                                                    this.props.staticData
                                                        .ApolloPaymentTermsType) ||
                                                []
                                            }
                                            label="Payment Terms "
                                            name="PaymentTermsTypeId"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes(
                                                    'PaymentTermsTypeId'
                                                )
                                            }
                                            value={
                                                this.state.formData.PaymentTermsTypeId ||
                                                bapi70CustData.PaymentTermsTypeId
                                            }
                                            isRequired={paymentTermsEditable}
                                            formErrors={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                          'PaymentTermsTypeId'
                                                      ]
                                                    : null
                                            }
                                            onFieldChange={this.onFieldChange}
                                        />
                                    )}
                                    {Deltas['AccountTypeId'] ? (
                                        <DeltaField
                                            label="Account Type"
                                            delta={Deltas['AccountTypeId']}
                                        />
                                    ) : (
                                        <DynamicSelect
                                            readOnly
                                            arrayOfData={dropdowns.AccountTypeId}
                                            label="Account Type"
                                            name="AccountTypeId"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes('AccountTypeId')
                                            }
                                            value={
                                                this.state.formData.AccountTypeId ||
                                                (bapi70CustData &&
                                                    bapi70CustData.AccountTypeId)
                                            }
                                            isRequired
                                            formErrors={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                          'AccountTypeId'
                                                      ]
                                                    : null
                                            }
                                            onFieldChange={this.onFieldChange}
                                        />
                                    )}
                                </Box>
                                <Box width={1 / 2} mx="auto" alignItems="center">
                                    {Deltas['IncoTerms1TypeId'] ? (
                                        <DeltaField
                                            delta={Deltas['IncoTerms1TypeId']}
                                            label="Incoterms 1"
                                        />
                                    ) : (
                                        <DynamicSelect
                                            readOnly={!extendSalesOrgEnabled}
                                            arrayOfData={
                                                (this.props.staticData &&
                                                    this.props.staticData
                                                        .ApolloIncoTermsType) ||
                                                []
                                            }
                                            label="Incoterms 1"
                                            name="IncoTerms1TypeId"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes(
                                                    'IncoTerms1TypeId'
                                                )
                                            }
                                            value={
                                                this.state.formData.IncoTerms1TypeId ||
                                                (bapi70CustData &&
                                                    bapi70CustData.IncoTerms1TypeId)
                                            }
                                            isRequired
                                            formErrors={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                          'IncoTerms1TypeId'
                                                      ]
                                                    : null
                                            }
                                            onFieldChange={this.onFieldChange}
                                        />
                                    )}

                                    {Deltas['IncoTerms2'] ? (
                                        <DeltaField
                                            delta={Deltas['IncoTerms2']}
                                            label="Incoterms 2"
                                        />
                                    ) : (
                                        <FormInput
                                            readOnly={!extendSalesOrgEnabled}
                                            label="IncoTerms 2"
                                            name="IncoTerms2"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes('IncoTerms2')
                                            }
                                            maxLength={28}
                                            value={
                                                this.state.formData
                                                    ? this.state.formData['IncoTerms2']
                                                    : bapi70CustData &&
                                                      bapi70CustData.IncoTerms2
                                            }
                                            onChange={this.onFieldChange}
                                            error={
                                                this.state.formErrors
                                                    ? this.state.formErrors['IncoTerms2']
                                                    : null
                                            }
                                            type="text"
                                            {...(extendSalesOrgEnabled
                                                ? pageProps
                                                : inputProps)}
                                            variant={
                                                this.state.formData.isReadonlyINCOT2 ||
                                                workflow.isReadOnly
                                                    ? 'outline'
                                                    : 'solid'
                                            }
                                            required={
                                                this.state.formData.isRequiredINCOT2
                                            }
                                            showInlineRequired={
                                                this.state.formData.isRequiredINCOT2
                                            }
                                        />
                                    )}

                                    {Deltas['CustomerGroupTypeId'] ? (
                                        <DeltaField
                                            delta={Deltas['CustomerGroupTypeId']}
                                        />
                                    ) : (
                                        <DynamicSelect
                                            readOnly={!extendSalesOrgEnabled}
                                            arrayOfData={dropdowns.CustomerGroupTypeId}
                                            label="Customer Group"
                                            name="CustomerGroupTypeId"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes(
                                                    'CustomerGroupTypeId'
                                                )
                                            }
                                            team="contracts"
                                            isRequired={true}
                                            formErrors={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                          'CustomerGroupTypeId'
                                                      ]
                                                    : null
                                            }
                                            onFieldChange={this.onFieldChange}
                                            value={
                                                this.state.formData[
                                                    'CustomerGroupTypeId'
                                                ] || bapi70CustData.CustomerGroupTypeId
                                            }
                                            inputProps={
                                                action === 'extend-salesorg'
                                                    ? inputReadonlyProps
                                                    : inputPropsForDefaultRules[
                                                          'CustomerGroupTypeId'
                                                      ]
                                            }
                                        />
                                    )}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    {files && <FilesList files={files} readOnly />}
                    {workflow.isReadOnly ? (
                        <Box my={4}>
                            <Box flexDirection="row" justifyContent="center">
                                {bapi70CustData && bapi70CustData.RejectionReason && (
                                    <>
                                        <Box width={1 / 2} mx="auto" alignItems="center">
                                            <FormInput
                                                label="Notes"
                                                name="RejectionReason"
                                                inputWidth="100%"
                                                required
                                                onChange={this.onFieldChange}
                                                error={
                                                    this.state.formErrors
                                                        ? this.state.formErrors[
                                                              'RejectionReason'
                                                          ]
                                                        : null
                                                }
                                                multiline
                                                numberOfLines={2}
                                                type="text"
                                                value={bapi70CustData.RejectionReason}
                                                variant={'outline'}
                                                inline={
                                                    workflow.isReadOnly ? true : false
                                                }
                                            />
                                        </Box>
                                        <Box
                                            width={1 / 2}
                                            mx="auto"
                                            alignItems="center"
                                        />
                                    </>
                                )}
                            </Box>
                        </Box>
                    ) : (
                        <FilesList
                            title="NEW ATTACHMENTS"
                            formErrors={this.state.fileErrors}
                            files={selectedFilesIds.map((id) => selectedFiles[id])}
                            onChange={(value, id) => {
                                this.setState({
                                    selectedFiles: {
                                        ...selectedFiles,
                                        [id]: {
                                            ...selectedFiles[id],
                                            DocumentType: parseInt(value),
                                        },
                                    },
                                });
                            }}
                            onRemove={(value, id) => this.removeFile(id)}
                        />
                    )}
                    <Box {...showButtons}>
                        <Flex
                            justifyEnd
                            alignCenter
                            style={{
                                paddingTop: 15,
                                flexDirection: 'row',
                                alignItems: 'center',
                                paddingLeft: 10,
                                paddingRight: 15,
                                marginTop: 20,
                                marginBottom: 10,
                                marginHorizontal: 25,
                            }}>
                            <label htmlFor="file-upload" className="custom-file-upload">
                                <MaterialIcons
                                    name="attach-file"
                                    size={18}
                                    color="#fff"
                                />
                            </label>
                            <input
                                id="file-upload"
                                type="file"
                                key={this.state.fileinputkey}
                                onChange={this.selectFiles}
                                multiple
                            />

                            <Button
                                onPress={(event) =>
                                    this.setState({ isReject: false, modalVisible: true })
                                }
                                title="Approve"
                            />
                            <Button
                                title="Reject"
                                onPress={(event) =>
                                    this.setState({ isReject: true, modalVisible: true })
                                }
                            />
                        </Flex>
                    </Box>
                </View>
            </ScrollView>
        );
    }
}

class Default extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const props = this.props;
        return (
            <DimensionAware
                render={(dimensions) => (
                    <Page
                        {...{
                            ...props,
                            width: getWindowWidth(dimensions),
                            height: getWindowHeight(dimensions),
                            marginBottom: 25,
                        }}
                    />
                )}
            />
        );
    }
}

const mapStateToProps = ({ workflows, myTasks, updateFlow, customer, staticData }) => {
    const {
        bapi70CustData,
        deltas,
        denormalizedDeltas,
        fetching: fetchingCustomer,
    } = customer;
    const { fetching: fetchingUpdateflow } = updateFlow;
    const { fetching, alert } = myTasks;
    const {
        fetchingfnGroupData,
        statusBarData,
        functionalGroupDetails,
        TasksStatusByTeamId,
        fetchingStatusBar,
    } = workflows;
    const { data, fetching: staticFetching } = staticData;

    return {
        fetchingfnGroupData,
        fetching:
            fetching ||
            fetchingStatusBar ||
            fetchingfnGroupData ||
            fetchingCustomer ||
            fetchingUpdateflow ||
            staticFetching,
        alert,
        statusBarData,
        TasksStatusByTeamId,
        functionalGroupDetails,
        bapi70CustData,
        deltas,
        denormalizedDeltas,
        staticData: data,
    };
};

export default connect(mapStateToProps, {
    saveApolloUpdateUpdateMyTaskContracts,
    getFunctionalGroupData,
    getStatusBarData,
    getCustomerFromSAP,
    getStaticRequest,
})(Default);

const styles = StyleSheet.create({
    progressIndicator: {
        flex: 1,
        paddingBottom: 5,
        flexDirection: 'row-reverse',
        alignItems: 'flex-end',
    },
});
