import React from 'react';
import {
    ScrollView,
    View,
    TouchableOpacity,
    ActivityIndicator,
    Image,
    CheckBox,
    StyleSheet,
    Dimensions,
} from 'react-native';
import {
    DimensionAware,
    getWindowHeight,
    getWindowWidth,
} from 'react-native-dimension-aware';
import { Colors, StyledText } from '../../../theme/Index';
import { Flex, Column, Card, Button, Box, Text, Label } from '../../../components/common';
import { FormInput, FormSelect, Wrapper } from '../../../components/form';
import ProgressBarAnimated from 'react-native-progress-bar-animated';
import GlobalMdmFields from '../../../components/GlobalMdmFields';
import DynamicSelect from '../../../components/DynamicSelect';
import { CheckBoxItem } from '../../../components/CheckBoxItem';
import debounce from 'lodash.debounce';
import {
    resolveDependencies,
    passFields,
    parseAndSanitise,
    yupAllFieldsValidation,
} from '../../../constants/utils';
import {
    FieldValidationRoleType,
    mytaskCustomerMasterRules,
    rejectRules,
} from '../../../constants/FieldRules';
import { fetchDuns } from '../../../appRedux/actions/Customer';
import { saveApolloMyTaskCustomerMaster } from '../../../appRedux/actions/MyTasks';
import { connect } from 'react-redux';
import {
    fetchCustomerMasterDropDownData,
    fetchTeamRoleFieldMapping,
} from '../../../redux/DropDownDatas';
import Loading from '../../../components/Loading';
import FlashMessage, { FlashMessages } from '../../../components/FlashMessage';
import {
    RoleType,
    SalesOrgType,
    SystemType,
    DistributionChannelType,
    DivisionType,
    CompanyCodeType,
} from '../../../constants/WorkflowEnums';
import MultiColorProgressBar from '../../../components/MultiColorProgressBar';
import {
    getStatusBarData,
    getFunctionalGroupData,
} from '../../../appRedux/actions/Workflow';
import BreadCrumbs from '../../../components/BreadCrumbs';
import Modal from 'modal-enhanced-react-native-web';
import * as moment from 'moment';
import FilesList from '../../../components/FilesList';
import { MaterialIcons } from '@expo/vector-icons';
import { removeMessage, getStaticRequest } from '../../../appRedux/actions';
import idx from 'idx';

class Page extends React.Component {
    constructor(props) {
        super(props);
        const editableProp = {
            inline: false,
            variant: 'solid',
            onChange: this.onFieldChange,
        };
        let { state: workflow } = this.props.location;
        let isWorkFlowReadOnly = this.props.location.state.isReadOnly;
        // let isWorkFlowReadOnly=false
        this.state = {
            isWorkFlowReadOnly: this.props.location.state.isReadOnly,
            WorkflowId: this.props.location.state.WorkflowId,
            TaskId: this.props.location.state.TaskId,
            loading: this.props.fetching,
            functionalGroupDetails: this.props.functionalGroupDetails,
            dropDownDatas: {},
            teamRoleFieldMapping: {},
            formData: {
                RejectionButton: false,
                displayINCOT2: isWorkFlowReadOnly ? true : false,
                display_LN: isWorkFlowReadOnly ? true : false,
                PaymentHistoryRecord: true,
                OrderCombination: false,
                TaxClassificationTypeId: 2,
                AccountStatementTypeId: 2,
                SortKey: '009',
                PaymentMethods: 'C',
                AcctgClerk: '01',
            },

            formErrors: {},
            inputPropsForDefaultRules: { CustomerGroupTypeId: editableProp },
            selectedFiles: {},
            selectedFilesIds: [],
            files: [],
            fileinputkey: Date.now(),
        };

        this.fetchDunsFields = debounce(this.props.fetchDuns, 200);
    }

    componentDidMount() {
        let { state: wf } = this.props.location;
        this.props.getStaticRequest([42, 43, 82]);
        let postJson = {
            workflowId: wf.WorkflowId,
            fuctionalGroup: 'customermaster',
            taskId: wf.TaskId,
        };
        this.props.getStatusBarData(postJson);
        this.props.getFunctionalGroupData(postJson);
        fetchCustomerMasterDropDownData().then((res) => {
            const data = res;
            this.setState({ dropDownDatas: data });
        });
        fetchTeamRoleFieldMapping().then((resp) => {
            const data = resp;
            this.setState({ teamRoleFieldMapping: data });
        });
    }

    componentWillReceiveProps(newProps) {
        if (
            newProps.functionalGroupDetails != this.props.functionalGroupDetails &&
            !this.state.isWorkFlowReadOnly
        ) {
            var customerdata = newProps.functionalGroupDetails.Customer;

            this.setState({
                formData: {
                    ...this.state.formData,
                    Role:
                        FieldValidationRoleType[
                            newProps.functionalGroupDetails.Customer.RoleTypeId
                        ],
                },
                inputPropsForDefaultRules: {
                    ...this.state.inputPropsForDefaultRules,
                },
            });
        }
    }

    setFormErrors = (errors) => {
        const { formErrors } = this.state;
        this.setState({ formErrors: errors }, () => window.scrollTo(0, 0));
    };

    onFieldChange = (value, e) => {
        const { name } = e.target;
        const { formData: prevFormData } = this.state;

        if (name === 'DunsNumber' && value.length > 0) {
            const { isDunsValid } = this.state;
            const {
                validity: { valid },
            } = e.target;

            if (valid !== isDunsValid)
                this.setState({
                    isDunsValid: valid,
                    formErrors: {
                        ...this.state.formErrors,
                        isDunsValid: !valid ? ' only numbers (0-9) are allowed.' : null,
                    },
                });
        } else if (value.length <= 0 && this.state.formErrors['isDunsValid']) {
            this.setState({
                formErrors: {
                    ...this.state.formErrors,
                    isDunsValid: null,
                },
            });
        }

        this.setState(
            {
                formData: {
                    ...this.state.formData,
                    [name]: value,
                },
            },
            () => {
                if (
                    name === 'CustomerClassTypeId' ||
                    name === 'IncoTerms1TypeId' ||
                    name === 'CustomerGroupTypeId'
                ) {
                    this.validateRules(name, value);
                } else if (
                    name === 'DunsNumber' &&
                    prevFormData &&
                    prevFormData.DunsNumber !== value
                ) {
                    this.fetchDunsFields({
                        UserId: localStorage.getItem('userId'),
                        DunsNumber: value,
                    });
                }
                //remove from formData if TypeId is set to 0 OR ''
                if (name.includes('TypeId') && parseInt(value) === 0) {
                    delete this.state.formData[name];
                }
            }
        );
    };

    parseAndHandleFieldChange = (value, e) => {
        const { name } = e.target;
        const val = parseAndSanitise(value, 10);
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: val,
            },
        });
    };

    setBulkFormDataUpdate = (keyValdict) => {
        this.setState({
            formData: {
                ...this.state.formData,
                ...keyValdict,
            },
        });
    };
    setFormDataValues = (name, value) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: value,
            },
        });
    };

    setInputPropsForDefaultRules = (field_name, property) => {
        this.setState({
            inputPropsForDefaultRules: {
                ...this.state.inputPropsForDefaultRules,
                [field_name]: property,
            },
        });
    };
    // display or set input/dropdowns values based on rules
    validateRules = (stateKey, stateVal) => {
        const readOnlyInputprop = { inline: true, variant: 'outline' };
        const editInputProp = {
            inline: false,
            variant: 'solid',
            onChange: this.onFieldChange,
        };
        const readOnlyDropDown = { disabled: true };
        // check for CustomerPriceProcTypeId
        if (stateKey === 'CustomerClassTypeId') {
            var CC_val = stateVal;
            if (['1', '2', '3', '4', '5'].includes(CC_val)) {
                this.setFormDataValues('CustomerPriceProcTypeId', 2);
                this.setInputPropsForDefaultRules(
                    'CustomerPriceProcTypeId',
                    readOnlyDropDown
                );
            } else {
                this.setFormDataValues('CustomerPriceProcTypeId', '');
                this.setInputPropsForDefaultRules('CustomerPriceProcTypeId', {
                    disabled: false,
                });
            }
        }
        // check for incoterms2
        if (stateKey === 'IncoTerms1TypeId') {
            var INCOT1_val = stateVal;
            if (INCOT1_val === '1') {
                this.setBulkFormDataUpdate({
                    isRequiredINCOT2: true,
                    isReadonlyINCOT2: false,
                    IncoTerms2: '',
                });
                this.setInputPropsForDefaultRules('IncoTerms2', { disabled: false });
            } else if (INCOT1_val === '3') {
                this.setBulkFormDataUpdate({
                    isRequiredINCOT2: false,
                    isReadonlyINCOT2: true,
                    IncoTerms2: 'Carriage Paid To',
                });
                this.setInputPropsForDefaultRules('IncoTerms2', readOnlyDropDown);
            } else if (INCOT1_val === '4') {
                this.setBulkFormDataUpdate({
                    isRequiredINCOT2: false,
                    isReadonlyINCOT2: true,
                    IncoTerms2: 'Delivered at Location',
                });
                this.setInputPropsForDefaultRules('IncoTerms2', readOnlyDropDown);
            } else if (INCOT1_val === '5') {
                this.setBulkFormDataUpdate({
                    isRequiredINCOT2: false,
                    isReadonlyINCOT2: true,
                    IncoTerms2: 'Delivered Duty Paid',
                });
                this.setInputPropsForDefaultRules('IncoTerms2', readOnlyDropDown);
            } else if (INCOT1_val === '7') {
                this.setBulkFormDataUpdate({
                    isRequiredINCOT2: false,
                    isReadonlyINCOT2: true,
                    IncoTerms2: 'Ex Works',
                });
                this.setInputPropsForDefaultRules('IncoTerms2', readOnlyDropDown);
            } else if (INCOT1_val === '8') {
                this.setBulkFormDataUpdate({
                    isRequiredINCOT2: false,
                    isReadonlyINCOT2: true,
                    IncoTerms2: 'Free Carrier',
                });
                this.setInputPropsForDefaultRules('IncoTerms2', readOnlyDropDown);
            } else {
                this.setBulkFormDataUpdate({
                    isRequiredINCOT2: false,
                    isReadonlyINCOT2: false,
                    IncoTerms2: '',
                });
                this.setInputPropsForDefaultRules('IncoTerms2', { disabled: false });
            }
        }
        // check for AccountTypeId
        if (stateKey === 'CustomerGroupTypeId') {
            var cg_val = stateVal;
            if (cg_val === '1' || cg_val === '10') {
                this.setFormDataValues('AccountTypeId', '1');
                this.setInputPropsForDefaultRules('AccountTypeId', readOnlyDropDown);
            } else if (cg_val === '2' || cg_val === '7') {
                this.setFormDataValues('AccountTypeId', '2');
                this.setInputPropsForDefaultRules('AccountTypeId', readOnlyDropDown);
            } else if (
                cg_val === '3' ||
                cg_val === '4' ||
                cg_val === '6' ||
                cg_val === '11'
            ) {
                this.setFormDataValues('AccountTypeId', '3');
                this.setInputPropsForDefaultRules('AccountTypeId', readOnlyDropDown);
            } else if (cg_val === '8') {
                this.setFormDataValues('AccountTypeId', '6');
                this.setInputPropsForDefaultRules('AccountTypeId', readOnlyDropDown);
            } else {
                this.setFormDataValues('AccountTypeId', '');
                this.setInputPropsForDefaultRules('AccountTypeId', {
                    disabled: false,
                });
            }
        }
    };

    validateFromSourceData = (source_data) => {
        const readOnlyDropDown = { disabled: true };
        const newStateValue = {},
            newStyleProps = {};
        //check License Number
        let d_LN_RegionsList = [
            'DE',
            'FL',
            'GA',
            'HI',
            'IL',
            'IN',
            'KS',
            'MA',
            'ME',
            'MN',
            'NC',
            'ND',
            'NE',
            'NM',
            'OH',
            'OK',
            'RI',
            'SD',
            'VT',
            'WA',
            'WV',
        ];
        if (
            source_data.RoleTypeId === 1 ||
            source_data.RoleTypeId === 2 ||
            source_data.RoleTypeId === 5 ||
            source_data.RoleTypeId === 6
        ) {
            newStateValue['display_LN'] = true;
            if (source_data.RoleTypeId === 5) {
                newStateValue['License'] = 'R-SALES REP EXEMPT';
                newStateValue['LicenseExpDate'] = '9999-12-31';
            } else if (source_data.Country != 'US') {
                newStateValue['License'] = 'I-INTERNATIONAL EXEMPT';
                newStateValue['LicenseExpDate'] = '9999-12-31';
            } else if (d_LN_RegionsList.includes(source_data.Region)) {
                newStateValue['License'] = 'S-IN STATE EXEMPT APPROVAL SM';
                newStateValue['LicenseExpDate'] = '9999-12-31';
            }
        }
        //check transportation zone
        let d_TransporationZone_RegionList = ['NS', 'NT', 'NU', 'PE', 'SK', 'YT'];
        if (source_data.Country === 'US' || source_data.Country === 'PR') {
            newStateValue[
                'TransporationZone'
            ] = source_data.PostalCode.toString().substring(0, 3);
        } else if (
            source_data.Country === 'CA' &&
            d_TransporationZone_RegionList.includes(source_data.Region)
        ) {
            newStateValue['TransporationZone'] = 'INTL';
        } else if (source_data.Country === 'CA') {
            newStateValue['TransporationZone'] = source_data.Region;
        } else {
            newStateValue['TransporationZone'] = 'INTL';
        }

        //check price list
        if (source_data.Country != 'US') {
            newStateValue['PriceListTypeId'] = '5';
            newStyleProps['PriceListTypeId'] = readOnlyDropDown;
        } else {
            newStateValue['PriceListTypeId'] = 0;
            newStyleProps['PriceListTypeId'] = { disabled: false };
        }

        //check Customer group
        if (source_data.CategoryTypeId != undefined) {
            let categoryTypeid = parseAndSanitise(source_data.CategoryTypeId);
            if (categoryTypeid === 2) {
                //if self-distributor
                newStateValue['CustomerGroupTypeId'] = '5';
                newStyleProps['CustomerGroupTypeId'] = readOnlyDropDown;
            } else if (categoryTypeid === 3 || categoryTypeid === 6) {
                //if oem or kitter
                newStateValue['CustomerGroupTypeId'] = '9';
                newStyleProps['CustomerGroupTypeId'] = readOnlyDropDown;
            } else if (categoryTypeid === 7) {
                // if dropship
                newStateValue['AccountTypeId'] = '3';
                newStyleProps['AccountTypeId'] = readOnlyDropDown;
                newStateValue['CustomerGroupTypeId'] = '11';
                newStyleProps['CustomerGroupTypeId'] = readOnlyDropDown;
            }
        }
        //check shipping conditions
        if (source_data.Country != 'US') {
            newStateValue['ShippingConditionsTypeId'] = '2';
            newStyleProps['ShippingConditionsTypeId'] = readOnlyDropDown;
        } else {
            newStateValue['ShippingConditionsTypeId'] = '1';
            newStyleProps['ShippingConditionsTypeId'] = readOnlyDropDown;
        }

        return { newStateValue, newStyleProps };
    };

    handleFormSubmission = () => {
        let {
                TaskId,
                WorkflowId,
                formData,
                selectedFiles,
                selectedFilesIds,
            } = this.state,
            castedFormData = {};

        formData = {
            ...formData,
            ...this.props.dunsFields,
        };

        try {
            const WorkflowTaskModel = {
                RejectReason: formData['RejectionButton']
                    ? formData['RejectionReason']
                    : '',
                TaskId: TaskId,
                UserId: localStorage.getItem('userId'),
                WorkflowId: WorkflowId,
                WorkflowTaskOperationType: !formData['RejectionButton'] ? 1 : 2,
            };

            let postData = {};
            postData['formData'] = {
                WorkflowTaskModel,
                AdditionalNotes: formData['AdditionalNotes'],
                SfdcNumber: formData['SfdcNumber'],
            };
            postData['files'] = selectedFilesIds.map((id) => selectedFiles[id]);
            // console.log('psot', postData);
            this.props.saveApolloMyTaskCustomerMaster(postData);
            this.resetForm();
            this.scrollToTop();
        } catch (error) {
            console.log('customer master form approval error');
        }
    };

    onSubmit = (event, reject, schema) => {
        const {
            functionalGroupDetails: { Customer: globalMdmDetail = {} },
        } = this.props;

        let { formData, selectedFilesIds, selectedFiles } = this.state;
        let fileErrors = {};
        let errors = false;
        selectedFilesIds.map((id) => {
            if (selectedFiles[id] && selectedFiles[id].DocumentType <= 0) {
                fileErrors[id] = 'Document Type Required for file';
                errors = true;
            }
        });
        this.setState({ fileErrors, isFileErrors: errors });

        this.setState(
            {
                modalVisible: false,
                formData: {
                    ...this.state.formData,
                    RejectionButton: reject,
                },
            },
            () => {
                if (
                    this.state.formData &&
                    (this.state.formData['SfdcNumber'] === '' ||
                        !this.state.formData['SfdcNumber'])
                ) {
                    this.setFormErrors({
                        SfdcNumber: 'this field is required',
                    });
                } else if (this.state.isFileErrors === false) this.handleFormSubmission();
            }
        );
    };

    scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    resetFormData = () => {
        Object.keys(this.state.formData).map((key) => {
            var myitem = key;
            if (
                ![
                    'OrderCombination',
                    'PaymentHistoryRecord',
                    'RejectionButton',
                    'displayINCOT2',
                    'display_LN',
                ].includes(myitem)
            ) {
                this.setState({
                    formData: {
                        ...this.state.formData,
                        [key]: '',
                    },
                });
            } else {
                if (!['displayINCOT2', 'display_LN'].includes(myitem)) {
                    this.setState({
                        formData: {
                            ...this.state.formData,
                            [key]: false,
                        },
                    });
                }
            }
        });
    };

    resetForm = () => {
        let { formErrors } = this.state;

        Object.keys(this.state.formErrors).forEach((key) => (formErrors[key] = ''));

        this.setState(
            {
                formErrors,
            },
            this.resetFormData
        );
    };

    selectFiles = (events) => {
        event.preventDefault();

        const { selectedFilesIds, selectedFiles } = this.state;
        const id = events.target.files[0].name;

        this.setState({
            selectedFiles: {
                ...selectedFiles,
                [id]: {
                    data: events.target.files[0],
                    DocumentName: events.target.files[0].name,
                    DocumentType: 0,
                },
            },
            selectedFilesIds: [...selectedFilesIds, id],
            filename: events.target.files[0].name,
            fileinputkey: Date.now(),
        });
    };

    removeFile = (id) => {
        event.preventDefault();

        var selectedFilesDict = this.state.selectedFiles;
        var selectedFilesIdsList = this.state.selectedFilesIds;
        // remove the existing state
        delete selectedFilesDict[id];
        selectedFilesIdsList = selectedFilesIdsList.filter((fileid) => fileid !== id);

        this.setState({
            selectedFiles: selectedFilesDict,
            selectedFilesIds: selectedFilesIdsList,
        });
    };

    _renderModalContent = () => (
        <Box
            my={4}
            mx="30%"
            backgroundColor="white"
            borderRadius="4px"
            borderColor="rgba(0, 0, 0, 0.1)">
            <Text my={3} fontWeight="regular" color="lightBlue" fontSize={24} pl={4}>
                Submit Workflow
            </Text>
            <Box px="0px" pb="22px" justifyContent="center">
                <Box alignItems="center" justifyContent="center">
                    {!this.state.isReject ? (
                        <FormInput
                            label="Additional Notes"
                            multiline
                            numberOfLines={2}
                            maxLength={250}
                            name="AdditionalNotes"
                            type="text"
                            onChange={this.onFieldChange}
                            value={
                                this.state.formData &&
                                this.state.formData['AdditionalNotes']
                            }
                            variant={'solid'}
                        />
                    ) : (
                        <FormInput
                            label="Rejection Reason"
                            name="RejectionReason"
                            maxLength={250}
                            onChange={this.onFieldChange}
                            error={'*this field is required'}
                            multiline
                            numberOfLines={2}
                            type="text"
                            value={
                                this.state.formData &&
                                this.state.formData['RejectionReason']
                            }
                            variant="solid"
                        />
                    )}
                </Box>
                <Flex
                    justifyEnd
                    alignCenter
                    alignSelf="flex-end"
                    style={{
                        paddingTop: 15,
                        flexDirection: 'row',
                        alignItems: 'center',
                        paddingLeft: 10,
                        paddingRight: 15,
                        marginTop: 20,
                        marginBottom: 10,
                        marginHorizontal: 25,
                    }}>
                    <Button
                        onPress={(event) => {
                            if (this.state.isReject)
                                this.setState({ isReject: false }, () =>
                                    this.onSubmit(event, true, rejectRules)
                                );
                            else this.onSubmit(event, false, mytaskCustomerMasterRules);
                        }}
                        title="Submit"
                    />
                    <Button
                        title="Cancel"
                        onPress={() => this.setState({ modalVisible: null })}
                    />
                </Flex>
            </Box>
        </Box>
    );

    render() {
        const {
            width,
            location,
            functionalGroupDetails: {
                DocumentLocation: files,
                Customer: globalMdmDetail = {},
                CustomerMaster: customerMaster = null,
                TaskNote: Notes = '',
                RequestorName,
            },
            alert = {},
            statusBarData,
            TasksStatusByTeamId = null,
            fetching,
        } = this.props;

        const {
            dropDownDatas,
            teamRoleFieldMapping,
            inputPropsForDefaultRules,
            selectedFilesIds,
            selectedFiles,
        } = this.state;

        const { state } = location;

        const CustomerMasterTeamFields =
            idx(teamRoleFieldMapping, (_) => _.CustomerMasterTeamFields) || [];
        const RoleTypeId = globalMdmDetail.RoleTypeId;
        const SystemTypeId = globalMdmDetail.SystemTypeId;
        const allowedFieldList = CustomerMasterTeamFields.filter(
            (item) => item.RoleTypeId === RoleTypeId && item.SystemTypeId === SystemTypeId
        );
        let allowedFields = undefined;
        if (allowedFieldList && allowedFieldList.length > 0) {
            allowedFields = allowedFieldList[0].Fields;
        }

        const workflow = {
            ...state,
            isReadOnly:
                TasksStatusByTeamId === null ||
                !(
                    globalMdmDetail.WorkflowStateTypeId === 2 &&
                    TasksStatusByTeamId[3].WorkflowTaskStateTypeId === 2
                ),
        };

        const requestDateUtc = moment(globalMdmDetail.DateOfCreation);
        const localDate = moment(requestDateUtc).local();
        const requestDate = localDate.format('YYYY-MM-DD hh:mm:ss A');

        let isContractEnabled = false;
        //check iff contract fields to be displayed or not
        if (globalMdmDetail && globalMdmDetail.CategoryTypeId != undefined) {
            let categoryTypeid = parseAndSanitise(globalMdmDetail.CategoryTypeId);
            if (
                categoryTypeid === 1 ||
                categoryTypeid === 2 ||
                categoryTypeid === 3 ||
                categoryTypeid === 6
            ) {
                isContractEnabled = true;
            }
        }
        const isWorkFlowReadOnly = workflow.isReadOnly;

        const inputReadonlyProps = workflow.isReadOnly ? { disabled: true } : null;

        const showFunctionalDetail =
            state.isReadOnly && customerMaster === null ? { display: 'none' } : null;

        const enableDisplay = isWorkFlowReadOnly ? { display: 'none' } : null;

        var bgcolor = alert.color || '#FFF';
        if (fetching) {
            return <Loading />;
        }

        return (
            <ScrollView
                keyboardShouldPersistTaps="always"
                style={{
                    backgroundColor: '#EFF3F6',
                    paddingBottom: 75,
                }}>
                <Modal isVisible={this.state.modalVisible}>
                    {this._renderModalContent()}
                </Modal>
                <FlashMessages
                    toasts={this.props.toasts}
                    onDismiss={this.props.removeMessage}
                />
                {alert.display && (
                    <FlashMessage
                        bg={{ backgroundColor: bgcolor }}
                        message={alert.message}
                    />
                )}

                <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between">
                    <Text
                        style={{
                            fontWeight: '500',
                            fontSize: 24,
                            color: Colors.lightBlue,
                            marginTop: 25,
                            marginBottom: 20,
                            paddingLeft: 45,
                        }}>
                        MY TASKS - {state.Header}
                    </Text>
                    <Box
                        marginTop="25px"
                        flex={3 / 4}
                        flexDirection="row-reverse"
                        alignItems="flex-end">
                        <MultiColorProgressBar readings={statusBarData} />
                    </Box>
                </Box>
                <Box marginTop="25px" flexDirection="row-reverse" alignItems="flex-end">
                    <StyledText
                        style={{
                            fontWeight: '600',
                            paddingRight: '20px',
                            fontSize: '16px',
                            color: '#10206C',
                        }}>
                        {' '}
                        Requestor : {RequestorName}{' '}
                    </StyledText>
                </Box>
                <Box flexDirection="row-reverse" alignItems="flex-end">
                    <StyledText
                        style={{
                            fontWeight: '600',
                            paddingRight: '20px',
                            fontSize: '16px',
                            color: '#10206C',
                        }}>
                        {' '}
                        Date of Request: {requestDate}{' '}
                    </StyledText>
                </Box>

                <View
                    style={{
                        flex: 1,
                        paddingHorizontal: width < 1440 ? 60 : width * 0.1,
                        paddingBottom: 10,
                    }}>
                    <Box fullHeight my={2}>
                        <Box flexDirection="row" justifyContent="space-around" my={4}>
                            <FormInput
                                px="25px"
                                flex={1 / 4}
                                label="WorkflowTitle"
                                maxLength={40}
                                name="WorkflowTitle"
                                variant="outline"
                                type="text"
                                value={globalMdmDetail && globalMdmDetail.WorkflowTitle}
                            />
                            <FormInput
                                px="25px"
                                flex={1 / 4}
                                label="Workflow Number"
                                name="workflow-number"
                                variant="outline"
                                type="text"
                                value={globalMdmDetail && globalMdmDetail.WorkflowId}
                            />
                        </Box>
                        <GlobalMdmFields
                            formData={{
                                ...globalMdmDetail,
                            }}
                            dunsEditable={false}
                            readOnly
                            formErrors={this.state.formErrors}
                            onFieldChange={this.onFieldChange}
                            staticDropDownData={this.props.staticData}
                        />
                        <Text
                            mt="42px"
                            mb="20px"
                            fontWeight="regular"
                            color="lightBlue"
                            fontSize={24}
                            pl={4}>
                            SYSTEM FIELDS
                        </Text>
                        <Box flexDirection="row" justifyContent="center">
                            <Box width={1 / 2} mx="auto" alignItems="center">
                                <FormInput
                                    label="System"
                                    name="System"
                                    inline
                                    variant="outline"
                                    type="text"
                                    value={
                                        SystemType[
                                            globalMdmDetail &&
                                                globalMdmDetail.SystemTypeId
                                        ]
                                    }
                                />
                                <FormInput
                                    label="SFDC Number"
                                    name="SfdcNumber"
                                    multiline
                                    numberOfLines={3}
                                    onChange={this.onFieldChange}
                                    style={{ lineHeight: '2.5' }}
                                    maxLength={100}
                                    value={
                                        this.state.formData['SfdcNumber'] ||
                                        (customerMaster && customerMaster.SfdcNumber)
                                    }
                                    variant={isWorkFlowReadOnly ? 'outline' : 'solid'}
                                    inline={isWorkFlowReadOnly ? true : false}
                                    type="text"
                                    show
                                    required={!isWorkFlowReadOnly}
                                    error={
                                        this.state.formErrors
                                            ? this.state.formErrors['SfdcNumber']
                                            : null
                                    }
                                />
                            </Box>
                            <Box width={1 / 2} mx="auto" alignItems="center">
                                <FormInput
                                    label="Purpose of Request"
                                    name="Purpose"
                                    multiline
                                    numberOfLines={
                                        globalMdmDetail.Purpose &&
                                        globalMdmDetail.Purpose.length > 36
                                            ? 3
                                            : 1
                                    }
                                    style={{ lineHeight: '2.5' }}
                                    value={globalMdmDetail && globalMdmDetail.Purpose}
                                    inline
                                    variant="outline"
                                    maxLength={1000}
                                    type="text"
                                />
                            </Box>
                        </Box>

                        {files && <FilesList files={files} readOnly />}
                        {workflow.isReadOnly ? (
                            <>
                                <Box my={4}>
                                    <Box flexDirection="row" justifyContent="center">
                                        <Box width={1 / 2} mx="auto" alignItems="center">
                                            <FormInput
                                                label="Notes"
                                                name="AdditionalNotes"
                                                multiline
                                                numberOfLines={
                                                    Notes && Notes.length > 30 ? 2 : 1
                                                }
                                                type="text"
                                                inputWidth="100%"
                                                value={
                                                    isWorkFlowReadOnly
                                                        ? Notes
                                                        : this.state.formData
                                                        ? this.state.formData[
                                                              'AdditionalNotes'
                                                          ]
                                                        : null
                                                }
                                                variant={
                                                    isWorkFlowReadOnly
                                                        ? 'outline'
                                                        : 'solid'
                                                }
                                                inline={isWorkFlowReadOnly ? true : false}
                                            />
                                        </Box>
                                        <Box
                                            width={1 / 2}
                                            mx="auto"
                                            alignItems="center"></Box>
                                    </Box>
                                </Box>
                            </>
                        ) : (
                            <FilesList
                                title="NEW ATTACHMENTS"
                                formErrors={this.state.fileErrors}
                                files={selectedFilesIds.map((id) => selectedFiles[id])}
                                onChange={(value, id) => {
                                    this.setState({
                                        selectedFiles: {
                                            ...selectedFiles,
                                            [id]: {
                                                ...selectedFiles[id],
                                                DocumentType: parseInt(value),
                                            },
                                        },
                                    });
                                }}
                                onRemove={(value, id) => this.removeFile(id)}
                            />
                        )}
                    </Box>
                    <Box {...enableDisplay}>
                        <Flex
                            justifyEnd
                            alignCenter
                            style={{
                                paddingTop: 15,
                                flexDirection: 'row',
                                alignItems: 'center',
                                paddingLeft: 10,
                                paddingRight: 15,
                                marginTop: 20,
                                marginBottom: 10,
                                marginHorizontal: 25,
                            }}>
                            <label htmlFor="file-upload" className="custom-file-upload">
                                <MaterialIcons
                                    name="attach-file"
                                    size={18}
                                    color="#fff"
                                />
                            </label>
                            <input
                                id="file-upload"
                                key={this.state.fileinputkey}
                                type="file"
                                onChange={this.selectFiles}
                                multiple
                            />

                            <Button
                                onPress={(event) =>
                                    this.setState({
                                        formErrors: {},
                                        isReject: false,
                                        modalVisible: true,
                                    })
                                }
                                title="Approve"
                            />
                            <Button
                                title="Reject"
                                onPress={(event) =>
                                    this.setState({ isReject: true, modalVisible: true })
                                }
                            />
                        </Flex>
                    </Box>
                </View>
            </ScrollView>
        );
    }
}

class Default extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const props = this.props;

        return (
            <DimensionAware
                render={(dimensions) => (
                    <Page
                        {...{
                            ...props,
                            width: getWindowWidth(dimensions),
                            height: getWindowHeight(dimensions),
                            marginBottom: 25,
                        }}
                    />
                )}
            />
        );
    }
}

const mapStateToProps = ({ workflows, myTasks, toasts, staticData }) => {
    const { fetching, alert } = myTasks;
    const {
        fetchingfnGroupData,
        statusBarData,
        functionalGroupDetails,
        TasksStatusByTeamId,
        fetchingStatusBar,
        dunsFields = {},
    } = workflows;

    const { data, fetching: staticFetching } = staticData;
    return {
        fetching: fetching || staticFetching || fetchingStatusBar || fetchingfnGroupData,
        dunsFields,
        alert,
        statusBarData,
        functionalGroupDetails,
        TasksStatusByTeamId,
        toasts,
        staticData: data,
    };
};

export default connect(mapStateToProps, {
    saveApolloMyTaskCustomerMaster,
    getFunctionalGroupData,
    getStatusBarData,
    removeMessage,
    fetchDuns,
    getStaticRequest,
})(Default);

const styles = StyleSheet.create({
    progressIndicator: {
        flex: 1,
        paddingBottom: 5,
        flexDirection: 'row-reverse',
        alignItems: 'flex-end',
    },
    statusText: {
        fontSize: 15,
        color: '#1D4289',
        fontFamily: 'Poppins',
        textAlign: 'center',
        marginTop: 20,
    },
});
