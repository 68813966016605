/**
 * @prettier
 */

import { MaterialIcons } from '@expo/vector-icons';
import idx from 'idx';
import * as moment from 'moment';
import React from 'react';
import { ScrollView, View, ActivityIndicator, StyleSheet } from 'react-native';
import {
    DimensionAware,
    getWindowHeight,
    getWindowWidth,
} from 'react-native-dimension-aware';
import { GenerateWorkflowId } from '@env';
import { TextInput } from 'react-native-web';
import { connect } from 'react-redux';
import { FlashMessages } from '../components/FlashMessage';

import { ajaxGetRequest } from '../appRedux/sagas/config';
import DynamicSelect from '../components/DynamicSelect';
import FilesList from '../components/FilesListTextInput';
import { Flex, Button, Box, Text } from '../components/common';
import { FormInput } from '../components/form';
import { otherRequestRules } from '../constants/FieldRules';
import { yupAllFieldsValidation } from '../constants/utils';
import { fetchCreateCustomerDropDownData } from '../redux/DropDownDatas';
import { removeMessage, otherRequest, hideMessage } from '../appRedux/actions';
import { Colors, StyledText } from '../theme/Index';

function merge(...schemas) {
    const [first, ...rest] = schemas;

    const merged = rest.reduce(
        (mergedSchemas, schema) => mergedSchemas.concat(schema),
        first
    );

    return merged;
}

const initFormdData = {
    SystemTypeId: 1,
};

const mapKeys = (obj, callback) => {
    const keys = Object.keys(obj);
    return keys.map((key, index) => callback(obj[key], key));
};

export const SystemType = [
    {
        id: 1,
        description: 'SAP Apollo',
    },
    {
        id: 2,
        description: 'SAP Olympus',
    },
    {
        id: 3,
        description: 'Pointman',
    },
    {
        id: 5,
        description: 'JD Edwards',
    },
    {
        id: 4,
        description: 'Made2Manage',
    },
    // {
    //     id: 6,
    //     description: 'Salesforce',
    // },
    {
        id: 7,
        description: 'Majesty',
    },
];

class Page extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            system: '',
            role: '',
            formData: {
                ...initFormdData,
                EffectiveDate: new Date().toJSON().slice(0, 10),
            },
            isSalesForce: { disabled: false },
            dropDownDatas: {},
            taxUpdated: false,
            fetchingWorkflowId: false,
            fileErrors: {},
            selectedFiles: {},
            selectedFilesIds: [],
            files: [],
            userId: localStorage.getItem('userId'),
            inputPropsForDefaultRules: {},
            fileinputkey: Date.now(),
        };
    }

    generateWorkflowId() {
        const url = GenerateWorkflowId;

        ajaxGetRequest(url).then((res) => {
            if (res.IsSuccess)
                this.setState({
                    fetchingWorkflowId: false,
                    formData: {
                        ...initFormdData,
                        ...this.state.formData,
                        WorkflowId: res.ResultData,
                        UserId: this.state.userId,
                    },
                });
        });
    }

    componentDidMount() {
        fetchCreateCustomerDropDownData().then((res) => {
            const data = res;
            this.setState({ dropDownDatas: data }, this.generateWorkflowId);
        });

        if (localStorage.getItem('SFDCData')) {
            const savedData = JSON.parse(localStorage.getItem('SFDCData'));

            delete savedData['type'];

            this.setState({
                ...this.state,
                formData: {
                    ...this.state.formData,
                    ...savedData,
                },
            });
        }
    }

    componentWillUnmount() {
        this.props.hideMessage();
        localStorage.removeItem('SFDCData');
    }

    updateFormData = (val, name) => {};

    onFieldChange = (val, e) => {
        const { formData: prevFormData } = this.state;
        const name = e.target.name;
        let value = val.trim();
        // other fields to uppercase
        value = val;

        this.setState((prevState, props) => {
            return {
                formData: {
                    ...prevState.formData,
                    [name]: value,
                },
            };
        });
    };

    setFormErrors = (errors) => {
        const { formErrors } = this.state;
        this.setState({ formErrors: errors }, () => window.scrollTo(0, 0));
    };

    proceedAction = () => {
        const { history } = this.props;
        const { selectedFilesIds, selectedFiles, formData, userId } = this.state;
        var isSalesForce = parseInt(formData.SystemTypeId, 10) === 6;
        let wfType = parseInt(formData.RoleTypeId, 10);
        if (isSalesForce) {
            wfType = 33;
        }
        var data = {
            ...formData,
            UserId: userId,
            SystemTypeId: parseInt(formData.SystemTypeId, 10),
        };
        this.props.otherRequest({
            data,
            files: selectedFilesIds.map((id) => selectedFiles[id]),
            history,
        });
    };

    onSubmit = (event, schema, IsSaveToWorkflow) => {
        let { formData, selectedFilesIds, selectedFiles } = this.state;
        const { Category, ...data } = formData;
        let fileErrors = {};
        let errors = false;

        // selectedFilesIds.map((id) => {
        //     // if (selectedFiles[id] && selectedFiles[id].DocumentType <= 0) {
        //     //     fileErrors[id] = 'Document Type Required for file';
        //     //     errors = true;
        //     }
        // });

        this.setState({ fileErrors, isFileErrors: errors });

        this.setState(
            {
                formData: {
                    ...data,
                    IsSaveToWorkflow,
                },
            },
            () => {
                yupAllFieldsValidation(
                    { ...formData, TaxJurisdiction: ' ' },
                    otherRequestRules,
                    (...rest) => {
                        if (this.state.isFileErrors === false)
                            this.proceedAction(...rest);
                    },
                    this.setFormErrors
                );
            }
        );
    };

    selectFile = (events) => {
        event.preventDefault();

        const { selectedFilesIds, selectedFiles } = this.state;
        const id = events.target.files[0].name;

        this.setState({
            selectedFiles: {
                ...selectedFiles,
                [id]: {
                    data: events.target.files[0],
                    DocumentName: events.target.files[0].name,
                    AttachmentName: '',
                },
            },
            selectedFilesIds: [...selectedFilesIds, id],
            filename: events.target.files[0].name,
            fileinputkey: Date.now(),
        });
    };

    removeFile = (id) => {
        event.preventDefault();

        var selectedFilesDict = this.state.selectedFiles;
        var selectedFilesIdsList = this.state.selectedFilesIds;
        // remove the existing state
        delete selectedFilesDict[id];
        selectedFilesIdsList = selectedFilesIdsList.filter((fileid) => fileid !== id);

        this.setState({
            selectedFiles: selectedFilesDict,
            selectedFilesIds: selectedFilesIdsList,
        });
    };

    render() {
        const { width } = this.props;
        const {
            dropDownDatas,
            formData,
            selectedFilesIds,
            selectedFiles,
            isSalesForce,
        } = this.state;

        if (
            this.state.fetchingWorkflowId === true ||
            this.props.fetching ||
            !this.state.formData
        )
            return (
                <Box
                    display="flex"
                    flex={1}
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                    minHeight="650px"
                    bg="#eff3f6">
                    <FlashMessages
                        toasts={this.props.toasts}
                        onDismiss={this.props.removeMessage}
                    />
                    <View>
                        <ActivityIndicator size="large" />
                    </View>
                </Box>
            );

        return (
            <ScrollView
                keyboardShouldPersistTaps="always"
                style={{
                    backgroundColor: '#eff3f6',
                    paddingBottom: 75,
                }}>
                <Box m="10px 0 0 5%">
                    <Text
                        style={{
                            fontWeight: '500',
                            fontSize: 24,
                            color: Colors.lightBlue,
                            marginBottom: 20,
                            paddingLeft: 45,
                        }}>
                        Other Request
                    </Text>
                </Box>

                <FlashMessages
                    toasts={this.props.toasts}
                    onDismiss={this.props.removeMessage}
                />
                <View
                    style={{
                        flex: 1,
                        paddingHorizontal: width < 1440 ? 75 : width * 0.1,
                    }}>
                    <Box my={2}>
                        <Box
                            flexDirection="row"
                            justifyContent="space-around"
                            my={4}
                            alignItems="center">
                            <FormInput
                                padding="8px 25px 0px 25px"
                                team="general"
                                style={{ lineHeight: '2', paddingBottom: 0 }}
                                flex={1 / 4}
                                required
                                onChange={this.onFieldChange}
                                error={
                                    this.state.formErrors
                                        ? this.state.formErrors['WorkflowTitle']
                                        : null
                                }
                                value={this.state.formData.WorkflowTitle}
                                label="Workflow Title"
                                name="WorkflowTitle"
                                maxLength={40}
                                inline={false}
                                variant="solid"
                            />

                            <FormInput
                                px="25px"
                                flex={1 / 4}
                                label="Workflow Number"
                                name="WorkflowId"
                                style={{ lineHeight: '2' }}
                                variant="outline"
                                type="text"
                                value={formData.WorkflowId || ''}
                                disabled
                            />
                        </Box>
                        <TextInput
                            autoComplete="off"
                            name="hidden"
                            type="text"
                            style={{ display: 'none' }}
                        />

                        <Text
                            mt="42px"
                            mb="20px"
                            ml="5%"
                            fontWeight="light"
                            color="lightBlue"
                            fontSize="28px">
                            SYSTEM FIELDS
                        </Text>

                        <Box flexDirection="row" justifyContent="center">
                            <Box width={1 / 2} mx="auto" alignItems="center">
                                <DynamicSelect
                                    arrayOfData={SystemType}
                                    value={formData.SystemTypeId}
                                    label="System"
                                    name="SystemTypeId"
                                    isRequired
                                    formErrors={
                                        this.state.formErrors
                                            ? this.state.formErrors['SystemTypeId']
                                            : null
                                    }
                                    onFieldChange={this.onFieldChange}
                                />
                            </Box>
                            <Box width={1 / 2} mx="auto" alignItems="center">
                                <FormInput
                                    label="Effective Date"
                                    min={moment().format('YYYY-MM-DD')}
                                    name="EffectiveDate"
                                    error={
                                        this.state.formErrors
                                            ? this.state.formErrors['EffectiveDate']
                                            : null
                                    }
                                    value={this.state.formData['EffectiveDate']}
                                    required
                                    type="date"
                                    style={{ height: 'inherit' }}
                                    onChange={(value, element) => {
                                        this.onFieldChange(
                                            new Date(value).toJSON().slice(0, 19),
                                            element
                                        );
                                    }}
                                />
                            </Box>
                        </Box>

                        <Box mt={2} flexDirection="row" justifyContent="center">
                            <Box width={0.79} mx="auto" alignItems="center">
                                <FormInput
                                    maxWidth="98%"
                                    label="Purpose of Request"
                                    name="Purpose"
                                    maxLength={1000}
                                    multiline
                                    numberOfLines={4}
                                    onChange={this.onFieldChange}
                                    value={formData.Purpose || ''}
                                />
                            </Box>
                        </Box>
                        <Box mt={2} flexDirection="row" justifyContent="flex-start">
                            <Box width={0.79} mx="auto" alignItems="flex-start">
                                {this.state.formData.RoleTypeId && (
                                    <StyledText
                                        style={{ fontWeight: '500', color: 'red' }}>
                                        Recommended Attachments:{' '}
                                        {this.state.attachment_text}{' '}
                                    </StyledText>
                                )}
                            </Box>
                        </Box>
                        <Box mt={2} flexDirection="row" justifyContent="flex-start">
                            <Box width={0.79} mx="auto" alignItems="flex-start">
                                <a
                                    href="https://vyaire.sharepoint.com/teams/CustomerMaster/Shared%20Documents/Forms/AllItems.aspx?csf=1&web=1&e=16fDUW&cid=3903669d%2Daf1f%2D47e4%2Dae1c%2D4d2135dfecb6&RootFolder=%2Fteams%2FCustomerMaster%2FShared%20Documents%2FMDM%20Portal%20Templates&FolderCTID=0x0120008F0722A590030D499D1DB76431290424"
                                    target="_blank">
                                    Click Here for templates
                                </a>
                            </Box>
                        </Box>
                        <FilesList
                            formErrors={this.state.fileErrors}
                            files={selectedFilesIds.map((id) => selectedFiles[id])}
                            onChange={(value, id) => {
                                console.log(value, id);
                                this.setState({
                                    selectedFiles: {
                                        ...selectedFiles,
                                        [id]: {
                                            ...selectedFiles[id],
                                            AttachmentName: value,
                                        },
                                    },
                                });
                            }}
                            onRemove={(value, id) => this.removeFile(id)}
                        />
                    </Box>
                    <Flex
                        justifyEnd
                        alignCenter
                        style={{
                            paddingTop: 65,
                            flexDirection: 'row',
                            alignItems: 'center',
                            paddingLeft: 10,
                            paddingRight: 15,
                            marginTop: 20,
                            marginBottom: 10,
                            marginHorizontal: 25,
                        }}>
                        <label htmlFor="file-upload" className="custom-file-upload">
                            <MaterialIcons name="attach-file" size={18} color="#fff" />
                        </label>
                        <input
                            id="file-upload"
                            key={this.state.fileinputkey}
                            type="file"
                            onChange={this.selectFile}
                        />

                        <Button
                            onPress={() => this.props.history.goBack()}
                            title="Cancel"
                        />
                        <Button
                            onPress={(e) => {
                                this.setState({ formErrors: {} }, () =>
                                    this.onSubmit(e, otherRequestRules, true)
                                );
                            }}
                            title="Submit"
                        />
                    </Flex>
                </View>
                <Box fullHeight />
            </ScrollView>
        );
    }
}

class Default extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const props = this.props;

        return (
            <DimensionAware
                render={(dimensions) => (
                    <Page
                        {...{
                            ...props,
                            width: getWindowWidth(dimensions),
                            height: getWindowHeight(dimensions),
                            marginBottom: 25,
                        }}
                    />
                )}
            />
        );
    }
}

const styles = StyleSheet.create({
    progressIndicator: {
        flex: 1,
        paddingBottom: 5,
        flexDirection: 'row-reverse',
        alignItems: 'flex-end',
    },
    statusText: {
        fontSize: 15,
        color: '#1D4289',
        fontFamily: 'Poppins',
        textAlign: 'center',
        marginTop: 20,
    },
});

const mapStateToProps = ({ customer, toasts }) => {
    const { fetching, error, customerdata, alert, taxJuriData } = customer;
    return { fetching, error, customerdata, alert, taxJuriData, toasts };
};

export default connect(mapStateToProps, {
    otherRequest,
    removeMessage,
    hideMessage,
})(Default);
