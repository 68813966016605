import { Box, Flex, Text } from '../components/common';
import { CheckBox } from 'react-native';
import React from 'react';
import Indicator from './Indicator';

export const CheckBoxItem = ({
    name,
    title,
    hide,
    onValueChange,
    stateValue,
    inputProps,
    delta,
    style = {},
}) => (
    <>
        <Flex
            alignLeft
            style={{
                display: hide ? 'none' : 'flex',
                paddingTop: 15,
                flexDirection: 'row',
                alignItems: 'center',
                paddingLeft: 10,
                paddingRight: 15,
                marginBottom: 10,
                marginHorizontal: 25,
                maxWidth: '350px',
                width: '100%',
                ...style,
            }}>
            {delta && (
                <Box ml={delta && '-14px'}>
                    <Indicator />
                </Box>
            )}
            <CheckBox
                name={name}
                value={
                    delta
                        ? delta.UpdatedValue === 'true' || delta.UpdatedValue === 'T'
                        : stateValue === true || stateValue === 'T'
                }
                onValueChange={onValueChange}
                {...inputProps}
            />
            <Text
                my={2}
                fontSize="16px"
                fontWeight="500"
                fontFamily="Poppins"
                backgroundColor="transparent"
                color="#10206c"
                pl={4}>
                {title}
            </Text>
        </Flex>
    </>
);
