import React from 'react';
import {
    ScrollView,
    View,
    TouchableOpacity,
    ActivityIndicator,
    StyleSheet,
} from 'react-native';
import {
    DimensionAware,
    getWindowHeight,
    getWindowWidth,
} from 'react-native-dimension-aware';
import { Colors, StyledText } from '../../../theme/Index';
import { Flex, Button, Box, Text } from '../../../components/common';
import { FormInput } from '../../../components/form';
import GlobalMdmFields from '../../../components/GlobalMdmFields';
import DynamicSelect from '../../../components/DynamicSelect';
import { CheckBoxItem } from '../../../components/CheckBoxItem';
import { debounce } from 'lodash';
import { parseAndSanitise, yupAllFieldsValidation } from '../../../constants/utils';
import {
    FieldValidationRoleType,
    mytaskCustomerMasterRules,
    ptmnCustomerMasterRules,
    rejectRules,
} from '../../../constants/FieldRules';
import {
    saveApolloMyTaskCustomerMaster,
    fetchDuns,
    getStatusBarData,
    getFunctionalGroupData,
    getStaticRequest,
    removeMessage,
} from '../../../appRedux/actions/';
import { connect } from 'react-redux';
import {
    fetchCustomerMasterDropDownData,
    fetchCreateTeamRoleFieldMapping,
} from '../../../redux/DropDownDatas';
import Loading from '../../../components/Loading';
import FlashMessage, { FlashMessages } from '../../../components/FlashMessage';
import {
    RoleType,
    PtmnRoleType,
    SalesOrgType,
    SystemType,
    DistributionChannelType,
    DivisionType,
    CompanyCodeType,
} from '../../../constants/WorkflowEnums';
import MultiColorProgressBar from '../../../components/MultiColorProgressBar';
import Modal from 'modal-enhanced-react-native-web';
import * as moment from 'moment';
import FilesList from '../../../components/FilesList';
import { MaterialIcons } from '@expo/vector-icons';
import idx from 'idx';
import { Table, Row, Rows } from '../../../components/table';
import CollapsibleTable from '../../../components/table/CollapsibleTable';

const TableComponent = ({
    flexArray,
    headings,
    tableData,
    isFetching = false,
    fontsize = '15px',
    cellHeight = 'auto',
}) => (
    <View style={{ width: '93%' }}>
        <View
            style={{
                minHeight: 'auto',
                maxHeight: '300px',
            }}>
            <Table
                border="2px solid #234382"
                borderStyle={{
                    borderWidth: 1,
                    borderRightWidth: 1,
                    borderColor: '#98D7DA',
                    borderRightStyle: 'solid',
                }}>
                <Row
                    flexArr={flexArray}
                    data={headings}
                    style={{
                        backgroundColor: '#E6F5FA',
                        height: '45px',
                    }}
                    borderStyle={{
                        borderWidth: 0,
                        borderTopWidth: 0,
                        borderRightWidth: 1,
                        borderColor: '#98D7DA',
                        borderRightStyle: 'solid',
                    }}
                    textStyle={{
                        textAlign: 'left',
                        color: '#10206C',
                        fontWeight: '600',
                        fontFamily: 'Poppins',
                        fontSize: 12,
                        paddingTop: 24,
                        paddingBottom: 24,
                        paddingHorizontal: 15,
                    }}
                />
            </Table>
        </View>
        <ScrollView
            style={{
                flex: 1,
                overflowY: 'overlay',
                borderBottom: '3px solid #234382',
            }}
            keyboardShouldPersistTaps="always">
            <View
                style={{
                    minHeight: 'auto',
                    maxHeight: '300px',
                }}>
                <Table
                    border="2px solid #234382"
                    borderTopWidth="0px"
                    borderBottomWidth="0px">
                    {isFetching ? (
                        <ActivityIndicator />
                    ) : (
                        <Rows
                            flexArr={flexArray}
                            data={tableData}
                            style={{ minHeight: 20, height: cellHeight }}
                            borderStyle={{
                                borderWidth: 0,
                                borderTopWidth: 0,
                                borderRightWidth: 1,
                                borderColor: '#98D7DA',
                                borderRightStyle: 'solid',
                            }}
                            textStyle={{
                                color: '#353535',
                                fontSize: fontsize,
                                fontWeight: '500',
                                fontFamily: 'Poppins',
                                borderColor: '#98D7DA',
                                paddingTop: 10,
                                paddingBottom: 12,
                                paddingLeft: 5,
                                textAlign: 'left',
                                backgroundColor: '#F8F8F8',
                            }}
                        />
                    )}
                </Table>
            </View>
        </ScrollView>
    </View>
);

class Page extends React.Component {
    constructor(props) {
        super(props);
        const editableProp = {
            inline: false,
            variant: 'solid',
            onChange: this.onFieldChange,
        };
        let { state: workflow } = this.props.location;
        let isWorkFlowReadOnly = this.props.location.state.isReadOnly;
        // let isWorkFlowReadOnly=false
        this.state = {
            isWorkFlowReadOnly: this.props.location.state.isReadOnly,
            WorkflowId: this.props.location.state.WorkflowId,
            TaskId: this.props.location.state.TaskId,
            loading: this.props.fetching,
            functionalGroupDetails: this.props.functionalGroupDetails,
            dropDownDatas: {},
            teamRoleFieldMapping: {},
            formData: {
                RejectionButton: false,
                displayINCOT2: isWorkFlowReadOnly ? true : false,
                display_LN: isWorkFlowReadOnly ? true : false,
                PaymentHistoryRecord: true,
                OrderCombination: false,
                TaxClassificationTypeId: 2,
                AccountStatementTypeId: 2,
                SortKey: '009',
                PaymentMethods: 'C',
                AcctgClerk: '01',
                MdmNumberToGroup: null,
            },

            formErrors: {},
            inputPropsForDefaultRules: { CustomerGroupTypeId: editableProp },
            selectedFiles: {},
            selectedFilesIds: [],
            files: [],
            fileinputkey: Date.now(),
            checkboxState: false,
        };

        this.fetchDunsFields = debounce(this.props.fetchDuns, 200);
    }

    componentDidMount() {
        let { state: wf } = this.props.location;
        let postJson = {
            workflowId: wf.WorkflowId,
            fuctionalGroup: 'customermaster',
            taskId: wf.TaskId,
            systemTypeId: wf.SystemTypeId,
        };

        this.props.getStaticRequest([15, 30, 26, 17, 42, 43, 49, 82]);

        this.props.getStatusBarData(postJson);
        this.props.getFunctionalGroupData(postJson);
        fetchCustomerMasterDropDownData().then((res) => {
            const data = res;
            this.setState({ dropDownDatas: data });
        });
        fetchCreateTeamRoleFieldMapping().then((resp) => {
            const data = resp;
            this.setState({ teamRoleFieldMapping: data });
        });
    }

    componentWillReceiveProps(newProps) {
        if (
            newProps.functionalGroupDetails != this.props.functionalGroupDetails &&
            !this.state.isWorkFlowReadOnly
        ) {
            var customerdata = newProps.functionalGroupDetails.Customer;

            var result = this.validateFromSourceData(customerdata);

            if (
                customerdata &&
                customerdata.SystemTypeId === 3 &&
                customerdata.RoleTypeId === 2
            ) {
                result.newStateValue = {
                    ...result.newStateValue,
                    BillToCustomerNumber:
                        customerdata.BillToCustomerNumber &&
                            customerdata.BillToCustomerNumber.length > 0
                            ? customerdata.BillToCustomerNumber
                            : customerdata.BillToWorkflowId &&
                                customerdata.BillToWorkflowId.length > 0
                                ? customerdata.BillToWorkflowId
                                : null,
                };
            }

            this.setState({
                formData: {
                    ...this.state.formData,
                    ...result.newStateValue,
                    Role:
                        FieldValidationRoleType[
                        newProps.functionalGroupDetails.Customer.RoleTypeId
                        ],
                    DunsNumber: newProps.functionalGroupDetails.Customer.DunsNumber,
                    MarketingSegmentationTypeId:
                        newProps.functionalGroupDetails.CustomerMaster?.MarketingSegmentationTypeId ||
                        newProps.functionalGroupDetails.Customer
                            .MarketingSegmentationTypeId,
                },
                inputPropsForDefaultRules: {
                    ...this.state.inputPropsForDefaultRules,
                    ...result.newStyleProps,
                },
            });
        }
    }

    setFormErrors = (errors) => {
        const { formErrors } = this.state;
        this.setState({ formErrors: errors }, () => window.scrollTo(0, 0));
    };

    onDunsNewInput = (value, e) => {
        const { name } = e.target;
        this.setState(
            {
                formData: {
                    ...this.state.formData,
                    DunsNumber: value,
                },
            },
            () => console.log('hey state', this.state.formData)
        );
        console.log('hey ', value);
        this.fetchDunsFields({
            UserId: localStorage.getItem('userId'),
            DunsNumber: value,
        });
    };

    onFieldChange = (value, e) => {
        e.preventDefault();
        const { name } = e.target;
        const { formData: prevFormData } = this.state;
        if (name === 'ShipToCustomerNumber') {
            if (value.length > 0) {
                if (value.toLowerCase().replace(/\s/g, '') == 'billto') {
                    this.setState({
                        formErrors: {
                            ...this.state.formErrors,
                            shipToCustomerValidation:
                                ' cannot enter billto for ship to customer number',
                        },
                    });
                } else {
                    this.setState({
                        formErrors: {
                            ...this.state.formErrors,
                            shipToCustomerValidation: null,
                        },
                    });
                }
            } else if (
                value.length <= 0 &&
                this.state.formErrors['shipToCustomerValidation']
            ) {
                this.setState({
                    formErrors: {
                        ...this.state.formErrors,
                        shipToCustomerValidation: null,
                    },
                });
            }
        }
        if (name === 'DunsNumber' && value.length > 0) {
            const { isDunsValid } = this.state;
            const {
                validity: { valid },
            } = e.target;
            if (valid !== isDunsValid)
                this.setState({
                    isDunsValid: valid,
                    formErrors: {
                        ...this.state.formErrors,
                        isDunsValid: !valid ? ' only numbers (0-9) are allowed.' : null,
                    },
                });
        } else if (value.length <= 0 && this.state.formErrors['isDunsValid']) {
            this.setState({
                formErrors: {
                    ...this.state.formErrors,
                    isDunsValid: null,
                },
            });
        }

        this.setState(
            {
                test: { ...this.state.test, [name]: value },
                formData: {
                    ...this.state.formData,
                    [name]: value,
                },
            },
            () => {
                if (
                    name === 'CustomerClassTypeId' ||
                    name === 'IncoTerms1TypeId' ||
                    name === 'CustomerGroupTypeId'
                ) {
                    this.validateRules(name, value);
                }
                //remove from formData if TypeId is set to 0 OR ''
                if (name.includes('TypeId') && parseInt(value) === 0) {
                    delete this.state.formData[name];
                }
            }
        );
    };

    parseAndHandleFieldChange = (value, e) => {
        const { name } = e.target;
        const val = parseAndSanitise(value, 10);
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: val,
            },
        });
    };

    setBulkFormDataUpdate = (keyValdict) => {
        this.setState({
            formData: {
                ...this.state.formData,
                ...keyValdict,
            },
        });
    };
    setFormDataValues = (name, value) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: value,
            },
        });
    };

    setInputPropsForDefaultRules = (field_name, property) => {
        this.setState({
            inputPropsForDefaultRules: {
                ...this.state.inputPropsForDefaultRules,
                [field_name]: property,
            },
        });
    };
    // display or set input/dropdowns values based on rules
    validateRules = (stateKey, stateVal) => {
        const readOnlyInputprop = { inline: true, variant: 'outline' };
        const editInputProp = {
            inline: false,
            variant: 'solid',
            onChange: this.onFieldChange,
        };
        const readOnlyDropDown = { disabled: true };

        const { teamRoleFieldMapping } = this.state;

        const {
            functionalGroupDetails: { Customer: globalMdmDetail = {} },
        } = this.props;

        const CustomerMasterTeamFields =
            idx(teamRoleFieldMapping, (_) => _.CustomerMasterTeamFields) || [];
        const RoleTypeId = globalMdmDetail.RoleTypeId;
        const SystemTypeId = globalMdmDetail.SystemTypeId;
        const allowedFieldList = CustomerMasterTeamFields.filter(
            (item) => item.RoleTypeId === RoleTypeId && item.SystemTypeId === SystemTypeId
        );
        let allowedFields = undefined;
        if (allowedFieldList && allowedFieldList.length > 0) {
            allowedFields = allowedFieldList[0].Fields;
        }

        // check for CustomerPriceProcTypeId - ITMDMK1707
        if (
            allowedFields.includes('CustomerPriceProcTypeId') &&
            stateKey === 'CustomerClassTypeId'
        ) {
            var CC_val = stateVal;
            if (['1', '2', '3', '4', '5'].includes(CC_val)) {
                this.setFormDataValues('CustomerPriceProcTypeId', 2);
                this.setInputPropsForDefaultRules('CustomerPriceProcTypeId', {
                    disabled: false,
                });
            } else {
                this.setFormDataValues('CustomerPriceProcTypeId', '');
                this.setInputPropsForDefaultRules('CustomerPriceProcTypeId', {
                    disabled: false,
                });
            }
        }
        // check for incoterms2 - ITMDMK1707 - editable in all cases
        if (stateKey === 'IncoTerms1TypeId') {
            var INCOT1_val = stateVal;
            if (INCOT1_val === '1') {
                this.setBulkFormDataUpdate({
                    isRequiredINCOT2: true,
                    isReadonlyINCOT2: false,
                    IncoTerms2: '',
                });
                this.setInputPropsForDefaultRules('IncoTerms2', { disabled: false });
            } else if (INCOT1_val === '3') {
                this.setBulkFormDataUpdate({
                    isRequiredINCOT2: false,
                    isReadonlyINCOT2: false, //true
                    IncoTerms2: 'Carriage Paid To',
                });
                this.setInputPropsForDefaultRules('IncoTerms2', { disabled: false }); //readonlydropdown
            } else if (INCOT1_val === '4') {
                this.setBulkFormDataUpdate({
                    isRequiredINCOT2: false,
                    isReadonlyINCOT2: false, //true
                    IncoTerms2: 'Delivered at Location',
                });
                this.setInputPropsForDefaultRules('IncoTerms2', { disabled: false }); //readonlydropdown
            } else if (INCOT1_val === '5') {
                this.setBulkFormDataUpdate({
                    isRequiredINCOT2: false,
                    isReadonlyINCOT2: false, //true
                    IncoTerms2: 'Delivered Duty Paid',
                });
                this.setInputPropsForDefaultRules('IncoTerms2', { disabled: false }); //readonlydropdown
            } else if (INCOT1_val === '7') {
                this.setBulkFormDataUpdate({
                    isRequiredINCOT2: false,
                    isReadonlyINCOT2: false, //true
                    IncoTerms2: 'Ex Works',
                });
                this.setInputPropsForDefaultRules('IncoTerms2', { disabled: false }); //readonlydropdown
            } else if (INCOT1_val === '8') {
                this.setBulkFormDataUpdate({
                    isRequiredINCOT2: false,
                    isReadonlyINCOT2: false, //true
                    IncoTerms2: 'Free Carrier',
                });
                this.setInputPropsForDefaultRules('IncoTerms2', { disabled: false }); //readonlydropdown
            } else {
                this.setBulkFormDataUpdate({
                    isRequiredINCOT2: false,
                    isReadonlyINCOT2: false,
                    IncoTerms2: '',
                });
                this.setInputPropsForDefaultRules('IncoTerms2', { disabled: false });
            }
        }
        // check for AccountTypeId - itmdmk1707- AccountTypeid made editable in al cases
        if (stateKey === 'CustomerGroupTypeId') {
            var cg_val = stateVal;
            if (cg_val === '1' || cg_val === '10') {
                this.setFormDataValues('AccountTypeId', '1');
                this.setInputPropsForDefaultRules('AccountTypeId', { disabled: false });
            } else if (cg_val === '2' || cg_val === '7') {
                this.setFormDataValues('AccountTypeId', '2');
                this.setInputPropsForDefaultRules('AccountTypeId', { disabled: false });
            } else if (
                cg_val === '3' ||
                cg_val === '4' ||
                cg_val === '6' ||
                cg_val === '11'
            ) {
                this.setFormDataValues('AccountTypeId', '3');
                this.setInputPropsForDefaultRules('AccountTypeId', { disabled: false });
            } else if (cg_val === '8') {
                this.setFormDataValues('AccountTypeId', '6');
                this.setInputPropsForDefaultRules('AccountTypeId', { disabled: false });
            } else {
                this.setFormDataValues('AccountTypeId', '');
                this.setInputPropsForDefaultRules('AccountTypeId', { disabled: false });
            }
        }
    };

    validateFromSourceData = (source_data) => {
        const readOnlyDropDown = { disabled: true };
        const newStateValue = {},
            newStyleProps = {};
        //check License Number
        let d_LN_RegionsList = [
            'DE',
            'FL',
            'GA',
            'HI',
            'IL',
            'IN',
            'KS',
            'MA',
            'ME',
            'MN',
            'MO',
            'NC',
            'ND',
            'NE',
            'NM',
            'OH',
            'OK',
            'RI',
            'SD',
            'VT',
            'WA',
            'WV',
        ];
        if (
            source_data.RoleTypeId === 1 ||
            source_data.RoleTypeId === 2 ||
            source_data.RoleTypeId === 5 ||
            source_data.RoleTypeId === 6
        ) {
            newStateValue['display_LN'] = true;
            if (source_data.RoleTypeId === 5) {
                newStateValue['License'] = 'R-SALES REP EXEMPT';
                newStateValue['LicenseExpDate'] = '9999-12-31';
            } else if (source_data.Country != 'US') {
                newStateValue['License'] = 'I-INTERNATIONAL EXEMPT';
                newStateValue['LicenseExpDate'] = '9999-12-31';
            } else if (d_LN_RegionsList.includes(source_data.Region)) {
                newStateValue['License'] = 'S-IN STATE EXEMPT APPROVAL SM';
                newStateValue['LicenseExpDate'] = '9999-12-31';
            }
        }
        //check transportation zone
        let d_TransporationZone_RegionList = ['NS', 'NT', 'NU', 'PE', 'SK', 'YT'];
        if (source_data.Country === 'US' || source_data.Country === 'PR') {
            newStateValue[
                'TransporationZone'
            ] = source_data.PostalCode.toString().substring(0, 3);
        } else if (
            source_data.Country === 'CA' &&
            d_TransporationZone_RegionList.includes(source_data.Region)
        ) {
            newStateValue['TransporationZone'] = 'INTL';
        } else if (source_data.Country === 'CA') {
            newStateValue['TransporationZone'] = source_data.Region;
        } else {
            newStateValue['TransporationZone'] = 'INTL';
        }

        //check price list
        if (source_data.Country != 'US') {
            newStateValue['PriceListTypeId'] = '5';
            // newStyleProps['PriceListTypeId'] = readOnlyDropDown; -ITMDMK1707
            newStyleProps['PriceListTypeId'] = { disabled: false };
        } else {
            newStateValue['PriceListTypeId'] = 0;
            newStyleProps['PriceListTypeId'] = { disabled: false };
        }

        //check Customer group --as per #ITMDMK1756 customergroup is editable in all cases.
        if (source_data.CategoryTypeId != undefined) {
            let categoryTypeid = parseAndSanitise(source_data.CategoryTypeId);
            if (categoryTypeid === 2) {
                //if self-distributor
                newStateValue['CustomerGroupTypeId'] = '5';
                newStyleProps['CustomerGroupTypeId'] = { disabled: false }; // readOnlyDropDown;
            } else if (categoryTypeid === 3 || categoryTypeid === 6) {
                //if oem or kitter
                newStateValue['CustomerGroupTypeId'] = '9';
                newStyleProps['CustomerGroupTypeId'] = { disabled: false }; // readOnlyDropDown;;
            } else if (categoryTypeid === 7) {
                // if dropship
                newStateValue['AccountTypeId'] = '3';
                // newStyleProps['AccountTypeId'] = readOnlyDropDown; -ITMDMK1707
                newStyleProps['AccountTypeId'] = { disabled: false };
                newStateValue['CustomerGroupTypeId'] = '11';
                newStyleProps['CustomerGroupTypeId'] = { disabled: false }; // readOnlyDropDown;;
            }
        }
        //check shipping conditions
        if (source_data.Country != 'US') {
            newStateValue['ShippingConditionsTypeId'] = '2';
            // newStyleProps['ShippingConditionsTypeId'] = readOnlyDropDown; ITMDMK1707
            newStyleProps['ShippingConditionsTypeId'] = { disabled: false };
        } else {
            newStateValue['ShippingConditionsTypeId'] = '1';
            // newStyleProps['ShippingConditionsTypeId'] = readOnlyDropDown;
            newStyleProps['ShippingConditionsTypeId'] = { disabled: false };
        }

        if (source_data.RoleTypeId == 5) {
            newStyleProps['ShippingConditionsTypeId'] = { disabled: false };
        }

        return { newStateValue, newStyleProps };
    };

    handleFormSubmission = (schema) => {
        let {
            TaskId,
            WorkflowId,
            formData,
            selectedFiles,
            selectedFilesIds,
            teamRoleFieldMapping,
        } = this.state,
            castedFormData = {};

        formData = {
            ...formData,
            ...this.props.dunsFields,
        };
        const {
            functionalGroupDetails: { Customer: globalMdmDetail = {} },
        } = this.props;

        try {
            const WorkflowTaskModel = {
                RejectReason: formData['RejectionButton']
                    ? formData['RejectionReason']
                    : '',
                TaskId: TaskId,
                UserId: localStorage.getItem('userId'),
                WorkflowId: WorkflowId,
                WorkflowTaskOperationType: !formData['RejectionButton'] ? 1 : 2,
            };
            if (!formData['RejectionButton']) {
                castedFormData = schema.cast(formData);
            } else {
                castedFormData = formData;
            }
            delete castedFormData.RejectionButton;
            delete castedFormData.displayINCOT2;
            delete castedFormData.display_LN;
            delete castedFormData.Role;

            let postData = {};
            if (this.isSystemPointman())
                postData['formData'] = {
                    WorkflowTaskModel,
                    BillToCustomerNumber: formData.BillToCustomerNumber,
                    ShipToCustomerNumber: formData.ShipToCustomerNumber,
                    MarketingSegmentationTypeId: formData.MarketingSegmentationTypeId,
                    DunsNumber: formData.DunsNumber,
                    AdditionalNotes: formData.AdditionalNotes,
                    SicCode: formData.SicCode,
                    SicCode4: formData.SicCode4,
                    SicCode6: formData.SicCode6,
                    SicCode8: formData.SicCode8,
                    SicCodeDescription: formData.SicCodeDescription,
                    TaxNumber: formData.TaxNumber,
                    VatRegNo: formData.VatRegNo,
                    NaicsCode: formData.NaicsCode,
                    NaicsCodeDescription: formData.NaicsCodeDescription,
                    MdmNumberToGroup: formData.MdmNumberToGroup || null,
                };
            else {
                const CustomerMasterTeamFields =
                    idx(teamRoleFieldMapping, (_) => _.CustomerMasterTeamFields) || [];
                const RoleTypeId = globalMdmDetail.RoleTypeId;
                const SystemTypeId = globalMdmDetail.SystemTypeId;
                const allowedFieldList = CustomerMasterTeamFields.filter(
                    (item) =>
                        item.RoleTypeId === RoleTypeId &&
                        item.SystemTypeId === SystemTypeId
                );
                let allowedFields = undefined;
                if (allowedFieldList && allowedFieldList.length > 0) {
                    allowedFields = allowedFieldList[0].Fields;
                }
                // console.log(allowedFieldList)
                castedFormData['TransporationZone'] =
                    allowedFields && allowedFields.includes('TransporationZone')
                        ? castedFormData['TransporationZone']
                        : undefined;
                castedFormData['License'] =
                    allowedFields && allowedFields.includes('License')
                        ? castedFormData['License']
                        : undefined;
                castedFormData['LicenseExpDate'] =
                    allowedFields && allowedFields.includes('LicenseExpDate')
                        ? castedFormData['LicenseExpDate']
                        : undefined;
                castedFormData['SearchTerm1'] =
                    allowedFields && allowedFields.includes('SearchTerm1')
                        ? castedFormData['SearchTerm1']
                        : undefined;
                castedFormData['SearchTerm2'] =
                    allowedFields && allowedFields.includes('SearchTerm2')
                        ? castedFormData['SearchTerm2']
                        : undefined;
                castedFormData['CustomerClassTypeId'] =
                    allowedFields && allowedFields.includes('CustomerClassTypeId')
                        ? castedFormData['CustomerClassTypeId']
                        : undefined;
                castedFormData['CustomerPriceProcTypeId'] =
                    allowedFields && allowedFields.includes('CustomerPriceProcTypeId')
                        ? castedFormData['CustomerPriceProcTypeId']
                        : undefined;
                castedFormData['IndustryCodeTypeId'] =
                    allowedFields && allowedFields.includes('IndustryCodeTypeId')
                        ? castedFormData['IndustryCodeTypeId']
                        : undefined;
                castedFormData['ReconAccountTypeId'] =
                    allowedFields && allowedFields.includes('ReconAccountTypeId')
                        ? castedFormData['ReconAccountTypeId']
                        : undefined;
                castedFormData['AccountTypeId'] =
                    allowedFields && allowedFields.includes('AccountTypeId')
                        ? castedFormData['AccountTypeId']
                        : undefined;
                castedFormData['ShippingCustomerTypeId'] =
                    allowedFields && allowedFields.includes('ShippingCustomerTypeId')
                        ? castedFormData['ShippingCustomerTypeId']
                        : undefined;
                castedFormData['AcctAssignmentGroupTypeId'] =
                    allowedFields && allowedFields.includes('AcctAssignmentGroupTypeId')
                        ? castedFormData['AcctAssignmentGroupTypeId']
                        : undefined;
                castedFormData['TaxNumber2'] =
                    allowedFields && allowedFields.includes('TaxNumber2')
                        ? castedFormData['TaxNumber2']
                        : undefined;
                castedFormData['SortKey'] =
                    allowedFields && allowedFields.includes('SortKey')
                        ? castedFormData['SortKey']
                        : undefined;
                castedFormData['PaymentMethods'] =
                    allowedFields && allowedFields.includes('PaymentMethods')
                        ? castedFormData['PaymentMethods']
                        : undefined;
                castedFormData['AcctgClerk'] =
                    allowedFields && allowedFields.includes('AcctgClerk')
                        ? castedFormData['AcctgClerk']
                        : undefined;
                castedFormData['AccountStatementTypeId'] =
                    allowedFields && allowedFields.includes('AccountStatementTypeId')
                        ? castedFormData['AccountStatementTypeId']
                        : undefined;
                castedFormData['TaxClassificationTypeId'] =
                    allowedFields && allowedFields.includes('TaxClassificationTypeId')
                        ? castedFormData['TaxClassificationTypeId']
                        : undefined;
                castedFormData['PriceListTypeId'] =
                    allowedFields && allowedFields.includes('PriceListTypeId')
                        ? castedFormData['PriceListTypeId']
                        : undefined;
                castedFormData['DeliveryPriorityTypeId'] =
                    allowedFields && allowedFields.includes('DeliveryPriorityTypeId')
                        ? castedFormData['DeliveryPriorityTypeId']
                        : undefined;
                castedFormData['ShippingConditionsTypeId'] =
                    allowedFields && allowedFields.includes('ShippingConditionsTypeId')
                        ? castedFormData['ShippingConditionsTypeId']
                        : undefined;
                castedFormData['IncoTerms1TypeId'] =
                    allowedFields && allowedFields.includes('IncoTerms1TypeId')
                        ? castedFormData['IncoTerms1TypeId']
                        : undefined;
                castedFormData['IncoTerms2'] =
                    allowedFields && allowedFields.includes('IncoTerms2')
                        ? castedFormData['IncoTerms2']
                        : undefined;
                castedFormData['CustomerGroupTypeId'] =
                    allowedFields && allowedFields.includes('CustomerGroupTypeId')
                        ? castedFormData['CustomerGroupTypeId']
                        : undefined;
                castedFormData['PpcustProcTypeId'] =
                    allowedFields && allowedFields.includes('PpcustProcTypeId')
                        ? castedFormData['PpcustProcTypeId']
                        : undefined;
                castedFormData['SalesOfficeTypeId'] =
                    allowedFields && allowedFields.includes('SalesOfficeTypeId')
                        ? castedFormData['SalesOfficeTypeId']
                        : undefined;
                castedFormData['OrderCombination'] =
                    allowedFields && allowedFields.includes('OrderCombination')
                        ? castedFormData['OrderCombination']
                        : undefined;
                castedFormData['PaymentHistoryRecord'] =
                    allowedFields && allowedFields.includes('PaymentHistoryRecord')
                        ? castedFormData['PaymentHistoryRecord']
                        : undefined;
                console.log(castedFormData);
                postData['formData'] = {
                    WorkflowTaskModel,
                    MarketingSegmentationTypeId: formData.MarketingSegmentationTypeId,
                    ...castedFormData,
                };
            }
            postData['files'] = selectedFilesIds.map((id) => selectedFiles[id]);
            // console.log('postData---',postData)
            this.props.saveApolloMyTaskCustomerMaster(postData);
            this.resetForm();
            this.scrollToTop();
        } catch (error) {
            console.log(error);
        }
    };

    isSystemPointman = () => {
        const {
            functionalGroupDetails: { Customer: globalMdmDetail = {} },
        } = this.props;
        const isPointman = globalMdmDetail && globalMdmDetail.SystemTypeId === 3;

        return isPointman;
    };
    onSubmit = (event, reject, _schema) => {
        const {
            functionalGroupDetails: { Customer: globalMdmDetail = {} },
        } = this.props;

        const schema =
            this.isSystemPointman() && !reject ? ptmnCustomerMasterRules : _schema;
        let isContractEnabled = false;
        //check iff contract fields to be displayed or not
        if (globalMdmDetail && globalMdmDetail.CategoryTypeId != undefined) {
            let categoryTypeid = parseAndSanitise(globalMdmDetail.CategoryTypeId);
            if (
                categoryTypeid === 1 ||
                categoryTypeid === 2 ||
                categoryTypeid === 3 ||
                categoryTypeid === 6
            ) {
                isContractEnabled = true;
            }
        }

        let { formData, selectedFilesIds, selectedFiles } = this.state;
        let fileErrors = {};
        let errors = false;
        selectedFilesIds.map((id) => {
            if (selectedFiles[id] && selectedFiles[id].DocumentType <= 0) {
                fileErrors[id] = 'Document Type Required for file';
                errors = true;
            }
        });

        this.setState({ fileErrors, isFileErrors: errors });
        this.setState(
            {
                modalVisible: false,
                formData: {
                    ...this.state.formData,
                    RejectionButton: reject,
                },
            },
            () => {
                yupAllFieldsValidation(
                    {
                        ...this.state.formData,
                        isContractEnabled: isContractEnabled,
                        ...(this.isSystemPointman() && {
                            SystemTypeId: globalMdmDetail.SystemTypeId,
                            RoleTypeId: globalMdmDetail.RoleTypeId,
                        }),
                    },
                    schema,
                    (...rest) => {
                        if (this.state.isFileErrors === false)
                            this.handleFormSubmission(...rest);
                    },
                    this.setFormErrors
                );
            }
        );
    };

    scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    resetFormData = () => {
        Object.keys(this.state.formData).map((key) => {
            var myitem = key;
            if (
                ![
                    'OrderCombination',
                    'PaymentHistoryRecord',
                    'RejectionButton',
                    'displayINCOT2',
                    'display_LN',
                ].includes(myitem)
            ) {
                this.setState({
                    formData: {
                        ...this.state.formData,
                        [key]: '',
                    },
                });
            } else {
                if (!['displayINCOT2', 'display_LN'].includes(myitem)) {
                    this.setState({
                        formData: {
                            ...this.state.formData,
                            [key]: false,
                        },
                    });
                }
            }
        });
    };

    resetForm = () => {
        let { formErrors } = this.state;

        Object.keys(this.state.formErrors).forEach((key) => (formErrors[key] = ''));

        this.setState(
            {
                formErrors,
            },
            this.resetFormData
        );
    };

    selectFiles = (events) => {
        event.preventDefault();

        const { selectedFilesIds, selectedFiles } = this.state;
        const id = events.target.files[0].name;

        this.setState({
            selectedFiles: {
                ...selectedFiles,
                [id]: {
                    data: events.target.files[0],
                    DocumentName: events.target.files[0].name,
                    DocumentType: 0,
                },
            },
            selectedFilesIds: [...selectedFilesIds, id],
            filename: events.target.files[0].name,
            fileinputkey: Date.now(),
        });
    };

    removeFile = (id) => {
        event.preventDefault();

        var selectedFilesDict = this.state.selectedFiles;
        var selectedFilesIdsList = this.state.selectedFilesIds;
        // remove the existing state
        delete selectedFilesDict[id];
        selectedFilesIdsList = selectedFilesIdsList.filter((fileid) => fileid !== id);

        this.setState({
            selectedFiles: selectedFilesDict,
            selectedFilesIds: selectedFilesIdsList,
        });
    };

    renderMatches = (likelyMatches) => {
        const tableHeadings = [
            'Customer Name',
            'Customer Number',
            'Customer Address',
            'LocalMdm',
            'GlobalMdm',
            'Source',
            'Match Percentage',
        ];

        var data = [];
        var sortedMatchList = likelyMatches.sort((a, b) =>
            a.MatchPercentage < b.MatchPercentage ? 1 : -1
        );
        var matchData = sortedMatchList.map((match, index) => {
            let item_name = 'match' + index;
            let rqdata = [
                match.CustomerName,
                match.CustomerNumber,
                match.CustomerAddress,
                match.LocalMdm,
                match.GlobalMdm,
                match.Source,
                match.MatchPercentage,
            ];
            data.push(rqdata);
        });
        return (
            <TableComponent
                flexarray={[1.2, 1, 1.2, 1, 1, 1, 1, 1]}
                headings={tableHeadings}
                tableData={data}
                isFetching={false}
            />
        );
    };
    setMDM = (mdmObj) => {
        this.setState({
            formData: {
                ...this.state.formData,
                MdmNumberToGroup: mdmObj && mdmObj[0] && mdmObj[0]['MdmNumber'],
            },
        });
    };
    unCheckOthersExcept = (itemname, indexChosen, likelyMatches) => {
        //wf000000581819981
        // <CheckBoxItem
        //         name={item_name}
        //         stateValue={this.state[item_name] || match.IsChosen}
        //         onValueChange={() =>
        //             this.setState(
        //                 {
        //                     [item_name]: this.state[item_name]
        //                         ? !this.state[item_name]
        //                         : true,
        //                 },
        //                 () => this.unCheckOthersExcept(index, sortedMatchList)
        //             )
        //         }
        //     />,
        // this.setState([item_name]:this.state[item_name]?!this.state[item_name]:true})
        // var matchlength = likelyMatches.length;
        // var matchedItem = 'match' + indexChosen;
        // let toUncheck = {},
        //     newLikelyMatches = [],
        //     chosenMatch = {};
        // for (let i = 0; i < matchlength; i++) {
        //     var item = 'match' + i;
        //     if (i !== indexChosen) {
        //         toUncheck[item] = false;
        //     }
        //     chosenMatch = likelyMatches[i];
        //     chosenMatch['IsChosen'] = i !== indexChosen ? false : this.state[item];
        //     newLikelyMatches.push(chosenMatch);
        // }
        // this.setState({
        //     ...this.state,
        //     ...toUncheck,
        //     formData: {
        //         ...this.state.formData,
        //         LikelyMatches: newLikelyMatches,
        //     },
        // });
    };

    _renderModalContent = () => {
        const { formData } = this.state;
        return (
            <Box
                my={4}
                mx="30%"
                backgroundColor="white"
                borderRadius="4px"
                borderColor="rgba(0, 0, 0, 0.1)">
                <Text my={3} fontWeight="regular" color="lightBlue" fontSize={24} pl={4}>
                    Submit Workflow
                </Text>
                <Box px="0px" pb="22px" justifyContent="center">
                    <Box alignItems="center" justifyContent="center">
                        {!this.state.isReject ? (
                            <FormInput
                                label="Additional Notes"
                                multiline
                                numberOfLines={2}
                                maxLength={250}
                                name="AdditionalNotes"
                                type="text"
                                onChange={this.onFieldChange}
                                value={
                                    this.state.formData &&
                                    this.state.formData['AdditionalNotes']
                                }
                                variant={'solid'}
                            />
                        ) : (
                            <FormInput
                                label="Rejection Reason"
                                name="RejectionReason"
                                maxLength={250}
                                onChange={this.onFieldChange}
                                error={'*this field is required'}
                                multiline
                                numberOfLines={2}
                                type="text"
                                value={
                                    this.state.formData &&
                                    this.state.formData['RejectionReason']
                                }
                                variant="solid"
                            />
                        )}
                    </Box>
                    <Flex
                        justifyEnd
                        alignCenter
                        alignSelf="flex-end"
                        style={{
                            paddingTop: 15,
                            flexDirection: 'row',
                            alignItems: 'center',
                            paddingLeft: 10,
                            paddingRight: 15,
                            marginTop: 20,
                            marginBottom: 10,
                            marginHorizontal: 25,
                        }}>
                        <Button
                            onPress={(event) => {
                                if (this.state.isReject)
                                    this.setState({ isReject: false }, () =>
                                        this.onSubmit(event, true, rejectRules)
                                    );
                                else
                                    this.onSubmit(
                                        event,
                                        false,
                                        mytaskCustomerMasterRules
                                    );
                            }}
                            title="Submit"
                        />
                        <Button
                            title="Cancel"
                            onPress={() => this.setState({ modalVisible: null })}
                        />
                    </Flex>
                </Box>
            </Box>
        );
    };

    render() {
        const {
            width,
            location,
            functionalGroupDetails: {
                DocumentLocation: files,
                Customer: globalMdmDetail = {},
                CustomerMaster: customerMaster = null,
                TaskNote: Notes = '',
                RequestorName,
            },
            alert = {},
            statusBarData,
            TasksStatusByTeamId = null,
            fetching,
        } = this.props;

        const {
            dropDownDatas,
            teamRoleFieldMapping,
            inputPropsForDefaultRules,
            selectedFilesIds,
            selectedFiles,
        } = this.state;

        const { state } = location;
        const CustomerMasterTeamFields =
            idx(teamRoleFieldMapping, (_) => _.CustomerMasterTeamFields) || [];
        const RoleTypeId = globalMdmDetail.RoleTypeId;
        const SystemTypeId = globalMdmDetail.SystemTypeId;
        const allowedFieldList = CustomerMasterTeamFields.filter(
            (item) => item.RoleTypeId === RoleTypeId && item.SystemTypeId === SystemTypeId
        );
        let allowedFields = undefined;
        if (allowedFieldList && allowedFieldList.length > 0) {
            allowedFields = allowedFieldList[0].Fields;
        }
        var isReleased = true;
        for (let i = 0; i < statusBarData.length; i++) {
            let teamstatus = statusBarData[i];
            if (teamstatus.WorkflowTaskStateTypeId === 2) {
                isReleased = false;
                break;
            } else {
                isReleased = true;
            }
        }
        const workflow = {
            ...state,
            isReadOnly:
                TasksStatusByTeamId === null ||
                !(
                    globalMdmDetail.WorkflowStateTypeId === 2 &&
                    TasksStatusByTeamId[3].WorkflowTaskStateTypeId === 2
                ),
        };
        let isContractEnabled = false;
        //check iff contract fields to be displayed or not
        if (globalMdmDetail && globalMdmDetail.CategoryTypeId != undefined) {
            let categoryTypeid = parseAndSanitise(globalMdmDetail.CategoryTypeId);
            if (
                categoryTypeid === 1 ||
                categoryTypeid === 2 ||
                categoryTypeid === 3 ||
                categoryTypeid === 6
            ) {
                isContractEnabled = true;
            }
        }
        const isWorkFlowReadOnly = workflow.isReadOnly;

        const inputReadonlyProps = workflow.isReadOnly ? { disabled: true } : null;
        const requestDateUtc = moment(globalMdmDetail.DateOfCreation);
        const localDate = moment(requestDateUtc).local();
        const requestDate = localDate.format('YYYY-MM-DD hh:mm:ss A');

        const showFunctionalDetail =
            state.isReadOnly && customerMaster === null ? { display: 'none' } : null;

        const enableDisplay = isWorkFlowReadOnly ? { display: 'none' } : null;

        var bgcolor = alert.color || '#FFF';
        if (fetching) {
            return <Loading />;
        }

        const isPointman = globalMdmDetail && globalMdmDetail.SystemTypeId === 3;
        const isSFDC =
            globalMdmDetail &&
            globalMdmDetail.Sfdcid &&
            globalMdmDetail.Sfdcid.length > 0;

        return (
            <ScrollView
                keyboardShouldPersistTaps="always"
                style={{
                    backgroundColor: '#EFF3F6',
                    paddingBottom: 75,
                }}>
                <Modal isVisible={this.state.modalVisible}>
                    {this._renderModalContent()}
                </Modal>
                <FlashMessages
                    toasts={this.props.toasts}
                    onDismiss={this.props.removeMessage}
                />
                {alert.display && (
                    <FlashMessage
                        bg={{ backgroundColor: bgcolor }}
                        message={alert.message}
                        showDuration={7000}
                    />
                )}

                <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between">
                    <Text
                        style={{
                            fontWeight: '500',
                            fontSize: 24,
                            color: Colors.lightBlue,
                            marginTop: 25,
                            marginBottom: 20,
                            paddingLeft: 45,
                        }}>
                        MY TASKS - {state.Header}
                    </Text>
                    <Box
                        marginTop="25px"
                        flex={3 / 4}
                        flexDirection="row-reverse"
                        alignItems="flex-end">
                        <MultiColorProgressBar readings={statusBarData} />
                    </Box>
                </Box>
                <Box marginTop="25px" flexDirection="row-reverse" alignItems="flex-end">
                    <StyledText
                        style={{
                            fontWeight: '600',
                            paddingRight: '20px',
                            fontSize: '16px',
                            color: '#10206C',
                        }}>
                        {' '}
                        Requestor : {RequestorName}{' '}
                    </StyledText>
                </Box>
                <Box flexDirection="row-reverse" alignItems="flex-end">
                    <StyledText
                        style={{
                            fontWeight: '600',
                            paddingRight: '20px',
                            fontSize: '16px',
                            color: '#10206C',
                        }}>
                        {' '}
                        Date of Request: {requestDate}{' '}
                    </StyledText>
                </Box>
                <View
                    style={{
                        flex: 1,
                        paddingHorizontal: width < 1440 ? 60 : width * 0.1,
                        paddingBottom: 10,
                    }}>
                    <Box fullHeight my={2}>
                        <Box flexDirection="row" justifyContent="space-around" my={4}>
                            <FormInput
                                px="25px"
                                flex={1 / 4}
                                label="WorkflowTitle"
                                maxLength={40}
                                name="WorkflowTitle"
                                variant="outline"
                                type="text"
                                value={globalMdmDetail && globalMdmDetail.WorkflowTitle}
                            />
                            <FormInput
                                px="25px"
                                flex={1 / 4}
                                label="Workflow Number"
                                name="workflow-number"
                                variant="outline"
                                type="text"
                                value={globalMdmDetail && globalMdmDetail.WorkflowId}
                            />
                            {globalMdmDetail.SystemRecordId && (
                                <FormInput
                                    px="25px"
                                    flex={1 / 4}
                                    label="Customer Number"
                                    name="CustomerNumber"
                                    variant="outline"
                                    type="text"
                                    value={globalMdmDetail.SystemRecordId}
                                />
                            )}
                            {globalMdmDetail &&
                                globalMdmDetail.MdmCustomerId &&
                                (isReleased ? (
                                    <TouchableOpacity
                                        onPress={() =>
                                            this.props.history.push(
                                                `/search-results/${globalMdmDetail.MdmCustomerId}`
                                            )
                                        }>
                                        <FormInput
                                            px="25px"
                                            flex={1 / 4}
                                            label="MDM Number"
                                            name="mdm-number"
                                            variant="outline"
                                            type="text"
                                            value={
                                                globalMdmDetail &&
                                                globalMdmDetail.MdmCustomerId
                                            }
                                        />
                                    </TouchableOpacity>
                                ) : (
                                    <FormInput
                                        px="25px"
                                        flex={1 / 4}
                                        label="MDM Number"
                                        name="mdm-number"
                                        variant="outline"
                                        type="text"
                                        value={
                                            globalMdmDetail &&
                                            globalMdmDetail.MdmCustomerId
                                        }
                                    />
                                ))}
                        </Box>

                        <GlobalMdmFields
                            showMarketingSegmentation={true} //Apollo Marketseg field made readonly as on 09/09
                            formData={{
                                ...globalMdmDetail,
                                DunsNumber:
                                    this.state.formData.DunsNumber ||
                                    globalMdmDetail.DunsNumber,
                                MarketingSegmentationTypeId:
                                    idx(
                                        this.state,
                                        (_) => _.formData.MarketingSegmentationTypeId
                                    ) ||
                                    idx(
                                        customerMaster,
                                        (_) => _.MarketingSegmentationTypeId
                                    ) ||
                                    idx(
                                        globalMdmDetail,
                                        (_) => _.MarketingSegmentationTypeId
                                    ),
                                TaxNumber:
                                    this.state.formData.TaxNumber ||
                                    globalMdmDetail.TaxNumber,
                                VatRegNo:
                                    this.state.formData.VatRegNo ||
                                    globalMdmDetail.VatRegNo,
                            }}
                            // removing as mohit said to remove this logic on 30Dec  ---  dunsEditable={!workflow.isReadOnly}
                            //   removing as mohit said to remove this logic on 30Dec  --- editableInputs={{ TaxNumber: true, VatRegNo: true }}
                            onDunsNewInput={this.onDunsNewInput}
                            readOnly
                            formErrors={this.state.formErrors}
                            onFieldChange={this.onFieldChange}
                            staticDropDownData={this.props.staticData}
                            isSFDC={isSFDC}
                        />

                        <Text
                            mt="42px"
                            mb="20px"
                            fontWeight="regular"
                            color="lightBlue"
                            fontSize={24}
                            pl={4}>
                            SYSTEM FIELDS
                        </Text>
                        <Box flexDirection="row" justifyContent="center">
                            <Box width={1 / 2} mx="auto" alignItems="center">
                                <FormInput
                                    label="System"
                                    name="System"
                                    inline
                                    variant="outline"
                                    type="text"
                                    value={
                                        SystemType[
                                        globalMdmDetail &&
                                        globalMdmDetail.SystemTypeId
                                        ]
                                    }
                                />
                                <FormInput
                                    label="Role"
                                    name="Role"
                                    inline
                                    variant="outline"
                                    type="text"
                                    value={
                                        isPointman
                                            ? PtmnRoleType[
                                            globalMdmDetail &&
                                            globalMdmDetail.RoleTypeId
                                            ]
                                            : RoleType[
                                            globalMdmDetail &&
                                            globalMdmDetail.RoleTypeId
                                            ]
                                    }
                                />
                                <FormInput
                                    label="Sales Org"
                                    name="SalesOrg"
                                    inline
                                    variant="outline"
                                    type="text"
                                    hide={isPointman}
                                    value={
                                        SalesOrgType[
                                        globalMdmDetail &&
                                        globalMdmDetail.SalesOrgTypeId
                                        ]
                                    }
                                />
                                <FormInput
                                    label="Purpose of Request"
                                    name="Purpose"
                                    multiline
                                    value={globalMdmDetail && globalMdmDetail.Purpose}
                                    numberOfLines={
                                        globalMdmDetail.Purpose &&
                                            globalMdmDetail.Purpose.length > 36
                                            ? 3
                                            : 1
                                    }
                                    style={{ lineHeight: '2.5' }}
                                    inline
                                    maxLength={1000}
                                    variant="outline"
                                    type="text"
                                />
                            </Box>
                            {!isPointman ? (
                                <Box width={1 / 2} mx="auto" alignItems="center">
                                    <FormInput
                                        label="Distribution Channel"
                                        name="DistributionChannel"
                                        inline
                                        variant="outline"
                                        type="text"
                                        value={
                                            DistributionChannelType[
                                            globalMdmDetail &&
                                            globalMdmDetail.DistributionChannelTypeId
                                            ]
                                        }
                                    />
                                    <FormInput
                                        label="Division"
                                        name="DivisionTypeId"
                                        inline
                                        variant="outline"
                                        type="text"
                                        value={
                                            DivisionType[
                                            globalMdmDetail &&
                                            globalMdmDetail.DivisionTypeId
                                            ]
                                        }
                                    />
                                    <FormInput
                                        label="CompanyCode"
                                        name="CompanyCodeTypeId"
                                        inline
                                        variant="outline"
                                        type="text"
                                        value={
                                            CompanyCodeType[
                                            globalMdmDetail &&
                                            globalMdmDetail.CompanyCodeTypeId
                                            ]
                                        }
                                    />
                                </Box>
                            ) : (
                                <Box width={1 / 2} mx="auto" alignItems="center">
                                    {globalMdmDetail.RoleTypeId !== 4 && (
                                        <FormInput
                                            label="Ship To Customer Number"
                                            name="ShipToCustomerNumber"
                                            type="text"
                                            value={
                                                isWorkFlowReadOnly
                                                    ? customerMaster &&
                                                    customerMaster.ShipToCustomerNumber
                                                    : this.state.formData
                                                        ? this.state.formData[
                                                        'ShipToCustomerNumber'
                                                        ]
                                                        : null
                                            }
                                            error={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                    'shipToCustomerValidation'
                                                    ]
                                                    : null
                                            }
                                            onChange={this.onFieldChange}
                                            maxLength={12}
                                            variant={
                                                isWorkFlowReadOnly ? 'outline' : 'solid'
                                            }
                                            inline={isWorkFlowReadOnly ? true : false}
                                            required
                                        />
                                    )}
                                    <FormInput
                                        label="Bill To Customer"
                                        name="BillToCustomerNumber"
                                        type="text"
                                        value={
                                            isWorkFlowReadOnly
                                                ? customerMaster &&
                                                customerMaster.BillToCustomerNumber
                                                : this.state.formData
                                                    ? this.state.formData[
                                                    'BillToCustomerNumber'
                                                    ]
                                                    : null
                                        }
                                        onChange={this.onFieldChange}
                                        error={
                                            this.state.formErrors
                                                ? this.state.formErrors[
                                                'BillToCustomerNumber'
                                                ]
                                                : null
                                        }
                                        maxLength={17}
                                        variant={isWorkFlowReadOnly ? 'outline' : 'solid'}
                                        required={!isWorkFlowReadOnly}
                                    />
                                </Box>
                            )}
                        </Box>

                        {!isPointman && (
                            <Box {...showFunctionalDetail}>
                                <Text
                                    mt="42px"
                                    mb="20px"
                                    fontWeight="regular"
                                    color="lightBlue"
                                    fontSize={24}
                                    pl={4}>
                                    CUSTOMER MASTER FIELDS
                                </Text>
                                <Box flexDirection="row" justifyContent="center">
                                    <Box width={1 / 2} mx="auto" alignItems="center">
                                        <FormInput
                                            label="Transportation Zone"
                                            name="TransporationZone"
                                            maxLength={10}
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes(
                                                    'TransporationZone'
                                                )
                                            }
                                            error={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                    'TransporationZone'
                                                    ]
                                                    : null
                                            }
                                            onChange={this.onFieldChange}
                                            type="text"
                                            required
                                            value={
                                                isWorkFlowReadOnly
                                                    ? customerMaster &&
                                                    customerMaster.TransporationZone
                                                    : this.state.formData
                                                        ? this.state.formData[
                                                        'TransporationZone'
                                                        ]
                                                        : null
                                            }
                                            variant={
                                                isWorkFlowReadOnly ? 'outline' : 'solid'
                                            }
                                            inline={isWorkFlowReadOnly ? true : false}
                                        />
                                        {this.state.formData['display_LN'] ? (
                                            <>
                                                <FormInput
                                                    required
                                                    label="License Number"
                                                    name="License"
                                                    hide={
                                                        allowedFields &&
                                                        !allowedFields.includes('License')
                                                    }
                                                    maxLength={30}
                                                    error={
                                                        this.state.formErrors
                                                            ? this.state.formErrors[
                                                            'License'
                                                            ]
                                                            : null
                                                    }
                                                    onChange={this.onFieldChange}
                                                    type="text"
                                                    value={
                                                        isWorkFlowReadOnly
                                                            ? customerMaster &&
                                                            customerMaster.License
                                                            : this.state.formData
                                                                ? this.state.formData[
                                                                'License'
                                                                ]
                                                                : null
                                                    }
                                                    variant={
                                                        isWorkFlowReadOnly
                                                            ? 'outline'
                                                            : 'solid'
                                                    }
                                                    inline={
                                                        isWorkFlowReadOnly ? true : false
                                                    }
                                                />
                                                <FormInput
                                                    label="License Expiration Date"
                                                    min={
                                                        workflow.isReadOnly
                                                            ? null
                                                            : moment().format(
                                                                'YYYY-MM-DD'
                                                            )
                                                    }
                                                    max={'9999-12-31'}
                                                    name="LicenseExpDate"
                                                    hide={
                                                        allowedFields &&
                                                        !allowedFields.includes(
                                                            'LicenseExpDate'
                                                        )
                                                    }
                                                    onChange={(value, element) => {
                                                        element.preventDefault();
                                                        this.onFieldChange(
                                                            new Date(value)
                                                                .toJSON()
                                                                .slice(0, 19),
                                                            element
                                                        );
                                                    }}
                                                    error={
                                                        this.state.formErrors
                                                            ? this.state.formErrors[
                                                            'LicenseExpDate'
                                                            ]
                                                            : null
                                                    }
                                                    type="date"
                                                    required
                                                    value={
                                                        isWorkFlowReadOnly
                                                            ? customerMaster &&
                                                            customerMaster.LicenseExpDate &&
                                                            customerMaster.LicenseExpDate.split(
                                                                'T'
                                                            )[0]
                                                            : this.state.formData
                                                                ? this.state.formData[
                                                                'LicenseExpDate'
                                                                ]
                                                                : null
                                                    }
                                                    variant={
                                                        isWorkFlowReadOnly
                                                            ? 'outline'
                                                            : 'solid'
                                                    }
                                                    inline={
                                                        isWorkFlowReadOnly ? true : false
                                                    }
                                                />
                                            </>
                                        ) : null}
                                        <FormInput
                                            label="Search Term 1"
                                            name="SearchTerm1"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes('SearchTerm1')
                                            }
                                            maxLength={20}
                                            error={
                                                this.state.formErrors
                                                    ? this.state.formErrors['SearchTerm1']
                                                    : null
                                            }
                                            onChange={this.onFieldChange}
                                            type="text"
                                            value={
                                                isWorkFlowReadOnly
                                                    ? customerMaster &&
                                                    customerMaster.SearchTerm1
                                                    : this.state.formData
                                                        ? this.state.formData['SearchTerm1']
                                                        : null
                                            }
                                            variant={
                                                isWorkFlowReadOnly ? 'outline' : 'solid'
                                            }
                                            inline={isWorkFlowReadOnly ? true : false}
                                        />
                                        <FormInput
                                            label="Search Term 2"
                                            name="SearchTerm2"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes('SearchTerm2')
                                            }
                                            maxLength={20}
                                            error={
                                                this.state.formErrors
                                                    ? this.state.formErrors['SearchTerm2']
                                                    : null
                                            }
                                            onChange={this.onFieldChange}
                                            type="text"
                                            value={
                                                isWorkFlowReadOnly
                                                    ? customerMaster &&
                                                    customerMaster.SearchTerm2
                                                    : this.state.formData
                                                        ? this.state.formData['SearchTerm2']
                                                        : null
                                            }
                                            variant={
                                                isWorkFlowReadOnly ? 'outline' : 'solid'
                                            }
                                            inline={isWorkFlowReadOnly ? true : false}
                                        />
                                        <DynamicSelect
                                            arrayOfData={
                                                dropDownDatas.CustomerClassTypeId
                                            }
                                            label="Customer Class "
                                            name="CustomerClassTypeId"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes(
                                                    'CustomerClassTypeId'
                                                )
                                            }
                                            isRequired={true}
                                            formErrors={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                    'CustomerClassTypeId'
                                                    ]
                                                    : null
                                            }
                                            onFieldChange={this.onFieldChange}
                                            value={
                                                isWorkFlowReadOnly
                                                    ? customerMaster &&
                                                    parseAndSanitise(
                                                        customerMaster.CustomerClassTypeId
                                                    )
                                                    : this.state.formData
                                                        ? this.state.formData[
                                                        'CustomerClassTypeId'
                                                        ]
                                                        : null
                                            }
                                            inputProps={inputReadonlyProps}
                                        />

                                        <DynamicSelect
                                            arrayOfData={
                                                dropDownDatas.CustomerPriceProcTypeId
                                            }
                                            label="Customer Price Proc"
                                            name="CustomerPriceProcTypeId"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes(
                                                    'CustomerPriceProcTypeId'
                                                )
                                            }
                                            isRequired={true}
                                            formErrors={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                    'CustomerPriceProcTypeId'
                                                    ]
                                                    : null
                                            }
                                            onFieldChange={this.onFieldChange}
                                            inputProps={
                                                isWorkFlowReadOnly
                                                    ? inputReadonlyProps
                                                    : inputPropsForDefaultRules[
                                                    'CustomerPriceProcTypeId'
                                                    ]
                                            }
                                            value={
                                                isWorkFlowReadOnly
                                                    ? customerMaster &&
                                                    parseAndSanitise(
                                                        customerMaster.CustomerPriceProcTypeId
                                                    )
                                                    : this.state.formData
                                                        ? this.state.formData[
                                                        'CustomerPriceProcTypeId'
                                                        ]
                                                        : null
                                            }
                                        />
                                        <DynamicSelect
                                            arrayOfData={dropDownDatas.IndustryCodeTypeId}
                                            label="Industry Code 1"
                                            name="IndustryCodeTypeId"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes(
                                                    'IndustryCodeTypeId'
                                                )
                                            }
                                            isRequired={false}
                                            formErrors={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                    'IndustryCodeTypeId'
                                                    ]
                                                    : null
                                            }
                                            onFieldChange={this.onFieldChange}
                                            value={
                                                isWorkFlowReadOnly
                                                    ? customerMaster &&
                                                    parseAndSanitise(
                                                        customerMaster.IndustryCodeTypeId
                                                    )
                                                    : this.state.formData
                                                        ? this.state.formData[
                                                        'IndustryCodeTypeId'
                                                        ]
                                                        : null
                                            }
                                            inputProps={inputReadonlyProps}
                                        />
                                        <DynamicSelect
                                            arrayOfData={dropDownDatas.ReconAccountTypeId}
                                            label="Recon Account"
                                            name="ReconAccountTypeId"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes(
                                                    'ReconAccountTypeId'
                                                )
                                            }
                                            isRequired={true}
                                            formErrors={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                    'ReconAccountTypeId'
                                                    ]
                                                    : null
                                            }
                                            onFieldChange={this.onFieldChange}
                                            value={
                                                isWorkFlowReadOnly
                                                    ? customerMaster &&
                                                    parseAndSanitise(
                                                        customerMaster.ReconAccountTypeId
                                                    )
                                                    : this.state.formData
                                                        ? this.state.formData[
                                                        'ReconAccountTypeId'
                                                        ]
                                                        : null
                                            }
                                            inputProps={inputReadonlyProps}
                                        />
                                        {!isContractEnabled && (
                                            <DynamicSelect
                                                arrayOfData={dropDownDatas.AccountTypeId}
                                                label="Account Type"
                                                name="AccountTypeId"
                                                hide={
                                                    allowedFields &&
                                                    !allowedFields.includes(
                                                        'AccountTypeId'
                                                    )
                                                }
                                                isRequired={true}
                                                formErrors={
                                                    this.state.formErrors
                                                        ? this.state.formErrors[
                                                        'AccountTypeId'
                                                        ]
                                                        : null
                                                }
                                                onFieldChange={this.onFieldChange}
                                                value={
                                                    isWorkFlowReadOnly
                                                        ? customerMaster &&
                                                        parseAndSanitise(
                                                            customerMaster.AccountTypeId
                                                        )
                                                        : this.state.formData
                                                            ? this.state.formData[
                                                            'AccountTypeId'
                                                            ]
                                                            : null
                                                }
                                                inputProps={
                                                    isWorkFlowReadOnly
                                                        ? inputReadonlyProps
                                                        : inputPropsForDefaultRules[
                                                        'AccountTypeId'
                                                        ]
                                                }
                                            />
                                        )}
                                        <DynamicSelect
                                            arrayOfData={
                                                dropDownDatas.ShippingCustomerTypeId
                                            }
                                            label="Shipping Customer Type"
                                            name="ShippingCustomerTypeId"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes(
                                                    'ShippingCustomerTypeId'
                                                )
                                            }
                                            isRequired={true}
                                            formErrors={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                    'ShippingCustomerTypeId'
                                                    ]
                                                    : null
                                            }
                                            onFieldChange={this.onFieldChange}
                                            value={
                                                isWorkFlowReadOnly
                                                    ? customerMaster &&
                                                    parseAndSanitise(
                                                        customerMaster.ShippingCustomerTypeId
                                                    )
                                                    : this.state.formData
                                                        ? this.state.formData[
                                                        'ShippingCustomerTypeId'
                                                        ]
                                                        : null
                                            }
                                            inputProps={inputReadonlyProps}
                                        />
                                        <DynamicSelect
                                            arrayOfData={
                                                dropDownDatas.AcctAssignmentGroupTypeId
                                            }
                                            label="Acct Assgmt Group"
                                            name="AcctAssignmentGroupTypeId"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes(
                                                    'AcctAssignmentGroupTypeId'
                                                )
                                            }
                                            isRequired={true}
                                            formErrors={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                    'AcctAssignmentGroupTypeId'
                                                    ]
                                                    : null
                                            }
                                            onFieldChange={this.onFieldChange}
                                            value={
                                                isWorkFlowReadOnly
                                                    ? customerMaster &&
                                                    parseAndSanitise(
                                                        customerMaster.AcctAssignmentGroupTypeId
                                                    )
                                                    : this.state.formData
                                                        ? this.state.formData[
                                                        'AcctAssignmentGroupTypeId'
                                                        ]
                                                        : null
                                            }
                                            inputProps={inputReadonlyProps}
                                        />
                                        {/*<FormInput*/}
                                        {/*    label="Partner Function Number"*/}
                                        {/*    name="PartnerFunctionNumber"*/}
                                        {/*    error={*/}
                                        {/*        this.state.formErrors*/}
                                        {/*            ? this.state.formErrors[*/}
                                        {/*                  'PartnerFunctionNumber'*/}
                                        {/*              ]*/}
                                        {/*            : null*/}
                                        {/*    }*/}
                                        {/*    onChange={this.onFieldChange}*/}
                                        {/*    type="text"*/}
                                        {/*    value={*/}
                                        {/*        isWorkFlowReadOnly*/}
                                        {/*            ? customerMaster &&*/}
                                        {/*              customerMaster.PartnerFunctionNumber*/}
                                        {/*            : this.state.formData*/}
                                        {/*            ? this.state.formData[*/}
                                        {/*                  'PartnerFunctionNumber'*/}
                                        {/*              ]*/}
                                        {/*            : null*/}
                                        {/*    }*/}
                                        {/*    variant={*/}
                                        {/*        isWorkFlowReadOnly*/}
                                        {/*            ? 'outline'*/}
                                        {/*            : 'solid'*/}
                                        {/*    }*/}
                                        {/*    inline={*/}
                                        {/*        isWorkFlowReadOnly ? true : false*/}
                                        {/*    }*/}
                                        {/*/>*/}
                                    </Box>
                                    <Box width={1 / 2} mx="auto" alignItems="center">
                                        <FormInput
                                            label="Tax Number 2"
                                            name="TaxNumber2"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes('TaxNumber2')
                                            }
                                            maxLength={11}
                                            error={
                                                this.state.formErrors
                                                    ? this.state.formErrors['TaxNumber2']
                                                    : null
                                            }
                                            onChange={this.onFieldChange}
                                            type="text"
                                            value={
                                                isWorkFlowReadOnly
                                                    ? customerMaster &&
                                                    customerMaster.TaxNumber2
                                                    : this.state.formData
                                                        ? this.state.formData['TaxNumber2']
                                                        : null
                                            }
                                            variant={
                                                isWorkFlowReadOnly ? 'outline' : 'solid'
                                            }
                                            inline={isWorkFlowReadOnly ? true : false}
                                            placeholder={'Only numeric values'}
                                        />
                                        <FormInput
                                            label="Sort Key"
                                            name="SortKey"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes('SortKey')
                                            }
                                            maxLength={3}
                                            error={
                                                this.state.formErrors
                                                    ? this.state.formErrors['SortKey']
                                                    : null
                                            }
                                            type="text"
                                            required
                                            onChange={this.onFieldChange}
                                            value={
                                                isWorkFlowReadOnly
                                                    ? customerMaster &&
                                                    customerMaster.SortKey
                                                    : this.state.formData
                                                        ? this.state.formData['SortKey']
                                                        : null
                                            }
                                            variant={
                                                isWorkFlowReadOnly ? 'outline' : 'solid'
                                            }
                                            inline={isWorkFlowReadOnly ? true : false}
                                        />
                                        <FormInput
                                            label="Payment Methods"
                                            name="PaymentMethods"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes('PaymentMethods')
                                            }
                                            maxLength={10}
                                            error={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                    'PaymentMethods'
                                                    ]
                                                    : null
                                            }
                                            type="text"
                                            required
                                            onChange={this.onFieldChange}
                                            value={
                                                isWorkFlowReadOnly
                                                    ? customerMaster &&
                                                    customerMaster.PaymentMethods
                                                    : this.state.formData
                                                        ? this.state.formData[
                                                        'PaymentMethods'
                                                        ]
                                                        : null
                                            }
                                            variant={
                                                isWorkFlowReadOnly ? 'outline' : 'solid'
                                            }
                                            inline={isWorkFlowReadOnly ? true : false}
                                        />
                                        <FormInput
                                            label="Acctg Clerk"
                                            name="AcctgClerk"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes('AcctgClerk')
                                            }
                                            maxLength={2}
                                            error={
                                                this.state.formErrors
                                                    ? this.state.formErrors['AcctgClerk']
                                                    : null
                                            }
                                            type="text"
                                            required
                                            onChange={this.onFieldChange}
                                            value={
                                                isWorkFlowReadOnly
                                                    ? customerMaster &&
                                                    customerMaster.AcctgClerk
                                                    : this.state.formData
                                                        ? this.state.formData['AcctgClerk']
                                                        : null
                                            }
                                            variant={
                                                isWorkFlowReadOnly ? 'outline' : 'solid'
                                            }
                                            inline={isWorkFlowReadOnly ? true : false}
                                        />

                                        <DynamicSelect
                                            arrayOfData={
                                                dropDownDatas.AccountStatementTypeId
                                            }
                                            label="Account Statement"
                                            name="AccountStatementTypeId"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes(
                                                    'AccountStatementTypeId'
                                                )
                                            }
                                            isRequired={true}
                                            formErrors={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                    'AccountStatementTypeId'
                                                    ]
                                                    : null
                                            }
                                            onFieldChange={this.onFieldChange}
                                            value={
                                                isWorkFlowReadOnly
                                                    ? customerMaster &&
                                                    parseAndSanitise(
                                                        customerMaster.AccountStatementTypeId
                                                    )
                                                    : this.state.formData
                                                        ? this.state.formData[
                                                        'AccountStatementTypeId'
                                                        ]
                                                        : null
                                            }
                                            inputProps={inputReadonlyProps}
                                        />

                                        <DynamicSelect
                                            arrayOfData={
                                                dropDownDatas.TaxClassificationTypeId
                                            }
                                            label="Tax Classification"
                                            name="TaxClassificationTypeId"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes(
                                                    'TaxClassificationTypeId'
                                                )
                                            }
                                            isRequired={true}
                                            formErrors={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                    'TaxClassificationTypeId'
                                                    ]
                                                    : null
                                            }
                                            onFieldChange={this.onFieldChange}
                                            value={
                                                isWorkFlowReadOnly
                                                    ? customerMaster &&
                                                    parseAndSanitise(
                                                        customerMaster.TaxClassificationTypeId
                                                    )
                                                    : this.state.formData
                                                        ? this.state.formData[
                                                        'TaxClassificationTypeId'
                                                        ]
                                                        : null
                                            }
                                            inputProps={inputReadonlyProps}
                                        />

                                        <DynamicSelect
                                            arrayOfData={dropDownDatas.PriceListTypeId}
                                            label="Price List"
                                            name="PriceListTypeId"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes('PriceListTypeId')
                                            }
                                            isRequired={true}
                                            formErrors={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                    'PriceListTypeId'
                                                    ]
                                                    : null
                                            }
                                            onFieldChange={this.onFieldChange}
                                            value={
                                                isWorkFlowReadOnly
                                                    ? customerMaster &&
                                                    parseAndSanitise(
                                                        customerMaster.PriceListTypeId
                                                    )
                                                    : this.state.formData
                                                        ? this.state.formData[
                                                        'PriceListTypeId'
                                                        ]
                                                        : null
                                            }
                                            inputProps={
                                                isWorkFlowReadOnly
                                                    ? inputReadonlyProps
                                                    : inputPropsForDefaultRules[
                                                    'PriceListTypeId'
                                                    ]
                                            }
                                        />

                                        <DynamicSelect
                                            arrayOfData={
                                                dropDownDatas.DeliveryPriorityTypeId
                                            }
                                            label="Delivery Priority"
                                            name="DeliveryPriorityTypeId"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes(
                                                    'DeliveryPriorityTypeId'
                                                )
                                            }
                                            isRequired={true}
                                            formErrors={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                    'DeliveryPriorityTypeId'
                                                    ]
                                                    : null
                                            }
                                            onFieldChange={this.onFieldChange}
                                            value={
                                                isWorkFlowReadOnly
                                                    ? customerMaster &&
                                                    parseAndSanitise(
                                                        customerMaster.DeliveryPriorityTypeId
                                                    )
                                                    : this.state.formData
                                                        ? this.state.formData[
                                                        'DeliveryPriorityTypeId'
                                                        ]
                                                        : null
                                            }
                                            inputProps={inputReadonlyProps}
                                        />

                                        <DynamicSelect
                                            arrayOfData={
                                                dropDownDatas.ShippingConditionsTypeId
                                            }
                                            label="Shipping Conditions"
                                            name="ShippingConditionsTypeId"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes(
                                                    'ShippingConditionsTypeId'
                                                )
                                            }
                                            isRequired={true}
                                            formErrors={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                    'ShippingConditionsTypeId'
                                                    ]
                                                    : null
                                            }
                                            onFieldChange={this.onFieldChange}
                                            value={
                                                isWorkFlowReadOnly
                                                    ? customerMaster &&
                                                    parseAndSanitise(
                                                        customerMaster.ShippingConditionsTypeId
                                                    )
                                                    : this.state.formData
                                                        ? this.state.formData[
                                                        'ShippingConditionsTypeId'
                                                        ]
                                                        : null
                                            }
                                            inputProps={
                                                isWorkFlowReadOnly
                                                    ? inputReadonlyProps
                                                    : inputPropsForDefaultRules[
                                                    'ShippingConditionsTypeId'
                                                    ]
                                            }
                                        />
                                        {!isContractEnabled && (
                                            <>
                                                <DynamicSelect
                                                    arrayOfData={
                                                        (this.props.staticData &&
                                                            this.props.staticData
                                                                .ApolloIncoTermsType) ||
                                                        []
                                                    }
                                                    label="Incoterms 1"
                                                    name="IncoTerms1TypeId"
                                                    hide={
                                                        allowedFields &&
                                                        !allowedFields.includes(
                                                            'IncoTerms1TypeId'
                                                        )
                                                    }
                                                    maxLength={28}
                                                    isRequired={true}
                                                    formErrors={
                                                        this.state.formErrors
                                                            ? this.state.formErrors[
                                                            'IncoTerms1TypeId'
                                                            ]
                                                            : null
                                                    }
                                                    onFieldChange={this.onFieldChange}
                                                    value={
                                                        isWorkFlowReadOnly
                                                            ? customerMaster &&
                                                            parseAndSanitise(
                                                                customerMaster.IncoTerms1TypeId
                                                            )
                                                            : this.state.formData
                                                                ? this.state.formData[
                                                                'IncoTerms1TypeId'
                                                                ]
                                                                : null
                                                    }
                                                    inputProps={inputReadonlyProps}
                                                />
                                                <FormInput
                                                    label="Incoterms 2"
                                                    name="IncoTerms2"
                                                    hide={
                                                        allowedFields &&
                                                        !allowedFields.includes(
                                                            'IncoTerms2'
                                                        )
                                                    }
                                                    maxLength={28}
                                                    onChange={this.onFieldChange}
                                                    error={
                                                        this.state.formErrors
                                                            ? this.state.formErrors[
                                                            'IncoTerms2'
                                                            ]
                                                            : null
                                                    }
                                                    type="text"
                                                    required={
                                                        this.state.formData
                                                            .isRequiredINCOT2
                                                    }
                                                    value={
                                                        isWorkFlowReadOnly
                                                            ? customerMaster &&
                                                            customerMaster.IncoTerms2
                                                            : this.state.formData
                                                                ? this.state.formData[
                                                                'IncoTerms2'
                                                                ]
                                                                : null
                                                    }
                                                    variant={
                                                        this.state.formData
                                                            .isReadonlyINCOT2 ||
                                                            isWorkFlowReadOnly
                                                            ? 'outline'
                                                            : 'solid'
                                                    }
                                                    inline={
                                                        isWorkFlowReadOnly ? true : false
                                                    }
                                                    inputProps={
                                                        isWorkFlowReadOnly
                                                            ? inputReadonlyProps
                                                            : inputPropsForDefaultRules[
                                                            'IncoTerms2'
                                                            ]
                                                    }
                                                />
                                            </>
                                        )}

                                        {/*<DynamicSelect*/}
                                        {/*    arrayOfData={*/}
                                        {/*        dropDownDatas.PartnerFunctionTypeId*/}
                                        {/*    }*/}
                                        {/*    label="Partner Function"*/}
                                        {/*    name="PartnerFunctionTypeId"*/}
                                        {/*    isRequired={true}*/}
                                        {/*    formErrors={*/}
                                        {/*        this.state.formErrors*/}
                                        {/*            ? this.state.formErrors[*/}
                                        {/*                  'PartnerFunctionTypeId'*/}
                                        {/*              ]*/}
                                        {/*            : null*/}
                                        {/*    }*/}
                                        {/*    onFieldChange={this.onFieldChange}*/}
                                        {/*    value={*/}
                                        {/*        isWorkFlowReadOnly*/}
                                        {/*            ? customerMaster &&*/}
                                        {/*              parseAndSanitise(*/}
                                        {/*                  customerMaster.PartnerFunctionTypeId*/}
                                        {/*              )*/}
                                        {/*            : this.state.formData*/}
                                        {/*            ? this.state.formData[*/}
                                        {/*                  'PartnerFunctionTypeId'*/}
                                        {/*              ]*/}
                                        {/*            : null*/}
                                        {/*    }*/}
                                        {/*    inputProps={inputReadonlyProps}*/}
                                        {/*/>*/}
                                        {!isContractEnabled && (
                                            <DynamicSelect
                                                arrayOfData={
                                                    (this.props.staticData &&
                                                        this.props.staticData
                                                            .ApolloCustomerGroupType) ||
                                                    []
                                                }
                                                label="Customer Group"
                                                name="CustomerGroupTypeId"
                                                hide={
                                                    allowedFields &&
                                                    !allowedFields.includes(
                                                        'CustomerGroupTypeId'
                                                    )
                                                }
                                                isRequired={true}
                                                formErrors={
                                                    this.state.formErrors
                                                        ? this.state.formErrors[
                                                        'CustomerGroupTypeId'
                                                        ]
                                                        : null
                                                }
                                                onFieldChange={this.onFieldChange}
                                                value={
                                                    isWorkFlowReadOnly
                                                        ? customerMaster &&
                                                        parseAndSanitise(
                                                            customerMaster.CustomerGroupTypeId
                                                        )
                                                        : this.state.formData
                                                            ? this.state.formData[
                                                            'CustomerGroupTypeId'
                                                            ]
                                                            : null
                                                }
                                                inputProps={
                                                    isWorkFlowReadOnly
                                                        ? inputReadonlyProps
                                                        : inputPropsForDefaultRules[
                                                        'CustomerGroupTypeId'
                                                        ]
                                                }
                                            />
                                        )}
                                        <DynamicSelect
                                            arrayOfData={dropDownDatas.PpcustProcTypeId}
                                            label="PP Cust Proc"
                                            name="PpcustProcTypeId"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes(
                                                    'PpcustProcTypeId'
                                                )
                                            }
                                            isRequired={true}
                                            formErrors={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                    'PpcustProcTypeId'
                                                    ]
                                                    : null
                                            }
                                            onFieldChange={this.onFieldChange}
                                            value={
                                                isWorkFlowReadOnly
                                                    ? customerMaster &&
                                                    parseAndSanitise(
                                                        customerMaster.PpcustProcTypeId
                                                    )
                                                    : this.state.formData
                                                        ? this.state.formData[
                                                        'PpcustProcTypeId'
                                                        ]
                                                        : null
                                            }
                                            inputProps={inputReadonlyProps}
                                        />
                                        <DynamicSelect
                                            arrayOfData={dropDownDatas.SalesOfficeTypeId}
                                            label="Sales Office"
                                            name="SalesOfficeTypeId"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes(
                                                    'SalesOfficeTypeId'
                                                )
                                            }
                                            isRequired={true}
                                            formErrors={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                    'SalesOfficeTypeId'
                                                    ]
                                                    : null
                                            }
                                            onFieldChange={this.onFieldChange}
                                            value={
                                                isWorkFlowReadOnly
                                                    ? customerMaster &&
                                                    parseAndSanitise(
                                                        customerMaster.SalesOfficeTypeId
                                                    )
                                                    : this.state.formData
                                                        ? this.state.formData[
                                                        'SalesOfficeTypeId'
                                                        ]
                                                        : null
                                            }
                                            inputProps={inputReadonlyProps}
                                        />

                                        <CheckBoxItem
                                            title="Order Combination"
                                            name="OrderCombination"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes(
                                                    'OrderCombination'
                                                )
                                            }
                                            stateValue={
                                                isWorkFlowReadOnly
                                                    ? customerMaster &&
                                                    customerMaster.OrderCombination
                                                    : this.state.formData &&
                                                    this.state.formData[
                                                    'OrderCombination'
                                                    ]
                                            }
                                            inputProps={enableDisplay}
                                            onValueChange={() =>
                                                this.setState({
                                                    formData: {
                                                        ...this.state.formData,
                                                        OrderCombination: !this.state
                                                            .formData.OrderCombination,
                                                    },
                                                })
                                            }
                                        />
                                        <CheckBoxItem
                                            title="Payment History Record"
                                            name="PaymentHistoryRecord"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes(
                                                    'PaymentHistoryRecord'
                                                )
                                            }
                                            stateValue={
                                                isWorkFlowReadOnly
                                                    ? customerMaster &&
                                                    customerMaster.PaymentHistoryRecord
                                                    : this.state.formData &&
                                                    this.state.formData[
                                                    'PaymentHistoryRecord'
                                                    ]
                                            }
                                            inputProps={enableDisplay}
                                            onValueChange={() =>
                                                this.setState({
                                                    formData: {
                                                        ...this.state.formData,
                                                        PaymentHistoryRecord: !this.state
                                                            .formData
                                                            .PaymentHistoryRecord,
                                                    },
                                                })
                                            }
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        )}
                        {globalMdmDetail.LikelyMatches && (
                            <>
                                <Text
                                    mt="42px"
                                    mb="20px"
                                    fontWeight="regular"
                                    color="lightBlue"
                                    fontSize={24}
                                    pl={4}>
                                    LIKELY MATCHES
                                </Text>
                                {globalMdmDetail.AllowMdmGrouping == false && (
                                    <Text
                                        fontWeight="regular"
                                        color="red"
                                        fontSize={13}
                                        ml={'10%'}>
                                        MDM Assignment is not enabled because DUNS#
                                        matches {globalMdmDetail.MdmDunsMatch}
                                    </Text>
                                )}
                                <Box ml={'10%'} width={3 / 4} justifyContent="center">
                                    <Box
                                        flexDirection="row"
                                        justifyContent="center"
                                        alignItems="center">
                                        <CollapsibleTable
                                            AllowMdmGrouping={
                                                globalMdmDetail.AllowMdmGrouping !=
                                                    undefined
                                                    ? globalMdmDetail.AllowMdmGrouping
                                                    : true
                                            }
                                            readOnly={workflow.isReadOnly}
                                            MdmNumberToGroup={
                                                (customerMaster &&
                                                    customerMaster.MdmNumberToGroup) ||
                                                null
                                            }
                                            MDMDunsMatch={123142452}
                                            checkboxState={this.state.checkboxState}
                                            setSelectedMDM={this.setMDM}
                                            data={globalMdmDetail.LikelyMatches}
                                        />
                                    </Box>
                                </Box>
                            </>
                        )}
                        {/* {globalMdmDetail.LikelyMatches && (
                            <>
                                <Text
                                    mt="42px"
                                    mb="20px"
                                    fontWeight="regular"
                                    color="lightBlue"
                                    fontSize={24}
                                    pl={4}>
                                    Likely Matches
                                </Text>
                                <Box my={4}>
                                    <Box
                                        flexDirection="row"
                                        justifyContent="center"
                                        alignItems="center">
                                        {this.renderMatches(
                                            globalMdmDetail.LikelyMatches
                                        )}
                                    </Box>
                                </Box>
                            </>
                        )} */}

                        {files && <FilesList files={files} readOnly />}
                        {workflow.isReadOnly ? (
                            <>
                                <Box my={4}>
                                    <Box flexDirection="row" justifyContent="center">
                                        <Box width={1 / 2} mx="auto" alignItems="center">
                                            <FormInput
                                                label="Notes"
                                                name="AdditionalNotes"
                                                multiline
                                                numberOfLines={2}
                                                type="text"
                                                inputWidth="100%"
                                                value={
                                                    isWorkFlowReadOnly
                                                        ? Notes
                                                        : this.state.formData
                                                            ? this.state.formData[
                                                            'AdditionalNotes'
                                                            ]
                                                            : null
                                                }
                                                variant={
                                                    isWorkFlowReadOnly
                                                        ? 'outline'
                                                        : 'solid'
                                                }
                                                inline={isWorkFlowReadOnly ? true : false}
                                            />
                                        </Box>
                                        <Box
                                            width={1 / 2}
                                            mx="auto"
                                            alignItems="center"></Box>
                                    </Box>
                                </Box>
                            </>
                        ) : (
                            <FilesList
                                title="NEW ATTACHMENTS"
                                formErrors={this.state.fileErrors}
                                files={selectedFilesIds.map((id) => selectedFiles[id])}
                                onChange={(value, id) => {
                                    this.setState({
                                        selectedFiles: {
                                            ...selectedFiles,
                                            [id]: {
                                                ...selectedFiles[id],
                                                DocumentType: parseInt(value),
                                            },
                                        },
                                    });
                                }}
                                onRemove={(value, id) => this.removeFile(id)}
                            />
                        )}
                    </Box>
                    <Box {...enableDisplay}>
                        <Flex
                            justifyEnd
                            alignCenter
                            style={{
                                paddingTop: 15,
                                flexDirection: 'row',
                                alignItems: 'center',
                                paddingLeft: 10,
                                paddingRight: 15,
                                marginTop: 20,
                                marginBottom: 10,
                                marginHorizontal: 25,
                            }}>
                            <label htmlFor="file-upload" className="custom-file-upload">
                                <MaterialIcons
                                    name="attach-file"
                                    size={18}
                                    color="#fff"
                                />
                            </label>
                            <input
                                id="file-upload"
                                key={this.state.fileinputkey}
                                type="file"
                                onChange={this.selectFiles}
                                multiple
                            />

                            <Button
                                onPress={(event) =>
                                    this.setState({
                                        formErrors: {},
                                        isReject: false,
                                        modalVisible: true,
                                    })
                                }
                                disabled={
                                    this.isSystemPointman() &&
                                        this.state.formErrors['shipToCustomerValidation'] !==
                                        undefined &&
                                        this.state.formErrors['shipToCustomerValidation'] !==
                                        null
                                        ? true
                                        : false
                                }
                                title="Approve"
                            />
                            <Button
                                title="Reject"
                                onPress={(event) =>
                                    this.setState({ isReject: true, modalVisible: true })
                                }
                            />
                        </Flex>
                    </Box>
                </View>
            </ScrollView>
        );
    }
}

class Default extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const props = this.props;

        return (
            <DimensionAware
                render={(dimensions) => (
                    <Page
                        {...{
                            ...props,
                            width: getWindowWidth(dimensions),
                            height: getWindowHeight(dimensions),
                            marginBottom: 25,
                        }}
                    />
                )}
            />
        );
    }
}

const mapStateToProps = ({ workflows, myTasks, toasts, staticData }) => {
    const { fetching, alert } = myTasks;
    const {
        fetchingfnGroupData,
        statusBarData,
        functionalGroupDetails,
        TasksStatusByTeamId,
        fetchingStatusBar,
        dunsFields = {},
    } = workflows;
    const { data, fetching: staticFetching } = staticData;
    return {
        fetching: fetching || fetchingStatusBar || fetchingfnGroupData || staticFetching,
        dunsFields,
        alert,
        statusBarData,
        functionalGroupDetails,
        TasksStatusByTeamId,
        toasts,
        staticData: data,
    };
};

export default connect(mapStateToProps, {
    saveApolloMyTaskCustomerMaster,
    getFunctionalGroupData,
    getStatusBarData,
    removeMessage,
    fetchDuns,
    getStaticRequest,
})(Default);

const styles = StyleSheet.create({
    progressIndicator: {
        flex: 1,
        paddingBottom: 5,
        flexDirection: 'row-reverse',
        alignItems: 'flex-end',
    },
    statusText: {
        fontSize: 15,
        color: '#1D4289',
        fontFamily: 'Poppins',
        textAlign: 'center',
        marginTop: 20,
    },
});
