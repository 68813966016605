import {
    GET_POINTMAN_PARTNERS,
    GET_APOLLO_PARTNERS,
    GET_APOLLO_PARTNERS_SUCCESS,
    GET_APOLLO_PARTNERS_FAILURE,
    GET_POINTMAN_PARTNERS_SUCCESS,
    GET_POINTMAN_PARTNERS_FAILURE,
    ADD_NEW_PARTNER,
    FETCH_PARTNER_DETAILS,
    FETCH_PARTNER_DETAILS_SUCCESS,
    CREATE_APOLLO_PARTNER_REQUEST,
    CREATE_APOLLO_PARTNER_REQUEST_SUCCESS,
    CREATE_APOLLO_PARTNER_REQUEST_FAIL,
    GET_WF_APOLLO_PARTNERS,
    GET_WF_APOLLO_PARTNERS_SUCCESS,
    GET_WF_APOLLO_PARTNERS_FAILURE,
    APPROVE_APOLLO_PARTNERS,
    CLEAR_PARTNERS,
    CREATE_APOLLO_PARTNER_FAIL,
    APPROVE_APOLLO_PARTNER_REQUEST_SUCCESS,
    APPROVE_APOLLO_PARTNER_REQUEST_FAIL,
    CLEAR_PARTNEREDITSAFTERSAVE,
    GET_JDE_PARTNERS,
    GET_JDE_PARTNERS_SUCCESS,
    GET_JDE_PARTNERS_FAIL,
    SEARCH_JDE_PARTNERS,
    SEARCH_JDE_PARTNERS_SUCCESS,
    SEARCH_JDE_PARTNERS_FAIL,
    GET_WF_JDE_PARTNERS,
    GET_WF_JDE_PARTNERS_SUCCESS,
    GET_WF_JDE_PARTNERS_FAILURE,
} from '../../constants/ActionTypes';

export const getPartners = (data) => {
    return {
        type: GET_POINTMAN_PARTNERS,
        payload: data,
    };
};

export const getApolloPartners = (data) => {
    return {
        type: GET_APOLLO_PARTNERS,
        payload: data,
    };
};

export const getApolloPartnersSuccess = (res) => {
    console.log('TEst data', res);
    return {
        type: GET_APOLLO_PARTNERS_SUCCESS,
        payload: res,
    };
};

export const getApolloPartnersFailed = (res) => {
    return {
        type: GET_APOLLO_PARTNERS_FAILURE,
        payload: res,
    };
};
export const getPartnersSuccess = (res) => {
    return {
        type: GET_POINTMAN_PARTNERS_SUCCESS,
        payload: res,
    };
};

export const getPartnersFailed = (res) => {
    return {
        type: GET_POINTMAN_PARTNERS_FAILURE,
        payload: res,
    };
};

export const getWFApolloPartners = (data) => {
    return {
        type: GET_WF_APOLLO_PARTNERS,
        payload: data,
    };
};

export const getWFApolloPartnersSuccess = (res) => {
    return {
        type: GET_WF_APOLLO_PARTNERS_SUCCESS,
        payload: res,
    };
};

export const getWFApolloPartnersFailed = (res) => {
    return {
        type: GET_WF_APOLLO_PARTNERS_FAILURE,
        payload: res,
    };
};

export const addNewPartner = (res) => {
    return {
        type: ADD_NEW_PARTNER,
        payload: res,
    };
};

export const getPartnerDetails = (
    rowIndex,
    columnId,
    value,
    cellrow,
    data,
    partnerScreenSearchfields,
    partnerOperation,
    SystemTypeId
) => {
    // console.log(
    //     rowIndex,
    //     columnId,
    //     value,
    //     cellrow,
    //     data,
    //     partnerScreenSearchfields,
    //     partnerOperation,
    //     '++++++++ partners action'
    // );
    return {
        type: FETCH_PARTNER_DETAILS,
        payload: {
            rowIndex,
            columnId,
            value,
            cellrow,
            data,
            partnerScreenSearchfields,
            partnerOperation,
            SystemTypeId,
        },
    };
};

export const fetchPartnerCustomerDataSuccess = (res) => {
    return {
        type: FETCH_PARTNER_DETAILS_SUCCESS,
        payload: res,
    };
};

export const createApolloPartnerRequest = (data) => {
    return {
        type: CREATE_APOLLO_PARTNER_REQUEST,
        payload: data,
    };
};

export const createApolloPartnerRequestSuccess = (data) => {
    return {
        type: CREATE_APOLLO_PARTNER_REQUEST_SUCCESS,
        payload: data,
    };
};

export const createApolloPartnerRequestFail = (data) => {
    return {
        type: CREATE_APOLLO_PARTNER_FAIL,
        payload: data,
    };
};

export const approveRejectPartnersApollo = (data) => {
    console.log('reject approve');
    return {
        type: APPROVE_APOLLO_PARTNERS,
        payload: data,
    };
};

export const clearPartnerDetails = () => {
    return {
        type: CLEAR_PARTNERS,
        payload: [],
    };
};

export const approveApolloPartnerRequestSuccess = (data) => {
    return {
        type: APPROVE_APOLLO_PARTNER_REQUEST_SUCCESS,
        payload: data,
    };
};

export const approveApolloPartnerRequestFail = (data) => {
    return {
        type: APPROVE_APOLLO_PARTNER_REQUEST_FAIL,
        payload: data,
    };
};

export const clearPartnerEditsAfterSave = (data) => {
    return {
        type: CLEAR_PARTNEREDITSAFTERSAVE,
        payload: data,
    };
};

export const getJDEPartners = (data) => {
    return {
        type: GET_JDE_PARTNERS,
        payload: data,
    };
};

export const getJDEPartnerSuccess = (data) => {
    return {
        type: GET_JDE_PARTNERS_SUCCESS,
        payload: data,
    };
};
export const getJDEPartnerFail = (data) => {
    return {
        type: GET_JDE_PARTNERS_FAIL,
        payload: data,
    };
};

export const searchJdePartner = (
    rowIndex,
    columnId,
    value,
    cellrow,
    data,
    partnerOperation
) => {
    return {
        type: SEARCH_JDE_PARTNERS,
        payload: {
            rowIndex,
            columnId,
            value,
            cellrow,
            data,
            partnerOperation,
        },
    };
};

export const searchJdePartnerSuccess = (data) => {
    return {
        type: SEARCH_JDE_PARTNERS_SUCCESS,
        payload: data,
    };
};
export const searchJdePartnerFail = (data) => {
    return {
        type: SEARCH_JDE_PARTNERS_FAIL,
        payload: data,
    };
};

export const getWFJDEPartners = (data) => {
    return {
        type: GET_WF_JDE_PARTNERS,
        payload: data,
    };
};

export const getWFJDEPartnersSuccess = (res) => {
    return {
        type: GET_WF_JDE_PARTNERS_SUCCESS,
        payload: res,
    };
};

export const getWFJDEPartnersFailed = (res) => {
    return {
        type: GET_WF_JDE_PARTNERS_FAILURE,
        payload: res,
    };
};
