import React from 'react';
import { ScrollView, View, StyleSheet, TouchableOpacity } from 'react-native';
import {
    DimensionAware,
    getWindowHeight,
    getWindowWidth,
} from 'react-native-dimension-aware';
import { Colors, StyledText } from '../../../theme/Index';
import { Flex, Button, Box, Text } from '../../../components/common';
import { FormInput } from '../../../components/form';
import { saveApolloMyTaskCredit, getStaticRequest } from '../../../appRedux/actions/';
import {
    getStatusBarData,
    getFunctionalGroupData,
} from '../../../appRedux/actions/Workflow';
import { yupAllFieldsValidation, parseAndSanitise } from '../../../constants/utils';

import GlobalMdmFields from '../../../components/GlobalMdmFields';
import { mytaskOlympusCreditRules, rejectRules } from '../../../constants/FieldRules';
import {
    RoleType,
    SalesOrgType,
    SystemType,
    DistributionChannelType,
    DivisionType,
    CompanyCodeType,
    CategoryTypesById,
} from '../../../constants/WorkflowEnums';
import MultiColorProgressBar from '../../../components/MultiColorProgressBar';
import DynamicSelect from '../../../components/DynamicSelect';
import {
    fetchCreditDropDownData,
    fetchCreateTeamRoleFieldMapping,
    fetchOlympusCreateTeamRoleFieldMapping,
} from '../../../redux/DropDownDatas';
import Loading from '../../../components/Loading';
import FlashMessage, { FlashMessages } from '../../../components/FlashMessage';
import { connect } from 'react-redux';
import * as _ from 'lodash';
import BreadCrumbs from '../../../components/BreadCrumbs';
import Modal from 'modal-enhanced-react-native-web';
import FilesList from '../../../components/FilesList';
import { removeMessage } from '../../../appRedux/actions/Toast';
import { MaterialIcons } from '@expo/vector-icons';
import idx from 'idx';
import moment from 'moment-timezone';

const camelCaseToPascalCase = (str = '') => _.upperFirst(_.camelCase(str));

class Page extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            WorkflowId: this.props.location.state.WorkflowId,
            TaskId: this.props.location.state.TaskId,
            reject: false,
            dropDownDatas: {},
            teamRoleFieldMapping: {},
            formData: {
                CreditLimit: 1,
                RejectionButton: false,
                AccountStatementTypeId: 2,
                RiskCategoryTypeId: 1,
            },
            formErrors: {},
            selectedFiles: {},
            selectedFilesIds: [],
            files: [],
            fileinputkey: Date.now(),
            isInternationalVersionVisible: false,
        };
    }

    componentDidMount() {
        let { state: wf } = this.props.location;
        let postJson = {
            workflowId: wf.WorkflowId,
            fuctionalGroup: 'credit',
            taskId: wf.TaskId,
        };
        this.props.getStaticRequest([
            40,
            41,
            42,
            52,
            53,
            54,
            55,
            56,
            57,
            58,
            59,
            60,
            61,
            62,
            63,
            43,
            64,
            65,
            67,
            69,
            77,
            81,
            84,
            76,
            71,
        ]);
        this.props.getStatusBarData(postJson);
        this.props.getFunctionalGroupData(postJson);
        // fetchCreditDropDownData().then((res) => {

        //     const data = res;
        //     this.setState({ dropDownDatas: data });
        // });
        fetchOlympusCreateTeamRoleFieldMapping().then((resp) => {
            const data = resp;
            this.setState({ teamRoleFieldMapping: data });
        });
    }

    componentWillReceiveProps(newProps) {
        if (newProps.functionalGroupDetails != this.props.functionalGroupDetails) {
            let {
                functionalGroupDetails: { Customer: globalMdmDetail = {} },
            } = newProps;

            // this.props.fetchDuns({
            //     UserId: localStorage.getItem('userId'),
            //     DunsNumber: customerdata.DunsNumber,
            // });

            let defaultValues = {};
            if (globalMdmDetail && globalMdmDetail.CategoryTypeId != undefined) {
                var source_category = parseInt(globalMdmDetail.CategoryTypeId);
                //direct , dropship , other
                if (
                    source_category === CategoryTypesById.Direct ||
                    source_category === CategoryTypesById['Drop Ship'] ||
                    source_category === CategoryTypesById['Other']
                ) {
                    defaultValues['PaymentTermsTypeId'] = 3;
                }
            }

            if (globalMdmDetail && globalMdmDetail.RoleTypeId != undefined) {
                var role = RoleType[globalMdmDetail && globalMdmDetail.RoleTypeId];
                if (role === 'Sales Rep') {
                    defaultValues['PaymentTermsTypeId'] = 3;
                    defaultValues['RiskCategoryTypeId'] = 4;
                    defaultValues['CreditRepGroupTypeId'] = 21;
                }
                if (role === 'Drop Ship') {
                    defaultValues['PaymentTermsTypeId'] = 3;
                }
            }
            if (globalMdmDetail && globalMdmDetail.CompanyCodeTypeId != undefined) {
                defaultValues['CreditControlAreaTypeId'] =
                    globalMdmDetail.CompanyCodeTypeId;
            }
            this.setState({
                formData: {
                    ...this.state.formData,
                    CreditLimit:
                        newProps.functionalGroupDetails &&
                        newProps.functionalGroupDetails.Customer &&
                        newProps.functionalGroupDetails.Customer.RequestedCreditLimit,
                    ...defaultValues,
                },
            });
        }
    }

    setFormErrors = (errors) => {
        const { formErrors } = this.state;
        this.setState({ formErrors: errors }, () => window.scrollTo(0, 0));
    };

    isNumber = (n) => {
        return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
    };

    validateNumericKeypad = (value, e) => {
        const { name } = e.target;
        if (value.length !== 0 && !this.isNumber(value)) {
            this.setState({
                formErrors: {
                    [name]: 'Must be digits',
                },
            });
        } else {
            this.onFieldChange(value, e);
        }
    };

    onFieldChange = (value, e) => {
        const { name } = e.target;
        this.setState(
            {
                formData: {
                    ...this.state.formData,
                    [name]: value,
                },
                formErrors: {},
            },
            () => {
                //remove from formData if TypeId is set to 0 OR ''
                if (name.includes('TypeId') && parseInt(value) === 0) {
                    delete this.state.formData[name];
                }
            }
        );
    };

    handleDefaultValues = () => {
        let { formData } = this.state;
        let {
            functionalGroupDetails: { Customer: globalMdmDetail = {} },
        } = this.props;
        let defaultValues = {};
        console.log(formData.CreditLimit);
        if (
            !formData.CreditLimit ||
            formData.CreditLimit.toString().trim().length === 0
        ) {
            defaultValues['CreditLimit'] = 1;
        }

        // if (globalMdmDetail && globalMdmDetail.CategoryTypeId != undefined) {
        //     var source_category = parseInt(globalMdmDetail.CategoryTypeId);
        //     //direct , dropship , other
        //     if (
        //         (source_category === CategoryTypesById.Direct ||
        //             source_category === CategoryTypesById['Drop Ship'] ||
        //             source_category === CategoryTypesById['Other']) &&
        //         (formData.PaymentTermsTypeId === undefined ||
        //             formData.PaymentTermsTypeId === '' ||
        //             formData.PaymentTermsTypeId === 0)
        //     ) {
        //         defaultValues['PaymentTermsTypeId'] = 3;
        //     }
        // }
        if (
            formData.RiskCategoryTypeId === undefined ||
            formData.RiskCategoryTypeId === ''
        ) {
            defaultValues['RiskCategoryTypeId'] = '1';
        }
        // if (formData.CompanyCodeTypeId != formData.CreditControlAreaTypeId) {
        //     defaultValues['CreditControlAreaTypeId'] = formData.CompanyCodeTypeId;
        // }

        // if (
        //     formData.CreditControlAreaTypeId === undefined ||
        //     formData.CreditControlAreaTypeId === ''
        // ) {
        //     defaultValues['CreditControlAreaTypeId'] = globalMdmDetail.CompanyCodeTypeId;
        // } else {
        //     defaultValues['CreditControlAreaTypeId'] = globalMdmDetail.CompanyCodeTypeId;
        // }

        return defaultValues;
    };

    handleFormSubmission = (schema) => {
        let { TaskId, WorkflowId, selectedFiles, selectedFilesIds } = this.state,
            castedFormData = {},
            postData = {};
        const { TasksStatusByTeamId } = this.props;
        let {
            functionalGroupDetails: { Customer: globalMdmDetail = {} },
        } = this.props;

        try {
            const { formData } = this.state;
            // console.log(formData)
            const WorkflowTaskModel = {
                RejectReason: formData['RejectionButton']
                    ? formData['RejectionReason']
                    : '',
                TaskId: TaskId,
                UserId: localStorage.getItem('userId'),
                WorkflowId: WorkflowId,
                WorkflowTaskOperationType: !formData['RejectionButton'] ? 1 : 2,
                SystemTypeId:
                    globalMdmDetail.SystemTypeId != '' ? globalMdmDetail.SystemTypeId : 2,
            };
            if (!formData['RejectionButton']) {
                castedFormData = schema.cast(formData);
            } else {
                castedFormData = formData;
            }
            delete castedFormData.RejectionButton;
            const { teamRoleFieldMapping } = this.state;
            const CreditTeamFields =
                idx(teamRoleFieldMapping, (_) => _.CreditTeamFields) || [];
            const RoleTypeId = globalMdmDetail.RoleTypeId;
            const SystemTypeId = globalMdmDetail.SystemTypeId;
            const allowedFieldList = CreditTeamFields.filter(
                (item) =>
                    item.RoleTypeId == RoleTypeId && item.SystemTypeId == SystemTypeId
            );
            let allowedFields = undefined;
            if (allowedFieldList && allowedFieldList.length > 0) {
                allowedFields = allowedFieldList[0].Fields;
            }
            postData['formdata'] = {
                WorkflowTaskModel,
                // ...castedFormData,
                AccountStatementTypeId: allowedFields.includes('AccountStatement')
                    ? formData['AccountStatementTypeId']
                    : undefined,
                AdditionalNotes: formData['AdditionalNotes'],
                CredInfoNumber: allowedFields.includes('CredInfoNumber')
                    ? formData['CredInfoNumber']
                    : undefined,
                CreditControlAreaTypeId: allowedFields.includes('CreditControlArea')
                    ? formData['CreditControlAreaTypeId']
                    : undefined,
                CreditLimit: allowedFields.includes('CreditLimit')
                    ? formData['CreditLimit']
                    : undefined,
                CustomerCreditGroupTypeId: allowedFields.includes('CustomerCreditGroup')
                    ? formData['CustomerCreditGroupTypeId']
                    : undefined,
                LastExtReview: allowedFields.includes('LastExtReview')
                    ? formData['LastExtReview']
                    : undefined,
                PaymentIndex: allowedFields.includes('PaymentIndex')
                    ? formData['PaymentIndex']
                    : undefined,
                Rating: allowedFields.includes('Rating') ? formData['Rating'] : undefined,
                RiskCategoryTypeId: allowedFields.includes('RiskCategory')
                    ? formData['RiskCategoryTypeId']
                    : undefined,
                DunsNumber: formData.DunsNumber,
                AdditionalNotes: formData.AdditionalNotes,
                SicCode: formData.SicCode,
                SicCode4: formData.SicCode4,
                SicCode6: formData.SicCode6,
                SicCode8: formData.SicCode8,
                SicCodeDescription: formData.SicCodeDescription,
                TaxNumber: formData.TaxNumber,
                VatRegNo: formData.VatRegNo,
                NaicsCode: formData.NaicsCode,
                NaicsCodeDescription: formData.NaicsCodeDescription,
            };
            postData['files'] = selectedFilesIds.map((id) => selectedFiles[id]);
            console.log('postdata', postData);

            this.props.saveApolloMyTaskCredit(postData);
            this.resetForm();
            this.scrollToTop();
        } catch (error) {
            console.log('form validtion error', error);
        }
    };

    onSubmit = (event, reject, schema) => {
        let { formData, selectedFilesIds, selectedFiles } = this.state;
        let defaults = this.handleDefaultValues();
        console.log(defaults);
        let fileErrors = {};
        let errors = false;
        console.log('selectedFilesIds,selectedFiles: ', selectedFilesIds, selectedFiles);
        selectedFilesIds.map((id) => {
            if (selectedFiles[id] && selectedFiles[id].DocumentType <= 0) {
                fileErrors[id] = 'Document Type Required for file';
                errors = true;
            }
        });
        this.setState({ fileErrors, isFileErrors: errors });

        this.setState(
            {
                modalVisible: false,
                formData: {
                    ...this.state.formData,
                    ...defaults,
                    RejectionButton: reject,
                },
            },
            () => {
                yupAllFieldsValidation(
                    this.state.formData,
                    schema,
                    (...rest) => {
                        if (this.state.isFileErrors === false)
                            this.handleFormSubmission(...rest);
                    },
                    this.setFormErrors
                );
            }
        );
    };

    selectFiles = (events) => {
        event.preventDefault();

        const { selectedFilesIds, selectedFiles } = this.state;
        const id = events.target.files[0].name;

        this.setState({
            selectedFiles: {
                ...selectedFiles,
                [id]: {
                    data: events.target.files[0],
                    DocumentName: events.target.files[0].name,
                    DocumentType: 0,
                },
            },
            selectedFilesIds: [...selectedFilesIds, id],
            filename: events.target.files[0].name,
            fileinputkey: Date.now(),
        });
    };

    removeFile = (id) => {
        event.preventDefault();

        var selectedFilesDict = this.state.selectedFiles;
        var selectedFilesIdsList = this.state.selectedFilesIds;
        // remove the existing state
        delete selectedFilesDict[id];
        selectedFilesIdsList = selectedFilesIdsList.filter((fileid) => fileid !== id);

        this.setState({
            selectedFiles: selectedFilesDict,
            selectedFilesIds: selectedFilesIdsList,
        });
    };

    scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    resetFormData = () => {
        //restore initial values
        this.setState(
            {
                formData: { CreditLimit: 1, RejectionButton: false },
            },
            () => {
                Object.keys(this.state.formData).map((key) => {
                    this.setState({
                        formData: {
                            [key]: '',
                        },
                    });
                });
            }
        );
    };

    resetForm = () => {
        let { formErrors } = this.state;

        Object.keys(this.state.formErrors).forEach((key) => (formErrors[key] = ''));

        this.setState(
            {
                formErrors,
            },
            this.resetFormData
        );
    };

    _renderModalContent = () => (
        <Box
            my={4}
            mx="30%"
            backgroundColor="white"
            borderRadius="4px"
            borderColor="rgba(0, 0, 0, 0.1)">
            <Text my={3} fontWeight="regular" color="lightBlue" fontSize={24} pl={4}>
                Submit Workflow
            </Text>
            <Box px="0px" pb="22px" justifyContent="center">
                <Box alignItems="center" justifyContent="center">
                    {!this.state.isReject ? (
                        <FormInput
                            label="Additional Notes"
                            multiline
                            numberOfLines={2}
                            maxLength={250}
                            name="AdditionalNotes"
                            type="text"
                            onChange={this.onFieldChange}
                            error={
                                this.state.formErrors
                                    ? this.state.formErrors['AdditionalNotes']
                                    : null
                            }
                            value={
                                this.state.formData &&
                                this.state.formData['AdditionalNotes']
                            }
                            variant={'solid'}
                        />
                    ) : (
                        <FormInput
                            label="Rejection Reason"
                            name="RejectionReason"
                            maxLength={250}
                            onChange={this.onFieldChange}
                            error={'*this field is required'}
                            multiline
                            numberOfLines={2}
                            type="text"
                            value={
                                this.state.formData &&
                                this.state.formData['RejectionReason']
                            }
                            variant="solid"
                        />
                    )}
                </Box>

                <Flex
                    justifyEnd
                    alignCenter
                    alignSelf="flex-end"
                    style={{
                        paddingTop: 15,
                        flexDirection: 'row',
                        alignItems: 'center',
                        paddingLeft: 10,
                        paddingRight: 15,
                        marginTop: 20,
                        marginBottom: 10,
                        marginHorizontal: 25,
                    }}>
                    <Button
                        onPress={(event) => {
                            if (this.state.isReject)
                                this.setState(
                                    { isReject: false, modalVisible: null },
                                    () => this.onSubmit(event, true, rejectRules)
                                );
                            else this.onSubmit(event, false, mytaskOlympusCreditRules);
                        }}
                        title="Submit"
                    />
                    <Button
                        title="Cancel"
                        onPress={() => this.setState({ modalVisible: null })}
                    />
                </Flex>
            </Box>
        </Box>
    );

    getDropdownValues = (key, data, stateKey) => {
        let dropdownData = [];
        const self = this;
        const {
            functionalGroupDetails: {
                DocumentLocation: files,
                Customer: globalMdmDetail = {},
            },
        } = this.props;

        if (data) {
            dropdownData = [];
            data.forEach((dropdownList) => {
                if (dropdownList.ValidationData.length > 0) {
                    dropdownList.ValidationData.forEach((datatofilter) => {
                        if (globalMdmDetail && globalMdmDetail.SalesOrgTypeId)
                            if (
                                datatofilter[key] ===
                                parseInt(globalMdmDetail.SalesOrgTypeId)
                            ) {
                                dropdownData.push(dropdownList);
                            }
                    });
                }
            });
        }
        var check = new Set();
        let filteredArr = dropdownData.filter(
            (obj) => !check.has(obj['Id']) && check.add(obj['Id'])
        );
        return filteredArr;
    };

    getStaticDropdownValues = (Id, dropdownList) => {
        let description = '';
        if (Id && dropdownList)
            dropdownList.some((data) => {
                if (data.Id == Id) {
                    description = data.Value + ' : ' + data.Description;
                    return true;
                }
            });
        // if (description.includes('/n') || description.includes(';')) {
        //     console.log('html string');
        // }
        return description;
    };

    render() {
        const {
            width,
            location,
            functionalGroupDetails: {
                DocumentLocation: files,
                Customer: globalMdmDetail = {},
                Credit: creditDetail = null,
                TaskNote: Notes = '',
                RequestorName,
            },
            statusBarData,
            alert = {},
            TasksStatusByTeamId = null,
            staticData,
        } = this.props;

        const {
            dropDownDatas,
            teamRoleFieldMapping,
            selectedFilesIds,
            selectedFiles,
        } = this.state;

        const { state } = location;

        const workflow = {
            ...state,
            isReadOnly:
                TasksStatusByTeamId === null ||
                !(
                    globalMdmDetail.WorkflowStateTypeId === 2 &&
                    TasksStatusByTeamId[4].WorkflowTaskStateTypeId === 2
                ),
        };

        const requestDateUtc = moment(globalMdmDetail.DateOfCreation);
        const localDate = moment(requestDateUtc).local();
        const requestDate = localDate.format('YYYY-MM-DD hh:mm:ss A');

        const CreditTeamFields =
            idx(teamRoleFieldMapping, (_) => _.CreditTeamFields) || [];
        const RoleTypeId = globalMdmDetail.RoleTypeId;
        const SystemTypeId = globalMdmDetail.SystemTypeId;
        const allowedFieldList = CreditTeamFields.filter(
            (item) => item.RoleTypeId == RoleTypeId && item.SystemTypeId == SystemTypeId
        );
        let allowedFields = undefined;
        if (allowedFieldList && allowedFieldList.length > 0) {
            allowedFields = allowedFieldList[0].Fields;
        }
        // console.log(allowedFields);
        const inputReadonlyProps = workflow.isReadOnly ? { disabled: true } : null;
        let showCreditDetail =
            state.isReadOnly && creditDetail === null ? { display: 'none' } : null;

        const showButtons = workflow.isReadOnly ? { display: 'none' } : null;
        let disp_payterms = false;
        if (globalMdmDetail && globalMdmDetail.CategoryTypeId != undefined) {
            var source_category = parseInt(globalMdmDetail.CategoryTypeId);
            //direct , dropship , other
            if (
                source_category === CategoryTypesById.Direct ||
                source_category === CategoryTypesById['Drop Ship'] ||
                source_category === CategoryTypesById['Other']
            ) {
                disp_payterms = true;
            } else {
                // hide cred details except for soldto , payer , sales rep , dropship when disp_payterms is false
                if (
                    parseInt(globalMdmDetail.RoleTypeId, 10) != 1 &&
                    parseInt(globalMdmDetail.RoleTypeId, 10) != 2 &&
                    parseInt(globalMdmDetail.RoleTypeId, 10) != 3 &&
                    parseInt(globalMdmDetail.RoleTypeId, 10) != 4
                )
                    showCreditDetail = { display: 'none' };
            }
        }
        var isReleased = true;
        for (let i = 0; i < statusBarData.length; i++) {
            let teamstatus = statusBarData[i];
            if (teamstatus.WorkflowTaskStateTypeId === 2) {
                isReleased = false;
                break;
            } else {
                isReleased = true;
            }
        }
        var bgcolor = alert.color || '#FFF';
        if (this.props.fetching) {
            return <Loading />;
        }

        console.log(globalMdmDetail, this.state, staticData);

        return (
            <ScrollView
                keyboardShouldPersistTaps="always"
                style={{
                    backgroundColor: '#EFF3F6',
                    paddingBottom: 75,
                }}>
                <Modal isVisible={this.state.modalVisible}>
                    {this._renderModalContent()}
                </Modal>
                <FlashMessages
                    toasts={this.props.toasts}
                    onDismiss={this.props.removeMessage}
                />
                {alert.display && (
                    <FlashMessage
                        bg={{ backgroundColor: bgcolor }}
                        message={alert.message}
                    />
                )}

                <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between">
                    <Text
                        style={{
                            fontWeight: '500',
                            fontSize: 32,
                            color: Colors.lightBlue,
                            marginTop: 24,
                            marginBottom: 20,
                            paddingLeft: 45,
                        }}>
                        My Tasks - {state.Header}
                    </Text>
                    <Box
                        marginTop="25px"
                        flex={3 / 4}
                        flexDirection="row-reverse"
                        alignItems="flex-end">
                        <MultiColorProgressBar readings={statusBarData} />
                    </Box>
                </Box>
                <Box marginTop="25px" flexDirection="row-reverse" alignItems="flex-end">
                    <StyledText
                        style={{
                            fontWeight: '600',
                            paddingRight: '20px',
                            fontSize: '16px',
                            color: '#11307D',
                        }}>
                        {' '}
                        Requestor : {RequestorName}{' '}
                    </StyledText>
                </Box>
                <Box flexDirection="row-reverse" alignItems="flex-end">
                    <StyledText
                        style={{
                            fontWeight: '600',
                            paddingRight: '20px',
                            fontSize: '16px',
                            color: '#11307D',
                        }}>
                        {' '}
                        Date of Request: {requestDate}{' '}
                    </StyledText>
                </Box>
                <View
                    style={{
                        flex: 1,
                        paddingHorizontal: width < 1440 ? 60 : width * 0.1,
                        paddingBottom: 10,
                    }}>
                    <Box fullHeight my={2}>
                        <Box flexDirection="row" justifyContent="space-around" my={4}>
                            <FormInput
                                px="25px"
                                flex={1 / 4}
                                label="WorkflowTitle"
                                maxLength={40}
                                name="WorkflowTitle"
                                variant="outline"
                                type="text"
                                value={globalMdmDetail && globalMdmDetail.WorkflowTitle}
                            />
                            <FormInput
                                px="25px"
                                flex={1 / 4}
                                label="Workflow Number"
                                name="workflow-number"
                                variant="outline"
                                type="text"
                                value={globalMdmDetail && globalMdmDetail.WorkflowId}
                            />
                            {globalMdmDetail.SystemRecordId && (
                                <FormInput
                                    px="25px"
                                    flex={1 / 4}
                                    label="Customer Number"
                                    name="CustomerNumber"
                                    variant="outline"
                                    type="text"
                                    value={globalMdmDetail.SystemRecordId}
                                />
                            )}
                            {globalMdmDetail &&
                                globalMdmDetail.MdmCustomerId &&
                                (isReleased ? (
                                    <TouchableOpacity
                                        onPress={() =>
                                            this.props.history.push(
                                                `/search-results/${globalMdmDetail.MdmCustomerId}`
                                            )
                                        }>
                                        <FormInput
                                            px="25px"
                                            flex={1 / 4}
                                            label="MDM Number"
                                            name="mdm-number"
                                            variant="outline"
                                            type="text"
                                            value={
                                                globalMdmDetail &&
                                                globalMdmDetail.MdmCustomerId
                                            }
                                        />
                                    </TouchableOpacity>
                                ) : (
                                    <FormInput
                                        px="25px"
                                        flex={1 / 4}
                                        label="MDM Number"
                                        name="mdm-number"
                                        variant="outline"
                                        type="text"
                                        value={
                                            globalMdmDetail &&
                                            globalMdmDetail.MdmCustomerId
                                        }
                                    />
                                ))}
                        </Box>
                        {!this.state.isInternationalVersionVisible && (
                            <GlobalMdmFields
                                staticDropDownData={this.props.staticData}
                                formData={{
                                    ...globalMdmDetail,
                                    DunsNumber:
                                        this.state.formData?.DunsNumber ||
                                        creditDetail?.DunsNumber,
                                    NaicsCode: this.state.formData?.NaicsCode ||
                                        creditDetail?.NaicsCode,
                                    NaicsCodeDescription: this.state.formData?.NaicsCodeDescription ||
                                        creditDetail?.NaicsCodeDescription,
                                    SicCode: this.state.formData?.SicCode ||
                                        creditDetail?.SicCode,
                                    SicCode4: this.state.formData?.SicCode4 ||
                                        creditDetail?.SicCode4,
                                    SicCode6: this.state.formData?.SicCode6 ||
                                        creditDetail?.SicCode6,
                                    SicCode8: this.state.formData?.SicCode8 ||
                                        creditDetail?.SicCode8,
                                    SicCodeDescription: this.state.formData?.SicCodeDescription ||
                                        creditDetail?.SicCodeDescription,
                                    TaxNumber: this.state.formData?.TaxNumber ||
                                        creditDetail?.TaxNumber,
                                    VatRegNo: this.state.formData?.VatRegNo ||
                                        creditDetail?.VatRegNo,

                                }}
                                dunsEditable={true}
                                taxEditable={true}
                                onFieldChange={this.onFieldChange}
                                editableInputs={{ TaxNumber: true, VatRegNo: true }}

                                viewInternationalModal={() => {
                                    this.setState({
                                        isInternationalVersionVisible: true,
                                    });
                                }}
                                isOlympus={true}
                                readOnly
                            />
                        )}
                        <Text
                            mt="42px"
                            mb="20px"
                            fontWeight="regular"
                            color="lightBlue"
                            fontSize={24}
                            pl={4}>
                            SYSTEM FIELDS
                        </Text>
                        <Box flexDirection="row" justifyContent="center">
                            <Box width={1 / 2} mx="auto" alignItems="center">
                                <FormInput
                                    label="System"
                                    name="System"
                                    inline
                                    variant="outline"
                                    type="text"
                                    value={
                                        SystemType[
                                        globalMdmDetail &&
                                        globalMdmDetail.SystemTypeId
                                        ]
                                    }
                                />
                                <FormInput
                                    label="Role"
                                    name="Role"
                                    inline
                                    variant="outline"
                                    type="text"
                                    value={
                                        staticData &&
                                        globalMdmDetail &&
                                        this.getStaticDropdownValues(
                                            globalMdmDetail.RoleTypeId,
                                            this.props.staticData &&
                                            this.props.staticData.OlympusRoleType
                                        )
                                    }
                                />
                                <FormInput
                                    label="Sales Org"
                                    name="SalesOrg"
                                    inline
                                    variant="outline"
                                    type="text"
                                    value={
                                        staticData &&
                                        globalMdmDetail &&
                                        this.getStaticDropdownValues(
                                            globalMdmDetail.SalesOrgTypeId,
                                            this.props.staticData &&
                                            this.props.staticData.OlympusSalesOrgType
                                        )
                                    }
                                />
                                <FormInput
                                    inline
                                    variant="outline"
                                    label="URI/Service Code"
                                    name="URIServiceCode"
                                    type="text"
                                    hide={
                                        allowedFields &&
                                        !allowedFields.includes('ServiceCode')
                                    }
                                    value={
                                        globalMdmDetail && globalMdmDetail.URIServiceCode
                                    }
                                />
                                <FormInput
                                    inline
                                    variant="outline"
                                    label="Delivery Information"
                                    name="UnloadingPoints"
                                    type="text"
                                    hide={
                                        allowedFields &&
                                        !allowedFields.includes('DeliveryInformation')
                                    }
                                    value={
                                        globalMdmDetail && globalMdmDetail.UnloadingPoints
                                    }
                                />
                                <FormInput
                                    inline
                                    variant="outline"
                                    label="Tax Number 4"
                                    name="TaxNumber4"
                                    type="text"
                                    hide={
                                        allowedFields &&
                                        !allowedFields.includes('TaxNumber4')
                                    }
                                    value={globalMdmDetail && globalMdmDetail.TaxNumber4}
                                />
                                <FormInput
                                    inline
                                    variant="outline"
                                    label="Sales District"
                                    name="SalesDistrictTypeId"
                                    type="text"
                                    hide={
                                        allowedFields &&
                                        !allowedFields.includes('SalesDistrict')
                                    }
                                    value={
                                        staticData &&
                                        globalMdmDetail &&
                                        this.getStaticDropdownValues(
                                            globalMdmDetail.SalesDistrictTypeId,
                                            this.props.staticData &&
                                            this.props.staticData
                                                .OlympusSalesDistrictType
                                        )
                                    }
                                />
                                <DynamicSelect
                                    arrayOfData={
                                        this.props.staticData &&
                                        this.props.staticData.OlympusURIType
                                        // &&
                                        // this.props.staticData.OlympusCustomerClassType.filter(
                                        //     (role) =>
                                        //         role.Id ===
                                        //         parseInt(formData['CustomerClass'])
                                        // )
                                    }
                                    label="URI Type"
                                    name="URITypeId"
                                    // inputProps={isSalesForce}
                                    value={globalMdmDetail && globalMdmDetail.URITypeId}
                                    formErrors={
                                        this.state.formErrors
                                            ? this.state.formErrors['URITypeId']
                                            : null
                                    }
                                    onFieldChange={this.onFieldChange}
                                    hide={
                                        allowedFields &&
                                        !allowedFields.includes('URIType')
                                    }
                                    variant="outline"
                                    readOnly={true}
                                />
                                <FormInput
                                    inline
                                    variant="outline"
                                    label="Sales Office"
                                    name="SalesOffice"
                                    type="text"
                                    hide={
                                        allowedFields &&
                                        !allowedFields.includes('SalesOffice')
                                    }
                                    value={
                                        staticData &&
                                        globalMdmDetail &&
                                        this.getStaticDropdownValues(
                                            globalMdmDetail.SalesOfficeTypeId,
                                            this.props.staticData &&
                                            this.props.staticData
                                                .OlympusSalesOfficeType
                                        )
                                    }
                                />
                                <FormInput
                                    inline
                                    variant="outline"
                                    label="Sales Group"
                                    name="SalesGroup"
                                    type="text"
                                    hide={
                                        allowedFields &&
                                        !allowedFields.includes('SalesGroup')
                                    }
                                    value={
                                        staticData &&
                                        globalMdmDetail &&
                                        this.getStaticDropdownValues(
                                            globalMdmDetail.SalesGroupTypeId,
                                            this.props.staticData &&
                                            this.props.staticData
                                                .OlympusSalesGroupType
                                        )
                                    }
                                />
                                <FormInput
                                    inline
                                    variant="outline"
                                    label="Incoterms 1"
                                    name="Incoterms1"
                                    type="text"
                                    hide={
                                        allowedFields &&
                                        !allowedFields.includes('IncoTerms')
                                    }
                                    value={
                                        staticData &&
                                        globalMdmDetail &&
                                        this.getStaticDropdownValues(
                                            globalMdmDetail.IncoTermsTypeId,
                                            this.props.staticData &&
                                            this.props.staticData.OlympusIncoTermsType
                                        )
                                    }
                                />
                                <FormInput
                                    inline
                                    variant="outline"
                                    label="Place of Destination"
                                    name="PlaceOfDestination"
                                    type="text"
                                    hide={
                                        allowedFields &&
                                        !allowedFields.includes('PlaceOfDestination')
                                    }
                                    value={
                                        globalMdmDetail &&
                                        globalMdmDetail.PlaceOfDestination
                                    }
                                />
                                <FormInput
                                    inline
                                    variant="outline"
                                    label="Acct Assignment Group"
                                    name="AcctAssignmentGroupTypeId"
                                    type="text"
                                    hide={
                                        allowedFields &&
                                        !allowedFields.includes('AcctAssignmentGroup')
                                    }
                                    value={
                                        staticData &&
                                        globalMdmDetail &&
                                        this.getStaticDropdownValues(
                                            globalMdmDetail.AcctAssignmentGroupTypeId,
                                            this.props.staticData &&
                                            this.props.staticData
                                                .OlympusAcctAssignmentGroupType
                                        )
                                    }
                                />
                                <FormInput
                                    inline
                                    variant="outline"
                                    label="Tax Classification"
                                    name="TaxClassification"
                                    type="text"
                                    hide={
                                        allowedFields &&
                                        !allowedFields.includes('TaxClassification')
                                    }
                                    value={
                                        staticData &&
                                        globalMdmDetail &&
                                        this.getStaticDropdownValues(
                                            globalMdmDetail.TaxClassificationTypeId,
                                            this.props.staticData &&
                                            this.props.staticData
                                                .OlympusTaxClassificationType
                                        )
                                    }
                                />
                                <FormInput
                                    inline
                                    variant="outline"
                                    label="Customer Number"
                                    name="CustomerNumber"
                                    type="text"
                                    hide={
                                        allowedFields &&
                                        !allowedFields.includes('CustomerNumber')
                                    }
                                    value={
                                        staticData &&
                                        globalMdmDetail &&
                                        globalMdmDetail.CustomerNumber
                                    }
                                />
                            </Box>
                            <Box width={1 / 2} mx="auto" alignItems="center">
                                <FormInput
                                    label="Distribution Channel"
                                    name="DistributionChannel"
                                    inline
                                    variant="outline"
                                    type="text"
                                    value={
                                        staticData &&
                                        globalMdmDetail &&
                                        this.getStaticDropdownValues(
                                            globalMdmDetail.DistributionChannelTypeId,
                                            this.props.staticData &&
                                            this.props.staticData
                                                .OlympusDistributionChannelType
                                        )
                                    }
                                />
                                <FormInput
                                    label="Division"
                                    name="DivisionTypeId"
                                    inline
                                    variant="outline"
                                    type="text"
                                    value={
                                        staticData &&
                                        globalMdmDetail &&
                                        this.getStaticDropdownValues(
                                            globalMdmDetail.DivisionTypeId,
                                            this.props.staticData &&
                                            this.props.staticData.OlympusDivisionType
                                        )
                                    }
                                />
                                <FormInput
                                    label="CompanyCode"
                                    name="CompanyCodeTypeId"
                                    inline
                                    variant="outline"
                                    type="text"
                                    value={
                                        staticData &&
                                        globalMdmDetail &&
                                        this.getStaticDropdownValues(
                                            globalMdmDetail.CompanyCodeTypeId,
                                            this.props.staticData &&
                                            this.props.staticData
                                                .OlympusCompanyCodeType
                                        )
                                    }
                                />
                                <FormInput
                                    label="Sold To"
                                    name="SoldTo"
                                    team="system"
                                    inline
                                    variant="outline"
                                    type="text"
                                    hide={
                                        allowedFields &&
                                        !allowedFields.includes('SoldTo') &&
                                        globalMdmDetail &&
                                        globalMdmDetail.RoleTypeId != 2 &&
                                        globalMdmDetail.RoleTypeId != 3 &&
                                        globalMdmDetail.RoleTypeId != 4
                                    }
                                    value={globalMdmDetail && globalMdmDetail.SoldTo}
                                />
                                <FormInput
                                    label="Language"
                                    name="Language"
                                    team="system"
                                    inline
                                    variant="outline"
                                    type="text"
                                    value={
                                        staticData &&
                                        globalMdmDetail &&
                                        this.getStaticDropdownValues(
                                            globalMdmDetail.LanguageTypeId,
                                            this.props.staticData &&
                                            this.props.staticData.OlympusLanguageType
                                        )
                                    }
                                />
                                <FormInput
                                    label="Customer Class"
                                    name="CustomerClass"
                                    team="system"
                                    inline
                                    variant="outline"
                                    type="text"
                                    hide={
                                        allowedFields &&
                                        !allowedFields.includes('CustomerClass')
                                    }
                                    value={
                                        staticData &&
                                        globalMdmDetail &&
                                        this.getStaticDropdownValues(
                                            globalMdmDetail.CustomerClassTypeId,
                                            this.props.staticData &&
                                            this.props.staticData
                                                .OlympusCustomerClassType
                                        )
                                    }
                                />
                                <FormInput
                                    variant="outline"
                                    label="Price Group"
                                    name="PriceGroup"
                                    value={
                                        staticData &&
                                        globalMdmDetail &&
                                        this.getStaticDropdownValues(
                                            globalMdmDetail.PriceGroupTypeId,
                                            staticData.OlympusPriceGroupType
                                        )
                                    }
                                    hide={
                                        allowedFields &&
                                        !allowedFields.includes('PriceGroup')
                                    }
                                    formErrors={
                                        this.state.formErrors
                                            ? this.state.formErrors['PriceGroup']
                                            : null
                                    }
                                    onFieldChange={this.onFieldChange}
                                    inline={true}
                                />
                                <FormInput
                                    variant="outline"
                                    label="Delivering Plant"
                                    name="DeliveringPlant"
                                    value={
                                        staticData &&
                                        globalMdmDetail &&
                                        this.getStaticDropdownValues(
                                            globalMdmDetail.DeliveringPlantTypeId,
                                            staticData &&
                                            staticData.OlympusDeliveringPlantType
                                        )
                                    }
                                    formErrors={
                                        this.state.formErrors
                                            ? this.state.formErrors['DeliveringPlant']
                                            : null
                                    }
                                    hide={
                                        allowedFields &&
                                        !allowedFields.includes('DeliveringPlant')
                                    }
                                    onFieldChange={this.onFieldChange}
                                    inline={true}
                                />
                                <FormInput
                                    label="Recon Account"
                                    name="ReconAccount"
                                    team="system"
                                    inline
                                    variant="outline"
                                    type="text"
                                    hide={
                                        allowedFields &&
                                        !allowedFields.includes('ReconAccount')
                                    }
                                    // value={
                                    //     globalMdmDetail.ReconAccountTypeId != undefined &&
                                    //     this.props.staticData.OlympusReconAccountType !=
                                    //         undefined
                                    //         ? this.props.staticData
                                    //               .OlympusReconAccountType[
                                    //               globalMdmDetail.ReconAccountTypeId
                                    //           ].Description
                                    //         : globalMdmDetail &&
                                    //           globalMdmDetail.ReconAccountTypeId
                                    // }
                                    value={
                                        globalMdmDetail &&
                                        staticData &&
                                        this.getStaticDropdownValues(
                                            globalMdmDetail.ReconAccountTypeId,
                                            staticData.OlympusReconAccountType
                                        )
                                    }
                                />
                                <FormInput
                                    label="Terms of Payment"
                                    name="TermsofPayment"
                                    team="system"
                                    inline={false}
                                    variant="outline"
                                    type="text"
                                    hide={
                                        allowedFields &&
                                        !allowedFields.includes('TermsOfPayment')
                                    }
                                    value={
                                        staticData &&
                                        globalMdmDetail &&
                                        this.getStaticDropdownValues(
                                            globalMdmDetail.TermsOfPaymentTypeId,
                                            this.props.staticData &&
                                            this.props.staticData
                                                .OlympusTermsOfPaymentType
                                        )
                                    }
                                />
                                <FormInput
                                    label="Currency"
                                    name="Currency"
                                    team="system"
                                    inline
                                    variant="outline"
                                    type="text"
                                    hide={
                                        allowedFields &&
                                        !allowedFields.includes('Currency')
                                    }
                                    value={
                                        staticData &&
                                        globalMdmDetail &&
                                        this.getStaticDropdownValues(
                                            globalMdmDetail.CurrencyTypeId,
                                            this.props.staticData &&
                                            this.props.staticData.OlympusCurrencyType
                                        )
                                    }
                                />
                                <FormInput
                                    label="Primary GPO"
                                    name="PrimaryGPO"
                                    team="system"
                                    inline
                                    variant="outline"
                                    type="text"
                                    hide={
                                        allowedFields &&
                                        !allowedFields.includes('PrimaryGPO')
                                    }
                                    value={globalMdmDetail && globalMdmDetail.PrimaryGPO}
                                />

                                <FormInput
                                    label="Requested Credit Limit"
                                    name="RequestedCreditLimit"
                                    team="system"
                                    inline
                                    variant="outline"
                                    type="text"
                                    hide={
                                        allowedFields &&
                                        !allowedFields.includes('RequestedCreditLimit')
                                    }
                                    value={
                                        globalMdmDetail &&
                                        globalMdmDetail.RequestedCreditLimit
                                    }
                                />
                                <FormInput
                                    label="Priority"
                                    name="Priority"
                                    team="system"
                                    inline
                                    variant="outline"
                                    type="text"
                                    value={
                                        staticData &&
                                        globalMdmDetail &&
                                        this.getStaticDropdownValues(
                                            globalMdmDetail.PriorityTypeId,
                                            this.props.staticData &&
                                            this.props.staticData.OlympusPriorityType
                                        )
                                    }
                                />
                                <FormInput
                                    label="Effective Date"
                                    name="EffectiveDate"
                                    team="system"
                                    inline
                                    variant="outline"
                                    type="text"
                                    value={
                                        globalMdmDetail && globalMdmDetail.EffectiveDate
                                    }
                                />

                                <FormInput
                                    label="Purpose of Request"
                                    name="Purpose"
                                    maxLength={1000}
                                    value={globalMdmDetail && globalMdmDetail.Purpose}
                                    multiline
                                    numberOfLines={
                                        globalMdmDetail.Purpose &&
                                            globalMdmDetail.Purpose.length > 36
                                            ? 3
                                            : 1
                                    }
                                    style={{ lineHeight: '2.5' }}
                                    inline
                                    variant="outline"
                                    type="text"
                                />
                            </Box>
                        </Box>

                        <Box {...showCreditDetail}>
                            <Text
                                mt="42px"
                                mb="20px"
                                fontWeight="regular"
                                color="lightBlue"
                                fontSize={24}
                                pl={4}>
                                CREDIT FIELDS
                            </Text>
                            <Box flexDirection="row" justifyContent="center">
                                <Box width={1 / 2} mx="auto" alignItems="center">
                                    <DynamicSelect
                                        arrayOfData={
                                            this.props.staticData &&
                                            this.props.staticData
                                                .OlympusAccountStatementType
                                        }
                                        value={
                                            workflow.isReadOnly
                                                ? creditDetail &&
                                                parseAndSanitise(
                                                    creditDetail.AccountStatementTypeId
                                                )
                                                : this.state.formData
                                                    ? this.state.formData[
                                                    'AccountStatementTypeId'
                                                    ]
                                                    : null
                                        }
                                        label="Account Statement"
                                        name="AccountStatementTypeId"
                                        hide={
                                            allowedFields &&
                                            !allowedFields.includes('AccountStatement')
                                        }
                                        isRequired
                                        formErrors={
                                            this.state.formErrors
                                                ? this.state.formErrors[
                                                'AccountStatementTypeId'
                                                ]
                                                : null
                                        }
                                        onFieldChange={this.onFieldChange}
                                        inputProps={inputReadonlyProps}
                                    />
                                    <DynamicSelect
                                        arrayOfData={
                                            this.props.staticData &&
                                                this.props.staticData
                                                    .OlympusCreditControlAreaType &&
                                                workflow.isReadOnly
                                                ? this.props.staticData
                                                    .OlympusCreditControlAreaType
                                                : this.getDropdownValues(
                                                    'SalesOrgTypeId',
                                                    this.props.staticData &&
                                                    this.props.staticData
                                                        .OlympusCreditControlAreaType,
                                                    'SalesOrgTypeId'
                                                )
                                        }
                                        value={
                                            workflow.isReadOnly
                                                ? creditDetail &&
                                                parseAndSanitise(
                                                    creditDetail.CreditControlAreaTypeId
                                                )
                                                : this.state.formData
                                                    ? this.state.formData[
                                                    'CreditControlAreaTypeId'
                                                    ]
                                                    : null
                                        }
                                        label="Credit Control Area"
                                        name="CreditControlAreaTypeId"
                                        hide={
                                            allowedFields &&
                                            !allowedFields.includes('CreditControlArea')
                                        }
                                        isRequired
                                        formErrors={
                                            this.state.formErrors
                                                ? this.state.formErrors[
                                                'CreditControlAreaTypeId'
                                                ]
                                                : null
                                        }
                                        inline={workflow.isReadOnly ? true : false}
                                        variant={
                                            workflow.isReadOnly ? 'outline' : 'solid'
                                        }
                                        onFieldChange={this.onFieldChange}
                                        inputProps={inputReadonlyProps}
                                    />
                                    <DynamicSelect
                                        arrayOfData={
                                            this.props.staticData &&
                                            this.props.staticData.OlympusRiskCategoryType
                                        }
                                        label="Risk Category"
                                        name="RiskCategoryTypeId"
                                        isRequired
                                        hide={
                                            allowedFields &&
                                            !allowedFields.includes('RiskCategory')
                                        }
                                        value={
                                            workflow.isReadOnly
                                                ? creditDetail &&
                                                parseAndSanitise(
                                                    creditDetail.RiskCategoryTypeId
                                                )
                                                : this.state.formData
                                                    ? this.state.formData[
                                                    'RiskCategoryTypeId'
                                                    ]
                                                    : null
                                        }
                                        formErrors={
                                            this.state.formErrors
                                                ? this.state.formErrors[
                                                'RiskCategoryTypeId'
                                                ]
                                                : creditDetail.RiskCategoryTypeId
                                        }
                                        inline={workflow.isReadOnly ? true : false}
                                        variant={
                                            workflow.isReadOnly ? 'outline' : 'solid'
                                        }
                                        onFieldChange={this.onFieldChange}
                                        inputProps={inputReadonlyProps}
                                    />

                                    <DynamicSelect
                                        arrayOfData={
                                            this.props.staticData &&
                                            this.props.staticData
                                                .OlympusCustomerCreditGroupType
                                        }
                                        label="Customer Credit Group"
                                        name="CustomerCreditGroupTypeId"
                                        hide={
                                            allowedFields &&
                                            !allowedFields.includes('CustomerCreditGroup')
                                        }
                                        value={
                                            workflow.isReadOnly
                                                ? creditDetail &&
                                                parseAndSanitise(
                                                    creditDetail.CustomerCreditGroupTypeId
                                                )
                                                : this.state.formData
                                                    ? this.state.formData[
                                                    'CustomerCreditGroupTypeId'
                                                    ]
                                                    : null
                                        }
                                        inline={workflow.isReadOnly ? true : false}
                                        variant={
                                            workflow.isReadOnly ? 'outline' : 'solid'
                                        }
                                        formErrors={
                                            this.state.formErrors
                                                ? this.state.formErrors[
                                                'CustomerCreditGroupTypeId'
                                                ]
                                                : null
                                        }
                                        onFieldChange={this.onFieldChange}
                                        inputProps={inputReadonlyProps}
                                    />
                                </Box>
                                <Box width={1 / 2} mx="auto" alignItems="center">
                                    <FormInput
                                        label="Credit Limit"
                                        name="CreditLimit"
                                        hide={
                                            allowedFields &&
                                            !allowedFields.includes('CreditLimit')
                                        }
                                        maxLength={15}
                                        value={
                                            workflow.isReadOnly
                                                ? creditDetail &&
                                                parseAndSanitise(
                                                    creditDetail.CreditLimit
                                                )
                                                : this.state.formData
                                                    ? parseAndSanitise(
                                                        this.state.formData['CreditLimit']
                                                    )
                                                    : null
                                        }
                                        error={
                                            this.state.formErrors
                                                ? this.state.formErrors['CreditLimit']
                                                : null
                                        }
                                        onChange={this.validateNumericKeypad}
                                        variant={
                                            workflow.isReadOnly ? 'outline' : 'solid'
                                        }
                                        inline={workflow.isReadOnly ? true : false}
                                        type="number"
                                        placeholder={'Only numeric values'}
                                    />
                                    <FormInput
                                        label="Cred Info Number"
                                        name="CredInfoNumber"
                                        hide={
                                            allowedFields &&
                                            !allowedFields.includes('CredInfoNumber')
                                        }
                                        maxLength={11}
                                        value={
                                            workflow.isReadOnly
                                                ? creditDetail &&
                                                creditDetail.CredInfoNumber
                                                : this.state.formData
                                                    ? this.state.formData['CredInfoNumber']
                                                    : 0
                                        }
                                        error={
                                            this.state.formErrors
                                                ? this.state.formErrors['CredInfoNumber']
                                                : null
                                        }
                                        onChange={this.validateNumericKeypad}
                                        variant={
                                            workflow.isReadOnly ? 'outline' : 'solid'
                                        }
                                        inline={workflow.isReadOnly ? true : false}
                                        type="test"
                                    />
                                    <FormInput
                                        label="Payment Index"
                                        name="PaymentIndex"
                                        maxLength={3}
                                        hide={
                                            allowedFields &&
                                            !allowedFields.includes('PaymentIndex')
                                        }
                                        value={
                                            workflow.isReadOnly
                                                ? creditDetail &&
                                                creditDetail.PaymentIndex
                                                : this.state.formData
                                                    ? this.state.formData['PaymentIndex']
                                                    : null
                                        }
                                        error={
                                            this.state.formErrors
                                                ? this.state.formErrors['PaymentIndex']
                                                : null
                                        }
                                        onChange={this.validateNumericKeypad}
                                        inline={workflow.isReadOnly ? true : false}
                                        variant={
                                            workflow.isReadOnly ? 'outline' : 'solid'
                                        }
                                        type="text"
                                    />
                                    <FormInput
                                        label="Last Ext Review"
                                        name="LastExtReview"
                                        hide={
                                            allowedFields &&
                                            !allowedFields.includes('LastExtReview')
                                        }
                                        variant={
                                            workflow.isReadOnly ? 'outline' : 'solid'
                                        }
                                        error={
                                            this.state.formErrors
                                                ? this.state.formErrors['EffectiveDate']
                                                : null
                                        }
                                        value={
                                            workflow.isReadOnly
                                                ? creditDetail &&
                                                creditDetail.LastExtReview &&
                                                creditDetail.LastExtReview.split('T')[0]
                                                : this.state.formData
                                                    ? this.state.formData['LastExtReview']
                                                    : null
                                        }
                                        type="date"
                                        inline={workflow.isReadOnly ? true : false}
                                        onChange={(value, element) => {
                                            this.onFieldChange(
                                                new Date(value).toJSON().slice(0, 19),
                                                element
                                            );
                                        }}
                                    />
                                    <FormInput
                                        label="Rating"
                                        name="Rating"
                                        hide={
                                            allowedFields &&
                                            !allowedFields.includes('Rating')
                                        }
                                        type="text"
                                        maxLength={5}
                                        value={
                                            workflow.isReadOnly
                                                ? creditDetail && creditDetail.Rating
                                                : this.state.formData
                                                    ? this.state.formData['Rating']
                                                    : null
                                        }
                                        error={
                                            this.state.formErrors
                                                ? this.state.formErrors['Rating']
                                                : null
                                        }
                                        onChange={this.onFieldChange}
                                        variant={
                                            workflow.isReadOnly ? 'outline' : 'solid'
                                        }
                                        inline={workflow.isReadOnly ? true : false}
                                    />
                                </Box>
                            </Box>
                        </Box>
                        {files && <FilesList files={files} readOnly />}
                        {workflow.isReadOnly ? (
                            <Box mt={4} flexDirection="row" justifyContent="center">
                                <Box width={1 / 2} mx="auto" alignItems="center">
                                    <FormInput
                                        label="Notes"
                                        multiline
                                        inputWidth="100%"
                                        numberOfLines={2}
                                        name="AdditionalNotes"
                                        variant={
                                            workflow.isReadOnly ? 'outline' : 'solid'
                                        }
                                        inline={workflow.isReadOnly ? true : false}
                                        type="text"
                                        value={
                                            workflow.isReadOnly
                                                ? Notes
                                                : this.state.formData
                                                    ? this.state.formData['AdditionalNotes']
                                                    : null
                                        }
                                    />
                                </Box>
                                <Box width={1 / 2} mx="auto" alignItems="center"></Box>
                            </Box>
                        ) : (
                            <FilesList
                                title="NEW ATTACHMENTS"
                                formErrors={this.state.fileErrors}
                                files={selectedFilesIds.map((id) => selectedFiles[id])}
                                onChange={(value, id) => {
                                    this.setState({
                                        selectedFiles: {
                                            ...selectedFiles,
                                            [id]: {
                                                ...selectedFiles[id],
                                                DocumentType: parseInt(value),
                                            },
                                        },
                                    });
                                }}
                                onRemove={(value, id) => this.removeFile(id)}
                            />
                        )}
                    </Box>
                    <Box {...showButtons}>
                        <Flex
                            justifyEnd
                            alignCenter
                            style={{
                                paddingTop: 15,
                                flexDirection: 'row',
                                alignItems: 'center',
                                paddingLeft: 10,
                                paddingRight: 15,
                                marginTop: 20,
                                marginBottom: 10,
                                marginHorizontal: 25,
                            }}>
                            <label htmlFor="file-upload" className="custom-file-upload">
                                <MaterialIcons
                                    name="attach-file"
                                    size={18}
                                    color="#fff"
                                />
                            </label>
                            <input
                                id="file-upload"
                                type="file"
                                key={this.state.fileinputkey}
                                onChange={this.selectFiles}
                                multiple
                            />

                            <Button
                                onPress={(event) =>
                                    this.setState({ isReject: false, modalVisible: true })
                                }
                                title="Approve"
                            />
                            <Button
                                title="Reject"
                                onPress={(event) =>
                                    this.setState({ isReject: true, modalVisible: true })
                                }
                            />
                        </Flex>
                    </Box>
                    {this.state.isInternationalVersionVisible && (
                        <Modal
                            isVisible={this.state.isInternationalVersionVisible}
                            style={{ background: '#FFFFFF' }}>
                            <GlobalMdmFields
                                staticDropDownData={this.props.staticData}
                                formData={globalMdmDetail}
                                isInternationalVersion={true}
                                isOlympus={true}
                                isOlympusModal={true}
                                viewInternationalModal={() => {
                                    this.setState({
                                        isInternationalVersionVisible: false,
                                    });
                                }}
                                readOnly
                            />
                        </Modal>
                    )}
                </View>
            </ScrollView>
        );
    }
}

class Default extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const props = this.props;

        return (
            <DimensionAware
                render={(dimensions) => (
                    <Page
                        {...{
                            ...props,
                            width: getWindowWidth(dimensions),
                            height: getWindowHeight(dimensions),
                            marginBottom: 25,
                        }}
                    />
                )}
            />
        );
    }
}

const mapStateToProps = ({ workflows, myTasks, toasts, staticData }) => {
    const { fetching, alert, readOnly } = myTasks;
    const {
        fetchingfnGroupData,
        statusBarData,
        functionalGroupDetails,
        TasksStatusByTeamId,
        fetchingStatusBar,
    } = workflows;

    const { data, fetching: staticFetching } = staticData;
    return {
        fetching: fetching || fetchingStatusBar || fetchingfnGroupData || staticFetching,
        alert,
        statusBarData,
        functionalGroupDetails,
        readOnly,
        TasksStatusByTeamId,
        toasts,
        staticData: data || {},
    };
};

export default connect(mapStateToProps, {
    saveApolloMyTaskCredit,
    getFunctionalGroupData,
    getStatusBarData,
    removeMessage,
    getStaticRequest,
})(Default);

const styles = StyleSheet.create({
    progressIndicator: {
        flex: 1,
        paddingBottom: 5,
        flexDirection: 'row-reverse',
        alignItems: 'flex-end',
    },
    statusText: {
        fontSize: 15,
        color: '#1D4289',
        fontFamily: 'Poppins',
        textAlign: 'center',
        marginTop: 20,
    },
});
