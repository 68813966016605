import React, { Component, Fragment } from 'react';
import { Flex, Button, Box, Text } from './common';
import { connect } from 'react-redux';
import { FormInput, FormSelect } from './form';
import * as moment from 'moment';
import { ScrollView, TouchableOpacity, View } from 'react-native';
import DynamicSelect from './DynamicSelect';
import idx from 'idx';

class PartnersFilterFields extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const hiddenFields = {
            ...this.props.hiddenFields,
        };
        let { formData = {}, staticDropDownData = [], dropDownDatas = [] } = this.props;
        let SalesOrgType = idx(dropDownDatas, (_) => _.SalesOrgType) || [];
        let salesOrgData = SalesOrgType;
        let isPointman = parseInt(this.props.SystemTypeId) === 3;
        const inputProps = {
            inline: false,
            readOnly: false,
            onChange: this.props.onFieldChange,
        };

        return (
            <Fragment>
                <Box marginLeft="0%" flexDirection="row" justifyContent="left">
                    <Box mt="4px" alignItems="left">
                        <FormInput
                            label="Customer/Workflow Number"
                            name="CustomerOrWorkflowId"
                            error={
                                this.props.formErrors
                                    ? this.props.formErrors['CustomerOrWorkflowId']
                                    : null
                            }
                            value={this.props.formData.CustomerOrWorkflowId || ''}
                            {...inputProps}
                        />

                        {this.props.children}
                    </Box>
                    { isPointman && ( <> <Box mt="4px"  paddingLeft={10} alignItems="left">
                        <FormInput
                            label="Customer Name"
                            name="CustomerName"
                            error={
                                this.props.formErrors
                                    ? this.props.formErrors['CustomerName']
                                    : null
                            }
                            
                            value={this.props.formData.CustomerName || ''}
                            {...inputProps}
                        />

                        {this.props.children}
                    </Box>
                    <Box mt="4px" paddingLeft={10} alignItems="left">
                        <FormInput
                            label="Partner Role"
                            name="Role"
                            error={
                                this.props.formErrors
                                    ? this.props.formErrors['Role']
                                    : null
                            }
                            value={this.props.formData.Role || ''}
                            {...inputProps}
                        />

                        {this.props.children}
                    </Box>
                    <Box mt="4px"   paddingLeft={10} alignItems="left">
                        <FormInput
                            label="Address"
                            name="Address"
                            error={
                                this.props.formErrors
                                    ? this.props.formErrors['Address']
                                    : null
                            }
                            value={this.props.formData.Address || ''}
                            {...inputProps}
                        />

                        {this.props.children}
                    </Box>
                    <Box mt="4px"    paddingLeft={10} alignItems="left">
                        <FormInput
                            label="City"
                            name="City"
                            error={
                                this.props.formErrors
                                    ? this.props.formErrors['City']
                                    : null
                            }
                            value={this.props.formData.City || ''}
                            {...inputProps}
                        />

                        {this.props.children}
                    </Box>
                    </>
                    )}
                    {!isPointman && (
                        <Box mx="2" alignItems="left">
                            <DynamicSelect
                                arrayOfData={salesOrgData.filter(
                                    (sorg) =>
                                        sorg.systemId ===
                                        parseInt(this.props.SystemTypeId)
                                )}
                                label="Sales Org"
                                name="SalesOrgTypeId"
                                isRequired
                                value={this.props.formData.SalesOrgTypeId}
                                formErrors={
                                    this.props.formErrors
                                        ? this.props.formErrors['SalesOrgTypeId']
                                        : null
                                }
                                onFieldChange={this.props.onFieldChange}
                            />
                        </Box>
                    )}
                    {/* {!isPointman && (
                        <Box mx="2" alignItems="left">
                            <DynamicSelect
                                arrayOfData={
                                    dropDownDatas.RoleTypeId &&
                                    dropDownDatas.RoleTypeId.filter(
                                        (role) =>
                                            role.systemId ===
                                                parseInt(this.props.SystemTypeId) &&
                                            role.id <= 6
                                    )
                                }
                                label="Role"
                                name="RoleTypeId"
                                isRequired
                                value={this.props.formData.RoleTypeId}
                                formErrors={
                                    this.props.formErrors
                                        ? this.props.formErrors['RoleTypeId']
                                        : null
                                }
                                onFieldChange={this.props.onFieldChange}
                            />
                        </Box>
                    )} */}
                    {!isPointman && (
                        <Box mx="2" alignItems="left">
                            <DynamicSelect
                                arrayOfData={
                                    dropDownDatas.DistributionChannelType &&
                                    dropDownDatas.DistributionChannelType.filter(
                                        (role) =>
                                            role.systemId ===
                                            parseInt(this.props.SystemTypeId)
                                    )
                                }
                                label="Distribution Channel"
                                name="DistributionChannelTypeId"
                                value={this.props.formData.DistributionChannelTypeId}
                                isRequired
                                formErrors={
                                    this.props.formErrors
                                        ? this.props.formErrors[
                                              'DistributionChannelTypeId'
                                          ]
                                        : null
                                }
                                onFieldChange={this.props.onFieldChange}
                            />
                        </Box>
                    )}
                    {!isPointman && (
                        <Box mx="2" alignItems="left">
                            <DynamicSelect
                                arrayOfData={
                                    dropDownDatas.DivisionType &&
                                    dropDownDatas.DivisionType.filter(
                                        (role) =>
                                            role.systemId ===
                                            parseInt(this.props.SystemTypeId)
                                    )
                                }
                                label="Division"
                                name="DivisionTypeId"
                                value={this.props.formData.DivisionTypeId}
                                isRequired
                                formErrors={
                                    this.props.formErrors
                                        ? this.props.formErrors['DivisionTypeId']
                                        : null
                                }
                                onFieldChange={this.props.onFieldChange}
                            />
                        </Box>
                    )}
                    <View
                        style={{
                            flex: 1,
                        }}>
                        <Flex
                            alignCenter
                            style={{
                                paddingBottom: 15,
                                flexDirection: 'row',
                                alignItems: 'center',
                                paddingLeft: 10,
                                paddingRight: 15,
                                marginBottom: 10,
                                marginHorizontal: 25,
                                marginTop: '35px',
                            }}>
                            <Button
                                // style={{ height: '98%', paddingTop: '5%' }}
                                onPress={this.props.onSubmit}
                                title="Search"
                            />
                        </Flex>
                    </View>
                </Box>
            </Fragment>
        );
    }
}

export default PartnersFilterFields;
