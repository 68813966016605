import React, { Component } from 'react';
import { FormInput, FormSelect } from './form';
import { fetchCustomerMasterDropDownData } from '../redux/DropDownDatas';

class DynamicSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropDownDatas: {},
            fvalue: '',
        };
    }
    componentDidMount() {
        fetchCustomerMasterDropDownData().then((res) => {
            const data = res;
            this.setState({ dropDownDatas: data }, () => {
                if (this.props.readOnly) {
                    this.getDropDownDescription(this.state.dropDownDatas);
                }
            });
        });
    }

    getDropDownDescription = (dropDownDatas) => {
        let fname = this.props.name.trim();
        let { fvalue } = this.state;
        if (dropDownDatas[fname] != undefined && !this.props.arrayOfData) {
            var matched_desc = dropDownDatas[fname].filter(
                (dict) => dict.id === parseInt(this.props.value)
            );
            if (matched_desc && matched_desc.length > 0) {
                var valuepart = matched_desc[0].value || matched_desc[0].Value;
                var descpart = matched_desc[0].description || matched_desc[0].Description;
                fvalue = valuepart
                    ? valuepart + (descpart ? ' : ' + descpart : '')
                    : descpart;
                this.setState({ fvalue: fvalue });
            }
        } else {
            if (this.props.arrayOfData) {
                let stringValID = this.props.stringValID;
                let valp = stringValID ? this.props.value : parseInt(this.props.value);
                var matched_desc = this.props.arrayOfData.filter((dict) => {
                    return dict.Id === valp || dict.id === valp;
                });
                if (matched_desc && matched_desc.length > 0) {
                    var valuepart = matched_desc[0].value || matched_desc[0].Value;
                    var descpart =
                        matched_desc[0].description || matched_desc[0].Description;
                    fvalue = valuepart
                        ? valuepart + (descpart ? ' : ' + descpart : '')
                        : descpart;
                    this.setState({ fvalue: fvalue });
                }
            }
        }
    };
    render() {
        const { inputProps, readOnly } = this.props;
        let arrayOfData = this.props.arrayOfData || [];
        if (this.props.spliceIdZero) {
            arrayOfData = arrayOfData.filter((item) => item.Id !== 0);
        }
        let options =
            arrayOfData !== undefined &&
            arrayOfData.map((item) => {
                if (item.value || item.Value || item.Description || item.description) {
                    let data = {
                        id: item.id || item.Id,
                        value: item.value || item.Value,
                        description: item.description || item.Description,
                    };
                    return (
                        <option
                            team={this.props.team || data.id}
                            title={
                                data &&
                                data.description &&
                                data.description.length > 65 &&
                                (data.value
                                    ? data.value + ' : ' + data.description
                                    : data.description)
                            }
                            key={data.id}
                            value={data.id}
                            disabled={inputProps ? inputProps.disabled : false}>
                            {data.value
                                ? data.value +
                                  (data.description
                                      ? ' : ' +
                                        (data.description.length > 65
                                            ? data.description.substring(0, 65) + '...'
                                            : data.description)
                                      : '')
                                : data.description}
                        </option>
                    );
                }
            });
        const chooseFrom =
            arrayOfData &&
            arrayOfData[0] &&
            arrayOfData[0].Description &&
            arrayOfData[0].Description.includes('Choose from');
        if (!chooseFrom && arrayOfData && arrayOfData[0]) {
            if (
                // arrayOfData[0].Description === null ||
                arrayOfData[0].Description === ''
            ) {
                arrayOfData.shift();
            }
        }

        //if isRequired is false then show choose from option on dropdown list
        let isRequiredValue = this.props.isRequired ? this.props.isRequired : false;
        let displayChoose = isRequiredValue ? 'none' : 'flex';
        return (
            <>
                {!readOnly ? (
                    <FormSelect
                        label={this.props.label}
                        name={this.props.name}
                        value={this.props.value}
                        hide={this.props.hide}
                        small={this.props.small}
                        error={this.props.formErrors ? this.props.formErrors : null}
                        required={this.props.isRequired}
                        onChange={this.props.onFieldChange}
                        variant="solid"
                        disabled={this.props.disabled ? this.props.disabled : false}
                        {...inputProps}>
                        {!chooseFrom && (
                            <option
                                style={{ display: displayChoose }}
                                value={0}
                                team={this.props.team || 'general'}>
                                Choose from...
                            </option>
                        )}
                        {options}
                    </FormSelect>
                ) : (
                    <FormInput
                        px="25px"
                        flex={1 / 4}
                        width={this.props.width}
                        label={this.props.label}
                        hide={this.props.hide}
                        name={this.props.name}
                        small={this.props.small}
                        variant="outline"
                        inline={
                            this.props.inline !== undefined ? this.props.inline : true
                        }
                        type="text"
                        value={this.state.fvalue}
                        readOnly
                        showInlineRequired={this.props.showInlineRequired}
                    />
                )}
            </>
        );
    }
}

export default DynamicSelect;
