import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import M2MCustomerMasterForm from './CustomerMaster';
import OtherRequest from './OtherRequest';
{
    /* <Route path={`${match.url}/`} component={} /> */
}
const M2MComp = ({ match }) => {
    return (
        <Switch>
            <Route
                exact
                path={`${match.url}/customer-master/:id/other-request`}
                component={OtherRequest}
            />
            <Route
                path={`${match.url}/customer-master`}
                component={M2MCustomerMasterForm}
            />
        </Switch>
    );
};
export default M2MComp;
