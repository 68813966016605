import React from 'react';
import {
    ScrollView,
    View,
    TouchableOpacity,
    ActivityIndicator,
    Image,
    StyleSheet,
} from 'react-native';
import {
    DimensionAware,
    getWindowHeight,
    getWindowWidth,
} from 'react-native-dimension-aware';
import { MaterialIcons } from '@expo/vector-icons';
import { Colors, StyledText } from '../../../theme/Index';
import { Flex, Column, Card, Button, Box, Text } from '../../../components/common';
import { FormInput, FormSelect } from '../../../components/form';
import {
    resolveDependencies,
    passFields,
    yupFieldValidation,
    yupAllFieldsValidation,
} from '../../../constants/utils';
import {
    FieldValidationRoleType,
    disabled_mdmFieldsRules,
    mdmFieldsRules,
    ExtendFieldRules,
} from '../../../constants/FieldRules';
import {
    getCustomerFromSAP,
    withDrawRequest,
    getStatusBarData,
} from '../../../appRedux/actions';
import { removeMessage } from '../../../appRedux/actions/Toast';
import GlobalMdmFields from '../../../components/GlobalMdmFields';
import SystemFields from '../../../components/SystemFields';
import { CheckBoxItem } from '../../../components/CheckBoxItem';
import DynamicSelect from '../../../components/DynamicSelect';
import { connect } from 'react-redux';
import {
    fetchCustomerMasterDropDownData,
    fetchRoleFieldMapping,
} from '../../../redux/DropDownDatas';
import Loading from '../../../components/Loading';
import FlashMessage, { FlashMessages } from '../../../components/FlashMessage';
import { ajaxGetRequest } from '../../../appRedux/sagas/config';
import { updateDeltas } from '../../../appRedux/actions/UpdateFlowAction';
import { getStaticRequest } from '../../../appRedux/actions';
import FilesList from '../../../components/FilesList';
import TaskNotes from '../../../components/TaskNotes';
import FileInput from '../../../components/form/FileInput.jsx';
import DeltaField from '../../../components/DeltaField';
import {
    RoleType,
    SystemType,
    DistributionChannelType,
    DivisionType,
    CompanyCodeType,
    SalesOrgType,
} from '../../../constants/WorkflowEnums';

import { normalize, formatRequestorName } from '../../../appRedux/sagas/config';
import MultiColorProgressBar from '../../../components/MultiColorProgressBar';
import BreadCrumbs from '../../../components/BreadCrumbs';
import { GenerateWorkflowId } from '@env';
import idx from 'idx';
import { cos } from 'react-native-reanimated';

const _ = require('lodash');

const initFormdData = {
    SystemTypeId: 1,
};
class Page extends React.Component {
    constructor(props) {
        super(props);
        const editableProp = {
            inline: false,
            variant: 'solid',
            onChange: this.onFieldChange,
        };
        this.state = {
            system: '',
            role: '',
            dropDownDatas: {},
            roleFieldMapping: {},
            pricing: [],
            customermaster: [],
            credit: [],
            contracts: [],
            globaltrade: [],
            isContractEnabled: false,
            formData: {
                displayINCOT2: false,
                display_LN: false,
                TaxClassificationTypeId: 2,
                AccountStatementTypeId: 2,
                SortKey: '009',
                PaymentMethods: 'C',
                AcctgClerk: '01',
                CreditLimit: 1,
            },
            customer70Fields: {},
            updatedFormData: { display: false },
            formErrors: {},
            inputPropsForDefaultRules: { CustomerGroupTypeId: editableProp },
            fileErrors: {},
            selectedFiles: {},
            selectedFilesIds: [],
            files: [],
            dunsData: {},
            readOnly: false,
            isRequestPage: false,
            currentPage: 'update',
            extendSalesOrgEnabled: 'false',
            statusBarData: this.props.statusBarData,
            fileinputkey: Date.now(),
        };
    }

    generateWorkflowId(action) {
        if (action != 'update-request') {
            const url = GenerateWorkflowId;

            ajaxGetRequest(url).then((res) => {
                if (res.IsSuccess)
                    this.setState({
                        fetchingWorkflowId: false,
                        formData: {
                            ...initFormdData,
                            ...this.state.formData,
                            WorkflowId: res.ResultData,
                            UserId: this.state.userId,
                            WorkflowTitle: '',
                        },
                    });
            });
        }
    }

    componentDidMount() {
        const { action, id } = this.props.match.params;
        var jsonBody = {};
        fetchRoleFieldMapping().then((resp) => {
            const data = resp;
            this.setState({ roleFieldMapping: data });
        });

        this.props.getStaticRequest([15, 10, 11, 12, 30, 26, 17, 82]);

        fetchCustomerMasterDropDownData().then((res) => {
            const data = res;
            var isReqPage = action === 'update-request';
            var pageReadOnlyFields = action !== 'update'; //set readonly for my-requests & extend sales org & BlockUnblock .
            this.setState(
                {
                    dropDownDatas: data,
                    readOnly: pageReadOnlyFields,
                    isRequestPage: isReqPage,
                    currentPage: action,
                    extendSalesOrgEnabled: action === 'extend-salesorg' ? true : false,
                },
                this.generateWorkflowId(action)
            );
        });
        if (action === 'update-request') {
            //get sap details for my-request
            jsonBody = {
                WorkflowId: id,
                CustomerNumber: '',
                DivisionTypeId: 0,
                SystemTypeId: 0,
                DistributionChannelTypeId: 0,
                CompanyCodeTypeId: 0,
                SalesOrgTypeId: 0,
                RoleTypeId: 0,
            };
            this.props.getCustomerFromSAP(jsonBody);
            //show status bar for my requests functional team page
            let postJson = {
                workflowId: id,
                fuctionalGroup: '',
                taskId: '',
                userId: localStorage.getItem('userId'),
            };
            this.props.getStatusBarData(postJson);
        } else {
            //for update functional and extend to sales org
            const { state } = this.props.location;
            jsonBody = state.sysFieldsData;
            this.props.getCustomerFromSAP(jsonBody);
            this.setContractsVisibility(state.CategoryTypeId);
        }
    }

    componentWillReceiveProps(newProps) {
        if (newProps.bapiFullSet != this.props.bapiFullSet) {
            this.setState(
                {
                    formData: {
                        ...this.state.formData,
                        ...newProps.bapiFullSet.CustomerData,
                        retrievedBlockUnBlockData: newProps.bapiFullSet.BlockUnblockData,
                        MdmNumber: this.props.match.params.id,
                    },
                    customer70Fields: {
                        ...newProps.bapiFullSet.CustomerData,
                        PaymentHistoryRecord:
                            newProps.bapiFullSet.CustomerData.PaymentHistoryRecord ===
                            'T',
                        OrderCombination:
                            newProps.bapiFullSet.CustomerData.OrderCombination === 'T',
                        Role:
                            FieldValidationRoleType[
                                newProps.bapiFullSet.CustomerData.RoleTypeId
                            ],
                    },
                },
                () => {
                    if (!this.state.isReqPage) {
                        // console.log(
                        //     'newProps.bapiFullSet.CustomerData',
                        //     newProps.bapiFullSet
                        // );
                        // this.props.fetchDuns({
                        //     UserId: localStorage.getItem('userId'),
                        //     DunsNumber: newProps.bapiFullSet.DunsNumber,
                        // });
                        this.createDunsObj(newProps.bapiFullSet.CustomerData);
                    }
                }
            );
        }
        if (newProps.statusBarData != this.props.statusBarData) {
            this.setState({
                statusBarData: newProps.statusBarData,
            });
        }
    }
    setContractsVisibility = (category) => {
        let categoryTypeid = parseInt(category);
        let showContract = false;
        //check if Category = Distributor, OEM, Kitter, Self-Distributor then Contract else Customermaster
        if (
            categoryTypeid === 1 ||
            categoryTypeid === 2 ||
            categoryTypeid === 3 ||
            categoryTypeid === 6
        ) {
            // showContractsForReqDetails = true;
            showContract = true;
        }
        this.setState({ isContractEnabled: showContract });
    };

    isEmpty = (str) => {
        // checking if a string is empty, null or undefined
        return !str || 0 === str.length;
    };

    formatDeltaObj = () => {
        const { action } = this.props.match.params;
        let customerDataModel = {},
            functionElements = [],
            teams = ['pricing', 'customermaster', 'contracts', 'credit', 'globaltrade'];
        for (var i = 0; i < teams.length; i++) {
            let team = teams[i];
            if (this.state[team] != undefined && this.state[team].length > 0) {
                let functionalDelta = {};
                functionalDelta['functionName'] = team;
                functionalDelta['customerElements'] = this.state[team];
                functionElements.push(functionalDelta);
            }
        }

        if (action === 'extend-salesorg') {
            if (functionElements.length <= 0) {
                customerDataModel['functionElements'] = null;
            } else {
                customerDataModel['functionElements'] = functionElements;
            }
        }

        if (action === 'update') {
            if (functionElements.length <= 0) {
                customerDataModel = null;
                this.setState({ noDeltaUpdates: true }, () => window.scrollTo(0, 0));
                setTimeout(
                    function () {
                        this.setState({ noDeltaUpdates: false });
                    }.bind(this),
                    1000
                );
            } else {
                customerDataModel['functionElements'] = functionElements;
            }
        }

        return customerDataModel;
    };

    proceedAction = () => {
        const { history, location } = this.props;
        const { action, id } = this.props.match.params;
        const { state } = location;
        const { formData, selectedFilesIds, selectedFiles } = this.state;
        let customerDataModel = this.formatDeltaObj();
        let jsonObj = {
            userId: localStorage.getItem('userId'),
            workflowId: formData.WorkflowId,
            mdmCustomerId: formData.MdmNumber,
            WorkflowTitle: formData.WorkflowTitle,
            CustomerName: this.props.bapiFullSet.CustomerData.Name1,
            SystemRecordId: state.sysFieldsData.CustomerNumber,
            SystemTypeId: state.sysFieldsData.SystemTypeId,
            RoleTypeId: state.sysFieldsData.RoleTypeId,
            SalesOrgTypeId: state.sysFieldsData.SalesOrgTypeId,
            DivisionTypeId: state.sysFieldsData.DivisionTypeId,
            DistributionChannelTypeId: state.sysFieldsData.DistributionChannelTypeId,
            CompanyCodeTypeId: state.sysFieldsData.CompanyCodeTypeId,
            IsSaveToWorkflow: true,
            customerDataModel:
                formData.Purpose &&
                formData.Purpose.length > 0 &&
                formData.Purpose.trim().length > 0
                    ? {
                          ...customerDataModel,
                          customerElements: [
                              {
                                  name: 'Purpose',
                                  OriginalValue: null,
                                  UpdatedValue: formData.Purpose,
                              },
                          ],
                      }
                    : customerDataModel,
        };

        let data = {};

        if (action === 'update') {
            //update functional
            let updObj = {
                SalesOrgTypeId: state.sysFieldsData.SalesOrgTypeId,
                WorkflowType: 21,
                DUNSData: this.state.dunsData,
            };
            data = { ...jsonObj, ...updObj };
        } else if (action === 'extend-salesorg') {
            var role = state.sysFieldsData.RoleTypeId;
            let wfType = 31;
            if (role === 1 || role === 2) {
                wfType = 35; //soldto,shipto
            } else if (role === 1) {
                wfType = 36; //dropship
            } else wfType = 31;
            let updObj = {
                SourceSalesOrgTypeId: state.sysFieldsData.SalesOrgTypeId,
                TargetSalesOrgTypeId: state.sysFieldsData.TargetSalesOrgTypeId,
                WorkflowType: wfType,
            };
            data = { ...jsonObj, ...updObj };
        }
        let postData = {
            data,
            action,
            files: selectedFilesIds.map((id) => selectedFiles[id]),
            history,
        };
        // console.log('postData....',postData)
        if (customerDataModel != null) this.props.updateDeltas(postData);
    };

    onSubmit = () => {
        let {
            formData,
            customer70Fields,
            updatedFormData,
            selectedFilesIds,
            selectedFiles,
        } = this.state;
        const { Category, ...data } = formData;
        let fileErrors = {};
        let errors = false;

        selectedFilesIds.map((id) => {
            if (selectedFiles[id] && selectedFiles[id].DocumentType <= 0) {
                fileErrors[id] = 'Document Type Required for file';
                errors = true;
            }
        });
        // console.log(customer70Fields)
        let castedFormData = mdmFieldsRules.cast(customer70Fields);

        this.setState({ fileErrors, isFileErrors: errors });
        this.setState(
            {
                formData: {
                    ...data,
                },
            },
            () => {
                yupAllFieldsValidation(
                    { ...castedFormData },
                    ExtendFieldRules,
                    (...rest) => {
                        if (this.state.isFileErrors === false)
                            this.proceedAction(...rest);
                    },
                    this.setFormErrors
                );
            }
        );
    };
    selectFile = (events) => {
        event.preventDefault();

        const { selectedFilesIds, selectedFiles } = this.state;
        const id = events.target.files[0].name;

        this.setState({
            selectedFiles: {
                ...selectedFiles,
                [id]: {
                    data: events.target.files[0],
                    DocumentName: events.target.files[0].name,
                    DocumentType: 0,
                },
            },
            selectedFilesIds: [...selectedFilesIds, id],
            filename: events.target.files[0].name,
            fileinputkey: Date.now(),
        });
    };

    removeFile = (id) => {
        event.preventDefault();

        var selectedFilesDict = this.state.selectedFiles;
        var selectedFilesIdsList = this.state.selectedFilesIds;
        // remove the existing state
        delete selectedFilesDict[id];
        selectedFilesIdsList = selectedFilesIdsList.filter((fileid) => fileid !== id);

        this.setState({
            selectedFiles: selectedFilesDict,
            selectedFilesIds: selectedFilesIdsList,
        });
    };

    isNumber = (n) => {
        return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
    };

    validateNumericKeypad = (value, e) => {
        const { name } = e.target;
        if (value.length !== 0 && !this.isNumber(value)) {
            this.setState({
                formErrors: {
                    [name]: 'Must be digits',
                },
            });
        } else {
            this.onFieldChange(value, e);
        }
    };

    createDunsObj = (data, name = null, val = null) => {
        if (name === null || val === null) {
            this.setState({
                dunsData: {
                    Country: data.Country,
                    Name1: data.Name1,
                    City: data.City,
                    Region: data.Region,
                    Street: data.Street,
                    DunsNumber: data.DunsNumber,
                    SicCode4: data.SicCode4,
                    SicCode6: data.SicCode6,
                    SicCode8: data.SicCode8,
                    TaxNumber: data.Taxnumber,
                    VatRegNo: data.VatRegNo,
                    NaicsCode: data.NaicsCode,
                    NaicsCodeDescription: data.NaicsCodeDescription,
                },
            });
        } else {
            this.setState({
                dunsData: {
                    ...this.state.dunsData,
                    [name]: val,
                },
            });
        }
    };

    onFieldChange = (value, e) => {
        const { name } = e.target;
        var target_team =
            e.target.getAttribute('team') ||
            (e.target &&
                e.target.selectedOptions &&
                e.target.selectedOptions[0].getAttribute('team')) ||
            null;
        var tgt_team = name === 'LastExtReview' ? 'credit' : target_team;
        value = name === 'LastExtReview' ? value.split('T')[0] : value;
        var formDataValue =
            this.isNumber(value) && name != 'CreditLimit' ? parseInt(value, 10) : value;
        let origdata = this.props.bapiFullSet.CustomerData;

        if (name === 'WorkflowTitle') {
            this.setState({
                formData: {
                    ...this.state.formData,
                    [name]: value,
                },
                customer70Fields: {
                    ...this.state.customer70Fields,
                    [name]: value,
                },
            });
        }
        if (name === 'Purpose') {
            this.setState({
                formData: {
                    ...this.state.formData,
                    [name]: value,
                },
                customer70Fields: {
                    ...this.state.customer70Fields,
                    [name]: value,
                },
            });
        }
        if (
            name === 'DunsNumber' ||
            name === 'SicCode4' ||
            name === 'SicCode6' ||
            name === 'SicCode8' ||
            name === 'VatRegNo' ||
            name === 'TaxNumber' ||
            name === 'NaicsCode' ||
            name === 'NaicsCodeDescription'
        ) {
            let origdata = this.props.bapiFullSet.CustomerData;

            let origdatavalue = origdata[name]
                ? origdata[name].length != 0
                    ? origdata[name]
                    : ''
                : '';
            let newValue = value != null ? (value.length != 0 ? value : '') : '';

            let newOne =
                origdatavalue.length === 0 && newValue.length === 0
                    ? origdata[name]
                    : value;
            this.createDunsObj(origdata, name, newOne);
        } else if (tgt_team != null) {
            let origdatavalue =
                origdata[name] != null
                    ? origdata[name].length != 0
                        ? origdata[name]
                        : ''
                    : '';
            let newValue = value != null ? (value.length != 0 ? value : '') : '';

            let newOne =
                origdatavalue.length === 0 && newValue.length === 0
                    ? origdata[name]
                    : value;
            let teamsDelta = this.state[tgt_team] || [];
            let filterTeamDelta = [],
                newDeltaValue = {};
            if (origdatavalue === newOne) {
                //remove the delta obj from team if both orig , new data has null/empty data
                filterTeamDelta = teamsDelta.filter((delta) => delta.name != name);
            } else if (
                name.toLowerCase().includes('typeid') &&
                parseInt(origdatavalue) === parseInt(value)
            ) {
                //remove the delta obj from team if same dropdown selected again.
                filterTeamDelta = teamsDelta.filter((delta) => delta.name != name);
            } else {
                //else create the new delta obj and filter the existing delta obj with same key
                var finalvalue = value;
                if (name.toLowerCase().includes('typeid')) {
                    finalvalue = parseInt(value);
                }
                newDeltaValue = {
                    name: name,
                    originalValue: origdata[name],
                    updatedValue: finalvalue,
                };
                filterTeamDelta = teamsDelta.filter((delta) => delta.name != name);
            }

            this.setState(
                (state) => {
                    let list = filterTeamDelta;
                    if (Object.keys(newDeltaValue).length != 0) {
                        list = [...filterTeamDelta, newDeltaValue];
                    }
                    return {
                        ...this.state,
                        [tgt_team]: list,
                        updatedFormData: {
                            ...this.state.updatedFormData,
                            [name]: value,
                        },
                        customer70Fields: {
                            ...this.state.customer70Fields,
                            [name]: value,
                        },
                        formData: {
                            ...this.state.formData,
                            [name]: formDataValue,
                        },
                        formErrors: {},
                    };
                },
                () => {
                    if (
                        name === 'CustomerClassTypeId' ||
                        name === 'IncoTerms1TypeId' ||
                        name === 'CustomerGroupTypeId'
                    ) {
                        this.validateRules(name, value);
                    }
                    // console.log(
                    //     'team',
                    //     this.state[tgt_team],
                    //     'up',
                    //     this.state.updatedFormData,
                    //     'fm',
                    //     this.state.formData
                    // );
                }
            );
        }
    };

    setFormErrors = (errors) => {
        const { formErrors } = this.state;
        this.setState({ formErrors: errors, notifyBadData: true }, () =>
            window.scrollTo(0, 0)
        );
        setTimeout(
            function () {
                this.setState({ notifyBadData: false });
            }.bind(this),
            1000
        );
    };

    setFormDataValues = (name, value) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: value,
            },
            customer70Fields: {
                ...this.state.customer70Fields,
                [name]: value,
            },
        });
    };

    //this is called during validate rules and to set checkbox items
    setFunctionalTeamDeltaObject = (key = null, fieldValue = null, team = null) => {
        let value = fieldValue;
        //checkbox item fieldChange
        if (fieldValue === null) {
            //check if string is empty
            var currentBooleanValue =
                this.state.formData[key] != undefined && this.state.formData[key] === 'T';
            value = !currentBooleanValue;
        }
        let origdata = this.props.bapiFullSet && this.props.bapiFullSet.CustomerData;
        var formDataValue = this.isNumber(value) ? parseInt(value, 10) : value; //if the value is numeric type , convert string '2' to 2
        let newDeltaValue = {
            name: key,
            originalValue: origdata[key],
            updatedValue: value,
        };

        if (key === 'OrderCombination' || key === 'PaymentHistoryRecord') {
            newDeltaValue.originalValue = origdata;

            if (newDeltaValue.originalValue === newDeltaValue.updatedValue) {
                newDeltaValue = null;
            }
        }

        let keyValDict = {};

        if (key === 'IncoTerms2') {
            let inco1Val = this.state.formData['IncoTerms1TypeId'];
            if (inco1Val === 1)
                keyValDict = { isRequiredINCOT2: true, isReadonlyINCOT2: false };
            else if (
                inco1Val === 3 ||
                inco1Val === 4 ||
                inco1Val === 5 ||
                inco1Val === 7 ||
                inco1Val === 8
            )
                keyValDict = { isRequiredINCOT2: false, isReadonlyINCOT2: true };
            else keyValDict = { isRequiredINCOT2: false, isReadonlyINCOT2: false };
        }

        let teamsDelta = this.state[team] || [];
        let filterTeamDelta = teamsDelta.filter((delta) => delta.name != key);
        this.setState(
            (state) => {
                const list = newDeltaValue
                    ? [...filterTeamDelta, newDeltaValue]
                    : [...filterTeamDelta];
                return {
                    ...this.state,
                    updatedFormData: {
                        ...this.state.updatedFormData,
                        ...keyValDict,
                        [key]: value,
                    },
                    [team]: list,
                    formData: {
                        ...this.state.formData,
                        ...keyValDict,
                        [key]: formDataValue,
                    },
                    customer70Fields: {
                        ...this.state.customer70Fields,
                        ...keyValDict,
                        [key]: value,
                    },
                };
            }
            // () => console.log('statess', this.state)
        );
    };
    setInputPropsForDefaultRules = (field_name, property) => {
        this.setState({
            inputPropsForDefaultRules: {
                ...this.state.inputPropsForDefaultRules,
                [field_name]: property,
            },
        });
    };

    // display or set input/dropdowns values based on rules
    validateRules = (stateKey, stateVal) => {
        const readOnlyInputprop = { inline: true, variant: 'outline' };
        const editInputProp = {
            inline: false,
            variant: 'solid',
            onChange: this.onFieldChange,
        };
        const readOnlyDropDown = { disabled: true };
        let showContracts = this.state.isContractEnabled;

        // check for CustomerPriceProcTypeId
        if (stateKey === 'CustomerClassTypeId') {
            var CC_val = stateVal;
            if (['1', '2', '3', '4', '5'].includes(CC_val)) {
                this.setFunctionalTeamDeltaObject(
                    'CustomerPriceProcTypeId',
                    '2',
                    'customermaster'
                );
                this.setInputPropsForDefaultRules(
                    'CustomerPriceProcTypeId',
                    readOnlyDropDown
                );
            } else {
                this.setFunctionalTeamDeltaObject(
                    'CustomerPriceProcTypeId',
                    '',
                    'customermaster'
                );
                this.setInputPropsForDefaultRules('CustomerPriceProcTypeId', {
                    disabled: false,
                });
            }
        }
        // check for incoterms2

        if (stateKey === 'IncoTerms1TypeId') {
            var team = showContracts ? 'contracts' : 'customermaster';
            var INCOT1_val = stateVal;
            if (INCOT1_val === '3') {
                this.setFunctionalTeamDeltaObject('IncoTerms2', 'Carriage Paid To', team);
            } else if (INCOT1_val === '4') {
                this.setFunctionalTeamDeltaObject(
                    'IncoTerms2',
                    'Delivered at Location',
                    team
                );
            } else if (INCOT1_val === '5') {
                this.setFunctionalTeamDeltaObject(
                    'IncoTerms2',
                    'Delivered Duty Paid',
                    team
                );
            } else if (INCOT1_val === '7') {
                this.setFunctionalTeamDeltaObject('IncoTerms2', 'Ex Works', team);
            } else if (INCOT1_val === '8') {
                this.setFunctionalTeamDeltaObject('IncoTerms2', 'Free Carrier', team);
            } else {
                this.setFunctionalTeamDeltaObject('IncoTerms2', '', team);
            }
        }

        // check for AccountTypeId
        if (stateKey === 'CustomerGroupTypeId') {
            var team = showContracts ? 'contracts' : 'customermaster';
            var cg_val = stateVal;
            const readOnlyDropDown = { disabled: true };
            if (cg_val === '1' || cg_val === '10') {
                this.setFunctionalTeamDeltaObject('AccountTypeId', '1', team);
                this.setInputPropsForDefaultRules('AccountTypeId', readOnlyDropDown);
            } else if (cg_val === '2' || cg_val === '7') {
                this.setFunctionalTeamDeltaObject('AccountTypeId', '2', team);
                this.setInputPropsForDefaultRules('AccountTypeId', readOnlyDropDown);
            } else if (
                cg_val === '3' ||
                cg_val === '4' ||
                cg_val === '6' ||
                cg_val === '11'
            ) {
                this.setFunctionalTeamDeltaObject('AccountTypeId', '3', team);
                this.setInputPropsForDefaultRules('AccountTypeId', readOnlyDropDown);
            } else if (cg_val === '8') {
                this.setFunctionalTeamDeltaObject('AccountTypeId', '6', team);
                this.setInputPropsForDefaultRules('AccountTypeId', readOnlyDropDown);
            } else {
                this.setFunctionalTeamDeltaObject('AccountTypeId', '0', team);
                this.setInputPropsForDefaultRules('AccountTypeId', {
                    disabled: false,
                });
            }
        }
    };

    render() {
        const { width, location, alert } = this.props;
        const {
            dropDownDatas,
            roleFieldMapping,
            inputPropsForDefaultRules,
            selectedFilesIds,
            selectedFiles,
            isRequestPage,
            currentPage,
            extendSalesOrgEnabled,
        } = this.state;
        const {
            bapiFullSet: {
                CustomerData: globalDetail = {},
                Deltas: erpDeltas = [],
                TaskNoteData: tasknotes = [],
                RequestorName,
            },
        } = this.props;
        const { state } = location;
        const { action } = this.props.match.params;
        var requestor = RequestorName;
        var signedUser = formatRequestorName(localStorage.getItem('userId'));
        var IsWithdrawAllowed =
            signedUser === requestor ? this.props.bapiFullSet.IsWithdrawAllowed : false;

        let teams = JSON.parse(localStorage.getItem('teamIds'));
        // Hide options for Search team users of CM system / AD groups
        let isSearchTeamUser =
            (teams && teams.includes(20) && teams.length == 1) ||
            (teams && teams.length == 0) ||
            (teams && teams.includes(99));
        let withdrawToggle = IsWithdrawAllowed && !isSearchTeamUser;

        let workflowtype = 'Update';
        let Deltas = [],
            MdmNumber = '',
            SystemRecordId = '',
            globalMdmDetail,
            workFlowTaskStatus = [],
            showContracts = false,
            showInlineAstreik = true;

        if (currentPage === 'update-request') {
            //if on my-requests page
            showInlineAstreik = false;
            let CategoryTypeId = this.props.bapiFullSet.CategoryTypeId;
            globalMdmDetail = {
                ...globalDetail,
                // ...this.props.dunsFields,
                CategoryTypeId,
            };
            Deltas = normalize(erpDeltas || []);
            MdmNumber = this.props.bapiFullSet.MdmNumber;
            workFlowTaskStatus = this.state.statusBarData || [];
            workFlowTaskStatus = workFlowTaskStatus.filter(function (item) {
                return item.WorkflowTaskStateTypeId != 4;
            });
            var stateWFType = state.Type || state.WorkflowType;
            workflowtype = stateWFType.toLowerCase().includes('update')
                ? 'Update'
                : stateWFType.toLowerCase().includes('extend')
                ? 'Extend to New Sales Org'
                : 'Block/UnBlock';
            let categoryTypeid = parseInt(this.props.bapiFullSet.CategoryTypeId);
            //check if Category = Distributor, OEM, Kitter, Self-Distributor then Contract else Customermaster
            if (
                categoryTypeid === 1 ||
                categoryTypeid === 2 ||
                categoryTypeid === 3 ||
                categoryTypeid === 6
            )
                // showContractsForReqDetails = true;
                showContracts = true;
        } else {
            if (action === 'extend-salesorg') {
                showInlineAstreik = false;
            }
            //for update and extend sales org
            let CategoryTypeId =
                this.props.bapiFullSet.CategoryTypeId || state.CategoryTypeId;
            globalMdmDetail = {
                ...globalDetail,
                ...state.sysFieldsData,
                // ...this.props.dunsFields,
                CategoryTypeId,
            };
            MdmNumber = state.MdmNumber;
            let categoryTypeid = parseInt(state.CategoryTypeId);
            //check if Category = Distributor, OEM, Kitter, Self-Distributor then Contract else Customermaster
            if (
                categoryTypeid === 1 ||
                categoryTypeid === 2 ||
                categoryTypeid === 3 ||
                categoryTypeid === 6
            )
                // showContractsForReqDetails = true;
                showContracts = true;
        }

        const RoleFields = idx(roleFieldMapping, (_) => _.RoleFields) || [];
        const RoleTypeId = globalMdmDetail.RoleTypeId;
        const SystemTypeId = globalMdmDetail.SystemTypeId || globalMdmDetail.SystemType;
        const allowedFieldList = RoleFields.filter(
            (item) => item.RoleTypeId === RoleTypeId && item.SystemTypeId === SystemTypeId
        );
        let allowedFields = undefined;

        if (allowedFieldList && allowedFieldList.length > 0) {
            allowedFields = allowedFieldList[0].Fields;
        }
        SystemRecordId = this.props.bapiFullSet.SystemRecordId;
        const { WorkflowDocuments: files = [] } = this.props.bapiFullSet;
        // console.log('glob', globalMdmDetail, 'stateobj', this.state, 'props', this.props);
        const pageProps = this.state.readOnly
            ? {
                  inline: true,
                  variant: 'outline',
                  readOnly: true,
              }
            : {
                  inline: false,
                  readOnly: false,
                  onBlur: this.props.onFieldChange,
              };
        let nonReadOnlyForExtendSalesOrg = extendSalesOrgEnabled ? false : pageProps; // read only false when extend sales org for tax clasification
        let disp_payterms = false;
        if (this.state && this.state.formData.Category != undefined) {
            var source_category = this.state.formData.Category.toLowerCase();
            if (
                source_category === 'direct' ||
                source_category === 'dropship' ||
                source_category === 'other'
            ) {
                disp_payterms = true;
            }
        }
        var bgcolor = alert.color || '#FFF';

        if (this.props.fetching)
            return (
                <Box
                    display="flex"
                    flex={1}
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                    minHeight="650px"
                    bg="#eff3f6">
                    <FlashMessages
                        toasts={this.props.toasts}
                        onDismiss={this.props.removeMessage}
                    />
                    <View>
                        <ActivityIndicator size="large" />
                    </View>
                </Box>
            );

        const _role = RoleType[globalMdmDetail && globalMdmDetail.RoleTypeId] || '';

        return (
            <ScrollView
                keyboardShouldPersistTaps="always"
                style={{
                    backgroundColor: '#eff3f6',
                    paddingTop: isRequestPage ? 0 : 50,
                    paddingBottom: 75,
                }}>
                {this.state.notifyBadData && (
                    <FlashMessage
                        animate
                        bg={{ backgroundColor: '#c0392b' }}
                        message={'Bad Data , Cannot Submit a Request '}
                    />
                )}
                {this.state.noDeltaUpdates && (
                    <FlashMessage
                        animate
                        bg={{ backgroundColor: '#2980b9' }}
                        message={'No Updates Found, Cannot Submit a Request '}
                    />
                )}
                <FlashMessages
                    toasts={this.props.toasts}
                    onDismiss={this.props.removeMessage}
                />
                {isRequestPage && (
                    <>
                        <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            justifyContent="space-between">
                            {/* remove bread crumbs */}
                            {/* this.props.location.pathname !== '/' && (
                            <BreadCrumbs
                                title={'Search results screen2'}
                                currentPath={this.props.location.pathname}
                                links={this.props.location.pathname.split('/')}
                                blacklist={[]}
                            />
                        )*/}
                            <Text
                                style={{
                                    fontWeight: '500',
                                    fontSize: 24,
                                    color: Colors.lightBlue,
                                    marginTop: 25,
                                    marginBottom: 20,
                                    paddingLeft: 45,
                                }}>
                                MY REQUESTS - {workflowtype}
                            </Text>
                            <Box
                                marginTop="25px"
                                flex={3 / 4}
                                flexDirection="row-reverse"
                                alignItems="flex-end">
                                <MultiColorProgressBar
                                    readings={this.state.statusBarData}
                                />
                            </Box>
                        </Box>
                    </>
                )}
                <View
                    style={{
                        flex: 1,
                        paddingHorizontal: width < 1440 ? 75 : width * 0.1,
                        paddingBottom: 10,
                    }}>
                    <Box fullHeight my={2}>
                        <Box
                            flexDirection="row"
                            justifyContent="space-around"
                            my={4}
                            alignItems="center">
                            <FormInput
                                padding="8px 25px 0px 25px"
                                style={{ lineHeight: '2', paddingBottom: 0 }}
                                flex={1 / 4}
                                required
                                onChange={this.onFieldChange}
                                error={
                                    this.state.formErrors
                                        ? this.state.formErrors['WorkflowTitle']
                                        : null
                                }
                                value={
                                    isRequestPage
                                        ? this.props.bapiFullSet.WorkflowTitle
                                        : this.state.formData.WorkflowTitle || ''
                                }
                                label="Workflow Title"
                                name="WorkflowTitle"
                                maxLength={40}
                                inline={false}
                                variant={nonReadOnlyForExtendSalesOrg.variant}
                                readOnly={nonReadOnlyForExtendSalesOrg.readOnly}
                            />
                            <FormInput
                                px="25px"
                                flex={1 / 4}
                                label="Workflow Number"
                                name="WorkflowId"
                                style={{ lineHeight: '2' }}
                                variant="outline"
                                type="text"
                                value={
                                    !isRequestPage
                                        ? this.state.formData.WorkflowId
                                        : this.props.match.params.id
                                }
                            />
                            {!this.isEmpty(SystemRecordId) && (
                                <FormInput
                                    px="25px"
                                    flex={1 / 4}
                                    label="Customer Number"
                                    name="Customer Number"
                                    style={{ lineHeight: '2' }}
                                    variant="outline"
                                    type="text"
                                    value={SystemRecordId || ''}
                                />
                            )}

                            {!this.isEmpty(MdmNumber) && (
                                <TouchableOpacity
                                    onPress={() =>
                                        this.props.history.push(
                                            `/search-results/${MdmNumber}`
                                        )
                                    }>
                                    <FormInput
                                        px="25px"
                                        flex={1 / 4}
                                        label="MDM Number"
                                        name="MdmNumber"
                                        style={{ lineHeight: '2' }}
                                        variant="outline"
                                        type="text"
                                        value={MdmNumber || ''}
                                    />
                                </TouchableOpacity>
                            )}
                        </Box>

                        <GlobalMdmFields
                            formData={globalMdmDetail}
                            readOnly
                            deltas={Deltas ? Deltas : {}}
                            onFieldChange={this.onFieldChange}
                            staticDropDownData={this.props.staticData}
                        />

                        <Text
                            m="10px 0 24px 5%"
                            ml="2%"
                            fontWeight="light"
                            color="#4195C7"
                            fontSize="24px">
                            SYSTEM FIELDS
                        </Text>
                        <Box flexDirection="row" justifyContent="center">
                            <Box width={1 / 2} mx="auto" alignItems="center">
                                {Deltas && Deltas['SystemType'] ? (
                                    <DeltaField delta={Deltas['SystemType']} />
                                ) : (
                                    <FormInput
                                        label="System"
                                        name="System"
                                        team="system"
                                        inline
                                        variant="outline"
                                        type="text"
                                        value={
                                            SystemType[
                                                globalMdmDetail &&
                                                    (globalMdmDetail.SystemTypeId ||
                                                        globalMdmDetail.SystemType)
                                            ]
                                        }
                                    />
                                )}
                                {Deltas && Deltas['RoleTypeId'] ? (
                                    <DeltaField delta={Deltas['RoleTypeId']} />
                                ) : (
                                    <FormInput
                                        label="Role"
                                        name="Role"
                                        team="system"
                                        inline
                                        variant="outline"
                                        type="text"
                                        value={
                                            RoleType[
                                                globalMdmDetail &&
                                                    globalMdmDetail.RoleTypeId
                                            ]
                                        }
                                    />
                                )}

                                {Deltas && Deltas['SalesOrgTypeId'] ? (
                                    <DeltaField delta={Deltas['SalesOrgTypeId']} />
                                ) : (
                                    <FormInput
                                        label="Sales Org"
                                        name="SalesOrg"
                                        team="system"
                                        inline
                                        variant="outline"
                                        type="text"
                                        value={
                                            SalesOrgType[
                                                globalMdmDetail &&
                                                    globalMdmDetail.SalesOrgTypeId
                                            ]
                                        }
                                    />
                                )}
                                {this.state.extendSalesOrgEnabled ||
                                workflowtype === 'Extend to New Sales Org' ? (
                                    <FormInput
                                        label="Target Sales Org"
                                        name="TargetSalesOrg"
                                        team="system"
                                        inline
                                        variant="outline"
                                        type="text"
                                        value={
                                            SalesOrgType[
                                                this.props.bapiFullSet[
                                                    'TargetSalesOrgTypeId'
                                                ] ||
                                                    (globalMdmDetail &&
                                                        globalMdmDetail.TargetSalesOrgTypeId)
                                            ]
                                        }
                                    />
                                ) : null}
                            </Box>
                            <Box width={1 / 2} mx="auto" alignItems="center">
                                {Deltas && Deltas['DistributionChannelTypeId'] ? (
                                    <DeltaField
                                        delta={Deltas['DistributionChannelTypeId']}
                                    />
                                ) : (
                                    <FormInput
                                        label="Distribution Channel"
                                        name="DistributionChannel"
                                        team="system"
                                        inline
                                        variant="outline"
                                        type="text"
                                        value={
                                            DistributionChannelType[
                                                globalMdmDetail &&
                                                    globalMdmDetail.DistributionChannelTypeId
                                            ]
                                        }
                                    />
                                )}

                                {Deltas && Deltas['DivisionTypeId'] ? (
                                    <DeltaField delta={Deltas['DivisionTypeId']} />
                                ) : (
                                    <FormInput
                                        label="Division"
                                        name="DivisionTypeId"
                                        team="system"
                                        inline
                                        variant="outline"
                                        type="text"
                                        value={
                                            DivisionType[
                                                globalMdmDetail &&
                                                    globalMdmDetail.DivisionTypeId
                                            ]
                                        }
                                    />
                                )}

                                {Deltas && Deltas['CompanyCodeTypeId'] ? (
                                    <DeltaField delta={Deltas['CompanyCodeTypeId']} />
                                ) : (
                                    <FormInput
                                        label="CompanyCode"
                                        name="CompanyCodeTypeId"
                                        team="system"
                                        inline
                                        variant="outline"
                                        type="text"
                                        value={
                                            CompanyCodeType[
                                                globalMdmDetail &&
                                                    globalMdmDetail.CompanyCodeTypeId
                                            ]
                                        }
                                    />
                                )}

                                {action === 'update-request' && (
                                    <FormInput
                                        label="Purpose of Request"
                                        name="Purpose"
                                        value={
                                            (globalMdmDetail &&
                                                globalMdmDetail.Purpose) ||
                                            (Deltas['Purpose'] &&
                                                Deltas['Purpose'].UpdatedValue) ||
                                            (this.state.formData &&
                                                this.state.formData
                                                    .retrievedBlockUnBlockData &&
                                                this.state.formData
                                                    .retrievedBlockUnBlockData.Purpose)
                                        }
                                        multiline
                                        numberOfLines={
                                            (globalMdmDetail.Purpose &&
                                                globalMdmDetail.Purpose.length > 36) ||
                                            (Deltas.Purpose && Deltas.Purpose.length > 36)
                                                ? 3
                                                : 1
                                        }
                                        style={{ lineHeight: '2.5' }}
                                        inline
                                        variant="outline"
                                        maxLength={1000}
                                        type="text"
                                    />
                                )}
                            </Box>
                        </Box>

                        <React.Fragment key="customer-master">
                            <Text
                                m="10px 0 24px 5%"
                                ml="2%"
                                mt="2%"
                                fontWeight="light"
                                color="#4195C7"
                                fontSize="24px">
                                CUSTOMER MASTER FIELDS
                            </Text>

                            <Box flexDirection="row" justifyContent="center">
                                <Box width={1 / 2} mx="auto" alignItems="center">
                                    {Deltas && Deltas['TransporationZone'] ? (
                                        <DeltaField delta={Deltas['TransporationZone']} />
                                    ) : (
                                        <FormInput
                                            label="Transportation Zone"
                                            name="TransporationZone"
                                            maxLength={10}
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes(
                                                    'TransporationZone'
                                                )
                                            }
                                            team="customermaster"
                                            error={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                          'TransporationZone'
                                                      ]
                                                    : null
                                            }
                                            type="text"
                                            required
                                            value={
                                                this.state.formData['TransporationZone']
                                            }
                                            onChange={this.onFieldChange}
                                            {...pageProps}
                                            showInlineRequired={showInlineAstreik}
                                        />
                                    )}
                                    {Deltas && Deltas['SearchTerm1'] ? (
                                        <DeltaField delta={Deltas['SearchTerm1']} />
                                    ) : (
                                        <FormInput
                                            label="Search Term 1"
                                            name="SearchTerm1"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes('SearchTerm1')
                                            }
                                            team="customermaster"
                                            readOnly={this.state.readOnly}
                                            maxLength={20}
                                            value={
                                                this.state.formData
                                                    ? this.state.formData['SearchTerm1']
                                                    : null
                                            }
                                            error={
                                                this.state.formErrors
                                                    ? this.state.formErrors['SearchTerm1']
                                                    : null
                                            }
                                            onChange={this.onFieldChange}
                                            type="text"
                                            {...pageProps}
                                        />
                                    )}
                                    {Deltas && Deltas['SearchTerm2'] ? (
                                        <DeltaField delta={Deltas['SearchTerm2']} />
                                    ) : (
                                        <FormInput
                                            label="Search Term 2"
                                            name="SearchTerm2"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes('SearchTerm2')
                                            }
                                            readOnly={this.state.readOnly}
                                            maxLength={20}
                                            team="customermaster"
                                            value={
                                                this.state.formData
                                                    ? this.state.formData['SearchTerm2']
                                                    : null
                                            }
                                            error={
                                                this.state.formErrors
                                                    ? this.state.formErrors['SearchTerm2']
                                                    : null
                                            }
                                            onChange={this.onFieldChange}
                                            type="text"
                                            {...pageProps}
                                        />
                                    )}
                                    {Deltas && Deltas['TaxNumber2'] ? (
                                        <DeltaField delta={Deltas['TaxNumber2']} />
                                    ) : (
                                        <FormInput
                                            label="Tax Number 2"
                                            name="TaxNumber2"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes('TaxNumber2')
                                            }
                                            readOnly={this.state.readOnly}
                                            maxLength={11}
                                            team="customermaster"
                                            value={
                                                this.state.formData
                                                    ? this.state.formData['TaxNumber2']
                                                    : null
                                            }
                                            variant="solid"
                                            error={
                                                this.state.formErrors
                                                    ? this.state.formErrors['TaxNumber2']
                                                    : null
                                            }
                                            onChange={this.validateNumericKeypad}
                                            type="text"
                                            {...pageProps}
                                            placeholder={
                                                this.state.readOnly
                                                    ? ''
                                                    : 'Only numeric values'
                                            }
                                        />
                                    )}

                                    {Deltas && Deltas['CustomerClassTypeId'] ? (
                                        <DeltaField
                                            delta={Deltas['CustomerClassTypeId']}
                                        />
                                    ) : (
                                        <DynamicSelect
                                            readOnly={this.state.readOnly}
                                            team="customermaster"
                                            arrayOfData={
                                                dropDownDatas.CustomerClassTypeId
                                            }
                                            label="Customer Class "
                                            name="CustomerClassTypeId"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes(
                                                    'CustomerClassTypeId'
                                                )
                                            }
                                            isRequired={true}
                                            value={
                                                this.state.formData
                                                    ? this.state.formData[
                                                          'CustomerClassTypeId'
                                                      ]
                                                    : null
                                            }
                                            formErrors={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                          'CustomerClassTypeId'
                                                      ]
                                                    : null
                                            }
                                            onFieldChange={this.onFieldChange}
                                            showInlineRequired={showInlineAstreik}
                                        />
                                    )}
                                    {Deltas && Deltas['CustomerPriceProcTypeId'] ? (
                                        <DeltaField
                                            delta={Deltas['CustomerPriceProcTypeId']}
                                        />
                                    ) : (
                                        <DynamicSelect
                                            readOnly={this.state.readOnly}
                                            team="customermaster"
                                            arrayOfData={
                                                dropDownDatas.CustomerPriceProcTypeId
                                            }
                                            label="Customer Price Proc"
                                            name="CustomerPriceProcTypeId"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes(
                                                    'CustomerPriceProcTypeId'
                                                )
                                            }
                                            value={
                                                this.state.formData
                                                    ? this.state.formData[
                                                          'CustomerPriceProcTypeId'
                                                      ]
                                                    : null
                                            }
                                            isRequired={true}
                                            formErrors={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                          'CustomerPriceProcTypeId'
                                                      ]
                                                    : null
                                            }
                                            onFieldChange={this.onFieldChange}
                                            inputProps={
                                                inputPropsForDefaultRules[
                                                    'CustomerPriceProcTypeId'
                                                ]
                                            }
                                            showInlineRequired={showInlineAstreik}
                                        />
                                    )}
                                    {Deltas && Deltas['IndustryCodeTypeId'] ? (
                                        <DeltaField
                                            delta={Deltas['IndustryCodeTypeId']}
                                        />
                                    ) : (
                                        <DynamicSelect
                                            readOnly={this.state.readOnly}
                                            team="customermaster"
                                            arrayOfData={dropDownDatas.IndustryCodeTypeId}
                                            label="Industry Code 1"
                                            name="IndustryCodeTypeId"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes(
                                                    'IndustryCodeTypeId'
                                                )
                                            }
                                            value={
                                                this.state.formData
                                                    ? this.state.formData[
                                                          'IndustryCodeTypeId'
                                                      ]
                                                    : null
                                            }
                                            isRequired={false}
                                            formErrors={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                          'IndustryCodeTypeId'
                                                      ]
                                                    : null
                                            }
                                            onFieldChange={this.onFieldChange}
                                        />
                                    )}

                                    {Deltas && Deltas['ReconAccountTypeId'] ? (
                                        <DeltaField
                                            delta={Deltas['ReconAccountTypeId']}
                                        />
                                    ) : (
                                        <DynamicSelect
                                            readOnly={this.state.readOnly}
                                            team="customermaster"
                                            arrayOfData={dropDownDatas.ReconAccountTypeId}
                                            label="Recon Account"
                                            name="ReconAccountTypeId"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes(
                                                    'ReconAccountTypeId'
                                                )
                                            }
                                            value={
                                                this.state.formData
                                                    ? this.state.formData[
                                                          'ReconAccountTypeId'
                                                      ]
                                                    : null
                                            }
                                            isRequired={true}
                                            formErrors={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                          'ReconAccountTypeId'
                                                      ]
                                                    : null
                                            }
                                            onFieldChange={this.onFieldChange}
                                            readOnly={this.state.readOnly}
                                            showInlineRequired={showInlineAstreik}
                                        />
                                    )}
                                    {Deltas && Deltas['SalesOfficeTypeId'] ? (
                                        <DeltaField delta={Deltas['SalesOfficeTypeId']} />
                                    ) : (
                                        <DynamicSelect
                                            readOnly={this.state.readOnly}
                                            team="customermaster"
                                            arrayOfData={dropDownDatas.SalesOfficeTypeId}
                                            label="Sales Office"
                                            name="SalesOfficeTypeId"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes(
                                                    'SalesOfficeTypeId'
                                                )
                                            }
                                            value={
                                                this.state.formData
                                                    ? this.state.formData[
                                                          'SalesOfficeTypeId'
                                                      ]
                                                    : null
                                            }
                                            isRequired={true}
                                            formErrors={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                          'SalesOfficeTypeId'
                                                      ]
                                                    : null
                                            }
                                            onFieldChange={this.onFieldChange}
                                            showInlineRequired={showInlineAstreik}
                                        />
                                    )}

                                    {!showContracts &&
                                        (Deltas && Deltas['CustomerGroupTypeId'] ? (
                                            <DeltaField
                                                delta={Deltas['CustomerGroupTypeId']}
                                            />
                                        ) : (
                                            <DynamicSelect
                                                readOnly={this.state.readOnly}
                                                team="customermaster"
                                                arrayOfData={
                                                    (this.props.staticData &&
                                                        this.props.staticData
                                                            .ApolloCustomerGroupType) ||
                                                    []
                                                }
                                                label="Customer Group"
                                                name="CustomerGroupTypeId"
                                                hide={
                                                    allowedFields &&
                                                    !allowedFields.includes(
                                                        'CustomerGroupTypeId'
                                                    )
                                                }
                                                value={
                                                    this.state.formData
                                                        ? this.state.formData[
                                                              'CustomerGroupTypeId'
                                                          ]
                                                        : null
                                                }
                                                isRequired={true}
                                                formErrors={
                                                    this.state.formErrors
                                                        ? this.state.formErrors[
                                                              'CustomerGroupTypeId'
                                                          ]
                                                        : null
                                                }
                                                onFieldChange={this.onFieldChange}
                                                inputProps={
                                                    inputPropsForDefaultRules[
                                                        'CustomerGroupTypeId'
                                                    ]
                                                }
                                                showInlineRequired={showInlineAstreik}
                                            />
                                        ))}
                                    {Deltas && Deltas['PPCustProcTypeId'] ? (
                                        <DeltaField delta={Deltas['PPCustProcTypeId']} />
                                    ) : (
                                        <DynamicSelect
                                            readOnly={this.state.readOnly}
                                            team="customermaster"
                                            arrayOfData={dropDownDatas.PpcustProcTypeId}
                                            label="PP Cust Proc"
                                            name="PPCustProcTypeId"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes(
                                                    'PpcustProcTypeId'
                                                )
                                            }
                                            value={
                                                this.state.formData
                                                    ? this.state.formData[
                                                          'PPCustProcTypeId'
                                                      ]
                                                    : null
                                            }
                                            isRequired={true}
                                            formErrors={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                          'PPCustProcTypeId'
                                                      ]
                                                    : null
                                            }
                                            onFieldChange={this.onFieldChange}
                                            showInlineRequired={showInlineAstreik}
                                        />
                                    )}
                                </Box>
                                <Box width={1 / 2} mx="auto" alignItems="center">
                                    {Deltas && Deltas['SortKey'] ? (
                                        <DeltaField delta={Deltas['SortKey']} />
                                    ) : (
                                        <FormInput
                                            label="Sort Key"
                                            name="SortKey"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes('SortKey')
                                            }
                                            readOnly
                                            team="customermaster"
                                            maxLength={3}
                                            value={
                                                this.state.formData
                                                    ? this.state.formData['SortKey']
                                                    : null
                                            }
                                            variant="outline"
                                            inline
                                            error={
                                                this.state.formErrors
                                                    ? this.state.formErrors['SortKey']
                                                    : null
                                            }
                                            type="text"
                                            showInlineRequired={showInlineAstreik}
                                        />
                                    )}
                                    {Deltas && Deltas['PaymentMethods'] ? (
                                        <DeltaField delta={Deltas['PaymentMethods']} />
                                    ) : (
                                        <FormInput
                                            label="Payment Methods"
                                            name="PaymentMethods"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes('PaymentMethods')
                                            }
                                            readOnly
                                            team="customermaster"
                                            maxLength={10}
                                            value={
                                                this.state.formData
                                                    ? this.state.formData[
                                                          'PaymentMethods'
                                                      ]
                                                    : null
                                            }
                                            variant="outline"
                                            inline
                                            error={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                          'PaymentMethods'
                                                      ]
                                                    : null
                                            }
                                            type="text"
                                            showInlineRequired={showInlineAstreik}
                                        />
                                    )}
                                    {Deltas && Deltas['AcctgClerk'] ? (
                                        <DeltaField delta={Deltas['AcctgClerk']} />
                                    ) : (
                                        <FormInput
                                            label="Acctg Clerk"
                                            name="AcctgClerk"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes('AcctgClerk')
                                            }
                                            readOnly
                                            team="customermaster"
                                            maxLength={2}
                                            value={
                                                this.state.formData
                                                    ? this.state.formData['AcctgClerk']
                                                    : null
                                            }
                                            onChange={this.onFieldChange}
                                            error={
                                                this.state.formErrors
                                                    ? this.state.formErrors['AcctgClerk']
                                                    : null
                                            }
                                            type="text"
                                            variant="outline"
                                            inline
                                            showInlineRequired={showInlineAstreik}
                                        />
                                    )}
                                    {Deltas && Deltas['AccountStatementTypeId'] ? (
                                        <DeltaField
                                            delta={Deltas['AccountStatementTypeId']}
                                        />
                                    ) : (
                                        <DynamicSelect
                                            readOnly={this.state.readOnly}
                                            team="customermaster"
                                            arrayOfData={
                                                dropDownDatas.AccountStatementTypeId
                                            }
                                            label="Account Statement "
                                            name="AccountStatementTypeId"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes(
                                                    'AccountStatementTypeId'
                                                )
                                            }
                                            value={
                                                this.state.formData
                                                    ? this.state.formData[
                                                          'AccountStatementTypeId'
                                                      ]
                                                    : null
                                            }
                                            isRequired={true}
                                            formErrors={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                          'AccountStatementTypeId'
                                                      ]
                                                    : null
                                            }
                                            onFieldChange={this.onFieldChange}
                                            showInlineRequired={showInlineAstreik}
                                        />
                                    )}
                                    {Deltas && Deltas['TaxClassificationTypeId'] ? (
                                        <DeltaField
                                            delta={Deltas['TaxClassificationTypeId']}
                                        />
                                    ) : (
                                        <DynamicSelect
                                            readOnly={this.state.readOnly}
                                            team="customermaster"
                                            arrayOfData={
                                                dropDownDatas.TaxClassificationTypeId
                                            }
                                            label="Tax Classification "
                                            name="TaxClassificationTypeId"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes(
                                                    'TaxClassificationTypeId'
                                                )
                                            }
                                            value={
                                                this.state.formData
                                                    ? this.state.formData[
                                                          'TaxClassificationTypeId'
                                                      ]
                                                    : null
                                            }
                                            isRequired={true}
                                            formErrors={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                          'TaxClassificationTypeId'
                                                      ]
                                                    : null
                                            }
                                            onFieldChange={this.onFieldChange}
                                            showInlineRequired={showInlineAstreik}
                                        />
                                    )}

                                    {/*if Non-Us pricelist should be set to IN and readonly.
                                    Which is basically showing up the data from source salesorg */}
                                    {Deltas && Deltas['PriceListTypeId'] ? (
                                        <DeltaField delta={Deltas['PriceListTypeId']} />
                                    ) : (
                                        <DynamicSelect
                                            readOnly={this.state.readOnly}
                                            team="customermaster"
                                            arrayOfData={dropDownDatas.PriceListTypeId}
                                            label="Price List"
                                            name="PriceListTypeId"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes('PriceListTypeId')
                                            }
                                            value={
                                                this.state.formData
                                                    ? this.state.formData[
                                                          'PriceListTypeId'
                                                      ]
                                                    : null
                                            }
                                            isRequired={true}
                                            formErrors={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                          'PriceListTypeId'
                                                      ]
                                                    : null
                                            }
                                            onFieldChange={this.onFieldChange}
                                            inputProps={
                                                inputPropsForDefaultRules[
                                                    'PriceListTypeId'
                                                ]
                                            }
                                            showInlineRequired={showInlineAstreik}
                                        />
                                    )}
                                    {Deltas && Deltas['DeliveryPriorityTypeId'] ? (
                                        <DeltaField
                                            delta={Deltas['DeliveryPriorityTypeId']}
                                        />
                                    ) : (
                                        <DynamicSelect
                                            readOnly={this.state.readOnly}
                                            team="customermaster"
                                            arrayOfData={
                                                dropDownDatas.DeliveryPriorityTypeId
                                            }
                                            label="Delivery Priority"
                                            name="DeliveryPriorityTypeId"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes(
                                                    'DeliveryPriorityTypeId'
                                                )
                                            }
                                            value={
                                                this.state.formData
                                                    ? this.state.formData[
                                                          'DeliveryPriorityTypeId'
                                                      ]
                                                    : null
                                            }
                                            isRequired={true}
                                            formErrors={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                          'DeliveryPriorityTypeId'
                                                      ]
                                                    : null
                                            }
                                            onFieldChange={this.onFieldChange}
                                            showInlineRequired={showInlineAstreik}
                                        />
                                    )}
                                    {Deltas && Deltas['ShippingConditionsTypeId'] ? (
                                        <DeltaField
                                            delta={Deltas['ShippingConditionsTypeId']}
                                        />
                                    ) : (
                                        <DynamicSelect
                                            readOnly={this.state.readOnly}
                                            team="customermaster"
                                            arrayOfData={
                                                dropDownDatas.ShippingConditionsTypeId
                                            }
                                            label="Shipping Conditions"
                                            name="ShippingConditionsTypeId"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes(
                                                    'ShippingConditionsTypeId'
                                                )
                                            }
                                            isRequired={true}
                                            value={
                                                this.state.formData
                                                    ? this.state.formData[
                                                          'ShippingConditionsTypeId'
                                                      ]
                                                    : null
                                            }
                                            formErrors={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                          'ShippingConditionsTypeId'
                                                      ]
                                                    : null
                                            }
                                            onFieldChange={this.onFieldChange}
                                            inputProps={
                                                inputPropsForDefaultRules[
                                                    'ShippingConditionsTypeId'
                                                ]
                                            }
                                            showInlineRequired={showInlineAstreik}
                                        />
                                    )}

                                    {!showContracts &&
                                        (Deltas && Deltas['IncoTerms1TypeId'] ? (
                                            <DeltaField
                                                delta={Deltas['IncoTerms1TypeId']}
                                            />
                                        ) : (
                                            <DynamicSelect
                                                readOnly={this.state.readOnly}
                                                team="customermaster"
                                                arrayOfData={
                                                    (this.props.staticData &&
                                                        this.props.staticData
                                                            .ApolloIncoTermsType) ||
                                                    []
                                                }
                                                label="Incoterms 1"
                                                name="IncoTerms1TypeId"
                                                hide={
                                                    allowedFields &&
                                                    !allowedFields.includes(
                                                        'IncoTerms1TypeId'
                                                    )
                                                }
                                                value={
                                                    this.state.formData
                                                        ? this.state.formData[
                                                              'IncoTerms1TypeId'
                                                          ]
                                                        : null
                                                }
                                                isRequired
                                                formErrors={
                                                    this.state.formErrors
                                                        ? this.state.formErrors[
                                                              'IncoTerms1TypeId'
                                                          ]
                                                        : null
                                                }
                                                onFieldChange={this.onFieldChange}
                                            />
                                        ))}
                                    {!showContracts &&
                                        (Deltas && Deltas['IncoTerms2'] ? (
                                            <DeltaField delta={Deltas['IncoTerms2']} />
                                        ) : (
                                            <FormInput
                                                readOnly={this.state.readOnly}
                                                team="customermaster"
                                                label="Incoterms 2"
                                                name="IncoTerms2"
                                                maxLength={28}
                                                hide={
                                                    allowedFields &&
                                                    !allowedFields.includes('IncoTerms2')
                                                }
                                                value={
                                                    this.state.formData
                                                        ? this.state.formData[
                                                              'IncoTerms2'
                                                          ]
                                                        : null
                                                }
                                                onChange={this.onFieldChange}
                                                error={
                                                    this.state.formErrors
                                                        ? this.state.formErrors[
                                                              'IncoTerms2'
                                                          ]
                                                        : null
                                                }
                                                type="text"
                                                required={
                                                    this.state.formData.isRequiredINCOT2
                                                }
                                                showInlineRequired={
                                                    showInlineAstreik &&
                                                    this.state.formData.isRequiredINCOT2
                                                }
                                                {...pageProps}
                                            />
                                        ))}

                                    {Deltas && Deltas['AcctAssignmentGroupTypeId'] ? (
                                        <DeltaField
                                            delta={Deltas['AcctAssignmentGroupTypeId']}
                                        />
                                    ) : (
                                        <DynamicSelect
                                            readOnly={this.state.readOnly}
                                            team="customermaster"
                                            arrayOfData={
                                                dropDownDatas.AcctAssignmentGroupTypeId
                                            }
                                            label="Acct Assgmt Group"
                                            name="AcctAssignmentGroupTypeId"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes(
                                                    'AcctAssignmentGroupTypeId'
                                                )
                                            }
                                            isRequired={true}
                                            value={
                                                this.state.formData
                                                    ? this.state.formData[
                                                          'AcctAssignmentGroupTypeId'
                                                      ]
                                                    : null
                                            }
                                            formErrors={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                          'AcctAssignmentGroupTypeId'
                                                      ]
                                                    : null
                                            }
                                            onFieldChange={this.onFieldChange}
                                            showInlineRequired={showInlineAstreik}
                                        />
                                    )}

                                    {!showContracts &&
                                        (Deltas && Deltas['AccountTypeId'] ? (
                                            <DeltaField
                                                label="Account Type"
                                                delta={Deltas['AccountTypeId']}
                                            />
                                        ) : (
                                            <DynamicSelect
                                                readOnly={this.state.readOnly}
                                                team="customermaster"
                                                arrayOfData={dropDownDatas.AccountTypeId}
                                                label="Account Type"
                                                name="AccountTypeId"
                                                hide={
                                                    allowedFields &&
                                                    !allowedFields.includes(
                                                        'AccountTypeId'
                                                    )
                                                }
                                                isRequired={true}
                                                value={
                                                    this.state.formData
                                                        ? this.state.formData[
                                                              'AccountTypeId'
                                                          ]
                                                        : null
                                                }
                                                formErrors={
                                                    this.state.formErrors
                                                        ? this.state.formErrors[
                                                              'AccountTypeId'
                                                          ]
                                                        : null
                                                }
                                                onFieldChange={this.onFieldChange}
                                                inputProps={
                                                    inputPropsForDefaultRules[
                                                        'AccountTypeId'
                                                    ]
                                                }
                                                showInlineRequired={showInlineAstreik}
                                            />
                                        ))}
                                    {Deltas && Deltas['ShippingCustomerTypeId'] ? (
                                        <DeltaField
                                            delta={Deltas['ShippingCustomerTypeId']}
                                        />
                                    ) : (
                                        <DynamicSelect
                                            readOnly={this.state.readOnly}
                                            team="customermaster"
                                            arrayOfData={
                                                dropDownDatas.ShippingCustomerTypeId
                                            }
                                            label="Shipping Customer Type"
                                            name="ShippingCustomerTypeId"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes(
                                                    'ShippingCustomerTypeId'
                                                )
                                            }
                                            value={
                                                this.state.formData
                                                    ? this.state.formData[
                                                          'ShippingCustomerTypeId'
                                                      ]
                                                    : null
                                            }
                                            isRequired={true}
                                            formErrors={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                          'ShippingCustomerTypeId'
                                                      ]
                                                    : null
                                            }
                                            onFieldChange={this.onFieldChange}
                                            showInlineRequired={showInlineAstreik}
                                        />
                                    )}
                                    {!isRequestPage && action !== 'extend-salesorg' ? (
                                        <CheckBoxItem
                                            team="customermaster"
                                            title="Order Combination"
                                            name="OrderCombination"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes(
                                                    'OrderCombination'
                                                )
                                            }
                                            stateValue={
                                                this.state.formData &&
                                                this.state.formData.OrderCombination &&
                                                this.state.formData.OrderCombination ===
                                                    'T'
                                                    ? true
                                                    : null
                                            }
                                            onValueChange={(val) =>
                                                this.setFunctionalTeamDeltaObject(
                                                    'OrderCombination',
                                                    val ? 'T' : null,
                                                    'customermaster'
                                                )
                                            }
                                        />
                                    ) : (
                                        <CheckBoxItem
                                            team="customermaster"
                                            title="Order Combination"
                                            name="OrderCombination"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes(
                                                    'OrderCombination'
                                                )
                                            }
                                            stateValue={
                                                this.state.formData &&
                                                this.state.formData.OrderCombination &&
                                                this.state.formData.OrderCombination ===
                                                    'T'
                                                    ? true
                                                    : null
                                            }
                                            delta={Deltas['OrderCombination']}
                                        />
                                    )}

                                    {isRequestPage || action === 'extend-salesorg' ? (
                                        <CheckBoxItem
                                            team="customermaster"
                                            title="Payment History Record"
                                            name="PaymentHistoryRecord"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes(
                                                    'PaymentHistoryRecord'
                                                )
                                            }
                                            stateValue={
                                                this.state.formData &&
                                                this.state.formData
                                                    .PaymentHistoryRecord &&
                                                this.state.formData
                                                    .PaymentHistoryRecord === 'T'
                                            }
                                            delta={Deltas['PaymentHistoryRecord']}
                                        />
                                    ) : (
                                        <CheckBoxItem
                                            team="customermaster"
                                            title="Payment History Record"
                                            name="PaymentHistoryRecord"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes(
                                                    'PaymentHistoryRecord'
                                                )
                                            }
                                            stateValue={
                                                this.state.formData &&
                                                this.state.formData
                                                    .PaymentHistoryRecord &&
                                                this.state.formData
                                                    .PaymentHistoryRecord === 'T'
                                            }
                                            onValueChange={(val) =>
                                                this.setFunctionalTeamDeltaObject(
                                                    'PaymentHistoryRecord',
                                                    val ? 'T' : null,
                                                    'customermaster'
                                                )
                                            }
                                        />
                                    )}
                                </Box>
                            </Box>
                            <Box flexDirection="row" justifyContent="center"></Box>
                        </React.Fragment>
                        <React.Fragment key="credit">
                            <Text
                                m="10px 0 24px 5%"
                                ml="2%"
                                fontWeight="light"
                                color="#4195C7"
                                fontSize="24px">
                                CREDIT FIELDS
                            </Text>
                            <Box flexDirection="row" justifyContent="center">
                                <Box width={1 / 2} mx="auto" alignItems="center">
                                    {!showContracts &&
                                        (Deltas && Deltas['PaymentTermsTypeId'] ? (
                                            <DeltaField
                                                delta={Deltas['PaymentTermsTypeId']}
                                            />
                                        ) : (
                                            <DynamicSelect
                                                readOnly={this.state.readOnly}
                                                arrayOfData={
                                                    (this.props.staticData &&
                                                        this.props.staticData
                                                            .ApolloPaymentTermsType) ||
                                                    []
                                                }
                                                label="Payment Terms"
                                                name="PaymentTermsTypeId"
                                                hide={
                                                    allowedFields &&
                                                    !allowedFields.includes(
                                                        'PaymentTermsTypeId'
                                                    )
                                                }
                                                team="credit"
                                                isRequired={true}
                                                value={
                                                    this.state.formData
                                                        ? this.state.formData[
                                                              'PaymentTermsTypeId'
                                                          ]
                                                        : null
                                                }
                                                formErrors={
                                                    this.state.formErrors
                                                        ? this.state.formErrors[
                                                              'PaymentTermsTypeId'
                                                          ]
                                                        : null
                                                }
                                                onFieldChange={this.onFieldChange}
                                                showInlineRequired={showInlineAstreik}
                                            />
                                        ))}
                                    {Deltas && Deltas['RiskCategoryTypeId'] ? (
                                        <DeltaField
                                            delta={Deltas['RiskCategoryTypeId']}
                                        />
                                    ) : (
                                        <DynamicSelect
                                            readOnly={this.state.readOnly}
                                            arrayOfData={dropDownDatas.RiskCategoryTypeId}
                                            label="Risk Category"
                                            team="credit"
                                            name="RiskCategoryTypeId"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes(
                                                    'RiskCategoryTypeId'
                                                )
                                            }
                                            value={
                                                this.state.formData &&
                                                (this.state.formData[
                                                    'RiskCategoryTypeId'
                                                ] ||
                                                    this.state.formData[
                                                        'RiskCategoryTypeId'
                                                    ] ||
                                                    this.state.formData[
                                                        'RiskCategoryTypeId'
                                                    ])
                                            }
                                            formErrors={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                          'RiskCategoryTypeId'
                                                      ]
                                                    : null
                                            }
                                            onFieldChange={this.onFieldChange}
                                        />
                                    )}
                                    {Deltas && Deltas['CreditRepGroupTypeId'] ? (
                                        <DeltaField
                                            delta={Deltas['CreditRepGroupTypeId']}
                                        />
                                    ) : (
                                        <DynamicSelect
                                            readOnly={this.state.readOnly}
                                            arrayOfData={
                                                this.props.staticData &&
                                                this.props.staticData
                                                    .ApolloCreditRepGroupType
                                            }
                                            label="Credit Rep Group"
                                            team="credit"
                                            name="CreditRepGroupTypeId"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes(
                                                    'CreditRepGroupTypeId'
                                                )
                                            }
                                            value={
                                                this.state.formData &&
                                                (this.state.formData[
                                                    'CreditRepGroupTypeId'
                                                ] ||
                                                    this.state.formData[
                                                        'CreditRepGroupTypeId'
                                                    ] ||
                                                    this.state.formData[
                                                        'CreditRepGroupTypeId'
                                                    ])
                                            }
                                            formErrors={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                          'CreditRepGroupTypeId'
                                                      ]
                                                    : null
                                            }
                                            onFieldChange={this.onFieldChange}
                                        />
                                    )}

                                    {Deltas && Deltas['Rating'] ? (
                                        <DeltaField delta={Deltas['Rating']} />
                                    ) : (
                                        <FormInput
                                            {...pageProps}
                                            label="Rating"
                                            name="Rating"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes('Rating')
                                            }
                                            maxLength={5}
                                            team="credit"
                                            error={
                                                this.state.formErrors
                                                    ? this.state.formErrors['Rating']
                                                    : null
                                            }
                                            onChange={this.onFieldChange}
                                            value={this.state.formData['Rating']}
                                            formErrors={
                                                this.state.formErrors
                                                    ? this.state.formErrors['Rating']
                                                    : null
                                            }
                                            type="text"
                                        />
                                    )}
                                </Box>
                                <Box width={1 / 2} mx="auto" alignItems="center">
                                    {Deltas && Deltas['CreditLimit'] ? (
                                        <DeltaField delta={Deltas['CreditLimit']} />
                                    ) : (
                                        <FormInput
                                            {...pageProps}
                                            label="Credit Limit"
                                            team="credit"
                                            name="CreditLimit"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes('CreditLimit')
                                            }
                                            maxLength={15}
                                            value={this.state.formData['CreditLimit']}
                                            error={
                                                this.state.formErrors
                                                    ? this.state.formErrors['CreditLimit']
                                                    : null
                                            }
                                            onChange={this.validateNumericKeypad}
                                            type="text"
                                            placeholder={'Only numeric values'}
                                        />
                                    )}
                                    {Deltas && Deltas['CredInfoNumber'] ? (
                                        <DeltaField delta={Deltas['CredInfoNumber']} />
                                    ) : (
                                        <FormInput
                                            {...pageProps}
                                            label="Cred Info Number"
                                            name="CredInfoNumber"
                                            maxLength={11}
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes('CredInfoNumber')
                                            }
                                            team="credit"
                                            value={this.state.formData['CredInfoNumber']}
                                            error={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                          'CredInfoNumber'
                                                      ]
                                                    : null
                                            }
                                            onChange={this.validateNumericKeypad}
                                            type="text"
                                        />
                                    )}
                                    {Deltas && Deltas['PaymentIndex'] ? (
                                        <DeltaField delta={Deltas['PaymentIndex']} />
                                    ) : (
                                        <FormInput
                                            {...pageProps}
                                            label="Payment Index"
                                            name="PaymentIndex"
                                            maxLength={3}
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes('PaymentIndex')
                                            }
                                            team="credit"
                                            value={this.state.formData['PaymentIndex']}
                                            error={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                          'PaymentIndex'
                                                      ]
                                                    : null
                                            }
                                            onChange={this.validateNumericKeypad}
                                            type="text"
                                        />
                                    )}
                                    {Deltas && Deltas['LastExtReview'] ? (
                                        <DeltaField delta={Deltas['LastExtReview']} />
                                    ) : (
                                        <FormInput
                                            label="Last Ext Review"
                                            name="LastExtReview"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes('LastExtReview')
                                            }
                                            team="credit"
                                            value={this.state.formData['LastExtReview']}
                                            error={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                          'LastExtReview'
                                                      ]
                                                    : null
                                            }
                                            onChange={(value, element) => {
                                                this.onFieldChange(
                                                    new Date(value).toJSON().slice(0, 19),
                                                    element
                                                );
                                            }}
                                            type="date"
                                            readOnly={this.state.readOnly}
                                            {...(action !== 'extend-salesorg'
                                                ? pageProps
                                                : {
                                                      inline: true,
                                                      readOnly: true,
                                                      variant: 'outline',
                                                      onBlur: this.props.onFieldChange,
                                                  })}
                                        />
                                    )}
                                </Box>
                            </Box>
                        </React.Fragment>
                        {showContracts && (
                            <React.Fragment key="contract">
                                <Text
                                    m="10px 0 24px 5%"
                                    ml="2%"
                                    fontWeight="light"
                                    color="#4195C7"
                                    fontSize="24px">
                                    CONTRACT FIELDS
                                </Text>
                                <Box flexDirection="row" justifyContent="center">
                                    <Box width={1 / 2} mx="auto" alignItems="center">
                                        {Deltas && Deltas['PaymentTermsTypeId'] ? (
                                            <DeltaField
                                                delta={Deltas['PaymentTermsTypeId']}
                                            />
                                        ) : (
                                            <DynamicSelect
                                                readOnly={this.state.readOnly}
                                                arrayOfData={
                                                    (this.props.staticData &&
                                                        this.props.staticData
                                                            .ApolloPaymentTermsType) ||
                                                    []
                                                }
                                                label="Payment Terms"
                                                name="PaymentTermsTypeId"
                                                hide={
                                                    allowedFields &&
                                                    !allowedFields.includes(
                                                        'PaymentTermsTypeId'
                                                    )
                                                }
                                                team="contracts"
                                                isRequired={true}
                                                formErrors={
                                                    this.state.formErrors
                                                        ? this.state.formErrors[
                                                              'PaymentTermsTypeId'
                                                          ]
                                                        : null
                                                }
                                                onFieldChange={this.onFieldChange}
                                                value={
                                                    this.state.formData[
                                                        'PaymentTermsTypeId'
                                                    ]
                                                }
                                                inputProps={
                                                    inputPropsForDefaultRules[
                                                        'PaymentTermsTypeId'
                                                    ]
                                                }
                                                showInlineRequired={showInlineAstreik}
                                            />
                                        )}
                                        {Deltas && Deltas['AccountTypeId'] ? (
                                            <DeltaField delta={Deltas['AccountTypeId']} />
                                        ) : (
                                            <DynamicSelect
                                                readOnly={this.state.readOnly}
                                                arrayOfData={dropDownDatas.AccountTypeId}
                                                label="Account Type"
                                                name="AccountTypeId"
                                                hide={
                                                    allowedFields &&
                                                    !allowedFields.includes(
                                                        'AccountTypeId'
                                                    )
                                                }
                                                team="contracts"
                                                isRequired={true}
                                                formErrors={
                                                    this.state.formErrors
                                                        ? this.state.formErrors[
                                                              'AccountTypeId'
                                                          ]
                                                        : null
                                                }
                                                onFieldChange={this.onFieldChange}
                                                value={
                                                    this.state.formData['AccountTypeId']
                                                }
                                                inputProps={
                                                    inputPropsForDefaultRules[
                                                        'AccountTypeId'
                                                    ]
                                                }
                                                showInlineRequired={showInlineAstreik}
                                            />
                                        )}
                                    </Box>
                                    <Box width={1 / 2} mx="auto" alignItems="center">
                                        {Deltas && Deltas['IncoTerms1TypeId'] ? (
                                            <DeltaField
                                                delta={Deltas['IncoTerms1TypeId']}
                                            />
                                        ) : (
                                            <DynamicSelect
                                                readOnly={this.state.readOnly}
                                                arrayOfData={
                                                    (this.props.staticData &&
                                                        this.props.staticData
                                                            .ApolloIncoTermsType) ||
                                                    []
                                                }
                                                label="Incoterms 1"
                                                name="IncoTerms1TypeId"
                                                hide={
                                                    allowedFields &&
                                                    !allowedFields.includes(
                                                        'IncoTerms1TypeId'
                                                    )
                                                }
                                                team="contracts"
                                                isRequired={true}
                                                formErrors={
                                                    this.state.formErrors
                                                        ? this.state.formErrors[
                                                              'IncoTerms1TypeId'
                                                          ]
                                                        : null
                                                }
                                                onFieldChange={this.onFieldChange}
                                                value={
                                                    this.state.formData &&
                                                    this.state.formData[
                                                        'IncoTerms1TypeId'
                                                    ]
                                                }
                                            />
                                        )}
                                        {Deltas && Deltas['IncoTerms2'] ? (
                                            <DeltaField delta={Deltas['IncoTerms2']} />
                                        ) : (
                                            allowedFields &&
                                            allowedFields.includes('IncoTerms2') && (
                                                <FormInput
                                                    readOnly={this.state.readOnly}
                                                    team="customermaster"
                                                    label="Incoterms 2"
                                                    name="IncoTerms2"
                                                    maxLength={28}
                                                    hide={
                                                        allowedFields &&
                                                        !allowedFields.includes(
                                                            'IncoTerms2'
                                                        )
                                                    }
                                                    value={
                                                        this.state.formData
                                                            ? this.state.formData[
                                                                  'IncoTerms2'
                                                              ]
                                                            : null
                                                    }
                                                    onChange={this.onFieldChange}
                                                    error={
                                                        this.state.formErrors
                                                            ? this.state.formErrors[
                                                                  'IncoTerms2'
                                                              ]
                                                            : null
                                                    }
                                                    {...pageProps}
                                                    type="text"
                                                    required={
                                                        this.state.formData
                                                            .isRequiredINCOT2
                                                    }
                                                />
                                            )
                                        )}

                                        {Deltas && Deltas['CustomerGroupTypeId'] ? (
                                            <DeltaField
                                                delta={Deltas['CustomerGroupTypeId']}
                                            />
                                        ) : (
                                            <DynamicSelect
                                                readOnly={this.state.readOnly}
                                                arrayOfData={
                                                    (this.props.staticData &&
                                                        this.props.staticData
                                                            .ApolloCustomerGroupType) ||
                                                    []
                                                }
                                                label="Customer Group"
                                                name="CustomerGroupTypeId"
                                                hide={
                                                    allowedFields &&
                                                    !allowedFields.includes(
                                                        'CustomerGroupTypeId'
                                                    )
                                                }
                                                team="contracts"
                                                isRequired={true}
                                                formErrors={
                                                    this.state.formErrors
                                                        ? this.state.formErrors[
                                                              'CustomerGroupTypeId'
                                                          ]
                                                        : null
                                                }
                                                onFieldChange={this.onFieldChange}
                                                value={
                                                    this.state.formData[
                                                        'CustomerGroupTypeId'
                                                    ]
                                                }
                                                inputProps={
                                                    inputPropsForDefaultRules[
                                                        'CustomerGroupTypeId'
                                                    ]
                                                }
                                                showInlineRequired={showInlineAstreik}
                                            />
                                        )}
                                    </Box>
                                </Box>
                            </React.Fragment>
                        )}

                        <React.Fragment key="Pricing">
                            <Text
                                m="10px 0 24px 5%"
                                ml="2%"
                                fontWeight="light"
                                color="#4195C7"
                                fontSize="24px">
                                PRICING FIELDS
                            </Text>
                            <Box flexDirection="row" justifyContent="center">
                                <Box width={1 / 2} mx="auto" alignItems="center">
                                    {Deltas && Deltas['SpecialPricingTypeId'] ? (
                                        <DeltaField
                                            delta={Deltas['SpecialPricingTypeId']}
                                        />
                                    ) : (
                                        <DynamicSelect
                                            readOnly={this.state.readOnly}
                                            arrayOfData={
                                                dropDownDatas.SpecialPricingTypeId
                                            }
                                            label="Special Pricing"
                                            name="SpecialPricingTypeId"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes(
                                                    'SpecialPricingTypeId'
                                                )
                                            }
                                            team="pricing"
                                            formErrors={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                          'SpecialPricingTypeId'
                                                      ]
                                                    : null
                                            }
                                            value={
                                                this.state.formData[
                                                    'SpecialPricingTypeId'
                                                ]
                                            }
                                            onFieldChange={this.onFieldChange}
                                        />
                                    )}
                                    {Deltas && Deltas['DAPAPricingTypeId'] ? (
                                        <DeltaField delta={Deltas['DAPAPricingTypeId']} />
                                    ) : (
                                        <DynamicSelect
                                            readOnly={this.state.readOnly}
                                            arrayOfData={dropDownDatas.DAPA2PricingTypeId}
                                            label="DAPA Pricing"
                                            name="DAPAPricingTypeId"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes(
                                                    'DAPAPricingTypeId'
                                                )
                                            }
                                            team="pricing"
                                            formErrors={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                          'DAPAPricingTypeId'
                                                      ]
                                                    : null
                                            }
                                            value={
                                                this.state.formData['DAPAPricingTypeId']
                                            }
                                            onFieldChange={this.onFieldChange}
                                        />
                                    )}
                                    {Deltas && Deltas['DAPA2PricingTypeId'] ? (
                                        <DeltaField
                                            delta={Deltas['DAPA2PricingTypeId']}
                                        />
                                    ) : (
                                        <DynamicSelect
                                            readOnly={this.state.readOnly}
                                            arrayOfData={dropDownDatas.DAPA2PricingTypeId}
                                            label="DAPA2 Pricing"
                                            name="DAPA2PricingTypeId"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes(
                                                    'DAPA2PricingTypeId'
                                                )
                                            }
                                            team="pricing"
                                            formErrors={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                          'DAPA2PricingTypeId'
                                                      ]
                                                    : null
                                            }
                                            value={
                                                this.state.formData['DAPA2PricingTypeId']
                                            }
                                            onFieldChange={this.onFieldChange}
                                        />
                                    )}
                                </Box>
                                <Box width={1 / 2} mx="auto" alignItems="center">
                                    {Deltas && Deltas['DistLevelTypeId'] ? (
                                        <DeltaField delta={Deltas['DistLevelTypeId']} />
                                    ) : (
                                        <DynamicSelect
                                            readOnly={this.state.readOnly}
                                            arrayOfData={dropDownDatas.DistLevelTypeId}
                                            label="Dist Level Pricing"
                                            name="DistLevelTypeId"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes('DistLevelTypeId')
                                            }
                                            team="pricing"
                                            formErrors={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                          'DistLevelTypeId'
                                                      ]
                                                    : null
                                            }
                                            value={
                                                this.state.formData['DistLevelTypeId'] ||
                                                this.state.formData['DistLevelTypeId']
                                            }
                                            onFieldChange={this.onFieldChange}
                                        />
                                    )}
                                    {Deltas && Deltas['FSSPricingTypeId'] ? (
                                        <DeltaField delta={Deltas['FSSPricingTypeId']} />
                                    ) : (
                                        <DynamicSelect
                                            readOnly={this.state.readOnly}
                                            arrayOfData={dropDownDatas.DistLevelTypeId}
                                            label="FSS Pricing"
                                            name="FSSPricingTypeId"
                                            hide={
                                                allowedFields &&
                                                !allowedFields.includes(
                                                    'FSSPricingTypeId'
                                                )
                                            }
                                            team="pricing"
                                            formErrors={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                          'FSSPricingTypeId'
                                                      ]
                                                    : null
                                            }
                                            value={
                                                this.state.formData['FSSPricingTypeId'] ||
                                                this.state.formData['FSSPricingTypeId']
                                            }
                                            onFieldChange={this.onFieldChange}
                                        />
                                    )}
                                </Box>
                            </Box>
                        </React.Fragment>
                        {this.state.isRequestPage && workflowtype === 'Block/UnBlock' && (
                            <React.Fragment key="BlockUnblock">
                                <Text
                                    m="10px 0 24px 5%"
                                    ml="2%"
                                    fontWeight="light"
                                    color="#4195C7"
                                    fontSize="24px">
                                    Block - UnBlock Status
                                </Text>
                                <Box flexDirection="row" justifyContent="center">
                                    <Box width={1 / 2} ml="25%">
                                        <Text
                                            m="10px 0 24px 5%"
                                            ml="2%"
                                            fontWeight="light"
                                            color="#4195C7"
                                            fontSize="20px">
                                            Order Block
                                        </Text>
                                        <DynamicSelect
                                            readOnly={this.state.readOnly}
                                            arrayOfData={
                                                this.props.staticData &&
                                                this.props.staticData.ApolloBlockOrderType
                                            }
                                            spliceIdZero
                                            label="All Sales Area"
                                            name="OrderBlockTypeId"
                                            team="customermaster"
                                            value={
                                                this.state.formData[
                                                    'retrievedBlockUnBlockData'
                                                ] &&
                                                this.state.formData[
                                                    'retrievedBlockUnBlockData'
                                                ].OrderBlock.AllSalesAreaId
                                            }
                                        />
                                        <DynamicSelect
                                            readOnly={this.state.readOnly}
                                            arrayOfData={
                                                this.props.staticData &&
                                                this.props.staticData.ApolloBlockOrderType
                                            }
                                            spliceIdZero
                                            label="Selected Sales Area"
                                            name="OrderBlockTypeId"
                                            team="customermaster"
                                            value={
                                                this.state.formData[
                                                    'retrievedBlockUnBlockData'
                                                ] &&
                                                this.state.formData[
                                                    'retrievedBlockUnBlockData'
                                                ].OrderBlock.SelectedSalesAreaId
                                            }
                                        />
                                        <Text
                                            m="10px 0 24px 5%"
                                            ml="2%"
                                            fontWeight="light"
                                            color="#4195C7"
                                            fontSize="20px">
                                            Delivery Block
                                        </Text>
                                        <DynamicSelect
                                            readOnly={this.state.readOnly}
                                            arrayOfData={
                                                this.props.staticData &&
                                                this.props.staticData
                                                    .ApolloBlockDeliveryType
                                            }
                                            spliceIdZero
                                            label="All Sales Area"
                                            name="DeliveryBlockTypeId"
                                            team="customermaster"
                                            value={
                                                this.state.formData[
                                                    'retrievedBlockUnBlockData'
                                                ] &&
                                                this.state.formData[
                                                    'retrievedBlockUnBlockData'
                                                ].DeliveryBlock.AllSalesAreaId
                                            }
                                        />
                                        <DynamicSelect
                                            readOnly={this.state.readOnly}
                                            arrayOfData={
                                                this.props.staticData &&
                                                this.props.staticData
                                                    .ApolloBlockDeliveryType
                                            }
                                            spliceIdZero
                                            label="Selected Sales Area"
                                            name="DeliveryBlockTypeId"
                                            team="customermaster"
                                            value={
                                                this.state.formData[
                                                    'retrievedBlockUnBlockData'
                                                ] &&
                                                this.state.formData[
                                                    'retrievedBlockUnBlockData'
                                                ].DeliveryBlock.SelectedSalesAreaId
                                            }
                                        />
                                        <Text
                                            m="10px 0 24px 5%"
                                            ml="2%"
                                            fontWeight="light"
                                            color="#4195C7"
                                            fontSize="20px">
                                            Billing Block
                                        </Text>
                                        <DynamicSelect
                                            readOnly={this.state.readOnly}
                                            arrayOfData={
                                                this.props.staticData &&
                                                this.props.staticData
                                                    .ApolloBlockBillingType
                                            }
                                            spliceIdZero
                                            label="All Sales Area"
                                            name="BillingBlockTypeId"
                                            team="customermaster"
                                            value={
                                                this.state.formData[
                                                    'retrievedBlockUnBlockData'
                                                ] &&
                                                this.state.formData[
                                                    'retrievedBlockUnBlockData'
                                                ].BillingBlock.AllSalesAreaId
                                            }
                                        />
                                        <DynamicSelect
                                            readOnly={this.state.readOnly}
                                            arrayOfData={
                                                this.props.staticData &&
                                                this.props.staticData
                                                    .ApolloBlockBillingType
                                            }
                                            spliceIdZero
                                            label="Selected Sales Area"
                                            name="BillingBlockTypeId"
                                            team="customermaster"
                                            value={
                                                this.state.formData[
                                                    'retrievedBlockUnBlockData'
                                                ] &&
                                                this.state.formData[
                                                    'retrievedBlockUnBlockData'
                                                ].BillingBlock.SelectedSalesAreaId
                                            }
                                        />
                                    </Box>
                                    <Box width={1 / 2} mx="auto" ml="12%">
                                        <Text
                                            m="10px 0 24px 5%"
                                            ml="2%"
                                            fontWeight="light"
                                            color="#4195C7"
                                            fontSize="20px">
                                            Posting Block
                                        </Text>
                                        <CheckBoxItem
                                            team="customermaster"
                                            title="All Company Codes"
                                            name="AllCompanyCodes"
                                            stateValue={
                                                this.state.formData &&
                                                this.state.formData
                                                    .retrievedBlockUnBlockData &&
                                                this.state.formData
                                                    .retrievedBlockUnBlockData
                                                    .PostingBlock.AllCompanyCodes === true
                                            }
                                        />
                                        <CheckBoxItem
                                            team="customermaster"
                                            title="Selected Company Code"
                                            name="SelectedCompanyCode"
                                            stateValue={
                                                this.state.formData &&
                                                this.state.formData
                                                    .retrievedBlockUnBlockData &&
                                                this.state.formData
                                                    .retrievedBlockUnBlockData
                                                    .PostingBlock.SelectedCompanyCode ===
                                                    true
                                            }
                                        />
                                    </Box>
                                </Box>
                            </React.Fragment>
                        )}

                        {(extendSalesOrgEnabled || action === 'update') && (
                            <Box mt={2} flexDirection="row" justifyContent="center">
                                <Box width={0.79} mx="auto" alignItems="center">
                                    <FormInput
                                        maxWidth="98%"
                                        label="Purpose of Request"
                                        style={{ height: '100px' }}
                                        name="Purpose"
                                        value={this.state.formData.Purpose || ''}
                                        maxLength={1000}
                                        multiline
                                        numberOfLines={4}
                                        onChange={this.onFieldChange}
                                    />
                                </Box>
                            </Box>
                        )}

                        {files && <FilesList files={files} readOnly />}
                        <FilesList
                            formErrors={this.state.fileErrors}
                            files={selectedFilesIds.map((id) => selectedFiles[id])}
                            onChange={(value, id) => {
                                this.setState({
                                    selectedFiles: {
                                        ...selectedFiles,
                                        [id]: {
                                            ...selectedFiles[id],
                                            DocumentType: parseInt(value),
                                        },
                                    },
                                });
                            }}
                            onRemove={(value, id) => this.removeFile(id)}
                        />
                        {isRequestPage && (
                            <Box flexDirection="row" justifyContent="center">
                                <Box ml="5%" width={1 / 2}>
                                    {tasknotes && tasknotes.length > 0 && (
                                        <TaskNotes taskNotes={tasknotes} />
                                    )}
                                </Box>
                                <Box width={1 / 2} mx="auto" alignItems="center" />
                            </Box>
                        )}
                        <Box flexDirection="row" justifyContent="center" mt="2%">
                            <Box width={1 / 2} mx="auto" alignItems="flex-end" />
                            <Box width={1 / 2} mx="auto" alignItems="flex-end">
                                <Flex
                                    alignCenter
                                    style={{
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        paddingLeft: 10,
                                        paddingRight: 15,
                                        marginTop: 20,
                                        marginBottom: 10,
                                        marginHorizontal: 25,
                                    }}>
                                    {!isRequestPage && (
                                        <>
                                            <label
                                                htmlFor="file-upload"
                                                className="custom-file-upload">
                                                <MaterialIcons
                                                    name="attach-file"
                                                    size={18}
                                                    color="#fff"
                                                />
                                            </label>
                                            <input
                                                id="file-upload"
                                                key={this.state.fileinputkey}
                                                type="file"
                                                onChange={this.selectFile}
                                            />
                                            <Button
                                                onPress={() =>
                                                    this.props.history.goBack()
                                                }
                                                title="Cancel"
                                            />
                                            <Button
                                                onPress={(event) => this.onSubmit()}
                                                title="Submit"
                                                type="submit"
                                            />
                                        </>
                                    )}
                                    {isRequestPage && withdrawToggle && (
                                        //dont show withdraw for completed workflow
                                        <Button
                                            onPress={() => {
                                                window.scrollTo(0, 0);
                                                this.props.withDrawRequest(
                                                    {
                                                        WorkflowId: this.props.match
                                                            .params.id,
                                                        WorkflowOperationType: 4,
                                                    },
                                                    this.props.history
                                                );
                                            }}
                                            title="Withdraw"
                                        />
                                    )}
                                </Flex>
                            </Box>
                        </Box>
                    </Box>
                </View>
            </ScrollView>
        );
    }
}

class Default extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const props = this.props;

        return (
            <DimensionAware
                render={(dimensions) => (
                    <Page
                        {...{
                            ...props,
                            width: getWindowWidth(dimensions),
                            height: getWindowHeight(dimensions),
                            marginBottom: 25,
                        }}
                    />
                )}
            />
        );
    }
}

const mapStateToProps = ({
    customer,
    toasts,
    workflows,
    myRequests,
    updateFlow,
    staticData,
}) => {
    const { fetching: fetchingReq, alert: requestAlert } = myRequests;
    const { bapiFullSet, alert: customerAlert, fetching: fetchingCustomer } = customer;
    const { alert: workFlowAlert, statusBarData } = workflows;
    const alert = workFlowAlert.display
        ? workFlowAlert
        : customerAlert.display
        ? customerAlert
        : requestAlert;
    const { fetching } = updateFlow;

    const { data, fetching: staticFetching } = staticData;
    return {
        bapiFullSet,
        fetching: fetchingCustomer || fetching || fetchingReq || staticFetching,
        alert,
        toasts,
        statusBarData,
        staticData: data || [],
    };
};

export default connect(mapStateToProps, {
    updateDeltas,
    getCustomerFromSAP,
    removeMessage,
    withDrawRequest,
    getStatusBarData,
    getStaticRequest,
})(Default);

const styles = StyleSheet.create({
    progressIndicator: {
        flex: 1,
        paddingBottom: 5,
        flexDirection: 'row-reverse',
        alignItems: 'flex-end',
    },
    statusText: {
        fontSize: 15,
        color: '#1D4289',
        fontFamily: 'Poppins',
        textAlign: 'center',
        marginTop: 20,
    },
});
