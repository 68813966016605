import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import CreatePartnersEnhancedTable from './CreatePartnersEnhancedTable';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { getPartnerDetails } from '../appRedux/actions/Partners';
import { useSelector, useDispatch } from 'react-redux';
import { State } from 'react-native-gesture-handler';
import { ActivityIndicator } from 'react-native-web';
const CreatePartnersGrid = ({
    dataFull: parentGridData = [],
    data: results = [],
    columns: columnConfig = [],
    openModal,
    onChange: changeHandler = (data) => {},
    onPasteUpdate,
    setPayloadp,
    filterData,
    ApolloPartnersRoleType,
    setTableData,
    isSoldToPresent,
    originalTableData,
    addData,
    fetching,
}) => {
    const dispatch = useDispatch();
    const columns = React.useMemo(() => columnConfig, [columnConfig]);
    const [data, setData] = React.useState([...results] || []);
    const [selectedRows, setSelectedRows] = React.useState([]);
    const [selectedRowIds, setSelectedRowIds] = React.useState({});
    const [skipPageReset, setSkipPageReset] = React.useState(false);
    const [isdel, setdel] = React.useState(false);
    const [partnerEdits, setPartnerEdits] = React.useState([]);
    const [warning, setWarningDisplay] = React.useState(false);

    const [hasIsDefaultSetInGrid, sethasIsDefaultSetInGrid] = React.useState(
        results.some((sourcedataRow) => sourcedataRow.IsDefaultPartner === true)
    );

    const partnerDetails = useSelector((state) => state.myPartners.partnerDetails);
    const partnerScreenSearchfields = useSelector(
        (state) => state.myPartners.apolloPartnersSearchFields
    );

    const apolloPartnersData = useSelector(
        (state) => state.myPartners.apolloPartnersData
    );

    React.useEffect(() => {
        let selected = [];
        for (let i = 0; i < selectedRows.length; i++) {
            selected.push(selectedRows[i]['original']);
        }
        let difference = data.filter((x) => !selected.includes(x));
        if (difference.length > 0) {
            setData((arr) => [...difference]);
        }
        setdel(false);
    }, [isdel]);

    React.useEffect(() => {
        // console.log('partnerEditDict *** ---', partnerEdits)
        setPayloadp(partnerEdits);
    }, [partnerEdits]);

    React.useEffect(() => {
        //if any one IsDefaultPartner is updated to true on the grid, disable other selection
        sethasIsDefaultSetInGrid(data.some((row) => row.IsDefaultPartner === true));
    }, [data]);

    React.useEffect(() => {
        // console.log('partnerEditDict', partnerEdits);
        setData(apolloPartnersData);
    }, [apolloPartnersData]);

    React.useEffect(() => {
        // console.log('partnerEditDict', partnerEdits);
        if (addData) addNewPartnerData(addData);
    }, [addData]);

    // We need to keep the table from resetting the pageIndex when we
    // Update data. So we can keep track of that flag with a ref.

    // When our cell renderer calls updateMyData, we'll use
    // the rowIndex, columnId and new value to update the
    // original data

    const updateMyData = (rowIndex, cellrow, columnId, value) => {
        // We also turn on the flag to not reset the page
        let twoRolesRemoved = false;
        setSkipPageReset(true);
        var partnerOperation = '';
        const newData = data.map((row, index) => {
            if (index === rowIndex) {
                let editedData = {
                    ...data[rowIndex],
                    [columnId]: value,
                };
                let sData = originalTableData[rowIndex];
                if (
                    originalTableData[rowIndex] &&
                    sData['IsDefaultPartner'] == true &&
                    columnId === 'UnPartner' &&
                    value === false
                ) {
                    editedData = {
                        ...sData,
                    };
                }

                partnerOperation = 'Edit';

                if (columnId === 'UnPartner' && value === true) {
                    editedData['PartnersOperation'] = 'Remove';
                    editedData['UnPartner'] = true;
                    partnerOperation = 'Remove';
                } else if (results[rowIndex] === undefined || cellrow.original.newRow) {
                    partnerOperation = 'Add';
                    editedData['PartnersOperation'] = 'Add';
                }
                //build create request datastructure
                // console.log(row);
                let partnerEditDict = {
                    rowIndex,
                    PartnersOperation: partnerOperation,
                    PartnerFunctionTypeId: row.PartnerFunctionTypeId,
                    CustomerNumberOrWorkflowNumber:
                        row['CustomerNumberOrWorkflowNumber'] || row['PartnerNumber'],
                };
                if (partnerOperation == 'Add') {
                    if (columnId == 'PartnerNumber') {
                        partnerEditDict['CustomerNumberOrWorkflowNumber'] = value;
                    } else {
                        partnerEditDict['CustomerNumberOrWorkflowNumber'] =
                            cellrow.original.CustomerNumberOrWorkflowNumber;
                    }
                }
                // console.log(partnerEditDict, cellrow);

                if (columnId == 'PartnerNumber') {
                    partnerEditDict['CustomerNumberOrWorkflowNumber'] = value;
                    editedData['PartnerNumber'] = value;
                }

                if (partnerOperation == 'Remove' && columnId == 'UnPartner') {
                    partnerEditDict['UnPartner'] = value;
                }

                // console.log(partnerEditDict, editedData);
                if (
                    (results[rowIndex] === undefined ||
                        partnerOperation === 'Add' ||
                        cellrow.original.newRow) &&
                    columnId == 'PartnerNumber'
                ) {
                    //on adding new row , trigger api to get partner info detail based partner no
                    // console.log('--99999--',results,rowIndex,results[rowIndex] )
                    // console.log('calls ----');
                    dispatch(
                        getPartnerDetails(
                            rowIndex,
                            columnId,
                            value,
                            cellrow,
                            data,
                            partnerScreenSearchfields,
                            partnerOperation
                        )
                    );
                }
                // include the column name that is edited on partnereditdict
                if (partnerOperation === 'Edit') {
                    editedData['PartnersOperation'] = 'Edit';
                    partnerEditDict[columnId] = value;
                }

                if (
                    partnerOperation === 'Add' &&
                    (columnId === 'IsDefaultPartner' ||
                        columnId == 'PartnerFunctionTypeId' ||
                        columnId == 'UnPartner')
                ) {
                    partnerEditDict[columnId] = value;
                }

                let isDefaultEditList = [];

                let sourceIsDefaultIndex = '';

                // if(columnId==='IsDefaultPartner'){

                //     const sourceUpdatedIsDefaultEdits=results.map((sourcedataRow,sourcedataIndex)=>{

                //         //revert isdefault to false if there exists already in source data
                //         if(value===true && sourcedataRow.IsDefaultPartner===true){
                //             let updatedPartnerEdit={
                //                 rowIndex:sourcedataIndex,
                //                 PartnersOperation: 'Edit',
                //                 CustomerNumberOrWorkflowNumber: sourcedataRow.WorkflowOrCustomerNumber,
                //                 PartnerFunctionTypeId: sourcedataRow.PartnerFunctionTypeId,
                //                 IsDefaultPartner:false
                //             };
                //             isDefaultEditList.push(updatedPartnerEdit)

                //         }

                //         //on an added partnerEdit , if they wish to revert back the isDefault , restore the source data.

                //         console.log('updatedPartnerEdit',isDefaultEditList)
                //         return isDefaultEditList
                //     })
                // }

                //undo edited data if it matches to source data
                if (
                    sData &&
                    !editedData['newRow'] &&
                    editedData['PartnersOperation'] === 'Edit' &&
                    sData[columnId] == editedData[columnId]
                ) {
                    editedData['PartnersOperation'] = '';
                }

                //remove newly added row, if the data already exist in the grid.
                let isDuplicate = false,
                    duplicateRowIndex = null;
                if (cellrow.original.newRow) {
                    let newAddedRow = cellrow.original;
                    if (columnId === 'PartnerFunctionTypeId') {
                        //check if partnerno has input formadata data
                        if (newAddedRow['CustomerNumberOrWorkflowNumber']) {
                            isDuplicate = results.some(
                                (sourcerow) =>
                                    sourcerow.PartnerNumber ===
                                        partnerEditDict[
                                            'CustomerNumberOrWorkflowNumber'
                                        ].trim() &&
                                    sourcerow.PartnerFunctionTypeId ===
                                        partnerEditDict[
                                            'PartnerFunctionTypeId'
                                        ].toString()
                            );
                            duplicateRowIndex = index;
                            editedData = isDuplicate ? {} : editedData;
                            // console.log('^^^^^^^^^^^^^', isDuplicate);
                        }
                    }
                }

                setPartnerEdits((partnerarray) => {
                    let copy = [...partnerarray, partnerEditDict];
                    partnerarray.map((partnerrow, index) => {
                        if (partnerrow.rowIndex === partnerEditDict.rowIndex) {
                            // console.log('partnerrow', partnerrow);
                            //if there is any edit operation done on a new row added, the partneroperation shd retain as 'ADD'
                            if (partnerrow.PartnersOperation == 'Add')
                                partnerEditDict['PartnersOperation'] = 'Add';

                            copy.splice(index, 1);
                        }

                        // if(columnId==='IsDefaultPartner' && hasIsDefaultSetInGrid){

                        //     console.log('comes here--')
                        //     //remove all partner edits when we have existing isDefault set to True on Source data
                        //     //inorder to add the updated new fresh edits

                        //     if(partnerrow.PartnersOperation == 'Edit')
                        //         partnerarray.splice(index, 1);
                        // }

                        //if there are no isDefault to true on source data, and when user undo's the edit, it Should get removed from partner edits
                        if (columnId === 'IsDefaultPartner') {
                            if (
                                partnerrow.PartnersOperation == 'Edit' &&
                                partnerrow.rowIndex === partnerEditDict.rowIndex
                            ) {
                                copy = copy.filter(
                                    (editedDict) =>
                                        editedDict.rowIndex != partnerrow.rowIndex
                                );
                                console.log('aftersplice edit', partnerarray);
                            }
                        }

                        if (
                            sData &&
                            sData['IsDefaultPartner'] != true &&
                            columnId === 'UnPartner'
                        ) {
                            if (
                                partnerrow.PartnersOperation == 'Remove' &&
                                partnerrow.rowIndex === partnerEditDict.rowIndex
                            ) {
                                copy = copy.filter(
                                    (editedDict) =>
                                        editedDict.rowIndex != partnerrow.rowIndex
                                );
                                console.log('aftersplice remove', partnerarray);
                            }
                        }

                        if (
                            sData &&
                            sData['IsDefaultPartner'] == true &&
                            columnId === 'UnPartner' &&
                            value === false
                        ) {
                            if (partnerrow.rowIndex === partnerEditDict.rowIndex) {
                                copy = copy.filter(
                                    (editedDict) =>
                                        editedDict.rowIndex != partnerrow.rowIndex
                                );
                                console.log('aftersplice remove undo', partnerarray);
                            }
                        }

                        // //when ever you undo remove partner , remove the obj from the array Copy.
                        // if(columnId === 'UnPartner' && value==false ){
                        //     copy = copy.filter(
                        //         (editedDict) =>
                        //             editedDict.rowIndex != rowIndex
                        //     );
                        // }

                        //remove if new added row is redundant
                        if (
                            partnerrow.rowIndex === partnerEditDict.rowIndex &&
                            isDuplicate
                        ) {
                            copy.splice(index, 1);
                            setWarningDisplay(true);
                        }
                    });

                    console.log('..copy..', copy);

                    return [...copy];

                    // add only delta changes
                    // if(results[partnerEditDict.rowIndex][columnId]===value)
                    //     return[...partnerarray]
                    // else  return [...partnerarray, partnerEditDict];
                });

                return editedData;
            }

            return row;
        });

        var mnewData = newData.filter((value) => Object.keys(value).length !== 0);
        var setRedundantWarning = newData.some((value) => Object.keys(value).length == 0);
        // console.log('setRedundantWarning', setRedundantWarning);
        // remove if 'newly added partners' are redundant
        let finalPartnerEdits = mnewData.filter(
            (ele, ind) =>
                ind ===
                mnewData.findIndex(
                    (elem) =>
                        elem.PartnersOperation === ele.PartnersOperation &&
                        elem.PartnerNumber === ele.PartnerNumber &&
                        elem.PartnerFunctionTypeId === ele.PartnerFunctionTypeId
                )
        );
        setRedundantWarning = mnewData.length != finalPartnerEdits.length;
        console.log('mnewData', finalPartnerEdits, mnewData);
        // console.log('source data', results);
        changeHandler(finalPartnerEdits);
        setData((ary) => [...finalPartnerEdits]);
        setTableData([...finalPartnerEdits]);
        // setPartnerEdits([...finalPartnerEdits]);
        if (setRedundantWarning) setWarningDisplay(setRedundantWarning);
    };

    const selectRowHandler = (rows, rowIds = {}) => {
        setSelectedRows(rows);
        setSelectedRowIds(rowIds);
    };

    const addNewPartnerData = (partner) => {
        setData((arr) => [...arr, partner]);
        setdel(false);
        setSelectedRows([]);
        setSelectedRowIds([]);
        // console.log('ddd',data)
    };

    const removePartner = (row) => {
        let index = partnerEdits.findIndex(
            (x) =>
                x.CustomerNumberOrWorkflowNumber ==
                    row.original.CustomerNumberOrWorkflowNumber &&
                (row.original.newRow ||
                    x.PartnerFunctionTypeId == row.original.PartnerFunctionTypeId)
        );
        if (index >= 0) partnerEdits.splice(index, 1);
        setPartnerEdits([...partnerEdits]);
        data.splice(row.id, 1);
        setData([...data]);
        setTableData([...data]);
        // setdel(true);
    };

    return (
        <div>
            <CssBaseline />
            <CreatePartnersEnhancedTable
                columns={columns}
                data={data}
                setData={setData}
                hasIsDefaultSetInGrid={hasIsDefaultSetInGrid}
                updateMyData={updateMyData}
                skipPageReset={skipPageReset}
                selectRowHandler={selectRowHandler}
                addNewPartnerData={addNewPartnerData}
                removePartner={removePartner}
                formData={filterData}
                ApolloPartnersRoleType={ApolloPartnersRoleType}
                isSoldToPresent={isSoldToPresent}
                showWarning={warning}
            />
        </div>
    );
};

export default CreatePartnersGrid;
