/**
 * @prettier
 */

import React from 'react';
import {
    ScrollView,
    Text,
    View,
    TouchableOpacity,
    ActivityIndicator,
    StyleSheet,
    Image,
} from 'react-native';
import {
    DimensionAware,
    getWindowHeight,
    getWindowWidth,
} from 'react-native-dimension-aware';
import { connect } from 'react-redux';
import { FormInput, FormSelect } from '../../components/form';
import { Box, Flex, Button } from '../../components/common';
import { Table, TableWrapper, Row, Rows, Cell } from '../../components/table';
import { Link } from '../../navigation/router';
import { Colors, StyledText } from '../../theme/Index';
import { AntDesign } from '@expo/vector-icons';
import OverflowRight from '../../components/OverflowRight';
import PartnersFilterFields from '../../components/PartnersFilterFields';
import CreatePartnersGrid from '../../components/CreatePartnersGrid';
import {
    SystemType,
    RoleType,
    GeneralRoleType,
    CreateApolloPartnersColumns,
    ApolloPartners,
} from '../../constants/WorkflowEnums';
import { OverflowRightContext } from '../SearchResult/Apollo/MdmDetailScreen';
import queryString from 'query-string';
import {
    getStaticRequest,
    getApolloPartners,
    createApolloPartnerRequest,
    clearPartnerDetails,
    clearPartnerEditsAfterSave,
} from '../../appRedux/actions';
import { mapKeys } from 'lodash';
import { fetchCreateCustomerDropDownData } from '../../redux/DropDownDatas';
import idx from 'idx';
import Modal from 'modal-enhanced-react-native-web';
import { apolloPartnerRules } from '../../constants/FieldRules';
import { parseAndSanitise, yupAllFieldsValidation } from '../../constants/utils';
import { GenerateWorkflowId } from '@env';
import { ajaxGetRequest } from '../../appRedux/sagas/config';
import FilesList from '../../components/FilesList';
import { MaterialIcons } from '@expo/vector-icons';
import FlashMessage, { FlashMessages } from '../../components/FlashMessage';
import { removeMessage, clearMessage } from '../../appRedux/actions/Toast';
import { ThemeProvider } from 'emotion-theming';
import { FAILED_BGCOLOR } from '../../constants/ActionTypes';
class Page extends React.Component {
    toggle = (value = null) => {
        const isBoolean = value && typeof value === 'boolean';
        this.setState((state) => ({
            isToggled: isBoolean ? value : !state.isToggled,
        }));
    };
    constructor(props) {
        super(props);
        this.toggleOverflow = () => {
            this.setState((state) => ({
                isToggled: !state.isToggled,
            }));
        };
        this.state = {
            tableHead: [
                'Customer or Workflow Number',
                'Partner Number',
                'Partner Role',
                'Partner Mdm Number',
                'Partner Customer Name',
                'Partner Customer Address',
            ],
            isToggled: false,
            requestsTotal: 200,
            current_page: 1,
            toggleOverflow: this.toggle,
            formData: {
                OperationName: 'CreateApolloPartnersWorkflow',
                UserId: localStorage.getItem('userId'),
                DistributionChannelTypeId: '1',
                DivisionTypeId: '1',
            },
            loadedPartnersRequestData: false,
            partnersData: [],
            apolloPartners: [...this.props.myPartners],
            modalVisible: false,
            searchedFilterData: {},
            selectedFiles: {},
            selectedFilesIds: [],
            files: [],
            fileinputkey: Date.now(),
            payloadp: [],
            originalTableData: [],
            newAddedData: [],
            isSoldToPresent: false,
            displayMessage: false,
            errorMessage: '',
            WorkflowId: '',
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location && this.state.isToggled === true) {
            this.toggle(false);
        }
    }

    componentDidMount() {
        this.setState({ partnersData: [] });
        this.props.getStaticRequest([5, 6, 50, 29]);
        fetchCreateCustomerDropDownData().then((res) => {
            const data = res;
            this.setState({ dropDownDatas: data });
        });
        // this.generateWorkflowId();

        var postdata = {
            DistributionChannelTypeId: '1',
            DivisionTypeId: '1',
            SalesOrgTypeId: '2',
            RoleTypeId: '1',
            UserId: 'steve.goldman',
            WorkflowOrCustomerNumber: 'wf000000549138193',
        };
        // this.props.getApolloPartners(postdata);
    }

    componentWillReceiveProps(newProps) {
        if (newProps.totalPartners != this.props.totalPartners) {
            this.setState({ partnersData: newProps.totalPartners });
        }
        if (newProps.myPartners != this.props.myPartners) {
            let formData = this.state.formData;
            let searchedFilterData = this.state.searchedFilterData;
            let newArr = Array.from(newProps.myPartners);
            if (newArr && newArr.length > 0) {
                formData['fetchedCustomerId'] = newArr[0].WorkflowOrCustomerNumber;
                searchedFilterData['fetchedCustomerId'] =
                    newArr[0].WorkflowOrCustomerNumber;
            }
        }

        if (
            newProps.myPartners != this.props.myPartners &&
            newProps.partnerEditsAfterSave.length == 0
        ) {
            // console.log(newProps, '=========my partners');
            let formData = this.state.formData;
            let newArr = Array.from(newProps.myPartners);
            // if (newArr && newArr.length > 0)
            //     formData['fetchedCustomerId'] = newArr[0].WorkflowOrCustomerNumber;
            if (this.state.originalTableData.length === 0) {
                this.setState({
                    apolloPartners: newArr,
                    originalTableData: [...newArr],
                    formData,
                });
            } else {
                this.setState({ apolloPartners: newArr, formData });
            }
            if (newProps.myPartners.length > 0) {
                let isSoldToPresent = false;
                newProps.myPartners.some((partnerData) => {
                    if (partnerData.PartnerFunctionTypeId == 1) {
                        isSoldToPresent = true;
                        return true;
                    } else {
                        isSoldToPresent = false;
                    }
                });
                this.setState({
                    isSoldToPresent,
                });
            }
        }
        if (this.props.partnerEditsAfterSave !== newProps.partnerEditsAfterSave) {
            // if (newProps.partnerEditsAfterSave.length > 0) {
            //     let { apolloPartners } = this.state;
            //     let tempApolloPartners = [...apolloPartners];
            //     newProps.partnerEditsAfterSave.forEach((data) => {
            //         console.log(data);
            //         if (data.rowIndex) {
            //             let rowData = tempApolloPartners[data.rowIndex];
            //             tempApolloPartners[data.rowIndex] = { ...rowData, ...data };
            //         } else {
            //             tempApolloPartners.push(data);
            //         }
            //     });
            //     this.setState(
            //         {
            //             apolloPartners: tempApolloPartners,
            //         },
            //         () => {
            //             // console.log(this.state.apolloPartners)
            //         }
            //     );
            // }
        }
    }

    onFieldChange = (value, e) => {
        const name = e.target.name;
        const { formData: prevFormData } = this.state;

        this.setState((prevState, props) => {
            return {
                formData: {
                    ...prevState.formData,
                    [name]: value,
                },
            };
        });
    };

    onFilterSubmit = () => {
        const { formData, isToggled } = this.state;
        formData['WorkflowTitle'] = '';
        formData['Purpose'] = '';
        this.setState(
            {
                loadedPartnersRequestData: true,
                current_page: 1,
                payloadp: [],
                originalTableData: [],
                newAddedData: [],
                formData,
            },
            () => {
                this.props.clearPartnerDetails();
                this.props.clearPartnerEditsAfterSave();
                this.toggle(!isToggled);
                var postdata = {
                    UserId: localStorage.getItem('userId'),
                    WorkflowOrCustomerNumber: formData.CustomerOrWorkflowId,
                    DistributionChannelTypeId: formData.DistributionChannelTypeId,
                    DivisionTypeId: formData.DivisionTypeId,
                    SalesOrgTypeId: formData.SalesOrgTypeId,
                    RoleTypeId: formData.RoleTypeId,
                };
                this.setState({
                    previousPostData: postdata,
                    searchedFilterData: formData,
                });
                this.props.getApolloPartners(postdata);
                this.generateWorkflowId();
            }
        );
    };

    onReset = () => {
        const { toasts } = this.props;
        let flag = false;
        if (toasts && toasts.length > 0) {
        }
        this.setState(
            (prevState, props) => {
                return {
                    formData: {},
                    isToggled: !prevState.isToggled,
                    loadedPartnersRequestData: !prevState.loadedPartnersRequestData,
                    current_page: 1,
                    partnersData: [],
                    apolloPartners: this.props.myPartners,
                    modalVisible: false,
                    searchedFilterData: {},
                    selectedFiles: {},
                    selectedFilesIds: [],
                    files: [],
                    fileinputkey: Date.now(),
                };
            },
            () => {
                if (toasts && toasts.length > 0) {
                    if (toasts[0]['color'] == '#c0392b') this.generateWorkflowId();
                }
            }
        );
        // var postdata = {
        //     UserId: localStorage.getItem('userId'),
        // };
        this.props.clearPartnerDetails();
        this.generateWorkflowId();
        // this.props.getApolloPartners();
    };

    generateWorkflowId() {
        const url = GenerateWorkflowId;
        ajaxGetRequest(url).then((res) => {
            if (res.IsSuccess)
                this.setState({
                    fetchingWorkflowId: false,
                    formData: {
                        ...this.state.formData,
                        WorkflowId: res.ResultData,
                    },
                    WorkflowId: res.ResultData,
                });
        });
    }

    isEmpty = (data) => {
        let result = false;
        if (typeof data === 'string') {
            return !data || 0 === data.length;
        }
        if (typeof data === 'object') {
            for (var key in data) {
                if (data.hasOwnProperty(key)) return false;
            }
            return true;
        }
        return result;
    };

    onSubmit = (event, _schema) => {
        // this.setState(
        //     {
        //         modalVisible: false,
        //     },
        //     () => {
        console.log('tstaet', this.state.formData);
        yupAllFieldsValidation(
            this.state.formData,
            _schema,
            (...rest) => {
                this.handleSubmission(...rest);
                this.setState({
                    modalVisible: false,
                });
            },
            this.setFormErrors
        );
        // }
        // );
    };

    checkChangeOfData = (editedData, originalData) => {
        const { originalTableData } = this.state;
        let flag = false;
        if (editedData.PartnersOperation == 'Edit') {
            if (
                editedData.IsDefaultPartner != undefined &&
                editedData.IsDefaultPartner != null
            ) {
                return (
                    editedData.IsDefaultPartner !=
                    originalTableData[editedData.rowIndex].IsDefaultPartner
                );
            }
            if (editedData.UnPartner != null || editedData.UnPartner != undefined) {
                return editedData.UnPartner != originalData.UnPartner;
            }
        } else if (
            editedData.PartnersOperation == 'Add' ||
            editedData.PartnersOperation == 'Remove'
        ) {
            return true;
        }
    };

    checkMandatoryRoles = (data) => {
        let mandatedRoles = [1, 2, 3, 4];
        let exceptionRoles = [5, 6];
        let tempData = [];
        let flag = true;
        if (data.length > 0) {
            data.forEach((roleIds) => {
                tempData.push(parseInt(roleIds));
            });
            mandatedRoles.forEach((roleIds) => {
                if (roleIds) {
                    if (!tempData.includes(parseInt(roleIds))) {
                        flag = false;
                    }
                } else {
                    flag = false;
                }
            });
            console.log(flag);
            return flag;
        } else {
            return false;
        }
    };

    checkRedundantData = (payloadData) => {
        let isRedundant = false;
        const { newAddedData } = this.state;
        console.log(newAddedData, payloadData);
        payloadData.forEach((data, payloadIndex) => {
            newAddedData.some((checkData, checkIndex) => {
                // if(payloadIndex!=checkIndex){
                console.log('inside cond loop', data, checkData);
                if (
                    data.CustomerNumberOrWorkflowNumber == checkData.PartnerNumber &&
                    data.PartnerFunctionTypeId == checkData.PartnerFunctionTypeId &&
                    data.PartnersOperation == 'Add' &&
                    checkData.PartnersOperation != 'Remove'
                ) {
                    console.log(
                        data.CustomerNumberOrWorkflowNumber == checkData.PartnerNumber,
                        data.PartnerFunctionTypeId == checkData.PartnerFunctionTypeId,
                        data.PartnersOperation == 'Add' &&
                            checkData.PartnersOperation != 'Remove',
                        '========='
                    );
                    isRedundant = true;
                    return true;
                    // }
                }
            });
        });
        console.log(isRedundant, '================== is Redundant ++++++');
        payloadData.forEach((data, payloadIndex) => {
            payloadData.some((checkData, checkIndex) => {
                if (payloadIndex != checkIndex) {
                    // console.log('inside cond loop', data,checkData)
                    if (
                        data.CustomerNumberOrWorkflowNumber ==
                            checkData.CustomerNumberOrWorkflowNumber &&
                        data.PartnerFunctionTypeId == checkData.PartnerFunctionTypeId &&
                        data.PartnersOperation != 'Remove' &&
                        checkData.PartnersOperation != 'Remove'
                        //  && (!checkData.PartnersOperation && data.PartnersOperation=='Add')
                    ) {
                        console.log(
                            '=======',
                            data.PartnersOperation,
                            checkData.PartnersOperation
                        );
                        isRedundant = true;
                        return true;
                    }
                }
            });
        });

        console.log(isRedundant, '================ is Redundant');

        return !isRedundant;
    };

    handleSubmission = (schema) => {
        const { history } = this.props;
        let changedData = [];
        const {
            selectedFilesIds,
            selectedFiles,
            formData,
            userId,
            newAddedData,
        } = this.state;
        console.log(this.state.newAddedData);
        let flag = true;
        let checkRoles = [];
        let checkRoleConditionsIndex = null;
        if (newAddedData.length > 0) {
            checkRoleConditionsIndex = newAddedData.findIndex((x) => {
                // if the role is selected and it's partner operation is not "REMOVE" then roles are moved to an array for an mandatory role validation
                if (x.PartnerFunctionTypeId && x.PartnersOperation != 'Remove')
                    checkRoles.push(x.PartnerFunctionTypeId);
                // if the partner role or address, name and mdmnumber is not present then the flag is set to false so it's not a valid  data and the user has to select the role or remove the newly added row
                if (
                    !x.PartnerFunctionTypeId ||
                    (!x.MdmNumber && !x.PartnerCustomerAddress && !x.PartnerCustomerName)
                )
                    flag = false;
                return (
                    !x.PartnerFunctionTypeId ||
                    (!x.MdmNumber && !x.PartnerCustomerAddress && !x.PartnerCustomerName)
                );
            });
            if (checkRoleConditionsIndex < 0) {
                flag = true;
            } else {
                flag = false;
            }
            console.log(
                flag,
                checkRoleConditionsIndex,
                this.checkMandatoryRoles(checkRoles),
                checkRoles
            );

            if (
                flag &&
                this.checkMandatoryRoles(checkRoles) //method to check if all the mandatory roles are present
                // && this.checkRedundantData(this.state.payloadp)
            ) {
                if (this.props.myPartners.length > 0) {
                    this.state.payloadp.forEach((payloadData) => {
                        this.props.myPartners.some((origData) => {
                            // if the mandatory roles are present, check for the partner operation to be add or if there is an workflow number are same
                            if (
                                payloadData.PartnersOperation == 'Add' ||
                                payloadData.CustomerNumberOrWorkflowNumber ==
                                    origData.PartnerNumber
                            ) {
                                //method to check the change of data, such that an unpartner or default partner change is added in the payload dict
                                if (this.checkChangeOfData(payloadData, origData)) {
                                    changedData.push(payloadData);
                                }
                                return true;
                            }
                        });
                    });
                } else {
                    changedData = this.state.payloadp;
                }

                //remove if any duplicate data are present with newly added row
                let partnerEditsToSave = [];
                changedData.forEach((editData, editIndex) => {
                    if (editData.PartnersOperation == 'Add') {
                        if (partnerEditsToSave.length > 0) {
                            let x = partnerEditsToSave.findIndex(
                                (dataToFind, newIndex) =>
                                    dataToFind.CustomerNumberOrWorkflowNumber ==
                                        editData.CustomerNumberOrWorkflowNumber &&
                                    dataToFind.PartnerFunctionTypeId ==
                                        editData.PartnerFunctionTypeId
                            );
                            if (x < 0) {
                                partnerEditsToSave.push(editData);
                            } else {
                                console.log('Duplicate found', editData);
                            }
                        } else {
                            partnerEditsToSave.push(editData);
                        }
                    } else {
                        partnerEditsToSave.push(editData);
                    }
                });

                let postdata = {
                    UserId: localStorage.getItem('userId'),
                    OperationName: 'CreateApolloPartnersWorkflow',
                    WorkflowId: this.state.formData.WorkflowId,
                    SystemTypeId: 1,
                    CustomerNumberOrWorkflowNumber: this.state.formData
                        .CustomerOrWorkflowId,
                    DistributionChannelTypeId: this.state.formData
                        .DistributionChannelTypeId,
                    DivisionTypeId: this.state.formData.DivisionTypeId,
                    SalesOrgTypeId: this.state.formData.SalesOrgTypeId,
                    RoleTypeId: this.state.formData.RoleTypeId,
                    WorkflowTitle: this.state.formData.WorkflowTitle,
                    PartnerEdits: partnerEditsToSave,
                    PurposeOfRequest: this.state.formData['Purpose'],
                    files: selectedFilesIds.map((id) => selectedFiles[id]),
                };
                console.log(
                    '**--createrequest postdata--**',
                    postdata,
                    '============',
                    this.state.payloadp,
                    '=========',
                    this.state.apolloPartners
                );
                this.props.createApolloPartnerRequest({ history, postdata });
                // this.onReset();
            } else {
                this.setState(
                    {
                        displayMessage: true,
                        errorMessage:
                            checkRoleConditionsIndex > 0
                                ? 'Added row missing roles or Wrong MdmNumber'
                                : 'Sold To, Ship To, Bill To and Payer partner are mandatory',
                    },
                    () => {
                        window.scrollTo(0, 0);
                        setTimeout(() => {
                            this.setState({ displayMessage: false });
                        }, 4000);
                    }
                );
            }
        } else {
            // this.setState({
            //     displayMessage: true,
            //     errorMessage: checkRoleConditionsIndex > 0 ? "Added row missing roles" : "Sold To, Ship To, Bill To and Payer partner are mandatory"
            // }, () => {
            //     window.scrollTo(0, 0);
            //     setTimeout(() => {
            //         this.setState({ displayMessage: false })
            //     }, 4000);
            // })
        }
    };

    setFormErrors = (errors) => {
        const { formErrors } = this.state;
        this.setState({ formErrors: errors }, () => window.scrollTo(0, 0));
    };

    _renderModalContent = () => {
        const { formData, selectedFilesIds, selectedFiles } = this.state;

        return (
            <Box
                my={4}
                mx="30%"
                backgroundColor="white"
                borderRadius="4px"
                borderColor="rgba(0, 0, 0, 0.1)">
                {this.state.fetchingWorkflowId && <ActivityIndicator />}
                <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between">
                    <Text
                        style={{
                            fontWeight: '500',
                            fontSize: 20,
                            color: Colors.lightBlue,
                            marginTop: 25,
                            marginBottom: 20,
                            textAlign: 'center',
                        }}>
                        Submit Workflow
                    </Text>
                </Box>
                <Box px="0px" pb="22px" justifyContent="center">
                    <Box alignItems="center" justifyContent="center">
                        <FormInput
                            label="Workflow Id"
                            name="WorkflowId"
                            type="text"
                            value={
                                this.state.formData && this.state.formData['WorkflowId']
                            }
                            variant={'outline'}
                        />
                        <FormInput
                            label="Workflow Title"
                            multiline
                            numberOfLines={2}
                            maxLength={250}
                            name="WorkflowTitle"
                            error={'*this field is required'}
                            type="text"
                            onChange={this.onFieldChange}
                            value={
                                this.state.formData &&
                                this.state.formData['WorkflowTitle']
                            }
                            variant={'solid'}
                        />
                        <FormInput
                            label="Purpose"
                            multiline
                            numberOfLines={2}
                            maxLength={250}
                            name="Purpose"
                            // error={'*this field is required'}
                            type="text"
                            onChange={this.onFieldChange}
                            value={this.state.formData && this.state.formData['Purpose']}
                            variant={'solid'}
                        />
                    </Box>
                    <Flex
                        justifyEnd
                        alignCenter
                        alignSelf="flex-end"
                        style={{
                            paddingTop: 15,
                            flexDirection: 'row',
                            alignItems: 'center',
                            paddingLeft: 10,
                            paddingRight: 15,
                            marginTop: 20,
                            marginBottom: 10,
                            marginHorizontal: 25,
                        }}>
                        {/* <label htmlFor="file-upload" className="custom-file-upload">
                             <MaterialIcons name="attach-file" size={18} color="#fff" />
                         </label>
                         <input
                             id="file-upload"
                             key={this.state.fileinputkey}
                             type="file"
                             onChange={this.selectFiles}
                             multiple
                         /> */}
                        <Button
                            onPress={(event) => {
                                this.onSubmit(event, apolloPartnerRules);
                            }}
                            title="Submit"
                        />
                        <Button
                            title="Cancel"
                            onPress={() => this.setState({ modalVisible: null })}
                        />
                    </Flex>
                </Box>
            </Box>
        );
    };

    selectFiles = (events) => {
        event.preventDefault();

        const { selectedFilesIds, selectedFiles } = this.state;
        const id = events.target.files[0].name;

        this.setState({
            selectedFiles: {
                ...selectedFiles,
                [id]: {
                    data: events.target.files[0],
                    DocumentName: events.target.files[0].name,
                    DocumentType: 0,
                },
            },
            selectedFilesIds: [...selectedFilesIds, id],
            filename: events.target.files[0].name,
            fileinputkey: Date.now(),
        });
    };

    removeFile = (id) => {
        event.preventDefault();

        var selectedFilesDict = this.state.selectedFiles;
        var selectedFilesIdsList = this.state.selectedFilesIds;
        // remove the existing state
        delete selectedFilesDict[id];
        selectedFilesIdsList = selectedFilesIdsList.filter((fileid) => fileid !== id);

        this.setState({
            selectedFiles: selectedFilesDict,
            selectedFilesIds: selectedFilesIdsList,
        });
    };

    componentWillUnmount() {
        this.props.clearPartnerDetails();
        // this.props.removeMessage();
        this.props.clearMessage();
    }

    addPartnersHandler = (addNewData) => {
        this.setState({
            addNewData,
        });
    };

    checkForChange = (editedData) => {
        const { originalTableData } = this.state;
        let flag = false;
        editedData.some((editElem) => {
            originalTableData.some((originalItems) => {
                if (
                    originalItems.WorkflowOrCustomerNumber ==
                        editElem.WorkflowOrCustomerNumber &&
                    originalItems.PartnerFunctionTypeId == editElem.PartnerFunctionTypeId
                )
                    console.log(
                        originalItems.UnPartner != editElem.UnPartner,
                        originalItems.IsDefault != editElem.IsDefault,
                        originalItems.IsDefaultPartner != editElem.IsDefaultPartner
                    );
                if (
                    originalItems.PartnersOperation == 'Add' ||
                    (originalItems.WorkflowOrCustomerNumber ==
                        editElem.WorkflowOrCustomerNumber &&
                        originalItems.PartnerFunctionTypeId ==
                            editElem.PartnerFunctionTypeId &&
                        (originalItems.UnPartner != editElem.UnPartner ||
                            originalItems.IsDefault != editElem.IsDefault ||
                            originalItems.IsDefaultPartner != editElem.IsDefaultPartner))
                ) {
                    flag = true;
                }
                return flag;
            });
            return flag;
            // if(editElem.PartnersOperation=='Add'||editElem.)
        });
        return flag;
    };

    render() {
        const {
            width,
            height,
            marginBottom,
            location,
            partnersFilters: filters = {},
        } = this.props;
        const { state } = location;
        const { isToggled, selectedFiles, selectedFilesIds } = this.state;
        // if (this.props.fetching)
        //     return (
        //         <Box
        //             display="flex"
        //             flex={1}
        //             flexDirection="row"
        //             justifyContent="center"
        //             alignItems="center"
        //             minHeight="650px">
        //             <ActivityIndicator size="large" />
        //         </Box>
        //     );

        let details = '';
        if (!this.isEmpty(filters)) {
            let { CustomerOrWorkflowId } = filters;
            if (CustomerOrWorkflowId)
                details = details + '   Workflow or Customer Id: ' + CustomerOrWorkflowId;
        }

        const pageNumbers = [];

        if (this.props.totalPartners) {
            let totalpageCnt = Math.ceil(this.props.totalPartners.length);
            for (let i = 1; i <= totalpageCnt; i++) {
                pageNumbers.push(i);
            }
        }
        let styleProps = {};
        if (window.screen.availWidth < 900) {
            // styleProps={maxWidth:window.screen.availWidth/40,wordWrap:}
        }

        return (
            <View
                style={{
                    backgroundColor: '#EFF3F6',
                    paddingBottom: 75,
                    minHeight: '100vh',
                }}>
                <ScrollView
                    keyboardShouldPersistTaps="always"
                    style={{
                        flex: 1,
                        paddingHorizontal: width < 1440 ? 75 : width * 0.1,
                        paddingBottom: 5,
                    }}>
                    <Modal
                        isVisible={this.state.modalVisible}
                        style={{ minWidth: '800px' }}>
                        {this._renderModalContent()}
                    </Modal>

                    <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="space-between">
                        <Text
                            style={{
                                fontWeight: '500',
                                fontSize: 24,
                                color: Colors.lightBlue,
                                marginTop: 25,
                                marginBottom: 20,
                            }}>
                            APOLLO PARTNERS
                        </Text>
                    </Box>
                    <FlashMessages
                        toasts={this.props.toasts}
                        onDismiss={this.props.clearMessage}
                        showDuration={10000}
                        isCopy={true}
                        style={{ width: '90%', wordWrap: 'break-word', marginTop: '-5%' }}
                    />
                    {this.state.displayMessage && (
                        <FlashMessage
                            animate
                            bg={{ backgroundColor: FAILED_BGCOLOR }}
                            message={this.state.errorMessage}
                            showDuration={10000}
                            isCopy={true}
                            style={{
                                width: '90%',
                                wordWrap: 'break-word',
                                marginTop: '-5%',
                            }}
                        />
                    )}

                    <Box style={{ zIndex: '-999' }}>
                        <PartnersFilterFields
                            SystemTypeId="1"
                            dropDownDatas={this.state.dropDownDatas || []}
                            onFieldChange={this.onFieldChange}
                            formData={this.state.formData}
                            onSubmit={this.onFilterSubmit}
                            staticDropDownData={this.props.staticData}
                            onReset={this.onReset}
                            formErrors={this.state.formErrors}
                            fetching={this.props.fetching}
                        />
                    </Box>
                    {!this.isEmpty(filters) && details.length > 0 && (
                        <StyledText style={{ color: '#10206C', textAlign: 'inherit' }}>
                            Search Filters Applied: {details}
                        </StyledText>
                    )}
                    {this.props.fetching && (
                        <Box
                            display="flex"
                            flex={1}
                            flexDirection="row"
                            justifyContent="center"
                            alignItems="center"
                            minHeight="100px">
                            <ActivityIndicator size="large" />
                        </Box>
                    )}
                    <div
                        style={{
                            visibility: this.props.fetching ? 'hidden' : 'visible',
                        }}>
                        <CreatePartnersGrid
                            data={[...this.state.apolloPartners]}
                            columns={CreateApolloPartnersColumns}
                            onChange={(data) => {
                                this.setState(
                                    {
                                        apdata: data,
                                        message: null,
                                    }
                                    // console.log('apdata--', this.state.apdata)
                                );
                            }}
                            setPayloadp={(pdata) => {
                                console.log(pdata);
                                this.setState(
                                    {
                                        payloadp: pdata,
                                    }
                                    // console.log('payload--', this.state.payloadp, pdata)
                                );
                            }}
                            filterData={this.state.searchedFilterData}
                            ApolloPartnersRoleType={
                                this.props.staticData &&
                                this.props.staticData.ApolloPartnersRoleType
                            }
                            setTableData={(data) => {
                                // console.log(data);
                                this.setState({
                                    newAddedData: data,
                                });
                            }}
                            isSoldToPresent={this.state.isSoldToPresent}
                            originalTableData={[...this.state.originalTableData]}
                            addData={this.state.addNewData}
                            partnerEditsAfterSave={this.props.partnerEditsAfterSave}
                            fetching={this.props.fetching}
                        />
                    </div>
                    {!this.props.fetching && (
                        <div style={{ width: '100%' }}>
                            <div
                                style={{
                                    flexDirection: 'row',
                                    flexBasis: 'auto',
                                    display: 'flex',
                                }}>
                                <Button
                                    disabled={
                                        Object.keys(this.state.searchedFilterData)
                                            .length == 0
                                    }
                                    onPress={() => {
                                        if (
                                            Object.keys(this.state.searchedFilterData)
                                                .length > 0
                                        )
                                            this.addPartnersHandler({
                                                CustomerNumberOrWorkflowNumber: '',
                                                PartnerCustomerNumber: '',
                                                MdmNumber: '',
                                                PartnerCustomerName: '',
                                                PartnerCustomerAddress: '',
                                                PartnersOperation: 'Add',
                                                PartnerFunctionRole: '',
                                                PartnerFunctionTypeId: '',
                                                IsDefault: false,
                                                newRow: true,
                                                UnPartner: false,
                                                IsDefaultPartner: false,
                                            });
                                    }}
                                    style={{ maxWidth: '120px' }}
                                    title="Add Partner"
                                />
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        width: '90%',
                                        justifyContent: 'flex-end',
                                    }}>
                                    <label
                                        htmlFor="file-upload"
                                        className="custom-file-upload">
                                        <MaterialIcons
                                            name="attach-file"
                                            size={18}
                                            color="#fff"
                                        />
                                    </label>
                                    <input
                                        id="file-upload"
                                        key={this.state.fileinputkey}
                                        type="file"
                                        onChange={this.selectFiles}
                                        multiple
                                        disabled={
                                            (this.state.apolloPartners &&
                                                this.state.apolloPartners.length === 0) ||
                                            this.state.payloadp.length === 0
                                        }
                                    />
                                    <Button
                                        style={{ width: '10%', alignSelf: 'flex-end' }}
                                        onPress={(event) => {
                                            this.setState({
                                                formErrors: {},
                                                modalVisible: true,
                                            });
                                        }}
                                        disabled={
                                            (this.state.apolloPartners &&
                                                this.state.apolloPartners.length === 0) ||
                                            this.state.payloadp.length == 0 ||
                                            (this.state.payloadp.length === 0 &&
                                                !this.checkForChange(this.state.payloadp))
                                        }
                                        title="Save"
                                    />
                                </div>
                            </div>
                            <FilesList
                                title="NEW ATTACHMENTS"
                                formErrors={this.state.fileErrors}
                                files={selectedFilesIds.map((id) => selectedFiles[id])}
                                onChange={(value, id) => {
                                    this.setState({
                                        selectedFiles: {
                                            ...selectedFiles,
                                            [id]: {
                                                ...selectedFiles[id],
                                                DocumentType: parseInt(value),
                                            },
                                        },
                                    });
                                }}
                                onRemove={(value, id) => this.removeFile(id)}
                            />
                        </div>
                    )}
                </ScrollView>
            </View>
        );
    }
}

class Default extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {}

    render() {
        const props = this.props;

        return (
            <DimensionAware
                render={(dimensions) => (
                    <Page
                        {...{
                            ...props,
                            width: getWindowWidth(dimensions),
                            height: getWindowHeight(dimensions),
                            marginBottom: 25,
                        }}
                    />
                )}
            />
        );
    }
}

const mapStateToProps = ({ myPartners, staticData, toasts }) => {
    const {
        apolloPartnersData: partners,
        totalPartners,
        partnersFilters,
        fetching,
        alert,
        partnerEditsAfterSave,
    } = myPartners;
    const { data, fetching: staticFetching } = staticData;
    return {
        myPartners: partners || [],
        totalPartners: totalPartners || [],
        partnersFilters,
        fetching: fetching || staticFetching,
        alert,
        staticData: data,
        toasts,
        partnerEditsAfterSave: partnerEditsAfterSave || [],
    };
};

const styles = StyleSheet.create({
    inputContainer: {
        marginLeft: 0,
        borderWidth: 2,
        minWidth: 700,
        borderRadius: 20,
        flex: 1,
        borderBottomWidth: 2,
        borderBottomLeftRadius: 20,
        borderBottomRightRadius: 20,
    },
    noBorders: {
        borderBottomWidth: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
    },
    img: {
        width: 20,
        height: 20,
    },
});

export default connect(mapStateToProps, {
    getApolloPartners,
    getStaticRequest,
    createApolloPartnerRequest,
    clearPartnerDetails,
    removeMessage,
    clearPartnerEditsAfterSave,
    clearMessage,
})(Default);
