import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
// import asyncComponent from '../../../constants/asyncComponent'
import CustomerMasterForm from './CustomerMasterForm';
import MyTasksForm from './Form';
import OtherRequest from './OtherRequest';
{
    /* <Route path={`${match.url}/`} component={} /> */
}
const PtmnComp = ({ match }) => {
    console.log(match.url);
    return (
        <Switch>
            <Route
                exact
                path={`${match.url}/customer-master/:id/other-request`}
                component={OtherRequest}
            />
            <Route path={`${match.url}/customer-master`} component={CustomerMasterForm} />
            <Route path={`${match.url}/task`} component={MyTasksForm} />
        </Switch>
    );
};
export default PtmnComp;
