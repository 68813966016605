import React from 'react';
import {
    ScrollView,
    View,
    TouchableOpacity,
    ActivityIndicator,
    Image,
    CheckBox,
    StyleSheet,
    Dimensions,
} from 'react-native';
import {
    DimensionAware,
    getWindowHeight,
    getWindowWidth,
} from 'react-native-dimension-aware';
import { Colors, StyledText } from '../../../theme/Index';
import { Flex, Column, Card, Button, Box, Text } from '../../../components/common';
import FilesList from '../../../components/FilesList.js';
import { FormInput, FormSelect } from '../../../components/form';
import ProgressBarAnimated from 'react-native-progress-bar-animated';
import { saveApolloMyTaskContracts } from '../../../appRedux/actions/MyTasks';
import { getStaticRequest } from '../../../appRedux/actions';
import { removeMessage } from '../../../appRedux/actions/Toast';
import { yupFieldValidation, yupAllFieldsValidation } from '../../../constants/utils';
import { MaterialIcons } from '@expo/vector-icons';
import {
    getStatusBarData,
    getFunctionalGroupData,
} from '../../../appRedux/actions/Workflow';
import GlobalMdmFields from '../../../components/GlobalMdmFields';
import SystemFields from '../../../components/SystemFields';
import {
    FieldValidationRoleType,
    mytaskContractsRules,
    rejectRules,
} from '../../../constants/FieldRules';
import {
    RoleType,
    SalesOrgType,
    SystemType,
    DistributionChannelType,
    DivisionType,
    CompanyCodeType,
} from '../../../constants/WorkflowEnums';
import DynamicSelect from '../../../components/DynamicSelect';
import {
    fetchContractsDropDownData,
    fetchCreateTeamRoleFieldMapping,
} from '../../../redux/DropDownDatas';
import Loading from '../../../components/Loading';
import FlashMessage, { FlashMessages } from '../../../components/FlashMessage';
import { connect } from 'react-redux';
import MultiColorProgressBar from '../../../components/MultiColorProgressBar';
import { ConfirmSignIn } from 'aws-amplify-react';
import Modal from 'modal-enhanced-react-native-web';
import BreadCrumbs from '../../../components/BreadCrumbs';
import idx from 'idx';
import * as moment from 'moment';

class Page extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            WorkflowId: this.props.location.state.WorkflowId,
            TaskId: this.props.location.state.TaskId,
            reject: false,
            dropDownDatas: {},
            teamRoleFieldMapping: {},
            formData: { RejectionButton: false },
            formErrors: {},
            inputPropsForDefaultRules: {},
            fileErrors: {},
            selectedFiles: {},
            selectedFilesIds: [],
            files: [],
            fileinputkey: Date.now(),
        };
    }

    componentDidMount() {
        let { state: wf } = this.props.location;
        let postJson = {
            workflowId: wf.WorkflowId,
            fuctionalGroup: 'contracts',
            taskId: wf.TaskId,
        };

        this.props.getStaticRequest([15, 30, 26, 17, 42, 43, 82]);
        this.props.getStatusBarData(postJson);
        this.props.getFunctionalGroupData(postJson);

        fetchContractsDropDownData().then((res) => {
            const data = res;
            this.setState({ dropDownDatas: data });
        });
        fetchCreateTeamRoleFieldMapping().then((resp) => {
            const data = resp;
            this.setState({ teamRoleFieldMapping: data });
        });
    }

    componentWillReceiveProps(newProps) {
        let { state: wf } = this.props.location;
        if (
            newProps.functionalGroupDetails != this.props.functionalGroupDetails &&
            !wf.isReadOnly
        ) {
            var result = this.validateFromSourceData(
                newProps.functionalGroupDetails.Customer
            );
            this.setState({
                formData: {
                    ...this.state.formData,
                    ...result.newStateValue,
                    Role:
                        FieldValidationRoleType[
                            newProps.functionalGroupDetails.Customer.RoleTypeId
                        ],
                },
                inputPropsForDefaultRules: {
                    ...this.state.inputPropsForDefaultRules,
                    ...result.newStyleProps,
                },
            });
        }
    }

    setFormErrors = (errors) => {
        const { formErrors } = this.state;
        this.setState({ formErrors: errors }, () => window.scrollTo(0, 0));
    };

    onFieldChange = (value, e) => {
        const { name } = e.target;
        this.setState(
            {
                formData: {
                    ...this.state.formData,
                    [name]: value,
                },
            },
            () => {
                if (name === 'CustomerGroupTypeId' || name === 'IncoTerms1TypeId') {
                    this.validateRules(name, value);
                }
                //remove from formData if TypeId is set to 0 OR ''
                if (name.includes('TypeId') && parseInt(value) === 0) {
                    delete this.state.formData[name];
                }
            }
        );
    };

    setBulkFormDataUpdate = (keyValdict) => {
        this.setState({
            formData: {
                ...this.state.formData,
                ...keyValdict,
            },
        });
    };
    setFormDataValues = (name, value) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: value,
            },
        });
    };

    setInputPropsForDefaultRules = (field_name, property) => {
        this.setState({
            inputPropsForDefaultRules: {
                ...this.state.inputPropsForDefaultRules,
                [field_name]: property,
            },
        });
    };
    // display or set input/dropdowns values based on rules
    validateRules = (stateKey, stateVal) => {
        const readOnlyInputprop = { inline: true, variant: 'outline' };
        const editInputProp = {
            inline: false,
            variant: 'solid',
            onChange: this.onFieldChange,
        };
        const readOnlyDropDown = { disabled: true };
        // check for incoterms2
        if (stateKey === 'IncoTerms1TypeId') {
            var INCOT1_val = stateVal;
            if (INCOT1_val === '1') {
                this.setBulkFormDataUpdate({
                    isRequiredINCOT2: true,
                    isReadonlyINCOT2: false,
                    IncoTerms2: '',
                });
                this.setInputPropsForDefaultRules('IncoTerms2', { disabled: false });
            } else if (INCOT1_val === '3') {
                this.setBulkFormDataUpdate({
                    isRequiredINCOT2: false,
                    isReadonlyINCOT2: false,
                    IncoTerms2: 'Carriage Paid To',
                });
                this.setInputPropsForDefaultRules('IncoTerms2', { disabled: false });
            } else if (INCOT1_val === '4') {
                this.setBulkFormDataUpdate({
                    isRequiredINCOT2: false,
                    isReadonlyINCOT2: false,
                    IncoTerms2: 'Delivered at Location',
                });
                this.setInputPropsForDefaultRules('IncoTerms2', { disabled: false });
            } else if (INCOT1_val === '5') {
                this.setBulkFormDataUpdate({
                    isRequiredINCOT2: false,
                    isReadonlyINCOT2: false,
                    IncoTerms2: 'Delivered Duty Paid',
                });
                this.setInputPropsForDefaultRules('IncoTerms2', { disabled: false });
            } else if (INCOT1_val === '7') {
                this.setBulkFormDataUpdate({
                    isRequiredINCOT2: false,
                    isReadonlyINCOT2: false,
                    IncoTerms2: 'Ex Works',
                });
                this.setInputPropsForDefaultRules('IncoTerms2', { disabled: false });
            } else if (INCOT1_val === '8') {
                this.setBulkFormDataUpdate({
                    isRequiredINCOT2: false,
                    isReadonlyINCOT2: false,
                    IncoTerms2: 'Free Carrier',
                });
                this.setInputPropsForDefaultRules('IncoTerms2', { disabled: false });
            } else {
                this.setBulkFormDataUpdate({
                    isRequiredINCOT2: false,
                    isReadonlyINCOT2: false,
                    IncoTerms2: '',
                });
                this.setInputPropsForDefaultRules('IncoTerms2', { disabled: false });
            }
        }
        // check for AccountTypeId //ITMDMK1707 ALL Editable
        if (stateKey === 'CustomerGroupTypeId') {
            var cg_val = stateVal;
            if (cg_val === '1' || cg_val === '10') {
                this.setFormDataValues('AccountTypeId', '1');
                this.setInputPropsForDefaultRules('AccountTypeId', { disabled: false });
            } else if (cg_val === '2' || cg_val === '7') {
                this.setFormDataValues('AccountTypeId', '2');
                this.setInputPropsForDefaultRules('AccountTypeId', { disabled: false });
            } else if (
                cg_val === '3' ||
                cg_val === '4' ||
                cg_val === '6' ||
                cg_val === '11'
            ) {
                this.setFormDataValues('AccountTypeId', '3');
                this.setInputPropsForDefaultRules('AccountTypeId', { disabled: false });
            } else if (cg_val === '8') {
                this.setFormDataValues('AccountTypeId', '6');
                this.setInputPropsForDefaultRules('AccountTypeId', { disabled: false });
            } else {
                this.setFormDataValues('AccountTypeId', '');
                this.setInputPropsForDefaultRules('AccountTypeId', {
                    disabled: false,
                });
            }
        }
    };

    validateFromSourceData = (source_data) => {
        const readOnlyDropDown = { disabled: true };
        const newStateValue = {},
            newStyleProps = {};

        //check Customer group --as per #ITMDMK1756 customergroup is editable in all cases.
        if (source_data.CategoryTypeId != undefined) {
            let categoryTypeid = parseInt(source_data.CategoryTypeId);
            if (categoryTypeid === 2) {
                //if self-distributor
                newStateValue['CustomerGroupTypeId'] = '5';
                newStyleProps['CustomerGroupTypeId'] = { disabled: false }; // readOnlyDropDown;;
            } else if (categoryTypeid === 3 || categoryTypeid === 6) {
                //if oem or kitter
                newStateValue['CustomerGroupTypeId'] = '9';
                newStyleProps['CustomerGroupTypeId'] = { disabled: false }; // readOnlyDropDown;;
            } else if (categoryTypeid === 7) {
                // if dropship
                newStateValue['AccountTypeId'] = '3';
                newStyleProps['AccountTypeId'] = { disabled: false };
                newStateValue['CustomerGroupTypeId'] = '11';
                newStyleProps['CustomerGroupTypeId'] = { disabled: false };
            }
        }

        return { newStateValue, newStyleProps };
    };

    handleFormSubmission = (schema) => {
        const userId = localStorage.getItem('userId');

        let {
                TaskId,
                WorkflowId,
                formData,
                selectedFiles,
                selectedFilesIds,
                teamRoleFieldMapping,
            } = this.state,
            castedFormData = {},
            postData = {};
        const {
            functionalGroupDetails: {
                DocumentLocation: files,
                Customer: globalMdmDetail = {},
                Contracts: functionalDetail = null,
                DocumentLocation,
                TaskNote: Notes = '',
                RequestorName,
            },
        } = this.props;
        try {
            const WorkflowTaskModel = {
                RejectReason: formData['RejectionButton']
                    ? formData['RejectionReason']
                    : '',
                TaskId: TaskId,
                UserId: localStorage.getItem('userId'),
                WorkflowId: WorkflowId,
                WorkflowTaskOperationType: !formData['RejectionButton'] ? 1 : 2,
            };

            if (!formData['RejectionButton']) {
                castedFormData = schema.cast(formData);
            } else {
                castedFormData = formData;
                delete castedFormData.CustomerGroupTypeId;
                delete castedFormData.RejectionReason;
            }
            delete castedFormData.RejectionButton;
            delete castedFormData.Role;
            const ContractTeamFields =
                idx(teamRoleFieldMapping, (_) => _.ContractTeamFields) || [];
            const RoleTypeId = globalMdmDetail.RoleTypeId;
            const SystemTypeId = globalMdmDetail.SystemTypeId;
            const allowedFieldList = ContractTeamFields.filter(
                (item) =>
                    item.RoleTypeId === RoleTypeId && item.SystemTypeId === SystemTypeId
            );
            let allowedFields = undefined;
            if (allowedFieldList && allowedFieldList.length > 0) {
                allowedFields = allowedFieldList[0].Fields;
            }

            castedFormData['AccountTypeId'] =
                allowedFields && allowedFields.includes('AccountTypeId')
                    ? castedFormData['AccountTypeId']
                    : undefined;
            castedFormData['CustomerGroupTypeId'] =
                allowedFields && allowedFields.includes('CustomerGroupTypeId')
                    ? castedFormData['CustomerGroupTypeId']
                    : undefined;
            castedFormData['IncoTerms1TypeId'] =
                allowedFields && allowedFields.includes('IncoTerms1TypeId')
                    ? castedFormData['IncoTerms1TypeId']
                    : undefined;
            castedFormData['IncoTerms2'] =
                allowedFields && allowedFields.includes('IncoTerms2')
                    ? castedFormData['IncoTerms2']
                    : undefined;
            castedFormData['PaymentTermsTypeId'] =
                allowedFields && allowedFields.includes('PaymentTermsTypeId')
                    ? castedFormData['PaymentTermsTypeId']
                    : undefined;

            postData['formdata'] = {
                WorkflowTaskModel,
                ...castedFormData,
            };

            console.log(castedFormData);

            postData['files'] = selectedFilesIds.map((id) => selectedFiles[id]);
            this.props.saveApolloMyTaskContracts(postData);
            this.resetForm();
            this.scrollToTop();
        } catch (error) {
            console.log('form validtion error', error);
        }
    };

    onSubmit = (event, reject, schema) => {
        let { formData, selectedFilesIds, selectedFiles } = this.state;
        let fileErrors = {};
        let errors = false;
        selectedFilesIds.map((id) => {
            if (selectedFiles[id] && selectedFiles[id].DocumentType <= 0) {
                fileErrors[id] = 'Document Type Required for file';
                errors = true;
            }
        });

        this.setState({ fileErrors, isFileErrors: errors });

        this.setState(
            {
                modalVisible: false,
                formData: {
                    ...this.state.formData,
                    RejectionButton: reject,
                },
            },
            () => {
                yupAllFieldsValidation(
                    this.state.formData,
                    schema,
                    (...rest) => {
                        if (this.state.isFileErrors === false)
                            this.handleFormSubmission(...rest);
                    },
                    this.setFormErrors
                );
            }
        );
    };

    scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    resetForm = () => {
        Object.keys(this.state.formData).map((key) => {
            this.setState({
                formData: {
                    [key]: '',
                },
            });
        });
        Object.keys(this.state.formErrors).map((key) => {
            this.setState({
                formErrors: {
                    [key]: '',
                },
            });
        });
        //restore initial values
        this.setState({
            formData: { RejectionButton: false },
        });
    };

    selectFiles = (events) => {
        event.preventDefault();

        const { selectedFilesIds, selectedFiles } = this.state;
        const id = events.target.files[0].name;

        this.setState({
            selectedFiles: {
                ...selectedFiles,
                [id]: {
                    data: events.target.files[0],
                    DocumentName: events.target.files[0].name,
                    DocumentType: 0,
                },
            },
            selectedFilesIds: [...selectedFilesIds, id],
            filename: events.target.files[0].name,
            fileinputkey: Date.now(),
        });
    };

    removeFile = (id) => {
        event.preventDefault();

        var selectedFilesDict = this.state.selectedFiles;
        var selectedFilesIdsList = this.state.selectedFilesIds;
        // remove the existing state
        delete selectedFilesDict[id];
        selectedFilesIdsList = selectedFilesIdsList.filter((fileid) => fileid !== id);

        this.setState({
            selectedFiles: selectedFilesDict,
            selectedFilesIds: selectedFilesIdsList,
        });
    };

    _renderModalContent = () => (
        <Box
            my={4}
            mx="30%"
            backgroundColor="white"
            borderRadius="4px"
            borderColor="rgba(0, 0, 0, 0.1)">
            <Text my={3} fontWeight="regular" color="lightBlue" fontSize={24} pl={4}>
                Submit Workflow
            </Text>
            <Box px="0px" pb="22px" justifyContent="center">
                <Box alignItems="center" justifyContent="center">
                    {!this.state.isReject ? (
                        <FormInput
                            label="Additional Notes"
                            multiline
                            numberOfLines={2}
                            maxLength={250}
                            name="AdditionalNotes"
                            type="text"
                            onChange={this.onFieldChange}
                            error={
                                this.state.formErrors
                                    ? this.state.formErrors['AdditionalNotes']
                                    : null
                            }
                            value={
                                this.state.formData &&
                                this.state.formData['AdditionalNotes']
                            }
                            variant={'solid'}
                        />
                    ) : (
                        <FormInput
                            label="Rejection Reason"
                            name="RejectionReason"
                            maxLength={250}
                            onChange={this.onFieldChange}
                            error={' *this field is required'}
                            multiline
                            numberOfLines={2}
                            type="text"
                            value={
                                this.state.formData &&
                                this.state.formData['RejectionReason']
                            }
                            variant="solid"
                        />
                    )}
                </Box>
                <Flex
                    justifyEnd
                    alignCenter
                    alignSelf="flex-end"
                    style={{
                        paddingTop: 15,
                        flexDirection: 'row',
                        alignItems: 'center',
                        paddingLeft: 10,
                        paddingRight: 15,
                        marginTop: 20,
                        marginBottom: 10,
                        marginHorizontal: 25,
                    }}>
                    <Button
                        onPress={(event) => {
                            if (this.state.isReject)
                                this.setState(
                                    {
                                        isReject: false,
                                    },
                                    () => this.onSubmit(event, true, rejectRules)
                                );
                            else this.onSubmit(event, false, mytaskContractsRules);
                        }}
                        title="Submit"
                    />
                    <Button
                        title="Cancel"
                        onPress={() => this.setState({ modalVisible: null })}
                    />
                </Flex>
            </Box>
        </Box>
    );

    render() {
        const {
            width,
            location,
            functionalGroupDetails: {
                DocumentLocation: files,
                Customer: globalMdmDetail = {},
                Contracts: functionalDetail = null,
                DocumentLocation,
                TaskNote: Notes = '',
                RequestorName,
            },
            statusBarData,
            TasksStatusByTeamId = null,
            alert = {},
        } = this.props;

        const {
            dropDownDatas,
            teamRoleFieldMapping,
            inputPropsForDefaultRules,
            selectedFilesIds,
            selectedFiles,
        } = this.state;

        const { state } = location;

        const workflow = {
            ...state,
            isReadOnly:
                TasksStatusByTeamId === null ||
                !(
                    globalMdmDetail.WorkflowStateTypeId === 2 &&
                    TasksStatusByTeamId[5].WorkflowTaskStateTypeId === 2
                ),
        };

        const ContractTeamFields =
            idx(teamRoleFieldMapping, (_) => _.ContractTeamFields) || [];
        const RoleTypeId = globalMdmDetail.RoleTypeId;
        const SystemTypeId = globalMdmDetail.SystemTypeId;
        const allowedFieldList = ContractTeamFields.filter(
            (item) => item.RoleTypeId === RoleTypeId && item.SystemTypeId === SystemTypeId
        );
        let allowedFields = undefined;
        if (allowedFieldList && allowedFieldList.length > 0) {
            allowedFields = allowedFieldList[0].Fields;
        }

        const requestDateUtc = moment(globalMdmDetail.DateOfCreation);
        const localDate = moment(requestDateUtc).local();
        const requestDate = localDate.format('YYYY-MM-DD hh:mm:ss A');

        const inputReadonlyProps = workflow.isReadOnly ? { disabled: true } : null;

        const showFunctionalDetail =
            state.isReadOnly && functionalDetail === null ? { display: 'none' } : null;

        const showButtons = workflow.isReadOnly ? { display: 'none' } : null;
        var isReleased = true;
        for (let i = 0; i < statusBarData.length; i++) {
            let teamstatus = statusBarData[i];
            if (teamstatus.WorkflowTaskStateTypeId === 2) {
                isReleased = false;
                break;
            } else {
                isReleased = true;
            }
        }
        var bgcolor = alert.color || '#FFF';

        if (this.props.fetching) {
            return <Loading />;
        }

        return (
            <ScrollView
                keyboardShouldPersistTaps="always"
                style={{
                    backgroundColor: '#EFF3F6',
                    paddingBottom: 75,
                }}>
                <Modal isVisible={this.state.modalVisible}>
                    {this._renderModalContent()}
                </Modal>
                <FlashMessages
                    toasts={this.props.toasts}
                    onDismiss={this.props.removeMessage}
                />

                {alert.display && (
                    <FlashMessage
                        bg={{ backgroundColor: bgcolor }}
                        message={alert.message}
                    />
                )}

                <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between">
                    <Text
                        style={{
                            fontWeight: '500',
                            fontSize: 24,
                            color: Colors.lightBlue,
                            marginTop: 25,
                            marginBottom: 20,
                            paddingLeft: 45,
                        }}>
                        MY TASKS - {state.Header}
                    </Text>
                    <Box
                        marginTop="25px"
                        flex={3 / 4}
                        flexDirection="row-reverse"
                        alignItems="flex-end">
                        <MultiColorProgressBar readings={statusBarData} />
                    </Box>
                </Box>

                <Box marginTop="25px" flexDirection="row-reverse" alignItems="flex-end">
                    <StyledText
                        style={{
                            fontWeight: '600',
                            paddingRight: '20px',
                            fontSize: '16px',
                            color: '#10206C',
                        }}>
                        {' '}
                        Requestor : {RequestorName}{' '}
                    </StyledText>
                </Box>

                <Box flexDirection="row-reverse" alignItems="flex-end">
                    <StyledText
                        style={{
                            fontWeight: '600',
                            paddingRight: '20px',
                            fontSize: '16px',
                            color: '#10206C',
                        }}>
                        {' '}
                        Date of Request: {requestDate}{' '}
                    </StyledText>
                </Box>
                <View
                    style={{
                        flex: 1,
                        paddingHorizontal: width < 1440 ? 60 : width * 0.1,
                        paddingBottom: 10,
                    }}>
                    <Box fullHeight my={2}>
                        <Box flexDirection="row" justifyContent="space-around" my={4}>
                            <FormInput
                                px="25px"
                                flex={1 / 4}
                                label="WorkflowTitle"
                                maxLength={40}
                                name="WorkflowTitle"
                                variant="outline"
                                type="text"
                                value={globalMdmDetail && globalMdmDetail.WorkflowTitle}
                            />

                            <FormInput
                                px="25px"
                                flex={1 / 4}
                                label="Workflow Number"
                                name="workflow-number"
                                variant="outline"
                                type="text"
                                value={globalMdmDetail && globalMdmDetail.WorkflowId}
                            />
                            {globalMdmDetail.SystemRecordId && (
                                <FormInput
                                    px="25px"
                                    flex={1 / 4}
                                    label="Customer Number"
                                    name="CustomerNumber"
                                    variant="outline"
                                    type="text"
                                    value={globalMdmDetail.SystemRecordId}
                                />
                            )}

                            {globalMdmDetail &&
                                globalMdmDetail.MdmCustomerId &&
                                (isReleased ? (
                                    <TouchableOpacity
                                        onPress={() =>
                                            this.props.history.push(
                                                `/search-results/${globalMdmDetail.MdmCustomerId}`
                                            )
                                        }>
                                        <FormInput
                                            px="25px"
                                            flex={1 / 4}
                                            label="MDM Number"
                                            name="mdm-number"
                                            variant="outline"
                                            type="text"
                                            value={
                                                globalMdmDetail &&
                                                globalMdmDetail.MdmCustomerId
                                            }
                                        />
                                    </TouchableOpacity>
                                ) : (
                                    <FormInput
                                        px="25px"
                                        flex={1 / 4}
                                        label="MDM Number"
                                        name="mdm-number"
                                        variant="outline"
                                        type="text"
                                        value={
                                            globalMdmDetail &&
                                            globalMdmDetail.MdmCustomerId
                                        }
                                    />
                                ))}
                        </Box>
                        <GlobalMdmFields
                            staticDropDownData={this.props.staticData}
                            formData={globalMdmDetail}
                            readOnly
                        />

                        <Text
                            mt="42px"
                            mb="20px"
                            fontWeight="regular"
                            color="lightBlue"
                            fontSize={24}
                            pl={4}>
                            SYSTEM FIELDS
                        </Text>
                        <Box flexDirection="row" justifyContent="center">
                            <Box width={1 / 2} mx="auto" alignItems="center">
                                <FormInput
                                    label="System"
                                    name="System"
                                    inline
                                    variant="outline"
                                    type="text"
                                    value={
                                        SystemType[
                                            globalMdmDetail &&
                                                globalMdmDetail.SystemTypeId
                                        ]
                                    }
                                />
                                <FormInput
                                    label="Role"
                                    name="Role"
                                    inline
                                    variant="outline"
                                    type="text"
                                    value={
                                        RoleType[
                                            globalMdmDetail && globalMdmDetail.RoleTypeId
                                        ]
                                    }
                                />
                                <FormInput
                                    label="Sales Org"
                                    name="SalesOrg"
                                    inline
                                    variant="outline"
                                    type="text"
                                    value={
                                        SalesOrgType[
                                            globalMdmDetail &&
                                                globalMdmDetail.SalesOrgTypeId
                                        ]
                                    }
                                />
                                <FormInput
                                    label="Purpose of Request"
                                    name="Purpose"
                                    maxLength={1000}
                                    value={globalMdmDetail && globalMdmDetail.Purpose}
                                    multiline
                                    numberOfLines={
                                        globalMdmDetail.Purpose &&
                                        globalMdmDetail.Purpose.length > 36
                                            ? 3
                                            : 1
                                    }
                                    style={{ lineHeight: '2.5' }}
                                    inline
                                    variant="outline"
                                    type="text"
                                />
                            </Box>
                            <Box width={1 / 2} mx="auto" alignItems="center">
                                <FormInput
                                    label="Distribution Channel"
                                    name="DistributionChannel"
                                    inline
                                    variant="outline"
                                    type="text"
                                    value={
                                        DistributionChannelType[
                                            globalMdmDetail &&
                                                globalMdmDetail.DistributionChannelTypeId
                                        ]
                                    }
                                />
                                <FormInput
                                    label="Division"
                                    name="DivisionTypeId"
                                    inline
                                    variant="outline"
                                    type="text"
                                    value={
                                        DivisionType[
                                            globalMdmDetail &&
                                                globalMdmDetail.DivisionTypeId
                                        ]
                                    }
                                />
                                <FormInput
                                    label="CompanyCode"
                                    name="CompanyCodeTypeId"
                                    inline
                                    variant="outline"
                                    type="text"
                                    value={
                                        CompanyCodeType[
                                            globalMdmDetail &&
                                                globalMdmDetail.CompanyCodeTypeId
                                        ]
                                    }
                                />
                            </Box>
                        </Box>

                        <Box {...showFunctionalDetail}>
                            <Text
                                mt="42px"
                                mb="20px"
                                fontWeight="regular"
                                color="lightBlue"
                                fontSize={24}
                                pl={4}>
                                CONTRACT FIELDS
                            </Text>
                            <Box flexDirection="row" justifyContent="center">
                                <Box width={1 / 2} mx="auto" alignItems="center">
                                    <DynamicSelect
                                        arrayOfData={
                                            (this.props.staticData &&
                                                this.props.staticData
                                                    .ApolloPaymentTermsType) ||
                                            []
                                        }
                                        label="Payment Terms"
                                        name="PaymentTermsTypeId"
                                        hide={
                                            allowedFields &&
                                            !allowedFields.includes('PaymentTermsTypeId')
                                        }
                                        isRequired={true}
                                        formErrors={
                                            this.state.formErrors
                                                ? this.state.formErrors[
                                                      'PaymentTermsTypeId'
                                                  ]
                                                : null
                                        }
                                        onFieldChange={this.onFieldChange}
                                        value={
                                            workflow.isReadOnly
                                                ? functionalDetail &&
                                                  parseInt(
                                                      functionalDetail.PaymentTermsTypeId
                                                  )
                                                : this.state.formData
                                                ? this.state.formData[
                                                      'PaymentTermsTypeId'
                                                  ]
                                                : null
                                        }
                                        inputProps={
                                            workflow.isReadOnly
                                                ? inputReadonlyProps
                                                : inputPropsForDefaultRules[
                                                      'PaymentTermsTypeId'
                                                  ]
                                        }
                                    />
                                    <DynamicSelect
                                        arrayOfData={dropDownDatas.AccountTypeId}
                                        label="Account Type"
                                        name="AccountTypeId"
                                        hide={
                                            allowedFields &&
                                            !allowedFields.includes('AccountTypeId')
                                        }
                                        isRequired={true}
                                        formErrors={
                                            this.state.formErrors
                                                ? this.state.formErrors['AccountTypeId']
                                                : null
                                        }
                                        onFieldChange={this.onFieldChange}
                                        value={
                                            workflow.isReadOnly
                                                ? functionalDetail &&
                                                  parseInt(functionalDetail.AccountTypeId)
                                                : this.state.formData
                                                ? this.state.formData['AccountTypeId']
                                                : null
                                        }
                                        inputProps={
                                            workflow.isReadOnly
                                                ? inputReadonlyProps
                                                : inputPropsForDefaultRules[
                                                      'AccountTypeId'
                                                  ]
                                        }
                                    />
                                </Box>
                                <Box width={1 / 2} mx="auto" alignItems="center">
                                    <DynamicSelect
                                        arrayOfData={
                                            (this.props.staticData &&
                                                this.props.staticData
                                                    .ApolloIncoTermsType) ||
                                            []
                                        }
                                        label="Incoterms 1"
                                        name="IncoTerms1TypeId"
                                        hide={
                                            allowedFields &&
                                            !allowedFields.includes('IncoTerms1TypeId')
                                        }
                                        isRequired={true}
                                        formErrors={
                                            this.state.formErrors
                                                ? this.state.formErrors[
                                                      'IncoTerms1TypeId'
                                                  ]
                                                : null
                                        }
                                        onFieldChange={this.onFieldChange}
                                        value={
                                            workflow.isReadOnly
                                                ? functionalDetail &&
                                                  parseInt(
                                                      functionalDetail.IncoTerms1TypeId
                                                  )
                                                : this.state.formData
                                                ? this.state.formData['IncoTerms1TypeId']
                                                : null
                                        }
                                        inputProps={inputReadonlyProps}
                                    />
                                    <FormInput
                                        label="Incoterms 2"
                                        name="IncoTerms2"
                                        hide={
                                            allowedFields &&
                                            !allowedFields.includes('IncoTerms2')
                                        }
                                        maxLength={28}
                                        onChange={this.onFieldChange}
                                        error={
                                            this.state.formErrors
                                                ? this.state.formErrors['IncoTerms2']
                                                : null
                                        }
                                        type="text"
                                        required={this.state.formData.isRequiredINCOT2}
                                        value={
                                            workflow.isReadOnly
                                                ? functionalDetail &&
                                                  functionalDetail.IncoTerms2
                                                : this.state.formData
                                                ? this.state.formData['IncoTerms2']
                                                : null
                                        }
                                        variant={
                                            this.state.formData.isReadonlyINCOT2 ||
                                            workflow.isReadOnly
                                                ? 'outline'
                                                : 'solid'
                                        }
                                        inline={workflow.isReadOnly ? true : false}
                                        inputProps={
                                            workflow.isReadOnly
                                                ? inputReadonlyProps
                                                : inputPropsForDefaultRules['IncoTerms2']
                                        }
                                    />

                                    <DynamicSelect
                                        arrayOfData={
                                            (this.props.staticData &&
                                                this.props.staticData
                                                    .ApolloCustomerGroupType) ||
                                            []
                                        }
                                        label="Customer Group"
                                        name="CustomerGroupTypeId"
                                        hide={
                                            allowedFields &&
                                            !allowedFields.includes('CustomerGroupTypeId')
                                        }
                                        isRequired={true}
                                        formErrors={
                                            this.state.formErrors
                                                ? this.state.formErrors[
                                                      'CustomerGroupTypeId'
                                                  ]
                                                : null
                                        }
                                        onFieldChange={this.onFieldChange}
                                        value={
                                            workflow.isReadOnly
                                                ? functionalDetail &&
                                                  parseInt(
                                                      functionalDetail.CustomerGroupTypeId
                                                  )
                                                : this.state.formData
                                                ? this.state.formData[
                                                      'CustomerGroupTypeId'
                                                  ]
                                                : null
                                        }
                                        inputProps={
                                            workflow.isReadOnly
                                                ? inputReadonlyProps
                                                : inputPropsForDefaultRules[
                                                      'CustomerGroupTypeId'
                                                  ]
                                        }
                                    />
                                </Box>
                            </Box>
                        </Box>
                        {files && (
                            <FilesList
                                files={files}
                                fileTypes={[
                                    'Choose from...',
                                    'License',
                                    'CreditApp',
                                    'PurchaseOrder',
                                    'Supporting',
                                    'Contracts',
                                ]}
                                readOnly
                            />
                        )}
                        {workflow.isReadOnly ? (
                            <>
                                <Box my={4}>
                                    <Box flexDirection="row" justifyContent="center">
                                        <Box width={1 / 2} mx="auto" alignItems="center">
                                            <FormInput
                                                label="Notes"
                                                multiline
                                                numberOfLines={2}
                                                inputWidth="100%"
                                                name="additionalNotes"
                                                variant="solid"
                                                type="text"
                                                error={
                                                    this.state.formErrors
                                                        ? this.state.formErrors[
                                                              'additionalNotes'
                                                          ]
                                                        : null
                                                }
                                                value={
                                                    workflow.isReadOnly
                                                        ? Notes
                                                        : this.state.formData
                                                        ? this.state.formData[
                                                              'additionalNotes'
                                                          ]
                                                        : null
                                                }
                                                variant={
                                                    workflow.isReadOnly
                                                        ? 'outline'
                                                        : 'solid'
                                                }
                                                inline={
                                                    workflow.isReadOnly ? true : false
                                                }
                                            />
                                        </Box>

                                        <Box
                                            width={1 / 2}
                                            mx="auto"
                                            alignItems="center"></Box>
                                    </Box>
                                </Box>
                            </>
                        ) : (
                            <FilesList
                                title="NEW ATTACHMENTS"
                                formErrors={this.state.fileErrors}
                                files={selectedFilesIds.map((id) => selectedFiles[id])}
                                fileTypes={[
                                    'Choose from...',
                                    'License',
                                    'CreditApp',
                                    'PurchaseOrder',
                                    'Supporting',
                                    'Contracts',
                                ]}
                                onChange={(value, id) => {
                                    this.setState({
                                        selectedFiles: {
                                            ...selectedFiles,
                                            [id]: {
                                                ...selectedFiles[id],
                                                DocumentType: parseInt(value),
                                            },
                                        },
                                    });
                                }}
                                onRemove={(value, id) => this.removeFile(id)}
                            />
                        )}
                    </Box>

                    <Box {...showButtons}>
                        <Flex
                            justifyEnd
                            alignCenter
                            style={{
                                paddingTop: 15,
                                flexDirection: 'row',
                                alignItems: 'center',
                                paddingLeft: 10,
                                paddingRight: 15,
                                marginTop: 20,
                                marginBottom: 10,
                                marginHorizontal: 25,
                            }}>
                            <label htmlFor="file-upload" className="custom-file-upload">
                                <MaterialIcons
                                    name="attach-file"
                                    size={18}
                                    color="#fff"
                                />
                            </label>
                            <input
                                id="file-upload"
                                type="file"
                                key={this.state.fileinputkey}
                                onChange={this.selectFiles}
                                multiple
                            />

                            <Button
                                onPress={(event) =>
                                    this.setState({ isReject: false, modalVisible: true })
                                }
                                title="Approve"
                            />
                            <Button
                                title="Reject"
                                onPress={(event) =>
                                    this.setState({ isReject: true, modalVisible: true })
                                }
                            />
                        </Flex>
                    </Box>
                </View>
            </ScrollView>
        );
    }
}

class Default extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const props = this.props;
        return (
            <DimensionAware
                render={(dimensions) => (
                    <Page
                        {...{
                            ...props,
                            width: getWindowWidth(dimensions),
                            height: getWindowHeight(dimensions),
                            marginBottom: 25,
                        }}
                    />
                )}
            />
        );
    }
}

const mapStateToProps = ({ workflows, myTasks, toasts, staticData }) => {
    const { fetching, alert } = myTasks;
    const {
        fetchingfnGroupData,
        statusBarData,
        functionalGroupDetails,
        TasksStatusByTeamId,
        fetchingStatusBar,
    } = workflows;
    const { data, fetching: staticFetching } = staticData;
    return {
        fetchingfnGroupData,
        fetching: fetching || fetchingStatusBar || fetchingfnGroupData || staticFetching,
        alert,
        statusBarData,
        TasksStatusByTeamId,
        functionalGroupDetails,
        toasts,
        staticData: data,
    };
};

export default connect(mapStateToProps, {
    saveApolloMyTaskContracts,
    getFunctionalGroupData,
    getStatusBarData,
    removeMessage,
    getStaticRequest,
})(Default);

const styles = StyleSheet.create({
    progressIndicator: {
        flex: 1,
        paddingBottom: 5,
        flexDirection: 'row-reverse',
        alignItems: 'flex-end',
    },
});
