import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
// import asyncComponent from '../../../constants/asyncComponent'
import TaskDetails from '../index';
import Pricing from './PricingForm';
import Contracts from './ContractsForm';
import Checklist from './ReleaseCheckList';
import CustomerMasterForm from './CustomerMasterForm';
import CustomerMasterSalesForce from './CustomerMasterSalesForce';
import GlobalTradeForm from './GlobalTradeForm';
import CreditForm from './CreditForm';
import MyTasksForm from './Form';
import CustomerMasterUpdate from './Update/CustomerMasterForm';
import CreditFormTask from './Update/CreditForm';
import GlobalTradeUpdate from './Update/GlobalTradeForm';
import ContractsUpdate from './Update/ContractsForm';
import PricingUpdate from './Update/PricingForm';
import CustomerGlobalForm from './Update/CustomerGlobalForm';
import ExtendReleaseChecklist from './Update/ExtendReleaseChecklist';
import OtherRequest from './OtherRequest';
import MyTaskApolloPartners from './MyTaskApolloPartners';

{
    /* <Route path={`${match.url}/`} component={} /> */
}
const ApComp = ({ match }) => {
    console.log(match.url);
    return (
        <Switch>
            <Route
                path={`${match.url}/customer-master/:id/other-request`}
                component={OtherRequest}
            />
            <Route path={`${match.url}/pricing/:id/:action`} component={PricingUpdate} />
            <Route
                path={`${match.url}/customer-master/:id/:action`}
                component={CustomerMasterUpdate}
            />
            <Route
                path={`${match.url}/credit-form/:id/:action`}
                component={CreditFormTask}
            />
            <Route
                path={`${match.url}/global-trade/:id/:action`}
                component={GlobalTradeUpdate}
            />
            <Route
                path={`${match.url}/contracts/:id/:action`}
                component={ContractsUpdate}
            />
            <Route
                path={`${match.url}/customer-global/:id/:action`}
                component={CustomerGlobalForm}
            />
            <Route
                path={`${match.url}/extend/release/:id`}
                component={ExtendReleaseChecklist}
            />

            <Route
                path={`${match.url}/partners/:id/:page`}
                component={MyTaskApolloPartners}
            />

            <Route path={`${match.url}/pricing`} component={Pricing} />
            <Route path={`${match.url}/contracts`} component={Contracts} />
            <Route path={`${match.url}/release`} component={Checklist} />
            <Route path={`${match.url}/customer-master`} component={CustomerMasterForm} />
            <Route
                path={`${match.url}/customer-master/salesforce`}
                component={CustomerMasterSalesForce}
            />
            <Route path={`${match.url}/credit-form`} component={CreditForm} />
            <Route path={`${match.url}/global-trade`} component={GlobalTradeForm} />
            <Route path={`${match.url}/task`} component={MyTasksForm} />
        </Switch>
    );
};
export default ApComp;
