import { Feather } from '@expo/vector-icons';
import React, { useState, useEffect, useRef } from 'react';
import { View, Image, StyleSheet, TextInput, TouchableOpacity, Text } from 'react-native';
import { useHistory } from 'react-router-dom';
import { Link, useLocation } from '../../navigation/router';
import { Colors } from '../../theme/Index';
import MenuDivider from '../MenuDivider';
import MenuItem from '../MenuItem';
import PopUpMenu from '../PopUpMenu';
import VyaireLogo from '../VyaireLogo';
import Flex from '../common/Flex';
import NavLink from './NavLink';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
class DropDownMenu extends React.Component {
    _menu = null;

    constructor(props) {
        super(props);
        this.state = {
            showing: false,
        };
    }

    setMenuRef = (ref) => {
        this._menu = ref;
        this._menu.hide();
    };

    hideMenu = () => {
        this._menu.hide();
    };
    onLogout = () => {
        this.hideMenu();
        this.props.onLogout();
    };

    showMenu = () => {
        if (this.state.showing === true) {
            this._menu.hide();
        } else {
            this._menu.show();
        }

        this.setState({ showing: !this.state.showing });
    };

    render() {
        return (
            <View style={styles.dropDownMainContainer}>
                <PopUpMenu
                    onHidden={() => this.setState({ showing: false })}
                    ref={this.setMenuRef}
                    button={
                        <TouchableOpacity onPress={this.showMenu}>
                            <Image
                                source={require('../../../assets/icons/user.png')}
                                style={styles.userIcon}
                            />
                        </TouchableOpacity>
                    }>
                    <MenuItem onPress={this.hideMenu}>
                        <View style={styles.settingsView}>
                            <Text style={styles.settingViewText}>
                                {this.props.currentUser.username}
                            </Text>
                        </View>
                    </MenuItem>
                    <MenuDivider />
                    <MenuItem onPress={this.onLogout}>
                        <View style={styles.settingsView}>
                            <Feather
                                style={styles.featherStyle}
                                name="log-out"
                                size={13}
                                color="#1e272e"
                            />
                            <Text style={styles.logoutText}>Logout</Text>
                        </View>
                    </MenuItem>
                </PopUpMenu>
            </View>
        );
    }
}

function useComponentVisible(initialIsVisible) {
    const [open, setOpen] = useState(initialIsVisible);
    const ref = useRef(null);

    const handleHideDropdown = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
            setOpen(false);
        }
    };

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', handleHideDropdown, true);
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('keydown', handleHideDropdown, true);
            document.removeEventListener('click', handleClickOutside, true);
        };
    });

    return { ref, open, setOpen };
}

export default function Header({ onMenuIconPress, currentUser, onLogout }) {
    const location = useLocation();
    const { ref, open, setOpen } = useComponentVisible(false);

    // const [open, setOpen] = React.useState(false);
    const history = useHistory();
    const handleClick = () => {
        setOpen(!open);
    };

    const handlePartnerNav = (event, id) => {
        setOpen(!open);
        history.push({
            pathname: `/` + id + `/partners`,
        });
    };

    let teams = JSON.parse(localStorage.getItem('teamIds'));
    let isSearchTeamUser =
        (teams && teams.includes(20) && teams.length == 1) ||
        (teams && teams.includes(99));
    let cmUserNotAssignedToTeam = teams && teams.length == 0;
    let visibleMyTask = cmUserNotAssignedToTeam ? false : !isSearchTeamUser;
    let visibleMyRequest = cmUserNotAssignedToTeam ? true : !isSearchTeamUser;
    // !isSearchTeamUser ? true :(cmUserNotAssignedToTeam ? true : false)
    var showBurger = window.location.pathname != '/' ? true : false;
    return (
        <View style={styles.container}>
            <Flex alignCenter>
                {showBurger && (
                    <TouchableOpacity onPress={onMenuIconPress}>
                        <Feather
                            name="menu"
                            size={34}
                            color="#10206C"
                            // color="grey"
                            style={styles.menuIcon}
                        />
                    </TouchableOpacity>
                )}

                <Link to="/">
                    <Flex
                        style={styles.logoContainer}
                        alignCenter
                        padding="12px 10px 0px">
                        <View>
                            <Image
                                resizeMode="contain"
                                style={{
                                    width: 200,
                                    height: 100,
                                }}
                                source={require('../../../assets/icons/logo_new1.png')}
                            />
                        </View>
                        <Text style={styles.mdmText}>MDM</Text>
                    </Flex>
                </Link>
            </Flex>
            <Flex justifyStart alignCenter>
                {location.pathname !== '/' && (
                    <>
                        <View
                            style={{
                                marginRight: 20,
                                flex: 1,
                                flexDirection: 'row',
                            }}>
                            <List
                                ref={ref}
                                style={{ marginTop: '-10px' }}
                                component="nav">
                                <ListItem button onClick={handleClick}>
                                    <ListItemText>
                                        <span
                                            style={{
                                                fontFamily: 'Poppins',
                                                fontSize: 16,
                                                color: '#12243F',
                                                fontWeight: 'bold',
                                            }}>
                                            {' '}
                                            PARTNERS{' '}
                                        </span>
                                    </ListItemText>
                                    {open ? <ExpandLess /> : <ExpandMore />}
                                </ListItem>
                                <Collapse in={open} timeout="auto" unmountOnExit>
                                    <List
                                        dense={true}
                                        style={{
                                            position: 'absolute',
                                            background: '#f0f0f07d',
                                            width: '100%',
                                        }}
                                        component="div"
                                        disablePadding>
                                        <ListItem
                                            id="ptmn"
                                            onClick={(event) =>
                                                handlePartnerNav(event, 'ptmn')
                                            }
                                            button
                                            className={styles.nested}>
                                            <ListItemText
                                                style={{
                                                    fontSize: '14px',
                                                    fontWeight: '600',
                                                }}
                                                primary="Pointman"
                                            />
                                        </ListItem>
                                        <ListItem
                                            id="apollo"
                                            disabled
                                            onClick={(event) =>
                                                handlePartnerNav(event, 'apollo')
                                            }
                                            button
                                            className={styles.nested}>
                                            <ListItemText
                                                style={{
                                                    fontSize: '14px',
                                                    fontWeight: '600',
                                                }}
                                                primary="Apollo"
                                            />
                                        </ListItem>
                                        <ListItem
                                            id="olympus"
                                            onClick={(event) =>
                                                handlePartnerNav(event, 'olympus')
                                            }
                                            button
                                            className={styles.nested}>
                                            <ListItemText
                                                style={{
                                                    fontSize: '14px',
                                                    fontWeight: '600',
                                                }}
                                                primary="Olympus"
                                            />
                                        </ListItem>
                                        <ListItem
                                            button
                                            id="jde"
                                            onClick={(event) =>
                                                handlePartnerNav(event, 'jde')
                                            }
                                            className={styles.nested}>
                                            <ListItemText
                                                style={{
                                                    fontSize: '14px',
                                                    fontWeight: '600',
                                                }}
                                                primary="JDE "
                                            />
                                        </ListItem>
                                        <ListItem
                                            disabled
                                            button
                                            className={styles.nested}>
                                            <ListItemText
                                                style={{
                                                    fontSize: '14px',
                                                    fontWeight: '600',
                                                }}
                                                primary="M2M"
                                            />
                                        </ListItem>
                                    </List>
                                </Collapse>
                            </List>
                            {visibleMyTask && (
                                <NavLink href="/my-tasks">MY TASKS</NavLink>
                            )}
                            {visibleMyRequest && (
                                <NavLink href="/my-requests">MY REQUESTS</NavLink>
                            )}
                        </View>
                        <View>
                            <Link to="/search">
                                <Image
                                    source={require('../../../assets/search.png')}
                                    style={styles.searchIcon}
                                />
                            </Link>
                        </View>
                    </>
                )}

                {/*<DropDownMenu currentUser={currentUser} onLogout={onLogout} />*/}
            </Flex>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        minHeight: 100,
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'row',
        backgroundColor: Colors.white,
        flexWrap: 'wrap',
        borderBottomColor: Colors.silver,
        borderBottomWidth: 1,
        shadowColor: Colors.dark,
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 4,

        elevation: 4,
    },
    logoContainer: {
        paddingTop: 5,
    },
    userIcon: {
        width: 58,
        height: 58,
        marginTop: 5,
        marginRight: 5,
        backgroundColor: Colors.white,
    },
    searchIcon: {
        width: 22.5,
        height: 22.5,
        marginTop: 5,
        marginRight: 15,
        backgroundColor: Colors.white,
    },
    menuIcon: {
        marginHorizontal: 25,
    },

    gearIcon: {
        width: 43,
        height: 43,
        marginTop: 2.5,
        marginRight: 5,
        backgroundColor: '#FFFFFF',
    },

    mdmText: {
        fontSize: 34,
        fontWeight: '400',
        marginLeft: 5,
        paddingBottom: 5,
        color: Colors.lightBlue,
    },
    settingsView: {
        flex: 1,
        flexBasis: 'auto',
        flexDirection: 'row',
        alignItems: 'center',
        minWidth: 150,
    },
    settingViewText: {
        color: '#1D4289',
        fontWeight: 'bold',
    },
    dropDownMainContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 15,
    },
    featherStyle: {
        marginLeft: 8,
        marginRight: 15,
        borderRadius: 25,
        borderColor: 'grey',
        borderWidth: 1,
        padding: 5,
    },
    logoutView: {
        flex: 1,
        flexBasis: 'auto',
        flexDirection: 'row',
        alignItems: 'center',
    },
    logoutText: {
        color: '#e74c3c',
    },
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: 'white',
        fontFamily: 'Poppins',
        fontSize: 16,
        fontWeight: 'bold',
        color: '#12243F',
    },
    nested: {
        paddingLeft: 4,
    },
    rootFont: {
        fontFamily: 'Poppins',
        fontSize: 16,
        fontWeight: 'bold',
        color: '#12243F',
    },
});
