/**
 * @prettier
 */
import { TextInput } from 'react-native-web';

import React from 'react';
import { ActivityIndicator, ScrollView, StyleSheet, View } from 'react-native';
import {
    DimensionAware,
    getWindowHeight,
    getWindowWidth,
} from 'react-native-dimension-aware';
import { connect } from 'react-redux';
import { StyledText } from '../../../theme/Index';
import { MaterialIcons } from '@expo/vector-icons';
import { Box, Button, Flex } from '../../../components/common';
import { FormInput, FormRadio, FormRadioGroup } from '../../../components/form';
import FilesList from '../../../components/FilesList.js';
import idx from 'idx';

import {
    normalizeName,
    normalizeStreet,
    validatePostalCode,
    yupAllFieldsValidation,
} from '../../../constants/utils';
import { SalesOrgType, SystemType } from '../../../constants/WorkflowEnums.js';
import GlobalMdmFields from '../../../components/DunsEditableGlobalMdmFields';
import { updateGlobalMDMFieldRules } from '../../../constants/FieldRules';
import { fetchCreateCustomerDropDownData } from '../../../redux/DropDownDatas';
import { fetchDuns } from '../../../appRedux/actions/Customer.js';
import { ajaxGetRequest } from '../../../appRedux/sagas/config';
import { getTaxJurisdictionData } from '../../../appRedux/actions/MyTasks';
import {
    getMdmMappingMatrix,
    updateDeltas,
} from '../../../appRedux/actions/UpdateFlowAction';
import { removeMessage } from '../../../appRedux/actions/Toast';
import FlashMessage, { FlashMessages } from '../../../components/FlashMessage';
import debounce from 'debounce';
import { GenerateWorkflowId } from '@env';
import { getStaticRequest } from '../../../appRedux/actions';

const postalCodes = require('../../../postal-codes-js');

const SystemValidValues = Object.keys(SystemType).map((index) => {
    const system = SystemType[index];
    return { id: index, description: system };
});

const SalesOrgValidValues = Object.keys(SalesOrgType).map((index) => {
    const system = SalesOrgType[index];
    return { id: index, description: system, value: system };
});

function merge(...schemas) {
    const [first, ...rest] = schemas;

    const merged = rest.reduce(
        (mergedSchemas, schema) => mergedSchemas.concat(schema),
        first
    );

    return merged;
}

const initFormdData = {
    SystemTypeId: 1,
};

const mapKeys = (obj, callback) => {
    const keys = Object.keys(obj);
    return keys.map((key, index) => callback(obj[key], key));
};

class Page extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            system: '',
            role: '',
            formData: {
                ...initFormdData,
                MarketingSegmentationTypeId: 4,
                WorkflowTitle: '',
            },
            updatedFormData: {},
            isDunsValid: true,
            dropDownDatas: {},
            taxUpdated: false,
            fetchingWorkflowId: false,
            fileErrors: {},
            selectedFiles: {},
            selectedFilesIds: [],
            files: [],
            dunsData: {},
            userId: localStorage.getItem('userId'),
            inputPropsForDefaultRules: {},
            fileinputkey: Date.now(),
        };

        this.fetchDunsFields = debounce(this.props.fetchDuns, 200);
    }

    generateWorkflowId() {
        const url = GenerateWorkflowId;

        ajaxGetRequest(url).then((res) => {
            if (res.IsSuccess)
                this.setState({
                    fetchingWorkflowId: false,
                    formData: {
                        ...initFormdData,
                        ...this.state.formData,
                        WorkflowId: res.ResultData,
                        UserId: this.state.userId,
                    },
                });
        });
    }

    componentDidMount() {
        const { id } = this.props.match.params;
        var postJson = {
            requestObj: { MdmNumber: id, userId: localStorage.getItem('userId') },
        };
        this.props.getMdmMappingMatrix(postJson);

        this.props.getStaticRequest([42, 43]);
        fetchCreateCustomerDropDownData().then((res) => {
            const data = res;
            this.setState({ dropDownDatas: data }, () => {
                this.generateWorkflowId();
            });
        });
    }

    componentWillReceiveProps(newProps = {}) {
        if (
            newProps.dunsFields != this.props.dunsFields &&
            newProps.dunsFields.length > 0
        ) {
            this.setState({
                formData: {
                    ...this.state.formData,
                    DunsNumber: newProps.dunsFields.DunsNumber,
                },
            });
        }

        if (newProps.mdmcustomerdata != this.props.mdmcustomerdata) {
            this.setState(
                {
                    formData: {
                        ...this.state.formData,
                        ...newProps.mdmcustomerdata.MDMGlobalData,
                        MdmNumber: this.props.match.params.id,
                    },
                },
                () => {
                    this.getTaxJuri(
                        validatePostalCode(
                            this.state.formData['Country'],
                            this.state.formData['PostalCode']
                        )
                    );

                    this.createDunsObj();
                }
            );
        }
        if (newProps.taxJuriData != this.props.taxJuriData) {
            const taxJuriData = newProps.taxJuriData || [];

            this.setState({
                formData: {
                    ...this.state.formData,
                    TaxJurisdiction:
                        taxJuriData.filter(
                            (taxJuri, index) =>
                                taxJuri ===
                                this.props.mdmcustomerdata.MDMGlobalData.TaxJurisdiction
                        )[0] || taxJuriData[0],
                },
                updatedFormData: {
                    ...this.state.updatedFormData,
                    TaxJurisdiction:
                        taxJuriData.filter(
                            (taxJuri, index) =>
                                taxJuri ===
                                this.props.mdmcustomerdata.MDMGlobalData.TaxJurisdiction
                        )[0] || taxJuriData[0],
                },
            });
        }
    }

    onDunsNewInput = (value, e) => {
        const { name } = e.target;
        this.fetchDunsFields({
            UserId: localStorage.getItem('userId'),
            DunsNumber: value,
        });
    };

    getTaxJuri = (isPostalCodeValid) => {
        let { userId, formData } = this.state;

        console.log(formData);
        try {
            const address = {
                Country: formData['Country'].trim(),
                Region: formData['Region'].trim(),
                PostalCode: isPostalCodeValid ? formData['PostalCode'].trim() : '',
                City: formData['City'].trim(),
            };

            if (this.validateAddress(address))
                this.props.getTaxJurisdictionData({ userId, ...address });
        } catch (error) {
            console.log(error);
            console.log('tax juri api/formdata call error');
        }
    };

    getAddress = (formData) => ({
        Country: idx(formData, (_) => _.Country) || '',
        Region: idx(formData, (_) => _.Region) || '',
        PostalCode: idx(formData, (_) => _.PostalCode) || '',
        City: idx(formData, (_) => _.City) || '',
    });

    validateAddress = (address) => {
        const adressesObj = this.getAddress(address);

        const obj = mapKeys(adressesObj, (value, key) => ({
            value,
            key,
        }));

        return obj.every(({ key, value }) => {
            return value.length > 0;
        });
    };

    shouldTaxJuriUpdate(prevFormData, formData) {
        const adressesObj = this.getAddress(formData);
        const prevAddress = this.getAddress(prevFormData);

        const obj = mapKeys(adressesObj, (value, key) => ({
            value,
            key,
        }));

        const addressUpdated = obj.some(({ key, value }) => {
            const prevValue = idx(prevFormData, (_) => _[key]);
            return (prevValue ? prevValue.trim() : '') !== value.trim();
        });

        const addressFilled = obj.every(({ key, value }) => {
            return value.length > 0;
        });

        if (addressFilled && this.state.taxUpdated === false) {
            this.setState({ taxUpdated: true });
            return true;
        } else if (
            this.props.taxJuriData &&
            this.props.taxJuriData.length > 0 &&
            addressUpdated &&
            !addressFilled
        ) {
            return true;
        }

        return addressFilled && addressUpdated;
    }

    onFieldChange = (val, e) => {
        const name = e.target.name;
        let value = name === 'Country' ? val.toUpperCase() : val;
        const { formData: prevFormData } = this.state;
        // other fields to uppercase
        var otherUpperCaseFields = ['city', 'postalcode', 'email'];
        value = otherUpperCaseFields.includes(name.toLowerCase())
            ? val.toUpperCase()
            : val;

        if (name === 'DunsNumber' && value.length > 0) {
            const { isDunsValid } = this.state;
            const {
                validity: { valid },
            } = e.target;

            if (valid !== isDunsValid)
                this.setState({
                    isDunsValid: valid,
                    formErrors: {
                        ...this.state.formErrors,
                        isDunsValid: !valid ? ' only numbers (0-9) are allowed.' : null,
                    },
                });
        }

        if (name === 'PostalCode') {
            if (value.length > 0) {
                const { isPostalCodeValid } = this.state;
                // const valid = validatePostalCode(this.state.formData['Country'], value);
                let valid = true;
                const result = postalCodes.validate(
                    this.state.formData['Country'],
                    value
                );
                if (result === false) {
                    //wrong postal code fmt
                    valid = false;
                } else {
                    //valid postal code. For specific countries ,would return the formatted string.
                    // Such cases set the value of form data to be result .
                    var type = typeof result;
                    if (type === 'string') {
                        value = result;
                        valid = true;
                    } else {
                        valid = true;
                    }
                }
                if (valid != isPostalCodeValid)
                    this.setState({
                        isPostalCodeValid: valid,
                        formErrors: {
                            ...this.state.formErrors,
                            isPostalCodeValid: !valid ? ' Invalid PostalCode' : null,
                        },
                    });
            } else if (
                value.length <= 0 &&
                this.state.formErrors &&
                this.state.formErrors['isPostalCodeValid']
            ) {
                this.setState({
                    isPostalCodeValid: !this.state.isPostalCodeValid,
                    formErrors: {
                        ...this.state.formErrors,
                        isPostalCodeValid: null,
                    },
                });
            }
        }
        // name field abbreviations & uppercase
        if (name.includes('Name')) {
            value = normalizeName(val);
        }

        if (name.includes('Street')) {
            value = normalizeStreet(val);
        }

        this.setState(
            (prevState, props) => {
                return {
                    formData: {
                        ...prevState.formData,
                        [name]: value,
                    },
                    updatedFormData: {
                        ...prevState.updatedFormData,
                        [name]: value,
                    },
                };
            },
            () => {
                const { formData, taxUpdated } = this.state;
                if (
                    name === 'RoleTypeId' ||
                    name === 'CategoryTypeId' ||
                    name === 'Telephone'
                ) {
                    this.validateRules(name, val);
                } else if (
                    name === 'Country' ||
                    name === 'Region' ||
                    name === 'PostalCode' ||
                    name === 'City' ||
                    name === 'Name1' ||
                    name === 'Street'
                ) {
                    this.createDunsObj(name, val);

                    if (name === 'Country') this.validateRules(name, val);

                    if (this.shouldTaxJuriUpdate(prevFormData, formData)) {
                        this.getTaxJuri(this.state.isPostalCodeValid);
                    }
                }
            }
        );
    };

    createDunsObj = (name = null, val = null) => {
        const {
            mdmcustomerdata: {
                MDMGlobalData: state = {},
                MDMMappingMatrix: mdmMappingMatrix = [],
                CreditData: mdmCreditData = [],
            },
        } = this.props;
        if (name === null || val === null) {
            this.setState({
                dunsData: {
                    Country: state.Country,
                    Name1: state.Name1,
                    City: state.City,
                    Region: state.Region,
                    Street: state.Street,
                    DunsNumber: state.DunsNumber,
                    SicCode4: state.SicCode4,
                    SicCode6: state.SicCode6,
                    SicCode8: state.SicCode8,
                    TaxNumber: state.TaxNumber,
                    VatRegNo: state.VatRegNo,
                    NaicsCode: state.NaicsCode,
                    NaicsCodeDescription: state.NaicsCodeDescription,
                },
            });
        } else {
            this.setState({
                dunsData: {
                    ...this.state.dunsData,
                    [name]: val,
                },
            });
        }
    };

    setFormDataValues = (name, value) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: value,
            },
        });
    };

    setInputPropsForDefaultRules = (field_name, property) => {
        this.setState({
            inputPropsForDefaultRules: {
                ...this.state.inputPropsForDefaultRules,
                [field_name]: property,
            },
        });
    };

    validateRules = (stateKey, stateVal) => {
        const readOnlyDropDown = { disabled: true };
        // check for SalesOrgTypeId
        if (stateKey === 'RoleTypeId' || stateKey === 'CategoryTypeId') {
            var categoryTypeidValue = this.state.formData['CategoryTypeId'];
            var roleTypeidValue = this.state.formData['RoleTypeId'];

            if (parseInt(roleTypeidValue, 10) === 5) {
                this.setFormDataValues('SalesOrgTypeId', 4);
                this.setInputPropsForDefaultRules('SalesOrgTypeId', readOnlyDropDown);
            } else {
                if (categoryTypeidValue === '4') {
                    this.setFormDataValues('SalesOrgTypeId', 1);
                    this.setInputPropsForDefaultRules('SalesOrgTypeId', readOnlyDropDown);
                } else if (
                    categoryTypeidValue === '1' ||
                    categoryTypeidValue === '2' ||
                    categoryTypeidValue === '3' ||
                    categoryTypeidValue === '6' ||
                    categoryTypeidValue === '7'
                ) {
                    this.setFormDataValues('SalesOrgTypeId', 2);
                    this.setInputPropsForDefaultRules('SalesOrgTypeId', readOnlyDropDown);
                } else {
                    this.setFormDataValues('SalesOrgTypeId', '');
                    this.setInputPropsForDefaultRules('SalesOrgTypeId', {
                        disabled: false,
                    });
                }
            }
        } else if (stateKey === 'Country') {
            if (stateVal === 'CA') {
                this.setFormDataValues('CompanyCodeTypeId', 2);
            } else {
                this.setFormDataValues('CompanyCodeTypeId', 1);
            }
            this.setInputPropsForDefaultRules('CompanyCodeTypeId', readOnlyDropDown);
        } else if (stateKey === 'Telephone') {
            // console.log(stateVal, this.state.formData['Telephone']);
            if (stateVal > 0) this.setFormDataValues('Telephone', stateVal.trim());
        }
    };

    setFormErrors = (errors) => {
        console.log(errors);
        const { formErrors } = this.state;
        this.setState({ formErrors: errors }, () => window.scrollTo(0, 0));
    };

    createDeltas = (origData, newData) => {
        let customerDataModel = {},
            customerElements = [],
            addedKeys = [];

        const ignoreKeys = [
            'SicCode',
            'NaicsCodeDescription',
            'SicCodeDescription',
            'IsEmptyResult',
        ];

        const dunsKeys = {
            TaxNumber: true,
            SicCode4: true,
            SicCode6: true,
            SicCode8: true,
            NaicsCode: true,
            SicCode: true,
            NaicsCodeDescription: true,
            SicCodeDescription: true,
            IsEmptyResult: true,
        };

        for (const key in newData) {
            console.log(key);
            if (key != 'WorkflowTitle') {
                //check if orig data & new data doesnt duplicate empty string or null
                let orgiDataValue =
                    origData[key] != null
                        ? origData[key].length != 0
                            ? origData[key].toString().trim()
                            : ''
                        : '';

                let newDataValue =
                    newData[key] != null
                        ? newData[key].length != 0
                            ? newData[key].toString().trim()
                            : ''
                        : '';

                if (
                    orgiDataValue != newDataValue &&
                    !addedKeys.includes(key) &&
                    !ignoreKeys.includes(key)
                ) {
                    let delta = {};
                    delta['name'] = key;
                    delta['originalValue'] = origData[key];
                    delta['updatedValue'] = newData[key];
                    if (
                        (dunsKeys[key] &&
                            origData['DunsNumber'].toString().trim() !==
                                newData['DunsNumber'].toString().trim()) ||
                        !dunsKeys[key]
                    ) {
                        console.log(key);
                        addedKeys.push(key);
                        customerElements.push(delta);
                    }
                }
            }
        }
        console.log(customerElements);
        if (customerElements.length <= 0) {
            customerDataModel = {};
            this.setState({ noDeltaUpdates: true }, () => window.scrollTo(0, 0));
            setTimeout(
                function () {
                    this.setState({ noDeltaUpdates: false });
                }.bind(this),
                1000
            );
        } else {
            customerDataModel['customerElements'] = customerElements;
        }

        const updatedValues = customerDataModel.customerElements;

        if (
            updatedValues &&
            updatedValues.length <= 1 &&
            updatedValues[0].name === 'Purpose'
        ) {
            this.setFormErrors({ ...this.state.errors, Purpose: '' });
        }

        return customerDataModel;
    };

    proceedAction = () => {
        const { history } = this.props;

        let dunsDataModel = {};

        const {
            mdmcustomerdata: {
                MDMGlobalData: customerData = {},
                MDMMappingMatrix: mdmMappingMatrix = [],
                CreditData: mdmCreditData = [],
            },
        } = this.props;

        const { selectedFilesIds, selectedFiles, formData, userId } = this.state;

        let customerDataModel = this.createDeltas(
            customerData,
            this.state.updatedFormData
        );

        if (!this.props.dunsFields.IsEmptyResult) {
            const {
                TaxNumber,
                VatRegNo,
                DunsNumber,
                SicCode,
                SicCode4,
                SicCode6,
                SicCode8,
                NaicsCode,
            } = this.props.mdmcustomerdata.MDMGlobalData;

            dunsDataModel = this.createDeltas(
                {
                    TaxNumber,
                    VatRegNo,
                    DunsNumber,
                    SicCode,
                    SicCode4,
                    SicCode6,
                    SicCode8,
                    NaicsCode,
                },
                this.props.dunsFields
            );
            if (!this.isEmpty(customerDataModel) && !this.isEmpty(dunsDataModel))
                customerDataModel.customerElements.push(
                    ...dunsDataModel.customerElements
                );
            else if (this.isEmpty(customerDataModel) && !this.isEmpty(dunsDataModel))
                customerDataModel['customerElements'] = dunsDataModel.customerElements;
        }

        let data = {
            userId: userId,
            workflowId: formData.WorkflowId,
            mdmCustomerId: formData.MdmNumber,
            WorkflowTitle: formData.WorkflowTitle,
            CustomerName: customerData.Name1,
            WorkflowType: 21,
            SystemTypeId: 99,
            RoleTypeId: 99,
            IsSaveToWorkflow: true,
            DUNSData: this.props.dunsFields || this.state.dunsData,
            customerDataModel,
        };
        let postData = {
            data,
            action: 'update',
            files: selectedFilesIds.map((id) => selectedFiles[id]),
            history,
        };

        if (!this.isEmpty(customerDataModel) && this.state.formErrors['Purpose'] !== '')
            this.props.updateDeltas(postData);
    };

    onSubmit = (event, schema, IsSaveToWorkflow) => {
        let { formData, updatedFormData, selectedFilesIds, selectedFiles } = this.state;
        const { Category, ...data } = formData;
        let fileErrors = {};
        let errors = false;

        selectedFilesIds.map((id) => {
            if (selectedFiles[id] && selectedFiles[id].DocumentType <= 0) {
                fileErrors[id] = 'Document Type Required for file';
                errors = true;
            }
        });

        this.setState({ fileErrors, isFileErrors: errors });
        this.setState(
            {
                formData: {
                    ...data,
                    IsSaveToWorkflow,
                },
            },
            () => {
                // console.log('updatedFormData', this.state.updatedFormData);
                if (!this.isEmpty(this.state.updatedFormData)) {
                    yupAllFieldsValidation(
                        { ...updatedFormData, TaxJurisdiction: ' ' },
                        updateGlobalMDMFieldRules,
                        (...rest) => {
                            if (this.state.isFileErrors === false)
                                this.proceedAction(...rest);
                        },
                        this.setFormErrors
                    );
                } else {
                    this.setState({ noDeltaUpdates: true }, () => window.scrollTo(0, 0));
                    setTimeout(
                        function () {
                            this.setState({ noDeltaUpdates: false });
                        }.bind(this),
                        1000
                    );
                }
            }
        );
    };

    isEmpty = (data) => {
        let result = false;
        if (typeof data === 'string') {
            return !data || 0 === data.length;
        }
        if (typeof data === 'object') {
            for (var key in data) {
                if (data.hasOwnProperty(key)) return false;
            }
            return true;
        }
        return result;
    };

    selectFile = (events) => {
        event.preventDefault();

        const { selectedFilesIds, selectedFiles } = this.state;
        const id = events.target.files[0].name;

        this.setState({
            selectedFiles: {
                ...selectedFiles,
                [id]: {
                    data: events.target.files[0],
                    DocumentName: events.target.files[0].name,
                    DocumentType: 0,
                },
            },
            selectedFilesIds: [...selectedFilesIds, id],
            filename: events.target.files[0].name,
            fileinputkey: Date.now(),
        });
    };

    removeFile = (id) => {
        event.preventDefault();

        var selectedFilesDict = this.state.selectedFiles;
        var selectedFilesIdsList = this.state.selectedFilesIds;
        // remove the existing state
        delete selectedFilesDict[id];
        selectedFilesIdsList = selectedFilesIdsList.filter((fileid) => fileid !== id);

        this.setState({
            selectedFiles: selectedFilesDict,
            selectedFilesIds: selectedFilesIdsList,
        });
    };

    render() {
        const { width } = this.props;
        const { dropDownDatas, formData, selectedFilesIds, selectedFiles } = this.state;
        let disable_submit =
            this.state.formData['TaxJurisdiction'] === undefined ||
            this.state.formData['TaxJurisdiction'].length === 0;
        if (
            formData['Country'] &&
            formData['Country'].toLowerCase().trim() != 'us' &&
            formData['Country'].toLowerCase() != 'ca'
        ) {
            disable_submit = false;
        }

        if (
            this.state.fetchingWorkflowId === true ||
            this.props.fetching ||
            !this.state.formData
        )
            return (
                <Box
                    display="flex"
                    flex={1}
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                    minHeight="650px"
                    bg="#eff3f6">
                    <FlashMessages
                        toasts={this.props.toasts}
                        onDismiss={this.props.removeMessage}
                    />
                    <View>
                        <ActivityIndicator size="large" />
                    </View>
                </Box>
            );

        return (
            <ScrollView
                keyboardShouldPersistTaps="always"
                style={{
                    backgroundColor: '#eff3f6',
                    paddingTop: 50,
                    paddingBottom: 75,
                }}>
                {this.state.noDeltaUpdates && (
                    <FlashMessage
                        animate
                        bg={{ backgroundColor: '#2980b9' }}
                        message={'No Updates Found, Cannot Submit a Request '}
                    />
                )}
                <FlashMessages
                    toasts={this.props.toasts}
                    onDismiss={this.props.removeMessage}
                />
                <View
                    style={{
                        flex: 1,
                        paddingHorizontal: width < 1440 ? 75 : width * 0.1,
                        paddingBottom: 10,
                    }}>
                    <Box fullHeight my={2}>
                        <Box
                            flexDirection="row"
                            justifyContent="space-around"
                            my={4}
                            alignItems="center">
                            <FormInput
                                padding="8px 25px 0px 25px"
                                style={{ lineHeight: '2', paddingBottom: 0 }}
                                flex={1 / 4}
                                mb={2}
                                required
                                onChange={this.onFieldChange}
                                error={
                                    this.state.formErrors
                                        ? this.state.formErrors['WorkflowTitle']
                                        : null
                                }
                                value={formData.WorkflowTitle || ''}
                                label="Workflow Title"
                                name="WorkflowTitle"
                                maxLength={40}
                            />
                            <FormInput
                                px="25px"
                                flex={1 / 4}
                                label="Workflow Number"
                                name="WorkflowId"
                                style={{ lineHeight: '2' }}
                                variant="outline"
                                type="text"
                                value={formData.WorkflowId || ''}
                                disabled
                            />
                            <FormInput
                                px="25px"
                                flex={1 / 4}
                                label="MDM Number"
                                name="mdm-number"
                                value={formData.MdmNumber || ''}
                                style={{ lineHeight: '2' }}
                                variant="outline"
                                type="text"
                                disabled
                            />
                        </Box>
                        <TextInput
                            autoComplete="off"
                            name="hidden"
                            type="text"
                            style={{ display: 'none' }}
                        />
                        <GlobalMdmFields
                            formData={{
                                ...this.state.formData,
                                ...this.props.dunsFields,
                                DunsNumber:
                                    this.state.formData.DunsNumber !== undefined
                                        ? this.state.formData.DunsNumber
                                        : idx(
                                              this.props,
                                              (_) =>
                                                  _.mdmcustomerdata.MDMGlobalData
                                                      .DunsNumber
                                          ),
                            }}
                            staticDropDownData={this.props.staticData}
                            formErrors={this.state.formErrors}
                            editableInputs={{ TaxNumber: true, VatRegNo: true }}
                            countryNotEditable
                            dunsEditable
                            onDunsNewInput={this.onDunsNewInput}
                            onFieldChange={this.onFieldChange}>
                            <FormRadioGroup
                                value={
                                    this.state.formData['TaxJurisdiction'] ||
                                    this.props.taxJuriData[0]
                                }
                                onChange={this.onFieldChange}
                                error={
                                    this.state.formErrors
                                        ? this.state.formErrors['TaxJurisdiction']
                                        : null
                                }
                                disabled={this.props.taxJuriData.length <= 0}
                                name="TaxJurisdiction"
                                label="Tax Jurisdiction: ">
                                {this.props.taxJuriData.length > 0 ? (
                                    this.props.taxJuriData.map((taxJuri, index) => (
                                        <FormRadio
                                            key={`taxjuri-${index}`}
                                            value={taxJuri}
                                        />
                                    ))
                                ) : (
                                    <FormRadio
                                        key={1}
                                        value={this.state.formData['TaxJurisdiction']}
                                    />
                                )}
                            </FormRadioGroup>
                        </GlobalMdmFields>

                        <Box mt={2} flexDirection="row" justifyContent="center">
                            <Box width={0.79} mx="auto" alignItems="center">
                                <FormInput
                                    maxWidth="98%"
                                    label="Purpose of Request"
                                    style={{ height: '100px' }}
                                    name="Purpose"
                                    team="global"
                                    value={this.state.formData.Purpose || ''}
                                    maxLength={1000}
                                    multiline
                                    numberOfLines={4}
                                    onChange={this.onFieldChange}
                                />
                            </Box>
                        </Box>

                        <FilesList
                            formErrors={this.state.fileErrors}
                            files={selectedFilesIds.map((id) => selectedFiles[id])}
                            onChange={(value, id) => {
                                this.setState({
                                    selectedFiles: {
                                        ...selectedFiles,
                                        [id]: {
                                            ...selectedFiles[id],
                                            DocumentType: parseInt(value),
                                        },
                                    },
                                });
                            }}
                            onRemove={(value, id) => this.removeFile(id)}
                        />
                    </Box>

                    <Flex
                        justifyEnd
                        alignCenter
                        style={{
                            paddingTop: 65,
                            flexDirection: 'row',
                            alignItems: 'center',
                            paddingLeft: 10,
                            paddingRight: 15,
                            marginTop: 20,
                            marginBottom: 10,
                            marginHorizontal: 25,
                        }}>
                        <label htmlFor="file-upload" className="custom-file-upload">
                            <MaterialIcons name="attach-file" size={18} color="#fff" />
                        </label>
                        <input
                            id="file-upload"
                            key={this.state.fileinputkey}
                            type="file"
                            onChange={this.selectFile}
                        />

                        <Button
                            onPress={() => this.props.history.goBack()}
                            title="Cancel"
                        />
                        <Button
                            disabled={!this.state.isDunsValid || disable_submit}
                            onPress={(e) => {
                                this.setState({ formErrors: {} }, () => this.onSubmit());
                            }}
                            title="Submit"
                        />
                    </Flex>
                </View>
            </ScrollView>
        );
    }
}

class Default extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const props = this.props;

        return (
            <DimensionAware
                render={(dimensions) => (
                    <Page
                        {...{
                            ...props,
                            width: getWindowWidth(dimensions),
                            height: getWindowHeight(dimensions),
                            marginBottom: 25,
                        }}
                    />
                )}
            />
        );
    }
}

const styles = StyleSheet.create({
    progressIndicator: {
        flex: 1,
        paddingBottom: 5,
        flexDirection: 'row-reverse',
        alignItems: 'flex-end',
    },
    statusText: {
        fontSize: 15,
        color: '#1D4289',
        fontFamily: 'Poppins',
        textAlign: 'center',
        marginTop: 20,
    },
});

const mapStateToProps = ({ customer, toasts, updateFlow, workflows, staticData }) => {
    const { taxJuriData } = customer;
    const { dunsFields = {} } = workflows;
    const { mdmcustomerdata, fetching: fetchingupdateFlow } = updateFlow;
    const { data, fetching: staticFetching } = staticData;

    return {
        mdmcustomerdata,
        fetching: fetchingupdateFlow || staticFetching,
        taxJuriData,
        toasts,
        dunsFields,
        staticData: data,
    };
};

export default connect(mapStateToProps, {
    updateDeltas,
    getTaxJurisdictionData,
    removeMessage,
    getMdmMappingMatrix,
    fetchDuns,
    getStaticRequest,
})(Default);
