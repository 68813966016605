import {
    GET_MDM_MAPPING_MATRIX,
    SET_MDM_MAPPING_MATRIX,
    UPDATE_DELTAS,
    UPDATE_DELTAS_STATUS,
    UPDATE_EXTEND_SALES_ORG,
    SAVE_APOLLO_UPDATE_CONTRACTS,
    SAVE_APOLLO_UPDATE_CREDIT,
    SAVE_APOLLO_UPDATE_PRICING,
    SAVE_APOLLO_UPDATE_GLOBALTRADE,
    SAVE_APOLLO_UPDATE_CUSTOMER_MASTER,
    SAVE_APOLLO_UPDATE_GLOBAL,
    SHOW_MESSAGE,
    HIDE_MESSAGE,
    READ_REDSHIFT_REQUEST,
    READ_REDSHIFT_SUCCESS,
    REQUEST_BLOCK,
    REQUEST_BLOCK_SUCCESS,
    FETCH_MDM_PARENT_DATA_DUNS,
    FETCH_MDM_PARENT_DATA_DUNS_RESULT,
} from '../../constants/ActionTypes';
import Immutable from 'seamless-immutable';

const INITIAL_STATE = {
    mdmcustomerdata: [],
    fetching: false,
    alert: { display: false, message: '', color: '#FFF' },
    mdm_parentdata_duns: {},
    loadingParentDuns: false,
};

const normalize = (arr) => {
    const reducer = (accumulator, currentValue) => {
        accumulator[currentValue.Name] = currentValue;

        return accumulator;
    };

    return arr.reduce(reducer, {});
};

const updateFlowReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_MDM_MAPPING_MATRIX: {
            return {
                ...state,
                fetching: true,
                loadingParentDuns: true,
                mdmcustomerdata: [],
                mdm_parentdata_duns: {},
            };
        }
        case SET_MDM_MAPPING_MATRIX: {
            let mdmcustdataObj = action.payload;
            let mdm_mappings = mdmcustdataObj.MDMMappingMatrix;
            let hasApolloCustomers = mdm_mappings.filter(
                (mdm_mapping) => mdm_mapping.SystemTypeId === 1
            );
            mdmcustdataObj['hasApolloCustomers'] =
                hasApolloCustomers.length > 0 ? true : false;
            return {
                ...state,
                fetching: false,
                mdmcustomerdata: mdmcustdataObj,
            };
        }
        case FETCH_MDM_PARENT_DATA_DUNS_RESULT: {
            return {
                ...state,
                loadingParentDuns: false,
                mdm_parentdata_duns: action.payload || {},
            };
        }
        case READ_REDSHIFT_REQUEST: {
            return {
                ...state,
                fetching: true,
            };
        }
        case READ_REDSHIFT_SUCCESS: {
            return {
                ...state,
                fetching: false,
                customerData: action.payload.data,
                deltas: normalize(action.payload.deltas),
                denormalizedDeltas: action.payload.deltas,
                WorkflowDocuments: action.payload.WorkflowDocuments,
            };
        }
        case UPDATE_EXTEND_SALES_ORG: {
            return {
                ...state,
                fetching: true,
            };
        }
        case UPDATE_DELTAS: {
            return {
                ...state,
                fetching: true,
            };
        }
        case UPDATE_DELTAS_STATUS: {
            return {
                ...state,
                fetching: false,
                alert: {
                    display: true,
                    message: action.payload.msg,
                    color: action.payload.color,
                },
            };
        }

        case SAVE_APOLLO_UPDATE_CUSTOMER_MASTER: {
            return {
                ...state,
                fetching: true,
                readOnly: undefined,
                success: action.payload.success,
            };
        }
        case REQUEST_BLOCK:
        case SAVE_APOLLO_UPDATE_GLOBAL:
        case SAVE_APOLLO_UPDATE_CONTRACTS:
        case SAVE_APOLLO_UPDATE_CREDIT:
        case SAVE_APOLLO_UPDATE_PRICING:
        case SAVE_APOLLO_UPDATE_GLOBALTRADE: {
            return {
                ...state,
                fetching: true,
            };
        }
        case SHOW_MESSAGE: {
            return {
                ...state,
                fetching: false,
                readOnly: action.payload.readOnly || undefined,
                alert: {
                    display: true,
                    message: action.payload.msg,
                    color: action.payload.color,
                },
            };
        }
        case HIDE_MESSAGE: {
            return {
                ...state,
                alert: { display: false, message: '', color: '#FFF' },
            };
        }

        default:
            return state;
    }
};

export default updateFlowReducer;
