import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import CustomerMaster from './CustomerMaster';
import ContractsForm from './ContractsForm';
import GlobalTradeForm from './GlobalTradeForm';
import CreditForm from './CreditForm';
import PricingForm from './PricingForm';
import OtherRequest from './OtherRequest';
import MyTaskJdePartners from './MyTaskJdePartners';

const JdeComp = ({ match }) => {
    console.log('match: ', match.url);
    return (
        <Switch>
            {/* <Route exact path={`${match.url}/credit-form/:id`} component={CreditForm} /> */}
            <Route
                path={`${match.url}/customer-master/:id/other-request`}
                component={OtherRequest}
            />
            <Route
                exact
                path={`${match.url}/customer-master/:id`}
                component={CustomerMaster}
            />
            <Route exact path={`${match.url}/pricing/:id`} component={PricingForm} />
            <Route exact path={`${match.url}/credit-form/:id`} component={CreditForm} />
            <Route
                exact
                path={`${match.url}/global-trade/:id`}
                component={GlobalTradeForm}
            />
            <Route exact path={`${match.url}/contracts/:id`} component={ContractsForm} />
            <Route
                path={`${match.url}/partners/:id/:page`}
                component={MyTaskJdePartners}
            />
        </Switch>
    );
};
export default JdeComp;
