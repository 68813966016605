import React from 'react';
import { ScrollView, Text, TouchableOpacity, View } from 'react-native';
import {
    DimensionAware,
    getWindowHeight,
    getWindowWidth,
} from 'react-native-dimension-aware';
import { connect } from 'react-redux';

import { removeMessage } from '../appRedux/actions';
import { advanceSearchCustomer } from '../appRedux/actions/Customer';
import CountryRegionSelector from '../components/CountryRegionSelector';
import FlashMessage, { FlashMessages } from '../components/FlashMessage';
import Loading from '../components/Loading';
import { Column, Flex, Card, Button, Box } from '../components/common';
import { CountryDropdown, RegionDropdown } from '../components/form';
import FormInput from '../components/form/FormInput';
import { Colors } from '../theme/Index';

class Page extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: {},
            mdmDisabled: false,
            customerDisabled: false,
            workFlowDisabled: false,
            remainderDisabled: false,
            loading: this.props.fetching,
        };
    }

    componentWillReceiveProps(newProps) {
        // if (newProps.searchResult != this.props.searchResult) {
        //     this.props.history.push({
        //         pathname: `/search/results`,
        //         state: newProps.searchResult,
        //     });
        // }
        if (newProps.fetching != this.props.fetching) {
            if (newProps.fetching) {
                this.setState({
                    workFlowDisabled: false,
                    mdmDisabled: false,
                    customerDisabled: false,
                    remainderDisabled: false,
                });
            }
            this.setState({
                loading: newProps.fetching,
            });
        }
    }

    onFieldChange = (value, e) => {
        const { formData } = this.state;
        const { name } = e.target;
        const keys = [
            'Name',
            'Street',
            'city',
            'State',
            'Zip',
            'Country',
            'DunsNumber',
            'TaxIDOrVATRegNumber',
            'FamilyId',
            'FamilyName',
        ];
        if (name === 'DunsNumber' && value.length > 0) {
            const { isDunsValid } = this.state;
            const {
                validity: { valid },
            } = e.target;

            if (valid !== isDunsValid)
                this.setState({
                    isDunsValid: valid,
                    formErrors: {
                        ...this.state.formErrors,
                        isDunsValid: !valid ? ' only numbers (0-9) are allowed.' : null,
                    },
                });
        }
        formData[name] = value;
        const ids = ['workflowid', 'mdmNumber', 'SystemRecordId'];

        const touched = keys.some((key) => formData[key] && formData[key].length > 0);

        const idsTouched = ids.some((key) => formData[key] && formData[key].length > 0);

        const anyTouched = touched || idsTouched;

        if (anyTouched) {
            if (idsTouched) {
                this.setState({
                    workFlowDisabled: name === ids[1] || name === ids[2],
                    mdmDisabled: name === ids[0] || name === ids[2],
                    customerDisabled: name === ids[0] || name === ids[1],
                    remainderDisabled: true,
                });
            } else {
                this.setState({
                    workFlowDisabled: true,
                    mdmDisabled: true,
                    customerDisabled: true,
                    remainderDisabled: false,
                });
            }
        } else {
            this.setState({
                workFlowDisabled: false,
                mdmDisabled: false,
                customerDisabled: false,
                remainderDisabled: false,
            });
        }

        this.setState({
            formData: {
                ...this.state.formData,
                [name]: value,
            },
        });
    };

    onSubmit = () => {
        window.scrollTo(0, 0);

        const keys = [
            'Name',
            'Street',
            'city',
            'State',
            'Zip',
            'Country',
            'DunsNumber',
            'TaxIDOrVATRegNumber',
            'FamilyId',
            'FamilyName',
        ];

        let {
            formData = {
                Name: 'name',
                Street: 'street',
                City: null,
                State: null,
                Zip: null,
                Country: null,
                DunsNumber: null,
                TaxIDOrVATRegNumber: null,
                FamilyId: null,
                FamilyName: null,
            },
        } = this.state;

        try {
            let searchModel = {
                CustomerSearchType: 3,
                CustomerMasterSearchHits: {
                    from: 0,
                    size: 10,
                },
                WorkflowSearchHits: {
                    from: 0,
                    size: 10,
                },
            };

            var postData = {
                ...searchModel,
                ...formData,
            };

            if (formData['workflowid'] && formData['workflowid'].length > 0) {
                postData.CustomerSearchType = 1;
                postData['mdmNumber'] = null;
                postData['SystemRecordId'] = null;
            } else if (formData['mdmNumber'] && formData['mdmNumber'].length > 0) {
                postData.CustomerSearchType = 2;
                postData['workflowid'] = null;
                postData['SystemRecordId'] = null;
            } else if (
                formData['SystemRecordId'] &&
                formData['SystemRecordId'].length > 0
            ) {
                postData.CustomerSearchType = 4;
                postData['mdmNumber'] = null;
                postData['workflowid'] = null;
            } else {
                postData.CustomerSearchType = 3;
                postData['mdmNumber'] = null;
                postData['workflowid'] = null;
                postData['SystemRecordId'] = null;
            }
            this.setState({ formData: {} }, () =>
                this.props.advanceSearchCustomer(postData, this.props.history)
            );

            // this.resetForm();
        } catch (error) {
            console.log('form validtion error');
        }
    };

    render() {
        const {
            mdmDisabled,
            customerDisabled,
            workFlowDisabled,
            remainderDisabled,
        } = this.state;
        const { width, height, marginBottom, location } = this.props;
        const { state } = location;
        const customer = state;

        let teams = JSON.parse(localStorage.getItem('teamIds'));
        //Other Request blocked for search team users in CM system or in AD groups
        let isSearchTeamUser =
            (teams && teams.includes(20) && teams.length == 1) ||
            (teams && teams.includes(99));
        let cmUserNotAssignedToTeam = teams && teams.length == 0;
        let visibleOtherRequest = cmUserNotAssignedToTeam ? true : !isSearchTeamUser;

        if (this.state.loading) {
            return <Loading />;
        }

        const readOnly = {
            inline: false,
            disabled: true,
            readOnly: true,
        };

        const editable = {
            inline: false,
            readOnly: false,
            onChange: this.onFieldChange,
            onSubmit: this.onSubmit,
            onSubmitEditing: this.onSubmit,
        };

        return (
            <ScrollView
                keyboardShouldPersistTaps="always"
                style={{
                    backgroundColor: '#EFF3F6',
                }}>
                <Box m="10px 0 0 5%">
                    <Text
                        style={{
                            fontFamily: 'Poppins',
                            fontWeight: '500',
                            fontSize: 24,
                            color: '#10206C',
                            marginBottom: 20,
                            paddingLeft: 45,
                            marginLeft: '7%',
                        }}>
                        ADVANCED SEARCH
                    </Text>
                </Box>
                <View
                    style={{
                        // flex: 1,
                        paddingHorizontal: width < 1440 ? 75 : width * 0.1,
                    }}>
                    <Flex
                        alignCenter
                        style={{
                            paddingBottom: 15,
                            flexDirection: 'row',
                            alignItems: 'center',
                            paddingLeft: 10,
                            paddingRight: 15,
                            marginBottom: 10,
                            marginHorizontal: 25,
                            marginLeft: '10%',
                        }}>
                        <Button
                            disabled={!visibleOtherRequest}
                            onPress={(event) =>
                                this.props.history.push(`/advance-search/other-request`)
                            }
                            title="Other Request"
                        />
                    </Flex>
                </View>
                <View
                    style={{
                        // flex: 1, Removed space for the new chrome update
                        paddingHorizontal: width < 1440 ? 75 : width * 0.1,
                        height: '100vh',
                    }}>
                    <Box my="15px" flexDirection="row" justifyContent="center">
                        <Box width={1 / 2} mx="auto" alignItems="center">
                            <FormInput
                                onChange={this.onFieldChange}
                                value={this.state.formData['workflowid']}
                                disabledPadding
                                inputWidth="inherit"
                                name="workflowid"
                                label="Workflow Id"
                                my={1}
                                {...(workFlowDisabled ? readOnly : editable)}
                            />
                            <FormInput
                                onChange={this.onFieldChange}
                                value={this.state.formData['mdmNumber']}
                                disabledPadding
                                inputWidth="inherit"
                                name="mdmNumber"
                                label="MDM Number"
                                my={1}
                                {...(mdmDisabled ? readOnly : editable)}
                                autoComplete="off"
                            />
                            <FormInput
                                onChange={this.onFieldChange}
                                value={this.state.formData['SystemRecordId']}
                                disabledPadding
                                inputWidth="inherit"
                                name="SystemRecordId"
                                label="Customer Number"
                                my={1}
                                {...(customerDisabled ? readOnly : editable)}
                                autoComplete="off"
                            />
                            <FormInput
                                onChange={this.onFieldChange}
                                value={this.state.formData['DunsNumber']}
                                disabledPadding
                                inputWidth="inherit"
                                name="DunsNumber"
                                error={
                                    this.state.formErrors
                                        ? this.state.formErrors['isDunsValid']
                                        : null
                                }
                                label="DUNS Number"
                                my={1}
                                {...(remainderDisabled ? readOnly : editable)}
                            />
                            <FormInput
                                onChange={this.onFieldChange}
                                value={this.state.formData['TaxIDOrVATRegNumber']}
                                disabledPadding
                                inputWidth="inherit"
                                name="TaxIDOrVATRegNumber"
                                label="Tax ID/ VAT Reg No:"
                                my={1}
                                {...(remainderDisabled ? readOnly : editable)}
                            />
                            {/*<FormInput*/}
                            {/*    onChange={this.onFieldChange}*/}
                            {/*    value={this.state.formData['FamilyId']}*/}
                            {/*    disabledPadding*/}
                            {/*    inputWidth="inherit"*/}
                            {/*    name="FamilyId"*/}
                            {/*    label="Family Id"*/}
                            {/*    my={1}*/}
                            {/*    {...(remainderDisabled ? readOnly : editable)}*/}
                            {/*/>*/}
                            {/*<FormInput*/}
                            {/*    onChange={this.onFieldChange}*/}
                            {/*    value={this.state.formData['FamilyName']}*/}
                            {/*    disabledPadding*/}
                            {/*    inputWidth="inherit"*/}
                            {/*    name="FamilyName"*/}
                            {/*    label="Family Name"*/}
                            {/*    my={1}*/}
                            {/*    {...(remainderDisabled ? readOnly : editable)}*/}
                            {/*/>*/}
                        </Box>
                        <Box width={1 / 2} mx="auto" alignItems="center">
                            <FormInput
                                onChange={this.onFieldChange}
                                value={this.state.formData['Name']}
                                disabledPadding
                                inputWidth="inherit"
                                name="Name"
                                label="Name"
                                my={1}
                                {...(remainderDisabled ? readOnly : editable)}
                                autoComplete="off"
                            />
                            <FormInput
                                onChange={this.onFieldChange}
                                value={this.state.formData['street']}
                                disabledPadding
                                inputWidth="inherit"
                                name="street"
                                label="Street"
                                my={1}
                                {...(remainderDisabled ? readOnly : editable)}
                                autoComplete="off"
                            />
                            <FormInput
                                onChange={this.onFieldChange}
                                value={this.state.formData['city']}
                                disabledPadding
                                inputWidth="inherit"
                                name="city"
                                label="City"
                                my={1}
                                {...(remainderDisabled ? readOnly : editable)}
                                autoComplete="off"
                            />
                            <CountryRegionSelector
                                label="Country"
                                name="Country"
                                team="global"
                                autoComplete="off"
                                value={this.state.formData['Country']}
                                selectorType="Country"
                                upperCase
                                onFieldChange={this.onFieldChange}
                                {...(remainderDisabled ? readOnly : editable)}
                            />
                            <CountryRegionSelector
                                Country={this.state.formData['Country']}
                                label="State"
                                name="State"
                                team="global"
                                inline={false}
                                readOnly={false}
                                selectorType="region"
                                value={this.state.formData['State']}
                                onFieldChange={this.onFieldChange}
                                upperCase
                                autoComplete="off"
                                {...(remainderDisabled ? readOnly : editable)}
                            />
                            <FormInput
                                onChange={this.onFieldChange}
                                value={this.state.formData['Zip']}
                                disabledPadding
                                inputWidth="inherit"
                                name="Zip"
                                label="Zip Code"
                                my={1}
                                {...(remainderDisabled ? readOnly : editable)}
                                autoComplete="off"
                            />
                        </Box>
                    </Box>
                    <Flex
                        justifyEnd
                        alignCenter
                        style={{
                            paddingTop: 15,
                            flexDirection: 'row',
                            alignItems: 'center',
                            paddingLeft: 10,
                            paddingRight: 15,
                            marginTop: 20,
                            marginBottom: 10,
                            marginHorizontal: 25,
                        }}>
                        <Button onPress={(event) => this.onSubmit()} title="Submit" />
                        <Button
                            title="Cancel"
                            onPress={() => this.props.history.goBack()}
                        />
                    </Flex>
                </View>
            </ScrollView>
        );
    }
}

class Default extends React.Component {
    render() {
        const props = this.props;

        return (
            <View>
                <DimensionAware
                    render={(dimensions) => (
                        <Page
                            {...{
                                ...props,
                                width: getWindowWidth(dimensions),
                                height: getWindowHeight(dimensions),
                                marginBottom: 25,
                            }}
                        />
                    )}
                />
            </View>
        );
    }
}

const mapStateToProps = ({ customer }) => {
    const { searchResult, customerdata, fetching, alert } = customer;
    return { searchResult, customerdata, fetching, alert };
};

export default connect(mapStateToProps, {
    advanceSearchCustomer,
    removeMessage,
})(Default);
