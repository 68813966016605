import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import CustomerMaster from './CustomerMaster';
import CreditForm from './CreditForm';
import ContractsForm from './ContractsForm';
import PricingForm from './PricingForm';
import GlobalTradeForm from './GlobalTradeForm';
import UpdateCustomerMaster from './Update/CustomerMaster';
import UpdateContractsForm from './Update/ContractsForm';
import UpdateCreditForm from './Update/CreditForm';
import UpdatePricingForm from './Update/PricingForm';
import UpdateGlobalTradeForm from './Update/GlobalTradeForm';
import OtherRequest from './OtherRequest';
import MyTaskOlympusPartners from './MyTaskOlympusPartners';
{
    /* <Route path={`${match.url}/`} component={} /> */
}
const OlympusComp = ({ match }) => {
    console.log('match: ', match.url);
    return (
        <Switch>
            <Route
                exact
                path={`${match.url}/customer-master/:id/other-request`}
                component={OtherRequest}
            />
            <Route exact path={`${match.url}/credit-form/:id`} component={CreditForm} />
            <Route
                exact
                path={`${match.url}/global-trade/:id`}
                component={GlobalTradeForm}
            />
            <Route exact path={`${match.url}/contracts/:id`} component={ContractsForm} />
            <Route
                exact
                path={`${match.url}/customer-master/:id`}
                component={CustomerMaster}
            />
            <Route exact path={`${match.url}/pricing/:id`} component={PricingForm} />
            <Route
                path={`${match.url}/customer-master/:id/:action`}
                component={UpdateCustomerMaster}
            />
            <Route
                path={`${match.url}/pricing/:id/:action`}
                component={UpdatePricingForm}
            />
            <Route
                path={`${match.url}/global-trade/:id/:action`}
                component={UpdateGlobalTradeForm}
            />
            <Route
                path={`${match.url}/credit-form/:id/:action`}
                component={UpdateCreditForm}
            />
            <Route
                path={`${match.url}/contracts/:id/:action`}
                component={UpdateContractsForm}
            />
            <Route
                path={`${match.url}/partners/:id/:page`}
                component={MyTaskOlympusPartners}
            />
        </Switch>
    );
};
export default OlympusComp;
