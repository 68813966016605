import React from 'react';
import {
    ScrollView,
    View,
    ActivityIndicator,
    Keyboard,
    TouchableOpacity,
    StyleSheet,
} from 'react-native';
import {
    DimensionAware,
    getWindowHeight,
    getWindowWidth,
} from 'react-native-dimension-aware';
import { AntDesign } from '@expo/vector-icons';
import { Button, Box, Text, Flex } from '../../../components/common';
import { FormInput, FormSelect } from '../../../components/form';
import { getCustomerDetail } from '../../../appRedux/actions/Customer';
import { connect } from 'react-redux';
import OverflowRight from '../../../components/OverflowRight';
import { Table, TableWrapper, Row, Rows, Cell } from '../../../components/table';
import MiniTable from '../../../components/table/minimisableTable';
import { resolveDependencies, passFields } from '../../../constants/utils';
import GlobalMdmFields from '../../../components/GlobalMdmFields';
import SystemFields from '../../../components/SystemFields';
import {
    RoleType,
    SystemType,
    SalesOrgType,
    CreditControlAreaType,
} from '../../../constants/WorkflowEnums';
import Loading from '../../../components/Loading';
import { getMdmMappingMatrix, getStaticRequest } from '../../../appRedux/actions';
import { fetchCreateCustomerDropDownData } from '../../../redux/DropDownDatas';
import DynamicSelect from '../../../components/DynamicSelect';
import FlashMessage from '../../../components/FlashMessage';
import { PageTitle } from '../../../components/pagetitle';
import BreadCrumbs from '../../../components/BreadCrumbs';
import idx from 'idx';
import { Colors, StyledText } from '../../../theme/Index';
import debounce from 'lodash.debounce';

const _ = require('lodash');

const SalesOrgValidValues = Object.keys(SalesOrgType).map((index) => {
    const system = SalesOrgType[index];
    return { id: index, description: system };
});

export const OverflowRightContext = React.createContext({
    isToggled: false,
    toggleOverflow: () => {},
});

const TableComponent = ({
    flexArray,
    headings,
    tableData,
    isFetching = false,
    fontsize = '15px',
    cellHeight = '35px',
}) => (
    <View style={{ width: '100%' }}>
        <View
            style={{
                minHeight: 'auto',
                maxHeight: '300px',
            }}>
            <Table
                border="2px solid #234382"
                borderStyle={{
                    borderWidth: 1,
                    borderRightWidth: 1,
                    borderColor: '#98D7DA',
                    borderRightStyle: 'solid',
                }}>
                <Row
                    flexArr={flexArray}
                    data={headings}
                    style={{
                        backgroundColor: '#E6F5FA',
                        height: '45px',
                    }}
                    borderStyle={{
                        borderWidth: 0,
                        borderTopWidth: 0,
                        borderRightWidth: 1,
                        borderColor: '#98D7DA',
                        borderRightStyle: 'solid',
                    }}
                    textStyle={{
                        textAlign: 'left',
                        color: '#234385',
                        fontWeight: '600',
                        fontFamily: 'Poppins',
                        fontSize: 12,
                        paddingTop: 24,
                        paddingBottom: 24,
                        paddingHorizontal: 15,
                    }}
                />
            </Table>
        </View>
        <ScrollView
            style={{
                flex: 1,
                overflowY: 'overlay',
                borderBottom: '3px solid #234382',
            }}
            keyboardShouldPersistTaps="always">
            <View
                style={{
                    minHeight: 'auto',
                    maxHeight: '200px',
                }}>
                <Table
                    border="2px solid #234382"
                    borderTopWidth="0px"
                    borderBottomWidth="0px">
                    {isFetching ? (
                        <ActivityIndicator />
                    ) : (
                        <Rows
                            flexArr={flexArray}
                            data={tableData}
                            style={{ minHeight: 20, height: cellHeight }}
                            borderStyle={{
                                borderWidth: 0,
                                borderTopWidth: 0,
                                borderRightWidth: 1,
                                borderColor: '#98D7DA',
                                borderRightStyle: 'solid',
                            }}
                            textStyle={{
                                color: '#353535',
                                fontSize: fontsize,
                                fontWeight: '500',
                                fontFamily: 'Poppins',
                                borderColor: '#98D7DA',
                                paddingTop: 26,
                                paddingBottom: 27,
                                paddingLeft: 5,
                                textAlign: 'left',
                                backgroundColor: '#F8F8F8',
                            }}
                        />
                    )}
                </Table>
            </View>
        </ScrollView>
    </View>
);

const MdmMappingTableHead = [
    'System',
    'Role',
    'System Account Number',
    'Survivor Record Indicator',
];
// 'Survivor Record Indicator',

const MdmMappingTableData = [
    ['MDM', '', '00001', 'X'],
    ['SAP APOLLO', 'SOLD TO', '324212', ''],
    ['SAP APOLLO', 'SOLD TO', '731351', 'X'],
];

const ParentTableHead = [
    ' ',
    'DUNS',
    'Name',
    'Address',
    'City',
    'State',
    'Zip',
    'Country',
];
const ParentTableData = [
    ['Global', '', '', '', '', '', '', ''],
    ['Domestic', '', '', '', '', '', '', ''],
    ['Immediate', '', '', '', '', '', '', ''],
];

const CreditTableHead = [
    'System',
    'System Account Number',
    'Credit Limit',
    'Credit Control Area',
];
class Page extends React.Component {
    togglePanel = (value = null) => {
        const isBoolean = value && typeof value === 'boolean';
        this.setState((state) => ({
            isToggled: isBoolean ? value : !state.isToggled,
        }));
    };

    constructor(props) {
        super(props);
        this.toggleOverflow = () => {
            this.setState((state) => ({
                isToggled: !state.isToggled,
            }));
        };

        Keyboard.removeAllListeners();
        this.state = {
            loading: false,
            isToggled: false,
            isMdmMappingToggled: true,
            isParentTableToggled: true,
            isCreditTableToggled: true,
            formData: {},
            mdmTblHeight: '400px',
            creditTblHeight: '400px',
            parentTblHeight: '400px',
            dropDownDatas: {},
            toggleOverflow: this.togglePanel,
        };
    }
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location && this.state.isToggled === true) {
            this.togglePanel(false);
        }
    }

    componentDidMount() {
        const { id } = this.props.match.params;
        var postJson = {
            requestObj: { MdmNumber: id, userId: localStorage.getItem('userId') },
            getParentDuns: true,
        };

        this.props.getStaticRequest([40, 41, 42, 43, 50]);
        this.props.getMdmMappingMatrix(postJson);

        fetchCreateCustomerDropDownData().then((res) => {
            const data = res;
            this.setState({ dropDownDatas: data });
        });
    }

    toggle = (stateKey, stateValue) => {
        this.setState({ [stateKey]: stateValue }, () =>
            console.log('tog', stateKey, ' ', stateValue)
        );
        if (stateValue === false) {
            if (stateKey === 'isMdmMappingToggled') {
                this.setState({ mdmTblHeight: '0px' });
            } else if (stateKey === 'isCreditTableToggled') {
                this.setState({ creditTblHeight: '0px' });
            } else {
                this.setState({ parentTblHeight: '0px' });
            }
        } else {
            if (stateKey === 'isMdmMappingToggled') {
                this.setState({ mdmTblHeight: '400px' });
            } else if (stateKey === 'isCreditTableToggled') {
                this.setState({ creditTblHeight: '400px' });
            } else {
                this.setState({ parentTblHeight: '400px' });
            }
        }
    };

    setSystemFieldStates = (erpData) => {
        console.log('erpData: ', erpData);

        const { id } = this.props.match.params;
        this.setState(
            (state) => ({
                isToggled: !state.isToggled,
                selectedErp: erpData,
                sysFieldFormData: {
                    WorkflowId: '',
                    CustomerNumber: erpData.CustomerNumber,
                    SystemTypeId: erpData.SystemTypeId,
                    RoleTypeId: erpData.RoleTypeId,
                    SalesOrgTypeId: erpData.SalesOrgTypeIds[0],
                    DistributionChannelTypeId: erpData.DistributionChannelTypeIds[0],
                    DivisionTypeId: erpData.DivisionTypeIds[0],
                    CompanyCodeTypeId: erpData.CompanyCodeTypeIds[0],
                    MdmNumber: id,
                },
            }),
            () => window.scrollTo(1000, 10000)
        );
    };

    onFieldChange = (value, e) => {
        const { name } = e.target;
        this.setState({
            sysFieldFormData: {
                ...this.state.sysFieldFormData,
                [name]: value,
            },
        });
    };

    isEmpty = (data) => {
        let result = false;
        if (typeof data === 'string') {
            return !data || 0 === data.length;
        }
        if (typeof data === 'object') {
            for (var key in data) {
                if (data.hasOwnProperty(key)) return false;
            }
            return true;
        }
        return result;
    };

    render() {
        const {
            width,
            location,
            mdmcustomerdata: {
                MDMGlobalData: globalMdmDetail = {},
                MDMMappingMatrix: mdmMappingMatrix = [],
                CreditData: mdmCreditData = [],
                MdmNumber: state_mdmNumber,
                OriginalMdmNumber: old_mdmNumber,
                hasApolloCustomers: HasApolloCustomers,
            },
            loadingParentDuns,
            mdm_parentdata_duns,
        } = this.props;
        console.log(this.props);
        const { selectedSearchRecord: customer } = this.props;
        const mdmNumber = this.props.match.params.id;
        const {
            mdmTblHeight,
            creditTblHeight,
            parentTblHeight,
            isToggled,
            isMdmMappingToggled,
            isParentTableToggled,
            isCreditTableToggled,
            dropDownDatas,
        } = this.state;

        const SalesOrgType = idx(dropDownDatas, (_) => _.SalesOrgType) || [];

        let teams = JSON.parse(localStorage.getItem('teamIds'));
        // Hide options for Search team users of CM system / AD groups
        let isSearchTeamUser =
            (teams && teams.includes(20) && teams.length === 1) ||
            (teams && teams.length === 0) ||
            (teams && teams.includes(99));

        let cmUserNotAssignedToTeam = teams && teams.length == 0;
        let buttonsEnabled = cmUserNotAssignedToTeam ? true : !isSearchTeamUser;

        let updateGlobalVisibility = buttonsEnabled ? HasApolloCustomers : false;
        // let TargetSalesOrgValidValues =
        //     dropDownDatas &&
        //     globalMdmDetail.Country &&
        //     globalMdmDetail.Country.toLowerCase() != 'ca'
        //         ? SalesOrgType.filter((saleorg) => !(saleorg.value === '0150'))
        //         : [{ id: 3, value: '0150', description: 'Canada' }];

        let TargetSalesOrgValidValues = SalesOrgType;

        let mdmTableData = mdmMappingMatrix.map((mdmMapping, index) => {
            return [
                SystemType[mdmMapping.SystemTypeId],
                mdmMapping.SystemTypeId === 2
                    ? idx(
                          this.props.staticData,
                          (_) => _.OlympusRoleType[mdmMapping.RoleTypeId - 1].Description
                      )
                    : mdmMapping.SystemTypeId === 3
                    ? idx(
                          this.props.staticData,
                          (_) => _.PointmanRoleType[mdmMapping.RoleTypeId - 1].Description
                      )
                    : RoleType[mdmMapping.RoleTypeId],
                <Button
                    disabled={
                        mdmMapping.SystemTypeId === 3 ||
                        (mdmMapping.RoleTypeId <= 6 ? false : true)
                    }
                    onPress={() => this.setSystemFieldStates(mdmMapping)}
                    style={{ backgroundColor: 'transparent' }}
                    titleStyle={{ color: 'blue' }}
                    title={mdmMapping.CustomerNumber}
                />,
                mdmMapping.IsSurvivorFlag ? 'X' : null,
            ];
        });

        // mdmMapping.IsGoldenRecord ? 'X' : null,  --removed survivor from matrix

        let creditTableData = mdmCreditData.map((mdmCredit, index) => [
            SystemType[mdmCredit.SystemTypeId],
            mdmCredit.CustomerNumber,
            mdmCredit.SystemCreditLimit,
            mdmCredit.SystemTypeId === 2
                ? idx(
                      this.props.staticData
                          ? this.props.staticData.OlympusCreditControlAreaType
                          : '',
                      (_) => _[mdmCredit.CreditControlAreaTypeId - 1].Value
                  )
                : CreditControlAreaType[mdmCredit.CreditControlAreaTypeId],
        ]);

        let parentTableData = [];
        if (mdm_parentdata_duns && mdm_parentdata_duns.DunsResponse) {
            let ParentData = mdm_parentdata_duns.DunsResponse.ParentData;
            for (let key in ParentData) {
                parentTableData.push([
                    key,
                    ParentData[key].DUNSNumber,
                    ParentData[key].Name,
                    ParentData[key].Address,
                    ParentData[key].City,
                    ParentData[key].State,
                    ParentData[key].Zip,
                    ParentData[key].Country,
                ]);
            }
        } else {
            parentTableData = ParentTableData;
        }

        const MinimisableMdmMapping = (
            <MiniTable
                title="MDM Mapping"
                tblHeight={mdmTblHeight}
                onPressTable={() =>
                    this.toggle('isMdmMappingToggled', !isMdmMappingToggled)
                }
                tableContent={
                    <TableComponent
                        flexarray={[1, 1, 1, 1]}
                        headings={MdmMappingTableHead}
                        tableData={mdmTableData}
                        isFetching={this.props.fetching}
                    />
                }
                onMenuDismiss={() => this.toggle('isMdmMappingToggled', false)}
                isToggled={isMdmMappingToggled}
            />
        );

        const MinimisableParentTable = (
            <MiniTable
                title="Parent Table"
                tblHeight={parentTblHeight}
                onPressTable={() =>
                    this.toggle('isParentTableToggled', !isParentTableToggled)
                }
                tableContent={
                    <TableComponent
                        fontsize="13px"
                        cellHeight="55px"
                        flexarray={[1.6, 1, 1, 1.1, 1, 1, 1, 1.1]}
                        headings={ParentTableHead}
                        tableData={parentTableData}
                        isFetching={this.props.loadingParentDuns}
                    />
                }
                onMenuDismiss={() => this.toggle('isParentTableToggled', false)}
                isToggled={isParentTableToggled}
            />
        );

        const MinimisableCreditTable = (
            <MiniTable
                title="Credit Table"
                tblHeight={creditTblHeight}
                onPressTable={() =>
                    this.toggle('isCreditTableToggled', !isCreditTableToggled)
                }
                tableContent={
                    <TableComponent
                        flexarray={[1, 1, 1, 1.5]}
                        headings={CreditTableHead}
                        tableData={creditTableData}
                    />
                }
                onMenuDismiss={() => this.toggle('isCreditTableToggled', false)}
                isToggled={isCreditTableToggled}
            />
        );

        const TableInSlidePane = (
            <View>
                <Box>
                    <Box mt={4}>
                        {/*<Text
                            style={{
                                color: 'red',
                                width: '85%',
                                marginLeft: '5%',
                                border: 'solid 1px',
                                padding: '0.5%',
                            }}>
                            Please do not reference the ‘Survivor Record Indicator’ for
                            MDM records with 3 or less ERP duplicates, as these have been
                            selected at random and have not yet been identified as the
                            real survivor by the business.
                        </Text>*/}
                        {MinimisableMdmMapping}
                    </Box>
                    <Text
                        padding="2%"
                        fontWeight="light"
                        color="lightBlue"
                        fontSize="24px">
                        GLOBAL VIEW
                    </Text>
                    {MinimisableCreditTable}
                    {MinimisableParentTable}
                </Box>
            </View>
        );

        console.log('state', state_mdmNumber);
        console.log('mdm', mdmNumber);

        if (this.props.fetching || old_mdmNumber !== mdmNumber) {
            return <Loading />;
        }

        return (
            <ScrollView
                pointerEvents={'box-none'}
                keyboardShouldPersistTaps="always"
                style={{
                    backgroundColor: '#EFF3F6',
                }}>
                <View
                    pointerEvents={'box-none'}
                    style={{
                        display: 'flex',
                        flex: 1,
                        paddingHorizontal: '2%',
                    }}>
                    <PageTitle title={'Search results screen2'} />
                    <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="space-between">
                        {this.props.location.pathname !== '/' && (
                            <BreadCrumbs
                                title={'Search results screen2'}
                                currentPath={this.props.location.pathname}
                                links={this.props.location.pathname.split('/')}
                                blacklist={[]}
                            />
                        )}
                        <Box mt={3} pr={3}>
                            <TouchableOpacity
                                hitSlop={{
                                    top: 100,
                                    bottom: 100,
                                    left: 0,
                                    right: 0,
                                }}
                                onPress={() => this.toggle('isToggled', !isToggled)}
                                style={{
                                    flexDirection: 'row-reverse',
                                    alignItems: 'flex-end',
                                }}>
                                <label>
                                    <AntDesign
                                        name="arrowleft"
                                        size={38}
                                        color="#11307D"
                                        label="as"
                                    />
                                    <StyledText style={{ color: '#11307D' }}>
                                        MDM Matrix
                                    </StyledText>
                                </label>
                            </TouchableOpacity>
                        </Box>
                    </Box>
                    <Box>
                        <OverflowRightContext.Provider value={this.state}>
                            <View style={{ zIndex: 1, marginTop: '-58px' }}>
                                <OverflowRight
                                    content={TableInSlidePane}
                                    onMenuDismiss={() => this.toggle('isToggled', false)}
                                    style={{
                                        position: 'absolute',
                                        zIndex: 1,
                                    }}
                                    isToggled={isToggled}
                                />
                            </View>
                        </OverflowRightContext.Provider>
                    </Box>
                    <Box
                        my={2}
                        style={{
                            zIndex: -1,
                            paddingHorizontal: width < 1440 ? 60 : width * 0.05,
                        }}>
                        <Box
                            flexDirection="row"
                            justifyContent="space-around"
                            mt={4}
                            mb={4}
                            alignItems="center">
                            <Box
                                display="flex"
                                alignItems="center"
                                flex={1}
                                flexDirection="row">
                                <Text
                                    py="4px"
                                    display="inline-block"
                                    m="10px 0 24px 5%"
                                    ml="5%"
                                    my="0px"
                                    fontWeight="light"
                                    color="lightBlue"
                                    fontSize="24px">
                                    MDM GLOBAL FIELDS
                                </Text>
                                <FormInput
                                    px="25px"
                                    inline
                                    label="MDM Number"
                                    name="mdm-number"
                                    value={state_mdmNumber.toString()}
                                    style={{ lineHeight: '2' }}
                                    variant="outline"
                                    type="text"
                                />
                            </Box>
                        </Box>
                        <GlobalMdmFields
                            hideTitle
                            readOnly
                            formData={{
                                ...globalMdmDetail,
                                // ...mdm_parentdata_duns.DunsResponse,
                            }}
                            staticDropDownData={this.props.staticData}
                        />

                        {this.state.selectedErp && (
                            <>
                                <Text
                                    mt={5}
                                    mb={3}
                                    ml="5%"
                                    fontWeight="light"
                                    color="lightBlue"
                                    fontSize="24px">
                                    SYSTEM FIELDS
                                </Text>

                                <Box flexDirection="row" justifyContent="center">
                                    <Box width={1 / 2} mx="auto" alignItems="center">
                                        {/* <FormInput
                                            label="Customer Number"
                                            name="CustomerNumber"
                                            inline
                                            variant="outline"
                                            type="text"
                                            value={this.state.selectedErp.CustomerNumber}
                                        /> */}
                                        <FormInput
                                            label="System"
                                            name="system"
                                            inline
                                            variant="outline"
                                            type="text"
                                            value={
                                                SystemType[
                                                    this.state.selectedErp.SystemTypeId
                                                ]
                                            }
                                        />
                                        <FormInput
                                            label="Role"
                                            name="Role"
                                            inline
                                            variant="outline"
                                            type="text"
                                            value={
                                                RoleType[
                                                    this.state.selectedErp.RoleTypeId
                                                ]
                                            }
                                        />
                                        {/* <DynamicSelect
                                            arrayOfData={
                                                dropDownDatas.DistributionChannelType &&
                                                dropDownDatas.DistributionChannelType.filter(
                                                    (channel) =>
                                                        channel.systemId ===
                                                            parseInt(
                                                                this.state.selectedErp
                                                                    .SystemTypeId
                                                            ) &&
                                                        this.state.selectedErp.DistributionChannelTypeIds.includes(
                                                            channel.id
                                                        )
                                                )
                                            }
                                            label="Distribution Channel"
                                            name="DistributionChannelTypeId"
                                            isRequired
                                            formErrors={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                          'DistributionChannelTypeId'
                                                      ]
                                                    : null
                                            }
                                            value={
                                                this.state.sysFieldFormData &&
                                                this.state.sysFieldFormData
                                                    .DistributionChannelTypeId
                                            }
                                            onFieldChange={this.onFieldChange}
                                        /> */}
                                        {/* <DynamicSelect
                                            arrayOfData={
                                                dropDownDatas.DivisionType &&
                                                dropDownDatas.DivisionType.filter(
                                                    (division) =>
                                                        division.systemId ===
                                                            parseInt(
                                                                this.state.selectedErp
                                                                    .SystemTypeId
                                                            ) &&
                                                        this.state.selectedErp.DivisionTypeIds.includes(
                                                            division.id
                                                        )
                                                )
                                            }
                                            label="Division"
                                            name="DivisionTypeId"
                                            isRequired
                                            formErrors={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                          'DivisionTypeId'
                                                      ]
                                                    : null
                                            }
                                            value={
                                                this.state.sysFieldFormData &&
                                                this.state.sysFieldFormData.DivisionTypeId
                                            }
                                            onFieldChange={this.onFieldChange}
                                        /> */}
                                    </Box>
                                    <Box width={1 / 2} mx="auto" alignItems="center">
                                        {/* <DynamicSelect
                                            arrayOfData={dropDownDatas.CompanyCodeTypeId}
                                            label="Company Code"
                                            name="CompanyCodeTypeId"
                                            value={
                                                this.state.sysFieldFormData &&
                                                this.state.sysFieldFormData
                                                    .CompanyCodeTypeId
                                            }
                                            isRequired
                                            formErrors={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                          'CompanyCodeTypeId'
                                                      ]
                                                    : null
                                            }
                                            onFieldChange={this.onFieldChange}
                                        /> */}
                                        {/* <DynamicSelect
                                            arrayOfData={SalesOrgValidValues.filter(
                                                (salesorg) =>
                                                    this.state.selectedErp.SalesOrgTypeIds.includes(
                                                        parseInt(salesorg.id)
                                                    )
                                            )}
                                            label="Source Sales Org"
                                            name="SalesOrgTypeId"
                                            value={
                                                this.state.sysFieldFormData &&
                                                this.state.sysFieldFormData.SalesOrgTypeId
                                            }
                                            isRequired
                                            formErrors={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                          'SalesOrgTypeId'
                                                      ]
                                                    : null
                                            }
                                            onFieldChange={this.onFieldChange}
                                        /> */}
                                        {/* <DynamicSelect
                                            arrayOfData={TargetSalesOrgValidValues.filter(
                                                (salesorg) =>
                                                    !this.state.selectedErp.SalesOrgTypeIds.includes(
                                                        parseInt(salesorg.id)
                                                    )
                                            )}
                                            label="Target Sales Org"
                                            name="TargetSalesOrgTypeId"
                                            value={
                                                this.state.sysFieldFormData &&
                                                this.state.sysFieldFormData
                                                    .TargetSalesOrgTypeId
                                            }
                                            isRequired
                                            formErrors={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                          'TargetSalesOrgTypeId'
                                                      ]
                                                    : null
                                            }
                                            onFieldChange={this.onFieldChange}
                                        /> */}
                                    </Box>
                                </Box>
                            </>
                        )}
                    </Box>
                    <Flex
                        justifyEnd
                        alignCenter
                        style={{
                            zIndex: -1,
                            paddingTop: 65,
                            flexDirection: 'row',
                            alignItems: 'center',
                            paddingLeft: 10,
                            paddingRight: 15,
                            marginTop: 20,
                            marginHorizontal: 25,
                        }}>
                        {/** For PROD: This Button should be disabled
                         **  disabled={true}
                         **/}
                        {/* <Button
                            disabled={!updateGlobalVisibility}
                            onPress={() => {
                                this.props.history.push({
                                    pathname: `/search-results/${mdmNumber}/update-global`,
                                });
                            }}
                            title="Update Global"
                        /> */}
                        <Button
                            title="Update"
                            onPress={() => {
                                this.props.history.push({
                                    pathname: `/search-results/olympus/${mdmNumber}/${'update'}`,
                                    state: {
                                        sysFieldsData: this.state.sysFieldFormData,
                                        MdmNumber: mdmNumber,
                                        CategoryTypeId: globalMdmDetail.CategoryTypeId,
                                        dunsFields: mdm_parentdata_duns,
                                    },
                                });
                            }}
                        />

                        <Button title="Extend To System" />

                        <Button
                            title="Extend To Sales Org"
                            onPress={() => {
                                this.props.history.push({
                                    pathname: `/search-results/${mdmNumber}/${'extend-salesorg'}`,
                                    state: {
                                        sysFieldsData: this.state.sysFieldFormData,
                                        MdmNumber: mdmNumber,
                                        CategoryTypeId: globalMdmDetail.CategoryTypeId,
                                        dunsFields: mdm_parentdata_duns,
                                    },
                                });
                            }}
                        />

                        <Button title="Extend To Company Code" disabled={false} />

                        <Button
                            disabled={this.state.selectedErp ? false : true}
                            onPress={() => {
                                this.props.history.push({
                                    pathname: `/search-results/${mdmNumber}/block-unblock`,
                                    state: {
                                        MdmNumber: mdmNumber,
                                        sysFieldsData: this.state.sysFieldFormData,
                                    },
                                });
                            }}
                            disabled={false}
                            title="Block-UnBlock"
                        />
                    </Flex>
                    <Box width="200px" height={this.props.height / 2} />
                </View>
            </ScrollView>
        );
    }
}

class Default extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const props = this.props;

        return (
            <DimensionAware
                render={(dimensions) => (
                    <Page
                        {...{
                            ...props,
                            width: getWindowWidth(dimensions),
                            height: getWindowHeight(dimensions),
                            marginBottom: 25,
                        }}
                    />
                )}
            />
        );
    }
}
const styles = StyleSheet.create({
    TableHeaderContainer: {
        paddingLeft: 32,
        backgroundColor: '#234385',
        paddingVertical: 12,
    },
    menuItemsHeader: {
        color: 'white',
        fontFamily: 'Poppins',
        fontSize: 17,
    },
    menuItemText: {
        fontSize: 16,
        color: '#10254D',
        fontFamily: 'Poppins',
        fontWeight: '400',
    },
    bold: { color: '#10254D', fontFamily: 'Poppins', fontWeight: '700' },
});

const mapStateToProps = ({ updateFlow, customer, staticData }) => {
    const { selectedSearchRecord } = customer;
    const {
        mdmcustomerdata,
        fetching,
        loadingParentDuns,
        mdm_parentdata_duns,
    } = updateFlow;
    const { data, fetching: staticFetching } = staticData;

    return {
        mdmcustomerdata,
        selectedSearchRecord,
        fetching: fetching || staticFetching,
        loadingParentDuns,
        mdm_parentdata_duns,
        staticData: data,
    };
};

export default connect(mapStateToProps, { getMdmMappingMatrix, getStaticRequest })(
    Default
);
