import React from 'react';
import {
    ScrollView,
    View,
    TouchableOpacity,
    ActivityIndicator,
    StyleSheet,
} from 'react-native';
import {
    DimensionAware,
    getWindowHeight,
    getWindowWidth,
} from 'react-native-dimension-aware';
import { Colors, StyledText } from '../../../theme/Index';
import { Flex, Button, Box, Text } from '../../../components/common';
import { FormInput } from '../../../components/form';
import GlobalMdmFields from '../../../components/GlobalMdmFields';
import DynamicSelect from '../../../components/DynamicSelect';
import { CheckBoxItem } from '../../../components/CheckBoxItem';
import { debounce } from 'lodash';
import { parseAndSanitise, yupAllFieldsValidation } from '../../../constants/utils';
import { myTaskJdeRules, rejectRules } from '../../../constants/FieldRules';
import { saveApolloMyTaskContracts } from '../../../appRedux/actions/MyTasks';
import {
    // saveApolloMyTaskCustomerMaster,
    fetchDuns,
    getStatusBarData,
    getFunctionalGroupData,
    getStaticRequest,
    removeMessage,
} from '../../../appRedux/actions/';
import { connect } from 'react-redux';
import {
    fetchCustomerMasterDropDownData,
    fetchCreateTeamRoleFieldMapping,
    fetchOlympusFieldMapping,
    fetchJDECreateTeamRoleFieldMapping,
} from '../../../redux/DropDownDatas';
import Loading from '../../../components/Loading';
import FlashMessage, { FlashMessages } from '../../../components/FlashMessage';
import {
    RoleType,
    PtmnRoleType,
    SalesOrgType,
    SystemType,
    DistributionChannelType,
    DivisionType,
    CompanyCodeType,
} from '../../../constants/WorkflowEnums';
import MultiColorProgressBar from '../../../components/MultiColorProgressBar';
import Modal from 'modal-enhanced-react-native-web';
import * as moment from 'moment';
import FilesList from '../../../components/FilesList';
import { MaterialIcons } from '@expo/vector-icons';
import idx from 'idx';
import { Table, Row, Rows } from '../../../components/table';
import JDESystemFields from '../../JDESystemFields';
import JdeGlobalMdmFields from '../../../components/JdeGlobalMdmFields';

class Page extends React.Component {
    constructor(props) {
        super(props);
        const editableProp = {
            inline: false,
            variant: 'solid',
            onChange: this.onFieldChange,
        };
        // let isWorkFlowReadOnly=false
        this.state = {
            isWorkFlowReadOnly: this.props.location.state.isReadOnly,
            WorkflowId: this.props.location.state.WorkflowId,
            TaskId: this.props.location.state.TaskId,
            loading: this.props.fetching,
            functionalGroupDetails: this.props.functionalGroupDetails,
            dropDownDatas: {},
            formData: {
                RejectionButton: false,
            },
            formErrors: {},
            selectedFiles: {},
            selectedFilesIds: [],
            files: [],
            fileinputkey: Date.now(),
            jdeTeamRoleFieldMapping: {},
        };

        this.fetchDunsFields = debounce(this.props.fetchDuns, 200);
    }

    componentDidMount() {
        let { state: wf } = this.props.location;
        let postJson = {
            workflowId: wf.WorkflowId,
            fuctionalGroup: 'customermaster',
            taskId: wf.TaskId,
            systemTypeId: wf.SystemTypeId,
        };

        this.props.getStaticRequest([
            87,
            88,
            89,
            90,
            91,
            92,
            93,
            94,
            95,
            96,
            97,
            98,
            99,
            100,
            101,
            102,
            103,
            104,
            105,
            106,
            107,
            108,
            109,
            110,
            111,
            112,
            113,
            114,
            115,
            5,
            116,
            123,
        ]);

        this.props.getStatusBarData(postJson);
        this.props.getFunctionalGroupData(postJson);
        fetchCustomerMasterDropDownData().then((res) => {
            const data = res;
            this.setState({ dropDownDatas: data });
        });
        fetchJDECreateTeamRoleFieldMapping().then((resp) => {
            const data = resp;
            this.setState({ jdeTeamRoleFieldMapping: data });
        });
    }

    componentWillReceiveProps(newProps) {
        if (
            newProps.functionalGroupDetails != this.props.functionalGroupDetails &&
            !this.state.isWorkFlowReadOnly
        ) {
            this.setState({
                formData: {
                    ...this.state.formData,
                    Role: this.props.staticData
                        ? this.getDropdownValues(
                              newProps.functionalGroupDetails.Customer.RoleTypeId,
                              newProps.staticData.OlympusRoleType
                          )
                        : '',
                    RoleTypeId: newProps.functionalGroupDetails.Customer.RoleTypeId,
                    DunsNumber: newProps.functionalGroupDetails.Customer.DunsNumber,
                    MarketingSegmentationTypeId:
                        newProps.functionalGroupDetails.Customer
                            .MarketingSegmentationTypeId,
                },
                inputPropsForDefaultRules: {
                    ...this.state.inputPropsForDefaultRules,
                    // ...result.newStyleProps,
                },
            });
        }
    }

    setFormErrors = (errors) => {
        const { formErrors } = this.state;
        this.setState({ formErrors: errors }, () => window.scrollTo(0, 0));
    };

    onDunsNewInput = (value, e) => {
        const { name } = e.target;
        this.fetchDunsFields({
            UserId: localStorage.getItem('userId'),
            DunsNumber: value,
        });
    };

    onFieldChange = (value, e) => {
        const { name } = e.target;
        const { formData: prevFormData } = this.state;
        if (name === 'DunsNumber' && value.length > 0) {
            const { isDunsValid } = this.state;
            const {
                validity: { valid },
            } = e.target;
            if (valid !== isDunsValid)
                this.setState({
                    isDunsValid: valid,
                    formErrors: {
                        ...this.state.formErrors,
                        isDunsValid: !valid ? ' only numbers (0-9) are allowed.' : null,
                    },
                });
        } else if (value.length <= 0 && this.state.formErrors['isDunsValid']) {
            this.setState({
                formErrors: {
                    ...this.state.formErrors,
                    isDunsValid: null,
                },
            });
        }
        var customerdata = this.props.functionalGroupDetails.Customer;
        let formData = this.state.formData;
        this.setState({
            test: { ...this.state.test, [name]: value },
            formData: {
                ...this.state.formData,
                [name]: value,
            },
        });
    };

    handleFormSubmission = (reject = false) => {
        const userId = localStorage.getItem('userId');

        let {
            functionalGroupDetails: { Customer: globalMdmDetail = {} },
        } = this.props;

        try {
            const { location } = this.props;
            const { TaskId, WorkflowId, selectedFiles, selectedFilesIds } = this.state;
            const WorkflowTaskModel = {
                RejectReason: reject ? this.state.formData.RejectionReason : '',
                TaskId: TaskId,
                UserId: userId,
                WorkflowId: WorkflowId,
                WorkflowTaskOperationType: !reject ? 1 : 2,
                SystemTypeId:
                    globalMdmDetail.SystemTypeId != '' ? globalMdmDetail.SystemTypeId : 2,
            };
            let postData = {};
            postData['formdata'] = {
                WorkflowTaskModel,
                AdditionalNotes: this.state.formData.AdditionalNotes,
            };
            postData['files'] = selectedFilesIds.map((id) => selectedFiles[id]);
            //console.log('postdata', postData);
            this.props.saveApolloMyTaskContracts(postData);
            this.scrollToTop();
        } catch (error) {
            console.log('form error', error);
        }
    };

    isSystemPointman = () => {
        const {
            functionalGroupDetails: { Customer: globalMdmDetail = {} },
        } = this.props;
        const isPointman = globalMdmDetail && globalMdmDetail.SystemTypeId === 3;

        return isPointman;
    };
    onSubmit = (event, reject, _schema) => {
        let { formData, selectedFilesIds, selectedFiles } = this.state;
        let fileErrors = {};
        let errors = false;
        selectedFilesIds.map((id) => {
            if (selectedFiles[id] && selectedFiles[id].DocumentType <= 0) {
                fileErrors[id] = 'Document Type Required for file';
                errors = true;
            }
        });

        this.setState({ fileErrors, isFileErrors: errors });

        if (!this.state.formData.RejectReason && reject) {
            if (!this.props.rejectionRequired) {
                this.setState({ rejectionRequired: true, modalVisible: false });
                this.handleFormSubmission(reject);
            }
        } else {
            this.setState(
                { rejectionRequired: false, loading: true, modalVisible: false },
                () => {
                    if (this.state.isFileErrors === false)
                        this.handleFormSubmission(reject);
                }
            );
            //;
        }
    };

    scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    resetFormData = () => {
        Object.keys(this.state.formData).map((key) => {
            var myitem = key;
            if (
                ![
                    'OrderCombination',
                    'PaymentHistoryRecord',
                    'RejectionButton',
                    'displayINCOT2',
                    'display_LN',
                ].includes(myitem)
            ) {
                this.setState({
                    formData: {
                        ...this.state.formData,
                        [key]: '',
                    },
                });
            } else {
                if (!['displayINCOT2', 'display_LN'].includes(myitem)) {
                    this.setState({
                        formData: {
                            ...this.state.formData,
                            [key]: false,
                        },
                    });
                }
            }
        });
    };

    resetForm = () => {
        let { formErrors } = this.state;

        Object.keys(this.state.formErrors).forEach((key) => (formErrors[key] = ''));

        this.setState(
            {
                formErrors,
            },
            this.resetFormData
        );
    };

    selectFiles = (events) => {
        event.preventDefault();

        const { selectedFilesIds, selectedFiles } = this.state;
        const id = events.target.files[0].name;

        this.setState({
            selectedFiles: {
                ...selectedFiles,
                [id]: {
                    data: events.target.files[0],
                    DocumentName: events.target.files[0].name,
                    DocumentType: 0,
                },
            },
            selectedFilesIds: [...selectedFilesIds, id],
            filename: events.target.files[0].name,
            fileinputkey: Date.now(),
        });
    };

    removeFile = (id) => {
        event.preventDefault();

        var selectedFilesDict = this.state.selectedFiles;
        var selectedFilesIdsList = this.state.selectedFilesIds;
        // remove the existing state
        delete selectedFilesDict[id];
        selectedFilesIdsList = selectedFilesIdsList.filter((fileid) => fileid !== id);

        this.setState({
            selectedFiles: selectedFilesDict,
            selectedFilesIds: selectedFilesIdsList,
        });
    };

    _renderModalContent = () => {
        const { formData } = this.state;
        return (
            <Box
                my={4}
                mx="30%"
                backgroundColor="white"
                borderRadius="4px"
                style={{ padding: '3%' }}
                borderColor="rgba(0, 0, 0, 0.1)">
                <Text my={3} fontWeight="regular" color="lightBlue" fontSize={24} pl={4}>
                    Submit Workflow
                </Text>
                <Box px="0px" pb="22px" justifyContent="center">
                    <Box alignItems="center" justifyContent="center">
                        {!this.state.isReject ? (
                            <FormInput
                                label="Additional Notes"
                                multiline
                                numberOfLines={2}
                                maxLength={250}
                                name="AdditionalNotes"
                                type="text"
                                onChange={this.onFieldChange}
                                value={
                                    this.state.formData &&
                                    this.state.formData['AdditionalNotes']
                                }
                                variant={'solid'}
                            />
                        ) : (
                            <FormInput
                                label="Rejection Reason"
                                name="RejectionReason"
                                maxLength={250}
                                onChange={this.onFieldChange}
                                error={'*this field is required'}
                                multiline
                                numberOfLines={2}
                                type="text"
                                value={
                                    this.state.formData &&
                                    this.state.formData['RejectionReason']
                                }
                                variant="solid"
                            />
                        )}
                    </Box>
                    <Flex
                        justifyEnd
                        alignCenter
                        alignSelf="flex-end"
                        style={{
                            paddingTop: 15,
                            flexDirection: 'row',
                            alignItems: 'center',
                            paddingLeft: 10,
                            paddingRight: 15,
                            marginTop: 20,
                            marginBottom: 10,
                            marginHorizontal: 25,
                        }}>
                        <Button
                            onPress={(event) => {
                                if (this.state.isReject)
                                    this.setState({ isReject: false }, () =>
                                        this.onSubmit(event, true, rejectRules)
                                    );
                                else this.onSubmit(event, false, myTaskJdeRules);
                            }}
                            title="Submit"
                        />
                        <Button
                            title="Cancel"
                            onPress={() => this.setState({ modalVisible: null })}
                        />
                    </Flex>
                </Box>
            </Box>
        );
    };

    deliveryPlantMapping = (data) => {
        let systemFields = this.props.functionalGroupDetails.Customer;
        let dropdownData = [];
        if (data) {
            data.forEach((dropdownList) => {
                if (dropdownList.ValidationData.length > 0) {
                    dropdownList.ValidationData.forEach((datatofilter) => {
                        if (
                            datatofilter.SalesOrgTypeId === systemFields.SalesOrgTypeId &&
                            systemFields.DistributionChannelTypeId ===
                                datatofilter.DistributionChannelTypeId
                        ) {
                            dropdownData.push(dropdownList);
                        }
                    });
                }
            });
        }
        return dropdownData;
    };

    getDropdownValues = (Id, dropdownList) => {
        let description = '';
        if (Id && dropdownList)
            dropdownList.some((data) => {
                if (data.Id == Id) {
                    description = data.Value + ' : ' + data.Description;
                    return true;
                }
            });
        // if (description.includes('/n') || description.includes(';')) {
        //     console.log('html string');
        // }
        return description;
    };

    getDropdownValuesPart = (Id, dropdownList) => {
        let description = '';
        if (Id && dropdownList)
            dropdownList.some((data) => {
                if (data.Id == Id) {
                    description = data.Value;
                    return true;
                }
            });
        // if (description.includes('/n') || description.includes(';')) {
        //     console.log('html string');
        // }
        return description;
    };

    render() {
        const {
            width,
            location,
            functionalGroupDetails: {
                DocumentLocation: files,
                Customer: globalMdmDetail = {},
                Contracts: functionalDetail = null,
                DocumentLocation,
                TaskNote: Notes = '',
                RequestorName,
            },
            statusBarData,
            TasksStatusByTeamId = null,
            alert = {},
            staticData,
        } = this.props;

        const {
            //dropDownDatas,
            teamRoleFieldMapping,
            inputPropsForDefaultRules,
            selectedFilesIds,
            selectedFiles,
            jdeTeamRoleFieldMapping,
        } = this.state;

        const { state } = location;

        const workflow = {
            ...state,
            isReadOnly:
                TasksStatusByTeamId === null ||
                !(
                    globalMdmDetail.WorkflowStateTypeId === 2 &&
                    TasksStatusByTeamId[5].WorkflowTaskStateTypeId === 2
                ),
        };

        let CreateJDETeamFields = [];
        CreateJDETeamFields =
            idx(jdeTeamRoleFieldMapping, (_) => _.ContractTeamFields) || [];
        const RoleTypeId = globalMdmDetail.RoleTypeId;
        const SystemTypeId = globalMdmDetail.SystemTypeId;
        const allowedFieldList = CreateJDETeamFields.filter((item) => {
            return item.RoleTypeId == RoleTypeId && item.SystemTypeId == SystemTypeId;
        });
        let allowedFields = undefined;
        if (allowedFieldList && allowedFieldList.length > 0) {
            allowedFields = allowedFieldList[0].Fields;
        }
        // console.log(allowedFields)
        const requestDateUtc = moment(globalMdmDetail.DateOfCreation);
        const localDate = moment(requestDateUtc).local();
        const requestDate = localDate.format('YYYY-MM-DD hh:mm:ss A');

        const inputReadonlyProps = workflow.isReadOnly ? { disabled: true } : null;

        const showFunctionalDetail =
            state.isReadOnly && functionalDetail === null ? { display: 'none' } : null;

        const showButtons = workflow.isReadOnly ? { display: 'none' } : null;
        const isWorkFlowReadOnly = workflow.isReadOnly;
        const enableDisplay = isWorkFlowReadOnly ? { display: 'none' } : null;
        var isReleased = true;
        for (let i = 0; i < statusBarData.length; i++) {
            let teamstatus = statusBarData[i];
            if (teamstatus.WorkflowTaskStateTypeId === 2) {
                isReleased = false;
                break;
            } else {
                isReleased = true;
            }
        }
        var bgcolor = alert.color || '#FFF';
        // console.log(this.props, functionalDetail, workflow.isReadOnly);
        if (this.props.fetching) {
            return <Loading />;
        }

        return (
            <ScrollView
                keyboardShouldPersistTaps="always"
                style={{
                    backgroundColor: '#EFF3F6',
                    paddingBottom: 75,
                }}>
                <Modal isVisible={this.state.modalVisible} style={{ overflow: 'auto' }}>
                    {this._renderModalContent()}
                </Modal>
                <FlashMessages
                    toasts={this.props.toasts}
                    onDismiss={this.props.removeMessage}
                />
                {alert.display && (
                    <FlashMessage
                        bg={{ backgroundColor: bgcolor }}
                        message={alert.message}
                    />
                )}

                <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between">
                    <Text
                        style={{
                            fontWeight: '500',
                            fontSize: 24,
                            color: Colors.lightBlue,
                            marginTop: 25,
                            marginBottom: 20,
                            paddingLeft: 45,
                        }}>
                        My Tasks - {state.Header}
                    </Text>
                    <Box
                        marginTop="25px"
                        flex={3 / 4}
                        flexDirection="row-reverse"
                        alignItems="flex-end">
                        <MultiColorProgressBar readings={statusBarData} />
                    </Box>
                </Box>
                <Box marginTop="25px" flexDirection="row-reverse" alignItems="flex-end">
                    <StyledText
                        style={{
                            fontWeight: '600',
                            paddingRight: '20px',
                            fontSize: '16px',
                            color: '#11307D',
                        }}>
                        {' '}
                        Requestor : {RequestorName}{' '}
                    </StyledText>
                </Box>
                <Box flexDirection="row-reverse" alignItems="flex-end">
                    <StyledText
                        style={{
                            fontWeight: '600',
                            paddingRight: '20px',
                            fontSize: '16px',
                            color: '#11307D',
                        }}>
                        {' '}
                        Date of Request: {requestDate}{' '}
                    </StyledText>
                </Box>
                <View
                    style={{
                        flex: 1,
                        paddingHorizontal: width < 1440 ? 60 : width * 0.1,
                        paddingBottom: 10,
                    }}>
                    <Box fullHeight my={2}>
                        <Box flexDirection="row" justifyContent="space-around" my={4}>
                            <FormInput
                                px="25px"
                                flex={1 / 4}
                                label="WorkflowTitle"
                                maxLength={40}
                                name="WorkflowTitle"
                                variant="outline"
                                type="text"
                                value={globalMdmDetail && globalMdmDetail.WorkflowTitle}
                            />
                            <FormInput
                                px="25px"
                                flex={1 / 4}
                                label="Workflow Number"
                                name="workflow-number"
                                variant="outline"
                                type="text"
                                value={globalMdmDetail && globalMdmDetail.WorkflowId}
                            />
                            {globalMdmDetail.SystemRecordId && (
                                <FormInput
                                    px="25px"
                                    flex={1 / 4}
                                    label="Customer Number"
                                    name="CustomerNumber"
                                    variant="outline"
                                    type="text"
                                    value={globalMdmDetail.SystemRecordId}
                                />
                            )}
                            {globalMdmDetail &&
                                globalMdmDetail.MdmCustomerId &&
                                (isReleased ? (
                                    <TouchableOpacity
                                        onPress={() =>
                                            this.props.history.push(
                                                `/search-results/${globalMdmDetail.MdmCustomerId}`
                                            )
                                        }>
                                        <FormInput
                                            px="25px"
                                            flex={1 / 4}
                                            label="MDM Number"
                                            name="mdm-number"
                                            variant="outline"
                                            type="text"
                                            value={
                                                globalMdmDetail &&
                                                globalMdmDetail.MdmCustomerId
                                            }
                                        />
                                    </TouchableOpacity>
                                ) : (
                                    <FormInput
                                        px="25px"
                                        flex={1 / 4}
                                        label="MDM Number"
                                        name="mdm-number"
                                        variant="outline"
                                        type="text"
                                        value={
                                            globalMdmDetail &&
                                            globalMdmDetail.MdmCustomerId
                                        }
                                    />
                                ))}
                        </Box>

                        {!this.state.isInternationalVersionVisible && (
                            <JdeGlobalMdmFields
                                // showMarketingSegmentation={!workflow.isReadOnly}
                                formData={{
                                    ...globalMdmDetail,
                                    DunsNumber:
                                        this.state.formData.DunsNumber ||
                                        globalMdmDetail.DunsNumber,
                                    MarketingSegmentationTypeId: idx(
                                        globalMdmDetail,
                                        (_) => _.MarketingSegmentationTypeId
                                    ),
                                }}
                                // dunsEditable={!workflow.isReadOnly}
                                editableInputs={{ TaxNumber: false }}
                                onDunsNewInput={this.onDunsNewInput}
                                formErrors={this.state.formErrors}
                                onFieldChange={this.onFieldChange}
                                staticDropDownData={this.props.staticData}
                                readOnly
                            />
                        )}

                        <Text
                            mt="42px"
                            mb="20px"
                            fontWeight="regular"
                            color="lightBlue"
                            fontSize={24}
                            pl={4}>
                            SYSTEM FIELDS
                        </Text>
                        <JDESystemFields
                            formData={{ ...globalMdmDetail }}
                            {...this.props}
                            allowedFields={allowedFields}
                            isReadOnly={true}
                        />

                        {files && <FilesList files={files} readOnly />}
                        {workflow && workflow.isReadOnly ? (
                            <>
                                <Box my={4}>
                                    <Box flexDirection="row" justifyContent="center">
                                        <Box width={1 / 2} mx="auto" alignItems="center">
                                            <FormInput
                                                label="Notes"
                                                name="AdditionalNotes"
                                                multiline
                                                numberOfLines={2}
                                                type="text"
                                                inputWidth="100%"
                                                value={
                                                    isWorkFlowReadOnly
                                                        ? Notes
                                                        : this.state.formData
                                                        ? this.state.formData[
                                                              'AdditionalNotes'
                                                          ]
                                                        : null
                                                }
                                                variant={
                                                    isWorkFlowReadOnly
                                                        ? 'outline'
                                                        : 'solid'
                                                }
                                                inline={isWorkFlowReadOnly ? true : false}
                                            />
                                        </Box>
                                        <Box
                                            width={1 / 2}
                                            mx="auto"
                                            alignItems="center"></Box>
                                    </Box>
                                </Box>
                            </>
                        ) : (
                            <FilesList
                                title="NEW ATTACHMENTS"
                                formErrors={this.state.fileErrors}
                                files={selectedFilesIds.map((id) => selectedFiles[id])}
                                onChange={(value, id) => {
                                    this.setState({
                                        selectedFiles: {
                                            ...selectedFiles,
                                            [id]: {
                                                ...selectedFiles[id],
                                                DocumentType: parseInt(value),
                                            },
                                        },
                                    });
                                }}
                                onRemove={(value, id) => this.removeFile(id)}
                            />
                        )}
                    </Box>
                    <Box {...enableDisplay}>
                        <Flex
                            justifyEnd
                            alignCenter
                            style={{
                                paddingTop: 15,
                                flexDirection: 'row',
                                alignItems: 'center',
                                paddingLeft: 10,
                                paddingRight: 15,
                                marginTop: 20,
                                marginBottom: 10,
                                marginHorizontal: 25,
                            }}>
                            <>
                                <label
                                    htmlFor="file-upload"
                                    className="custom-file-upload">
                                    <MaterialIcons
                                        name="attach-file"
                                        size={18}
                                        color="#fff"
                                    />
                                </label>
                                <input
                                    id="file-upload"
                                    type="file"
                                    key={this.state.fileinputkey}
                                    onChange={this.selectFiles}
                                    multiple
                                />
                                <span id="testError"></span>
                            </>
                            <Button
                                onPress={(event) => {
                                    this.setState({
                                        formErrors: {},
                                        isReject: false,
                                        modalVisible: true,
                                    });
                                }}
                                title="Approve"
                            />
                            <Button
                                title="Reject"
                                onPress={(event) =>
                                    this.setState({ isReject: true, modalVisible: true })
                                }
                            />
                        </Flex>
                    </Box>
                </View>
            </ScrollView>
        );
    }
}

class Default extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const props = this.props;

        return (
            <DimensionAware
                render={(dimensions) => (
                    <Page
                        {...{
                            ...props,
                            width: getWindowWidth(dimensions),
                            height: getWindowHeight(dimensions),
                            marginBottom: 25,
                        }}
                    />
                )}
            />
        );
    }
}

const mapStateToProps = ({ workflows, myTasks, toasts, staticData }) => {
    const { fetching, alert } = myTasks;
    const {
        fetchingfnGroupData,
        statusBarData,
        functionalGroupDetails,
        TasksStatusByTeamId,
        fetchingStatusBar,
        dunsFields = {},
    } = workflows;
    const { data, fetching: staticFetching } = staticData;
    return {
        fetching: fetching || fetchingStatusBar || fetchingfnGroupData || staticFetching,
        dunsFields,
        alert,
        statusBarData,
        functionalGroupDetails,
        TasksStatusByTeamId,
        toasts,
        staticData: data,
    };
};

export default connect(mapStateToProps, {
    saveApolloMyTaskContracts,
    getFunctionalGroupData,
    getStatusBarData,
    removeMessage,
    fetchDuns,
    getStaticRequest,
})(Default);

const styles = StyleSheet.create({
    progressIndicator: {
        flex: 1,
        paddingBottom: 5,
        flexDirection: 'row-reverse',
        alignItems: 'flex-end',
    },
    statusText: {
        fontSize: 15,
        color: '#1D4289',
        fontFamily: 'Poppins',
        textAlign: 'center',
        marginTop: 20,
    },
});
