import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import {
    SUCCESS_BGCOLOR,
    FAILED_BGCOLOR,
    SAVE_APOLLO_CUSTOMER_MASTER,
    SAVE_APOLLO_CONTRACTS,
    SAVE_APOLLO_CREDIT,
    SAVE_APOLLO_PRICING,
    SAVE_APOLLO_GLOBALTRADE,
    GET_TAX_JURISDICTION,
    RELEASE_CHECKLIST,
    SAVE_OTHER_REQUEST,
    EXTEND_RELEASE_CHECKLIST,
} from '../../constants/ActionTypes';
import { showMessage, setTaxJurisdictionData } from '../../appRedux/actions/MyTasks';
import { showMessage as showToast } from '../../appRedux/actions/Toast';
import { UploadFiles } from './Customer';

import {
    getFunctionalGroupData,
    getStatusBarData,
    updateTaskStatus,
} from '../../appRedux/actions/Workflow';

import { TASK_APPROVED, TASK_REJECTED } from '../../constants/WorkflowEnums';

import { ajaxPostRequest, ajaxPutFileRequest, endpoints } from './config';

export function* getTaxJurisdictionDetails(data) {
    yield put(
        showToast({
            msg: 'Fetching Tax Jurisdiction',
            color: '#2980b9',
            delay: 100,
        })
    );
    try {
        var resp = { msg: '', color: '#FFF', delay: '' };
        var jsonBody = data.payload;
       //' var url = endpoints.getTaxJurisdiction;
     //   const result = yield call(ajaxPostRequest, url, jsonBody);

      const result = {IsSuccess: true, ResultData:{TaxJurisdictions: ["DE814613497"]}}
        let msg, color, delay;

        if (result.IsSuccess) {
            var data = result.ResultData.TaxJurisdictions;
            if (data.length === 0) {
                msg = 'No Valid Tax Jurisdiction Found';
                color = FAILED_BGCOLOR;
                delay: 10000;
            } else {
                msg = 'Tax Jurisdiction Found';
                color = SUCCESS_BGCOLOR;
            }
            resp = { msg: msg, color: color, taxData: data };
            yield put(showToast(resp));
            yield put(setTaxJurisdictionData(resp));
        } else {
            resp = {
                msg: 'No Valid Tax Jurisdiction Found',
                color: FAILED_BGCOLOR,
                delay: 10000,
            };
            yield put(showToast(resp));
        }
    } catch (error) {
        resp = { msg: error, color: FAILED_BGCOLOR };
        yield put(showToast(resp));
    }
}

export function* saveApolloCustMaster(data) {
    try {
        var resp = { msg: '', color: '#FFF' };
        var jsonBody = data.payload.formData;
        var url = endpoints.saveApolloCustMaster;
        const result = yield call(ajaxPostRequest, url, jsonBody);
        if (!result.IsSuccess) {
            let errorMessage = 'Error saving data: ';
            if (
                result.OperationResultMessages != null &&
                result.OperationResultMessages.length > 0
            ) {
                result.OperationResultMessages.forEach((operationResultMessage) => {
                    if (operationResultMessage.OperationalResultType == 3) {
                        errorMessage =
                            errorMessage + operationResultMessage.Message + ' ';
                    }
                });
            }

            resp = { msg: errorMessage, color: FAILED_BGCOLOR };
            yield put(showMessage(resp));
        } else {
            const postJson = {
                workflowId: jsonBody.WorkflowTaskModel.WorkflowId,
                fuctionalGroup: 'customermaster',
                taskId: jsonBody.WorkflowTaskModel.TaskId,
            };

            yield put(getFunctionalGroupData(postJson));
            // yield put(getStatusBarData(postJson));
            yield put(
                updateTaskStatus({
                    teamId: result.ResultData.WorkflowTeamType,
                    status:
                        result.ResultData.WorkflowTaskModel.WorkflowTaskOperationType ===
                        1
                            ? TASK_APPROVED
                            : TASK_REJECTED,
                })
            );

            resp = {
                msg: 'Successfully saved the data',
                color: SUCCESS_BGCOLOR,
                success: true,
            };
            yield put(showMessage(resp));
        }

        //fileupload aws
        if (data.payload.files && data.payload.files.length > 0) {
            yield put(
                showToast({
                    msg: 'Uploading files',
                    color: '#2980b9',
                })
            );
            var files = data.payload.files;
            // console.log(
            //     'uploading attachments cust master: ',
            //     jsonBody.WorkflowTaskModel.WorkflowId
            // );
            yield* UploadFiles(files, jsonBody.WorkflowTaskModel.WorkflowId);
        }
    } catch (error) {
        resp = { msg: error, color: FAILED_BGCOLOR };
        yield put(showMessage(resp));
    }
}

export function* saveApolloCredits(data) {
    try {
        var resp = { msg: '', color: '#FFF' };
        var jsonBody = data.payload.formdata;
        var url = endpoints.saveApolloCredit;
        const result = yield call(ajaxPostRequest, url, jsonBody);
        if (!result.IsSuccess) {
            resp = { msg: 'Error saving data', color: FAILED_BGCOLOR };
            yield put(showMessage(resp));
        } else {
            const postJson = {
                workflowId: jsonBody.WorkflowTaskModel.WorkflowId,
                fuctionalGroup: 'credit',
                taskId: jsonBody.WorkflowTaskModel.TaskId,
                systemTypeId: jsonBody.WorkflowTaskModel.SystemTypeId,
            };

            yield put(getFunctionalGroupData(postJson));
            // yield put(getStatusBarData(postJson));
            yield put(
                updateTaskStatus({
                    teamId: result.ResultData.WorkflowTeamType,
                    status:
                        result.ResultData.WorkflowTaskModel.WorkflowTaskOperationType ===
                        1
                            ? TASK_APPROVED
                            : TASK_REJECTED,
                })
            );

            resp = {
                msg: 'Successfully saved the data',
                color: SUCCESS_BGCOLOR,
                success: true,
            };
            yield put(showMessage(resp));
        }
        //fileupload aws
        if (data.payload.files && data.payload.files.length > 0) {
            yield put(
                showToast({
                    msg: 'Uploading files',
                    color: '#2980b9',
                })
            );
            var files = data.payload.files;
            // console.log(
            //     'uploading attachments credit: ',
            //     jsonBody.WorkflowTaskModel.WorkflowId
            // );
            yield* UploadFiles(files, jsonBody.WorkflowTaskModel.WorkflowId);
        }
    } catch (error) {
        resp = { msg: error, color: FAILED_BGCOLOR };
        yield put(showMessage(resp));
    }
}

export function* saveApolloContracts(data) {
    try {
        let resp = { msg: '', color: '#FFF' };
        let fileUploadStatus = 'Unsuccessful',
            formDataStatus = 'Unsuccessful';
        //save form inputs

        var formBody = data.payload.formdata;
        var url = endpoints.saveApolloContracts;
        const result = yield call(ajaxPostRequest, url, formBody);
        if (result.IsSuccess) {
            resp.taskStatus = {
                taskStatus: { teamId: 5, status: TASK_APPROVED },
            };
            const postJson = {
                workflowId: formBody.WorkflowTaskModel.WorkflowId,
                fuctionalGroup: 'contracts',
                taskId: formBody.WorkflowTaskModel.TaskId,
                systemTypeId: formBody.WorkflowTaskModel.SystemTypeId,
            };

            yield put(getFunctionalGroupData(postJson));
            yield put(
                updateTaskStatus({
                    teamId: result.ResultData.WorkflowTeamType,
                    status:
                        result.ResultData.WorkflowTaskModel.WorkflowTaskOperationType ===
                        1
                            ? TASK_APPROVED
                            : TASK_REJECTED,
                })
            );

            resp = { msg: 'Successful Saving Data', color: SUCCESS_BGCOLOR };
            yield put(showMessage(resp));
        } else {
            resp = { msg: 'UnSuccessful Saving Data', color: FAILED_BGCOLOR };
            yield put(showMessage(resp));
        }

        // save document into aws
        if (data.payload.files && data.payload.files.length > 0) {
            yield put(
                showToast({
                    msg: 'Uploading files',
                    color: '#2980b9',
                })
            );
            var files = data.payload.files;
            yield* UploadFiles(files, formBody.WorkflowTaskModel.WorkflowId);
        }
    } catch (error) {
        let resp = { msg: '', color: '#FFF' };
        resp = { msg: error, color: FAILED_BGCOLOR };
        yield put(showMessage(resp));
    }
}

export function* saveApolloPricing({ payload }) {
    console.log(payload);
    try {
        var resp = { msg: '', color: '#FFF' };
        const { files, formdata: jsonBody } = payload;
        const {
            WorkflowTaskModel: { WorkflowId },
        } = jsonBody;
        var url = endpoints.saveApolloPricing;

        console.log(files, WorkflowId);
        if (files && files.length > 0) {
            yield put(
                showToast({
                    msg: 'Uploading files',
                    color: '#2980b9',
                })
            );

            yield* UploadFiles(files, WorkflowId);
        }

        const result = yield call(ajaxPostRequest, url, jsonBody);
        if (!result.IsSuccess) {
            resp = { msg: 'Error saving data', color: FAILED_BGCOLOR };
            yield put(showMessage(resp));
        } else {
            const postJson = {
                workflowId: jsonBody.WorkflowTaskModel.WorkflowId,
                fuctionalGroup: 'pricing',
                taskId: jsonBody.WorkflowTaskModel.TaskId,
                systemTypeId: jsonBody.WorkflowTaskModel.SystemTypeId,
            };

            yield put(getFunctionalGroupData(postJson));
            // yield put(getStatusBarData(postJson));
            yield put(
                updateTaskStatus({
                    teamId: result.ResultData.WorkflowTeamType,
                    status:
                        result.ResultData.WorkflowTaskModel.WorkflowTaskOperationType ===
                        1
                            ? TASK_APPROVED
                            : TASK_REJECTED,
                })
            );

            resp = {
                msg: 'Successfully saved the data',
                color: SUCCESS_BGCOLOR,
                success: true,
            };
            yield put(showMessage(resp));
        }
    } catch (error) {
        resp = { msg: error, color: FAILED_BGCOLOR };
        yield put(showMessage(resp));
    }
}

export function* saveOtherRequest(data) {
    try {
        var resp = { msg: '', color: '#FFF' };
        var jsonBody = data.payload.formdata;

        var url = endpoints.SaveTaskOtherCustomerMasterApi;
        const result = yield call(ajaxPostRequest, url, jsonBody);
        if (!result.IsSuccess) {
            resp = { msg: 'Error saving data', color: FAILED_BGCOLOR };
            yield put(showMessage(resp));
        } else {
            const postJson = {
                workflowId: jsonBody.WorkflowTaskModel.WorkflowId,
                fuctionalGroup: 'customermaster',
                taskId: jsonBody.WorkflowTaskModel.TaskId,
            };

            yield put(getFunctionalGroupData(postJson));
            // yield put(getStatusBarData(postJson));
            yield put(
                updateTaskStatus({
                    teamId: result.ResultData.WorkflowTeamType,
                    status:
                        result.ResultData.WorkflowTaskModel.WorkflowTaskOperationType ===
                        1
                            ? TASK_APPROVED
                            : TASK_REJECTED,
                })
            );

            resp = {
                msg: 'Successfully saved the data',
                color: SUCCESS_BGCOLOR,
                success: true,
            };
            yield put(showMessage(resp));

            console.log(data.payload.files);
            //fileupload aws
            if (data.payload.files && data.payload.files.length > 0) {
                yield put(
                    showToast({
                        msg: 'Uploading files',
                        color: '#2980b9',
                    })
                );
                var files = data.payload.files;
                // console.log(
                //     'uploading attachments credit: ',
                //     jsonBody.WorkflowTaskModel.WorkflowId
                // );
                yield* UploadFiles(files, jsonBody.WorkflowTaskModel.WorkflowId, true);
            }
        }
    } catch (error) {
        resp = { msg: error, color: FAILED_BGCOLOR };
        yield put(showMessage(resp));
    }
}

export function* saveApolloGlobalTrade(data) {
    try {
        var resp = { msg: '', color: '#FFF' };
        var jsonBody = data.payload.formdata;
        var url = endpoints.saveApolloGlobalTrade;
        const result = yield call(ajaxPostRequest, url, jsonBody);
        if (!result.IsSuccess) {
            resp = { msg: 'Error saving data', color: FAILED_BGCOLOR };
            yield put(showMessage(resp));
        } else {
            const postJson = {
                workflowId: jsonBody.WorkflowTaskModel.WorkflowId,
                fuctionalGroup: 'globaltrade',
                taskId: jsonBody.WorkflowTaskModel.TaskId,
                systemTypeId: jsonBody.WorkflowTaskModel.SystemTypeId,
            };

            yield put(getFunctionalGroupData(postJson));
            // yield put(getStatusBarData(postJson));
            yield put(
                updateTaskStatus({
                    teamId: result.ResultData.WorkflowTeamType,
                    status:
                        result.ResultData.WorkflowTaskModel.WorkflowTaskOperationType ===
                        1
                            ? TASK_APPROVED
                            : TASK_REJECTED,
                })
            );
            resp = {
                msg: 'Successfully saved the data',
                color: SUCCESS_BGCOLOR,
            };
            yield put(showMessage(resp));
        }
        // save document into aws
        if (data.payload.files && data.payload.files.length > 0) {
            yield put(
                showToast({
                    msg: 'Uploading files',
                    color: '#2980b9',
                })
            );
            var files = data.payload.files;
            yield* UploadFiles(files, jsonBody.WorkflowTaskModel.WorkflowId);
        }
    } catch (error) {
        resp = { msg: error, color: FAILED_BGCOLOR };
        yield put(showMessage(resp));
    }
}

export function* release_check_list({ payload }) {
    console.log(payload);
    const { files, formData: jsonBody } = payload;
    const { WorkflowId } = jsonBody;
    var teamId = payload.teamId;
    var url = endpoints.releaseCheckList;
    var resp = { msg: '', color: '#FFF' };
    try {
        console.log(files);
        if (files && files.length > 0) {
            yield put(
                showToast({
                    msg: 'Uploading files',
                    color: '#2980b9',
                })
            );

            yield* UploadFiles(files, WorkflowId);
        }

        const result = yield call(ajaxPostRequest, url, jsonBody);

        if (!result.IsSuccess) {
            resp = { msg: 'Error saving data', color: FAILED_BGCOLOR };
            yield put(showMessage(resp));
        } else {
            yield put(
                updateTaskStatus({
                    teamId: teamId,
                    status:
                        result.ResultData.WorkflowTaskModel.WorkflowTaskOperationType ===
                        1
                            ? TASK_APPROVED
                            : TASK_REJECTED,
                })
            );

            resp = {
                msg: 'Successfully saved the data',
                color: SUCCESS_BGCOLOR,
                success: true,
            };
            yield put(showMessage(resp));
        }
    } catch (error) {
        resp = { msg: error, color: FAILED_BGCOLOR };
        yield put(showMessage(resp));
    }
}

export function* extend_release_check_list({ payload }) {
    console.log(payload);
    const { files, formData: jsonBody } = payload;
    const { WorkflowId } = jsonBody;
    var teamId = payload.teamId;
    var url = endpoints.extendReleaseCheckList;
    var resp = { msg: '', color: '#FFF' };
    try {
        console.log(files);
        if (files && files.length > 0) {
            yield put(
                showToast({
                    msg: 'Uploading files',
                    color: '#2980b9',
                })
            );

            yield* UploadFiles(files, WorkflowId);
        }
        const result = yield call(ajaxPostRequest, url, jsonBody);

        if (!result.IsSuccess) {
            resp = { msg: 'Error saving data', color: FAILED_BGCOLOR };
            yield put(showMessage(resp));
        } else {
            yield put(
                updateTaskStatus({
                    teamId: teamId,
                    status:
                        result.ResultData.WorkflowTaskModel.WorkflowTaskOperationType ===
                        1
                            ? TASK_APPROVED
                            : TASK_REJECTED,
                })
            );

            resp = {
                msg: 'Successfully saved the data',
                color: SUCCESS_BGCOLOR,
                success: true,
            };
            yield put(showMessage(resp));
        }
    } catch (error) {
        resp = { msg: error, color: FAILED_BGCOLOR };
        yield put(showMessage(resp));
    }
}
export function* saveApolloCustomerMasterData() {
    yield takeLatest(SAVE_APOLLO_CUSTOMER_MASTER, saveApolloCustMaster);
}
export function* saveApolloContractsData() {
    yield takeLatest(SAVE_APOLLO_CONTRACTS, saveApolloContracts);
}

export function* saveApolloCreditData() {
    yield takeLatest(SAVE_APOLLO_CREDIT, saveApolloCredits);
}

export function* saveApolloPricingData() {
    yield takeLatest(SAVE_APOLLO_PRICING, saveApolloPricing);
}

export function* saveApolloGlobalTradeData() {
    yield takeLatest(SAVE_APOLLO_GLOBALTRADE, saveApolloGlobalTrade);
}

export function* getTaxJurisdictionData() {
    yield takeLatest(GET_TAX_JURISDICTION, getTaxJurisdictionDetails);
}

export function* release_task_check_list() {
    yield takeLatest(RELEASE_CHECKLIST, release_check_list);
}
export function* extend_release_task_check_list() {
    yield takeLatest(EXTEND_RELEASE_CHECKLIST, extend_release_check_list);
}
export function* save_other_request() {
    yield takeLatest(SAVE_OTHER_REQUEST, saveOtherRequest);
}

const myTasksSagas = function* rootSaga() {
    yield all([
        fork(saveApolloCustomerMasterData),
        fork(saveApolloContractsData),
        fork(saveApolloCreditData),
        fork(saveApolloPricingData),
        fork(saveApolloGlobalTradeData),
        fork(getTaxJurisdictionData),
        fork(release_task_check_list),
        fork(extend_release_task_check_list),
        fork(save_other_request),
    ]);
};
export default myTasksSagas;
