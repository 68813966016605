import { mapKeys, upperFirst, camelCase } from 'lodash';
import { drop } from 'ramda';

const normalize = (arr) => {
    const reducer = (accumulator, currentValue) => {
        if (currentValue.value)
            accumulator[currentValue.id] =
                currentValue.value + ' : ' + currentValue.description;
        else accumulator[currentValue.id] = currentValue.description;
        return accumulator;
    };

    return arr.reduce(reducer, {});
};

export const fetchRoleFieldMapping = () => {
    return new Promise((resolve, reject) =>
        resolve({
            RoleFields: [
                {
                    id: 1,
                    SystemTypeId: 1,
                    Role: 'soldTo',
                    RoleTypeId: 1,
                    Fields: [
                        'CustomerGroupTypeId',
                        'PaymentTermsTypeId',
                        'AccountTypeId',
                        'PaymentTermsTypeId',
                        'RiskCategoryTypeId',
                        'CreditRepGroupTypeId',
                        'CreditLimit',
                        'CredInfoNumber',
                        'PaymentIndex',
                        'LastExtReview',
                        'Rating',
                        'SpecialPricingTypeId',
                        'DistLevelTypeId',
                        'DAPAPricingTypeId',
                        'DAPA2PricingTypeId',
                        'FSSPricingTypeId',
                        'TransporationZone',
                        'License',
                        'LicenseExpDate',
                        'SearchTerm1',
                        'SearchTerm2',
                        'CustomerClassTypeId',
                        'CustomerPriceProcTypeId',
                        'IndustryCodeTypeId',
                        'MarketingSegmentationTypeId',
                        'ReconAccountTypeId',
                        'SalesOfficeTypeId',
                        'CustomerGroupTypeId',
                        'PpcustProcTypeId',
                        'TaxNumber2',
                        'SortKey',
                        'PaymentMethods',
                        'AcctgClerk',
                        'AccountStatementTypeId',
                        'TaxClassificationTypeId',
                        'PriceListTypeId',
                        'DeliveryPriorityTypeId',
                        'ShippingConditionsTypeId',
                        'IncoTerms1TypeId',
                        'IncoTerms2',
                        'AcctAssignmentGroupTypeId',
                        'AccountTypeId',
                        'ShippingCustomerTypeId',
                        'OrderCombination',
                        'PaymentHistoryRecord',
                    ],
                },
                {
                    id: 2,
                    SystemTypeId: 1,
                    Role: 'shipTo',
                    RoleTypeId: 2,
                    Fields: [
                        'CustomerGroupTypeId',
                        'PaymentTermsTypeId',
                        'AccountTypeId',
                        'PaymentTermsTypeId',
                        'SpecialPricingTypeId',
                        'DistLevelTypeId',
                        'DAPAPricingTypeId',
                        'DAPA2PricingTypeId',
                        'FSSPricingTypeId',
                        'TransporationZone',
                        'License',
                        'LicenseExpDate',
                        'SearchTerm1',
                        'SearchTerm2',
                        'CustomerClassTypeId',
                        'CustomerPriceProcTypeId',
                        'IndustryCodeTypeId',
                        'MarketingSegmentationTypeId',
                        'ReconAccountTypeId',
                        'SalesOfficeTypeId',
                        'CustomerGroupTypeId',
                        'PpcustProcTypeId',
                        'TaxNumber2',
                        'SortKey',
                        'PaymentMethods',
                        'AcctgClerk',
                        'AccountStatementTypeId',
                        'TaxClassificationTypeId',
                        'PriceListTypeId',
                        'DeliveryPriorityTypeId',
                        'ShippingConditionsTypeId',
                        'IncoTerms1TypeId',
                        'IncoTerms2',
                        'AcctAssignmentGroupTypeId',
                        'AccountTypeId',
                        'ShippingCustomerTypeId',
                        'OrderCombination',
                        'PaymentHistoryRecord',
                    ],
                },
                {
                    id: 3,
                    SystemTypeId: 1,
                    Role: 'payer',
                    RoleTypeId: 3,
                    Fields: [
                        'PaymentTermsTypeId',
                        'AccountTypeId',
                        'PaymentTermsTypeId',
                        'RiskCategoryTypeId',
                        'CreditRepGroupTypeId',
                        'CreditLimit',
                        'CredInfoNumber',
                        'PaymentIndex',
                        'LastExtReview',
                        'Rating',
                        'SpecialPricingTypeId',
                        'DistLevelTypeId',
                        'DAPAPricingTypeId',
                        'DAPA2PricingTypeId',
                        'FSSPricingTypeId',
                        'SearchTerm1',
                        'SearchTerm2',
                        'CustomerClassTypeId',
                        'ReconAccountTypeId',
                        'SalesOfficeTypeId',
                        'TaxNumber2',
                        'SortKey',
                        'PaymentMethods',
                        'AcctgClerk',
                        'AccountStatementTypeId',
                        'TaxClassificationTypeId',
                        'AcctAssignmentGroupTypeId',
                        'AccountTypeId',
                        'ShippingCustomerTypeId',
                        'PaymentHistoryRecord',
                    ],
                },
                {
                    id: 4,
                    SystemTypeId: 1,
                    Role: 'billto',
                    RoleTypeId: 4,
                    Fields: [
                        'PaymentTermsTypeId',
                        'AccountTypeId',
                        'PaymentTermsTypeId',
                        'SpecialPricingTypeId',
                        'DistLevelTypeId',
                        'DAPAPricingTypeId',
                        'DAPA2PricingTypeId',
                        'FSSPricingTypeId',
                        'SearchTerm1',
                        'SearchTerm2',
                        'CustomerClassTypeId',
                        'ReconAccountTypeId',
                        'SalesOfficeTypeId',
                        'TaxNumber2',
                        'SortKey',
                        'PaymentMethods',
                        'AcctgClerk',
                        'AccountStatementTypeId',
                        'AccountTypeId',
                        'ShippingCustomerTypeId',
                        'PaymentHistoryRecord',
                    ],
                },
                {
                    id: 5,
                    SystemTypeId: 1,
                    Role: 'salesRep',
                    RoleTypeId: 5,
                    Fields: [
                        'IncoTerms1TypeId',
                        'IncoTerms2',
                        'CustomerGroupTypeId',
                        'PaymentTermsTypeId',
                        'AccountTypeId',
                        'RiskCategoryTypeId',
                        'CreditRepGroupTypeId',
                        'CreditLimit',
                        'TransporationZone',
                        'License',
                        'LicenseExpDate',
                        'SearchTerm1',
                        'SearchTerm2',
                        'CustomerClassTypeId',
                        'CustomerPriceProcTypeId',
                        'IndustryCodeTypeId',
                        'MarketingSegmentationTypeId',
                        'ReconAccountTypeId',
                        'SalesOfficeTypeId',
                        'CustomerGroupTypeId',
                        'PpcustProcTypeId',
                        'TaxNumber2',
                        'SortKey',
                        'PaymentMethods',
                        'AcctgClerk',
                        'AccountStatementTypeId',
                        'TaxClassificationTypeId',
                        'PriceListTypeId',
                        'DeliveryPriorityTypeId',
                        'ShippingConditionsTypeId',
                        'AcctAssignmentGroupTypeId',
                        'AccountTypeId',
                        'ShippingCustomerTypeId',
                        'OrderCombination',
                        'PaymentHistoryRecord',
                    ],
                },
                {
                    id: 6,
                    SystemTypeId: 1,
                    Role: 'dropShip',
                    RoleTypeId: 6,
                    Fields: [
                        'IncoTerms1TypeId',
                        'IncoTerms2',
                        'CustomerGroupTypeId',
                        'PaymentTermsTypeId',
                        'AccountTypeId',
                        'SpecialPricingTypeId',
                        'DistLevelTypeId',
                        'DAPAPricingTypeId',
                        'DAPA2PricingTypeId',
                        'FSSPricingTypeId',
                        'TransporationZone',
                        'License',
                        'LicenseExpDate',
                        'SearchTerm1',
                        'SearchTerm2',
                        'CustomerClassTypeId',
                        'CustomerPriceProcTypeId',
                        'IndustryCodeTypeId',
                        'MarketingSegmentationTypeId',
                        'ReconAccountTypeId',
                        'SalesOfficeTypeId',
                        'CustomerGroupTypeId',
                        'PpcustProcTypeId',
                        'TaxNumber2',
                        'SortKey',
                        'PaymentMethods',
                        'AcctgClerk',
                        'AccountStatementTypeId',
                        'TaxClassificationTypeId',
                        'PriceListTypeId',
                        'DeliveryPriorityTypeId',
                        'ShippingConditionsTypeId',
                        'IncoTerms1TypeId',
                        'IncoTerms2',
                        'AcctAssignmentGroupTypeId',
                        'AccountTypeId',
                        'ShippingCustomerTypeId',
                        'OrderCombination',
                        'PaymentHistoryRecord',
                    ],
                },
            ],
        })
    );
};

export const fetchTeamRoleFieldMapping = () => {
    return new Promise((resolve, reject) =>
        resolve({
            ContractTeamFields: [
                {
                    id: 1,
                    SystemTypeId: 1,
                    Role: 'soldTo',
                    RoleTypeId: 1,
                    Fields: [
                        'IncoTerms1TypeId',
                        'IncoTerms2',
                        'CustomerGroupTypeId',
                        'PaymentTermsTypeId',
                        'AccountTypeId',
                    ],
                },
                {
                    id: 2,
                    SystemTypeId: 1,
                    Role: 'shipTo',
                    RoleTypeId: 2,
                    Fields: [
                        'IncoTerms1TypeId',
                        'IncoTerms2',
                        'CustomerGroupTypeId',
                        'PaymentTermsTypeId',
                        'AccountTypeId',
                    ],
                },
                {
                    id: 3,
                    SystemTypeId: 1,
                    Role: 'payer',
                    RoleTypeId: 3,
                    Fields: ['PaymentTermsTypeId', 'AccountTypeId'],
                },
                {
                    id: 4,
                    SystemTypeId: 1,
                    Role: 'billto',
                    RoleTypeId: 4,
                    Fields: ['PaymentTermsTypeId', 'AccountTypeId'],
                },
                {
                    id: 5,
                    SystemTypeId: 1,
                    Role: 'salesRep',
                    RoleTypeId: 5,
                    Fields: [
                        'IncoTerms1TypeId',
                        'IncoTerms2',
                        'CustomerGroupTypeId',
                        'PaymentTermsTypeId',
                        'AccountTypeId',
                    ],
                },
                {
                    id: 6,
                    SystemTypeId: 1,
                    Role: 'dropShip',
                    RoleTypeId: 6,
                    Fields: [
                        'IncoTerms1TypeId',
                        'IncoTerms2',
                        'CustomerGroupTypeId',
                        'PaymentTermsTypeId',
                        'AccountTypeId',
                    ],
                },
            ],
            CreditTeamFields: [
                {
                    id: 1,
                    SystemTypeId: 1,
                    Role: 'soldTo',
                    RoleTypeId: 1,
                    Fields: [
                        'PaymentTermsTypeId',
                        'RiskCategoryTypeId',
                        'CreditRepGroupTypeId',
                        'CreditLimit',
                        'CredInfoNumber',
                        'PaymentIndex',
                        'LastExtReview',
                        'Rating',
                    ],
                },
                {
                    id: 2,
                    SystemTypeId: 1,
                    Role: 'shipTo',
                    RoleTypeId: 2,
                    Fields: ['PaymentTermsTypeId'],
                },
                {
                    id: 3,
                    SystemTypeId: 1,
                    Role: 'payer',
                    RoleTypeId: 3,
                    Fields: [
                        'PaymentTermsTypeId',
                        'RiskCategoryTypeId',
                        'CreditRepGroupTypeId',
                        'CreditLimit',
                        'CredInfoNumber',
                        'PaymentIndex',
                        'LastExtReview',
                        'Rating',
                    ],
                },
                {
                    id: 4,
                    SystemTypeId: 1,
                    Role: 'billto',
                    RoleTypeId: 4,
                    Fields: ['PaymentTermsTypeId'],
                },
                {
                    id: 5,
                    SystemTypeId: 1,
                    Role: 'salesRep',
                    RoleTypeId: 5,
                    Fields: [
                        'PaymentTermsTypeId',
                        'RiskCategoryTypeId',
                        'CreditRepGroupTypeId',
                        'CreditLimit',
                    ],
                },
                {
                    id: 6,
                    SystemTypeId: 1,
                    Role: 'dropShip',
                    RoleTypeId: 6,
                    Fields: ['PaymentTermsTypeId'],
                },
            ],
            PricingTeamFields: [
                {
                    id: 1,
                    SystemTypeId: 1,
                    Role: 'soldTo',
                    RoleTypeId: 1,
                    Fields: [
                        'SpecialPricingTypeId',
                        'DistLevelTypeId',
                        'DAPAPricingTypeId',
                        'DAPA2PricingTypeId',
                        'FSSPricingTypeId',
                    ],
                },
                {
                    id: 2,
                    SystemTypeId: 1,
                    Role: 'shipTo',
                    RoleTypeId: 2,
                    Fields: [
                        'SpecialPricingTypeId',
                        'DistLevelTypeId',
                        'DAPAPricingTypeId',
                        'DAPA2PricingTypeId',
                        'FSSPricingTypeId',
                    ],
                },
                {
                    id: 3,
                    SystemTypeId: 1,
                    Role: 'payer',
                    RoleTypeId: 3,
                    Fields: [
                        'SpecialPricingTypeId',
                        'DistLevelTypeId',
                        'DAPAPricingTypeId',
                        'DAPA2PricingTypeId',
                        'FSSPricingTypeId',
                    ],
                },
                {
                    id: 4,
                    SystemTypeId: 1,
                    Role: 'billto',
                    RoleTypeId: 4,
                    Fields: [
                        'SpecialPricingTypeId',
                        'DistLevelTypeId',
                        'DAPAPricingTypeId',
                        'DAPA2PricingTypeId',
                        'FSSPricingTypeId',
                    ],
                },
                { id: 5, SystemTypeId: 1, Role: 'salesRep', RoleTypeId: 5, Fields: [] },
                {
                    id: 6,
                    SystemTypeId: 1,
                    Role: 'dropShip',
                    RoleTypeId: 6,
                    Fields: [
                        'SpecialPricingTypeId',
                        'DistLevelTypeId',
                        'DAPAPricingTypeId',
                        'DAPA2PricingTypeId',
                        'FSSPricingTypeId',
                    ],
                },
            ],
            CustomerMasterTeamFields: [
                {
                    id: 1,
                    SystemTypeId: 1,
                    Role: 'soldTo',
                    RoleTypeId: 1,
                    Fields: [
                        'TransporationZone',
                        'License',
                        'LicenseExpDate',
                        'SearchTerm1',
                        'SearchTerm2',
                        'CustomerClassTypeId',
                        'CustomerPriceProcTypeId',
                        'IndustryCodeTypeId',
                        'MarketingSegmentationTypeId',
                        'ReconAccountTypeId',
                        'SalesOfficeTypeId',
                        'CustomerGroupTypeId',
                        'PpcustProcTypeId',
                        'TaxNumber2',
                        'SortKey',
                        'PaymentMethods',
                        'AcctgClerk',
                        'AccountStatementTypeId',
                        'TaxClassificationTypeId',
                        'PriceListTypeId',
                        'DeliveryPriorityTypeId',
                        'ShippingConditionsTypeId',
                        'IncoTerms1TypeId',
                        'IncoTerms2',
                        'AcctAssignmentGroupTypeId',
                        'AccountTypeId',
                        'ShippingCustomerTypeId',
                        'OrderCombination',
                        'PaymentHistoryRecord',
                    ],
                },
                {
                    id: 2,
                    SystemTypeId: 1,
                    Role: 'shipTo',
                    RoleTypeId: 2,
                    Fields: [
                        'TransporationZone',
                        'License',
                        'LicenseExpDate',
                        'SearchTerm1',
                        'SearchTerm2',
                        'CustomerClassTypeId',
                        'CustomerPriceProcTypeId',
                        'IndustryCodeTypeId',
                        'MarketingSegmentationTypeId',
                        'ReconAccountTypeId',
                        'SalesOfficeTypeId',
                        'CustomerGroupTypeId',
                        'PpcustProcTypeId',
                        'TaxNumber2',
                        'SortKey',
                        'PaymentMethods',
                        'AcctgClerk',
                        'AccountStatementTypeId',
                        'TaxClassificationTypeId',
                        'PriceListTypeId',
                        'DeliveryPriorityTypeId',
                        'ShippingConditionsTypeId',
                        'IncoTerms1TypeId',
                        'IncoTerms2',
                        'AcctAssignmentGroupTypeId',
                        'AccountTypeId',
                        'ShippingCustomerTypeId',
                        'OrderCombination',
                        'PaymentHistoryRecord',
                    ],
                },
                {
                    id: 3,
                    SystemTypeId: 1,
                    Role: 'payer',
                    RoleTypeId: 3,
                    Fields: [
                        'SearchTerm1',
                        'SearchTerm2',
                        'CustomerClassTypeId',
                        'ReconAccountTypeId',
                        'SalesOfficeTypeId',
                        'TaxNumber2',
                        'SortKey',
                        'PaymentMethods',
                        'AcctgClerk',
                        'AccountStatementTypeId',
                        'TaxClassificationTypeId',
                        'AcctAssignmentGroupTypeId',
                        'AccountTypeId',
                        'ShippingCustomerTypeId',
                        'PaymentHistoryRecord',
                    ],
                },
                {
                    id: 4,
                    SystemTypeId: 1,
                    Role: 'billto',
                    RoleTypeId: 4,
                    Fields: [
                        'SearchTerm1',
                        'SearchTerm2',
                        'CustomerClassTypeId',
                        'ReconAccountTypeId',
                        'SalesOfficeTypeId',
                        'TaxNumber2',
                        'SortKey',
                        'PaymentMethods',
                        'AcctgClerk',
                        'AccountStatementTypeId',
                        'AccountTypeId',
                        'ShippingCustomerTypeId',
                        'PaymentHistoryRecord',
                    ],
                },
                {
                    id: 5,
                    SystemTypeId: 1,
                    Role: 'salesRep',
                    RoleTypeId: 5,
                    Fields: [
                        'TransporationZone',
                        'License',
                        'LicenseExpDate',
                        'SearchTerm1',
                        'SearchTerm2',
                        'CustomerClassTypeId',
                        'CustomerPriceProcTypeId',
                        'IndustryCodeTypeId',
                        'MarketingSegmentationTypeId',
                        'ReconAccountTypeId',
                        'SalesOfficeTypeId',
                        'CustomerGroupTypeId',
                        'PpcustProcTypeId',
                        'TaxNumber2',
                        'SortKey',
                        'PaymentMethods',
                        'AcctgClerk',
                        'AccountStatementTypeId',
                        'TaxClassificationTypeId',
                        'PriceListTypeId',
                        'DeliveryPriorityTypeId',
                        'ShippingConditionsTypeId',
                        'IncoTerms1TypeId',
                        'IncoTerms2',
                        'AcctAssignmentGroupTypeId',
                        'AccountTypeId',
                        'ShippingCustomerTypeId',
                        'OrderCombination',
                        'PaymentHistoryRecord',
                    ],
                },
                {
                    id: 6,
                    SystemTypeId: 1,
                    Role: 'dropShip',
                    RoleTypeId: 6,
                    Fields: [
                        'TransporationZone',
                        'License',
                        'LicenseExpDate',
                        'SearchTerm1',
                        'SearchTerm2',
                        'CustomerClassTypeId',
                        'CustomerPriceProcTypeId',
                        'IndustryCodeTypeId',
                        'MarketingSegmentationTypeId',
                        'ReconAccountTypeId',
                        'SalesOfficeTypeId',
                        'CustomerGroupTypeId',
                        'PpcustProcTypeId',
                        'TaxNumber2',
                        'SortKey',
                        'PaymentMethods',
                        'AcctgClerk',
                        'AccountStatementTypeId',
                        'TaxClassificationTypeId',
                        'PriceListTypeId',
                        'DeliveryPriorityTypeId',
                        'ShippingConditionsTypeId',
                        'IncoTerms1TypeId',
                        'IncoTerms2',
                        'AcctAssignmentGroupTypeId',
                        'AccountTypeId',
                        'ShippingCustomerTypeId',
                        'OrderCombination',
                        'PaymentHistoryRecord',
                    ],
                },
            ],
        })
    );
};

export const fetchCreateTeamRoleFieldMapping = () => {
    return new Promise((resolve, reject) =>
        resolve({
            ContractTeamFields: [
                {
                    id: 1,
                    SystemTypeId: 1,
                    Role: 'soldTo',
                    RoleTypeId: 1,
                    Fields: [
                        'IncoTerms1TypeId',
                        'IncoTerms2',
                        'CustomerGroupTypeId',
                        'PaymentTermsTypeId',
                        'AccountTypeId',
                    ],
                },
                {
                    id: 2,
                    SystemTypeId: 1,
                    Role: 'shipTo',
                    RoleTypeId: 2,
                    Fields: [
                        'IncoTerms1TypeId',
                        'IncoTerms2',
                        'CustomerGroupTypeId',
                        'PaymentTermsTypeId',
                        'AccountTypeId',
                    ],
                },
                {
                    id: 3,
                    SystemTypeId: 1,
                    Role: 'payer',
                    RoleTypeId: 3,
                    Fields: ['PaymentTermsTypeId', 'AccountTypeId'],
                },
                {
                    id: 4,
                    SystemTypeId: 1,
                    Role: 'billto',
                    RoleTypeId: 4,
                    Fields: ['PaymentTermsTypeId', 'AccountTypeId'],
                },
                {
                    id: 5,
                    SystemTypeId: 1,
                    Role: 'salesRep',
                    RoleTypeId: 5,
                    Fields: [
                        'IncoTerms1TypeId',
                        'IncoTerms2',
                        'CustomerGroupTypeId',
                        'PaymentTermsTypeId',
                        'AccountTypeId',
                    ],
                },
                {
                    id: 6,
                    SystemTypeId: 1,
                    Role: 'dropShip',
                    RoleTypeId: 6,
                    Fields: [
                        'IncoTerms1TypeId',
                        'IncoTerms2',
                        'CustomerGroupTypeId',
                        'PaymentTermsTypeId',
                        'AccountTypeId',
                    ],
                },
            ],
            CreditTeamFields: [
                {
                    id: 1,
                    SystemTypeId: 1,
                    Role: 'soldTo',
                    RoleTypeId: 1,
                    Fields: [
                        'PaymentTermsTypeId',
                        'RiskCategoryTypeId',
                        'CreditRepGroupTypeId',
                        'CreditLimit',
                        'CredInfoNumber',
                        'PaymentIndex',
                        'LastExtReview',
                        'Rating',
                    ],
                },
                {
                    id: 2,
                    SystemTypeId: 1,
                    Role: 'shipTo',
                    RoleTypeId: 2,
                    Fields: [
                        'PaymentTermsTypeId',
                        'RiskCategoryTypeId',
                        'CreditRepGroupTypeId',
                        'CreditLimit',
                        'CredInfoNumber',
                        'PaymentIndex',
                        'LastExtReview',
                        'Rating',
                    ],
                },
                {
                    id: 3,
                    SystemTypeId: 1,
                    Role: 'payer',
                    RoleTypeId: 3,
                    Fields: [
                        'PaymentTermsTypeId',
                        'RiskCategoryTypeId',
                        'CreditRepGroupTypeId',
                        'CreditLimit',
                        'CredInfoNumber',
                        'PaymentIndex',
                        'LastExtReview',
                        'Rating',
                    ],
                },
                {
                    id: 4,
                    SystemTypeId: 1,
                    Role: 'billto',
                    RoleTypeId: 4,
                    Fields: ['PaymentTermsTypeId'],
                },
                {
                    id: 5,
                    SystemTypeId: 1,
                    Role: 'salesRep',
                    RoleTypeId: 5,
                    Fields: [
                        'PaymentTermsTypeId',
                        'RiskCategoryTypeId',
                        'CreditRepGroupTypeId',
                        'CreditLimit',
                    ],
                },
                {
                    id: 6,
                    SystemTypeId: 1,
                    Role: 'dropShip',
                    RoleTypeId: 6,
                    Fields: ['PaymentTermsTypeId'],
                },
            ],
            PricingTeamFields: [
                {
                    id: 1,
                    SystemTypeId: 1,
                    Role: 'soldTo',
                    RoleTypeId: 1,
                    Fields: [
                        'SpecialPricingTypeId',
                        'DistLevelTypeId',
                        'DAPAPricingTypeId',
                        'DAPA2PricingTypeId',
                        'FSSPricingTypeId',
                    ],
                },
                {
                    id: 2,
                    SystemTypeId: 1,
                    Role: 'shipTo',
                    RoleTypeId: 2,
                    Fields: [
                        'SpecialPricingTypeId',
                        'DistLevelTypeId',
                        'DAPAPricingTypeId',
                        'DAPA2PricingTypeId',
                        'FSSPricingTypeId',
                    ],
                },
                {
                    id: 3,
                    SystemTypeId: 1,
                    Role: 'payer',
                    RoleTypeId: 3,
                    Fields: [
                        'SpecialPricingTypeId',
                        'DistLevelTypeId',
                        'DAPAPricingTypeId',
                        'DAPA2PricingTypeId',
                        'FSSPricingTypeId',
                    ],
                },
                {
                    id: 4,
                    SystemTypeId: 1,
                    Role: 'billto',
                    RoleTypeId: 4,
                    Fields: [
                        'SpecialPricingTypeId',
                        'DistLevelTypeId',
                        'DAPAPricingTypeId',
                        'DAPA2PricingTypeId',
                        'FSSPricingTypeId',
                    ],
                },
                { id: 5, SystemTypeId: 1, Role: 'salesRep', RoleTypeId: 5, Fields: [] },
                {
                    id: 6,
                    SystemTypeId: 1,
                    Role: 'dropShip',
                    RoleTypeId: 6,
                    Fields: [
                        'SpecialPricingTypeId',
                        'DistLevelTypeId',
                        'DAPAPricingTypeId',
                        'DAPA2PricingTypeId',
                        'FSSPricingTypeId',
                    ],
                },
            ],
            CustomerMasterTeamFields: [
                {
                    id: 1,
                    SystemTypeId: 1,
                    Role: 'soldTo',
                    RoleTypeId: 1,
                    Fields: [
                        'TransporationZone',
                        'License',
                        'LicenseExpDate',
                        'SearchTerm1',
                        'SearchTerm2',
                        'CustomerClassTypeId',
                        'CustomerPriceProcTypeId',
                        'IndustryCodeTypeId',
                        'MarketingSegmentationTypeId',
                        'ReconAccountTypeId',
                        'SalesOfficeTypeId',
                        'CustomerGroupTypeId',
                        'PpcustProcTypeId',
                        'TaxNumber2',
                        'SortKey',
                        'PaymentMethods',
                        'AcctgClerk',
                        'AccountStatementTypeId',
                        'TaxClassificationTypeId',
                        'PriceListTypeId',
                        'DeliveryPriorityTypeId',
                        'ShippingConditionsTypeId',
                        'IncoTerms1TypeId',
                        'IncoTerms2',
                        'AcctAssignmentGroupTypeId',
                        'AccountTypeId',
                        'ShippingCustomerTypeId',
                        'OrderCombination',
                        'PaymentHistoryRecord',
                    ],
                },
                {
                    id: 2,
                    SystemTypeId: 1,
                    Role: 'shipTo',
                    RoleTypeId: 2,
                    Fields: [
                        'TransporationZone',
                        'License',
                        'LicenseExpDate',
                        'SearchTerm1',
                        'SearchTerm2',
                        'CustomerClassTypeId',
                        'CustomerPriceProcTypeId',
                        'IndustryCodeTypeId',
                        'MarketingSegmentationTypeId',
                        'ReconAccountTypeId',
                        'SalesOfficeTypeId',
                        'CustomerGroupTypeId',
                        'PpcustProcTypeId',
                        'TaxNumber2',
                        'SortKey',
                        'PaymentMethods',
                        'AcctgClerk',
                        'AccountStatementTypeId',
                        'TaxClassificationTypeId',
                        'PriceListTypeId',
                        'DeliveryPriorityTypeId',
                        'ShippingConditionsTypeId',
                        'IncoTerms1TypeId',
                        'IncoTerms2',
                        'AcctAssignmentGroupTypeId',
                        'AccountTypeId',
                        'ShippingCustomerTypeId',
                        'OrderCombination',
                        'PaymentHistoryRecord',
                    ],
                },
                {
                    id: 3,
                    SystemTypeId: 1,
                    Role: 'payer',
                    RoleTypeId: 3,
                    Fields: [
                        'SearchTerm1',
                        'SearchTerm2',
                        'CustomerClassTypeId',
                        'ReconAccountTypeId',
                        'SalesOfficeTypeId',
                        'TaxNumber2',
                        'SortKey',
                        'PaymentMethods',
                        'AcctgClerk',
                        'AccountStatementTypeId',
                        'TaxClassificationTypeId',
                        'AcctAssignmentGroupTypeId',
                        'AccountTypeId',
                        'ShippingCustomerTypeId',
                        'PaymentHistoryRecord',
                    ],
                },
                {
                    id: 4,
                    SystemTypeId: 1,
                    Role: 'billto',
                    RoleTypeId: 4,
                    Fields: [
                        'SearchTerm1',
                        'SearchTerm2',
                        'CustomerClassTypeId',
                        'ReconAccountTypeId',
                        'SalesOfficeTypeId',
                        'TaxNumber2',
                        'SortKey',
                        'PaymentMethods',
                        'AcctgClerk',
                        'AccountStatementTypeId',
                        'AccountTypeId',
                        'ShippingCustomerTypeId',
                        'PaymentHistoryRecord',
                    ],
                },
                {
                    id: 5,
                    SystemTypeId: 1,
                    Role: 'salesRep',
                    RoleTypeId: 5,
                    Fields: [
                        'TransporationZone',
                        'License',
                        'LicenseExpDate',
                        'SearchTerm1',
                        'SearchTerm2',
                        'CustomerClassTypeId',
                        'CustomerPriceProcTypeId',
                        'IndustryCodeTypeId',
                        'MarketingSegmentationTypeId',
                        'ReconAccountTypeId',
                        'SalesOfficeTypeId',
                        'CustomerGroupTypeId',
                        'PpcustProcTypeId',
                        'TaxNumber2',
                        'SortKey',
                        'PaymentMethods',
                        'AcctgClerk',
                        'AccountStatementTypeId',
                        'TaxClassificationTypeId',
                        'PriceListTypeId',
                        'DeliveryPriorityTypeId',
                        'ShippingConditionsTypeId',
                        'IncoTerms1TypeId',
                        'IncoTerms2',
                        'AcctAssignmentGroupTypeId',
                        'AccountTypeId',
                        'ShippingCustomerTypeId',
                        'OrderCombination',
                        'PaymentHistoryRecord',
                    ],
                },
                {
                    id: 6,
                    SystemTypeId: 1,
                    Role: 'dropShip',
                    RoleTypeId: 6,
                    Fields: [
                        'TransporationZone',
                        'License',
                        'LicenseExpDate',
                        'SearchTerm1',
                        'SearchTerm2',
                        'CustomerClassTypeId',
                        'CustomerPriceProcTypeId',
                        'IndustryCodeTypeId',
                        'MarketingSegmentationTypeId',
                        'ReconAccountTypeId',
                        'SalesOfficeTypeId',
                        'CustomerGroupTypeId',
                        'PpcustProcTypeId',
                        'TaxNumber2',
                        'SortKey',
                        'PaymentMethods',
                        'AcctgClerk',
                        'AccountStatementTypeId',
                        'TaxClassificationTypeId',
                        'PriceListTypeId',
                        'DeliveryPriorityTypeId',
                        'ShippingConditionsTypeId',
                        'IncoTerms1TypeId',
                        'IncoTerms2',
                        'AcctAssignmentGroupTypeId',
                        'AccountTypeId',
                        'ShippingCustomerTypeId',
                        'OrderCombination',
                        'PaymentHistoryRecord',
                    ],
                },
            ],
        })
    );
};

export const fetchCustomerMasterDropDownData = (
    normalizeData = false,
    returnBoth = false
) => {
    return new Promise((resolve, reject) => {
        let dropdowns = {
            RoleTypeId: [
                {
                    id: 1,
                    systemId: 1,
                    description: 'Sold To',
                },
                {
                    id: 2,
                    systemId: 1,
                    description: 'Ship To',
                },
                {
                    id: 3,
                    systemId: 1,
                    description: 'Payer',
                },
                {
                    id: 4,
                    systemId: 1,
                    description: 'Bill To',
                },
                {
                    id: 5,
                    systemId: 1,
                    description: 'Sales Rep',
                },
                {
                    id: 6,
                    systemId: 1,
                    description: 'Drop Ship',
                },
                {
                    id: 7,
                    systemId: 2,
                    description: 'SAP Olympus: Sold To',
                },
                {
                    id: 8,
                    systemId: 2,
                    description: 'SAP Olympus: Ship To',
                },
                {
                    id: 9,
                    systemId: 2,
                    description: 'SAP Olympus: Payer',
                },
                {
                    id: 10,
                    systemId: 2,
                    description: 'SAP Olympus: Bill To',
                },
                {
                    id: 11,
                    systemId: 2,
                    description: 'SAP Olympus: Sales Rep',
                },
                {
                    id: 12,
                    systemId: 3,
                    description: 'Pointman: Sold To',
                },
                {
                    id: 13,
                    systemId: 3,
                    description: 'Pointman: Ship To',
                },
                {
                    id: 14,
                    systemId: 3,
                    description: 'Pointman: Sales Rep',
                },
                {
                    id: 15,
                    systemId: 4,
                    description: 'Made2Manage: Sold To',
                },
                {
                    id: 16,
                    systemId: 4,
                    description: 'Made2Manage: Ship To',
                },
                {
                    id: 17,
                    systemId: 4,
                    description: 'Made2Manage: Sales Rep',
                },
                {
                    id: 18,
                    systemId: 5,
                    description: 'JD Edwards: Sold To',
                },
                {
                    id: 19,
                    systemId: 5,
                    description: 'JD Edwards: Ship To',
                },
                {
                    id: 20,
                    systemId: 5,
                    description: 'JD Edwards: Sales Rep',
                },
            ],
            CategoryTypeId: [
                { id: 1, description: 'Distributor' },
                { id: 2, description: 'Self-Distributor' },
                { id: 3, description: 'OEM' },
                { id: 4, description: 'Direct' },
                { id: 5, description: 'Other' },
                { id: 6, description: 'Kitter' },
                { id: 7, description: 'Drop Ship' },
            ],
            TaxClassificationTypeId: [
                { id: 1, value: '0', description: 'Tax Exempt' },
                { id: 2, value: '1', description: 'Taxable' },
            ],
            AccountStatementTypeId: [
                { id: 1, value: '1', description: 'Weekly Account Stmt' },
                { id: 2, value: '2', description: 'Monthly Account Stmt' },
            ],
            AccountTypeId: [
                { id: 1, description: 'DTR : Rebated Distributor' },
                { id: 2, description: 'INT : Internal' },
                { id: 3, description: 'IDV : Individual Consumer' },
                { id: 4, description: 'NRD : Non-Rebated Distributor' },
                { id: 5, description: 'SDT : Self Distributor' },
                { id: 6, description: 'IEX: International Exporter' },
                { id: 7, description: 'OEM: OEM' },
                { id: 8, description: 'KTR: Kitter' },
            ],
            CustomerGroupTypeId: [
                { id: 1, value: 1, description: 'Rbtd Dist - CAH' },
                { id: 2, value: 2, description: 'Affiliates' },
                { id: 3, value: 3, description: 'Alternate Site' },
                { id: 4, value: 4, description: 'Biomed Repair' },
                {
                    id: 5,
                    value: 5,
                    description: 'Non Rbtd Distributor/Self Distributor',
                },
                { id: 6, value: 6, description: 'Hospital' },
                { id: 7, value: 8, description: 'Internal' },
                { id: 8, value: 9, description: 'Intl Dealer/ Exporter' },
                { id: 9, value: 10, description: 'OEM/Kitter' },
                { id: 10, value: 12, description: 'Rbtd Dist - Non CAH' },
                { id: 11, value: 14, description: 'Third Party End User' },
            ],
            CustomerPriceProcTypeId: [
                { id: 1, value: 3, description: 'Affiliate' },
                { id: 2, value: 'G', description: 'MPT Gov pric proc' },
                { id: 3, value: 1, description: 'Standard' },
            ],
            PriceListTypeId: [
                { id: 1, value: 'A1', description: 'Intercompany' },
                { id: 2, value: 'VA', description: 'Government (VA)' },
                { id: 3, value: 'GV', description: 'Government (Non VA)' },
                {
                    id: 4,
                    value: 'DM',
                    description: 'Domestic (US, non-government)',
                },
                { id: 5, value: 'IN', description: 'International' },
            ],
            CustomerClassTypeId: [
                { id: 1, value: '01', description: 'Dept of Defense' },
                { id: 2, value: '02', description: 'Public Health Services' },
                { id: 3, value: '03', description: 'General Services Admin' },
                { id: 4, value: '04', description: 'Veterans Admin' },
                { id: 5, value: '05', description: 'State/Local' },
                { id: 6, value: '06', description: 'Non Government' },
            ],
            IndustryCodeTypeId: [
                { id: 1, value: '0001', description: 'CMA' },
                { id: 2, value: '0002', description: 'ICO' },
                { id: 3, value: '0003', description: 'OEM' },
                { id: 4, value: '0004', description: 'Distributor' },
                { id: 5, value: '0005', description: 'Government' },
            ],
            MarketingSegmentationTypeId: [
                { id: 1, value: '01', description: 'Acute' },
                { id: 2, value: '02', description: 'Non-Acute' },
                { id: 3, value: '03', description: 'Acute & Non-Acute' },
                { id: 4, value: '04', description: 'N/A' },
            ],
            ReconAccountTypeId: [
                {
                    id: 1,
                    value: '12100',
                    description: 'Customer/Trade Account',
                },
                { id: 2, value: '12900', description: 'Intercompany' },
            ],
            SalesOfficeTypeId: [
                { id: 1, value: '2100', description: 'Direct' },
                { id: 2, value: '2120', description: 'Sales Reps' },
                { id: 3, value: '2140', description: 'International' },
                { id: 4, value: '2200', description: 'Government' },
                { id: 5, value: '3500', description: 'Distributors' },
                { id: 6, value: '3700', description: 'OEM/Kitters' },
            ],
            PpcustProcTypeId: [
                { id: 1, value: 'A', description: 'Product Proposal' },
                { id: 2, value: 'B', description: 'Cross Selling' },
            ],
            PPCustProcTypeId: [
                { id: 1, value: 'A', description: 'Product Proposal' },
                { id: 2, value: 'B', description: 'Cross Selling' },
            ],
            CompanyCodeTypeId: [
                { id: 1, systemId: 1, value: '0120', description: 'Vyaire Medical' },
                {
                    id: 2,
                    systemId: 1,
                    value: '0150',
                    description: 'Vyaire Medical Canada',
                },
            ],
            DeliveryPriorityTypeId: [
                {
                    id: 1,
                    value: '30',
                    description: 'Ground Parcel',
                },
                { id: 2, value: '35', description: 'Ground LTL' },
                { id: 3, value: '40', description: 'International Ground' },
                {
                    id: 4,
                    value: '45',
                    description: 'International Ocean',
                },
            ],
            ShippingConditionsTypeId: [
                { id: 1, value: 'DM', description: 'Domestic ' },
                { id: 2, value: 'EX', description: 'International' },
            ],
            Incoterms1TypeId: [
                { id: 1, value: 'COL', description: 'Collect Freight from Consignee' },
                { id: 2, value: 'CP2', description: 'PrePaid FOB Origin' },
                { id: 3, value: 'CPT', description: 'Carriage Paid To' },
                { id: 4, value: 'DAP', description: 'Delivered at Location' },
                { id: 5, value: 'DDP', description: 'Delivered Duty Paid' },
                { id: 6, value: 'DPA', description: 'PrePaid+Bill FOB Destination' },
                { id: 7, value: 'EXW', description: 'Ex Works' },
                { id: 8, value: 'FCA', description: 'Free Carrier' },
                { id: 9, value: 'PPA', description: 'PrePaid+Bill FOB Origin' },
                { id: 10, value: 'PPD', description: 'PrePaid FOB Destination' },
            ],
            IncoTerms1TypeId: [
                { id: 1, value: 'COL', description: 'Collect Freight from Consignee' },
                { id: 2, value: 'CP2', description: 'PrePaid FOB Origin' },
                { id: 3, value: 'CPT', description: 'Carriage Paid To' },
                { id: 4, value: 'DAP', description: 'Delivered at Location' },
                { id: 5, value: 'DDP', description: 'Delivered Duty Paid' },
                { id: 6, value: 'DPA', description: 'PrePaid+Bill FOB Destination' },
                { id: 7, value: 'EXW', description: 'Ex Works' },
                { id: 8, value: 'FCA', description: 'Free Carrier' },
                { id: 9, value: 'PPA', description: 'PrePaid+Bill FOB Origin' },
                { id: 10, value: 'PPD', description: 'PrePaid FOB Destination' },
            ],
            AcctAssignmentGroupTypeId: [
                { id: 1, value: '01', description: 'Domestic' },
                { id: 2, value: '02', description: 'International' },
                { id: 3, value: 'ZA', description: 'InterCompany' },
            ],
            PartnerFunctionTypeId: [
                { id: 1, description: 'BP' },
                { id: 2, description: 'PY' },
                { id: 3, description: 'SH' },
                { id: 4, description: 'Y0' },
                { id: 5, description: 'YO' },
                { id: 6, description: 'YL' },
                { id: 7, description: 'YS' },
            ],
            ShippingCustomerTypeId: [
                { id: 1, value: 'DIR', description: 'Direct' },
                { id: 2, value: 'DIS', description: 'Distributor' },
                { id: 3, value: 'INT', description: 'International' },
                { id: 4, value: 'OEM', description: 'OEM' },
            ],
            IncoTermsTypeId: [
                { id: 1, value: 'COL', description: 'Collect Freight from Consignee' },
                { id: 2, value: 'CP2', description: 'PrePaid FOB Origin' },
                { id: 3, value: 'CPT', description: 'Carriage Paid To' },
                { id: 4, value: 'DAP', description: 'Delivered at Location' },
                { id: 5, value: 'DDP', description: 'Delivered Duty Paid' },
                { id: 6, value: 'DPA', description: 'PrePaid+Bill FOB Destination' },
                { id: 7, value: 'EXW', description: 'Ex Works' },
                { id: 8, value: 'FCA', description: 'Free Carrier' },
                { id: 9, value: 'PPA', description: 'PrePaid+Bill FOB Origin' },
                { id: 10, value: 'PPD', description: 'PrePaid FOB Destination' },
            ],
            IncoTerms1TypeId: [
                { id: 1, value: 'COL', description: 'Collect Freight from Consignee' },
                { id: 2, value: 'CP2', description: 'PrePaid FOB Origin' },
                { id: 3, value: 'CPT', description: 'Carriage Paid To' },
                { id: 4, value: 'DAP', description: 'Delivered at Location' },
                { id: 5, value: 'DDP', description: 'Delivered Duty Paid' },
                { id: 6, value: 'DPA', description: 'PrePaid+Bill FOB Destination' },
                { id: 7, value: 'EXW', description: 'Ex Works' },
                { id: 8, value: 'FCA', description: 'Free Carrier' },
                { id: 9, value: 'PPA', description: 'PrePaid+Bill FOB Origin' },
                { id: 10, value: 'PPD', description: 'PrePaid FOB Destination' },
            ],
            PaymentTermsTypeId: [
                { id: 1, value: 'Z000', description: 'Payable Immediately' },
                {
                    id: 2,
                    value: 'Z001',
                    description: 'Net 15 Days from invoice date',
                },
                {
                    id: 3,
                    value: 'Z002',
                    description: 'Net 30 Days from invoice date',
                },
                {
                    id: 4,
                    value: 'Z003',
                    description: 'Net 45 Days from invoice date',
                },
                {
                    id: 5,
                    value: 'Z004',
                    description: 'Net 60 Days from invoice date',
                },
                {
                    id: 6,
                    value: 'Z005',
                    description: 'Net 75 Days from invoice date',
                },
                {
                    id: 7,
                    value: 'Z006',
                    description: 'Net 90 Days from invoice date',
                },
                {
                    id: 8,
                    value: 'Z007',
                    description: 'Net 120 Days from invoice date',
                },
                {
                    id: 9,
                    value: 'Z008',
                    description: 'Net 180 Days from invoice date',
                },
                {
                    id: 10,
                    value: 'Z012',
                    description: 'Net 7 Days from invoice date',
                },
                {
                    id: 11,
                    value: 'Z020',
                    description: '1% discount within 15 days,net 30 days',
                },
                {
                    id: 12,
                    value: 'Z021',
                    description: '1% discount within 20 days,net 30 days',
                },
                {
                    id: 13,
                    value: 'Z022',
                    description: '1% discount within 30 days,net 31 days',
                },
                {
                    id: 14,
                    value: 'Z026',
                    description: '2% in 20 Days,Net 30 Days',
                },
                {
                    id: 15,
                    value: 'Z030',
                    description: '2% discount within 15 days,net 30 days',
                },
                {
                    id: 16,
                    value: 'Z032',
                    description: '1% discount within 10 days,net 30 days',
                },
                {
                    id: 17,
                    value: 'Z051',
                    description: 'Letter of Credit at 30 days',
                },
                {
                    id: 18,
                    value: 'Z052',
                    description: 'Letter of Credit at 60 Days',
                },
                {
                    id: 19,
                    value: 'Z053',
                    description: 'Letter of Credit at 90 Days',
                },
                { id: 20, value: 'Z058', description: 'Cash in advance' },
                { id: 21, value: 'Z060', description: 'Credit Card' },
                {
                    id: 22,
                    value: 'Z070',
                    description: '2% discount within 30 days,net 45 days',
                },
                {
                    id: 23,
                    value: 'Z080',
                    description: '.5% in 10 Days,Net 30 Days',
                },
                {
                    id: 24,
                    value: 'Z086',
                    description: '10 Days from Date of Invoice',
                },
                {
                    id: 25,
                    value: 'Z087',
                    description: '20 Days from Date of Invoice',
                },
                {
                    id: 26,
                    value: 'Z106',
                    description: '1.5% discount within 15 days,net 30 days',
                },
            ],
            RiskCategoryTypeId: [
                { id: 1, description: '001 : Release All (w/ check >$10MM)' },
                { id: 2, description: '002 : Review All Orders' },
                { id: 3, description: '006 : NEW' },
                { id: 4, description: '009 : Vyaire - Hold at Limit' },
                { id: 5, description: 'Z01 : Release All (w/ check >$10MM)' },
                { id: 6, description: 'Z02 : Review All Orders' },
                { id: 7, description: 'Z06 : NEW' },
                { id: 8, description: 'Z09 : Vyaire - Hold at Limit' },
            ],
            CreditRepGroupTypeId: [
                { id: 1, description: '001 : US-Eastern States' },
                { id: 2, description: '003 : US-Mid West' },
                { id: 3, description: "004 : US-Gov't. & Bio Med" },
                { id: 4, description: '005 : US-Western States' },
                { id: 5, description: '008 : Collection Agency' },
                { id: 6, description: '009 : Bankruptcy' },
                { id: 7, description: '013 : US Export-Latin America' },
                { id: 8, description: '018 : Mid South' },
                { id: 9, description: '019 : Owens and Minor' },
                { id: 10, description: '020 : Cardinal' },
                { id: 11, description: '021 : GE' },
                { id: 12, description: '022 : Medline' },
                { id: 13, description: '023 : Kaiser' },
                { id: 14, description: '024 : McKesson' },
                { id: 15, description: '025 : RGH/Assuramed' },
                { id: 16, description: '026 : Seneca' },
                { id: 17, description: '027 : Canada' },
                { id: 18, description: '028 : International' },
                { id: 19, description: '029 : Intercompany' },
                { id: 20, description: '030 : BD' },
                { id: 21, description: '031 : Sales' },
                { id: 22, description: '099 : Inactive' },
                { id: 23, description: '032 : Jennifer Perez' },
                { id: 24, description: '033 : Rhonda Bellefeuille' },
                { id: 25, description: '015 : US-South East' },
            ],
            SpecialPricingTypeId: [
                { id: 1, description: 'S12: Presource Kitting' },
                { id: 2, description: 'S14: Med Action - Avid Kit' },
                { id: 3, description: 'S16: Centurion' },
                { id: 4, description: 'S5: CAH Canada' },
                { id: 5, description: 'S7: CAH USAMMA' },
                { id: 6, description: 'S8: Medline Kitting' },
            ],
            DistLevelTypeId: [
                { id: 1, description: 'D1: DAPA' },
                { id: 2, description: 'D2: DAPA 2 ' },
                { id: 3, description: 'F1: FSS Price' },
                { id: 4, description: 'L1: Level 1' },
                { id: 5, description: 'L2: Level 2' },
            ],
            DAPAPricingTypeId: [
                { id: 1, description: 'D1: DAPA' },
                { id: 2, description: 'D2: DAPA 2 ' },
                { id: 3, description: 'F1: FSS Price' },
                { id: 4, description: 'L1: Level 1' },
                { id: 5, description: 'L2: Level 2' },
            ],
            DAPA2PricingTypeId: [
                { id: 1, description: 'D1: DAPA' },
                { id: 2, description: 'D2: DAPA 2 ' },
                { id: 3, description: 'F1: FSS Price' },
                { id: 4, description: 'L1: Level 1' },
                { id: 5, description: 'L2: Level 2' },
            ],
            FSSPricingTypeId: [
                { id: 1, description: 'D1: DAPA' },
                { id: 2, description: 'D2: DAPA 2 ' },
                { id: 3, description: 'F1: FSS Price' },
                { id: 4, description: 'L1: Level 1' },
                { id: 5, description: 'L2: Level 2' },
            ],
            OrderBlockTypeId: [
                { id: 1, value: '01', description: 'Overall Block' },
                { id: 2, value: '02', description: 'All Free of Charge' },
            ],
            DeliveryBlockTypeId: [
                { id: 1, value: '01', description: 'Credit Limit' },
                { id: 2, value: '02', description: 'TBD 1' },
                { id: 3, value: 'Z9', description: 'Addr. Override Block' },
                { id: 4, value: 'ZC', description: 'TBD 3' },
                { id: 5, value: 'ZD', description: 'International' },
                { id: 6, value: 'ZT', description: 'Terms & Conditions' },
            ],
            BillingBlockTypeId: [
                { id: 1, value: '03', description: 'Prices Incomplete' },
                { id: 2, value: '04', description: 'Check Terms of Payment' },
                { id: 3, value: 'ZA', description: 'TBD 10' },
            ],
        };
        let normalizedDropDowns = {};
        if (normalizeData)
            mapKeys(dropdowns, (value, key) => {
                normalizedDropDowns[key] = normalize(value);
            });

        if (returnBoth) {
            resolve({ normalizedDropDowns, dropdowns });
        } else {
            resolve(dropdowns);
        }
    });
};

export const fetchCreateCustomerDropDownData = () => {
    return new Promise((resolve, reject) =>
        resolve({
            SystemType: [
                {
                    id: 1,
                    description: 'SAP Apollo',
                },
                {
                    id: 3,
                    description: 'Pointman',
                },
                {
                    id: 6,
                    description: 'SalesForce',
                },
            ],
            RoleTypeId: [
                {
                    id: 1,
                    systemId: 1,
                    description: 'Sold To',
                },
                {
                    id: 2,
                    systemId: 1,
                    description: 'Ship To',
                },
                {
                    id: 3,
                    systemId: 1,
                    description: 'Payer',
                },
                {
                    id: 4,
                    systemId: 1,
                    description: 'Bill To',
                },
                {
                    id: 5,
                    systemId: 1,
                    description: 'Sales Rep',
                },
                {
                    id: 6,
                    systemId: 1,
                    description: 'Drop Ship',
                },
                {
                    id: 7,
                    systemId: 2,
                    description: 'SAP Olympus: Sold To',
                },
                {
                    id: 8,
                    systemId: 2,
                    description: 'SAP Olympus: Ship To',
                },
                {
                    id: 9,
                    systemId: 2,
                    description: 'SAP Olympus: Payer',
                },
                {
                    id: 10,
                    systemId: 2,
                    description: 'SAP Olympus: Bill To',
                },
                {
                    id: 11,
                    systemId: 2,
                    description: 'SAP Olympus: Sales Rep',
                },
                {
                    id: 4,
                    systemId: 3,
                    description: 'Bill To',
                },
                {
                    id: 2,
                    systemId: 3,
                    description: 'Ship To',
                },
                {
                    id: 15,
                    systemId: 4,
                    description: 'Made2Manage: Sold To',
                },
                {
                    id: 16,
                    systemId: 4,
                    description: 'Made2Manage: Ship To',
                },
                {
                    id: 17,
                    systemId: 4,
                    description: 'Made2Manage: Sales Rep',
                },
                {
                    id: 18,
                    systemId: 5,
                    description: 'JD Edwards: Sold To',
                },
                {
                    id: 19,
                    systemId: 5,
                    description: 'JD Edwards: Ship To',
                },
                {
                    id: 20,
                    systemId: 5,
                    description: 'JD Edwards: Sales Rep',
                },
                { id: 21, systemId: 1, value: '0007', description: 'sales partners' },
                {
                    id: 22,
                    systemId: 1,
                    value: '0110',
                    description: 'dBranch w/o intercomp.billing',
                },
                {
                    id: 23,
                    systemId: 1,
                    value: '0120',
                    description: 'dBranch with intercomp.billing',
                },
                {
                    id: 24,
                    systemId: 1,
                    value: '0130',
                    description: 'dBranch with external billing',
                },
                { id: 25, systemId: 1, value: '0140', description: 'dAssortment' },
                { id: 26, systemId: 1, value: '0150', description: 'dFranchisee' },
                {
                    id: 27,
                    systemId: 1,
                    value: 'CPD',
                    description: 'dOne-time cust.(int.no.assgnmt)',
                },
                {
                    id: 28,
                    systemId: 1,
                    value: 'CPDA',
                    description: 'dOne-time cust.(ext.no.assgnmt)',
                },
                {
                    id: 29,
                    systemId: 1,
                    value: 'KUNA',
                    description: 'dCustomer (ext.number assgnmt)',
                },
                { id: 30, systemId: 1, value: 'Z001', description: 'dBuying Group' },
                {
                    id: 31,
                    systemId: 1,
                    value: 'Z003',
                    description: 'sales Territory/Area/Region',
                },
                {
                    id: 32,
                    systemId: 1,
                    value: 'Z004',
                    description: 'dNational Accounts/GPO',
                },
                {
                    id: 33,
                    systemId: 1,
                    value: 'Z005',
                    description: 'dIntegrated Health Network',
                },
                { id: 34, systemId: 1, value: 'Z006', description: 'dTrace Customer' },
                {
                    id: 35,
                    systemId: 1,
                    value: 'Z009',
                    description: 'dDispensing Side ID',
                },
                {
                    id: 36,
                    systemId: 1,
                    value: 'Z011',
                    description: 'dIntercompany Payer',
                },
                {
                    id: 37,
                    systemId: 1,
                    value: 'Z012',
                    description: 'dGPO Hierarchy Node',
                },
                {
                    id: 38,
                    systemId: 1,
                    value: 'Z998',
                    description: 'dUltimate Destination Country',
                },
                {
                    id: 39,
                    systemId: 1,
                    value: 'Z999',
                    description: 'dTest Acct with All Fields',
                },
                { id: 40, systemId: 1, value: 'ZSEL', description: 'seller' },
            ],
            CompanyCodeTypeId: [
                { id: 1, systemId: 1, value: '0120', description: 'Vyaire Medical' },
                {
                    id: 2,
                    systemId: 1,
                    value: '0150',
                    description: 'Vyaire Medical Canada',
                },
            ],
            DistributionChannelType: [
                {
                    id: 1,
                    systemId: 1,
                    description: '10',
                },
            ],
            DivisionType: [
                {
                    id: 1,
                    systemId: 1,
                    description: '99',
                },
            ],
            SalesOrgType: [
                {
                    id: 1,
                    systemId: 1,
                    value: '0120',
                    description: 'Direct',
                },
                {
                    id: 2,
                    systemId: 1,
                    value: '0130',
                    description: 'Distributor',
                },
                {
                    id: 3,
                    systemId: 1,
                    value: '0150',
                    description: 'Canada ',
                },
            ],
            OlympusRoleType: [
                { description: 'Sold-To Party', id: '1', value: '0001' },
                { description: 'Goods Recipient (Ship To)', id: '2', value: '0002' },
                { description: 'Payer', id: '3', value: '0003' },
                { description: 'Bill-To Party', id: '4', value: '0004' },
                { description: 'Intl Intercompany Customers', id: '23', value: 'Y006' },
                { description: 'Interco Customers (Payer)', id: '28', value: 'ZICC' },
                { description: 'Sales Rep', id: '29', value: '0002' },
            ],
            JDERoleType: [
                { id: '1', value: 'X', description: 'Billing & Shipping (Sold To)' },
                { id: '2', value: 'B', description: 'Billing Only (Bill To)' },
                { id: '3', value: 'S', description: 'Shipping Only (Ship To)' },
            ],
        })
    );
};
export const fetchContractsDropDownData = (normalizeData = false, returnBoth = false) => {
    return new Promise((resolve, reject) => {
        let dropdowns = {
            AccountTypeId: [
                { id: 1, description: 'DTR : Rebated Distributor' },
                { id: 2, description: 'INT : Internal' },
                { id: 3, description: 'IDV : Individual Consumer' },
                { id: 4, description: 'NRD : Non-Rebated Distributor' },
                { id: 5, description: 'SDT: Self Distributor' },
                { id: 6, description: 'IEX: International Exporter' },
                { id: 7, description: 'OEM: OEM' },
                { id: 8, description: 'KTR: Kitter' },
            ],
            CustomerGroupTypeId: [
                { id: 1, value: 1, description: 'Rbtd Dist - CAH' },
                { id: 2, value: 2, description: 'Affiliates' },
                { id: 3, value: 3, description: 'Alternate Site' },
                { id: 4, value: 4, description: 'Biomed Repair' },
                {
                    id: 5,
                    value: 5,
                    description: 'Non Rbtd Distributor/Self Distributor',
                },
                { id: 6, value: 6, description: 'Hospital' },
                { id: 7, value: 8, description: 'Internal' },
                { id: 8, value: 9, description: 'Intl Dealer/ Exporter' },
                { id: 9, value: 10, description: 'OEM/Kitter' },
                { id: 10, value: 12, description: 'Rbtd Dist - Non CAH' },
                { id: 11, value: 14, description: 'Third Party End User' },
            ],
            IncoTerms1TypeId: [
                { id: 1, value: 'COL', description: 'Collect Freight from Consignee' },
                { id: 2, value: 'CP2', description: 'PrePaid FOB Origin' },
                { id: 3, value: 'CPT', description: 'Carriage Paid To' },
                { id: 4, value: 'DAP', description: 'Delivered at Location' },
                { id: 5, value: 'DDP', description: 'Delivered Duty Paid' },
                { id: 6, value: 'DPA', description: 'PrePaid+Bill FOB Destination' },
                { id: 7, value: 'EXW', description: 'Ex Works' },
                { id: 8, value: 'FCA', description: 'Free Carrier' },
                { id: 9, value: 'PPA', description: 'PrePaid+Bill FOB Origin' },
                { id: 10, value: 'PPD', description: 'PrePaid FOB Destination' },
            ],
            PaymentTermsTypeId: [
                { id: 1, value: 'Z000', description: 'Payable Immediately' },
                {
                    id: 2,
                    value: 'Z001',
                    description: 'Net 15 Days from invoice date',
                },
                {
                    id: 3,
                    value: 'Z002',
                    description: 'Net 30 Days from invoice date',
                },
                {
                    id: 4,
                    value: 'Z003',
                    description: 'Net 45 Days from invoice date',
                },
                {
                    id: 5,
                    value: 'Z004',
                    description: 'Net 60 Days from invoice date',
                },
                {
                    id: 6,
                    value: 'Z005',
                    description: 'Net 75 Days from invoice date',
                },
                {
                    id: 7,
                    value: 'Z006',
                    description: 'Net 90 Days from invoice date',
                },
                {
                    id: 8,
                    value: 'Z007',
                    description: 'Net 120 Days from invoice date',
                },
                {
                    id: 9,
                    value: 'Z008',
                    description: 'Net 180 Days from invoice date',
                },
                {
                    id: 10,
                    value: 'Z012',
                    description: 'Net 7 Days from invoice date',
                },
                {
                    id: 11,
                    value: 'Z020',
                    description: '1% discount within 15 days,net 30 days',
                },
                {
                    id: 12,
                    value: 'Z021',
                    description: '1% discount within 20 days,net 30 days',
                },
                {
                    id: 13,
                    value: 'Z022',
                    description: '1% discount within 30 days,net 31 days',
                },
                {
                    id: 14,
                    value: 'Z026',
                    description: '2% in 20 Days,Net 30 Days',
                },
                {
                    id: 15,
                    value: 'Z030',
                    description: '2% discount within 15 days,net 30 days',
                },
                {
                    id: 16,
                    value: 'Z032',
                    description: '1% discount within 10 days,net 30 days',
                },
                {
                    id: 17,
                    value: 'Z051',
                    description: 'Letter of Credit at 30 days',
                },
                {
                    id: 18,
                    value: 'Z052',
                    description: 'Letter of Credit at 60 Days',
                },
                {
                    id: 19,
                    value: 'Z053',
                    description: 'Letter of Credit at 90 Days',
                },
                { id: 20, value: 'Z058', description: 'Cash in advance' },
                { id: 21, value: 'Z060', description: 'Credit Card' },
                {
                    id: 22,
                    value: 'Z070',
                    description: '2% discount within 30 days,net 45 days',
                },
                {
                    id: 23,
                    value: 'Z080',
                    description: '.5% in 10 Days,Net 30 Days',
                },
                {
                    id: 24,
                    value: 'Z086',
                    description: '10 Days from Date of Invoice',
                },
                {
                    id: 25,
                    value: 'Z087',
                    description: '20 Days from Date of Invoice',
                },
                {
                    id: 26,
                    value: 'Z106',
                    description: '1.5% discount within 15 days,net 30 days',
                },
            ],
        };
        let normalizedDropDowns = {};
        if (normalizeData)
            mapKeys(dropdowns, (value, key) => {
                normalizedDropDowns[key] = normalize(value);
            });

        console.log(returnBoth);

        if (returnBoth) {
            resolve({ normalizedDropDowns, dropdowns });
        } else {
            resolve(dropdowns);
        }
    });
};

export const fetchCreditDropDownData = (normalizeData = false, returnBoth) => {
    return new Promise((resolve, reject) => {
        let dropdowns = {
            PaymentTermsTypeId: [
                { id: 1, value: 'Z000', description: 'Payable Immediately' },
                {
                    id: 2,
                    value: 'Z001',
                    description: 'Net 15 Days from invoice date',
                },
                {
                    id: 3,
                    value: 'Z002',
                    description: 'Net 30 Days from invoice date',
                },
                {
                    id: 4,
                    value: 'Z003',
                    description: 'Net 45 Days from invoice date',
                },
                {
                    id: 5,
                    value: 'Z004',
                    description: 'Net 60 Days from invoice date',
                },
                {
                    id: 6,
                    value: 'Z005',
                    description: 'Net 75 Days from invoice date',
                },
                {
                    id: 7,
                    value: 'Z006',
                    description: 'Net 90 Days from invoice date',
                },
                {
                    id: 8,
                    value: 'Z007',
                    description: 'Net 120 Days from invoice date',
                },
                {
                    id: 9,
                    value: 'Z008',
                    description: 'Net 180 Days from invoice date',
                },
                {
                    id: 10,
                    value: 'Z012',
                    description: 'Net 7 Days from invoice date',
                },
                {
                    id: 11,
                    value: 'Z020',
                    description: '1% discount within 15 days,net 30 days',
                },
                {
                    id: 12,
                    value: 'Z021',
                    description: '1% discount within 20 days,net 30 days',
                },
                {
                    id: 13,
                    value: 'Z022',
                    description: '1% discount within 30 days,net 31 days',
                },
                {
                    id: 14,
                    value: 'Z026',
                    description: '2% in 20 Days,Net 30 Days',
                },
                {
                    id: 15,
                    value: 'Z030',
                    description: '2% discount within 15 days,net 30 days',
                },
                {
                    id: 16,
                    value: 'Z032',
                    description: '1% discount within 10 days,net 30 days',
                },
                {
                    id: 17,
                    value: 'Z051',
                    description: 'Letter of Credit at 30 days',
                },
                {
                    id: 18,
                    value: 'Z052',
                    description: 'Letter of Credit at 60 Days',
                },
                {
                    id: 19,
                    value: 'Z053',
                    description: 'Letter of Credit at 90 Days',
                },
                { id: 20, value: 'Z058', description: 'Cash in advance' },
                { id: 21, value: 'Z060', description: 'Credit Card' },
                {
                    id: 22,
                    value: 'Z070',
                    description: '2% discount within 30 days,net 45 days',
                },
                {
                    id: 23,
                    value: 'Z080',
                    description: '.5% in 10 Days,Net 30 Days',
                },
                {
                    id: 24,
                    value: 'Z086',
                    description: '10 Days from Date of Invoice',
                },
                {
                    id: 25,
                    value: 'Z087',
                    description: '20 Days from Date of Invoice',
                },
                {
                    id: 26,
                    value: 'Z106',
                    description: '1.5% discount within 15 days,net 30 days',
                },
            ],
            RiskCategoryTypeId: [
                { id: 1, description: '001 : Release All (w/ check >$10MM)' },
                { id: 2, description: '002 : Review All Orders' },
                { id: 3, description: '006 : NEW' },
                { id: 4, description: '009 : Vyaire - Hold at Limit' },
                { id: 5, description: 'Z01 : Release All (w/ check >$10MM)' },
                { id: 6, description: 'Z02 : Review All Orders' },
                { id: 7, description: 'Z06 : NEW' },
                { id: 8, description: 'Z09 : Vyaire - Hold at Limit' },
            ],
            CreditRepGroupTypeId: [
                { id: 1, description: '001 : US-Eastern States' },
                { id: 2, description: '003 : US-Mid West' },
                { id: 3, description: "004 : US-Gov't. & Bio Med" },
                { id: 4, description: '005 : US-Western States' },
                { id: 5, description: '008 : Collection Agency' },
                { id: 6, description: '009 : Bankruptcy' },
                { id: 7, description: '013 : US Export-Latin America' },
                { id: 8, description: '018 : Mid South' },
                { id: 9, description: '019 : Owens and Minor' },
                { id: 10, description: '020 : Cardinal' },
                { id: 11, description: '021 : GE' },
                { id: 12, description: '022 : Medline' },
                { id: 13, description: '023 : Kaiser' },
                { id: 14, description: '024 : McKesson' },
                { id: 15, description: '025 : RGH/Assuramed' },
                { id: 16, description: '026 : Seneca' },
                { id: 17, description: '027 : Canada' },
                { id: 18, description: '028 : International' },
                { id: 19, description: '029 : Intercompany' },
                { id: 20, description: '030 : BD' },
                { id: 21, description: '031 : Sales' },
                { id: 22, description: '099 : Inactive' },
                { id: 23, description: '032 : Jennifer Perez' },
                { id: 24, description: '033 : Rhonda Bellefeuille' },
                { id: 25, description: '015 : US-South East' },
            ],
        };
        let normalizedDropDowns = {};
        if (normalizeData)
            mapKeys(dropdowns, (value, key) => {
                normalizedDropDowns[key] = normalize(value);
            });

        if (returnBoth) {
            resolve({ normalizedDropDowns, dropdowns });
        } else {
            resolve(dropdowns);
        }
    });
};

export const fetchPricingDropDownData = (normalizeData = false) => {
    return new Promise((resolve, reject) => {
        let dropdowns = {
            SpecialPricingTypeId: [
                { id: 1, description: 'S12: Presource Kitting' },
                { id: 2, description: 'S14: Med Action - Avid Kit' },
                { id: 3, description: 'S16: Centurion' },
                { id: 4, description: 'S5: CAH Canada' },
                { id: 5, description: 'S7: CAH USAMMA' },
                { id: 6, description: 'S8: Medline Kitting' },
            ],
            DistLevelTypeId: [
                { id: 1, description: 'D1: DAPA' },
                { id: 2, description: 'D2: DAPA 2 ' },
                { id: 3, description: 'F1: FSS Price' },
                { id: 4, description: 'L1: Level 1' },
                { id: 5, description: 'L2: Level 2' },
            ],
            DAPAPricingTypeId: [
                { id: 1, description: 'D1: DAPA' },
                { id: 2, description: 'D2: DAPA 2 ' },
                { id: 3, description: 'F1: FSS Price' },
                { id: 4, description: 'L1: Level 1' },
                { id: 5, description: 'L2: Level 2' },
            ],
            DAPA2PricingTypeId: [
                { id: 1, description: 'D1: DAPA' },
                { id: 2, description: 'D2: DAPA 2 ' },
                { id: 3, description: 'F1: FSS Price' },
                { id: 4, description: 'L1: Level 1' },
                { id: 5, description: 'L2: Level 2' },
            ],
            FSSPricingTypeId: [
                { id: 1, description: 'D1: DAPA' },
                { id: 2, description: 'D2: DAPA 2 ' },
                { id: 3, description: 'F1: FSS Price' },
                { id: 4, description: 'L1: Level 1' },
                { id: 5, description: 'L2: Level 2' },
            ],
        };
        if (normalizeData)
            mapKeys(dropdowns, (value, key) => {
                dropdowns[key] = normalize(value);
            });
        resolve(dropdowns);
    });
};

export const fetchBlockDropDownData = () => {
    return new Promise((resolve, reject) =>
        resolve({
            OrderBlockTypeId: [
                { id: 1, value: '01', description: 'Overall Block' },
                { id: 2, value: '02', description: 'All Free of Charge' },
            ],
            DeliveryBlockTypeId: [
                { id: 1, value: '01', description: 'Credit Limit' },
                { id: 2, value: '02', description: 'TBD 1' },
                { id: 3, value: 'Z9', description: 'Addr. Override Block' },
                { id: 4, value: 'ZC', description: 'TBD 3' },
                { id: 5, value: 'ZD', description: 'International' },
                { id: 6, value: 'ZT', description: 'Terms & Conditions' },
            ],
            BillingBlockTypeId: [
                { id: 1, value: '03', description: 'Prices Incomplete' },
                { id: 2, value: '04', description: 'Check Terms of Payment' },
                { id: 3, value: 'ZA', description: 'TBD 10' },
            ],
        })
    );
};

export const fetchOlympusSalesDropdownData = () => {
    return new Promise((resolve, reject) =>
        resolve({
            SalesOfficeTypeId: [
                { SalesOfficeId: 'DE05', Description: 'Axel Lehmann' },
                { SalesOfficeId: 'DE12', Description: 'DE Vents Service' },
                { SalesOfficeId: 'IT01', Description: 'Paolo BADII' },
                { SalesOfficeId: 'IT02', Description: 'Antonio MORELLO' },
                { SalesOfficeId: 'IT03', Description: 'Pierluigi ROSA' },
                { SalesOfficeId: 'IT04', Description: 'Paolo SCARPETTI' },
                { SalesOfficeId: 'IT05', Description: 'Mimmo SILEO' },
                { SalesOfficeId: 'IT06', Description: 'Do not use' },
                { SalesOfficeId: 'IT07', Description: 'Do not use' },
                { SalesOfficeId: 'IT08', Description: 'Do not use' },
                { SalesOfficeId: 'IT09', Description: 'Massimo PRESENTINI' },
            ],
            SalesGroupTypeId: [
                {
                    SalesOfficeId: 'AU01',
                    SalesGroupId: '218',
                    Description: 'Service',
                },
                {
                    SalesOfficeId: 'AU01',
                    SalesGroupId: '219',
                    Description: 'BM - Territory 1',
                },
                {
                    SalesOfficeId: 'AU01',
                    SalesGroupId: '223',
                    Description: 'BM - Territory 2',
                },
                {
                    SalesOfficeId: 'AU01',
                    SalesGroupId: '229',
                    Description: 'Unused',
                },
                {
                    SalesOfficeId: 'AU02',
                    SalesGroupId: '220',
                    Description: 'NSW North',
                },
                {
                    SalesOfficeId: 'AU02',
                    SalesGroupId: '221',
                    Description: 'NSW South',
                },
                {
                    SalesOfficeId: 'AU02',
                    SalesGroupId: '222',
                    Description: 'Unused',
                },
                {
                    SalesOfficeId: 'AU02',
                    SalesGroupId: '228',
                    Description: 'Unused',
                },
                {
                    SalesOfficeId: 'AU02',
                    SalesGroupId: '230',
                    Description: 'Unused',
                },
                {
                    SalesOfficeId: 'AU02',
                    SalesGroupId: '622',
                    Description: 'ME',
                },
                {
                    SalesOfficeId: 'AU02',
                    SalesGroupId: '623',
                    Description: 'Unused',
                },
                {
                    SalesOfficeId: 'AU02',
                    SalesGroupId: '633',
                    Description: 'Unused',
                },
                {
                    SalesOfficeId: 'AU02',
                    SalesGroupId: '635',
                    Description: 'Unused',
                },
                {
                    SalesOfficeId: 'AU02',
                    SalesGroupId: '636',
                    Description: 'Unused',
                },
                {
                    SalesOfficeId: 'AU03',
                    SalesGroupId: '208',
                    Description: 'QLD - Territory 1',
                },
                {
                    SalesOfficeId: 'AU03',
                    SalesGroupId: '224',
                    Description: 'QLD - Territory 2',
                },
                {
                    SalesOfficeId: 'AU03',
                    SalesGroupId: '225',
                    Description: 'Unused',
                },
                {
                    SalesOfficeId: 'AU03',
                    SalesGroupId: '624',
                    Description: 'Unused',
                },
                {
                    SalesOfficeId: 'AU03',
                    SalesGroupId: '627',
                    Description: 'Unused',
                },
                {
                    SalesOfficeId: 'AU03',
                    SalesGroupId: '628',
                    Description: 'Unused',
                },
                {
                    SalesOfficeId: 'AU03',
                    SalesGroupId: '629',
                    Description: 'Unused',
                },
                {
                    SalesOfficeId: 'AU03',
                    SalesGroupId: '632',
                    Description: 'Unused',
                },
                {
                    SalesOfficeId: 'AU03',
                    SalesGroupId: '634',
                    Description: 'Unused',
                },
                {
                    SalesOfficeId: 'AU04',
                    SalesGroupId: '226',
                    Description: 'VIC Territory 1',
                },
                {
                    SalesOfficeId: 'AU04',
                    SalesGroupId: '227',
                    Description: 'VIC Territory 2',
                },
                {
                    SalesOfficeId: 'AU04',
                    SalesGroupId: '254',
                    Description: 'Unused',
                },
                {
                    SalesOfficeId: 'AU04',
                    SalesGroupId: '255',
                    Description: 'Unused',
                },
                {
                    SalesOfficeId: 'AU04',
                    SalesGroupId: '621',
                    Description: 'HVS',
                },
                {
                    SalesOfficeId: 'AU04',
                    SalesGroupId: '630',
                    Description: 'Unused',
                },
                {
                    SalesOfficeId: 'AU04',
                    SalesGroupId: '631',
                    Description: 'Unused',
                },
                {
                    SalesOfficeId: 'AU05',
                    SalesGroupId: '231',
                    Description: 'TAS Territory',
                },
                {
                    SalesOfficeId: 'AU05',
                    SalesGroupId: '232',
                    Description: 'Unused',
                },
                {
                    SalesOfficeId: 'AU05',
                    SalesGroupId: '280',
                    Description: 'Unused',
                },
                {
                    SalesOfficeId: 'AU05',
                    SalesGroupId: '621',
                    Description: 'HVS',
                },
                {
                    SalesOfficeId: 'AU05',
                    SalesGroupId: '859',
                    Description: 'Unused',
                },
                {
                    SalesOfficeId: 'AU06',
                    SalesGroupId: '234',
                    Description: 'SA Territory',
                },
                {
                    SalesOfficeId: 'AU06',
                    SalesGroupId: '615',
                    Description: 'Unused',
                },
                {
                    SalesOfficeId: 'AU06',
                    SalesGroupId: '620',
                    Description: 'IHR',
                },
                {
                    SalesOfficeId: 'AU06',
                    SalesGroupId: '625',
                    Description: 'Unused',
                },
                {
                    SalesOfficeId: 'AU06',
                    SalesGroupId: '858',
                    Description: 'Unused',
                },
                {
                    SalesOfficeId: 'AU07',
                    SalesGroupId: '253',
                    Description: 'NT Territory',
                },
                {
                    SalesOfficeId: 'AU07',
                    SalesGroupId: '856',
                    Description: 'Unused',
                },
                {
                    SalesOfficeId: 'AU07',
                    SalesGroupId: '857',
                    Description: 'Unused',
                },
                {
                    SalesOfficeId: 'AU08',
                    SalesGroupId: '641',
                    Description: 'WA Territory',
                },
                {
                    SalesOfficeId: 'AU08',
                    SalesGroupId: '642',
                    Description: 'Unused',
                },
                {
                    SalesOfficeId: 'AU08',
                    SalesGroupId: '643',
                    Description: 'Unused',
                },
                {
                    SalesOfficeId: 'AU08',
                    SalesGroupId: '644',
                    Description: 'Unused',
                },
                {
                    SalesOfficeId: 'AU09',
                    SalesGroupId: '645',
                    Description: 'NZ Territory',
                },
                {
                    SalesOfficeId: 'CH01',
                    SalesGroupId: '751',
                    Description: 'Sales Group 1',
                },
                {
                    SalesOfficeId: 'CH01',
                    SalesGroupId: '752',
                    Description: 'Sales Group 2',
                },
                {
                    SalesOfficeId: 'CH01',
                    SalesGroupId: '753',
                    Description: 'Sales Group 3',
                },
                {
                    SalesOfficeId: 'CN01',
                    SalesGroupId: '902',
                    Description: 'John Guo',
                },
                {
                    SalesOfficeId: 'CN01',
                    SalesGroupId: '903',
                    Description: 'Jeffrey Liao',
                },
                {
                    SalesOfficeId: 'CN01',
                    SalesGroupId: '905',
                    Description: 'Francis Chua',
                },
                {
                    SalesOfficeId: 'CN01',
                    SalesGroupId: '906',
                    Description: 'Edward Ling',
                },
                {
                    SalesOfficeId: 'CN02',
                    SalesGroupId: '901',
                    Description: 'Green Chai',
                },
                {
                    SalesOfficeId: 'CN02',
                    SalesGroupId: '902',
                    Description: 'John Guo',
                },
                {
                    SalesOfficeId: 'CN03',
                    SalesGroupId: '904',
                    Description: 'Sam Zhang',
                },
                {
                    SalesOfficeId: 'DE05',
                    SalesGroupId: '425',
                    Description: 'Maria Rudolph',
                },
                {
                    SalesOfficeId: 'DE05',
                    SalesGroupId: '426',
                    Description: 'Karsten Giesberts',
                },
                {
                    SalesOfficeId: 'DE05',
                    SalesGroupId: '427',
                    Description: 'Axel Lehmann',
                },
                {
                    SalesOfficeId: 'DK01',
                    SalesGroupId: '700',
                    Description: 'Denmark',
                },
                {
                    SalesOfficeId: 'ES01',
                    SalesGroupId: '304',
                    Description: 'GRAU TRAVESET DAVID',
                },
                {
                    SalesOfficeId: 'ES01',
                    SalesGroupId: '305',
                    Description: 'GUTIERREZ GONZALEZ D',
                },
                {
                    SalesOfficeId: 'ES01',
                    SalesGroupId: '307',
                    Description: 'REIG MALDONADO ALBER',
                },
                {
                    SalesOfficeId: 'ES01',
                    SalesGroupId: '312',
                    Description: 'GRANERO GOMEZ JUAN C',
                },
                {
                    SalesOfficeId: 'ES02',
                    SalesGroupId: '301',
                    Description: 'VILAS BARREIRO JOSE',
                },
                {
                    SalesOfficeId: 'ES02',
                    SalesGroupId: '313',
                    Description: 'GARCIA FERNANDEZ JOA',
                },
                {
                    SalesOfficeId: 'ES02',
                    SalesGroupId: '316',
                    Description: 'HERRERO PERALES BEAT',
                },
                {
                    SalesOfficeId: 'ES03',
                    SalesGroupId: '302',
                    Description: 'GONZALEZ PEREZ LAURA',
                },
                {
                    SalesOfficeId: 'ES03',
                    SalesGroupId: '311',
                    Description: 'DE LOS REYES AMO BEL',
                },
                {
                    SalesOfficeId: 'ES03',
                    SalesGroupId: '314',
                    Description: 'MARCOS PEREZ SUSANA',
                },
                {
                    SalesOfficeId: 'ES03',
                    SalesGroupId: '317',
                    Description: 'CAMPOS NUÑEZ JOSE AN',
                },
                {
                    SalesOfficeId: 'ES04',
                    SalesGroupId: '308',
                    Description: 'PLAZA RIOS JUAN-MANU',
                },
                {
                    SalesOfficeId: 'ES04',
                    SalesGroupId: '309',
                    Description: 'TORRALBA SALIDO FERN',
                },
                {
                    SalesOfficeId: 'ES04',
                    SalesGroupId: '310',
                    Description: 'RICO GALIANO SERGIO',
                },
                {
                    SalesOfficeId: 'ES05',
                    SalesGroupId: '803',
                    Description: 'Almería',
                },
                {
                    SalesOfficeId: 'ES05',
                    SalesGroupId: '810',
                    Description: 'Cádiz',
                },
                {
                    SalesOfficeId: 'ES05',
                    SalesGroupId: '813',
                    Description: 'Córdoba',
                },
                {
                    SalesOfficeId: 'ES05',
                    SalesGroupId: '817',
                    Description: 'Granada',
                },
                {
                    SalesOfficeId: 'ES05',
                    SalesGroupId: '820',
                    Description: 'Huelva',
                },
                {
                    SalesOfficeId: 'ES05',
                    SalesGroupId: '822',
                    Description: 'Jaén',
                },
                {
                    SalesOfficeId: 'ES05',
                    SalesGroupId: '828',
                    Description: 'Málaga',
                },
                {
                    SalesOfficeId: 'ES05',
                    SalesGroupId: '840',
                    Description: 'Sevilla',
                },
                {
                    SalesOfficeId: 'ES06',
                    SalesGroupId: '821',
                    Description: 'Huesca',
                },
                {
                    SalesOfficeId: 'ES06',
                    SalesGroupId: '843',
                    Description: 'Teruel',
                },
                {
                    SalesOfficeId: 'ES06',
                    SalesGroupId: '849',
                    Description: 'Zaragoza',
                },
                {
                    SalesOfficeId: 'ES07',
                    SalesGroupId: '832',
                    Description: 'Asturias',
                },
                {
                    SalesOfficeId: 'ES08',
                    SalesGroupId: '806',
                    Description: 'Baleares',
                },
                {
                    SalesOfficeId: 'ES09',
                    SalesGroupId: '801',
                    Description: 'Albacete',
                },
                {
                    SalesOfficeId: 'ES09',
                    SalesGroupId: '812',
                    Description: 'Ciudad Real',
                },
                {
                    SalesOfficeId: 'ES09',
                    SalesGroupId: '815',
                    Description: 'Cuenca',
                },
                {
                    SalesOfficeId: 'ES09',
                    SalesGroupId: '818',
                    Description: 'Guadalajara',
                },
                {
                    SalesOfficeId: 'ES09',
                    SalesGroupId: '844',
                    Description: 'Toledo',
                },
                {
                    SalesOfficeId: 'ES10',
                    SalesGroupId: '804',
                    Description: 'Ávila',
                },
                {
                    SalesOfficeId: 'ES10',
                    SalesGroupId: '808',
                    Description: 'Burgos',
                },
                {
                    SalesOfficeId: 'ES10',
                    SalesGroupId: '823',
                    Description: 'León',
                },
                {
                    SalesOfficeId: 'ES10',
                    SalesGroupId: '833',
                    Description: 'Palencia',
                },
                {
                    SalesOfficeId: 'ES10',
                    SalesGroupId: '836',
                    Description: 'Salamanca',
                },
                {
                    SalesOfficeId: 'ES10',
                    SalesGroupId: '839',
                    Description: 'Segovia',
                },
                {
                    SalesOfficeId: 'ES10',
                    SalesGroupId: '841',
                    Description: 'Soria',
                },
                {
                    SalesOfficeId: 'ES10',
                    SalesGroupId: '846',
                    Description: 'Valladolid',
                },
                {
                    SalesOfficeId: 'ES10',
                    SalesGroupId: '848',
                    Description: 'Zamora',
                },
                {
                    SalesOfficeId: 'ES11',
                    SalesGroupId: '802',
                    Description: 'Alicante',
                },
                {
                    SalesOfficeId: 'ES11',
                    SalesGroupId: '811',
                    Description: 'Castellón',
                },
                {
                    SalesOfficeId: 'ES11',
                    SalesGroupId: '845',
                    Description: 'Valencia',
                },
                {
                    SalesOfficeId: 'ES13',
                    SalesGroupId: '838',
                    Description: 'Cantabria',
                },
                {
                    SalesOfficeId: 'ES14',
                    SalesGroupId: '807',
                    Description: 'Barcelona',
                },
                {
                    SalesOfficeId: 'ES14',
                    SalesGroupId: '816',
                    Description: 'Girona',
                },
                {
                    SalesOfficeId: 'ES14',
                    SalesGroupId: '824',
                    Description: 'Lleida',
                },
                {
                    SalesOfficeId: 'ES14',
                    SalesGroupId: '842',
                    Description: 'Tarragona',
                },
                {
                    SalesOfficeId: 'ES15',
                    SalesGroupId: '814',
                    Description: 'A Coruña',
                },
                {
                    SalesOfficeId: 'ES15',
                    SalesGroupId: '826',
                    Description: 'Lugo',
                },
                {
                    SalesOfficeId: 'ES15',
                    SalesGroupId: '831',
                    Description: 'Ourense',
                },
                {
                    SalesOfficeId: 'ES15',
                    SalesGroupId: '835',
                    Description: 'Pontevedra',
                },
                {
                    SalesOfficeId: 'ES16',
                    SalesGroupId: '825',
                    Description: 'La Rioja',
                },
                {
                    SalesOfficeId: 'ES17',
                    SalesGroupId: '827',
                    Description: 'Madrid',
                },
                {
                    SalesOfficeId: 'ES18',
                    SalesGroupId: '850',
                    Description: 'Ceuta',
                },
                {
                    SalesOfficeId: 'ES18',
                    SalesGroupId: '851',
                    Description: 'Melilla',
                },
                {
                    SalesOfficeId: 'ES19',
                    SalesGroupId: '829',
                    Description: 'Murcia',
                },
                {
                    SalesOfficeId: 'ES20',
                    SalesGroupId: '830',
                    Description: 'Navarra',
                },
                {
                    SalesOfficeId: 'ES21',
                    SalesGroupId: '800',
                    Description: 'Álava',
                },
                {
                    SalesOfficeId: 'ES21',
                    SalesGroupId: '819',
                    Description: 'Guipúzcoa',
                },
                {
                    SalesOfficeId: 'ES21',
                    SalesGroupId: '847',
                    Description: 'Vizcaya',
                },
                {
                    SalesOfficeId: 'ES22',
                    SalesGroupId: '805',
                    Description: 'Badajoz',
                },
                {
                    SalesOfficeId: 'ES22',
                    SalesGroupId: '809',
                    Description: 'Cáceres',
                },
                {
                    SalesOfficeId: 'ES23',
                    SalesGroupId: '852',
                    Description: 'Portugal',
                },
                {
                    SalesOfficeId: 'ES23',
                    SalesGroupId: '855',
                    Description: 'Alemania',
                },
                {
                    SalesOfficeId: 'ES24',
                    SalesGroupId: '853',
                    Description: 'Andorra',
                },
                {
                    SalesOfficeId: 'ES24',
                    SalesGroupId: '854',
                    Description: 'Gibraltar',
                },
                {
                    SalesOfficeId: 'FI01',
                    SalesGroupId: '711',
                    Description: 'Sales Group 1 FI',
                },
                {
                    SalesOfficeId: 'FI01',
                    SalesGroupId: '712',
                    Description: 'Sales Group 2 FI',
                },
                {
                    SalesOfficeId: 'FI01',
                    SalesGroupId: '713',
                    Description: 'Sales Group 3 FI',
                },
                {
                    SalesOfficeId: 'FI99',
                    SalesGroupId: '710',
                    Description: 'Finland',
                },
                {
                    SalesOfficeId: 'FR01',
                    SalesGroupId: '600',
                    Description: 'HQ',
                },
                {
                    SalesOfficeId: 'FR01',
                    SalesGroupId: '601',
                    Description: 'Carine LACKMY',
                },
                {
                    SalesOfficeId: 'FR01',
                    SalesGroupId: '602',
                    Description: 'Julien VACHE',
                },
                {
                    SalesOfficeId: 'FR01',
                    SalesGroupId: '603',
                    Description: 'Yamina TAHIRI',
                },
                {
                    SalesOfficeId: 'FR01',
                    SalesGroupId: '605',
                    Description: 'Nicolas VOLKOFF',
                },
                {
                    SalesOfficeId: 'FR01',
                    SalesGroupId: '606',
                    Description: 'Viviane BOURGEOIS',
                },
                {
                    SalesOfficeId: 'FR01',
                    SalesGroupId: '607',
                    Description: 'Catherine NGUYEN',
                },
                {
                    SalesOfficeId: 'FR01',
                    SalesGroupId: '608',
                    Description: 'Dom Tom',
                },
                {
                    SalesOfficeId: 'FR01',
                    SalesGroupId: '609',
                    Description: 'Clarisse WEISSMANN',
                },
                {
                    SalesOfficeId: 'FR01',
                    SalesGroupId: '60X',
                    Description: 'Export_non affecté',
                },
                {
                    SalesOfficeId: 'FR01',
                    SalesGroupId: '610',
                    Description: 'Eric SIMONIN',
                },
                {
                    SalesOfficeId: 'FR01',
                    SalesGroupId: '612',
                    Description: 'Corsica',
                },
                {
                    SalesOfficeId: 'FR01',
                    SalesGroupId: '613',
                    Description: 'Non Ded North',
                },
                {
                    SalesOfficeId: 'FR01',
                    SalesGroupId: '626',
                    Description: 'FMB',
                },
                {
                    SalesOfficeId: 'FR01',
                    SalesGroupId: '639',
                    Description: 'Paris-Normandie',
                },
                {
                    SalesOfficeId: 'FR01',
                    SalesGroupId: '640',
                    Description: 'Naâmane MOUMEN',
                },
                {
                    SalesOfficeId: 'FR02',
                    SalesGroupId: '600',
                    Description: 'HQ',
                },
                {
                    SalesOfficeId: 'FR02',
                    SalesGroupId: '601',
                    Description: 'Carine LACKMY',
                },
                {
                    SalesOfficeId: 'FR02',
                    SalesGroupId: '602',
                    Description: 'Julien VACHE',
                },
                {
                    SalesOfficeId: 'FR02',
                    SalesGroupId: '603',
                    Description: 'Yamina TAHIRI',
                },
                {
                    SalesOfficeId: 'FR02',
                    SalesGroupId: '605',
                    Description: 'Nicolas VOLKOFF',
                },
                {
                    SalesOfficeId: 'FR02',
                    SalesGroupId: '606',
                    Description: 'Viviane BOURGEOIS',
                },
                {
                    SalesOfficeId: 'FR02',
                    SalesGroupId: '607',
                    Description: 'Catherine NGUYEN',
                },
                {
                    SalesOfficeId: 'FR02',
                    SalesGroupId: '608',
                    Description: 'Dom Tom',
                },
                {
                    SalesOfficeId: 'FR02',
                    SalesGroupId: '609',
                    Description: 'Clarisse WEISSMANN',
                },
                {
                    SalesOfficeId: 'FR02',
                    SalesGroupId: '60X',
                    Description: 'Export_non affecté',
                },
                {
                    SalesOfficeId: 'FR02',
                    SalesGroupId: '610',
                    Description: 'Eric SIMONIN',
                },
                {
                    SalesOfficeId: 'FR02',
                    SalesGroupId: '612',
                    Description: 'Corsica',
                },
                {
                    SalesOfficeId: 'FR02',
                    SalesGroupId: '613',
                    Description: 'Non Ded North',
                },
                {
                    SalesOfficeId: 'FR02',
                    SalesGroupId: '626',
                    Description: 'FMB',
                },
                {
                    SalesOfficeId: 'FR02',
                    SalesGroupId: '639',
                    Description: 'Paris-Normandie',
                },
                {
                    SalesOfficeId: 'FR02',
                    SalesGroupId: '640',
                    Description: 'Naâmane MOUMEN',
                },
                {
                    SalesOfficeId: 'FR03',
                    SalesGroupId: '600',
                    Description: 'HQ',
                },
                {
                    SalesOfficeId: 'FR03',
                    SalesGroupId: '601',
                    Description: 'Carine LACKMY',
                },
                {
                    SalesOfficeId: 'FR03',
                    SalesGroupId: '602',
                    Description: 'Julien VACHE',
                },
                {
                    SalesOfficeId: 'FR03',
                    SalesGroupId: '603',
                    Description: 'Yamina TAHIRI',
                },
                {
                    SalesOfficeId: 'FR03',
                    SalesGroupId: '605',
                    Description: 'Nicolas VOLKOFF',
                },
                {
                    SalesOfficeId: 'FR03',
                    SalesGroupId: '606',
                    Description: 'Viviane BOURGEOIS',
                },
                {
                    SalesOfficeId: 'FR03',
                    SalesGroupId: '607',
                    Description: 'Catherine NGUYEN',
                },
                {
                    SalesOfficeId: 'FR03',
                    SalesGroupId: '608',
                    Description: 'Dom Tom',
                },
                {
                    SalesOfficeId: 'FR03',
                    SalesGroupId: '609',
                    Description: 'Clarisse WEISSMANN',
                },
                {
                    SalesOfficeId: 'FR03',
                    SalesGroupId: '60X',
                    Description: 'Export_non affecté',
                },
                {
                    SalesOfficeId: 'FR03',
                    SalesGroupId: '610',
                    Description: 'Eric SIMONIN',
                },
                {
                    SalesOfficeId: 'FR03',
                    SalesGroupId: '612',
                    Description: 'Corsica',
                },
                {
                    SalesOfficeId: 'FR03',
                    SalesGroupId: '613',
                    Description: 'Non Ded North',
                },
                {
                    SalesOfficeId: 'FR03',
                    SalesGroupId: '626',
                    Description: 'FMB',
                },
                {
                    SalesOfficeId: 'FR03',
                    SalesGroupId: '639',
                    Description: 'Paris-Normandie',
                },
                {
                    SalesOfficeId: 'FR03',
                    SalesGroupId: '640',
                    Description: 'Naâmane MOUMEN',
                },
                {
                    SalesOfficeId: 'FR04',
                    SalesGroupId: '600',
                    Description: 'HQ',
                },
                {
                    SalesOfficeId: 'FR04',
                    SalesGroupId: '601',
                    Description: 'Carine LACKMY',
                },
                {
                    SalesOfficeId: 'FR04',
                    SalesGroupId: '602',
                    Description: 'Julien VACHE',
                },
                {
                    SalesOfficeId: 'FR04',
                    SalesGroupId: '603',
                    Description: 'Yamina TAHIRI',
                },
                {
                    SalesOfficeId: 'FR04',
                    SalesGroupId: '605',
                    Description: 'Nicolas VOLKOFF',
                },
                {
                    SalesOfficeId: 'FR04',
                    SalesGroupId: '606',
                    Description: 'Viviane BOURGEOIS',
                },
                {
                    SalesOfficeId: 'FR04',
                    SalesGroupId: '607',
                    Description: 'Catherine NGUYEN',
                },
                {
                    SalesOfficeId: 'FR04',
                    SalesGroupId: '608',
                    Description: 'Dom Tom',
                },
                {
                    SalesOfficeId: 'FR04',
                    SalesGroupId: '609',
                    Description: 'Clarisse WEISSMANN',
                },
                {
                    SalesOfficeId: 'FR04',
                    SalesGroupId: '60X',
                    Description: 'Export_non affecté',
                },
                {
                    SalesOfficeId: 'FR04',
                    SalesGroupId: '610',
                    Description: 'Eric SIMONIN',
                },
                {
                    SalesOfficeId: 'FR04',
                    SalesGroupId: '612',
                    Description: 'Corsica',
                },
                {
                    SalesOfficeId: 'FR04',
                    SalesGroupId: '613',
                    Description: 'Non Ded North',
                },
                {
                    SalesOfficeId: 'FR04',
                    SalesGroupId: '626',
                    Description: 'FMB',
                },
                {
                    SalesOfficeId: 'FR04',
                    SalesGroupId: '639',
                    Description: 'Paris-Normandie',
                },
                {
                    SalesOfficeId: 'FR04',
                    SalesGroupId: '640',
                    Description: 'Naâmane MOUMEN',
                },
                {
                    SalesOfficeId: 'GB01',
                    SalesGroupId: '052',
                    Description: 'Simon Edgington',
                },
                {
                    SalesOfficeId: 'GB01',
                    SalesGroupId: '053',
                    Description: 'Andrew Partrick',
                },
                {
                    SalesOfficeId: 'GB01',
                    SalesGroupId: '054',
                    Description: 'John Bailey',
                },
                {
                    SalesOfficeId: 'GB01',
                    SalesGroupId: '055',
                    Description: 'Alison Avery',
                },
                {
                    SalesOfficeId: 'GB03',
                    SalesGroupId: '111',
                    Description: 'Andre Ludwig',
                },
                {
                    SalesOfficeId: 'GB03',
                    SalesGroupId: '113',
                    Description: 'Jonathan White',
                },
                {
                    SalesOfficeId: 'GB03',
                    SalesGroupId: '114',
                    Description: 'Jihad Hussami',
                },
                {
                    SalesOfficeId: 'GB04',
                    SalesGroupId: '111',
                    Description: 'Andre Ludwig',
                },
                {
                    SalesOfficeId: 'GB04',
                    SalesGroupId: '113',
                    Description: 'Jonathan White',
                },
                {
                    SalesOfficeId: 'GB04',
                    SalesGroupId: '114',
                    Description: 'Jihad Hussami',
                },
                {
                    SalesOfficeId: 'GB05',
                    SalesGroupId: '111',
                    Description: 'Andre Ludwig',
                },
                {
                    SalesOfficeId: 'GB05',
                    SalesGroupId: '113',
                    Description: 'Jonathan White',
                },
                {
                    SalesOfficeId: 'GB05',
                    SalesGroupId: '114',
                    Description: 'Jihad Hussami',
                },
                {
                    SalesOfficeId: 'GB06',
                    SalesGroupId: '111',
                    Description: 'Andre Ludwig',
                },
                {
                    SalesOfficeId: 'GB06',
                    SalesGroupId: '113',
                    Description: 'Jonathan White',
                },
                {
                    SalesOfficeId: 'GB06',
                    SalesGroupId: '114',
                    Description: 'Jihad Hussami',
                },
                {
                    SalesOfficeId: 'GB07',
                    SalesGroupId: '111',
                    Description: 'Andre Ludwig',
                },
                {
                    SalesOfficeId: 'GB07',
                    SalesGroupId: '113',
                    Description: 'Jonathan White',
                },
                {
                    SalesOfficeId: 'GB07',
                    SalesGroupId: '114',
                    Description: 'Jihad Hussami',
                },
                {
                    SalesOfficeId: 'GB08',
                    SalesGroupId: '111',
                    Description: 'Andre Ludwig',
                },
                {
                    SalesOfficeId: 'GB08',
                    SalesGroupId: '113',
                    Description: 'Jonathan White',
                },
                {
                    SalesOfficeId: 'GB08',
                    SalesGroupId: '114',
                    Description: 'Jihad Hussami',
                },
                {
                    SalesOfficeId: 'GB09',
                    SalesGroupId: '113',
                    Description: 'Jonathan White',
                },
                {
                    SalesOfficeId: 'GB10',
                    SalesGroupId: '057',
                    Description: 'National Accounts',
                },
                {
                    SalesOfficeId: 'GB13',
                    SalesGroupId: '061',
                    Description: 'Pyxis Scot/Eng/Wales',
                },
                {
                    SalesOfficeId: 'GB16',
                    SalesGroupId: '052',
                    Description: 'Simon Edgington',
                },
                {
                    SalesOfficeId: 'GB21',
                    SalesGroupId: '009',
                    Description: 'Kim Phillipson',
                },
                {
                    SalesOfficeId: 'GB21',
                    SalesGroupId: '052',
                    Description: 'Simon Edgington',
                },
                {
                    SalesOfficeId: 'GB22',
                    SalesGroupId: '053',
                    Description: 'Andrew Partrick',
                },
                {
                    SalesOfficeId: 'GB22',
                    SalesGroupId: '055',
                    Description: 'Alison Avery',
                },
                {
                    SalesOfficeId: 'GB22',
                    SalesGroupId: '063',
                    Description: 'Vacant',
                },
                {
                    SalesOfficeId: 'GB22',
                    SalesGroupId: '064',
                    Description: 'Kevin Tozer',
                },
                {
                    SalesOfficeId: 'GB23',
                    SalesGroupId: '068',
                    Description: 'Josh Aikin-George',
                },
                {
                    SalesOfficeId: 'GB24',
                    SalesGroupId: '069',
                    Description: 'House Accounts',
                },
                {
                    SalesOfficeId: 'GB40',
                    SalesGroupId: '052',
                    Description: 'Simon Edgington',
                },
                {
                    SalesOfficeId: 'GB41',
                    SalesGroupId: '053',
                    Description: 'Andrew Partrick',
                },
                {
                    SalesOfficeId: 'GB42',
                    SalesGroupId: '055',
                    Description: 'Alison Avery',
                },
                {
                    SalesOfficeId: 'IT02',
                    SalesGroupId: '362',
                    Description: 'PIGRUCCI',
                },
                {
                    SalesOfficeId: 'IT03',
                    SalesGroupId: '366',
                    Description: 'P ROSA',
                },
                {
                    SalesOfficeId: 'IT04',
                    SalesGroupId: '319',
                    Description: 'RUBINO',
                },
                {
                    SalesOfficeId: 'IT04',
                    SalesGroupId: '357',
                    Description: 'CALABRESE',
                },
                {
                    SalesOfficeId: 'IT04',
                    SalesGroupId: '383',
                    Description: 'CARERE',
                },
                {
                    SalesOfficeId: 'IT06',
                    SalesGroupId: '393',
                    Description: 'PUGLIA',
                },
                {
                    SalesOfficeId: 'NL01',
                    SalesGroupId: '201',
                    Description: 'De Koning',
                },
                {
                    SalesOfficeId: 'NL01',
                    SalesGroupId: '202',
                    Description: 'Erik Pat',
                },
                {
                    SalesOfficeId: 'NL01',
                    SalesGroupId: '203',
                    Description: 'No Salesman',
                },
                {
                    SalesOfficeId: 'NL01',
                    SalesGroupId: '204',
                    Description: 'Janine Schaveling',
                },
                {
                    SalesOfficeId: 'NL01',
                    SalesGroupId: '205',
                    Description: 'Richard Monkel',
                },
                {
                    SalesOfficeId: 'NL01',
                    SalesGroupId: '206',
                    Description: "Arnaud D'Elfant",
                },
                {
                    SalesOfficeId: 'NO01',
                    SalesGroupId: '250',
                    Description: 'District 1',
                },
                {
                    SalesOfficeId: 'NO01',
                    SalesGroupId: '251',
                    Description: 'District 2',
                },
                {
                    SalesOfficeId: 'NO01',
                    SalesGroupId: '252',
                    Description: 'District 3',
                },
                {
                    SalesOfficeId: 'NZ01',
                    SalesGroupId: '239',
                    Description: 'Esther Fraser',
                },
                {
                    SalesOfficeId: 'NZ01',
                    SalesGroupId: '290',
                    Description: 'Debbie Douglas',
                },
                {
                    SalesOfficeId: 'NZ01',
                    SalesGroupId: '637',
                    Description: 'NZ_1',
                },
                {
                    SalesOfficeId: 'NZ02',
                    SalesGroupId: '289',
                    Description: 'Lesley Cox',
                },
                {
                    SalesOfficeId: 'SE01',
                    SalesGroupId: '240',
                    Description: 'Distrikt 1',
                },
                {
                    SalesOfficeId: 'SE01',
                    SalesGroupId: '241',
                    Description: 'Distrikt 2',
                },
                {
                    SalesOfficeId: 'SE01',
                    SalesGroupId: '242',
                    Description: 'Distrikt 3',
                },
                {
                    SalesOfficeId: 'SE01',
                    SalesGroupId: '243',
                    Description: 'Distrikt 4',
                },
                {
                    SalesOfficeId: 'SE01',
                    SalesGroupId: '244',
                    Description: 'Distrikt 5',
                },
                {
                    SalesOfficeId: 'SE01',
                    SalesGroupId: '245',
                    Description: 'Denmark',
                },
                {
                    SalesOfficeId: 'SE01',
                    SalesGroupId: '246',
                    Description: 'Finland',
                },
                {
                    SalesOfficeId: 'SE01',
                    SalesGroupId: '247',
                    Description: 'Iceland',
                },
            ],
        })
    );
};

export const fetchOlympusCreateTeamRoleFieldMapping = () => {
    return new Promise((resolve, reject) =>
        resolve({
            ContractTeamFields: [
                {
                    id: 1,
                    SystemTypeId: 2,
                    Role: 'soldTo',
                    RoleTypeId: 1,
                    Fields: [
                        'System',
                        'Role',
                        'CompanyCode',
                        'SalesOrg',
                        'DistributionChannel',
                        'Division',
                        'Language',
                        'ServiceCode',
                        'DeliveryInformation',
                        'TaxNumber4',
                        'CustomerClass',
                        'ReconAccount',
                        'TermsOfPayment',
                        'SalesDistrict',
                        'SalesOffice',
                        'SalesGroup',
                        'Currency',
                        'PrimaryGPO',
                        'IncoTerms1',
                        'PlaceOfDestination',
                        'AcctAssignmentGroup',
                        'TaxClassification',
                        'RequestedCreditLimit',
                        'PurposeOfRequest',
                        'Priority',
                        'EffectiveDate',
                        'TermsOfPayment',
                        'IncoTerms1TypeId',
                        'Incoterms2',
                        'DistributionAgreement',
                        'AdditionalNotes',
                        'URIType',
                        'DeliveringPlant',
                        'PriceGroup',
                        'PaymentTerms',
                    ],
                },
                {
                    id: 2,
                    SystemTypeId: 2,
                    Role: 'shipTo',
                    RoleTypeId: 2,
                    Fields: [
                        'System',
                        'Role',
                        'CompanyCode',
                        'SalesOrg',
                        'DistributionChannel',
                        'Division',
                        'SoldTo',
                        'Language',
                        'DeliveryInformation',
                        'TaxNumber4',
                        'TaxClassification',
                        'PurposeOfRequest',
                        'Priority',
                        'EffectiveDate',
                        'AdditionalNotes',
                        'DeliveringPlant',
                    ],
                },
                {
                    id: 3,
                    SystemTypeId: 2,
                    Role: 'payer',
                    RoleTypeId: 3,
                    Fields: [
                        'System',
                        'Role',
                        'CompanyCode',
                        'SalesOrg',
                        'DistributionChannel',
                        'Division',
                        'SoldTo',
                        'Language',
                        'ServiceCode',
                        'DeliveryInformation',
                        'TaxNumber4',
                        'CustomerClass',
                        'ReconAccount',
                        'TermsOfPayment',
                        'IncoTerms1',
                        'PlaceOfDestination',
                        'AcctAssignmentGroup',
                        'TaxClassification',
                        'RequestedCreditLimit',
                        'PurposeOfRequest',
                        'Priority',
                        'EffectiveDate',
                        'TermsOfPayment',
                        'IncoTerms1TypeId',
                        'Incoterms2',
                        'AdditionalNotes',
                        'URIType',
                        'PaymentTerms',
                    ],
                },
                {
                    id: 4,
                    SystemTypeId: 2,
                    Role: 'billto',
                    RoleTypeId: 4,
                    Fields: [
                        'System',
                        'Role',
                        'CompanyCode',
                        'SalesOrg',
                        'DistributionChannel',
                        'Division',
                        'SoldTo',
                        'Language',
                        'ServiceCode',
                        'DeliveryInformation',
                        'TaxNumber4',
                        'CustomerClass',
                        'TermsOfPayment',
                        'RequestedCreditLimit',
                        'PurposeOfRequest',
                        'Priority',
                        'EffectiveDate',
                        'AdditionalNotes',
                        'URIType',
                        'PaymentTerms',
                    ],
                },
                {
                    id: 23,
                    SystemTypeId: 2,
                    Role: 'IntercompanyCustomers',
                    RoleTypeId: 23,
                    Fields: [
                        'System',
                        'Role',
                        'CompanyCode',
                        'SalesOrg',
                        'DistributionChannel',
                        'Division',
                        'Language',
                        'DeliveryInformation',
                        'TaxNumber4',
                        'CustomerClass',
                        'ReconAccount',
                        'TermsOfPayment',
                        'Currency',
                        'IncoTerms1',
                        'PlaceOfDestination',
                        'AcctAssignmentGroup',
                        'TaxClassification',
                        'RequestedCreditLimit',
                        'PurposeofRequest',
                        'Priority',
                        'EffectiveDate',
                        'AdditionalNotes',
                        'CustomerNumber',
                    ],
                },
                {
                    id: 28,
                    SystemTypeId: 2,
                    Role: 'IntercompanyCustomersPayer',
                    RoleTypeId: 28,
                    Fields: [
                        'System',
                        'Role',
                        'CompanyCode',
                        'SalesOrg',
                        'DistributionChannel',
                        'Division',
                        'Language',
                        'ReconAccount',
                        'TermsOfPayment',
                        'AcctAssignmentGroup',
                        'TaxClassification',
                        'PurposeofRequest',
                        'Priority',
                        'EffectiveDate',
                        'AdditionalNotes',
                        'CustomerNumber',
                        'DeliveryInformation',
                    ],
                },
                {
                    id: 29,
                    SystemTypeId: 2,
                    Role: 'SalesRep',
                    RoleTypeId: 29,
                    Fields: [
                        'System',
                        'Role',
                        'CompanyCode',
                        'SalesOrg',
                        'DistributionChannel',
                        'Division',
                        'SoldTo',
                        'Language',
                        'DeliveryInformation',
                        'TaxNumber4',
                        'TaxClassification',
                        'PurposeOfRequest',
                        'Priority',
                        'EffectiveDate',
                        'AdditionalNotes',
                    ],
                },
            ],
            CreditTeamFields: [
                {
                    id: 1,
                    SystemTypeId: 2,
                    Role: 'soldTo',
                    RoleTypeId: 1,
                    Fields: [
                        'ServiceCode',
                        'UnloadingPoints',
                        'Language',
                        'CustomerClass',
                        'ReconAccount',
                        'TermsOfPayment',
                        'SalesDistrict',
                        'SalesOffice',
                        'SalesGroup',
                        'Currency',
                        'PrimaryGPO',
                        'IncoTerms',
                        'PlaceOfDestination',
                        'AcctAssignmentGroup',
                        'TaxClassification',
                        'RequestedCreditLimit',
                        'AccountStatement',
                        'CreditControlArea',
                        'CreditLimit',
                        'RiskCategory',
                        'CustomerCreditGroup',
                        'CredInfoNumber',
                        'PaymentIndex',
                        'LastExtReview',
                        'Rating',
                        'AccountStatementTypeId',
                        'CreditControlAreaTypeId',
                        'RiskCategoryTypeId',
                        'CustomerCreditGroupTypeId',
                        'LastExtReview',
                        'URIType',
                        'DeliveringPlant',
                        'PriceGroup',
                        'DeliveryInformation',
                        'TaxNumber4',
                    ],
                },
                {
                    id: 2,
                    SystemTypeId: 2,
                    Role: 'shipTo',
                    RoleTypeId: 2,
                    Fields: [
                        'System',
                        'Role',
                        'CompanyCode',
                        'SalesOrg',
                        'DistributionChannel',
                        'Division',
                        'SoldTo',
                        'Language',
                        'DeliveryInformation',
                        'TaxNumber4',
                        'TaxClassification',
                        'PurposeofRequest',
                        'Priority',
                        'EffectiveDate',
                        'AdditionalNotes',
                        'SoldTo',
                        'TaxClassification',
                        // 'Rating',
                        'AccountStatementTypeId',
                        'CreditControlAreaTypeId',
                        'RiskCategoryTypeId',
                        'CustomerCreditGroupTypeId',
                        // 'LastExtReview',
                        'UnloadingPoints',
                        'DeliveringPlant',
                    ],
                },
                {
                    id: 3,
                    SystemTypeId: 2,
                    Role: 'payer',
                    RoleTypeId: 3,
                    Fields: [
                        'System',
                        'Role',
                        'CompanyCode',
                        'SalesOrg',
                        'DistributionChannel',
                        'Division',
                        'SoldTo',
                        'Language',
                        'ServiceCode',
                        'DeliveryInformation',
                        'TaxNumber4',
                        'CustomerClass',
                        'ReconAccount',
                        'TermsOfPayment',
                        'IncoTerms',
                        'PlaceOfDestination',
                        'AcctAssignmentGroup',
                        'TaxClassification',
                        'RequestedCreditLimit',
                        'PurposeOfRequest',
                        'Priority',
                        'EffectiveDate',
                        'AccountStatement',
                        'CreditControlArea',
                        'CreditLimit',
                        'RiskCategory',
                        'CustomerCreditGroup',
                        'CredInfoNumber',
                        'PaymentIndex',
                        'LastExtReview',
                        'Rating',
                        'AdditionalNotes',
                        'AccountStatementTypeId',
                        'CreditControlAreaTypeId',
                        'RiskCategoryTypeId',
                        'CustomerCreditGroupTypeId',
                        'LastExtReview',
                        'URIType',
                    ],
                },
                {
                    id: 4,
                    SystemTypeId: 2,
                    Role: 'billto',
                    RoleTypeId: 4,
                    Fields: [
                        'System',
                        'Role',
                        'CompanyCode',
                        'SalesOrg',
                        'DistributionChannel',
                        'Division',
                        'SoldTo',
                        'Language',
                        'ServiceCode',
                        'DeliveryInformation',
                        'TaxNumber4',
                        'CustomerClass',
                        'TermsOfPayment',
                        'RequestedCreditLimit',
                        'PurposeOfRequest',
                        'Priority',
                        'EffectiveDate',
                        'AccountStatement',
                        'CreditControlArea',
                        'CreditLimit',
                        'RiskCategory',
                        'CustomerCreditGroup',
                        'CredInfoNumber',
                        'PaymentIndex',
                        'LastExtReview',
                        'Rating',
                        'AdditionalNotes',
                        'AccountStatementTypeId',
                        'CreditControlAreaTypeId',
                        'RiskCategoryTypeId',
                        'CustomerCreditGroupTypeId',
                        'LastExtReview',
                        'URIType',
                    ],
                },
                {
                    id: 23,
                    SystemTypeId: 2,
                    Role: 'IntercompanyCustomers',
                    RoleTypeId: 23,
                    Fields: [
                        'System',
                        'Role',
                        'CompanyCode',
                        'SalesOrg',
                        'DistributionChannel',
                        'Division',
                        'Language',
                        'DeliveryInformation',
                        'TaxNumber4',
                        'CustomerClass',
                        'ReconAccount',
                        'TermsOfPayment',
                        'Currency',
                        'IncoTerms',
                        'PlaceOfDestination',
                        'AcctAssignmentGroup',
                        'TaxClassification',
                        'RequestedCreditLimit',
                        'PurposeofRequest',
                        'Priority',
                        'EffectiveDate',
                        'AdditionalNotes',
                        'CustomerNumber',
                    ],
                },
                {
                    id: 28,
                    SystemTypeId: 2,
                    Role: 'IntercompanyCustomersPayer',
                    RoleTypeId: 28,
                    Fields: [
                        'System',
                        'Role',
                        'CompanyCode',
                        'SalesOrg',
                        'DistributionChannel',
                        'Division',
                        'Language',
                        'ReconAccount',
                        'TermsOfPayment',
                        'AcctAssignmentGroup',
                        'TaxClassification',
                        'PurposeofRequest',
                        'Priority',
                        'EffectiveDate',
                        'AdditionalNotes',
                        'CustomerNumber',
                        'DeliveryInformation',
                    ],
                },
                {
                    id: 29,
                    SystemTypeId: 2,
                    Role: 'SalesRep',
                    RoleTypeId: 29,
                    Fields: [
                        'System',
                        'Role',
                        'CompanyCode',
                        'SalesOrg',
                        'DistributionChannel',
                        'Division',
                        'SoldTo',
                        'Language',
                        'DeliveryInformation',
                        'TaxNumber4',
                        'TaxClassification',
                        'PurposeofRequest',
                        'Priority',
                        'EffectiveDate',
                        'AdditionalNotes',
                        'SoldTo',
                        'TaxClassification',
                        // 'Rating',
                        'AccountStatementTypeId',
                        'CreditControlAreaTypeId',
                        'RiskCategoryTypeId',
                        'CustomerCreditGroupTypeId',
                        // 'LastExtReview',
                        'UnloadingPoints',
                    ],
                },
            ],
            GlobalTradeTeamFields: [
                {
                    id: 1,
                    SystemTypeId: 2,
                    Role: 'soldTo',
                    RoleTypeId: 1,
                    Fields: [
                        'System',
                        'Role',
                        'CompanyCode',
                        'SalesOrg',
                        'DistributionChannel',
                        'Division',
                        'Language',
                        'ServiceCode',
                        'DeliveryInformation',
                        'TaxNumber4',
                        'CustomerClass',
                        'ReconAccount',
                        'TermsOfPayment',
                        'SalesDistrict',
                        'SalesOffice',
                        'SalesGroup',
                        'Currency',
                        'PrimaryGPO',
                        'Incoterms1',
                        'PlaceOfDestination',
                        'AcctAssignmentGroup',
                        'TaxClassification',
                        'RequestedCreditLimit',
                        'PurposeOfRequest',
                        'Priority',
                        'EffectiveDate',
                        'AdditionalNotes',
                        'URIType',
                        'DeliveringPlant',
                        'PriceGroup',
                    ],
                },
                {
                    id: 2,
                    SystemTypeId: 2,
                    Role: 'shipTo',
                    RoleTypeId: 2,
                    Fields: [
                        'System',
                        'Role',
                        'CompanyCode',
                        'SalesOrg',
                        'DistributionChannel',
                        'Division',
                        'SoldTo',
                        'Language',
                        'DeliveryInformation',
                        'TaxNumber4',
                        'TaxClassification',
                        'PurposeOfRequest',
                        'Priority',
                        'EffectiveDate',
                        'AdditionalNotes',
                        'DeliveringPlant',
                    ],
                },
                {
                    id: 3,
                    SystemTypeId: 2,
                    Role: 'payer',
                    RoleTypeId: 3,
                    Fields: [
                        'System',
                        'Role',
                        'CompanyCode',
                        'SalesOrg',
                        'DistributionChannel',
                        'Division',
                        'SoldTo',
                        'Language',
                        'ServiceCode',
                        'DeliveryInformation',
                        'TaxNumber4',
                        'CustomerClass',
                        'ReconAccount',
                        'TermsOfPayment',
                        'Incoterms1',
                        'PlaceOfDestination',
                        'AcctAssignmentGroup',
                        'TaxClassification',
                        'RequestedCreditLimit',
                        'PurposeOfRequest',
                        'Priority',
                        'EffectiveDate',
                        'AdditionalNotes',
                        'URIType',
                    ],
                },
                {
                    id: 4,
                    SystemTypeId: 2,
                    Role: 'billto',
                    RoleTypeId: 4,
                    Fields: [
                        'System',
                        'Role',
                        'CompanyCode',
                        'SalesOrg',
                        'DistributionChannel',
                        'Division',
                        'SoldTo',
                        'Language',
                        'ServiceCode',
                        'DeliveryInformation',
                        'TaxNumber4',
                        'CustomerClass',
                        'TermsOfPayment',
                        'RequestedCreditLimit',
                        'PurposeOfRequest',
                        'Priority',
                        'EffectiveDate',
                        'AdditionalNotes',
                        'URIType',
                    ],
                },
                {
                    id: 23,
                    SystemTypeId: 2,
                    Role: 'IntercompanyCustomers',
                    RoleTypeId: 23,
                    Fields: [
                        'System',
                        'Role',
                        'CompanyCode',
                        'SalesOrg',
                        'DistributionChannel',
                        'Division',
                        'Language',
                        'DeliveryInformation',
                        'TaxNumber4',
                        'CustomerClass',
                        'ReconAccount',
                        'TermsOfPayment',
                        'Currency',
                        'Incoterms1',
                        'PlaceOfDestination',
                        'AcctAssignmentGroup',
                        'TaxClassification',
                        'RequestedCreditLimit',
                        'PurposeOfRequest',
                        'Priority',
                        'EffectiveDate',
                        'AdditionalNotes',
                        'CustomerNumber',
                    ],
                },
                {
                    id: 28,
                    SystemTypeId: 2,
                    Role: 'IntercompanyCustomersPayer',
                    RoleTypeId: 28,
                    Fields: [
                        'System',
                        'Role',
                        'CompanyCode',
                        'SalesOrg',
                        'DistributionChannel',
                        'Division',
                        'Language',
                        'ReconAccount',
                        'TermsOfPayment',
                        'AcctAssignmentGroup',
                        'TaxClassification',
                        'PurposeofRequest',
                        'Priority',
                        'EffectiveDate',
                        'AdditionalNotes',
                        'CustomerNumber',
                        'PurposeOfRequest',
                        'DeliveryInformation',
                    ],
                },
                {
                    id: 29,
                    SystemTypeId: 2,
                    Role: 'SalesRep',
                    RoleTypeId: 29,
                    Fields: [
                        'System',
                        'Role',
                        'CompanyCode',
                        'SalesOrg',
                        'DistributionChannel',
                        'Division',
                        // 'SoldTo',
                        'Language',
                        'DeliveryInformation',
                        'TaxNumber4',
                        'TaxClassification',
                        'PurposeOfRequest',
                        'Priority',
                        'EffectiveDate',
                        'AdditionalNotes',
                        // 'DeliveringPlant',
                    ],
                },
            ],
            PricingTeamFields: [
                {
                    id: 1,
                    SystemTypeId: 2,
                    Role: 'soldTo',
                    RoleTypeId: 1,
                    Fields: [
                        'System',
                        'Role',
                        'CompanyCode',
                        'SalesOrg',
                        'DistributionChannel',
                        'Division',
                        'Language',
                        'ServiceCode',
                        'DeliveryInformation',
                        'TaxNumber4',
                        'CustomerClass',
                        'ReconAccount',
                        'TermsOfPayment',
                        'SalesDistrict',
                        'SalesOffice',
                        'SalesGroup',
                        'Currency',
                        'PrimaryGPO',
                        'Incoterms1',
                        'PlaceOfDestination',
                        'AcctAssignmentGroup',
                        'TaxClassification',
                        'RequestedCreditLimit',
                        'PurposeOfRequest',
                        'Priority',
                        'EffectiveDate',
                        'AdditionalNotes',
                        'PriceGroup',
                        'URIType',
                        'DeliveringPlant',
                        'PriceGroup',
                    ],
                },
                {
                    id: 2,
                    SystemTypeId: 2,
                    Role: 'shipTo',
                    RoleTypeId: 2,
                    Fields: [
                        'System',
                        'Role',
                        'CompanyCode',
                        'SalesOrg',
                        'DistributionChannel',
                        'Division',
                        'SoldTo',
                        'Language',
                        'DeliveryInformation',
                        'TaxNumber4',
                        'TaxClassification',
                        'PurposeOfRequest',
                        'Priority',
                        'EffectiveDate',
                        'AdditionalNotes',
                        'DeliveringPlant',
                    ],
                },
                {
                    id: 3,
                    SystemTypeId: 2,
                    Role: 'payer',
                    RoleTypeId: 3,
                    Fields: [
                        'System',
                        'Role',
                        'CompanyCode',
                        'SalesOrg',
                        'DistributionChannel',
                        'Division',
                        'SoldTo',
                        'Language',
                        'ServiceCode',
                        'DeliveryInformation',
                        'TaxNumber4',
                        'CustomerClass',
                        'ReconAccount',
                        'TermsOfPayment',
                        'Incoterms1',
                        'PlaceOfDestination',
                        'AcctAssignmentGroup',
                        'TaxClassification',
                        'RequestedCreditLimit',
                        'PurposeOfRequest',
                        'Priority',
                        'EffectiveDate',
                        'AdditionalNotes',
                        'URIType',
                    ],
                },
                {
                    id: 4,
                    SystemTypeId: 2,
                    Role: 'billto',
                    RoleTypeId: 4,
                    Fields: [
                        'System',
                        'Role',
                        'CompanyCode',
                        'SalesOrg',
                        'DistributionChannel',
                        'Division',
                        'SoldTo',
                        'Language',
                        'ServiceCode',
                        'DeliveryInformation',
                        'TaxNumber4',
                        'CustomerClass',
                        'TermsOfPayment',
                        'RequestedCreditLimit',
                        'PurposeOfRequest',
                        'Priority',
                        'EffectiveDate',
                        'AdditionalNotes',
                        'URIType',
                    ],
                },
                { id: 5, SystemTypeId: 2, Role: 'salesRep', RoleTypeId: 5, Fields: [] },
                {
                    id: 6,
                    SystemTypeId: 2,
                    Role: 'dropShip',
                    RoleTypeId: 6,
                    Fields: [
                        'SpecialPricingTypeId',
                        'DistLevelTypeId',
                        'DAPAPricingTypeId',
                        'DAPA2PricingTypeId',
                        'FSSPricingTypeId',
                    ],
                },
                {
                    id: 23,
                    SystemTypeId: 2,
                    Role: 'IntercompanyCustomers',
                    RoleTypeId: 23,
                    Fields: [
                        'System',
                        'Role',
                        'CompanyCode',
                        'SalesOrg',
                        'DistributionChannel',
                        'Division',
                        'Language',
                        'DeliveryInformation',
                        'TaxNumber4',
                        'CustomerClass',
                        'ReconAccount',
                        'TermsofPayment',
                        'Currency',
                        'IncoTerms1',
                        'PlaceofDestination',
                        'AcctAssignmentGroup',
                        'TaxClassification',
                        'RequestedCreditLimit',
                        'PurposeofRequest',
                        'Priority',
                        'EffectiveDate',
                        'AdditionalNotes',
                        'CustomerNumber',
                    ],
                },
                {
                    id: 28,
                    SystemTypeId: 2,
                    Role: 'IntercompanyCustomersPayer',
                    RoleTypeId: 28,
                    Fields: [
                        'System',
                        'Role',
                        'CompanyCode',
                        'SalesOrg',
                        'DistributionChannel',
                        'Division',
                        'Language',
                        'ReconAccount',
                        'TermsOfPayment',
                        'AcctAssignmentGroup',
                        'TaxClassification',
                        'PurposeofRequest',
                        'Priority',
                        'EffectiveDate',
                        'AdditionalNotes',
                        'CustomerNumber',
                        'DeliveryInformation',
                    ],
                },
                {
                    id: 29,
                    SystemTypeId: 2,
                    Role: 'SalesRep',
                    RoleTypeId: 29,
                    Fields: [
                        'System',
                        'Role',
                        'CompanyCode',
                        'SalesOrg',
                        'DistributionChannel',
                        'Division',
                        'SoldTo',
                        'Language',
                        'DeliveryInformation',
                        'TaxNumber4',
                        'TaxClassification',
                        'PurposeOfRequest',
                        'Priority',
                        'EffectiveDate',
                        'AdditionalNotes',
                    ],
                },
            ],
            CustomerMasterTeamFields: [
                {
                    id: 1,
                    SystemTypeId: 1,
                    Role: 'soldTo',
                    RoleTypeId: 1,
                    Fields: [
                        'TransporationZone',
                        'License',
                        'LicenseExpDate',
                        'SearchTerm1',
                        'SearchTerm2',
                        'CustomerClassTypeId',
                        'CustomerPriceProcTypeId',
                        'IndustryCodeTypeId',
                        'MarketingSegmentationTypeId',
                        'ReconAccountTypeId',
                        'SalesOfficeTypeId',
                        'CustomerGroupTypeId',
                        'PpcustProcTypeId',
                        'TaxNumber2',
                        'SortKey',
                        'PaymentMethods',
                        'AcctgClerk',
                        'AccountStatementTypeId',
                        'TaxClassificationTypeId',
                        'PriceListTypeId',
                        'DeliveryPriorityTypeId',
                        'ShippingConditionsTypeId',
                        'IncoTerms1TypeId',
                        'IncoTerms2',
                        'AcctAssignmentGroupTypeId',
                        'AccountTypeId',
                        'ShippingCustomerTypeId',
                        'OrderCombination',
                        'PaymentHistoryRecord',
                        'URIType',
                    ],
                },
                {
                    id: 2,
                    SystemTypeId: 1,
                    Role: 'shipTo',
                    RoleTypeId: 2,
                    Fields: [
                        'TransporationZone',
                        'License',
                        'LicenseExpDate',
                        'SearchTerm1',
                        'SearchTerm2',
                        'CustomerClassTypeId',
                        'CustomerPriceProcTypeId',
                        'IndustryCodeTypeId',
                        'MarketingSegmentationTypeId',
                        'ReconAccountTypeId',
                        'SalesOfficeTypeId',
                        'CustomerGroupTypeId',
                        'PpcustProcTypeId',
                        'TaxNumber2',
                        'SortKey',
                        'PaymentMethods',
                        'AcctgClerk',
                        'AccountStatementTypeId',
                        'TaxClassificationTypeId',
                        'PriceListTypeId',
                        'DeliveryPriorityTypeId',
                        'ShippingConditionsTypeId',
                        'IncoTerms1TypeId',
                        'IncoTerms2',
                        'AcctAssignmentGroupTypeId',
                        'AccountTypeId',
                        'ShippingCustomerTypeId',
                        'OrderCombination',
                        'PaymentHistoryRecord',
                    ],
                },
                {
                    id: 3,
                    SystemTypeId: 1,
                    Role: 'payer',
                    RoleTypeId: 3,
                    Fields: [
                        'SearchTerm1',
                        'SearchTerm2',
                        'CustomerClassTypeId',
                        'ReconAccountTypeId',
                        'SalesOfficeTypeId',
                        'TaxNumber2',
                        'SortKey',
                        'PaymentMethods',
                        'AcctgClerk',
                        'AccountStatementTypeId',
                        'TaxClassificationTypeId',
                        'AcctAssignmentGroupTypeId',
                        'AccountTypeId',
                        'ShippingCustomerTypeId',
                        'PaymentHistoryRecord',
                        'URIType',
                    ],
                },
                {
                    id: 4,
                    SystemTypeId: 1,
                    Role: 'billto',
                    RoleTypeId: 4,
                    Fields: [
                        'SearchTerm1',
                        'SearchTerm2',
                        'CustomerClassTypeId',
                        'ReconAccountTypeId',
                        'SalesOfficeTypeId',
                        'TaxNumber2',
                        'SortKey',
                        'PaymentMethods',
                        'AcctgClerk',
                        'AccountStatementTypeId',
                        'AccountTypeId',
                        'ShippingCustomerTypeId',
                        'PaymentHistoryRecord',
                        'URIType',
                    ],
                },
                {
                    id: 29,
                    SystemTypeId: 1,
                    Role: 'salesRep',
                    RoleTypeId: 9,
                    Fields: [
                        'TransporationZone',
                        'License',
                        'LicenseExpDate',
                        'SearchTerm1',
                        'SearchTerm2',
                        'CustomerClassTypeId',
                        'CustomerPriceProcTypeId',
                        'IndustryCodeTypeId',
                        'MarketingSegmentationTypeId',
                        'ReconAccountTypeId',
                        'SalesOfficeTypeId',
                        'CustomerGroupTypeId',
                        'PpcustProcTypeId',
                        'TaxNumber2',
                        'SortKey',
                        'PaymentMethods',
                        'AcctgClerk',
                        'AccountStatementTypeId',
                        'TaxClassificationTypeId',
                        'PriceListTypeId',
                        'DeliveryPriorityTypeId',
                        'ShippingConditionsTypeId',
                        'IncoTerms1TypeId',
                        'IncoTerms2',
                        'AcctAssignmentGroupTypeId',
                        'AccountTypeId',
                        'ShippingCustomerTypeId',
                        'OrderCombination',
                        'PaymentHistoryRecord',
                    ],
                },
                {
                    id: 6,
                    SystemTypeId: 1,
                    Role: 'dropShip',
                    RoleTypeId: 6,
                    Fields: [
                        'TransporationZone',
                        'License',
                        'LicenseExpDate',
                        'SearchTerm1',
                        'SearchTerm2',
                        'CustomerClassTypeId',
                        'CustomerPriceProcTypeId',
                        'IndustryCodeTypeId',
                        'MarketingSegmentationTypeId',
                        'ReconAccountTypeId',
                        'SalesOfficeTypeId',
                        'CustomerGroupTypeId',
                        'PpcustProcTypeId',
                        'TaxNumber2',
                        'SortKey',
                        'PaymentMethods',
                        'AcctgClerk',
                        'AccountStatementTypeId',
                        'TaxClassificationTypeId',
                        'PriceListTypeId',
                        'DeliveryPriorityTypeId',
                        'ShippingConditionsTypeId',
                        'IncoTerms1TypeId',
                        'IncoTerms2',
                        'AcctAssignmentGroupTypeId',
                        'AccountTypeId',
                        'ShippingCustomerTypeId',
                        'OrderCombination',
                        'PaymentHistoryRecord',
                    ],
                },
            ],
        })
    );
};

export const fetchOlympusFieldMapping = () => {
    return new Promise((resolve, reject) =>
        resolve({
            OlympusCustomerMasterFields: [
                {
                    id: 1,
                    SystemTypeId: 2,
                    Role: 'SoldTo',
                    RoleTypeId: 1,
                    Fields: ['SoldTo', 'CustomerNumber'],
                },
                {
                    id: 2,
                    Role: 'ShipTo',
                    SystemTypeId: 2,
                    RoleTypeId: 2,
                    Fields: [
                        'ServiceCode',
                        'CustomerClass',
                        'ReconAccount',
                        'TermsOfPayment',
                        'SalesDistrict',
                        'SalesOffice',
                        'SalesGroup',
                        'Currency',
                        'PrimaryGPO',
                        'Incoterms1',
                        'PlaceOfDestination',
                        'AcctAssignmentGroup',
                        'RequestedCreditLimit',
                        'Industry',
                        'IndustryCode1',
                        'SortKey',
                        'CustomerPriceProc',
                        'CustomerGroup',
                        'OrderProbab',
                        'PriceGroup',
                        'CustomerStatGroup',
                        'PaymentHistoryRecord',
                        'IncoTerms2',
                        'URIType',
                        'CustomerNumber',
                    ],
                },
                {
                    id: 3,
                    SystemTypeId: 2,
                    Role: 'Payer',
                    RoleTypeId: 3,
                    Fields: [
                        'SalesDistrict',
                        'SalesOffice',
                        'SalesGroup',
                        'Currency',
                        'PrimaryGPO',
                        'CustomerPriceProc',
                        'CustomerGroup',
                        'DeliveryPriority',
                        'OrderCombination',
                        'ShippingConditions',
                        'MaxPartialDeliveries',
                        'OrderProbab',
                        'PriceGroup',
                        'CompleteDeliveryRequired',
                        'PartialDeliveryPerItem',
                        'CustomerStatGroup',
                        'DeliveringPlant',
                        'TransportationZone',
                        'CustomerNumber',
                    ],
                },
                {
                    id: 4,
                    SystemTypeId: 2,
                    Role: 'BillTo',
                    RoleTypeId: 4,
                    Fields: [
                        'ReconAccount',
                        'SalesDistrict',
                        'SalesOffice',
                        'SalesGroup',
                        'Currency',
                        'PrimaryGPO',
                        'Incoterms1',
                        'PlaceOfDestination',
                        'AcctAssignmentGroup',
                        'TaxClassification',
                        'SortKey',
                        'CustomerPriceProc',
                        'CustomerGroup',
                        'DeliveryPriority',
                        'IncoTerms2',
                        'OrderCombination',
                        'ShippingConditions',
                        'MaxPartialDeliveries',
                        'OrderProbab',
                        'PriceGroup',
                        'CompleteDeliveryRequired',
                        'PartialDeliveryPerItem',
                        'CustomerStatGroup',
                        'DeliveringPlant',
                        'TransportationZone',
                        'CustomerNumber',
                    ],
                },
                {
                    id: 23,
                    SystemTypeId: 2,
                    Role: 'IntercompanyCustomers',
                    RoleTypeId: 23,
                    Fields: [
                        'SoldTo',
                        'SalesDistrict',
                        'SalesOffice',
                        'SalesGroup',
                        'PrimaryGPO',
                        'SearchTerm2',
                        'CustomerGroup',
                        'DeliveryPriority',
                        'IncoTerms2',
                        'OrderCombination',
                        'ShippingConditions',
                        'MaxPartialDeliveries',
                        'OrderProbab',
                        'PriceGroup',
                        'CompleteDeliveryRequired',
                        'PartialDeliveryPerItem',
                        'CustomerStatGroup',
                        'DeliveringPlant',
                        'TransportationZone',
                        'PaymentHistoryRecord',
                        'URIType',
                        'ServiceCode',
                    ],
                },
                {
                    id: 28,
                    SystemTypeId: 2,
                    Role: 'IntercompanyCustomersPayer',
                    RoleTypeId: 28,
                    Fields: [
                        'Category',
                        'Tax Number',
                        'DUNSNumber',
                        'SICCode4',
                        'SICCode6',
                        'SICCode8',
                        'NAICSCode',
                        'SoldTo',
                        'ServiceCode',
                        // 'DeliveryInformation',
                        'TaxNumber4',
                        'CustomerClass',
                        'SalesDistrict',
                        'SalesOffice',
                        'SalesGroup',
                        'Currency',
                        'PrimaryGPO',
                        'Incoterms1',
                        'PlaceOfDestination',
                        // 'AcctAssignmentGroup',
                        // 'TaxClassification',
                        'CustomerPriceProc',
                        'CustomerGroup',
                        'DeliveryPriority',
                        'IncoTerms2',
                        'OrderCombination',
                        'ShippingConditions',
                        'MaxPartialDeliveries',
                        'OrderProbab',
                        'PriceGroup',
                        'CompleteDeliveryRequired',
                        'PartialDeliveryPerItem',
                        'CustomerStatGroup',
                        'DeliveringPlant',
                        'TransportationZone',
                        'PaymentHistoryRecord',
                        'URIType',
                        'RequestedCreditLimit',
                    ],
                },
                {
                    id: 29,
                    SystemTypeId: 2,
                    Role: 'SalesRep',
                    RoleTypeId: 29,
                    Fields: [
                        'ServiceCode',
                        'CustomerClass',
                        'ReconAccount',
                        'TermsOfPayment',
                        'SalesDistrict',
                        'SalesOffice',
                        'SalesGroup',
                        'Currency',
                        'PrimaryGPO',
                        'Incoterms1',
                        'PlaceOfDestination',
                        'AcctAssignmentGroup',
                        'RequestedCreditLimit',
                        'Industry',
                        'IndustryCode1',
                        'SortKey',
                        'CustomerPriceProc',
                        'CustomerGroup',
                        'OrderProbab',
                        'PriceGroup',
                        'CustomerStatGroup',
                        'PaymentHistoryRecord',
                        'IncoTerms2',
                        // 'TransportationZone',
                        'URIType',
                        'CustomerNumber',
                        'DeliveringPlant',
                    ],
                },
            ],
            OlympusCreateCustomerFields: [
                {
                    id: 1,
                    SystemTypeId: 2,
                    Role: 'SoldTo',
                    RoleTypeId: 1,
                    Fields: ['SoldTo', 'CustomerNumber'],
                },
                {
                    id: 2,
                    SystemTypeId: 2,
                    Role: 'ShipTo',
                    RoleTypeId: 2,
                    Fields: [
                        'ServiceCode',
                        'CustomerClass',
                        'ReconAccount',
                        'TermsOfPayment',
                        'SalesDistrict',
                        'SalesOffice',
                        'SalesGroup',
                        'Currency',
                        'PrimaryGPO',
                        'Incoterms1',
                        'PlaceOfDestination',
                        'AcctAssignmentGroup',
                        'RequestedCreditLimit',
                        'URIType',
                        'PriceGroup',
                        'CustomerNumber',
                    ],
                },
                {
                    id: 3,
                    SystemTypeId: 2,
                    Role: 'Payer',
                    RoleTypeId: 3,
                    Fields: [
                        'DeliveryInformation',
                        'SalesDistrict',
                        'SalesOffice',
                        'SalesGroup',
                        'Currency',
                        'PrimaryGPO',
                        // 'URIType',
                        'PriceGroup',
                        'DeliveringPlant',
                        'CustomerNumber',
                    ],
                },
                {
                    id: 4,
                    SystemTypeId: 2,
                    Role: 'BillTo',
                    RoleTypeId: 4,
                    Fields: [
                        'ReconAccount',
                        'SalesDistrict',
                        'SalesOffice',
                        'SalesGroup',
                        'Currency',
                        'PrimaryGPO',
                        'Incoterms1',
                        'PlaceOfDestination',
                        'AcctAssignmentGroup',
                        'TaxClassification',
                        'PriceGroup',
                        'DeliveringPlant',
                        'CustomerNumber',
                    ],
                },
                {
                    id: 23,
                    SystemTypeId: 2,
                    Role: 'IntercompanyCustomers',
                    RoleTypeId: 23,
                    Fields: [
                        'ServiceCode',
                        'SalesDistrict',
                        'SalesOffice',
                        'SalesGroup',
                        'PrimaryGPO',
                        'SoldTo',
                        'ServiceCode',
                        'URIType',
                        'PriceGroup',
                        'DeliveringPlant',
                    ],
                },
                {
                    id: 28,
                    SystemTypeId: 2,
                    Role: 'IntercompanyCustomersPayer',
                    RoleTypeId: 28,
                    Fields: [
                        // 'Division',
                        'SoldTo',
                        'ServiceCode',
                        'TaxNumber4',
                        'CustomerClass',
                        'SalesDistrict',
                        'SalesOffice',
                        'SalesGroup',
                        'Currency',
                        'PrimaryGPO',
                        'Incoterms1',
                        'PlaceOfDestination',
                        // 'AcctAssignmentGroup',
                        // 'TaxClassification',
                        'RequestedCreditLimit',
                        'URIType',
                        'PriceGroup',
                        'DeliveringPlant',
                    ],
                },
                {
                    id: 29,
                    SystemTypeId: 2,
                    Role: 'SalesRep',
                    RoleTypeId: 29,
                    Fields: [
                        'SoldTo',
                        'ServiceCode',
                        'CustomerClass',
                        'ReconAccount',
                        'TermsOfPayment',
                        'SalesDistrict',
                        'SalesOffice',
                        'SalesGroup',
                        'Currency',
                        'PrimaryGPO',
                        'Incoterms1',
                        'PlaceOfDestination',
                        'AcctAssignmentGroup',
                        'RequestedCreditLimit',
                        'URIType',
                        'PriceGroup',
                        'DeliveringPlant',
                        'CustomerNumber',
                    ],
                },
            ],
            OlympusUpdateExtendFields: [
                {
                    id: 1,
                    SystemTypeId: 2,
                    Role: 'SoldTo',
                    RoleTypeId: 1,
                    Fields: [
                        'SoldTo',
                        'DeliveryInformation',
                        'TermsOfPaymentContracts',
                        'PaymentTermsContracts',
                        'IncoTerms1Contracts',
                        'Incoterms2Contracts',
                        'DistributionAgreementContracts',
                        'AccountStatementTypeIdCredit',
                        'CreditControlAreaTypeIdCredit',
                        'RiskCategoryTypeIdCredit',
                        'CustomerCreditGroupTypeIdCredit',
                        'CredInfoNumberCredit',
                        'PaymentIndexCredit',
                        'LastExtReviewCredit',
                        'RatingCredit',
                    ],
                },
                {
                    id: 2,
                    SystemTypeId: 2,
                    Role: 'ShipTo',
                    RoleTypeId: 2,
                    Fields: [
                        'ServiceCode',
                        'CustomerClass',
                        'ReconAccount',
                        'TermsOfPayment',
                        'SalesDistrict',
                        'SalesOffice',
                        'SalesGroup',
                        'Currency',
                        'PrimaryGPO',
                        'Incoterms1',
                        'PlaceOfDestination',
                        'AcctAssignmentGroup',
                        'RequestedCreditLimit',
                        'URIType',
                        'PriceGroup',
                        'CustomerNumber',
                        'Industry',
                        'IndustryCode1',
                        'SortKey',
                        'CustomerPriceProc',
                        'CustomerGroup',
                        'OrderProbab',
                        'PriceGroup',
                        'CustomerStatGroup',
                        'PaymentHistoryRecord',
                        'IncoTerms2',
                    ],
                },
                {
                    id: 3,
                    SystemTypeId: 2,
                    Role: 'Payer',
                    RoleTypeId: 3,
                    Fields: [
                        'DeliveryInformation',
                        'SalesDistrict',
                        'SalesOffice',
                        'SalesGroup',
                        'Currency',
                        'PrimaryGPO',
                        // 'URIType',
                        'PriceGroup',
                        'DeliveringPlant',
                        'CustomerNumber',
                        'CustomerPriceProc',
                        'CustomerGroup',
                        'DeliveryPriority',
                        'OrderCombination',
                        'ShippingConditions',
                        'MaxPartialDeliveries',
                        'OrderProbab',
                        'PriceGroup',
                        'CompleteDeliveryRequired',
                        'PartialDeliveryPerItem',
                        'CustomerStatGroup',
                        'DeliveringPlant',
                        'TransportationZone',
                        'TermsOfPaymentContracts',
                        'IncoTerms1TypeIdContracts',
                        'Incoterms2Contracts',
                        'AccountStatementTypeIdCredit',
                        'CreditControlAreaTypeIdCredit',
                        'RiskCategoryTypeIdCredit',
                        'CustomerCreditGroupTypeIdCredit',
                        'CredInfoNumberCredit',
                        'PaymentIndexCredit',
                        'LastExtReviewCredit',
                        'RatingCredit',
                    ],
                },
                {
                    id: 4,
                    SystemTypeId: 2,
                    Role: 'BillTo',
                    RoleTypeId: 4,
                    Fields: [
                        'ReconAccount',
                        'SalesDistrict',
                        'SalesOffice',
                        'SalesGroup',
                        'Currency',
                        'PrimaryGPO',
                        'Incoterms1',
                        'PlaceOfDestination',
                        'AcctAssignmentGroup',
                        'TaxClassification',
                        'PriceGroup',
                        'DeliveringPlant',
                        'CustomerNumber',
                        'SortKey',
                        'CustomerPriceProc',
                        'CustomerGroup',
                        'DeliveryPriority',
                        'IncoTerms2',
                        'OrderCombination',
                        'ShippingConditions',
                        'MaxPartialDeliveries',
                        'OrderProbab',
                        'PriceGroup',
                        'CompleteDeliveryRequired',
                        'PartialDeliveryPerItem',
                        'CustomerStatGroup',
                        'DeliveringPlant',
                        'TransportationZone',
                        'CustomerNumber',
                        'TermsOfPaymentContracts',
                        'AccountStatementTypeIdCredit',
                        'CreditControlAreaTypeIdCredit',
                        'RiskCategoryTypeIdCredit',
                        'CustomerCreditGroupTypeIdCredit',
                        'CredInfoNumberCredit',
                        'PaymentIndexCredit',
                        'LastExtReviewCredit',
                        'RatingCredit',
                    ],
                },
                {
                    id: 23,
                    SystemTypeId: 2,
                    Role: 'IntercompanyCustomers',
                    RoleTypeId: 23,
                    Fields: [
                        'ServiceCode',
                        'SalesDistrict',
                        'SalesOffice',
                        'SalesGroup',
                        'PrimaryGPO',
                        'SoldTo',
                        'ServiceCode',
                        'URIType',
                        'PriceGroup',
                        'DeliveringPlant',
                        'SearchTerm2',
                        'CustomerGroup',
                        'DeliveryPriority',
                        'IncoTerms2',
                        'OrderCombination',
                        'ShippingConditions',
                        'MaxPartialDeliveries',
                        'OrderProbab',
                        'PriceGroup',
                        'CompleteDeliveryRequired',
                        'PartialDeliveryPerItem',
                        'CustomerStatGroup',
                        'DeliveringPlant',
                        'TransportationZone',
                        'PaymentHistoryRecord',
                    ],
                },
                {
                    id: 28,
                    SystemTypeId: 2,
                    Role: 'IntercompanyCustomersPayer',
                    RoleTypeId: 28,
                    Fields: [
                        // 'Division',
                        'SoldTo',
                        'ServiceCode',
                        'TaxNumber4',
                        'CustomerClass',
                        'SalesDistrict',
                        'SalesOffice',
                        'SalesGroup',
                        'Currency',
                        'PrimaryGPO',
                        'Incoterms1',
                        'PlaceOfDestination',
                        // 'AcctAssignmentGroup',
                        // 'TaxClassification',
                        'RequestedCreditLimit',
                        'URIType',
                        'PriceGroup',
                        'DeliveringPlant',
                        'CustomerPriceProc',
                        'CustomerGroup',
                        'DeliveryPriority',
                        'IncoTerms2',
                        'OrderCombination',
                        'ShippingConditions',
                        'MaxPartialDeliveries',
                        'OrderProbab',
                        'PriceGroup',
                        'CompleteDeliveryRequired',
                        'PartialDeliveryPerItem',
                        'CustomerStatGroup',
                        'DeliveringPlant',
                        'TransportationZone',
                        'PaymentHistoryRecord',
                    ],
                },
                {
                    id: 29,
                    SystemTypeId: 2,
                    Role: 'SalesRep',
                    RoleTypeId: 29,
                    Fields: [
                        'SoldTo',
                        'ServiceCode',
                        'CustomerClass',
                        'ReconAccount',
                        'TermsOfPayment',
                        'SalesDistrict',
                        'SalesOffice',
                        'SalesGroup',
                        'Currency',
                        'PrimaryGPO',
                        'Incoterms1',
                        'PlaceOfDestination',
                        'AcctAssignmentGroup',
                        'RequestedCreditLimit',
                        'URIType',
                        'PriceGroup',
                        'DeliveringPlant',
                        'CustomerNumber',
                        'Industry',
                        'IndustryCode1',
                        'SortKey',
                        'CustomerPriceProc',
                        'CustomerGroup',
                        'OrderProbab',
                        'PriceGroup',
                        'CustomerStatGroup',
                        'PaymentHistoryRecord',
                        'IncoTerms2',
                    ],
                },
            ],
        })
    );
};

export const fetchJDECreateTeamRoleFieldMapping = () => {
    return new Promise((resolve, reject) =>
        resolve({
            CreateTeamFields: [
                {
                    id: 1,
                    SystemTypeId: 5,
                    Role: 'Billing & Shipping (Sold To)',
                    RoleTypeId: 1,
                    Fields: ['SoldTo'],
                },
                {
                    id: 2,
                    SystemTypeId: 5,
                    Role: 'Billing Only (Bill To)',
                    RoleTypeId: 2,
                    Fields: [],
                },
                {
                    id: 3,
                    SystemTypeId: 5,
                    Role: 'Shipping Only (Ship To)',
                    RoleTypeId: 3,
                    Fields: [],
                },
            ],
            ContractTeamFields: [
                {
                    id: 1,
                    SystemTypeId: 5,
                    Role: 'Billing & Shipping (Sold To)',
                    RoleTypeId: 1,
                    Fields: ['SoldTo'],
                },
                {
                    id: 2,
                    SystemTypeId: 5,
                    Role: 'Billing Only (Bill To)',
                    RoleTypeId: 2,
                    Fields: [],
                },
                {
                    id: 3,
                    SystemTypeId: 5,
                    Role: 'Shipping Only (Ship To)',
                    RoleTypeId: 3,
                    Fields: [],
                },
            ],
            CreditTeamFields: [
                {
                    id: 1,
                    SystemTypeId: 5,
                    Role: 'Billing & Shipping (Sold To)',
                    RoleTypeId: 1,
                    Fields: ['SoldTo'],
                },
                {
                    id: 2,
                    SystemTypeId: 5,
                    Role: 'Billing Only (Bill To)',
                    RoleTypeId: 2,
                    Fields: [],
                },
                {
                    id: 3,
                    SystemTypeId: 5,
                    Role: 'Shipping Only (Ship To)',
                    RoleTypeId: 3,
                    Fields: ['CreditLimit'],
                },
            ],
            GlobalTradeTeamFields: [
                {
                    id: 1,
                    SystemTypeId: 5,
                    Role: 'Billing & Shipping (Sold To)',
                    RoleTypeId: 1,
                    Fields: ['SoldTo'],
                },
                {
                    id: 2,
                    SystemTypeId: 5,
                    Role: 'Billing Only (Bill To)',
                    RoleTypeId: 2,
                    Fields: [],
                },
                {
                    id: 3,
                    SystemTypeId: 5,
                    Role: 'Shipping Only (Ship To)',
                    RoleTypeId: 3,
                    Fields: [],
                },
            ],
            PricingTeamFields: [
                {
                    id: 1,
                    SystemTypeId: 5,
                    Role: 'Billing & Shipping (Sold To)',
                    RoleTypeId: 1,
                    Fields: ['SoldTo'],
                },
                {
                    id: 2,
                    SystemTypeId: 5,
                    Role: 'Billing Only (Bill To)',
                    RoleTypeId: 2,
                    Fields: [],
                },
                {
                    id: 3,
                    SystemTypeId: 5,
                    Role: 'Shipping Only (Ship To)',
                    RoleTypeId: 3,
                    Fields: [],
                },
            ],
            CustomerMasterTeamFields: [
                {
                    id: 1,
                    SystemTypeId: 5,
                    Role: 'Billing & Shipping (Sold To)',
                    RoleTypeId: 1,
                    Fields: ['SoldTo'],
                },
                {
                    id: 2,
                    SystemTypeId: 5,
                    Role: 'Billing Only (Bill To)',
                    RoleTypeId: 2,
                    Fields: [],
                },
                {
                    id: 3,
                    SystemTypeId: 5,
                    Role: 'Shipping Only (Ship To)',
                    RoleTypeId: 3,
                    Fields: ['CreditLimit'],
                },
            ],
        })
    );
};
