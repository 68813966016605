/**
 * @prettier
 */

import React from 'react';
import {
    ScrollView,
    Text,
    View,
    TouchableOpacity,
    ActivityIndicator,
    StyleSheet,
    Image,
    Modal,
} from 'react-native';
import {
    DimensionAware,
    getWindowHeight,
    getWindowWidth,
} from 'react-native-dimension-aware';
import { connect } from 'react-redux';
import { FormInput, FormSelect } from '../../components/form';
import { Box, Flex } from '../../components/common';
import { Table, TableWrapper, Row, Rows, Cell } from '../../components/table';
import { Link } from '../../navigation/router';
import { Colors, StyledText } from '../../theme/Index';
import { AntDesign } from '@expo/vector-icons';
import OverflowRight from '../../components/OverflowRight';
import PartnersFilterFields from '../../components/PartnersFilterFields';
import { SystemType, RoleType, GeneralRoleType } from '../../constants/WorkflowEnums';
import { OverflowRightContext } from '../SearchResult/Apollo/MdmDetailScreen';
import { getStaticRequest, getPartners } from '../../appRedux/actions';
import { mapKeys } from 'lodash';
import idx from 'idx';

class Page extends React.Component {
    toggle = (value = null) => {
        const isBoolean = value && typeof value === 'boolean';
        this.setState((state) => ({
            isToggled: isBoolean ? value : !state.isToggled,
        }));
    };
    constructor(props) {
        super(props);
        this.toggleOverflow = () => {
            this.setState((state) => ({
                isToggled: !state.isToggled,
            }));
        };
        this.state = {
            tableHead: [
                'Customer or Workflow Number',
                'Partner Number',
                'Partner Role',
                'Partner Mdm Number',
                'Partner Customer Name',
                'Partner Customer Address',
            ],
            isToggled: false,
            requestsTotal: 200,
            current_page: 1,
            toggleOverflow: this.toggle,
            formData: {},
            loadedPartnersRequestData: false,
            partnersData: [],
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location && this.state.isToggled === true) {
            this.toggle(false);
        }
    }

    componentDidMount() {
        this.setState({ partnersData: [] });
        this.props.getStaticRequest([5, 6, 50]);
    }

    componentWillReceiveProps(newProps) {
        if (newProps.totalPartners != this.props.totalPartners) {
            this.setState({ partnersData: newProps.totalPartners });
        }
    }

    onFieldChange = (value, e) => {
        const name = e.target.name;
        const { formData: prevFormData } = this.state;

        this.setState((prevState, props) => {
            return {
                formData: {
                    ...prevState.formData,
                    [name]: value,
                },
            };
        });
    };

    onFilterSubmit = () => {
        const { formData, isToggled } = this.state;
        this.setState({ loadedPartnersRequestData: true, current_page: 1 });
        this.toggle(!isToggled);``
        var postdata = {
            UserId: localStorage.getItem('userId'),
            CustomerOrWorkflowId: formData.CustomerOrWorkflowId,
            CustomerName: formData.CustomerName || "",
            Address: formData.Address || "",
            City: formData.City || "",
            Role: formData.Role || "",
            SystemTypeId: 3,
        };
        this.setState({ previousPostData: postdata });
        this.props.getPartners(postdata);
    };

    onReset = () => {
        this.setState((prevState, props) => {
            return {
                formData: {},
                isToggled: !prevState.isToggled,
                loadedPartnersRequestData: !prevState.loadedPartnersRequestData,
                current_page: 1,
            };
        });
        var postdata = {
            UserId: localStorage.getItem('userId'),
        };

        this.props.getPartners();
    };

    renderPage = (pagenumber) => {
        //set current page number & start from pointer
        const { formData } = this.state;
        const userId = localStorage.getItem('userId');
        let from_size = 0,
            to_size = 10;
        this.setState({
            current_page: pagenumber,
        });

        if (pagenumber !== 1) {
            from_size = pagenumber * 10 - 10;
        }
    };

    moveNext = () => {
        let lastPageNumber = Math.ceil(this.props.totalPartners / 10);
        this.setState(
            {
                current_page: this.state.current_page + 1,
            },
            () => {
                if (this.state.current_page <= lastPageNumber) {
                    this.renderPage(this.state.current_page);
                }
            }
        );
    };
    movePrev = () => {
        this.setState(
            {
                current_page: this.state.current_page - 1,
            },
            () => {
                if (this.state.current_page >= 1) {
                    this.renderPage(this.state.current_page);
                }
            }
        );
    };

    isEmpty = (data) => {
        let result = false;
        if (typeof data === 'string') {
            return !data || 0 === data.length;
        }
        if (typeof data === 'object') {
            for (var key in data) {
                if (data.hasOwnProperty(key)) return false;
            }
            return true;
        }
        return result;
    };

    renderPartners(currentPage) {
        let totalPartners = this.state.partnersData;
        const { staticData } = this.props;
        const partners = totalPartners[currentPage - 1] || [];
        var data = [];
        var partnersData = partners.map((partner, index) => {
            let prData = [
                partner.BillToCustomerNumber,
                partner.ShipToCustomerNumber,
                partner.PartnerFunc,
                <Link
                    style={{
                        paddingTop: 26,
                        paddingBottom: 27,
                        paddingLeft: 20,
                    }}
                    to={{
                        pathname: '/search-results/' + partner.AssignedMdm,
                        state: {
                            ...partner,
                            partnerMdmNumber: partner.AssignedMdm,
                            Header: 'Mdm Number',
                        },
                    }}>
                    {partner.AssignedMdm}
                </Link>,
                partner.CustomerName,
                partner.Address,
            ];
            data.push(prData);
        });

        return (
            <Box
                my={2}
                style={{
                    zIndex: -1,
                }}>
                <Table
                    border="2px solid #234382"
                    borderStyle={{
                        borderWidth: 1,
                        borderRightWidth: 1,
                        borderColor: '#98D7DA',
                        borderRightStyle: 'solid',
                    }}>
                    <Row
                        data={this.state.tableHead}
                        flexArr={[1.0, 0.6, 0.5, 0.8, 1.5, 1.5]}
                        style={{
                            backgroundColor: '#E6F5FA',
                            maxHeight: 75,
                        }}
                        borderStyle={{
                            borderWidth: 0,
                            borderTopWidth: 0,
                            borderRightWidth: 1,
                            borderColor: '#98D7DA',
                            borderRightStyle: 'solid',
                        }}
                        textStyle={{
                            textAlign: 'left',
                            color: '#10206C',
                            fontWeight: '600',
                            fontFamily: 'Poppins',
                            fontSize: 16,
                            paddingTop: 24,
                            paddingBottom: 24,
                            paddingHorizontal: 15,
                        }}
                    />
                    <Rows
                        data={data}
                        flexArr={[1.0, 0.6, 0.5, 0.8, 1.5, 1.5]}
                        style={{ minHeight: 65, maxHeight: 80 }}
                        borderStyle={{
                            borderWidth: 0,
                            borderTopWidth: 0,
                            borderRightWidth: 1,
                            borderColor: '#98D7DA',
                            borderRightStyle: 'solid',
                        }}
                        textStyle={{
                            color: '#353535',
                            fontSize: 14,
                            fontWeight: '500',
                            fontFamily: 'Poppins',
                            borderColor: '#98D7DA',
                            paddingTop: 26,
                            paddingBottom: 27,
                            paddingLeft: 20,
                            paddingRight: 10,
                            textAlign: 'left',
                            backgroundColor: '#F8F8F8',
                        }}
                    />
                </Table>
            </Box>
        );
    }

    renderPageNumbers() {
        let totalpageCnt = Math.ceil(this.props.totalPartners / 10);
        const pageNumbers = [];
        for (let i = 1; i <= totalpageCnt; i++) {
            pageNumbers.push(i);
        }

        return;
    }

    render() {
        const {
            width,
            height,
            marginBottom,
            location,
            partnersFilters: filters = {},
        } = this.props;
        const { state } = location;
        const { isToggled } = this.state;

        if (this.props.fetching)
            return (
                <Box
                    display="flex"
                    flex={1}
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                    minHeight="650px">
                    <ActivityIndicator size="large" />
                </Box>
            );

        let details = '';
        if (!this.isEmpty(filters)) {
            let { CustomerOrWorkflowId } = filters;
            if (CustomerOrWorkflowId)
                details = details + '   Workflow or Customer Id: ' + CustomerOrWorkflowId;
        }

        const pageNumbers = [];

        if (this.props.totalPartners) {
            let totalpageCnt = Math.ceil(this.props.totalPartners.length);
            for (let i = 1; i <= totalpageCnt; i++) {
                pageNumbers.push(i);
            }
        }

        return (
            <View
                style={{
                    backgroundColor: '#EFF3F6',
                    paddingBottom: 75,
                    minHeight: '100vh',
                }}>
                <ScrollView
                    keyboardShouldPersistTaps="always"
                    style={{
                        flex: 1,
                        paddingHorizontal: width < 1440 ? 75 : width * 0.1,
                        paddingBottom: 5,
                    }}>
                    <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="space-between">
                        <Text
                            style={{
                                fontWeight: '500',
                                fontSize: 24,
                                color: Colors.lightBlue,
                                marginTop: 25,
                                marginBottom: 20,
                            }}>
                            POINTMAN PARTNERS
                        </Text>
                    </Box>

                    <Box>
                        <PartnersFilterFields
                            SystemTypeId="3"
                            onFieldChange={this.onFieldChange}
                            formData={this.state.formData}
                            onSubmit={this.onFilterSubmit}
                            staticDropDownData={this.props.staticData}
                            onReset={this.onReset}></PartnersFilterFields>
                    </Box>
                    {!this.isEmpty(filters) && details.length > 0 && (
                        <StyledText style={{ color: '#10206C', textAlign: 'inherit' }}>
                            Search Filters Applied: {details}
                        </StyledText>
                    )}
                    {this.renderPartners(this.state.current_page)}
                    <View
                        style={{
                            zIndex: -1,
                        }}
                        className="pagination">
                        <span onClick={() => this.movePrev()}>&laquo;</span>
                        {pageNumbers.map((number) => {
                            let classes =
                                this.state.current_page === number ? 'active' : '';
                            if (
                                number === 1 ||
                                number === this.props.totalPartners ||
                                (number >= this.state.current_page - 2 &&
                                    number <= this.state.current_page + 2)
                            ) {
                                return (
                                    <span
                                        key={number}
                                        className={classes}
                                        onClick={() => {
                                            this.renderPage(number);
                                        }}>
                                        {number}
                                    </span>
                                );
                            }
                        })}
                        <span onClick={() => this.moveNext()}>&raquo;</span>
                    </View>
                </ScrollView>
            </View>
        );
    }
}

class Default extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {}

    render() {
        const props = this.props;

        return (
            <DimensionAware
                render={(dimensions) => (
                    <Page
                        {...{
                            ...props,
                            width: getWindowWidth(dimensions),
                            height: getWindowHeight(dimensions),
                            marginBottom: 25,
                        }}
                    />
                )}
            />
        );
    }
}

const mapStateToProps = ({ myPartners, staticData }) => {
    const {
        data: partners,
        totalPartners,
        partnersFilters,
        fetching,
        alert,
    } = myPartners;
    const { data, fetching: staticFetching } = staticData;
    return {
        myPartners: partners || [],
        totalPartners: totalPartners || [],
        partnersFilters,
        fetching: fetching || staticFetching,
        alert,
        staticData: data,
    };
};

const styles = StyleSheet.create({
    inputContainer: {
        marginLeft: 0,
        borderWidth: 2,
        minWidth: 700,
        borderRadius: 20,
        flex: 1,
        borderBottomWidth: 2,
        borderBottomLeftRadius: 20,
        borderBottomRightRadius: 20,
    },
    noBorders: {
        borderBottomWidth: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
    },
    img: {
        width: 20,
        height: 20,
    },
});

export default connect(mapStateToProps, { getPartners, getStaticRequest })(Default);
