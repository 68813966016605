import { all, call, put, takeLatest } from 'redux-saga/effects';
import { getStaticSuccess } from '../../appRedux/actions';
import { GET_STATIC_DATA_REQUEST } from '../../constants/ActionTypes';
import { ajaxPostRequest, endpoints } from './config';

const STATIC_ID_MAPPINGS = {
    6: 'ApolloRoleType',
    15: 'ApolloCreditRepGroupType',
    40: 'OlympusRoleType',
    41: 'OlympusCreditControlAreaType',
    42: 'OlympusSalesOrgType',
    10: 'ApolloBlockBillingType',
    11: 'ApolloBlockDeliveryType',
    12: 'ApolloBlockOrderType',
    30: 'ApolloPaymentTermsType',
    26: 'ApolloIncoTermsType',
    17: 'ApolloCustomerGroupType',
    15: 'ApolloCreditRepGroupType',
    42: 'OlympusSalesOrgType',
    43: 'OlympusMarketingSegmentationType',
    5: 'SystemType',
    49: 'OlympusCountryType',
    50: 'PointmanRoleType',
    51: 'OlympusCategoryType',
    44: 'OlympusMarketingSegmentationType',
    46: 'WorkflowTaskType',
    49: 'PointmanMarketingSegmentationType',
    50: 'PointmanRoleType',
    52: 'OlympusCompanyCodeType',
    53: 'OlympusCurrencyType',
    54: 'OlympusCustomerClassType',
    55: 'OlympusDistributionChannelType',
    56: 'OlympusDivisionType',
    57: 'OlympusIncoTermsType',
    58: 'OlympusLanguageType',
    59: 'OlympusPriorityType',
    60: 'OlympusReconAccountType',
    61: 'OlympusSalesDistrictType',
    62: 'OlympusSalesGroupType',
    63: 'OlympusSalesOfficeType',
    64: 'OlympusTaxClassificationType',
    65: 'OlympusTermsOfPaymentType',
    66: 'OlympusIndustryType',
    67: 'OlympusAccountStatementType',
    68: 'OlympusCustPriceProcType',
    69: 'OlympusCustomerCreditGroupType',
    70: 'OlympusCustomerGroupType',
    71: 'OlympusDeliveringPlantType',
    72: 'OlympusDeliveryPriorityType',
    73: 'OlympusIndustryCodeType',
    74: 'OlympusMaxPartialDeliveriesType',
    75: 'OlympusPartialDeliveryPerItemType',
    76: 'OlympusPriceGroupType',
    77: 'OlympusRiskCategoryType',
    78: 'OlympusShippingConditionsType',
    79: 'OlympusSortKeyType',
    80: 'OlympusCountryType',
    81: 'OlympusAcctAssignmentGroupType',
    82: 'ApolloMarketingSegmentationType',
    83: 'OlympusTransporationZoneType',
    84: 'OlympusURIType',
    87: 'JDE1099ReportingType',
    88: 'JDEAdjustmentScheduleType',
    89: 'JDEBillingAddressTypeType',
    90: 'JDECarrierType',
    91: 'JDECategoryCode03Type',
    92: 'JDECategoryCode22Type',
    93: 'JDECollectionManagerType',
    94: 'JDECompanyType',
    95: 'JDECurrencyType',
    96: 'JDEDocumentsLanguageType',
    97: 'JDEGLOffsetType',
    98: 'JDEGPOType',
    99: 'JDEIncoTermsType',
    100: 'JDEIndustryClassType',
    101: 'JDEItemRestrictionsType',
    102: 'JDELanguageType',
    103: 'JDEPaymentInstructionType',
    104: 'JDEPaymentTermsType',
    105: 'JDEPolicyNameType',
    106: 'JDEPriceGroupType',
    107: 'JDERegionType',
    108: 'JDERelatedAddressNumType',
    109: 'JDESalesAreaType',
    110: 'JDESalesOrgType',
    111: 'JDESalesRepsType',
    112: 'JDESearchTypeType',
    113: 'JDESendMethodType',
    114: 'JDETaxExplCodeType',
    115: 'JDETaxRateAreaType',
    116: 'JDERoleType',
    117: 'JDETimeZoneType',
    118: 'JDEGeographicalType',
    121: 'JDECategoryCode28Type',
    122: 'JDECategoryCode29Type',
    123: 'JDEMarketSegmentationType',
    29: 'ApolloPartnersRoleType',
    124: 'OlympusPartnersRoleType',
    125: 'JDEPartnerFunctionType',
    116: 'JDERoleType',
};

export function* getStaticData({ payload }) {
    const url = endpoints.getStaticData;

    try {
        const result = yield call(ajaxPostRequest, url, {
            StaticDataTypes: payload,
        });
        if (result.IsSuccess) {
            const staticItems = result.ResultData;
            let data = {};
            staticItems.forEach((item) => {
                const key = STATIC_ID_MAPPINGS[item.StaticDataType] || null;
                if (key) {
                    data[key] = item.StaticDataItems;
                }
            });
            yield put(getStaticSuccess(data));
        }
    } catch (error) {
        console.log('team info not found error', error);
    }
}

const authSagas = function* rootSaga() {
    yield all([takeLatest(GET_STATIC_DATA_REQUEST, getStaticData)]);
};
export default authSagas;
