import React, { useState } from 'react';
import { Colors } from '../theme/Index';
import { FontAwesome } from '@expo/vector-icons';
import styled from 'styled-components/native';

import {
    ScrollView,
    Text,
    View,
    TouchableOpacity,
    ActivityIndicator,
    Image,
} from 'react-native';
import { Text as CustomText } from '../components/common';
import {
    DimensionAware,
    getWindowHeight,
    getWindowWidth,
} from 'react-native-dimension-aware';
import { Button, Flex, Box } from '../components/common';
import { Link } from '../navigation/router';
import {
    searchCustomer,
    advanceSearchCustomer,
    selectRecord,
    exportcsvdata,
} from '../appRedux/actions/Customer';
import { selectWorkflow } from '../appRedux/actions/Workflow';
import { getMockSearchResult, formatRequestorName } from '../appRedux/sagas/config';
import { WorkflowStateType } from '../constants/WorkflowEnums';
import { Tabs } from '../components/tabs';
import { connect } from 'react-redux';
import FlashMessage from '../components/FlashMessage';
import { CSVLink } from 'react-csv';

const StyledButton = styled.TouchableOpacity`
    padding: 10.2px 10px 8.5px 12px;
    background-color: #10206c;
    border-radius: 2.5px;
    margin-right: 15px;
    justify-content: center;
    align-items: center;
    flex-direction: row;
`;

const StyledText = styled.Text`
    font-size: 14px;
    color: #ffffff;
    text-align: center;
    padding-top: 1px;
    font-family: Poppins;
    font-weight: 400;
`;

const StyledImage = styled.Image`
    background-color: transparent;
    height: 20px;
    width: 20px;
    margin-right: 3px;
`;
const HeadCell = ({ children, rowSpan, style }) => (
    <th
        rowSpan={rowSpan}
        style={{
            fontSize: 16,
            borderSpacing: 0,
            borderRightWidth: 1,
            borderColor: '#98D7DA',
            borderRightStyle: 'solid',
            paddingTop: 24,
            paddingBottom: 24,
            paddingHorizontal: 15,
            paddingLeft: 14,
            paddingRight: 16,
            textAlign: 'left',
            wordWrap: 'break-word',
            ...style,
        }}>
        {children}
    </th>
);

const Cell = ({ children, style, odd }) => (
    <td
        style={{
            borderRightWidth: 1,
            borderColor: '#98D7DA',
            borderRightStyle: 'solid',
            borderSpacing: 0,
            paddingTop: 26,
            paddingBottom: 27,
            textAlign: 'left',
            backgroundColor: odd ? '#F8F8F8' : '#FFF',
            ...style,
        }}>
        {children}
    </td>
);

const Row = ({ children, style, dataArr, odd, borderLess }) => (
    <tr>
        {dataArr.map((value, index) => {
            if (index === 0)
                return (
                    <Cell
                        key={index}
                        odd={odd}
                        borderLess={borderLess}
                        style={{
                            paddingLeft: index === 0 ? 20 : 16,
                            paddingRight: 12,
                            borderRightWidth: index === dataArr.length - 1 ? 0 : 1,
                        }}>
                        <Link
                            to={{
                                pathname: `/customers/${value}`,
                                data: {},
                            }}>
                            {value}
                        </Link>
                    </Cell>
                );
            else
                return (
                    <Cell
                        key={index}
                        odd={odd}
                        style={{
                            paddingLeft: index === 0 ? 20 : 16,
                            paddingRight: 12,
                            borderRightWidth: index === dataArr.length - 1 ? 0 : 1,
                        }}>
                        {value}
                    </Cell>
                );
        })}
    </tr>
);

const CustomerRow = ({ children, customer, odd, props }) => (
    <tr>
        <Cell
            odd={odd}
            style={{
                paddingLeft: 20,
                paddingRight: 12,
                borderRightWidth: 1,
            }}>
            <Link
                to={{
                    pathname: `/search-results/${customer.MdmNumber}`,
                    state: customer,
                }}
                onClick={() => props.selectRecord(customer)}>
                {customer.MdmNumber}
            </Link>
            {/* <Link
                to={{
                    pathname: `/search-results/${customer.MdmNumber}`,
                    state: customer,
                }}
                onClick={() => props.selectRecord(customer)}>
                {customer.MdmNumber}
            </Link> */}
        </Cell>
        <Cell
            odd={odd}
            style={{
                paddingLeft: 16,
                paddingRight: 12,
            }}>
            {customer.Name1 || customer.Name}
        </Cell>
        <Cell
            odd={odd}
            style={{
                paddingLeft: 16,
                paddingRight: 12,
            }}>
            {customer.Street}
        </Cell>
        <Cell
            odd={odd}
            style={{
                paddingLeft: 16,
                paddingRight: 12,
            }}>
            {customer.City}
        </Cell>
        <Cell
            odd={odd}
            style={{
                paddingLeft: 16,
                paddingRight: 12,
            }}>
            {customer.State}
        </Cell>
        <Cell
            odd={odd}
            style={{
                paddingLeft: 16,
                paddingRight: 12,
            }}>
            {customer.Zip}
        </Cell>
        <Cell
            odd={odd}
            style={{
                paddingLeft: 16,
                paddingRight: 12,
            }}>
            {customer.Country}
        </Cell>
        <Cell
            odd={odd}
            style={{
                paddingLeft: 16,
                paddingRight: 12,
                borderRightWidth: 0,
            }}>
            {customer.DUNSNumber}
        </Cell>
    </tr>
);

const workFlowStatus = ['Draft', 'In Progress', 'Rejected', 'Approved'];
const workFlowTypes = ['Create', 'Extend', 'Update', 'Block'];

const WorkFlowRow = ({ children, workflow: customer, odd, selectWorkflow }) => {
    const d = new Date(customer.CreatedDate);
    var navigateTo;
    if (customer && customer.WorkflowType.toLowerCase().includes('create')) {
        navigateTo = '/my-requests/' + customer.WorkflowId;
    } else if (customer && customer.WorkflowType.toLowerCase().includes('update')) {
        if (customer && customer.IsGlobalUpdate) {
            navigateTo = '/my-requests/' + customer.WorkflowId;
        } else {
            if (customer.SystemType == 'OLYMPUS') {
                navigateTo =
                    '/my-requests/' +
                    customer.SystemType.toLowerCase() +
                    '/' +
                    customer.WorkflowId +
                    '/update-request';
            } else {
                navigateTo = '/my-requests/' + customer.WorkflowId + '/update-request';
            }
        }
    } else if (customer && customer.WorkflowType.toLowerCase().includes('extend')) {
        navigateTo = '/my-requests/' + customer.WorkflowId + '/update-request';
    } else if (customer && customer.WorkflowType.toLowerCase().includes('block')) {
        navigateTo = '/my-requests/' + customer.WorkflowId + '/update-request';
    }

    let createdAt = '';

    if (d.getTime() > 0) {
        const dtf = new Intl.DateTimeFormat('en', {
            year: 'numeric',
            month: 'short',
            day: '2-digit',
        });
        const [{ value: mo }, , { value: da }, , { value: ye }] = dtf.formatToParts(d);

        createdAt = `${mo} ${da}, ${ye}`;
    }

    let _Type = customer.WorkflowType;

    if (_Type === 'CreateOther') _Type = 'Other';

    return (
        <tr>
            <Cell
                odd={odd}
                style={{
                    paddingLeft: 20,
                    paddingRight: 12,
                    borderRightWidth: 1,
                }}>
                <Link
                    to={{
                        pathname: navigateTo,
                        state: customer,
                    }}
                    onClick={() => selectWorkflow(customer)}>
                    {customer.WorkflowId}
                </Link>
            </Cell>
            <Cell
                odd={odd}
                style={{
                    paddingLeft: 16,
                    paddingRight: 12,
                }}>
                {formatRequestorName(customer.CreatedUser)}
            </Cell>
            <Cell
                odd={odd}
                style={{
                    paddingLeft: 16,
                    paddingRight: 12,
                }}>
                {_Type}
            </Cell>
            <Cell
                odd={odd}
                style={{
                    paddingLeft: 16,
                    paddingRight: 12,
                }}>
                {customer.WorkflowTitle || customer.Title}
            </Cell>
            <Cell
                odd={odd}
                style={{
                    paddingLeft: 16,
                    paddingRight: 12,
                }}>
                {customer.Name1 || 'N/A'}
            </Cell>
            <Cell
                odd={odd}
                style={{
                    paddingLeft: 16,
                    paddingRight: 12,
                }}>
                {createdAt}
            </Cell>
            <Cell
                odd={odd}
                style={{
                    paddingLeft: 16,
                    paddingRight: 12,
                    borderRightWidth: 0,
                }}>
                {customer.WorkflowStatusType}
            </Cell>
        </tr>
    );
};

class ResultsPage extends React.Component {
    constructor(props) {
        super(props);
        this.csvLink = React.createRef();
        this.state = {
            customers: this.props.searchResult.Customers,
            workflows: this.props.searchResult.Workflows,
            workflowTotal: this.props.searchResult.WorkflowSearchHits,
            customerTotal: this.props.searchResult.CustomerMasterSearchHits,
            current_mdm_page: 1,
            current_wf_page: 1,
            searchType: this.props.searchResult.CustomerSearchType,
            WorkflowId: this.props.searchResult.WorkflowId,
            MdmNumber: this.props.searchResult.MdmNumber,
            SystemRecordId: this.props.searchResult.SystemRecordId,
            Name: this.props.searchResult.Name,
            Street: this.props.searchResult.Street,
            City: this.props.searchResult.City,
            State: this.props.searchResult.State,
            Zip: this.props.searchResult.Zip,
            Country: this.props.searchResult.Country,
            DUNSNumber: this.props.searchResult.DUNSNumber,
            TaxIDOrVATRegNumber: this.props.searchResult.TaxIDOrVATRegNumber,
            selectedTab: '',
            totalCustomers: [],
            totalWorkflows: [],
            isDownloadable: false,
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        const {
            Customers: customers = [],
            Workflows: workflows = [],
        } = this.props.searchResult;
        if (customers.length > 0 || this.state.searchType === 2) {
            this.setSelectedTab('MDM');
        } else if (workflows.length > 0 || this.state.searchType === 1) {
            this.setSelectedTab('Workflow');
        } else {
            this.setSelectedTab('MDM');
        }
    }
    componentWillReceiveProps(newProps) {
        if (newProps.customerdata != this.props.customerdata) {
            this.setState({ customers: newProps.customerdata });
        }
        if (newProps.searchResult != this.props.searchResult) {
            this.setState({
                searchResult: newProps.searchResult,
                workflows: newProps.searchResult.Workflows,
            });
        }
        if (newProps.totalsearchResult != this.props.totalsearchResult) {
            this.setState({
                totalCustomers: newProps.totalsearchResult.Customers,
                totalWorkflows: newProps.totalsearchResult.Workflows,
                isDownloadable: true,
            });
        }
    }

    setSelectedTab = (tab) => {
        this.setState({ selectedTab: tab });
    };

    makeHttpRequestWithPage = (pagenumber) => {
        console.log(pagenumber);
        let current_page =
            this.state.selectedTab === 'MDM'
                ? this.state.current_mdm_page
                : this.state.current_wf_page;
        let selectedCurrenPageKey =
            this.state.selectedTab === 'MDM' ? 'current_mdm_page' : 'current_wf_page';

        //set current page number & start from pointer

        let from_size_mdm = 0,
            from_size_wf = 0,
            to_size = 10;
        this.setState({
            [selectedCurrenPageKey]: pagenumber,
        });

        if (pagenumber != 1) {
            if (this.state.selectedTab === 'MDM') {
                from_size_mdm = pagenumber * 10 - 10;
            } else {
                from_size_wf = pagenumber * 10 - 10;
            }
        }

        if (this.state.selectedTab === 'MDM') {
            from_size_mdm = pagenumber * 10 - 10;
            this.setState({ from_size_mdm: from_size_mdm }, () => this.searchRequest());
        } else {
            from_size_wf = pagenumber * 10 - 10;
            this.setState({ from_size_wf: from_size_wf }, () => this.searchRequest());
        }
    };

    searchRequest = (isExport = false) => {
        const userId = localStorage.getItem('userId');
        var postObj = {
            customerSearchType: this.state.searchType,
            CustomerMasterSearchHits: {
                from: this.state.from_size_mdm,
                size: isExport ? 5000 : 10,
            },
            WorkflowSearchHits: {
                from: this.state.from_size_wf,
                size: isExport ? 5000 : 10,
            },
        };

        var advSearch = {
            userId: userId,
            workflowid: this.state.WorkflowId,
            mdmNumber: this.state.MdmNumber,
            SystemRecordId: this.state.SystemRecordId,
            name: this.state.Name,
            street: this.state.Street,
            city: this.state.City,
            state: this.state.State,
            zip: this.state.Zip,
            country: this.state.Country,
            dunsNumber: this.state.DUNSNumber,
            TaxIDOrVATRegNumber: this.state.TaxIDOrVATRegNumber,
        };
        if (this.state.searchType === 5) {
            var postdata = {
                ...postObj,
                TypeaheadKeyword: this.state.Name,
            };
        } else {
            var postdata = {
                ...postObj,
                ...advSearch,
            };
        }
        // if (this.state.CustomerSearchType === 1) {
        //     this.props.searchCustomer(postdata);
        // } else {
        if (!isExport)
            this.props.advanceSearchCustomer(postdata, this.props.history, false);
        else {
            this.props.exportcsvdata(postdata, this.props.history);
        }
        // }
    };

    fetchExportData = () => {
        this.searchRequest(true);
    };

    moveNext = () => {
        let total =
            this.state.selectedTab === 'MDM'
                ? this.state.customerTotal.Total
                : this.state.workflowTotal.Total;
        let selectedCurrenPageKey =
            this.state.selectedTab === 'MDM' ? 'current_mdm_page' : 'current_wf_page';
        let lastPageNumber = Math.ceil(total / 10);
        this.setState(
            {
                [selectedCurrenPageKey]: this.state[selectedCurrenPageKey] + 1,
            },
            () => {
                if (this.state[selectedCurrenPageKey] <= lastPageNumber) {
                    this.makeHttpRequestWithPage(this.state[selectedCurrenPageKey]);
                }
            }
        );
    };
    movePrev = () => {
        let selectedCurrenPageKey =
            this.state.selectedTab === 'MDM' ? 'current_mdm_page' : 'current_wf_page';

        this.setState(
            {
                [selectedCurrenPageKey]: this.state[selectedCurrenPageKey] - 1,
            },
            () => {
                if (this.state[selectedCurrenPageKey] >= 1) {
                    this.makeHttpRequestWithPage(this.state[selectedCurrenPageKey]);
                }
            }
        );
    };

    getPages = () => {
        let current_page =
            this.state.selectedTab === 'MDM'
                ? this.state.current_mdm_page
                : this.state.current_wf_page;
        let total =
            this.state.selectedTab === 'MDM'
                ? this.state.customerTotal.Total
                : this.state.workflowTotal.Total;

        let totalpageCnt = Math.ceil(total / 10);
        const pageNumbers = [];
        for (let i = 1; i <= totalpageCnt; i++) {
            pageNumbers.push(i);
        }
        let renderPageNumbers = pageNumbers.map((number) => {
            let classes = current_page === number ? 'active' : '';

            if (
                number == 1 ||
                number == total ||
                (number >= current_page - 2 && number <= current_page + 2)
            ) {
                return (
                    <Box
                        color="black"
                        p="8px 16px"
                        textDecoration="none"
                        border="1px solid #ddd"
                        style={{
                            cursor: 'pointer',
                            padding: '8px 16px',
                            textDecoration: 'none',
                            transition: 'background-color 0.3',
                        }}
                        className={classes}
                        onClick={() => this.makeHttpRequestWithPage(number)}>
                        {number}
                    </Box>
                );
            }
        });
        return renderPageNumbers;
    };

    render() {
        const {
            width,
            height,
            marginBottom,
            location,
            workflowSearchResults = 0,
        } = this.props;
        const {
            Customers: customers = [],
            Workflows: workflows = [],
        } = this.props.searchResult;
        console.log(this.state.current_mdm_page);
        let teams = JSON.parse(localStorage.getItem('teamIds'));
        let isSearchTeamUser =
            (teams && teams.includes(20) && teams.length == 1) ||
            (teams && teams.includes(99));
        let cmUserNotAssignedToTeam = teams && teams.length == 0;
        // Hide WF Searchresults for Search users in CM system / Ad Groups.
        let showCreate = !isSearchTeamUser || cmUserNotAssignedToTeam;
        let showWfResults = !isSearchTeamUser && !cmUserNotAssignedToTeam;
        const custCsvHeader = [
            { label: 'Mdm Number', key: 'MdmNumber' },
            { label: 'Name', key: 'Name' },
            { label: 'Street', key: 'Street' },
            { label: 'City', key: 'City' },
            { label: 'State', key: 'State' },
            { label: 'Zip', key: 'Zip' },
            { label: 'Country', key: 'Country' },
            { label: 'DUNS Number', key: 'DUNSNumber' },
        ];
        const wfCsvHeader = [
            { label: 'Workflow Number', key: 'WorkflowId' },
            { label: 'Requestor', key: 'CreatedUser' },
            { label: 'Type', key: 'WorkflowType' },
            { label: 'Title', key: 'WorkflowTitle' },
            { label: 'Customer Name', key: 'Name1' },
            { label: 'Created Date', key: 'CreatedDate' },
            { label: 'Status', key: 'WorkflowStatusType' },
        ];
        const data = workflows;
        console.log(workflows, '-------', this.props);
        let renderPageNumbers, renderMDMPageNumbers;
        const { mdmSearchResults } = this.state;
        if (this.state.workflowTotal) {
            renderPageNumbers = this.getPages();
        }
        if (!data)
            return (
                <View
                    style={{
                        flex: 1,
                        flexBasis: 'auto',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        minHeight: 650,
                    }}>
                    <ActivityIndicator />
                </View>
            );

        let selectedIndex;

        if (customers.length > 0 || this.state.searchType === 2) {
            selectedIndex = 0;
        } else if (data.length > 0 || this.state.searchType === 1) {
            selectedIndex = 1;
        } else {
            selectedIndex = 0;
        }

        return (
            <ScrollView
                keyboardShouldPersistTaps="always"
                style={{
                    backgroundColor: '#EFF3F6',
                    paddingTop: 40,
                    paddingBottom: 75,
                }}>
                <Box m="10px 0 0 5%">
                    <Text
                        style={{
                            fontWeight: '500',
                            fontSize: 24,
                            color: Colors.lightBlue,
                            marginBottom: 20,
                            paddingLeft: 45,
                        }}>
                        Search Results
                    </Text>
                </Box>

                {data.length <= 0 && customers.length <= 0 && (
                    <FlashMessage
                        animate
                        bg={{ backgroundColor: '#EA0029' }}
                        message={'No Results'}
                    />
                )}

                <View
                    style={{
                        flex: 1,
                        marginTop: 75,
                        paddingHorizontal:
                            width > 1400 ? width * 0.1 : width <= 1024 ? 50 : 75,
                        paddingBottom: 5,
                    }}>
                    <Tabs
                        setSelectedTab={this.setSelectedTab}
                        selectedIndex={selectedIndex}>
                        <View
                            id="MDM"
                            label={
                                <View>
                                    <Text style={{ textAlign: 'center' }}>MDM</Text>
                                    <Text style={{ fontSize: 14, textAlign: 'center' }}>
                                        {(this.state.customerTotal &&
                                            this.state.customerTotal.Total) ||
                                            '0'}
                                        {` `} results
                                    </Text>
                                </View>
                            }
                            style={{ marginRight: 5 }}>
                            <table
                                style={{
                                    zIndex: 0,
                                    borderWidth: 1.75,
                                    borderColor: '#234382',
                                    borderStyle: 'solid',
                                    borderSpacing: 0,
                                }}>
                                <thead
                                    style={{
                                        borderSpacing: 0,
                                    }}>
                                    <tr
                                        style={{
                                            borderBottomWidth: 1,
                                            borderBottomColor: '#ddd',
                                            borderSpacing: 0,
                                            color: '#10206C',
                                            backgroundColor: '#E6F5FA',
                                        }}>
                                        <HeadCell
                                            style={{
                                                paddingLeft: 20,
                                                width: 120,
                                            }}>
                                            MDM Number
                                        </HeadCell>
                                        <HeadCell>Name</HeadCell>
                                        <HeadCell>Street</HeadCell>
                                        <HeadCell>City</HeadCell>
                                        <HeadCell>State</HeadCell>
                                        <HeadCell>Zip</HeadCell>
                                        <HeadCell>Country</HeadCell>
                                        <HeadCell style={{ borderRightWidth: 0 }}>
                                            DUNS Number
                                        </HeadCell>
                                    </tr>
                                </thead>
                                <tbody>
                                    {customers.map((customer, index) => {
                                        return (
                                            <CustomerRow
                                                key={customer.MdmCustomerNumber}
                                                customer={customer}
                                                odd={index % 2}
                                                props={this.props}
                                            />
                                        );
                                    })}
                                </tbody>
                            </table>
                            {/* {console.log(this.state)} */}
                            <Box
                                mt={20}
                                alignItems={'center'}
                                flexDirection={'row'}
                                justifyContent="space-between">
                                <Box>
                                    <CustomText color="black" fontSize="15px">
                                        {`Showing ${
                                            10 * (this.state.current_mdm_page - 1) + 1
                                        } to ${
                                            customers.length * this.state.current_mdm_page
                                        } of ${
                                            this.state.customerTotal &&
                                            this.state.customerTotal.Total
                                        }`}
                                    </CustomText>
                                </Box>

                                <Box flexDirection="row">
                                    <Box
                                        color="black"
                                        p="8px 16px"
                                        textDecoration="none"
                                        border="1px solid #ddd"
                                        style={{
                                            cursor: 'pointer',
                                            padding: '8px 16px',
                                            textDecoration: 'none',
                                            transition: 'background-color 0.3',
                                        }}
                                        onClick={() => this.movePrev()}>
                                        <Text>&laquo;</Text>
                                    </Box>
                                    {renderPageNumbers}
                                    <Box
                                        color="black"
                                        p="8px 16px"
                                        textDecoration="none"
                                        border="1px solid #ddd"
                                        style={{
                                            cursor: 'pointer',
                                            padding: '8px 16px',
                                            textDecoration: 'none',
                                            transition: 'background-color 0.3',
                                        }}
                                        onClick={() => this.moveNext()}>
                                        <Text>&raquo;</Text>
                                    </Box>
                                </Box>
                            </Box>
                        </View>
                        <View
                            id="Workflow"
                            label={
                                <View>
                                    <Text style={{ textAlign: 'center' }}>Workflow</Text>
                                    <Text style={{ fontSize: 14, textAlign: 'center' }}>
                                        {!showWfResults
                                            ? '0'
                                            : (this.state.workflowTotal &&
                                                  this.state.workflowTotal.Total) ||
                                              '0'}
                                        {` `} results
                                    </Text>
                                </View>
                            }>
                            <table
                                style={{
                                    zIndex: 0,
                                    borderWidth: 1.75,
                                    borderColor: '#234382',
                                    borderStyle: 'solid',
                                    borderSpacing: 0,
                                }}>
                                <thead
                                    style={{
                                        borderSpacing: 0,
                                    }}>
                                    <tr
                                        style={{
                                            borderBottomWidth: 1,
                                            borderBottomColor: '#ddd',
                                            borderCollapse: 'collapse',
                                            borderSpacing: 0,
                                            color: '#10206C',
                                            backgroundColor: '#E6F5FA',
                                        }}>
                                        <HeadCell
                                            style={{
                                                paddingLeft: 20,
                                                width: 120,
                                            }}>
                                            Workflow Number
                                        </HeadCell>
                                        <HeadCell>Requestor</HeadCell>
                                        <HeadCell>Type</HeadCell>
                                        <HeadCell>Title</HeadCell>
                                        <HeadCell>Customer Name</HeadCell>
                                        <HeadCell>Date of Creation</HeadCell>
                                        <HeadCell
                                            style={{
                                                borderRightWidth: 0,
                                            }}>
                                            Status
                                        </HeadCell>
                                    </tr>
                                </thead>
                                <tbody>
                                    {showWfResults &&
                                        data.map((workflow, index) => (
                                            <WorkFlowRow
                                                key={workflow.WorkflowId}
                                                workflow={workflow}
                                                odd={index % 2 === 1}
                                                selectWorkflow={this.props.selectWorkflow}
                                            />
                                        ))}
                                </tbody>
                            </table>

                            <Box
                                mt={20}
                                alignItems={'center'}
                                flexDirection={'row'}
                                justifyContent="space-between">
                                <Box>
                                    <CustomText color="black" fontSize="15px">
                                        {`Showing ${
                                            10 * (this.state.current_wf_page - 1) + 1
                                        } to ${
                                            workflows.length * this.state.current_wf_page
                                        } of ${
                                            this.state.workflowTotal &&
                                            this.state.workflowTotal.Total
                                        }`}
                                    </CustomText>
                                </Box>

                                <Box flexDirection="row">
                                    <Box
                                        color="black"
                                        p="8px 16px"
                                        textDecoration="none"
                                        border="1px solid #ddd"
                                        style={{
                                            cursor: 'pointer',
                                            padding: '8px 16px',
                                            textDecoration: 'none',
                                            transition: 'background-color 0.3',
                                        }}
                                        onClick={() => this.movePrev()}>
                                        <Text>&laquo;</Text>
                                    </Box>
                                    {renderPageNumbers}
                                    <Box
                                        color="black"
                                        p="8px 16px"
                                        textDecoration="none"
                                        border="1px solid #ddd"
                                        style={{
                                            cursor: 'pointer',
                                            padding: '8px 16px',
                                            textDecoration: 'none',
                                            transition: 'background-color 0.3',
                                        }}
                                        onClick={() => this.moveNext()}>
                                        <Text>&raquo;</Text>
                                    </Box>
                                </Box>
                            </Box>
                        </View>
                    </Tabs>

                    <Flex
                        justifyEnd
                        alignCenter
                        style={{
                            paddingTop: 70,
                            flexDirection: 'row',
                            alignItems: 'center',
                            paddingLeft: 10,
                            paddingRight: 15,
                            marginTop: 20,
                            marginBottom: 10,
                            marginHorizontal: 25,
                        }}>
                        {this.props.csvdatafetching ? (
                            <ActivityIndicator style={{ marginRight: '5%' }} />
                        ) : (
                            (customers.length != 0 || workflows.length != 0) &&
                            !this.state.isDownloadable && (
                                <StyledButton
                                    hitSlop={{ top: 30, bottom: 30, left: 30, right: 30 }}
                                    onPress={this.fetchExportData}>
                                    <StyledImage
                                        source={require('../../assets/icons/excel_icon@2x.png')}
                                        alt="Image"
                                    />
                                    <StyledText>Export</StyledText>
                                </StyledButton>
                            )
                        )}
                        {this.state.isDownloadable ? (
                            <CSVLink
                                ref={this.csvLink}
                                style={{
                                    fontSize: '14px',
                                    marginTop: '2%',
                                    marginRight: '3%',
                                    borderBottom: '1px solid',
                                }}
                                data={
                                    this.state.selectedTab === 'MDM'
                                        ? this.state.totalCustomers
                                        : this.state.totalWorkflows
                                }
                                headers={
                                    this.state.selectedTab === 'MDM'
                                        ? custCsvHeader
                                        : wfCsvHeader
                                }
                                filename={
                                    this.state.selectedTab === 'MDM'
                                        ? 'customer_details.csv'
                                        : 'workflow_details.csv'
                                }>
                                Please click the link to download
                            </CSVLink>
                        ) : (
                            <></>
                        )}
                        <Button
                            disabled={!showCreate}
                            onPress={() =>
                                this.props.history.push('/search-results/create-customer')
                            }
                            title="Create New"
                        />
                    </Flex>
                    <Box fullHeight />
                </View>
            </ScrollView>
        );
    }
}

class Default extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {}

    render() {
        const props = this.props;

        return (
            <DimensionAware
                render={(dimensions) => (
                    <ResultsPage
                        {...{
                            ...props,
                            width: getWindowWidth(dimensions),
                            height: getWindowHeight(dimensions),
                            marginBottom: 25,
                        }}
                    />
                )}
            />
        );
    }
}

const mapStateToProps = ({ customer }) => {
    const {
        totalsearchResult,
        totalcustomerdata,
        searchResult,
        customerdata,
        fetching,
        csvdatafetching,
    } = customer;
    return {
        totalsearchResult,
        totalcustomerdata,
        searchResult,
        customerdata,
        fetching,
        csvdatafetching,
    };
};

export default connect(mapStateToProps, {
    searchCustomer,
    selectRecord,
    selectWorkflow,
    advanceSearchCustomer,
    exportcsvdata,
})(Default);
