/**
 * @prettier
 */

import React from 'react';
import {
    ScrollView,
    Text,
    View,
    TouchableOpacity,
    ActivityIndicator,
    StyleSheet,
    Image,
} from 'react-native';
import {
    DimensionAware,
    getWindowHeight,
    getWindowWidth,
} from 'react-native-dimension-aware';
import { connect } from 'react-redux';
import { FormInput, FormSelect } from '../../../components/form';
import { Box, Flex, Button } from '../../../components/common';
import { Table, TableWrapper, Row, Rows, Cell } from '../../../components/table';
import { Colors, StyledText } from '../../../theme/Index';
import { AntDesign } from '@expo/vector-icons';
import OverflowRight from '../../../components/OverflowRight';
import PartnersFilterFields from '../../../components/PartnersFilterFields';
import MyTaskPartnerGrid from '../../../components/MyTaskOlympusPartnerGrid';
import {
    SystemType,
    RoleType,
    GeneralRoleType,
    MyTaskApolloPartnersColumns,
    SalesOrgType,
    DistributionChannelType,
    DivisionType,
    CompanyCodeType,
} from '../../../constants/WorkflowEnums';
import { OverflowRightContext } from '../../SearchResult/Apollo/MdmDetailScreen';
import queryString from 'query-string';
import {
    getStaticRequest,
    getWFApolloPartners,
    approveRejectPartnersApollo,
    clearPartnerDetails,
    withDrawRequest,
} from '../../../appRedux/actions';
import { mapKeys } from 'lodash';
import { fetchCreateCustomerDropDownData } from '../../../redux/DropDownDatas';
import idx from 'idx';
import Modal from 'modal-enhanced-react-native-web';
import {
    apolloPartnerRules,
    approveApolloPartners,
    rejectPartnerRules,
} from '../../../constants/FieldRules';
import { parseAndSanitise, yupAllFieldsValidation } from '../../../constants/utils';
import { GenerateWorkflowId } from '@env';
import { ajaxGetRequest } from '../../../appRedux/sagas/config';
import FilesList from '../../../components/FilesList';
import { MaterialIcons } from '@expo/vector-icons';
import FlashMessage, { FlashMessages } from '../../../components/FlashMessage';
import { FAILED_BGCOLOR, SUCCESS_BGCOLOR } from '../../../constants/ActionTypes';
import { showMessage, clearMessage } from '../../../appRedux/actions/Toast';
import TaskNotes from '../../../components/TaskNotes';
import {
    getStatusBarData,
    getFunctionalGroupData,
} from '../../../appRedux/actions/Workflow';
import MultiColorProgressBar from '../../../components/MultiColorProgressBar';
class Page extends React.Component {
    toggle = (value = null) => {
        const isBoolean = value && typeof value === 'boolean';
        this.setState((state) => ({
            isToggled: isBoolean ? value : !state.isToggled,
        }));
    };
    constructor(props) {
        super(props);
        this.toggleOverflow = () => {
            this.setState((state) => ({
                isToggled: !state.isToggled,
            }));
        };
        this.state = {
            tableHead: [
                'Customer or Workflow Number',
                'Partner Number',
                'Partner Role',
                'Partner Mdm Number',
                'Partner Customer Name',
                'Partner Customer Address',
            ],
            isToggled: false,
            requestsTotal: 200,
            current_page: 1,
            toggleOverflow: this.toggle,
            formData: {
                OperationName: 'MyTaskApolloPartnersWorkflow',
                UserId: localStorage.getItem('userId'),
            },
            loadedPartnersRequestData: false,
            partnersData: [],
            wfApolloPartners: this.props.myPartners.PartnerEdits,
            modalVisible: false,
            isApproveReject: null,
            selectedFilesIds: [],
            files: [],
            fileinputkey: Date.now(),
            originalTableData: [],
            originalEditDict: [],
            newAddedData: [],
            isSoldToPresent: false,
            displayMessage: false,
            errorMessage: '',
            allowApprove: true,
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location && this.state.isToggled === true) {
            this.toggle(false);
        }
    }

    componentDidMount() {
        this.setState({ partnersData: [] });
        this.props.getStaticRequest([5, 6, 50, 29, 42, 55, 56, 124]);

        fetchCreateCustomerDropDownData().then((res) => {
            const data = res;
            this.setState({ dropDownDatas: data }, this.generateWorkflowId);
        });
        let { state: wf } = this.props.location;
        var postdata = {
            WorkflowId: wf.WorkflowId,
            UserId: localStorage.getItem('userId'),
            SystemTypeId: 2,
        };
        this.props.getWFApolloPartners(postdata);
        this.getWorkflowStatus();
    }
    getWorkflowStatus = () => {
        let { state: wf } = this.props.location;
        let statusBarJson = {
            workflowId: wf.WorkflowId,
            fuctionalGroup: 'customermaster',
            taskId: wf.TaskId,
            systemTypeId: 1,
        };
        this.props.getStatusBarData(statusBarJson);
    };

    componentWillReceiveProps(newProps) {
        if (this.props.testPartners !== newProps.testPartners) {
            this.setState({ wfApolloPartners: newProps.testPartners });
        }
        if (newProps.totalPartners != this.props.totalPartners) {
            this.setState({ partnersData: newProps.totalPartners });
        }
        if (newProps.myPartners !== this.props.myPartners) {
            // console.log(newProps.myPartners, '========== my partners');
            let mergedData = [];
            if (
                newProps.myPartners.SapPartners &&
                newProps.myPartners.SapPartners.length > 0 &&
                newProps.myPartners.PartnerEdits &&
                newProps.myPartners.PartnerEdits.length > 0
            ) {
                let partnerObj = {};
                partnerObj = Object.assign({}, newProps.myPartners);
                let totalData = [];
                let partnerEditsData = [];
                let totalName = '';
                let editName = '';
                let flag = false;

                let combinedArray = [];
                // console.log('test else')
                let apolloPartnersData = newProps.myPartners.SapPartners;
                partnerEditsData = newProps.myPartners.PartnerEdits;
                // to find those in common:
                let commonData = partnerEditsData.filter((o1) =>
                    apolloPartnersData.some((o2) => {
                        if (newProps.myPartners.WorkflowTaskStateTypeId == 2)
                            return (
                                o1.CustomerNumberOrWorkflowNumber == o2.PartnerNumber &&
                                o1.PartnerFunctionTypeId == o2.PartnerFunctionTypeId &&
                                o1.PartnersOperation != 'Add'
                            );
                        if (newProps.myPartners.WorkflowTaskStateTypeId != 2)
                            return (
                                o1.CustomerNumberOrWorkflowNumber == o2.PartnerNumber &&
                                o1.PartnerFunctionTypeId == o2.PartnerFunctionTypeId
                            );
                    })
                );

                // To find those in 1 NOT in 2:
                let uniqueData = partnerEditsData.filter((o1) => {
                    if (newProps.myPartners.WorkflowTaskStateTypeId == 2)
                        return (
                            !apolloPartnersData.some(
                                (o2) =>
                                    o1.CustomerNumberOrWorkflowNumber ==
                                        o2.PartnerNumber &&
                                    o1.PartnerFunctionTypeId == o2.PartnerFunctionTypeId
                            ) || o1.PartnersOperation == 'Add'
                        );
                    if (newProps.myPartners.WorkflowTaskStateTypeId != 2)
                        return !apolloPartnersData.some(
                            (o2) =>
                                o1.CustomerNumberOrWorkflowNumber == o2.PartnerNumber &&
                                o1.PartnerFunctionTypeId == o2.PartnerFunctionTypeId
                        );
                });
                let testArray = [];
                apolloPartnersData.forEach((o1) => {
                    let flag = true;
                    commonData.some((o2) => {
                        if (
                            o1.PartnerNumber == o2.CustomerNumberOrWorkflowNumber &&
                            o1.PartnerFunctionTypeId == o2.PartnerFunctionTypeId
                        ) {
                            let obj = {};
                            obj = { ...o1, ...o2 };
                            testArray.push(obj);
                            flag = false;
                        }
                    });
                    if (flag) {
                        testArray.push(o1);
                    }
                });
                uniqueData.forEach((a2) => {
                    testArray.push(a2);
                });

                // console.log(testArray, '============ new logic');
                // }
                mergedData = [...testArray];

                if (mergedData && mergedData.length > 0)
                    mergedData.forEach((data) => {
                        data['IsDefaultPartner'] =
                            data.IsDefault != null || data.IsDefault != undefined
                                ? data.IsDefault
                                : data.IsDefaultPartner;
                        if (data.PartnersOperation == 'Remove') {
                            data['UnPartner'] = true;
                        }
                    });
            } else {
                mergedData = newProps.myPartners.PartnerEdits;
                if (mergedData && mergedData.length > 0)
                    mergedData.forEach((data) => {
                        data['IsDefaultPartner'] = data.IsDefault;
                        if (data.PartnersOperation == 'Remove') {
                            data['UnPartner'] = true;
                        }
                    });
                // console.log(mergedData, '===========');
            }
            if (
                this.state.originalTableData &&
                this.state.originalTableData.length === 0 &&
                newProps.myPartners &&
                newProps.myPartners.SapPartners
            ) {
                this.setState({
                    originalTableData: [...newProps.myPartners.SapPartners],
                    originalEditDict: [...newProps.myPartners.PartnerEdits],
                });
            }
            // console.log(mergedData, '==== merged data');
            if (mergedData && mergedData.length > 0) {
                let isSoldToPresent = false;
                mergedData.some((partnerData) => {
                    if (partnerData.PartnerFunctionTypeId == 1) {
                        isSoldToPresent = true;
                        return true;
                    } else {
                        isSoldToPresent = false;
                    }
                });

                // console.log(isSoldToPresent);
                this.setState({
                    isSoldToPresent,
                });
            }
            this.setState({ wfApolloPartners: mergedData });
        }
        if (this.props.statusApproval !== newProps.statusApproval) {
            this.setState(
                {
                    allowApprove: !newProps.statusApproval,
                },
                () => {
                    if (!this.state.allowApprove) {
                        let { state: wf } = this.props.location;
                        var postdata = {
                            WorkflowId: wf.WorkflowId,
                            UserId: localStorage.getItem('userId'),
                            SystemTypeId: 2,
                        };
                        // this.props.getWFApolloPartners(postdata);
                    }
                }
            );
        }
        if (
            this.props.fetching !== newProps.fetching &&
            this.state.wfApolloPartners &&
            this.state.wfApolloPartners.length > 0
        ) {
            this.getWorkflowStatus();
        }
    }

    onFieldChange = (value, e) => {
        const name = e.target.name;
        const { formData: prevFormData } = this.state;

        this.setState((prevState, props) => {
            return {
                formData: {
                    ...prevState.formData,
                    [name]: value,
                },
            };
        });
    };

    onFilterSubmit = () => {
        const { formData, isToggled } = this.state;
        this.setState({ loadedPartnersRequestData: true, current_page: 1 });
        this.toggle(!isToggled);
        var postdata = {
            UserId: localStorage.getItem('userId'),
            CustomerOrWorkflowId: formData.CustomerOrWorkflowId,
            SystemTypeId: 3,
        };
        this.setState({ previousPostData: postdata });
        this.props.getWFApolloPartners(postdata);
    };

    onReset = () => {
        this.setState((prevState, props) => {
            return {
                formData: {},
                isToggled: !prevState.isToggled,
                loadedPartnersRequestData: !prevState.loadedPartnersRequestData,
                current_page: 1,
                isApproveReject: null,
            };
        });
        var postdata = {
            UserId: localStorage.getItem('userId'),
        };

        this.props.getWFApolloPartners();
    };

    generateWorkflowId() {
        const url = GenerateWorkflowId;
        ajaxGetRequest(url).then((res) => {
            if (res.IsSuccess)
                this.setState({
                    fetchingWorkflowId: false,
                    formData: {
                        ...this.state.formData,
                        WorkflowId: res.ResultData,
                    },
                });
        });
    }

    isEmpty = (data) => {
        let result = false;
        if (typeof data === 'string') {
            return !data || 0 === data.length;
        }
        if (typeof data === 'object') {
            for (var key in data) {
                if (data.hasOwnProperty(key)) return false;
            }
            return true;
        }
        return result;
    };

    onClickSave = (selected, isApproveReject) => {
        // console.log('huray', selected);
        this.setState({
            formErrors: {},
            modalVisible: true,
            selectedDataToApprove: selected,
            isApproveReject,
        });
    };

    onSubmit = (event, flag, _schema) => {
        // this.setState(
        //     {
        //         modalVisible: false,
        //     },
        //     () => {
        console.log('tstaet', this.state.formData);
        yupAllFieldsValidation(
            { ...this.state.formData, ...this.state.isApproveReject },
            _schema,
            (...rest) => {
                this.handleSubmission(...rest);
                this.setState({ modalVisible: false });
            },
            this.setFormErrors
        );
        // }
        // this.handleSubmission()
        // );
    };

    checkChangeOfData = (editedData, originalData) => {
        let flag = false;
        if (editedData.PartnersOperation == 'Edit') {
            if (
                (editedData.IsDefaultPartner != undefined &&
                    editedData.IsDefaultPartner != null) ||
                (originalData.IsDefaultPartner != undefined &&
                    originalData.IsDefaultPartner != null)
            ) {
                // console.log(
                //     editedData.IsDefaultPartner != originalData.IsDefaultPartner ||
                //     editedData.IsDefault != originalData.IsDefault
                // );
                return (
                    editedData.IsDefaultPartner != originalData.IsDefaultPartner ||
                    editedData.IsDefault != originalData.IsDefault
                );
            }
            if (editedData.UnPartner != null || editedData.UnPartner != undefined) {
                return editedData.UnPartner != originalData.UnPartner;
            }
        } else if (
            editedData.PartnersOperation == 'Add' ||
            editedData.PartnersOperation == 'Remove'
        ) {
            return true;
        }
    };

    checkMandatoryRoles = (data) => {
        let mandatedRoles = [1, 2, 3, 4];
        let exceptionRoles = [5, 6];
        let tempData = [];
        let flag = true;
        if (data.length > 0) {
            data.forEach((roleIds) => {
                tempData.push(parseInt(roleIds));
            });
            mandatedRoles.forEach((roleIds) => {
                if (roleIds) {
                    if (!tempData.includes(parseInt(roleIds))) {
                        flag = false;
                    }
                } else {
                    flag = false;
                }
            });
            console.log(flag);
            return flag;
        } else {
            return false;
        }
    };

    checkRedundantData = (payloadData) => {
        let isRedundant = false;
        const { originalTableData } = this.state;
        payloadData.forEach((data, payloadIndex) => {
            originalTableData.some((checkData, checkIndex) => {
                // if(payloadIndex!=checkIndex){
                // console.log('inside cond loop', data,checkData)
                if (
                    data.CustomerNumberOrWorkflowNumber == checkData.PartnerNumber &&
                    data.PartnerFunctionTypeId == checkData.PartnerFunctionTypeId &&
                    data.PartnersOperation == 'Add'
                ) {
                    isRedundant = true;
                    return true;
                    // }
                }
            });
        });
        payloadData.forEach((data, payloadIndex) => {
            payloadData.some((checkData, checkIndex) => {
                if (payloadIndex != checkIndex) {
                    // console.log('inside cond loop', data,checkData)
                    if (
                        data.CustomerNumberOrWorkflowNumber ==
                            checkData.CustomerNumberOrWorkflowNumber &&
                        data.PartnerFunctionTypeId == checkData.PartnerFunctionTypeId &&
                        data.PartnersOperation == 'Add'
                    ) {
                        isRedundant = true;
                        return true;
                    }
                }
            });
        });

        // console.log(isRedundant,'================ is Redundant')

        return !isRedundant;
    };

    handleSubmission = (schema) => {
        const { myPartners } = this.props;
        const { selectedDataToApprove = [] } = this.state;
        const {
            selectedFilesIds,
            selectedFiles,
            formData,
            userId,
            newAddedData,
        } = this.state;
        let partnerEdits = [];
        let changedData = [];
        //create payload
        // console.log(this.props, '=======', this.state.payloadp,"===========",newAddedData,"=======");
        let flag = true;
        let checkRoles = [];
        let checkRoleConditionsIndex = null;
        if (newAddedData.length > 0) {
            checkRoleConditionsIndex = newAddedData.findIndex((x) => {
                if (x.PartnerFunctionTypeId) checkRoles.push(x.PartnerFunctionTypeId);
                if (!x.PartnerFunctionTypeId) flag = false;
                return !x.PartnerFunctionTypeId;
            });
            if (checkRoleConditionsIndex < 0) {
                flag = true;
            } else {
                flag = false;
            }
        } else {
            if (this.state.payloadp.length > 0) {
                checkRoleConditionsIndex = this.state.wfApolloPartners.findIndex((x) => {
                    if (x.PartnerFunctionTypeId) checkRoles.push(x.PartnerFunctionTypeId);
                    if (!x.PartnerFunctionTypeId) flag = false;
                    return !x.PartnerFunctionTypeId;
                });
                if (checkRoleConditionsIndex < 0) {
                    flag = true;
                } else {
                    flag = false;
                }
            } else {
                flag = false;
            }
        }

        if (flag && this.checkMandatoryRoles(checkRoles)) {
            // console.log(this.state.originalTableData,this.state.payloadp)
            if (
                this.state.wfApolloPartners.length > 0 &&
                this.props.myPartners.SapPartners.length > 0
            ) {
                // console.log("test =========",this.state.payloadp, this.state.originalTableData, 'inside cond')
                this.state.payloadp.forEach((payloadData) => {
                    this.state.originalTableData.some((origData) => {
                        if (
                            payloadData.PartnersOperation == 'Add' ||
                            payloadData.CustomerNumberOrWorkflowNumber ==
                                origData.PartnerNumber
                        ) {
                            if (this.checkChangeOfData(payloadData, origData)) {
                                if (payloadData['IsDefault'])
                                    payloadData['IsDefaultPartner'] =
                                        payloadData['IsDefault'];
                                changedData.push(payloadData);
                            }
                            return true;
                        }
                    });
                });
            } else {
                changedData = this.state.payloadp;
                // console.log("test =========",this.state.payloadp, this.state.originalTableData, 'inside cond else')
            }
            let a = {
                UserId: localStorage.getItem('userId'),
                OperationName: 'ApprovePartners',
                WorkflowId: myPartners.WorkflowId,
                SystemTypeId: 2,
                CustomerNumberOrWorkflowNumber: myPartners.WorkflowOrCustomerNumber,
                DistributionChannelTypeId: myPartners.DistributionChannelTypeId,
                DivisionTypeId: myPartners.DivisionTypeId,
                SalesOrgTypeId: myPartners.SalesOrgTypeId,
                RoleTypeId: myPartners.RoleTypeId,
                WorkflowTitle: myPartners.WorkflowTitle,
                PurposeOfRequest: myPartners.PurposeOfRequest,
                WorkflowTaskModel: {
                    RejectionReason: this.state.isApproveReject
                        ? undefined
                        : this.state.formData['RejectionReason'],
                    ApproveRejectReason: this.state.isApproveReject
                        ? this.state.formData['AdditionalNotes']
                        : this.state.formData['RejectionReason'],
                    WorkflowTaskNote: this.state.formData.AdditionalNotes,
                    TaskId: myPartners.TaskId,
                    UserId: localStorage.getItem('userId'),
                    WorkflowId: myPartners.WorkflowId,
                    WorkflowTaskOperationTypeId: this.state.isApproveReject ? 1 : 2,
                },
            };
            // console.log(
            //     selectedDataToApprove,
            //     '---------------',
            //     this.state.wfApolloPartners,
            //     '-----------------',
            //     changedData,
            //     '---------',
            //     partnerEdits
            // );
            let partnersData = this.state.wfApolloPartners;
            // partnersData.forEach(data=>{
            //     if(data)
            // })
            // console.log(changedData,"--------- Changed data")
            for (let i = 0; i < changedData.length; i++) {
                let originalData = changedData[i];
                originalData['ID'] = originalData['ID'] ? originalData['ID'] : '';
                let duplicateData = { ...originalData };
                // let obj = {};
                // obj["ID"] = originalData.ID ? originalData.ID : null;
                // obj["PartnersOperation"] = originalData.PartnersOperation;
                // obj["PartnerFunctionTypeId"] = originalData.PartnerFunctionTypeId;
                // obj["IsDefaultPartner"] = originalData.IsDefaultPartner ? originalData.IsDefaultPartner : false;
                // obj['Unpartner'] = originalData.Unpartner ? originalData.Unpartner : false;
                // console.log(originalData);
                // if (originalData.WorkflowOrCustomerNumber) {
                //     obj['CustomerNumberOrWorkflowNumber'] =
                //         originalData.WorkflowOrCustomerNumber;
                // } else {
                //     obj['CustomerNumberOrWorkflowNumber'] = originalData.CustomerNumberOrWorkflowNumber;
                // }
                if (duplicateData.WorkflowOrCustomerNumber) {
                    duplicateData['CustomerNumberOrWorkflowNumber'] =
                        duplicateData.WorkflowOrCustomerNumber;
                }
                if (duplicateData.SearchedSapPartner)
                    delete duplicateData['SearchedSapPartner'];
                delete duplicateData['WorkflowOrCustomerNumber'];
                delete duplicateData['PartnerFunctionRole'];
                delete duplicateData['rowIndex'];
                partnerEdits.push(duplicateData);
            }

            // console.log('partnerEdits', partnerEdits);
            let postdata = {
                ...a,
                PartnerEdits: partnerEdits,
                files: selectedFilesIds.map((id) => selectedFiles[id]),
            };
            console.log('**--createrequest postdata--**', postdata);
            this.props.approveRejectPartnersApollo(postdata);
        } else {
            // alert("test")
            this.setState(
                {
                    displayMessage: true,
                    errorMessage:
                        checkRoleConditionsIndex > 0
                            ? 'Added row missing roles'
                            : 'Sold To, Ship To, Bill To and Payer partner are mandatory',
                },
                () => {
                    window.scrollTo(0, 0);
                    setTimeout(() => {
                        this.setState({ displayMessage: false });
                    }, 4000);
                }
            );
            // this.props.showMessage(obj)
        }
    };

    setFormErrors = (errors) => {
        const { formErrors } = this.state;
        console.log(errors);
        this.setState({ formErrors: errors }, () => window.scrollTo(0, 0));
    };

    _renderModalContent = () => {
        const { formData, isToggled, selectedFilesIds, selectedFiles } = this.state;
        return (
            <Box
                my={4}
                mx="30%"
                backgroundColor="white"
                borderRadius="4px"
                borderColor="rgba(0, 0, 0, 0.1)">
                <Text
                    style={{
                        fontWeight: '500',
                        fontSize: 20,
                        color: Colors.lightBlue,
                        marginTop: 25,
                        marginBottom: 20,
                        textAlign: 'center',
                    }}>
                    Submit Workflow
                </Text>
                <Box px="0px" pb="22px" justifyContent="center">
                    <Box alignItems="center" justifyContent="center">
                        {this.state.isApproveReject ? (
                            <FormInput
                                label="Additional Notes"
                                multiline
                                numberOfLines={2}
                                maxLength={250}
                                name="AdditionalNotes"
                                type="text"
                                onChange={this.onFieldChange}
                                value={
                                    this.state.formData &&
                                    this.state.formData['AdditionalNotes']
                                }
                                variant={'solid'}
                            />
                        ) : (
                            <FormInput
                                label="Rejection Reason"
                                name="RejectionReason"
                                maxLength={250}
                                onChange={this.onFieldChange}
                                error={'*this field is required'}
                                multiline
                                numberOfLines={2}
                                type="text"
                                value={
                                    this.state.formData &&
                                    this.state.formData['RejectionReason']
                                }
                                variant="solid"
                            />
                        )}
                        {/* <FormInput
                             label="Purpose of Request"
                             multiline
                             numberOfLines={2}
                             maxLength={250}
                             name="PurposeOfRequest"
                             type="text"
                             onChange={this.onFieldChange}
                             value={
                                 this.state.formData &&
                                 this.state.formData['PurposeOfRequest']
                             }
                             variant={'solid'}
                         /> */}
                        {/* <FilesList
                             title="NEW ATTACHMENTS"
                             formErrors={this.state.fileErrors}
                             files={selectedFilesIds.map((id) => selectedFiles[id])}
                             onChange={(value, id) => {
                                 this.setState({
                                     selectedFiles: {
                                         ...selectedFiles,
                                         [id]: {
                                             ...selectedFiles[id],
                                             DocumentType: parseInt(value),
                                         },
                                     },
                                 });
                             }}
                             onRemove={(value, id) => this.removeFile(id)}
                         /> */}
                    </Box>
                    <Flex
                        justifyEnd
                        alignCenter
                        alignSelf="flex-end"
                        style={{
                            paddingTop: 15,
                            flexDirection: 'row',
                            alignItems: 'center',
                            paddingLeft: 10,
                            paddingRight: 15,
                            marginTop: 20,
                            marginBottom: 10,
                            marginHorizontal: 25,
                        }}>
                        {/* <label htmlFor="file-upload" className="custom-file-upload">
                             <MaterialIcons name="attach-file" size={18} color="#fff" />
                         </label>
                         <input
                             id="file-upload"
                             key={this.state.fileinputkey}
                             type="file"
                             onChange={this.selectFiles}
                             multiple
                         /> */}

                        <Button
                            onPress={(event) => {
                                if (!this.state.isApproveReject)
                                    this.setState({ isApproveReject: false }, () =>
                                        this.onSubmit(event, true, rejectPartnerRules)
                                    );
                                else this.onSubmit(event, false, approveApolloPartners);
                            }}
                            title="Submit"
                        />
                        <Button
                            title="Cancel"
                            onPress={() => this.setState({ modalVisible: null })}
                        />
                    </Flex>
                </Box>
            </Box>
        );
    };

    _wfrenderModalContent = () => {
        const { formData } = this.state;

        return (
            <Box
                my={4}
                mx="30%"
                backgroundColor="white"
                borderRadius="4px"
                borderColor="rgba(0, 0, 0, 0.1)">
                {this.state.fetchingWorkflowId && <ActivityIndicator />}
                <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between">
                    <Text
                        style={{
                            fontWeight: '500',
                            fontSize: 20,
                            color: Colors.lightBlue,
                            marginTop: 25,
                            marginBottom: 20,
                            textAlign: 'center',
                        }}>
                        Submit Workflow
                    </Text>
                </Box>
                <Box px="0px" pb="22px" justifyContent="center">
                    <Box alignItems="center" justifyContent="center">
                        <FormInput
                            label="Workflow Id"
                            name="WorkflowId"
                            type="text"
                            value={
                                this.state.formData && this.state.formData['WorkflowId']
                            }
                            variant={'outline'}
                        />
                        <FormInput
                            label="Workflow Title"
                            multiline
                            numberOfLines={2}
                            maxLength={250}
                            name="WorkflowTitle"
                            error={'*this field is required'}
                            type="text"
                            onChange={this.onFieldChange}
                            value={
                                this.state.formData &&
                                this.state.formData['WorkflowTitle']
                            }
                            variant={'solid'}
                        />
                    </Box>
                    <Flex
                        justifyEnd
                        alignCenter
                        alignSelf="flex-end"
                        style={{
                            paddingTop: 15,
                            flexDirection: 'row',
                            alignItems: 'center',
                            paddingLeft: 10,
                            paddingRight: 15,
                            marginTop: 20,
                            marginBottom: 10,
                            marginHorizontal: 25,
                        }}>
                        <Button
                            onPress={(event) => {
                                this.onSubmit(event, approveApolloPartners);
                            }}
                            title="Submit"
                        />
                        <Button
                            title="Cancel"
                            onPress={() => this.setState({ modalVisible: null })}
                        />
                    </Flex>
                </Box>
            </Box>
        );
    };

    componentWillUnmount() {
        this.props.clearPartnerDetails();
        this.props.clearMessage();
    }

    selectFiles = (events) => {
        event.preventDefault();

        const { selectedFilesIds, selectedFiles } = this.state;
        const id = events.target.files[0].name;

        this.setState({
            selectedFiles: {
                ...selectedFiles,
                [id]: {
                    data: events.target.files[0],
                    DocumentName: events.target.files[0].name,
                    DocumentType: 0,
                },
            },
            selectedFilesIds: [...selectedFilesIds, id],
            filename: events.target.files[0].name,
            fileinputkey: Date.now(),
        });
    };

    removeFile = (id) => {
        event.preventDefault();

        var selectedFilesDict = this.state.selectedFiles;
        var selectedFilesIdsList = this.state.selectedFilesIds;
        // remove the existing state
        delete selectedFilesDict[id];
        selectedFilesIdsList = selectedFilesIdsList.filter((fileid) => fileid !== id);

        this.setState({
            selectedFiles: selectedFilesDict,
            selectedFilesIds: selectedFilesIdsList,
        });
    };

    getStaticDropdownValues = (Id, dropdownList) => {
        let description = '';
        if (Id && dropdownList)
            dropdownList.some((data) => {
                if (data.Id === Id) {
                    description = data.Value + ' : ' + data.Description;
                    return true;
                }
            });
        return description;
    };

    render() {
        const {
            width,
            height,
            marginBottom,
            location,
            partnersFilters: filters = {},
            myPartners,
            staticData,
            statusBarData,
        } = this.props;
        const { state } = location;
        const { isToggled, selectedFilesIds, selectedFiles } = this.state;

        const { id, page } = this.props.match.params;

        let isRequestPage = page === 'my-requests' ? true : false;

        let details = '';
        if (!this.isEmpty(filters)) {
            let { CustomerOrWorkflowId } = filters;
            if (CustomerOrWorkflowId)
                details = details + '   Workflow or Customer Id: ' + CustomerOrWorkflowId;
        }

        const pageNumbers = [];

        if (this.props.totalPartners) {
            let totalpageCnt = Math.ceil(this.props.totalPartners.length);
            for (let i = 1; i <= totalpageCnt; i++) {
                pageNumbers.push(i);
            }
        }

        // console.log('wfApolloPartners', this.props, this.state);
        let tempStatusBarData = statusBarData.filter(
            (data) => data.TeamId == 1 || data.TeamId == 3
        );

        return (
            <View
                style={{
                    backgroundColor: '#EFF3F6',
                    paddingBottom: 75,
                }}>
                <ScrollView
                    keyboardShouldPersistTaps="always"
                    style={{
                        flex: 1,
                        paddingHorizontal: width < 1440 ? 75 : width * 0.1,
                        paddingBottom: 5,
                    }}>
                    <Modal isVisible={this.state.modalVisible}>
                        {this._renderModalContent()}
                    </Modal>
                    {this.props.fetching && (
                        <Box
                            display="flex"
                            flex={1}
                            flexDirection="row"
                            justifyContent="center"
                            alignItems="center"
                            minHeight="650px">
                            <ActivityIndicator size="large" />
                        </Box>
                    )}
                    <div
                        style={{
                            visibility: this.props.fetching ? 'hidden' : 'visible',
                        }}>
                        <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            justifyContent="space-between">
                            <Text
                                style={{
                                    fontWeight: '500',
                                    fontSize: 24,
                                    color: Colors.lightBlue,
                                    marginTop: 25,
                                }}>
                                {this.props.location &&
                                this.props.location.pathname.includes('my-tasks')
                                    ? 'My  Tasks - OLYMPUS PARTNERS'
                                    : 'My  Requests - OLYMPUS PARTNERS'}
                            </Text>
                            <Box
                                marginTop="25px"
                                flex={1 / 4}
                                flexDirection="row-reverse"
                                alignItems="flex-end">
                                <MultiColorProgressBar
                                    readings={tempStatusBarData}
                                    chevronWidth={'40%'}
                                />
                            </Box>
                        </Box>
                        {this.state.displayMessage && (
                            <FlashMessage
                                animate
                                bg={{ backgroundColor: '#EA0029' }}
                                message={this.state.errorMessage}
                                showDuration={10000}
                                style={{
                                    width: '90%',
                                    wordWrap: 'break-word',
                                    marginTop: '-5%',
                                }}
                                isCopy={true}
                            />
                        )}
                        <FlashMessages
                            toasts={this.props.toasts}
                            onDismiss={this.props.clearMessage}
                            showDuration={10000}
                            style={{
                                width: '90%',
                                wordWrap: 'break-word',
                                marginTop: '-5%',
                            }}
                            isCopy={true}
                        />
                        {this.props.location &&
                            this.props.location.pathname.includes('my-tasks') && (
                                <>
                                    <Box
                                        marginTop="25px"
                                        flexDirection="row-reverse"
                                        alignItems="flex-end">
                                        <StyledText
                                            style={{
                                                fontWeight: '600',
                                                paddingRight: '20px',
                                                fontSize: '16px',
                                                color: '#10206C',
                                            }}>
                                            {' '}
                                            Requestor :
                                            {this.props.myPartners &&
                                                this.props.myPartners.RequestorName &&
                                                ' ' +
                                                    this.props.myPartners
                                                        .RequestorName}{' '}
                                        </StyledText>
                                    </Box>

                                    <Box
                                        flexDirection="row-reverse"
                                        alignItems="flex-end">
                                        <StyledText
                                            style={{
                                                fontWeight: '600',
                                                paddingRight: '20px',
                                                fontSize: '16px',
                                                color: '#10206C',
                                            }}>
                                            {' '}
                                            Date of Request :
                                            {this.props.myPartners &&
                                                this.props.myPartners.DateOfCreation &&
                                                ' ' +
                                                    this.props.myPartners
                                                        .DateOfCreation}{' '}
                                        </StyledText>
                                    </Box>
                                </>
                            )}

                        <Box my={2}>
                            <Box flexDirection="row" justifyContent="space-around" my={4}>
                                <FormInput
                                    px="25px"
                                    flex={1 / 4}
                                    label="WorkflowTitle"
                                    maxLength={40}
                                    name="WorkflowTitle"
                                    variant="outline"
                                    type="text"
                                    value={myPartners && myPartners.WorkflowTitle}
                                />
                                <FormInput
                                    px="25px"
                                    flex={1 / 4}
                                    label="Workflow Number"
                                    name="workflowNumber"
                                    variant="outline"
                                    type="text"
                                    value={myPartners && myPartners.WorkflowId}
                                />
                            </Box>
                        </Box>
                        <Text
                            style={{
                                fontWeight: '500',
                                fontSize: 20,
                                color: Colors.lightBlue,
                                marginTop: 15,
                                marginBottom: 10,
                            }}>
                            SYSTEM FIELDS
                        </Text>
                        <Box flexDirection="row" justifyContent="center">
                            <Box width={1 / 2} mx="auto" alignItems="center">
                                <FormInput
                                    label="System"
                                    name="System"
                                    inline
                                    variant="outline"
                                    type="text"
                                    value={'Olympus Partners'}
                                />
                                <FormInput
                                    label="Role"
                                    name="Role"
                                    inline
                                    variant="outline"
                                    type="text"
                                    value={RoleType[myPartners && myPartners.RoleTypeId]}
                                />
                                <FormInput
                                    label="Sales Org"
                                    name="SalesOrg"
                                    inline
                                    variant="outline"
                                    type="text"
                                    value={
                                        myPartners &&
                                        staticData &&
                                        this.getStaticDropdownValues(
                                            myPartners.SalesOrgTypeId,
                                            staticData.OlympusSalesOrgType
                                        )
                                    }
                                />
                            </Box>

                            <Box width={1 / 2} mx="auto" alignItems="center">
                                <FormInput
                                    label="Distribution Channel"
                                    name="DistributionChannel"
                                    inline
                                    variant="outline"
                                    type="text"
                                    value={
                                        myPartners &&
                                        staticData &&
                                        this.getStaticDropdownValues(
                                            myPartners.DistributionChannelTypeId,
                                            staticData.OlympusDistributionChannelType
                                        )
                                    }
                                />
                                <FormInput
                                    label="Division"
                                    name="DivisionTypeId"
                                    inline
                                    variant="outline"
                                    type="text"
                                    value={
                                        myPartners &&
                                        staticData &&
                                        this.getStaticDropdownValues(
                                            myPartners.DivisionTypeId,
                                            staticData.OlympusDivisionType
                                        )
                                    }
                                />
                                <FormInput
                                    label="Purpose of Request"
                                    name="Purpose"
                                    multiline
                                    value={myPartners && myPartners.PurposeOfRequest}
                                    numberOfLines={
                                        myPartners.Purpose &&
                                        myPartners.Purpose.length > 36
                                            ? 3
                                            : 1
                                    }
                                    inline
                                    maxLength={1000}
                                    variant="outline"
                                    type="text"
                                />
                                {/* {myPartners && myPartners.WorkflowTaskStateTypeId != 2 && (
                                <FormInput
                                    label="Notes"
                                    name="AdditionalNotes"
                                    multiline
                                    numberOfLines={2}
                                    type="text"
                                    style={{ display: 'none' }}
                                    inputWidth="100%"
                                    value={myPartners.WorkflowTaskNote}
                                    variant={'outline'}
                                    inline={true}
                                />
                            )} */}
                            </Box>
                        </Box>
                        {!this.isEmpty(filters) && details.length > 0 && (
                            <StyledText
                                style={{ color: '#10206C', textAlign: 'inherit' }}>
                                Search Filters Applied: {details}
                            </StyledText>
                        )}
                        <MyTaskPartnerGrid
                            data={this.state.wfApolloPartners}
                            allowEdit={myPartners && myPartners.AllowApolloPartnerEdit}
                            location={this.props && this.props.location}
                            systemFields={myPartners}
                            readOnly={isRequestPage}
                            columns={MyTaskApolloPartnersColumns}
                            onClickSave={this.onClickSave}
                            onChange={(data) => {
                                console.log(data, '====== onchange data');
                                this.setState({
                                    apdata: data,
                                    message: null,
                                });
                            }}
                            setPayloadp={(pdata) => {
                                console.log('payload--', pdata);
                                this.setState({
                                    payloadp: pdata,
                                    isDataEdited: true,
                                });
                            }}
                            ApolloPartnersRoleType={
                                this.props.staticData &&
                                this.props.staticData.OlympusPartnersRoleType
                            }
                            defaultPartnerEdits={myPartners && myPartners.PartnerEdits}
                            setTableData={(data) => {
                                this.setState({
                                    newAddedData: data,
                                });
                            }}
                            isSoldToPresent={this.state.isSoldToPresent}
                            screen={
                                this.props.location &&
                                this.props.location.pathname.includes('my-tasks')
                                    ? 'mytask'
                                    : 'myrequest'
                            }
                            allowApprove={this.state.allowApprove}
                            originalTableData={this.state.originalTableData}
                            originalEditDict={this.state.originalEditDict}
                            isDataEdited={this.state.isDataEdited}
                            selectFiles={this.selectFiles}
                        />
                        {this.props.myPartners &&
                            this.props.myPartners.DocumentLocation &&
                            this.props.myPartners.DocumentLocation.length > 0 && (
                                <FilesList
                                    files={this.props.myPartners.DocumentLocation}
                                    fileTypes={[
                                        'Choose from...',
                                        'License',
                                        'CreditApp',
                                        'PurchaseOrder',
                                        'Supporting',
                                        'Contracts',
                                    ]}
                                    readOnly
                                />
                            )}

                        <FilesList
                            title="NEW ATTACHMENTS"
                            formErrors={this.state.fileErrors}
                            files={selectedFilesIds.map((id) => selectedFiles[id])}
                            onChange={(value, id) => {
                                this.setState({
                                    selectedFiles: {
                                        ...selectedFiles,
                                        [id]: {
                                            ...selectedFiles[id],
                                            DocumentType: parseInt(value),
                                        },
                                    },
                                });
                            }}
                            onRemove={(value, id) => this.removeFile(id)}
                        />

                        {myPartners &&
                            myPartners.WorkflowTaskStateTypeId != 2 &&
                            myPartners.TaskNoteData &&
                            myPartners.TaskNoteData.length > 1 && (
                                <Box flexDirection="row" justifyContent="center">
                                    <Box ml="5%" width={1 / 2}>
                                        {myPartners &&
                                            myPartners.TaskNoteData &&
                                            myPartners.TaskNoteData.length > 0 && (
                                                <TaskNotes
                                                    taskNotes={myPartners.TaskNoteData.filter(
                                                        (data) => data.TeamName != 999
                                                    )}
                                                />
                                            )}
                                    </Box>
                                    <Box width={1 / 2} mx="auto" alignItems="center" />
                                </Box>
                            )}
                        {this.props.location &&
                            !this.props.location.pathname.includes('my-tasks') &&
                            myPartners &&
                            myPartners.WorkflowTaskStateTypeId == 2 &&
                            myPartners.WorkflowStateTypeId == 2 && (
                                <Button
                                    style={{ width: '10%', alignSelf: 'flex-end' }}
                                    onPress={() => {
                                        window.scrollTo(0, 0);
                                        this.props.withDrawRequest(
                                            {
                                                WorkflowId: myPartners.WorkflowId,
                                                WorkflowOperationType: 4,
                                            },
                                            this.props.history
                                        );
                                    }}
                                    title="Withdraw"
                                />
                            )}

                        {/* <Button
                         style={{ width: '10%', alignSelf: 'flex-end' }}
                         onPress={(event) =>
                             this.setState({
                                 formErrors: {},
                                 modalVisible: true,
                             })
                         }
                         title="Save"
                     /> */}
                    </div>
                </ScrollView>
            </View>
        );
    }
}

class Default extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {}

    render() {
        const props = this.props;
        return (
            <DimensionAware
                render={(dimensions) => (
                    <Page
                        {...{
                            ...props,
                            width: getWindowWidth(dimensions),
                            height: getWindowHeight(dimensions),
                            marginBottom: 25,
                        }}
                    />
                )}
            />
        );
    }
}

const mapStateToProps = ({ myPartners, staticData, toasts, workflows }) => {
    const {
        wfApolloPartnersData: partners,
        apolloPartnersData: partnersData,
        totalPartners,
        partnersFilters,
        fetching,
        alert,
        statusApproval,
    } = myPartners;
    const {
        fetchingfnGroupData,
        statusBarData,
        functionalGroupDetails,
        TasksStatusByTeamId,
        fetchingStatusBar,
    } = workflows;
    const { data, fetching: staticFetching } = staticData;
    return {
        myPartners: partners || [],
        testPartners: partnersData,
        totalPartners: totalPartners || [],
        partnersFilters,
        fetching: fetching || staticFetching,
        alert,
        staticData: data,
        toasts,
        functionalGroupDetails,
        workflows,
        statusApproval,
        statusBarData,
    };
};

const styles = StyleSheet.create({
    inputContainer: {
        marginLeft: 0,
        borderWidth: 2,
        minWidth: 700,
        borderRadius: 20,
        flex: 1,
        borderBottomWidth: 2,
        borderBottomLeftRadius: 20,
        borderBottomRightRadius: 20,
    },
    noBorders: {
        borderBottomWidth: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
    },
    img: {
        width: 20,
        height: 20,
    },
});

export default connect(mapStateToProps, {
    getWFApolloPartners,
    getStaticRequest,
    approveRejectPartnersApollo,
    clearPartnerDetails,
    showMessage,
    clearMessage,
    withDrawRequest,
    getStatusBarData,
})(Default);
