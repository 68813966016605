import React from 'react';
import { ScrollView, View, StyleSheet, Dimensions, TouchableOpacity } from 'react-native';
import {
    DimensionAware,
    getWindowHeight,
    getWindowWidth,
} from 'react-native-dimension-aware';
import { Flex, Button, Box, Text } from '../../../../components/common';
import { FormInput, FormSelect } from '../../../../components/form';
import { extendReleaseChecklist } from '../../../../appRedux/actions/MyTasks';
import { yupFieldValidation } from '../../../../constants/utils';
import { rejectRules } from '../../../../constants/FieldRules';
import { Colors, StyledText } from '../../../../theme/Index';
import Modal from 'modal-enhanced-react-native-web';
import { MaterialIcons } from '@expo/vector-icons';
import TaskNotes from '../../../../components/TaskNotes';

import GlobalMdmFields from '../../../../components/GlobalMdmFields';
import {
    RoleType,
    SalesOrgType,
    SystemType,
    DistributionChannelType,
    DivisionType,
    CompanyCodeType,
    WorkflowTeamType,
} from '../../../../constants/WorkflowEnums';
import Loading from '../../../../components/Loading';
import FlashMessage from '../../../../components/FlashMessage';
import { connect } from 'react-redux';
import MultiColorProgressBar from '../../../../components/MultiColorProgressBar';
import { getStatusBarData } from '../../../../appRedux/actions/Workflow';
import { getCustomerFromSAP, getStaticRequest } from '../../../../appRedux/actions';

import BreadCrumbs from '../../../../components/BreadCrumbs';
import FilesList from '../../../../components/FilesList';
import * as moment from 'moment';
import idx from 'idx';

class Page extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            WorkflowId: this.props.location.state.WorkflowId,
            TaskId: this.props.location.state.TaskId,
            selectedFiles: {},
            selectedFilesIds: [],
        };
    }

    componentDidMount() {
        this.props.getStaticRequest([42, 43, 82]);
        let { state: wf } = this.props.location;
        let postJson = {
            workflowId: wf.WorkflowId,
            fuctionalGroup: WorkflowTeamType[wf.TeamId].toLowerCase(),
            taskId: wf.TaskId,
        };
        this.props.getStatusBarData(postJson);
        this.props.getCustomerFromSAP({
            WorkflowId: wf.WorkflowId,
            TaskId: wf.TaskId,
            CustomerNumber: '',
            DivisionTypeId: 0,
            SystemTypeId: 0,
            DistributionChannelTypeId: 0,
            CompanyCodeTypeId: '',
            SalesOrgTypeId: 0,
        });
    }

    handleFormSubmission = () => {
        let {
                TaskId,
                WorkflowId,
                selectedFilesIds,
                selectedFiles,
                formData = {},
            } = this.state,
            postData = {};
        try {
            postData['formData'] = {
                TaskNote: formData.additionalNotes,
                TaskId: TaskId,
                UserId: localStorage.getItem('userId'),
                WorkflowId: WorkflowId,
                WorkflowTaskOperationType: 1,
            };
            postData['teamId'] = this.props.location.state.TeamId;
            postData['files'] = selectedFilesIds.map((id) => selectedFiles[id]);
            this.props.extendReleaseChecklist(postData);
            this.setState({ modalVisible: false });
            this.scrollToTop();
        } catch (error) {
            console.log(error);
        }
    };

    onSubmit = () => {
        let { formData, selectedFilesIds, selectedFiles } = this.state;
        let fileErrors = {};
        let errors = false;
        selectedFilesIds.map((id) => {
            if (selectedFiles[id] && selectedFiles[id].DocumentType <= 0) {
                fileErrors[id] = 'Document Type Required for file';
                errors = true;
            }
        });

        this.setState({ fileErrors, isFileErrors: errors });

        console.log(errors);
        if (errors === false) this.handleFormSubmission();
    };

    scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    _renderModalContent = () => (
        <Box
            my={4}
            mx="30%"
            backgroundColor="white"
            borderRadius="4px"
            borderColor="rgba(0, 0, 0, 0.1)">
            <Text my={3} fontWeight="regular" color="lightBlue" fontSize={24} pl={4}>
                Release
            </Text>
            <Box px="0px" pb="22px" justifyContent="center">
                <Box alignItems="center" justifyContent="center">
                    <FormInput
                        label="Notes"
                        multiline
                        numberOfLines={2}
                        maxLength={250}
                        name="additionalNotes"
                        type="text"
                        onChange={this.onFieldChange}
                        error={
                            this.state.formErrors
                                ? this.state.formErrors['additionalNotes']
                                : null
                        }
                        value={
                            this.state.formData && this.state.formData['additionalNotes']
                        }
                        variant={'solid'}
                    />
                </Box>
                <Flex
                    justifyEnd
                    alignCenter
                    alignSelf="flex-end"
                    style={{
                        paddingTop: 15,
                        flexDirection: 'row',
                        alignItems: 'center',
                        paddingLeft: 10,
                        paddingRight: 15,
                        marginTop: 20,
                        marginBottom: 10,
                        marginHorizontal: 25,
                    }}>
                    <Button
                        onPress={(event) => {
                            this.onSubmit(event, false);
                        }}
                        title="Confirm"
                    />
                    <Button
                        title="Cancel"
                        onPress={() => this.setState({ modalVisible: null })}
                    />
                </Flex>
            </Box>
        </Box>
    );

    onFieldChange = (value, e) => {
        const { name } = e.target;
        this.setState(
            {
                formData: {
                    ...this.state.formData,
                    [name]: value,
                },
            },
            () => {
                //remove from formData if TypeId is set to 0 OR ''
                if (name.includes('TypeId') && parseInt(value) === 0) {
                    delete this.state.formData[name];
                }
            }
        );
    };

    selectFiles = (events) => {
        event.preventDefault();

        const { selectedFilesIds, selectedFiles } = this.state;
        const id = events.target.files[0].name;

        this.setState({
            selectedFiles: {
                ...selectedFiles,
                [id]: {
                    data: events.target.files[0],
                    DocumentName: events.target.files[0].name,
                    DocumentType: 0,
                },
            },
            selectedFilesIds: [...selectedFilesIds, id],
            filename: events.target.files[0].name,
            fileinputkey: Date.now(),
        });
    };

    removeFile = (id) => {
        event.preventDefault();

        var selectedFilesDict = this.state.selectedFiles;
        var selectedFilesIdsList = this.state.selectedFilesIds;
        // remove the existing state
        delete selectedFilesDict[id];
        selectedFilesIdsList = selectedFilesIdsList.filter((fileid) => fileid !== id);

        this.setState({
            selectedFiles: selectedFilesDict,
            selectedFilesIds: selectedFilesIdsList,
        });
    };

    render() {
        const {
            width,
            location,
            history: { action },
            bapi70CustData: globalMdmDetail = {},
            deltas = {},
            alert = {},
            TasksStatusByTeamId = null,
            statusBarData,
            bapi70CustData,
        } = this.props;
        const Deltas = deltas;

        const { TaskNote: Notes = '' } = globalMdmDetail;

        const {
            WorkflowDocuments: files = [],
            RequestorName,
            TaskNoteData,
        } = this.props.bapi70CustData;
        const { state } = location;
        const workflow = {
            ...state,
            isReadOnly:
                TasksStatusByTeamId === null ||
                !(
                    globalMdmDetail.WorkflowStateTypeId === 2 &&
                    TasksStatusByTeamId[state.TeamId].WorkflowTaskStateTypeId === 2
                ),
        };

        const requestDateUtc = moment(idx(bapi70CustData, (_) => _.DateOfRequest));
        const localDate = moment(requestDateUtc).local();
        const requestDate = localDate.format('YYYY-MM-DD hh:mm:ss A');

        const showButtons = workflow.isReadOnly ? { display: 'none' } : null;
        var bgcolor = alert.color || '#FFF';
        if (this.props.fetching) {
            return <Loading />;
        }

        const { selectedFilesIds, selectedFiles } = this.state;

        let tasknotes = TaskNoteData || [];

        console.log(tasknotes);
        return (
            <ScrollView
                keyboardShouldPersistTaps="always"
                style={{
                    backgroundColor: '#EFF3F6',
                    paddingBottom: 75,
                }}>
                <Modal isVisible={this.state.modalVisible}>
                    {this._renderModalContent()}
                </Modal>
                {alert.display && (
                    <FlashMessage
                        bg={{ backgroundColor: bgcolor }}
                        message={alert.message}
                    />
                )}

                <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between">
                    <Text
                        style={{
                            fontWeight: '500',
                            fontSize: 24,
                            color: Colors.lightBlue,
                            marginTop: 25,
                            marginBottom: 20,
                            paddingLeft: 45,
                        }}>
                        MY TASKS - {state.Header}
                    </Text>
                    <Box
                        marginTop="25px"
                        flex={3 / 4}
                        flexDirection="row-reverse"
                        alignItems="flex-end">
                        <MultiColorProgressBar readings={statusBarData} />
                    </Box>
                </Box>
                <Box marginTop="25px" flexDirection="row-reverse" alignItems="flex-end">
                    <StyledText
                        style={{
                            fontWeight: '600',
                            paddingRight: '20px',
                            fontSize: '16px',
                            color: '#10206C',
                        }}>
                        {' '}
                        Requestor : {RequestorName}{' '}
                    </StyledText>
                </Box>
                <Box flexDirection="row-reverse" alignItems="flex-end">
                    <StyledText
                        style={{
                            fontWeight: '600',
                            paddingRight: '20px',
                            fontSize: '16px',
                            color: '#10206C',
                        }}>
                        {' '}
                        Date of Request: {requestDate}{' '}
                    </StyledText>
                </Box>
                <View
                    style={{
                        flex: 1,
                        paddingHorizontal: width < 1440 ? 60 : width * 0.1,
                        paddingBottom: 10,
                    }}>
                    <Box fullHeight>
                        <Box my={2}>
                            <Box flexDirection="row" justifyContent="space-around" my={4}>
                                <FormInput
                                    px="25px"
                                    flex={1 / 4}
                                    label="Title"
                                    name="WorkflowTitle"
                                    maxLength={40}
                                    variant="outline"
                                    type="text"
                                    value={
                                        globalMdmDetail && globalMdmDetail.WorkflowTitle
                                    }
                                />
                                <FormInput
                                    px="25px"
                                    flex={1 / 4}
                                    label="Workflow Number"
                                    name="workflow-number"
                                    variant="outline"
                                    type="text"
                                    value={state && state.WorkflowId}
                                />
                                <FormInput
                                    px="25px"
                                    flex={1 / 4}
                                    label="Customer Number"
                                    name="CustomerNumber"
                                    variant="outline"
                                    type="text"
                                    value={
                                        globalMdmDetail && globalMdmDetail.SystemRecordId
                                    }
                                />

                                {globalMdmDetail && globalMdmDetail.MdmNumber && (
                                    <TouchableOpacity
                                        onPress={() =>
                                            this.props.history.push(
                                                `/search-results/${globalMdmDetail.MdmNumber}`
                                            )
                                        }>
                                        <FormInput
                                            px="25px"
                                            flex={1 / 4}
                                            label="MDM Number"
                                            name="mdm-number"
                                            variant="outline"
                                            type="text"
                                            value={
                                                globalMdmDetail &&
                                                globalMdmDetail.MdmNumber
                                            }
                                        />
                                    </TouchableOpacity>
                                )}
                            </Box>
                            <GlobalMdmFields
                                formData={globalMdmDetail}
                                staticDropDownData={this.props.staticData}
                                readOnly
                            />

                            <Text
                                mt="42px"
                                mb="20px"
                                fontWeight="regular"
                                color="lightBlue"
                                fontSize={24}
                                pl={4}>
                                SYSTEM FIELDS
                            </Text>
                            <Box flexDirection="row" justifyContent="center">
                                <Box width={1 / 2} mx="auto" alignItems="center">
                                    <FormInput
                                        label="System"
                                        name="System"
                                        inline
                                        variant="outline"
                                        type="text"
                                        value={
                                            SystemType[
                                                globalMdmDetail &&
                                                    globalMdmDetail.SystemType
                                            ]
                                        }
                                    />
                                    <FormInput
                                        label="Role"
                                        name="Role"
                                        inline
                                        variant="outline"
                                        type="text"
                                        value={
                                            RoleType[
                                                globalMdmDetail &&
                                                    globalMdmDetail.RoleTypeId
                                            ]
                                        }
                                    />
                                    <FormInput
                                        label="Sales Org"
                                        name="SalesOrg"
                                        inline
                                        variant="outline"
                                        type="text"
                                        value={
                                            SalesOrgType[
                                                globalMdmDetail &&
                                                    globalMdmDetail.SalesOrgTypeId
                                            ]
                                        }
                                    />
                                    <FormInput
                                        label="Target Sales Org"
                                        name="TargetSalesOrg"
                                        team="system"
                                        inline
                                        variant="outline"
                                        type="text"
                                        value={
                                            SalesOrgType[
                                                globalMdmDetail &&
                                                    globalMdmDetail.TargetSalesOrgTypeId
                                            ]
                                        }
                                    />
                                </Box>
                                <Box width={1 / 2} mx="auto" alignItems="center">
                                    <FormInput
                                        label="Distribution Channel"
                                        name="DistributionChannel"
                                        inline
                                        variant="outline"
                                        type="text"
                                        value={
                                            DistributionChannelType[
                                                globalMdmDetail &&
                                                    globalMdmDetail.DistributionChannelTypeId
                                            ]
                                        }
                                    />
                                    <FormInput
                                        label="Division"
                                        name="DivisionTypeId"
                                        inline
                                        variant="outline"
                                        type="text"
                                        value={
                                            DivisionType[
                                                globalMdmDetail &&
                                                    globalMdmDetail.DivisionTypeId
                                            ]
                                        }
                                    />
                                    <FormInput
                                        label="CompanyCode"
                                        name="CompanyCodeTypeId"
                                        inline
                                        variant="outline"
                                        type="text"
                                        value={
                                            CompanyCodeType[
                                                globalMdmDetail &&
                                                    globalMdmDetail.CompanyCodeTypeId
                                            ]
                                        }
                                    />
                                    <FormInput
                                        label="Purpose of Request"
                                        name="Purpose"
                                        value={
                                            (globalMdmDetail &&
                                                globalMdmDetail.Purpose) ||
                                            (Deltas['Purpose'] &&
                                                Deltas['Purpose'].UpdatedValue)
                                        }
                                        numberOfLines={
                                            globalMdmDetail.Purpose &&
                                            globalMdmDetail.Purpose.length > 36
                                                ? 3
                                                : 1
                                        }
                                        style={{ lineHeight: '2.5' }}
                                        inline
                                        variant="outline"
                                        maxLength={1000}
                                        multiline
                                        type="text"
                                    />
                                </Box>
                            </Box>
                        </Box>
                        {files && <FilesList files={files} readOnly />}
                        {workflow.isReadOnly ? (
                            <>
                                <Box my={4}>
                                    <Box flexDirection="row" justifyContent="center">
                                        <Box width={1 / 2} mx="auto" alignItems="center">
                                            <FormInput
                                                label="Notes"
                                                multiline
                                                numberOfLines={2}
                                                inputWidth="100%"
                                                name="additionalNotes"
                                                variant="solid"
                                                type="text"
                                                error={
                                                    this.state.formErrors
                                                        ? this.state.formErrors[
                                                              'additionalNotes'
                                                          ]
                                                        : null
                                                }
                                                value={
                                                    workflow.isReadOnly
                                                        ? Notes
                                                        : this.state.formData
                                                        ? this.state.formData[
                                                              'additionalNotes'
                                                          ]
                                                        : null
                                                }
                                                variant={
                                                    workflow.isReadOnly
                                                        ? 'outline'
                                                        : 'solid'
                                                }
                                                inline={
                                                    workflow.isReadOnly ? true : false
                                                }
                                            />
                                        </Box>

                                        <Box
                                            width={1 / 2}
                                            mx="auto"
                                            alignItems="center"></Box>
                                    </Box>
                                </Box>
                            </>
                        ) : (
                            <FilesList
                                title="NEW ATTACHMENTS"
                                formErrors={this.state.fileErrors}
                                files={selectedFilesIds.map((id) => selectedFiles[id])}
                                onChange={(value, id) => {
                                    this.setState({
                                        selectedFiles: {
                                            ...selectedFiles,
                                            [id]: {
                                                ...selectedFiles[id],
                                                DocumentType: parseInt(value),
                                            },
                                        },
                                    });
                                }}
                                onRemove={(value, id) => this.removeFile(id)}
                            />
                        )}
                        <Box flexDirection="row" justifyContent="center">
                            <Box ml="5%" width={1 / 2}>
                                {tasknotes && tasknotes.length > 0 && (
                                    <TaskNotes taskNotes={tasknotes} />
                                )}
                            </Box>
                            <Box width={1 / 2} mx="auto" alignItems="center" />
                        </Box>
                        <Box {...showButtons}>
                            <Flex
                                justifyEnd
                                alignCenter
                                style={{
                                    paddingTop: 15,
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    paddingLeft: 10,
                                    paddingRight: 15,
                                    marginTop: 20,
                                    marginBottom: 10,
                                    marginHorizontal: 35,
                                }}>
                                <label
                                    htmlFor="file-upload"
                                    className="custom-file-upload">
                                    <MaterialIcons
                                        name="attach-file"
                                        size={18}
                                        color="#fff"
                                    />
                                </label>
                                <input
                                    id="file-upload"
                                    key={this.state.fileinputkey}
                                    type="file"
                                    onChange={this.selectFiles}
                                    multiple
                                />

                                <Button
                                    onPress={() =>
                                        this.setState({
                                            isReject: false,
                                            modalVisible: true,
                                        })
                                    }
                                    title="Release"
                                />
                            </Flex>
                        </Box>
                    </Box>
                </View>
            </ScrollView>
        );
    }
}

class Default extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const props = this.props;

        return (
            <DimensionAware
                render={(dimensions) => (
                    <Page
                        {...{
                            ...props,
                            width: getWindowWidth(dimensions),
                            height: getWindowHeight(dimensions),
                            marginBottom: 25,
                        }}
                    />
                )}
            />
        );
    }
}

const mapStateToProps = ({ workflows, customer, myTasks, staticData }) => {
    const { fetching, alert } = myTasks;
    const { bapi70CustData, deltas, fetching: fetchingCustomer } = customer;
    const { statusBarData, TasksStatusByTeamId, fetchingStatusBar } = workflows;
    const { data, fetching: staticFetching } = staticData;
    return {
        fetching: fetching || staticFetching || fetchingStatusBar || fetchingCustomer,
        alert,
        statusBarData,
        bapi70CustData,
        deltas,
        TasksStatusByTeamId,
        staticData: data,
    };
};

export default connect(mapStateToProps, {
    extendReleaseChecklist,
    getCustomerFromSAP,
    getStatusBarData,
    getStaticRequest,
})(Default);

const styles = StyleSheet.create({
    progressIndicator: {
        flex: 1,
        paddingBottom: 5,
        flexDirection: 'row-reverse',
        alignItems: 'flex-end',
    },
    statusText: {
        fontSize: 15,
        color: '#1D4289',
        fontFamily: 'Poppins',
        textAlign: 'center',
        marginTop: 20,
    },
});
