import { all, call, takeLatest, takeEvery, fork, put, select } from 'redux-saga/effects';
import {
    getWorkflowsSuccess,
    getWorkflowsFailed,
    setStatusBarData,
    setFunctionalGroupData,
    setTeamUsers,
    assignUserSuccess,
    unAssignUserSuccess,
} from '../../appRedux/actions/Workflow.js';

import {
    SUCCESS_BGCOLOR,
    FAILED_BGCOLOR,
    GET_WORKFLOW,
    GET_STATUS_BAR_DATA,
    GET_FUCTIONAL_GROUP_DATA,
    OTHER_REQUEST,
    GET_TEAM_USERLIST,
    ASSIGN_USER,
    ASSIGN_USER_SUCCESS,
    UN_ASSIGN_USER,
} from '../../constants/ActionTypes';

import { ajaxPostRequest, endpoints, formatRequestorName } from './config';
import { mapKeys, upperFirst, camelCase } from 'lodash';
import {
    otherRequest,
    otherRequestSuccess,
    otherRequestFailed,
    showMessage as showToast,
} from '../actions';
import { UploadFiles, fetchCustomerDunsFields } from './Customer';
import { AlternateWorkflowTeamTypeId } from '../../constants/WorkflowEnums.js';

const camelCaseToPascalCase = (str = '') => upperFirst(camelCase(str));

const camelCaseHandler = {
    get: (target, prop) => target[camelCase(prop)] || target[camelCaseToPascalCase(prop)],
    set: (target, prop, value) =>
        target[camelCase(prop)]
            ? (target[prop] = value)
            : (target[camelCaseToPascalCase(prop)] = value),
};

function merge(obj1, obj2) {
    let answer = {};
    for (let key in obj1) {
        if (answer[key] === undefined || answer[key] === null) answer[key] = obj1[key];
    }
    for (let key in obj2) {
        if (answer[key] === undefined || answer[key] === null) answer[key] = obj2[key];
    }
    return answer;
}

export function* getWorkflows(data) {
    const userId = localStorage.getItem('userId');

    var resp = { msg: '', color: '#FFF' };
    const url = endpoints.getMyTasks;
    try {
        var jsonBody = data.payload || {
            WorkFlowEngineRequestType: 3,
            WorkFlowTaskFilterRequest: {
                UserId: userId,
                WorkflowTaskOperationType: 3,
                Pager: { From: 0, Size: 10 },
            },
        };

        const result = yield call(ajaxPostRequest, url, jsonBody);

        if (result.IsSuccess) {
            let resultData = {
                userTasks: result.ResultData,
                TotalItems: result.TotalItems,
            };
            if (data.payload && data.payload.WorkflowRequestFilter) {
                resultData['filters'] = data.payload.WorkflowRequestFilter;
            }
            yield put(getWorkflowsSuccess(resultData));
        } else {
            resp = { msg: 'No data found', color: FAILED_BGCOLOR };
            yield put(getWorkflowsFailed(resp));
        }
    } catch (error) {
        resp = { msg: error, color: FAILED_BGCOLOR };
        yield put(getWorkflowsFailed(resp));
    }
}

export function* getTeamUserList(data) {
    const url = endpoints.getMyTeamUsers;
    try {
        var jsonBody = data.payload;

        const result = yield call(ajaxPostRequest, url, jsonBody);
        if (result.IsSuccess) yield put(setTeamUsers(result.ResultData.UsersPerTeam));
        else yield put(setTeamUsers([]));
    } catch (error) {
        yield put(setTeamUsers([]));
    }
}
export function* assignUserToTask(data) {
    const url = endpoints.assignUserToTask;
    try {
        var jsonBody = data.payload.postdata;
        const result = yield call(ajaxPostRequest, url, jsonBody);
        if (result.IsSuccess) {
            let resp = {
                wf: data.payload.wfdata || [],
                msg: 'Successfully Assigned User',
                color: SUCCESS_BGCOLOR,
            };

            yield put(assignUserSuccess(resp));
        } else {
            let resp = {
                msg: 'Unable to assign user , Please try later',
                color: FAILED_BGCOLOR,
            };
            yield put(assignUserSuccess(resp));
        }
    } catch (error) {
        let resp = { msg: error, color: FAILED_BGCOLOR };
        yield put(assignUserSuccess(resp));
    }
}

export function* unassignFromTask(data) {
    const url = endpoints.unAssignUserFromTask;
    try {
        var jsonBody = data.payload.postdata;
        // console.log('jsonBody', jsonBody, url);
        const result = yield call(ajaxPostRequest, url, jsonBody);
        if (result.IsSuccess) {
            let resp = {
                wf: data.payload.wfdata || [],
                msg: 'Successfully UnAssigned User',
                color: SUCCESS_BGCOLOR,
            };

            yield put(unAssignUserSuccess(resp));
        } else {
            let resp = {
                msg: 'Unable to unassign user , Please try later',
                color: FAILED_BGCOLOR,
            };
            yield put(unAssignUserSuccess(resp));
        }
    } catch (error) {
        let resp = { msg: error, color: FAILED_BGCOLOR };
        yield put(unAssignUserSuccess(resp));
    }
}

export function* getStatusBarDetails(data) {
    const userId = localStorage.getItem('userId');
    // console.log(data);
    const parseCredit = data.parseCredit || false;

    var resp = { msg: '', color: '#FFF' };
    var wfId = data.payload.workflowId;
    var taskId = data.payload.taskId ? data.payload.taskId : '';
    const url = endpoints.getStatusBarDetails;

    try {
        var jsonBody = {
            UserId: userId,
            Workflowid: data.payload.wfId || wfId,
            TaskId: taskId,
        };
        const result = yield call(ajaxPostRequest, url, jsonBody);
        if (result.IsSuccess) {
            const WorkflowTaskStatus = result.ResultData.WorkflowTaskStatus;

            let taskStatusArr = [];
            let creditStatus = null;
            let alternateStatus = [];
            if (WorkflowTaskStatus.length > 0) {
                taskStatusArr = [];

                WorkflowTaskStatus.reverse().map((status) => {
                    const teamId = parseInt(status.TeamId);
                    // console.log(teamId, status);

                    if (teamId <= 9) {
                        let alternateStatusData = alternateStatus.find(function (data) {
                            if (data.TeamId == teamId) return data;
                        });

                        if (alternateStatusData) {
                            taskStatusArr.push(alternateStatusData);
                        } else {
                            taskStatusArr.push(status);
                        }
                    } else {
                        if (status.WorkflowTaskStateTypeId > 0)
                            alternateStatus.push({
                                ...status,
                                TeamId: AlternateWorkflowTeamTypeId[teamId],
                                OriginalTeamId: teamId,
                            });
                    }

                    // if (teamId <= 9) {
                    //     if (teamId === 4 && creditStatus)
                    //         taskStatusArr.push(creditStatus);
                    //     else taskStatusArr.push(status);
                    // } else {
                    //     if (status.WorkflowTaskStateTypeId > 0)
                    //         creditStatus = {
                    //             ...status,
                    //             TeamId: 4,
                    //             OriginalTeamId: teamId,
                    //         };
                    // }
                });
            }

            // console.log(taskStatusArr);

            const taskStatusData = taskStatusArr.reverse();
            let normalizedTaskStatus = mapKeys(taskStatusData, 'TeamId');

            // console.log(taskStatusData);
            yield put(
                setStatusBarData({
                    all: taskStatusData,
                    byTeamId: normalizedTaskStatus,
                })
            );
        } else {
            resp = { msg: 'No data found', color: FAILED_BGCOLOR };
            yield put(getWorkflowsFailed(resp));
        }
    } catch (error) {
        resp = { msg: error, color: FAILED_BGCOLOR };
        yield put(getWorkflowsFailed(resp));
    }
}

export function* getFunctionalGroupDetails({ payload }) {
    const userId = localStorage.getItem('userId');
    var resp = { msg: '', color: '#FFF' };
    var workflowId = payload.workflowId;
    var fuctionalGroup = payload.fuctionalGroup;
    var TaskId = payload.taskId;
    const url = endpoints.getFunctionalGroupDetails;

    try {
        var jsonBody = {
            WorkflowId:
                payload.wfId !== null && payload.wfId !== undefined && payload.wfId !== ''
                    ? payload.wfId
                    : workflowId,
            UserId: userId,
            FunctionalGroup: fuctionalGroup,
            TaskId,
            SystemTypeId: payload.systemTypeId || 1,
        };

        const result = yield call(ajaxPostRequest, url, jsonBody);

        const { Customer, CustomerMaster } = result.ResultData;
        if (CustomerMaster) {
            const {
                TaxNumber: CM_TaxNumber,
                DunsNumber: CM_DunsNumber,
                SicCode4: CM_SicCode4,
                SicCode6: CM_SicCode6,
                SicCode8: CM_SicCode8,
                NaicsCode: CM_NaicsCode,
                VatRegNo: CM_VatRegNo,
            } = CustomerMaster;
            const {
                TaxNumber,
                DunsNumber,
                SicCode4,
                SicCode6,
                SicCode8,
                NaicsCode,
                VatRegNo,
            } = Customer;
            result.ResultData.Customer = {
                ...Customer,
                ...merge(
                    {
                        TaxNumber: CM_TaxNumber,
                        DunsNumber: CM_DunsNumber,
                        SicCode4: CM_SicCode4,
                        SicCode6: CM_SicCode6,
                        SicCode8: CM_SicCode8,
                        NaicsCode: CM_NaicsCode,
                        VatRegNo: CM_VatRegNo,
                    },
                    {
                        TaxNumber,
                        DunsNumber,
                        SicCode4,
                        SicCode6,
                        SicCode8,
                        NaicsCode,
                        VatRegNo,
                    }
                ),
            };
        }
        const { RequestorName } = result.ResultData;
        if (RequestorName) {
            result.ResultData.RequestorName = formatRequestorName(RequestorName);
        }
        if (result.IsSuccess) {
            const { Credit: CreditObj, ...rest } = result.ResultData;
            yield put(
                setFunctionalGroupData({
                    ...rest,
                    Credit: CreditObj ? new Proxy(CreditObj, camelCaseHandler) : null,
                })
            );
            var dunsdata = {};
            dunsdata['payload'] = {
                UserId: localStorage.getItem('userId'),
                DunsNumber: result.ResultData.Customer.DunsNumber || null,
            };
            console.log('dunsdata', dunsdata);
            yield* fetchCustomerDunsFields(dunsdata);
        } else {
            resp = { msg: 'No data found', color: FAILED_BGCOLOR };
            yield put(setFunctionalGroupData(result.ResultData));
            var dunsdata = {};
            dunsdata['payload'] = {
                UserId: localStorage.getItem('userId'),
                DunsNumber: result.ResultData.Customer.DunsNumber || null,
            };
            console.log('dunsdata', dunsdata);
            yield* fetchCustomerDunsFields(dunsdata);
        }
    } catch (error) {
        resp = { msg: error, color: FAILED_BGCOLOR };
        yield put(getWorkflowsFailed(resp));
    }
}

export function* OtherRequest({ payload }) {
    const { history, data: jsonBody, files } = payload;
    const url = endpoints.CreateOtherCustomerApi;
    var resp = { msg: '', color: '#FFF' };
    try {
        yield put(
            showToast({
                msg: 'Saving request',
                color: '#2980b9',
            })
        );
        const createResult = yield call(ajaxPostRequest, url, jsonBody);
        if (createResult.IsSuccess) {
            window.scrollTo(0, 0);

            let result = { IsSuccess: true };
            if (files && files.length > 0) {
                yield put(
                    showToast({
                        msg: 'Uploading files',
                        color: '#2980b9',
                    })
                );

                yield* UploadFiles(files, jsonBody.WorkflowId, true);
            }

            if (result.IsSuccess) {
                yield put(
                    otherRequestSuccess({
                        msg: 'Successfully created new customer',
                        color: '#27ae60',
                    })
                );
                localStorage.removeItem('SFDCData');
                history.push({
                    pathname: '/my-requests',
                });
            } else {
                resp = {
                    msg: 'Error uploading files',
                    color: FAILED_BGCOLOR,
                };
                yield put(otherRequestFailed(resp));
                window.scrollTo(0, 0);
            }
        } else {
            resp = {
                msg: `Internal Error: \nCould not create customer`,
                color: FAILED_BGCOLOR,
            };
            yield put(showToast(resp));
            yield put(otherRequestFailed(resp));
        }

        // const jsonBody = data;
        // const result = yield call(ajaxPostRequest, url, jsonBody);
        // if (result.IsSuccess) {
        //     yield put(createCustomerSuccess(result.ResultData));
        //     history.push({
        //         pathname: '/my-requests',
        //     });
        // }
    } catch (error) {
        // resp = { msg: error, color: FAILED_BGCOLOR };
        // yield put(getWorkflowsFailed(resp));

        resp = {
            msg: error.message,
            color: FAILED_BGCOLOR,
        };
        yield put(otherRequestFailed(resp));
    }
}

export function* getWorkflowsData() {
    yield takeLatest(GET_WORKFLOW, getWorkflows);
}

export function* teamUserlist() {
    yield takeLatest(GET_TEAM_USERLIST, getTeamUserList);
}

export function* assignUserTask() {
    yield takeLatest(ASSIGN_USER, assignUserToTask);
}

export function* getStatusData() {
    yield takeLatest(GET_STATUS_BAR_DATA, getStatusBarDetails);
}

export function* getGlobalMDMRecords() {
    yield takeLatest(GET_FUCTIONAL_GROUP_DATA, getFunctionalGroupDetails);
}

export function* createOtherRequest() {
    yield takeLatest(OTHER_REQUEST, OtherRequest);
}

export function* unassignUserTask() {
    yield takeEvery(UN_ASSIGN_USER, unassignFromTask);
}

const workflowSagas = function* rootSaga() {
    yield all([
        fork(getWorkflowsData),
        fork(teamUserlist),
        fork(getStatusData),
        fork(getGlobalMDMRecords),
        fork(createOtherRequest),
        fork(assignUserTask),
        fork(unassignUserTask),
    ]);
};
export default workflowSagas;
