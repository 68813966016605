import React from 'react';
import {
    ScrollView,
    View,
    TouchableOpacity,
    ActivityIndicator,
    StyleSheet,
} from 'react-native';
import {
    DimensionAware,
    getWindowHeight,
    getWindowWidth,
} from 'react-native-dimension-aware';
import { Colors, StyledText } from '../../../theme/Index';
import { Flex, Button, Box, Text } from '../../../components/common';
import { FormInput } from '../../../components/form';
import GlobalMdmFields from '../../../components/GlobalMdmFields';
import DynamicSelect from '../../../components/DynamicSelect';
import { CheckBoxItem } from '../../../components/CheckBoxItem';
import { debounce } from 'lodash';
import { parseAndSanitise, yupAllFieldsValidation } from '../../../constants/utils';
import {
    FieldValidationRoleType,
    mytaskCustomerMasterRules,
    mytaskOlympusCustomerMasterRules,
    ptmnCustomerMasterRules,
    rejectRules,
} from '../../../constants/FieldRules';
import {
    saveApolloMyTaskCustomerMaster,
    fetchDuns,
    getStatusBarData,
    getFunctionalGroupData,
    getStaticRequest,
    removeMessage,
} from '../../../appRedux/actions/';
import { connect } from 'react-redux';
import {
    fetchCustomerMasterDropDownData,
    fetchCreateTeamRoleFieldMapping,
    fetchOlympusFieldMapping,
} from '../../../redux/DropDownDatas';
import Loading from '../../../components/Loading';
import FlashMessage, { FlashMessages } from '../../../components/FlashMessage';
import {
    RoleType,
    PtmnRoleType,
    SalesOrgType,
    SystemType,
    DistributionChannelType,
    DivisionType,
    CompanyCodeType,
} from '../../../constants/WorkflowEnums';
import MultiColorProgressBar from '../../../components/MultiColorProgressBar';
import Modal from 'modal-enhanced-react-native-web';
import * as moment from 'moment';
import FilesList from '../../../components/FilesList';
import { MaterialIcons } from '@expo/vector-icons';
import idx from 'idx';
import { Table, Row, Rows } from '../../../components/table';
import CollapsibleTable from '../../../components/table/CollapsibleTable';

class Page extends React.Component {
    constructor(props) {
        super(props);
        const editableProp = {
            inline: false,
            variant: 'solid',
            onChange: this.onFieldChange,
        };
        let { state: workflow } = this.props.location;
        let isWorkFlowReadOnly = this.props.location.state.isReadOnly;
        // let isWorkFlowReadOnly=false
        this.state = {
            isWorkFlowReadOnly: this.props.location.state.isReadOnly,
            WorkflowId: this.props.location.state.WorkflowId,
            TaskId: this.props.location.state.TaskId,
            loading: this.props.fetching,
            functionalGroupDetails: this.props.functionalGroupDetails,
            dropDownDatas: {},
            teamRoleFieldMapping: {},
            formData: {
                RejectionButton: false,
                displayINCOT2: isWorkFlowReadOnly ? true : false,
                display_LN: isWorkFlowReadOnly ? true : false,
                PaymentHistoryRecord: true,
                OrderCombination: true,
                TaxClassificationTypeId: 2,
                AccountStatementTypeId: 2,
                SortKey: 1,
                PaymentMethods: 'C',
                AcctgClerk: '01',
                SortKey: 1,
                CustPriceProc: '1',
                DeliveryPriority: '2',
                ShippingConditions: '5',
                MaxPartialDeliveries: '9',
                OrderProbab: '100',
                CompleteDeliveryRequired: false,
                PartialDeliveryPerItem: 1,
                CustomerStatGroup: 'S: Vyaire Standard',
                TransportationZone: '60',
            },
            formErrors: {},
            inputPropsForDefaultRules: { CustomerGroupTypeId: editableProp },
            selectedFiles: {},
            selectedFilesIds: [],
            files: [],
            fileinputkey: Date.now(),
            isInternationalVersionVisible: false,
        };

        this.fetchDunsFields = debounce(this.props.fetchDuns, 200);
    }

    componentDidMount() {
        let { state: wf } = this.props.location;
        let postJson = {
            workflowId: wf.WorkflowId,
            fuctionalGroup: 'customermaster',
            taskId: wf.TaskId,
            systemTypeId: wf.SystemTypeId,
        };

        this.props.getStaticRequest([
            15,
            30,
            26,
            17,
            42,
            43,
            49,
            42,
            43,
            5,
            52,
            53,
            54,
            55,
            56,
            57,
            58,
            59,
            60,
            61,
            62,
            63,
            64,
            65,
            40,
            81,
            66,
            67,
            68,
            69,
            70,
            71,
            72,
            73,
            74,
            75,
            76,
            77,
            78,
            79,
            80,
            81,
            82,
            83,
            84,
        ]);

        this.props.getStatusBarData(postJson);
        this.props.getFunctionalGroupData(postJson);
        fetchCustomerMasterDropDownData().then((res) => {
            const data = res;
            this.setState({ dropDownDatas: data });
        });

        fetchOlympusFieldMapping().then((resp) => {
            const data = resp;
            this.setState({ teamRoleFieldMapping: data });
        });
    }

    componentWillReceiveProps(newProps) {
        if (
            newProps.functionalGroupDetails != this.props.functionalGroupDetails &&
            !this.state.isWorkFlowReadOnly
        ) {
            var customerdata = newProps.functionalGroupDetails.Customer;

            var result = this.validateFromSourceData(customerdata);
            let formData = this.state.formData;
            if (
                customerdata &&
                customerdata.Country == 'IT' &&
                customerdata.CustomerClassTypeId == 2
            ) {
                formData['CustPriceProc'] = '2';
            } else {
                formData['CustPriceProc'] = '1';
            }

            this.setState({
                formData: {
                    ...this.state.formData,
                    ...result.newStateValue,
                    Role: this.props.staticData
                        ? this.getDropdownValues(
                            newProps.functionalGroupDetails.Customer.RoleTypeId,
                            newProps.staticData.OlympusRoleType
                        )
                        : '',
                    RoleTypeId: newProps.functionalGroupDetails.Customer.RoleTypeId,
                    DunsNumber: newProps.functionalGroupDetails.Customer.DunsNumber,
                    MarketingSegmentationTypeId:
                        newProps.functionalGroupDetails?.CustomerMaster?.MarketingSegmentationTypeId ||
                        newProps.functionalGroupDetails.Customer
                            .MarketingSegmentationTypeId,
                },
                inputPropsForDefaultRules: {
                    ...this.state.inputPropsForDefaultRules,
                    ...result.newStyleProps,
                },
            });
        }
    }

    setFormErrors = (errors) => {
        const { formErrors } = this.state;
        this.setState({ formErrors: errors }, () => window.scrollTo(0, 0));
    };

    onDunsNewInput = (value, e) => {
        const { name } = e.target;
        this.fetchDunsFields({
            UserId: localStorage.getItem('userId'),
            DunsNumber: value,
        });
    };

    onFieldChange = (value, e) => {
        const { name } = e.target;
        const { formData: prevFormData } = this.state;
        if (name === 'DunsNumber' && value.length > 0) {
            const { isDunsValid } = this.state;
            const {
                validity: { valid },
            } = e.target;
            if (valid !== isDunsValid)
                this.setState({
                    isDunsValid: valid,
                    formErrors: {
                        ...this.state.formErrors,
                        isDunsValid: !valid ? ' only numbers (0-9) are allowed.' : null,
                    },
                });
        } else if (value.length <= 0 && this.state.formErrors['isDunsValid']) {
            this.setState({
                formErrors: {
                    ...this.state.formErrors,
                    isDunsValid: null,
                },
            });
        }
        var customerdata = this.props.functionalGroupDetails.Customer;
        let formData = this.state.formData;
        this.setState({
            test: { ...this.state.test, [name]: value },
            formData: {
                ...this.state.formData,
                [name]: value,
            },
        });
    };

    parseAndHandleFieldChange = (value, e) => {
        const { name } = e.target;
        const val = parseAndSanitise(value, 10);
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: val,
            },
        });
    };

    setBulkFormDataUpdate = (keyValdict) => {
        this.setState({
            formData: {
                ...this.state.formData,
                ...keyValdict,
            },
        });
    };
    setFormDataValues = (name, value) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: value,
            },
        });
    };

    setInputPropsForDefaultRules = (field_name, property) => {
        this.setState({
            inputPropsForDefaultRules: {
                ...this.state.inputPropsForDefaultRules,
                [field_name]: property,
            },
        });
    };
    // display or set input/dropdowns values based on rules

    validateFromSourceData = (source_data) => {
        const readOnlyDropDown = { disabled: true };
        const newStateValue = {},
            newStyleProps = {};
        //check License Number
        let d_LN_RegionsList = [
            'DE',
            'FL',
            'GA',
            'HI',
            'IL',
            'IN',
            'KS',
            'MA',
            'ME',
            'MN',
            'MO',
            'NC',
            'ND',
            'NE',
            'NM',
            'OH',
            'OK',
            'RI',
            'SD',
            'VT',
            'WA',
            'WV',
        ];
        if (
            source_data.RoleTypeId === 1 ||
            source_data.RoleTypeId === 2 ||
            source_data.RoleTypeId === 5 ||
            source_data.RoleTypeId === 6
        ) {
            newStateValue['display_LN'] = true;
            if (source_data.RoleTypeId === 5) {
                newStateValue['License'] = 'R-SALES REP EXEMPT';
                newStateValue['LicenseExpDate'] = '9999-12-31';
            } else if (source_data.Country != 'US') {
                newStateValue['License'] = 'I-INTERNATIONAL EXEMPT';
                newStateValue['LicenseExpDate'] = '9999-12-31';
            } else if (d_LN_RegionsList.includes(source_data.Region)) {
                newStateValue['License'] = 'S-IN STATE EXEMPT APPROVAL SM';
                newStateValue['LicenseExpDate'] = '9999-12-31';
            }
        }
        //check transportation zone
        let d_TransporationZone_RegionList = ['NS', 'NT', 'NU', 'PE', 'SK', 'YT'];
        if (source_data.Country === 'US' || source_data.Country === 'PR') {
            newStateValue[
                'TransporationZone'
            ] = source_data.PostalCode.toString().substring(0, 3);
        } else if (
            source_data.Country === 'CA' &&
            d_TransporationZone_RegionList.includes(source_data.Region)
        ) {
            newStateValue['TransporationZone'] = 'INTL';
        } else if (source_data.Country === 'CA') {
            newStateValue['TransporationZone'] = source_data.Region;
        } else {
            newStateValue['TransporationZone'] = 'INTL';
        }

        //check price list
        if (source_data.Country != 'US') {
            newStateValue['PriceListTypeId'] = '5';
            // newStyleProps['PriceListTypeId'] = readOnlyDropDown; -ITMDMK1707
            newStyleProps['PriceListTypeId'] = { disabled: false };
        } else {
            newStateValue['PriceListTypeId'] = 0;
            newStyleProps['PriceListTypeId'] = { disabled: false };
        }

        //check Customer group --as per #ITMDMK1756 customergroup is editable in all cases.
        if (source_data.CategoryTypeId != undefined) {
            let categoryTypeid = parseAndSanitise(source_data.CategoryTypeId);
            if (categoryTypeid === 2) {
                //if self-distributor
                newStateValue['CustomerGroupTypeId'] = '5';
                newStyleProps['CustomerGroupTypeId'] = { disabled: false }; // readOnlyDropDown;
            } else if (categoryTypeid === 3 || categoryTypeid === 6) {
                //if oem or kitter
                newStateValue['CustomerGroupTypeId'] = '9';
                newStyleProps['CustomerGroupTypeId'] = { disabled: false }; // readOnlyDropDown;;
            } else if (categoryTypeid === 7) {
                // if dropship
                newStateValue['AccountTypeId'] = '3';
                // newStyleProps['AccountTypeId'] = readOnlyDropDown; -ITMDMK1707
                newStyleProps['AccountTypeId'] = { disabled: false };
                newStateValue['CustomerGroupTypeId'] = '11';
                newStyleProps['CustomerGroupTypeId'] = { disabled: false }; // readOnlyDropDown;;
            }
        }
        //check shipping conditions
        if (source_data.Country != 'US') {
            newStateValue['ShippingConditionsTypeId'] = '2';
            // newStyleProps['ShippingConditionsTypeId'] = readOnlyDropDown; ITMDMK1707
            newStyleProps['ShippingConditionsTypeId'] = { disabled: false };
        } else {
            newStateValue['ShippingConditionsTypeId'] = '1';
            // newStyleProps['ShippingConditionsTypeId'] = readOnlyDropDown;
            newStyleProps['ShippingConditionsTypeId'] = { disabled: false };
        }

        if (source_data.RoleTypeId == 5) {
            newStyleProps['ShippingConditionsTypeId'] = { disabled: false };
        }

        return { newStateValue, newStyleProps };
    };

    handleFormSubmission = (schema) => {
        let {
            TaskId,
            WorkflowId,
            formData,
            selectedFiles,
            selectedFilesIds,
        } = this.state,
            castedFormData = {};

        formData = {
            ...formData,
            ...this.props.dunsFields,
        };

        try {
            const WorkflowTaskModel = {
                RejectReason: formData['RejectionButton']
                    ? formData['RejectionReason']
                    : '',
                TaskId: TaskId,
                UserId: localStorage.getItem('userId'),
                WorkflowId: WorkflowId,
                WorkflowTaskOperationType: !formData['RejectionButton'] ? 1 : 2,
            };
            if (!formData['RejectionButton']) {
                castedFormData = schema.cast(formData);
            } else {
                castedFormData = formData;
            }
            delete castedFormData.RejectionButton;
            delete castedFormData.displayINCOT2;
            delete castedFormData.display_LN;
            delete castedFormData.Role;
            let systemFields = this.props.functionalGroupDetails
                ? this.props.functionalGroupDetails.Customer
                : {};
            let internationalVersionData = systemFields.InternationalVersionDomain
                ? systemFields.InternationalVersionDomain
                : {};
            let postData = {};
            const { teamRoleFieldMapping } = this.state;
            const CustomerMasterTeamFields =
                idx(teamRoleFieldMapping, (_) => _.OlympusCustomerMasterFields) || [];
            const RoleTypeId = this.props.functionalGroupDetails.Customer.RoleTypeId;
            const SystemTypeId = this.props.functionalGroupDetails.Customer.SystemTypeId;
            const allowedFieldList = CustomerMasterTeamFields.filter((item) => {
                return (
                    item.RoleTypeId === RoleTypeId && item.SystemTypeId === SystemTypeId
                );
            });
            let allowedFields = undefined;
            if (allowedFieldList && allowedFieldList.length > 0) {
                allowedFields = allowedFieldList[0].Fields;
            }
            // if (this.isSystemPointman())
            postData['formData'] = {
                WorkflowTaskModel,
                SearchTerm1: allowedFields.includes('SearchTerm1')
                    ? undefined
                    : formData.SearchTerm1,
                SearchTerm2: allowedFields.includes('SearchTerm2')
                    ? undefined
                    : formData.SearchTerm2,
                IndustryCodeTypeId: allowedFields.includes('IndustryCode1')
                    ? undefined
                    : formData.IndustryCode,
                SortKey: allowedFields.includes('SortKey') ? undefined : formData.SortKey,
                CustomerPriceProcTypeId: allowedFields.includes('CustomerPriceProc')
                    ? undefined
                    : formData.CustPriceProc,
                CustomerGroupTypeId: allowedFields.includes('CustomerGroup')
                    ? undefined
                    : formData.CustomerGroup,
                DeliveryPriorityTypeId: allowedFields.includes('DeliveryPriority')
                    ? undefined
                    : formData.DeliveryPriority,
                IncoTerms2: allowedFields.includes('IncoTerms2')
                    ? undefined
                    : formData.IncoTerms2,
                OrderCombination: allowedFields.includes('OrderCombination')
                    ? undefined
                    : formData.OrderCombination,
                ShippingConditionsTypeId: allowedFields.includes('ShippingConditions')
                    ? undefined
                    : formData.ShippingConditions,
                MaxPartialDeliveriesTypeId: allowedFields.includes('MaxPartialDeliveries')
                    ? undefined
                    : formData.MaxPartialDeliveries,
                OrderProbab: allowedFields.includes('OrderProbab')
                    ? undefined
                    : formData.OrderProbab,
                CompleteDeliveryRequired: allowedFields.includes(
                    'CompleteDeliveryRequired'
                )
                    ? undefined
                    : formData.CompleteDeliveryRequired,
                PartialDeliveryPerItemTypeId: allowedFields.includes(
                    'PartialDeliveryPerItem'
                )
                    ? undefined
                    : formData.PartialDeliveryPerItem,
                DeliveringPlantTypeId: allowedFields.includes('DeliveringPlant')
                    ? undefined
                    : formData.DeliveringPlant,
                TransportationZone: allowedFields.includes('TransportationZone')
                    ? undefined
                    : formData.TransportationZone,
                PaymentHistoryRecord: allowedFields.includes('PaymentHistoryRecord')
                    ? undefined
                    : formData.PaymentHistoryRecord,
                AcctAssignmentGroupTypeId: allowedFields.includes('AcctAssignmentGroup')
                    ? undefined
                    : systemFields.AcctAssignmentGroupTypeId,
                TaxClassificationTypeId: allowedFields.includes('TaxClassification')
                    ? undefined
                    : systemFields.TaxClassificationTypeId,
                PriorityTypeId: allowedFields.includes('Priority')
                    ? undefined
                    : systemFields.PriorityTypeId,
                PriceGroupTypeId: allowedFields.includes('PriceGroup')
                    ? undefined
                    : formData.PriceGroup,
                AdditionalNotes: formData.AdditionalNotes,
                CustomerStatGroup: allowedFields.includes('CustomerStatGroup')
                    ? undefined
                    : formData.CustomerStatGroup,
                MdmNumberToGroup: formData.MdmNumberToGroup || null,
                MarketingSegmentationTypeId: formData['MarketingSegmentationTypeId'],
            };
            // else
            //     postData['formData'] = {
            //         WorkflowTaskModel,
            //         ...castedFormData,
            //     };
            // postData['files'] = selectedFilesIds.map((id) => selectedFiles[id]);

            // console.log(this.props, this.state)

            // console.log(postData['formData'], "1st obj")
            // Object.keys(postData['formData']).forEach(dataToSend => {

            //     allowedFields.some(fieldList => {
            //         console.log(fieldList)
            //         if (dataToSend.toLowerCase().includes(fieldList.toLowerCase())) {
            //             delete postData['formData'][dataToSend]
            //             return true
            //         }
            //     })
            // })
            // console.log(postData['formData'], 'filteredData==============', allowedFields)

            this.props.saveApolloMyTaskCustomerMaster(postData);
            this.resetForm();
            this.scrollToTop();
        } catch (error) {
            console.log(error);
        }
    };

    isSystemPointman = () => {
        const {
            functionalGroupDetails: { Customer: globalMdmDetail = {} },
        } = this.props;
        const isPointman = globalMdmDetail && globalMdmDetail.SystemTypeId === 3;

        return isPointman;
    };
    onSubmit = (event, reject, _schema) => {
        const {
            functionalGroupDetails: { Customer: globalMdmDetail = {} },
        } = this.props;

        const schema = this.isSystemPointman() ? ptmnCustomerMasterRules : _schema;
        let isContractEnabled = false;
        //check iff contract fields to be displayed or not
        if (globalMdmDetail && globalMdmDetail.CategoryTypeId != undefined) {
            let categoryTypeid = parseAndSanitise(globalMdmDetail.CategoryTypeId);
            if (
                categoryTypeid === 1 ||
                categoryTypeid === 2 ||
                categoryTypeid === 3 ||
                categoryTypeid === 6
            ) {
                isContractEnabled = true;
            }
        }

        let { formData, selectedFilesIds, selectedFiles } = this.state;
        let fileErrors = {};
        let errors = false;
        selectedFilesIds.map((id) => {
            if (selectedFiles[id] && selectedFiles[id].DocumentType <= 0) {
                fileErrors[id] = 'Document Type Required for file';
                errors = true;
            }
        });

        this.setState({ fileErrors, isFileErrors: errors });
        console.log(this.state.formData);
        this.setState(
            {
                modalVisible: false,
                formData: {
                    ...this.state.formData,
                    RejectionButton: reject,
                },
            },
            () => {
                yupAllFieldsValidation(
                    {
                        ...this.state.formData,
                    },
                    schema,
                    (...rest) => {
                        if (this.state.isFileErrors === false)
                            this.handleFormSubmission(...rest);
                    },
                    this.setFormErrors
                );
            }
        );
    };

    scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    resetFormData = () => {
        Object.keys(this.state.formData).map((key) => {
            var myitem = key;
            if (
                ![
                    'OrderCombination',
                    'PaymentHistoryRecord',
                    'RejectionButton',
                    'displayINCOT2',
                    'display_LN',
                ].includes(myitem)
            ) {
                this.setState({
                    formData: {
                        ...this.state.formData,
                        [key]: '',
                    },
                });
            } else {
                if (!['displayINCOT2', 'display_LN'].includes(myitem)) {
                    this.setState({
                        formData: {
                            ...this.state.formData,
                            [key]: false,
                        },
                    });
                }
            }
        });
    };

    resetForm = () => {
        let { formErrors } = this.state;

        Object.keys(this.state.formErrors).forEach((key) => (formErrors[key] = ''));

        this.setState(
            {
                formErrors,
            },
            this.resetFormData
        );
    };

    selectFiles = (events) => {
        event.preventDefault();

        const { selectedFilesIds, selectedFiles } = this.state;
        const id = events.target.files[0].name;

        this.setState({
            selectedFiles: {
                ...selectedFiles,
                [id]: {
                    data: events.target.files[0],
                    DocumentName: events.target.files[0].name,
                    DocumentType: 0,
                },
            },
            selectedFilesIds: [...selectedFilesIds, id],
            filename: events.target.files[0].name,
            fileinputkey: Date.now(),
        });
    };

    removeFile = (id) => {
        event.preventDefault();

        var selectedFilesDict = this.state.selectedFiles;
        var selectedFilesIdsList = this.state.selectedFilesIds;
        // remove the existing state
        delete selectedFilesDict[id];
        selectedFilesIdsList = selectedFilesIdsList.filter((fileid) => fileid !== id);

        this.setState({
            selectedFiles: selectedFilesDict,
            selectedFilesIds: selectedFilesIdsList,
        });
    };

    _renderModalContent = () => {
        const { formData } = this.state;
        return (
            <Box
                my={4}
                mx="30%"
                backgroundColor="white"
                borderRadius="4px"
                style={{ padding: '3%' }}
                borderColor="rgba(0, 0, 0, 0.1)">
                <Text my={3} fontWeight="regular" color="lightBlue" fontSize={24} pl={4}>
                    Submit Workflow
                </Text>
                <Box px="0px" pb="22px" justifyContent="center">
                    <Box alignItems="center" justifyContent="center">
                        {!this.state.isReject ? (
                            <FormInput
                                label="Additional Notes"
                                multiline
                                numberOfLines={2}
                                maxLength={250}
                                name="AdditionalNotes"
                                type="text"
                                onChange={this.onFieldChange}
                                value={
                                    this.state.formData &&
                                    this.state.formData['AdditionalNotes']
                                }
                                variant={'solid'}
                            />
                        ) : (
                            <FormInput
                                label="Rejection Reason"
                                name="RejectionReason"
                                maxLength={250}
                                onChange={this.onFieldChange}
                                error={'*this field is required'}
                                multiline
                                numberOfLines={2}
                                type="text"
                                value={
                                    this.state.formData &&
                                    this.state.formData['RejectionReason']
                                }
                                variant="solid"
                            />
                        )}
                    </Box>
                    <Flex
                        justifyEnd
                        alignCenter
                        alignSelf="flex-end"
                        style={{
                            paddingTop: 15,
                            flexDirection: 'row',
                            alignItems: 'center',
                            paddingLeft: 10,
                            paddingRight: 15,
                            marginTop: 20,
                            marginBottom: 10,
                            marginHorizontal: 25,
                        }}>
                        <Button
                            onPress={(event) => {
                                if (this.state.isReject)
                                    this.setState({ isReject: false }, () =>
                                        this.onSubmit(event, true, rejectRules)
                                    );
                                else
                                    this.onSubmit(
                                        event,
                                        false,
                                        mytaskOlympusCustomerMasterRules
                                    );
                            }}
                            title="Submit"
                        />
                        <Button
                            title="Cancel"
                            onPress={() => this.setState({ modalVisible: null })}
                        />
                    </Flex>
                </Box>
            </Box>
        );
    };

    renderOlympusFields = (isPointman, globalMdmDetail, staticData, allowedFields) => {
        const { formData } = this.state;
        return (
            <>
                <FormInput
                    variant="outline"
                    label="Distribution Channel"
                    name="DistributionChannelTypeId"
                    hide={allowedFields && allowedFields.includes('DistributionChannel')}
                    value={
                        staticData &&
                        globalMdmDetail &&
                        this.getDropdownValues(
                            globalMdmDetail.DistributionChannelTypeId,
                            staticData && staticData.OlympusDistributionChannelType
                        )
                    }
                    isRequired
                    formErrors={
                        this.state.formErrors
                            ? this.state.formErrors['DistributionChannelTypeId']
                            : null
                    }
                    inline={true}
                />

                <FormInput
                    label="Division"
                    name="DivisionTypeId"
                    hide={allowedFields && allowedFields.includes('Division')}
                    variant="outline"
                    type="text"
                    value={
                        staticData &&
                        globalMdmDetail &&
                        this.getDropdownValues(
                            globalMdmDetail.DivisionTypeId,
                            staticData.OlympusDivisionType
                        )
                    }
                    inline={true}
                />

                <FormInput
                    variant="outline"
                    hide={
                        isPointman &&
                        allowedFields &&
                        allowedFields.includes('CompanyCode')
                    }
                    label="Company Code"
                    name="CompanyCodeTypeId"
                    inputProps={this.state.inputPropsForDefaultRules['CompanyCodeTypeId']}
                    value={
                        staticData &&
                        globalMdmDetail &&
                        this.getDropdownValues(
                            globalMdmDetail.CompanyCodeTypeId,
                            staticData && staticData.OlympusCompanyCodeType,
                            'Company code'
                        )
                    }
                    isRequired
                    formErrors={
                        this.state.formErrors
                            ? this.state.formErrors['CompanyCodeTypeId']
                            : null
                    }
                    onFieldChange={this.onFieldChange}
                    inline={true}
                />
                <FormInput
                    variant="outline"
                    label="Sold To"
                    name="SoldTo"
                    error={this.state.formErrors ? this.state.formErrors['SoldTo'] : null}
                    hide={
                        globalMdmDetail &&
                        globalMdmDetail.RoleTypeId != 2 &&
                        globalMdmDetail.RoleTypeId != 3 &&
                        globalMdmDetail.RoleTypeId != 4 &&
                        allowedFields &&
                        allowedFields.includes('SoldTo')
                    }
                    value={globalMdmDetail && globalMdmDetail['SoldTo']}
                    onChange={(value, element) => {
                        this.onFieldChange(value, element);
                    }}
                    inline={true}
                />
                <FormInput
                    variant="outline"
                    value={
                        staticData &&
                        globalMdmDetail &&
                        this.getDropdownValues(
                            globalMdmDetail.LanguageTypeId,
                            staticData && staticData.OlympusLanguageType
                        )
                    }
                    hide={allowedFields && allowedFields.includes('Language')}
                    label="Language"
                    name="Language"
                    isRequired
                    formErrors={
                        this.state.formErrors ? this.state.formErrors['Language'] : null
                    }
                    onFieldChange={this.onFieldChange}
                    inline={true}
                />

                <FormInput
                    variant="outline"
                    label="Customer Class"
                    name="CustomerClass"
                    value={
                        staticData &&
                        globalMdmDetail &&
                        this.getDropdownValues(
                            globalMdmDetail.CustomerClassTypeId,
                            staticData && staticData.OlympusCustomerClassType
                        )
                    }
                    formErrors={
                        this.state.formErrors
                            ? this.state.formErrors['CustomerClass']
                            : null
                    }
                    onFieldChange={this.onFieldChange}
                    hide={allowedFields && allowedFields.includes('CustomerClass')}
                    inline={true}
                />
                <FormInput
                    label="URI Type"
                    name="UriTypeId"
                    // inputProps={isSalesForce}
                    value={
                        staticData &&
                        globalMdmDetail &&
                        this.getDropdownValues(
                            globalMdmDetail.URITypeId,
                            this.props.staticData && this.props.staticData.OlympusURIType
                        )
                    }
                    formErrors={
                        this.state.formErrors ? this.state.formErrors['UriTypeId'] : null
                    }
                    onFieldChange={this.onFieldChange}
                    hide={allowedFields && allowedFields.includes('URIType')}
                    variant="outline"
                    readOnly={true}
                    inline
                />
                <FormInput
                    variant="outline"
                    label="Price Group"
                    name="PriceGroup"
                    value={
                        staticData &&
                        globalMdmDetail &&
                        this.getDropdownValues(
                            globalMdmDetail.PriceGroupTypeId,
                            staticData && staticData.OlympusPriceGroupType
                        )
                    }
                    formErrors={
                        this.state.formErrors ? this.state.formErrors['PriceGroup'] : null
                    }
                    hide={allowedFields && allowedFields.includes('PriceGroup')}
                    onFieldChange={this.onFieldChange}
                    inline={true}
                />
                <FormInput
                    variant="outline"
                    label="Delivering Plant"
                    name="DeliveringPlant"
                    value={
                        staticData &&
                        globalMdmDetail &&
                        this.getDropdownValues(
                            globalMdmDetail.DeliveringPlantTypeId,
                            staticData && staticData.OlympusDeliveringPlantType
                        )
                    }
                    formErrors={
                        this.state.formErrors
                            ? this.state.formErrors['DeliveringPlant']
                            : null
                    }
                    hide={allowedFields && allowedFields.includes('DeliveringPlant')}
                    onFieldChange={this.onFieldChange}
                    inline={true}
                />
                <FormInput
                    variant="outline"
                    label="Recon Account"
                    name="ReconAccount"
                    hide={allowedFields && allowedFields.includes('ReconAccount')}
                    value={
                        staticData &&
                        globalMdmDetail &&
                        this.getDropdownValues(
                            globalMdmDetail.ReconAccountTypeId,
                            staticData && staticData.OlympusReconAccountType
                        )
                    }
                    isRequired
                    formErrors={
                        this.state.formErrors
                            ? this.state.formErrors['ReconAccount']
                            : null
                    }
                    onFieldChange={this.onFieldChange}
                    inline={true}
                />
                <FormInput
                    variant="outline"
                    label="Terms of Payment"
                    name="TermsOfPayment"
                    hide={allowedFields && allowedFields.includes('TermsOfPayment')}
                    style={{ whiteSpace: 'pre-wrap' }}
                    multiline={true}
                    value={
                        staticData &&
                        globalMdmDetail &&
                        this.getDropdownValues(
                            globalMdmDetail.TermsOfPaymentTypeId,
                            staticData && staticData.OlympusTermsOfPaymentType
                        )
                    }
                    isRequired
                    formErrors={
                        this.state.formErrors
                            ? this.state.formErrors['TermsOfPayment']
                            : null
                    }
                    onFieldChange={this.onFieldChange}
                    inline={true}
                />
                <FormInput
                    variant="outline"
                    label="Currency"
                    name="Currency"
                    hide={allowedFields && allowedFields.includes('Currency')}
                    value={
                        staticData &&
                        globalMdmDetail &&
                        this.getDropdownValues(
                            globalMdmDetail.CurrencyTypeId,
                            staticData && staticData.OlympusCurrencyType
                        )
                    }
                    formErrors={
                        this.state.formErrors ? this.state.formErrors['Currency'] : null
                    }
                    onFieldChange={this.onFieldChange}
                    inline={true}
                />
                <FormInput
                    variant="outline"
                    label="Primary GPO"
                    name="PrimaryGpo"
                    hide={allowedFields && allowedFields.includes('PrimaryGPO')}
                    error={
                        this.state.formErrors ? this.state.formErrors['PrimaryGpo'] : null
                    }
                    value={globalMdmDetail && globalMdmDetail['PrimaryGPO']}
                    style={{ height: 'inherit' }}
                    onChange={(value, element) => {
                        this.onFieldChange(value, element);
                    }}
                    inline={true}
                />
                <FormInput
                    variant="outline"
                    label="Requested Credit Limit"
                    name="RequestedCreditLimit"
                    hide={allowedFields && allowedFields.includes('RequestedCreditLimit')}
                    error={
                        this.state.formErrors
                            ? this.state.formErrors['RequestedCreditLimit']
                            : null
                    }
                    value={globalMdmDetail && globalMdmDetail['RequestedCreditLimit']}
                    style={{ height: 'inherit' }}
                    onChange={(value, element) => {
                        this.validateNumericKeypad(value, element);
                    }}
                    inline={true}
                />

                <FormInput
                    variant="outline"
                    label="Priority"
                    name="Priority"
                    hide={allowedFields && allowedFields.includes('Priority')}
                    value={
                        staticData &&
                        globalMdmDetail &&
                        this.getDropdownValues(
                            globalMdmDetail.PriorityTypeId,
                            staticData && staticData.OlympusPriorityType
                        )
                    }
                    formErrors={
                        this.state.formErrors ? this.state.formErrors['Priority'] : null
                    }
                    onFieldChange={this.onFieldChange}
                    inline={true}
                />
                <FormInput
                    variant="outline"
                    label="Effective Date"
                    hide={allowedFields && allowedFields.includes('EffectiveDate')}
                    min={moment().format('YYYY-MM-DD')}
                    name="EffectiveDate"
                    error={
                        this.state.formErrors
                            ? this.state.formErrors['EffectiveDate']
                            : null
                    }
                    value={
                        globalMdmDetail &&
                        moment(globalMdmDetail['EffectiveDate']).format('YYYY-MM-DD')
                    }
                    type="date"
                    style={{ height: 'inherit' }}
                    onChange={(value, element) => {
                        this.onFieldChange(
                            new Date(value).toJSON().slice(0, 19),
                            element
                        );
                    }}
                    inline={true}
                />
                <FormInput
                    label="Purpose of Request"
                    name="Purpose"
                    hide={allowedFields && allowedFields.includes('Purpose')}
                    multiline
                    value={globalMdmDetail && globalMdmDetail.Purpose}
                    numberOfLines={
                        globalMdmDetail.Purpose && globalMdmDetail.Purpose.length > 36
                            ? 3
                            : 1
                    }
                    style={{ lineHeight: '2.5' }}
                    inline
                    maxLength={1000}
                    variant="outline"
                    type="text"
                />
            </>
        );
    };
    isNumber = (n) => {
        return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
    };

    validateNumericKeypad = (value, e) => {
        const { name } = e.target;
        if (value) {
            if (!this.isNumber(value) || parseInt(value) < 0 || parseInt(value) > 100) {
                this.setState({
                    formErrors: {
                        [name]:
                            value >= 0 || value <= 100
                                ? 'Value should be greater 0 and less than 100'
                                : 'Must be digits',
                    },
                });
            } else {
                this.onFieldChange(value, e);
            }
        } else {
            this.onFieldChange(value, e);
        }
    };

    deliveryPlantMapping = (data) => {
        let systemFields = this.props.functionalGroupDetails.Customer;
        let dropdownData = [];
        if (data) {
            data.forEach((dropdownList) => {
                if (dropdownList.ValidationData.length > 0) {
                    dropdownList.ValidationData.forEach((datatofilter) => {
                        if (
                            datatofilter.SalesOrgTypeId === systemFields.SalesOrgTypeId &&
                            systemFields.DistributionChannelTypeId ===
                            datatofilter.DistributionChannelTypeId
                        ) {
                            dropdownData.push(dropdownList);
                        }
                    });
                }
            });
        }
        return dropdownData;
    };

    getDropdownValues = (Id, dropdownList) => {
        let description = '';
        if (Id && dropdownList)
            dropdownList.some((data) => {
                if (data.Id == Id) {
                    description = data.Value + ' : ' + data.Description;
                    return true;
                }
            });
        // if (description.includes('/n') || description.includes(';')) {
        //     console.log('html string');
        // }
        return description;
    };

    getDropdownList = (key, data, stateKey) => {
        let dropdownData = [];
        const self = this;
        const {
            functionalGroupDetails: {
                DocumentLocation: files,
                Customer: globalMdmDetail = {},
            },
        } = this.props;

        if (data) {
            dropdownData = [];
            data.forEach((dropdownList) => {
                if (dropdownList.ValidationData.length > 0) {
                    dropdownList.ValidationData.forEach((datatofilter) => {
                        if (datatofilter[key] === globalMdmDetail[stateKey]) {
                            dropdownData.push(dropdownList);
                        }
                    });
                }
            });
        }
        var check = new Set();
        let filteredArr = dropdownData.filter(
            (obj) => !check.has(obj['Id']) && check.add(obj['Id'])
        );
        return filteredArr;
    };

    setMDM = (mdmObj) => {
        this.setState({
            formData: {
                ...this.state.formData,
                MdmNumberToGroup: mdmObj && mdmObj[0] && mdmObj[0]['MdmNumber'],
            },
        });
    };

    render() {
        const {
            width,
            location,
            functionalGroupDetails: {
                DocumentLocation: files,
                Customer: globalMdmDetail = {},
                CustomerMaster: customerMaster = null,
                TaskNote: Notes = '',
                RequestorName,
            },
            alert = {},
            statusBarData,
            TasksStatusByTeamId = null,
            fetching,
            staticData,
        } = this.props;

        const { formData } = this.state;
        const {
            dropDownDatas,
            teamRoleFieldMapping,
            inputPropsForDefaultRules,
            selectedFilesIds,
            selectedFiles,
        } = this.state;

        const { state } = location;
        const CustomerMasterTeamFields =
            idx(teamRoleFieldMapping, (_) => _.OlympusCustomerMasterFields) || [];
        const RoleTypeId = globalMdmDetail.RoleTypeId;
        const SystemTypeId = globalMdmDetail.SystemTypeId;
        const allowedFieldList = CustomerMasterTeamFields.filter((item) => {
            return item.RoleTypeId === RoleTypeId && item.SystemTypeId === SystemTypeId;
        });
        let allowedFields = undefined;
        if (allowedFieldList && allowedFieldList.length > 0) {
            allowedFields = allowedFieldList[0].Fields;
        }
        var isReleased = true;
        for (let i = 0; i < statusBarData.length; i++) {
            let teamstatus = statusBarData[i];
            if (teamstatus.WorkflowTaskStateTypeId === 2) {
                isReleased = false;
                break;
            } else {
                isReleased = true;
            }
        }
        const workflow = {
            ...state,
            isReadOnly:
                TasksStatusByTeamId === null ||
                !(
                    globalMdmDetail.WorkflowStateTypeId === 2 &&
                    TasksStatusByTeamId[3].WorkflowTaskStateTypeId === 2
                ),
        };
        let isContractEnabled = false;
        //check iff contract fields to be displayed or not
        if (globalMdmDetail && globalMdmDetail.CategoryTypeId != undefined) {
            let categoryTypeid = parseAndSanitise(globalMdmDetail.CategoryTypeId);
            if (
                categoryTypeid === 1 ||
                categoryTypeid === 2 ||
                categoryTypeid === 3 ||
                categoryTypeid === 6
            ) {
                isContractEnabled = true;
            }
        }
        const isWorkFlowReadOnly = workflow.isReadOnly;

        const inputReadonlyProps = workflow.isReadOnly ? { disabled: true } : null;
        const requestDateUtc = moment(globalMdmDetail.DateOfCreation);
        const localDate = moment(requestDateUtc).local();
        const requestDate = localDate.format('YYYY-MM-DD hh:mm:ss A');

        const showFunctionalDetail =
            state.isReadOnly && customerMaster === null ? { display: 'none' } : null;

        const enableDisplay = isWorkFlowReadOnly ? { display: 'none' } : null;

        var bgcolor = alert.color || '#FFF';
        if (fetching) {
            return <Loading />;
        }

        const isPointman = globalMdmDetail && globalMdmDetail.SystemTypeId === 3;

        return (
            <ScrollView
                keyboardShouldPersistTaps="always"
                style={{
                    backgroundColor: '#EFF3F6',
                    paddingBottom: 75,
                }}>
                <Modal isVisible={this.state.modalVisible} style={{ overflow: 'auto' }}>
                    {this._renderModalContent()}
                </Modal>
                <FlashMessages
                    toasts={this.props.toasts}
                    onDismiss={this.props.removeMessage}
                />
                {alert.display && (
                    <FlashMessage
                        bg={{ backgroundColor: bgcolor }}
                        message={alert.message}
                    />
                )}

                <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between">
                    <Text
                        style={{
                            fontWeight: '500',
                            fontSize: 24,
                            color: Colors.lightBlue,
                            marginTop: 25,
                            marginBottom: 20,
                            paddingLeft: 45,
                        }}>
                        My Tasks - {state.Header}
                    </Text>
                    <Box
                        marginTop="25px"
                        flex={3 / 4}
                        flexDirection="row-reverse"
                        alignItems="flex-end">
                        <MultiColorProgressBar readings={statusBarData} />
                    </Box>
                </Box>
                <Box marginTop="25px" flexDirection="row-reverse" alignItems="flex-end">
                    <StyledText
                        style={{
                            fontWeight: '600',
                            paddingRight: '20px',
                            fontSize: '16px',
                            color: '#11307D',
                        }}>
                        {' '}
                        Requestor : {RequestorName}{' '}
                    </StyledText>
                </Box>
                <Box flexDirection="row-reverse" alignItems="flex-end">
                    <StyledText
                        style={{
                            fontWeight: '600',
                            paddingRight: '20px',
                            fontSize: '16px',
                            color: '#11307D',
                        }}>
                        {' '}
                        Date of Request: {requestDate}{' '}
                    </StyledText>
                </Box>
                <View
                    style={{
                        flex: 1,
                        paddingHorizontal: width < 1440 ? 60 : width * 0.1,
                        paddingBottom: 10,
                    }}>
                    <Box fullHeight my={2}>
                        <Box flexDirection="row" justifyContent="space-around" my={4}>
                            <FormInput
                                px="25px"
                                flex={1 / 4}
                                label="WorkflowTitle"
                                maxLength={40}
                                name="WorkflowTitle"
                                variant="outline"
                                type="text"
                                value={globalMdmDetail && globalMdmDetail.WorkflowTitle}
                            />
                            <FormInput
                                px="25px"
                                flex={1 / 4}
                                label="Workflow Number"
                                name="workflow-number"
                                variant="outline"
                                type="text"
                                value={globalMdmDetail && globalMdmDetail.WorkflowId}
                            />
                            {globalMdmDetail.SystemRecordId && (
                                <FormInput
                                    px="25px"
                                    flex={1 / 4}
                                    label="Customer Number"
                                    name="CustomerNumber"
                                    variant="outline"
                                    type="text"
                                    value={globalMdmDetail.SystemRecordId}
                                />
                            )}
                            {globalMdmDetail &&
                                globalMdmDetail.MdmCustomerId &&
                                (isReleased ? (
                                    <TouchableOpacity
                                        onPress={() =>
                                            this.props.history.push(
                                                `/search-results/${globalMdmDetail.MdmCustomerId}`
                                            )
                                        }>
                                        <FormInput
                                            px="25px"
                                            flex={1 / 4}
                                            label="MDM Number"
                                            name="mdm-number"
                                            variant="outline"
                                            type="text"
                                            value={
                                                globalMdmDetail &&
                                                globalMdmDetail.MdmCustomerId
                                            }
                                        />
                                    </TouchableOpacity>
                                ) : (
                                    <FormInput
                                        px="25px"
                                        flex={1 / 4}
                                        label="MDM Number"
                                        name="mdm-number"
                                        variant="outline"
                                        type="text"
                                        value={
                                            globalMdmDetail &&
                                            globalMdmDetail.MdmCustomerId
                                        }
                                    />
                                ))}
                        </Box>

                        {!this.state.isInternationalVersionVisible && (
                            <GlobalMdmFields
                                showMarketingSegmentation={true}
                                formData={{
                                    ...globalMdmDetail,
                                    DunsNumber:
                                        this.state.formData.DunsNumber ||
                                        globalMdmDetail.DunsNumber,
                                    MarketingSegmentationTypeId:
                                        idx(
                                            this.state,
                                            (_) => _.formData.MarketingSegmentationTypeId
                                        ) ||
                                        idx(
                                            customerMaster,
                                            (_) => _.MarketingSegmentationTypeId
                                        ) ||
                                        idx(
                                            globalMdmDetail,
                                            (_) => _.MarketingSegmentationTypeId
                                        ),
                                }}
                                // dunsEditable={!workflow.isReadOnly}
                                editableInputs={{ TaxNumber: false }}
                                onDunsNewInput={this.onDunsNewInput}
                                formErrors={this.state.formErrors}
                                onFieldChange={this.onFieldChange}
                                staticDropDownData={this.props.staticData}
                                viewInternationalModal={() => {
                                    this.setState({
                                        isInternationalVersionVisible: true,
                                    });
                                }}
                                isOlympus={true}
                                readOnly
                            />
                        )}

                        <Text
                            mt="42px"
                            mb="20px"
                            fontWeight="regular"
                            color="lightBlue"
                            fontSize={24}
                            pl={4}>
                            SYSTEM FIELDS
                        </Text>
                        <Box flexDirection="row" justifyContent="center">
                            <Box width={1 / 2} mx="auto" alignItems="center">
                                <FormInput
                                    label="System"
                                    name="System"
                                    inline={true}
                                    variant="outline"
                                    type="text"
                                    hide={
                                        allowedFields && allowedFields.includes('System')
                                    }
                                    value={
                                        SystemType[
                                        globalMdmDetail &&
                                        globalMdmDetail.SystemTypeId
                                        ]
                                    }
                                />
                                <FormInput
                                    label="Role"
                                    name="Role"
                                    hide={allowedFields && allowedFields.includes('Role')}
                                    inline={true}
                                    variant="outline"
                                    type="text"
                                    value={
                                        staticData &&
                                        staticData &&
                                        this.getDropdownValues(
                                            globalMdmDetail.RoleTypeId,
                                            staticData &&
                                            this.props.staticData.OlympusRoleType
                                        )
                                    }
                                />
                                <FormInput
                                    label="Sales Org"
                                    name="SalesOrg"
                                    hide={
                                        allowedFields &&
                                        allowedFields.includes('SalesOrg')
                                    }
                                    inline={true}
                                    variant="outline"
                                    type="text"
                                    value={
                                        staticData &&
                                        globalMdmDetail &&
                                        this.getDropdownValues(
                                            globalMdmDetail.SalesOrgTypeId,
                                            staticData && staticData.OlympusSalesOrgType
                                        )
                                    }
                                />
                                <FormInput
                                    label="Service Code"
                                    name="ServiceCode"
                                    variant="outline"
                                    hide={
                                        allowedFields &&
                                        allowedFields.includes('ServiceCode')
                                    }
                                    error={
                                        this.state.formErrors
                                            ? this.state.formErrors['ServiceCode']
                                            : null
                                    }
                                    value={
                                        globalMdmDetail &&
                                        globalMdmDetail['URIServiceCode']
                                    }
                                    onChange={(value, element) => {
                                        this.onFieldChange(value, element);
                                    }}
                                    inline={true}
                                />
                                <FormInput
                                    variant="outline"
                                    label="Delivery Information"
                                    name="DeliveryInformation"
                                    inline={true}
                                    hide={
                                        allowedFields &&
                                        allowedFields.includes('DeliveryInformation')
                                    }
                                    error={
                                        this.state.formErrors
                                            ? this.state.formErrors['DeliveryInformation']
                                            : null
                                    }
                                    value={
                                        globalMdmDetail &&
                                        globalMdmDetail['UnloadingPoints']
                                    }
                                    style={{ height: 'inherit' }}
                                    onChange={(value, element) => {
                                        this.onFieldChange(value, element);
                                    }}
                                />
                                <FormInput
                                    variant="outline"
                                    label="Sales District"
                                    name="SalesDistrict"
                                    hide={
                                        allowedFields &&
                                        allowedFields.includes('SalesDistrict')
                                    }
                                    value={
                                        staticData &&
                                        globalMdmDetail &&
                                        this.getDropdownValues(
                                            globalMdmDetail.SalesDistrictTypeId,
                                            staticData &&
                                            staticData.OlympusSalesDistrictType
                                        )
                                    }
                                    isRequired={this.state.formData['Country'] === 'DE'}
                                    formErrors={
                                        this.state.formErrors
                                            ? this.state.formErrors['SalesDistrict']
                                            : null
                                    }
                                    onFieldChange={this.onFieldChange}
                                    inline={true}
                                />
                                <FormInput
                                    variant="outline"
                                    label="Sales Office"
                                    name="SalesOffice"
                                    hide={
                                        allowedFields &&
                                        allowedFields.includes('SalesOffice')
                                    }
                                    value={
                                        staticData &&
                                        globalMdmDetail &&
                                        this.getDropdownValues(
                                            globalMdmDetail.SalesOfficeTypeId,
                                            staticData &&
                                            staticData.OlympusSalesOfficeType
                                        )
                                    }
                                    isRequired={this.state.formData['Country'] === 'DE'}
                                    formErrors={
                                        this.state.formErrors
                                            ? this.state.formErrors['SalesOffice']
                                            : null
                                    }
                                    onFieldChange={this.onFieldChange}
                                    inline={true}
                                />
                                <FormInput
                                    variant="outline"
                                    label="Sales Group"
                                    name="SalesGroup"
                                    hide={
                                        allowedFields &&
                                        allowedFields.includes('SalesGroup')
                                    }
                                    value={
                                        staticData &&
                                        globalMdmDetail &&
                                        this.getDropdownValues(
                                            globalMdmDetail.SalesGroupTypeId,
                                            staticData && staticData.OlympusSalesGroupType
                                        )
                                    }
                                    isRequired={this.state.formData['Country'] === 'DE'}
                                    formErrors={
                                        this.state.formErrors
                                            ? this.state.formErrors['SalesGroup']
                                            : null
                                    }
                                    onFieldChange={this.onFieldChange}
                                    inline={true}
                                />
                                <FormInput
                                    variant="outline"
                                    label="Tax Number 4"
                                    name="TaxNumber4"
                                    hide={
                                        allowedFields &&
                                        allowedFields.includes('TaxNumber4')
                                    }
                                    error={
                                        this.state.formErrors
                                            ? this.state.formErrors['TaxNumber4']
                                            : null
                                    }
                                    value={
                                        globalMdmDetail && globalMdmDetail['TaxNumber4']
                                    }
                                    style={{ height: 'inherit' }}
                                    onChange={(value, element) => {
                                        this.onFieldChange(value, element);
                                    }}
                                    inline={true}
                                />
                                <FormInput
                                    variant="outline"
                                    label="Incoterms1"
                                    name="Incoterms1"
                                    hide={
                                        allowedFields &&
                                        allowedFields.includes('Incoterms1')
                                    }
                                    value={
                                        staticData &&
                                        globalMdmDetail &&
                                        this.getDropdownValues(
                                            globalMdmDetail.IncoTermsTypeId,
                                            staticData && staticData.OlympusIncoTermsType
                                        )
                                    }
                                    formErrors={
                                        this.state.formErrors
                                            ? this.state.formErrors['Incoterms1']
                                            : null
                                    }
                                    onFieldChange={this.onFieldChange}
                                    inline={true}
                                />
                                <FormInput
                                    variant="outline"
                                    label="Place Of Destination"
                                    name="PlaceOfDestination"
                                    hide={
                                        allowedFields &&
                                        allowedFields.includes('PlaceOfDestination')
                                    }
                                    error={
                                        this.state.formErrors
                                            ? this.state.formErrors['PlaceOfDestination']
                                            : null
                                    }
                                    value={
                                        globalMdmDetail &&
                                        globalMdmDetail['PlaceOfDestination']
                                    }
                                    style={{ height: 'inherit' }}
                                    onChange={(value, element) => {
                                        this.onFieldChange(value, element);
                                    }}
                                    inline={true}
                                />
                                <FormInput
                                    variant="outline"
                                    label="Acct Assignment Group"
                                    name="AcctAssignmentGroup"
                                    hide={
                                        allowedFields &&
                                        allowedFields.includes('AcctAssignmentGroup')
                                    }
                                    value={
                                        staticData &&
                                        globalMdmDetail &&
                                        this.getDropdownValues(
                                            globalMdmDetail.AcctAssignmentGroupTypeId,
                                            staticData &&
                                            staticData.OlympusAcctAssignmentGroupType
                                        )
                                    }
                                    formErrors={
                                        this.state.formErrors
                                            ? this.state.formErrors['AcctAssignmentGroup']
                                            : null
                                    }
                                    onFieldChange={this.onFieldChange}
                                    inline={true}
                                />
                                <FormInput
                                    variant="outline"
                                    label="Tax Classification"
                                    name="TaxClassification"
                                    hide={
                                        allowedFields &&
                                        allowedFields.includes('TaxClassification')
                                    }
                                    value={
                                        staticData &&
                                        globalMdmDetail &&
                                        this.getDropdownValues(
                                            globalMdmDetail.TaxClassificationTypeId,
                                            staticData &&
                                            staticData.OlympusTaxClassificationType
                                        )
                                    }
                                    formErrors={
                                        this.state.formErrors
                                            ? this.state.formErrors['TaxClasssification']
                                            : null
                                    }
                                    onFieldChange={this.onFieldChange}
                                    inline={true}
                                />
                                <FormInput
                                    inline
                                    variant="outline"
                                    label="Customer Number"
                                    name="CustomerNumber"
                                    type="text"
                                    hide={
                                        allowedFields &&
                                        allowedFields.includes('CustomerNumber')
                                    }
                                    value={
                                        staticData &&
                                        globalMdmDetail &&
                                        globalMdmDetail.CustomerNumber
                                    }
                                />
                            </Box>
                            {!isPointman ? (
                                <Box width={1 / 2} mx="auto" alignItems="center">
                                    {this.renderOlympusFields(
                                        isPointman,
                                        globalMdmDetail,
                                        staticData,
                                        allowedFields
                                    )}
                                </Box>
                            ) : (
                                <Box width={1 / 2} mx="auto" alignItems="center">
                                    {globalMdmDetail.RoleTypeId !== 1 && (
                                        <FormInput
                                            label="Ship To Customer Number"
                                            name="ShipToCustomerNumber"
                                            type="number"
                                            value={
                                                isWorkFlowReadOnly
                                                    ? customerMaster &&
                                                    customerMaster.ShipToCustomerNumber
                                                    : this.state.formData
                                                        ? this.state.formData[
                                                        'ShipToCustomerNumber'
                                                        ]
                                                        : null
                                            }
                                            error={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                    'ShipToCustomerNumber'
                                                    ]
                                                    : null
                                            }
                                            onChange={(val, e) =>
                                                this.onFieldChange(
                                                    val.toLowerCase().trim(),
                                                    e
                                                )
                                            }
                                            maxLength={8}
                                            variant={
                                                isWorkFlowReadOnly ? 'outline' : 'solid'
                                            }
                                            inline={isWorkFlowReadOnly ? true : false}
                                            required
                                        />
                                    )}
                                    {this.renderOlympusFields(
                                        isPointman,
                                        globalMdmDetail,
                                        staticData,
                                        allowedFields
                                    )}
                                </Box>
                            )}
                        </Box>

                        {!isPointman && (
                            <Box {...showFunctionalDetail}>
                                <Text
                                    mt="42px"
                                    mb="20px"
                                    fontWeight="regular"
                                    color="lightBlue"
                                    fontSize={24}
                                    pl={4}>
                                    CUSTOMER MASTER FIELDS
                                </Text>
                                <Box flexDirection="row" justifyContent="center">
                                    <Box width={1 / 2} mx="auto" alignItems="center">
                                        <FormInput
                                            label="Search Term 1"
                                            name="SearchTerm1"
                                            hide={
                                                allowedFields &&
                                                allowedFields.includes('SearchTerm1')
                                            }
                                            maxLength={20}
                                            error={
                                                this.state.formErrors
                                                    ? this.state.formErrors['SearchTerm1']
                                                    : null
                                            }
                                            onChange={this.onFieldChange}
                                            type="text"
                                            value={
                                                isWorkFlowReadOnly
                                                    ? customerMaster &&
                                                    customerMaster.SearchTerm1
                                                    : this.state.formData
                                                        ? this.state.formData['SearchTerm1']
                                                        : null
                                            }
                                            variant={
                                                isWorkFlowReadOnly ? 'outline' : 'solid'
                                            }
                                            inline={isWorkFlowReadOnly ? true : false}
                                        />
                                        <FormInput
                                            label="Search Term 2"
                                            name="SearchTerm2"
                                            hide={
                                                allowedFields &&
                                                allowedFields.includes('SearchTerm2')
                                            }
                                            maxLength={20}
                                            error={
                                                this.state.formErrors
                                                    ? this.state.formErrors['SearchTerm2']
                                                    : null
                                            }
                                            onChange={this.onFieldChange}
                                            type="text"
                                            required={true}
                                            value={
                                                isWorkFlowReadOnly
                                                    ? customerMaster &&
                                                    customerMaster.SearchTerm2
                                                    : this.state.formData
                                                        ? this.state.formData['SearchTerm2']
                                                        : null
                                            }
                                            variant={
                                                isWorkFlowReadOnly ? 'outline' : 'solid'
                                            }
                                            inline={isWorkFlowReadOnly ? true : false}
                                        />
                                        {/* <DynamicSelect
                                            arrayOfData={
                                                staticData &&
                                                staticData.OlympusIndustryType
                                            }
                                            label="Industry"
                                            name="Industry"
                                            hide={
                                                allowedFields &&
                                                allowedFields.includes('Industry')
                                            }
                                            isRequired={true}
                                            formErrors={
                                                this.state.formErrors
                                                    ? this.state.formErrors['Industry']
                                                    : null
                                            }
                                            onFieldChange={this.onFieldChange}
                                            value={
                                                isWorkFlowReadOnly
                                                    ? customerMaster &&
                                                      parseAndSanitise(
                                                          customerMaster.Industry
                                                      )
                                                    : this.state.formData
                                                    ? this.state.formData['Industry']
                                                    : null
                                            }
                                            inputProps={inputReadonlyProps}
                                        /> */}
                                        <DynamicSelect
                                            arrayOfData={
                                                staticData &&
                                                staticData.OlympusIndustryCodeType
                                            }
                                            label="Industry Code 1"
                                            name="IndustryCode"
                                            hide={
                                                allowedFields &&
                                                allowedFields.includes('IndustryCode1')
                                            }
                                            isRequired={false}
                                            formErrors={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                    'IndustryCode'
                                                    ]
                                                    : null
                                            }
                                            onFieldChange={this.onFieldChange}
                                            value={
                                                isWorkFlowReadOnly
                                                    ? customerMaster &&
                                                    parseAndSanitise(
                                                        customerMaster.IndustryCodeTypeId
                                                    )
                                                    : this.state.formData
                                                        ? this.state.formData['IndustryCode']
                                                        : null
                                            }
                                            inputProps={inputReadonlyProps}
                                        />
                                        <DynamicSelect
                                            arrayOfData={
                                                staticData &&
                                                staticData.OlympusSortKeyType
                                            }
                                            label="Sort Key"
                                            name="SortKey"
                                            hide={
                                                allowedFields &&
                                                allowedFields.includes('SortKey')
                                            }
                                            isRequired={true}
                                            formErrors={
                                                this.state.formErrors
                                                    ? this.state.formErrors['SortKey']
                                                    : null
                                            }
                                            onFieldChange={this.onFieldChange}
                                            value={
                                                isWorkFlowReadOnly
                                                    ? customerMaster &&
                                                    parseAndSanitise(
                                                        customerMaster.SortKey
                                                    )
                                                    : this.state.formData
                                                        ? this.state.formData['SortKey']
                                                        : null
                                            }
                                            inputProps={inputReadonlyProps}
                                        />

                                        <DynamicSelect
                                            arrayOfData={
                                                staticData &&
                                                staticData.OlympusCustPriceProcType
                                            }
                                            label="Cust Price Proc"
                                            name="CustPriceProc"
                                            hide={
                                                allowedFields &&
                                                allowedFields.includes(
                                                    'CustomerPriceProc'
                                                )
                                            }
                                            isRequired={true}
                                            formErrors={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                    'CustPriceProc'
                                                    ]
                                                    : null
                                            }
                                            onFieldChange={this.onFieldChange}
                                            value={
                                                isWorkFlowReadOnly
                                                    ? customerMaster &&
                                                    parseAndSanitise(
                                                        customerMaster.CustomerPriceProcTypeId
                                                    )
                                                    : this.state.formData
                                                        ? this.state.formData['CustPriceProc']
                                                        : null
                                            }
                                            inputProps={inputReadonlyProps}
                                        />

                                        <DynamicSelect
                                            arrayOfData={
                                                staticData &&
                                                staticData.OlympusCustomerGroupType
                                            }
                                            label="Customer Group"
                                            name="CustomerGroup"
                                            hide={
                                                allowedFields &&
                                                allowedFields.includes('CustomerGroup')
                                            }
                                            isRequired={false}
                                            formErrors={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                    'CustomerGroup'
                                                    ]
                                                    : null
                                            }
                                            onFieldChange={this.onFieldChange}
                                            value={
                                                isWorkFlowReadOnly
                                                    ? customerMaster &&
                                                    parseAndSanitise(
                                                        customerMaster.CustomerGroupTypeId
                                                    )
                                                    : this.state.formData
                                                        ? this.state.formData['CustomerGroup']
                                                        : null
                                            }
                                            inputProps={inputReadonlyProps}
                                        />

                                        <DynamicSelect
                                            arrayOfData={
                                                staticData &&
                                                staticData.OlympusDeliveryPriorityType
                                            }
                                            label="Delivery Priority"
                                            name="DeliveryPriority"
                                            hide={
                                                allowedFields &&
                                                allowedFields.includes('DeliveryPriority')
                                            }
                                            isRequired={true}
                                            formErrors={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                    'DeliveryPriority'
                                                    ]
                                                    : null
                                            }
                                            onFieldChange={this.onFieldChange}
                                            value={
                                                isWorkFlowReadOnly
                                                    ? customerMaster &&
                                                    parseAndSanitise(
                                                        customerMaster.DeliveryPriorityTypeId
                                                    )
                                                    : this.state.formData
                                                        ? this.state.formData[
                                                        'DeliveryPriority'
                                                        ]
                                                        : null
                                            }
                                            inputProps={inputReadonlyProps}
                                        />

                                        {/* <FormInput
                                            label="Additional Notes"
                                            name="AdditionalNotes"
                                            hide={
                                                allowedFields &&
                                                allowedFields.includes('AdditionalNotes')
                                            }
                                            maxLength={20}
                                            error={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                          'AddtionalNotes'
                                                      ]
                                                    : null
                                            }
                                            onChange={this.onFieldChange}
                                            type="text"
                                            value={
                                                isWorkFlowReadOnly
                                                    ? customerMaster &&
                                                      customerMaster.SearchTerm1
                                                    : this.state.formData
                                                    ? this.state.formData[
                                                          'AdditionalNotes'
                                                      ]
                                                    : null
                                            }
                                            variant={
                                                isWorkFlowReadOnly ? 'outline' : 'solid'
                                            }
                                            inline={isWorkFlowReadOnly ? true : false}
                                        /> */}
                                    </Box>
                                    <Box width={1 / 2} mx="auto" alignItems="center">
                                        <DynamicSelect
                                            arrayOfData={
                                                staticData &&
                                                staticData.OlympusShippingConditionsType
                                            }
                                            label="Shipping Conditions"
                                            name="ShippingConditions"
                                            hide={
                                                allowedFields &&
                                                allowedFields.includes(
                                                    'ShippingConditions'
                                                )
                                            }
                                            isRequired={true}
                                            formErrors={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                    'ShippingConditions'
                                                    ]
                                                    : null
                                            }
                                            onFieldChange={this.onFieldChange}
                                            value={
                                                isWorkFlowReadOnly
                                                    ? customerMaster &&
                                                    parseAndSanitise(
                                                        customerMaster.ShippingConditionsTypeId
                                                    )
                                                    : this.state.formData
                                                        ? this.state.formData[
                                                        'ShippingConditions'
                                                        ]
                                                        : null
                                            }
                                            inputProps={inputReadonlyProps}
                                        />

                                        <DynamicSelect
                                            arrayOfData={
                                                staticData &&
                                                staticData.OlympusMaxPartialDeliveriesType
                                            }
                                            label="Max Partial Deliveries"
                                            name="MaxPartialDeliveries"
                                            hide={
                                                allowedFields &&
                                                allowedFields.includes(
                                                    'MaxPartialDeliveries'
                                                )
                                            }
                                            isRequired={true}
                                            formErrors={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                    'MaxPartialDeliveries'
                                                    ]
                                                    : null
                                            }
                                            onFieldChange={this.onFieldChange}
                                            value={
                                                isWorkFlowReadOnly
                                                    ? customerMaster &&
                                                    parseAndSanitise(
                                                        customerMaster.MaxPartialDeliveriesTypeId
                                                    )
                                                    : this.state.formData
                                                        ? this.state.formData[
                                                        'MaxPartialDeliveries'
                                                        ]
                                                        : null
                                            }
                                            inputProps={inputReadonlyProps}
                                        />
                                        <FormInput
                                            label="Order Probab"
                                            name="OrderProbab"
                                            hide={
                                                allowedFields &&
                                                allowedFields.includes('OrderProbab')
                                            }
                                            maxLength={3}
                                            required={true}
                                            error={
                                                this.state.formErrors
                                                    ? this.state.formErrors['OrderProbab']
                                                    : null
                                            }
                                            onChange={this.validateNumericKeypad}
                                            type="number"
                                            value={
                                                isWorkFlowReadOnly
                                                    ? customerMaster &&
                                                    customerMaster.OrderProbab
                                                    : this.state.formData
                                                        ? this.state.formData['OrderProbab']
                                                        : null
                                            }
                                            variant={
                                                isWorkFlowReadOnly ? 'outline' : 'solid'
                                            }
                                            inline={isWorkFlowReadOnly ? true : false}
                                        />

                                        <CheckBoxItem
                                            title="Complete Delivery Required"
                                            name="CompleteDeliveryRequired"
                                            hide={
                                                allowedFields &&
                                                allowedFields.includes(
                                                    'CompleteDeliveryRequired'
                                                )
                                            }
                                            stateValue={
                                                isWorkFlowReadOnly
                                                    ? customerMaster &&
                                                    customerMaster.CompleteDeliveryRequired
                                                    : this.state.formData &&
                                                    this.state.formData[
                                                    'CompleteDeliveryRequired'
                                                    ]
                                            }
                                            inputProps={enableDisplay}
                                            onValueChange={() => {
                                                this.setState({
                                                    formData: {
                                                        ...this.state.formData,
                                                        CompleteDeliveryRequired: !this
                                                            .state.formData
                                                            .CompleteDeliveryRequired,
                                                        PartialDeliveryPerItem: !this
                                                            .state.formData
                                                            .CompleteDeliveryRequired
                                                            ? 4
                                                            : 1,
                                                    },
                                                });
                                            }}
                                        />

                                        {/* <DynamicSelect
                                            arrayOfData={
                                                staticData &&
                                                staticData.OlympusPriceGroupType
                                            }
                                            label="Price Group"
                                            name="PriceGroup"
                                            isRequired={true}
                                            formErrors={
                                                this.state.formErrors
                                                    ? this.state.formErrors['PriceGroup']
                                                    : null
                                            }
                                            onFieldChange={this.onFieldChange}
                                            value={
                                                isWorkFlowReadOnly
                                                    ? customerMaster &&
                                                      parseAndSanitise(
                                                          customerMaster.PriceGroupTypeId
                                                      )
                                                    : this.state.formData
                                                    ? this.state.formData['PriceGroup']
                                                    : null
                                            }
                                            inputProps={inputReadonlyProps}
                                        /> */}

                                        <DynamicSelect
                                            arrayOfData={
                                                staticData &&
                                                staticData.OlympusPartialDeliveryPerItemType
                                            }
                                            label="Partial Delivery Per Item"
                                            name="PartialDeliveryPerItem"
                                            hide={
                                                allowedFields &&
                                                allowedFields.includes(
                                                    'PartialDeliveryPerItem'
                                                )
                                            }
                                            // isRequired={true}
                                            formErrors={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                    'PartialDeliveryPerItem'
                                                    ]
                                                    : null
                                            }
                                            onFieldChange={this.onFieldChange}
                                            value={
                                                isWorkFlowReadOnly
                                                    ? customerMaster &&
                                                    parseAndSanitise(
                                                        customerMaster.PartialDeliveryPerItemTypeId
                                                    )
                                                    : this.state.formData
                                                        ? this.state.formData[
                                                        'PartialDeliveryPerItem'
                                                        ]
                                                        : null
                                            }
                                            inputProps={inputReadonlyProps}
                                        />

                                        <FormInput
                                            label="Customer Stat Group"
                                            name="CustomerStatGroup"
                                            variant="outline"
                                            hide={
                                                allowedFields &&
                                                allowedFields.includes(
                                                    'CustomerStatGroup'
                                                )
                                            }
                                            maxLength={20}
                                            error={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                    'CustomerStatGroup'
                                                    ]
                                                    : null
                                            }
                                            onChange={this.onFieldChange}
                                            type="text"
                                            value={
                                                isWorkFlowReadOnly
                                                    ? customerMaster &&
                                                    customerMaster.CustomerStatGroup
                                                    : this.state.formData
                                                        ? this.state.formData[
                                                        'CustomerStatGroup'
                                                        ]
                                                        : null
                                            }
                                            inline={isWorkFlowReadOnly ? true : false}
                                        />

                                        {/* <DynamicSelect
                                            arrayOfData={
                                                staticData &&
                                                this.deliveryPlantMapping(
                                                    staticData.OlympusDeliveringPlantType
                                                )
                                            }
                                            label="Delivering Plant"
                                            name="DeliveringPlant"
                                            hide={
                                                allowedFields &&
                                                allowedFields.includes('DeliveringPlant')
                                            }
                                            formErrors={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                          'DeliveringPlant'
                                                      ]
                                                    : null
                                            }
                                            onFieldChange={this.onFieldChange}
                                            value={
                                                isWorkFlowReadOnly
                                                    ? customerMaster &&
                                                      parseAndSanitise(
                                                          customerMaster.DeliveringPlantTypeId
                                                      )
                                                    : this.state.formData
                                                    ? this.state.formData[
                                                          'DeliveringPlant'
                                                      ]
                                                    : null
                                            }
                                            inputProps={inputReadonlyProps}
                                        /> */}
                                        <DynamicSelect
                                            arrayOfData={
                                                staticData &&
                                                staticData.OlympusTransporationZoneType &&
                                                this.getDropdownList(
                                                    'CountryTypeValue',
                                                    staticData.OlympusTransporationZoneType,
                                                    'Country'
                                                )
                                            }
                                            label="Transportation Zone"
                                            name="TransportationZone"
                                            hide={
                                                allowedFields &&
                                                allowedFields.includes(
                                                    'TransportationZone'
                                                )
                                            }
                                            isRequired={true}
                                            formErrors={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                    'TransportationZone'
                                                    ]
                                                    : null
                                            }
                                            onFieldChange={this.onFieldChange}
                                            value={
                                                isWorkFlowReadOnly
                                                    ? customerMaster &&
                                                    parseAndSanitise(
                                                        customerMaster.TransportationZone
                                                    )
                                                    : this.state.formData
                                                        ? this.state.formData[
                                                        'TransportationZone'
                                                        ]
                                                        : null
                                            }
                                            inputProps={inputReadonlyProps}
                                        />
                                        {/* <FormInput
                                            label="Transportation Zone"
                                            name="TransportationZone"
                                            hide={
                                                allowedFields &&
                                                allowedFields.includes(
                                                    'TransportationZone'
                                                )
                                            }
                                            maxLength={20}
                                            error={
                                                this.state.formErrors
                                                    ? this.state.formErrors[
                                                          'TransportionZone'
                                                      ]
                                                    : null
                                            }
                                            onChange={this.onFieldChange}
                                            type="text"
                                            value={
                                                isWorkFlowReadOnly
                                                    ? customerMaster &&
                                                      customerMaster.TransportationZone
                                                    : this.state.formData
                                                    ? this.state.formData[
                                                          'TransportationZone'
                                                      ]
                                                    : null
                                            }
                                            required={true}
                                            variant={
                                                isWorkFlowReadOnly ? 'outline' : 'solid'
                                            }
                                            inline={isWorkFlowReadOnly ? true : false}
                                        /> */}
                                        <CheckBoxItem
                                            title="Payment History Record"
                                            name="PaymentHistoryRecord"
                                            hide={
                                                allowedFields &&
                                                allowedFields.includes(
                                                    'PaymentHistoryRecord'
                                                )
                                            }
                                            stateValue={
                                                isWorkFlowReadOnly
                                                    ? customerMaster &&
                                                    customerMaster.PaymentHistoryRecord
                                                    : this.state.formData &&
                                                    this.state.formData[
                                                    'PaymentHistoryRecord'
                                                    ]
                                            }
                                            required={true}
                                            inputProps={enableDisplay}
                                            onValueChange={() =>
                                                this.setState({
                                                    formData: {
                                                        ...this.state.formData,
                                                        PaymentHistoryRecord: !this.state
                                                            .formData
                                                            .PaymentHistoryRecord,
                                                    },
                                                })
                                            }
                                        />
                                        <FormInput
                                            label="Incoterms 2"
                                            name="IncoTerms2"
                                            hide={
                                                allowedFields &&
                                                allowedFields.includes('IncoTerms2')
                                            }
                                            maxLength={28}
                                            onChange={this.onFieldChange}
                                            error={
                                                this.state.formErrors
                                                    ? this.state.formErrors['IncoTerms2']
                                                    : null
                                            }
                                            type="text"
                                            required={true}
                                            value={
                                                isWorkFlowReadOnly
                                                    ? customerMaster &&
                                                    customerMaster.IncoTerms2
                                                    : this.state.formData
                                                        ? this.state.formData['IncoTerms2']
                                                        : null
                                            }
                                            variant={
                                                this.state.formData.isReadonlyINCOT2 ||
                                                    isWorkFlowReadOnly
                                                    ? 'outline'
                                                    : 'solid'
                                            }
                                            inline={isWorkFlowReadOnly ? true : false}
                                            inputProps={
                                                isWorkFlowReadOnly
                                                    ? inputReadonlyProps
                                                    : inputPropsForDefaultRules[
                                                    'IncoTerms2'
                                                    ]
                                            }
                                        />

                                        <CheckBoxItem
                                            title="Order Combination"
                                            name="OrderCombination"
                                            hide={
                                                allowedFields &&
                                                allowedFields.includes('OrderCombination')
                                            }
                                            stateValue={
                                                isWorkFlowReadOnly
                                                    ? customerMaster &&
                                                    customerMaster.OrderCombination
                                                    : this.state.formData &&
                                                    this.state.formData[
                                                    'OrderCombination'
                                                    ]
                                            }
                                            inputProps={enableDisplay}
                                            onValueChange={() =>
                                                this.setState({
                                                    formData: {
                                                        ...this.state.formData,
                                                        OrderCombination: !this.state
                                                            .formData.OrderCombination,
                                                    },
                                                })
                                            }
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        )}
                        {globalMdmDetail.LikelyMatches && (
                            <>
                                <Text
                                    mt="42px"
                                    mb="20px"
                                    fontWeight="regular"
                                    color="lightBlue"
                                    fontSize={24}
                                    pl={4}>
                                    LIKELY MATCHES
                                </Text>
                                {globalMdmDetail.AllowMdmGrouping == false && (
                                    <Text
                                        fontWeight="regular"
                                        color="red"
                                        fontSize={13}
                                        ml={'10%'}>
                                        MDM Assignment is not enabled because DUNS#
                                        matches {globalMdmDetail.MdmDunsMatch}
                                    </Text>
                                )}
                                <Box ml={'10%'} width={3 / 4} justifyContent="center">
                                    <Box
                                        flexDirection="row"
                                        justifyContent="center"
                                        alignItems="center">
                                        <CollapsibleTable
                                            AllowMdmGrouping={
                                                globalMdmDetail.AllowMdmGrouping !=
                                                    undefined
                                                    ? globalMdmDetail.AllowMdmGrouping
                                                    : true
                                            }
                                            readOnly={workflow.isReadOnly}
                                            MdmNumberToGroup={
                                                (customerMaster &&
                                                    customerMaster.MdmNumberToGroup) ||
                                                null
                                            }
                                            MDMDunsMatch={123142452}
                                            checkboxState={this.state.checkboxState}
                                            setSelectedMDM={this.setMDM}
                                            data={globalMdmDetail.LikelyMatches}
                                        />
                                    </Box>
                                </Box>
                            </>
                        )}
                        {files && <FilesList files={files} readOnly />}
                        {workflow && workflow.isReadOnly ? (
                            <>
                                <Box my={4}>
                                    <Box flexDirection="row" justifyContent="center">
                                        <Box width={1 / 2} mx="auto" alignItems="center">
                                            <FormInput
                                                label="Notes"
                                                name="AdditionalNotes"
                                                multiline
                                                numberOfLines={2}
                                                type="text"
                                                inputWidth="100%"
                                                value={
                                                    isWorkFlowReadOnly
                                                        ? Notes
                                                        : this.state.formData
                                                            ? this.state.formData[
                                                            'AdditionalNotes'
                                                            ]
                                                            : null
                                                }
                                                variant={
                                                    isWorkFlowReadOnly
                                                        ? 'outline'
                                                        : 'solid'
                                                }
                                                inline={isWorkFlowReadOnly ? true : false}
                                            />
                                        </Box>
                                        <Box
                                            width={1 / 2}
                                            mx="auto"
                                            alignItems="center"></Box>
                                    </Box>
                                </Box>
                            </>
                        ) : (
                            <FilesList
                                title="NEW ATTACHMENTS"
                                formErrors={this.state.fileErrors}
                                files={selectedFilesIds.map((id) => selectedFiles[id])}
                                onChange={(value, id) => {
                                    this.setState({
                                        selectedFiles: {
                                            ...selectedFiles,
                                            [id]: {
                                                ...selectedFiles[id],
                                                DocumentType: parseInt(value),
                                            },
                                        },
                                    });
                                }}
                                onRemove={(value, id) => this.removeFile(id)}
                            />
                        )}
                    </Box>
                    <Box {...enableDisplay}>
                        <Flex
                            justifyEnd
                            alignCenter
                            style={{
                                paddingTop: 15,
                                flexDirection: 'row',
                                alignItems: 'center',
                                paddingLeft: 10,
                                paddingRight: 15,
                                marginTop: 20,
                                marginBottom: 10,
                                marginHorizontal: 25,
                            }}>
                            <>
                                <label
                                    htmlFor="file-upload"
                                    className="custom-file-upload">
                                    <MaterialIcons
                                        name="attach-file"
                                        size={18}
                                        color="#fff"
                                    />
                                </label>
                                <input
                                    id="file-upload"
                                    type="file"
                                    key={this.state.fileinputkey}
                                    onChange={this.selectFiles}
                                    multiple
                                />
                                <span id="testError"></span>
                            </>
                            <Button
                                onPress={(event) => {
                                    this.setState({
                                        formErrors: {},
                                        isReject: false,
                                        modalVisible: true,
                                    });
                                }}
                                title="Approve"
                            />
                            <Button
                                title="Reject"
                                onPress={(event) =>
                                    this.setState({ isReject: true, modalVisible: true })
                                }
                            />
                        </Flex>
                    </Box>
                    {this.state.isInternationalVersionVisible && (
                        <Modal
                            isVisible={this.state.isInternationalVersionVisible}
                            style={{ background: '#FFFFFF' }}>
                            <GlobalMdmFields
                                staticDropDownData={this.props.staticData}
                                formData={globalMdmDetail}
                                isInternationalVersion={true}
                                isOlympus={true}
                                isOlympusModal={true}
                                viewInternationalModal={() => {
                                    this.setState({
                                        isInternationalVersionVisible: false,
                                    });
                                }}
                                readOnly
                            />
                        </Modal>
                    )}
                </View>
            </ScrollView>
        );
    }
}

class Default extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const props = this.props;

        return (
            <DimensionAware
                render={(dimensions) => (
                    <Page
                        {...{
                            ...props,
                            width: getWindowWidth(dimensions),
                            height: getWindowHeight(dimensions),
                            marginBottom: 25,
                        }}
                    />
                )}
            />
        );
    }
}

const mapStateToProps = ({ workflows, myTasks, toasts, staticData }) => {
    const { fetching, alert } = myTasks;
    const {
        fetchingfnGroupData,
        statusBarData,
        functionalGroupDetails,
        TasksStatusByTeamId,
        fetchingStatusBar,
        dunsFields = {},
    } = workflows;
    const { data, fetching: staticFetching } = staticData;
    return {
        fetching: fetching || fetchingStatusBar || fetchingfnGroupData || staticFetching,
        dunsFields,
        alert,
        statusBarData,
        functionalGroupDetails,
        TasksStatusByTeamId,
        toasts,
        staticData: data,
    };
};

export default connect(mapStateToProps, {
    saveApolloMyTaskCustomerMaster,
    getFunctionalGroupData,
    getStatusBarData,
    removeMessage,
    fetchDuns,
    getStaticRequest,
})(Default);

const styles = StyleSheet.create({
    progressIndicator: {
        flex: 1,
        paddingBottom: 5,
        flexDirection: 'row-reverse',
        alignItems: 'flex-end',
    },
    statusText: {
        fontSize: 15,
        color: '#1D4289',
        fontFamily: 'Poppins',
        textAlign: 'center',
        marginTop: 20,
    },
});
