import React, { Component } from 'react';
import styled from 'styled-components';
import { Animated, Easing, View } from 'react-native';
import { pick } from '@styled-system/props';
import { Box, Text } from './common';
import useAnimation from './useAnimation';
import { FontAwesome, MaterialIcons } from '@expo/vector-icons';
import { FAILED_BGCOLOR } from '../constants/ActionTypes';
function AnimatedComponent({
    doAnimation,
    onAnimationEnd,
    children,
    config,
    transition,
    innerRef,
}) {
    const animation = useAnimation({
        doAnimation,
        delay: config.delay,
        duration: config.duration,
        easing: Easing.out(),
        onEnd: onAnimationEnd,
        type: 'spring',
    });
    return (
        <Animated.View
            pointerEvents={doAnimation ? 'auto' : 'none'}
            style={{
                position: 'fixed',
                top: 75,
                right: 0,
                transform: [
                    {
                        [config.property]: animation.interpolate(transition),
                    },
                ],
                opacity: animation,
            }}>
            {children}
        </Animated.View>
    );
}

const Message = styled(View)`
    padding: 10px 15px;
    border-radius: 3px;
    margin-bottom: 5px;
    color: #fff;
    font-size: 14px;
`;

class FlashMessage extends Component {
    state = {
        animate: true,
        hide: false,
    };

    _isMounted = false;

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const {
            bg,
            message,
            onDismiss,
            showDuration,
            isCopy,
            style,
            ...rest
        } = this.props;
        const wrapperProps = pick(rest);

        return !this.state.hide ? (
            <AnimatedComponent
                config={{
                    delay: 200,
                    duration: showDuration ? showDuration : 200,
                    property: 'translateY',
                }}
                transition={{
                    inputRange: [0, 1],
                    outputRange: [this.state.animate ? 100 : 50, 0],
                }}
                doAnimation={this.props.animate || this.state.animate}
                onAnimationEnd={() => {
                    if (this.state.animate)
                        setTimeout(
                            () => {
                                if (this._isMounted) this.setState({ animate: false });
                            },
                            showDuration === undefined ? 2000 : showDuration
                        );
                    else
                        this.setState({ hide: true }, () => {
                            if (onDismiss) onDismiss();
                        });
                }}>
                <Box
                    top="20px"
                    right="1px"
                    style={{ zIndex: 999, ...style }}
                    {...wrapperProps}
                    p="0px 45px 0px 45px">
                    <Message style={bg}>
                        <Text
                            color="#FFFFFF"
                            fontSize="14px"
                            fontFamily="Poppins"
                            px="15px">
                            {message + '  '}
                            {isCopy && isCopy == true && (
                                <MaterialIcons
                                    name="content-copy"
                                    style={{ width: '20%', margin: 'auto' }}
                                    color="white"
                                    size="24"
                                    title="Copy error message"
                                    onPress={() => {
                                        navigator.clipboard.writeText(message);
                                    }}
                                />
                            )}
                        </Text>
                    </Message>
                </Box>
            </AnimatedComponent>
        ) : null;
    }
}

export const FlashMessages = ({
    toasts = [],
    style,
    onDismiss,
    showDuration,
    isCopy,
}) => {
    if (toasts.length <= 0) return null;

    const { id, msg, color } = toasts[0];
    return (
        <p>
            <FlashMessage
                bg={{
                    backgroundColor: color || '#FFF',
                }}
                style={{ zIndex: 999, ...style }}
                message={msg}
                key={id}
                onDismiss={() => onDismiss(msg)}
                marginTop="40px"
                showDuration={showDuration ? showDuration : 2000}
                isCopy={isCopy}
            />
        </p>
    );
};
// export class FlashMsgQue extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             messages: [],
//         };
//     }
//     render() {
//         const {messages } =
//         return this.updateCellsBatchingPeriod;
//     }
// }

export default FlashMessage;
