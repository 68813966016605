/**
 * @prettier
 */

import React, { Component, Fragment } from 'react';
import { Box, Text } from './common';
import { FormInput, FormSelect, FieldLabel } from './form';
import { FontAwesome } from '@expo/vector-icons';
import { times } from 'lodash';
import { View } from 'react-native';
import { TextInput } from 'react-native-web';
import CountryRegionData from '../constants/data.normalized';
import idx from 'idx';
import DeltaField from './DeltaField';
import debounce from 'debounce';
import CountryRegionSelector from './CountryRegionSelector';
import DynamicSelect from '../components/DynamicSelect';

const CategoryTypes = [
    { name: 'Distributor', id: 1 },
    { name: 'Self-Distributor', id: 2 },
    { name: 'OEM', id: 3 },
    { name: 'Direct', id: 4 },
    { name: 'Kitter', id: 6 },
    { name: 'Drop Ship', id: 7 },
    { name: 'Other', id: 5 },
];

const AddIcon = ({ onPress }) => (
    <Box ml={3}>
        <FontAwesome.Button
            name="plus"
            size={15}
            color="#FFFFFF"
            backgroundColor="#264384"
            borderRadius={13}
            iconStyle={{ marginRight: 0, paddingHorizontal: 1 }}
            onPress={onPress}
        />
    </Box>
);

const RemoveIcon = ({ onPress }) => (
    <Box ml={3}>
        <FontAwesome.Button
            name="minus"
            size={15}
            color="#FFFFFF"
            backgroundColor="#264384"
            borderRadius={13}
            iconStyle={{ marginRight: 0, paddingHorizontal: 1 }}
            onPress={onPress}
        />
    </Box>
);

export function SicCodeInputs({ deltas, formData, inputProps }) {
    const _getValue = (inputName) => {
        const inputVal = deltas[inputName]
            ? deltas[inputName].UpdatedValue
            : formData[inputName];
        const SicCode = formData.SicCode;
        const SicCodeDescription = formData.SicCodeDescription;

        if (inputVal === SicCode && SicCodeDescription) {
            return `${inputVal}:\n${formData.SicCodeDescription}`;
        } else {
            return inputVal;
        }
    };
    const sicCodeStyle = {
        flexDirection: 'row',
        inputWidth: '100%',
        containerWidth: '60%',
        alignItems: 'baseline',
        multiline: true,
        inline: true,
    };

    return (
        <>
            {deltas[`SicCode4`] ? (
                <DeltaField
                    delta={deltas[`SicCode4`]}
                    getValue={() => _getValue('SicCode4')}
                    inputWidth="100%"
                    containerWidth="59%"
                />
            ) : (
                <FormInput
                    label="SIC Code 4"
                    name="SicCode4"
                    team="global"
                    labelColor={deltas['SicCode4'] && '#239d56'}
                    getValue={() => _getValue('SicCode4')}
                    {...inputProps}
                    whiteSpace={'pre-line'}
                    type="text"
                    {...sicCodeStyle}
                />
            )}
            {deltas[`SicCode6`] ? (
                <DeltaField
                    delta={deltas[`SicCode6`]}
                    getValue={() => _getValue('SicCode6')}
                    inputWidth="100%"
                    containerWidth="59%"
                />
            ) : (
                <FormInput
                    label="SIC Code 6"
                    name="SicCode6"
                    team="global"
                    labelColor={deltas['SicCode6'] && '#239d56'}
                    getValue={() => _getValue('SicCode6')}
                    {...inputProps}
                    whiteSpace={'pre-line'}
                    type="text"
                    {...(formData.SicCode === formData[`SicCode6`] &&
                        formData.SicCodeDescription !== undefined &&
                        formData.SicCodeDescription !== null &&
                        sicCodeStyle)}
                />
            )}
            {deltas[`SicCode8`] ? (
                <DeltaField
                    delta={deltas[`SicCode8`]}
                    getValue={() => _getValue('SicCode8')}
                    inputWidth="100%"
                    containerWidth="59%"
                />
            ) : (
                <FormInput
                    label="SIC Code 8"
                    name="SicCode8"
                    team="global"
                    labelColor={deltas['SicCode8'] && '#239d56'}
                    getValue={() => _getValue('SicCode8')}
                    {...inputProps}
                    whiteSpace={'pre-line'}
                    type="text"
                    {...(formData.SicCode === formData[`SicCode8`] &&
                        formData.SicCodeDescription !== undefined &&
                        formData.SicCodeDescription !== null &&
                        sicCodeStyle)}
                />
            )}
        </>
    );
}

class GlobalMdmFields extends Component {
    constructor(props) {
        super(props);
        this.state = {
            namesInput: 0,
            country: '',
            region: '',
        };
    }

    selectCountry(val) {
        this.setState({ country: val });
    }

    selectRegion(val) {
        this.setState({ region: val });
    }

    addNameInput = () => {
        const { namesInput } = this.state;

        if (namesInput < 2) {
            this.setState({
                namesInput: namesInput + 1,
            });
        }
    };

    removeNameInput = () => {
        const { namesInput } = this.state;

        if (namesInput >= 1) {
            this.setState({
                namesInput: namesInput - 1,
            });
        }
    };

    componentDidMount() {
        if (this.props.readOnly) {
            this.setState({ namesInput: 3 });
        }
    }
    componentWillReceiveProps(newProps) {
        if (newProps.formData != this.props.formData) {
            this.setState({
                PostalCode: newProps.formData.PostalCode,
            });
        }
    }
    handleChange = (val, e) => {
        e.persist();
        const {
            name,
            value,
            validity: { valid },
        } = e.target;

        if (valid) this.setState({ [name]: value });
    };

    handleTextChange = (val, e) => {
        e.persist();
        const { name, value } = e.target;
        this.setState({ [name]: value });
    };

    render() {
        let {
            readOnly,
            formData = {},
            staticDropDownData = [],
            deltas = {},
            hide,
            editableInputs = {},
            taxEditable = false,
            dunsEditable = false,
            countryNotEditable = false,
            hideTitle = false,
        } = this.props;
        if (!formData) formData = {};

        const selectorProps = readOnly ? { disabled: true } : null;
        const inputProps = readOnly
            ? {
                inline: true,
                variant: 'outline',
                readOnly: true,
            }
            : {
                inline: false,
                readOnly: false,
                onBlur: this.props.onFieldChange,
            };

        const FaxAndTaxProps = readOnly
            ? {
                inline: true,
                variant: 'outline',
                readOnly: true,
            }
            : {
                inline: false,
                readOnly: false,
                onBlur: this.props.onFieldChange,
            };

        const taxInputProps = !taxEditable
            ? {
                inline: true,
                variant: 'outline',
                readOnly: true,
            }
            : {
                inline: true,
                readOnly: false,
                onChange: this.props.onFieldChange,
            };

        const dunsInputProps =
            dunsEditable || taxEditable
                ? {
                    inline: false,
                    readOnly: false,
                    onChange: this.props.onFieldChange,
                    onBlur: this.props.onDunsNewInput,
                }
                : {
                    inline: true,
                    variant: 'outline',
                    readOnly: true,
                };
        const emailProps = readOnly
            ? {
                inline: true,
                variant: 'outline',
                readOnly: true,
            }
            : {
                inline: false,
                readOnly: false,
                onChange: this.props.onFieldChange,
            };

        const { namesInput } = this.state;

        const country = idx(CountryRegionData, (_) => _[formData.Country]) || {};

        const region = idx(country, (_) => _.regions[formData.Region]) || {};
        const isCountry_US = formData.Country && formData.Country.toLowerCase() === 'us';
        const isCountry_US_OR_CA =
            formData.Country &&
            (formData.Country.toLowerCase() === 'us' ||
                formData.Country.toLowerCase() === 'ca');
        return (
            <Fragment>
                {!hideTitle && (
                    <Text
                        m="10px 0 24px 5%"
                        ml={readOnly ? '2%' : '5%'}
                        fontWeight="light"
                        color="lightBlue"
                        fontSize="24px">
                        {this.props.title ? this.props.title : 'MDM GLOBAL FIELDS'}
                    </Text>
                )}

                <Box flexDirection="row" justifyContent="center">
                    <Box width={1 / 2} mx="auto" alignItems="center">
                        {deltas[`Name1`] ? (
                            <DeltaField delta={deltas[`Name1`]} label="Name" />
                        ) : (
                            <FormInput
                                display="flex"
                                flex={1}
                                flexDirection="row"
                                label="Name"
                                name="Name1"
                                maxLength={35}
                                team="global"
                                pattern="[^~*:;\^\\><|]+"
                                onChange={this.handleChange}
                                showRequired
                                value={
                                    this.state.Name1 !== undefined
                                        ? this.state.Name1
                                        : this.props.formData && this.props.formData.Name1
                                }
                                rightComponent={() =>
                                    !readOnly && <AddIcon onPress={this.addNameInput} />
                                }
                                {...inputProps}
                            />
                        )}

                        {times(namesInput, (index) => {
                            index += 1;
                            if (readOnly) {
                                return deltas[`Name${index + 1}`] ? (
                                    <DeltaField delta={deltas[`Name${index + 1}`]} />
                                ) : formData[`Name${index + 1}`] ? (
                                    <FormInput
                                        key={`name${index}`}
                                        label={`Name ${index + 1}`}
                                        name={`Name${index + 1}`}
                                        maxLength={35}
                                        team="global"
                                        error={
                                            this.props.formErrors
                                                ? this.props.formErrors[
                                                `Name${index + 1}`
                                                ]
                                                : null
                                        }
                                        value={formData[`Name${index + 1}`]}
                                        rightComponent={() =>
                                            !readOnly && (
                                                <RemoveIcon
                                                    onPress={this.removeNameInput}
                                                />
                                            )
                                        }
                                        {...inputProps}
                                    />
                                ) : null;
                            } else {
                                return (
                                    <FormInput
                                        key={`name${index}`}
                                        label={`Name ${index + 1}`}
                                        name={`Name${index + 1}`}
                                        maxLength={35}
                                        team="global"
                                        error={
                                            this.props.formErrors
                                                ? this.props.formErrors[
                                                `Name${index + 1}`
                                                ]
                                                : null
                                        }
                                        pattern="[^~*:;\^\\><|]+"
                                        onChange={this.handleChange}
                                        value={
                                            this.state[`Name${index + 1}`] !== undefined
                                                ? this.state[`Name${index + 1}`]
                                                : this.props.formData &&
                                                this.props.formData[`Name${index + 1}`]
                                        }
                                        rightComponent={() =>
                                            !readOnly && (
                                                <RemoveIcon
                                                    onPress={this.removeNameInput}
                                                />
                                            )
                                        }
                                        {...inputProps}
                                    />
                                );
                            }
                        })}

                        {deltas[`Street`] ? (
                            <DeltaField delta={deltas[`Street`]} />
                        ) : (
                            <FormInput
                                label="Street"
                                name="Street"
                                maxLength={35}
                                team="global"
                                error={
                                    this.props.formErrors
                                        ? this.props.formErrors['Street']
                                        : null
                                }
                                showRequired
                                pattern="[^~*:;\^\\><|]+"
                                onChange={this.handleChange}
                                value={
                                    this.state.Street !== undefined
                                        ? this.state.Street
                                        : this.props.formData &&
                                        this.props.formData.Street
                                }
                                {...inputProps}
                            />
                        )}

                        {deltas[`Street2`] ? (
                            <DeltaField delta={deltas[`Street2`]} label="Street 2" />
                        ) : (
                            <FormInput
                                label="Street 2"
                                name="Street2"
                                maxLength={35}
                                team="global"
                                pattern="[^~*:;\^\\><|]+"
                                onChange={this.handleChange}
                                value={
                                    this.state.Street2 !== undefined
                                        ? this.state.Street2
                                        : this.props.formData &&
                                        this.props.formData.Street2
                                }
                                {...inputProps}
                            />
                        )}

                        {deltas[`City`] ? (
                            <DeltaField delta={deltas[`City`]} />
                        ) : (
                            <FormInput
                                label="City"
                                name="City"
                                maxLength={35}
                                team="global"
                                error={
                                    this.props.formErrors
                                        ? this.props.formErrors['City']
                                        : null
                                }
                                required
                                onChange={this.handleTextChange}
                                value={
                                    this.state.City !== undefined
                                        ? this.state.City
                                        : this.props.formData && this.props.formData.City
                                }
                                {...inputProps}
                                autoComplete="off"
                            />
                        )}
                        <CountryRegionSelector
                            label="Country"
                            name="Country"
                            team="global"
                            inline={countryNotEditable}
                            readOnly={countryNotEditable}
                            formErrors={
                                this.props.formErrors
                                    ? this.props.formErrors['Country']
                                    : null
                            }
                            isRequired={true}
                            autoComplete="off"
                            value={formData.Country}
                            selectorType="Country"
                            upperCase
                            onFieldChange={this.props.onFieldChange}
                        />
                        <CountryRegionSelector
                            label="Region"
                            name="Region"
                            team="global"
                            inline={false}
                            readOnly={false}
                            selectorType="region"
                            formErrors={
                                this.props.formErrors
                                    ? this.props.formErrors['Region']
                                    : null
                            }
                            isRequired={isCountry_US ? true : false}
                            Country={
                                this.state.Country != undefined
                                    ? this.state.Country
                                    : formData.Country || ''
                            }
                            value={formData.Region || ''}
                            onFieldChange={this.props.onFieldChange}
                            {...inputProps}
                            upperCase
                            autoComplete="off"
                        />

                        {deltas[`PostalCode`] ? (
                            <DeltaField delta={deltas[`PostalCode`]} />
                        ) : (
                            <FormInput
                                label="Postal Code"
                                name="PostalCode"
                                maxLength={10}
                                team="global"
                                error={
                                    this.props.formErrors
                                        ? this.props.formErrors['isPostalCodeValid']
                                        : null
                                }
                                pattern={isCountry_US ? '[0-9-]+' : '[a-zA-Z0-9-]+'}
                                onChange={this.handleChange}
                                showRequired={isCountry_US_OR_CA}
                                value={
                                    this.state.PostalCode !== undefined
                                        ? this.state.PostalCode
                                        : this.props.formData &&
                                        this.props.formData.PostalCode
                                }
                                {...inputProps}
                                autoComplete="off"
                                placeholder={
                                    isCountry_US
                                        ? 'Only Numeric Values'
                                        : 'Alpha-Numeric Values'
                                }
                            />
                        )}

                        {!readOnly && (
                            <Fragment>
                                <FormInput
                                    label="Telephone"
                                    name="Telephone"
                                    team="global"
                                    error={
                                        this.props.formErrors
                                            ? this.props.formErrors['Telephone']
                                            : null
                                    }
                                    {...FaxAndTaxProps}
                                    disabled={readOnly}
                                    type="text"
                                    maxLength={20}
                                    pattern="[0-9*#+\-\(\)]+"
                                    onChange={this.handleChange}
                                    value={
                                        this.state.Telephone !== undefined
                                            ? this.state.Telephone
                                            : this.props.formData &&
                                            this.props.formData.Telephone
                                    }
                                />
                                <FormInput
                                    label="Fax"
                                    name="Fax"
                                    team="global"
                                    error={
                                        this.props.formErrors
                                            ? this.props.formErrors['Fax']
                                            : null
                                    }
                                    {...FaxAndTaxProps}
                                    disabled={readOnly}
                                    maxLength={30}
                                    type="text"
                                    pattern="[0-9*#+\-\(\)]+"
                                    onChange={this.handleChange}
                                    value={
                                        this.state.Fax !== undefined
                                            ? this.state.Fax
                                            : this.props.formData &&
                                            this.props.formData.Fax
                                    }
                                />

                                <FormInput
                                    label="Email"
                                    name="Email"
                                    maxLength={241}
                                    team="global"
                                    value={
                                        this.state.Email !== undefined
                                            ? this.state.Email
                                            : this.props.formData &&
                                            (this.props.formData.Email ||
                                                this.props.formData.ContactEmailAddress)
                                    }
                                    error={
                                        this.props.formErrors
                                            ? this.props.formErrors['Email']
                                            : null
                                    }
                                    disabled={readOnly}
                                    {...emailProps}
                                    autoComplete="off"
                                />
                            </Fragment>
                        )}
                    </Box>
                    <Box width={1 / 2} mx="auto" alignItems="center">
                        {countryNotEditable && (
                            <>
                                {deltas[`DunsNumber`] ? (
                                    <DeltaField delta={deltas[`DunsNumber`]} />
                                ) : (
                                    <FormInput
                                        label="DUNS Number"
                                        name="DunsNumber"
                                        team="global"
                                        maxLength={9}
                                        pattern="[0-9]+"
                                        error={
                                            this.props.formErrors
                                                ? this.props.formErrors['isDunsValid']
                                                : null
                                        }
                                        labelColor={deltas['DunsNumber'] && '#239d56'}
                                        value={formData.DunsNumber}
                                        {...dunsInputProps}
                                        type="text"
                                    />
                                )}
                                {deltas[`TaxNumber`] ? (
                                    <DeltaField delta={deltas[`TaxNumber`]} />
                                ) : (
                                    <FormInput
                                        label="Tax Number 1"
                                        name="TaxNumber"
                                        team="global"
                                        maxLength={16}
                                        delta={deltas['TaxNumber']}
                                        getValue={() =>
                                            formData.TaxNumber || formData.Taxnumber
                                        }
                                        {...(editableInputs.TaxNumber === true
                                            ? {
                                                inline: true,
                                                readOnly: false,
                                                onChange: this.props.onFieldChange,
                                            }
                                            : taxInputProps)}
                                        type="text"
                                    />
                                )}
                                <SicCodeInputs
                                    deltas={deltas}
                                    formData={formData}
                                    inputProps={taxInputProps}
                                />

                                {deltas[`NaicsCode`] ? (
                                    <DeltaField
                                        delta={deltas[`NaicsCode`]}
                                        getValue={() => {
                                            const inputVal = deltas.NaicsCode
                                                ? deltas.NaicsCode.updatedValue
                                                : formData.NaicsCode;
                                            const codeDescription =
                                                formData.NaicsCodeDescription;

                                            if (codeDescription) {
                                                return `${inputVal}: \n ${codeDescription}`;
                                            } else {
                                                return inputVal;
                                            }
                                        }}
                                        inputWidth="100%"
                                        containerWidth="59%"
                                    />
                                ) : (
                                    <FormInput
                                        label="NAICS Code"
                                        name="NaicsCode"
                                        maxLength={10}
                                        team="global"
                                        labelColor={deltas['NaicsCode'] && '#239d56'}
                                        getValue={() => {
                                            const inputVal = deltas.NaicsCode
                                                ? deltas.NaicsCode.updatedValue
                                                : formData.NaicsCode;
                                            const codeDescription =
                                                formData.NaicsCodeDescription;

                                            if (codeDescription) {
                                                return `${inputVal}: \n ${codeDescription}`;
                                            } else {
                                                return inputVal;
                                            }
                                        }}
                                        {...taxInputProps}
                                        {...(formData.NaicsCodeDescription !==
                                            undefined &&
                                            formData.NaicsCodeDescription !== null && {
                                            flexDirection: 'row',
                                            inputWidth: '100%',
                                            containerWidth: '60%',
                                            alignItems: 'baseline',
                                            multiline: true,
                                            inline: true,
                                        })}
                                        type="text"
                                    />
                                )}
                                {deltas[`VatRegNo`] ? (
                                    <DeltaField delta={deltas[`VatRegNo`]} />
                                ) : (
                                    <FormInput
                                        label="Vat Reg No"
                                        name="VatRegNo"
                                        maxLength={25}
                                        team="global"
                                        labelColor={deltas['VatRegNo'] && '#239d56'}
                                        getValue={() =>
                                            deltas['VatRegNo']
                                                ? deltas['VatRegNo'].UpdatedValue
                                                : formData.VatRegNo
                                        }
                                        {...(editableInputs.VatRegNo
                                            ? {
                                                inline: true,
                                                readOnly: false,
                                                onChange: this.props.onFieldChange,
                                            }
                                            : taxInputProps)}
                                        type="text"
                                    />
                                )}

                                <FormSelect
                                    label="Category"
                                    name="CategoryTypeId"
                                    team="global"
                                    onChange={this.props.onFieldChange}
                                    required
                                    error={
                                        this.props.formErrors
                                            ? this.props.formErrors['CategoryTypeId']
                                            : null
                                    }
                                    variant="solid"
                                    value={this.props.formData['CategoryTypeId']}>
                                    <option hidden={true}>Choose from...</option>
                                    {CategoryTypes.map((category, index) => (
                                        <option
                                            key={`category-${index}`}
                                            value={category.id}>
                                            {category.name}
                                        </option>
                                    ))}
                                </FormSelect>
                                <DynamicSelect
                                    arrayOfData={
                                        (staticDropDownData &&
                                            staticDropDownData.ApolloMarketingSegmentationType) ||
                                        []
                                    }
                                    label="Market Segmentation "
                                    name="MarketingSegmentationTypeId"
                                    isRequired={true}
                                    formErrors={
                                        this.state.formErrors
                                            ? this.state.formErrors[
                                            'MarketingSegmentationTypeId'
                                            ]
                                            : null
                                    }
                                    onFieldChange={this.props.onFieldChange}
                                    value={
                                        this.state.formData
                                            ? this.state.formData[
                                            'MarketingSegmentationTypeId'
                                            ]
                                            : this.props.formData[
                                            'MarketingSegmentationTypeId'
                                            ]
                                    }
                                    inputProps={selectorProps}
                                />

                                {/*{deltas[`FamilyId`] ? (*/}
                                {/*    <DeltaField delta={deltas[`FamilyId`]} />*/}
                                {/*) : (*/}
                                {/*    <FormInput*/}
                                {/*        label="Family Id"*/}
                                {/*        name="FamilyId"*/}
                                {/*        maxLength={10}*/}
                                {/*        team="FamilyId"*/}
                                {/*        error={*/}
                                {/*            this.props.formErrors*/}
                                {/*                ? this.props.formErrors['FamilyId']*/}
                                {/*                : null*/}
                                {/*        }*/}
                                {/*        required*/}
                                {/*        onChange={this.handleTextChange}*/}
                                {/*        value={*/}
                                {/*            this.state.FamilyId !== undefined*/}
                                {/*                ? this.state.FamilyId*/}
                                {/*                : this.props.formData &&*/}
                                {/*                  this.props.formData.FamilyId*/}
                                {/*        }*/}
                                {/*        {...inputProps}*/}
                                {/*        autoComplete="off"*/}
                                {/*    />*/}
                                {/*)}*/}
                                {/*{deltas[`FamilyName`] ? (*/}
                                {/*    <DeltaField delta={deltas[`FamilyName`]} />*/}
                                {/*) : (*/}
                                {/*    <FormInput*/}
                                {/*        label="Family Name"*/}
                                {/*        name="FamilyName"*/}
                                {/*        multiline*/}
                                {/*        maxLength={250}*/}
                                {/*        team="FamilyName"*/}
                                {/*        style={{ width: '30%', lineHeight: '2.5' }}*/}
                                {/*        error={*/}
                                {/*            this.props.formErrors*/}
                                {/*                ? this.props.formErrors['FamilyName']*/}
                                {/*                : null*/}
                                {/*        }*/}
                                {/*        required*/}
                                {/*        onChange={this.handleTextChange}*/}
                                {/*        value={*/}
                                {/*            this.state.FamilyName !== undefined*/}
                                {/*                ? this.state.FamilyName*/}
                                {/*                : this.props.formData &&*/}
                                {/*                  this.props.formData.FamilyName*/}
                                {/*        }*/}
                                {/*        numberOfLines={*/}
                                {/*            this.props.formData &&*/}
                                {/*            this.props.formData.FamilyName &&*/}
                                {/*            this.props.formData.FamilyName.length > 36*/}
                                {/*                ? 3*/}
                                {/*                : 1*/}
                                {/*        }*/}
                                {/*        {...inputProps}*/}
                                {/*        autoComplete="off"*/}
                                {/*    />*/}
                                {/*)}*/}
                            </>
                        )}
                        {this.props.children && this.props.children}
                    </Box>
                </Box>
            </Fragment>
        );
    }
}

export default GlobalMdmFields;
