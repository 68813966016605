/**
 * @prettier
 */

import { all, call, takeLatest, takeEvery, fork, put, select } from 'redux-saga/effects';
import {
    getPartnersSuccess,
    getPartnersFailed,
    getApolloPartnersSuccess,
    getApolloPartnersFailed,
    fetchPartnerCustomerDataSuccess,
    getWFApolloPartnersSuccess,
    getWFApolloPartnersFailed,
    createApolloPartnerRequestFail,
    approveApolloPartnerRequestSuccess,
    approveApolloPartnerRequestFail,
    getJDEPartnerSuccess,
    getJDEPartnerFail,
    searchJdePartnerSuccess,
    getWFJDEPartnersSuccess,
    getWFJDEPartnersFailed,
} from '../../appRedux/actions/Partners.js';
import {
    GET_POINTMAN_PARTNERS,
    GET_APOLLO_PARTNERS,
    FAILED_BGCOLOR,
    FETCH_PARTNER_DETAILS,
    CREATE_APOLLO_PARTNER_REQUEST,
    GET_WF_APOLLO_PARTNERS,
    APPROVE_APOLLO_PARTNERS,
    SUCCESS_BGCOLOR,
    GET_JDE_PARTNERS,
    SEARCH_JDE_PARTNERS,
    GET_WF_JDE_PARTNERS,
} from '../../constants/ActionTypes';
import { showMessage as showToast } from '../../appRedux/actions/Toast';
import { ajaxPostRequest, endpoints, ajaxPutFileRequest } from './config';

//POINTMAN
export function* fetchPartners(data) {
    const userId = localStorage.getItem('userId');

    var resp = { msg: '', color: '#FFF' };
    const url = endpoints.fetchPartners;
    var jsonBody = data.payload || {
        UserId: userId,
    };

    try {
        const result = yield call(ajaxPostRequest, url, jsonBody);

        if (result.IsSuccess) {
            let resultData = result.ResultData;
            if (data.payload && data.payload) {
                resultData['filters'] = data.payload;
            }
            yield put(getPartnersSuccess(resultData));
        } else {
            let resultData = result.ResultData;

            yield put(
                showToast({
                    msg: 'No partners found',
                    color: FAILED_BGCOLOR,
                })
            );
            resp = { msg: 'No data found', color: FAILED_BGCOLOR };
            yield put(getPartnersFailed(resultData));
        }
    } catch (error) {
        yield put(
            showToast({
                msg: 'Error fetching Partners',
                color: FAILED_BGCOLOR,
            })
        );
        resp = { msg: error, color: FAILED_BGCOLOR };
        yield put(getPartnersFailed(resp));
    }
}

//APOLLO
export function* fetchApolloPartners(data) {
    const userId = localStorage.getItem('userId');

    var resp = { msg: '', color: '#FFF' };
    // console.log(data, endpoints);
    let url = '';
    if (data && data.payload && data.payload.SystemTypeId == 2) {
        url = endpoints.fetchOlympusPartners;
    } else {
        url = endpoints.fetchApolloPartners;
    }
    // console.log(url);
    var jsonBody = data.payload || {
        UserId: userId,
    };
    try {
        const result = yield call(ajaxPostRequest, url, jsonBody);

        if (result.IsSuccess) {
            let resultData = result.ResultData;
            if (data.payload && data.payload) {
                resultData['filters'] = data.payload;
            }
            yield put(getApolloPartnersSuccess(resultData));
            // if (resultData.SapPartners.length == 0) {
            //     yield put(
            //         showToast({
            //             msg: 'Partner Data not found',
            //             color: FAILED_BGCOLOR,
            //         })
            //     );
            // }
        } else {
            let resultData = result.ResultData;
            // resp = { msg: 'No data found', color: FAILED_BGCOLOR };
            yield put(getApolloPartnersFailed(resultData));
            if (result && result.OperationResultMessages.length > 0) {
                let message = result.OperationResultMessages[0].Message;
                yield put(
                    showToast({
                        msg: message,
                        color: FAILED_BGCOLOR,
                    })
                );
            }
        }
    } catch (error) {
        console.log(error);
        yield put(
            showToast({
                msg: 'Error fetching Partners',
                color: FAILED_BGCOLOR,
            })
        );
        resp = { msg: error, color: FAILED_BGCOLOR };
        yield put(getPartnersFailed(resp));
    }
}

// get wf partner task data
export function* fetchWorkflowApolloPartner(data) {
    const userId = localStorage.getItem('userId');
    var resp = { msg: '', color: '#FFF' };
    let url = '';
    if (data && data.payload && data.payload.SystemTypeId == 2) {
        url = endpoints.fetchwfOlymmpusPartners;
    } else url = endpoints.fetchwfApolloPartners;
    var jsonBody = data.payload || {
        UserId: userId,
    };
    try {
        const result = yield call(ajaxPostRequest, url, jsonBody);
        if (result.IsSuccess) {
            let resultData = result.ResultData;
            if (data.payload && data.payload) {
                resultData['filters'] = data.payload;
            }
            yield put(getWFApolloPartnersSuccess(resultData));
        } else {
            yield put(
                showToast({
                    msg:
                        result.OperationResultMessages &&
                            result.OperationResultMessages.length > 0
                            ? result.OperationResultMessages[0].Message
                            : '',
                    color: FAILED_BGCOLOR,
                })
            );
            resp = { msg: 'No data found', color: FAILED_BGCOLOR };
            if (
                (result &&
                    result.ResultData &&
                    result.ResultData.SapPartners &&
                    result.ResultData.SapPartners.length > 0) ||
                (result.ResultData.PartnerEdits &&
                    result.ResultData.PartnerEdits.length > 0)
            ) {
                let resultData = result.ResultData;
                resultData['IsSuccess'] = false;
                yield put(getWFApolloPartnersSuccess(resultData));
            } else {
                yield put(getWFApolloPartnersFailed(resp));
            }
        }
    } catch (error) {
        yield put(
            showToast({
                msg: 'Error fetching Partners',
                color: FAILED_BGCOLOR,
            })
        );
        resp = { msg: error, color: FAILED_BGCOLOR };
        yield put(getPartnersFailed(resp));
    }
}

// Apollo - get a name,street etc details of a partner
export function* fetchPartnerDetails(data) {
    console.log(data.payload.value, data.payload.partnerOperation, data);
    if (data.payload.partnerOperation == 'Add') {
        data.payload.partnerScreenSearchfields['WorkflowOrCustomerNumber'] =
            data.payload.value;
        data.payload.partnerScreenSearchfields['UserId'] = localStorage.getItem('userId');
    }
    const jsonBody = data.payload.partnerScreenSearchfields;
    var resp = { msg: '', color: '#FFF' };
    let url = '';
    if (data && data.payload && data.payload.SystemTypeId == 2) {
        url = endpoints.searchOlympusPartner;
    } else {
        url = endpoints.searchApolloPartner;
    }
    // console.log(url,"===========",data && data.payload && data.payload.SystemTypeId == 2)
    const row = data.payload;
    // console.log('payloooood=-', data.payload);
    try {
        const result = yield call(ajaxPostRequest, url, jsonBody);
        if (result.IsSuccess) {
            let resultData = result.ResultData;
            yield put(
                fetchPartnerCustomerDataSuccess({
                    edits: row,
                    result: resultData,
                })
            );
            yield put(
                showToast({
                    msg: 'Fetched successfully',
                    color: SUCCESS_BGCOLOR,
                })
            );

            // yield put(
            //     fetchPartnerCustomerDataSuccess({
            //         edits: row,
            //         result: {
            //             PartnerCustomerNumber: row.value,
            //             MdmNumber: 'MDMCM0001456',
            //             PartnerCustomerName: 'Cardinal East',
            //             PartnerCustomerAddress: '123 Main Street',
            //         },
            //     })
            // );
        } else {
            let message =
                result && result.OperationResultMessages.length > 0
                    ? result.OperationResultMessages[0].Message
                    : '';
            yield put(
                showToast({
                    msg: message ? message : 'No partners details found',
                    color: FAILED_BGCOLOR,
                })
            );
            yield put(
                fetchPartnerCustomerDataSuccess({
                    edits: row,
                    result: result.ResultData,
                })
            );
            resp = { msg: 'No data found', color: FAILED_BGCOLOR };
        }
    } catch (error) {
        console.log(error);
        yield put(
            showToast({
                msg: 'Error fetching Partners',
                color: FAILED_BGCOLOR,
            })
        );
        yield put(
            fetchPartnerCustomerDataSuccess({
                edits: row,
                result: {},
            })
        );
        resp = { msg: error, color: FAILED_BGCOLOR };
    }
}

export function* UploadFiles(files, workflowId, otherRequest = false) {
    let filesBody = {};
    let filesData = files.map((file) => {
        const { DocumentType, DocumentName, data, AttachmentName } = file;
        filesBody[DocumentName] = data;

        let _data = {
            userId: localStorage.getItem('userId'),
            workflowId,
            documentType: DocumentType,
            documentName: DocumentName,
        };

        if (otherRequest) {
            _data.AttachmentName = AttachmentName;
            delete _data.documentType;
        }

        return _data;
    });

    const url = endpoints.addDocument;
    const result = yield call(ajaxPostRequest, url, filesData);
    if (!result.IsSuccess) {
        yield put(
            showToast({
                msg: workflowId + ': File Upload Failed',
                color: FAILED_BGCOLOR,
            })
        );

        throw new Error(result.OperationResultMessages[0]);
    }

    const { ResultData: resp } = result;
    // console.log('test=====', resp);
    let requests = resp.map(({ PreSignedURL, DocumentName }) => {
        event.preventDefault();
        var options = {
            headers: {
                'Content-Type': filesBody[DocumentName],
            },
        };
        return fork(ajaxPutFileRequest, `${PreSignedURL}`, filesBody[DocumentName], {
            'Content-Type': 'multipart/form-data',
            'x-amz-acl': 'public-read',
        });
    });
    var uploadedFiles;
    if (requests != null && requests != undefined && requests.length != 0) {
        uploadedFiles = yield all(requests);
    }
    // console.log(uploadedFiles);

    if (uploadedFiles) {
        yield put(
            showToast({
                msg: workflowId + ': File Upload Successful',
                color: SUCCESS_BGCOLOR,
            })
        );
    } else {
        yield put(
            showToast({
                msg: workflowId + ': File Upload Unsuccessful',
                color: FAILED_BGCOLOR,
            })
        );
    }
    // return uploadedFiles;
}

export function* createApolloPartner(data) {
    const { history, postdata } = data.payload;
    const url = endpoints.createApolloPartners;
    var resp = { msg: '', color: '#FFF' };
    // console.log(data);
    try {
        // yield put(
        //     showToast({
        //         msg: 'Saving Partner Workflow',
        //         color: '#2980b9',
        //     })
        // );
        const result = yield call(ajaxPostRequest, url, postdata);
        if (result.IsSuccess) {
            let resultData = result.ResultData;
            if (postdata.files && postdata.files.length > 0) {
                // if (files && files.length > 0) {
                // yield put(
                //     showToast({
                //         msg: 'Uploading files',
                //         color: '#2980b9',
                //     })
                // );

                yield* UploadFiles(postdata.files, postdata.WorkflowId);
                // }
            }

            // console.log('resultdata', resultData);
            history.push({
                pathname: '/my-requests',
            });
        } else {
            window.scrollTo(0, 0);
            if (result.OperationResultMessages.length > 0) {
                yield put(
                    showToast({
                        msg:
                            result.OperationResultMessages &&
                                result.OperationResultMessages.length > 0
                                ? result.OperationResultMessages[0].Message
                                : '',
                        color: FAILED_BGCOLOR,
                    })
                );
            }
            yield put(createApolloPartnerRequestFail(postdata.PartnerEdits));

            resp = { msg: 'No data found', color: FAILED_BGCOLOR };
        }
    } catch (error) {
        yield put(
            showToast({
                msg: 'Error Create Partners',
                color: FAILED_BGCOLOR,
            })
        );
        resp = { msg: error, color: FAILED_BGCOLOR };
    }
}

export function* apolloApproveRejectPartners(data) {
    const postdata = data.payload;
    const url = endpoints.approveRejectApolloPartner;
    var resp = { msg: '', color: '#FFF' };
    // console.log(postdata);
    try {
        const result = yield call(ajaxPostRequest, url, postdata);
        if (result.IsSuccess) {
            window.scrollTo(0, 0);
            if (postdata.files && postdata.files.length > 0)
                yield* UploadFiles(postdata.files, postdata.WorkflowId);
            yield put(
                showToast({
                    msg:
                        postdata &&
                            postdata.WorkflowTaskModel &&
                            postdata.WorkflowTaskModel.WorkflowTaskOperationTypeId == 2
                            ? 'Partner rejected'
                            : 'Partner approved',
                    color:
                        postdata.WorkflowTaskModel.WorkflowTaskOperationTypeId == 2
                            ? FAILED_BGCOLOR
                            : SUCCESS_BGCOLOR,
                })
            );
            console.log(result.ResultData, '====== result data');
            yield put(approveApolloPartnerRequestSuccess(result.ResultData));
            // let fetchPartnerData= {
            //     WorkflowId: postdata.WorkflowId,
            //     UserId: localStorage.getItem('userId'),
            // }
            // console.log(fetchPartnerData)
            // yield takeLatest(fetchApolloPartners(fetchPartnerData))
        } else {
            window.scrollTo(0, 0);
            yield put(
                showToast({
                    msg:
                        result.OperationResultMessages &&
                            result.OperationResultMessages.length > 0
                            ? result.OperationResultMessages[0].Message
                            : '',
                    color: FAILED_BGCOLOR,
                })
            );
            resp = { msg: 'No data found', color: FAILED_BGCOLOR };
            yield put(approveApolloPartnerRequestFail(result.ResultData));
        }
    } catch (error) {
        yield put(
            showToast({
                msg: 'Error Approval',
                color: FAILED_BGCOLOR,
            })
        );
        resp = { msg: error, color: FAILED_BGCOLOR };
    }
}

//JDE

export function* fetchJDEPartners(data) {
    const userId = localStorage.getItem('userId');

    var resp = { msg: '', color: '#FFF' };
    let url = endpoints.getJdePartners;
    var jsonBody = data.payload || {
        UserId: userId,
    };
    try {
        const result = yield call(ajaxPostRequest, url, jsonBody);

        if (result.IsSuccess) {
            let resultData = result.ResultData;

            if (resultData.SapPartners?.length > 0) {
                yield put(getJDEPartnerSuccess(resultData));
            } else {
                // If no partners found . We should handle it as the failure case
                yield put(getJDEPartnerFail(resultData));
                let message ="Cannot find record for Customer: "+resultData.WorkflowOrCustomerNumber+" with Source: JDE"
                if (result && result.OperationResultMessages.length > 0) {
                    message = result.OperationResultMessages[0].Message;
                }
                yield put(
                    showToast({
                        msg: message,
                        color: FAILED_BGCOLOR,
                    })
                );

            }
            // if (resultData.SapPartners.length == 0) {
            //     yield put(
            //         showToast({
            //             msg: 'Partner Data not found',
            //             color: FAILED_BGCOLOR,
            //         })
            //     );
            // }
        } else {
            let resultData = result.ResultData;
            // resp = { msg: 'No data found', color: FAILED_BGCOLOR };
            yield put(getJDEPartnerFail(resultData));
            if (result && result.OperationResultMessages.length > 0) {
                let message = result.OperationResultMessages[0].Message;
                yield put(
                    showToast({
                        msg: message,
                        color: FAILED_BGCOLOR,
                    })
                );
            }
        }
    } catch (error) {
        console.log(error);
        yield put(
            showToast({
                msg: 'Error fetching Partners',
                color: FAILED_BGCOLOR,
            })
        );
        resp = { msg: error, color: FAILED_BGCOLOR };
        yield put(getJDEPartnerFail(resp));
    }
}

// Apollo - get a name,street etc details of a partner
export function* searchJdeDetails(data) {
    var resp = { msg: '', color: '#FFF' };

    const userId = localStorage.getItem('userId');
    let url = endpoints.searchJDEPartners;
    const row = data.payload;
    let jsonBody = {
        UserId: userId,
        WorkflowOrCustomerNumber: row.value,
    };
    // console.log('payloooood=-', data.payload);
    try {
        const result = yield call(ajaxPostRequest, url, jsonBody);
        if (result.IsSuccess) {
            let resultData = result.ResultData;
            yield put(
                searchJdePartnerSuccess({
                    edits: row,
                    result: resultData,
                })
            );
            yield put(
                showToast({
                    msg: 'Fetched successfully',
                    color: SUCCESS_BGCOLOR,
                })
            );
        } else {
            let message =
                result && result.OperationResultMessages.length > 0
                    ? result.OperationResultMessages[0].Message
                    : '';
            yield put(
                showToast({
                    msg: message ? message : 'No partners details found',
                    color: FAILED_BGCOLOR,
                })
            );
            yield put(
                searchJdePartnerSuccess({
                    edits: row,
                    result: result.ResultData,
                })
            );
            resp = { msg: 'No data found', color: FAILED_BGCOLOR };
        }
    } catch (error) {
        console.log(error);
        yield put(
            showToast({
                msg: 'Error fetching Partners',
                color: FAILED_BGCOLOR,
            })
        );
        yield put(
            searchJdePartnerSuccess({
                edits: row,
                result: {},
            })
        );
        resp = { msg: error, color: FAILED_BGCOLOR };
    }
}

// get jde wf partner task data
export function* fetchWorkflowJDEPartner(data) {
    const userId = localStorage.getItem('userId');
    var resp = { msg: '', color: '#FFF' };
    let url = endpoints.GetJdePartnerWorkflowData;
    var jsonBody = data.payload || {
        UserId: userId,
    };
    try {
        const result = yield call(ajaxPostRequest, url, jsonBody);
        if (result.IsSuccess) {
            let resultData = result.ResultData;

            yield put(getWFJDEPartnersSuccess(resultData));
        } else {
            yield put(
                showToast({
                    msg:
                        result.OperationResultMessages &&
                            result.OperationResultMessages.length > 0
                            ? result.OperationResultMessages[0].Message
                            : '',
                    color: FAILED_BGCOLOR,
                })
            );
            resp = { msg: 'No data found', color: FAILED_BGCOLOR };
            if (
                (result &&
                    result.ResultData &&
                    result.ResultData.SapPartners &&
                    result.ResultData.SapPartners.length > 0) ||
                (result.ResultData.PartnerEdits &&
                    result.ResultData.PartnerEdits.length > 0)
            ) {
                let resultData = result.ResultData;
                resultData['IsSuccess'] = false;
                yield put(getWFJDEPartnersSuccess(resultData));
            } else {
                yield put(getWFJDEPartnersFailed(resp));
            }
        }
    } catch (error) {
        yield put(
            showToast({
                msg: 'Error fetching Partners',
                color: FAILED_BGCOLOR,
            })
        );
        resp = { msg: error, color: FAILED_BGCOLOR };
        yield put(getWFJDEPartnersFailed(resp));
    }
}

export function* getPartnersData() {
    yield takeLatest(GET_POINTMAN_PARTNERS, fetchPartners);
}

export function* getApolloPartnersData() {
    yield takeLatest(GET_APOLLO_PARTNERS, fetchApolloPartners);
}

export function* getPartnerDetailsSaga() {
    yield takeEvery(FETCH_PARTNER_DETAILS, fetchPartnerDetails);
}

export function* createApolloRequest() {
    yield takeLatest(CREATE_APOLLO_PARTNER_REQUEST, createApolloPartner);
}

export function* fetchWFApolloRequest() {
    yield takeLatest(GET_WF_APOLLO_PARTNERS, fetchWorkflowApolloPartner);
}

export function* approvePartners() {
    yield takeLatest(APPROVE_APOLLO_PARTNERS, apolloApproveRejectPartners);
}

export function* getJDEPartnersData() {
    yield takeLatest(GET_JDE_PARTNERS, fetchJDEPartners);
}

export function* searchJdePartnerDetails() {
    yield takeLatest(SEARCH_JDE_PARTNERS, searchJdeDetails);
}

export function* getWorkflowJDEPartner() {
    yield takeLatest(GET_WF_JDE_PARTNERS, fetchWorkflowJDEPartner);
}

const partnersSagas = function* rootSaga() {
    yield all([
        fork(getPartnersData),
        fork(getPartnerDetailsSaga),
        fork(getApolloPartnersData),
        fork(fetchWFApolloRequest),
        fork(createApolloRequest),
        fork(approvePartners),
        fork(getJDEPartnersData),
        fork(searchJdePartnerDetails),
        fork(getWorkflowJDEPartner),
    ]);
};
export default partnersSagas;
