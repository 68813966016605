import React from 'react';

import clsx from 'clsx';
import DeleteIcon from '@material-ui/icons/Delete';
import GlobalFilter from './GlobalFilter';
import IconButton from '@material-ui/core/IconButton';
import { lighten, makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import {
    // Text,
    View,
} from 'react-native';

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                  color: theme.palette.secondary.main,
                  backgroundColor: lighten(theme.palette.secondary.light, 0.85),
              }
            : {
                  color: theme.palette.text.primary,
                  backgroundColor: theme.palette.secondary.dark,
              },
    title: {
        flex: '1 1 100%',
    },
}));

const TableToolbar = (props) => {
    const classes = useToolbarStyles();
    const {
        numSelected,
        addUserHandler,
        deleteUserHandler,
        preGlobalFilteredRows,
        setGlobalFilter,
        globalFilter,
    } = props;
    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}>
            <View
                style={{
                    flex: 1,
                    flexDirection: 'row',
                    paddingLeft: 10,
                    paddingRight: 35,
                    paddingTop: 20,
                    paddingBottom: 12,
                }}>
                <View
                    style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}>
                    {/*<Text*/}
                    {/*  style={{*/}
                    {/*    fontWeight: 'bold',*/}
                    {/*    fontSize: 24,*/}
                    {/*    color: '#10206C'*/}
                    {/*  }}>*/}
                    {/*  Customers*/}
                    {/*</Text>*/}
                </View>
            </View>
            <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
            />
        </Toolbar>
    );
};

TableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
    addUserHandler: PropTypes.func.isRequired,
    deleteUserHandler: PropTypes.func.isRequired,
    setGlobalFilter: PropTypes.func.isRequired,
    preGlobalFilteredRows: PropTypes.array.isRequired,
};

export default TableToolbar;
