import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';

import {
    AliasWorkflowTeamType,
    ColorCodeWorkFlowTaskStateType,
} from '../constants/WorkflowEnums';
import { formatRequestorName } from '../appRedux/sagas/config';

class MultiColorProgressBar extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const parent = this.props;
        let bars =
            parent.readings &&
            parent.readings.length != 0 &&
            parent.readings.map(function (item, i) {
                var itemName = AliasWorkflowTeamType[item.TeamId];
                var modifiedUser = item.ModifiedUser;
                var itemColor =
                    ColorCodeWorkFlowTaskStateType[item.WorkflowTaskStateTypeId];
                var chevronText = 'Assigned To Team';
                if (
                    item.AssignedUserId != null &&
                    (item.WorkflowTaskStateTypeId === 1 ||
                        item.WorkflowTaskStateTypeId === 2 ||
                        item.WorkflowTaskStateTypeId === 3)
                )
                    chevronText =
                        'Assigned to ' + formatRequestorName(item.AssignedUserId);
                if (item.WorkflowTaskStateTypeId === 4)
                    chevronText = 'Approved By ' + formatRequestorName(modifiedUser);
                if (item.WorkflowTaskStateTypeId === 5)
                    chevronText = 'Rejected By ' + formatRequestorName(modifiedUser);

                if (!itemName) return null;

                var fontsize = itemName.length > 8 ? '11.5px' : 'small';
                return (
                    <React.Fragment key={i}>
                        <div
                            className="bar"
                            data-tip
                            data-for={i + 'chevron'}
                            style={{
                                backgroundColor: itemColor,
                                width: this.props.chevronWidth
                                    ? this.props.chevronWidth
                                    : '11%',
                            }}>
                            <center style={{ marginTop: '-10px' }}>
                                <p
                                    style={{
                                        marginLeft: '20%',
                                        fontSize: fontsize,
                                    }}>
                                    {itemName}
                                </p>
                            </center>
                        </div>
                        {item.WorkflowTaskStateTypeId != 0 && (
                            <ReactTooltip
                                id={i + 'chevron'}
                                place="bottom"
                                effect="solid">
                                {chevronText}
                            </ReactTooltip>
                        )}
                    </React.Fragment>
                );
            }, this);

        return (
            <div className="multicolor-bar">
                {/* <div className="values">
                {values == ''?'':values}
            </div>
            <div className="scale">
                {calibrations == ''?'':calibrations}
            </div>*/}
                <div className="bars">{bars == '' ? '' : bars}</div>
                {/*<div className="legends">
                {legends == ''?'':legends}
        </div>*/}
            </div>
        );
    }
}

export default MultiColorProgressBar;
