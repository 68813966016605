import React from 'react';
import { View } from 'react-native';
import { Route, Switch } from './router';
import { withTitle } from '../components/pagetitle';

import SFDC from '../containers/SFDC';

import CreateCustomer from '../containers/CreateCustomer';
import CreateList from '../containers/CreateList';
import UpdateCustomer from '../containers/UpdateCustomer';
import SearchPage from '../containers/SearchPage';
import ResultsPage from '../containers/ResultsPage';
import AdvanceSearch from '../containers/AdvancedSearch';
import MyRequests from '../containers/MyRequests/';
import MyRequestsForm from '../containers/MyRequests/Form';
import HomePage from '../containers/HomePage';
// My-Task screens of Different system
import Checklist from '../containers/MyTasks/Apollo/ReleaseCheckList';
import MyTasks from '../containers/MyTasks/index';
import ApolloComponents from '../containers/MyTasks/Apollo/ApolloRoutes';
import M2MComponents from '../containers/MyTasks/M2M/M2MRoutes';
import PointmanComponents from '../containers/MyTasks/PTMN/PointmanRoutes';
import OlympusComponents from '../containers/MyTasks/Olympus/OlympusRoutes';
import JdeComponents from '../containers/MyTasks/Jde/JdeRoutes';
import CustomerMasterSalesForce from '../containers/MyTasks/Apollo/CustomerMasterSalesForce';
// SearchResult screens of Different system
import Extend1 from '../containers/SearchResult/Apollo/Extend1';
import Extend2 from '../containers/SearchResult/Apollo/Extend2';
import Block from '../containers/SearchResult/Apollo/Block';
import Screen2 from '../containers/SearchResult/Apollo/MdmDetailScreen';
import MdmDetailScreen from '../containers/SearchResult/Olympus/MdmDetailScreen';
import JDEExtend2 from '../containers/SearchResult/JDE/Extend2';
import M2MExtend2 from '../containers/SearchResult/M2M/Extend2';
import PTMNExtend2 from '../containers/SearchResult/PTMN/Extend2';
import OlympusExtend2 from '../containers/SearchResult/Olympus/Extend2';
//Update screens for different systems
import UpdateGlobal from '../containers/UpdateForm/Apollo/UpdateGlobalMDM';
import UpdateExtendForm from '../containers/UpdateForm/Apollo/UpdateExtendForm';
import BlockScreen from '../containers/UpdateForm/Apollo/BlockScreen';
import PTMNUpdate from '../containers/UpdateForm/PTMN/UpdateScreen';
import M2MUpdate from '../containers/UpdateForm/M2M/UpdateScreen';
import JDEUpdate from '../containers/UpdateForm/JDE/UpdateScreen';
import OlympusUpdate from '../containers/UpdateForm/Olympus/UpdateScreen';
import OlympusExtendForm from '../containers/UpdateForm/Olympus/UpdateExtendForm';
import OtherWorkflow from '../containers/OtherWorkflow';
import OtherRequest from '../components/OtherRequest';
// Partners
import PointmanPartners from '../containers/Partners/PointmanPartners';
import ApolloPartners from '../containers/Partners/CreateApolloPartners';
import MyTaskApolloPartners from '../containers/MyTasks/Apollo/MyTaskApolloPartners';
import OlympusPartners from '../containers/Partners/CreateOlympusPartner';
import MyTaskOlympusPartners from '../containers/MyTasks/Olympus/MyTaskOlympusPartners';
import CreateJdePartners from '../containers/Partners/CreateJDEPartners';
import MyTaskJdePartners from '../containers/MyTasks/Jde/MyTaskJdePartners';
class Routes extends React.PureComponent {
    render() {
        const { match } = this.props;

        return (
            <View>
                <Switch>
                    <Route
                        exact
                        path="/advance-search"
                        component={withTitle({
                            component: AdvanceSearch,
                            title: 'Advance search',
                            backgroundColor: '#EFF3F6',
                            blacklist: ['advance-search'],
                        })}
                    />

                    <Route
                        exact
                        path="/advance-search/other-request"
                        component={withTitle({
                            component: OtherWorkflow,
                            title: 'Other Request',
                            backgroundColor: '#EFF3F6',
                        })}
                    />

                    <Route
                        exact
                        path="/search-results/create-customer"
                        component={withTitle({
                            component: CreateCustomer,
                            title: 'CREATE CUSTOMER',
                            backgroundColor: '#EFF3F6',
                            displayBreadCrumb: false,
                        })}
                    />

                    <Route exact path="/search-results/:id" component={Screen2} />

                    <Route
                        exact
                        path="/search-results/olympus/:id"
                        component={MdmDetailScreen}
                    />

                    {/* 'Block-Unblock'fields */}
                    <Route
                        exact
                        path="/search-results/:id/block-unblock"
                        component={withTitle({
                            component: BlockScreen,
                            title: 'Block-Unblock',
                            backgroundColor: '#EFF3F6',
                        })}
                    />

                    {/* update global fields */}
                    <Route
                        exact
                        path="/search-results/:id/update-global"
                        component={withTitle({
                            component: UpdateGlobal,
                            title: 'Update global data',
                            backgroundColor: '#EFF3F6',
                        })}
                    />

                    {/* update functional 70 fields bapi
                    :action takes value update / extend-salesorg */}
                    <Route
                        exact
                        path="/search-results/:id/:action"
                        component={withTitle({
                            component: UpdateExtendForm,
                            title: 'Customer master',
                            backgroundColor: '#EFF3F6',
                            exact: true,
                        })}
                    />
                    <Route
                        exact
                        path="/search-results/olympus/:id/:action"
                        component={withTitle({
                            component: OlympusExtendForm,
                            title: 'Update Extend Olympus',
                            backgroundColor: '#EFF3F6',
                            exact: true,
                        })}
                    />

                    {/* myrequest update request for functional 70 fields
                    :action takes value update-request */}
                    <Route
                        exact
                        path="/my-requests/:id/:action"
                        component={UpdateExtendForm}
                        title="Customer master"
                        backgroundColor="#EFF3F6"
                    />
                    <Route
                        exact
                        path="/my-requests/olympus/partners/:id"
                        component={withTitle({
                            component: MyTaskOlympusPartners,
                            displayBreadCrumb: false,
                            title: 'Show Partners',
                            backgroundColor: '#EFF3F6',
                        })}
                    />
                    <Route
                        exact
                        path="/my-requests/olympus/:id/:action"
                        component={OlympusExtendForm}
                        title="Customer master"
                        backgroundColor="#EFF3F6"
                    />

                    <Route
                        exact
                        path="/customers/create-additional"
                        component={withTitle({
                            component: CreateList,
                            title: 'CREATE ADDITIONAL',
                        })}
                    />

                    <Route
                        exact
                        path="/customers/:id"
                        component={withTitle({
                            component: UpdateCustomer,
                            title: 'UPDATE CUSTOMER',
                        })}
                    />

                    <Route
                        exact
                        path="/ptmn/update"
                        component={withTitle({
                            component: PTMNUpdate,
                            title: 'Update',
                        })}
                    />
                    <Route
                        exact
                        path="/m2m/update"
                        component={withTitle({
                            component: M2MUpdate,
                            title: 'Update',
                        })}
                    />
                    <Route
                        exact
                        path="/jde/update"
                        component={withTitle({
                            component: JDEUpdate,
                            title: 'Update',
                        })}
                    />
                    <Route
                        exact
                        path="/olympus/update"
                        component={withTitle({
                            component: OlympusUpdate,
                            title: 'Update',
                        })}
                    />
                    <Route
                        exact
                        path="/search"
                        component={withTitle({
                            component: SearchPage,
                            title: 'Search',
                            displayBreadCrumb: false,
                        })}
                    />

                    <Route
                        exact
                        path="/search-results"
                        component={withTitle({
                            component: ResultsPage,
                            title: 'Search Results',
                            displayBreadCrumb: false,
                            backgroundColor: '#000',
                        })}
                    />
                    <Route
                        exact
                        path="/search-results/:id/extend-system"
                        component={withTitle({
                            component: Extend1,
                            title: 'Search Results Extend 1',
                            backgroundColor: '#EFF3F6',
                        })}
                    />
                    <Route
                        exact
                        path="/search-results/extend2"
                        component={withTitle({
                            component: Extend2,
                            title: 'Search Results Exted 2',
                        })}
                    />
                    <Route
                        exact
                        path="/search-results/jde/extend2"
                        component={withTitle({
                            component: JDEExtend2,
                            title: 'Search Results Exted 2',
                        })}
                    />
                    <Route
                        exact
                        path="/search-results/m2m/extend2"
                        component={withTitle({
                            component: M2MExtend2,
                            title: 'Search Results Exted 2',
                        })}
                    />

                    <Route
                        exact
                        path="/search-results/ptmn/extend2"
                        component={withTitle({
                            component: PTMNExtend2,
                            title: 'Search Results Exted 2',
                        })}
                    />
                    <Route
                        exact
                        path="/search-results/olympus/extend2"
                        component={withTitle({
                            component: OlympusExtend2,
                            title: 'Search Results Exted 2',
                        })}
                    />
                    <Route
                        exact
                        path="/search-results/screen2"
                        component={withTitle({
                            component: Screen2,
                            title: 'Search results screen2',
                        })}
                    />

                    <Route
                        exact
                        path="/search-results/:id/block"
                        component={withTitle({
                            component: Block,
                            title: 'Block Screen',
                            backgroundColor: '#EFF3F6',
                        })}
                    />

                    <Route
                        exact
                        path="/search-results/block"
                        component={withTitle({
                            component: Block,
                            title: 'Block Screen',
                        })}
                    />

                    <Route
                        exact
                        path="/my-tasks"
                        component={withTitle({
                            component: MyTasks,
                            title: 'MY TASKS',
                            displayBreadCrumb: false,
                        })}
                    />

                    <Route
                        path={`${match.url}my-tasks/apollo`}
                        component={withTitle({
                            component: ApolloComponents,
                            title: 'My Tasks',
                            displayBreadCrumb: false,
                        })}
                    />
                    <Route
                        path={`${match.url}my-tasks/m2m`}
                        component={withTitle({
                            component: M2MComponents,
                            title: 'My Tasks',
                            displayBreadCrumb: false,
                        })}
                    />
                    <Route
                        path={`${match.url}my-tasks/ptmn`}
                        component={withTitle({
                            component: PointmanComponents,
                            title: 'My Tasks',
                            displayBreadCrumb: false,
                        })}
                    />

                    <Route
                        path={`${match.url}my-tasks/Olympus`}
                        component={withTitle({
                            component: OlympusComponents,
                            title: 'My Tasks',
                            displayBreadCrumb: false,
                        })}
                    />

                    <Route
                        path={`${match.url}my-tasks/salesforce/customer-master/salesforce/:id`}
                        component={CustomerMasterSalesForce}
                    />
                    <Route
                        exact
                        path={`${match.url}my-tasks/:system/:customer-master/:id/other-request`}
                        component={withTitle({
                            component: OtherRequest,
                            title: 'MY TASKS Customer Master',
                            displayBreadCrumb: false,
                        })}
                    />

                    <Route
                        path={`${match.url}my-tasks/jded`}
                        component={withTitle({
                            component: JdeComponents,
                            title: 'My Tasks',
                            displayBreadCrumb: false,
                        })}
                    />

                    <Route
                        exact
                        path="/my-requests/jded/partners/:id"
                        component={withTitle({
                            component: MyTaskJdePartners,
                            displayBreadCrumb: false,
                            title: 'Show Partners',
                            backgroundColor: '#EFF3F6',
                        })}
                    />
                    <Route
                        exact
                        path="/my-requests"
                        component={withTitle({
                            component: MyRequests,
                            title: 'MY REQUESTS',
                            displayBreadCrumb: false,
                        })}
                    />
                    <Route
                        exact
                        path="/my-requests/:id"
                        component={MyRequestsForm}
                        title="MY REQUESTS"
                        backgroundColor="#EFF3F6"
                    />

                    <Route
                        exact
                        path="/sfdc"
                        component={withTitle({
                            component: SFDC,
                            displayBreadCrumb: false,
                        })}
                    />
                    <Route
                        exact
                        path="/"
                        component={withTitle({
                            component: HomePage,
                            title: 'Vyaire MDM',
                        })}
                    />

                    <Route
                        exact
                        path="/ptmn/partners"
                        component={withTitle({
                            component: PointmanPartners,
                            displayBreadCrumb: false,
                            title: 'Show Partners',
                            backgroundColor: '#EFF3F6',
                        })}
                    />

                    <Route
                        exact
                        path="/apollo/partners"
                        component={withTitle({
                            component: ApolloPartners,
                            displayBreadCrumb: false,
                            title: 'Show Partners',
                            backgroundColor: '#EFF3F6',
                        })}
                    />

                    <Route
                        exact
                        path="/my-requests/apollo/partners/:id"
                        component={withTitle({
                            component: MyTaskApolloPartners,
                            displayBreadCrumb: false,
                            title: 'Show Partners',
                            backgroundColor: '#EFF3F6',
                        })}
                    />

                    <Route
                        exact
                        path="/olympus/partners"
                        component={withTitle({
                            component: OlympusPartners,
                            displayBreadCrumb: false,
                            title: 'Show Partners',
                            backgroundColor: '#EFF3F6',
                        })}
                    />
                    <Route
                        exact
                        path="/jde/partners"
                        component={withTitle({
                            component: CreateJdePartners,
                            displayBreadCrumb: false,
                            title: 'Show Partners',
                            backgroundColor: '#EFF3F6',
                        })}
                    />
                </Switch>
            </View>
        );
    }
}

export default Routes;
