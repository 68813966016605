/**
 * @prettier
 */

import {
    GET_POINTMAN_PARTNERS,
    GET_APOLLO_PARTNERS,
    GET_APOLLO_PARTNERS_SUCCESS,
    GET_APOLLO_PARTNERS_FAILURE,
    GET_POINTMAN_PARTNERS_SUCCESS,
    GET_POINTMAN_PARTNERS_FAILURE,
    ADD_NEW_PARTNER,
    FETCH_PARTNER_DETAILS_SUCCESS,
    CREATE_APOLLO_PARTNER_REQUEST,
    CREATE_APOLLO_PARTNER_REQUEST_SUCCESS,
    CREATE_APOLLO_PARTNER_REQUEST_FAIL,
    GET_WF_APOLLO_PARTNERS,
    GET_WF_APOLLO_PARTNERS_SUCCESS,
    GET_WF_APOLLO_PARTNERS_FAILURE,
    APPROVE_APOLLO_PARTNERS,
    CLEAR_PARTNERS,
    CREATE_APOLLO_PARTNER_FAIL,
    APPROVE_APOLLO_PARTNER_REQUEST_SUCCESS,
    APPROVE_APOLLO_PARTNER_REQUEST_FAIL,
    CLEAR_PARTNEREDITSAFTERSAVE,
    GET_JDE_PARTNERS,
    GET_JDE_PARTNERS_SUCCESS,
    GET_JDE_PARTNERS_FAIL,
    SEARCH_JDE_PARTNERS_SUCCESS,
    GET_WF_JDE_PARTNERS,
    GET_WF_JDE_PARTNERS_SUCCESS,
    GET_WF_JDE_PARTNERS_FAILURE,
} from '../../constants/ActionTypes';

import Immutable from 'seamless-immutable';

const INITIAL_STATE = {
    myTaskData: [],
    fetching: false,
    fetchingGlobaldata: false,
    error: null,
    data: [],
    statusBarData: [],
    globalMdmDetail: [],
    totalPartners: [],
    alert: { display: false, message: '', color: '#FFF' },
    partnersFilters: {},
    partnerDetails: [],
    apolloPartnersWFObj: {},
    apolloPartnersSearchFields: {},
    apolloPartnersData: [],
    wfApolloPartnersData: null,
    jdePartnersData: [],
    jdePartnerEdits: [],
};

function chunk(array, size) {
    const chunked_arr = [];
    let index = 0;
    while (index < array.length) {
        chunked_arr.push(array.slice(index, size + index));
        index += size;
    }
    return chunked_arr;
}

//Exclusive for pointman
// Moves the bill To Record as a first record 
function moveBillToRec(array, size) {
  // Bring the billTo To First
  let index = array.findIndex(x=> x.PartnerFunc === "BILLTO");
  let removedEntity = array.splice(index,1);
  
  array.unshift(...removedEntity); 

  return chunk(array,size);

}

const partnersReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_POINTMAN_PARTNERS: {
            return {
                ...state,
                fetching: true,
                alert: {
                    display: false,
                    message: '',
                    color: '#fff',
                },
            };
        }
        case GET_APOLLO_PARTNERS: {
            return {
                ...state,
                fetching: true,
                apolloPartnersSearchFields: action.payload,
                alert: {
                    display: false,
                    message: '',
                    color: '#fff',
                },
            };
        }
        case GET_APOLLO_PARTNERS_SUCCESS: {
            let obj = { ...state };
            obj['partnerEditsAfterSave'] = [];
            return {
                ...obj,
                fetching: false,
                apolloPartnersWFObj: action.payload,
                apolloPartnersData: action.payload.SapPartners,
                partnersFilters: action.payload.filters,
            };
        }
        case GET_APOLLO_PARTNERS_FAILURE: {
            let obj = { ...state };
            obj['partnerEditsAfterSave'] = [];
            return {
                ...obj,
                fetching: false,
                apolloPartnersWFObj: {},
                apolloPartnersData: action.payload.SapPartners,
                alert: {
                    display: true,
                    message: action.payload.msg,
                    color: action.payload.color,
                },
            };
        }
        case GET_WF_APOLLO_PARTNERS: {
            return {
                ...state,
                fetching: true,
                alert: {
                    display: false,
                    message: '',
                    color: '#fff',
                },
            };
        }
        case GET_WF_APOLLO_PARTNERS_SUCCESS: {
            return {
                ...state,
                fetching: false,
                apolloPartnersWFObj: action.payload,
                wfApolloPartnersData: action.payload,
                partnersFilters: action.payload.filters,
            };
        }
        case GET_WF_APOLLO_PARTNERS_FAILURE: {
            return {
                ...state,
                fetching: false,
                apolloPartnersWFObj: {},
                apolloPartnersData: [],
                wfApolloPartnersData: null,
                alert: {
                    display: true,
                    message: action.payload.msg,
                    color: action.payload.color,
                },
            };
        }
        case GET_POINTMAN_PARTNERS_SUCCESS: {
            return {
                ...state,
                fetching: false,
                totalPartners: moveBillToRec(action.payload, 10),
                data: action.payload,
                partnersFilters: action.payload.filters,
            };
        }
        case GET_POINTMAN_PARTNERS_FAILURE: {
            return {
                ...state,
                fetching: false,
                totalPartners: [],
                data: [],
                partnersFilters: {},
                alert: {
                    display: true,
                    message: action.payload.msg,
                    color: action.payload.color,
                },
            };
        }
        case CREATE_APOLLO_PARTNER_REQUEST: {
            return {
                ...state,
                fetching: true,
            };
        }
        case CREATE_APOLLO_PARTNER_REQUEST_SUCCESS: {
            return {
                ...state,
                fetching: false,
            };
        }
        case CREATE_APOLLO_PARTNER_FAIL: {
            console.log('Apollo Partners Create fail');
            console.log(state, action.payload);
            return {
                ...state,
                partnerEditsAfterSave: action.payload,
                fetching: false,
                alert: {
                    display: true,
                    message: action.payload.msg,
                    color: action.payload.color,
                },
            };
        }
        case ADD_NEW_PARTNER: {
            return {
                ...state,
                apolloPartnersData: [...state.apolloPartnersData, ...action.payload],
            };
        }
        case FETCH_PARTNER_DETAILS_SUCCESS: {
            // console.log('inside reducere ', action.payload);
            const existingdata = action.payload.edits.data;
            let apiResponse = action.payload.result;
            let newAddedRow = action.payload.edits.cellrow;
            let newEditRowIndex = action.payload.edits.rowIndex;
            let apolloPartnersData = [];
            // existingdata.map((row, index) => {
            //     if (index === newEditRowIndex) {
            //         let editedData = {
            //             ...existingdata[newEditRowIndex],
            //             ...apiResponse,
            //         };
            //         return editedData;
            //     }
            //     return row;
            // });
            let obj = apiResponse;
            obj['CustomerNumberOrWorkflowNumber'] =
                apiResponse['WorkflowOrCustomerNumber'];
            obj['PartnerNumber'] = apiResponse['WorkflowOrCustomerNumber'];
            // console.log(obj,"===============Api Response Data")
            // apiResponse['CustomerNumberOrWorkflowNumber']=apiResponse['WorkflowOrCustomerNumber'];
            if (existingdata[newEditRowIndex])
                existingdata[newEditRowIndex] = {
                    ...existingdata[newEditRowIndex],
                    ...apiResponse,
                };
            apolloPartnersData = [...existingdata];
            if (apolloPartnersData != existingdata)
                // console.log(' check this ***** ', {
                //     ...state,
                //     apolloPartnersData: [...apolloPartnersData],
                // });

                return {
                    ...state,
                    apolloPartnersData: [...apolloPartnersData],
                };
        }

        case CLEAR_PARTNERS: {
            return {
                ...state,
                apolloPartnersData: [],
                wfApolloPartnersData: [],
                jdePartnersData: [],
                wfJdePartnersData: [],
            };
        }

        case APPROVE_APOLLO_PARTNER_REQUEST_SUCCESS: {
            console.log('success approve');
            let obj = { ...state };
            console.log(obj);
            obj['WorkflowTaskStateTypeId'] = 6;
            if (obj.wfApolloPartnersData)
                obj.wfApolloPartnersData['WorkflowTaskStateTypeId'] = 6;
            return {
                ...obj,
                fetching: false,
                statusApproval: true,
            };
        }

        case APPROVE_APOLLO_PARTNER_REQUEST_FAIL: {
            let obj = { ...state };
            console.log('fail approve');
            // obj['WorkflowTaskStateTypeId'] = 6;
            // if (obj.wfApolloPartnersData)
            //     obj.wfApolloPartnersData['WorkflowTaskStateTypeId'] = 6;
            return {
                ...obj,
                fetching: false,
                statusApproval: false,
            };
        }

        case APPROVE_APOLLO_PARTNERS: {
            return {
                ...state,
                fetching: true,
            };
        }

        case CLEAR_PARTNEREDITSAFTERSAVE: {
            console.log(state);
            return {
                ...state,
                fetching: false,
            };
        }

        case GET_JDE_PARTNERS: {
            return {
                ...state,
                fetching: true,
                alert: {
                    display: false,
                    message: '',
                    color: '#fff',
                },
            };
        }
        case GET_JDE_PARTNERS_SUCCESS: {
            return {
                ...state,
                fetching: false,
                jdePartnersData: action.payload.SapPartners,
            };
        }
        case GET_JDE_PARTNERS_FAIL: {
            return {
                ...state,
                fetching: false,
                jdePartnersData: action.payload.SapPartners,
                alert: {
                    display: true,
                    message: action.payload.msg,
                    color: action.payload.color,
                },
            };
        }
        case SEARCH_JDE_PARTNERS_SUCCESS: {
            console.log('inside reducere', action.payload);
            const existingdata = action.payload.edits.data;
            let apiResponse = action.payload.result;
            let newEditRowIndex = action.payload.edits.rowIndex;
            let jdePartnersData = [];

            if (existingdata[newEditRowIndex])
                existingdata[newEditRowIndex] = {
                    ...existingdata[newEditRowIndex],
                    ...apiResponse,
                };
            jdePartnersData = [...existingdata];
            if (jdePartnersData != existingdata) {
                // let mypartnersedits=action.payload.PartnerEdits
                // let edits=[];
                // for(let i=0;i<mypartnersedits.length;i++){
                //     edits.push({...mypartnersedits[i],
                //         PartnerRoleTypeId:mypartnersedits[i]['SearchedSapPartner']['PartnerCustomerRoleTypeId']})
                // }
                return {
                    ...state,
                    jdePartnersData: [...jdePartnersData],
                };
            }
        }

        case GET_WF_JDE_PARTNERS: {
            return {
                ...state,
                fetching: true,
                alert: {
                    display: false,
                    message: '',
                    color: '#fff',
                },
            };
        }
        case GET_WF_JDE_PARTNERS_SUCCESS: {
            let mypartnersedits = action.payload.PartnerEdits;
            let edits = [];
            for (let i = 0; i < mypartnersedits.length; i++) {
                edits.push({
                    ...mypartnersedits[i],
                    PartnerRoleTypeId:
                        mypartnersedits[i]['SearchedSapPartner'][
                            'PartnerCustomerRoleTypeId'
                        ],
                });
            }
            console.log('edits**', edits, action.payload);
            return {
                ...state,
                fetching: false,
                jdePartnersWFObj: action.payload,
                wfJdePartnersData: action.payload,
                jdePartnerEdits: edits,
            };
        }
        case GET_WF_JDE_PARTNERS_FAILURE: {
            return {
                ...state,
                fetching: false,
                jdePartnersWFObj: {},
                jdePartnersData: [],
                wfJdePartnersData: null,
                alert: {
                    display: true,
                    message: action.payload.msg,
                    color: action.payload.color,
                },
            };
        }

        default:
            return state;
    }
};

export default partnersReducer;
