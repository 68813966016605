import React, { Component, Fragment } from 'react';
import { Flex, Button, Box, Text } from './common';
import { FormInput, FormSelect } from './form';
import * as moment from 'moment';
import {
    SystemType,
    GeneralRoleType,
    WorkflowProcessType,
    TaskType,
    WorkflowStateType,
    WorkflowTaskStateType,
} from '../constants/WorkflowEnums';
import DynamicSelect from '../components/DynamicSelect';
import {
    fetchCreateCustomerDropDownData,
    fetchOlympusFieldMapping,
} from '../redux/DropDownDatas';

const WorkflowTaskTypeValidValues = Object.keys(TaskType).map((index) => {
    const system = TaskType[index];
    return { id: index, description: system };
});

const WorkflowStateTypeValidValues = Object.keys(WorkflowStateType).map((index) => {
    const system = WorkflowStateType[index];
    return { id: index, description: system };
});

const GeneralWorkflowTaskTypeValidValues = Object.keys(WorkflowProcessType).map(
    (index) => {
        const system = WorkflowProcessType[index];
        return { id: index, description: system };
    }
);

const SystemTypeValidValues = Object.keys(SystemType).map((index) => {
    const system = SystemType[index];
    return { id: index, description: system };
});

const WorkflowTaskStateTypeValues = Object.keys(WorkflowTaskStateType).map((index) => ({
    id: index,
    description: WorkflowTaskStateType[index],
}));

const RoleTypeValidValues = Object.keys(GeneralRoleType).map((index) => {
    const system = GeneralRoleType[index];
    return { id: index, description: system };
});

class RequestFilterFields extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropDownDatas: undefined,
        };
    }

    getRoleTypes = (systemId) => {
        const { dropDownDatas } = this.state;
        const { formData } = this.props;
        if (systemId == 1) {
            return (
                dropDownDatas &&
                dropDownDatas.RoleTypeId.filter(
                    (role) =>
                        role.systemId === parseInt(formData['SystemTypeId']) &&
                        role.id <= 6
                )
            );
        } else if (systemId == 2) {
            return dropDownDatas && dropDownDatas.OlympusRoleType;
        } else if (systemId == 3) {
            return (
                dropDownDatas &&
                dropDownDatas.RoleTypeId.filter(
                    (role) =>
                        role.systemId === parseInt(formData['SystemTypeId']) &&
                        role.id <= 6
                )
            );
        } else if (systemId == 5) {
            return dropDownDatas && dropDownDatas.JDERoleType;
        } else {
            return RoleTypeValidValues;
        }
    };

    componentDidMount() {
        fetchCreateCustomerDropDownData().then((res) => {
            const data = res;
            this.setState({ dropDownDatas: data });
        });
    }

    render() {
        const hiddenFields = {
            ...this.props.hiddenFields,
        };
        let { formData = {} } = this.props;
        const inputProps = {
            inline: false,
            readOnly: false,
            onChange: this.props.onFieldChange,
        };
        return (
            <Fragment>
                <Box
                    width="85%"
                    marginLeft="5%"
                    flexDirection="row"
                    justifyContent="center">
                    <Box width={1 / 2} mx="auto" alignItems="center">
                        <FormInput
                            label="Workflow Number"
                            name="WorkflowId"
                            small
                            error={
                                this.props.formErrors
                                    ? this.props.formErrors['WorkflowId']
                                    : null
                            }
                            value={this.props.formData.WorkflowId || ''}
                            {...inputProps}
                        />

                        <FormInput
                            label="Customer Name"
                            name="CustomerName"
                            small
                            error={
                                this.props.formErrors
                                    ? this.props.formErrors['CustomerName']
                                    : null
                            }
                            value={formData.CustomerName}
                            {...inputProps}
                        />
                        {this.props.children}
                        <FormInput
                            label="From"
                            name="CreatedOn_StartRange"
                            onChange={(value, element) => {
                                element.preventDefault();
                                this.props.onFieldChange(
                                    new Date(value).toJSON().slice(0, 19),
                                    element
                                );
                            }}
                            error={
                                this.props.formErrors
                                    ? this.props.formErrors['CreatedOn_StartRangeFrom']
                                    : null
                            }
                            type="date"
                            small
                            value={
                                formData &&
                                formData['CreatedOn_StartRange'] &&
                                formData['CreatedOn_StartRange'].split('T')[0]
                            }
                            variant={'solid'}
                            inline={false}
                        />

                        <FormInput
                            label="To"
                            max={moment().format('YYYY-MM-DD')}
                            name="CreatedOn_EndRange"
                            onChange={(value, element) => {
                                element.preventDefault();
                                this.props.onFieldChange(
                                    new Date(value)
                                        .toJSON()
                                        .slice(0, 11)
                                        .concat('23:59:59'),
                                    element
                                );
                            }}
                            error={
                                this.props.formErrors
                                    ? this.props.formErrors['CreatedOn_EndRange']
                                    : null
                            }
                            type="date"
                            small
                            value={
                                formData &&
                                formData['CreatedOn_EndRange'] &&
                                formData['CreatedOn_EndRange'].split('T')[0]
                            }
                            variant={'solid'}
                            inline={false}
                        />
                    </Box>
                    <Box width={1 / 2} mx="auto" alignItems="flex-start">
                        <DynamicSelect
                            arrayOfData={SystemTypeValidValues}
                            label="System"
                            name="SystemTypeId"
                            small
                            formErrors={
                                this.props.formErrors
                                    ? this.props.formErrors['SystemTypeId']
                                    : null
                            }
                            onFieldChange={this.props.onFieldChange}
                            value={formData.SystemTypeId}
                        />
                        <DynamicSelect
                            arrayOfData={this.getRoleTypes(formData.SystemTypeId)}
                            label="Role"
                            name="RoleTypeId"
                            small
                            formErrors={
                                this.props.formErrors
                                    ? this.props.formErrors['RoleTypeId']
                                    : null
                            }
                            onFieldChange={this.props.onFieldChange}
                            value={formData.RoleTypeId}
                        />
                        <DynamicSelect
                            arrayOfData={GeneralWorkflowTaskTypeValidValues}
                            label="Workflow Process"
                            name="ProcessTypeId"
                            small
                            formErrors={
                                this.props.formErrors
                                    ? this.props.formErrors['ProcessTypeId']
                                    : null
                            }
                            onFieldChange={this.props.onFieldChange}
                            value={formData.ProcessTypeId}
                        />
                        <DynamicSelect
                            arrayOfData={WorkflowStateTypeValidValues}
                            label="Workflow Status"
                            name="WorkflowStateTypeId"
                            small
                            formErrors={
                                this.props.formErrors
                                    ? this.props.formErrors['WorkflowStateTypeId']
                                    : null
                            }
                            onFieldChange={this.props.onFieldChange}
                            value={formData.WorkflowStateTypeId}
                        />
                        {!hiddenFields['WorkflowTaskStateTypeId'] && (
                            <DynamicSelect
                                arrayOfData={WorkflowTaskStateTypeValues}
                                label="Workflow Task Status"
                                name="WorkflowTaskStateTypeId"
                                small
                                formErrors={
                                    this.props.formErrors
                                        ? this.props.formErrors['WorkflowTaskStateTypeId']
                                        : null
                                }
                                onFieldChange={this.props.onFieldChange}
                                value={formData.WorkflowTaskStateTypeId}
                            />
                        )}
                    </Box>
                </Box>
                <Flex
                    justifyStart
                    alignCenter
                    style={{
                        paddingTop: 15,
                        flexDirection: 'row',
                        alignItems: 'center',
                        paddingLeft: 40,
                        paddingRight: 15,
                        marginTop: 20,
                        marginBottom: 10,
                        marginHorizontal: 25,
                    }}>
                    <Button onPress={this.props.onSubmit} title="Submit" />
                    <Button onPress={this.props.onReset} title="Reset" />
                </Flex>
            </Fragment>
        );
    }
}

export default RequestFilterFields;
