import {
    GET_WORKFLOW,
    GET_WORKFLOW_SUCCESS,
    GET_WORKFLOW_FAILURE,
    SET_STATUS_BAR_DATA,
    GET_FUCTIONAL_GROUP_DATA,
    SET_FUCTIONAL_GROUP_DATA,
    SET_TAX_JURISDICTION,
    GET_TAX_JURISDICTION,
    GET_STATUS_BAR_DATA,
    UPDATE_TASK_STATUS,
    FETCH_DUNS_FIELDS_REQUEST,
    FETCH_DUNS_FIELDS_SUCCESS,
    FETCH_DUNS_FIELDS_FAILED,
    CREATE_CUSTOMER_REQUEST,
    CREATE_CUSTOMER_SUCCESS,
    CREATE_CUSTOMER_FAILURE,
    OTHER_REQUEST,
    OTHER_REQUEST_SUCCESS,
    OTHER_REQUEST_FAILED,
    GET_TEAM_USERLIST,
    GET_TEAM_USERLIST_SUCCESS,
    ASSIGN_USER,
    ASSIGN_USER_SUCCESS,
    UN_ASSIGN_USER,
    UN_ASSIGN_USER_SUCCESS,
    GET_MYREQUESTS,
    SELECT_WORKFLOW,
} from '../../constants/ActionTypes';
import { formatRequestorName } from '../../appRedux/sagas/config';
import Immutable from 'seamless-immutable';

const mergeArrayWithObject = (arr, obj) =>
    arr &&
    arr.map((t) =>
        t.WorkflowId === obj.WorkflowId && t.WorkflowTeamType === obj.WorkflowTeamType
            ? obj
            : t
    );

const INITIAL_STATE = {
    myTaskData: [],
    fetching: false,
    fetchingfnGroupData: false,
    error: null,
    statusBarData: [],
    functionalGroupDetails: [],
    alert: { display: false, message: '', color: '#FFF' },
    myTaskFilters: {},
    fetchingTeamUsers: false,
    teamUsers: [],
    assigningUser: false,
};

const workflowsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_MYREQUESTS: {
            return {
                ...state,
                fetching: true,
                functionalGroupDetails: [],
                dunsFields: { IsEmptyResult: true },
            };
        }
        case SELECT_WORKFLOW: {
            return {
                ...state,
                selectedWorkflowRecord: action.payload,
                functionalGroupDetails: [],
                dunsFields: { IsEmptyResult: true },
            };
        }
        case GET_WORKFLOW: {
            return {
                ...state,
                fetching: true,
                functionalGroupDetails: [],
                dunsFields: { IsEmptyResult: true },
            };
        }
        case GET_WORKFLOW_SUCCESS: {
            return {
                ...state,
                fetching: false,
                fetchingGlobaldata: false,
                fetchingStatusBar: false,
                myTaskData: action.payload.userTasks,
                myTaskFilters: action.payload.filters,
                myTasksTotal: action.payload.TotalItems,
            };
        }
        case GET_WORKFLOW_FAILURE: {
            return {
                ...state,
                fetching: false,
                fetchingfnGroupData: false,
                fetchingGlobaldata: false,
                fetchingStatusBar: false,
                alert: {
                    display: true,
                    message: action.payload.msg,
                    color: action.payload.color,
                },
            };
        }
        case GET_TEAM_USERLIST: {
            return {
                ...state,
                fetchingTeamUsers: true,
            };
        }
        case GET_TEAM_USERLIST_SUCCESS: {
            var userlist = action.payload || [];
            var newlist = [];
            for (let i = 0; i < userlist.length; i++) {
                let fmtUsername = {};
                fmtUsername['FormattedUserName'] = formatRequestorName(
                    userlist[i].UserName
                );
                newlist.push({ ...userlist[i], ...fmtUsername });
            }
            return {
                ...state,
                fetchingTeamUsers: false,
                teamUsers: newlist.sort(function (a, b) {
                    return a.UserName.localeCompare(b.UserName);
                }),
            };
        }
        case ASSIGN_USER: {
            return {
                ...state,
                assigningUser: true,
                alert: { display: false, message: '', color: '#FFF' },
            };
        }
        case ASSIGN_USER_SUCCESS: {
            let updatedWF = state.myTaskData;
            if (action.payload.wf && action.payload.wf.length != 0)
                updatedWF = mergeArrayWithObject(state.myTaskData, action.payload.wf);
            return {
                ...state,
                assigningUser: false,
                myTaskData: updatedWF,
                alert: {
                    display: true,
                    message: action.payload.msg,
                    color: action.payload.color,
                },
            };
        }
        case UN_ASSIGN_USER: {
            return {
                ...state,
                assigningUser: true,
            };
        }
        case UN_ASSIGN_USER_SUCCESS: {
            let updatedWF = state.myTaskData;
            if (action.payload.wf && action.payload.wf.length != 0)
                updatedWF = mergeArrayWithObject(state.myTaskData, action.payload.wf);
            return {
                ...state,
                assigningUser: false,
                myTaskData: updatedWF,
                alert: {
                    display: true,
                    message: action.payload.msg,
                    color: action.payload.color,
                },
            };
        }
        case GET_STATUS_BAR_DATA: {
            return {
                ...state,
                statusBarData: [],
                TasksStatusByTeamId: null,
                fetchingStatusBar: true,
            };
        }
        case SET_STATUS_BAR_DATA: {
            return {
                ...state,
                statusBarData: action.payload.all,
                TasksStatusByTeamId: action.payload.byTeamId,
                fetchingStatusBar: false,
            };
        }
        case GET_FUCTIONAL_GROUP_DATA: {
            return {
                ...state,
                fetchingfnGroupData: true,
            };
        }
        case SET_FUCTIONAL_GROUP_DATA: {
            return {
                ...state,
                functionalGroupDetails: action.payload,
                fetchingfnGroupData: false,
            };
        }
        case UPDATE_TASK_STATUS: {
            return {
                ...state,
                statusBarData: state.statusBarData.map((taskStatus) => {
                    if (taskStatus.TeamId === action.payload.teamId)
                        return {
                            ...taskStatus,
                            WorkflowTaskStateTypeId: action.payload.status,
                        };
                    else return taskStatus;
                }),
                TasksStatusByTeamId: {
                    ...state.TasksStatusByTeamId,
                    [action.payload.teamId]: {
                        TeamId: action.payload.teamId,
                        WorkflowTaskStateTypeId: action.payload.status,
                    },
                },
            };
        }
        case FETCH_DUNS_FIELDS_REQUEST: {
            return {
                ...state,
                dunsFields: { IsEmptyResult: true },
            };
        }
        case FETCH_DUNS_FIELDS_SUCCESS: {
            return {
                ...state,
                functionalGroupDetails: {
                    ...state.functionalGroupDetails,
                    Customer: {
                        ...state.functionalGroupDetails.Customer,
                        ...action.payload,
                    },
                },
                dunsFields: action.payload,
                fetchingfnGroupData: false,
            };
        }
        case FETCH_DUNS_FIELDS_FAILED: {
            const emptyResults = {
                DunsNumber: null,
                FullResults: null,
                IsEmptyResult: true,
                NaicsCode: null,
                NaicsCodeDescription: null,
                ParentData: null,
                SicCode: null,
                SicCode4: '',
                SicCode6: '',
                SicCode8: '',
                SicCodeDescription: null,
                TaxNumber: null,
                VatRegNo: null,
            };

            return {
                ...state,
                functionalGroupDetails: {
                    ...state.functionalGroupDetails,
                    Customer: {
                        ...state.functionalGroupDetails.Customer,
                        ...emptyResults,
                    },
                },
                dunsFields: emptyResults,
                fetchingfnGroupData: false,
            };
        }
        case OTHER_REQUEST: {
            return {
                ...state,
                fetching: true,
                alert: { display: false, message: '', color: '#FFF' },
            };
        }
        case OTHER_REQUEST_SUCCESS: {
            return {
                ...state,
                fetching: false,
                customerdata: action.payload,
                taxJuriData: [],
                alert: {
                    display: true,
                    message: action.payload.msg,
                    color: action.payload.color,
                },
            };
        }
        case OTHER_REQUEST_FAILED: {
            return {
                ...state,
                fetching: false,
                error: action.payload.msg,
                alert: {
                    display: true,
                    message: action.payload.msg,
                    color: action.payload.color,
                },
            };
        }
        default:
            return state;
    }
};

export default workflowsReducer;
