import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
    SUCCESS_BGCOLOR,
    FAILED_BGCOLOR,
    GET_MDM_MAPPING_MATRIX,
    UPDATE_DELTAS,
    SAVE_APOLLO_UPDATE_CONTRACTS,
    SAVE_APOLLO_UPDATE_CREDIT,
    SAVE_APOLLO_UPDATE_PRICING,
    SAVE_APOLLO_UPDATE_GLOBALTRADE,
    SAVE_APOLLO_UPDATE_GLOBAL,
    READ_REDSHIFT_REQUEST,
    READ_REDSHIFT_SUCCESS,
    SAVE_APOLLO_UPDATE_CUSTOMER_MASTER,
    REQUEST_BLOCK,
} from '../../constants/ActionTypes';
import {
    getCustomerFromSAP,
    setMdmMappingMatrix,
    updateDeltasStatus,
    setCustomerGlobalData,
    fetchMdmParentDataDunsResult,
    getFunctionalGroupData,
    showMessage,
    updateTaskStatus,
} from '../actions';
import { showMessage as showToast } from '../../appRedux/actions/Toast';
import { ajaxPostRequest, endpoints, formatRequestorName } from './config';
import { UploadFiles } from './Customer';
import { TASK_APPROVED, TASK_REJECTED } from '../../constants/WorkflowEnums';

export function* saveCustomerMaster(data) {
    try {
        var resp = { msg: '', color: '#FFF' };
        var jsonBody = data.payload.formData;
        if (jsonBody.action === 'update') {
            var url = endpoints.saveApolloUpdateCustMaster;
        } else if (jsonBody.action === 'extend-salesorg') {
            var url = endpoints.saveApolloUpdateCustMasterExtendSalesOrg;
        } else if (jsonBody.action === 'block-unblock') {
            var url = endpoints.saveBlockCustomerMaser;
        }
        delete jsonBody.action;
        const result = yield call(ajaxPostRequest, url, jsonBody);
        if (!result.IsSuccess) {
            resp = { msg: 'Error saving data', color: FAILED_BGCOLOR };
            yield put(showMessage(resp));
        } else {
            const postJson = {
                WorkflowId: jsonBody.WorkflowTaskModel.WorkflowId,
                TaskId: jsonBody.WorkflowTaskModel.TaskId,
                CustomerNumber: '',
                DivisionTypeId: 0,
                SystemTypeId: 0,
                DistributionChannelTypeId: 0,
                CompanyCodeTypeId: '',
                SalesOrgTypeId: 0,
            };

            yield put(getCustomerFromSAP(postJson));
            // yield put(getStatusBarData(postJson));
            yield put(
                updateTaskStatus({
                    teamId: result.ResultData.WorkflowTeamType,
                    status:
                        result.ResultData.WorkflowTaskModel.WorkflowTaskOperationType ===
                        1
                            ? TASK_APPROVED
                            : TASK_REJECTED,
                })
            );

            resp = {
                msg: 'Successfully saved the data',
                color: SUCCESS_BGCOLOR,
                success: true,
            };
            yield put(showMessage(resp));
        }

        //fileupload aws
        if (data.payload.files && data.payload.files.length > 0) {
            yield put(
                showToast({
                    msg: 'Uploading files',
                    color: '#2980b9',
                })
            );
            var files = data.payload.files;
            // console.log(
            //     'uploading attachments update cust master: ',
            //     jsonBody.WorkflowTaskModel.WorkflowId
            // );
            yield* UploadFiles(files, jsonBody.WorkflowTaskModel.WorkflowId);
        }
    } catch (error) {
        resp = { msg: error, color: FAILED_BGCOLOR };
        yield put(showMessage(resp));
    }
}

export function* getMdmMatrix({ payload }) {
    var jsonBody = payload.requestObj;
    try {
        var resp = { msg: '', color: '#FFF', delay: '' };
        var url = endpoints.mdmMappingMatrix;
        const result = yield call(ajaxPostRequest, url, jsonBody);
        if (!result.IsSuccess) {
            resp = { msg: 'Error saving data', color: FAILED_BGCOLOR };
            yield put(setMdmMappingMatrix({}));
            yield put(showToast(resp));
        } else {
            const { RequestorName } = result.ResultData;
            if (RequestorName) {
                result.ResultData.RequestorName = formatRequestorName(RequestorName);
            }
            yield put(setMdmMappingMatrix(result.ResultData));

            let dunsnumber = result.ResultData.MDMGlobalData.DunsNumber;
            console.log("Calling Matrix function");
            if (
                payload.getParentDuns &&
                dunsnumber.length > 0 &&
                dunsnumber.toString().charAt(0).toLowerCase().trim() !== 'v'
            ) {
                const data = {
                    UserId: localStorage.getItem('userId'),
                    DunsNumber: dunsnumber,
                };

                const dunsresult = undefined; // removed D&B API call
                if (dunsresult?.IsSuccess) {
                    console.log('dun', dunsresult.ResultData);
                    yield put(fetchMdmParentDataDunsResult(dunsresult.ResultData));
                } else {
                    var resp = { msg: 'No Parent Data Information Found' };
                    yield put(fetchMdmParentDataDunsResult(resp));
                }
            } else {
                var resp = { msg: 'No Duns Number Found' };
                yield put(fetchMdmParentDataDunsResult(resp));
            }
        }
    } catch (error) {
        yield put(showToast(error));
    }
}

export function* updateDeltaDatas({ payload }) {
    const { history, action, data: jsonBody, files } = payload;
    try {
        var resp = { msg: '', color: '#FFF', delay: '' };
        if (action === 'update') {
            var url = endpoints.deltaUpdate;
        }
        if (action === 'extend-salesorg') {
            var url = endpoints.extendSalesOrg;
        }
        yield put(
            showToast({
                msg: 'Saving new updates',
                color: '#2980b9',
            })
        );
        const result = yield call(ajaxPostRequest, url, jsonBody);
        window.scrollTo(0, 0);
        if (!result.IsSuccess) {
            resp = { msg: 'Error updating data', color: FAILED_BGCOLOR };
            yield put(showToast(resp));
            yield put(updateDeltasStatus());
        } else {
            yield put(
                updateDeltasStatus({
                    msg: 'Successfully updated ',
                    color: SUCCESS_BGCOLOR,
                })
            );
            history.push({
                pathname: '/my-requests',
            });
        }
        if (files && files.length > 0) {
            yield put(
                showToast({
                    msg: 'Uploading files',
                    color: '#2980b9',
                })
            );
            // console.log(
            //     'uploading attachments for ',
            //     action,
            //     'wf: ',
            //     jsonBody.workflowId
            // );
            yield* UploadFiles(files, jsonBody.workflowId);
        }
    } catch (error) {
        yield put(showToast(error));
    }
}

export function* blockRequest({ payload }) {
    const { history, action, jsonData: jsonBody, files } = payload;
    try {
        var resp = { msg: '', color: '#FFF', delay: '' };

        var url = endpoints.blockRequest;
        yield put(
            showToast({
                msg: 'Saving new request',
                color: '#2980b9',
            })
        );
        const result = yield call(ajaxPostRequest, url, jsonBody);
        window.scrollTo(0, 0);
        if (!result.IsSuccess) {
            resp = { msg: 'Error saving data', color: FAILED_BGCOLOR };
            yield put(showToast(resp));
            yield put(updateDeltasStatus());
        } else {
            yield put(
                updateDeltasStatus({
                    msg: 'Successfully updated ',
                    color: SUCCESS_BGCOLOR,
                })
            );
            history.push({
                pathname: '/my-requests',
            });
        }
        if (files && files.length > 0) {
            yield put(
                showToast({
                    msg: 'Uploading files',
                    color: '#2980b9',
                })
            );
            // console.log(
            //     'uploading attachments for ',
            //     action,
            //     'wf: ',
            //     jsonBody.workflowId
            // );
            yield* UploadFiles(files, jsonBody.WorkflowId);
        }
    } catch (error) {
        yield put(showToast(error));
    }
}

export function* saveTaskContracts(data) {
    try {
        let resp = { msg: '', color: '#FFF' };
        let fileUploadStatus = 'Unsuccessful',
            formDataStatus = 'Unsuccessful';
        //save form inputs

        var formBody = data.payload.formdata;
        if (formBody.action === 'update') {
            var url = endpoints.saveApolloUpdateContracts;
        } else if (formBody.action === 'extend-salesorg') {
            var url = endpoints.saveApolloUpdateContractsExtendSalesOrg;
        }
        delete formBody.action;

        const result = yield call(ajaxPostRequest, url, formBody);
        if (result.IsSuccess) {
            const postJson = {
                WorkflowId: formBody.WorkflowTaskModel.WorkflowId,
                TaskId: formBody.WorkflowTaskModel.TaskId,
                CustomerNumber: '',
                DivisionTypeId: 0,
                SystemTypeId: 0,
                DistributionChannelTypeId: 0,
                CompanyCodeTypeId: '',
                SalesOrgTypeId: 0,
            };

            yield put(getCustomerFromSAP(postJson));
            yield put(
                updateTaskStatus({
                    teamId: result.ResultData.WorkflowTeamType,
                    status:
                        result.ResultData.WorkflowTaskModel.WorkflowTaskOperationType ===
                        1
                            ? TASK_APPROVED
                            : TASK_REJECTED,
                })
            );
            resp = {
                msg: 'Successfully saved the data',
                color: SUCCESS_BGCOLOR,
                success: true,
            };
            yield put(showMessage(resp));
        } else {
            resp = { msg: 'UnSuccessful Saving Data', color: FAILED_BGCOLOR };
            yield put(showMessage(resp));
        }
        //fileupload aws
        if (data.payload.files && data.payload.files.length > 0) {
            yield put(
                showToast({
                    msg: 'Uploading files',
                    color: '#2980b9',
                })
            );
            var files = data.payload.files;
            // console.log(
            //     'uploading attachments update pricing: ',
            //     formBody.WorkflowTaskModel.WorkflowId
            // );
            yield* UploadFiles(files, formBody.WorkflowTaskModel.WorkflowId);
        }
    } catch (error) {
        let resp = { msg: '', color: '#FFF' };
        resp = { msg: error, color: FAILED_BGCOLOR };
        yield put(showMessage(resp));
    }
}

export function* saveTaskPricing(data) {
    try {
        var resp = { msg: '', color: '#FFF' };
        var jsonBody = data.payload.formData;
        if (jsonBody.action === 'update') {
            var url = endpoints.saveApolloUpdatePricing;
        } else if (jsonBody.action === 'extend-salesorg') {
            var url = endpoints.saveApolloUpdatePricingExtendSalesOrg;
        }
        delete jsonBody.action;
        const result = yield call(ajaxPostRequest, url, jsonBody);
        if (!result.IsSuccess) {
            resp = { msg: 'Error saving data', color: FAILED_BGCOLOR };
            yield put(showMessage(resp));
        } else {
            const postJson = {
                WorkflowId: jsonBody.WorkflowTaskModel.WorkflowId,
                TaskId: jsonBody.WorkflowTaskModel.TaskId,
                CustomerNumber: '',
                DivisionTypeId: 0,
                SystemTypeId: 0,
                DistributionChannelTypeId: 0,
                CompanyCodeTypeId: '',
                SalesOrgTypeId: 0,
            };

            yield put(getCustomerFromSAP(postJson));
            // yield put(getStatusBarData(postJson));
            yield put(
                updateTaskStatus({
                    teamId: result.ResultData.WorkflowTeamType,
                    status:
                        result.ResultData.WorkflowTaskModel.WorkflowTaskOperationType ===
                        1
                            ? TASK_APPROVED
                            : TASK_REJECTED,
                })
            );

            resp = {
                msg: 'Successfully saved the data',
                color: SUCCESS_BGCOLOR,
                success: true,
            };
            yield put(showMessage(resp));
        }

        //fileupload aws
        if (data.payload.files && data.payload.files.length > 0) {
            yield put(
                showToast({
                    msg: 'Uploading files',
                    color: '#2980b9',
                })
            );
            var files = data.payload.files;
            // console.log(
            //     'uploading attachments update pricing: ',
            //     jsonBody.WorkflowTaskModel.WorkflowId
            // );
            yield* UploadFiles(files, jsonBody.WorkflowTaskModel.WorkflowId);
        }
    } catch (error) {
        resp = { msg: error, color: FAILED_BGCOLOR };
        yield put(showMessage(resp));
    }
}

export function* saveTaskCredits(data) {
    try {
        var resp = { msg: '', color: '#FFF' };
        var jsonBody = data.payload.formData;
        if (jsonBody.action === 'update') {
            var url = endpoints.saveApolloUpdateCredit;
        } else if (jsonBody.action === 'extend-salesorg') {
            var url = endpoints.saveApolloUpdateCreditExtendSalesOrg;
        }
        delete jsonBody.action;
        const result = yield call(ajaxPostRequest, url, jsonBody);
        if (!result.IsSuccess) {
            resp = { msg: 'Error saving data', color: FAILED_BGCOLOR };
            yield put(showMessage(resp));
        } else {
            const postJson = {
                WorkflowId: jsonBody.WorkflowTaskModel.WorkflowId,
                TaskId: jsonBody.WorkflowTaskModel.TaskId,
                CustomerNumber: '',
                DivisionTypeId: 0,
                SystemTypeId: 0,
                DistributionChannelTypeId: 0,
                CompanyCodeTypeId: '',
                SalesOrgTypeId: 0,
            };

            yield put(getCustomerFromSAP(postJson));
            // yield put(getStatusBarData(postJson));
            yield put(
                updateTaskStatus({
                    teamId: result.ResultData.WorkflowTeamType,
                    status:
                        result.ResultData.WorkflowTaskModel.WorkflowTaskOperationType ===
                        1
                            ? TASK_APPROVED
                            : TASK_REJECTED,
                })
            );

            resp = {
                msg: 'Successfully saved the data',
                color: SUCCESS_BGCOLOR,
                success: true,
            };
            yield put(showMessage(resp));
        }
        //fileupload aws
        if (data.payload.files && data.payload.files.length > 0) {
            yield put(
                showToast({
                    msg: 'Uploading files',
                    color: '#2980b9',
                })
            );
            var files = data.payload.files;
            // console.log(
            //     'uploading attachments update credit: ',
            //     jsonBody.WorkflowTaskModel.WorkflowId
            // );
            yield* UploadFiles(files, jsonBody.WorkflowTaskModel.WorkflowId);
        }
    } catch (error) {
        resp = { msg: error, color: FAILED_BGCOLOR };
        yield put(showMessage(resp));
    }
}

export function* saveTaskCustomerGlobal(data) {
    try {
        var resp = { msg: '', color: '#FFF' };
        var jsonBody = data.payload.formData;
        if (jsonBody.action === 'update') {
            var url = endpoints.saveApolloCustomerGlobal;
        } else if (jsonBody.action === 'extend-salesorg') {
            var url = endpoints.saveApolloCustomerGlobalExtendSalesOrg;
        }
        delete jsonBody.action;
        const result = yield call(ajaxPostRequest, url, jsonBody);
        if (!result.IsSuccess) {
            resp = { msg: 'Error saving data', color: FAILED_BGCOLOR };
            yield put(showMessage(resp));
        } else {
            const postJson = {
                WorkflowId: jsonBody.WorkflowTaskModel.WorkflowId,
                TaskId: jsonBody.WorkflowTaskModel.TaskId,
                CustomerNumber: '',
                DivisionTypeId: 0,
                SystemTypeId: 0,
                DistributionChannelTypeId: 0,
                CompanyCodeTypeId: '',
                SalesOrgTypeId: 0,
            };

            yield put(getCustomerFromSAP(postJson));
            // yield put(getStatusBarData(postJson));
            yield put(
                updateTaskStatus({
                    teamId: result.ResultData.WorkflowTeamType,
                    status:
                        result.ResultData.WorkflowTaskModel.WorkflowTaskOperationType ===
                        1
                            ? TASK_APPROVED
                            : TASK_REJECTED,
                })
            );

            resp = {
                msg: 'Successfully saved the data',
                color: SUCCESS_BGCOLOR,
                success: true,
            };
            yield put(showMessage(resp));
        }

        //fileupload aws
        if (data.payload.files && data.payload.files.length > 0) {
            yield put(
                showToast({
                    msg: 'Uploading files',
                    color: '#2980b9',
                })
            );
            var files = data.payload.files;
            // console.log(
            //     'uploading attachments global update: ',
            //     jsonBody.WorkflowTaskModel.WorkflowId
            // );
            yield* UploadFiles(files, jsonBody.WorkflowTaskModel.WorkflowId);
        }
    } catch (error) {
        resp = { msg: error, color: FAILED_BGCOLOR };
        yield put(showMessage(resp));
    }
}

export function* readRedShift({ payload }) {
    var jsonBody = payload;
    try {
        var resp = { msg: '', color: '#FFF', delay: '' };
        var url = endpoints.mdmMappingMatrix;
        const result = yield call(ajaxPostRequest, url, jsonBody);
        if (!result.IsSuccess) {
            resp = { msg: 'Error saving data', color: FAILED_BGCOLOR };
            yield put(showToast(resp));
        } else {
            const { MdmNumber } = result.ResultData;
            const { WorkflowDocuments } = result.ResultData;
            const { TaskNote } = result.ResultData;
            const { Deltas: deltas, ...rest } = result.ResultData.MDMGlobalData;
            let { RequestorName } = result.ResultData;
            if (RequestorName) {
                RequestorName = formatRequestorName(RequestorName);
            }
            yield put(
                setCustomerGlobalData({
                    data: {
                        ...rest,
                        MdmNumber,
                        RequestorName,
                        RejectionReason: TaskNote,
                    },
                    deltas,
                    WorkflowDocuments,
                })
            );
        }
    } catch (error) {
        yield put(showToast(error));
    }
}

export function* saveTaskGlobalTrade({ payload }) {
    try {
        var resp = { msg: '', color: '#FFF' };
        var jsonBody = payload.formData;
        if (jsonBody.action === 'update') {
            var url = endpoints.saveApolloUpdateGlobalTrade;
        } else if (jsonBody.action === 'extend-salesorg') {
            var url = endpoints.saveApolloUpdateGlobalTradeExtendSalesOrg;
        }
        delete jsonBody.action;
        const result = yield call(ajaxPostRequest, url, jsonBody);
        if (!result.IsSuccess) {
            resp = { msg: 'Error saving data', color: FAILED_BGCOLOR };
            yield put(showMessage(resp));
        } else {
            const postJson = {
                WorkflowId: jsonBody.WorkflowTaskModel.WorkflowId,
                TaskId: jsonBody.WorkflowTaskModel.TaskId,
                CustomerNumber: '',
                DivisionTypeId: 0,
                SystemTypeId: 0,
                DistributionChannelTypeId: 0,
                CompanyCodeTypeId: '',
                SalesOrgTypeId: 0,
            };

            yield put(getCustomerFromSAP(postJson));
            // yield put(getStatusBarData(postJson));
            yield put(
                updateTaskStatus({
                    teamId: result.ResultData.WorkflowTeamType,
                    status:
                        result.ResultData.WorkflowTaskModel.WorkflowTaskOperationType ===
                        1
                            ? TASK_APPROVED
                            : TASK_REJECTED,
                })
            );

            resp = {
                msg: 'Successfully saved the data',
                color: SUCCESS_BGCOLOR,
                success: true,
            };
            yield put(showMessage(resp));
        }
        //fileupload aws
        if (payload.files && payload.files.length > 0) {
            yield put(
                showToast({
                    msg: 'Uploading files',
                    color: '#2980b9',
                })
            );
            var files = payload.files;
            // console.log(
            //     'uploading attachments update credit: ',
            //     jsonBody.WorkflowTaskModel.WorkflowId
            // );
            yield* UploadFiles(files, jsonBody.WorkflowTaskModel.WorkflowId);
        }
    } catch (error) {
        resp = { msg: error, color: FAILED_BGCOLOR };
        yield put(showMessage(resp));
    }
}

export function* fetch_mdm_mapping_matrix() {
    yield takeLatest(GET_MDM_MAPPING_MATRIX, getMdmMatrix);
}

export function* updateData() {
    yield takeLatest(UPDATE_DELTAS, updateDeltaDatas);
}

export function* saveTaskCreditUpdate() {
    yield takeLatest(SAVE_APOLLO_UPDATE_CREDIT, saveTaskCredits);
}

export function* saveTaskGlobalTradeUpdate() {
    yield takeLatest(SAVE_APOLLO_UPDATE_GLOBALTRADE, saveTaskGlobalTrade);
}

export function* saveTaskCustomerMasterUpdate() {
    yield takeLatest(SAVE_APOLLO_UPDATE_CUSTOMER_MASTER, saveCustomerMaster);
}
export function* saveTaskPricingUpdate() {
    yield takeLatest(SAVE_APOLLO_UPDATE_PRICING, saveTaskPricing);
}
export function* saveTaskContractUpdate() {
    yield takeLatest(SAVE_APOLLO_UPDATE_CONTRACTS, saveTaskContracts);
}
export function* readReadShiftData() {
    yield takeLatest(READ_REDSHIFT_REQUEST, readRedShift);
}

export function* saveTaskCustGlobalUpdate() {
    yield takeLatest(SAVE_APOLLO_UPDATE_GLOBAL, saveTaskCustomerGlobal);
}

export function* requestBlock() {
    yield takeLatest(REQUEST_BLOCK, blockRequest);
}

const updateFlowSagas = function* rootSaga() {
    yield all([
        fork(fetch_mdm_mapping_matrix),
        fork(updateData),
        fork(saveTaskPricingUpdate),
        fork(saveTaskCreditUpdate),
        fork(saveTaskContractUpdate),
        fork(readReadShiftData),
        fork(saveTaskCustomerMasterUpdate),
        fork(saveTaskCustGlobalUpdate),
        fork(saveTaskGlobalTradeUpdate),
        fork(requestBlock),
    ]);
};
export default updateFlowSagas;
